import React, { useEffect, Fragment } from "react";
import { posti_postal_code } from "../../../../helpers/RequestToAnotherServer";
import AutoFillComponent from "../../../common/AutoFillComponent";
import InputPhone from "../../../common/InputPhone";
import { LabelTextStyle } from "../../../common/StyledComponents";
import TextAreaFieldGroup from "../../../common/TextAreaFieldGroup";
import TextInputModal from "../../../common/TextInputModal";
import UploadImages from "../../../common/UploadImages";

function ServiceForms_2({
  service_type = "",
  client,
  onChangeHandler,
  errors,
  onChangeHandlerMass,
  attachments,
  onChangeAttachments,
  description,
}) {
  useEffect(() => {
    if (client.client_city && client.client_street) {
      const t = setTimeout(async () => {
        const { success, postal_code } = await posti_postal_code(
          client.client_city,
          client.client_street
        );
        if (success && postal_code) {
          onChangeHandler(
            { target: { value: postal_code, name: "client_postal_code" } },
            "client"
          );
        }
      }, 1000);

      return () => {
        clearTimeout(t);
      };
    }
  }, [client.client_city, client.client_street]);
  const ArrayBuilder = (arr) => {
    const responseBuild = arr.map((e) => ({
      label: `${e.firstname} ${e.lastname} ${e.street}`,
      value: e.id,
      city: e.city,
      company: e.company,
      company_code: e.company_code,
      firstname: e.firstname,
      lastname: e.lastname,
      other: e.other,
      phone: e.phone,
      postal_code: e.postal_code,
      street: e.street,
    }));
    return responseBuild;
  };
  const renderSearchEndPoint = (value) => {
    const arrayOfString = value.trim().split(" ");
    const searchString = arrayOfString.reduce(
      (cur, acc) => cur + `&q[firstname_or_lastname_or_street_cont_any]=${acc}`,
      ""
    );
    return `/api/v1/addresses?${searchString}`;
  };
  return (
    <div>
      <LabelTextStyle>Kliento informacija</LabelTextStyle>
      <div className="row">
        <div className="col-3">
          <AutoFillComponent
            label="Pasirinkti klientą"
            endPoint={renderSearchEndPoint}
            onChange={(_, full) =>
              onChangeHandlerMass({
                client_name: full.firstname || "",
                client_lastname: full.lastname || "",
                client_phone: full?.phone?.split("+370").join("") || "",
                client_mail: full.other || "",
                client_city: full.city || "",
                client_street: full.street || "",
                client_postal_code: full.postal_code || "",
              })
            }
            arrayBuilder={ArrayBuilder}
          />
        </div>
        <div className="col-3">
          <TextInputModal
            text="Vardas"
            required={true}
            name="client_name"
            value={client.client_name}
            onChange={(e) => onChangeHandler(e, "client")}
            warning={errors.client_name}
          />
        </div>
        <div className="col-3">
          <TextInputModal
            text="Pavardė"
            required={true}
            name="client_lastname"
            value={client.client_lastname}
            onChange={(e) => onChangeHandler(e, "client")}
            warning={errors.client_lastname}
          />
        </div>
        <div className="col-3">
          <InputPhone
            type="text"
            text="Telefonas"
            required={true}
            name="client_phone"
            placeholder="6XXXXXXX"
            value={client.client_phone}
            onChange={(e) => onChangeHandler(e, "client")}
            warning={errors.client_phone}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-3">
          <TextInputModal
            text="El. paštas"
            name="client_mail"
            value={client.client_mail}
            onChange={(e) => onChangeHandler(e, "client")}
            warning={errors.client_mail}
          />
        </div>
        <div className="col-3">
          <TextInputModal
            text="Miestas"
            required={true}
            name="client_city"
            value={client.client_city}
            onChange={(e) => onChangeHandler(e, "client")}
            warning={errors.client_city}
          />
        </div>
        <div className="col-3">
          <TextInputModal
            text="Gatvė, namo ir buto numeris "
            required={true}
            name="client_street"
            value={client.client_street}
            onChange={(e) => onChangeHandler(e, "client")}
            warning={errors.client_street}
          />
        </div>
        <div className="col-3">
          <TextInputModal
            text="Pašto kodas"
            type="number"
            required={true}
            name="client_postal_code"
            value={client.client_postal_code}
            onChange={(e) => onChangeHandler(e, "client")}
            warning={errors.client_postal_code}
          />
        </div>
        {service_type == "order" && (
          <Fragment>
            <div className="col-6">
              <TextAreaFieldGroup
                type="text"
                placeholder="Aprašymas"
                name="custom"
                rows={3}
                onlyPlaceholder="Įveskite"
                value={description.custom}
                onChange={(e) => onChangeHandler(e, "description")}
              />
            </div>
            <div className="col-6">
              <div className="text-bold my-1 ">Dokumento/nuotraukos įkėlimas</div>
              <UploadImages
                name="custom"
                items={attachments.custom.docs}
                onChangeAttachments={onChangeAttachments}
              />
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default ServiceForms_2;
