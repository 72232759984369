import React from "react";
import InputText from "./InputFilter";
import Container from "../../Layout/Container";
import ContentBlock from "../../Layout/ContentBlock";
import FilterMenu from "../../Layout/FilterMenu";
import TableFilterBox from "../../common/TableFilterBox";
import TableColumnAO from "./items/TableColumnAO";
import ContentValidation from "../../common/ContentValidation";
import { CheckBoxInput, SelectForm } from "../../common/Buttons";
import {
  getOrders,
  getOrder,
  updateSerialNumber,
} from "../../../redux/Actions/orderAction";
import { getCommentStateOption } from "../../../redux/Actions/comment_StateAction";
import { getSuppliersOption } from "../../../redux/Actions/supplierAction";
import { updateUser } from "../../../redux/Actions/userAction";

import { connect } from "react-redux";
import Pagination from "../../Dashboard/items/Pagination";

import { AlertSuccess, AlertError } from "../../common/Alerts";
import RangePickerComp from "../../common/RangePickerComp";
import { Select, Checkbox, Switch, Spin } from "antd";

import RegularModal from "./Modals/RegularModalsOrders";
import {
  exportDocument,
  formatDatePlus,
  getDateFormat,
} from "../../../helpers/Utils";
import { getRivileStoreOptions } from "../../../redux/Actions/rivileStoreAction";
const { Option, OptGroup } = Select;
import Axios from "axios";
import { getCarriers } from "../../../requests/Carriers";
import {
  resetOrdersFilter,
  valueOrdersFilter,
  valueMultiOrdersFilter,
} from "../../../redux/Actions/filtersAction";
import ScrollYComponent from "./items/ScrollYComponent";
import { getPaymentTypesOption } from "../../../redux/Actions/paymentAction";
import { rline_user } from "../../../helpers/user";
import { Label } from "../../global";

class AcceptedOrders extends React.Component {
  constructor() {
    super();
    this.state = {
      requiredId: null,
      checkedArray: [],
      checkedAll: false,
      page: 1,
      pageCount: 100,
      layoutOfTable: [],
      comment_state: [],
      payment_types: [],
      comments_state_all: [],
      stores: [],
      carriers: [],
      paramsFilter: "",
      autoFilter: false,
      rline: false,
      suppliers: [],
    };
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    // this.modalRef.current.openModal("OrderCreateModal");
    const fetchCarriers = async (searchString) => {
      const carriers = await getCarriers();
      this.setState(
        {
          carriers,
        },
        () => (searchString ? this.locationSearch() : null)
      );
    };

    if (this.props.location.search) {
      fetchCarriers(true);
    } else if (!this.props.match.params.action) {
      this.onFilter();
      fetchCarriers();
    } else {
      this.onEdit("test", this.props.match.params.action);
      this.onFilter();
      this.props.history.push("/orders");
      fetchCarriers();
    }
    getRivileStoreOptions().then((stores) =>
      this.setState({
        stores: stores.map((e) => ({ label: e.address, value: e.address })),
      })
    );
    getCommentStateOption().then((res) => {
      if (res.data) {
        let commentAll = [];
        let comment_ka = [];
        let comment_management = [];
        let comment_logistic = [];

        res.data.forEach((item) => {
          let newObj = {
            label: item.name,
            name: item.name,
            value: item.id,
            color: item.color,
            ordering: item.ordering,
            identifier: item.identifier,
            comment_type: item.comment_type,
            show_date: item.show_date,
          };
          commentAll.push(newObj);
          let cmst = item.comment_type;
          if (cmst == "comment_ka") {
            comment_ka.push(item);
          } else if (cmst == "comment_management") {
            comment_management.push(item);
          } else {
            comment_logistic.push(item);
          }
        });
        const return_comment_state = [
          {
            title: "KA komentaras",
            children: comment_ka,
          },
          {
            title: "Vadybos komentaras",
            children: comment_management,
          },
          {
            title: "Logistikos komentaras",
            children: comment_logistic,
          },
        ];
        this.setState({
          comment_state: return_comment_state,
          comments_state_all: commentAll,
        });
      }
    });

    getSuppliersOption().then((response) => {
      if (response.data) {
        let newArray = [
          { label: "Visi", value: "" },
          { label: "Nėra tiekimo", value: "null" },
        ];
        response.data.data.forEach((item) => {
          const findIndex = newArray.findIndex(
            (find) => find.name == item.name
          );
          if (findIndex == -1) {
            return newArray.push({
              value: item.id,
              label: item.name,
            });
          }
        });
        this.setState({
          suppliers: newArray,
        });
      }
    });

    getPaymentTypesOption().then((payment_types) => {
      this.setState({
        payment_types: [{ label: "Visi", value: "" }, ...payment_types],
      });
    });

    this.layoutOfTableLocalStorage();
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.props.getOrders(
        this.state.page,
        this.state.pageCount,
        this.state.paramsFilter
      );
    }
  }

  layoutOfTableLocalStorage = () => {
    const defaultStorage = [
      "Užsakymo data",
      "Eksp",
      "Order numeris",
      "Klientas",
      "Suma",
      "Prekes",
      "Likučiai",
      "DOCS",
      "Mokėjimo būdas",
      "Pristatymas",
      "Statusas",
      "KA komentarai",
      "Vadyba",
      "Logistika",
    ];
    const getLocalStorage = Array.isArray(
      JSON.parse(localStorage.getItem("orderTable"))
    )
      ? JSON.parse(localStorage.getItem("orderTable"))
      : defaultStorage;

    this.setState({
      layoutOfTable: getLocalStorage,
    });
  };

  reloadOrders = () => {
    this.props.getOrders(
      this.state.page,
      this.state.pageCount,
      this.state.paramsFilter
    );
  };

  reloadData = (data) => {
    this.props.getOrders(this.state.page, this.state.pageCount, data);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.user).length > 0 && !this.state.autoFilter) {
      this.setState(
        {
          autoFilter: true,
        },
        () =>
          this.props.valueMultiOrdersFilter({
            statusFilter: nextProps.user.user_statuses,
            categoryFilter: nextProps.user.user_categories,
          })
      );
    }
  }

  locationSearch = async () => {
    let comment_state = [];
    const delivery = this.getQueryVariable("delivery")
      ? decodeURI(
          this.getQueryVariable("delivery").split("+").join(" ").split("%2C")
        ).split(",")
      : undefined;
    const delivery_custom = delivery
      ? this.deliveryCustom(
          this.getQueryVariable("delivery")
            ? decodeURI(
                this.getQueryVariable("delivery")
                  .split("+")
                  .join(" ")
                  .split("%2C")
              ).split(",")
            : []
        )
      : [];
    const co_s_ka = this.getQueryVariable("comment_state_ka")
      ? this.getQueryVariable("comment_state_ka").split("%2C")
      : [];
    const co_s_log = this.getQueryVariable("comment_state_log")
      ? this.getQueryVariable("comment_state_log").split("%2C")
      : [];
    const co_s_man = this.getQueryVariable("comment_state_man")
      ? this.getQueryVariable("comment_state_man").split("%2C")
      : [];
    if (co_s_ka.length > 0) {
      co_s_ka.forEach((item) =>
        comment_state.push({ children2: "KA komentaras", value: +item })
      );
    }
    if (co_s_log.length > 0) {
      co_s_log.forEach((item) =>
        comment_state.push({
          children2: "Logistikos komentaras",
          value: +item,
        })
      );
    }
    if (co_s_man.length > 0) {
      co_s_man.forEach((item) =>
        comment_state.push({ children2: "Vadybos komentaras ", value: +item })
      );
    }

    await this.props.valueMultiOrdersFilter({
      id: this.getQueryVariable("id"),
      client: this.getQueryVariable("client").split("+").join(" "),
      order_row: this.getQueryVariable("order_row").split("+").join(" "),
      date:
        this.getQueryVariable("date") !== ""
          ? this.getQueryVariable("date").split("%2C")
          : ["", ""],
      r_line: this.getQueryVariable("r_line"),
      delivery,
      delivery_custom,
      payment: this.getQueryVariable("payment")
        ? decodeURI(this.getQueryVariable("payment")).split("+").join(" ")
        : "",
      comment: this.getQueryVariable("comment").split("+").join(" "),
      comment_state: comment_state,
      at_the_store:
        decodeURI(this.getQueryVariable("at_the_store").split("+").join(" ")) ||
        undefined,
    });
    await this.onFilter();
  };

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return "";
  }

  setGetParam(key, value) {
    if (history.pushState) {
      let params = new URLSearchParams(window.location.search);
      if (Array.isArray(value) && value[0] == "") {
        params.delete(key);
      } else if (value !== undefined && value.length > 0) {
        params.set(key, value);
      } else {
        params.delete(key);
      }

      var newUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        params.toString();
      window.history.pushState({ path: newUrl }, "", newUrl);
    }
  }

  onEdit = (order_id, id) => {
    this.setState({
      requiredId: order_id,
    });
    this.props.getOrder(id);
    this.modalRef.current.openModal("OrderShowModal");
  };

  onCreate = (options = {}) => {
    const { rline } = options;

    this.setState({
      requiredId: null,
      rline,
    });
    this.modalRef.current.openModal("OrderCreateModal");
  };

  setPagination = (page) => {
    this.setState({
      page,
    });
  };
  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  handleCheckboxChange = (value) => {
    const currentIndex = this.state.checkedArray.indexOf(value);
    const newChecked = [...this.state.checkedArray];
    const myData = this.props.orders;

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    let variableAll = myData.length === newChecked.length;

    this.setState({
      checkedArray: newChecked,
      checkedAll: variableAll,
    });
  };

  handleCheckboxAll() {
    const myData = this.props.orders;
    const newChecked = [...this.state.checkedArray];
    let newArray = [];
    if (myData.length === newChecked.length) {
      this.setState({
        checkedAll: false,
        checkedArray: [],
      });
    } else {
      myData.forEach((item) => newArray.push(item.id));
      this.setState({
        checkedAll: true,
        checkedArray: newArray,
      });
    }
  }

  checkBoxIncludes = (item) => {
    return this.state.layoutOfTable.includes(item);
  };

  tableColumnSelector = (selectedItems) => {
    this.setState({
      layoutOfTable: selectedItems,
    });
  };

  deliveryCustom = (items) => {
    const delivery_custom = [];
    items.forEach((e) => {
      const finItem = this.state.carriers.find((fi) => fi.value == e);
      if (finItem) {
        finItem.options.forEach((fe) => delivery_custom.push(fe));
      }
    });
    return delivery_custom;
  };

  filterChange = async (e, date) => {
    if (date === "delivery") {
      let filters = {
        [date]: e,
        delivery_custom: this.deliveryCustom(e),
      };

      const includesStore = e
        .map((e) => e.toLowerCase())
        .some((e) => e.includes("atsiėmimas"));
      if (!includesStore) {
        filters.at_the_store = undefined;
      }

      await this.props.valueMultiOrdersFilter(filters);
      await this.onFilter();
    } else if (date) {
      await this.props.valueOrdersFilter({ name: date, value: e });
      await this.onFilter();
    } else {
      this.props.valueOrdersFilter({
        name: e.target.name,
        value: e.target.value,
      });
    }
  };

  filterStatusChange = async (e) => {
    let body = {
      user: {
        user_statuses: e,
      },
    };
    await this.props.updateUser(this.props.user.id, body);
    await this.props.valueOrdersFilter({ name: "statusFilter", value: e });
    await this.reloadOrders();
  };

  filterCategoryChange = async (e) => {
    let body = {
      user: {
        user_categories: e,
      },
    };
    await this.props.updateUser(this.props.user.id, body);
    await this.props.valueOrdersFilter({ name: "categoryFilter", value: e });
    await this.reloadOrders();
  };

  onFilterActive = async () => {
    await this.props.valueMultiOrdersFilter({
      active_orders: !this.props.orderFilter.active_orders,
      not_ready_orders: false,
      express_orders: false,
    });
    await this.onFilter();
  };

  onFilterExpress = async () => {
    await this.props.valueMultiOrdersFilter({
      express_orders: !this.props.orderFilter.express_orders,
      active_orders: false,
      not_ready_orders: false,
    });
    await this.onFilter();
  };

  onFilterNotReady = async () => {
    await this.props.valueMultiOrdersFilter({
      not_ready_orders: !this.props.orderFilter.not_ready_orders,
      active_orders: false,
      express_orders: false,
    });
    await this.onFilter();
  };

  onFilter = () => {
    if (this.props.loading_orders) {
      return this.delayOnFilter();
    }
    let filterParams = [];

    if (this.props.orderFilter.game_participation) {
      filterParams.push(
        `&q[order_additionals_key_cont_any]=game_participation`
      );
    }
    if (!rline_user(this.props.user.user_role.identifier)) {
      if (this.props.orderFilter.express_orders) {
        filterParams.push(
          `&q[shipping_address_street2_or_billing_address_street2_eq]=express${[
            5, 6, 39, 29, 45,
          ]
            .map((e) => `&q[status_id_status_not_in][]=${e}`)
            .join("")}`
        );
      }
      if (this.props.orderFilter.not_ready_orders) {
        filterParams.push(
          // status_id = "Uzsakymas issiustas"
          // comment_state_id NE "Paruosta atsiemimui"
          `&q[not_ready_eq]=1`
        );
      }
      if (this.props.orderFilter.active_orders) {
        filterParams.push(
          // add param and count orders based on it?
          // status_id Ne "Baigta", "Pristatyta", "Atsaukta"
          `&q[order_active_eq]=1`
        );
      }
    }

    if (this.props.orderFilter.supplier === "null") {
      filterParams.push("&q[order_rows_supplyings_id_blank]=1");
    } else if (
      this.props.orderFilter.supplier &&
      this.props.orderFilter.supplier != ""
    ) {
      filterParams.push(
        `&q[order_rows_supplyings_supplier_id_eq]=${this.props.orderFilter.supplier}`
      );
    }

    if (this.props.orderFilter.supp_date[0].length > 0) {
      filterParams.push(
        `&q[order_rows_supplyings_date_gteq]=${this.props.orderFilter.supp_date[0]}`
      );
    }

    if (this.props.orderFilter.supp_date[1].length > 0) {
      filterParams.push(
        `&q[order_rows_supplyings_date_lteq]=${this.props.orderFilter.supp_date[1]}`
      );
    }

    if (this.props.orderFilter.id.length > 0) {
      filterParams.push(`&q[id_order_cont]=${this.props.orderFilter.id}`);
    }
    if (this.props.orderFilter.client.length > 0) {
      let clientArray = this.props.orderFilter.client.split(" ");
      clientArray.forEach((item) => {
        filterParams.push(
          `&q[billing_address_firstname_or_billing_address_lastname_or_billing_address_phone_or_billing_address_company_or_billing_address_other_cont_any]=${item}`
        );
      });
    }
    if (
      this.props.orderFilter.delivery &&
      this.props.orderFilter.delivery
        .map((e) => e.toLowerCase())
        .some((e) => e.includes("atsiėmimas")) &&
      this.props.orderFilter.at_the_store &&
      this.props.orderFilter.at_the_store !== "Visos"
    ) {
      filterParams.push(
        `&q[shipping_address_street_cont_any]=${this.props.orderFilter.at_the_store}`
      );
    }

    if (
      !rline_user(this.props.user.user_role.identifier) &&
      this.props.orderFilter.created
    ) {
      filterParams.push(
        `&q[created_cont_any]=${this.props.orderFilter.created}`
      );
    }
    if (this.props.orderFilter.date[0].length > 0)
      filterParams.push(
        `&q[order_date_gteq]=${this.props.orderFilter.date[0]}`
      );

    if (this.props.orderFilter.date[1].length > 0) {
      filterParams.push(
        `&q[order_date_lt]=${formatDatePlus(this.props.orderFilter.date[1])}`
      );
    }

    if (this.props.orderFilter.order_row.length > 0)
      filterParams.push(
        `&q[order_rows_product_name_or_order_rows_product_reference_or_order_rows_product_upc_cont]=${this.props.orderFilter.order_row}`
      );

    if (
      this.props.orderFilter.payment &&
      !["0", 0].includes(this.props.orderFilter.payment)
    )
      filterParams.push(`&q[payment_cont]=${this.props.orderFilter.payment}`);

    if (this.props.orderFilter.delivery !== undefined) {
      this.props.orderFilter.delivery_custom?.forEach((each) => {
        filterParams.push(`&q[carrier_name_in][]=${each}`);
      });
    }

    // Rline
    if (
      !rline_user(this.props.user.user_role.identifier) &&
      this.props.orderFilter.r_line &&
      this.props.orderFilter.r_line.length > 0
    ) {
      if (this.props.orderFilter.r_line == "rline") {
        filterParams.push(`&q[customer_rline_true]=true`);
      } else {
        filterParams.push(`&q[customer_rline_true]=false`);
      }
    }

    if (this.props.orderFilter.comment.length > 0) {
      this.props.orderFilter.comment.split(" ").forEach((item) => {
        filterParams.push(`&q[comments_body_cont]=${item}`);
      });
    }

    // Serialnumber order_rows
    if (this.props.orderFilter.serialNumber_check) {
      const filterSerialNumberSwitch =
        this.props.orderFilter.serialNumber_switch;
      const filterParramString = `&q[order_rows_required_serial_number_true]=${filterSerialNumberSwitch}&q[order_rows_serial_number_present]=${
        filterSerialNumberSwitch
          ? !filterSerialNumberSwitch
          : filterSerialNumberSwitch
      }`;
      filterParams.push(filterParramString);
    }

    let filterSearchParamsUrl = [
      {
        key: "id",
        value: this.props.orderFilter.id,
      },
      {
        key: "client",
        value: this.props.orderFilter.client,
      },
      {
        key: "date",
        value: this.props.orderFilter.date,
      },
      {
        key: "payment",
        value: this.props.orderFilter.payment,
      },
      {
        key: "order_row",
        value: this.props.orderFilter.order_row,
      },
      {
        key: "delivery",
        value: this.props.orderFilter.delivery,
      },
      // {
      //   key: "delivery_custom",
      //   value: this.props.orderFilter.delivery_custom,
      // },
      {
        key: "r_line",
        value: this.props.orderFilter.r_line,
      },
      {
        key: "comment",
        value: this.props.orderFilter.comment,
      },
      {
        key: "at_the_store",
        value:
          this.props.orderFilter.delivery &&
          this.props.orderFilter.delivery
            .map((e) => e.toLowerCase())
            .some((e) => e.includes("atsiėmimas")) &&
          ![undefined, "Visos"].includes(this.props.orderFilter.at_the_store)
            ? this.props.orderFilter.at_the_store
            : "",
      },
      // {
      //   key: "comment_state",
      //   value: this.props.orderFilter.comment_state.map((item) => item.value),
      // },
    ];

    if (this.props.orderFilter.comment_state.length > 0) {
      let search_ka = [];
      let search_log = [];
      let search_man = [];
      this.props.orderFilter.comment_state.forEach((item) => {
        if (item.children2 == "KA komentaras") {
          filterParams.push(`&q[comment_ka_id_in][]=${item.value}`);
          search_ka.push(item.value);
        } else if (item.children2 == "Logistikos komentaras") {
          filterParams.push(`&q[comment_logistic_id_in][]=${item.value}`);
          search_log.push(item.value);
        } else if (item.children2 == "Vadybos komentaras") {
          filterParams.push(`&q[comment_management_id_in][]=${item.value}`);
          search_man.push(item.value);
        }
      });
      filterSearchParamsUrl.push({
        key: "comment_state_ka",
        value: search_ka,
      });
      filterSearchParamsUrl.push({
        key: "comment_state_log",
        value: search_log,
      });
      filterSearchParamsUrl.push({
        key: "comment_state_man",
        value: search_man,
      });
    } else {
      filterSearchParamsUrl.push({
        key: "comment_state_ka",
        value: [],
      });
      filterSearchParamsUrl.push({
        key: "comment_state_log",
        value: [],
      });
      filterSearchParamsUrl.push({
        key: "comment_state_man",
        value: [],
      });
    }

    filterSearchParamsUrl.forEach((item) => {
      this.setGetParam(item.key, item.value);
    });
    this.setState(
      {
        paramsFilter: filterParams.join(""),
        page: 1,
      },
      () => this.reloadData(filterParams.join(""))
    );
  };
  delayOnFilter = () => {
    if (this.props.loading_orders) {
      return setTimeout(() => {
        return this.delayOnFilter();
      }, 1000);
    }
    this.onFilter();
  };

  handleKeyDown = (e) => {
    if (e.key == "Enter") {
      this.onFilter();
    }
  };

  clearFilter = async () => {
    await this.props.resetOrdersFilter();
    await this.onFilter();
  };

  exportContracts = (action) => {
    let url;
    let checked;
    if (action == "selected") {
      url = `/api/v1/orders/export_orders_in_excel?${this.state.paramsFilter}`;
      checked = this.state.checkedArray;
    } else if (action == "all") {
      url = `/api/v1/orders/export_orders_in_excel?${this.state.paramsFilter}`;
      checked = [];
    }
    Axios({
      url: url,
      method: "POST",
      data: {
        checked: checked,
      },
      responseType: "blob", // important
      headers: { Accept: "application/vnd.ms-excel" },
    }).then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", `${"Užsakymai"}.xlsx`); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  };

  innerDocs = (action) => {
    let url;
    let checked;
    url = `/api/v1/inner_docs?${this.state.paramsFilter}`;
    checked = this.state.checkedArray;
    Axios({
      url: url,
      method: "POST",
      data: {
        checked: checked,
      },
    }).then((response) => {});
  };

  renderCustomButton = () => {
    return (
      <>
        <div className="dropdown">
          <button
            className="btn btn-sm btn-primary float-left ml-3 dropdown-toggle"
            type="button"
            id="dropdownMenuButtonNewOrder"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Naujas užsakymas
          </button>

          <div
            className="dropdown-menu"
            aria-labelledby="dropdownMenuButtonNewOrder"
          >
            {!rline_user(this.props.user.user_role.identifier) && (
              <button className="dropdown-item" onClick={this.onCreate}>
                Online
              </button>
            )}

            {this.props.user.store_id ? (
              <button
                className="dropdown-item"
                onClick={() => this.onCreate({ rline: true })}
              >
                R-Line
              </button>
            ) : null}
          </div>
        </div>
        {!rline_user(this.props.user.user_role.identifier) && (
          <div className="dropdown">
            <button
              className="btn btn-sm btn-dark float-right mr-3 dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Eksportas
            </button>

            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <button
                className="dropdown-item"
                onClick={() => this.exportContracts("selected")}
              >
                Eksportuoti pasirinktus
              </button>
              <button
                className="dropdown-item"
                onClick={() => this.exportContracts("all")}
              >
                Eksportuoti išfiltruotus
              </button>

              <button
                className="dropdown-item"
                onClick={() =>
                  exportDocument(
                    `/api/v1/orders/export_refrigerator_game_participation`,
                    `refrigerator_game_participation.xlsx`,
                    "POST"
                  )
                }
              >
                Išfiltruotus loterijos dalyvius
              </button>

              <button
                className="dropdown-item"
                onClick={() => this.innerDocs()}
              >
                Vidinis į U050
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  render() {
    const requiredItem = this.state.requiredId;
    const customText = (
      <div>
        <span className="filterMenuText first">Viso suma:</span>
        <span className="firstLength">
          {this.props.total_amount} {"\u20AC"}
        </span>
      </div>
    );
    return (
      <>
        <Container
          location={{
            locationName: "Patvirtinti užsakymai",
            locationUrl: this.props.match.url,
          }}
        >
          <>
            <ContentBlock
              extraClass="sticky-top sticky-extra"
              style={{ zIndex: 102 }}
            >
              <div className="d-flex align-items-center">
                <ScrollYComponent />
                <div style={{ width: "100%" }}>
                  <FilterMenu
                    length={`${this.props.totals || 0} / ${
                      this.props.orders.length || 0
                    }`}
                    text="užsakymų"
                    text2="Užsakymų"
                    onChangeCountPage={this.setPageCount}
                    countPage={this.state.pageCount}
                    // statusFilter={true}
                    statusFilter={this.modalRef.current}
                    categoryFilterModal={this.modalRef.current}
                    tableLayout={this.modalRef.current}
                    customText={customText}
                  />
                </div>
              </div>
            </ContentBlock>
            <AlertSuccess alertId={"order-s"} />
            <AlertError alertId={"order-e"} />
            <ContentBlock>
              <TableFilterBox
                extraClass="sticky-top"
                style={{
                  top: document.querySelector(".sticky-extra")
                    ? document.querySelector(".sticky-extra").offsetHeight
                    : "50px",
                  zIndex: 10,
                }}
                clearFilter={() => this.clearFilter()}
                button1={this.renderCustomButton()}
              >
                <div className="px-1">
                  <InputText
                    number="1"
                    name="id"
                    value={this.props.orderFilter.id}
                    placeholder="Užsakymo nr."
                    // blockSize="12rem"
                    style={{ width: 150, fontSize: "0.75rem" }}
                    onKeyDown={this.handleKeyDown}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
                <div className="px-1">
                  <InputText
                    // width={"0.75rem"}
                    style={{ width: 150, fontSize: "0.75rem" }}
                    number="1"
                    name="client"
                    placeholder="Klientas"
                    value={this.props.orderFilter.client}
                    onKeyDown={this.handleKeyDown}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
                <div className="px-1">
                  <InputText
                    // width={"0.75rem"}
                    style={{ width: 150, fontSize: "0.75rem" }}
                    number="1"
                    name="order_row"
                    placeholder="Prekė"
                    onKeyDown={this.handleKeyDown}
                    value={this.props.orderFilter.order_row}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
                <div className="px-1">
                  <div className="form-group">
                    <Label>Data:</Label>
                    <div>
                      <RangePickerComp
                        style={{ width: 250 }}
                        placeholder={["Pradžios laikas", "Pabaigos laikas"]}
                        value={[
                          getDateFormat(this.props.orderFilter.date[0]),
                          getDateFormat(this.props.orderFilter.date[1]),
                        ]}
                        onChange={(e, args) => this.filterChange(args, "date")}
                      />
                    </div>
                  </div>
                </div>
                {!rline_user(this.props.user.user_role.identifier) && (
                  <div className="px-1" style={{ width: "150px" }}>
                    <SelectForm
                      placeholder={"Kanalas"}
                      onChange={(e) => this.filterChange(e, "r_line")}
                      options={[
                        { label: "Visi", value: "" },
                        { label: "RLine", value: "rline" },
                        { label: "Online", value: "online" },
                      ]}
                      value={this.props.orderFilter.r_line}
                    />
                  </div>
                )}
                <div className="px-1" style={{ width: "150px" }}>
                  <SelectForm
                    options={this.state.payment_types}
                    dropdownMatchSelectWidth={false}
                    placeholder="Mokėjimo būdas"
                    onChange={(e) => this.filterChange(e, "payment")}
                    value={this.props.orderFilter.payment}
                  />
                </div>
                <div className="px-1" style={{ width: "150px" }}>
                  <SelectForm
                    options={this.state.suppliers}
                    dropdownMatchSelectWidth={false}
                    placeholder="Tiekėjas"
                    onChange={(e) => this.filterChange(e, "supplier")}
                    value={this.props.orderFilter.supplier}
                  />
                </div>
                <div className="px-1">
                  <div className="form-group">
                    <Label>Tiekimo data</Label>
                    <div>
                      <RangePickerComp
                        style={{ width: 250 }}
                        placeholder={["Pradžios laikas", "Pabaigos laikas"]}
                        disabledDate={() => {}}
                        value={[
                          getDateFormat(this.props.orderFilter.supp_date[0]),
                          getDateFormat(this.props.orderFilter.supp_date[1]),
                        ]}
                        onChange={(e, args) =>
                          this.filterChange(args, "supp_date")
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="px-1" style={{ minWidth: "150px" }}>
                  <SelectForm
                    placeholder="Pristatymo tipas"
                    mode="multiple"
                    dropdownMatchSelectWidth={false}
                    options={this.state.carriers}
                    onChange={(e) => this.filterChange(e, "delivery")}
                    value={this.props.orderFilter.delivery}
                  />
                </div>
                {!rline_user(this.props.user.user_role.identifier) && (
                  <div className="px-1" style={{ minWidth: "150px" }}>
                    <SelectForm
                      placeholder="Sukurtas per"
                      dropdownMatchSelectWidth={false}
                      options={[
                        {
                          label: "Visi",
                          value: null,
                        },
                        {
                          label: "Iš presta",
                          value: "presta",
                        },
                        {
                          label: "Per užsakymus",
                          value: "užsakymai",
                        },
                      ]}
                      onChange={(e) => this.filterChange(e, "created")}
                      value={this.props.orderFilter.created}
                    />
                  </div>
                )}
                <div className="px-1" style={{ minWidth: "150px" }}>
                  <Label>Komentarų statusai</Label>
                  <Select
                    placeholder="Komentarų statusai"
                    mode="multiple"
                    dropdownMatchSelectWidth={false}
                    value={
                      this.props.orderFilter.comment_state.map(
                        (item) => item.value
                      ) || undefined
                    }
                    style={{ minWidth: 150, maxWidth: 1000 }}
                    onChange={(e, arg) =>
                      this.filterChange(arg, "comment_state")
                    }
                    filterOption={(input, option) => {
                      if (option.children) {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {this.state.comment_state.map((comState, index) => {
                      return (
                        <OptGroup key={index} label={comState.title}>
                          {comState.children.map((com_children, idx) => (
                            <Option
                              key={`children_${index}_${idx}`}
                              children2={comState.title}
                              value={com_children.id}
                            >
                              {com_children.name}
                            </Option>
                          ))}
                        </OptGroup>
                      );
                    })}
                  </Select>
                </div>
                <div className="px-1">
                  <InputText
                    style={{ width: 150, fontSize: "0.75rem" }}
                    number="1"
                    name="comment"
                    placeholder="Komentarai"
                    onKeyDown={this.handleKeyDown}
                    onChange={(e) => this.filterChange(e)}
                    value={this.props.orderFilter.comment}
                  />
                </div>
                <div className="px-1">
                  <div className="form-group">
                    <div>
                      <Label>Dalyvauja loterijoje</Label>
                    </div>
                    <div
                      className="d-flex justify-content-center mt-2"
                      style={{ fontSize: "0.75rem" }}
                    >
                      <Checkbox
                        checked={this.props.orderFilter.game_participation}
                        onChange={(e) =>
                          this.filterChange(
                            e.target.checked,
                            "game_participation"
                          )
                        }
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="px-1">
                  <div className="form-group">
                    <div>
                      <Label>Serijinis numeris</Label>
                    </div>
                    <div
                      className="d-flex justify-content-center mt-2"
                      style={{ fontSize: "0.75rem" }}
                    >
                      <Checkbox
                        checked={this.props.orderFilter.serialNumber_check}
                        onChange={(e) =>
                          this.filterChange(
                            e.target.checked,
                            "serialNumber_check"
                          )
                        }
                      />
                      {this.props.orderFilter.serialNumber_check && (
                        <Switch
                          checkedChildren={"S/N"}
                          unCheckedChildren={"S/N"}
                          checked={this.props.orderFilter.serialNumber_switch}
                          onChange={(value) =>
                            this.filterChange(value, "serialNumber_switch")
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>{" "}
                <br />
                {!rline_user(this.props.user.user_role.identifier) && (
                  <div className="mr-3 d-flex justify-content-center align-items-center">
                    <div className="mr-3 d-flex justify-content-center align-items-center">
                      <span
                        className="filter-order-span"
                        style={{
                          color: this.props.orderFilter.not_ready_orders
                            ? "#FF0000"
                            : "#bfbfbf",
                        }}
                        onClick={this.onFilterNotReady}
                      >
                        Neparuošti
                      </span>
                      <div
                        className="ml-2 text-center filter-orders-active"
                        style={{ background: "#FF0000" }}
                      >
                        {this.props.total_not_ready_orders == "loading" ? (
                          <Spin className="custom_spinner" />
                        ) : (
                          this.props.total_not_ready_orders
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center mr-3">
                      <span
                        className="filter-order-span"
                        style={{
                          color: this.props.orderFilter.active_orders
                            ? "#008000"
                            : "#bfbfbf",
                        }}
                        onClick={this.onFilterActive}
                      >
                        Aktyvūs
                      </span>
                      <div
                        className="ml-2 text-center filter-orders-active"
                        style={{ background: "#008000" }}
                      >
                        {this.props.total_active_orders == "loading" ? (
                          <Spin className="custom_spinner" />
                        ) : (
                          this.props.total_active_orders
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <span
                        className="filter-order-span"
                        style={{
                          color: this.props.orderFilter.express_orders
                            ? "#F7951E"
                            : "#bfbfbf",
                        }}
                        onClick={this.onFilterExpress}
                      >
                        Skubūs
                      </span>
                      <div
                        className="ml-2 text-center filter-orders-active"
                        style={{ background: "#F7951E" }}
                      >
                        {this.props.total_express_orders == "loading" ? (
                          <Spin className="custom_spinner" />
                        ) : (
                          this.props.total_express_orders
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {this.props.orderFilter.delivery &&
                  this.props.orderFilter.delivery
                    .map((e) => e.toLowerCase())
                    .some((e) => e.includes("atsiėmimas")) && (
                    <div className="pr-1 pl-3" style={{ width: "180px" }}>
                      <SelectForm
                        options={[
                          { label: "Visos", value: "Visos" },
                          ...this.state.stores,
                        ]}
                        dropdownMatchSelectWidth={false}
                        placeholder="Atsiėmimas parduotuvėje"
                        onChange={(e) => this.filterChange(e, "at_the_store")}
                        value={this.props.orderFilter.at_the_store}
                      />
                    </div>
                  )}
              </TableFilterBox>
              <div>
                <ContentValidation
                  loader={this.props.loading_orders}
                  array={this.props.orders}
                  alertId_s="ordersAlert-s"
                  alertId_e="ordersAlert-e"
                >
                  <Pagination
                    setPagination={this.setPagination}
                    page={this.state.page}
                    totals={this.props.totals}
                    pageCount={this.state.pageCount}
                  />
                  <div className="table-responsive">
                    <table className="table table-sm table-hover mt-3">
                      <thead>
                        <tr className="text-muted">
                          <th className="text-black">
                            <div className="d-flex">
                              <CheckBoxInput
                                type="checkbox"
                                color="#f7951e"
                                checked={this.state.checkedAll}
                                onChange={() => this.handleCheckboxAll()}
                              />
                            </div>
                          </th>
                          {this.checkBoxIncludes("Eksp") && (
                            <th className="text-black">Eksp.</th>
                          )}
                          {this.checkBoxIncludes("Užsakymo data") && (
                            <th className="text-black">Užs. data</th>
                          )}
                          {this.checkBoxIncludes("Order numeris") && (
                            <th className="text-black">Nr</th>
                          )}
                          {this.checkBoxIncludes("Klientas") && (
                            <th className="text-black">Klientas</th>
                          )}
                          {this.checkBoxIncludes("Gabenimo adresas") && (
                            <th className="text-black">Gabenimo adresas</th>
                          )}
                          {this.checkBoxIncludes("Prekes") && (
                            <th className="text-black">Prekės</th>
                          )}
                          {this.checkBoxIncludes("Likučiai") && (
                            <th className="text-black">Likučiai</th>
                          )}
                          {this.checkBoxIncludes("Mokėjimo būdas") && (
                            <th className="text-black">Mokėjimo būdas</th>
                          )}
                          {this.checkBoxIncludes("Pristatymas") && (
                            <th className="text-black">Pristatymas</th>
                          )}
                          {this.checkBoxIncludes("Statusas") && (
                            <th className="text-black">Statusas</th>
                          )}
                          {this.checkBoxIncludes("KA komentarai") && (
                            <th className="text-black">KA komentarai</th>
                          )}
                          {this.checkBoxIncludes("Logistika") && (
                            <th className="text-black">
                              Logistikos komentarai
                            </th>
                          )}
                          {this.checkBoxIncludes("Vadyba") && (
                            <th className="text-black">Vadybos komentarai</th>
                          )}
                          {this.checkBoxIncludes("Sukurė") && (
                            <th className="text-black">Sukurė</th>
                          )}
                          {this.checkBoxIncludes("Suma") && (
                            <th className="text-black">Suma</th>
                          )}
                          <th className="text-black"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.orders.map((item, index) => {
                          return (
                            <TableColumnAO
                              key={index}
                              index={index}
                              id={item.id}
                              item={item}
                              onEdit={this.onEdit}
                              comments_state={this.state.comments_state_all}
                              handleCheckboxChange={this.handleCheckboxChange}
                              layoutOfTable={this.state.layoutOfTable}
                              checkedArray={this.state.checkedArray}
                              checkBoxIncludes={this.checkBoxIncludes}
                              updateSerialNumber={this.props.updateSerialNumber}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                    <Pagination
                      setPagination={this.setPagination}
                      page={this.state.page}
                      totals={this.props.totals}
                      pageCount={this.state.pageCount}
                    />
                  </div>
                </ContentValidation>
              </div>
            </ContentBlock>
          </>
          <div id="modal-roots" />
          <RegularModal
            ref={this.modalRef}
            layoutOfTable={this.state.layoutOfTable}
            layoutOfTablesFunc={this.tableColumnSelector}
            filterCategoryChange={this.filterCategoryChange}
            categoryFilter={this.props.orderFilter.categoryFilter}
            avalaibleStatus={this.props.avalaibleStatus}
            statusFilter={this.props.orderFilter.statusFilter}
            filterStatusChange={(e) => this.filterStatusChange(e)}
            // Order Show Modal
            // objectData={modalData}
            requiredItem={requiredItem}
            comments_state_all={this.state.comments_state_all}
            // Order created modal
            payment_types={this.state.payment_types}
            carriers={this.state.carriers}
            rline={this.state.rline}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  // Pagr order
  orders: state.orders.orders,
  avalaibleStatus: state.orders.avalaibleStatus,
  totals: state.orders.totals,
  total_express_orders: state.orders.total_express_orders,
  total_active_orders: state.orders.total_active_orders,
  total_not_ready_orders: state.orders.total_not_ready_orders,
  total_amount: state.orders.total_amount,
  loading_orders: state.orders.loading_orders,
  user: state.auth.user,
  orderFilter: state.filters.orders,
});
export default connect(mapStateToProps, {
  updateUser,
  getOrders,
  getOrder,
  updateSerialNumber,
  getSuppliersOption,
  resetOrdersFilter,
  valueOrdersFilter,
  valueMultiOrdersFilter,
})(AcceptedOrders);
