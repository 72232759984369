
import Axios from "axios";
import {
  antdMessageError,
  antdMessageSuccess,
  buildErrorObject,
  errorHandler,
} from "../../helpers/Utils";

export const getEnergyLabels =
  (page = 1, pageCount = 50, filter = "") =>
    async (dispatch) => {
      try {
        dispatch({
          type: "LOADING_CUSTOM",
        });
        const { data } = await Axios.get(
          `/api/v1/energy_labels?page=${page}&per_page=${pageCount}${filter}`
        );
        console.log({ data })
        if (data.success) {
          dispatch({
            type: "GET_CUSTOMS",
            payload: { ...data, current: "EnergyLabels" },
          });
          return { success: true };
        }
        throw new Error("Error");
      } catch (err) {
        console.error('getEnergyLabels: ', err)
        dispatch({
          type: "STOP_LOADING_CUSTOM",
        });
        return { success: false };
      }
    };

export const updateEnergyLabel = (params) => async (dispatch) => {
  try {
    const api_url = params.id
      ? `/api/v1/energy_labels/${params.id}`
      : "/api/v1/energy_labels/";
    let body = { ...params }
    body.energy_products_attributes = params.energy_products.map(d => {
      const new_val = { ...d }
      delete new_val.imported
      return new_val
    })
    delete body.label_image_base64
    delete body.data_sheet_base64
    delete body.energy_products
    console.log({ body })
    if (typeof body.data_sheet == 'object') delete body.data_sheet
    if (typeof body.label_image == 'object') delete body.label_image
    // if(type)

    const { data } = await Axios({
      url: api_url,
      data: {
        energy_label: body,
      },
      method: params.id ? "PUT" : "POST",
    });

    if (data.success) {
      dispatch({
        type: params.id ? "UPDATE_CUSTOM" : "POST_CUSTOM",
        payload: data.data,
      });
      antdMessageSuccess();
      return { success: true }
    }
    throw new Error("Error");
  } catch (err) {
    console.log(err.response?.data?.message);
    antdMessageError(err.response?.data?.message);
    errorHandler(err);
    return {
      success: false,
      errors: buildErrorObject(err?.response?.data?.errors),
    };
  }
};



export const deleteEnergyLabel = (id) => async (dispatch) => {
  try {
    const { data } = await Axios.delete(`/api/v1/energy_labels/${id}`);
    if (!data?.success) throw new Error("Error");
    dispatch({
      type: "REMOVE_CUSTOM",
      payload: id,
    });
    antdMessageSuccess();
    return { success: true };
  } catch (err) {
    antdMessageError();
    errorHandler(err);
    return { success: false };
  }
};


export const getEnergyLabelsOptions = () => async (dispatch) => {
  try {
    const getOptions = () =>
      Axios.get(`/api/v1/energy_labels/energy_class_image_options`).then((res) => {
        return res.data;
      });

    Promise.all([getOptions()]).then((res) => {
      console.log("Response: ", { res });
      dispatch({
        type: "SET_OPTIONS",
        payload: {
          energy_class_image: res[0]?.success ? [{ label: "Visi", value: "" }, ...res[0].data] : [],
        },
      });
    });
    return { success: true };
  } catch (err) {
    console.log("ERROR ", err);
    return { success: false };
  }
};
