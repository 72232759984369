import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onChangeUseState } from "../../helpers/GlobalFunctions";
import useFetch from "../../helpers/useFetch";

import {
  sendLeasingRequest,
  sendLeasingToRivile,
  setContractIdentifier,
  updateLeasing,
} from "../../redux/Actions/leasingAction";
import { CheckBoxInput, SelectForm } from "../common/Buttons";
import ButtonSecondary from "../common/ButtonSecondary";
import Spinner from "../common/Spinner";
import TextInputModal from "../common/TextInputModal";
import SwitchComp from "../common/SwitchComp";
import AutoFillComponent from "../common/AutoFillComponent";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import {
  antdMessageError,
  antdMessageInfo,
  dateDayLongs,
  returnEuroString,
} from "../../helpers/Utils";
import useLeasingItems from "./useLeasingItems";
import { MdControlPointDuplicate } from "react-icons/md";
import { Tooltip } from "antd";
import { SbLeasingAdditionals } from "./SbLeasingAdditionals";
import { SbLeasingDocumentTypes } from "./SbLeasingDocumentTypes";
import Axios from "axios";
import SbLeasingQrCodeScanner from "./SbLeasingQrCodeScanner";
import { IconBarcode, IconChevronLeft, IconTag } from "../Layout/Icons";
import DocumentAddModal from "../ListsOfDocuments/items/documentAddModal";

function LeasingModal({ leasing_id, setLeasingSelected, onClose }) {
  const dispatch = useDispatch();
  const { options } = useSelector((state) => state.custom);
  const user = useSelector((state) => state.auth.user);
  const [step, setStep] = useState(1);
  const [createDocument, setCreateDocument] = useState(false);
  const [extraLoading, setExtraLoading] = useState(false);
  const [qrCodeSearch, setQrCodeSearch] = useState(false);
  const [createType, setCreateType] = useState(false);
  const { value, loading, setValue, error } = useFetch(
    `/api/v1/sb_leasings/${leasing_id == "create" ? "new" : leasing_id}`,
    {},
    [leasing_id, step, createDocument]
  );
  const [identification_number, set_identification_number] = useState(null);

  const items = useLeasingItems([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (value?.id) {
      items.set(value.items);
      if (identification_number && !value.identification_number) {
        onChangeUseState(
          {
            target: {
              value: identification_number,
              name: "identification_number",
            },
          },
          setValue
        );
      }
    }
  }, [value?.id]);

  useEffect(() => {
    if (value?.state == "signed") {
      setStep(2);
    }
  }, [value?.state]);

  useEffect(() => {
    if (leasing_id == "create") {
      setCreateType(true);
    }
  }, [leasing_id]);

  const disabled = useMemo(() => {
    if (createType) return false;
    if (!value) return true;
    if (value?.contract_number) return true;
    if (["admin"].includes(user.user_role.identifier)) return false;
    if (user.id == value.user_id) return false;

    return true;
  }, [value?.user_id, value?.contract_number, user, createType]);

  const store_permissions = useMemo(() => {
    console.count("store_permissions");
    if (!user) "Nėra priskirtų parduotuvių";
    if (user?.store?.departments?.length > 0) return user.store.departments;
    return "Nėra priskirtų parduotuvių";
  }, [user]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spinner />
      </div>
    );
  }
  if (error) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        Įvyko klaida
      </div>
    );
  }

  const onUpdate = async (type) => {
    try {
      setExtraLoading(true);
      if (items.array.some((d) => (d.identifier != "1234" && !d.rivile_code)))
        return antdMessageError("Pasirinkite padalinį");
      let params = {
        ...value,
        advance: value.advance || 0,
        items_attributes: [
          ...items.array.map((d) => {
            let data = { ...d };
            data.items_attributes = d.items
              .map((d) => {
                let obj = { ...d };
                delete obj.contract_number;
                return obj;
              })
              .filter((s) => {
                if (["PPG", "SGG", "SGD", "EK1"].includes(s.identifier)) {
                  if (!d.serial_number_required) {
                    return false;
                  }
                }
                return true;
              });
            delete data.contract_number;
            delete data.items;
            return data;
          }),
          ...items.destroyed,
        ],
      };

      delete params.full_price;
      delete params.items;
      delete params.user;
      delete params.barcode;

      const { success, errors, data } = await dispatch(updateLeasing(params));
      if (success) {
        if (type == "send_to_rivile") {
          dispatch(sendLeasingToRivile(value.id));
          onClose();
        } else {
          setErrors({});
          setLeasingSelected(data.id);
          setStep(2);
          set_identification_number(params.identification_number);
        }
      } else {
        console.log(errors);
        setErrors(errors);
      }
    } catch (err) {
      // errorHandler(err);
    } finally {
      setExtraLoading(false);
    }
  };

  const show_send_to_rivile = value.api_errors
    ? true
    : value.contract_number &&
    disabled &&
    value?.items?.some((d) => !d.rivile_code);

  const renderButtons = () => {
    return (
      <>
        <div className="mb-3">
          <div className="bg-silver rounded">
            <div className="col-12 py-2 px-4">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h2 className="font-semi-bold text-black text-sm mb-0">
                    Dokumento nr.
                  </h2>
                  <p className="text-black text-sm mb-0">
                    OP. nr: {value.rivile_operation_number || "Nėra"}
                    <br />
                    SF. nr: {value.invoice_number || "Nėra"}
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <RenderSbLeasingBarcode id={value.id} />
                  <div className="d-flex flex-column ml-md-5 ml-2">
                    <h2 className="font-semi-bold text-black text-sm mb-0">
                      Krepšelio suma
                    </h2>
                    <p className="text-black text-sm mb-0">
                      {returnEuroString(
                        items.array.reduce((total, cur) => {
                          let product_total = +cur.amount * (+cur.price + +cur.agata_price);
                          const extra_total = cur.items
                            .filter((d) =>
                              cur.serial_number_required
                                ? true
                                : !["PPG", "SGG", "SGD", "EK1"].includes(
                                  d.identifier
                                )
                            )
                            .reduce((to, cu) => {
                              return +cu.total * +cu.amount + to;
                            }, 0);
                          return product_total + total + extra_total;
                        }, 0)
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-end"
          style={{
            paddingBottom: 60,
          }}
        >
          {show_send_to_rivile && (
            <div
              style={{
                width: 150,
              }}
            >
              <ButtonSecondary
                outline={true}
                onClick={() => onUpdate("send_to_rivile")}
                loading={extraLoading}
              >
                Siųsti į rivilę
              </ButtonSecondary>
            </div>
          )}
          {value?.state !== "signed" && step == 2 && (
            <div
              className="mr-3"
              style={{
                width: 150,
              }}
            >
              <ButtonSecondary
                outline={true}
                onClick={() => setStep(1)}
                loading={extraLoading}
              >
                Grįžti į redagavimą
              </ButtonSecondary>
            </div>
          )}
          {value?.state !== "signed" && !disabled && (
            <div
              style={{
                width: 150,
              }}
            >
              <ButtonSecondary
                onClick={async () => {
                  if (step == 1) {
                    onUpdate();
                  } else {
                    const { data, success } = await dispatch(
                      sendLeasingRequest(value.id, identification_number)
                    );
                    if (success) {
                      antdMessageInfo("Generuojama nuoroda");
                      setTimeout(() => {
                        window.open(data.leasing_url, "_blank");
                      }, 4000);
                      onClose();
                    }
                  }
                }}
                loading={extraLoading}
              >
                {step == 2 ? "Teisingai, tęsti" : "Išsaugoti ir tęsti"}
              </ButtonSecondary>
            </div>
          )}
        </div>
      </>
    );
  };

  if (createDocument) {
    const item = value.items.find((d) =>
      d.items.find((s) => s.id == createDocument)
    );
    const service = item.items.find((d) => d.id == createDocument);
    return (
      <div>
        <div className="mb-4">
          <div className="d-flex justify-content-between font-weight-bold d-block modal-title">
            <div
              className="d-flex align-items-center cursor-pointer"
              onClick={() => {
                setCreateDocument(false);
              }}
            >
              <IconChevronLeft className="mr-2" />
              Dokumento kūrimas
            </div>
            <div className="cursor-pointer d-flex align-items-center justify-content-center">
              <AiOutlineClose
                className="pl-2"
                size={25}
                color={"#BFBFBF"}
                onClick={() => setCreateDocument(false)}
              />
            </div>
          </div>
        </div>

        <DocumentAddModal
          from_leasings={true}
          documentPlan={options.documentPlan}
          document_types={options.documentType.map((d) => ({
            ...d,
            plans: options.documentPlan.filter(
              (s) => s.document_type_id == d.value
            ),
          }))}
          onEdit={async (_, id, params) => {
            try {
              await setContractIdentifier(service.id, id, params);
              setCreateDocument(false);
            } catch (e) {
              console.log({ e });
            }
          }}
          data={{
            customer: `${value.name} ${value.last_name}`,
            email: value.email,
            phone: value.phone?.trim() || "",
            document_type_id: options.documentPlan.find(
              (s) => s.value == service.document_plan_id
            )?.document_type_id,
            document_plan_id: service.document_plan_id,
            price: service.price,
            charge: options.documentPlan.find(
              (s) => s.value == service.document_plan_id
            )?.percentage,
            buy_date: value.created_at.substring(0, 10),
            invoice_number: value.invoice_number || "",
          }}
        />
      </div>
    );
  }

  if (step == 2) {
    const showCreateDocument = () => {
      // cia
      if (!value.contract_number) return true;
      if (!value.signed_at) return false;
      if (dateDayLongs(value.signed_at) >= -30) return true;
      return false;
    };

    return (
      <div className="pb-5">
        <div className="font-semi-bold text-black text-xl mb-3">
          {value?.state == "signed" ? "Peržiura" : "Patvirtinkite duomenis"}
        </div>
        <div className="bg-silver rounded">
          <table className="table table-sm table-hover mt-3">
            <thead>
              <tr className="text-muted">
                <th className="text-black pl-3 pt-2">Vardas, pavardė</th>
                {value?.state !== "signed" && (
                  <th className="text-black pt-2">Asmens kodas</th>
                )}
                <th className="text-black pt-2">Tel. nr.</th>
                <th className="text-black pt-2">El. paštas</th>
                <th className="text-black pt-2">Avansas</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="pb-2 pl-3">
                  {value.name} {value.last_name}
                </td>
                {value?.state !== "signed" && (
                  <td className="pb-2">{identification_number}</td>
                )}
                <td className="pb-2">{value.phone}</td>
                <td className="pb-2">{value.email}</td>
                <td className="pb-2">{returnEuroString(value.advance)}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="text-black text-sm font-semi-bold">Prekės</div>

        {value.items.map((d) => {
          const plans_filtered = d.items.filter((d) =>
            ["PPG", "SGG", "SGD", "EK1"].includes(d.identifier)
          );
          const other_filtered = d.items.filter((d) =>
            ["Užnešimas", "Sandėliavimas", "Pristatymas"].includes(d.identifier)
          );

          return (
            <div
              key={`${d.identifier}_${d.id || Math.random()}`}
              className="rounded mb-2 p-3"
              style={{
                backgroundColor: "#FFF6E8",
              }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <div>{d.identifier}</div>
                  <div className="font-semi-bold">
                    {d.title} {d.amount}*
                  </div>
                  <div>{d.serial_number && `S/N: ${d.serial_number}`}</div>
                </div>
                <div>{returnEuroString(+d.amount * (+d.price + +d.agata_price))}</div>
              </div>
              {!!(plans_filtered.length > 0) && (
                <>
                  <hr
                    className="my-2"
                    style={{
                      backgroundColor: "#FCE2C3",
                    }}
                  />
                  {plans_filtered.map((e) => {
                    const value_option = options.documentPlan?.find(
                      (d) => d.value == e.document_plan_id
                    );
                    return (
                      <div
                        key={d.identifier}
                        className="d-flex align-items-center justify-content-between"
                      >
                        <div>
                          <AiOutlineCheck
                            className="text-xl mr-1"
                            color="#F7931E"
                          />
                          {e.identifier} (
                          {value_option?.label || e.document_plan_id})
                          {e.identifier == "PPG" && (
                            <>
                              {e.contract_number ? (
                                <div
                                  className="ml-2 px-2 py-1 badge badge-pill cursor-pointer text-xs2 font-normal text-black"
                                  style={{
                                    backgroundColor: "#FCE2C3",
                                  }}
                                  onClick={() => {
                                    window.open(
                                      `/documents/${e.contract_id}`,
                                      "_blank"
                                    );
                                    // setCreateDocument(e.id)
                                  }}
                                >
                                  {e.contract_number}
                                </div>
                              ) : showCreateDocument() ? (
                                <span>
                                  <Tooltip placement="top" title="Pildyti">
                                    <span
                                      className="cursor-pointer"
                                      style={{
                                        color: "#F7931E",
                                      }}
                                      onClick={() => setCreateDocument(e.id)}
                                    >
                                      <IconTag className="mx-2" />
                                    </span>
                                  </Tooltip>
                                </span>
                              ) : null}
                            </>
                          )}
                        </div>
                        <div>{returnEuroString(e.total)}</div>
                      </div>
                    );
                  })}
                </>
              )}
              {!!(other_filtered.length > 0) || d.old_collect ? (
                <>
                  <hr
                    className="my-2"
                    style={{
                      backgroundColor: "#FCE2C3",
                    }}
                  />
                  {other_filtered.map((e) => (
                    <div
                      key={d.identifier}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <div>
                        <AiOutlineCheck
                          className="text-xl mr-1"
                          color="#F7931E"
                        />
                        {e.identifier}
                      </div>
                      <div>{returnEuroString(e.price)}</div>
                    </div>
                  ))}
                  {d.old_collect && (
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <AiOutlineCheck
                          className="text-xl mr-1"
                          color="#F7931E"
                        />
                        Senos išvežimas
                      </div>
                    </div>
                  )}
                </>
              ) : null}
            </div>
          );
        })}
        {renderButtons()}
      </div>
    );
  }

  const renderSearchEndPoint = (value) => {
    return `/api/v1/products/simple_products.json?&[inspection_rivile]=1&q[title_or_barcode_or_identifier_i_cont_any]=${value}`;
  };

  const ArrayBuilder = (arr) => {
    const responseBuild = arr.map((e) => ({
      label: e.title,
      value: e.identifier,
      manufacturer: e.manufacturer || "",
      family: e.family_name || "",
      product_price: e.product_price || 0,
    }));
    responseBuild.unshift({
      label: "Rankinė prekė",
      value: "1234",
      manufacturer: "",
      family: "",
      product_price: 0,
    });
    return responseBuild;
  };

  return (
    <div className="pb-5">
      <div className="row">
        <div className="col-12 d-flex align-items-center justify-content-between">
          <div className="font-semi-bold text-black text-xl mb-3">
            {value?.id ? disabled ? "Peržiūra" : "Redagavimas" : "Naujas lizingo krepšelis"}
          </div>
          <div>
            <AiOutlineClose
              className="pl-2 cursor-pointer"
              size={25}
              color={"#BFBFBF"}
              onClick={() => onClose()}
            />
          </div>
        </div>
        <div className="col-6">
          <TextInputModal
            disabled={disabled}
            text="Vardas"
            name="name"
            required={true}
            value={value?.name}
            onChange={(e) => onChangeUseState(e, setValue)}
            warning={errors.name}
          />
        </div>
        <div className="col-6">
          <TextInputModal
            disabled={disabled}
            text="Pavardė"
            name="last_name"
            required={true}
            value={value?.last_name}
            onChange={(e) => onChangeUseState(e, setValue)}
            warning={errors.last_name}
          />
        </div>

        <div className="col-6">
          <TextInputModal
            disabled={disabled}
            maxLength={11}
            text="Asmens kodas"
            name="identification_number"
            required={true}
            value={value?.identification_number}
            onChange={(e) => onChangeUseState(e, setValue)}
            warning={errors.identification_number}
          />
        </div>
        <div className="col-6">
          <TextInputModal
            disabled={disabled}
            text="Avansas"
            name="advance"
            value={value?.advance}
            onChange={(e) => onChangeUseState(e, setValue)}
            warning={errors.advance}
          />
        </div>

        <div className="col-6">
          <TextInputModal
            disabled={disabled}
            text="Tel. nr."
            name="phone"
            required={true}
            value={value?.phone}
            onChange={(e) => onChangeUseState(e, setValue)}
            warning={errors.phone}
          />
        </div>

        <div className="col-6">
          <TextInputModal
            type="email"
            disabled={disabled}
            text="El. paštas"
            name="email"
            required={true}
            value={value?.email}
            onChange={(e) => onChangeUseState(e, setValue)}
            warning={errors.email}
          />
        </div>

        {!disabled && Array.isArray(store_permissions) && (
          <>
            <div className="col-8 pr-0">
              <AutoFillComponent
                mode="custom"
                label={"Prekės"}
                placeholder="Prekės pavadinimas arba prekės kodas"
                endPoint={renderSearchEndPoint}
                onChange={async (_, full) => {
                  // if (full.value == "1234")
                  //   return items.push({
                  //     title: full.label,
                  //     identifier: full.value,
                  //     amount: 1,
                  //     price: full.product_price,
                  //     total: full.product_price,
                  //     rivile_code: "",
                  //     items: [],
                  //     old_collect: false,
                  //   });
                  const { data } = await Axios.get(
                    `/api/v1/products/has_product_quantities?code=${full.value}`
                  );
                  if (!data.success)
                    return antdMessageError("Padaliniai neturi produkto");

                  const agata_data = await Axios.get(
                    `/api/v1/products/has_product_agata?code=${full.value}`
                  );
                  items.push({
                    title: full.label,
                    identifier: full.value,
                    amount: 1,
                    price: full.product_price,
                    total: full.product_price,
                    rivile_code: "",
                    agata_price: agata_data.data.price || null,
                    items: [],
                    old_collect: false,
                  });
                }}
                arrayBuilder={ArrayBuilder}
              />
            </div>
            <div className="col-4 d-flex flex-column pl-0">
              <label className={"text-bold my-1 invisible"}>ARBA</label>
              <div className="d-flex align-items-center">
                <span
                  className="text-xs mx-2"
                  style={{
                    color: "#C2C2C2",
                  }}
                >
                  ARBA
                </span>
                <ButtonSecondary
                  outline={true}
                  outline_2={true}
                  onClick={() => setQrCodeSearch(true)}
                >
                  <IconBarcode />
                  <span className="ml-1">Skenuoti prekę</span>
                </ButtonSecondary>
              </div>
              {qrCodeSearch && (
                <SbLeasingQrCodeScanner
                  onClose={() => setQrCodeSearch(false)}
                  onSave={(res) => {
                    if (res.title && res.identifier) {
                      items.push({
                        title: res.title,
                        identifier: res.identifier,
                        amount: 1,
                        price: res.product_price || 0,
                        total: res.product_price || 0,
                        rivile_code: "",
                        agata_price: null,
                        items: [],
                        old_collect: false,
                      });
                    }
                  }}
                />
              )}
            </div>
          </>
        )}
        {!Array.isArray(store_permissions) && (
          <div className="col-12 mt-3 text-danger">
            {store_permissions || ""}
          </div>
        )}
        <div className="col-12 my-4">
          {items.array.map((d, idx) => (
            <ProductExtra
              key={`${idx}_${d.identifier}`}
              disabled={disabled}
              idx={idx}
              {...d}
              state_items={items}
              store_permissions={store_permissions}
            />
          ))}
        </div>
      </div>
      {renderButtons()}
    </div>
  );
}

export default LeasingModal;

function ProductExtra({
  idx,
  disabled,
  items,
  state_items,
  identifier,
  title,
  serial_number,
  serial_number_required,
  amount,
  price,
  old_collect,
  rivile_code = null,
  agata_price = null,
  store_permissions,
}) {
  const [oldRivileCode] = useState(rivile_code);
  const [storesArr, setStores] = useState([]);
  const { stores } = useSelector((state) => state.custom.options);
  useEffect(() => {
    if (!stores) return;
    if (!Array.isArray(store_permissions)) return;
    const fetcher = async () => {
      console.count("usememo optionsstores");
      const code = identifier;
      const { data } = await Axios.get(
        `/api/v1/products/has_product_quantities?code=${code}`
      );
      let r_stores = stores
        .filter((d) => store_permissions.includes(d.value))
        .filter((d) => data.data[d.value] && data.data[d.value].qty)
        .map((d) => {
          const qty = data.data[d.value] ? data.data[d.value].qty : 0;
          return {
            ...d,
            amount: qty,
            label: `${d.label} (${qty})`
          }
        }
        )
      setStores(r_stores);
    };
    fetcher();
  }, [stores]);

  return (
    <div
      className="rounded"
      style={{
        backgroundColor: "#FFF6E8",
      }}
    >
      <div className="col-12">
        <table className="table">
          <tbody>
            <tr>
              <td
                className="align-middle pl-0"
                style={{
                  width: disabled ? "35%" : "30%",
                }}
              >
                {identifier == "1234" ? (
                  <TextInputModal
                    text={identifier}
                    value={title}
                    onChange={(e) => {
                      state_items.update(idx, { title: e.target.value });
                    }}
                    warning={!title}
                  />
                ) : (
                  <>
                    <div className="text-xs">{identifier}</div>
                    <div className="font-semi-bold text-sm">{title}</div>
                  </>
                )}
                <div className="mt-1">
                  <SwitchComp
                    disabled={disabled}
                    checked={serial_number_required}
                    checkedChildren="S/N"
                    unCheckedChildren="S/N"
                    onChange={() => {
                      state_items.update(idx, {
                        serial_number_required: !serial_number_required,
                      });
                      if (serial_number_required) {
                        state_items.update(idx, { serial_number: "" });
                      }
                    }}
                  />
                </div>
              </td>
              <td
                className="align-middle pl-0"
                style={{
                  width: "15%",
                }}
              >
                <TextInputModal
                  disabled={true}
                  text="Kiekis"
                  value={amount}
                  // onChange={(e) => {
                  //   state_items.update(idx, { amount: e.target.value });
                  // }}
                  warning={!amount}
                />
              </td>
              <td
                className="align-middle pl-0"
                style={{
                  width: "15%",
                }}
              >
                <TextInputModal
                  required={true}
                  disabled={disabled}
                  text="Kaina"
                  style={{
                    minWidth: "80px",
                  }}
                  value={price}
                  onChange={(e) => {
                    const price_product = e.target.value;
                    state_items.update(idx, {
                      price: price_product,
                      total: price_product,
                    });
                  }}
                  warning={!price}
                />
              </td>

              {agata_price &&
                <td
                  className="align-middle pl-0"
                  style={{
                    width: "15%",
                  }}
                >
                  <TextInputModal
                    disabled={true}
                    text="AGATA"
                    value={agata_price}
                  />
                </td>
              }

              <td
                className={`align-middle ${disabled ? "pl-0" : "px-0"}`}
                style={{
                  width: disabled ? "35%" : "30%",
                }}
              >
                <SelectForm
                  required={identifier != '1234'}
                  disabled={oldRivileCode ? disabled : false}
                  label="Padalinys"
                  value={rivile_code || undefined}
                  options={storesArr || []}
                  onChange={(val) => {
                    state_items.update(idx, { rivile_code: val });
                  }}
                  dropdownMatchSelectWidth={false}
                  warning={!rivile_code && identifier != '1234'}
                />
              </td>

              {!disabled && (
                <td
                  className="align-middle pl-0"
                  style={{
                    width: "10%",
                  }}
                >
                  <div className="d-flex flex-column align-items-end">
                    <div className={`text-bold invisible`}>Pašalinti</div>
                    <Tooltip placement="top" title="Pašalinti">
                      <AiOutlineClose
                        className="cursor-pointer text-xl"
                        color={"#FE9C00"}
                        onClick={() => {
                          if (!confirm("Ar tikrai norite pašalinti?")) return;
                          state_items.remove(idx);
                        }}
                      />
                    </Tooltip>
                  </div>
                </td>
              )}
            </tr>
          </tbody>
        </table>
        {serial_number_required && (
          <>
            <hr
              style={{
                backgroundColor: "#FCE2C3",
              }}
            />
            <TextInputModal
              placeholder="Serijinis nr."
              disabled={disabled}
              value={serial_number}
              onChange={(e) => {
                state_items.update(idx, { serial_number: e.target.value });
              }}
            />
            <table className="table">
              <tbody>
                {["PPG", "SGG", "SGD", "EK1"].map((identifier) => (
                  <SbLeasingDocumentTypes
                    key={identifier}
                    disabled={disabled || !serial_number_required}
                    identifier={identifier}
                    item={state_items.filter(items, identifier)}
                    update_children={(val) =>
                      state_items.update_children(idx, identifier, val)
                    }
                    remove_children={() =>
                      state_items.remove_children(idx, identifier)
                    }
                    price={price || 0}
                  />
                ))}
              </tbody>
            </table>
          </>
        )}
        <hr
          style={{
            backgroundColor: "#FCE2C3",
          }}
        />

        <table className="table">
          <tbody>
            {["Užnešimas", "Sandėliavimas", "Pristatymas"].map((identifier) => (
              <SbLeasingAdditionals
                key={identifier}
                disabled={disabled}
                identifier={identifier}
                item={state_items.filter(items, identifier)}
                update_children={(val) =>
                  state_items.update_children(idx, identifier, val)
                }
                remove_children={() =>
                  state_items.remove_children(idx, identifier)
                }
              />
            ))}
            <tr>
              <td className="pl-0">
                <nobr>
                  <CheckBoxInput
                    disabled={disabled}
                    checked={old_collect}
                    text={"Senos išvežimas"}
                    onChange={() => {
                      state_items.update(idx, { old_collect: !old_collect });
                    }}
                  />
                </nobr>
              </td>
              <td></td>
              <td className="float-right">
                {!disabled && (
                  <Tooltip placement="top" title="Dublikuoti">
                    <MdControlPointDuplicate
                      className="cursor-pointer text-xl"
                      color="orange"
                      onClick={() => state_items.duplicate(idx)}
                    />
                  </Tooltip>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

const RenderSbLeasingBarcode = ({ id }) => {
  const [qrCode, setQrCode] = useState("");

  useEffect(() => {
    if (!id) return;
    const fetchQrCode = async () => {
      const { data } = await Axios.get(
        `/api/v1/sb_leasings/${id}/render_barcode`
      );
      if (data.qrcode) {
        setQrCode(data.qrcode);
      }
    };
    fetchQrCode();
  }, [id]);
  if (!id || !qrCode) return <div></div>;
  return (
    <div>
      {" "}
      <img src={qrCode} />
    </div>
  );
};
