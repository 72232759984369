import Axios from "axios";
import {
  GET_SMSITEM,
  GET_SMSITEMS,
  POST_SMSITEM,
  DELETE_SMSITEM,
  UPDATE_SMSITEM,
  LOADING_SMS,
  CLEAR_ERRORS,
  GET_ERRORS,
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

const success = () => {
  if (!document.getElementById("smsAlert-s")) return;
  document.getElementById("smsAlert-s").style.display = "block";
  setTimeout(
    () => (document.getElementById("smsAlert-s").style.display = "none"),
    5000
  );
  document.getElementById("modalClose").click();
};
const error = () => {
  if (!document.getElementById("smsAlert-e")) return;
  document.getElementById("smsAlert-e").style.display = "block";
  setTimeout(
    () => (document.getElementById("smsAlert-e").style.display = "none"),
    5000
  );
};

export const postSmsItem = (smsData) => (dispatch) => {
  Axios.post("/api/v1/sms_templates", smsData)
    .then((res) => {
      dispatch({
        type: POST_SMSITEM,
        payload: res.data,
      });
      dispatch(clearErrors());
      success("post");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const getSmsItems = (page, pageCount) => (dispatch) => {
  dispatch(setSmsLoading());
  Axios.get(`/api/v1/sms_templates?page=${page}&per_page=${pageCount}`)
    .then((res) => {
      dispatch({
        type: GET_SMSITEMS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getSmsItemsOption = () => {
  return Axios.get(`/api/v1/sms_templates`).then((res) => {
    return res;
  });
};

export const deleteSmsItem = (_id) => (dispatch) => {
  if (confirm("Ar tikrai norite ištrinti ? ")) {
    Axios.delete(`/api/v1/sms_templates/${_id}`)
      .then((res) => {
        dispatch({
          type: DELETE_SMSITEM,
          payload: _id,
        });
        success();
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        error();
      });
  }
};

export const updateSmsItem = (_id, body, indexOfArray) => (dispatch) => {
  Axios.put(`/api/v1/sms_templates/${_id}`, body)
    .then((res) => {
      dispatch(clearErrors());
      dispatch({
        type: UPDATE_SMSITEM,
        payload: res.data,
        payload_id: _id,
      });
      success("update");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const setSmsLoading = () => {
  return {
    type: LOADING_SMS,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
