import Axios from "axios";
import {
  GET_PICKUPADDRESSES,
  POST_PICKUPADDRESS,
  DELETE_PICKUPADDRESS,
  UPDATE_PICKUPADDRESS,
  LOADING_PICKUPADDRESS,
  GET_COUNTRIESOPTION,
  CLEAR_ERRORS,
  GET_ERRORS,
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

const success = () => {
  if (!document.getElementById("pickupAlert-s")) return;
  document.getElementById("pickupAlert-s").style.display = "block";
  setTimeout(
    () => (document.getElementById("pickupAlert-s").style.display = "none"),
    5000
  );
  document.getElementById("modalClose").click();
};
const error = () => {
  if (!document.getElementById("pickupAlert-e")) return;
  document.getElementById("pickupAlert-e").style.display = "block";
  setTimeout(
    () => (document.getElementById("pickupAlert-e").style.display = "none"),
    5000
  );
};

export const postPickupAddress = (PickupAddressData) => (dispatch) => {
  Axios.post("/api/v1/pickup_addresses", PickupAddressData)
    .then((res) => {
      dispatch({
        type: POST_PICKUPADDRESS,
        payload: res.data,
      });
      dispatch(clearErrors());
      success("post");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const getPickupAddresses = (page, pageCount) => (dispatch) => {
  dispatch(setPickupAddressLoading());
  Axios.get(`/api/v1/pickup_addresses?page=${page}&per_page=${pageCount}`)
    .then((res) => {
      dispatch({
        type: GET_PICKUPADDRESSES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getPickupAddressesOption = () => {
  return Axios.get(`/api/v1/pickup_addresses`).then((res) => {
    return { data: { data: res.data.data.filter((e) => e.active) } };
  });
};

export const deletePickupAddress = (_id) => (dispatch) => {
  if (confirm("Ar tikrai norite ištrinti ? ")) {
    Axios.delete(`/api/v1/pickup_addresses/${_id}`)
      .then((res) => {
        dispatch({
          type: DELETE_PICKUPADDRESS,
          payload: _id,
        });
        success();
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        error();
      });
  }
};

export const updatePickupAddress = (_id, body, indexOfArray) => (dispatch) => {
  Axios.put(`/api/v1/pickup_addresses/${_id}`, body)
    .then((res) => {
      dispatch(clearErrors());
      dispatch({
        type: UPDATE_PICKUPADDRESS,
        payload: res.data,
        payload_id: _id,
      });
      success("update");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const setPickupAddressLoading = () => {
  return {
    type: LOADING_PICKUPADDRESS,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
