import React, { useState } from "react";
import { Alert } from "antd";
import { leasingRequest } from "../../../../redux/Actions/orderAction";
import { useDispatch } from "react-redux";
import { FlopppyButton } from "../../ButtonWithLoading";
import TextInputModal from "../../TextInputModal";
import { CheckBoxInput } from "../../Buttons";

function LeasingComponent({
  p_discount_5090,
  p_leasing_number,
  p_leasing_discount,
  p_leasing_advance,
  orderId,
  disabled = false,
}) {
  const dispatch = useDispatch();
  const [variables, setVariables] = useState({
    discount_5090: p_discount_5090,
    leasing_number: p_leasing_number,
    leasing_discount: p_leasing_discount,
    leasing_advance: p_leasing_advance,
  });

  const { discount_5090, leasing_number, leasing_discount, leasing_advance } =
    variables;

  const [alertText, setAlertText] = useState({
    success: "success",
    text: "",
    show: false,
  });
  const [loading, setLoading] = useState(false);

  const onSave = () => {
    setLoading(true);
    dispatch(leasingRequest(orderId, variables)).then((res) => {
      if (res.success) {
        setLoading(false);
        setAlertText({
          success: "success",
          text: "Sėkmingai atnaujinta.",
          show: true,
        });
      } else {
        setLoading(false);
        setAlertText({
          success: "error",
          text: "Įvyko klaida atnaujinant.",
          show: true,
        });
      }
    });
  };

  const onChangeHandler = (e) => {
    setVariables({
      ...variables,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="col-12 py-4">
      {alertText.show && (
        <Alert message={alertText.text} type={alertText.success} showIcon />
      )}
      <div className="row">
        <div className="col-6">
          <TextInputModal
            disabled={disabled}
            text="Lizingo sutarties numeris"
            placeholder="Lizingo sutarties numeris"
            name="leasing_number"
            value={leasing_number}
            onChange={onChangeHandler}
          />
          <TextInputModal
            disabled={disabled}
            type="number"
            text="Lizingo nuolaida, EUR"
            placeholder="Lizingo nuolaida, EUR"
            name="leasing_discount"
            value={leasing_discount}
            onChange={onChangeHandler}
          />
        </div>
        <div className="col-4">
          <TextInputModal
            disabled={disabled}
            type="number"
            text="Avansas"
            placeholder="Avansas"
            name="leasing_advance"
            value={leasing_advance}
            onChange={onChangeHandler}
          />
          <div className="text-bold my-1" style={{ visibility: "hidden" }}>
            Nuolaida 5090
          </div>
          <div className="mt-2">
            <CheckBoxInput
              disabled={disabled}
              choised="discount_5090"
              name="discount_5090"
              color="#f7951e"
              text="Nuolaida 5090"
              checked={discount_5090}
              onChange={(e) =>
                onChangeHandler({
                  target: {
                    name: "discount_5090",
                    value: e.target.checked,
                  },
                })
              }
            />
          </div>
        </div>

        <div className="col-2 d-flex align-items-center justify-content-end">
          <div>
            {!disabled && (
              <FlopppyButton loading={loading} onClick={() => onSave()} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeasingComponent;
