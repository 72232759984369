import { message } from "antd";
import Axios from "axios";
import {
  antdMessageError,
  antdMessageSuccess,
  errorHandler,
} from "../../helpers/Utils";
import {
  GET_PRODUCTS,
  CLEAR_ERRORS,
  GET_ERRORS,
  LOADING_PRODUCTS,
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

export const getProducts = (page, pageCount, filter) => (dispatch) => {
  dispatch(setProductsLoading());
  Axios.get(`/api/v1/products?page=${page}&per_page=${pageCount}${filter}`)
    .then((res) => {
      dispatch({
        type: GET_PRODUCTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const add_substitutions_for_product =
  (id, value) => async (dispatch) => {
    try {
      const { data } = await Axios.post(`/api/v1/products/${id}/add_upsell`, {
        value,
      });
      // if (data.remake) {
      //   console.log("remakinam");
      //   if (
      //     confirm("Produktas iš ankščiau yra pašalintas ar norite sugrąžinti ?")
      //   ) {
      //     const { data } = await Axios.post(
      //       `/api/v1/products/${id}/remake_substitutions`,
      //       {
      //         value,
      //       }
      //     );
      //     message.success(data.message);
      //     dispatch({
      //       type: "UPDATE_UPSELL_PRODUCT",
      //       payload: {
      //         id,
      //         data: data.data,
      //       },
      //     });
      //     return {
      //       success: true,
      //       remake: true,
      //     };
      //   } else {
      //     if (!data.success) throw new Error(data.message);
      //   }
      // }
      if (!data.success) throw new Error(data.message);
      dispatch({
        type: "ADD_UPSELL_PRODUCT",
        payload: {
          id,
          value,
        },
      });
      message.success(data.message);
      return {
        success: true,
        data: data.data,
      };
    } catch (err) {
      errorHandler(err);
      message.error(err.message);
      return {
        success: false,
      };
    }
  };

export const remake_substitutions_for_product =
  (id, value) => async (dispatch) => {
    try {
      const { data } = await Axios.post(`/api/v1/products/${id}/add_upsell`, {
        value,
      });
      console.log({ data });
      if (data.remake) {
        if (
          confirm("Produktas iš ankščiau yra pašalintas ar norite sugrąžinti ?")
        ) {
        }
      }
      if (!data.success) throw new Error(data.message);
      dispatch({
        type: "ADD_UPSELL_PRODUCT",
        payload: {
          id,
          value,
        },
      });
      message.success(data.message);
      return {
        success: true,
        data: data.data,
      };
    } catch (err) {
      errorHandler(err);
      message.error(err.message);
      return {
        success: false,
      };
    }
  };

export const remove_substitutions_for_product =
  (id, value, product_identifier) => async (dispatch) => {
    try {
      if (!confirm("Ar tikrai norite ištrinti ?")) throw new Error("Atšaukta");
      const { data } = await Axios.post(
        `/api/v1/products/${id}/remove_upsell`,
        {
          value,
        }
      );
      console.log({ data });
      if (!data.success) throw new Error(data.message);
      dispatch({
        type: "REMOVE_UPSELL_PRODUCT",
        payload: {
          id,
          value: product_identifier,
        },
      });
      message.success(data.message);
      return {
        success: true,
        data: data.data,
      };
    } catch (err) {
      errorHandler(err);
      message.error(err.message);
      return {
        success: false,
      };
    }
  };

export const remove_checked_substitutions =
  (ids, type = "selected", params = "") =>
  async (dispatch) => {
    try {
      if (type == "selected") {
        if (ids.length == 0) return message.error("Nepasirinktos focus prekės");
      }
      if (!confirm("Ar tikrai norite ištrinti ?")) return;
      const { data } = await Axios.post(
        `/api/v1/products/remove_checked_upsells?${params}`,
        {
          ids,
          type,
        }
      );
      if (!data.success) throw new Error(data.message);
      dispatch(setProductsLoading());
      if (type == "selected") {
        dispatch({
          type: "CHECKED_REMOVE_UPSELL_PRODUCT",
          payload: {
            ids,
          },
        });
      } else {
        dispatch(getProducts(1, 100, params));
      }
      message.success(data.message);
      return {
        success: true,
      };
    } catch (err) {
      errorHandler(err);
      message.error(err.message);
      return {
        success: false,
      };
    }
  };

export const syncProductAkemeo = () => async (dispatch) => {
  try {
    message.info("Produktai: SYNC vykdoma");
    const { data } = await Axios.post("/api/v1/products/sync_products");
    console.log({ data });
    if (data.success) {
      message.success("Produktai: SYNC sėkmingai");
    }
    dispatch(getProducts(1, 100, ""));
    return true;
  } catch (err) {
    console.error(err);
    message.error("Produktai: SYNC klaida");
    return false;
  }
};

export const searchProduct = async (value) => {
  try {
    message.info("Ieškomas produktas");
    const { data } = await Axios.get(
      `/api/v1/products?page=1&per_page=9999999&q[title_or_identifier_i_cont_any]=${value}`
    );
    antdMessageSuccess("Sėkmingai surasti produktai");
    return data.data;
  } catch (err) {
    antdMessageError();
    return [];
  }
};

export const setProductsLoading = () => {
  return {
    type: LOADING_PRODUCTS,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
