import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Select } from "antd";
import Axios from "axios";
import TextInputModal from "../../../common/TextInputModal";
import { Button1, SelectForm } from "../../../common/Buttons";
import { ClassicButton } from "../../../common/ButtonWithLoading";
import { FaCheck, FaTimes, FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { HiCursorClick } from "react-icons/hi";

function StatusFilterModal(props) {
  const [favoritedFilters, setFavoritedFilters] = useState([]);

  const [newStatus, setNewStatus] = useState(false);

  useEffect(() => {
    const getStatuses = async () => {
      try {
        const { data } = await Axios.get(`/api/v1/user_status_favorites?assigned=${props.type || "Order"}`);
        if (data) {
          setFavoritedFilters(data);
        }
      } catch (err) {
        console.log("Error: ", err);
      }
    };
    getStatuses();
  }, []);

  const createNewFilter = async (params) => {
    try {
      const { data } = await Axios.post("/api/v1/user_status_favorites", {
        user_status_favorite: {
          ...params,
          assigned: props.type || "Order",
        },
      });
      if (data) {
        setFavoritedFilters([...favoritedFilters, data]);
        setNewStatus(false);
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  };
  const updateFilter = async (params) => {
    try {
      const { data } = await Axios.put(
        `/api/v1/user_status_favorites/${params.id}`,
        {
          user_status_favorite: params,
        }
      );
      if (data) {
        const allFavorites = [...favoritedFilters];
        const findIndex = allFavorites.findIndex((e) => e.id == params.id);
        if (findIndex !== -1) {
          allFavorites[findIndex] = data;
          setFavoritedFilters(allFavorites);
          setNewStatus(false);
          return true;
        } else {
          setNewStatus(false);
        }
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const removeFilter = async (id) => {
    try {
      if (!confirm("Ar tikrai norite ištrinti ?")) return;
      const { data } = await Axios.delete(
        `/api/v1/user_status_favorites/${id}`
      );
      if (data.success) {
        setFavoritedFilters(favoritedFilters.filter((e) => e.id !== id));
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="row">
      <div className="col">
        <div>
          <Divs className="container-title-block">
            <MainBlock>
              <span>Pasirinkimai</span>
            </MainBlock>
            <Select
              mode="multiple"
              placeholder="Filtruoti pagal statusus"
              value={props.selectedItems}
              onChange={props.tableColumnSelector}
              style={{ width: "100%" }}
            >
              {props.options.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Divs>

          <p className="font-weight-bold mb-1 mt-4">Mylimiausi filtrai </p>

          <div className="">
            {favoritedFilters.map((e) => (
              <FavoritedFilter
                key={e.id}
                {...e}
                selectFilter={(val) => props.tableColumnSelector(val)}
                options={props.options}
                boolean_filter={
                  e.filters?.sort().toString() ==
                  props.selectedItems?.sort().toString()
                }
                filters_full={
                  e.filters
                    ? e.filters.map((e) => {
                        const find = props.options.find((fi) => fi.value == e);
                        return find ? find.label : `(${e})`;
                      })
                    : []
                }
                updateFilter={updateFilter}
                removeFilter={removeFilter}
              />
            ))}
          </div>
          {newStatus && (
            <CreateFilter
              options={props.options}
              createNewFilter={createNewFilter}
            />
          )}
          {favoritedFilters.length < 5 && (
            <div className="position-relative mt-5">
              <Button1
                style={{
                  position: "absolute",
                  top: "-16px",
                  left: "50%",
                  transform: "translate(-50%, 0px)",
                }}
                text={newStatus ? "Atšaukti" : "Pridėti naują statusą"}
                option={newStatus ? "minus" : "plus"}
                onClick={() => setNewStatus(!newStatus)}
              />
              <hr />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function CreateFilter({ options, createNewFilter }) {
  const [newFF, setNewFF] = useState({
    title: "",
    filters: [],
  });

  const [errors, setErrors] = useState({});
  const createItem = () => {
    if (newFF.title.trim().length === 0 || newFF.filters.length === 0) {
      let errors = {};
      if (newFF.title.trim().length === 0)
        errors.title = "Įveskite pavadinima.";
      if (newFF.filters.length === 0) errors.filters = "Pasirinkite statusus.";

      return setErrors(errors);
    }

    createNewFilter(newFF);
  };
  return (
    <div>
      <div className="d-flex justify-content-between flex-column mt-3">
        <p className="font-weight-bold mb-1">Naujo statusų filtro kūrimas</p>
        <PretendDiv>
          <TextInputModal
            required="true"
            text="Pavadinimas"
            placeholder="Įvesti"
            name="title"
            value={newFF.title}
            onChange={(e) => setNewFF({ ...newFF, title: e.target.value })}
            warning={errors.title}
          />
        </PretendDiv>
        <PretendDiv>
          <SelectForm
            required="true"
            mode="multiple"
            placeholder="Statusai"
            onlyPlaceholder="Pasirinkti"
            value={newFF.filters}
            onChange={(filters) => setNewFF({ ...newFF, filters })}
            style={{ width: "100%" }}
            options={options}
            error={errors.filters}
          />
        </PretendDiv>
      </div>
      <div className="d-flex justify-content-end mt-2">
        <ClassicButton
          loading={false}
          text={"Sukurti"}
          onClick={() => createItem()}
        />
      </div>
    </div>
  );
}

function FavoritedFilter({
  id,
  title,
  filters,
  selectFilter,
  options,
  updateFilter,
  removeFilter,
  filters_full,
  boolean_filter,
}) {
  const [edit, setEdit] = useState(false);
  const [editFilters, setEditFilters] = useState(filters);
  return (
    <div className="mb-2 pl-2" style={{ borderLeft: "2px solid #f7951e" }}>
      <div className="d-flex justify-content-between mb-2 pb-1 border-bottom border-muted">
        {edit ? (
          <span>{title}</span>
        ) : (
          <div
            className="cursor-pointer expand-hover"
            onClick={() => selectFilter(filters)}
          >
            <HiCursorClick
              size={17}
              className="text-warning mr-2"
              title="Pasirinkti"
            />
            <span className="font-weight-bold">{title}</span>
          </div>
        )}
        {edit ? (
          <span>
            <FaCheck
              size={17}
              className="text-success cursor-pointer"
              onClick={async () => {
                const success = await updateFilter({
                  id,
                  filters: editFilters,
                });
                if (success) {
                  setEdit(false);
                }
              }}
            />
            <FaTimes
              size={17}
              className="text-danger cursor-pointer ml-2"
              onClick={() => {
                setEditFilters(filters);
                setEdit(false);
              }}
            />
          </span>
        ) : (
          <span>
            <MdEdit
              size={17}
              className="cursor-pointer"
              color="green"
              onClick={() => {
                setEditFilters(filters);
                setEdit(true);
              }}
            />

            <FaTrash
              className="cursor-pointer text-danger ml-2"
              size={17}
              onClick={() => removeFilter(id)}
            />
          </span>
        )}
      </div>
      {edit ? (
        <Select
          mode="multiple"
          placeholder="Pasirinkti"
          value={editFilters}
          onChange={(val) => setEditFilters(val)}
          style={{ width: "100%" }}
        >
          {options.map((item) => (
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      ) : boolean_filter ? (
        <div></div>
      ) : (
        filters_full.map((e, idx) => (
          <span key={idx}>
            {e}
            {filters_full.length - 1 == idx ? "" : ", "}
          </span>
        ))
      )}
    </div>
  );
}

const MainBlock = styled.div`
  background-color: #f2f2f2;
  padding: 8px 10px;
  display: block;
  font-weight: 700;
  color: #000000;
  font-size: 10px;
`;

const PretendDiv = styled.div`
  color: #000000;
  /* flex: 1; */
  width: 100%;
  & .text-bold {
    font-weight: 700;
  }
`;

const Divs = styled.div`
  margin: 6px 0 !important;
  display: block;
  color: #000000;
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
  & .text-bold {
    font-weight: 700;
  }
  & .form-input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 0.5px);
    padding: 0.1rem 0.2rem;
    font-size: 0.6rem;
    border: 1px solid silver;
    background-color: transparent;
    color: black;
    border-radius: 0.25rem;
    &:active {
      border: 1px solid #f7951e !important;
      border-radius: 0.25rem !important;
    }
    &:focus {
      border: 1px solid #f7951e !important;
      border-radius: 0.25rem !important;
      & input {
        outline: none !important;
      }
    }
  }
  & .orange_color {
    color: #f7951e;
  }
`;

export default StatusFilterModal;
