import React, { useMemo } from "react";
import Spinner from "../../../common/Spinner";
import ModalSpecial from "../../../Layout/Modals/ModalSpecial";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch } from "react-redux";
import SwitchComp from "../../../common/SwitchComp";
import { DebounceSelect } from "../../../common";
import Axios from "axios";
import DoubleButton from "../GuideBuyersGuides/DoubleButton";
import {
  deleteGuideRecommendations,
  postGuideRecommendations,
} from "../../../../redux/Actions/guidesRecommendationAction";
import useFetch from "../../../../helpers/useFetch";
import AntdConfirm from "../../../../helpers/AntdConfirm";
import { buildErrorObject } from "../../../../helpers/Utils";
import useErrors from "../../../../hooks/useErrors";

const ModalGuideRecommendation = ({ id, onClose }) => {
  const dispatch = useDispatch();
  const { value, setValue, loading } = useFetch(
    `/api/v1/guide_products/${id || "new"}`,
    {},
    []
  );
  const { errors, setErrors } = useErrors();

  const type = id == 0 ? "create" : "update";

  const onChangeState = (e) => {
    const { name, value } = e.target;
    setValue((prevProps) => ({ ...prevProps, [name]: value }));
  };

  const onSave = async () => {
    console.error("REQUEST STARTED 2");
    // console.log(modalType);
    try {
      setErrors({});
      const { success, errors } = await dispatch(
        postGuideRecommendations(value)
      );

      if (success) {
        onClose();
      } else {
        setErrors(buildErrorObject(errors));
        console.log({ errors });
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const disabled = useMemo(() => {
    if (loading) return true;
    if (!value) return true;
    return !value.guide_product_items.some((d) => d.ready);
  }, [loading]);

  return (
    <ModalSpecial
      style={{ maxWidth: 600 }}
      text={
        <span className="text-black text-xl">
          {type == "create" ? "Pridėti kategoriją" : "Redaguoti kategoriją"}
        </span>
      }
      onClose={() => {
        return onClose({});
      }}
    >
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100%" }}
        >
          <Spinner />
        </div>
      ) : (
        <div>
          <DebounceSelect
            label="Kategorija"
            showSearch={true}
            options={
              value?.shop_category_id
                ? [
                    {
                      value: value?.shop_category?.id,
                      label: value?.shop_category?.title,
                    },
                  ]
                : []
            }
            fetchOptions={async (value) => {
              try {
                let api_url = `/api/v1/shop_categories/list?q[title_i_cont_any]=${value}`;

                const { data } = await Axios.get(api_url);
                const valArr = data?.filter((d) => d.title) || [];
                return (
                  valArr.map((e) => {
                    return {
                      ...e,
                      value: e.id,
                      label: e.title,
                    };
                  }) || []
                );
              } catch (err) {
                errorHandler(err);
                return [];
              }
            }}
            style={{
              width: "100%",
            }}
            //
            placeholder="Kategorija"
            value={value.shop_category_id || undefined}
            onChange={(value) => {
              onChangeState({
                target: {
                  name: "shop_category_id",
                  value: value.value,
                },
              });
            }}
            error={errors.shop_category}
          />

          <SwitchComp
            disabled={value.active ? false : disabled}
            placeholder="Statusas"
            checkedChildren="I"
            unCheckedChildren="0"
            checked={value.active}
            inlineText={value.active ? "Aktyvus" : "Neaktyvus"}
            onChange={() => {
              onChangeState({
                target: {
                  name: "active",
                  value: !value.active,
                },
              });
            }}
          />
          {disabled && <p className="text-danger">Nėra priskirta aktyvių prekių</p>}

          <DoubleButton
            onSave={onSave}
            showRemove={type == "update"}
            onBack={() => {
              AntdConfirm({
                onOk: () => {
                  dispatch(deleteGuideRecommendations(value.id)).then((res) => {
                    if (res.success) {
                      onClose();
                    }
                  });
                  console.log("ok11");
                },
              });
            }}
          />
        </div>
      )}
    </ModalSpecial>
  );
};

export default ModalGuideRecommendation;
