import {
  LOADING_RIVILE_ITEMS,
  GET_RIVILE_ITEMS,
  GET_RIVILE_ITEM_TYPES,
  GET_RIVILE_ITEM,
  EDIT_RIVILE_ITEM,
  GET_RIVILE_ITEMS_SALES,
  CHANGE_STATUS,
  POST_COMMENT,
  CHANGE_STATUS_TO_EXPORTED,
  EDIT_RIVILE_INVOICE,
  GET_RIVILE_STORES,
  CHANGE_STATUS_MISMATCHED
} from "../Actions/types";

const initialState = {
  rivileItems: [],
  rivileItem: {rivile_invoiced_items: []},
  rivileItemTypes: [],
  rivileStores: [],
  loading_rivile: false,
  totals: 0
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_RIVILE_ITEMS:
      return {
        ...state,
        loading_rivile: true,
      };
    case GET_RIVILE_ITEMS:
      return {
        ...state,
        rivileItems: action.payload.data,
        totals: action.payload.totals,
        loading_rivile: false,
      };
    case GET_RIVILE_ITEMS_SALES:
      return {
        ...state,
        totals: action.payload.totals,
        rivileItems: action.payload.data,
        loading_rivile: false,
      };
    case GET_RIVILE_ITEM_TYPES:
      return {
        ...state,
        rivileItemTypes: action.payload.data,
        loading_rivile: false,
      };
    case GET_RIVILE_STORES:
      return {
        ...state,
        rivileStores: action.payload.data,
        loading_rivile: false,
      };
    case GET_RIVILE_ITEM:
      return {
        ...state,
        rivileItem: action.payload,
      };
    case EDIT_RIVILE_ITEM:
      return {
        ...state,
        rivileItem: editRivileItem(action.payload, state),
        rivileItems: editRivileItems(action.payload, state),
      };
      case EDIT_RIVILE_INVOICE:
        return {
          ...state,
          rivileItem: action.payload,
        }
    case CHANGE_STATUS_MISMATCHED:
      return {
        ...state,
        rivileItem: editRivileItemAgata(action.payload, state),
        loading_rivile: false
      };
    case CHANGE_STATUS:
      return {
        ...state,
        rivileItems: [...state.rivileItems].map(it => {
          if(action.payload.includes(it.id)) {
            return {
              ...it,
              status: "exporting",
              agata_mismatch: false
            }
          } else {
            return {...it}
          }
        }),
        loading_rivile: false
      };
    case CHANGE_STATUS_TO_EXPORTED:
      return {
        ...state,
        rivileItems: [...state.rivileItems].map(it => {
          if(action.payload.includes(it.id)) {
            hideForceExportButton(it.id)
            return {
              ...it,
              status: "exported"
            }
          } else {
            return {...it}
          }
        }),
        // loading_rivile: false
      }
    case POST_COMMENT:
      return {
        ...state,
        rivileItem: addComment(action.payload, state),
        rivileItems: editRivileComments(action.payload, state),
      };
    default:
      return state;
  }
}


const addComment = (item, state) => {
  let rivileItem = {...state.rivileItem};
      rivileItem.rivile_comments.push(item.data);
  return rivileItem;
};

const editRivileComments = (item, state) => {
  let rivileItem = [...state.rivileItems];
  const findIndex = rivileItem.findIndex((e) => e.id == item.data.rivile_invoice_id);
  if (findIndex !== -1) {
    const findIndexChildren = rivileItem[
      findIndex
    ].rivile_comments.findIndex((find) => find.id == item.data.id);
    if (findIndexChildren == -1) {
      rivileItem[findIndex].rivile_comments.push(item.data);
    }
  }
  return rivileItem;
};

const editRivileItems = (item, state) => {
  let rivileItem = [...state.rivileItems];
  const findIndex = rivileItem.findIndex((e) => e.id == item.rivile_invoice_id);
  if (findIndex !== -1) {
    const findIndexChildren = rivileItem[
      findIndex
    ].rivile_invoiced_items.findIndex((find) => find.id == item.id);
    if (findIndexChildren !== -1) {
      rivileItem[findIndex].rivile_invoiced_items[findIndexChildren] = item;
    }
  }
  return rivileItem;
};

const editRivileItem = (item, state) => {
  let rivileItem = { ...state.rivileItem };
  const findIndexChildren = rivileItem.rivile_invoiced_items.findIndex(
    (find) => find.id == item.id
  );
  if (findIndexChildren !== -1) {
    rivileItem.rivile_invoiced_items[findIndexChildren] = item;
  }
  return rivileItem;
};

const editRivileItemAgata = (code, state) => {
  let rivileItem = { ...state.rivileItem };
  const findIndexChildren = rivileItem.rivile_invoiced_items.findIndex(
    (find) => find.code == code
  );
  if (findIndexChildren !== -1) {
    rivileItem.rivile_invoiced_items[findIndexChildren].agata_mismatch = true;
  }
  return rivileItem;
};

const hideForceExportButton = (id) => {
  document.querySelector(".force-export-" + id).style.display = "none"
}
