import Axios from "axios";
import {
  GET_RIVILE_SERVICE,
  LOADING_RIVILE_SERVICE,
  UPDATE_RIVILE_SERVICE,
  CLEAR_ERRORS,
  DELETE_RIVILE_SERVICE,
  POST_RIVILE_SERVICE,
  GET_ERRORS,
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

const success = () => {
  if (!document.getElementById("rivileAlert-s")) return;
  document.getElementById("rivileAlert-s").style.display = "block";
  setTimeout(
    () => (document.getElementById("rivileAlert-s").style.display = "none"),
    5000
  );
  document.getElementById("modalClose").click();
};

export const postRivileService = (serviceData) => (dispatch) => {
  return Axios.post("/api/v1/rivile_services", serviceData)
    .then((res) => {
      dispatch({
        type: POST_RIVILE_SERVICE,
        payload: res.data,
      });
      dispatch(clearErrors());
      success("post");
      return { success: true };
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      return { success: false };
    });
};

export const getRivileServices = (page, pageCount) => (dispatch) => {
  dispatch(setRivileServiceLoading());
  Axios.get(`/api/v1/rivile_services?page=${page}&per_page=${pageCount}`)
    .then((res) => {
      dispatch({
        type: GET_RIVILE_SERVICE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getRivileServicesOption = () => {
  return Axios.get(`/api/v1/rivile_services?page=1&per_page=9999`)
    .then((res) => {
      return (
        res.data?.data
          .filter((d) => d.rivile_code)
          .map((d) => ({
            value: d.id,
            label: `${d.rivile_code || ""} - ${d.title}`,
          })) || []
      );
    })
    .catch((err) => {
      return [];
    });
};

export const updateRivileServices = (_id, body) => (dispatch) => {
  return Axios.put(`/api/v1/rivile_services/${_id}`, body)
    .then((res) => {
      dispatch({
        type: UPDATE_RIVILE_SERVICE,
        payload: res.data,
      });
      success();
      return { success: true };
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const deleteRivileService = (_id) => (dispatch) => {
  if (confirm("Ar tikrai norite ištrinti ? ")) {
    Axios.delete(`/api/v1/rivile_services/${_id}`)
      .then((res) => {
        dispatch({
          type: DELETE_RIVILE_SERVICE,
          payload: _id,
        });
        success();
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      });
  }
};

export const setRivileServiceLoading = () => {
  return {
    type: LOADING_RIVILE_SERVICE,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
