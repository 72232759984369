import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const PrivateRoute = ({ component: Component, allowed, auth, ...rest }) => {
  const redirect = (role) => {
    if (role == "admin" || role == "manager") {
      return <Redirect to="/orders" />;
    } else if (role == "store_manager" || role == "store_user") {
      return <Redirect to="/documents" />;
    } else if (role == "big_box_user") {
      return <Redirect to="/rivile-pardavimai" />;
    }
  };
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isAuthenticated === true ? (
          true ? (
            <Component {...props} />
          ) : (
            redirect(auth.user.user_role.identifier)
            // <Redirect to="/sign_in" />
          )
        ) : (
          <Redirect to="/sign_in" />
        )
      }
    />
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
