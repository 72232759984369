import React from "react";
import Container from "../Layout/Container";
import { connect } from "react-redux";
import {
  getCommentState,
  postCommentState,
  deleteCommentState,
  updateCommentState,
  clearErrors
} from "../../redux/Actions/comment_StateAction";
import CommentsStateEdit from "./Items/CommentsStateEdit";
import ContentBlock from "../Layout/ContentBlock";
import TableColumnCommentsState from "./Items/TableColumnCommentsState";
import FilterMenu from "../Layout/FilterMenu";
import TableFilterBox from "../common/TableFilterBox";
import ContentValidation from "../common/ContentValidation";
import PaginationComp from "../Dashboard/items/Pagination";

class CommentState extends React.Component {
  constructor() {
    super();
    this.state = {
      requiredId: 0,
      page: 1,
      pageCount: 100,
    };
  }
  componentDidMount() {
    this.props.getCommentState(this.state.page, this.state.pageCount);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.props.getCommentState(this.state.page, this.state.pageCount);
    }
  }

  onAction = (action, index) => {
    this.setState({
      requiredId: index,
      action: action,
    });
  };

  setPagination = (page) => {
    this.setState({
      page,
    });
  };

  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  render() {
    const requiredItem = this.state.requiredId;
    let modalData = this.props.comments_state[requiredItem]
      ? this.props.comments_state[requiredItem]
      : [];
    return (
      <Container
        location={{
          locationName: "Komentarų būsenos",
          locationUrl: this.props.match.url,
        }}
      >
        <ContentBlock>
          <FilterMenu
            length={`${this.props.totals || 0} / ${
              this.props.comments_state.length || 0
              }`}
              onChangeCountPage={this.setPageCount}
              countPage={this.state.pageCount}
            text="komentarų būsenų"
            text2="Komentarų būsenų"
          />
        </ContentBlock>
        <ContentBlock>
          <TableFilterBox filter={false} add={this.onAction}></TableFilterBox>
          <div>
            <ContentValidation
              loader={this.props.loading_comments_state}
              array={this.props.comments_state}
              alertId_s="commentsStateAlert-s"
              alertId_e="commentsStateAlert-e"
            >
              <div className="table-responsive">
                <table className="table table-sm table-hover mt-3">
                  <thead>
                    <tr className="text-muted">
                      <th className="text-black">#</th>
                      <th className="text-black">Pavadnimas</th>
                      <th className="text-black">Spalvos kodas</th>
                      <th className="text-black">Identifikatorius</th>
                      <th className="text-black">Komentaro tipas</th>
                      <th className="text-black">Užsakymas</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.comments_state.map((item, index) => {
                      return (
                        <TableColumnCommentsState
                          key={index}
                          index={index}
                          item={item}
                          onEdit={this.onAction}
                          onDelete={this.props.deleteCommentState}
                        />
                      );
                    })}
                  </tbody>
                </table>
                <PaginationComp
                  setPagination={this.setPagination}
                  page={this.state.page}
                  totals={this.props.totals}
                  pageCount={this.state.pageCount}
                />
              </div>
            </ContentValidation>
          </div>
        </ContentBlock>

        <CommentsStateEdit
          action={this.state.action}
          postCommentState={this.props.postCommentState}
          updateCommentState={this.props.updateCommentState}
          objectData={modalData}
          clearErrors={this.props.clearErrors}
          errors={this.props.errors}
        />
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  comments_state: state.comment_state.comments_state,
  totals: state.comment_state.totals,
  loading_comments_state: state.comment_state.loading_comments_state,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getCommentState,
  postCommentState,
  deleteCommentState,
  updateCommentState,
  clearErrors
})(CommentState);
