import Axios from "axios";
import {
  GET_COMMENTSSTATE,
  POST_COMMENTSSTATE,
  DELETE_COMMENTSSTATE,
  UPDATE_COMMENTSSTATE,
  LOADING_COMMENTSSTATE,
  CLEAR_ERRORS,
  GET_ERRORS,
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

const success = () => {
  if (!document.getElementById("commentsStateAlert-s")) return;
  document.getElementById("commentsStateAlert-s").style.display = "block";
  setTimeout(
    () =>
      (document.getElementById("commentsStateAlert-s").style.display = "none"),
    5000
  );
  document.getElementById("modalClose").click();
};

const error = () => {
  if (!document.getElementById("commentsStateAlert-e")) return;
  document.getElementById("commentsStateAlert-e").style.display = "block";
  setTimeout(
    () =>
      (document.getElementById("commentsStateAlert-e").style.display = "none"),
    5000
  );
};

export const postCommentState = (smsData) => (dispatch) => {
  Axios.post("/api/v1/comment_states", smsData)
    .then((res) => {
      dispatch({
        type: POST_COMMENTSSTATE,
        payload: res.data,
      });
      dispatch(clearErrors());
      success();
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const getCommentState = (page, pageCount) => (dispatch) => {
  dispatch(setCommentStateLoading());
  Axios.get(`/api/v1/comment_states?page=${page}&per_page=${pageCount}`)
    .then((res) => {
      dispatch({
        type: GET_COMMENTSSTATE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getCommentStateOption = () => {
  // dispatch(setCommentStateLoading());
  return Axios.get(
    `/api/v1/comment_states?page=1&per_page=99999`
  ).then((res) => {
    return res.data;
  });
};

export const deleteCommentState = (_id) => (dispatch) => {
  if (confirm("Ar tikrai norite ištrinti ? ")) {
    Axios.delete(`/api/v1/comment_states/${_id}`)
      .then((res) => {
        dispatch({
          type: DELETE_COMMENTSSTATE,
          payload: _id,
        });
        success();
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        error();
      });
  }
};

export const updateCommentState = (_id, body, indexOfArray) => (dispatch) => {
  Axios.put(`/api/v1/comment_states/${_id}`, body)
    .then((res) => {
      dispatch(clearErrors());
      dispatch({
        type: UPDATE_COMMENTSSTATE,
        payload: res.data,
        payload_id: _id,
      });
      success();
      document.querySelector(".close-emodal").click();
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const setCommentStateLoading = () => {
  return {
    type: LOADING_COMMENTSSTATE,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
