import React from "react";
import { Dot } from "../../common/Dot";
import { Checkbox } from "antd";
import moment from "moment";
import { IconArrowRight } from "../../Layout/Icons";

function TableColumnSms({
  item,
  index,
  setSelected,
  selected,
  calculateDiscount,
  openModal,
}) {
  return (
    <tr className={"align-middle tableColumn repricingColumn "} key={index}>
      <td className="align-middle">
        <Checkbox onChange={setSelected} checked={selected} />
      </td>
      <td className="align-middle">
        {item.full_img_url && (
          <img src={item.full_img_url} className="brand-img" />
        )}
      </td>
      <td className="align-middle">
        <div
          className="cursor-pointer hover-text-primary"
          onClick={openModal}
          style={{ color: "inherit", textDecoration: "underline" }}
        >
          {item.product.identifier}
        </div>
      </td>
      <td className="align-middle">{item.product.title}</td>
      <td className="align-middle">{item.description || ""}</td>
      <td className="align-middle text-center">
        <Dot value={item.family?.marked} />
      </td>
      <td className="align-middle text-center">
        <Dot value={!item.canceled} />
      </td>
      <td className="align-middle">{item.base_price}</td>
      <td className="align-middle">{item.new_price ? item.new_price : ""}</td>
      <td className="align-middle">
        {item.base_price && item.new_price
          ? calculateDiscount(item.base_price, item.new_price)
          : ""}
      </td>
      <td className="align-middle">
        {item.discount_start && (
          <div className="d-flex align-item-center">
            <div className="d-flex flex-column align-items-center">
              <div className="text-sm text-black">
                {moment(item.discount_start).format("YYYY-MM-DD")}
              </div>
              <div className="text-xs2 text-gray-600">
                {moment(item.discount_start).format("HH:mm")}
              </div>
            </div>
            <div className="mx-2">
              <IconArrowRight />
            </div>

            <div className="d-flex flex-column align-items-center">
              <div className="text-sm text-black">
                {moment(item.discount_end).format("YYYY-MM-DD")}
              </div>
              <div className="text-xs2 text-gray-600">
                {moment(item.discount_end).format("HH:mm")}
              </div>
            </div>
          </div>
        )}
      </td>
    </tr>
  );
}

export default TableColumnSms;
