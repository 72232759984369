import React from 'react'
import { Lock_svg } from '../../helpers/SvgStrings'

export default function Label({
    children, error, className, disabled, required
}) {
    return (
        <div className={`${className ?? ""} font-medium text-sm my-1 ${children == "-" ? "invisible" : ""} ${error ? "text-danger" : ""}`}>
            {children}{" "}
            {disabled
                ? Lock_svg()
                : required && <span className="orange_color">*</span>}
        </div>
    )
}