import React from 'react';
import spinner from '../../../assets/images/loading.svg';

export default () => {
  return (
    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
      <img
        src={spinner}
        style={{ width: '100px', padding: '15px' }}
        alt="Loading..."
      />
    </div>
  );
};