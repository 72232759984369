import React from "react";
import { ButtonEdit, ButtonDelete, EditElementClick } from "../../common/Buttons";

function TableColumnSms({ item, onDelete, index, onEdit }) {
  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle">{item.id}</td>
      <td className="align-middle">
        <EditElementClick name={item.name} onEdit={() => onEdit("edit", index)} />
      </td>
      <td className="align-middle">{item.en_name}</td>
      <td className="align-middle">{item.currency}</td>
      <td className="align-middle">{item.code}</td>
      <td className="align-middle">{item.eu ? "TAIP" : "NE"}</td>
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonEdit onEdit={() => onEdit("edit",index)} />
      </td>
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonDelete onDelete={() => onDelete(item.id)} />
      </td>
    </tr>
  );
}

export default TableColumnSms;
