import React, { memo } from "react";
import { useMemo } from "react";
import useFetch from "../../../helpers/useFetch";
import CommerceProductStoresPreview from "./CommerceProductStoresPreview";
import { mainStoresWithDepartments } from "../../../helpers/GlobalOptions";


const CommerceTableColumnOpen = ({
  product,
  checkedIncluded,
  onChangeCheckedCallBack,
  onHandleModalOptCallBack,
  setOpenCallBack,
}) => {
  console.log("CommerceTableColumnOpen: ", product.identifier);
  const { value: shopsArr } = useFetch(`/api/v1/stores`);

  const shops = useMemo(() => {
    if (!shopsArr) return [];

    return shopsArr.data
      .filter((shop) => mainStoresWithDepartments[shop.rivile_code])
      .map((shop) => ({
        value: shop.id,
        label: `${mainStoresWithDepartments[shop.rivile_code]}`,
      }));
  }, [shopsArr]);

  return (
    <>
      <CommerceProductStoresPreview
        type="parent"
        child={!!product.childs?.length}
        open={true}
        shops={shops}
        product={product}
        setOpenCallBack={setOpenCallBack}
        checkedIncluded={checkedIncluded}
        onChangeCheckedCallBack={onChangeCheckedCallBack}
        onHandleModalOptCallBack={onHandleModalOptCallBack}
      />

      {product.childs?.map((d) => (
        <CommerceProductStoresPreview
          key={d.id}
          type="child"
          child={true}
          open={true}
          shops={shops}
          product={d}
          setOpenCallBack={setOpenCallBack}
          parent_stores={product.stores}
        />
      ))}
    </>
  );
};

export default memo(CommerceTableColumnOpen);
