import React from 'react'
import { message } from "antd";

const antdAsyncMessageLoading = ({ text = "Vykdoma...", key = "default" }) =>
  message.loading({
    content: text,
    key,
    duration: 0,
  });

const antdAsyncMessageSuccess = ({
  text = "Sėkmingai atlikta",
  key = "default",
}) =>
  message.success({
    content: text,
    key,
    duration: 2,
  });

const antdAsyncMessageError = ({ text = "Įvyko klaida", key = "default" }) =>
  message.error({
    content: <span dangerouslySetInnerHTML={{__html: text || "Įvyko klaida"}} />,
    key,
    duration: 2,
  });

export {
  antdAsyncMessageError,
  antdAsyncMessageSuccess,
  antdAsyncMessageLoading,
};
