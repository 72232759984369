import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { GiSave } from "react-icons/gi";

import { phonenumberValid } from "../../../../../helpers/Utils";
import TextInputModal from "../../../../common/TextInputModal";
import { GrClose, GrEdit } from "react-icons/gr";
import { Divs, UlElements } from "../../../../common/StyledComponents";
import {
  postToRivile,
  updateServiceRepairAddress,
} from "../../../../../redux/Actions/Service/serviceAction";
import { LongButton } from "../../../../common/Buttons";
import { ClassicButtonOutline } from "../../../../common/ButtonWithLoading";
import { AiOutlineDownload } from "react-icons/ai";
import { posti_postal_code } from "../../../../../helpers/RequestToAnotherServer";

function ServiceRepairAddress({ address, orderId, rivile_store_id }) {
  const dispatch = useDispatch();
  const [editAddress, setEditAddress] = useState(false);
  const [addressArr, setAddressArr] = useState(address);
  const [rivile, setRivile] = useState({
    success: false,
    error: null,
  });

  useEffect(() => {
    if (
      !rivile_store_id &&
      editAddress &&
      addressArr.city &&
      addressArr.street
    ) {
      const t = setTimeout(async () => {
        const { success, postal_code } = await posti_postal_code(
          addressArr.city,
          addressArr.street
        );
        if (success && postal_code) {
          handleChangeText({
            target: { value: postal_code, name: "postal_code" },
          });
        }
      }, 1000);

      return () => clearTimeout(t);
    }
  }, [addressArr.city, addressArr.street]);

  const handleChangeText = (e) => {
    const { name, value } = e.target;
    setAddressArr({
      ...addressArr,
      [name]: value,
    });
  };

  const updateOrderAddress = () => {
    let objData = {
      ...address,
      ...addressArr,
    };
    delete objData.valid_phone;

    dispatch(updateServiceRepairAddress(objData.id, objData)).then((res) => {
      if (res.ok) {
        setAddressArr(res.data);
        setEditAddress(false);
      }
    });
  };
  const toggleAddress = () => setEditAddress((prev) => !prev);
  const phonenumber = phonenumberValid(address.phone);

  const postRivile = () => {
    setRivile({ success: "loading", error: rivile.error });
    dispatch(postToRivile(orderId)).then((res) => {
      if (res.success) {
        setRivile({
          success: true,
          error: null,
        });
      } else {
        setRivile({
          success: false,
          error: res.error,
        });
      }
    });
  };

  return (
    <Divs className="mb-3">
      <div className="d-flex justify-content-between font-weight-bold mb-3">
        <span>Adresas</span>
        <div
          className="d-flex align-items-center"
          style={{ cursor: "pointer" }}
          onClick={() => toggleAddress()}
        >
          {editAddress ? (
            <GrClose title="Atšaukti" />
          ) : (
            <GrEdit title="Redaguoti" />
          )}
        </div>
      </div>
      <UlElements>
        {editAddress ? (
          <React.Fragment>
            <li>
              <TextInputModal
                text="Vardas"
                placeholder="Įvesti"
                name="firstname"
                value={addressArr.firstname}
                onChange={(e) => handleChangeText(e)}
              />
            </li>
            <li>
              <TextInputModal
                text="Pavardė"
                placeholder="Įvesti"
                name="lastname"
                value={addressArr.lastname}
                onChange={(e) => handleChangeText(e)}
              />
            </li>
            {!rivile_store_id && (
              <Fragment>
                <li>
                  <TextInputModal
                    text="Adresas"
                    placeholder="Įvesti"
                    name="street"
                    value={addressArr.street}
                    onChange={(e) => handleChangeText(e)}
                  />{" "}
                </li>
                <li>
                  <TextInputModal
                    text="Miestas"
                    placeholder="Įvesti"
                    name="city"
                    value={addressArr.city}
                    onChange={(e) => handleChangeText(e)}
                  />{" "}
                </li>
                <li>
                  <TextInputModal
                    text="Pašto kodas"
                    placeholder="Įvesti"
                    name="postal_code"
                    value={addressArr.postal_code}
                    onChange={(e) => handleChangeText(e)}
                  />
                </li>
              </Fragment>
            )}
            <li>
              <TextInputModal
                text="Telefono numeris"
                placeholder="Įvesti"
                name="phone"
                value={addressArr.phone}
                onChange={(e) => handleChangeText(e)}
              />{" "}
            </li>
            <li>
              <TextInputModal
                text="El. paštas"
                placeholder="Įvesti"
                name="other"
                value={addressArr.other}
                onChange={(e) => handleChangeText(e)}
              />
            </li>

            <li className="my-3">
              <button
                className="btn btn-sm btn-primary"
                style={{ width: "100%" }}
                onClick={() => updateOrderAddress(addressArr)}
              >
                <GiSave />
                {"Atnaujinti"}
              </button>
            </li>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <li>
              {address.firstname} {address.lastname}
            </li>
            <li>{address.street}</li>
            {address.city || address.postal_code ? (
              <li>
                {address.city}, {address.postal_code}
              </li>
            ) : null}
            <li className={phonenumber ? "" : "text-danger"}>
              {address.phone}
            </li>
            <li>{address.other}</li>
            <li>
              {address.registered_in_rivile ? (
                <div className="d-flex">
                  <LongButton
                    style={{
                      backgroundColor: "#28a745",
                      color: "white",
                      fontSize: "12px",
                      maxWidth: "150px",
                      height: "auto",
                      width: "auto",
                      padding: "0 13px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    text={"Eksportuota"}
                  />
                </div>
              ) : (
                <ClassicButtonOutline
                  loading={rivile.success === "loading"}
                  onClick={() => postRivile()}
                >
                  <AiOutlineDownload size={16} />
                  <span>Klientas</span>
                </ClassicButtonOutline>
              )}
            </li>
            {rivile.error !== null && (
              <li
                className="text-danger d-flex justify-content-end"
                style={{ fontSize: "13px" }}
              >
                {rivile.error.map((item) => (
                  <div>{item}</div>
                ))}
              </li>
            )}
          </React.Fragment>
        )}
      </UlElements>
    </Divs>
  );
}

export default ServiceRepairAddress;
