import React from "react";
import { CheckBoxInput, SelectForm } from "../../Buttons";
import TextInputModal from "../../TextInputModal";
import ShippmentTable from "./editModal/Shippment/ShippmentTable";
import { AlertError, AlertSuccess } from "../../Alerts";
import styled from "styled-components";
import { ClassicButton } from "../../ButtonWithLoading";
import RowsInShipment from "./editModal/RowsInShipment";
import ShipmentAdditionalServices from "./ShipmentAdditionalServices";
import { orCorrectDeliveryMethod } from "../../../../helpers/OrdersFunctionsGlobal";

function ShippingComponent({
  disabled = false,
  order_type,
  delivery,
  order_id,
  id_order,
  mode,
  shipping_address_edit,
  terminalSelected,
  handleChangeText,
  pickupAddress,
  departamentOptions = [],
  order_rows,
  shipments,
  handleCheckBoxOrdersSelected,
  parcel_terminals,
  selectedOrderRows,
  variables,
  onChangeCheckBoxDelivery,
  onChangeDate,
  createShipping,
  errors,
  handleChangeOption,
  shipmentRowForStore,
  shipmentRowForStoreFunction,
}) {
  const itsTypeStore = order_type == "at_the_store";
  return (
    <>
      {!disabled && (
        <div className="col-12 bg-silver pt-3 mb-4">
          <AlertSuccess
            title="Siuntos pridėjimas"
            alertId={"orderShippment-s"}
          />
          <AlertError title="Siuntos pridėjimas" alertId={"orderShippment-e"} />

          <div className="d-flex">
            <div className="flex-1">
              {order_type === "dpd" && (
                <>
                  <CheckBoxInput
                    choised="dpd_b2c"
                    name="dpd_b2c"
                    color="#f7951e"
                    text="B2C siunta (privačiam asmeniui)"
                    checked={delivery == "dpd_b2c"}
                    onChange={(e) => onChangeCheckBoxDelivery(e)}
                  />
                  <CheckBoxInput
                    choised="dpd_postMachine"
                    name="dpd_postMachine"
                    color="#f7951e"
                    text="Pristatymas į paštomatą"
                    checked={delivery == "dpd_postMachine"}
                    onChange={(e) => onChangeCheckBoxDelivery(e)}
                  />
                  {mode === "Servisas" && order_type === "dpd" && (
                    <CheckBoxInput
                      choised="pickup_from_department"
                      name="pickup_from_department"
                      color="#f7951e"
                      text="Siuntimas į padalinį"
                      checked={delivery == "pickup_from_department"}
                      onChange={(e) => onChangeCheckBoxDelivery(e)}
                    />
                  )}{" "}
                  {mode === "Servisas" && order_type === "dpd" && (
                    <CheckBoxInput
                      choised="dpd_with_label"
                      name="dpd_with_label"
                      color="#f7951e"
                      text="Paėmimas su lipduku"
                      checked={delivery == "dpd_with_label"}
                      onChange={(e) => onChangeCheckBoxDelivery(e)}
                    />
                  )}
                </>
              )}

              {order_type === "omniva" && (
                <>
                  <CheckBoxInput
                    choised="omniva_home"
                    name="omniva_home"
                    color="#f7951e"
                    text="Pristatymas į namus"
                    checked={delivery == "omniva_home"}
                    onChange={(e) => onChangeCheckBoxDelivery(e)}
                  />
                  <CheckBoxInput
                    choised="omniva_postMachine"
                    name="omniva_postMachine"
                    color="#f7951e"
                    text="Pristatymas į paštomatą"
                    checked={delivery == "omniva_postMachine"}
                    onChange={(e) => onChangeCheckBoxDelivery(e)}
                  />
                  <CheckBoxInput
                    choised="omniva_return"
                    name="omniva_return"
                    color="#f7951e"
                    text="Grąžinimas"
                    checked={delivery == "omniva_return"}
                    onChange={(e) => onChangeCheckBoxDelivery(e)}
                  />
                </>
              )}

              {order_type === "itella" && (
                <CheckBoxInput
                  choised="itella_postMachine"
                  name="itella_postMachine"
                  color="#f7951e"
                  text="Pristatymas į paštomatą"
                  checked={delivery == "itella_postMachine"}
                  onChange={(e) => onChangeCheckBoxDelivery(e)}
                />
              )}
              {order_type === "lp_express" && (
                <>
                  <CheckBoxInput
                    choised="lp_express_home"
                    name="lp_express_home"
                    color="#f7951e"
                    text="Pristatymas į namus"
                    checked={delivery == "lp_express_home"}
                    onChange={(e) => onChangeCheckBoxDelivery(e)}
                  />
                  <CheckBoxInput
                    choised="lp_express_postMachine"
                    name="lp_express_postMachine"
                    color="#f7951e"
                    text="Pristatymas į paštomatą"
                    checked={delivery == "lp_express_postMachine"}
                    onChange={(e) => onChangeCheckBoxDelivery(e)}
                  />
                </>
              )}
              {order_type === "venipak" && (
                <>
                  <CheckBoxInput
                    choised="venipak_home"
                    name="venipak_home"
                    color="#f7951e"
                    text="Pristatymas į namus"
                    checked={delivery == "venipak_home"}
                    onChange={(e) => onChangeCheckBoxDelivery(e)}
                  />
                  <CheckBoxInput
                    choised="venipak_postMachine"
                    name="venipak_postMachine"
                    color="#f7951e"
                    text="Pristatymas į paštomatą"
                    checked={delivery == "venipak_postMachine"}
                    onChange={(e) => onChangeCheckBoxDelivery(e)}
                  />
                </>
              )}
            </div>
          </div>
          {delivery == "pickup_from_department" && (
            <Divs>
              <SelectForm
                options={departamentOptions}
                placeholder={"Padaliniai"}
                onlyPlaceholder="Pasirinkti"
                selector="suppliers"
                value={variables.departament_id}
                dropdownMatchSelectWidth={false}
                onChange={(e) => handleChangeOption(e, "departament_id")}
              />
            </Divs>
          )}
          <Divs>
            <TextInputModal
              required="true"
              text={
                delivery == "dpd_with_label" ? "Paėmimo adresas" : "Gavėjas"
              }
              placeholder="Įvesti"
              name="firstname"
              value={shipping_address_edit.firstname}
              onChange={(e) => handleChangeText(e, "shipping_address_edit")}
            />
          </Divs>
          <Divs>
            <TextInputModal
              required="true"
              text="Kontaktinis asmuo"
              placeholder="Įvesti"
              name="lastname"
              value={shipping_address_edit.lastname}
              onChange={(e) => handleChangeText(e, "shipping_address_edit")}
            />
          </Divs>
          <Divs>
            <TextInputModal
              required="true"
              text="Adresas"
              placeholder="Įvesti"
              name="street"
              value={shipping_address_edit.street}
              onChange={(e) => handleChangeText(e, "shipping_address_edit")}
            />
          </Divs>
          {order_type === "omniva" &&
            ["omniva_postMachine", "omniva_return"].includes(delivery) && (
              <Divs>
                <SelectForm
                  options={parcel_terminals.omniva}
                  placeholder="Paštomatas"
                  onlyPlaceholder="Pasirinkti"
                  value={terminalSelected}
                  onChange={(e) =>
                    handleChangeText({
                      target: { name: "terminalSelected", value: e },
                    })
                  }
                />
              </Divs>
            )}
          {order_type === "lp_express" &&
            delivery === "lp_express_postMachine" && (
              <Divs>
                <SelectForm
                  options={parcel_terminals.lp_express}
                  placeholder="Paštomatas"
                  onlyPlaceholder="Pasirinkti"
                  value={terminalSelected}
                  onChange={(e) =>
                    handleChangeText({
                      target: { name: "terminalSelected", value: e },
                    })
                  }
                />
              </Divs>
            )}
          {order_type === "dpd" && delivery === "dpd_postMachine" && (
            <Divs>
              <SelectForm
                options={parcel_terminals.dpd}
                placeholder="Paštomatas"
                onlyPlaceholder="Pasirinkti"
                value={terminalSelected}
                onChange={(e) =>
                  handleChangeText({
                    target: { name: "terminalSelected", value: e },
                  })
                }
              />
            </Divs>
          )}
          {order_type === "itella" && delivery === "itella_postMachine" && (
            <Divs>
              <SelectForm
                options={parcel_terminals.itella}
                placeholder="Paštomatas"
                onlyPlaceholder="Pasirinkti"
                value={terminalSelected}
                onChange={(e) =>
                  handleChangeText({
                    target: { name: "terminalSelected", value: e },
                  })
                }
              />
            </Divs>
          )}
          {order_type === "venipak" && delivery === "venipak_postMachine" && (
            <Divs>
              <SelectForm
                options={parcel_terminals.venipak}
                placeholder="Paštomatas"
                onlyPlaceholder="Pasirinkti"
                value={terminalSelected}
                onChange={(e) =>
                  handleChangeText({
                    target: { name: "terminalSelected", value: e },
                  })
                }
              />
            </Divs>
          )}
          <Divs>
            <TextInputModal
              required="true"
              text="Miestas"
              placeholder="Įvesti"
              name="city"
              value={shipping_address_edit.city}
              onChange={(e) => handleChangeText(e, "shipping_address_edit")}
            />
          </Divs>
          <Divs>
            <TextInputModal
              required="true"
              text="Pašto kodas"
              placeholder="Įvesti"
              name="postal_code"
              value={shipping_address_edit.postal_code}
              onChange={(e) => handleChangeText(e, "shipping_address_edit")}
            />
          </Divs>
          <Divs>
            <TextInputModal
              text="Telefonas"
              placeholder="Įvesti"
              name="phone"
              value={shipping_address_edit.phone}
              onChange={(e) => handleChangeText(e, "shipping_address_edit")}
            />
          </Divs>
          <Divs>
            <TextInputModal
              text="El. paštas"
              placeholder="Įvesti"
              name="other"
              value={shipping_address_edit.other}
              onChange={(e) => handleChangeText(e, "shipping_address_edit")}
            />
          </Divs>
          <Divs>
            <TextInputModal
              text="Pastaba kurjeriui"
              placeholder="Įvesti"
              value={shipping_address_edit.comment}
              name="comment"
              onChange={(e) => handleChangeText(e, "shipping_address_edit")}
            />
          </Divs>
          <SelectForm
            options={pickupAddress}
            placeholder={
              itsTypeStore
                ? "Atidavimas iš"
                : delivery == "dpd_with_label"
                ? "Pristatyti į"
                : "Tiekėjas"
            }
            onlyPlaceholder="Pasirinkti"
            selector="suppliers"
            value={variables.suppliers}
            onChange={(e) => handleChangeOption(e, "suppliers")}
            error={
              errors.pickup_address &&
              errors.pickup_address.map((item) => `${item} `)
            }
          />
          {!orCorrectDeliveryMethod(order_type, variables.suppliers, [
            { service_key: "delivery", service_value: delivery },
          ]) && (
            <div className="text-danger">
              <div>Neteisingai pasirinktas pristatymo būdas</div>
            </div>
          )}
          <Divs className="border-top border-bottom mt-2">
            <RowsInShipment
              at_the_store={order_type == "at_the_store"}
              order_rows={order_rows}
              shipmentRowForStore={shipmentRowForStore}
              shipmentRowForStoreFunction={shipmentRowForStoreFunction}
              handleCheckBoxOrdersSelected={handleCheckBoxOrdersSelected}
              selectedOrderRows={selectedOrderRows}
            />
          </Divs>
          <>
            {delivery !== "dpd_with_label" && (
              <ShipmentAdditionalServices
                order_id={order_id}
                itsTypeStore={itsTypeStore}
                createShipping={createShipping}
                order_type={order_type}
                delivery={delivery}
                variables={variables}
                handleChangeText={handleChangeText}
                shipmentRowForStore={shipmentRowForStore}
                onChangeDate={onChangeDate}
                errors={errors}
                postal_code={shipping_address_edit.postal_code}
              />
            )}

            <div className="mt-2">
              <ClassicButton
                loading={variables.shipping_loading}
                text={itsTypeStore ? "Sukurti atidavimą" : "Sukurti siuntą"}
                onClick={() => createShipping()}
              />
            </div>
          </>
        </div>
      )}
      <table className="table mb-4">
        <tbody>
          {shipments.map((item, index) => (
            <ShippmentTable
              order_id={order_id}
              id_order={id_order}
              key={index}
              index={index}
              mode={mode}
              item={item}
              parcel_terminals={parcel_terminals}
            />
          ))}
        </tbody>
      </table>
    </>
  );
}

const Divs = styled.div`
  display: block;
  color: #000000;
  font-size: 15px;
  font-weight: 400;
  & .text-bold {
    font-size: 14px;
    line-height: 20px;
    color: #030303;
    font-weight: 600;
  }
  & .residuesItem {
    font-size: 0.8rem;
    word-wrap: break-word;
  }

  & .orange_color {
    color: #f7951e;
  }
  & .addDoc {
    margin-top: 5px;
    color: #f7951e;
    &:hover {
      color: #bd6903;
      border-bottom: 1px solid #f7951e;
      cursor: pointer;
    }
  }
  & .input_text {
    right: 7%;
    top: 50%;
    font-weight: bold;
    text-transform: uppercase;
    transform: translate(-50%, -50%);
    z-index: 100;
  }
`;

export default ShippingComponent;
