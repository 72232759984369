import Axios from "axios";

export const posti_postal_code = async (city, address) => {
  try {
    const request = await fetch(
      `https://api.postit.lt/v2/?city=${city.replace(
        / /g,
        "+"
      )}&address=${address.replace(/ /g, "+")}&key=tG6vI4eRO27ePdx6etAm`
    );
    const response = await request.json();
    if (response.status == "success" && response.total > 0) {
      return {
        success: true,
        postal_code: response.data[0].post_code || "",
      };
    }
    throw new Error("Nerasta!");
  } catch (err) {
    console.log("Error: ", err);
    return { success: false };
  }
};
