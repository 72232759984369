import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { exportDocument } from "../../../helpers/Utils";
import { Bubble } from "../../common/StyledComponents";
import CustomSidebarExport from "./CustomSidebarExport";

function SidebarNavLink(props) {
  if (!props.url) {
    const layout = useMemo(() => {
      if (props.uuid == "exports_minus") {
        return (
          <div
            className={"nav-link sidebar-link subnav-item cursor-pointer"}
            onClick={() =>
              exportDocument(
                "/api/v1/products/export_pim_products",
                "exported_pim_whosale.xlsx",
                "POST"
              )
            }
          >
            {props.name}
          </div>
        );
      }
      if (props.uuid == "exports_price_protection") {
        return (
          <li className="nav-item">
            <a
              className={"nav-link sidebar-link subnav-item cursor-pointer"}
              href="https://pim.technorama.lt/download_less_than_wholesale_file.php?token=secret_s6dg4sdgoq5ag16sfdg198r"
              download
            >
              {"Kainos apsauga"}
            </a>
          </li>
        );
      }
      if (
        [
          "exports_adrem_balances",
          "exports_orders",
          "sku_url_export",
          "exports_balances_of_suppliers",
          "exports_retail_vs_online",
        ].includes(props.uuid)
      ) {
        return <CustomSidebarExport name={props.name} />;
      }
      return <div>Nėra priskirtas UUID</div>;
    }, [props.uuid]);

    return layout;
  }
  return (
    <li className="nav-item">
      <NavLink
        activeClassName="current-link"
        to={props.url.replace(":page", "1")}
        className={
          props.sub
            ? "nav-link sidebar-link subnav-item cursor-pointer"
            : `nav-link px-3 mb-1 sidebar-link cursor-pointer`
        }
      >
        {props.children}
        {props.name}
        {!!props.dot && (
          <Bubble width={13} up={true}>
            {props.dot}
          </Bubble>
        )}
      </NavLink>
    </li>
  );
}
export default SidebarNavLink;
