import React from "react";
import styled from "styled-components";

export default function SimplePagination({
  setPagination,
  pageCount = 10,
  page,
  totals = 10,
}) {
  const total_pages = Math.ceil(totals / pageCount);
  if (total_pages == 1) return <div />
  return (
    <div className="d-flex align-items-center justify-content-center">
      {[...new Array(total_pages)].map((_, idx) => (
        <PaginationStyled
          key={idx}
          active={page == idx + 1}
          onClick={() => setPagination(idx + 1)}
        >
          {idx + 1}
        </PaginationStyled>
      ))}
    </div>
  );
}

const PaginationStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  background-color: ${(props) => (props.active ? "#FE9C00" : "#fff")};
  color: ${(props) => (props.active ? "#fff" : "#212121")};
`;
