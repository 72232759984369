import Axios from "axios";
import {
  antdMessageError,
  antdMessageSuccess,
  errorHandler,
} from "../../helpers/Utils";

export const getPoster = (page, filterParams) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_ONLY_CUSTOM" });
    const { data } = await Axios.get(
      `/api/v1/posters?page=${page}${filterParams}`
    );
    console.log({ data });
    dispatch({
      type: "GET_CUSTOMS",
      payload: { ...data, current: "Plakatai" },
    });
    return { success: true };
  } catch (err) {
    errorHandler(err);
    antdMessageError();
    return { success: false };
  }
};

export const getPosterOptions = () => async (dispatch) => {
  try {
    const getBrands = () =>
      Axios.get(`/api/v1/brands/all_brands`).then((res) => {
        return res.data.data.map((e) => ({
          value: e.id,
          label: e.title,
        }));
      });
    const getFamilies = () =>
      Axios.get(`/api/v1/posters/families_posters`).then((res) => {
        return res.data.data.map((value) => ({
          value,
          label: value,
        }));
      });

    Promise.all([getBrands(), getFamilies()]).then((res) => {
      console.log("Response: ", { res });
      dispatch({
        type: "SET_OPTIONS",
        payload: {
          brands: res[0],
          families: res[1],
        },
      });
    });
    return { success: true };
  } catch (err) {
    console.log("ERROR ", err);
    return { success: false };
  }
};

export const downloadPoster = async (ids, type) => {
  try {
    const { data } = await Axios.post(
      `/api/v1/posters/download.pdf?type=${type}`,
      {
        ids,
      }
    );
    if (data.success && data.file_path) {
      window.open(`/${data.file_path}`, "_blank");
      antdMessageSuccess();
      return {
        success: true,
      };
    }
    throw new Error("ERR");
  } catch (err) {
    antdMessageError();
    console.log("ERROR: ", err);
    return {
      success: false,
    };
  }
};

export const postPoster = async (params) => {
  try {
    const poster_id = params.poster.id;
    const { data } = await Axios({
      url: poster_id ? `/api/v1/posters/${poster_id}` : `/api/v1/posters`,
      data: params,
      method: poster_id ? "PUT" : "POST",
    });
    console.log({ data });
    antdMessageSuccess();
    return { success: true };
  } catch (err) {
    errorHandler(err);
    antdMessageError();
    return { success: false };
  }
};
export const massDestroy = (ids) => async (dispatch, getState) => {
  try {
    const { data } = await Axios({
      url: `/api/v1/posters/mass_destroy`,
      data: {
        ids,
      },
      method: "POST",
    });
    console.log({ data });
    antdMessageSuccess();
    const customData = getState().custom;
    const filtered = customData.data.filter((d) => {
      if (ids.includes(d.id)) {
        return false;
      }
      return true;
    });
    dispatch({
      type: "GET_CUSTOMS",
      payload: {
        current: "Plakatai",
        data: filtered,
        totals: customData.totals || 0,
        loading: false,
      },
    });
    return { success: true };
  } catch (err) {
    errorHandler(err);
    antdMessageError();
    return { success: false };
  }
};

export const updatePostersDate = (params) => async (dispatch, getState) => {
  try {
    const { data } = await Axios({
      url: `/api/v1/posters/update_posters_date`,
      data: params,
      method: "POST",
    });
    console.log({ data });
    antdMessageSuccess();
    const customData = getState().custom;
    dispatch({
      type: "GET_CUSTOMS",
      payload: {
        current: "Plakatai",
        data: customData.data.map((d) => {
          if (params.ids.includes(d.id)) {
            return { ...d, ...params };
          }
          return d;
        }),
        totals: customData.totals || 0,
        loading: false,
      },
    });
    return { success: true };
  } catch (err) {
    errorHandler(err);
    antdMessageError();
    return { success: false };
  }
};

export const removePoster = (id) => async (dispatch) => {
  try {
    const { data } = await Axios.delete(`/api/v1/posters/${id}`);
    console.log({ data });
    antdMessageSuccess();
    dispatch({
      type: "REMOVE_CUSTOM",
      payload: id,
    });
    return { success: true };
  } catch (err) {
    errorHandler(err);
    antdMessageError();
    return { success: false };
  }
};
