import React, { useState, useEffect } from "react";
import TextInputModal from "../../../common/TextInputModal";
import { FaCheck, FaTimes } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { updateServiceDescription } from "../../../../redux/Actions/Service/serviceAction";
import { useDispatch } from "react-redux";

export default function ServiceDescriptionEditable({
  id,
  label,
  value_name,
  manual,
  value,
}) {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(value);
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    if (value !== inputValue) setInputValue(value);
  }, [edit]);

  const onSubmit = async () => {
    if (value.trim() == inputValue.trim()) {
      return setEdit(false);
    } else {
      const params = {
        service_description: {
          [value_name]: inputValue,
          [`${value_name}_manual`]: true,
        },
      };

      await dispatch(updateServiceDescription(id, params));
      return setEdit(false);
    }
  };
  if (edit) {
    return (
      <li className="d-flex justify-content-between align-items-center">
        <TextInputModal
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <div className="d-flex align-items-center ml-3">
          <FaCheck
            className="text-success cursor-pointer"
            size={18}
            onClick={() => onSubmit()}
          />
          <FaTimes
            className="text-danger cursor-pointer"
            style={{
              marginLeft: "10px",
            }}
            size={18}
            onClick={() => setEdit(false)}
          />
        </div>
      </li>
    );
  }
  return (
    <li
      className={`d-flex justify-content-between align-items-center ${
        manual ? "border-bottom border-danger" : ""
      }`}
      style={{ fontSize: "0.8rem" }}
    >
      <span>
        <span className="font-weight-bold">{label}: </span>
        {value}
      </span>
      <div className="d-flex align-items-center ml-3">
        <MdEdit
          className="cursor-pointer"
          color="green"
          onClick={() => setEdit(true)}
        />
      </div>
    </li>
  );
}
