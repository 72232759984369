import Axios from "axios";
import {
  GET_USERS_PICKUP,
  POST_USER_PICKUP,
  DELETE_USER_PICKUP,
  UPDATE_USER_PICKUP,
  LOADING_USER_PICKUP,
  CLEAR_ERRORS,
  GET_ERRORS,
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

const success = () => {
  if (!document.getElementById("userAlert-s")) return;
  document.getElementById("userAlert-s").style.display = "block";
  setTimeout(
    () => (document.getElementById("userAlert-s").style.display = "none"),
    5000
  );
  document.getElementById("modalClose").click();
};
const error = () => {
  if (!document.getElementById("userAlert-e")) return;
  document.getElementById("userAlert-e").style.display = "block";
  setTimeout(
    () => (document.getElementById("userAlert-e").style.display = "none"),
    5000
  );
};

export const postUser = (dataBody) => (dispatch) => {
  Axios.post("/api/v1/pickup_users", dataBody)
    .then((res) => {
      dispatch({
        type: POST_USER_PICKUP,
        payload: res.data,
      });
      dispatch(clearErrors());
      success("post");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const getUsers = (page, pageCount, filter) => (dispatch) => {
  dispatch(setUserLoading());
  Axios.get(`/api/v1/pickup_users?page=${page}&per_page=${pageCount}${filter}`)
    .then((res) => {
      dispatch({
        type: GET_USERS_PICKUP,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateUser = (_id, body, indexOfArray) => (dispatch) => {
  Axios.put(`/api/v1/pickup_users/${_id}`, body)
    .then((res) => {
      dispatch(clearErrors());
      dispatch({
        type: UPDATE_USER_PICKUP,
        payload: res.data,
        payload_id: _id,
      });
      success("update");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const authRemove = (_id) => async (dispatch) => {
  try {
    const { data } = await Axios.post(
      `/api/v1/pickup_users/${_id}/two_fa_reset/`
    );
    if (data.success) {
      dispatch(clearErrors());
      dispatch({
        type: UPDATE_USER_PICKUP,
        payload: data.pickup_user,
        payload_id: _id,
      });
      success("update");
      return;
    }
    throw new Error("Err");
  } catch (err) {
    error();
    console.log("Error:authRemove ", err);
  }
};

export const deleteUer = (_id) => (dispatch) => {
  if (confirm("Ar tikrai norite ištrinti ? ")) {
    Axios.delete(`/api/v1/pickup_users/${_id}`)
      .then((res) => {
        dispatch({
          type: DELETE_USER_PICKUP,
          payload: _id,
        });
        success();
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        error();
      });
  }
};

export const setUserLoading = () => {
  return {
    type: LOADING_USER_PICKUP,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
