import React from "react";
// import Sidebar from "./Sidebar/Sidebar";
import NavBar from "./NavBar";
import UserMenu from "./UserMenu";
import { connect } from "react-redux";
import { logoutUser } from "../../redux/Actions/authAction";

function Container(props) {
  return (
    <>
      <div id="page-content-wrapper">
        <NavBar
          goBack={props.goBack}
          location={props.location}
          breadcrumbs={props.breadcrumbs}
          logout={() => props.logoutUser()}
          user={props.auth.user}
        />
        <div>{props.children}</div>
      </div>
      <UserMenu logout={() => props.logoutUser()} user={props.auth.user} />
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Container);
