import React from "react";
import { useMemo, useState } from "react";
import { Spin, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import {
  deleteCommerceStore,
  duplicateCommerceStore,
  updateCommerceStore,
  updateCommerceStoreIds,
} from "../../../redux/Actions/commerce_stores_action";
import ButtonSettings from "../../buttons/ButtonSettings";
import TableUi from "../../common/TableUi";
import TextInputModal from "../../common/TextInputModal";
import { IconDelete, IconDuplicate, IconExclamation, IconNewProduct } from "../../Layout/Icons";
import useDebounce from "../../../hooks/useDebounce";
import SelectInput from "../../common/SelectInput";
import { antdMessageError, numberRound } from "../../../helpers/Utils";
import { uniqueArray } from "../../../helpers/array";
import CommerceTableProductColumn from "./CommerceTableProductColumn";
import AntdConfirm from "../../../helpers/AntdConfirm";
import { mainStoresWithDepartments } from "../../../helpers/GlobalOptions";
import useFetch from "../../../helpers/useFetch";

const CommerceProductStoresPreview = ({
  type,
  child,
  open,
  shops,
  product,
  setOpenCallBack,
  checkedIncluded,
  onChangeCheckedCallBack,
  onHandleModalOptCallBack,
  parent_stores
}) => {
  const dispatch = useDispatch();
  const { value: suppliers } = useFetch(
    `/api/v1/commerce/lists/sorted_suppliers?sku=${product.identifier}`
  );

  const rebuildedArray = useMemo(() => {
    const reducePrices = (arr, value) =>
      arr.reduce((acc, cur) => +acc + +cur[value], 0);

    const departmentsExtra = (department_code) => {
      const akropolis = ["U101", "U111"];
      if (akropolis.includes(department_code)) return akropolis;
      const aitvaras = ["U010", "U011", "U021"];
      if (aitvaras.includes(department_code)) return aitvaras;

      return [];
    };

    const stores = product.stores
      .map((d) => {
        const stocks = product.stocks.filter((s) => {
          if (s.code == d.code) return true;
          if (departmentsExtra(s.code).includes(d.code)) {
            return true;
          }
          return false;
        });
        return {
          ...d,
          store_name: `${mainStoresWithDepartments[d.code]}`,
          amount: reducePrices(stocks, "amount"),
          reserved: reducePrices(stocks, "reserved"),
          sales: reducePrices(stocks, "sales"),
          turnover: stocks.every((d) => d.turnover == null)
            ? null
            : numberRound(reducePrices(stocks, "turnover")),
        };
      })
      .sort((a, b) => {
        if (a.store_name < b.store_name) {
          return -1;
        }
        if (a.store_name > b.store_name) {
          return 1;
        }
        return 0;
      });

    const amounts = {
      amount: reducePrices(stores, "amount"),
      reserved: reducePrices(stores, "reserved"),
      min_qty: reducePrices(stores, "min_qty"),
      max_qty: reducePrices(stores, "max_qty"),
      order_qty: reducePrices(stores, "order_qty"),
      sales: reducePrices(stores, "sales"),
      turnover: !!stores.length
        ? numberRound(reducePrices(stores, "turnover") / stores.length)
        : null,
    };

    return {
      amounts,
      stores,
      storesUnique: uniqueArray(stores.map((d) => d.store_id)),
    };
  }, [product.stocks.length, product.stores.length, product?.updated_at]);

  const { stores, amounts, storesUnique } = rebuildedArray;

  const trStyles = {
    backgroundColor: child ? "#E7E7ED" : "#f9f9f9",
  };
  return (
    <>
      <CommerceTableProductColumn
        type={type}
        product={product}
        open={open}
        trStyles={trStyles}
        checkedIncluded={checkedIncluded}
        setOpenCallBack={setOpenCallBack}
        onChangeCheckedCallBack={onChangeCheckedCallBack}
        onHandleModalOptCallBack={onHandleModalOptCallBack}
      />
      <TableUi.tr style={trStyles}>
        <TableUi.td align_middle={false} colspan={1}>
          {/* <div className="position-relative">
            <IconDotLine />
          </div> */}
        </TableUi.td>
        <TableUi.td colspan={8}>
          <TableUi
            ths={[
              <div className="d-flex">
                <div className="mr-1">Parduotuvė</div>
                <ButtonSettings
                  columns={shops}
                  showColumns={storesUnique}
                  setShowColumns={async (store_ids) =>
                    dispatch(
                      updateCommerceStoreIds(product.id, {
                        product: {
                          store_ids,
                        },
                      })
                    )
                  }
                />
              </div>,
              "Likutis",
              "MIN",
              "MAX",
              "Užs. kiekis",
              "Tiekėjas",
              <div>
                <Tooltip placement="top" title="7 dienos">
                  Pardavimai
                </Tooltip>
              </div>,
              <div>
                <Tooltip placement="top" title="30 dienų">
                  Apyvartumas
                </Tooltip>
              </div>,
              "Prognozė",
              "Rivilės vidinis",
              "",
            ]}
          >
            {!stores.length ? (
              <TableUi.tr
                style={{
                  backgroundColor: "#FFFFFF",
                }}
              >
                <TableUi.td className={"text-center"} colspan={11}>
                  <div className="my-2">{"Nėra duomenų"}</div>
                </TableUi.td>
              </TableUi.tr>
            ) : (
              stores.map((store) => (
                <CommerceProductStoreColumn
                  key={`store_key_${store.code}_${store.id}`}
                  store={store}
                  suppliers={suppliers}
                  product_id={product.id}
                  parent_store={type == 'child' && parent_stores.find(parent_store => parent_store.store_id == store.store_id)}
                />
              ))
            )}
            <TableUi.tr
              key={product.id}
              className="font-medium align-middle tableColumn"
              style={{
                backgroundColor: "#FFFFFF",
              }}
            >
              <TableUi.td className="p-left">Viso</TableUi.td>
              <TableUi.td>
                {+amounts.amount + +amounts.reserved} (R:{amounts.reserved};L:
                {amounts.amount})
              </TableUi.td>
              <TableUi.td>{amounts.min_qty}</TableUi.td>
              <TableUi.td>{amounts.max_qty}</TableUi.td>
              <TableUi.td>{amounts.order_qty}</TableUi.td>
              <TableUi.td></TableUi.td>
              <TableUi.td>{amounts.sales}</TableUi.td>
              <TableUi.td>
                <RenderBadge number={amounts.turnover} />
              </TableUi.td>
              <TableUi.td></TableUi.td>
              <TableUi.td></TableUi.td>
              <TableUi.td></TableUi.td>
            </TableUi.tr>
          </TableUi>
        </TableUi.td>
      </TableUi.tr>
    </>
  );
};

export default CommerceProductStoresPreview;

const CommerceProductStoreColumn = ({ store, product_id, suppliers, parent_store }) => {
  const dispatch = useDispatch();
  const [handleDebounce, setHandleDebounce] = useState(0);
  const [variables, setVariables] = useState({
    min_qty: store.min_qty,
    max_qty: store.max_qty,
    order_qty: store.order_qty,
    supplier_id: store.supplier_id,
  });

  useDebounce(
    () => {
      if (!handleDebounce) return;
      dispatch(updateCommerceStore(product_id, { ...variables, id: store.id }));
      if (variables.order_qty >= variables.max_qty * 2)
        antdMessageError("Užsakomas kiekis 100% ar daugiau viršija MAX kiekį");
    },
    1000,
    [handleDebounce]
  );

  const onChange = (e) => {
    const { name, value } = e.target;
    // IF “Likutis padalinyje” <= “Min” (numatytas), THEN “Max” (numatytas) - “Likutis padalinyje” --> “Oder (uzsakomas kiekis)
    const likutis = +store.amount || 0;
    setVariables((prev) => {
      const newObj = {
        ...prev,
        [name]: value,
      };
      if (!!parent_store?.order_qty) {
        newObj.order_qty = 0;
        return newObj
      }
      if (["min_qty", "max_qty"].includes(name)) {
        if (newObj.min_qty >= likutis && likutis <= newObj.max_qty) {
          newObj.order_qty = newObj.max_qty - likutis;
        } else {
          newObj.order_qty = 0;
        }
      }
      return newObj;
    });
    setHandleDebounce((prev) => prev + 1);
  };

  function handleKeyDown(event) {
    const invalidChars = ["+", "-", ",", ";", ".", "e"];
    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  }

  return (
    <TableUi.tr
      style={{
        backgroundColor: store.need_review ? "#FCF8F2" : "#FCFCFC",
        borderLeft: `5px solid ${store.need_review ? "#FE9C00" : "transparent"
          }`,
      }}
    >
      <TableUi.td className="p-left">
        <div className="d-flex">
          {store.is_clone && (
            <div className="mr-1">
              <Tooltip placement="top" title="Dublikatas">
                <div
                  className="text-sm text-white d-flex align-items-center justify-content-center"
                  style={{
                    background: "#FE9C00",
                    borderRadius: 40,
                    width: 20,
                    height: 20,
                  }}
                >
                  D
                </div>
              </Tooltip>
            </div>
          )}
          <div>{store.store_name}</div>
        </div>
      </TableUi.td>
      <TableUi.td>
        {+store.amount + +store.reserved} (R:{store.reserved};L:
        {store.amount})
      </TableUi.td>
      <TableUi.td>
        <TextInputModal
          style={{
            width: 100,
          }}
          min={0}
          disabled={store.is_clone}
          type="number"
          name="min_qty"
          value={variables.min_qty}
          onChange={onChange}
          onKeyDown={handleKeyDown}
        />
      </TableUi.td>
      <TableUi.td>
        <TextInputModal
          style={{
            width: 100,
          }}
          min={variables.min_qty || 0}
          type="number"
          disabled={store.is_clone}
          name="max_qty"
          value={variables.max_qty}
          onChange={onChange}
          warning={variables.max_qty < variables.min_qty ? " " : null}
          onKeyDown={handleKeyDown}
        />
      </TableUi.td>
      <TableUi.td>
        <TextInputModal
          disabled={true}
          style={{
            width: 100,
          }}
          type="number"
          name="order_qty"
          value={variables.order_qty}
          onChange={onChange}
          onKeyDown={handleKeyDown}
        />
        {!!parent_store?.order_qty &&
          <Tooltip placement="top" title="Pirminei yra priskirtas užs. kiekis">
            <IconExclamation className="ml-2" />
          </Tooltip>}
      </TableUi.td>
      <TableUi.td>
        {Array.isArray(suppliers) ? (
          <SelectInput
            style={{
              width: 150,
            }}
            options={suppliers.map((d) => ({
              value: d.id,
              label: d.name,
            }))}
            value={variables.supplier_id}
            onChange={(value) =>
              onChange({
                target: {
                  name: "supplier_id",
                  value,
                },
              })
            }
          />
        ) : (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              width: 150,
            }}
          >
            <Spin size="small" />
          </div>
        )}
      </TableUi.td>
      <TableUi.td>{store.sales}</TableUi.td>
      <TableUi.td>
        <RenderBadge number={store.turnover} />
      </TableUi.td>
      <TableUi.td>
        <SliderFreeStep number={store.turnover} />
      </TableUi.td>
      <TableUi.td>
        <div className="text-xs">
          {store.rivile_inner_document ? (
            store.rivile_inner_document.api_error ? (
              <>
                <p className="mb-0 text-danger">
                  {store.rivile_inner_document.api_error}
                </p>
              </>
            ) : (
              <>
                <p className="mb-0">
                  {store.rivile_inner_document.inner_document_number}
                </p>
                <p className="mb-0">
                  {store.rivile_inner_document.updated_at.substr(0, 10)}
                </p>
              </>
            )
          ) : (
            <p className="mb-0 text-sm text-silver">-</p>
          )}
        </div>
      </TableUi.td>
      <TableUi.td>
        {store.is_clone ? (
          <Tooltip placement="top" title="Trinti">
            <div
              className="cursor-pointer"
              onClick={() =>
                AntdConfirm({
                  onOk: () =>
                    dispatch(deleteCommerceStore(product_id, store.id)),
                })
              }
            >
              <IconDelete className="text-silver text-xl" />
            </div>
          </Tooltip>
        ) : (
          <Tooltip placement="top" title="Dublikuoti">
            <div
              className="cursor-pointer"
              onClick={() => {
                dispatch(
                  duplicateCommerceStore(product_id, {
                    ...store,
                    ...variables,
                    is_clone: true,
                  })
                );
              }}
            >
              <IconDuplicate />
            </div>
          </Tooltip>
        )}
      </TableUi.td>
    </TableUi.tr>
  );
};

const SliderFreeStep = ({ number }) => {
  const levels = [
    { value: "Mažinti", color: "#E61D01" },
    { value: "Gerai", color: "#FFEB3B" },
    { value: "Didinti", color: "#10CA00" },
  ];
  const active = useMemo(() => {
    if (number == null) return "none";
    if (number <= 60) return levels[2].value;
    if (number <= 105) return levels[1].value;
    return levels[0].value;
  }, [number]);
  return (
    <>
      <div className="position-relative">
        <div
          className="position-absolute"
          style={{
            background:
              "linear-gradient(90deg, #E61D01 -2.44%, #FFEB3B 46.19%, #10CA00 103.66%)",
            width: 200,
            // width: "100%",
            height: 2,
            top: 9,
          }}
        ></div>
        <div
          className="d-flex justify-content-between align-items-center"
          style={{
            width: 200,
          }}
        >
          {levels.map((level) => (
            <div
              key={level.value}
              className="d-flex flex-column justify-content-between align-items-center"
            >
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  height: 18,
                }}
              >
                <div
                  style={{
                    zIndex: 2,
                    width: active == level.value ? 18 : 12,
                    height: active == level.value ? 18 : 12,
                    background: active == level.value ? level.color : "#C2C2C2",
                    border: `${active == level.value ? 2 : 1}px solid #FFFFFF`,
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.18)",
                    borderRadius: "50%",
                  }}
                ></div>
              </div>
              <div
                className="text-xs"
                style={{
                  color: active == level.value ? "#212121" : "#D9D9D9",
                  textDecorationLine:
                    active == level.value ? "underline" : null,
                }}
              >
                {level.value}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const RenderBadge = ({ number }) => {
  const color = useMemo(() => {
    if (number == null) return "new";
    if (number <= 60) return "#4CAF50";
    if (number <= 105) return "#FFEB3B";
    return "#EF5050";
  }, [number]);
  if (color == "new") {
    return (
      <Tooltip placement="top" title="Nėra pardavimų">
        <div className="text-center">
          <IconNewProduct />
        </div>
      </Tooltip>
    );
  }
  return (
    <div className="text-center">
      <span
        className="badge badge-pill py-1 px-2 text-white"
        style={{
          backgroundColor: color,
        }}
      >
        {number}
      </span>
    </div>
  );
};
