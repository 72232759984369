import React from "react";
import { connect } from "react-redux";
import InputText from "../../orders/accepted_orders/InputFilter";
import Container from "../../Layout/Container";
import ContentBlock from "../../Layout/ContentBlock";
import FilterMenu from "../../Layout/FilterMenu";
import TableFilterBox from "../../common/TableFilterBox";
import TableColumnParticipants from "./items/TableColumnParticipants";
import ContentValidation from "../../common/ContentValidation";
import PaginationComp from "../../Dashboard/items/Pagination";
import dalyviaiSvg from "../../../../assets/images/dalyviai.svg";
import {
  getLotteryPlayers,
  deleteLotteryPlayers,
  clearErrors,
} from "../../../redux/Actions/lotteryAction";
import Axios from "axios";
import moment from "moment";

class Participans extends React.Component {
  constructor() {
    super();
    this.state = {
      requiredId: 0,
      page: 1,
      pageCount: 100,
      paramsFilter: "",
      filter: {
        name: "",
        email: "",
        phone: "",
        code: "",
        prize: "",
        project: "",
      },
    };
  }

  componentDidMount() {
    this.reloadData(this.state.paramsFilter);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.reloadData(this.state.paramsFilter);
    }
  }

  reloadData = (paramsFilter) => {
    this.props.getLotteryPlayers(
      this.state.page,
      this.state.pageCount,
      paramsFilter
    );
  };

  onAction = () => {
    // Axios.get('api/v1/lottery_players/download')
    Axios({
      url: `/api/v1/lottery_players/download`,
      method: "get",
      responseType: "blob", // important
      headers: { Accept: "application/vnd.ms-excel" },
    }).then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute(
        "download",
        `${moment().format("YYYY/MM/DD")}_DALYVIAI.xlsx`
      ); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  };

  delayOnFilter = () => {
    if (this.props.loading) {
      return setTimeout(() => {
        return this.delayOnFilter();
      }, 1000);
    }
    this.onFilter();
  };

  onFilter = () => {
    if (this.props.loading) {
      return this.delayOnFilter();
    }
    let filterParams = [];
    if (this.state.filter.name.length > 0)
      filterParams.push(`&q[name_i_cont_any]=${this.state.filter.name}`);

    if (this.state.filter.email.length > 0)
      filterParams.push(`&q[email_i_cont_any]=${this.state.filter.email}`);

    if (this.state.filter.phone.length > 0) {
      let phoneNumber = this.state.filter.phone;
      if (this.state.filter.phone.search(/[\[\]?*+|{}\\()@.\n\r]/) != -1) {
        phoneNumber = phoneNumber.split("+").join("");
      }
      filterParams.push(`&q[phone_i_cont_any]=${phoneNumber}`);
    }

    if (this.state.filter.code.length > 0)
      filterParams.push(`&q[code_i_cont_any]=${this.state.filter.code}`);

    if (this.state.filter.email.length > 0)
      filterParams.push(`&q[email_i_cont_any]=${this.state.filter.email}`);

    if (this.state.filter.prize.length > 0)
      filterParams.push(
        `&q[lottery_ticket_prize_i_cont_any]=${this.state.filter.prize}`
      );

    if (this.state.filter.project.length > 0)
      filterParams.push(
        `&q[lottery_ticket_project_i_cont_any]=${this.state.filter.project}`
      );

    this.setState(
      {
        paramsFilter: filterParams.join(""),
        page: 1
      },
      () => this.reloadData(filterParams.join(""))
    );
  };

  filterChange = (e) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [e.target.name]: e.target.value,
      },
    });
  };

  setPagination = (page) => {
    this.setState({
      page,
    });
  };
  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };
  handleKeyDown = (e) => {
    if (e.key == "Enter") {
      this.onFilter();
    }
  };

  clearFilter = () => {
    this.setState(
      {
        filter: {
          name: "",
          email: "",
          phone: "",
          code: "",
          prize: "",
          project: "",
        },
      },
      () => this.onFilter()
    );
  };

  render() {
    const { errors, players } = this.props;
    const requiredItem = this.state.requiredId;
    let modalData = players[requiredItem] ? players[requiredItem] : [];
    return (
      <>
        <Container
          location={{
            locationName: "Dalyviai",
            locationUrl: this.props.match.url,
          }}
        >
          <>
            <ContentBlock>
              <FilterMenu
                length={`${this.props.totals || 0} / ${
                  this.props.players.length || 0
                }`}
                onChangeCountPage={this.setPageCount}
                countPage={this.state.pageCount}
                text="dalyvių"
                text2="Dalyvių"
                icon={dalyviaiSvg}
              />
            </ContentBlock>
            <ContentBlock>
              <TableFilterBox
                // filter={true}
                add={this.onAction}
                addText={"Eksportuoti dalyvius"}
                // onFilter={this.onFilter}
                modalId={"#tableNew"}
                clearFilter={() => this.clearFilter()}
              >
                <div className="px-1">
                  <InputText
                    width={"0.75rem"}
                    number="1"
                    name="name"
                    placeholder="Vardas"
                    onKeyDown={this.handleKeyDown}
                    value={this.state.filter.name}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
                <div className="px-1">
                  <InputText
                    width={"0.75rem"}
                    number="1"
                    name="email"
                    placeholder="El. paštas"
                    onKeyDown={this.handleKeyDown}
                    value={this.state.filter.email}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
                <div className="px-1">
                  <InputText
                    width={"0.75rem"}
                    number="1"
                    name="phone"
                    placeholder="Telefono numeris"
                    onKeyDown={this.handleKeyDown}
                    value={this.state.filter.phone}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
                <div className="px-1">
                  <InputText
                    width={"0.75rem"}
                    number="1"
                    name="code"
                    placeholder="Žaidimo kodas"
                    onKeyDown={this.handleKeyDown}
                    value={this.state.filter.code}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
                <div className="px-1">
                  <InputText
                    width={"0.75rem"}
                    number="1"
                    name="prize"
                    placeholder="Prizas"
                    onKeyDown={this.handleKeyDown}
                    value={this.state.filter.prize}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
                <div className="px-1">
                  <InputText
                    width={"0.75rem"}
                    number="1"
                    name="project"
                    placeholder="Projektas"
                    onKeyDown={this.handleKeyDown}
                    value={this.state.filter.project}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
              </TableFilterBox>
              <div>
                <ContentValidation
                  loader={this.props.loading}
                  array={this.props.players}
                  alertId_s="userAlert-s"
                  alertId_e="userAlert-e"
                >
                  <div className="table-responsive">
                    <table className="table table-sm table-hover mt-3">
                      <thead>
                        <tr className="text-muted">
                          <th className="text-black"></th>
                          <th className="text-black">Vardas</th>
                          <th className="text-black">El. paštas</th>
                          <th className="text-black">Telefono numeris</th>
                          <th className="text-black">Žaidimo kodas</th>
                          <th className="text-black">Prizas</th>
                          <th className="text-black">Projektas</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.players.map((item, index) => {
                          return (
                            <TableColumnParticipants
                              key={index}
                              index={index}
                              id={item.id}
                              item={item}
                              stores={this.state.stores}
                              onEdit={this.onAction}
                              onDelete={this.props.deleteLotteryPlayers}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                    <PaginationComp
                      setPagination={this.setPagination}
                      page={this.state.page}
                      totals={this.props.totals}
                      pageCount={this.state.pageCount}
                    />
                  </div>
                </ContentValidation>
              </div>
            </ContentBlock>
          </>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  players: state.lottery.players,
  totals: state.lottery.totals_players,
  loading: state.lottery.loading_tickets,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getLotteryPlayers,
  deleteLotteryPlayers,
  clearErrors,
})(Participans);
