import React, { useState, useEffect } from "react";
import { Input, DatePicker } from "antd";
import { useDispatch } from "react-redux";
import { SelectForm } from "../../../../common/Buttons";
import moment from "moment";
import { postComments } from "../../../../../redux/Actions/commentAction";
import { FlopppyButton } from "../../../ButtonWithLoading";
import { getDateFormat } from "../../../../../helpers/Utils";

const { TextArea } = Input;

const SubmitComment = (props) => {
  const dispatch = useDispatch();
  const [InputValue, setInput] = useState("");
  const [Select, setSelect] = useState(undefined);
  const [DateInput, setDateInput] = useState(undefined);
  const [ErrorText, setErrorText] = useState(false);
  const [Options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  let dateShow = props.options.find((item) => item.value == Select)
    ? props.options.find((item) => item.value == Select).show_date
    : false;

useEffect(() => {
  let options = [];
  props.options.forEach((e) => {
    if (e.comment_type == props.type) {
      if (e.name == "Perskambinti klientui") {
        options.splice(1, 0, e);
      } else {
        options.push(e);
      }
    }
  })
  setOptions(options)
}, [props.options])


  const onSubmit = (e) => {
    setLoading(true);
    let dateTime = dateShow ? DateInput : false;
    if (dateShow && DateInput === undefined) {
      setLoading(false);
      return setErrorText("Iveskitę datą");
    }
    if (Select == undefined) {
      setLoading(false);
      return setErrorText("Pasirinkite komentaro būseną.");
    }
    if (InputValue == "") {
      setLoading(false);
      return setErrorText("Įrašykite komentarą");
    }

    let variablesBody = {
      order_id: props.orderId,
      comment: {
        comment_state_id: Select,
        body: InputValue.trim(),
        comment_type: props.type,
      },
    };
    if (dateTime) {
      variablesBody = {
        order_id: props.orderId,
        comment: {
          comment_state_id: Select,
          body: InputValue.trim(),
          comment_type: props.type,
          date: moment(DateInput),
        },
      };
    }

    return dispatch(postComments(variablesBody)).then((res) => {
      if (res.success) {
        setInput("");
        setSelect(undefined);
        setDateInput(undefined);
        setErrorText(false);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <>
      <div>
        <div className="mt-2">
          <SelectForm
            onlyPlaceholder="Komentarų būsenos"
            options={Options}
            value={Select}
            onChange={(e) => setSelect(e)}
          />
        </div>
      </div>
      <div className="my-2">
        {Select !== undefined && dateShow && (
          <DatePicker
            placeholder="Data"
            style={{ width: "100%" }}
            disabledDate={(d) =>
              !d || d.isBefore(moment().format("YYYY-MM-DD"))
            }
            value={getDateFormat(DateInput)}
            onChange={(e, arg) => setDateInput(arg)}
          />
        )}
      </div>
      <TextArea
        className="mb-2"
        placeholder="Komentaras"
        value={InputValue}
        onChange={(e) => setInput(e.target.value)}
      />
      <div className="d-flex justify-content-between">
        <div className="text-danger">{ErrorText}</div>

        <FlopppyButton loading={loading} onClick={(e) => onSubmit(e)} />
      </div>
    </>
  );
};

export default SubmitComment;
