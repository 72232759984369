import React from "react";
import InputForm from "../../../common/InputForm";
import BasicModalTickets from "../../../Layout/ModalToChangeTickets";
import { SelectForm } from "../../../common/Buttons";
import { OrderTableFooterButton } from "../../../common/Buttons";

class TicketEditModal extends React.Component {
  constructor() {
    super();
    this.state = {
      id: 0,
      code: "",
      prize: "",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.objectData.id !== this.state.id) {
      let item = nextProps.objectData;
      this.setState({
        id: item.id || 0,
        code: item.code || "",
        prize: item.prize || "",
        action: "edit",
      });
      this.removeErrors(nextProps.errors);
    }
  }

  removeErrors = (errors) => {
    if (Object.keys(errors).length > 0) {
      this.props.clearErrors();
    }
  };

  handleChange = (e, select) => {
    if (select) {
      this.setState({
        [select]: e,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const variables = {
      lottery_ticket: {
        prize: this.state.prize,
        code: this.state.code,
      },
    };

    this.props.putLotteryTicket(this.state.id, variables);
  };

  render() {
    return (
      <BasicModalTickets
        handleSave={(e) => this.onSubmit(e)}
        status="tableEdit"
        text={"Bilieto peržiura"}
        close={'closeModal0'}
      >
        <InputForm
          type="text"
          placeholder="Žaidimo kodas"
          name="code"
          value={this.state.code}
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Prizas"
          name="prize"
          value={this.state.prize}
          onChange={(e) => this.handleChange(e)}
        />
        <div className="d-block d-flex justify-content-center">
          <OrderTableFooterButton
            id={"ticketEdit"}
            color="#ee3624"
            text="Išsaugoti"
            handleSave={(e) => this.onSubmit(e)}
          />
        </div>
      </BasicModalTickets>
    );
  }
}

export default TicketEditModal;
