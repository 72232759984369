import React from "react";
import { Pagination } from "antd";

export default function PaginationComp(props) {
  const changePage = (selectedPage) => {
    props.setPagination(selectedPage);
  };
  return (
    <ul className="pagination d-flex justify-content-center">
      <Pagination
        simple
        current={props.page}
        pageSize={props.pageCount}
        total={props.totals}
        onChange={(e) => changePage(e)}
      />
    </ul>
  );
}
