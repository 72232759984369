import React from "react";
import { FaEye, FaDownload } from "react-icons/fa";
import { GoPrimitiveDot } from "react-icons/go";
import moment from "moment";
import Axios from "axios";
import { CheckBoxInput } from "../../../common/Buttons";

function TableColumnManifest(props) {
  const downloadButton = () => {
    Axios({
      url: `/api/v1/manifests/${props.item.id}/download`,
      method: "POST",
      responseType: "blob", // important
      headers: { Accept: "application/vnd.ms-excel" },
    }).then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute(
        "download",
        `${
          props.item.carrier + "_manifest_" + props.item.id
        }.pdf`
      ); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  };

  const downloadLabelsButton = () => {
    Axios({
      url: `/api/v1/manifests/${props.item.id}/download_labels`,
      method: "POST",
      responseType: "blob", // important
      headers: { Accept: "application/vnd.ms-excel" },
    }).then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute(
        "download",
        `${
          props.item.carrier + "_" + props.item.id
        }.pdf`
      ); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  };

  const countPackets = (array) => {
    let total = array.reduce((acc, cur) => acc + +cur.packets, 0);
    return total;
  };

  const countWeight = (array) => {
    let total = array.reduce((acc, cur) => acc + +cur.weight, 0);
    return total;
  };

  const ordersId = (array) => {
    return array.map((item, i) => (
      <span key={item.id}>
        <a className="text-primary" onClick={() => props.orderModal(item.order.number || item.order.id_order || `T${item.order.id}`, item.order.id,   item.service_order_type)}>
          {item.order.number || item.order.id_order || `T${item.order.id}`}
        </a>
        {array.length - 1 == i ? "" : ", "}
      </span>
    ));
  };
  return (
    <tr className="align-middle tableColumn">
      {/* <td className="align-middle text-center">
        <button
          className="btn btn-sm"
          data-toggle="modal"
          data-target="#omnivaManifestModal"
          onClick={() => props.onAction(props.index, props.item.id)}
        >
          <FaEye className="text-info" />
        </button>
      </td> */}
      <td className="align-middle">
        <div className="d-flex">
          <CheckBoxInput
            type="checkbox"
            color="#f7951e"
            checked={props.checkedArray.includes(props.item.id)}
            onChange={() => props.handleCheckboxChange(props.item.id)}
          />
        </div>
      </td>
      <td className="align-middle text-center">
        <GoPrimitiveDot
          style={
            props.item.closed_at !== null
              ? { color: "green" }
              : { color: "red" }
          }
        />
      </td>
      <td className="align-middle text-center">
        <GoPrimitiveDot
          style={
            props.item.called_at !== null
              ? { color: "green" }
              : { color: "red" }
          }
        />
      </td>
      <td className="align-middle">
        {moment(props.item.created_at).format("YYYY-MM-DD HH:mm")}
      </td>
      <td className="align-middle">{props.item.pickup_address.code}</td>
      <td className="align-middle">{ordersId(props.item.shipments)}</td>
      <td className="align-middle">{props.item.shipments.length}</td>
      <td className="align-middle">{countPackets(props.item.shipments)}</td>
      <td className="align-middle">{countWeight(props.item.shipments)}</td>
      <td className="align-middle">
        {props.item.shipments.length > 0 && (
          <button
            className="btn btn-primary btn-sm"
            onClick={() => downloadLabelsButton()}
          >
            <FaDownload className="mr-2" title="Parsisiųsti" />
            Parsisiųsti
          </button>
        )}
      </td>
      <td className="align-middle">
        {props.item.shipments.length > 0 && (
          <button
            className="btn btn-primary btn-sm"
            onClick={() => downloadButton()}
          >
            <FaDownload className="mr-2" title="Parsisiųsti"  />
            Parsisiųsti
          </button>
        )}
      </td>
    </tr>
  );
}

export default TableColumnManifest;
