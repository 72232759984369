import React from "react";
import { Link } from "react-router-dom";
import { FaHome, FaUserCircle, FaGoogle, FaTimes } from "react-icons/fa";
import no_avatar_png from "../../../assets/images/no_avatar.png";

const UserMenu = (props) => {
  return (
    <div className="user-menu bg-white border-left"
    style={{
      zIndex: 59999999999999999999,
    }}
    >
      <div className="logo">
        <img src={no_avatar_png} alt="user" />
        <div className="dismiss float-right">
          <FaTimes
            className="btn-primary"
            onClick={() =>
              document.querySelector(".user-menu").classList.toggle("show-nav")
            }
          />
        </div>
      </div>
      <span className="px-2 text-primary d-block text-center">
        {props.user.firstname || props.user.email}
      </span>
      <ul className="userMenu">
        <li>
          <Link to={"/"}>
            <FaHome className="mr-2" /> Pagrindinis
          </Link>
        </li>
        <li>
          <Link to={"/profile"}>
            <FaUserCircle className="mr-2" /> Profilis
          </Link>
        </li>
        <li>
          <button
            className="btn"
            style={{ padding: "0" }}
            onClick={() => props.logout()}
          >
            <FaGoogle className="mr-2" />
            Atsijungti
          </button>
        </li>
      </ul>
    </div>
  );
};

export default UserMenu;
