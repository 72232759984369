import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { IconBack } from "./Icons";

export default function NavBar(props) {
  return (
    <nav className="navbar navbar-expand-lg navbar-light mx-2">
      <span
        className="navbar-toggler-icon"
        id="menu-toggle"
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          e.preventDefault();
          $("#wrapper").toggleClass("toggled");

          const collapseSetup =
            document.getElementById("wrapper").className === "d-flex toggled";

          localStorage.setItem("collapse", collapseSetup);
        }}
      ></span>

      <div className="pl-3">
        <h4 className="location-text d-flex align-items-center">
          {props.goBack && (
            <IconBack className="mr-2 cursor-pointer" onClick={props.goBack} />
          )}
          {props.location.locationName}
        </h4>
        <h5 className="location-subText">
          <Link to="/">Pagrindinis</Link>
          {props.breadcrumbs ? (
            props.breadcrumbs.map((d) => (
              <React.Fragment key={`${d.label}_${d.value}`}>
                {">"}
                {d.onClick ? (
                  <a href="#" onClick={d.onClick}>
                    {d.label}
                  </a>
                ) : (
                  <Link to={d.value}>{d.label}</Link>
                )}
              </React.Fragment>
            ))
          ) : (
            <>
              {">"}
              <Link to={props.location.locationUrl}>
                {props.location.locationName}
              </Link>
            </>
          )}
        </h5>
      </div>
      <button
        className="navbar-toggler border-0"
        type="button"
        onClick={() =>
          document.querySelector(".user-menu").classList.toggle("show-nav")
        }
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse">
        <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
          <div className="dropdown show">
            <a
              className="btn dropdown-toggle"
              href="#"
              role="button"
              id="dropdownNavBar"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {props.user.firstname
                ? `${props.user.firstname} ${
                    props.user.lastname
                      ? props.user.lastname.substr(0, 1) + "."
                      : ""
                  }`
                : props.user.email}
            </a>

            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdownNavBar"
            >
              <button
                className="btn dropdown-item"
                onClick={() => props.logout()}
              >
                Atsijungti
              </button>
            </div>
          </div>
        </ul>
      </div>
    </nav>
  );
}
