import React, { forwardRef, useImperativeHandle } from "react";
import ReactDOM from "react-dom";
import ModalSpecial from "../../../Layout/Modals/ModalSpecial";
import OrderShowModal from "./orderShowModal";
import { getStatusesOptions } from "../../../../redux/Actions/statusesAction";
import { getSuppliersOption } from "../../../../redux/Actions/supplierAction";
import { getPickupAddressesOption } from "../../../../redux/Actions/pickupAddressAction";
import { getCommentStateOption } from "../../../../redux/Actions/comment_StateAction";
import { addOverFlow, removeOverFlow } from "../../../../helpers/modalFunc";
import ServiceModalComponent from "../../../ServicePage/Items/ServiceForms/ServiceModalComponent";
import { getParcelTerminalsOption } from "../../../../redux/Actions/parcelTerminalsAction";


const RegularOrderChildren = forwardRef((props, ref) => {
  const [display, setDisplay] = React.useState("first");
  const [parcelTerminals, setParcelTerminals] = React.useState({});
  const [PickupAddressData, setPickupAddressData] = React.useState([]);
  const [CommentsState, setCommentsState] = React.useState([]);
  const [AvailableStatus, setAvailableStatus] = React.useState([]);
  useImperativeHandle(ref, () => {
    return {
      openModal: (active) => open(active),
      close: () => close(),
      active: display,
    };
  });

  const open = (active) => {
    if (active !== "Service") {
      requestOfOptions();
    }
    addOverFlow();
    setDisplay(active);
  };

  const close = () => {
    removeOverFlow();
    setDisplay(false);
  };

  const requestOfOptions = () => {
    getParcelTerminalsOption().then((res) => {
      setParcelTerminals(res);
    });
 
      getPickupAddressesOption().then((response) => {
        if (response.data) {
          let newPickupAdress = [];
          response.data.data.forEach((address) => {
            let newAddressObj = {
              ...address,
              value: address.id,
              label: `${address.company} - ${address.address} - ${address.city} `,
            };
            return newPickupAdress.push(newAddressObj);
          });
          setPickupAddressData(newPickupAdress);
        }
      });

      getCommentStateOption().then((res) => {
        if (res.data) {
          let commentAll = [];
          let comment_ka = [];
          let comment_management = [];
          let comment_logistic = [];

          res.data.forEach((item) => {
            let newObj = {
              label: item.name,
              name: item.name,
              value: item.id,
              color: item.color,
              ordering: item.ordering,
              identifier: item.identifier,
              comment_type: item.comment_type,
              show_date: item.show_date,
            };
            commentAll.push(newObj);
            let cmst = item.comment_type;
            if (cmst == "comment_ka") {
              comment_ka.push(item);
            } else if (cmst == "comment_management") {
              comment_management.push(item);
            } else {
              comment_logistic.push(item);
            }
          });
          setCommentsState(commentAll);
        }
      });

      getStatusesOptions().then((response) => {
        if (response.data) {
          let availableStatusArray = [];
          response.data.forEach((item) => {
            const findIndex = availableStatusArray.findIndex(
              (value) => value.label == item.title
            );
            if (findIndex == -1) {
              availableStatusArray.push({
                value: item.id,
                label: item.title,
              });
            }
          });

          setAvailableStatus(availableStatusArray);
        }
      });
   

    console.count("Request times; ");
  };

  if (display == "OrderShowModal") {
    const tilteSpan = (
      <span
        style={{
          fontSize: "1.2rem",
          lineHeight: "27px",
          color: "#000000",
          fontWeight: "700",
        }}
      >
        Užsakymas <span className="text-orange">#</span>
        {props.requiredOrderId}
        {/* {this.state.id_order} */}
      </span>
    );

    return ReactDOM.createPortal(
      <ModalSpecial
        text={tilteSpan}
        handleSave={() => {}}
        onClose={() => close()}
      >
        <OrderShowModal
          avalaibleStatus={AvailableStatus}
          pickupAddress={PickupAddressData}
          comments_state={CommentsState}
          parcel_terminals={parcelTerminals}

        />
      </ModalSpecial>,
      document.getElementById("modal-roots")
    );
  } else if (display == "ServiceShowModal") {
    const tilteSpan = (
      <span
        style={{
          fontSize: "1.2rem",
          lineHeight: "27px",
          color: "#000000",
          fontWeight: "700",
        }}
      >
        <span className="text-black">RMA</span>{" "}
        <span className="text-orange">#</span>
        <span className="text-black">R{+props.requiredOrderId + 100}</span>
      </span>
    );

    return ReactDOM.createPortal(
      <ModalSpecial
        text={tilteSpan}
        handleSave={() => {}}
        onClose={() => close()}
      >
        <ServiceModalComponent id={props.requiredOrderId} />
      </ModalSpecial>,
      document.getElementById("modal-roots")
    );
  }

  return null;
});

export default RegularOrderChildren;
