import React from "react";
import InputForm from "../../common/InputForm";
import BasicModalOrder from "../../Layout/BasicModalOrder";
import TextAreaFieldGroup from "../../common/TextAreaFieldGroup";
import { MainBlock } from "../../common/Buttons";
import TableItemsRivile from "./TableItemsRivile";
import { Button1, Button2 } from "../../common/Buttons";
import { Input } from "antd";
const { TextArea } = Input;
import Axios from "axios";
import { connect } from "react-redux";
import {
  postComment,
  changeStatusAction,
  changeStatusToExported,
  forceStatusToExported,
  changeMismatchedAgatas
} from "../../../redux/Actions/rivileAction";
import Comments from "./Comments";
// import DepartmentComponent from "./DepartmentComponent";
import StoreComponent from "./StoreComponent";

class RivileModal extends React.Component {
  constructor() {
    super();
    this.state = {
      preview_comments: false,
      preview_items: true,
      preview_activity: false,
      requiredId: 0,
      addComment: false,
      comment_form: {
        comment: ""
      },
    };
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    if(this.state.requiredId !== nextProps.requiredId) {
      this.setState({
        preview_items: true,
        requiredId: nextProps.requiredId,
        comment_form: {
          comment: ""
        },
      })
    }
  }

  openBlock = (block) => {
    this.setState({
      [block]: !this.state[block],
    });
  };

  onChangeComment = (e) => {
    this.setState({
      comment_form: {
        ...this.state.comment_form,
        [e.target.name]: e.target.value,
      },
    });
  };

  exportInvoice = () => {
    let array = [this.props.rivileItem.id]
    let url = "/api/v1/rivile_invoices/state_to_exporting";
    Axios({
      url: url,
      method: "POST",
      data: {
        checked: array,
      },
    }).then((response) => {
      if(response.data && response.data.success == true && response.data.mismatched.length == 0){
        this.props.changeStatusAction(response.data.checked, true)
      } else {
        this.props.changeMismatchedAgatas(response.data.mismatched, response.data.notification)
      }
    });
  };

  importInvoice = () => {
    let array = [this.props.rivileItem.id]
    let url = "/api/v1/rivile_invoices/import_selected";
    Axios({
      url: url,
      method: "POST",
      data: {
        checked: array,
      },
    }).then((response) => {
      if(response.data && response.data.success == true){
        this.props.changeStatusToExported(response.data.exported, true, response.data.error)
      }
    });
  };


  onSubmit() {
    this.props.postComment(this.props.rivileItem.id, this.state.comment_form.comment)
    this.setState({
      comment_form: {
        comment: ""
      }
    })

  }

  forceExportInvoice = () => {
    let array = [this.props.rivileItem.id]
    let url = "/api/v1/rivile_invoices/force_export";
    if (confirm("Ar tikrai norite žymėti kaip eksportuotą?")) {
      Axios({
        url: url,
        method: "POST",
        data: {
          checked: array,
        },
      }).then((response) => {
        if(response.data && response.data.success == true){
          this.props.forceStatusToExported(response.data.checked, true)
        }
      });
    }
  };

  render() {
    const allowToEditStore = this.props.url == '/rivile-pirkimai' && this.props.auth.user_role?.identifier !== 'big_box_user';

    return (
      <BasicModalOrder
        handleSave={(e) => this.onSubmit(e)}
        status="tableEdit"
        text={"VAŽTARAŠTIS " + this.props.rivileItem.document_number}
        notes={
          <StoreComponent accessEdit={allowToEditStore} rivileInvoiceId={this.props.rivileItem.id} store={this.props.rivileItem.notes} stores={this.props.stores} />
        }
        // notes={<DepartmentComponent
        //   accessEdit={this.props.auth.user_role?.identifier !== 'big_box_user'}
        //   rivileInvoiceId={this.props.rivileItem.id}
        //   notes={this.props.rivileItem.notes}
        //   store={this.props.rivileItem.store}
        //   url={this.props.url}
        //   stores={this.props.stores}
        //   />}
        color="text-black"
        export={this.props.rivileItem.status}
        exportInvoice={() => this.exportInvoice()}
        importInvoice={() => this.importInvoice()}
        forceExportInvoice={() => this.forceExportInvoice()}
        invoiceId={this.props.rivileItem.id}
        url={this.props.url}
      >
        <div className="row">
          <div className="col-md-6">
            <MainBlock
              color={"#06c76d"}
              action={this.state.preview_comments}
              onChange={() => this.openBlock("preview_comments")}
            >
              Komentarai
            </MainBlock>
            {this.state.preview_comments && (
              <>
              {!(this.props.url  == "/rivile-pardavimai"  && (this.props.rivileItem.status == "exporting" || this.props.rivileItem.status == "exported")) && (
                <>
                  {!(this.props.rivileItem.status == "exported") && (
                    <div style={{ position: "relative", top: "5px" }}>
                      <Button1
                        style={{
                          position: "absolute",
                          top: "-16px",
                          left: "50%",
                          transform: "translate(-50%, 0px)",
                        }}
                        text={this.state.addComment ? "Sutraukti" : "Pridėti komentarą"}
                        option={this.state.addComment ? "minus" : "plus"}
                        onClick={() => this.openBlock("addComment")}
                      />
                      <hr />
                    </div>
                  )}
                </>
              )}
              {this.state.addComment && (
                <div className="row mt-4">
                  <div className="col-md-12">
                    <TextArea
                      placeholder="Komentaras"
                      name="comment"
                      value={this.state.comment_form.comment}
                      onChange={(e) => this.onChangeComment(e)}
                      rows={2}
                    />
                  </div>
                  <div className="col-md-12 text-center m-1">
                    <Button2
                      text={"Pridėti"}
                      onClick={() => this.onSubmit()}
                    />
                  </div>
                </div>
              )}
              {this.props.rivileItem.rivile_comments.map(
                (comment, index) => {
                  return (
                    <Comments
                      key={index}
                      comment={comment}
                    />
                  );
                }
              )}
              </>
            )}
          </div>
          <div className="col-md-6">
            <MainBlock
              color={"#00aaa0"}
              action={this.state.preview_activity}
              onChange={() => this.openBlock("preview_activity")}
            >
              Veiklos žurnalas
            </MainBlock>
          </div>
        </div>{" "}
        <br />
        <div className="row">
          <div className="col-md-12">
            <MainBlock
              action={this.state.preview_items}
              onChange={() => this.openBlock("preview_items")}
            >
              Prekės
            </MainBlock>
            {this.state.preview_items && (
              <div className="table-responsive">
                <table className="table table-sm table-hover mt-3">
                  <thead>
                    <tr className="text-muted">
                      {this.props.url == '/rivile-pardavimai' ? 
                        <>
                          <th className="text-black">
                            {this.props.auth.user_role.identifier == 'big_box_user' ? 'BB kodas' : 'TR kodas'}
                          </th>
                          <th className="text-black">
                            {this.props.auth.user_role.identifier == 'big_box_user' ? 'TR kodas' : 'BB kodas'}
                          </th>
                        </>
                      :
                        <>
                          <th className="text-black">
                            {this.props.auth.user_role.identifier == 'big_box_user' ? 'TR kodas' : 'BB kodas'}
                          </th>
                          <th className="text-black">
                            {this.props.auth.user_role.identifier == 'big_box_user' ? 'BB kodas' : 'TR kodas'}
                          </th>
                        </>
                      }
                      <th className="text-black">Prekės pavadinimas</th>
                      <th className="text-black">Kiekis</th>
                      <th className="text-black">Kaina be PVM(1vnt.)</th>
                      <th className="text-black">PVM</th>
                      <th className="text-black">Atvirkštinis PVM</th>
                      <th className="text-black"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.rivileItem.rivile_invoiced_items.map(
                      (item, index) => {
                        return (
                          <TableItemsRivile
                            key={index}
                            index={index}
                            id={item.id}
                            onEdit={this.onEdit}
                            item={item}
                            rivileItem={this.props.rivileItem}
                            url={this.props.url}
                            role={this.props.auth.user_role.identifier}
                          />
                        );
                      }
                    )}
                  </tbody>
                </table>
                <p className="float-right mt-2 mr-4">
                  <span className="text-black mr-3" style={{fontSize: "14px"}}><b>Suma:</b> {this.props.rivileItem.price_without_pvm}</span>
                  <span className="text-black" style={{fontSize: "14px"}}><b>PVM suma:</b> {this.props.rivileItem.pvm}</span>
                </p>
              </div>
            )}
          </div>
        </div>
      </BasicModalOrder>
    );
  }
}

const mapStateToProps = (state) => ({
  rivileComments: state.rivile.rivileComments,
});

export default connect(mapStateToProps, {
  changeStatusAction,
  postComment,
  changeStatusToExported,
  forceStatusToExported,
  changeMismatchedAgatas
})(RivileModal);
