import Axios from "axios";
import {
  GET_COMMENTS,
  // POST_COMMENTS,
  POST_COMMENTS_TO_ORDERS,
  DELETE_COMMENTS,
  UPDATE_COMMENTS,
  LOADING_COMMENTS,
  CLEAR_ERRORS,
  GET_ERRORS
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

const success = () => {
  if (!document.getElementById("comments-s")) return;
  document.getElementById("comments-s").style.display = "block";
  setTimeout(
    () =>
      (document.getElementById("comments-s").style.display = "none"),
    5000
  );
  document.getElementById('modalClose').click()

};

const error = () => {
  if(!document.getElementById("comments-e")) return;
  document.getElementById("comments-e").style.display = "block";
  setTimeout(
    () => (document.getElementById("comments-e").style.display = "none"),
    5000
  );
};

export const postComments = variables => dispatch => {
 return  Axios.post("/api/v1/comments", variables)
    .then(res => {
      dispatch({
        type: POST_COMMENTS_TO_ORDERS,
        payload: res.data
      });
      // dispatch({
      //   type: POST_COMMENTS,
      //   payload: res.data
      // });
      dispatch(clearErrors());
      success("post");
      return {success: true}
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      error();
      return {success: false}
    });
};

export const getComments = () => dispatch => {
  dispatch(setCommentsLoading());
  Axios.get("/api/v1/comments")
    .then(res => {
      dispatch({
        type: GET_COMMENTS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};


export const deleteComments = _id => dispatch => {
  if (confirm("Ar tikrai norite ištrinti ? ")) {
    Axios.delete(`/api/v1/comments/${_id}`)
      .then(res => {
        dispatch({
          type: DELETE_COMMENTS,
          payload: _id
        });
        success();
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
        error();
      });
  }
};

export const updateComments = (_id, body) => dispatch => {
  Axios.put(`/api/v1/comments/${_id}`, body)
    .then(res => {
      dispatch(clearErrors());
      dispatch({
        type: UPDATE_COMMENTS,
        payload: res.data,
        payload_id: _id
      });
      success("update");
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      error();
    });
};

export const setCommentsLoading = () => {
  return {
    type: LOADING_COMMENTS
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
