import React from "react";
import { ButtonEdit, EditElementClick } from "../../common/Buttons";

function TableColumnRivileService({ id, item, onDelete, index, onAction }) {
  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle">{item.id}</td>
      <td className="align-middle">
        <EditElementClick onEdit={() => onAction(index)} name={item.title} />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction(index)}
          name={item.city}
        />
      </td>
      <td className="align-middle">
        <EditElementClick onEdit={() => onAction(index)} name={item.address} />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction(index)}
          name={item.postal_code}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction(index)}
          name={item.phone}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction(index)}
          name={item.rivile_code}
        />
      </td> 
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction(index)}
          name={item.invoice_prefix}
        />
      </td>
        <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction(index)}
          name={item.order_prefix}
        />
      </td>
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonEdit onEdit={() => onAction(index)} />
      </td>
    </tr>
  );
}

export default TableColumnRivileService;
