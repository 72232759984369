import React from "react";
import InputForm from "../../common/InputForm";
import ModalToChange from "../../Layout/ModalToChange";
import { MdSecurity } from "react-icons/md";

class PickupUsersEditModal extends React.Component {
  constructor() {
    super();
    this.state = {
      id: 0,
      email: "",
      title: "",
      password: "",
      password2: "",
      action: "new",
      passwordFields: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.action === "new" && this.state.action === "edit") {
      this.setState({
        email: "",
        title: "",
        password: "",
        password2: "",
        action: "new",
        passwordFields: false,
      });
      this.removeErrors(nextProps.errors);
    } else if (
      nextProps.action === "edit" &&
      nextProps.objectData.id !== this.state.id
    ) {
      let item = nextProps.objectData;
      this.setState({
        id: item.id || 0,
        email: item.email || "",
        title: item.title || "",
        password: "",
        password2: "",
        action: "edit",
        passwordFields: false,
      });
      this.removeErrors(nextProps.errors);
    }
  }

  removeErrors = (errors) => {
    if (Object.keys(errors).length > 0) {
      this.props.clearErrors();
    }
  };

  handleChange = (e, select) => {
    if (select) {
      this.setState({
        [select]: e,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };
  handleSave = () => {
    this.props.saveModalDetails(this.state);
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.password.length !== 0 && this.state.password2.length !== 0) {
      const variables = {
        pickup_user: {
          email: this.state.email,
          title: this.state.title,
          password: this.state.password,
          password_confirmation: this.state.password2,
        },
      };
      if (this.props.action == "new") {
        this.props.postUser(variables);
      } else if (this.props.action === "edit") {
        this.props.updateUser(this.state.id, variables);
      }
      this.setState({
        passwordFields: false,
      });
    } else {
      this.setState({
        passwordFields: true,
      });
    }
  };

  removeAuthorization = async () => {
    if (confirm("Ar tikrai norite atnaujinti 2FA autorizaciją ?")) {
      await this.props.authRemove(this.state.id);
    }
  };

  render() {
    return (
      <ModalToChange
        handleSave={(e) => this.onSubmit(e)}
        status="tableEdit"
        text={this.props.action === "new" ? "Kurti naują" : "Redaguoti"}
        buttonText={this.props.action === "new" ? "Sukurti" : "Atnaujinti"}
      >
        <InputForm
          type="email"
          placeholder="Elektroninis paštas"
          name="email"
          value={this.state.email}
          error={
            this.props.errors.email &&
            this.props.errors.email.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />

        <InputForm
          type="text"
          placeholder="Vardas Pavardė"
          name="title"
          value={this.state.title}
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="password"
          placeholder="Slaptažodis"
          name="password"
          value={this.state.password}
          error={
            this.props.errors.password &&
            this.props.errors.password.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="password"
          placeholder="Pakartoti slaptažodį"
          name="password2"
          value={this.state.password2}
          error={
            this.props.errors.password_confirmation &&
            this.props.errors.password_confirmation.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        {this.state.passwordFields && (
          <div
            className="invalid-feedback d-block"
            style={{ fontSize: "1rem" }}
          >
            {"Slaptažodžių laukai privalomi!"}
          </div>
        )}

        {this.props.action === "edit" && (
          <button
            className="btn btn-danger mt-4"
            onClick={() => this.removeAuthorization()}
          >
            <span className="d-flex align-items-center">
              <MdSecurity className="mr-" />
              Atnaujinti 2FA autorizaciją
            </span>
          </button>
        )}
      </ModalToChange>
    );
  }
}

export default PickupUsersEditModal;
