import React, { useEffect } from "react";
import { addOverFlow, removeOverFlow } from "../../helpers/modalFunc";
import ReactDOM from "react-dom";
import ModalSpecial from "../Layout/Modals/ModalSpecial";
import EnergyLabelModal from "./EnergyLabelModal";

function EnergyLabelsModalWrapper({ modalOpt, closeModal, options }) {
  useEffect(() => {
    if (modalOpt) {
      addOverFlow();
    }
  }, []);

  const onClose = () => {
    removeOverFlow();
    closeModal();
  };
  console.log({ modalOpt, options });
  if (modalOpt.type == "create" || modalOpt.type == "update") {
    return ReactDOM.createPortal(
      <ModalSpecial
        text={`${modalOpt.type == "create" ? "Sukurti naują" : "Redaguoti"} etiketę`}
        style={{
          width: 330,
        }}
        onClose={onClose}
      >
        <EnergyLabelModal
          modalOpt={modalOpt}
          onClose={onClose}
          options={options}
        />
      </ModalSpecial>,
      document.getElementById("custom_modal")
    );
  }

  return null;
}

export default EnergyLabelsModalWrapper;
