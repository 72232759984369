import React from "react";
import { ButtonEdit, EditElementClick } from "../../common/Buttons";

function TableColumnRivilePaymentTypes({ item, onAction, index }) {
  return (
    <tr className="align-middle tableColumn">
      <td className="align-middle">{item.id}</td>
      <td className="align-middle">
        {" "}
        <EditElementClick onEdit={() => onAction(index)} name={item.title} />
      </td>
      <td className="align-middle">{item.module_name}</td>{" "}
      <td className="align-middle">{item.rivile_code}</td>
      <td className="align-middle">{item.client_code}</td>
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonEdit onEdit={() => onAction(index)} />
      </td>
    </tr>
  );
}

export default TableColumnRivilePaymentTypes;
