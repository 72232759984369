import React, { useState } from "react";
import { Tooltip } from "antd";
import { FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { removeServiceRowChanges } from "../../../../redux/Actions/Service/serviceAction";

export default function ServiceRowChangesTable({ item, orderId }) {
  const [showRemove, setShowRemove] = useState(false);

  const dispatch = useDispatch();
  return (
    <tr
      onMouseEnter={() => setShowRemove(true)}
      onMouseLeave={() => setShowRemove(false)}
    >
      <td className="residuesItem text-xs" colSpan={2}>
        {item.created_at.substr(0, 10)}
      </td>
      <td className="residuesItem text-xs">Keitimas </td>
      <td colSpan={2}>
        <Tooltip align={"top"} title={item.product?.title || ""}>
          {item.product?.identifier || ""}{" "}
        </Tooltip>
      </td>
      <td className="residuesItem text-xs" colSpan={2}>
        <div className="d-flex">
          {item.prime_cost}
          <span> €</span>
        </div>
      </td>
      <td className="residuesItem text-xs" colSpan={3}>
        {item.user_title}
      </td>
      {orderId && (
        <td className="d-flex justify-content-center align-items-center">
          <FaTrash
            style={{
              color: "orange",
              cursor: "pointer",
              float: "right",
              visibility: showRemove ? "visible" : "hidden",
            }}
            onClick={() =>
              dispatch(
                removeServiceRowChanges(item.id, item.service_row_id, orderId)
              )
            }
          />
        </td>
      )}
    </tr>
  );
}
