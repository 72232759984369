import React from "react";
import styled from "styled-components";
import { CheckBoxInput } from "../../../common/Buttons";
import InputForm from "../../../common/InputForm";
import { GiSave } from "react-icons/gi";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { FiUpload } from "react-icons/fi";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  // const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  // if (!isJpgOrPng) {
  //   message.error("You can only upload JPG/PNG file!");
  // }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  // return isJpgOrPng && isLt2M;
  return isLt2M;
}

class DocumentModalType extends React.Component {
  constructor() {
    super();
    this.state = {
      id: null,
      name: "",
      prefix: "",
      asset: "",
      active: false,
      loading: false,
      imageUrl: "",
      action: "new",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.action === "new" && this.state.action === "edit") {
      this.setState({
        name: "",
        prefix: "",
        asset: "",
        active: false,
        action: "new",
      });
      this.removeErrors(nextProps.errors);
    } else if (nextProps.action === "edit" && nextProps.modalData.id !== this.state.id) {
      let item = nextProps.modalData;
      this.setState({
        id: item.id || 0,
        name: item.name || "",
        prefix: item.prefix || "",
        asset: item.asset || "",
        active: item.active || false,
        action: "edit",
      });
      this.removeErrors(nextProps.errors);
    }
  }

  removeErrors = (errors) => {
    if (Object.keys(errors).length > 0) {
      this.props.clearErrors();
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onSubmit = (e) => {
    e.preventDefault();
    const variables = {
      name: this.state.name,
      prefix: this.state.prefix,
      asset: this.state.imageUrl,
      active: this.state.active,
    };
    if (this.props.action == "new") {
      this.props.postDocumentType(variables);
    } else if (this.props.action === "edit") {
      this.props.updateDocumentType(this.state.id, variables);
    }
  };

  handleChangeImageUpload = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  render() {
    const { imageUrl } = this.state;
    const uploadButton = (
      <div>
        {this.state.loading && <LoadingOutlined />}
        <div className="ant-upload-text">
          <FiUpload className="mr-2" /> Įkelkite failą čia.
        </div>
      </div>
    );

    return (
      <div
        className="modal fade"
        id="tabletype"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-edit" role="document">
          <div className="modal-content px-2 py-2" style={{ height: "100vh" }}>
            <div className="col">
              <span className="font-weight-bold d-block">
                <button
                  type="button"
                  id="closeDocumentType"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </span>
            </div>
            <div
              className="modal-body"
              style={{ height: "100vh", overflow: "auto" }}
            >
              <DocumentModal>
                <h3 className="modal_title">Dokumentų tipas</h3>

                <div className="modal_mark">
                  <h3 className="modal_mark_text">Informacija</h3>
                </div>

                <div className="modal_content">
                  <InputForm
                    type="text"
                    placeholder="Pavadinimas"
                    name="name"
                    value={this.state.name}
                    onChange={(e) => this.handleChange(e)}
                    error={
                      this.props.errors.name &&
                      this.props.errors.name.map((item) => `${item} `)
                    }
                  />
                  <InputForm
                    type="text"
                    placeholder="Prefiksas"
                    name="prefix"
                    value={this.state.prefix}
                    onChange={(e) => this.handleChange(e)}
                    error={
                      this.props.errors.prefix &&
                      this.props.errors.prefix.map((item) => `${item} `)
                    }
                  />
                  <div
                    style={{
                      width: "100%",
                      border: "1px dashed #d9d9d9",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "25px 15px",
                      borderRadius: "2px",
                      background: "#fafafa",
                      cursor: "pointer",
                      WebkitTransition: "border-color 0.3s",
                      transition: "border-color 0.3s",
                    }}
                  >
                    <Upload
                      name="document"
                      showUploadList={false}
                      customRequest={this.dummyRequest}
                      beforeUpload={beforeUpload}
                      onChange={this.handleChangeImageUpload}
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="document"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </div>

                  <div className="my-2 mx-2">
                    <CheckBoxInput
                      type="checkbox"
                      color="#f7951e"
                      text="Aktyvus"
                      checked={this.state.active}
                      onChange={(e) =>
                        this.setState({ active: e.target.checked })
                      }
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn modal_button_save"
                    onClick={(e) => this.onSubmit(e)}
                  >
                    <GiSave />{" "}
                    {this.props.action === "new"
                      ? "Sukurtį naują"
                      : "Išsaugoti"}
                  </button>
                </div>
              </DocumentModal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DocumentModalType;

const DocumentModal = styled.div`
  .modal_title {
    font-size: 25px;
    line-height: 14px;
    color: #222222;
    font-family: "Barlow";
    font-weight: bold;
    margin-bottom: 30px;
  }
  .modal_mark {
    display: flex;
    align-items: center;
    width: 100%;
    height: 35px;
    background-color: #f2f2f2;
    margin-bottom: 15px;

    &_text {
      margin: 0;
      margin-left: 20px;
      font-size: 15px;
      line-height: 14px;
      color: #000000;
      font-family: "Barlow";
      font-weight: 700;
    }
  }
  .modal_button_save {
    width: 159px;
    height: 30px;
    border-radius: 4px;
    background-color: #ee3624;

    font-size: 13px;
    line-height: 14px;
    color: #ffffff;
    font-family: "Barlow";
    font-weight: 700;
  }
`;
