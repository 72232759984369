import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckBoxInput,
  SelectForm,
} from "../../components/common/Buttons";
import ButtonSecondary from "../../components/common/ButtonSecondary";
import ContentValidation from "../../components/common/ContentValidation";
import InputSwitchComponent from "../../components/common/InputSwitchComponent";
import SwitchComp from "../../components/common/SwitchComp";
import TableFilterBox from "../../components/common/TableFilterBox";
import PaginationComp from "../../components/Dashboard/items/Pagination";
import Container from "../../components/Layout/Container";
import ContentBlock from "../../components/Layout/ContentBlock";
import CreateReportBlock from "../../components/report/CreateReportBlock";
import ReportModalWrapper from "../../components/report/ReportModalWrapper";
import TableColumnReport from "../../components/report/TableColumnReport";
import { onChangeUseState } from "../../helpers/GlobalFunctions";
import { antdMessageError, exportDocument } from "../../helpers/Utils";
import {
  deactivateReports,
  getReportOptions,
  getReports,
} from "../../redux/Actions/reportAction";
import { createConsumer } from "@rails/actioncable";
import { reportDepFullOptions, reportDepOptions, week_lt_short } from "../../helpers/GlobalOptions";
import { DropdownButton } from "../../components/global";

const filtersInitial = {
  method: "",
  active: false,
  report_type: undefined,
  department: undefined,
  category: undefined,
  period: "",
};
export default function ReportScreen({ match }) {
  const dispatch = useDispatch();
  const { loading, data, totals, options, current } = useSelector(
    (state) => state.custom
  );

  const [filters, setFilters] = useState(filtersInitial);
  const [page, setPage] = useState(1);
  const [perCount, setPerPage] = useState(50);
  const [createBlock, setCreateBlock] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [reportSelected, setReportSelected] = useState(false);
  const [newValue, setNewValue] = useState({
    id: false,
  });

  const startedPack = () => {
    dispatch(getReports(page, perCount, ""));
    setCreateBlock(false);
  };
  useEffect(() => {
    dispatch(getReportOptions());
    startedPack();
    const consumer = createConsumer();
    consumer.subscriptions.create(
      { channel: "ReportsChannel" },
      {
        connected() {
          console.log("Connected WS ReportsChannel");
        },
        received(data) {
          console.log("Data", data);
          if (typeof data.report !== "undefined") {
            setNewValue(data.report);
          }
        },
      }
    );

    const paramsAction = match.params?.action;
    if (paramsAction) {
      setReportSelected(paramsAction);
    }
  }, []);
  useEffect(() => {
    console.count("FETCH");
    dispatch(getReports(page, perCount, onFilter()));
  }, [
    filters.active,
    filters.method,
    filters.report_type,
    filters.department,
    filters.category,
    filters.period,
    perCount,
    page,
  ]);

  const onFilter = () => {
    const { active, method, report_type, department, category, period } =
      filters;

    let search_string = "";

    if (active) {
      search_string = search_string + "&q[active_true]=1";
    }
    if (method) {
      search_string = search_string + `&q[method_eq]=${method}`;
    }
    if (report_type) {
      search_string = search_string + `&q[report_type_eq]=${report_type}`;
    }
    if (department) {
      search_string = search_string + `&q[departments_raw_cont]=${department}`;
    }
    if (category) {
      search_string = search_string + `&q[family_id_eq]=${category}`;
    }
    if (!method || method == "auto") {
      if (period) {
        search_string = search_string + `&q[period_eq]=${period}`;
      }
    }
    setSelected([]);
    setSelectedAll(false);
    setPage(1);
    return search_string;
  };

  const downloadDocumentsBySelected = (ids) => {
    if (ids.length == 0) return antdMessageError("Nepasirinktos ataskaitos");
    data
      .filter((d) => ids.includes(d.id))
      .forEach((d) => {
        if (["residues", "all"].includes(d.report_type)) {
          exportDocument(
            `/api/v1/reports/${d.id}/download_residues`,
            `download_${d.id}.${d.format}`,
            "POST"
          );
        }
        if (["sales", "all"].includes(d.report_type)) {
          exportDocument(
            `/api/v1/reports/${d.id}/download_sales`,
            `download_${d.id}.${d.format}`,
            "POST"
          );
        }
      });
  };

  const renderCustomButton = () => {
    return (
      <div className="col-12">
        <div
          className="float-left bg-white position-absolute"
          style={{
            top: 4,
          }}
        >
          <span className="text-black text-xs mr-2">Ataskaitų puslapyje</span>
          <select onChange={(value) => setPerPage(value)}>
            {[50, 100, 150, 200].map((d) => (
              <option key={d} value={d}>
                {d}
              </option>
            ))}
          </select>
        </div>
        <div className="float-right">
          <DropdownButton
            text="Masiniai veiksmai"
            actions={[
              {
                label: "Deaktyvuoti visas pažymėtas",
                onFunction: () =>
                  dispatch(
                    deactivateReports(selected, page, perCount, onFilter())
                  ),
              },
              {
                label: "Parsisiųsti pažymėtas ataskaitas formatais",
                onFunction: () => downloadDocumentsBySelected(selected),
              },
            ]}
          />
        </div>
      </div>
    );
  };
  const handleCheckboxChange = (value) => {
    setSelected((prev) => {
      const items = [...prev];
      const currentIndex = prev.indexOf(value);
      const myData = data;

      if (currentIndex === -1) {
        items.push(value);
      } else {
        items.splice(currentIndex, 1);
      }
      let variableAll = myData.length === items.length;
      setSelectedAll(variableAll);
      return items;
    });
  };

  const handleCheckboxChangeAll = () => {
    setSelected((prev) => {
      if (data.length === prev.length) {
        setSelectedAll(false);
        return [];
      } else {
        setSelectedAll(true);
        return data.map((d) => d.id);
      }
    });
  };

  return (
    <Container
      location={{
        locationName: "Ataskaitos",
        locationUrl: match.url,
      }}
    >
      <ContentBlock>
        {createBlock ? (
          <CreateReportBlock close={() => setCreateBlock(false)} />
        ) : (
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="text-l text-black font-semi-bold mb-0">
                Naujo tiekėjų ataskaita
              </h3>
              <div
                style={{
                  width: 217,
                  height: 32,
                }}
              >
                <ButtonSecondary onClick={() => setCreateBlock(true)}>
                  Kurti naują ataskaitą
                </ButtonSecondary>
              </div>
            </div>
          </div>
        )}
      </ContentBlock>
      <ContentBlock>
        <TableFilterBox
          filterText={"Eksportuoti"}
          button1={renderCustomButton()}
          clearFilter={() => setFilters(filtersInitial)}
        >
          <div className="col-2">
            <SwitchComp
              name="Aktyvi"
              placeholder="Aktyvi"
              checked={filters.active}
              onChange={(value) =>
                onChangeUseState(
                  { target: { value, name: "active" } },
                  setFilters
                )
              }
            />
          </div>
          <div
            className="col-2"
            // style={{
            //   width: 180,
            // }}
          >
            <InputSwitchComponent
              name="method"
              label="Būdas"
              options={[
                {
                  value: "manual",
                  label: "Rankinis",
                },
                {
                  value: "auto",
                  label: "Automatinis",
                },
              ]}
              value={filters.method}
              onChange={(value) => onChangeUseState(value, setFilters)}
            />
          </div>
          <div className="col-2">
            <SelectForm
              label="Atakaitos tipas"
              name="report_type"
              options={[
                {
                  value: "",
                  label: "Visi",
                },
                {
                  value: "residues",
                  label: "Likučiai",
                },
                {
                  value: "sales",
                  label: "Pardavimai",
                },
                {
                  value: "all",
                  label: "Pardavimai/Likučiai",
                },
              ]}
              value={filters.report_type}
              onChange={(value) =>
                onChangeUseState(
                  { target: { value, name: "report_type" } },
                  setFilters
                )
              }
            />
          </div>
          <div className="col-2">
            <SelectForm
              label="Padalinys"
              name="department"
              options={reportDepFullOptions}
              value={filters.department}
              onChange={(value) =>
                onChangeUseState(
                  { target: { value, name: "department" } },
                  setFilters
                )
              }
            />
          </div>
          <div className="col-2">
            <SelectForm
              label="Kategorija"
              name="category"
              options={options.families}
              value={filters.category}
              onChange={(value) =>
                onChangeUseState(
                  { target: { value, name: "category" } },
                  setFilters
                )
              }
            />
          </div>
          <div
            className="col-2"
            // style={{
            //   width: 180,
            // }}
          >
            <InputSwitchComponent
              name="period"
              label="Laikotarpis"
              options={[
                {
                  value: "month",
                  label: "Mėnesis",
                },
                {
                  value: "week",
                  label: "Savaitė",
                },
                {
                  value: "other",
                  label: "Kita",
                },
              ]}
              value={filters.period}
              onChange={(value) => onChangeUseState(value, setFilters)}
            />
          </div>
        </TableFilterBox>
        <div>
          <ContentValidation loader={loading} array={data}>
            <div className="table-responsive">
              <table className="table table-sm table-hover mt-3">
                <thead>
                  <tr className="text-muted">
                    <th className="text-black">
                      <div className="d-flex justify-content-center">
                        <CheckBoxInput
                          type="checkbox"
                          color="#f7951e"
                          checked={selectedAll}
                          onChange={() => handleCheckboxChangeAll()}
                        />
                      </div>
                    </th>
                    <th className="text-black">Aktyvi</th>
                    <th className="text-black">Būdas</th>
                    <th className="text-black">Tipas</th>
                    <th className="text-black">Tiekėjas</th>
                    <th className="text-black">Kategorija</th>
                    <th className="text-black">Pr. ženklas</th>
                    <th className="text-black">Padalinys</th>
                    <th className="text-black">El. paštas</th>
                    <th className="text-black">Laik.</th>
                    <th className="text-black">Diena.</th>
                    <th className="text-black text-center pb-0">
                      Sav. diena
                      <div className="d-flex">
                        {week_lt_short.map((d, idx) => (
                          <div
                            className="py-0 font-weight-normal text-center"
                            key={d.value}
                            style={{
                              flex: 1,
                              minWidth: 25,
                              background:
                                idx % 2 == 0
                                  ? "rgba(214, 214, 214, 0.13)"
                                  : "none",
                            }}
                          >
                            {d.label}
                          </div>
                        ))}
                      </div>
                    </th>
                    <th className="text-black">Laikas</th>
                    <th className="text-black"></th>
                  </tr>
                </thead>
                <tbody>
                  {current == "Ataskaitos" && data.map((e) => {
                    return (
                      <TableColumnReport
                        key={e.id}
                        item={e}
                        handleCheckboxChange={() => handleCheckboxChange(e.id)}
                        selected={selected.includes(e.id)}
                        show_more={() => setReportSelected(e.id)}
                      />
                    );
                  })}
                </tbody>
              </table>
              <PaginationComp
                page={page}
                totals={totals}
                setPagination={setPage}
                pageCount={perCount}
              />
            </div>
          </ContentValidation>
        </div>
      </ContentBlock>
      {reportSelected && (
        <ReportModalWrapper
          reportSelected={reportSelected}
          closeModal={() => setReportSelected(false)}
          newValue={newValue}
          setNewValue={setNewValue}
        />
      )}
    </Container>
  );
}
