import React, { memo } from "react";
import styled from "styled-components";

export const ButtonSecondary = ({
  children,
  onClick,
  disabled = false,
  loading = false,
  outline = false,
  outline_2 = false,
}) => {
  // console.count("ButtonSecondary: Count: ");
  return (
    <ButtonPrimaryStyled
      className={`text-xs ${
        outline ? `outline ${outline_2 ? "outline_2" : ""}` : ""
      }`}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? (
        <>
          <span className="spinner-border spinner-border-sm"></span>
          <span> Vykdoma...</span>
        </>
      ) : (
        children
      )}
    </ButtonPrimaryStyled>
  );
};

export default memo(ButtonSecondary);

const ButtonPrimaryStyled = styled.button`
  width: 100%;
  height: 100%;
  background: #f29302;
  color: #ffffff;
  border-radius: 3.2px;
  padding: 5px 10px;
  font-style: normal;
  border: none;
  &.outline {
    background: #ffffff;
    border: 1px solid #fe9c00;
    color: #fe9c00;
  }
  &.outline_2 {
    background: #fff6e8;
  }

  &:disabled {
    opacity: 0.5;
  }
  &:focus {
    outline: none;
  }
  &:active {
    background-color: #c47700;
  }
  &:hover {
    background-color: #c47700;
  }
  &.outline:hover,
  &.outline:active {
    background-color: #fe9c00;
    color: #fff;
    & svg {
      /* filter: invert(100%) sepia(33%) saturate(643%) hue-rotate(181deg)
        brightness(103%) contrast(105%); */
      fill: #fff !important;
    }
  }
`;
