import React from "react";
import InputForm from "../../common/InputForm";
import ModalToChange from "../../Layout/ModalToChange";
import { SelectForm } from "../../common/Buttons";
import { Option } from "antd";

class PickupEditModal extends React.Component {
  constructor() {
    super();
    this.state = {
      id: 0,
      code: "",
      company: "",
      contact_person: "",
      address: "",
      address2: "",
      city: "",
      country_id: "",
      postal_code: "",
      phone: "",
      work_time_to: "",
      pickup_time_from: "",
      email: "",
      active: false,
      comment: "",
      countries: [],
      action: "new",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.action === "new" && this.state.action === "edit") {
      this.setState({
        code: "",
        company: "",
        contact_person: "",
        address: "",
        address2: "",
        city: "",
        country_id: "",
        postal_code: "",
        phone: "",
        work_time_to: "",
        pickup_time_from: "",
        email: "",
        active: false,
        comment: "",
        action: "new",
      });
      this.removeErrors(nextProps.errors);
    } else if (nextProps.action === "edit" && nextProps.objectData.id !== this.state.id) {
      let item = nextProps.objectData;
      this.setState({
        id: item.id || 0,
        code: item.code || "",
        company: item.company || "",
        contact_person: item.contact_person || "",
        address: item.address || "",
        address2: item.address2 || "",
        city: item.city || "",
        country_id: item.country_id || "",
        postal_code: item.postal_code || "",
        phone: item.phone || "",
        work_time_to: item.work_time_to || "",
        pickup_time_from: item.pickup_time_from || "",
        email: item.email || "",
        active: item.active || false,
        comment: item.comment || "",
        action: "edit",
      });
      this.removeErrors(nextProps.errors);
    }
    if (nextProps.countries) {
      let countries = [];
      nextProps.countries.forEach((item) => {
        return countries.push({
          label: item.name,
          value: item.id,
        });
      });
      this.setState({
        countries,
      });
    }
  }

  removeErrors = (errors) => {
    if (Object.keys(errors).length > 0) {
      this.props.clearErrors();
    }
  };

  handleChange = (e, select) => {
    if (select) {
      this.setState({
        [select]: e,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const variables = {
      pickup_address: {
        code: this.state.code,
        company: this.state.company,
        contact_person: this.state.contact_person,
        address: this.state.address,
        address2: this.state.address2,
        city: this.state.city,
        country_id: this.state.country_id,
        postal_code: this.state.postal_code,
        phone: this.state.phone,
        work_time_to: this.state.work_time_to,
        pickup_time_from: this.state.pickup_time_from,
        email: this.state.email,
        active: this.state.active,
        comment: this.state.comment,
      },
    };
    if (this.props.action == "new") {
      this.props.postPickupAddress(variables);
    } else if (this.props.action === "edit") {
      this.props.updatePickupAddress(this.state.id, variables);
    }
  };

  render() {
    const activeOptions = [
      { label: "Aktyvus", value: true },
      { label: "Neaktyvus", value: false },
    ];
    return (
      <ModalToChange
        handleSave={(e) => this.onSubmit(e)}
        status="tableEdit"
        text={this.props.action === "new" ? "Kurti naują" : "Redaguoti"}
        buttonText={this.props.action === "new" ? "Sukurti" : "Atnaujinti"}
      >
        <InputForm
          type="text"
          placeholder="Kodas"
          name="code"
          value={this.state.code}
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Kompanija"
          name="company"
          value={this.state.company}
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Kontaktinis asmuo"
          name="contact_person"
          value={this.state.contact_person}
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Adresas"
          name="address"
          value={this.state.address}
          error={
            this.props.errors.address &&
            this.props.errors.address.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Antrasis adresas"
          name="address2"
          value={this.state.address2}
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Miestas"
          name="city"
          value={this.state.city}
          error={
            this.props.errors.city &&
            this.props.errors.city.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        <SelectForm
          placeholder={"Šalys"}
          selector="country_id"
          value={this.state.country_id}
          options={this.state.countries}
          onChange={(e) => this.handleChange(e, "country_id")}
          error={
            this.props.errors.country_id &&
            this.props.errors.country_id.map((item) => `${item} `)
          }
        />

        <InputForm
          type="text"
          placeholder="Pašto kodas"
          name="postal_code"
          value={this.state.postal_code}
          error={
            this.props.errors.postal_code &&
            this.props.errors.postal_code.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Telefono numeris"
          name="phone"
          value={this.state.phone}
          error={
            this.props.errors.phone &&
            this.props.errors.phone.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Darbo laikas nuo:"
          name="pickup_time_from"
          value={this.state.pickup_time_from}
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Darbo laikas iki:"
          name="work_time_to"
          value={this.state.work_time_to}
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="email"
          placeholder="Elektroninis paštas"
          name="email"
          value={this.state.email}
          error={
            this.props.errors.email &&
            this.props.errors.email.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />

        <SelectForm
          placeholder={" Ar aktyvus?"}
          selector="active"
          value={this.state.active}
          onChange={(e) => this.handleChange(e, "active")}
          options={activeOptions}
        />

        <InputForm
          type="text"
          placeholder="Komentarai"
          name="comment"
          value={this.state.comment}
          onChange={(e) => this.handleChange(e)}
        />
      </ModalToChange>
    );
  }
}

export default PickupEditModal;
