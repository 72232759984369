import React, { useState, useEffect } from "react";
import AutoFillComponent from "../../../AutoFillComponent";
import { LongButton } from "../../../Buttons";
import { Divs, UlElements } from "../../../StyledComponents";
import TextInputModal from "../../../TextInputModal";
import { posti_postal_code } from "../../../../../helpers/RequestToAnotherServer";

function RenderAddress({
  delivery_children,
  onChange,
  shippingAddress,
  billingAddress,
  carriers,
  carrier_id,
  errors,
  rline,
  rline_code,
  customer_email,
  general,
}) {
  const [carrier, setCarrier] = useState("");
  useEffect(() => {
    let string = "";
    const findItem = carriers.find((e) => e.value == carrier_id);
    if (findItem) {
      switch (findItem.label) {
        case "Atsiėmimas parduotuvėje":
          string = "Atsiėmimą parduotuvėje";
          break;
        case "OMNIVA Paštomatas":
          string = "Paštomatas";
          break;
        case "DPD Paštomatas":
          string = "Paštomatas";
          break;
        case "LP Express 24 pristatymas į paštomatą":
          string = "Paštomatas";
          break;
        case "Itella paštomatas":
          string = "Paštomatas";
          break;
        case "Venipak paštomatas":
          string = "Paštomatas";
          break;
        case "Venipak pickup point":
          string = "Paštomatas";
          break;
        default:
          string = "Kurjeris";
      }
    }
    setCarrier(string);
  }, [carrier_id]);

  useEffect(() => {
    if (
      ["Kurjeris"].includes(carrier) &&
      shippingAddress.city &&
      shippingAddress.street
    ) {
      const t = setTimeout(async () => {
        const { success, postal_code } = await posti_postal_code(
          shippingAddress.city,
          shippingAddress.street
        );
        if (success && postal_code) {
          onChange({
            target: {
              value: postal_code,
              name: "[shippingAddress]postal_code",
            },
          });
        }
      }, 1000);

      return () => clearTimeout(t);
    }
  }, [shippingAddress.city, shippingAddress.street]);

  useEffect(() => {
    if (billingAddress.city && billingAddress.street) {
      const t = setTimeout(async () => {
        const { success, postal_code } = await posti_postal_code(
          billingAddress.city,
          billingAddress.street
        );
        if (success && postal_code) {
          onChange({
            target: { value: postal_code, name: "[billingAddress]postal_code" },
          });
        }
      }, 1000);

      return () => clearTimeout(t);
    }
  }, [billingAddress.city, billingAddress.street]);

  const autoFillChange = (full_vall, type, updateNext) => {
    onChange(full_vall, type, updateNext);
  };

  const ArrayBuilder = (arr) => {
    const responseBuild = arr.map((e) => ({
      label: `${e.firstname} ${e.lastname} ${e.street}`,
      value: e.id,
      city: e.city,
      company: e.company,
      company_code: e.company_code,
      firstname: e.firstname,
      lastname: e.lastname,
      other: e.other,
      phone: e.phone,
      postal_code: e.postal_code,
      street: e.street,
      vat_code: e.vat_code,
    }));
    return responseBuild;
  };

  const renderSearchEndPoint = (value) => {
    const arrayOfString = value.trim().split(" ");
    const searchString = arrayOfString.reduce(
      (cur, acc) =>
        cur + `&q[firstname_or_lastname_or_street_i_cont_any]=${acc}`,
      ""
    );
    return `/api/v1/addresses?${searchString}`;
  };

  return (
    <div className="col-12 bg-silver row py-3 m-0">
      <Divs className="col-6" style={{ width: "50%" }}>
        <div className="d-flex justify-content-between font-weight-bold mb-3">
          <span>Mokėtojo adresas</span>
        </div>
        <UlElements>
          <li>
            <AutoFillComponent
              endPoint={renderSearchEndPoint}
              onChange={(_, full) =>
                autoFillChange(full, "billingAddress", carrier === "Kurjeris")
              }
              arrayBuilder={ArrayBuilder}
            />
          </li>
          <li>
            <TextInputModal
              text="Vardas"
              placeholder="Įvesti"
              name="[billingAddress]firstname"
              value={billingAddress.firstname}
              onChange={onChange}
              error={errors["billing_address.firstname"]}
            />
          </li>
          <li>
            <TextInputModal
              text="Pavardė"
              placeholder="Įvesti"
              name="[billingAddress]lastname"
              value={billingAddress.lastname}
              onChange={onChange}
              error={errors["billing_address.lastname"]}
            />
          </li>
          <li>
            <TextInputModal
              text="Adresas"
              placeholder="Įvesti"
              name="[billingAddress]street"
              value={billingAddress.street}
              onChange={onChange}
            />{" "}
          </li>
          <li>
            <TextInputModal
              text="Miestas"
              placeholder="Įvesti"
              name="[billingAddress]city"
              value={billingAddress.city}
              onChange={onChange}
            />{" "}
          </li>
          <li>
            <TextInputModal
              type="number"
              text="Pašto kodas"
              placeholder="Įvesti"
              name="[billingAddress]postal_code"
              value={billingAddress.postal_code}
              onChange={onChange}
            />
          </li>
          <li>
            <TextInputModal
              text="Telefono numeris"
              require={true}
              placeholder="Įvesti"
              name="[billingAddress]phone"
              value={billingAddress.phone}
              onChange={onChange}
              error={errors["billing_address.phone"]}
            />{" "}
          </li>
          <li>
            <TextInputModal
              type="email"
              text="El. paštas"
              placeholder="Įvesti"
              name="[billingAddress]other"
              value={billingAddress.other}
              onChange={onChange}
              error={errors["billing_address.email"]}
            />
          </li>
          <li>
            <TextInputModal
              text="Imonė"
              placeholder="Įvesti"
              name="[billingAddress]company"
              value={billingAddress.company}
              onChange={onChange}
            />
          </li>
          <li>
            <TextInputModal
              required={!!billingAddress.company}
              text="Imonės kodas"
              placeholder="Įvesti"
              name="[billingAddress]company_code"
              value={billingAddress.company_code}
              onChange={onChange}
              error={errors["billing_address.company_code"]}
            />
          </li>

          <li>
            <TextInputModal
              text="PVM"
              placeholder="Įvesti"
              name="[billingAddress]vat_code"
              value={billingAddress.vat_code}
              onChange={onChange}
            />
          </li>
        </UlElements>
      </Divs>
      <Divs
        className="col-6"
        style={{
          width: "50%",
          borderLeft: "1px solid #e3e3e3",
        }}
      >
        <div className="d-flex justify-content-between font-weight-bold mb-3">
          <span>
            Pristatymo adresas {carrier && `(${carrier})`}
            {billingAddress.street2 == "express" ? (
              <span className="text-danger ml-1">Express</span>
            ) : (
              ""
            )}
          </span>
        </div>

        <UlElements>
          {["", "Kurjeris"].includes(carrier) && (
            <li>
              <AutoFillComponent
                endPoint={renderSearchEndPoint}
                onChange={(_, full) => autoFillChange(full, "shippingAddress")}
                arrayBuilder={ArrayBuilder}
              />
            </li>
          )}
          {carrier !== "Atsiėmimą parduotuvėje" && (
            <>
              <li>
                <TextInputModal
                  text="Vardas"
                  placeholder="Įvesti"
                  name="[shippingAddress]firstname"
                  value={shippingAddress.firstname}
                  onChange={onChange}
                />
              </li>
              <li>
                <TextInputModal
                  text="Pavardė"
                  placeholder="Įvesti"
                  name="[shippingAddress]lastname"
                  value={shippingAddress.lastname}
                  onChange={onChange}
                />
              </li>
            </>
          )}

          {carrier !== "Paštomatas" ? (
            <>
              <li>
                <TextInputModal
                  text="Adresas"
                  placeholder="Įvesti"
                  name="[shippingAddress]street"
                  value={shippingAddress.street}
                  onChange={onChange}
                />{" "}
              </li>

              <li>
                <TextInputModal
                  text="Miestas"
                  placeholder="Įvesti"
                  name="[shippingAddress]city"
                  value={shippingAddress.city}
                  // disabled={haveRivileShops}
                  onChange={onChange}
                />{" "}
              </li>
              <li>
                <TextInputModal
                  type="number"
                  text="Pašto kodas"
                  placeholder="Įvesti"
                  name="[shippingAddress]postal_code"
                  value={shippingAddress.postal_code}
                  // disabled={haveRivileShops}
                  onChange={onChange}
                />
              </li>
            </>
          ) : (
            <li>
              <TextInputModal
                text="Paštomatas"
                value={delivery_children}
                disabled={true}
              />
            </li>
          )}
          <li>
            <TextInputModal
              require={true}
              text="Telefono numeris"
              placeholder="Įvesti"
              name="[shippingAddress]phone"
              value={shippingAddress.phone}
              onChange={onChange}
              error={errors["shipping_address.phone"]}
            />
          </li>
          <li>
            <TextInputModal
              text="El. paštas"
              placeholder="Įvesti"
              name="[shippingAddress]other"
              value={shippingAddress.other}
              onChange={onChange}
            />
          </li>
          {rline && (
            <li>
              <span className="text-bold my-1">
                <span className="text-danger">[R-Line]:</span> {rline_code}
              </span>
            </li>
          )}
          {general.consultant && (
            <>
              <li>
                <span className="text-bold my-1">
                  <span className="text-danger">Konsultanto kodas:</span>{" "}
                  {general.consultant_code}
                </span>
              </li>
              <LongButton
                text={general.consultant_email}
                style={{
                  backgroundColor: "silver",
                  color: "black",
                  fontSize: "0.8rem",
                  height: "24px",
                }}
              />
            </>
          )}
        </UlElements>
      </Divs>
    </div>
  );
}

export default RenderAddress;
