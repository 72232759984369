import React from "react";
import InputForm from "../../common/InputForm";
import ModalToChange from "../../Layout/ModalToChange";
import { SelectForm } from "../../common/Buttons";
import moment from "moment";

class CommentsStateEdit extends React.Component {
  constructor() {
    super();
    this.state = {
      id: 0,
      name: "",
      color: "#FFFFFF",
      identifier: "",
      comment_type: "",
      ordering: "",
      action: "new",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.action === "new" && this.state.action === "edit") {
      this.setState({
        name: "",
        color: "#FFFFFF",
        identifier: "",
        comment_type: "",
        ordering: "",
        action: "new",
      });
      this.removeErrors(nextProps.errors);
    } else if (
      nextProps.action === "edit" &&
      nextProps.objectData.id !== this.state.id
    ) {
      let item = nextProps.objectData;
      this.setState({
        id: item.value || 0,
        name: item.name || "",
        color: item.color || "#FFFFFF",
        identifier: item.identifier || "",
        comment_type: item.comment_type || "",
        ordering: item.ordering || "",
        action: "edit",
      });
      this.removeErrors(nextProps.errors);
    }
  }

  removeErrors = (errors) => {
    if (Object.keys(errors).length > 0) {
      this.props.clearErrors();
    }
  };

  handleChange = (e, select) => {
    if (select) {
      this.setState({
        [select]: e,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const variables = {
      comment_state: {
        name: this.state.name,
        color: this.state.color,
        identifier: this.state.identifier,
        comment_type: this.state.comment_type,
        ordering: this.state.ordering,
      },
    };
    if (this.props.action == "new") {
      this.props.postCommentState(variables);
    } else if (this.props.action === "edit") {
      this.props.updateCommentState(this.state.id, variables);
    }
  };

   textBuilder = (val) => {
    const textValue = val || "";
    const replacePattern1 =
      /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;

    return textValue.replace(
      replacePattern1,
      '<a href="$1" target="_blank">$1</a>'
    );
  };

  render() {
    let chooseComState = [
      {
        label: "KA komentarai",
        value: "comment_ka",
      },
      {
        label: "Logistika",
        value: "comment_logistic",
      },
      {
        label: "Vadyba",
        value: "comment_management",
      },
      {
        label: "Parduotuvės komentarai",
        value: "comment_store",
      },
    ];
    return (
      <ModalToChange
        handleSave={(e) => this.onSubmit(e)}
        status="tableEdit"
        text={this.props.action === "new" ? "Kurti naują" : "Redaguoti"}
        buttonText={this.props.action === "new" ? "Sukurti" : "Atnaujinti"}
      >
        <InputForm
          type="text"
          placeholder="Pavadinimas"
          name="name"
          value={this.state.name}
          error={
            this.props.errors.name &&
            this.props.errors.name.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="color"
          placeholder="Spalva"
          name="color"
          value={this.state.color}
          onChange={(e) => this.handleChange(e)}
        />
        {/*  */}
        <div
          className="align-middle border p-0"
          style={{
            backgroundColor: this.state.color,
          }}
        >
          <div
            className="p-2"
            style={{
              width: "auto",
              fontSize: "0.8rem",
            }}
          >
            <span className="text-primary">
              {" "}
              {moment().format("YYYY MM DD [\n] HH:mm")}
            </span>
            <br />
            <span className="font-weight-bold">
              {`Vardenis Pavardenis`}
            </span>
            {/* {comment.date !== null && (
              <>
                <br />
                <span className="font-weight-bold">
                  {moment(comment.date).format("YYYY MM DD")}
                </span>
              </>
            )} */}
            <div
              style={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{
                __html: `<p>${this.textBuilder(
                  `Lorem Ipsum is simply dummy text of the printing and typesetting industry http://technorama.lt`
                )}</p>`,
              }}
            ></div>
          </div>
        </div>
        {/*  */}
        <InputForm
          type="text"
          placeholder="Identifikatorius"
          name="identifier"
          value={this.state.identifier}
          error={
            this.props.errors.identifier &&
            this.props.errors.identifier.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        <SelectForm
          options={chooseComState}
          selector="žinučiu-typas"
          name="comment_type"
          placeholder="Pasirinkti žinučių tipą"
          value={this.state.comment_type || undefined}
          onChange={(e) => this.handleChange(e, "comment_type")}
          error={
            this.props.errors.comment_type &&
            this.props.errors.comment_type.map((item) => `${item} `)
          }
        />
        <InputForm
          type="number"
          placeholder="Rikiavimas"
          name="ordering"
          value={this.state.ordering}
          onChange={(e) => this.handleChange(e)}
        />
      </ModalToChange>
    );
  }
}

export default CommentsStateEdit;
