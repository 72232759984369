import React, { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import { GoPrimitiveDot } from "react-icons/go";
import { Input } from "antd";
import { Trash_svg } from "../../../helpers/SvgStrings";
const CategoryChildren = (props) => {
  return (
    <>
      {props.children.map((itemTable) => {
        return (
          <React.Fragment key={itemTable.id}>
            <li
              className={`list-group-item node-treeview mb-2 collapse ${
                props.currentOpen.includes(props.openID) && "show"
              }`}
              key={itemTable.id}
            >
              <span>
                {typeof itemTable.children !== undefined && (
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ marginLeft: `${props.level * 1}rem` }}
                  >
                    <span
                      type="button"
                      onClick={() => {
                        props.handleToggle(itemTable.id);
                      }}
                    >
                      {itemTable.hasOwnProperty("children") ? (
                        !props.currentOpen.includes(itemTable.id) ? (
                          <FaPlus className="mr-2" />
                        ) : (
                          <FaMinus className="mr-2" />
                        )
                      ) : (
                        <GoPrimitiveDot className="mr-2 text-success" />
                      )}
                      {itemTable.name}
                    </span>
                    {itemTable.hasOwnProperty("children") ? (
                      <span
                        className="badge badge-primary badge-pill"
                        style={{ float: "right" }}
                      >
                        {itemTable.children.length}
                      </span>
                    ) : (
                      <Input
                        typ="number"
                        style={{
                          width: 80,
                        }}
                        prefix={<Trash_svg />}
                        size="small"
                        placeholder="Šiukšlių mokestis"
                        value={props.values[itemTable.id] || 0}
                        onChange={(e) =>
                          props.onChange({
                            amount: e.target.value || 0,
                            category_id: itemTable.id,
                          })
                        }
                      />
                    )}
                  </div>
                )}
              </span>
            </li>
            {itemTable.hasOwnProperty("children") && props.currentOpen.includes(itemTable.id) && (
              <CategoryChildren
                children={itemTable.children || []}
                level={props.level + 1}
                openID={itemTable.id}
                currentOpen={props.currentOpen}
                handleToggle={props.handleToggle}
                onChange={props.onChange}
                values={props.values}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};
export default CategoryChildren;
