import React, { useState } from "react";
import moment from "moment";

function ColumnTableComment(props) {
  const [ShowMore, setShowMore] = useState(false);
  const data = props.comments
    .filter((comFilter) => comFilter.identifier === props.type)
    .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  let tableShow;

  if (data.length == 0) {
    tableShow = (
      <td
        className="align-middle border"
        style={{ width: "15%", margin: 0, padding: 0 }}
      ></td>
    );
  } else if (data.length == 1) {
    let comment = data[0];
    tableShow = (
      <React.Fragment>
        <td
          className="align-middle border"
          style={{
            width: "15%",
            backgroundColor: props.type === "comment_ka" ? "#dee2e6" : "rgba(232 236 241)",
            margin: 0,
            padding: 0,
          }}
        >
          <div
            style={{
              fontSize: "0.8rem",
            }}
          >
            <span className="text-primary">
              {" "}
              {moment(comment.created_at).format("YYYY MM DD [\n] HH:mm")}
            </span>
            <div className="text-secondary">{comment.user}</div>
            <p style={{ whiteSpace: "pre-wrap" }}>{comment.comment}</p>
          </div>
        </td>
      </React.Fragment>
    );
  } else {
    let manyDataFirst = data.splice(-1);
    let manyDataSeconds = data;

    tableShow = (
      <td
        className="align-middle border"
        style={{ width: "15%", margin: 0, padding: 0 }}
      >
        <button
          className="btn btn-sm btn-primary"
          style={{
            padding: "0",
            width: "100%",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
          onClick={() => setShowMore(!ShowMore)}
        >
          <span style={{ fontSize: "0.8rem", fontWeight: "400" }}>
            Rodyti senesnius
          </span>
        </button>
        {ShowMore
          ? manyDataSeconds.map((comment, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    style={{
                      fontSize: "0.8rem",
                      padding: "3px",
                      backgroundColor: props.type === "comment_ka" ? "#dee2e6" : "rgba(232 236 241)",
                    }}
                  >
                    <span className="text-primary">
                      {moment(comment.created_at).format(
                        "YYYY MM DD [\n] HH:mm"
                      )}
                    </span>
                    <div className="text-secondary">{comment.user}</div>
                    <p style={{ whiteSpace: "pre-wrap" }}>{comment.comment}</p>
                  </div>
                </React.Fragment>
              );
            })
          : null}
        {manyDataFirst.map((comment, index) => {
          return (
            <React.Fragment key={index}>
              <div
                style={{
                  fontSize: "0.8rem",
                  padding: "3px",
                  backgroundColor: props.type === "comment_ka" ? "#dee2e6" : "rgba(232 236 241)",
                }}
              >
                <span className="text-primary">
                  {moment(comment.created_at).format("YYYY MM DD [\n] HH:mm")}
                </span>
                <div className="text-secondary">{comment.user}</div>
                <p style={{ whiteSpace: "pre-wrap" }}>{comment.comment}</p>
              </div>
            </React.Fragment>
          );
        })}
      </td>
    );
  }

  return tableShow;
}

export default ColumnTableComment;
