import React from "react";
import Container from "../Layout/Container";
import { connect } from "react-redux";
import {
  getShopCategories,
  updateShopCategories,
} from "../../redux/Actions/shopCategoriesAction";
import ContentBlock from "../Layout/ContentBlock";
import TableColumnRivilePVM25 from "./Items/TableColumnRivilePVM25";
import TableFilterBox from "../common/TableFilterBox";
import ContentValidation from "../common/ContentValidation";

class RivilePVM extends React.Component {
  componentDidMount() {
    this.props.getShopCategories();
  }

  render() {
    return (
      <Container
        location={{
          locationName: "Rivile PVM 25",
          locationUrl: this.props.match.url,
        }}
      >
        <ContentBlock>
          <TableFilterBox filter={false} add={false}></TableFilterBox>
          <div>
            <ContentValidation
              loader={this.props.loading_shop_categories}
              array={this.props.shop_categories}
            >
              <div className="table-responsive">
                <table className="table table-sm table-hover mt-3">
                  <thead>
                    <tr className="text-black">
                      <th>#</th>
                      <th>Pavadinimas</th>
                      <th>PVM25</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.shop_categories.map((item, index, array) => {
                      return (
                        <TableColumnRivilePVM25
                          key={index}
                          index={index}
                          item={item}
                          array={array}
                          updateShopCategories={this.props.updateShopCategories}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </ContentValidation>
          </div>
        </ContentBlock>
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  shop_categories: state.shopCategories.shop_categories,
  loading_shop_categories: state.shopCategories.loading_shop_categories,
});

export default connect(mapStateToProps, {
  getShopCategories,
  updateShopCategories,
})(RivilePVM);
