import {
  LOADING_SUPP_INVOICES,
  GET_SUPP_INVOICES,
  SHOW_SUPP_INVOICE,
  UPDATE_INVOICE_STATUS,
  DELETE_SUPP_INVOICE,
  EDIT_SUPP_INVOICE,
} from "../Actions/types";

const initialState = {
  invoice: {},
  invoices: [],
  totals: 0,
  loading_invoices: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_SUPP_INVOICES:
      return {
        ...state,
        loading_invoices: true
      };
    case GET_SUPP_INVOICES:
      return {
        ...state,
        invoices: action.payload.data,
        totals: action.payload.totals,
        loading_invoices: false
      };
    case SHOW_SUPP_INVOICE:
      return {
        ...state,
        invoice: action.payload,
      };
    case UPDATE_INVOICE_STATUS:
      let updated_invoices = [...state.invoices];
      const updateElementId = element => element.id === action.payload;
      const findIndex = updated_invoices.findIndex(updateElementId);
      updated_invoices[findIndex].exported = true;
      return {
        ...state,
        invoices: updated_invoices,
      };
    case DELETE_SUPP_INVOICE:
      return {
        ...state,
        invoices: [...state.invoices].filter(
          filterItem => filterItem.id !== action.payload
        ),
        loading_invoices: false
      };
    case EDIT_SUPP_INVOICE:
      return {
        ...state,
        invoice: action.payload,
      };
    default:
      return state;
  }
}
