import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { returnEuroString } from "../../../../../../helpers/Utils";
import { shipment_types_options } from "../../../../../../helpers/GlobalOptions";
import { removeShipmentBeforeCalled } from "../../../../../../redux/Actions/shipmentsAction";
import { ClassicButtonOutline } from "../../../../ButtonWithLoading";
import { useDispatch } from "react-redux";
import { FaTrash } from "react-icons/fa";
import AntdConfirm from "../../../../../../helpers/AntdConfirm";

function ShippingInformation({ information, parcel_terminals }) {
  let additionalServices = [];

  information.shipment_additional_services.forEach((item) => {
    const key = item.service_key;
    const value = item.service_value;
    if (key == "delivery") {
      if (value == "dpd_b2c") {
        additionalServices.unshift("B2C siunta");
      } else if (value == "dpd_postMachine") {
        additionalServices.unshift(
          "Prekių pristatymas per DPD siuntų terminalą"
        );
      } else if (value == "dpd_with_label") {
        additionalServices.unshift("Paėmimas su lipduku");
      } else if (value == "pickup_from_department") {
        additionalServices.unshift("Siuntimas į padalinį");
      } else if (value == "omniva_home") {
        additionalServices.unshift("Pristatymas į namus");
      } else if (value == "omniva_postMachine") {
        additionalServices.unshift(
          "Prekių pristatymas per Omniva siuntų terminalą"
        );
      } else if (value === "omniva_return") {
        additionalServices.unshift("Prekės grąžinimas");
      } else if (value == "lp_express_postMachine") {
        additionalServices.unshift(
          "Prekių pristatymas per LP express siuntų terminalą"
        );
      } else if (value == "lp_express_home") {
        additionalServices.unshift("Pristatymas į namus");
      } else if (value == "venipak_home") {
        additionalServices.unshift("Pristatymas į namus");
      } else if (value == "venipak_postMachine") {
        additionalServices.unshift(
          "Prekių pristatymas per Venipak siuntų terminalą"
        );
      }
    }
    if (key === "time" && !!value) {
      additionalServices.push(`Pristatymo laikas: ${value}`);
    }
    if (key === "cod" && !!value) {
      additionalServices.push(`COD: ${returnEuroString(value)}`);
    }
    if (key === "order_return" && value == 1) {
      additionalServices.push(`Siuntos paėmimas iš kliento`);
    }
    if (key == "sat" && value == 1) {
      additionalServices.push(`Pristatymas šeštadienį`);
    }
    if (key == "docret" && value !== "0") {
      additionalServices.push(`Siunčiami dokumentai: ${value}`);
    }
    if (key == "carrying" && value == 1) {
      additionalServices.push("Užnešimas");
    }
    if (key == "swap" && value == 1) {
      additionalServices.push("SWAP");
    }
    if (key == "pack" && value > 0) {
      additionalServices.push(`${value} paketai`);
    }
    if (key == "weight" && value > 0) {
      additionalServices.push(`Svoris: ${value} kg`);
    }
    if (key == "lp_express_sizes" && value !== null) {
      additionalServices.push(`Paketo dydis: ${value}`);
    }
  });

  const orderTypeValue = (value) => {
    const findShipment = shipment_types_options.find((e) => e.value === value);
    if (findShipment) {
      return findShipment.label;
    } else {
      return "";
    }
  };

  const findParcel = (shipment, value) => {
    let findParcel;
    if (shipment == "omniva") {
      findParcel = parcel_terminals.omniva.find((item) => item.value == value);
    } else if (shipment == "dpd") {
      findParcel = parcel_terminals.dpd.find((item) => item.value == value);
    } else if (shipment == "lp_express") {
      findParcel = parcel_terminals.lp_express.find(
        (item) => item.value == value
      );
    } else if (shipment == "itella") {
      findParcel = parcel_terminals.itella.find((item) => item.value == value);
    } else if (shipment == "venipak") {
      findParcel = parcel_terminals.venipak.find((item) => item.value == value);
    }
    return findParcel ? findParcel.label : null;
  };

  const renderPickupAddress = () => {
    return (
      <React.Fragment>
        <UlElements>
          <li className="font-weight-bold">Adresas</li>
          <li className="li-font">{information.pickup_address.address}</li>
        </UlElements>
        {!!information.pickup_address.address2 && (
          <UlElements>
            <li className="font-weight-bold">Adresas 2</li>
            <li className="li-font">{information.pickup_address.address2}</li>
          </UlElements>
        )}
        <UlElements>
          <li className="font-weight-bold">Miestas</li>
          <li className="li-font">{information.pickup_address.city}</li>
        </UlElements>
        <UlElements>
          <li className="font-weight-bold">Pašto kodas</li>
          <li className="li-font">{information.pickup_address.postal_code}</li>
        </UlElements>
        <br />
        <UlElements>
          <li className="font-weight-bold">Kontaktinis asmuo</li>
          <li className="li-font">
            {information.pickup_address.contact_person}
          </li>
        </UlElements>
        {information.pickup_address.company !== null && (
          <UlElements>
            <li className="font-weight-bold">Kompanija</li>
            <li className="li-font">{information.pickup_address.company}</li>
          </UlElements>
        )}
        {information.pickup_address.vat_code !== null && (
          <UlElements>
            <li className="font-weight-bold">PVM</li>
            <li className="li-font">{information.pickup_address.vat_code}</li>
          </UlElements>
        )}
        {!!information.pickup_address.email && (
          <UlElements>
            <li className="font-weight-bold">Elektroninis paštas</li>
            <li className="li-font">{information.pickup_address.email}</li>
          </UlElements>
        )}
        <UlElements>
          <li className="font-weight-bold">Telefono numeris</li>
          <li className="li-font">{information.pickup_address.phone}</li>
        </UlElements>
        <UlElements>
          <li className="font-weight-bold">Darbo Laikas</li>
          <li className="li-font">
            {information.pickup_address.pickup_time_from +
              " - " +
              information.pickup_address.work_time_to}
          </li>
        </UlElements>
        <br />
        <UlElements>
          <li className="font-weight-bold">Kodas</li>
          <li className="li-font"> {information.pickup_address.code}</li>
        </UlElements>
        {!!information.pickup_address.comment && (
          <UlElements>
            <li className="font-weight-bold">Komentaras</li>
            <li className="li-font">{information.pickup_address.comment}</li>
          </UlElements>
        )}
      </React.Fragment>
    );
  };

  const renderShippingAddress = () => {
    return (
      <React.Fragment>
        {information.shipment_address.parcel_terminal_id !== null && (
          <UlElements>
            <li className="font-weight-bold">Paštomatas</li>
            <li className="li-font">
              {findParcel(
                information.shipment_type,
                information.shipment_address.parcel_terminal_id
              )}
            </li>
          </UlElements>
        )}
        <UlElements>
          <li className="font-weight-bold">Adresas</li>
          <li className="li-font">{information.shipment_address.address}</li>
        </UlElements>

        <UlElements>
          <li className="font-weight-bold">Miestas</li>
          <li className="li-font">{information.shipment_address.city}</li>
        </UlElements>
        <UlElements>
          <li className="font-weight-bold">Pašto kodas</li>
          <li className="li-font">
            {information.shipment_address.postal_code}
          </li>
        </UlElements>
        <br />
        <UlElements>
          <li className="font-weight-bold">Klientas</li>
          <li className="li-font">{information.shipment_address.name}</li>
        </UlElements>

        {information.shipment_address.company !== null && (
          <UlElements>
            <li className="font-weight-bold">Kompanija</li>
            <li className="li-font">{information.shipment_address.company}</li>
          </UlElements>
        )}

        <UlElements>
          <li className="font-weight-bold">Elektroninis paštas</li>
          <li className="li-font">{information.shipment_address.email}</li>
        </UlElements>

        <UlElements>
          <li className="font-weight-bold">Telefono numeris</li>
          <li className="li-font">{information.shipment_address.phone}</li>
        </UlElements>
        <br />

        <UlElements>
          <li className="font-weight-bold">Pastaba kurjeriui</li>
          <li className="li-font">{information.shipment_address.comment}</li>
        </UlElements>
      </React.Fragment>
    );
  };

  return (
    <tr className="border" style={{ width: "100%", backgroundColor: "white" }}>
      <td colSpan="3" style={{ padding: "0.8rem 1.3rem" }}>
        <div className="d-flex">
          <div style={{ width: "48%" }}>
            <UlElements>
              <li className="font-weight-bold">Svoris</li>
              <li className="li-font">{information.weight}</li>
            </UlElements>
            {information.picked_up_at && (
              <UlElements>
                <li className="font-weight-bold">Paėmimo data</li>
                <li className="li-font">
                  {moment(information.picked_up_at).format("YYYY-MM-DD HH:mm")}
                </li>
              </UlElements>
            )}
          </div>
          <div style={{ width: "4%" }}></div>
          <div style={{ width: "48%" }}>
            <UlElements>
              <li className="font-weight-bold">Siuntos tipas</li>
              <li className="li-font">
                {orderTypeValue(information.shipment_type)}
              </li>
            </UlElements>
            {information.delivered_at && (
              <UlElements>
                <li className="font-weight-bold">Pristatymo data</li>
                <li className="li-font">
                  {moment(information.delivered_at).format("YYYY-MM-DD HH:mm")}
                </li>
              </UlElements>
            )}
          </div>
        </div>
        <br />
        <div className="d-flex">
          <div style={{ width: "48%" }}>
            {information.hasOwnProperty("pickup_address") && (
              <React.Fragment>
                <MainText>Paėmimo adresas</MainText>
                {additionalServices.includes(`Siuntos paėmimas iš kliento`)
                  ? renderShippingAddress()
                  : renderPickupAddress()}
              </React.Fragment>
            )}
          </div>
          <div style={{ width: "4%" }}></div>
          <div style={{ width: "48%" }}>
            {information.hasOwnProperty("shipment_address") && (
              <React.Fragment>
                <MainText>Pristatymo adresas</MainText>
                {additionalServices.includes(`Siuntos paėmimas iš kliento`)
                  ? renderPickupAddress()
                  : renderShippingAddress()}
              </React.Fragment>
            )}
          </div>
        </div>
        <br />
        <React.Fragment>
          <div className="d-flex">
            <div
              style={{
                width: information.tracking_numbers.length > 0 ? "48%" : "100%",
              }}
            >
              {additionalServices.length > 0 && (
                <React.Fragment>
                  <MainText>Papildomos paslaugos</MainText>
                  <br />
                  {additionalServices.map((item, index) => {
                    return (
                      <div key={index}>
                        <UlElements>
                          <li className="li-font">{item}</li>
                        </UlElements>
                      </div>
                    );
                  })}
                </React.Fragment>
              )}
            </div>
            {information.courier_trackings.length > 0 && (
              <React.Fragment>
                <div style={{ width: "2%" }}></div>
                <div style={{ width: "48%" }}>
                  <MainText>Siuntų sekimas</MainText>
                  <br />
                  {information.courier_trackings.map((tracking_number) => (
                    <span key={tracking_number.number}>
                      <UlElements>
                        <li className="li-font">
                          <a href={tracking_number.url} target="_blank">
                            {tracking_number.number}
                          </a>
                        </li>
                      </UlElements>
                      <br />
                    </span>
                  ))}
                </div>
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
        <RemoveShippingInformation
          id={information.id}
          order_type={information.service_order_type}
          orderId={information.service_order_id}
        />
      </td>
    </tr>
  );
}

const RemoveShippingInformation = ({ id, orderId, order_type }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);

    AntdConfirm({
      onOk: () => {
        dispatch(removeShipmentBeforeCalled(id, orderId, order_type));
        setLoading(false);
      },
      onCancel: () => {
        setLoading(false);
      },
    });
  };

  return (
    <div className="d-flex justify-content-end align-items-center">
      <ClassicButtonOutline loading={loading} onClick={() => onSubmit()}>
        <FaTrash className="text-orange cursor-pointer" />
        <span className="ml-1">Pašalinti</span>
      </ClassicButtonOutline>
    </div>
  );
};

const MainText = styled.h3`
  color: #000000;
  font-weight: 900;
  font-size: 1rem;
  line-height: 27px;
  color: #000000;
`;
const TextWeight = styled.div`
  color: black;
  margin-bottom: 0.3rem;
  font-size: 0.8rem;
  line-height: 1.2rem;
  color: #000000;
  font-weight: normal;
  .main {
    font-weight: 700;
    margin-right: 0.2rem;
  }
`;
const UlElements = styled.ul`
  margin-bottom: 8px;
  list-style-type: none;
  padding: 0px;
  li {
    word-wrap: break-word;
    font-size: 10px;
    line-height: 14px;
    color: #000000;
  }
  .li-font {
    font-size: 14px;
  }
`;

export default ShippingInformation;
