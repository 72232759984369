import React from "react";
import {
  ButtonDelete,
  ButtonEdit,
  EditElementClick,
} from "../../common/Buttons";

function TableColumnServices({ auth, id, item, onDelete, index, onAction }) {
  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", index)}
          name={item.category}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", index)}
          name={item.sub_category}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", index)}
          name={item.manufacturer}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", index)}
          name={item.service_center}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", index)}
          name={item.phone}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", index)}
          name={item.address}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", index)}
          name={item.email}
        />
      </td>
      <td className="align-middle">
        <p style={{ whiteSpace: "pre-wrap" }}>{item.comments}</p>
      </td>
      <td className="align-middle" style={{ width: "50px" }}>
        {["admin", "manager"].includes(auth.user_role?.identifier) && (
          <ButtonEdit onEdit={() => onAction("edit", index)} />
        )}
      </td>
      <td className="align-middle" style={{ width: "50px" }}>
        {["admin", "manager"].includes(auth.user_role?.identifier) && (
          <ButtonDelete onDelete={() => onDelete(id)} />
        )}
      </td>
    </tr>
  );
}

export default TableColumnServices;
