import Axios from "axios";
import moment from "moment";
import React, { useState, useEffect, Fragment } from "react";
import { phonenumberValid, prettyDateFormat } from "../../../helpers/Utils";
import {
  onChangeUseState,
  renderByColor,
} from "../../../helpers/GlobalFunctions";
import {
  Button1,
  CheckBoxInput,
  EditElementClick,
  MainBlockWithContext,
} from "../../common/Buttons";
import { FlopppyButton } from "../../common/ButtonWithLoading";
import { Divs } from "../../common/StyledComponents";
import TableUi from "../../common/TableUi";
import ModalSpecial from "../../Layout/Modals/ModalSpecial";
import { Input, message } from "antd";
const { TextArea } = Input;
import SmsSending from "../../common/GlobalModals/Orders/editModal/SmallComp/sms_sending";
import TextInputModal from "../../common/TextInputModal";
import SelectInput from "../../common/SelectInput";

const shipment_options = [
  {
    label: "DPD siunta",
    value: "DPD",
  },
  {
    label: "Atsiemimas parduotuvėje",
    value: "PICKUP",
  },
  {
    label: "Transportas",
    value: "TRANSPORT",
  },
];

const ModalShowSales3PL = ({
  closeModal,
  showModal,
  updateIndex,
  returnValueByType,
}) => {
  const [type, setType] = useState("DPD");
  const [data, setData] = useState({});
  const [openedTabs, setOpenedTabs] = useState({
    goods: true,
    states: true,
    shipping: true,
    comments: true,
    sms_sendins: true,
    tracking_numbers: true,
  });
  const fetchPurchaseById = async (second = true) => {
    try {
      const { data: response } = await Axios.get(`/api/v1/sales/${showModal}`);
      if (!response || !response?.success) throw new Error("err");
      setData(response.data);
      setType(response.data.courier_type || "DPD");
      if (second) {
        updateIndex(response.data);
      }
    } catch (err) {
      console.log("Errors: ", err);
    }
  };
  useEffect(() => {
    fetchPurchaseById(false);
  }, [showModal]);

  const renderTopInfo = (
    <div className="w-100" style={{ borderRight: "2px solid #EDEDED" }}>
      <div className="d-flex align-items-center">
        <span className="mr-2">
          <div className="text-black font-semi-bold text-xl">
            {data.document_number}
          </div>
          <div className="font-weight-normal text-secondary">
            {data?.address?.street}
          </div>
        </span>
      </div>
    </div>
  );

  const renderContent = () => {
    if (!data || Object.entries(data).length == 0) {
      return <div>Loading...</div>;
    } else {
      return (
        <Fragment>
          <Divs>
            <MainBlockWithContext
              color={"#F7951E"}
              text="Prekės"
              action={openedTabs.goods}
              onChange={() =>
                setOpenedTabs({ ...openedTabs, goods: !openedTabs.goods })
              }
            >
              {openedTabs.goods && (
                <Fragment>
                  <div>
                    <TableUi
                      ths={[
                        "Prekės kodas",
                        "Prekės pavadinimas",
                        "MPN",
                        "Kiekis",
                        // "Klaidų pranešimai",
                      ]}
                    >
                      {data.sale_items?.map((e) => {
                        return (
                          <TableUi.tr key={e.id}>
                            <TableUi.td className="p-left">{e.sku}</TableUi.td>
                            <TableUi.td>{e.product_name}</TableUi.td>
                            <TableUi.td>{e.title}</TableUi.td>
                            <TableUi.td>
                              {Number(e.quantity)}
                              <span
                                className={
                                  Number(e.quantity) !==
                                  Number(e.prepared_quantity)
                                    ? "text-danger ml-1"
                                    : "ml-1"
                                }
                              >
                                ({Number(e.prepared_quantity || 0)})
                              </span>
                            </TableUi.td>
                          </TableUi.tr>
                        );
                      })}
                    </TableUi>
                  </div>
                  <div
                    style={{
                      margin: "0 15px",
                      borderBottom: "2px solid #EDEDED",
                    }}
                  />
                  <div className="col-12 my-2">
                    <div className="row">
                      <div className="col-6">
                        <div className="text-sm">
                          <span className="font-semi-bold">SKU kiekis</span>
                          <span className="px-3">{data.sale_items.length}</span>
                        </div>
                      </div>
                      <div className="col-6 text-right">
                        <div className="text-sm">
                          <span className="font-semi-bold">Bendras kiekis</span>
                          <span className="px-3">
                            {data.sale_items.reduce(
                              (total, acc) => +acc.quantity + total,
                              0
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
            </MainBlockWithContext>
          </Divs>
          <Divs>
            <MainBlockWithContext
              custom={true}
              text={
                <div className="d-flex justify-content-between">
                  <div
                    className="cursor-pointer flex-1"
                    onClick={() =>
                      setOpenedTabs({
                        ...openedTabs,
                        shipping: !openedTabs.shipping,
                      })
                    }
                  >
                    Siuntimas
                  </div>
                  {data.status !== "saved" ? (
                    <div
                      className="flex-1 text-danger cursor-pointer"
                      onClick={() =>
                        setOpenedTabs({
                          ...openedTabs,
                          shipping: !openedTabs.shipping,
                        })
                      }
                    >
                      Išsiųsta
                    </div>
                  ) : (
                    <div className="mr-2" style={{ width: 200 }}>
                      <SelectInput
                        options={shipment_options.filter((e) =>
                          returnValueByType(true, e.value !== "PICKUP", true)
                        )}
                        value={type}
                        onChange={(val) => setType(val)}
                      />
                    </div>
                  )}
                </div>
              }
              color={"#FFCC69"}
              action={openedTabs.shipping}
              onChange={() =>
                setOpenedTabs({ ...openedTabs, shipping: !openedTabs.shipping })
              }
            >
              {openedTabs.shipping ? (
                <div className="bg-silver py-2-3">
                  <LogisticShipmentForm
                    type={type}
                    sended={data.status !== "saved"}
                    address={data.address}
                    loading_service={data.loading_service || false}
                    showModal={showModal}
                    fetchPurchaseById={fetchPurchaseById}
                    returnValueByType={returnValueByType}
                  />
                </div>
              ) : (
                <div className="mb-2" />
              )}
            </MainBlockWithContext>
          </Divs>
          {returnValueByType(true, true, false) && (
            <Divs>
              <MainBlockWithContext
                text="Būsenos"
                color={"#BD00FF"}
                action={openedTabs.states}
                onChange={() =>
                  setOpenedTabs({ ...openedTabs, states: !openedTabs.states })
                }
              >
                {openedTabs.states && (
                  <>
                    {data.sale_states?.length > 0 ? (
                      <TableUi ths={["Data", "Būsena"]}>
                        {data.sale_states
                          ?.sort((a, b) =>
                            a.created_at < b.created_at ? 1 : -1
                          )
                          .map((e) => {
                            return (
                              <TableUi.tr
                                key={e.id}
                                style={{
                                  borderLeft: `3px solid ${renderByColor(
                                    e.mscan_state_code
                                  )}`,
                                }}
                              >
                                <TableUi.td className="p-left">
                                  {prettyDateFormat(e.created_at)}
                                </TableUi.td>
                                <TableUi.td>{e.state_text}</TableUi.td>
                              </TableUi.tr>
                            );
                          })}
                      </TableUi>
                    ) : (
                      <div className="col-12 font-weight-bold">
                        Nėra būsenų.
                      </div>
                    )}
                  </>
                )}
              </MainBlockWithContext>
            </Divs>
          )}
          <Divs>
            <MainBlockWithContext
              text="Sekimo numeriai"
              color={"#BD00FF"}
              action={openedTabs.tracking_numbers}
              onChange={() =>
                setOpenedTabs({
                  ...openedTabs,
                  tracking_numbers: !openedTabs.tracking_numbers,
                })
              }
            >
              {openedTabs.tracking_numbers && (
                <>
                  {data.courier_trackings?.length > 0 ? (
                    <TableUi ths={["Numeris", "Nuoroda"]}>
                      {data.courier_trackings.map((e) => {
                        return (
                          <TableUi.tr
                            key={e.id}
                            style={{
                              borderLeft: `3px solid #BD00FF`,
                            }}
                          >
                            <TableUi.td className="p-left text-orange">
                              <EditElementClick
                                name={e.number}
                                onEdit={() => window.open(e.url, "_blank")}
                              />
                            </TableUi.td>
                            <TableUi.td>{e.url}</TableUi.td>
                          </TableUi.tr>
                        );
                      })}
                    </TableUi>
                  ) : (
                    <div className="col-12 font-weight-bold">
                      Nėra sekimo numerių.
                    </div>
                  )}
                </>
              )}
            </MainBlockWithContext>
          </Divs>
          <div className="col-12 px-0">
            <div className="row">
              {returnValueByType(true, true, false) && (
                <div className={returnValueByType("col-6", "col-12", "col-6")}>
                  <Divs className="bg-silver">
                    <MainBlockWithContext
                      text="Komentarai"
                      color={"#06C76D"}
                      action={openedTabs.comments}
                      onChange={() =>
                        setOpenedTabs({
                          ...openedTabs,
                          comments: !openedTabs.comments,
                        })
                      }
                    >
                      {openedTabs.comments && (
                        <div className="col-12 mt-4">
                          <div className="management">
                            {data.sale_comments?.map((e) => (
                              <div className="d-flex mb-2" key={e.id}>
                                <div className="block_one flex-column d-flex justify-content-center">
                                  <div className="name text-center font-weight-bold">
                                    {e.user_title}
                                  </div>
                                  <div className="time text-center">
                                    {moment(e.created_at).format("YYYY MM DD")}
                                    <br />
                                    {moment(e.created_at).format("HH:mm")}
                                  </div>
                                </div>
                                <div className="block_two_main">
                                  <div className="block_two p-2">
                                    <p
                                      className="description"
                                      style={{ whiteSpace: "pre-wrap" }}
                                    >
                                      {e.body}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <SubmitComment
                            id={showModal}
                            fetchPurchaseById={fetchPurchaseById}
                          />
                        </div>
                      )}
                    </MainBlockWithContext>
                  </Divs>
                </div>
              )}
              {returnValueByType(true, false, false) && (
                <div className="col-6">
                  <Divs className="bg-silver">
                    <MainBlockWithContext
                      text={
                        <span>
                          SMS siuntimas numeriui
                          <span
                            className={
                              phonenumberValid(data.address?.phone)
                                ? ""
                                : "text-danger"
                            }
                          >
                            {data.address?.phone}
                          </span>
                        </span>
                      }
                      color={"#ADCB35"}
                      action={openedTabs.sms_sendins}
                      onChange={() =>
                        setOpenedTabs({
                          ...openedTabs,
                          sms_sendins: !openedTabs.sms_sendins,
                        })
                      }
                    >
                      {openedTabs.sms_sendins && (
                        <div className="col-12">
                          <SmsSending
                            id={data.document_number}
                            phoneNumber={data.address?.phone}
                            shipping_address_street={data.address?.street}
                            orderId={data.document_number}
                            shipments={[]}
                            custom_sms_sendings={data.sms_sendings}
                            custom_function={async (t1, param) => {
                              try {
                                const { data: res } = await Axios.post(
                                  `/api/v1/sales/${data.id}/send_sms`,
                                  {
                                    sms_sending: param,
                                  }
                                );

                                if (res.success) {
                                  fetchPurchaseById();
                                  return { success: true };
                                }
                                throw new Error("err");
                              } catch (err) {
                                console.log("Error: ", err);
                                return { success: false };
                              }
                            }}
                          />
                        </div>
                      )}
                    </MainBlockWithContext>
                  </Divs>
                </div>
              )}
            </div>
          </div>
        </Fragment>
      );
    }
  };
  return (
    <ModalSpecial
      text={renderTopInfo}
      handleSave={() => {}}
      onClose={() => closeModal()}
    >
      {renderContent()}
    </ModalSpecial>
  );
};

const LogisticShipmentForm = ({
  type,
  sended,
  address = {},
  loading_service,
  showModal,
  fetchPurchaseById,
  returnValueByType,
}) => {
  useEffect(() => {
    if (!type || !address || Object.entries(address).length == 0) return;
    setState({
      company: address.company,
      // lastName: address.lastname,
      street: address.street,
      city: address.city,
      postal_code: address.postal_code,
      phone: address.phone,
      other: address.other,
      remark: address.remark,
    });
    setAdditional({
      carrying: loading_service,
    });
  }, [type]);

  const [state, setState] = useState({
    company: address.company,
    // lastName: address.lastname,
    street: address.street,
    city: address.city,
    postal_code: address.postal_code,
    phone: address.phone,
    other: address.other,
    remark: address.remark,
  });
  const [additional, setAdditional] = useState({
    carrying: loading_service,
  });
  const [errors, setErrors] = useState({});

  const onSubmit = async () => {
    try {
      let params = {
        courier_type: type,
      };
      if (type !== "PICKUP") {
        params.address_attributes = state;
        params.loading_service = additional.carrying;
      } else {
        params.address_attributes = { phone: state.phone };
      }
      const { data } = await Axios.post(
        `/api/v1/sales/${showModal}/send_to_warehouse`,
        { sale: params }
      );
      if (data.success) {
        fetchPurchaseById();
        return message.success("Sėkmingai išsiųsta");
      } else {
        let objError = {};
        data.error.forEach((val) => {
          if (val == "Address phone phone number not valid") {
            objError.phone = "Blogai įvestas telefono numeris";
          } else if (val == "Address phone privalomas") {
            objError.phone = "Telefono numeris būtinas";
          } else if (val == "Address company privalomas") {
            objError.company = "Kompanijos pavadinimas būtinas";
          } else if (val == "Address city privalomas") {
            objError.city = "Miestas būtinas";
          } else if (val == "Address postal code privalomas") {
            objError.postal_code = "Pašto kodas būtinas";
          } else if (val == "Address street privalomas") {
            objError.street = "Adresas būtinas";
          }
        });
        setErrors(objError);
      }
      throw new Error("Err");
    } catch (err) {
      console.log("ERROR: ", err, err?.response?.data);
      return message.error("Įvyko klaida");
    }
  };
  const form = (disabled = false) => (
    <div>
      <div className="col-12">
        <div className="font-weight-normal text-secondary">
          {address.rivile_address}
        </div>
      </div>
      <div className="col-12 mb-3">
        <div className="row">
          <div className="col-3">
            <TextInputModal
              disabled={disabled}
              text="Gavėjas"
              name="company"
              required={true}
              value={state.company}
              onChange={(e) => onChangeUseState(e, setState)}
              warning={errors.company}
            />
          </div>
          <div className="col-3">
            <TextInputModal
              disabled={disabled}
              text="Adresas"
              name="street"
              required={true}
              value={state.street}
              onChange={(e) => onChangeUseState(e, setState)}
              warning={errors.street}
            />
          </div>
          <div className="col-3">
            <TextInputModal
              disabled={disabled}
              text="Miestas"
              name="city"
              required={true}
              value={state.city}
              onChange={(e) => onChangeUseState(e, setState)}
              warning={errors.city}
            />
          </div>
          <div className="col-3">
            <TextInputModal
              disabled={disabled}
              text="Pašto kodas"
              name="postal_code"
              required={true}
              value={state.postal_code}
              onChange={(e) => onChangeUseState(e, setState)}
              warning={errors.postal_code}
            />
          </div>
          <div className="col-3">
            <TextInputModal
              disabled={disabled}
              text="Telefonas "
              name="phone"
              value={state.phone}
              onChange={(e) => onChangeUseState(e, setState)}
              warning={errors.phone}
            />
          </div>
          <div className="col-3">
            <TextInputModal
              disabled={disabled}
              text="El. paštas"
              name="other"
              required={true}
              value={state.other}
              onChange={(e) => onChangeUseState(e, setState)}
            />
          </div>
          <div className="col-3">
            <TextInputModal
              disabled={disabled}
              text="Pastaba kurjeriui"
              name="remark"
              value={state.remark}
              onChange={(e) => onChangeUseState(e, setState)}
            />
          </div>
        </div>
      </div>
      <div className="col-12">
        <CheckBoxInput
          disabled={disabled}
          choised="carrying"
          color="#f7951e"
          text="Užnešimas"
          checked={additional.carrying}
          onChange={(e) =>
            onChangeUseState(
              {
                target: { name: "carrying", value: e.target.checked },
              },
              setAdditional
            )
          }
        />
      </div>
      <div className="mb-3" />
    </div>
  );
  const formPickup = (disabled = false) => (
    <div>
      <div className="col-12">
        <div className="font-weight-normal text-secondary">
          {address.rivile_address}
        </div>
      </div>
      <div className="col-12 mb-3">
        <div className="row">
          <div className="col-3">
            <TextInputModal
              disabled={disabled}
              text="Telefonas "
              name="phone"
              value={state.phone}
              onChange={(e) => onChangeUseState(e, setState)}
              warning={errors.phone}
            />
          </div>
        </div>
      </div>
      <div className="mb-3" />
    </div>
  );
  if (sended) {
    return (
      <div>
        <div className="col-12 mb-2">
          Tipas:{" "}
          <b>{shipment_options.find((e) => e.value == type)?.label || ""}</b>
        </div>
        {type == "PICKUP" ? formPickup(true) : form(true)}
      </div>
    );
  }
  return (
    <div>
      {type == "PICKUP" ? formPickup(false) : form(false)}
      {returnValueByType(true, true, false) && (
        <div className="col-12">
          <button
            style={{
              minWidth: 131,
              minHeight: 31,
            }}
            className="btn btn-primary font-semi-bold text-xs"
            onClick={() => onSubmit()}
          >
            Siųsti į Adrem
          </button>
        </div>
      )}
    </div>
  );
};

const SubmitComment = (props) => {
  const [addComment, setAddComment] = useState(false);
  const [InputValue, setInput] = useState("");
  const [ErrorText, setErrorText] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);

    if (InputValue == "") {
      setLoading(false);
      return setErrorText("Įrašykite komentarą");
    }

    let variablesBody = {
      sale_comment: {
        body: InputValue,
      },
    };
    try {
      const { data } = await Axios.post(
        `/api/v1/sales/${props.id}/add_comment`,
        variablesBody
      );
      setLoading(false);
      if (data.success) {
        setInput("");
        props.fetchPurchaseById();
      }
    } catch (err) {
      console.log("Error: ", err);
      setErrorText("Įvyko klaida");
      setLoading(false);
    }
  };

  return (
    <>
      {addComment && (
        <>
          <TextArea
            className="mb-2"
            placeholder="Komentaras"
            value={InputValue}
            onChange={(e) => setInput(e.target.value)}
          />
          <div className="d-flex justify-content-between">
            <div className="text-danger">{ErrorText}</div>

            <FlopppyButton loading={loading} onClick={() => onSubmit()} />
          </div>
        </>
      )}

      <div className="py-4">
        <div style={{ position: "relative" }}>
          <Button1
            style={{
              position: "absolute",
              top: "-16px",
              left: "50%",
              transform: "translate(-50%, 0px)",
            }}
            text={addComment ? "Atšaukti" : "Pridėti naują komentarą"}
            option={addComment ? "minus" : "plus"}
            onClick={() => setAddComment(!addComment)}
          />
          <hr />
        </div>
      </div>
    </>
  );
};

export default ModalShowSales3PL;
