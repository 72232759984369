import { Select, Spin } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import useDebounce from "../../hooks/useDebounce";

function DebounceSelect({ fetchOptions, debounceTimeout = 600, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  useDebounce(() => actionFunc(), debounceTimeout, [searchVal]);
  useEffect(() => {
    if (props.options) {
      setOptions(props.options);
    }
  }, [props.options]);

  const actionFunc = () => {
    if (searchVal == "") {
      setOptions([]);
      setFetching(false);
      return;
    }

    setOptions([]);
    setFetching(true);
    fetchOptions(searchVal).then((newOptions) => {
      setOptions(newOptions);
      setFetching(false);
    });
  };

  return (
    <>
      {props.label && (
        <div
          className={`font-medium text-sm my-1 ${
            props.error || props.warning ? "text-danger" : ""
          }`}
        >
          {props.label}{" "}
          {props.required && <span className="orange_color">*</span>}
        </div>
      )}
      <Select
        className={props?.error ? "error_border" : ""}
        labelInValue
        filterOption={false}
        onSearch={(val) => setSearchVal(val)}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        dropdownMatchSelectWidth={props.dropdownMatchSelectWidth || false}
        placeholder="Pasirinkti"
        style={{
          width: "100%",
        }}
        {...props}
        options={options}
      />
      {props?.error && <div className="text-danger">{props.error}</div>}
    </>
  );
}

export default DebounceSelect;
