import React, { useEffect, useState } from "react";
import { Popover } from "antd";
import { IconExpand } from "../Layout/Icons";
import { PopoverModal } from "../global/ProductSku";
import { AiOutlineClose } from "react-icons/ai";

const TableProductPreview = ({ autosys_products, open, setOpen }) => {
  const autosys_products_length = autosys_products.length > 12;
  if (!autosys_products_length) {
    return (
      <div
        style={{
          maxWidth: 280,
          borderRadius: "4px 2px 2px 4px",
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: 5,
          padding: "5px 4px",
        }}
      >
        {autosys_products.map((d, idx) => (
          <div
            key={`${d?.value}_${idx}`}
            className="d-flex align-items-center justify-content-center cursor-pointer"
            style={{
              backgroundColor: "#F5F5F5",
              borderRadius: 2,
              padding: "4px 5px 4px 6px",
            }}
          >
            {d.sku}
          </div>
        ))}
      </div>
    );
  }

  const shrinkArray = () => {
    let arr = [];

    for (var i = 0; i < 12; i++) {
      arr.push(autosys_products[i]);
    }
    return arr;
  };
  return (
    <Popover
      style={{
        width: 407,
      }}
      placement="left"
      title={
        <div className="d-flex align-items-center justify-content-between py-2">
          <div className="font-semi-bold text-base text-black">Kodai</div>
          <AiOutlineClose
            className="cursor-pointer text-black"
            onClick={() => setOpen(false)}
          />
        </div>
      }
      content={
        open ? (
          <PopoverModal
            products={autosys_products.map((d) => ({
              value: d.sku,
            }))}
          />
        ) : (
          ""
        )
      }
      visible={open}
      trigger="click"
    >
      <div
        style={{
          border: "1px solid #F2F2F2",
          backgroundColor: "#fff",
          borderRadius: "4px 2px 2px 4px",
          padding: "5px 4px",
          maxWidth: 280,
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: 5,
          }}
        >
          {shrinkArray().map((d) => (
            <div
              key={d?.value}
              className="d-flex align-items-center justify-content-center cursor-pointer text-sm"
              style={{
                backgroundColor: "#F5F5F5",
                borderRadius: 2,
                padding: "4px 5px 4px 6px",
                filter: open ? "blur(3px)" : "none",
              }}
            >
              {d.sku}
            </div>
          ))}
        </div>
        <div>
          <div
            className="d-flex align-items-center justify-content-center cursor-pointer text-orange pt-3 pb-2"
            onClick={() => setOpen(true)}
          >
            <IconExpand className="text-xl text-orange mr-2" />
            Išplėsti vaizdą
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default TableProductPreview;
