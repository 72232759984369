import React, { useState } from "react";
import InputText from "../../common/InputText";
import {
  createLeasing,
  updateLeasing,
} from "../../../redux/Actions/leasingsAction";
import Spinner from "../../common/Spinner";
import { SelectForm } from "../../common/Buttons";
import RangePickerComp from "../../common/RangePickerComp";
import TextInputModal from "../../common/TextInputModal";
import moment from "moment";
import readXlsxFile from "read-excel-file";
import { useDispatch } from "react-redux";
import useFetch from "../../../helpers/useFetch";
import { getDateFormat } from "../../../helpers/Utils";
import ModalSpecial from "../../Layout/Modals/ModalSpecial";
import ButtonSecondary from "../../common/ButtonSecondary";
import { useMemo } from "react";

function LeasingEditModal({
  leasing_id,
  onClose,
  families,
  searchFamilies,
  brands,
  searchBrands,
  products,
  searchProducts,
}) {
  const dispatch = useDispatch();
  const [loading_leasing, set_loading_leasing] = useState(false);
  const [errors, setErrors] = useState({});
  const { value, setValue, loading, error } = useFetch(
    `/api/v1/leasings/${leasing_id || "new"}`,
    {},
    [leasing_id]
  );

  const old_data = useMemo(() => {
    let defaultData = {
      families: [],
      brands: [],
      products: [],
    };
    if (!value?.id) return defaultData;
    defaultData.families = value.leasing_families_attributes
      .filter((d) => !d._destroy)
      .filter((d) => d.id)
      .map((n) => ({
        value: n.family_id,
        label: n.title,
      }));
    defaultData.brands = value.leasing_brands_attributes
      .filter((d) => !d._destroy)
      .filter((d) => d.id)
      .map((n) => ({
        value: n.brand_id,
        label: n.title,
      }));
    defaultData.products = value.leasing_products_attributes
      .filter((d) => !d._destroy)
      .filter((d) => d.id || d.imported)
      .map((n) => ({
        value: n.product_id,
        label: n.title,
      }));
    return defaultData;
  }, [value?.id, loading_leasing]);

  const onSubmit = (e) => {
    e.preventDefault();
    setErrors({});
    let error_state = {};
    if (!value.title) {
      error_state.title = "Įveskite pavadinimą";
    }
    if (value.price_from && parseFloat(value.price_from).toFixed(2) < 1) {
      error_state.price_from = "Kaina turi būti didesnė už 1";
    }

    if (
      value.price_to &&
      parseFloat(value.price_from) >= parseFloat(value.price_to)
    ) {
      error_state.price_to = "Kaina turi būti didesnė už " + value.price_from;
    }
    if (Object.entries(error_state).length > 0) {
      return setErrors(error_state);
    }

    const variables = {
      leasing: {
        ...value,
        leasing_products_attributes: value.leasing_products_attributes.filter(
          (d) => (d.id ? d._destroy : true)
        ),
        leasing_brands_attributes: value.leasing_brands_attributes.filter((d) =>
          d.id ? d._destroy : true
        ),
        leasing_families_attributes: value.leasing_families_attributes.filter(
          (d) => (d.id ? d._destroy : true)
        ),
      },
    };
    delete variables.leasing.leasing_brands;
    delete variables.leasing.leasing_families;
    delete variables.leasing.leasing_products;
    if (leasing_id) {
      dispatch(updateLeasing(value.id, variables)).then((res) => {
        if (res) return onClose();
      });
    } else {
      dispatch(createLeasing(variables)).then((res) => {
        if (res) return onClose();
      });
    }
  };

  const typeChange = (e) => {
    setValue((prev) => ({
      ...prev,
      [e.name]: e.value,
    }));
  };

  const inputChange = (e) => {
    setValue((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const selectChange = (value, name, attr) => {
    setValue((prev) => {
      const new_ids = value.map((d) => {
        const item = prev[name].find((s) => s[attr] == d);

        if (item) {
          return {
            id: item.id,
            [attr]: item[attr],
            leasing_id,
          };
        } else {
          return {
            id: null,
            [attr]: d,
            leasing_id,
          };
        }
      });
      prev[name]
        .filter((d) => !value.includes(d[attr]))
        .forEach((d) => {
          new_ids.push({
            id: d.id,
            _destroy: 1,
            [attr]: d[attr],
            leasing_id,
          });
        });

      return {
        ...prev,
        [name]: new_ids,
      };
    });
  };

  const dateChange = (args, field) => {
    setValue((prev) => ({
      ...prev,
      leasing_start: args[0],
      leasing_end: args[1],
    }));
  };

  const fileUploadClick = (e) => {
    e.preventDefault();
    document.getElementById("leasingXls").click();
  };

  const fileSelect = (e) => {
    set_loading_leasing(true);

    readXlsxFile(e.target.files[0]).then((rows) => {
      let query = { identifiers: [] };
      rows.map((i) => {
        query.identifiers.push(i[0]);
      });
      if (query !== "") {
        fetch(`/api/v1/leasings/get_products`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(query),
        })
          .then((response) => response.json())
          .then((response) => {
            setValue((prev) => {
              let old = { ...prev };
              response.forEach((d) => {
                if (
                  !old.leasing_products_attributes.find(
                    (s) => s.product_id == d.id
                  )
                ) {
                  old.leasing_products_attributes.push({
                    product_id: d.id,
                    title: d.title,
                    imported: true,
                  });
                }
              });
              return old;
            });
            set_loading_leasing(false);
          });
      }
    });
    e.target.value = "";
  };

  return (
    <ModalSpecial
      style={{
        width: 650,
      }}
      text={!leasing_id ? "Kurti naują taisyklę" : "Redaguoti taisyklę"}
      onClose={onClose}
    >
      {loading ? (
        <div className="bg-silver py-3 d-flex align-items-center justify-content-center">
          <Spinner />
        </div>
      ) : error ? (
        <div>{JSON.stringify(error)}</div>
      ) : (
        <>
          <div className={loading_leasing || loading_leasing ? "d-none " : ""}>
            <div className="row mt-2">
              <div className="col-12">
                <TextInputModal
                  labelClass="input-modal-label"
                  text="Lizingo akcijos pavadinimas"
                  name="title"
                  required={true}
                  value={value.title ? value.title : ""}
                  onChange={(e) => inputChange(e)}
                  warning={errors.title}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-6">
                <InputText
                  width={"0.75rem"}
                  number="1"
                  placeholder="Tekstas"
                  name="leasing_text"
                  value={value.leasing_text ? value.leasing_text : ""}
                  onChange={(e) => inputChange(e)}
                />
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <div>
                        <label className={"editLabelForm"}>Prioritetas</label>
                      </div>
                      <div className="d-flex">
                        <button
                          className={
                            "btn btn-custom-filter small " +
                            (value.priority === 1 ? "active" : "")
                          }
                          onClick={() =>
                            typeChange({ name: "priority", value: 1 })
                          }
                        >
                          1
                        </button>
                        <button
                          className={
                            "btn btn-custom-filter small " +
                            (value.priority === 2 ? "active" : "")
                          }
                          onClick={() =>
                            typeChange({ name: "priority", value: 2 })
                          }
                        >
                          2
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <div>
                        <label className={"editLabelForm"}>Mėnesiai</label>
                      </div>
                      <div className="d-flex">
                        <button
                          className={
                            "btn btn-custom-filter small " +
                            (value.months === 10 ? "active" : "")
                          }
                          onClick={() =>
                            typeChange({ name: "months", value: 10 })
                          }
                        >
                          10
                        </button>
                        <button
                          className={
                            "btn btn-custom-filter small " +
                            (value.months === 24 ? "active" : "")
                          }
                          onClick={() =>
                            typeChange({ name: "months", value: 24 })
                          }
                        >
                          24
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-6">
                <div className={`form-group`}>
                  <div>
                    <label className="editLabelForm">Kaina</label>
                  </div>
                  <div className="d-flex">
                    <div>
                      <TextInputModal
                        type="number"
                        name={"price_from"}
                        className="form-control"
                        placeholder={"Nuo"}
                        style={{ fontSize: `0.75rem` }}
                        value={value.price_from ? value.price_from : ""}
                        onChange={(e) => inputChange(e)}
                        warning={errors.price_from}
                      />
                    </div>
                    <span className="p-1"> - </span>
                    <div>
                      <TextInputModal
                        type="number"
                        name={"price_to"}
                        className="form-control"
                        placeholder={"Iki"}
                        style={{ fontSize: `0.75rem` }}
                        value={value.price_to ? value.price_to : ""}
                        onChange={(e) => inputChange(e)}
                        warning={errors.price_to}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className={`form-group`}>
                  <label className="editLabelForm">Galiojimo data</label>
                  <div className="datepicker-sm">
                    <RangePickerComp
                      placeholder={["Pradžios laikas", "Pabaigos laikas"]}
                      value={[
                        getDateFormat(value.leasing_start),
                        getDateFormat(value.leasing_end),
                      ]}
                      onChange={(_, args) => dateChange(args, "created_at")}
                      disabledDate={(d) =>
                        !d || d.isBefore(moment().format("YYYY-MM-DD"))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2 form-group">
              <div className="col-12">
                <SelectForm
                  placeholder="Taisyklė (kategorija)"
                  mode="multiple"
                  onlyPlaceholder="Pasirinkti"
                  value={
                    value.leasing_families_attributes
                      ?.filter((d) => !d._destroy)
                      ?.map((n) => n.family_id) || []
                  }
                  options={[
                    ...families.map((n) => ({
                      value: n.id,
                      label: n.name,
                    })),
                    ...old_data.families,
                  ]}
                  onChange={(a) =>
                    selectChange(a, "leasing_families_attributes", "family_id")
                  }
                  onSearch={(e) => searchFamilies(e)}
                />
              </div>
            </div>
            <div className="row mt-2 form-group">
              <div className="col-12">
                <SelectForm
                  placeholder="Taisyklė (gamintojas)"
                  mode="multiple"
                  onlyPlaceholder="Pasirinkti"
                  value={
                    value.leasing_brands_attributes
                      ?.filter((d) => !d._destroy)
                      ?.map((n) => n.brand_id) || []
                  }
                  options={[
                    ...brands.map((n) => ({
                      value: n.id,
                      label: n.title,
                    })),
                    ...old_data.brands,
                  ]}
                  onChange={(a) =>
                    selectChange(a, "leasing_brands_attributes", "brand_id")
                  }
                  onSearch={(e) => searchBrands(e)}
                />
              </div>
            </div>
            <div className="row mt-2 form-group">
              <div className="col-12">
                <SelectForm
                  placeholder="Taisyklė (prekė)"
                  mode="multiple"
                  onlyPlaceholder="Pasirinkti"
                  value={
                    value.leasing_products_attributes
                      ?.filter((d) => !d._destroy)
                      ?.map((n) => n.product_id) || []
                  }
                  options={[
                    ...products.map((n) => ({
                      value: n.id,
                      label: n.title + ", " + n.identifier,
                    })),
                    ...old_data.products,
                  ]}
                  onChange={(a) =>
                    selectChange(a, "leasing_products_attributes", "product_id")
                  }
                  onSearch={(e) => searchProducts(e)}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12 text-right">
                <input
                  type="file"
                  id="leasingXls"
                  style={{ display: "none" }}
                  onChange={(e) => fileSelect(e)}
                />
                <a href="#" onClick={(e) => fileUploadClick(e)}>
                  Įkelti .xslx
                </a>
              </div>
            </div>
          </div>
          <div
            style={{
              width: 200,
            }}
          >
            <ButtonSecondary outline={true} onClick={onSubmit}>
              Išsaugoti
            </ButtonSecondary>
          </div>
        </>
      )}
    </ModalSpecial>
  );
}

export default LeasingEditModal;
