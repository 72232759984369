import React from "react";
import styled from "styled-components";
import { Label } from "../global";

export default function InputSwitchComponent({
  label,
  name,
  options = [],
  value,
  onChange,
  disabled = false,
  touches = false,
}) {
  const options_total = options.length;
  return (
    <>
      {label && <Label disabled={disabled}>{label}</Label>}
      <InputSwitchComponentStyled>
        {options.map((d, idx) => (
          <div
            key={`${idx}_${d.value}`}
            className={`block ${
              d.value == value ? "active" : ""
            } cursor-pointer ${!value ? "extra_border" : ""} ${
              options_total > 2
                ? idx + 1 > 1 && idx + 1 < options_total
                  ? "extra_border"
                  : ""
                : ""
            } ${touches ? (idx == 0 ? "touches" : "") : ""}`}
            onClick={
              disabled
                ? () => console.log("DISABLED")
                : () => {
                    onChange({ target: { name, value: d.value } });
                  }
            }
          >
            {d.label}
          </div>
        ))}
      </InputSwitchComponentStyled>
    </>
  );
}

const InputSwitchComponentStyled = styled.div`
  display: flex;
  .block:first-child {
    border-radius: 2px 0px 0px 2px;
    border-width: 1px 0px 1px 1px;
  }
  .block.touches {
    border-radius: 0px 0px 0px 0px;
  }
  .block:last-child {
    border-width: 1px 1px 1px 0px;
    border-radius: 0px 2px 2px 0px;
  }
  .block {
    flex: 1;
    padding: 8px 10px;
    border-width: 1px 0px 1px 0px;
    border-style: solid;
    border-color: #d9d9d9;
    background: #fcfcfc;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #6c6c6c;
    &.extra_border {
      border-width: 1px;
    }
    &.active {
      border-width: 1px;
      border-color: #fe9c00;
      background: #fff6e8;
      color: #fe9c00;
    }
  }
`;
