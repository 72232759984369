import React from "react";
import {
  ButtonDelete,
  ButtonEdit,
  EditElementClick,
} from "../../common/Buttons";
import { GoPrimitiveDot } from "react-icons/go";

function TableColumnSms({ id, item, onDelete, index, onAction }) {
  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle">
        <GoPrimitiveDot
          style={item.identifier ? { color: "green" } : { color: "red" }}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", index)}
          name={item.message}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", index)}
          name={item.body}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", index)}
          name={item.identifier}
        />
      </td>
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonEdit onEdit={() => onAction("edit", index)} />
      </td>
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonDelete onDelete={() => onDelete(id)} />
      </td>
    </tr>
  );
}

export default TableColumnSms;
