import {
  LOADING_PRESTA_SERVICES,
  GET_PRESTA_SERVICES,
  POST_SERVICE_ITEM,
  UPDATE_SERVICE_ITEM,
  DELETE_SERVICE_ITEM,
} from "../Actions/types";

const initialState = {
  serviceItems: [],
  totals: 0,
  loading_service_items: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_PRESTA_SERVICES:
      return {
        ...state,
        loading_service_items: true
      };
    case GET_PRESTA_SERVICES:
      return {
        ...state,
        serviceItems: action.payload.data,
        totals: action.payload.totals,
        loading_service_items: false
      };
    case POST_SERVICE_ITEM:
      let updateData = [...state.serviceItems];
      updateData.unshift(action.payload);
      return {
        ...state,
        serviceItems: updateData
      };
    case UPDATE_SERVICE_ITEM:
      let getData = [...state.serviceItems];
      const updateElementId = element => element.id === action.payload_id;
      const findIndex = getData.findIndex(updateElementId);
      getData[findIndex] = action.payload;
      return {
        ...state,
        serviceItems: getData
      };
    case DELETE_SERVICE_ITEM:
      return {
        ...state,
        serviceItems: [...state.serviceItems].filter(
          filterItem => filterItem.id !== action.payload
        ),
        loading_service_items: false
      };
    default:
      return state;
  }
}
