import { message } from "antd";
import axios from "axios";
import useAsync from "./useAsync";

export default function useFetch(url, options = {}, dependencies = []) {
  return useAsync(() => {
    if (dependencies.length > 0) {
      console.log({dependencies})
      if (dependencies.some((d) => d == 'skip')) {
        return Promise.resolve({
          success: false,
        });
      }
    }
    return axios
      .get(url, options)
      .then((res) => {
        if (res.data) {
          return Promise.resolve(res.data);
        }
      })
      .catch((err) => {
        message.error("Įvyko klaida")
        return Promise.reject("Įvyko klaida");
      });
  }, dependencies);
}
