import Axios from "axios";
import {
  ADD_STATUS_JOB,
  GET_STATUSES,
  GET_STATUS,
  UPDATE_STATUS_JOB,
  REMOVE_STATUS_JOB,
  CHANDE_STATUS_JOB,
  LOADING_STATUSES,
  GET_ERRORS,
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

const success = () => {
  if (!document.getElementById("statusAlert-s")) return;
  document.getElementById("statusAlert-s").style.display = "block";
  setTimeout(
    () => (document.getElementById("statusAlert-s").style.display = "none"),
    5000
  );
  // document.getElementById("modalClose").click();
};
const error = () => {
  if (!document.getElementById("statusAlert-e")) return;
  document.getElementById("statusAlert-e").style.display = "block";
  setTimeout(
    () => (document.getElementById("statusAlert-e").style.display = "none"),
    5000
  );
};

export const addStatusJob = (data) => (dispatch) => {
  dispatch({
    type: ADD_STATUS_JOB,
    payload: data,
  });
};

export const updateStatusJob = (data) => (dispatch) => {
  const status_job = {
    status_job: {
      title: data.title,
      klass_name: data.klass_name,
      start_at: data.start_at,
      end_at: data.end_at,
    },
  };

  if (data.created_at) {
    return Axios.put(
      `/api/v1/statuses/${data.show_id}/status_jobs/${data.id}`,
      status_job
    ).then((response) => {
      dispatch({
        type: UPDATE_STATUS_JOB,
        payload: {
          data: response.data,
          index: data.index,
        },
      });
      success();
    });
  } else {
    return Axios.post(
      `/api/v1/statuses/${data.show_id}/status_jobs`,
      status_job
    )
      .then((response) => {
        dispatch({
          type: UPDATE_STATUS_JOB,
          payload: {
            data: response.data,
            index: data.index,
          },
        });
        success();
        let newObj = {};
        newObj.error = false;
        newObj.data = response.data;

        return newObj;
      })
      .catch((err) => {
        if (err.response.data) {
          let newObj = {};
          newObj.error = true;
          newObj.data = err.response.data;

          error();
          return newObj;
        }
      });
  }
};

export const removeStatusJob = (ID, data) => (dispatch) => {
  if (data.created_at) {
    Axios.delete(`/api/v1/statuses/${data.show_id}/status_jobs/${ID}`)
      .then((response) => {
        dispatch({
          type: REMOVE_STATUS_JOB,
          payload: ID,
        });
        success();
      })
      .catch((err) => error());
  } else {
    dispatch({
      type: REMOVE_STATUS_JOB,
      payload: ID,
    });
  }
};

export const changeStatusJob = (array) => (dispatch) => {
  dispatch({
    type: CHANDE_STATUS_JOB,
    payload: array,
  });
};

export const getStatuses = (page, pageCount) => (dispatch) => {
  dispatch(setStatusesLoading());
  Axios.get(`/api/v1/statuses?page=${page}&per_page=${pageCount}`)
    .then((res) => {
      dispatch({
        type: GET_STATUSES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getStatusesOptions = () => {
  return Axios.get("/api/v1/statuses?page=1&per_page=9999").then((res) => {
    return res.data;
  });
};

export const getStatusShow = (showId) => (dispatch) => {
  Axios.get(`/api/v1/statuses/${showId}/status_jobs`)
    .then((res) => {
      dispatch({
        type: GET_STATUS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setStatusesLoading = () => {
  return {
    type: LOADING_STATUSES,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
