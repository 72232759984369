import { message } from "antd";
import Axios from "axios";
import {
  antdMessageError,
  antdMessageInfo,
  antdMessageSuccess,
  errorHandler,
} from "../../helpers/Utils";
import {
  GET_DESCRIPTIONS,
  CLEAR_ERRORS,
  GET_ERRORS,
  LOADING_DESCRIPTIONS,
  LOADING_DESCRIPTION,
  GET_DESCRIPTION,
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

export const getDescriptions =
  (page, pageCount, filterParams) => (dispatch) => {
    dispatch(setDescriptionsLoading());
    Axios.get(
      `/api/v1/descriptions?page=${page}&per_page=${pageCount}${filterParams}`
    )
      .then((res) => {
        dispatch({
          type: GET_DESCRIPTIONS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      });
  };

export const descriptionCallESL = async (ids) => {
  try {
    antdMessageInfo("Generuojama");
    const { data } = await Axios.post(`/api/v1/descriptions/esl_update`, {
      ids,
    });
    if (data.error) throw new Error(data.error);
    antdMessageSuccess();
  } catch (err) {
    antdMessageError(err?.response?.data?.error || err?.message);

    console.log({ err });
  }
};

export const getDescription = (id, store_id) => (dispatch) => {
  dispatch(setDescriptionLoading());
  Axios.get(`/api/v1/descriptions/${id}?store_id=${store_id}`)
    .then((res) => {
      dispatch({
        type: GET_DESCRIPTION,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
    });
};

export const setDescriptionsLoading = () => {
  return {
    type: LOADING_DESCRIPTIONS,
  };
};

export const setDescriptionLoading = () => {
  return {
    type: LOADING_DESCRIPTION,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
