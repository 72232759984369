import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import { onChangeUseState } from "../../helpers/GlobalFunctions";
import { createReport } from "../../redux/Actions/reportAction";
import { CheckBoxInput, SelectForm } from "../common/Buttons";
import InputSwitchComponent from "../common/InputSwitchComponent";
import EmailSetterInput from "../common/EmailSetterInput";
import CalendarDayInput from "../common/CalendarDayInput";
import ButtonSecondary from "../common/ButtonSecondary";
import { getDateFormat } from "../../helpers/Utils";
import DataPickerInputModal from "../common/DataPickerInputModal";
import RangePickerComp from "../common/RangePickerComp";
import { DebounceSelect } from "../../components/common";
import Axios from "axios";
import { reportDepFullOptions } from "../../helpers/GlobalOptions";

const CreateReportBlock = ({ close }) => {
  const dispatch = useDispatch();
  const { options } = useSelector((state) => state.custom);
  const initialState = {
    active: true,
    method: "auto",
    supplier_id: undefined,
    family_id: undefined,
    brand_id: undefined,
    //
    departments: [],
    emails: [],
    period: "month",
    date: "",
    report_type: "residues",
    format: "xls",
  };

  const initialColumnsState = {
    product_code: false,
    product_name: false,
    product_long_name: false,
    manufacturer: false,
    barcode: false,
    income_doc_no: false,
    income_doc_date: false,
    quantity: false,
    amount_without_vat: false,
    department: false
  };

  const [variables, setVariables] = useState(initialState);
  const [columns, setColumns] = useState(initialColumnsState);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const errorHandler = (val) => {
    let errors = {};
    console.log("wtf: ", val);
    if (
      [val.supplier_id, val.brand_id, val.family_id].filter((d) => d).length ==
      0
    ) {
      errors.supplier_id = "Pasirinkite bent vieną iš trijų";
      errors.family_id = "Pasirinkite bent vieną iš trijų";
      errors.brand_id = "Pasirinkite bent vieną iš trijų";
    }
    if (val.departments.length == 0) errors.departments = "Nepasirinkta";
    if (val.emails.length == 0) errors.emails = "Nepasirinkta";
    if (val.method == "auto") {
      if (!val.date) {
        errors.date = "Nepasirinkta";
      }
    }
    if (!val.report_type) errors.report_type = "Nepasirinkta";
    return errors;
  };

  const onSave = async () => {
    try {
      setLoading(true);
      let params = {
        ...variables,
        columns,
      };
      console.log({ variables });
      if (variables.period !== "other") {
        // params.period = null;
        params.dates_to_take = null;
      }
      const custom_errors = errorHandler(params);
      if (Object.entries(custom_errors).length > 0) {
        return setErrors(custom_errors);
      }
      const { success } = await dispatch(createReport(params));
      if (success) {
        close();
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const set_report_type = (val) => {
    let value = variables.report_type;
    if (value == "all") {
      value = val == "residues" ? "sales" : "residues";
    } else if (val == value) {
      value = "";
    } else {
      if (value == "") {
        value = val;
      } else if (value !== val) {
        value = "all";
      }
    }
    onChangeUseState({ target: { value, name: "report_type" } }, setVariables);
  };

  return (
    <div>
      <div className="col-12">
        <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
          <div className="font-weight-bold text-black text-l">
            Naujo tiekėjų ataskaita
          </div>
          <AiOutlineClose
            className="cursor-pointer text-l"
            color="#C9C9C9"
            onClick={() => close()}
          />
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-2-5 pr-2">
              <InputSwitchComponent
                label="Aktyvi"
                name="active"
                options={[
                  {
                    label: "Taip",
                    value: true,
                  },
                  {
                    label: "Ne",
                    value: false,
                  },
                ]}
                value={variables.active}
                onChange={(value) => onChangeUseState(value, setVariables)}
              />
            </div>
            <div className="col-2-5  px-2">
              <InputSwitchComponent
                label="Būdas"
                name="method"
                options={[
                  {
                    label: "Rankinis",
                    value: "manual",
                  },
                  {
                    label: "Automatinis",
                    value: "auto",
                  },
                ]}
                value={variables.method}
                onChange={(e) => {
                  const { name, value } = e.target;
                  setVariables((prev) => ({
                    ...prev,
                    [name]: value,
                    date: "",
                  }));
                }}
              />
            </div>
            <div className="col-2-5 px-2">
              <SelectForm
                label="Tiekėjas"
                value={variables.supplier_id}
                options={options.suppliers}
                dropdownMatchSelectWidth={false}
                onChange={(value) =>
                  onChangeUseState(
                    { target: { value, name: "supplier_id" } },
                    setVariables
                  )
                }
                error={errors.supplier_id}
              />
            </div>
            <div className="col-2-5 px-2">
              <DebounceSelect
                label="Prekių kategorija"
                showSearch={true}
                fetchOptions={async (value) => {
                  try {
                    let api_url = `/api/v1/families/all_families?[q][name_cont]=${value}`;
                    const { data } = await Axios.get(api_url);
                    const valArr = data?.data?.filter((d) => d.name) || [];
                    return [
                      {
                        label: "Nepasirinkti",
                        value: "",
                      },
                      ...valArr.map((e) => {
                        return {
                          ...e,
                          value: e.id,
                          label: e.name,
                        };
                      }),
                    ];
                  } catch (err) {
                    errorHandler(err);
                    return [];
                  }
                }}
                style={{
                  width: "100%",
                }}
                value={variables.family_id || undefined}
                onChange={(value) => {
                  onChangeUseState(
                    {
                      target: { value: value.value || null, name: "family_id" },
                    },
                    setVariables
                  );
                }}
                error={errors.family_id}
              />
            </div>
            <div className="col-2-5 pl-2">
              <DebounceSelect
                label="Prekės ženklas"
                showSearch={true}
                fetchOptions={async (value) => {
                  try {
                    let api_url = `/api/v1/brands/all_brands?[q][title_cont]=${value}`;
                    const { data } = await Axios.get(api_url);
                    const valArr = data?.data?.filter((d) => d.title) || [];
                    return [
                      {
                        label: "Nepasirinkti",
                        value: "",
                      },
                      ...valArr.map((e) => {
                        return {
                          ...e,
                          value: e.id,
                          label: e.title,
                        };
                      }),
                    ];
                  } catch (err) {
                    errorHandler(err);
                    return [];
                  }
                }}
                style={{
                  width: "100%",
                }}
                value={variables.brand_id || undefined}
                onChange={(value) => {
                  onChangeUseState(
                    {
                      target: { value: value.value || null, name: "brand_id" },
                    },
                    setVariables
                  );
                }}
                error={errors.brand_id}
              />
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-2-5 pr-2">
              <SelectForm
                label="Padalinys"
                value={variables.departments}
                options={reportDepFullOptions}
                mode="multiple"
                dropdownMatchSelectWidth={false}
                onChange={(value) => {
                  let val = value;
                  if (val.includes("suminis")) {
                    val = ["suminis"];
                  }
                  onChangeUseState(
                    { target: { value: val, name: "departments" } },
                    setVariables
                  );
                }}
                error={errors.departments}
              />
            </div>
            <div className="col-2-5 px-2">
              <EmailSetterInput
                emails={variables.emails}
                onChangeEmails={(value) =>
                  onChangeUseState(
                    {
                      target: {
                        name: "emails",
                        value,
                      },
                    },
                    setVariables
                  )
                }
                error={errors.emails}
              />
            </div>
            <div className="col-2-5 px-2">
              <InputSwitchComponent
                label="Laikotarpis"
                name="period"
                options={[
                  {
                    value: "month",
                    label: "Mėnesis",
                  },
                  {
                    value: "week",
                    label: "Savaitė",
                  },
                  {
                    value: "other",
                    label: "Kita",
                  },
                ].filter((d) =>
                  variables.method == "auto" ? d.value !== "other" : true
                )}
                value={variables.period}
                onChange={(value) => {
                  onChangeUseState(value, setVariables);
                  onChangeUseState(
                    { target: { value: "", name: "date" } },
                    setVariables
                  );
                }}
              />
            </div>
            {variables.period == "other" ? (
              <>
                <div className="col-2-5 px-2">
                  <DataPickerInputModal
                    text="Diena"
                    value={getDateFormat(variables.date)}
                    onChange={(_, value) =>
                      onChangeUseState(
                        { target: { value, name: "date" } },
                        setVariables
                      )
                    }
                    error={errors.date}
                  />
                </div>
                <div className="col-2-5 px-2">
                  <label className={`editLabelForm text-bold my-1 `}>
                    Datos imtis
                  </label>
                  <RangePickerComp
                    placeholder={["Nuo", "Iki"]}
                    value={
                      !variables.dates_to_take
                        ? ["", ""]
                        : [
                            getDateFormat(
                              variables.dates_to_take.split("_")[0]
                            ),
                            getDateFormat(
                              variables.dates_to_take.split("_")[1]
                            ),
                          ]
                    }
                    onChange={(args, value) => {
                      console.log({ args, value });
                      onChangeUseState(
                        {
                          target: {
                            value: value.join("_"),
                            name: "dates_to_take",
                          },
                        },
                        setVariables
                      );
                    }}
                    disabledDate={(d) => !d}
                  />
                </div>
              </>
            ) : (
              <div className="col-2-5 px-2">
                <CalendarDayInput
                  method={variables.method}
                  period={variables.period}
                  value={variables.date}
                  onChange={(value) =>
                    onChangeUseState(
                      { target: { value, name: "date" } },
                      setVariables
                    )
                  }
                  error={errors.date}
                />
              </div>
            )}
            <div className="col-2-5 pl-2">
              <div className="form-group">
                <label
                  className={`editLabelForm text-bold my-1 ${
                    errors.report_type ? "text-danger" : ""
                  }`}
                >
                  Tipas
                </label>
                <div className="d-flex justify-content-between mt-2">
                  <CheckBoxInput
                    text="Likučiai"
                    checked={["residues", "all"].includes(
                      variables.report_type
                    )}
                    onChange={() => set_report_type("residues")}
                  />
                  <CheckBoxInput
                    text="Pardavimai"
                    checked={["sales", "all"].includes(variables.report_type)}
                    onChange={() => set_report_type("sales")}
                  />
                </div>
                {errors.report_type && (
                  <div className="text-danger">{errors.report_type}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 pt-3 bg-silver">
        <div className="col-12">
          <div className="row">
            <div className="col-2-5">
              <CheckBoxInput
                text="Prekės kodas"
                checked={columns.product_code}
                onChange={(e) =>
                  onChangeUseState(
                    {
                      target: { value: e.target.checked, name: "product_code" },
                    },
                    setColumns
                  )
                }
              />
            </div>
            <div className="col-2-5">
              <CheckBoxInput
                text="Prekės pavadinimas"
                checked={columns.product_name}
                onChange={(e) =>
                  onChangeUseState(
                    {
                      target: { value: e.target.checked, name: "product_name" },
                    },
                    setColumns
                  )
                }
              />
            </div>
            <div className="col-2-5">
              <CheckBoxInput
                text="Prekės ilgas pavadinimas"
                checked={columns.product_long_name}
                onChange={(e) =>
                  onChangeUseState(
                    {
                      target: {
                        value: e.target.checked,
                        name: "product_long_name",
                      },
                    },
                    setColumns
                  )
                }
              />
            </div>
            <div className="col-2-5">
              <CheckBoxInput
                text="Gamintojas"
                checked={columns.manufacturer}
                onChange={(e) =>
                  onChangeUseState(
                    {
                      target: { value: e.target.checked, name: "manufacturer" },
                    },
                    setColumns
                  )
                }
              />
            </div>
            <div className="col-2-5">
              <CheckBoxInput
                text="Barkodas"
                checked={columns.barcode}
                onChange={(e) =>
                  onChangeUseState(
                    { target: { value: e.target.checked, name: "barcode" } },
                    setColumns
                  )
                }
              />
            </div>
            <div className="col-2-5">
              <CheckBoxInput
                text="Pajamavimo dok. nr."
                checked={columns.income_doc_no}
                onChange={(e) =>
                  onChangeUseState(
                    {
                      target: {
                        value: e.target.checked,
                        name: "income_doc_no",
                      },
                    },
                    setColumns
                  )
                }
              />
            </div>
            <div className="col-2-5">
              <CheckBoxInput
                text="Pajamavimo dok. data"
                checked={columns.income_doc_date}
                onChange={(e) =>
                  onChangeUseState(
                    {
                      target: {
                        value: e.target.checked,
                        name: "income_doc_date",
                      },
                    },
                    setColumns
                  )
                }
              />
            </div>
            <div className="col-2-5">
              <CheckBoxInput
                text="Kiekis"
                checked={columns.quantity}
                onChange={(e) =>
                  onChangeUseState(
                    { target: { value: e.target.checked, name: "quantity" } },
                    setColumns
                  )
                }
              />
            </div>
            <div className="col-2-5">
              <CheckBoxInput
                text="Suma be PVM"
                checked={columns.amount_without_vat}
                onChange={(e) =>
                  onChangeUseState(
                    {
                      target: {
                        value: e.target.checked,
                        name: "amount_without_vat",
                      },
                    },
                    setColumns
                  )
                }
              />
            </div>
            <div className="col-2-5">
              <CheckBoxInput
                text="Padalinys"
                checked={columns.department}
                onChange={(e) =>
                  onChangeUseState(
                    {
                      target: {
                        value: e.target.checked,
                        name: "department",
                      },
                    },
                    setColumns
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="mt-2" style={{ width: 400 }}>
            <InputSwitchComponent
              label="Formatas"
              options={[
                {
                  value: "xls",
                  label: "XLS",
                },
                {
                  value: "xml",
                  label: "XML",
                },
                {
                  value: "csv",
                  label: "CSV",
                },
              ]}
              name="format"
              value={variables.format}
              onChange={(value) => onChangeUseState(value, setVariables)}
            />
          </div>
          <div>
            <label className="editLabelForm text-bold my-1 invisible">
              button
            </label>
            <div
              style={{
                width: 217,
                height: 32,
              }}
            >
              <ButtonSecondary onClick={() => onSave()} loading={loading}>
                Nauja ataskaita
              </ButtonSecondary>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateReportBlock;

//
