import React, { Fragment } from "react";

import OrderRowsSimpleComponent from "./order_rows_simple";
import { CheckBoxInput } from "../../../Buttons";
import { FaPlus } from "react-icons/fa";

function RowsInShipment({
  handleCheckBoxOrdersSelected,
  selectedOrderRows,
  shipmentRowForStore,
  shipmentRowForStoreFunction,
  at_the_store,
  order_rows,
}) {
  // at_the_store = true
  return (
    <Fragment>
      <div className="text-bold pt-2 d-flex justify-content-between">
        <span>Užsakymo prekės</span>
        <span>
          {at_the_store && (
            <FaPlus
              title="Atidaryti"
              className="cursor-pointer text-success"
              onClick={() => shipmentRowForStoreFunction("push")}
            />
          )}
        </span>
      </div>

      <span style={{ fontWeight: "400" }}>
        <table className="table table-sm">
          <tbody>
            {at_the_store ? (
              <Fragment>
                {shipmentRowForStore.map((item, index) => (
                  <OrderRowsSimpleComponent
                    key={index}
                    item={item}
                    index={index}
                    shipmentRowForStoreFunction={shipmentRowForStoreFunction}
                  />
                ))}
              </Fragment>
            ) : (
              <Fragment>
                {order_rows.map((e) => (
                  <tr key={e.id}>
                    <td className="align-middle">
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: 32 }}
                      >
                        <CheckBoxInput
                          checked={selectedOrderRows.includes(e.id)}
                          onChange={() => handleCheckBoxOrdersSelected(e.id)}
                        />
                      </div>
                    </td>
                    <td className="align-middle text-center" style={{ width: "10%" }}>
                      <div>{e.amount || Number(e.quantity)}</div>
                    </td>
                    <td className="align-middle">
                      <div>{e.product_name}</div>
                    </td>
                    <td className="align-middle">
                      <span style={{ whiteSpace: "nowrap" }}>
                        {Number(e.unit_price_tax_incl).toFixed(2) || 0} &#8364;
                      </span>
                    </td>
                  </tr>
                ))}
              </Fragment>
            )}
          </tbody>
        </table>
      </span>
    </Fragment>
  );
}

export default RowsInShipment;
