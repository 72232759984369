import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import styled from "styled-components";

function RenderChart({ history }) {
  const [years, setYears] = useState([]);
  const [data, setData] = useState([]);

  const dateBuilder = () => {
    // const history = [
    //   ["2020-11-01", 0],
    //   ["2020-12-01", 0],
    //   ["2021-01-01", 159.07],
    //   ["2021-02-01", 582.49],
    //   ["2021-03-01", 1521.78],
    // ];
    let carcass = {};
    const randomColor = ["#F7941D", "#A8009D", "#EE311B", "#0198CB"];

    const allYears = [...new Set(history.map((e) => e[0].slice(0, 4)))].map(
      (year, idx) => {
        const color =
          idx <= 3
            ? randomColor[idx]
            : `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        return {
          year,
          color: color,
        };
      }
    );
    allYears.forEach(
      (e) =>
        (carcass[e.year] = {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
        })
    );

    history.forEach(([date, total]) => {
      const filterDate = new Date(date);
      const fullYears = filterDate.getFullYear();
      const fullMonth = filterDate.getMonth() + 1;
      carcass[fullYears][fullMonth] = total;
    });
    const returnChart = Object.entries(carcass)
      .map(([year, days]) => {
        let color = "#000";

        const findYear = allYears.find((e) => e.year == year);
        if (findYear) {
          color = findYear.color;
        }
        return {
          label: year,
          data: Object.values(days),
          fill: false,
          year: year,
          backgroundColor: color,
          borderColor: color,
          pointHoverRadius: 6,
          pointRadius: 5,
          tension: 0.3,
          pointBackgroundColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          borderWidth: 2,
        };
      })
      .sort((a, b) => (a.year < b.year ? 1 : -1));
    setYears(allYears);
    return returnChart;
  };
  useEffect(() => {
    const dataObj = {
      labels: [
        "Sausis",
        "Vasaris",
        "Kovas",
        "Balandis",
        "Gegužė",
        "Birželis",
        "Liepa",
        "Rugpjūtis",
        "Rugsėjis",
        "Spalis",
        "Lapkritis",
        "Gruodis",
      ],
      datasets: dateBuilder(),
    };

    setData(dataObj);
  }, [history]);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {},
    },
    // scales: {
    //   y: [
    //     {
    //       ticks: {
    //         beginAtZero: true,
    //       },
    //     },
    //   ],
    // },
  };
  return (
    <div className="mt-3">
      <div className="d-flex justify-content-between">
        <span className="font-semi-bold" style={{ fontSize: 15 }}>
          Užmokesčio lentelė
        </span>
        <div className="d-flex justify-content-between align-items-center">
          {years.map((e) => (
            <StyledChartYearBlock key={e.year} color={e.color}>
              <div className="cube">
                <div className="fill"></div>
              </div>
              <span>{e.year}</span>
            </StyledChartYearBlock>
          ))}
        </div>
      </div>
      <div>
        <Line data={data} options={options} height={100} />
      </div>
    </div>
  );
}

const StyledChartYearBlock = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  .cube {
    border-radius: 3px;
    border: 2px solid #fff;
    box-shadow: 0px 0px 3px #00000029;
    padding: 3px;
    margin-right: 3px;
    & .fill {
      width: 15px;
      height: 15px;
      background-color: ${(props) => props.color};
    }
  }
  /* span {
    font-size: 13px;
  } */
`;

export default RenderChart;
