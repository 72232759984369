import Axios from "axios";
import React, { useEffect, useState } from "react";
import ContentValidation from "../../components/common/ContentValidation";
import TableFilterBox from "../../components/common/TableFilterBox";
import PaginationComp from "../../components/Dashboard/items/Pagination";
import Container from "../../components/Layout/Container";
import ContentBlock from "../../components/Layout/ContentBlock";
import FilterMenu from "../../components/Layout/FilterMenu";
import { SelectForm } from "../../components/common/Buttons";
import InputText from "../../components/orders/accepted_orders/InputFilter";
import RangePickerComp from "../../components/common/RangePickerComp";
import { getDateFormat } from "../../helpers/Utils";
import TableColumnSales3PL from "../../components/3pl/sales3pl/TableColumnSales3PL";
import ModalShowSales3PL from "../../components/3pl/sales3pl/ModalShowSales3PL";
import { handleKeyDown } from "../../helpers/GlobalFunctions";
import { message } from "antd";

export default function Sales3Pl({ match }) {
  const match_path = match.path;
  const [pageCount, setPageCount] = useState(100);
  const [page, setPage] = useState(1);
  const [filter_string, setFilterString] = useState("");
  const [statuses, setStatuses] = useState([]);
  const [filters, setfilters] = useState({
    sent_date: ["", ""],
    operation_date: ["", ""],
    document_number: "",
    sku: "",
    status: "",
    address: "",
  });

  const returnValueByType = (sale, internal, shipment) => {
    if (match.path == "/3pl/sales") {
      return sale;
    } else if (match.path == "/3pl/internal") {
      return internal;
    }
    return shipment;
  };

  const fetchASC = async () => {
    setLoading(true);
    try {
      const { data } = await Axios.get(
        `/api/v1/${returnValueByType(
          "sales/",
          "sales/inner_sales_index/",
          "sales/bigbox_sales_index/"
        )}?page=${page}&per_page=${pageCount}${filter_string}`
      );
      if (!data || !data?.data) throw new Error("Err");
      setData(data.data);
      setTotals(data.totals);
      setStatuses(data.statuses);
      setLoading(false);
      // setPage(1);
    } catch (err) {
      console.log("Error: ", err);
      setLoading(false);
      // setPage(1);
    }
  };
  useEffect(() => {
    fetchASC();
  }, [page, pageCount, filter_string]);
  useEffect(() => {
    clearFilter();
    fetchASC();
  }, [match_path]);
  useEffect(
    () => onFilter(),
    [filters.sent_date, filters.operation_date, filters.status]
  );

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totals, setTotals] = useState(0);

  const [showModal, setShowModal] = useState(false);

  const onFilter = () => {
    let filterParams = [];

    const { sent_date, operation_date, document_number, sku, status, address } =
      filters;

    if (sent_date[0].length > 0) {
      filterParams.push(`&q[sent_date_gteq]=${sent_date[0]}`);
    }
    if (sent_date[1].length > 0) {
      filterParams.push(`&q[sent_date_lteq]=${sent_date[1]}`);
    }
    if (operation_date[0].length > 0) {
      filterParams.push(`&q[operation_date_gteq]=${operation_date[0]}`);
    }
    if (operation_date[1].length > 0) {
      filterParams.push(`&q[operation_date_lteq]=${operation_date[1]}`);
    }
    if (document_number) {
      filterParams.push(`&q[document_number_cont_any]=${document_number}`);
    }
    if (sku) {
      filterParams.push(`&q[sale_items_sku_cont_any]=${sku}`);
    }
    if (status) {
      filterParams.push(`&q[status_cont_any]=${status}`);
    }
    if (address) {
      filterParams.push(
        `&q[address_company_or_address_firstname_or_address_lastname_or_address_street_or_address_phone_or_address_other_cont]=${address}`
      );
    }

    setFilterString(filterParams.join(""));
    setPage(1);
  };

  const clearFilter = () => {
    setPage(1)
    setfilters({
      sent_date: ["", ""],
      operation_date: ["", ""],
      document_number: "",
      sku: "",
      status: "",
      address: "",
    });
  };
  return (
    <Container
      location={{
        locationName: returnValueByType("Pardavimai", "Vidiniai", "Siuntos"),
        locationUrl: match.url,
      }}
    >
      <ContentBlock>
        <FilterMenu
          length={`${totals || 0} / ${data.length || 0}`}
          onChangeCountPage={setPageCount}
          countPage={pageCount}
          text={returnValueByType("pardavimų", "vidinių", "siuntų")}
          text2={returnValueByType("Pardavimų", "Vidinių", "Siuntų")}
        />
      </ContentBlock>
      <ContentBlock>
        <TableFilterBox
          add={
            returnValueByType(true, true, false)
              ? async () => {
                  const operation_number = prompt("Įveskite numerį", "");
                  if (!operation_number || operation_number?.trim() == "")
                    return;
                  await message.loading(
                    `Importuojamas dokumentas: ${operation_number}`
                  );

                  try {
                    const { data } = await Axios({
                      url: `/api/v1/callbacks/${returnValueByType(
                        "rivile_sale",
                        "rivile_inner_sale",
                        ""
                      )}`,
                      method: "POST",
                      data: {
                        operation_number,
                      },
                      headers: { Authorization: "Bearer h3rs1lu8FJ" },
                    });
                    if (data.message == "success") {
                      console.log("All Data", data);
                      message.success(
                        `Sėkmingai importuotas: ${operation_number}`
                      );
                      return fetchASC();
                    }
                    throw new Error("Err");
                  } catch (err) {
                    return message.error(
                      err?.response?.data?.message
                        ? `${err?.response?.data?.message}: ${operation_number}`
                        : `Įvyko klaida: ${operation_number}`
                    );
                  }
                }
              : false
          }
          clearFilter={clearFilter}
        >
          <div className="px-1">
            <InputText
              value={filters.sku}
              placeholder="Prekės kodas"
              style={{ width: 150, fontSize: "0.75rem" }}
              onKeyDown={(e) => handleKeyDown(e, onFilter)}
              onChange={(e) => {
                setfilters({
                  ...filters,
                  sku: e.target.value || "",
                });
              }}
            />
          </div>
          <div className="px-1">
            <InputText
              value={filters.document_number}
              placeholder="Dokumento numeris"
              style={{ width: 150, fontSize: "0.75rem" }}
              onKeyDown={(e) => handleKeyDown(e, onFilter)}
              onChange={(e) => {
                setfilters({
                  ...filters,
                  document_number: e.target.value || "",
                });
              }}
            />
          </div>
          <div className="px-1">
            <div className="form-group">
              <label className="editLabelForm">Išvežimo data</label>
              <div>
                <RangePickerComp
                  style={{ width: 250 }}
                  placeholder={["Pradžios laikas", "Pabaigos laikas"]}
                  disabledDate={() => {}}
                  value={[
                    getDateFormat(filters.sent_date[0]),
                    getDateFormat(filters.sent_date[1]),
                  ]}
                  onChange={(_, args) => {
                    setfilters({
                      ...filters,
                      sent_date: args || "",
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="px-1">
            <div className="form-group">
              <label className="editLabelForm">Operacijos data</label>
              <div>
                <RangePickerComp
                  style={{ width: 250 }}
                  placeholder={["Pradžios laikas", "Pabaigos laikas"]}
                  disabledDate={() => {}}
                  value={[
                    getDateFormat(filters.operation_date[0]),
                    getDateFormat(filters.operation_date[1]),
                  ]}
                  onChange={(_, args) => {
                    setfilters({
                      ...filters,
                      operation_date: args || "",
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="px-1" style={{ minWidth: "150px" }}>
            <SelectForm
              placeholder="Būsena"
              dropdownMatchSelectWidth={false}
              options={[
                { label: "Visi", value: null },
                ...Object.entries(statuses).map(([key, val]) => ({
                  label: val,
                  value: key,
                })),
              ]}
              value={filters.status || undefined}
              onChange={(e) => {
                setfilters((prev) => ({
                  ...prev,
                  status: e,
                }));
              }}
            />
          </div>
          <div className="px-1">
            <InputText
              value={filters.address}
              placeholder="Klientas"
              style={{ width: 150, fontSize: "0.75rem" }}
              onKeyDown={(e) => handleKeyDown(e, onFilter)}
              onChange={(e) => {
                setfilters({
                  ...filters,
                  address: e.target.value || "",
                });
              }}
            />
          </div>
        </TableFilterBox>
        <div>
          <ContentValidation
            loader={loading}
            array={data}
            alertId_s="ascAlert-s"
            alertId_e="ascAlert-e"
          >
            <div className="table-responsive">
              <table className="table table-sm table-hover mt-3">
                <thead>
                  <tr className="text-muted">
                    {[
                      "Opr. data",
                      "Išs. data",
                      "Dok. nr.",
                      "Klientas",
                      "Parduotuvė",
                      "Sekimo nr.",
                      "P/N",
                      "Būsena",
                      "MScan",
                    ]
                      .filter((d) =>
                        ["Parduotuvė", "MScan", "P/N"].includes(d)
                          ? returnValueByType(true, true, false)
                          : true
                      )
                      .map((title) => (
                        <th key={title || "button"} className="text-black">
                          {title}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((e) => (
                    <TableColumnSales3PL
                      key={`sales_3pl_${e.id}`}
                      item={e}
                      returnValueByType={returnValueByType}
                      statuses={statuses}
                      setShowModal={setShowModal}
                    />
                  ))}
                </tbody>
              </table>
              <PaginationComp
                setPagination={setPage}
                page={page}
                totals={totals}
                pageCount={pageCount}
              />
            </div>
          </ContentValidation>
        </div>
      </ContentBlock>
      {showModal && (
        <ModalShowSales3PL
          returnValueByType={returnValueByType}
          closeModal={() => setShowModal(false)}
          showModal={showModal}
          updateIndex={(response) => {
            setData(data.map((e) => (e.id == response.id ? response : e)));
          }}
        />
      )}
    </Container>
  );
}
