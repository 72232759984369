import React, { useEffect, useState } from "react";
// Styles
import "jquery/dist/jquery.min.js";
import "bootstrap/scss/bootstrap";
import "bootstrap/dist/js/bootstrap.min.js";
import "../stylesheet/App";
import "../stylesheet/tailwind";
import "antd/dist/antd.css";
import {
  autoLogout,
  setCurrentUser,
  loginUserWithAzure,
  logoutUser,
  setAuthenticate,
  userAdditionalInformation,
} from "../redux/Actions/authAction";
import { updateOrderSockets } from "../redux/Actions/orderAction";
import jwt_decode from "jwt-decode";
import { Provider } from "react-redux";
import { createConsumer } from "@rails/actioncable";
import store from "../packs/store";

import Axios from "axios";
import moment from "moment";
import Routes from "./Routes";
import { updateRivileBBSockets } from "../redux/Actions/rivile/BigboxRivileAction";
import { exportedDocumentAction } from "../redux/Actions/exportedDocumentAction";

function Index() {
  const [loading, setLoading] = useState(true);

  const webSocketReceivedData = (response) => {
    // console.log(response)
    if (typeof response.order !== "undefined") {
      store.dispatch(updateOrderSockets(response.order));
    } else if (typeof response.rivile_request !== "undefined") {
      const res = JSON.parse(response.rivile_request);
      store.dispatch(updateRivileBBSockets(res));
    } else if (response?.type == "additional_information") {
      console.count("WEBSOCKET additional_information");
      store.dispatch(userAdditionalInformation(response.user_id));
    } else if (response?.type == "auto_login") {
      const { user } = store.getState().auth;

      if (response.user_id === user.id) {
        localStorage.setItem('redirectToUsers', true)

        store.dispatch(loginUserWithAzure());
      }
    } else if (response?.type == "auto_logout") {
      store.dispatch(autoLogout(response.user_id));
    }
  };

  const localStorageAction = async () => {
    const authenticateLS = localStorage.getItem("trAuthenticate");
    const tokeneLS = localStorage.getItem("trToken");

    try {
      if (tokeneLS) {
        const decoded = jwt_decode(localStorage.trToken);

        // Timeout
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          return store.dispatch(logoutUser());
        }
        //
        const { data, status } = await Axios.get("/api/v1/logged_in_user");
        if (status == 302) {
          return store.dispatch(logoutUser());
        }
        if (data) {
          if (data.id == null || !data.consumed_timestep) {
            return store.dispatch(logoutUser());
          }
          setLoading(false);
          const authenticate = decoded.iat + "" + decoded.exp;
          const validAuthenticate =
            !!authenticateLS && authenticateLS == authenticate;

          let object = {
            ...decoded,
            ...data,
          };
          store.dispatch(setCurrentUser(object));

          if (validAuthenticate && data.consumed_timestep) {
            store.dispatch(setAuthenticate());
          }
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log("Error Index", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    localStorageAction();
    const consumer = createConsumer();
    consumer.subscriptions.create(
      { channel: "MainChannel" },
      {
        connected() {
          console.log("Connected WS");
        },
        received(data) {
          webSocketReceivedData(data);
        },
        webSocketReceivedData: webSocketReceivedData,
      }
    );
    consumer.subscriptions.create(
      { channel: "ExportedDocumentChannel" },
      {
        connected() {
          console.log("Connected WS ExportedDocumentChannel");
        },
        received(data) {
          console.log({ data });
          if (!data.exported_document) return;
          store.dispatch(exportedDocumentAction(data.exported_document));
        },
      }
    );
    // WebSockets end
    moment.updateLocale("en", {
      weekdaysMin: ["Sk", "Pr", "An", "Tr", "Kt", "Pn", "Št"],
      monthsShort: [
        "Sausis",
        "Vasaris",
        "Kovas",
        "Balandis",
        "Gegužė",
        "Birželis",
        "Liepa",
        "Rugpjūtis",
        "Rugsėjis",
        "Spalis",
        "Lapkritis",
        "Gruodis",
      ],
    });
  }, []);

  return (
    <Provider store={store}>
      <Routes loading={loading} />
    </Provider>
  );
}

export default Index;
