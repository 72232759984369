import Axios from "axios";
import {
  UPDATE_PAYMENT_ORDER,
  POST_PAYMENT_ORDER,
  EDIT_PAYMENT_ORDER,
  DELETE_PAYMENT_ORDER,
  GET_WAITING_PAYMENTS,
  CREATE_WAITING_PAYMENT,
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;
import { success } from "./modalsFunctios";

export const resendWaitingPayment = (
  orderId,
  id,
  belongs_to = "orders"
) => async (dispatch) => {
  const params = {
    waiting_payment: {
      payment_id: id,
    },
  };

  try {
    const { data } = await Axios.post(
      `/api/v1/${belongs_to}/${orderId}/waiting_payments/resend_custom_payment`,
      params
    );
    return { success: true };
  } catch (e) {
    console.log("error resending waiting payment");
    return { suceess: false };
  }
};

export const createWaitingPayment = (orderId, service, price, belongs_to = "orders") => async (
  dispatch
) => {
  const params = {
    waiting_payment: {
      waiting_paymentable_id: orderId,
      service: service,
      price: +price,
    },
  };

  try {
    const { data } = await Axios.post(
      `/api/v1/${belongs_to}/${orderId}/waiting_payments/send_custom_paysera_payment`,
      params
    );
      dispatch({
        type: belongs_to === "orders" ? CREATE_WAITING_PAYMENT : "SERVICE_CREATE_WAITING_PAYMENT",
        payload: data,
      });
    return { success: true };
  } catch (e) {
    console.log("error creating waiting payment");
    return { success: false, errors: e.response.data };
  }
};

export const getWaitingPayments = (orderId) => async (dispatch) => {
  try {
    // const { data } = await Axios.post(
    //   `/api/v1/orders/${orderId}/waiting_payments/send_custom_paysera_payment`,
    //   params
    const { data } = await Axios.get(
      `/api/v1/orders/${orderId}/waiting_payments/`
    );
    dispatch({
      type: GET_WAITING_PAYMENTS,
      payload: data,
    });
    return { success: true };
  } catch (e) {
    console.log("error creating waiting payment");
    return { success: false, errors: e.response.data };
  }
};

export const postPayment = (orderId, body) => (dispatch) => {
  return Axios.post(`/api/v1/orders/${orderId}/payments`, body)
    .then((res) => {
      dispatch({
        type: POST_PAYMENT_ORDER,
        payload: res.data,
      });
      return { success: true };
    })
    .catch((err) => {
      return { success: false, errors: err.response.data };
    });
};

export const editPayment = (variables) => (dispatch) => {
  dispatch({
    type: EDIT_PAYMENT_ORDER,
    payload: variables,
  });
};
export const updatePayment = (orderId, id, body) => (dispatch) => {
  return Axios.put(`/api/v1/orders/${orderId}/payments/${id}`, body)
    .then((res) => {
      dispatch({
        type: UPDATE_PAYMENT_ORDER,
        payload: res.data,
      });
      return { success: true };
      success("paymentTypes-s", "modalClose");
    })
    .catch((err) => {
      return { success: false, errors: err.response.data };
    });
};

export const deletePayment = (orderId, id) => (dispatch) => {
  return Axios.delete(`/api/v1/orders/${orderId}/payments/${id}`)
    .then((res) => {
      dispatch({
        type: DELETE_PAYMENT_ORDER,
        payload: id,
      });
      return true;
      success("paymentTypes-s", "modalClose");
    })
    .catch((err) => {
      if (err.response.data) {
        return err.response.data;
      }
    });
};

export const getPaymentTypesOption = () => {
  return Axios.get(`/api/v1/payment_types`).then((res) => {
    return res.data.data.map((item) => {
      return {
        value: item.title,
        label: item.title,
        module_name: item.module_name,
        rline: item.rline,
      };
    });
  });
};
