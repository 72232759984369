import Axios from "axios";
import {
  GET_MANIFEST_DPD,
  GET_MANIFEST_OMNIVA,
  GET_MANIFEST_LP_EXPRESS,
  LOADING_MANIFEST_DPD,
  LOADING_MANIFEST_OMNIVA,
  LOADING_MANIFEST_LP_EXPRESS,
  GET_ERRORS,
} from "./types";
import { success, error } from "./modalsFunctios";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

export const getManifestDpd = (page, pageCount, filter) => (dispatch) => {
  dispatch(setManifestLoadingDpd());
  Axios.get(
    `/api/v1/manifests/c/dpd?page=${page}&per_page=${pageCount}${filter}`
  )
    .then((res) => {
      dispatch({
        type: GET_MANIFEST_DPD,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getManifestOmniva = (page, pageCount, filter) => (dispatch) => {
  dispatch(setManifestLoadingOmniva());
  Axios.get(
    `/api/v1/manifests/c/omniva?page=${page}&per_page=${pageCount}${filter}`
  )
    .then((res) => {
      dispatch({
        type: GET_MANIFEST_OMNIVA,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};


export const getManifest = (type ,page, pageCount, filter) => (dispatch) => {
  dispatch(setManifestLoading());
  Axios.get(
    `/api/v1/manifests/c/${type}?page=${page}&per_page=${pageCount}${filter}`
  )
    .then((res) => {
      dispatch({
        type: "GET_MANIFEST",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getManifestLPexpress = (page, pageCount, filter) => (dispatch) => {
  dispatch(setManifestLoadingLPexpress());
  Axios.get(
    `/api/v1/manifests/c/lp_express?page=${page}&per_page=${pageCount}${filter}`
  )
    .then((res) => {
      dispatch({
        type: GET_MANIFEST_LP_EXPRESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const callCourrierManifest = (id) => (dispatch) => {
  // dispatch(setManifestLoadingOmniva());
  Axios.post(`/api/v1/manifests/${id}/pickup`)
    .then((res) => {
      if (res.data) {
        if (res.data.called_at == null) {
          error("manifest-e");
        } else {
          success("manifest-s", "modalClose");
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setManifestLoadingDpd = () => {
  return {
    type: LOADING_MANIFEST_DPD,
  };
};
export const setManifestLoadingOmniva = () => {
  return {
    type: LOADING_MANIFEST_OMNIVA,
  };
};

export const setManifestLoadingLPexpress = () => {
  return {
    type: LOADING_MANIFEST_LP_EXPRESS,
  };
};

export const setManifestLoading = () => {
  return {
    type: "LOADING_MANIFEST",
  };
};