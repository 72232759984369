import React, { useState } from "react";
import {
  FaSearch,
  FaTimes,
  FaMinus,
  FaCheck,
  FaChevronDown,
  FaChevronUp,
  FaTrash,
} from "react-icons/fa";
import styled from "styled-components";
import { GiSave } from "react-icons/gi";
import { Checkbox, Select, Input, message } from "antd";
const { Option } = Select;
import plus from "../../../assets/images/plus.svg";
import upload from "../../../assets/images/upload.svg";
import floppyDisk from "../../../assets/images/floppy-disk.svg";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { MdEdit, MdClose } from "react-icons/md";
import { Divs } from "./StyledComponents";
import { AiOutlinePlus } from "react-icons/ai";
import { errorHandler, exportDocument } from "../../helpers/Utils";
import { Lock_svg } from "../../helpers/SvgStrings";

export const ButtonEdit = (props) => {
  return (
    <button
      id={props.id}
      disabled={props.disabled || false}
      className={`btn btn-sm ${props.className || ""}`}
      style={props.style || { background: "#f29302" }}
      data-toggle="modal"
      data-target="#tableEdit"
      onClick={props.onEdit}
    >
      <FaSearch className="text-white" />
    </button>
  );
};

export const ButtonDelete2 = (props) => {
  return (
    <button
      className="btn btn-sm"
      style={{
        backgroundColor: "#E61D01",
      }}
      onClick={props.onDelete}
    >
      <FaTrash className="text-white text-sm" />
    </button>
  );
};

export const ButtonSave = (props) => {
  return (
    <button
      className="btn btn-sm shadow-sm"
      style={props.style || { background: "#f57920" }}
      onClick={props.onClick}
    >
      <img
        src={floppyDisk}
        width="15"
        style={{
          filter:
            "invert(92%) sepia(94%) saturate(29%) hue-rotate(261deg) brightness(106%) contrast(100%)",
        }}
      />
    </button>
  );
};

export const ButtonGradientAdd = ({ text, onClick }) => {
  return (
    <ButtonGradientAddStyled className="px-3" onClick={onClick}>
      {/* <AiOutlinePlus color="#FFFFFF" size={18} /> */}
      {text}
    </ButtonGradientAddStyled>
  );
};

const ButtonGradientAddStyled = styled.button`
  padding: 5px 10px;
  color: #fff;
  border: none;
  font-size: 12px;
  background: linear-gradient(96.66deg, #ef3824 -11.91%, #f7931e 95.16%);
  border-radius: 60px;
  &:focus {
    outline: none;
  }
`;

export const ButtonSuccess = (props) => {
  return (
    <button
      className="btn btn-sm shadow-sm"
      style={{ background: "green" }}
      onClick={props.onClick}
    >
      <FaCheck color="#fff" size="15" />
    </button>
  );
};

export const ButtonSuccessSimple = (props) => {
  return (
    <button className="btn btn-sm btn-success" onClick={props.onClick}>
      <FaCheck className="text-white" />
    </button>
  );
};

export const ButtonDelete = (props) => {
  return (
    <button
      className="btn btn-sm"
      style={{
        borderRadius: 3,
        backgroundColor: "#E61D01",
        width: 33,
        height: 33,
      }}
      onClick={props.onDelete}
    >
      <FaTrash className="text-white text-sm" />
    </button>
  );
};

export const EditElementClick = (props) => {
  return (
    <a
      id={props.id}
      data-toggle="modal"
      data-target="#tableEdit"
      onClick={props.onEdit}
    >
      {props.name}
    </a>
  );
};

export const CheckBoxInput = (props) => {
  return (
    <div className="d-block">
      <Checkbox
        disabled={props.disabled || false}
        checked={props.checked || false}
        color={props.color}
        name={props.choised}
        id={props.choised}
        onChange={props.onChange}
      >
        {props.text} {props.disabled && Lock_svg()}
      </Checkbox>
    </div>
  );
};

export const OrdersTableButton = (props) => {
  return (
    <ButtonOrder
      className={props.class || "btn"}
      float={props.float}
      width={props.width}
      onClick={props.onClick}
    >
      <span className="d-flex justify-content-center align-items-center">
        {props.text}
      </span>
    </ButtonOrder>
  );
};

export const OrderTableFooterButton = (props) => {
  return (
    <SaveButton
      id={props.id}
      color={props.color}
      type="button"
      className="btn"
      // aria-label="Close"
      // data-dismiss="modal"
      onClick={(e) => props.handleSave(e)}
    >
      {props.text == "Išsaugoti" && <GiSave size="20" />}
      {props.text}
    </SaveButton>
  );
};

export const SelectForm = (props) => {
  return (
    <div className="d-flex flex-column w-100">
      {[!!props.placeholder, !!props.label].includes(true) && (
        <label
          className={`font-medium text-sm my-1 ${
            props.error ? "text-danger" : ""
          }`}
          htmlFor={`id-${props.selector}`}
        >
          {props.placeholder} {props.label}{" "}
          {props.disabled
            ? Lock_svg()
            : props.required && <span className="orange_color">*</span>}
        </label>
      )}
      <Select
        showSearch
        className={props.error || props.warning ? "error_border" : ""}
        style={props.style || { width: "100%" }}
        placeholder={props.placeholder || props.onlyPlaceholder || "Pasirinkti"}
        optionFilterProp="children"
        onChange={props.onChange}
        onSearch={props.onSearch}
        mode={props.mode}
        onBlur={props.onBlur}
        name={`id-${props.selector}`}
        dropdownMatchSelectWidth={
          props.dropdownMatchSelectWidth == undefined
            ? true
            : props.dropdownMatchSelectWidth
        }
        value={props.value}
        size={props.size || "default"}
        disabled={props.disabled}
      >
        {props.options
          ? props.options.map((item, index) => (
              <Option key={index} value={item.value}>
                {item.label}
              </Option>
            ))
          : props.children}
      </Select>
      {props.error && <div className="text-danger">{props.error}</div>}
    </div>
  );
};

const ButtonExpandWithInputStyled = styled.div`
  #search {
    float: right;
    margin-top: 9px;
    width: 250px;
  }

  .search {
    padding: 5px 0;
    width: 230px;
    height: 30px;
    position: relative;
    left: 10px;
    top: -3px;
    float: left;
    line-height: 22px;
  }

  .search input {
    position: absolute;
    width: 0px;
    float: Left;
    margin-left: 210px;
    -webkit-transition: all 0.7s ease-in-out;
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
    height: 30px;
    line-height: 18px;
    padding: 0 2px 0 2px;
    border-radius: 1px;
    background: #ffffff;
    border-radius: 40px 0px 0px 40px;
    padding: 0px;
    border: none;
  }
  .search input::placeholder {
    color: #d1d1d1;
  }
  .search:hover input,
  .search input:focus {
    width: 200px;
    margin-left: 0px;
    padding: 0 0 0 10px !important;
    border: 1px solid #f7931e !important;
  }

  .btn {
    position: absolute;
    right: 10px;
    top: 5px;
    border-radius: 1px;
    height: 32px;
    width: 32px;
    background-image: linear-gradient(to bottom right, #ef3824, #f7931e);
  }
`;
export const ButtonExpandWithInput = ({ onClick = () => {} }) => {
  const [value, setValue] = useState("");
  const onSubmit = async () => {
    try {
      const success = await onClick(value);
      if (success) {
        setValue("");
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  return (
    <ButtonExpandWithInputStyled className="">
      <div className="position-relative px-0">
        <hr className="position-absolute" style={{ width: "100%" }} />
        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <div className="search">
              <Input
                type="text"
                placeholder="Prekės kodas"
                className="form-control input-sm"
                value={value}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    onSubmit();
                  }
                }}
                onChange={(e) => setValue(e.target.value)}
              />
              <button
                className="btn btn-primary btn-sm rounded-circle"
                onClick={() => onSubmit()}
              >
                <img
                  src={plus}
                  width="15"
                  style={{
                    marginTop: "-1px",
                    marginLeft: "-1px",
                  }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </ButtonExpandWithInputStyled>
  );
};

export const Button1 = ({ text, option, style, onClick }) => {
  const optionsSelector = (option) => {
    if (option == "plus") {
      return (
        <img
          src={plus}
          width="15"
          style={{
            marginBottom: "4px",
            marginRight: "6px",
            marginLeft: "-2px",
          }}
        />
      );
    } else if (option == "upload") {
      return (
        <img
          src={upload}
          width="15"
          style={{
            marginBottom: "4px",
            marginRight: "6px",
            marginLeft: "-2px",
          }}
        />
      );
    } else if (option == "edit") {
      return (
        <MdEdit
          style={{
            marginBottom: "4px",
            marginRight: "6px",
            marginLeft: "-2px",
          }}
        />
      );
    } else if (option == "minus") {
      return (
        <FaMinus
          style={{
            marginBottom: "4px",
            marginRight: "6px",
            marginLeft: "-2px",
          }}
        />
      );
    } else if (option == "times") {
      return (
        <FaTimes
          style={{
            marginBottom: "4px",
            marginRight: "6px",
            marginLeft: "-2px",
          }}
        />
      );
    }
  };
  return (
    <ExpandButton className="button_holder" style={style}>
      <button onClick={onClick} className="btn expand_btn btn-sm btn-circle">
        <span className="expand_btn-text">
          {optionsSelector(option)}
          {text}
        </span>
      </button>
    </ExpandButton>
  );
};

export const ButtonCenter = ({ text, style, onClick }) => {
  const plusSvg = (
    <img
      src={plus}
      width="15"
      style={{
        marginBottom: "4px",
        marginRight: "6px",
        marginLeft: "-2px",
      }}
    />
  );
  return (
    <ExpandButton className="button_holder" style={style}>
      <button
        onClick={onClick}
        className="btn expand_btn btn-sm btn-circle float-center"
      >
        <span className="expand_btn-text">
          {plusSvg}
          {text}
        </span>
      </button>
    </ExpandButton>
  );
};

export const Button2 = ({ onClick, text, style }) => {
  return (
    <ClassicButton className="btn btn-success" style={style} onClick={onClick}>
      {text}
    </ClassicButton>
  );
};

export const LongButton = ({ text, onClick, style, className }) => {
  return (
    <LongButtonStyle
      className={className ? `${className} text-center` : "text-center"}
      style={style}
      onClick={onClick}
    >
      {text}
    </LongButtonStyle>
  );
};

export const SyncButton = ({ onClick, children, className, disabled }) => {
  const [loading, setLoading] = useState(false);
  const onDownloadFile = async () => {
    try {
      setLoading(true);
      const data = await onClick();
      if (data) {
        return setLoading(false);
      }
      throw new Error("ERR");
    } catch (err) {
      setLoading(false);
    }
  };
  return (
    <button
      className={className || "btn btn-sm btn-dark float-right mr-3"}
      disabled={disabled || loading}
      onClick={() => onDownloadFile()}
    >
      {loading ? (
        <>
          <span className="spinner-border spinner-border-sm"></span>
          <span> Vykdoma...</span>
        </>
      ) : (
        children
      )}
    </button>
  );
};

export const ExportDocumentButton = ({
  children,
  api_url = "",
  file_name = "test.xlsx",
  method = "GET",
}) => {
  const [loading, setLoading] = useState(false);
  const onDownloadFile = async () => {
    try {
      message.info("Ruošiama parsisiųsti");
      setLoading(true);
      const { success } = await exportDocument(api_url, file_name, method);
      if (!success) throw new Error("ERR");
      message.success("Atsiunčiama");
    } catch (err) {
      errorHandler(err);
      message.error("Įvyko klaida");
    } finally {
      setLoading(false);
    }
  };
  return (
    <button
      className="dropdown-item"
      disabled={loading}
      onClick={() => onDownloadFile()}
    >
      {loading ? (
        <>
          <span className="spinner-border spinner-border-sm"></span>
          <span> Vykdoma...</span>
        </>
      ) : (
        children
      )}
    </button>
  );
};

export const MainBlock = ({
  className = "",
  onChange,
  children,
  action,
  color,
  custom,
  height,
}) => {
  return (
    <MainBlockStyle className={className} color={color} height={height}>
      {custom ? (
        <div
          className="d-flex justify-content-between align-items-center mx-3"
          style={{ cursor: "pointer", height: "100%" }}
        >
          <span className="font-weight-bold w-100">{children}</span>
          <span onClick={onChange}>
            {action ? (
              <IoIosArrowUp title="Sutraukti" color="silver" />
            ) : (
              <IoIosArrowDown color="silver" title="Išskleisti" />
            )}
          </span>
        </div>
      ) : (
        <div
          className="d-flex justify-content-between align-items-center mx-3"
          style={{ cursor: "pointer", height: "100%" }}
          onClick={onChange}
        >
          <span className="font-weight-bold w-100">{children}</span>
          <span>
            {action ? (
              <IoIosArrowUp title="Sutraukti" color="silver" />
            ) : (
              <IoIosArrowDown color="silver" title="Išskleisti" />
            )}
          </span>
        </div>
      )}
    </MainBlockStyle>
  );
};

export const MainBlockWithContext = ({
  onChange,
  children,
  text,
  action,
  color,
  custom,
  height,
}) => {
  return (
    <React.Fragment>
      <Divs>
        <MainBlockStyle color={color} height={height}>
          {custom ? (
            <div
              className="d-flex justify-content-between align-items-center mx-3"
              style={{ cursor: "pointer", height: "100%" }}
            >
              <span className="font-weight-bold w-100">{text}</span>
              <span onClick={onChange}>
                {action ? (
                  <IoIosArrowUp title="Sutraukti" color="silver" />
                ) : (
                  <IoIosArrowDown color="silver" title="Išskleisti" />
                )}
              </span>
            </div>
          ) : (
            <div
              className="d-flex justify-content-between align-items-center mx-3"
              style={{ cursor: "pointer", height: "100%" }}
              onClick={onChange}
            >
              <span className="font-weight-bold w-100">{text}</span>
              <span>
                {action ? (
                  <IoIosArrowUp title="Sutraukti" color="silver" />
                ) : (
                  <IoIosArrowDown color="silver" title="Išskleisti" />
                )}
              </span>
            </div>
          )}
        </MainBlockStyle>

        {action ? (
          <div className="bg-silver py-2-3">{children}</div>
        ) : (
          <div className="mb-2" />
        )}
      </Divs>
    </React.Fragment>
  );
};

export const MiniBlock = ({ onChange, children, action, color, Collapse }) => {
  return (
    <MiniBlockStyle color={color}>
      <div
        className="d-flex justify-content-between align-items-center mx-3"
        style={{ cursor: "pointer", height: "100%" }}
        onClick={onChange}
      >
        <div className="font-weight-bold w-100">{children}</div>
        <div>
          {Collapse &&
            (action ? (
              <IoIosArrowUp title="Sutraukti" color="silver" />
            ) : (
              <IoIosArrowDown color="silver" title="Išskleisti" />
            ))}
        </div>
      </div>
    </MiniBlockStyle>
  );
};
MiniBlock.defaultProps = {
  Collapse: true,
};

export const InputWithButton = ({
  children,
  placeholder = "Įvesti",
  value,
  onChange,
  onClick,
}) => {
  return (
    <InputWithButtonStyled className="d-flex">
      <Input
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <button className="btn btn-primary" onClick={onClick}>
        {children}
      </button>
    </InputWithButtonStyled>
  );
};

const InputWithButtonStyled = styled.div`
  input {
    border-top-left-radius: 3.2px;
    border-bottom-left-radius: 3.2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  button {
    border-top-right-radius: 3.2px;
    border-bottom-right-radius: 3.2px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    /*  */
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
    background-color: #f7951e;
    border-color: #f7951e;
  }
`;

export const ButtonPrimary = ({
  children,
  onClick,
  disabled = false,
  active = false,
  loading = false,
}) => {
  return (
    <ButtonPrimaryStyled
      className={active ? "active" : ""}
      onClick={onClick}
      disabled={disabled || loading}
      active={active}
    >
      {loading ? (
        <>
          <span className="spinner-border spinner-border-sm"></span>
          <span> Vykdoma...</span>
        </>
      ) : (
        children
      )}
    </ButtonPrimaryStyled>
  );
};

export const ButtonTrash = ({ onClick }) => (
  <button
    className="btn btn-sm btn-danger"
    onClick={onClick}
    style={{
      width: 30,
      height: 30,
    }}
  >
    <FaTrash className="text-white" />
  </button>
);

const ButtonPrimaryStyled = styled.button`
  background: #ffffff;
  border: 1px solid #f7951e;
  color: #f7951e;
  border-radius: 3.2px;
  padding: 5px 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  &:disabled {
    opacity: 0.5;
  }
  &:focus {
    outline: none;
  }
  &.active {
    border: none;
    color: #04b600;
    text-decoration: underline;
    opacity: 1;
  }
  &.active:hover {
    background: #ffffff;
    color: #04b600;
  }
  &:hover {
    background-color: #f7951e;
    color: #fff;
  }
`;

export const MiniBlockOrderRows = ({
  onChange,
  children,
  action,
  color,
  goodsAddRow,
  goodsAddRowClick,
  editAction,
}) => {
  const itsTrueActions = editAction && action && goodsAddRowClick;
  return (
    <MiniBlockStyle color={color}>
      <div
        className="d-flex justify-content-between align-items-center mx-3"
        style={{ cursor: "pointer", height: "100%" }}
      >
        <span className="font-weight-bold w-100" onClick={onChange}>
          {children}
        </span>
        <span className="d-flex">
          <div className="mr-3">
            {itsTrueActions &&
              (goodsAddRow ? (
                <MdClose color="red" onClick={goodsAddRowClick} />
              ) : (
                <MdEdit color="green" onClick={goodsAddRowClick} />
              ))}
          </div>
          <div onClick={onChange}>
            {action ? (
              <IoIosArrowUp title="Sutraukti" color="silver" />
            ) : (
              <IoIosArrowDown color="silver" title="Išskleisti" />
            )}
          </div>
        </span>
      </div>
    </MiniBlockStyle>
  );
};
MiniBlockOrderRows.defaultProps = {
  editAction: true,
};

const SaveButton = styled.button`
  width: 159px;
  height: 30px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
  color: white;
  font-size: 10px;
  line-height: 14px;
  color: #ffffff;
  font-weight: 700;
`;

const ButtonOrder = styled.button`
  width: ${(props) => props.width};
  height: 32px;
  background-color: #f7951e;
  color: white;
  padding: 0.25rem 0.5rem;
  margin-left: 3px;
  float: ${(props) => props.float};
  span {
    font-size: 0.7rem;
  }
`;

const ClassicButton = styled.button`
  font-size: 12px;
  background-image: linear-gradient(to bottom right, #ef3824, #f7931e);
  border: 0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  &:not(:disabled):not(.disabled).active:focus,
  .btn-success:not(:disabled):not(.disabled):active:focus,
  .show > .btn-success.dropdown-toggle:focus,
  .btn-success.focus,
  .btn-success:focus,
  .btn-success:not(:disabled):not(.disabled).active,
  .btn-success:not(:disabled):not(.disabled):active,
  .show > .btn-success.dropdown-toggle,
  .btn-success:hover {
    transform: translateY(-1px);
    background-image: linear-gradient(to bottom right, #e43421, #eb8b1b);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
  border-radius: 4px !important;
`;

const ExpandButton = styled.div`
  .expand_btn {
    font-size: 15px;
    border-radius: 40px;
    color: #fff;
    background-color: #000;
    border: 1px solid #000;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    float: left;
    overflow: hidden;
    max-width: 30px;
    -webkit-transition: max-width 1s ease-in-out;
    -moz-transition: max-width 1s ease-in-out;
    -o-transition: max-width 1s ease-in-out;
    transition: max-width 1s ease-in-out;
    transition-timing-function: ease-out;
    transition-timing-function: cubic-bezier(0.29, 0.01, 0.56, 0.97);
    background-image: linear-gradient(to bottom right, #ef3824, #f7931e);
    border: 0;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .expand_btn:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }

  .expand_btn-text {
    display: block;
    white-space: nowrap;
    color: #fff;
  }

  .expand_btn:hover {
    background-color: #000;
    color: #fff;
    max-width: 280px;
  }
`;

const LongButtonStyle = styled.div`
  background-color: #ffa800;
  border: 1px solid transparent;
  font-size: 15px;
  color: #ffffff;
  width: 218px;
  height: 29px;
  border-radius: 14px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    border: none;
  }
  &:active {
    border: none;
  }
`;

const MainBlockStyle = styled.div`
  border-left: 5px solid ${(props) => props.color || "orange"};
  height: ${(props) => props.height || "3rem"};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  background-color: #ffffff;
  font-size: 1rem;
  line-height: 27px;
  color: #000000;
`;

const MiniBlockStyle = styled.div`
  border-left: 3px solid ${(props) => props.color || "orange"};
  height: 2rem;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.1));
  background-color: #ffffff;
  font-size: 0.8rem;
  line-height: 27px;
  color: #000000;
`;
