import React, { useState } from "react";


const defaultValue = {
  type: "",
  record_id: null,
  record: {}
}
export default function useModal(value = defaultValue) {
  const [modalOpt, setModalOpt] = useState(value);

  return {
    modalOpt,
    setModalOpt: (type = defaultValue.type, record_id = defaultValue.record_id, record = defaultValue.record) => setModalOpt({
      type,
      record_id,
      record
    }),
    closeModal: () => setModalOpt(defaultValue)
  };
}
