import React from "react";
import Container from "../Layout/Container";
import { connect } from "react-redux";
import {
  confirmRepricings,
  getRepricing,
} from "../../redux/Actions/repricingAction";
import ContentBlock from "../Layout/ContentBlock";
import TableColumnProduct from "./Items/TableColumnProduct";
import ContentValidation from "../common/ContentValidation";
import { Checkbox } from "antd";
import { CheckBoxInput } from "../common/Buttons";
import RepricingModal from "./RepricingModal";
import moment from "moment";
import DescriptionModalWrapper from "../Descriptions/DescriptionModalWrapper";
import InputSwitchComponent from "../common/InputSwitchComponent";

let filterSearchTimeout;

class Repricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceControl: true,
      showModal: false,
      modalInfo: { id: 0, number: 0 },

      bodyValue: "",
      paramsFilter: "",
      selected: [],
      selectedAll: false,
      filter: {
        marked: false,
        types: "",
        inwarehouse: "",
      },
      description_modal: false,
    };
    this.available_edit = props.auth.user_role.identifier !== "hosts";
  }

  componentDidMount() {
    this.reloadData(this.state.paramsFilter);
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.reloadData(this.state.paramsFilter);
    }
  }

  reloadData = (paramsFilter) => {
    this.props.getRepricing(this.props.match.params.action, paramsFilter);
  };

  getDateFormat = (date) => {
    var d = moment(date);
    return date && d.isValid() ? d : "";
  };

  handleFilterMarked = (e) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          marked: e.target.checked,
        },
      },
      () => this.onFilter()
    );
  };

  onFilter = () => {
    let filterParams = [];
    if (this.state.filter.marked === true) {
      filterParams.push(`q[repricings_product_family_marked_eq]=true`);
    }
    if (this.state.filter.types) {
      if (this.state.filter.types == "active") {
        filterParams.push(`q[repricings_canceled_false]=1`);
      }
    }
    if (this.state.filter.inwarehouse) {
      if (this.state.filter.inwarehouse == "active") {
        filterParams.push(`inwarehouse=1`);
      }
    }
    this.setState(
      {
        paramsFilter: filterParams.join("&"),
      },
      () => this.reloadData(filterParams.join("&"))
    );
  };

  handleToggle = (value) => {
    const currentIndex = this.state.selected.indexOf(value);
    let newChecked = [...this.state.selected];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      selected: newChecked,
      selectedAll: false,
    });
  };

  handleCheckboxAll() {
    const myData = this.props.repricing.repricings;
    const newChecked = [...this.state.selected];
    let newArray = [];
    if (myData.length === newChecked.length) {
      this.setState({
        selectedAll: false,
        selected: [],
      });
    } else {
      myData.forEach((item) => newArray.push(item.id));
      this.setState({
        selectedAll: true,
        selected: newArray,
      });
    }
  }

  printSelected = (e, type, id = false) => {
    e.preventDefault();
    let ids = [];
    console.log(type);
    if (type === "selected") {
      if (this.state.selected.length > 0) {
        ids = this.state.selected;
      }
    } else {
      ids = this.props.repricing.repricings.map((i) => i.id);
    }
    if (id !== false) {
      ids = [id];
    }
    if (ids.length > 0) {
      let data = {
        repricing: {
          ids: ids,
        },
      };
      fetch("/api/v1/repricings/combined_repricings", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((response) => response.blob())
        .then((response) => {
          const fileURL = URL.createObjectURL(response);
          const pdfWindow = window.open();
          pdfWindow.location.href = fileURL;
        });
    }
  };

  openModal = (type, info) => {
    this.setState({
      showModal: type,
    });
    if (info) {
      this.setState({
        modalInfo: info,
      });
    }
  };

  calculateDiscount = (before, after) => {
    if (before === after) {
      return "-";
    }
    return (100 - (100 / before) * after).toFixed(2) + " %";
  };

  render() {
    const { info, repricings } = this.props.repricing;
    const breadcrumbs = [
      {
        label: "Perkainavimas",
        value: "/goods/repricings"
      },
      {
        label: "Perkainavimas " + (info?.document || ""),
        value: this.props.match.url
      }
    ]
    return (
      <Container
        breadcrumbs={
          breadcrumbs
        }
        location={{
          locationName: "Perkainavimas " + (info?.document || ""),
          locationUrl: this.props.match.url,
        }}
        goBack={() => this.props.history.push(breadcrumbs[0].value)}
      >
        <ContentBlock>
          <div className="d-flex text-sm px-3 align-items-center py-2">
            <div className="col-2-5">
              <span className="font-semi-bold">Dok. nr.:</span> {info?.document}
            </div>
            <div className="col-2-5">
              <span className="font-semi-bold">Dok. tipas:</span>{" "}
              {info?.repricing_type}
            </div>
            <div className="col-2-5">
              <span className="font-semi-bold">Padalinys:</span>{" "}
              {info?.department}
            </div>
            <div className="col-2-5">
              <span className="font-semi-bold">Data:</span>{" "}
              {info?.operation_date}
            </div>
            <div className="col-2-5">
              <span className="font-semi-bold">Komentaras:</span> {info?.note}
            </div>
          </div>
        </ContentBlock>
        <div className="row m-0">
          <div className="col-6 my-3 d-flex align-items-center">
            <div className="dropdown dropdown-repricing d-inline-block mr-3">
              <button
                className="btn btn-danger dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ fontSize: "0.8rem" }}
              >
                Spausdinti
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => this.printSelected(e, "pricetag")}
                >
                  Kainoženklius
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => this.printSelected(e, "marked")}
                >
                  Markiruojamas
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => this.printSelected(e, "all")}
                >
                  Visus
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => this.printSelected(e, "printed")}
                >
                  Nemarkiruojamas
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => this.printSelected(e, "selected")}
                >
                  Pažymėtus
                </a>
              </div>
            </div>
            <div className="mr-1">
              <CheckBoxInput
                type="checkbox"
                color="#f7951e"
                checked={this.state.filter.marked}
                onChange={(e) => this.handleFilterMarked(e)}
                text="Markiruojamos"
              />
            </div>

            <div
              style={{
                width: 200,
              }}
            >
              <InputSwitchComponent
                name="types"
                options={[
                  {
                    label: "Visos",
                    value: "",
                  },
                  // {
                  //   label: "Esančios sandelyje",
                  //   value: "in_warehouse",
                  // },
                  {
                    label: "Aktyvios",
                    value: "active",
                  },
                ]}
                value={this.state.filter.types}
                onChange={(e) => {
                  console.log("E", e);
                  const types = e.target.value;
                  this.setState(
                    {
                      filter: {
                        ...this.state.filter,
                        types,
                      },
                    },
                    () => this.onFilter()
                  );
                }}
              />
            </div>
            <div
              style={{
                width: 200,
                paddingLeft: 10,
              }}
            >
              <InputSwitchComponent
                name="inwarehouse"
                options={[
                  {
                    label: "Visos",
                    value: "",
                  },
                  // {
                  //   label: "Esančios sandelyje",
                  //   value: "in_warehouse",
                  // },
                  {
                    label: "Yra padalinyje",
                    value: "active",
                  },
                ]}
                value={this.state.filter.inwarehouse}
                onChange={(e) => {
                  console.log("E", e);
                  const inwarehouse = e.target.value;
                  this.setState(
                    {
                      filter: {
                        ...this.state.filter,
                        inwarehouse,
                      },
                    },
                    () => this.onFilter()
                  );
                }}
              />
            </div>

            <div>{/*  */}</div>
          </div>
          <div className="col-6 my-3 text-right">
            <button
              className="btn btn-primary"
              style={{ fontSize: "0.8rem" }}
              onClick={() => this.props.confirmRepricings()}
            >
              Perkainuoti
            </button>
          </div>
        </div>
        <ContentBlock>
          <div>
            <ContentValidation
              loader={this.props.loading}
              array={repricings}
              alertId_s="repricingsAlert-s"
              alertId_e="repricingsAlert-e"
            >
              <div className="table-responsive" style={{ overflow: "hidden" }}>
                <table className="table mt-3">
                  <thead>
                    <tr className="text-muted">
                      <th className="text-black">
                        <Checkbox
                          onChange={() => this.handleCheckboxAll()}
                          checked={this.state.selectedAll}
                        />
                      </th>
                      <th className="text-black"></th>
                      <th className="text-black">Kodas</th>
                      <th className="text-black">Pavadinimas</th>
                      <th className="text-black">Aprašymas</th>
                      <th className="text-black text-center">Markiruojamos</th>
                      <th className="text-black text-center">Aktyvus</th>
                      <th className="text-black">Bazinė kaina</th>
                      <th className="text-black">Kaina su nuolaida</th>
                      <th className="text-black">Nuolaida</th>
                      <th className="text-black">Akcijos laikotarpis</th>
                    </tr>
                  </thead>
                  <tbody>
                    {repricings &&
                      repricings?.length &&
                      repricings.map((item, index) => {
                        return (
                          <TableColumnProduct
                            key={index}
                            index={index}
                            item={item}
                            onPrint={this.printSelected}
                            setSelected={() => this.handleToggle(item.id)}
                            selected={this.state.selected.includes(item.id)}
                            calculateDiscount={this.calculateDiscount}
                            openModal={() =>
                              this.setState({
                                description_modal: {
                                  description_id: item.product.id,
                                  repricing_id: item.id,
                                },
                              })
                            }
                          />
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </ContentValidation>
          </div>
        </ContentBlock>
        <RepricingModal
          showModal={this.state.showModal}
          modalInfo={this.state.modalInfo}
          closeModal={this.openModal}
        />
        {this.state.description_modal && (
          <DescriptionModalWrapper
            description_id={this.state.description_modal.description_id}
            repricing_id={this.state.description_modal.repricing_id}
            closeModal={() =>
              this.setState({
                description_modal: false,
              })
            }
          />
        )}
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  repricing: state.repricing.repricing,
  totals: state.repricing.totals,
  loading: state.repricing.loading_repricing,
  errors: state.errors,
  auth: state.auth.user,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getRepricing,
  confirmRepricings,
})(Repricing);
