import React from "react";
import InputForm from "../../common/InputForm";
import ModalToChange from "../../Layout/ModalToChange";
import moment from "moment";
import StatusJobsForm from "./StatusJobsForm";
import { Button1 } from "../../common/Buttons";
class StatusesEditModal extends React.Component {
  constructor() {
    super();
    this.state = {
      id: null,
      id_status: "",
      title: "",
      errorBlock: [],
      validateError: true
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const item = nextProps.objectData;
    this.setState({
      id: item.id,
      id_status: item.id_status,
      title: item.title,
      errorBlock: [],
    });
  }

  addStatusJob = () => {
    const customId = Math.floor(Math.random() * 100);
    const variables = {
      id: customId,
      title: "",
      klass_name: "",
      show_id: this.state.id,
      start_at: new Date(),
      end_at: new Date(),
    };
    this.props.addStatusJob(variables);
  };

  updateStatusJob = (object) => {
    if (object.created_at) {
      object.show_id = this.state.id;
    }
    this.props.updateStatusJob(object).then((response) => {
      let errorArray = this.state.errorBlock;
      const findIndex = errorArray.findIndex((find) => find.id == object.id);
      if (response.error) {
        if (findIndex == -1) {
          errorArray.push({
            id: object.id,
            errors: response.data,
          });
          this.setState({
            errorBlock: errorArray,
            validateError: !this.state.validateError
          });
        } else {
          errorArray[findIndex] = {
            id: object.id,
            errors: response.data,
          };
          this.setState({
            errorBlock: errorArray,
            validateError: !this.state.validateError
          });
        }
      } else {
        if (findIndex !== -1) {
          errorArray.splice(findIndex, 1);
          this.setState({
            errorBlock: errorArray,
            validateError: !this.state.validateError
          });
        }
      }
    });
  };

  removeStatusJob = (ID, object) => {
    let removeAlert = confirm("Ar tikrai norite ištrinti?");
    if (removeAlert) {
      object.show_id = this.state.id;
      this.props.removeStatusJob(ID, object);
    }
  };

  onChangeValue = (e, index) => {
    let status_jobs = [...this.props.status_jobs];
    status_jobs[index][e.target.name] = e.target.value;
    this.props.changeStatusJob(status_jobs);
  };

  render() {
    return (
      <ModalToChange
        handleSave={() => document.getElementById("modalClose").click()}
        status="tableEdit"
        text={"Statusas"}
        buttonText={"Uždaryti"}
      >
        <div className="d-flex justify-content-between font-weight-bold mb-2">
          <div>Pavadinimas</div>
          <div>{this.state.title}</div>
        </div>
        <div className="d-flex justify-content-between font-weight-bold mb-2">
          <div>Statuso ID</div>
          <div>{this.state.id_status}</div>
        </div>
        {this.props.status_jobs.length !== 0 && (
          <>
            <br />
            <hr />
          </>
        )}
        {this.props.status_jobs.map((item, index) => (
          <StatusJobsForm
            key={item.id}
            status={item}
            index={index}
            errorBlock={this.state.errorBlock}
            validateError={this.state.validateError}
            onChangeValue={this.onChangeValue}
            updateStatusJob={this.updateStatusJob}
            removeStatusJob={this.removeStatusJob}
          />
        ))}
        <div className="d-flex justify-content-center align-items-center mt-2">
          <Button1
            text={"Pridėti"}
            option="plus"
            onClick={() => this.addStatusJob()}
          />
        </div>
      </ModalToChange>
    );
  }
}

export default StatusesEditModal;
