import React from "react";
import {
  ButtonEdit,
  ButtonDelete,
  EditElementClick,
} from "../../common/Buttons";
import { GoPrimitiveDot } from "react-icons/go";
import { Label } from "../../global";

function TableColumnRivileService({ id, item, onDelete, index, onAction }) {
  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle">
        <GoPrimitiveDot
          className={`${item.has_vat ? "text-success" : "text-danger"}`}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", index)}
          name={item.id_product}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", index)}
          name={item.title}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", index)}
          name={item.identifier}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", index)}
          name={item.rivile_code}
        />
      </td>
      <td className="align-middle">{item.alt_price}</td>
      <td className="align-middle">{item.alt_code}</td>
      <td>
        {!!item.limitation_shop_categories_titles.length && (
          <>
            <Label>
              Kategorijų apribojimas:
            </Label>
            <div className="text-sm">
              {item.limitation_shop_categories_titles.join(", ")}
            </div>
          </>
        )}
        {!!item.limitation_brands_titles.length && (
          <>
            <Label>
              Gamintojų apribojimas:
            </Label>
            <div className="text-sm">
              {item.limitation_brands_titles.join(", ")}
            </div>
          </>
        )}
        {!!item.limitation_products_titles.length && (
          <>
            <Label>
              Produktų apribojimas:
            </Label>
            <div className="text-sm">
              {item.limitation_products_titles.join(", ")}
            </div>
          </>
        )}

      </td>
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonEdit onEdit={() => onAction("edit", index)} />
      </td>
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonDelete onDelete={() => onDelete(id)} />
      </td>
    </tr>
  );
}

export default TableColumnRivileService;
