import React from "react";
import { ButtonEdit } from "../../common/Buttons";



function TableColumnSms({ item, index, onEdit }) {
  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle">{item.id}</td>
      <td className="align-middle">{item.title}</td>
      <td className="align-middle">{item.asset.url !== null &&
        <img src = {item.asset.url} title = {item.title} alt = {item.title} className = "brand-img" />
      }</td>
      <td className="align-middle" style={{ width: "100px" }}>
        <ButtonEdit onEdit={() => onEdit("edit",index)} />
      </td>
    </tr>
  );
}

export default TableColumnSms;
