import React, { useState } from "react";
import { SelectForm } from "../../common/Buttons";
import TextInputModal from "../../common/TextInputModal";
import moment from "moment";
import { DatePicker } from "antd";
import { useDispatch } from "react-redux";
import { addContractDocument } from "../../../redux/Actions/orderContractAction";
import AutoFillComponent from "../../common/AutoFillComponent";

const defaultState = {
  customer: "",
  email: "",
  phone: "",
  invoice_number: "",
  document_type_id: undefined,
  document_plan_id: undefined,
  price: 0,
  charge: 0,
  buy_date: moment(),
  serial_number: "",
  warranty: 0,
};

function DocumentAddModal(props) {
  const dispatch = useDispatch();
  const [state, setState] = useState(props.data || defaultState);
  const [errors, setErrors] = useState({});

  const onSubmit = (e) => {
    e.preventDefault();
    let error_state = {};
    if (!state.document_plan_id)
      error_state.document_plan_id = ["Pasirinkite dokumento planą"];
    if (!state.phone || state.phone?.length < 10)
      error_state.phone = ["Pasitikrinkite telefono numerį"];
    if (Object.entries(error_state).length > 0) {
      return setErrors(error_state);
    }
    const postVariables = {
      contract: {
        document_plan_id: state.document_plan_id,
        invoice_number: state.invoice_number.trim(),
        phone: state.phone.trim(),
        customer: state.customer,
        email: state.email,
        warranty: state.warranty,
        price: state.price,
        charge: state.charge,
        buy_date: state.buy_date,
      },
    };

    dispatch(addContractDocument(state.invoice_number, postVariables)).then(
      (res) => {
        if (res) {
          if (res.data) {
            if (props.from_leasings)
              return props.onEdit("created", res.data.id, {
                serial_number: state.serial_number || ""
              });
            props.onEdit("created", res.data.id);
          }
        }
      }
    );
  };

  const onChange = (e, select) => {
    let obj = { ...state };
    if (select) {
      if (select == "document_type_id") {
        obj.document_plan_id = undefined;
      } else if (select == "document_plan_id" && e) {
        const findDocumentPlan = props.documentPlan.find((fi) => fi.value == e);
        if (findDocumentPlan) {
          obj.price = findDocumentPlan.cost;
          obj.charge = findDocumentPlan.percentage;
        }
      }
      setState({
        ...obj,
        [select]: e,
      });
    } else {
      setState({
        ...obj,
        [e.target.name]: e.target.value,
      });
    }
  };

  const inputOnFocus = () => {
    if (state.phone.length == 0) {
      setState({
        ...state,
        phone: "+3706",
      });
    }
  };

  const plan_options = () => {
    if (!state.document_type_id) return [];
    const find = props.document_types.find(
      (e) => e.value == state.document_type_id
    );
    return find ? find.plans : [];
  };

  const renderSearchEndPoint = (value) => {
    const arrayOfString = value.trim().split(" ");
    const searchString = arrayOfString.reduce(
      (cur, acc) =>
        cur + `&q[customer_or_phone_or_email_or_number_i_cont_any]=${acc}`,
      ""
    );
    return `/api/v1/contracts/search_contract?${searchString}`;
  };

  const ArrayBuilder = (arr) => {
    const responseBuild = arr.map((e) => ({
      label: `${e.customer} - ${e.number}`,
      value: e.id,
      customer: e.customer,
      document_plan_id: e.document_plan_id,
      document_type_id: e.document_type_id,
      phone: e.phone,
      email: e.email,
      invoice_number: e.invoice_number,
      charge: e.charge,
      price: e.price,
      buy_date: e.buy_date,
    }));
    return responseBuild;
  };


  return (
    <form onSubmit={onSubmit}>
      <div className="text-bold my-1">Dokumento paieška</div>
      <AutoFillComponent
        endPoint={renderSearchEndPoint}
        onChange={(_, full) => {
          setState({
            ...state,
            document_type_id: full.document_type_id,
            document_plan_id: full.document_plan_id,
            invoice_number: full.invoice_number,
            phone: full.phone,
            customer: full.customer,
            email: full.email,
            price: full.price,
            charge: full.charge,
            buy_date: full.buy_date,
          });
        }}
        arrayBuilder={ArrayBuilder}
      />

      <div className="text-bold my-1">
        Data <span className="orange_color">*</span>
      </div>
      <DatePicker
        style={{ width: "100%" }}
        placeholder="Data"
        disabledDate={(d) =>
          d.isBefore(moment().subtract(40, "days")) ||
          d.isAfter(moment().format("YYYY-MM-DD"))
        }
        value={moment(state.buy_date) || ""}
        onChange={(_, arg) => onChange(arg == "" ? moment() : arg, "buy_date")}
      />
      <TextInputModal
        required="true"
        text="Klientas"
        placeholder="Klientas"
        name="customer"
        value={state.customer}
        onChange={(e) => onChange(e)}
      />
      <TextInputModal
        required="true"
        text="El. paštas"
        placeholder="El. paštas"
        name="email"
        value={state.email}
        onChange={(e) => onChange(e)}
      />
      <TextInputModal
        required="true"
        text="Telefonas"
        placeholder="Telefonas"
        name="phone"
        style={
          state.phone.length === 12
            ? { border: "1px solid green" }
            : { border: "1px solid red" }
        }
        value={state.phone}
        onChange={(e) =>
          onChange({
            target: {
              value: e.target.value.trim(),
              name: "phone",
            },
          })
        }
        onFocus={() => inputOnFocus()}
        error={errors.phone && errors.phone.map((item) => `${item} `)}
      />
      <TextInputModal
        required="true"
        text="Pirk. Dok. Nr."
        placeholder="Galima suvesti vėliau"
        name="invoice_number"
        value={state.invoice_number}
        onChange={(e) => onChange(e)}
      />
      {props.from_leasings && (
        <>
          <TextInputModal
            required="true"
            text="S/N ar IMEI"
            placeholder="S/N ar IMEI"
            name="serial_number"
            value={state.serial_number}
            onChange={(e) => onChange(e)}
          />
          <SelectForm
            label="Gamintojo garantija"
            onlyPlaceholder="Pasirinkti"
            required={true}
            options={[
              {
                label: "1 metai",
                value: 1,
              },
              {
                label: "2 metai",
                value: 2,
              },
              {
                label: "3 metai",
                value: 3,
              },
              {
                label: "4 metai",
                value: 4,
              },
              {
                label: "5 metai",
                value: 5,
              },
              {
                label: "Garantijos nėra",
                value: 0,
              },
            ]}
            value={state.warranty}
            onChange={(e) => onChange(e, "warranty")}
          />
        </>
      )}
      <div className="text-bold my-1">
        Tipas: <span className="orange_color">*</span>
      </div>
      <SelectForm
        onlyPlaceholder="Tipas"
        options={props.document_types}
        value={state.document_type_id}
        onChange={(e) => onChange(e, "document_type_id")}
      />
      <div
        className={`text-bold my-1 ${
          errors.document_plan_id ? "text-danger" : ""
        }`}
      >
        Planas: <span className="orange_color">*</span>
      </div>
      <SelectForm
        // mode="tags"
        onlyPlaceholder="Planas"
        options={plan_options()}
        value={state.document_plan_id}
        onChange={(e) => onChange(e, "document_plan_id")}
        disabled={state.document_type_id === undefined}
        error={
          errors.document_plan_id &&
          errors.document_plan_id.map((item) => `${item} `)
        }
      />
      <TextInputModal
        disabled={true}
        // required="true"
        type="number"
        text="Procentai"
        placeholder="Procentai"
        value={state.charge}
        name="charge"
        onChange={(e) => onChange(e)}
      />
      <TextInputModal
        disabled={props.from_leasings}
        required="true"
        type="number"
        text="Mokestis"
        placeholder="Mokestis"
        value={state.price}
        name="price"
        onChange={(e) => onChange(e)}
      />
      <div>
        <button
          type="submit"
          className="btn btn-primary mt-3 float-right"
          style={{ width: "50%" }}
          // onClick={(e) => onSubmit(e)}
        >
          Tęsti
        </button>
      </div>
    </form>
  );
}
export default DocumentAddModal;
