import React from "react";
import { Button2 } from "../../../common/Buttons";

const DoubleButton = ({ onSave, onBack, showBack, showRemove }) => {
  return (
    <div className="d-flex align-items-center justify-content-end mt-3">
      {showRemove && (
        <button
          className="btn btn-outline-primary mr-2"
          style={{
            fontSize: 12,
            borderRadius: 4,
          }}
          onClick={() => onBack()}
        >
          Ištrinti
        </button>
      )}
      {showBack && (
        <button
          className="btn btn-secondary btn-sm mr-2"
          style={{
            color: "#A0A0A0",
            border: "none",
            backgroundColor: "transparent",
          }}
          onClick={() => onBack()}
        >
          Grįžti
        </button>
      )}
      <Button2 onClick={() => onSave()} text="Išsaugoti" />
    </div>
  );
};
export default DoubleButton;
