import Axios from "axios";
import {
  GET_LEASINGS,
  CLEAR_ERRORS,
  GET_ERRORS,
  LOADING_LEASINGS,
  LOADING_LEASING,
  GET_LEASING,
  UPDATE_LEASING,
  CREATE_LEASING,
  DELETE_LEASING,
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

const success = () => {
  if (!document.getElementById("leasingsAlert-s")) return;
  document.getElementById("leasingsAlert-s").style.display = "block";
  setTimeout(
    () => (document.getElementById("leasingsAlert-s").style.display = "none"),
    5000
  );
  document.getElementById("modalClose").click();
};

const error = () => {
  if (!document.getElementById("leasingsAlert-e")) return;
  document.getElementById("leasingsAlert-e").style.display = "block";
  setTimeout(
    () => (document.getElementById("leasingsAlert-e").style.display = "none"),
    5000
  );
};

export const getLeasings = (page, pageCount, filterParams) => (dispatch) => {
  dispatch(setLeasingsLoading());
  Axios.get(
    `/api/v1/leasings?page=${page}&per_page=${pageCount}${filterParams}`
  )
    .then((res) => {
      dispatch({
        type: GET_LEASINGS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getLeasing = (id) => (dispatch) => {
  dispatch(setLeasingLoading());
  Axios.get(`/api/v1/leasings/${id}`)
    .then((res) => {
      dispatch({
        type: GET_LEASING,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
    });
};

export const deleteLeasing = (id) => (dispatch) => {
  dispatch(setLeasingLoading());
  Axios.delete(`/api/v1/leasings/${id}`)
    .then((res) => {
      dispatch({
        type: DELETE_LEASING,
        payload_id: id,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: null,
      });
    });
};

export const updateLeasing = (_id, body) => (dispatch) => {
  dispatch(setLeasingLoading());
  return Axios.put(`/api/v1/leasings/${_id}`, body)
    .then((res) => {
      dispatch({
        type: UPDATE_LEASING,
        payload: res.data,
        payload_id: _id,
      });
      return true;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
      return false;
    });
};

export const createLeasing = (body) => (dispatch) => {
  dispatch(setLeasingLoading());
  return Axios.post(`/api/v1/leasings`, body)
    .then((res) => {
      dispatch({
        type: CREATE_LEASING,
        payload: res.data,
      });
      return true;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
      return false;
    });
};

export const setLeasingsLoading = () => {
  return {
    type: LOADING_LEASINGS,
  };
};

export const setLeasingLoading = () => {
  return {
    type: LOADING_LEASING,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
