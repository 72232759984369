import { Modal } from "antd";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import AntdConfirm from "../../helpers/AntdConfirm";
import { exportDocument } from "../../helpers/Utils";
import { deleteEnergyLabel } from "../../redux/Actions/energy_labels_action";
import { ButtonDelete, ButtonEdit } from "../common/Buttons";
import { IconEdit, IconPDF, IconSearch } from "../Layout/Icons";
import TableUi from "../common/TableUi";
import TableProductPreview from "../table/TableProductPreview";

const EnergyLabelColumn = ({
  id,
  data_sheet,
  label_image,
  energy_products,
  energy_class,
  eprel_id,
  setModalOpt,
  label_image_base64,
  data_sheet_base64,
}) => {
  const dispatch = useDispatch();
  const tableRef = useRef();
  const [open, setOpen] = useState(false);
  return (
    <tr ref={tableRef}>
      <TableUi.td className="p-left">
        <TableProductPreview
          autosys_products={energy_products}
          open={open}
          setOpen={setOpen}
        />
      </TableUi.td>
      <TableUi.td>{energy_class}</TableUi.td>
      <TableUi.td>{eprel_id}</TableUi.td>
      <TableUi.td className={"pt-0 pb-0"}>
        {data_sheet?.url && (
          <div
            className="d-flex flex-wrap align-items-center justify-content-between bg-white text-sm px-2 mb-2 cursor-pointer border-radius-3"
            style={{
              border: "1px solid #E0E0E0",
              padding: "4px 5px 4px 4px",
              maxWidth: 180,
            }}
            onClick={() => {
              exportDocument(
                `/api/v1/energy_labels/${id}/download_document`,
                data_sheet.url?.split("/")?.at(-1),
                "POST",
                {
                  type: "data_sheet",
                }
              );
            }}
          >
            <div>{data_sheet.url.split("/")?.at(-1) ?? "Something wrong"}</div>
            <IconPDF />
          </div>
        )}
      </TableUi.td>
      <TableUi.td>
        {label_image?.url && (
          <LabelImagePreview
            asset={label_image}
            base64={label_image_base64}
            id={id}
          />
        )}
      </TableUi.td>
      <TableUi.td>
        <div className="d-flex align-items-center justify-content-end">
          <div>
            <button
              className="btn btn-sm"
              style={{
                borderRadius: 3,
                background: "#f29302",
                width: 33,
                height: 33,
              }}
              onClick={() => setModalOpt("update", id)}
            >
              <IconEdit className="text-white text-sm" />
            </button>
          </div>

          <div className="mx-1" />
          <div className="">
            <ButtonDelete
              onDelete={() =>
                AntdConfirm({
                  onOk: () => {
                    dispatch(deleteEnergyLabel(id));
                  },
                })
              }
            />
          </div>
        </div>
      </TableUi.td>
    </tr>
  );
};

const LabelImagePreview = ({ asset, base64, id }) => {
  const [previewImage, setPreviewImage] = useState(false);

  return (
    <div
      className="position-relative"
      onClick={() => setPreviewImage(!previewImage)}
      style={{
        width: 45,
        height: 90,
      }}
    >
      <div
        style={{
          backgroundImage: `url(${base64})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100%",
        }}
      />

      <div
        className="position-absolute d-flex align-items-center justify-content-center cursor-pointer"
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "rgba(255, 255, 255, 0.9)",
          border: "1px solid #E0E0E0",
          borderRadius: 32,
          width: 30,
          height: 30,
        }}
      >
        <IconSearch color="#777777" />
      </div>
      {previewImage && (
        <Modal
          title={asset?.url?.split("/")?.at(-1) ?? "Something wrong"}
          visible={previewImage}
          onOk={() => {
            exportDocument(
              `/api/v1/energy_labels/${id}/download_document`,
              asset.url?.split("/")?.at(-1),
              "POST",
              {
                type: "label_image",
              }
            );
          }}
          onCancel={() => setPreviewImage(false)}
          width={1000}
          okText="Atsisiųsti"
          cancelText="Uždaryti"
        >
          <div className="d-flex justify-content-between">
            <div className="d-flex justify-content-center" style={{ flex: 4 }}>
              <img className="img-fluid" src={base64} />
            </div>

            <div className="pl-2" style={{ flex: 1 }}>
              <ul>
                <li>
                  <span className="font-weight-bold">Pavadinimas: </span>
                  {asset?.url?.split("/")?.at(-1) ?? "Something wrong"}
                </li>
                <hr />
                <li>
                  <span className="font-weight-bold">Formatas: </span>
                  {asset?.url?.split(".")?.at(-1) ?? "Something wrong"}
                </li>
              </ul>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default EnergyLabelColumn;
