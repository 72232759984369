import { GET_BRANDS, LOADING_BRANDS, POST_BRAND, UPDATE_BRAND } from "../Actions/types";

const initialState = {
  brands: [],
  totals: 0,
  loading_brands: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_BRANDS:
      return {
        ...state,
        loading_brands: true,
      };
    case GET_BRANDS:
      return {
        ...state,
        brands: action.payload.data,
        totals: action.payload.totals,
        loading_brands: false,
      };
    case POST_BRAND:
      let updateData = [...state.brands];
      updateData.push(action.payload);
      return {
        ...state,
        brands: updateData
      };
    case UPDATE_BRAND:
      let getData = [...state.brands];
      const updateElementId = element => element.id === action.payload_id;
      const findIndex = getData.findIndex(updateElementId);
      getData[findIndex] = action.payload;
      return {
        ...state,
        brands: getData
      };
    default:
      return state;
  }
}
