import React from "react";
import TextAreaFieldGroup from "../../../common/TextAreaFieldGroup";
import { CheckBoxInput } from "../../../common/Buttons";
import PictureWall from "./PictureWall";
import { LabelTextStyle } from "../../../common/StyledComponents";
import Spinner from "../../../common/Spinner";

function ServiceForms_3({
  description,
  onChangeHandler,
  onChangeAttachments,
  attachments,
  loading,
  errors
}) {
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner />
      </div>
    );
  }
  return (
    <div>
      <TextAreaFieldGroup
        type="text"
        placeholder="Gedimas"
        validate={true}
        name="breaks"
        onlyPlaceholder="Įveskite"
        rows={3}
        labelStyle={true}
        value={description.breaks}
        onChange={(e) => onChangeHandler(e, "description")}
        error={errors.breaks}
      />
      <TextAreaFieldGroup
        type="text"
        placeholder="Prekės išorė"
        name="goods_outside"
        rows={3}
        labelStyle={true}
        onlyPlaceholder="Įveskite"
        value={description.goods_outside}
        onChange={(e) => onChangeHandler(e, "description")}
      />
      <TextAreaFieldGroup
        type="text"
        placeholder="Komplektacija"
        name="complete_set"
        rows={3}
        labelStyle={true}
        onlyPlaceholder="Įveskite"
        value={description.complete_set}
        onChange={(e) => onChangeHandler(e, "description")}
      />

      <LabelTextStyle>Dokumentų įkėlimas</LabelTextStyle>
      <div className="pt-1">
        {/* <CheckBoxInput
          checked={attachments.defect_act.boolean}
          text="Defekto aktas"
          onChange={() =>
            onChangeAttachments(
              "defect_act",
              "boolean",
              !attachments.defect_act.boolean
            )
          }
        />
        {attachments.defect_act.boolean && (
          <div className="ml-4 mt-2 mb-3">
            <PictureWall
              name="defect_act"
              items={attachments.defect_act.docs}
              onChangeAttachments={onChangeAttachments}
            />
          </div>
        )} */}

        <CheckBoxInput
          checked={attachments.goods_appearance.boolean}
          text="Prekės išvaizda"
          onChange={() =>
            onChangeAttachments(
              "goods_appearance",
              "boolean",
              !attachments.goods_appearance.boolean
            )
          }
        />
        {attachments.goods_appearance.boolean && (
          <div className="ml-4 mt-2 mb-3">
            <PictureWall
              name="goods_appearance"
              items={attachments.goods_appearance.docs}
              onChangeAttachments={onChangeAttachments}
            />
          </div>
        )}

        <CheckBoxInput
          checked={attachments.complaint.boolean}
          text="Skundas"
          onChange={() =>
            onChangeAttachments(
              "complaint",
              "boolean",
              !attachments.complaint.boolean
            )
          }
        />
        {attachments.complaint.boolean && (
          <div className="ml-4 mt-2 mb-3">
            <PictureWall
              name="complaint"
              items={attachments.complaint.docs}
              onChangeAttachments={onChangeAttachments}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ServiceForms_3;
