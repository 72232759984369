import { GET_PRODUCTS, LOADING_PRODUCTS } from "../Actions/types";

const initialState = {
  products: [],
  totals: 0,
  loading_products: false,
  last_sync: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_PRODUCTS:
      return {
        ...state,
        loading_products: true,
      };
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload.data,
        totals: action.payload.totals,
        last_sync: action.payload.last_sync,
        loading_products: false,
      };
    case "ADD_UPSELL_PRODUCT":
      return {
        ...state,
        products: state.products.map((d) => {
          if (d.id == action.payload.id) {
            return {
              ...d,
              upsell: [...d.upsell, action.payload.value],
            };
          }
          return d;
        }),
      };
    case "UPDATE_UPSELL_PRODUCT":
      return {
        ...state,
        products: state.products.map((d) => {
          if (d.id == action.payload.id) {
            return {
              ...d,
              upsell: action.payload.data,
            };
          }
          return d;
        }),
      };
    case "REMOVE_UPSELL_PRODUCT":
      return {
        ...state,
        products: state.products.map((d) => {
          if (d.id == action.payload.id) {
            return {
              ...d,
              upsell: d.upsell.filter(
                (d) => d !== action.payload.value
              ),
            };
          }
          return d;
        }),
      };
    case "CHECKED_REMOVE_UPSELL_PRODUCT":
      return {
        ...state,
        products: state.products.map((e) => {
          if (action.payload.ids.includes(e.id)) {
            return {
              ...e,
              upsell: [],
            };
          }
          return e;
        }),
        loading_products: false,
      };
    default:
      return state;
  }
}
