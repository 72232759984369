import {
  LOADING_USER_PICKUP,
  GET_USERS_PICKUP,
  DELETE_USER_PICKUP,
  UPDATE_USER_PICKUP,
  POST_USER_PICKUP,
} from "../Actions/types";

const initialState = {
  users: [],
  totals: 0,
  loading_users: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_USER_PICKUP:
      return {
        ...state,
        loading_users: true,
      };
    case GET_USERS_PICKUP:
      return {
        ...state,
        users: action.payload.data,
        totals: action.payload.totals,
        loading_users: false,
      };
    case POST_USER_PICKUP:
      let updateData = [...state.users];
      updateData.push(action.payload);
      return {
        ...state,
        users: updateData,
      };
    case UPDATE_USER_PICKUP:
      let getData = [...state.users];
      const updateElementId = (element) => element.id === action.payload_id;
      const findIndex = getData.findIndex(updateElementId);
      getData[findIndex] = action.payload;
      return {
        ...state,
        users: getData,
      };
    case DELETE_USER_PICKUP:
      return {
        ...state,
        users: [...state.users].filter(
          (filterItem) => filterItem.id !== action.payload
        ),
        loading_users: false,
      };
    default:
      return state;
  }
}
