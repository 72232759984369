import React from "react";
import styled from "styled-components";
import { LabelTextStyle } from "../../../common/StyledComponents";
import { service_type_options } from "../../../../helpers/GlobalOptions";
import ServiceFormChildren1 from "./ServiceForm1Comp/ServiceForm_children_1";
import ServiceFormChildren2 from "./ServiceForm1Comp/ServiceForm_children_2";
import ServiceFormOrder from "./ServiceForm1Comp/ServiceFormOrder";

function ServiceForms_1({
  form,
  onChangeType,
  onChangeHandler,
  onChangeHandlerMass,
  onSelectType,
  errors,
  searching,
}) {
  const onChangeTypeFunc = (type) => {
    const targetVal = { target: { value: type, name: "type" } };
    onChangeType(targetVal, "form");
  };
  return (
    <>
      <div className="row justify-content-between mx-0 mb-2">
        {service_type_options.map((i) => (
          <LargeButton
            key={i.label}
            key={i.label}
            disabled={i.label === "Defekto aktas"}
            className={`btn btn-lg ${form.type == i.value && "button_active"}`}
            onClick={() => onChangeTypeFunc(i.value)}
          >
            {i.label}
          </LargeButton>
        ))}
      </div>
      {errors.type && (
        <div className="text-center text-danger">{errors.type}</div>
      )}
      {form.type == "" ? (
        <div></div>
      ) : form.type == "Negarantinis" ? (
        <>
          <LabelTextStyle>Pirkimas</LabelTextStyle>
          <ServiceFormChildren2
            form={form}
            onChangeHandler={onChangeHandler}
            errors={errors}
          />
        </>
      ) : form.type == "order" ? (
        <div>
          <div className="d-flex">
            <div style={{ width: 400 }}>
              <LabelTextStyle>Prekės pavadinimas<span className="orange_color">*</span></LabelTextStyle>
            </div>
            <div className="mx-2" style={{ width: 200 }}>
              <LabelTextStyle>Prekės kiekis<span className="orange_color">*</span></LabelTextStyle>
            </div>
            <div style={{ width: 200 }}>
              <LabelTextStyle>Prekės kaina</LabelTextStyle>
            </div>
          </div>
          <ServiceFormOrder
            form={form}
            onChangeHandler={onChangeHandler}
            onChangeHandlerMass={onChangeHandlerMass}
            onSelectType={onSelectType}
            errors={errors}
            searching={searching}
          />
        </div>
      ) : (
        <>
          <LabelTextStyle>Pirkimas</LabelTextStyle>
          <ServiceFormChildren1
            form={form}
            onChangeHandler={onChangeHandler}
            onChangeHandlerMass={onChangeHandlerMass}
            onSelectType={onSelectType}
            errors={errors}
            searching={searching}
          />
        </>
      )}
    </>
  );
}

const LargeButton = styled.button`
  background-color: #f6f6f6;
  font-size: 17px;
  border-radius: 2px;
  height: 70px;
  width: 18%;
  &.button_active {
    background-color: #fff;
    border: 1px solid #f7931e;
  }
  &:hover {
    background-color: #fff;
    border: 1px solid #f7931e;
    color: #f7931e;
  }
`;

export default ServiceForms_1;
