export const valueOrdersFilter = (obj) => (dispatch) => {
  dispatch({
    type: "UPDATE_ORDERS_FILTERS",
    payload: obj,
  });
};

export const valueMultiOrdersFilter = (obj) => (dispatch) => {
  dispatch({
    type: "UPDATE_MULTI_ORDERS_FILTERS",
    payload: obj,
  });
};


export const resetOrdersFilter = () => (dispatch) => {
  dispatch({
    type: "RESET_ORDERS_FILTERS",
  });
};

// 
export const updateServiceFilter = (filters) => (dispatch) => {
  dispatch({
    type: "SET_SERVICE_FILTERS",
    payload: filters,
  });
};

export const valueServiceFilter = (obj) => (dispatch) => {
  dispatch({
    type: "UPDATE_SERVICE_FILTERS",
    payload: obj,
  });
};

export const resetServiceFilter = () => (dispatch) => {
  dispatch({
    type: "RESET_SERVICE_FILTERS",
  });
};
