import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

function CommentsMainPage({ comments }) {
  const [open, setOpen] = useState(false);

  const invoidedMapFilt = () => {
    if (open) {
      return comments;
    } else {
      return comments.slice(0, 2);
    }
  };

  return (
    <React.Fragment>
      {invoidedMapFilt().map((item, index) => (
        <React.Fragment key={index}>
          <b> {item.user_name} </b>
          <span> - {item.comment} </span> 
          <br />
        </React.Fragment>
      ))}
      {comments.length > 2 && (
        <button style={{  
            backgroundColor: "white",
            fontSize: "12px",
            color: "#989c9a",
            border: "1px solid #989c9a",
            borderRadius: "5px",
            fontWeight: "500px"
          }} className="btn btn-md btn-block my-1" onClick={() => setOpen(open ? false : true)}>
          {open ? 
            ("Rodyti mažiau komentarų") : 
            (`Rodyti daugiau komentarų (${comments.length - 2}) `)
          }
          { open ? <FaAngleUp /> : <FaAngleDown /> }
        </button>
      )}
    </React.Fragment>
  );
}

export default CommentsMainPage;
