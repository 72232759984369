import React from "react";
import Container from "../Layout/Container";
import { connect } from "react-redux";
import {
  getRivileServices,
  updateRivileServices,
  deleteRivileService,
  postRivileService,
  clearErrors,
} from "../../redux/Actions/rivileServiceAction";
import RivileServiceEditModal from "./Items/RivileServiceEditModal";
import ContentBlock from "../Layout/ContentBlock";
import TableColumnRivileService from "./Items/TableColumnRivileService";
import FilterMenu from "../Layout/FilterMenu";
import TableFilterBox from "../common/TableFilterBox";
import ContentValidation from "../common/ContentValidation";
import PaginationComp from "../Dashboard/items/Pagination";

class RivileServiceTemplate extends React.Component {
  constructor() {
    super();
    this.state = {
      requiredId: 0,
      page: 1,
      pageCount: 100,
      action: "new",
    };
  }
  componentDidMount() {
    this.props.getRivileServices(this.state.page, this.state.pageCount);
  }
  componentDidUpdate(_, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.props.getRivileServices(this.state.page, this.state.pageCount);
    }
  }

  onAction = (action, index) => {
    this.setState({
      requiredId: index,
      action: action,
    });
  };

  setPagination = (page) => {
    this.setState({
      page,
    });
  };

  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  render() {
    const requiredItem = this.state.requiredId;
    let modalData = this.props.rivileService[requiredItem]
      ? this.props.rivileService[requiredItem]
      : [];
    return (
      <Container
        location={{
          locationName: "Rivile paslaugos",
          locationUrl: this.props.match.url,
        }}
      >
        <ContentBlock>
          <FilterMenu
            length={`${this.props.totals || 0} / ${
              this.props.rivileService.length || 0
            }`}
            onChangeCountPage={this.setPageCount}
            countPage={this.state.pageCount}
            text="rivilės paslaugų"
            text2="Rivilės paslaugų"
          />
        </ContentBlock>
        <ContentBlock>
          <TableFilterBox filter={false} add={this.onAction}></TableFilterBox>
          <div>
            <ContentValidation
              loader={this.props.loading_rivileService}
              array={this.props.rivileService}
              alertId_s="rivileAlert-s"
            //   alertId_e="rivileAlert-e"
            >
              <div className="table-responsive">
                <table className="table table-sm table-hover mt-3">
                  <thead>
                    <tr className="text-black">
                      <th>PVM</th>
                      <th>Produkto ID</th>
                      <th>Pavadinimas</th>
                      <th>Identifikatorius</th>
                      <th>Rivile kodas</th>
                      <th>Alternatyvos kaina</th>
                      <th>Alternatyvos kodas</th>
                      <th>Apribojimai</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.rivileService.map((item, index) => {
                      return (
                        <TableColumnRivileService
                          key={index}
                          index={index}
                          id={item.id}
                          item={item}
                          onAction={this.onAction}
                          onDelete={this.props.deleteRivileService}
                        />
                      );
                    })}
                  </tbody>
                </table>
                <PaginationComp
                  setPagination={this.setPagination}
                  page={this.state.page}
                  totals={this.props.totals}
                  pageCount={this.state.pageCount}
                />
              </div>
            </ContentValidation>
          </div>
        </ContentBlock>

        <RivileServiceEditModal
          action={this.state.action}
          objectData={modalData}
          postRivileService={this.props.postRivileService}
          updateRivileServices={this.props.updateRivileServices}
          errors={this.props.errors}
        />
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  rivileService: state.rivileService.rivile_service,
  totals: state.rivileService.totals,
  loading_rivileService: state.rivileService.loading_rivile_service,
  errors: state.errors,
});

export default connect(mapStateToProps, {
    getRivileServices,
    updateRivileServices,
    deleteRivileService,
    postRivileService,
  clearErrors,
})(RivileServiceTemplate);
