import Axios from "axios";
import {
  GET_DOCUMENT_TYPE,
  POST_DOCUMENT_TYPE,
  UPDATE_DOCUMENT_TYPE,
  DELETE_DOCUMENT_TYPE,
  CLEAR_ERRORS,
  GET_ERRORS,
  LOADING_DOCUMENT_TYPE,
} from "./types";

const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

const success = () => {
  if (!document.getElementById("document-s")) return;
  document.getElementById("document-s").style.display = "block";
  setTimeout(
    () => (document.getElementById("document-s").style.display = "none"),
    5000
  );
  document.querySelector("#closeDocumentPlan").click();
};
const error = () => {
  if (!document.getElementById("document-e")) return;
  document.getElementById("document-e").style.display = "block";
  setTimeout(
    () => (document.getElementById("document-e").style.display = "none"),
    5000
  );
};

export const postDocumentType = (dataBody) => (dispatch) => {
  Axios.post("/api/v1/document_types", dataBody)
    .then((res) => {
      dispatch({
        type: POST_DOCUMENT_TYPE,
        payload: res.data,
      });
      dispatch(clearErrors());
      success();
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const getDocumentType = () => (dispatch) => {
  dispatch(setUserLoading());
  Axios.get("/api/v1/document_types")
    .then((res) => {
      dispatch({
        type: GET_DOCUMENT_TYPE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getDocumentTypeOption = (type = "order") => {
  return Axios.get("/api/v1/document_types")
    .then((res) => {
      if (res.data !== undefined || Object.keys(res.data).length !== 0) {
        let documentType = [];
        let documentPlan = [];
        res.data.forEach((item) => {
          if (type == "order" && !item.active) return;
          if (item.document_plans.length == 0) return;
          documentType.push({
            value: item.id,
            label: item.name,
            prefix: item.prefix,
          });
          item.document_plans.forEach((planItem) => {
            if (type == "order" && !planItem.active) return;
            documentPlan.push({
              value: planItem.id,
              label: planItem.name,
              type: planItem.document_type_id,
              document_type_id: planItem.document_type_id,
              cost: planItem.cost,
              percentage: planItem.percentage,
              parent_prefix: item.prefix,
            });
          });
        });
        return {
          documentType,
          documentPlan,
        };
      }
      throw new Error("ERR");
    })
    .catch(() => {
      return {
        documentType: [],
        documentPlan: [],
      };
    });
};

export const updateDocumentType = (id, body) => (dispatch) => {
  Axios.put(`/api/v1/document_types/${id}`, body)
    .then((res) => {
      dispatch({
        type: UPDATE_DOCUMENT_TYPE,
        payload: res.data,
      });
      dispatch(clearErrors());
      success();
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const deleteDocumentType = (id) => (dispatch) => {
  Axios.delete(`/api/v1/document_types/${id}`)
    .then((res) => {
      dispatch({
        type: DELETE_DOCUMENT_TYPE,
        payload: id,
      });
      dispatch(clearErrors());
      success();
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const setUserLoading = () => {
  return {
    type: LOADING_DOCUMENT_TYPE,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
