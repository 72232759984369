import Axios from "axios";
import {
  GET_FAMILIES,
  CLEAR_ERRORS,
  GET_ERRORS,
  LOADING_FAMILIES,
  LOADING_FAMILY,
  GET_FAMILY,
  UPDATE_FAMILY,
  UPDATE_MARKING
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

const success = () => {
  if (!document.getElementById("familiesAlert-s")) return;
  document.getElementById("familiesAlert-s").style.display = "block";
  setTimeout(
    () =>
      (document.getElementById("familiesAlert-s").style.display = "none"),
    5000
  );
  document.getElementById('modalClose').click()
};

const error = () => {
  if(!document.getElementById("familiesAlert-e")) return;
  document.getElementById("familiesAlert-e").style.display = "block";
  setTimeout(
    () => (document.getElementById("familiesAlert-e").style.display = "none"),
    5000
  );
};

export const getFamilies = (page, pageCount, filterParams) => (dispatch) => {
  dispatch(setFamiliesLoading());
  Axios.get(`/api/v1/families?page=${page}&per_page=${pageCount}${filterParams}`)
    .then((res) => {
      dispatch({
        type: GET_FAMILIES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getFamily = (id) => (dispatch) => {
  dispatch(setFamilyLoading());
  Axios.get(`/api/v1/families/${id}`)
    .then((res) => {
      dispatch({
        type: GET_FAMILY,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
    });
};

export const updateFamily = (_id, body, indexOfArray) => dispatch => {
  dispatch(setFamilyLoading());
  Axios.post(`/api/v1/families/create_priorities`, body)
    .then(res => {
      dispatch(clearErrors());
      dispatch({
        type: UPDATE_FAMILY,
        payload: res,
        payload_id: _id
      });
      success("update");
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      });
      error();
    });
};

export const updateMarking = (body, indexOfArray) => dispatch => {
  Axios.patch(`/api/v1/families/update_marking`, body)
    .then(res => {
      dispatch(clearErrors());
      dispatch({
        type: UPDATE_MARKING,
        payload: res.data
      });
      success("update");
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      error();
    });
};

export const setFamiliesLoading = () => {
  return {
    type: LOADING_FAMILIES,
  };
};

export const setFamilyLoading = () => {
  return {
    type: LOADING_FAMILY,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
