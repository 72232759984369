import React from "react";
import { Dot } from "../../common/Dot";
import { Checkbox, Tooltip } from "antd";
import printerSvg from "../../../../assets/images/printer.svg";
import mailSvg from "../../../../assets/images/mail.svg";
import pdfSvg from "../../../../assets/images/pdf.svg";
import { ButtonEdit } from "../../common/Buttons";
import Axios from "axios";
import { IconDot } from "../../Layout/Icons";

function TableColumnSms({
  item,
  index,
  onPrint,
  setSelected,
  selected,
  onEmail,
  page,
  store,
  openModal,
}) {
  return (
    <tr
      className={
        "align-middle tableColumn descriptionColumn " +
        (item.family && item.family.printed ? "printed" : "marked")
      }
      key={index}
    >
      <td className="align-middle">
        <Checkbox onChange={setSelected} checked={selected} />
      </td>
      <td className="align-middle">
        {item.full_image_url ? (
          <img src={item.full_image_url} className="brand-img" />
        ) : (
          ""
        )}
      </td>
      <td className="align-middle">{item.barcode}</td>
      <td className="align-middle">
        {item.esl > 0 ? (
          <Tooltip
            placement="top"
            title={
              <div>
                {item.esl_info.map((d, idx) => (
                  <div key={idx}>
                    Padalinys: {d.department}, Kaina: {d.price}
                    <br /> ESL barkodai:
                    {d?.esl_barcodes?.join(", ")}
                  </div>
                ))}
              </div>
            }
          >
            {item.esl}* <IconDot className={"text-success"} />
          </Tooltip>
        ) : (
          <IconDot className={"text-danger"} />
        )}
      </td>
      <td className="align-middle">
        <div
          className="cursor-pointer hover-text-primary"
          onClick={openModal}
          style={{ textDecoration: "underline", color: "inherit" }}
        >
          {item.identifier}
        </div>
      </td>
      <td className="align-middle">{item.title}</td>
      <td className="align-middle">{item.family ? item.family.name : "-"}</td>
      <td className="align-middle">
        <Dot value={item.family ? item.family.printed : false} />
      </td>
      <td className="align-middle text-center">
        {item.manual_url && (
          <img
            className="cursor-pointer"
            width="12"
            height="16"
            src={pdfSvg}
            onClick={() => {
              Axios.post(
                `/api/v1/descriptions/${item.id}/download_manual`
              ).then((res) => {
                window.open(res.data.url, "_blank").focus();
              });
            }}
          />
        )}
      </td>
      <td className="align-middle" style={{ width: "120px" }}>
        {item.manual_url && (
          <button
            className="btn btn-sm mb-1 ml-1"
            style={{ background: "#8678ab" }}
            onClick={() => onEmail("email", item)}
          >
            <img
              style={{ color: "white" }}
              width="14"
              height="14"
              src={mailSvg}
            />
          </button>
        )}
        {item.family && item.family.printed && (
          <button
            className="btn btn-sm mb-1 ml-1"
            style={{ background: "#ef3824" }}
            onClick={(e) => onPrint(e, "selected", item.id)}
            disabled={item.family.format_id === null}
          >
            <img
              style={{ color: "white" }}
              width="14"
              height="14"
              src={printerSvg}
            />
          </button>
        )}
        <ButtonEdit className="mb-1 ml-1" onEdit={openModal} />
      </td>
    </tr>
  );
}

export default TableColumnSms;
