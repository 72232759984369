import Axios from "axios";
import {
  GET_PRESTA_SERVICES,
  LOADING_PRESTA_SERVICES,
  POST_SERVICE_ITEM,
  UPDATE_SERVICE_ITEM,
  DELETE_SERVICE_ITEM,
  CLEAR_ERRORS,
  GET_ERRORS,
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

const success = () => {
  if (!document.getElementById("serviceAlert-s")) return;
  document.getElementById("serviceAlert-s").style.display = "block";
  setTimeout(
    () => (document.getElementById("serviceAlert-s").style.display = "none"),
    5000
  );
  document.getElementById("modalClose").click();
};
const error = () => {
  if (!document.getElementById("serviceAlert-e")) return;
  document.getElementById("serviceAlert-e").style.display = "block";
  setTimeout(
    () => (document.getElementById("serviceAlert-e").style.display = "none"),
    5000
  );
};

export const getPrestaServicesItems = (filter, page, pageCount) => (dispatch) => {
  dispatch(setServicesLoading());
  Axios.get(`/api/v1/presta_services?page=${page}&per_page=${pageCount}${filter}`)
    .then((res) => {
      dispatch({
        type: GET_PRESTA_SERVICES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const postServiceItem = (serviceData) => (dispatch) => {
 return Axios.post("/api/v1/presta_services", serviceData)
    .then((res) => {
      dispatch({
        type: POST_SERVICE_ITEM,
        payload: res.data,
      });
      dispatch(clearErrors());
      success("post");
      return {success: true}
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const updateServiceItem = (_id, body, indexOfArray) => (dispatch) => {
  Axios.put(`/api/v1/presta_services/${_id}`, body)
    .then((res) => {
      dispatch(clearErrors());
      dispatch({
        type: UPDATE_SERVICE_ITEM,
        payload: res.data,
        payload_id: _id,
      });
      success("update");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};


export const deleteServiceItem = (_id) => (dispatch) => {
  if (confirm("Ar tikrai norite ištrinti ? ")) {
    Axios.delete(`/api/v1/presta_services/${_id}`)
      .then((res) => {
        dispatch({
          type: DELETE_SERVICE_ITEM,
          payload: _id,
        });
        success();
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        error();
      });
  }
};

export const setServicesLoading = () => {
  return {
    type: LOADING_PRESTA_SERVICES,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
