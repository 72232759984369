import React from "react";
import {
  ButtonEdit,
  ButtonDelete,
  EditElementClick,
} from "../../common/Buttons";

function TableColumnSms({ item, onDelete, index, onEdit }) {
  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle">{item.id}</td>
      <td className="align-middle">
        {" "}
        <EditElementClick
          name={item.code}
          onEdit={() => onEdit("edit", index)}
        />
      </td>
      <td className="align-middle">{item.company}</td>
      <td className="align-middle">{item.contact_person}</td>
      <td className="align-middle">
        {item.address} {" , "} {item.address2 ? item.address2 : ""}
      </td>
      <td className="align-middle">{item.city}</td>
      <td className="align-middle">{item.country_name}</td>
      <td className="align-middle">{item.postal_code}</td>
      <td className="align-middle">{item.phone}</td>
      <td className="align-middle">
        {item.work_time_to + " - " + item.pickup_time_from}{" "}
      </td>
      <td className="align-middle">{item.email}</td>
      <td className="align-middle">{item.active ? "Aktyvus" : "Neaktyvus"}</td>
      <td className="align-middle">{item.comment}</td>
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonEdit onEdit={() => onEdit("edit", index)} />
      </td>
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonDelete onDelete={() => onDelete(item.id)} />
      </td>
    </tr>
  );
}

export default TableColumnSms;
