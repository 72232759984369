import React, { useState, useEffect } from "react";
import TextInputModal from "../../../TextInputModal";
import { GiSave } from "react-icons/gi";
import { LongButton } from "../../../Buttons";
import { Select } from "antd";
import { getRivileStoreOptions } from "../../../../../redux/Actions/rivileStoreAction";
import { phonenumberValid } from "../../../../../helpers/Utils";
import { posti_postal_code } from "../../../../../helpers/RequestToAnotherServer";

function ShippingAddressComponent({
  shipping_address,
  UlElements,
  shippingAdr_action_edit,
  updateOrderAddress,
  customer,
  module_name,
}) {
  const [shippingAdr, setShippingAdr] = useState(shipping_address);
  const [rivileOpt, setRivileOpt] = useState([]);

  useEffect(() => {
    if (
      module_name !== "fspickupatstorecarrier" &&
      shippingAdr_action_edit &&
      shippingAdr.city &&
      shippingAdr.street
    ) {
      const t = setTimeout(async () => {
        const { success, postal_code } = await posti_postal_code(
          shippingAdr.city,
          shippingAdr.street
        );
        if (success && postal_code) {
          handleChangeText({
            target: { value: postal_code, name: "postal_code" },
          });
        }
      }, 1000);

      return () => clearTimeout(t);
    }
  }, [shippingAdr.city, shippingAdr.street]);

  useEffect(() => {
    if (module_name == "fspickupatstorecarrier") {
      getRivileStoreOptions().then((response) => setRivileOpt(response));
    }
  }, [module_name]);

  useEffect(() => {
    setShippingAdr(shipping_address);
  }, [shipping_address]);

  const handleChangeText = (e) => {
    const { name, value } = e.target;
    setShippingAdr({
      ...shippingAdr,
      [name]: value,
    });
  };

  const onChangeAddress = (value) => {
    setShippingAdr({
      ...shippingAdr,
      city: value.city,
      postal_code: value.postal_code,
      street: value.value,
      // firstname: "",
      // lastname: "",
      // phone: "",
      // other: "",
    });
  };

  const phonenumber = phonenumberValid(shipping_address.phone);

  const haveRivileShops =
    module_name == "fspickupatstorecarrier" && rivileOpt.length > 0;
  if (shippingAdr_action_edit) {
    if (module_name == "fspickupatstorecarrier") {
      return (
        <UlElements>
          <li>
            <div className="text-bold my-1">Parduotuvė</div>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder={"Pasirintki"}
              name="street"
              onChange={(e, arg) => onChangeAddress(arg)}
              dropdownMatchSelectWidth={true}
              value={shippingAdr.street}
              size="default"
            >
              {rivileOpt.map((item) => (
                <Select.Option
                  key={item.id}
                  value={item.address}
                  postal_code={item.postal_code}
                  city={item.city}
                >
                  {item.address}
                </Select.Option>
              ))}
            </Select>
          </li>
          <li className="my-3">
            <button
              className="btn btn-sm btn-primary"
              style={{ width: "100%" }}
              onClick={() => updateOrderAddress("shipping", shippingAdr)}
            >
              <GiSave />
              {"Atnaujinti"}
            </button>
          </li>
        </UlElements>
      );
    }
    return (
      <UlElements>
        <li>
          <TextInputModal
            text="Vardas"
            placeholder="Įvesti"
            name="firstname"
            value={shippingAdr.firstname}
            onChange={(e) => handleChangeText(e, "shippingAdr")}
          />
        </li>
        <li>
          <TextInputModal
            text="Pavardė"
            placeholder="Įvesti"
            name="lastname"
            value={shippingAdr.lastname}
            onChange={(e) => handleChangeText(e, "shippingAdr")}
          />
        </li>
        <li>
          <TextInputModal
            text="Adresas"
            placeholder="Įvesti"
            name="street"
            value={shippingAdr.street}
            onChange={(e) => handleChangeText(e, "shippingAdr")}
          />{" "}
        </li>

        <li>
          <TextInputModal
            text="Miestas"
            placeholder="Įvesti"
            name="city"
            value={shippingAdr.city}
            disabled={haveRivileShops}
            onChange={(e) => handleChangeText(e, "shippingAdr")}
          />{" "}
        </li>
        <li>
          <TextInputModal
            text="Pašto kodas"
            placeholder="Įvesti"
            name="postal_code"
            value={shippingAdr.postal_code}
            disabled={haveRivileShops}
            onChange={(e) => handleChangeText(e, "shippingAdr")}
          />{" "}
        </li>
        <li>
          <TextInputModal
            text="Telefono numeris"
            placeholder="Įvesti"
            name="phone"
            value={shippingAdr.phone}
            onChange={(e) => handleChangeText(e, "shippingAdr")}
            warning={
              phonenumberValid(shippingAdr.phone)
                ? null
                : "Blogai suvestas telefono numeris."
            }
          />{" "}
        </li>
        <li>
          <TextInputModal
            text="El. paštas"
            placeholder="Įvesti"
            name="other"
            value={shippingAdr.other}
            onChange={(e) => handleChangeText(e, "shippingAdr")}
          />
        </li>
        <li className="my-3">
          <button
            className="btn btn-sm btn-primary"
            style={{ width: "100%" }}
            onClick={() => updateOrderAddress("shipping", shippingAdr)}
          >
            <GiSave />
            {"Atnaujinti"}
          </button>
        </li>
      </UlElements>
    );
  }
  return (
    <React.Fragment>
      <UlElements>
        {module_name !== "fspickupatstorecarrier" && (
          <>
            <li>
              {shipping_address.firstname} {shipping_address.lastname}
            </li>
          </>
        )}
        <li>{shipping_address.street}</li>
        {shipping_address.street2 !== null && (
          <li>{shipping_address.street2}</li>
        )}
        {shipping_address.city || shipping_address.postal_code ? (
          <li>
            {shipping_address.city && `${shipping_address.city}, `}
            {shipping_address.postal_code}
          </li>
        ) : null}
        {module_name !== "fspickupatstorecarrier" && (
          <>
            <li className={phonenumber ? "" : "text-danger"}>
              {shipping_address.phone}
            </li>
            <li>{shipping_address.other}</li>
          </>
        )}
        {customer.consultant_code && (
          <li>Konsultantas: {customer.consultant_code}</li>
        )}
        {customer.rline && (
          <li>
            <span className="text-danger">[R-Line]:</span>
            {customer.rline_code}
          </li>
        )}

        {customer.rline && (
          <LongButton
            text={customer.email}
            style={{
              backgroundColor: "silver",
              color: "black",
              fontSize: "0.8rem",
              height: "24px",
            }}
          />
        )}
      </UlElements>
    </React.Fragment>
  );
}

export default ShippingAddressComponent;
