import React, { Fragment, useEffect } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { useSelector } from "react-redux";
// Components
import Login from "../components/Authorization/Login/Login";
import Omniauth from "../components/Authorization/Omniauth";
import Unavaible from "../components/Authorization/Unavailable/Unavailable";
import LoadingPage from "../page/LoadingPage";
import axios from "axios";
import Sidebar from "../components/Layout/Sidebar/Sidebar";
import OrderRoutesLogic from "./OrderRoutesLogic";
import { userAdditionalInformation } from "../redux/Actions/authAction";
import { useDispatch } from "react-redux";

function Routes({ loading }) {
  const dispatch = useDispatch();
  const token = document.querySelector('meta[name="csrf-token"]').content;
  axios.defaults.headers.common["X-CSRF-Token"] = token;

  const { user, isAuthenticated, isAvalaible, additional_information } =
    useSelector((state) => state.auth);
  let getCollapse = localStorage.getItem("collapse") == "true" ? "toggled" : "";

  useEffect(() => {
    if (isAuthenticated && user?.id) {
      dispatch(userAdditionalInformation());
      console.log("IM IN");
    }
  }, [isAuthenticated]);

  if (loading) {
    return (
      <Router>
        <Switch>
          <Route exact path="*" component={LoadingPage} />
        </Switch>
      </Router>
    );
  }

  if (isAuthenticated == false) {
    return (
      <Router>
        <Switch>
          <Route exact path="/sign_in" component={Login} />
          <Route exact path="/omniauth" component={Omniauth} />
          <Route exact path="*" component={Login} />
        </Switch>
      </Router>
    );
  } else if (isAvalaible === false && hasExtraAuthenticate(user)) {
    return (
      <Router>
        <Switch>
          <Route exact path="*" component={Unavaible} />
        </Switch>
      </Router>
    );
  }
  return (
    <Fragment>
      <div id="custom_modal"></div>
      <div className={`d-flex ${getCollapse}`} id="wrapper">
        <Router>
          <Sidebar
            location={{
              locationUrl: "Main page",
            }}
            auth={user}
            additional_information={additional_information}
          />
          <OrderRoutesLogic
            user_id={user.id}
            permissions={user.user_role?.permissions || {}}
          />
        </Router>
      </div>
    </Fragment>
  );
}

export default Routes;

const hasExtraAuthenticate = (user) => {
  if (env == "development") return false;
  if (
    [
      "smilte@bigbox.lt",
      "ilona@bigbox.lt",
      "justas@bigbox.lt",
      "sarune@bigbox.lt",
      "reda.c@bigbox.lt",
      "diana@bigbox.lt",
      "erika@bigbox.lt",
    ].includes(user.email)
  )
    return false;
  return true;
};
