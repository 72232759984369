import React from "react";
import moment from "moment";
import styled from "styled-components";

function EmailBlockEach({ item }) {
  return (
    <div>
      <hr />
      <div className="receiver">
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <UlElements>
                <li className="font-weight-bold">El. paštas</li>
                <li className="li-font">{item.receiver_email}</li>
              </UlElements>
            </div>
            <div className="col-6">
              <UlElements>
                <li className="font-weight-bold">Data</li>
                <li className="li-font">
                  {moment(item.created_at).format("YYYY-MM-DD HH:mm")}
                </li>
              </UlElements>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <UlElements>
          <li className="font-weight-bold">Užsakymas</li>
          <li className="li-font">{item.subject}</li>
        </UlElements>
      </div>
      <div className="col-12">
        <UlElements>
          <li className="font-weight-bold">Žinutė</li>
          <li className="li-font">{item.body}</li>
        </UlElements>
      </div>
    </div>
  );
}

const UlElements = styled.ul`
  margin-bottom: 8px;
  list-style-type: none;
  padding: 0px;
  li {
    word-wrap: break-word;
    font-size: 10px;
    line-height: 14px;
    color: #000000;
  }
  .li-font {
    font-size: 14px;
  }
`;

export default EmailBlockEach;
