import Axios from "axios";
import {
  GET_STORE,
  GET_STORES,
  POST_STORE,
  DELETE_STORE,
  UPDATE_STORE,
  LOADING_STORE,
  CLEAR_ERRORS,
  GET_ERRORS,
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

const success = () => {
  if (!document.getElementById("storeAlert-s")) return;
  document.getElementById("storeAlert-s").style.display = "block";
  setTimeout(
    () => (document.getElementById("storeAlert-s").style.display = "none"),
    5000
  );
  document.getElementById("modalClose").click();
};
const error = () => {
  if (!document.getElementById("storeAlert-e")) return;
  document.getElementById("storeAlert-e").style.display = "block";
  setTimeout(
    () => (document.getElementById("storeAlert-e").style.display = "none"),
    5000
  );
};

export const postStoreItem = (storeData) => (dispatch) => {
  Axios.post("/api/v1/stores", storeData)
    .then((res) => {
      dispatch({
        type: POST_STORE,
        payload: res.data,
      });
      dispatch(clearErrors());
      success("post");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const getStoreItems = (page, pageCount, filter) => (dispatch) => {
  dispatch(setStoreLoading());
  Axios.get(`/api/v1/stores?page=${page}&per_page=${pageCount}${filter}`)
    .then((res) => {
      dispatch({
        type: GET_STORES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getStoreItem = (id) => {
  return Axios.get(`/api/v1/stores/${id}`)
    .then((res) => {
      return {
        success: true,
        data: res.data,
      };
    })
    .catch((err) => {
      return {
        success: false,
        data: {},
      };
    });
};

export const getStoreOptions = (page = 1, per_page = 99999, filter = "") => {
  return Axios.get(`/api/v1/stores?page=${page}&per_page=${per_page}${filter}`).then((res) => {
    if (!res.data) {
      return [];
    }
    if (res.data.data) {
      const newData = [...res.data.data].filter((filt) => !!filt.rivile_code);
      return newData;
    }
  });
};

export const getPickupStoreOptions = () => {
  return Axios.get(`/pickups/requests/stores`).then((res) => {
    return res.data;
  });
};

export const deleteStoreItem = (_id) => (dispatch) => {
  if (confirm("Ar tikrai norite ištrinti ? ")) {
    Axios.delete(`/api/v1/stores/${_id}`)
      .then((res) => {
        dispatch({
          type: DELETE_STORE,
          payload: _id,
        });
        success();
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        error();
      });
  }
};

export const updateStoreItem = (_id, body) => (dispatch) => {
  Axios.put(`/api/v1/stores/${_id}`, body)
    .then((res) => {
      dispatch(clearErrors());
      dispatch({
        type: UPDATE_STORE,
        payload: res.data,
        payload_id: _id,
      });
      success("update");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const setStoreLoading = () => {
  return {
    type: LOADING_STORE,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
