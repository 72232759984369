import { Input } from "antd";
import React, { Fragment, useState } from "react";
import { FaCheck, FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";

function OrderRowsSimpleComponent({
  shipmentRowForStoreFunction,
  item,
  index,
}) {
  const [showRemove, setShowRemove] = useState(false);

  const onDeleteHandler = () => {
    if (confirm("Ar tikrai norite ištrinti ?")) {
      shipmentRowForStoreFunction("remove", index);
    }
  };

  const onChange = (e) => {
    shipmentRowForStoreFunction("edit", index, e);
  };
  return (
    <tr
      onMouseEnter={() => setShowRemove(true)}
      onMouseLeave={() => setShowRemove(false)}
    >
      <td className="align-middle text-center" style={{ width: "10%" }}>
        {item.edit && !item.delivery ? (
          <Input
            style={{
              width: "32px",
              height: "32px",
              borderRadius: "4px",
            }}
            name="quantity"
            value={item.quantity}
            onChange={onChange}
          />
        ) : 
          Number(item.quantity)
        }
      </td>
      <td className="align-middle">{item.product_reference && <span>{item.product_reference}</span>}</td>
      <td className="pr-0 align-middle">
        {item.edit ? (
          <Input
            placeholder="Įvesti"
            name="title"
            value={item.title}
            onChange={onChange}
          />
        ) : (
          <div>{item.title}</div>
        )}
      </td>
      <td className="pr-0 text-center align-middle" style={{width: "33%"}}>
        {item.edit ? (
          <Input
            type="number"
            name="price"
            placeholder="Įvesti"
            value={item.price}
            onChange={onChange}
          />
        ) : (
          <span>{Number(item.price).toFixed(2) || 0} &#8364;</span>
        )}
      </td>
      <td className="align-middle">
        <div className="d-flex justify-content-end align-items-center py-2">
          {item.edit ? (
            <Fragment>
              <FaCheck
                className="cursor-pointer text-success"
                style={{
                  marginLeft: "5px",
                }}
                onClick={() =>
                  onChange({ target: { name: "edit", value: !item.edit } })
                }
              />
              <FaTrash
                className="cursor-pointer text-danger"
                style={{
                  marginLeft: "5px",
                }}
                onClick={() => onDeleteHandler()}
              />
            </Fragment>
          ) : (
            <Fragment>
              <MdEdit
                className="cursor-pointer text-warning"
                style={{
                  visibility: showRemove ? "visible" : "hidden",
                  marginLeft: "5px",
                }}
                onClick={() =>
                  onChange({ target: { name: "edit", value: !item.edit } })
                }
              />

              <FaTrash
                className="cursor-pointer text-danger"
                style={{
                  visibility: showRemove ? "visible" : "hidden",
                  marginLeft: "5px",
                }}
                onClick={() => onDeleteHandler()}
              />
            </Fragment>
          )}
        </div>
      </td>
    </tr>
  );
}

export default OrderRowsSimpleComponent;
