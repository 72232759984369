import Axios from "axios";
import {
  antdMessageError,
  antdMessageSuccess,
  errorHandler,
} from "../../helpers/Utils";

export const updateCommerceStore = (product_id, params) => async (dispatch, getState) => {
  try {
    const api_url = `/api/v1/commerce/stores/${params.id}`;
    delete params.category;
    const { data } = await Axios({
      url: api_url,
      data: {
        store: params,
      },
      method: "PUT",
    });

    let product = getState().custom.data.find((product) => product.id == product_id)
    product.stores = product.stores.map(d => d.id == params.id ? ({ ...d, ...data }) : d);
    product.updated_at = new Date();
    // antdMessageSuccess("Sėkmingai išsaugotas");
    dispatch({
      type: "UPDATE_CUSTOM",
      payload: product,
    })
  } catch (err) {
    antdMessageError();
    errorHandler(err);
  }
};


export const updateCommerceStoreIds = (product_id, params) => async (dispatch) => {
  try {
    const { data } = await Axios({
      url: `/api/v1/commerce/products/${product_id}`,
      method: "PUT",
      data: params,
    });

    console.log('wtf: ',
      { ...data, update_at: new Date().toISOString() })
    dispatch({
      type: "UPDATE_CUSTOM",
      payload: { ...data, update_at: new Date().toISOString() },
    });

    return
  } catch (err) {
    antdMessageError();
    errorHandler(err);
    return { success: false };
  }
};

export const duplicateCommerceStore = (product_id, params) => async (dispatch, getState) => {
  try {
    const { data } = await Axios({
      url: `/api/v1/commerce/stores/${params.id}/dublicate`,
      method: "POST",
      data: {
        store: params,
      },
    });
    let product = getState().custom.data.find((product) => product.id == product_id)
    product.stores.push(data)
    antdMessageSuccess();
    dispatch({
      type: "UPDATE_CUSTOM",
      payload: product,
    })
  } catch (err) {
    antdMessageError();
    errorHandler(err);
    return { success: false };
  }
};



export const changesCommerceStore = (json) => async (dispatch, getState) => {
  try {
    json = JSON.parse(json)
    if (typeof json !== 'object') throw new Error('json is not an object')
    if (!Object.keys(json).length) throw new Error('json is not an object')
    console.log({ json })
    let product = getState().custom.data.find((product) => product.id == json.commerce_product_id)
    const findIndex = product.stores.findIndex((store) => store.id == json.id)
    if (findIndex !== -1) {
      product.stores[findIndex] = {
        ...product.stores[findIndex],
        ...json,
        updated_at: Date.now()
      }
      product.updated_at = Date.now()
    }
    dispatch({
      type: "UPDATE_CUSTOM",
      payload: product,
    })
    return 1
  } catch (err) {
    console.log('changesCommerceStore: ', err)
    return 0
  }
}

export const deleteCommerceStore = (product_id, commerce_store_id) => async (dispatch, getState) => {
  try {
    const { data } = await Axios({
      url: `/api/v1/commerce/stores/${commerce_store_id}`,
      method: "DELETE",
    });
    if (!data.success) throw new Error("Something went wrong");
    let product = getState().custom.data.find((product) => product.id == product_id)
    product.stores = product.stores.filter(d => d.id != commerce_store_id)
    antdMessageSuccess();
    dispatch({
      type: "UPDATE_CUSTOM",
      payload: product,
    })
  } catch (err) {
    antdMessageError();
    errorHandler(err);
    return { success: false };
  }
};
