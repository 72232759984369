import React from "react";
import InputText from "../../orders/accepted_orders/InputFilter";
import { DatePicker } from "antd";
import moment from "moment";
import { ButtonSuccessSimple, ButtonDelete } from "../../common/Buttons";
import { useEffect, useMemo } from "react";
import { useState } from "react";

function StatusJobsForm({
  status,
  index,
  onChangeValue,
  updateStatusJob,
  removeStatusJob,
  errorBlock,
  validateError,
}) {
  const [ShowErrors, setShowErrors] = useState({
    title: [],
    klass_name: [],
  });
  const getDateFormat = (date) => {
    var d = moment(date);
    return date && d.isValid() ? d : "";
  };

  useMemo(() => {
    const findIndex = errorBlock.findIndex((find) => find.id == status.id);
    if (findIndex !== -1) {
      setShowErrors(errorBlock[findIndex].errors);
    }
  }, [validateError]);

  return (
    <React.Fragment>
      <div className="d-flex">
        <div
          style={{
            width: "2%",
            borderLeft: `solid ${status.created_at ? "green" : "red"}`,
          }}
        ></div>
        <div style={{ width: "100%" }}>
          <div style={{ width: "98%" }}>
            <div className="d-flex">
              <div style={{ width: "45%" }}>
                <InputText
                  id={status.id + "pav"}
                  placeholder="Pavadinimas"
                  name="title"
                  value={status.title}
                  error={ShowErrors.title && ShowErrors.title.length !== 0}
                  onChange={(e) => onChangeValue(e, index)}
                />
              </div>
              <div style={{ width: "45%" }}>
                <InputText
                  id={status.id + "class_pav"}
                  placeholder="Klasės pavadinimas"
                  name="klass_name"
                  value={status.klass_name}
                  error={
                    ShowErrors.klass_name && ShowErrors.klass_name.length !== 0
                  }
                  onChange={(e) => onChangeValue(e, index)}
                />
              </div>
              <div
                className="d-flex align-items-end justify-content-end"
                style={{ width: "10%" }}
              >
                <ButtonSuccessSimple
                  onClick={() => updateStatusJob({ ...status, index })}
                />
              </div>
            </div>
          </div>
          <div style={{ width: "98%" }}>
            <div className="d-flex">
              <div style={{ width: "45%" }}>
                <div className="form-group">
                  <div>
                    <label className="editLabelForm">{"Pradžios data"}</label>
                  </div>

                  <DatePicker
                    format="YYYY-MM-DD HH:mm"
                    style={{ width: "100%" }}
                    value={getDateFormat(status.start_at)}
                    showTime={{ format: "HH:mm" }}
                    placeholder="Start"
                    allowClear={false}
                    onChange={(e, arg) =>
                      onChangeValue(
                        { target: { name: "start_at", value: arg } },
                        index
                      )
                    }
                  />
                </div>
              </div>
              <div style={{ width: "45%" }}>
                <div className="form-group">
                  <div>
                    <label className="editLabelForm">{"Pabaigos data"}</label>
                  </div>
                  <DatePicker
                    format="YYYY-MM-DD HH:mm"
                    style={{ width: "100%" }}
                    value={getDateFormat(status.end_at)}
                    showTime={{ format: "HH:mm" }}
                    placeholder="Start"
                    allowClear={false}
                    onChange={(e, arg) =>
                      onChangeValue(
                        { target: { name: "end_at", value: arg } },
                        index
                      )
                    }
                  />
                </div>
              </div>
              <div
                className="d-flex align-items-start justify-content-end"
                style={{ width: "10%" }}
              >
                <ButtonDelete
                  onDelete={() =>
                    removeStatusJob(status.id, {
                      created_at: status.created_at,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default StatusJobsForm;
