import React, { useEffect, useState, Fragment } from "react";
import { Upload, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getServicesShow,
  logServiceRepairUpdate,
  requestDocument,
  updateService,
  postServiceRowGoods,
  postServiceRowSupplyings,
  removeServiceRowSupplyings,
  updateServiceRowGoods,
  removeServiceRowGoods,
} from "../../../../redux/Actions/Service/serviceAction";
import {
  Button1,
  MainBlock,
  MainBlockWithContext,
  MiniBlock,
  MiniBlockOrderRows,
} from "../../../common/Buttons";
import Spinner from "../../../common/Spinner";
import PreviewComment from "./ServiceCommentPreview";
import SubmitComment from "./ServiceCommentSubmit";
import ServiceRepairAddress from "./modal/ServiceRepairAddress";
import ServiceRepairSms from "./ServiceRepairSms";
import { Divs, UlElements } from "../../../common/StyledComponents";
import {
  first_element_array,
  getUrlExtension,
  phonenumberValid,
} from "../../../../helpers/Utils";
import ServiceRepairShipment from "./modal/ServiceRepairShipment";
import moment from "moment";
import DocumentItem from "../../../common/GlobalModals/Orders/editModal/AttachOrderDocument/DocumentItem";
import FormWithOneInput from "../../../common/FormWithOneInput";
import { toFixedNumber } from "../../../../helpers/Utils";
import { status_options } from "../../../../helpers/GlobalOptions";
import OrderRowAdd from "../../../common/GlobalModals/Orders/editModal/order_row_add";
import { getSuppliersOption } from "../../../../redux/Actions/supplierAction";
import OrderRowsComponent from "../../../common/GlobalModals/Orders/editModal/order_rows";
import ServiceSupplier from "./ServiceSupplier";
import styled from "styled-components";
import StatusComponent from "../../../common/GlobalModals/Orders/StatusComponent";
import PaymentUpsale from "../../../common/GlobalModals/Orders/editModal/Payments/payment_upsale";
import ServiceDescriptionEditable from "./ServiceDescriptionEditable";
import ServiceChangeProduct from "./ServiceProductChange";

const collapseBlockFetch = () => {
  const defaultStorage = { general: true };

  const getLocalStorage = JSON.parse(localStorage.getItem("serviceRepair"))
    ? JSON.parse(localStorage.getItem("serviceRepair"))
    : defaultStorage;

  return getLocalStorage;
};

function ServiceModalComponent({ id }) {
  const dispatch = useDispatch();
  const { serviceShow, loadingShow } = useSelector((state) => state.service);
  const [showBlock, setShowBlock] = useState(collapseBlockFetch());
  const [supplySupplierData, setSupplySupplierData] = useState([]);
  const [editRepairPrice, setEditRepairPrice] = useState({
    price: serviceShow.service.price,
    contoller: false,
    loading: false,
  });

  useEffect(() => {
    dispatch(getServicesShow(id));
    dispatch(logServiceRepairUpdate(id));

    getSuppliersOption().then((response) => {
      if (response.data) {
        let newArray = [];
        response.data.data.forEach((item) => {
          const findIndex = newArray.findIndex(
            (find) => find.name == item.name
          );
          if (findIndex == -1) {
            return newArray.push({
              value: item.id,
              name: item.name,
              code: item.code,
              label: item.name,
              is_code: item.is_code,
            });
          }
        });
        setSupplySupplierData(newArray);
      }
    });
  }, []);

  const handleChangeCollapse = (name) => {
    const showObj = {
      ...showBlock,
      [name]: !showBlock[name],
    };
    setShowBlock(showObj);
    localStorage.setItem("serviceRepair", JSON.stringify(showObj));
  };

  const onClickSavePrice = () => {
    setEditRepairPrice({ ...editRepairPrice, loading: true });
    dispatch(updateService({ price: editRepairPrice.price, id })).then(
      (res) => {
        setEditRepairPrice({
          ...editRepairPrice,
          loading: false,
          contoller: false,
        });
      }
    );
  };

  const openRepairPrice = () => {
    if (editRepairPrice.contoller) {
      setEditRepairPrice({ ...editRepairPrice, contoller: false });
    } else {
      setEditRepairPrice({
        ...editRepairPrice,
        price: toFixedNumber(serviceShow.service.price),
        contoller: true,
      });
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () =>
      callback({ name: img.name, url: reader.result })
    );
    reader.readAsDataURL(img);
  }

  const onChange = (info) => {
    if (info.file.status === "done") {
      getBase64(
        info.file.originFileObj,
        (imgObj) => dispatch(requestDocument(id, imgObj.url, "custom"))
        // onChangeAttachments(name, "docs", [...items, imgObj])
      );
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} failo įkėlimas nepavyko!`);
      // this.setState({
      //   excel_loading: false,
      // });
    }
  };

  const {
    service,
    address,
    service_documents,
    service_description,
    sms,
    active_log,
    repair_history,
    parcel_terminals,
    shipments,
    waiting_payments,
  } = serviceShow;

  if (loadingShow) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spinner />
      </div>
    );
  }
  return (
    <div className="row">
      <div className="col-12 col-lg-6">
        {service && (
          <MainBlockWithContext
            color={"#ffa800"}
            action={showBlock.general}
            text={"Kliento informacija"}
            onChange={() => handleChangeCollapse("general")}
          >
            {showBlock.general && (
              <>
                <div className="py-4">
                  <div className="col-6">
                    <ServiceRepairAddress
                      address={address}
                      orderId={service.id}
                      rivile_store_id={service.rivile_store_id}
                    />
                  </div>
                  <div className="text-center mb-4">
                    <div
                      className="badge badge-warning badge-middle  badge-order-price cursor-pointer"
                      // onClick={() => openRepairPrice()}
                    >
                      <Fragment>
                        Remonto kaina: {toFixedNumber(service.total_price)}{" "}
                        {"\u20AC"}
                      </Fragment>
                    </div>
                  </div>
                  {editRepairPrice.contoller && (
                    <FormWithOneInput
                      type="number"
                      value={editRepairPrice.price || 0}
                      loading={editRepairPrice.loading}
                      onChange={(e) =>
                        setEditRepairPrice({
                          ...editRepairPrice,
                          price: e.target.value,
                        })
                      }
                      onSave={() => onClickSavePrice()}
                      onClose={() => openRepairPrice()}
                    />
                  )}
                </div>
                {/*  */}

                <MiniBlock
                  color={"#8fc8f8"}
                  action={showBlock.general_goods}
                  onChange={() => handleChangeCollapse("general_goods")}
                >
                  Prekės
                </MiniBlock>
                {showBlock.general_goods && (
                  <div className="bg-silver">
                    <div className="col-12 py-3 mb-2">
                      <div className="table-responsive">
                        <table className="table table-sm mb-0">
                          {service.service_rows &&
                            service.service_rows
                              .filter((e) => e.manual === false)
                              .map((e) => ({
                                id: e.id,
                                quantity: e.item_quantity,
                                product_name: e.item_name,
                                unit_price_tax_incl: e.item_price,
                                product_reference: e.item_code,
                                serial_number: !e.manual
                                  ? service.serial_number
                                  : "",
                                manual: false,
                                supplyings: e.supplyings,
                                service_row_changes: e.service_row_changes,
                              }))
                              .map((item, index) => (
                                <OrderRowsComponent
                                  mode={"Servisas"}
                                  key={index}
                                  item={item}
                                  orderId={service.id}
                                  deleteSupply={(itemId, orderId) =>
                                    dispatch(
                                      removeServiceRowSupplyings(
                                        itemId,
                                        orderId,
                                        service.id
                                      )
                                    )
                                  }
                                  updateSerialNumber={() => {}}
                                  updateOrderRowGoods={(
                                    variables,
                                    orderId,
                                    itemId
                                  ) =>
                                    dispatch(
                                      updateServiceRowGoods(
                                        variables,
                                        orderId,
                                        itemId
                                      )
                                    )
                                  }
                                  deleteOrderRowGoods={(orderId, itemID) =>
                                    dispatch(
                                      removeServiceRowGoods(orderId, itemID)
                                    )
                                  }
                                />
                              ))}
                        </table>
                      </div>
                      <div className="my-3"></div>

                      {/* table */}
                      <div className="table-responsive">
                        <TableStyled className="table table-sm mb-0">
                          <thead>
                            <tr>
                              {service.buy_date && (
                                <th scope="col">Pirkimo data</th>
                              )}
                              {service.certificate_date && (
                                <th scope="col">Sertifikato data:</th>
                              )}
                              {service.certificate_number && (
                                <th scope="col">Sertifikato numeris</th>
                              )}

                              {service.buy_document && (
                                <th scope="col">Pirktas dokumentas</th>
                              )}

                              {service.invoice && (
                                <th scope="col">Sąskaita faktūra</th>
                              )}

                              {service.document_type && (
                                <th scope="col">Dokumento tipas</th>
                              )}

                              {service.number && <th scope="col">Numeris</th>}

                              {service.service_type && (
                                <th scope="col">Paslaugos tipas</th>
                              )}
                              {service.rivile_store && (
                                <th scope="col">Padalinys</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {service.buy_date && (
                                <td>
                                  {moment(service.buy_date).format(
                                    "YYYY-MM-DD"
                                  )}
                                </td>
                              )}
                              {service.certificate_date && (
                                <td>
                                  {moment(service.certificate_date).format(
                                    "YYYY-MM-DD"
                                  )}
                                </td>
                              )}
                              {service.certificate_number && (
                                <td>{service.certificate_number}</td>
                              )}

                              {service.buy_document && (
                                <td>{service.buy_document}</td>
                              )}
                              {service.invoice && <td>{service.invoice}</td>}
                              {service.document_type && (
                                <td>
                                  {service.document_type == "invoice"
                                    ? "Sąskaita faktūra"
                                    : service.document_type == "cheque"
                                    ? "Čekis"
                                    : ""}
                                </td>
                              )}
                              {service.number && <td>{service.number}</td>}
                              {service.service_type && (
                                <td>{service.service_type}</td>
                              )}
                              {service.rivile_store && (
                                <td>{service.rivile_store?.title}</td>
                              )}
                            </tr>
                          </tbody>
                        </TableStyled>
                      </div>
                      <div className="my-3"></div>
                      {/* Table end */}

                      {service_description && (
                        <UlElements
                          className="pl-2"
                          style={{ borderLeft: "3px solid #8fc8f8" }}
                        >
                          <ServiceDescriptionEditable
                            label={"Gedimai"}
                            value_name="description"
                            id={service_description.id}
                            value={service_description.description}
                            manual={service_description.description_manual}
                          />
                          <ServiceDescriptionEditable
                            label={"Prekės išorė"}
                            value_name="item_description"
                            id={service_description.id}
                            value={service_description.item_description}
                            manual={service_description.item_description_manual}
                          />
                          <ServiceDescriptionEditable
                            label={"Komplektacija"}
                            value_name="item_set"
                            id={service_description.id}
                            value={service_description.item_set}
                            manual={service_description.item_set_manual}
                          />
                          {service_description.custom && (
                            <li
                              className={
                                "d-flex justify-content-between align-items-center"
                              }
                              style={{ fontSize: "0.8rem" }}
                            >
                              <span>
                                <span className="font-weight-bold">
                                  Aprašymas:{" "}
                                </span>
                                {service_description.custom}
                              </span>
                            </li>
                          )}
                        </UlElements>
                      )}
                    </div>
                  </div>
                )}

                <MiniBlockOrderRows
                  color={"#8fc8f8"}
                  action={showBlock.general_repair}
                  onChange={() => handleChangeCollapse("general_repair")}
                  goodsAddRow={showBlock.general_repair_add}
                  goodsAddRowClick={() =>
                    setShowBlock({
                      ...showBlock,
                      general_repair_add: !showBlock.general_repair_add,
                    })
                  }
                >
                  Remontas
                </MiniBlockOrderRows>
                {showBlock.general_repair && (
                  <Divs>
                    <div className="bg-silver py-2">
                      <div className="col-12">
                        <div className="table-responsive">
                          <table className="table table-sm mb-0">
                            {service.service_rows &&
                              service.service_rows
                                .filter((e) => e.manual)
                                .map((e) => ({
                                  id: e.id,
                                  quantity: e.item_quantity,
                                  product_name: e.item_name,
                                  unit_price_tax_incl: e.item_price,
                                  product_reference: e.item_code,
                                  manual: true,
                                  supplyings: e.supplyings,
                                }))
                                .map((item, index) => (
                                  <OrderRowsComponent
                                    mode={"Servisas_Remontas"}
                                    key={index}
                                    item={item}
                                    orderId={service.id}
                                    deleteSupply={(itemId, orderId) =>
                                      dispatch(
                                        removeServiceRowSupplyings(
                                          itemId,
                                          orderId,
                                          service.id
                                        )
                                      )
                                    }
                                    updateSerialNumber={() => {}}
                                    updateOrderRowGoods={(
                                      variables,
                                      orderId,
                                      itemId
                                    ) =>
                                      dispatch(
                                        updateServiceRowGoods(
                                          variables,
                                          orderId,
                                          itemId
                                        )
                                      )
                                    }
                                    deleteOrderRowGoods={(orderId, itemID) =>
                                      dispatch(
                                        removeServiceRowGoods(orderId, itemID)
                                      )
                                    }
                                  />
                                ))}
                          </table>
                        </div>
                      </div>
                      {showBlock.general_repair_add && (
                        <div>
                          <OrderRowAdd
                            mode="Servisas"
                            orderId={service.id}
                            postOrderRowGoods={(body, orderId, rowGoods) =>
                              dispatch(
                                postServiceRowGoods(body, orderId, rowGoods)
                              )
                            }
                          />
                        </div>
                      )}
                    </div>
                  </Divs>
                )}

                <MiniBlock
                  color={"#8fc8f8"}
                  action={showBlock.general_supply}
                  onChange={() => handleChangeCollapse("general_supply")}
                >
                  Servisai
                </MiniBlock>
                {showBlock.general_supply && (
                  <div className="bg-silver">
                    <ServiceSupplier
                      mode="Servisas"
                      supplyItem={
                        service.service_rows
                          ? service.service_rows
                              .filter((e) => !e.manual)
                              .map((e) => ({
                                value: e.id,
                                label: e.item_name,
                              }))
                          : []
                      }
                      order_rows={
                        service.service_rows ? service.service_rows : []
                      }
                      supplySupplier={supplySupplierData}
                      orderId={service.id}
                      postOrderRow={(
                        resVariables,
                        orderId,
                        supplier_selected
                      ) =>
                        postServiceRowSupplyings(
                          resVariables,
                          orderId,
                          supplier_selected,
                          service.id
                        )
                      }
                    />
                  </div>
                )}
                {/*  */}
                <MiniBlock
                  color={"#8fc8f8"}
                  action={showBlock.general_change}
                  onChange={() => handleChangeCollapse("general_change")}
                >
                  Keitimas
                </MiniBlock>
                {showBlock.general_change && (
                  <div className="bg-silver">
                    <ServiceChangeProduct
                      service_id={service.id}
                      service_row_id={
                        service.service_rows
                          ? first_element_array(
                              service.service_rows
                                .filter((e) => !e.manual)
                                .map((e) => e.id)
                            )
                          : null
                      }
                    />
                  </div>
                )}
                {/*  */}
                <MiniBlock
                  color={"#8fc8f8"}
                  action={showBlock.general_status}
                  onChange={() => handleChangeCollapse("general_status")}
                >
                  Statusas
                </MiniBlock>
                {showBlock.general_status && (
                  <Divs>
                    <StatusComponent
                      mode="Servisas"
                      orderId={service.id}
                      avalaibleStatus={status_options}
                      order_histories={active_log.map((e) => ({
                        datetime: e.created_at,
                        title: e.user,
                        status: e.body,
                      }))}
                    />
                  </Divs>
                )}
                <MiniBlock
                  color={"#8fc8f8"}
                  action={showBlock.general_upsale}
                  onChange={() => handleChangeCollapse("general_upsale")}
                >
                  Apmokėjimai
                </MiniBlock>
                {showBlock.general_upsale && (
                  <Divs>
                    <div className="col-12 bg-silver py-2">
                      <PaymentUpsale
                        waiting_payments={waiting_payments}
                        orderId={service.id}
                        type="Service"
                      />
                    </div>
                  </Divs>
                )}
              </>
            )}
          </MainBlockWithContext>
        )}
        {service && service.service_comments && (
          <MainBlockWithContext
            color={"#008aff"}
            action={showBlock.comments}
            text={"Komentarai"}
            onChange={() => handleChangeCollapse("comments")}
          >
            <div className="mb-4 mt-2">
              <MiniBlock
                color={"#7ebff7"}
                action={showBlock.comments_ka}
                onChange={() => handleChangeCollapse("comments_ka")}
              >
                KA komentarai
              </MiniBlock>

              {showBlock.comments_ka && (
                <div className="col-12">
                  <div className="my-2"></div>
                  <SubmitComment identifier="comment_ka" service_id={id} />
                  <PreviewComment
                    comments={service.service_comments}
                    type="comment_ka"
                  />
                </div>
              )}
              <MiniBlock
                color={"#7ebff7"}
                action={showBlock.comments_lp}
                onChange={() => handleChangeCollapse("comments_lp")}
              >
                Logistikos komentarai
              </MiniBlock>
              {showBlock.comments_lp && (
                <div className="col-12">
                  <div className="my-2"></div>
                  <SubmitComment
                    identifier="comment_logistic"
                    service_id={id}
                  />
                  <PreviewComment
                    comments={service.service_comments}
                    type="comment_logistic"
                  />
                </div>
              )}
            </div>
          </MainBlockWithContext>
        )}

        <MainBlockWithContext
          color={"#d07ffe"}
          action={showBlock.doc_upload}
          text={"Dokumentai"}
          onChange={() => handleChangeCollapse("doc_upload")}
        >
          <div className="pb-3">
            {service_documents.filter(
              (f) => f.document_type == "goods_appearance"
            ).length > 0 && (
              <>
                <MiniBlock
                  color={"#dca7fb"}
                  action={showBlock.document_1}
                  onChange={() => handleChangeCollapse("document_1")}
                >
                  Prekės išvaizda
                </MiniBlock>

                {showBlock.document_1 && (
                  <>
                    <DocumentItem
                      documents={service_documents
                        .filter((f) => f.document_type == "goods_appearance")
                        .map((e) => ({
                          ...e,
                          asset: { url: e.image_base64 },
                          title: "Prekės išvaizda",
                          format: getUrlExtension(e.attachment.url),
                        }))}
                      deleteOrderDocument={false}
                    />
                  </>
                )}
              </>
            )}

            {service_documents.filter((f) => f.document_type == "complaint")
              .length > 0 && (
              <>
                <MiniBlock
                  color={"#dca7fb"}
                  action={showBlock.document_2}
                  onChange={() => handleChangeCollapse("document_2")}
                >
                  Skundai
                </MiniBlock>

                {showBlock.document_2 && (
                  <div className="mb-2">
                    <DocumentItem
                      documents={service_documents
                        .filter((f) => f.document_type == "complaint")
                        .map((e) => ({
                          ...e,
                          asset: { url: e.image_base64 },
                          title: "Skundai",
                          format: getUrlExtension(e.attachment.url),
                        }))}
                      deleteOrderDocument={false}
                    />
                  </div>
                )}
              </>
            )}
            {service_documents.filter((f) => f.document_type == "defect_act")
              .length > 0 && (
              <>
                <MiniBlock
                  color={"#dca7fb"}
                  action={showBlock.document_3}
                  onChange={() => handleChangeCollapse("document_3")}
                >
                  Defekto aktas
                </MiniBlock>
                {showBlock.document_3 && (
                  <div className="mb-2">
                    <DocumentItem
                      documents={service_documents
                        .filter((f) => f.document_type == "defect_act")
                        .map((e) => ({
                          ...e,
                          asset: { url: e.image_base64 },
                          title: "Defekto aktas",
                          format: getUrlExtension(e.attachment.url),
                        }))}
                      deleteOrderDocument={false}
                    />
                  </div>
                )}
              </>
            )}

            <MiniBlock
              color={"#dca7fb"}
              action={showBlock.document_4}
              onChange={() => handleChangeCollapse("document_4")}
            >
              Kita
            </MiniBlock>

            {showBlock.document_4 && (
              <>
                <div className="mb-2">
                  <DocumentItem
                    documents={service_documents
                      .filter((f) => f.document_type == "custom")
                      .map((e) => ({
                        ...e,
                        asset: { url: e.image_base64 },
                        // asset: e.attachment ,
                        title: "Kita",
                        format: getUrlExtension(e.attachment.url),
                      }))}
                    deleteOrderDocument={false}
                  />

                  <div className="position-relative mt-5">
                    <Upload
                      showUploadList={false}
                      onChange={onChange}
                      customRequest={dummyRequest}
                    >
                      <Button1
                        style={{
                          position: "absolute",
                          top: "-16px",
                          left: "50%",
                          transform: "translate(-50%, 0px)",
                        }}
                        text={"Pridėti naują dokumentą"}
                        option={"plus"}
                      />
                      <hr />
                    </Upload>
                  </div>
                </div>
              </>
            )}
          </div>
        </MainBlockWithContext>
      </div>

      <div className="col-12 col-lg-6">
        <Divs>
          {!showBlock.shipping && (
            <MainBlock
              color={"#59aba0"}
              action={showBlock.shipping}
              onChange={() => handleChangeCollapse("shipping")}
            >
              Siuntimas
            </MainBlock>
          )}
          {showBlock.shipping && (
            <ServiceRepairShipment
              id={id}
              shipmentAddr={address || {}}
              service={service}
              parcel_terminals={parcel_terminals || []}
              shipments={shipments}
              onChangeCol={() => handleChangeCollapse("shipping")}
            />
          )}
        </Divs>

        <Divs>
          <MainBlockWithContext
            color={"#adcb35"}
            action={showBlock.sms_send}
            text={
              <span>
                SMS siuntimas numeriui{" "}
                <span
                  className={
                    phonenumberValid(address.phone || "") ? "" : "text-danger"
                  }
                >
                  {address.phone || ""}
                </span>
              </span>
            }
            onChange={() => handleChangeCollapse("sms_send")}
          >
            {showBlock.sms_send && (
              <ServiceRepairSms
                id={id}
                sms={sms}
                phoneNumber={address.phone}
                shipping_address_street={address.street}
                orderId={service.number}
                shipments={shipments}
              />
            )}
          </MainBlockWithContext>
        </Divs>
        <Divs>
          <MainBlockWithContext
            color={"#428dcc"}
            action={showBlock.history}
            text="Kita"
            onChange={() => handleChangeCollapse("history")}
          >
            {showBlock.history && (
              <Divs>
                {repair_history?.order_history_presta?.length > 0 && (
                  <React.Fragment>
                    <MiniBlock
                      color={"#629acb"}
                      action={showBlock.eshop_history}
                      onChange={() => handleChangeCollapse("eshop_history")}
                    >
                      Eshop istorija
                    </MiniBlock>
                    {showBlock.eshop_history && (
                      <div className="px-3 pt-2">
                        {repair_history?.order_history_presta.map((e, idx) => (
                          <div className="py-1" key={idx}>
                            <UlElements className="border-bottom">
                              <li className="mb-0 font-weight-bold">
                                Užsakymas:{" "}
                                <span
                                  className="cursor-pointer text-primary"
                                  onClick={() =>
                                    window.open(`/orders/${e.id}`, "_blank")
                                  }
                                >
                                  {e.order_id}
                                </span>
                              </li>
                              <li>Sukurtas: {e.created_at}</li>
                            </UlElements>
                          </div>
                        ))}
                      </div>
                    )}
                  </React.Fragment>
                )}
                <MiniBlock
                  color={"#629acb"}
                  action={showBlock.repair_history}
                  onChange={() => handleChangeCollapse("repair_history")}
                >
                  Remonto istorija
                </MiniBlock>
                {showBlock.repair_history && (
                  <div className="px-3 pt-2">
                    <div className="py-1 text-center">
                      <span>
                        Istorija <b>S/N {service?.serial_number}</b>
                      </span>
                    </div>

                    <div className="py-1 d-flex justify-content-between align-items-center">
                      <span>
                        Servisai (
                        <b>
                          {repair_history.history
                            ? Object.entries(repair_history.history).length
                            : 0}
                        </b>
                        )
                      </span>
                      <span>
                        Užsakymai (
                        <b>{repair_history?.order_history?.length}</b>)
                      </span>
                      <span>
                        Dokumentai (
                        <b>{repair_history?.documents_history?.length}</b>)
                      </span>
                    </div>
                    {repair_history?.history &&
                      Object.entries(repair_history.history).map((e, idx) => (
                        <div className="py-1" key={idx}>
                          {e[1].length > 0 && (
                            <UlElements className="border-bottom">
                              <li className="mb-0 font-weight-bold">
                                Servisas:{" "}
                                <span
                                  className="cursor-pointer text-primary"
                                  onClick={() =>
                                    window.open(
                                      `/service/repair/${e[1][0].id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  {e[0]}
                                </span>
                              </li>
                              {e[1].map((i) => (
                                <li key={i.text}>
                                  Veiksmas: {i.date} - {i.text}
                                </li>
                              ))}
                            </UlElements>
                          )}
                        </div>
                      ))}
                    <div className="py-1">
                      {repair_history?.order_history?.map((e) => (
                        <UlElements className="border-bottom">
                          <li className="mb-0 font-weight-bold">
                            Užsakymas:{" "}
                            <span
                              className="cursor-pointer text-primary"
                              onClick={() =>
                                window.open(`/orders/${e.id}`, "_blank")
                              }
                            >
                              {e.order_id}
                            </span>
                          </li>
                          <li>Sukurtas: {e.created_at}</li>
                        </UlElements>
                      ))}
                    </div>
                    <div className="py-1">
                      {repair_history?.documents_history?.map((e) => (
                        <UlElements className="border-bottom">
                          <li className="mb-0 font-weight-bold">
                            Dokumentas:{" "}
                            <span
                              className="cursor-pointer text-primary"
                              onClick={() =>
                                window.open(`/documents/${e.id}`, "_blank")
                              }
                            >
                              {e.number}
                            </span>
                          </li>
                          <li>Sukurtas: {e.created_at}</li>
                        </UlElements>
                      ))}
                    </div>
                  </div>
                )}
              </Divs>
            )}
          </MainBlockWithContext>
        </Divs>
      </div>
    </div>
  );
}

const TableStyled = styled.table`
  th {
    font-size: 0.8rem;
  }

  td {
    font-size: 0.8rem;
  }
`;

export default ServiceModalComponent;
