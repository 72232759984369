import React from "react";
import InputText from "../orders/accepted_orders/InputFilter";
import Container from "../Layout/Container";
import ContentBlock from "../Layout/ContentBlock";
import FilterMenu from "../Layout/FilterMenu";
import TableFilterBox from "../common/TableFilterBox";
import TableColumnUsers from "./items/TableColumnPickupUsers";
import UserseditModal from "./items/PickupUsersEditModal";
import ContentValidation from "../common/ContentValidation";
import {
  getUsers,
  postUser,
  deleteUer,
  updateUser,
  clearErrors,
  authRemove
} from "../../redux/Actions/pickup_userAction";
import { connect } from "react-redux";
import PaginationComp from "../Dashboard/items/Pagination";

class PickupUsers extends React.Component {
  constructor() {
    super();
    this.state = {
      requiredId: 0,
      page: 1,
      pageCount: 100,

      filter: {
        email: "",
        first_lastname: "",
      },
      paramsFilter: "",
      action: "new",
    };
  }

  componentDidMount() {
    this.reloadData(
      this.state.paramsFilter
    );
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount 
    ) {
      this.reloadData(
        this.state.paramsFilter
      );
    }
  }

  reloadData = (paramsFilter) => {
    this.props.getUsers(this.state.page, this.state.pageCount, paramsFilter);
  };

  onAction = (action, index) => {
    this.setState({
      requiredId: index,
      action: action,
    });
  };

  delayOnFilter = () => {
    if (this.props.loading_users) {
      return setTimeout(() => {
        return this.delayOnFilter();
      }, 1000);
    }
    this.onFilter();
  };


  onFilter = () => {
    if (this.props.loading_users) {
      return this.delayOnFilter();
    }
    let filterParams = [];
    if (this.state.filter.email.length > 0)
      filterParams.push(`&q[email_i_cont_any]=${this.state.filter.email}`);
    if (this.state.filter.first_lastname.length > 0) {
      let clientArray = this.state.filter.first_lastname.split(" ");
      clientArray.forEach((item) => {
        filterParams.push(`&q[title_cont]=${item}`);
      });
    }
    this.setState({
      paramsFilter: filterParams.join(""),
      page: 1
    }, () => this.reloadData(filterParams.join("")));
  };

  filterChange = (e) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [e.target.name]: e.target.value,
      },
    });
  };

  setPagination = (page) => {
    this.setState({
      page,
    });
  };
  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  handleKeyDown = (e) => {
    if (e.key == "Enter") {
      this.onFilter();
    }
  };

  clearFilter = () => {
    this.setState(
      {
        filter: {
          email: "",
          first_lastname: "",
        },
      },
      () => this.onFilter()
    );
  };


  render() {
    const { errors, users } = this.props;
    const requiredItem = this.state.requiredId;
    let modalData = users[requiredItem] ? users[requiredItem] : [];
    return (
      <>
        <Container
          location={{
            locationName: "Atsiėmimų vartotojai",
            locationUrl: this.props.match.url,
          }}
        >
          <>
            <ContentBlock>
              <FilterMenu
                length={`${this.props.totals || 0} / ${
                  this.props.users.length || 0
                }`}
                q
                onChangeCountPage={this.setPageCount}
                countPage={this.state.pageCount}
                text="vartotojų"
                text2="Vartotojų"
              />
            </ContentBlock>
            <ContentBlock>
              <TableFilterBox
                // filter={true}
                add={this.onAction}
                // onFilter={this.onFilter}
                clearFilter={() => this.clearFilter()}

              >
                <div className="px-1">
                  <InputText
                    width={"0.75rem"}
                    number="1"
                    name="email"
                    placeholder="Email"
                    onKeyDown={this.handleKeyDown}
                    value={this.state.filter.email}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
                <div className="px-1">
                  <InputText
                    width={"0.75rem"}
                    number="1"
                    name="first_lastname"
                    placeholder="Vardas Pavardė"
                    onKeyDown={this.handleKeyDown}
                    value={this.state.filter.first_lastname}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
              </TableFilterBox>
              <div>
                <ContentValidation
                  loader={this.props.loading_users}
                  array={this.props.users}
                  alertId_s="userAlert-s"
                  alertId_e="userAlert-e"
                >
                  <div className="table-responsive">
                    <table className="table table-sm table-hover mt-3">
                      <thead>
                        <tr className="text-muted">
                          <th className="text-black"></th>
                          <th className="text-black">Email</th>
                          <th className="text-black">Vardas Pavardė</th>
                          <th className="text-black">2FA autentifikacija</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.users.map((item, index) => {
                          return (
                            <TableColumnUsers
                              key={index}
                              index={index}
                              id={item.id}
                              item={item}
                              onEdit={this.onAction}
                              onDelete={this.props.deleteUer}
                              handleCheckboxChange={this.handleCheckboxChange}
                              checkedArray={this.state.checkedArray}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                    <PaginationComp
                      setPagination={this.setPagination}
                      page={this.state.page}
                      totals={this.props.totals}
                      pageCount={this.state.pageCount}
                    />
                  </div>
                </ContentValidation>
              </div>
            </ContentBlock>
          </>

          <UserseditModal
            action={this.state.action}
            postUser={this.props.postUser}
            updateUser={this.props.updateUser}
            objectData={modalData}
            clearErrors={this.props.clearErrors}
            errors={errors}
            authRemove={this.props.authRemove}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.pickup_user.users,
  totals: state.pickup_user.totals,
  loading_users: state.pickup_user.loading_users,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getUsers,
  postUser,
  deleteUer,
  updateUser,
  clearErrors,
  authRemove
})(PickupUsers);
