export const successToast = (title) => {
  if (!document.getElementById(title)) return;
  document.getElementById(title).style.display = "block";
  setTimeout(() => {
    if (document.getElementById(title)) {
      document.getElementById(title).style.display = "none";
    }
  }, 5000);
};
export const unsuccessToast = (title) => {
  if (!document.getElementById(title)) return;
  document.getElementById(title).style.display = "block";
  setTimeout(() => {
    if (document.getElementById(title)) {
      document.getElementById(title).style.display = "none";
    }
  }, 5000);
};
