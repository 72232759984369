import {
  LOADING_DOCUMENTS,
  GET_DOCUMENTS,
  GET_DOCUMENT,
  UPDATE_CONTRACT_ITEM_TEST,
  SEND_DOCUMENT_EMAIL,
  ADD_CONTRACT_DOCUMENT,
  UPDATE_CONTRACT_ITEM_DOCUMENT,
  CANCEL_DOCUMENT,
} from "../Actions/types";

const initialState = {
  document: {},
  document_history: {},
  documents: [],
  totals: 0,
  total_price: 0,
  loading_documents: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_DOCUMENTS:
      return {
        ...state,
        loading_documents: true,
      };
    case GET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload.data,
        total_price: action.payload.total_price,
        totals: action.payload.totals,
        loading_documents: false,
      };
    case GET_DOCUMENT:
      return {
        ...state,
        document: action.payload,
      };
    case "GET_DOCUMENT_HISTORY":
      return {
        ...state,
        document_history: action.payload,
      };
    case ADD_CONTRACT_DOCUMENT:
      let contractDoc = [...state.documents];
      contractDoc.unshift(action.payload);
      return {
        ...state,
        documents: contractDoc,
      };
    case UPDATE_CONTRACT_ITEM_DOCUMENT:
      return {
        ...state,
        documents: updateContractItemsDocument(action.payload, state.documents),
        document: action.payload,
      };
    case SEND_DOCUMENT_EMAIL: {
      return {
        ...state,
        document: {
          ...state.document,
          contract_emails: [...state.document.contract_emails, action.payload],
        },
      };
    }
    case UPDATE_CONTRACT_ITEM_TEST:
      let data = { ...state.document };
      data.contract_items.push(action.payload);
      return {
        ...state,
        document: data,
      };
    case CANCEL_DOCUMENT:
      return {
        ...state,
        documents: updateContractItemsDocument(action.payload, state.documents),
      };
    default:
      return state;
  }
}

const updateContractItemsDocument = (action, data) => {
  let oldData = [...data];
  const findIndex = oldData.findIndex((item) => item.id == action.id);
  if (findIndex !== -1) {
    oldData[findIndex] = action;
  }
  return oldData;
};
