import React, { Component } from "react";
import Avatar from "./profile.svg";
import Logo from "../../../../assets/images/techno_logo_black.svg";
import { FaUser, FaLock, FaEyeSlash, FaEye } from "react-icons/fa";
// import { Link } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import { loginUser, loginUserWithAzure, logoutUser } from "../../../redux/Actions/authAction";
import { AlertError } from "../../common/Alerts";
import HistoryRedirect from "../../../helpers/HistoryRedirect";
class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      errorLogin: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth.isAuthenticated !== this.props.auth.isAuthenticated) {
      if (this.props.auth.isAuthenticated) {
        HistoryRedirect(
          this.props.auth.user.user_role.permissions,
          this.props.history
        );
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      HistoryRedirect(
        this.props.auth.user.user_role.permissions,
        this.props.history
      );
    }
  }

  addClass = (name, value) => {
    let inputName;
    if (name === "username")
      inputName = document.querySelector(".input-div.one");
    if (name === "password")
      inputName = document.querySelector(".input-div.two");

    if (value == "") {
      inputName.classList.remove("focus");
    } else {
      inputName.classList.add("focus");
    }
  };

  handleChange = (e) => {
    this.addClass(e.target.name, e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const variables = {
      user: {
        email: this.state.username,
        password: this.state.password,
      },
    };

    this.props.loginUser(variables, this.props.history);
  };

  onAzureClick = (e) => {
    e.preventDefault();

    this.props.loginUserWithAzure();
  };

  render() {
    return (
      <LoginContainer>
        <div className="lg-container">
          <div className="d-flex justify-content-center align-items-center flex-column lg-img">
            <img src={Logo} />
            <h2
              className="font-semi-bold"
              style={{
                letterSpacing: 16,
              }}
            >
              THUB
            </h2>
          </div>
          <AlertError title="Prisijungimas" alertId={"loginError"} />
          <div className="login-container">
            <form className="lg" onSubmit={this.onSubmit}>
              <img className="lg-avatar" src={Avatar} />
              <h2 className="title">Prisijungimas</h2>
              <div className="input-div one">
                <div className="i">
                  <FaUser />
                </div>
                <div className="div">
                  <h5>Vartotojas</h5>
                  <input
                    type="text"
                    className="input"
                    name="username"
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>
              </div>
              <div className="input-div two">
                <div className="i">
                  <FaLock />
                </div>
                <div className="div position-relative">
                  <h5>Slaptažodis</h5>
                  <input
                    type={this.state.show_password ? "text" : "password"}
                    name="password"
                    className="input"
                    onChange={(e) => this.handleChange(e)}
                  />
                  <div
                    className="position-absolute cursor-pointer"
                    style={{ bottom: 10, right: 10 }}
                    onClick={() =>
                      this.setState({
                        show_password: !this.state.show_password,
                      })
                    }
                  >
                    {this.state.show_password ? (
                      <FaEyeSlash color="#858585" size={17} />
                    ) : (
                      <FaEye color="#858585" size={17} />
                    )}
                  </div>
                </div>
              </div>
              <br />
              {/* <Link to="#">Pamiršai slaptažodį? </Link> */}
              <input
                type="submit"
                className="logButton mt-2"
                value="Prisijungti"
              />

              <button className="logButton mt-2" data-turbo="false" onClick={this.onAzureClick}>
                Prisijungti su Azure
              </button>
            </form>
          </div>
        </div>
      </LoginContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser, loginUserWithAzure, logoutUser })(Login);

const LoginContainer = styled.div`
  .wave {
    position: fixed;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
  }

  .lg-container {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 7rem;
    padding: 0 2rem;
    background-color: #f3f3f3;
  }
  .lg-img {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .lg-img img {
    width: 500px;
  }
  .login-container {
    display: flex;
    align-items: center;
    text-align: center;
  }

  form.lg {
    width: 360px;
  }

  .lg-avatar {
    width: 100px;
  }

  form.lg h2 {
    font-size: 2.9rem;
    text-transform: uppercase;
    margin: 15px 0;
    color: #333;
  }
  .input-div {
    display: grid;
    grid-template-columns: 7% 93%;
    margin: 25px 0;
    padding: 5px 0;
    border-bottom: 2px solid #d9d9d9;

    &:after,
    &:before {
      content: "";
      position: absolute;
      bottom: -2px;
      width: 0;
      height: 2px;
      background-color: #f7931d;
      transition: 0.3s;
    }

    &:after {
      right: 50%;
    }
    &::before {
      left: 50%;
    }
    &.focus {
      & .i svg {
        color: #f7931d;
      }
      & div h5 {
        top: -5px;
        font-size: 15px;
      }
      &:after,
      &:before {
        width: 50%;
      }
    }
  }

  .input-div.one {
    margin-top: 0;
  }

  .input-div.two {
    margin-bottom: 4px;
  }

  .i {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .i svg {
    color: #d9d9d9;
    transition: 0.3s;
  }

  .input-div > div {
    position: relative;
    height: 45px;
  }

  .input-div > div h5 {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    font-size: 18px;
    transition: 0.3s;
  }
  .input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: none;
    outline: none;
    background: none;
    padding: 0.5rem 0.7rem;
    font-size: 1.2rem;
    color: #555;
  }

  a {
    display: block;
    text-align: right;
    text-decoration: none;
    color: #999;
    font-size: 0.9rem;
    transition: 0.3s;
    &:hover {
      color: #f7931d;
    }
  }

  .logButton {
    display: block;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    margin: 1rem 0;
    font-size: 1.2rem;
    outline: none;
    border: none;
    background-image: linear-gradient(to right, #f7931d, #f7941f, #ff9517);
    cursor: pointer;
    color: #fff;
    background-size: 200%;
    transition: 0.5s;
    &:hover {
      background-position: right;
    }
  }

  @media screen and (max-width: 1050px) {
    .lg-container {
      grid-gap: 5rem;
    }
  }

  @media screen and (max-width: 1000px) {
    form.lg {
      width: 290px;
    }

    form.lg h2 {
      font-size: 2.4rem;
      margin: 8px 0;
    }
    .lg-img img {
      width: 400px;
    }
  }

  @media screen and (max-width: 900px) {
    .lg-img {
      display: none !important;
    }
    .lg-container {
      grid-template-columns: 1fr;
    }
    .wave {
      display: none;
    }
    .login-container {
      justify-content: center;
    }
  }
`;
