import { GET_FAMILIES, LOADING_FAMILIES, LOADING_FAMILY, UPDATE_FAMILY, GET_FAMILY, UPDATE_MARKING } from "../Actions/types";

const initialState = {
  families: [],
  totals: 0,
  loading_families: false,
  loading_family: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_FAMILIES:
      return {
        ...state,
        loading_families: true,
      };
    case LOADING_FAMILY:
      return {
        ...state,
        loading_family: true,
      };
    case GET_FAMILIES:
      return {
        ...state,
        families: action.payload.data,
        totals: action.payload.totals,
        loading_families: false,
      };
    case GET_FAMILY:
        return {
          ...state,
          family: action.payload.data,
          loading_family: false,
        };
    case UPDATE_FAMILY:
      let getData = [...state.families];
      const updateElementId = element => element.id === action.payload_id;
      const findIndex = getData.findIndex(updateElementId);
      getData[findIndex].synonim = action.payload.data.synonim;
      getData[findIndex].marked = action.payload.data.marked;
      getData[findIndex].printed = action.payload.data.printed;
      return {
        ...state,
        family: action.payload.data,
        families: getData,
        loading_family: false,
      };
    case UPDATE_MARKING:
        return {
          ...state
        };
    default:
      return state;
  }
}
