export const handleChangeOrdertypeGlobal = (
  value,
  select,
  shipping_address,
  billing_address
) => {
  let objectBasic = {
    carrying: false,
    cod: false,
    sendingDocuments: false,
    deliveryTime: false,
    deliveryOnSaturday: false,
    terminalSelected: undefined,
    swap: false,
    deliveryTimeHours: ["", ""],
    [select]: value,
    who: "",
    shipping_address_edit: shipping_address,
    weight: 0.1,
    pack: 1,
  };

  const shipmentParcelTerminal = shipping_address.parcel_terminal_id === null;
  if (value === "omniva") {
    objectBasic.delivery = shipmentParcelTerminal
      ? "omniva_home"
      : "omniva_postMachine";
  } else if (value === "dpd") {
    objectBasic.delivery = shipmentParcelTerminal
      ? "dpd_b2c"
      : "dpd_postMachine";
  } else if (value === "lp_express") {
    objectBasic.delivery = shipmentParcelTerminal
      ? "lp_express_home"
      : "lp_express_postMachine";
  } else if (value === "at_the_store") {
    objectBasic.delivery = false;
    objectBasic.pack = 1;
    objectBasic.weight = 1;
    objectBasic.shipping_address_edit = billing_address;
    //   objectBasic.shipping_address_edit = this.props.order.billing_address;
    // this.setState({
    //   ...objectBasic,
    // });
  } else if (value === "itella") {
    objectBasic.delivery = false;
    objectBasic.delivery = "itella_postMachine";
  } else if (value === "venipak") {
    objectBasic.delivery = !shipmentParcelTerminal
      ? "venipak_home"
      : "venipak_postMachine";
  } else {
    objectBasic.delivery = false;
  }

  return objectBasic;
};

export const onChangeCheckBoxDelivery = (e) => {
  const parentName = e.target.name;
  if (this.state.delivery !== parentName) {
    let deliveryInfo = {};
    if (
      "lp_express_postMachine" == parentName ||
      "omniva_postMachine" == parentName ||
      "dpd_postMachine" == parentName ||
      "venipak_postMachine" == parentName
    ) {
      deliveryInfo.pack = 1;
    }
    if (
      "lp_express_home" === parentName ||
      "lp_express_postMachine" === parentName
    ) {
      deliveryInfo.lp_express_sizes = undefined;
    }
    this.setState({
      ...deliveryInfo,
      swap: false,
      delivery: e.target.name,
      terminalSelected: undefined,
    });
  } else {
    this.setState({
      delivery: false,
    });
  }
};

export const onChangeCheckBoxDeliveryGlobal = (e, delivery, shipping) => {
  const parentName = e.target.name;
  if (delivery !== parentName) {
    let deliveryInfo = {
      shipping_address_edit: shipping,
      terminalSelected: undefined,
    };
    if (
      "lp_express_postMachine" == parentName ||
      "omniva_postMachine" == parentName ||
      "dpd_postMachine" == parentName ||
      "venipak_postMachine" == parentName
    ) {
      deliveryInfo.pack = 1;
    }
    if (
      "lp_express_home" === parentName ||
      "lp_express_postMachine" === parentName
    ) {
      deliveryInfo.lp_express_sizes = undefined;
    }
    if ("omniva_return" === parentName) {
      deliveryInfo.shipping_address_edit = {
        city: "Klaipėda",
        firstname: "UAB Verslo vartai",
        lastname: "Technorama.lt",
        other: "info@technorama.lt",
        parcel_terminal_id: null,
        phone: "",
        postal_code: "91150",
        street: "Taikos 39",
        comment: "Grąžinimas",
      };
      deliveryInfo.terminalSelected = 259;
    }
    return {
      ...deliveryInfo,
      swap: false,
      delivery: e.target.name,
    };
  } else {
    return {
      delivery: false,
    };
  }
};

export const orderTypeMount = (carrier) => {
  if (!carrier) return "dpd";
  if (carrier.module_name == "omnivaltshipping") {
    return "omniva";
  } else if (carrier.module_name == "technocarrier") {
    return "dpd";
  } else if (carrier.module_name == "lpexpress") {
    return "lp_express";
  } else if (carrier.module_name == "itellashipping") {
    return "itella";
  } else if (carrier.module_name == "mijoravenipak") {
    return "venipak";
  } else if (carrier.module_name == "fspickupatstorecarrier") {
    return "at_the_store";
  } else {
    return "dpd";
  }
};

export const orderDeliveryMount = (data) => {
  if (data == "dpd") return "dpd_b2c";
  else if (data === "omniva") return "omniva_home";
  else if (data == "lp_express") return "lp_express_home";
  else if (data === "itella") return "itella_postMachine";
  else if (data === "venipak") return "venipak_home";
  else return false;
};

export const setterOrderCod = ({ cod, payment_module, id_status }) => {
  if (cod) return true;
  if (
    payment_module &&
    ["codfee", "ps_cashondelivery"].includes(payment_module)
  )
    return true;

  if (id_status && [13].includes(id_status)) return true;
  return false;
};

export const have_product_reference = (product_reference) =>
  !["{}", false, "", null, "1234"].includes(product_reference);

const checkifDeliveryIsPostMachine = (d) => Array.isArray(d) ? d.some(
  (some) => some.service_key == "delivery" && some.service_value == 'dpd_postMachine'
) : false

export const orCorrectDeliveryMethod = (type, pickup_address_id, delivery) => {
  const deltaco = [11, 100];
  if (type != "dpd" && deltaco.includes(pickup_address_id) ||
    type == "dpd" && deltaco.includes(pickup_address_id) && checkifDeliveryIsPostMachine(delivery)) {
    return false;
  }
  return true;
};