import React from "react";
import SupplyingTable from "./Supplyings_table";
import { Switch, Spin } from "antd";

import { AiOutlineCheckCircle } from "react-icons/ai";
import { FaTrash, FaCheck, FaTimes } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import TextInputModal from "../../../TextInputModal";
import ServiceRowChangesTable from "../../../../ServicePage/Items/ServiceForms/service_row_changes_table";
import { url_to_store_by_reference } from "../../../../../helpers/Utils";
import { have_product_reference } from "../../../../../helpers/OrdersFunctionsGlobal";

function OrderRowsComponent({
  item,
  mode,
  orderId,
  updateSerialNumber,
  updateOrderRowGoods,
  deleteOrderRowGoods,
  deleteSupply,
  disabled = false,
}) {
  const [showRemove, setShowRemove] = React.useState(false);
  const [Loading, setLoading] = React.useState(false);
  const [EditBlock, setEditBlock] = React.useState(false);
  const [EditDetails, setEditDetails] = React.useState({
    quantity: item.quantity,
    price: item.unit_price_tax_incl,
  });

  const editHandler = (boolean) => {
    setEditDetails({
      quantity: item.quantity,
      price: item.unit_price_tax_incl,
    });
    setEditBlock(!EditBlock);
  };

  const editOrderRowGoodsFunc = () => {
    setLoading(true);
    const variables = {
      order_row: {
        quantity: EditDetails.quantity,
        product_price: EditDetails.price,
        unit_price_tax_incl: EditDetails.price,
        unit_price_tax_excl: EditDetails.price,
        manual: 1,
      },
    };

    updateOrderRowGoods(variables, orderId, item.id).then((res) => {
      if (res) {
        setLoading(false);
        setEditBlock(false);
      }
    });
  };
  const deleteOrderRowGoodsFunc = (orderId, itemID) => {
    setLoading(true);
    if (confirm("Ar tikrai norite ištrinti?")) {
      deleteOrderRowGoods(orderId, itemID).then((response) => {
        if (response.ok) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setEditDetails({
      ...EditDetails,
      [name]: value,
    });
  };

  if (Loading) {
    return (
      <thead
        onMouseEnter={() => setShowRemove(true)}
        onMouseLeave={() => setShowRemove(false)}
      >
        <tr>
          <th colSpan="4">
            {" "}
            <div className="d-flex align-items-center justify-content-center">
              <Spin size="small" />
            </div>
          </th>
        </tr>
      </thead>
    );
  }
  item.quantity = Number(item.quantity).toFixed(0);
  const serviceType = mode == "Servisas" || mode == "Servisas_Remontas";
  return (
    <React.Fragment>
      <thead
        onMouseEnter={() => setShowRemove(true)}
        onMouseLeave={() => setShowRemove(false)}
      >
        <tr>
          <th style={{ fontSize: "0.8rem" }}>
            {have_product_reference(item.product_reference) ? (
              <a
                className={`text-primary ${
                  item.manual ? "border-bottom border-danger" : ""
                }`}
                href={url_to_store_by_reference(item.product_reference)}
                target="_blank"
              >
                {item.product_reference}
              </a>
            ) : (
              <div className="text-center m-auto">
                <AiOutlineCheckCircle
                  className={`text-${
                    item.product_reference == "1234" ? "warning" : "success"
                  } mr-1`}
                />
              </div>
            )}
          </th>
          <th colSpan={serviceType ? 6 : 4}>
            <span style={{ fontSize: "0.8rem" }}>
              <span>
                &nbsp;*&nbsp;
                <span
                  className={item.manual ? "border-bottom border-danger" : null}
                >
                  {item.product_name}
                </span>{" "}
              </span>
            </span>
          </th>
          {EditBlock ? (
            <React.Fragment>
              <th style={{ fontSize: "0.8rem" }}>
                <TextInputModal
                  type="number"
                  placeholder="Kiekis"
                  name="quantity"
                  onChange={(e) => onChange(e)}
                  value={EditDetails.quantity}
                />
              </th>
              <td colSpan={2}>
                <TextInputModal
                  type="number"
                  placeholder="Kaina"
                  name="price"
                  onChange={(e) => onChange(e)}
                  value={EditDetails.price}
                />
              </td>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {serviceType ? (
                <td>{item.serial_number}</td>
              ) : (
                <td>
                  {have_product_reference(item.product_reference) && (
                    <Switch
                      checkedChildren="S/N"
                      unCheckedChildren="S/N"
                      checked={item.required_serial_number}
                      disabled={
                        disabled
                          ? true
                          : item.required_serial_number && item.serial_number
                      }
                      onChange={(e) => {
                        updateSerialNumber(orderId, item.id, {
                          order_row: {
                            serial_number: item.serial_number,
                            required_serial_number: e,
                          },
                        });
                      }}
                    />
                  )}
                </td>
              )}

              <td style={{ fontSize: "0.8rem" }}>{item.quantity}</td>
              <td
                style={{
                  float: "right",
                  fontSize: "0.8rem",
                }}
              >
                <span className="d-flex">
                  <span>
                    {Number(item.unit_price_tax_incl).toFixed(2) || 0}
                  </span>
                  <span> &#8364;</span>
                </span>
              </td>
            </React.Fragment>
          )}
          <td>
            {EditBlock ? (
              <React.Fragment>
                <div
                  className="d-flex align-items-center"
                  style={{ height: "35px" }}
                >
                  <FaCheck
                    style={{
                      color: "green",
                      cursor: "pointer",
                      marginLeft: "5px",
                    }}
                    onClick={() => editOrderRowGoodsFunc()}
                  />
                  <FaTimes
                    style={{
                      color: "red",
                      cursor: "pointer",
                      marginLeft: "10px",
                    }}
                    onClick={() => editHandler()}
                  />
                </div>
              </React.Fragment>
            ) : disabled ? null : (
              <React.Fragment>
                <div className="d-flex-inline">
                  <MdEdit
                    style={{
                      color: "red",
                      cursor: "pointer",
                      visibility: showRemove ? "visible" : "hidden",
                      marginLeft: "5px",
                    }}
                    onClick={() => editHandler(true)}
                  />
                  {mode !== "Servisas" && (
                    <FaTrash
                      style={{
                        color: "orange",
                        cursor: "pointer",
                        visibility: showRemove ? "visible" : "hidden",
                        marginLeft: "10px",
                      }}
                      onClick={() => deleteOrderRowGoodsFunc(orderId, item.id)}
                    />
                  )}
                </div>
              </React.Fragment>
            )}
          </td>
        </tr>
      </thead>
      <tbody style={{ fontWeight: "400" }}>
        {item.supplyings &&
          item.supplyings.map((itemSup, i) => (
            <SupplyingTable
              disabled={disabled}
              mode={mode}
              key={i}
              item={itemSup}
              deleteSupply={deleteSupply}
              product_reference={item.product_reference}
              id_order_row={item.id}
            />
          ))}
        {serviceType &&
          item.service_row_changes &&
          item.service_row_changes.map((itemSup, i) => (
            <ServiceRowChangesTable key={i} item={itemSup} orderId={orderId} />
          ))}
      </tbody>
    </React.Fragment>
  );
}

export default OrderRowsComponent;
