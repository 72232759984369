import {
  LOADING_USER,
  GET_USERS,
  DELETE_USER,
  UPDATE_USER,
  POST_USER
} from "../Actions/types";

const initialState = {
  users: [],
  totals: 0,
  loading_users: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_USER:
      return {
        ...state,
        loading_users: true
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload.data,
        totals: action.payload.totals,
        loading_users: false
      };
    case POST_USER:
      let updateData = [...state.users];
      updateData.push(action.payload);
      return {
        ...state,
        users: updateData
      };
    case UPDATE_USER:
      let getData = [...state.users];
      const updateElementId = element => element.id === action.payload.id;
      const findIndex = getData.findIndex(updateElementId);
      getData[findIndex] = action.payload;
      return {
        ...state,
        users: getData
      };
    case DELETE_USER:
      return {
        ...state,
        users: [...state.users].filter(
          filterItem => filterItem.id !== action.payload
        ),
        loading_users: false
      };
    default:
      return state;
  }
}
