import React, { useState, useEffect } from "react";
import { Modal, DatePicker, message, Upload } from "antd";
import styled from "styled-components";
import {
  antdMessageError,
  antdMessageInfo,
  errorHandler,
  exportDocument,
  getDateFormat,
  prettyDateFormat,
} from "../../../helpers/Utils";
import Axios from "axios";
import moment from "moment";
import RangePickerComp from "../../common/RangePickerComp";
import SelectInput from "../../common/SelectInput";
import { getSettingDetails } from "../../../redux/Actions/settingAction";
import { AiOutlineDownload } from "react-icons/ai";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { getRivileStoreOptions } from "../../../redux/Actions/rivileStoreAction";
import { ButtonPrimary } from "../../common/Buttons";

const CustomSidebarExport = ({ name }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <li className="nav-item">
        <div
          className={"nav-link sidebar-link subnav-item cursor-pointer"}
          onClick={() => setIsModalVisible(true)}
        >
          {name}
        </div>
      </li>

      {isModalVisible && (
        <>
          {name == "Užsakymai" ? (
            <OrderModal name={name} setIsModalVisible={setIsModalVisible} />
          ) : name == "Tiekėjų likučiai" ? (
            <SupplierBalances
              name={name}
              setIsModalVisible={setIsModalVisible}
            />
          ) : name == "SKU + URL eksportavimas" ? (
            <SkuUrlModal name={name} setIsModalVisible={setIsModalVisible} />
          ) : name == "Retail vs Online (Kaina)" ? (
            <RetailVsOnlineModal
              name={name}
              setIsModalVisible={setIsModalVisible}
            />
          ) : (
            <AdremModal name={name} setIsModalVisible={setIsModalVisible} />
          )}
        </>
      )}
    </>
  );
};

const OrderModal = ({ name, setIsModalVisible }) => {
  const [date, setDate] = useState(["", ""]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [details, setDetails] = useState(false);

  const detailsCallFunction = () => {
    getSettingDetails("order_xlsx").then((res) => {
      if (res.success) {
        setDetails(res.data);
        if (res.data.body.includes("Generuojama")) {
          setConfirmLoading(true);
          setTimeout(() => detailsCallFunction(), 6000);
        } else {
          setConfirmLoading(false);
        }
      }
    });
  };
  useEffect(() => {
    detailsCallFunction();
  }, []);

  const handleOk = async () => {
    try {
      setConfirmLoading(true);
      if (!date || date.some((d) => !d)) {
        message.error("Pasirinkite datą");
        return;
      }

      const { data } = await Axios.post(
        `/api/v1/orders/export_orders_from_to_xlsx?from=${date[0]}&to=${date[1]}`
      );

      antdMessageInfo("Generuoja.");
      if (data.success) {
        return detailsCallFunction();
      }
      return;
    } catch (err) {
      errorHandler(err);
      antdMessageError();
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      title={name}
      open
      confirmLoading={confirmLoading}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Eksportuoti"
      cancelText="Atšaukti"
    >
      <div className="form-group">
        <div className="form-group d-flex flex-column">
          {details && (
            <>
              <h2 className="text-sm text-black mb-0">
                <b>Statusas:</b> {details.body}
              </h2>
              <h2 className="text-sm text-black">
                <b>Paskutinį kartą generuota:</b>{" "}
                {prettyDateFormat(details.updated_at)}
              </h2>
            </>
          )}
          <div>
            <button
              className="btn btn-primary btn-sm mb-3 d-flex align-items-center"
              onClick={() =>
                exportDocument(
                  "/api/v1/orders/download_orders_from_to_xlsx",
                  "download_orders_from_to_xlsx.xlsx",
                  "POST"
                )
              }
            >
              <AiOutlineDownload className="text-xl mr-2" color="#fff" />
              Atsisiųsti
            </button>
          </div>
          <label className="editLabelForm">Data nuo - iki</label>
          <RangePickerComp
            style={{ width: "100%" }}
            placeholder={["Pradžios laikas", "Pabaigos laikas"]}
            value={[getDateFormat(date[0]), getDateFormat(date[1])]}
            onChange={(_, args) => setDate(args)}
          />
        </div>
      </div>
    </Modal>
  );
};

const SupplierBalances = ({ name, setIsModalVisible }) => {
  const [date, setDate] = useState("");
  const [supplierId, setSupplierId] = useState(undefined);
  const [suppliers, setSuppliers] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    getRivileStoreOptions().then((res) => {
      setSuppliers(
        res.map((d) => ({
          label: d.title,
          value: d.rivile_code,
        }))
      );
    });
  }, []);
  const handleOk = async () => {
    try {
      setConfirmLoading(true);
      // if (!date) {
      //   message.error("Pasirinkite datą");
      //   setConfirmLoading(false);
      //   return;
      // }

      if (!supplierId) {
        message.error("Pasirinkite padalinį");
        setConfirmLoading(false);
        return;
      }

      setIsModalVisible(false);
      await exportDocument(
        `/api/v1/products/download_supplier_balances_by_date_sheet?date=${date}&rivile_code=${supplierId}`,
        "supplier_balances.xlsx",
        "POST"
      );
    } catch (err) {
      errorHandler(err);
      setConfirmLoading(false);
      message.error("Įvyko klaida");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      title={name}
      open
      confirmLoading={confirmLoading}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Eksportuoti"
      cancelText="Atšaukti"
    >
      <div className="form-group d-flex flex-column">
        {/* <label className="editLabelForm">Data</label>
        <DatePicker
          placeholder="Pabaigos laikas"
          value={getDateFormat(date)}
          onChange={(_, val) => setDate(val)}
        /> */}
        <SelectInput
          label="Tiekėjas"
          value={supplierId}
          options={suppliers}
          onChange={(e) => setSupplierId(e)}
        />
      </div>
    </Modal>
  );
};

const AdremModal = ({ name, setIsModalVisible }) => {
  const [date, setDate] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    try {
      if (!date) {
        message.error("Pasirinkite datą");
        setConfirmLoading(false);
        return;
      }
      setConfirmLoading(true);

      setIsModalVisible(false);
      const { success } = await exportDocument(
        `/api/v1/products/download_adrem_products_by_date_sheet?date=${date}`,
        "adrem_products.xlsx",
        "POST"
      );
      setConfirmLoading(false);
    } catch (err) {
      errorHandler(err);
      message.error("Įvyko klaida");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [adremDates, setAdremDates] = useState([]);

  useEffect(() => {
    const getAdremDates = () => {
      Axios.get(`/api/v1/products/adrem_dates`).then((res) => {
        let adremData;
        if (res.data) {
          adremData = {
            earliest_date: res.data.earliest_date,
            available_dates: res.data.available_dates,
          };
        }
        setAdremDates(adremData);
      });
    };

    getAdremDates();
  }, []);

  function disabledAdremExportDate(current) {
    let earliest;
    let available_arr;
    earliest = adremDates.earliest_date;
    available_arr = adremDates.available_dates;

    return (
      current &&
      (current < moment(earliest, "YYYY-MM-DD") ||
        !available_arr.includes(current.format("YYYY-MM-DD")))
    );
  }

  return (
    <Modal
      title={name}
      open
      confirmLoading={confirmLoading}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Eksportuoti"
      cancelText="Atšaukti"
    >
      <div className="form-group d-flex flex-column">
        <label className="editLabelForm">Data iki</label>
        <DatePicker
          placeholder="Pasirinkti"
          value={getDateFormat(date)}
          disabledDate={disabledAdremExportDate}
          onChange={(_, val) => setDate(val)}
        />
      </div>
    </Modal>
  );
};

const SkuUrlModal = ({ name, setIsModalVisible }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChange = (info) => {
    if (info.file.status === "uploading" && !info.event) {
      setConfirmLoading(true);
      antdMessageInfo(`${info.file.name} failas generuojamas.`);
    }
    if (info.file.status === "done") {
      setConfirmLoading(false);
      message.success(`${info.file.name} failas sėkmingai įkeltas!`);
      if (info.file) {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([info.file.response])
        );

        const link = document.createElement("a");

        link.href = downloadUrl;

        link.setAttribute("download", "sku_url.csv"); //any other extension

        document.body.appendChild(link);

        link.click();

        link.remove();
        // this.props.getRivileBigBoxItemsFromImport(info.file.response);
      }
    } else if (info.file.status === "error") {
      setConfirmLoading(false);
      message.error(`${info.file.name} failo įkėlimas nepavyko!`);
    }
  };

  const props = {
    name: "file",
    action: `/api/v1/orders/sku_to_urls`,
    showUploadList: false,
    onChange: onChange,
  };

  return (
    <Modal
      title={name}
      open
      onCancel={handleCancel}
      cancelText="Uždaryti"
      okButtonProps={{ style: { display: "none" } }}
    >
      <Upload {...props} className="w-100">
        <ButtonStyled className="btn btn-light text-sm">
          {confirmLoading ? (
            <>
              <span className="spinner-border spinner-border-sm"></span>
              <span> Vykdoma...</span>
            </>
          ) : (
            <>
              <MdOutlineAddCircleOutline
                className="mr-2 text-xl"
                color="#BABABA"
              />
              Įkelti dokumentą
            </>
          )}
        </ButtonStyled>
      </Upload>
    </Modal>
  );
};

const RetailVsOnlineModal = ({ name, setIsModalVisible }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [details, setDetails] = useState(false);

  const detailsCallFunction = () => {
    getSettingDetails("retail_vs_online_price_xlsx").then((res) => {
      if (res.success) {
        setDetails(res.data);
        if (res.data.body.includes("Generuojama")) {
          setConfirmLoading(true);
          setTimeout(() => detailsCallFunction(), 6000);
        } else {
          setConfirmLoading(false);
        }
      }
    });
  };
  useEffect(() => {
    detailsCallFunction();
  }, []);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      title={name}
      open
      onCancel={handleCancel}
      cancelText="Uždaryti"
      confirmLoading={confirmLoading}
      onOk={() =>
        exportDocument(
          "/api/v1/products/download_retail_vs_online_price_xlsx",
          "retail_vs_online_price.xlsx",
          "POST"
        )
      }
      okText="Atsisiųsti"
    >
      {details && (
        <>
          <h2 className="text-sm text-black mb-0">
            <b>Statusas:</b> {details.body}
          </h2>
          <h2 className="text-sm text-black">
            <b>Paskutinį kartą generuota:</b>{" "}
            {prettyDateFormat(details.updated_at)}
          </h2>
        </>
      )}
    </Modal>
  );
};

const ButtonStyled = styled.button`
  width: 100%;
  background: #f3f3f3;
  border-radius: 3px;
  padding: 9px 8px;
  color: #848484;
  text-align: left;
  font-weight: 400;
  &:hover,
  &:focus {
    color: #848484;
    background: #dbdbdb;
  }
`;
export default CustomSidebarExport;
