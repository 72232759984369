import React, { useState } from "react";
import { Modal } from "antd";
import {
  GrDocumentExcel,
  GrDocumentCsv,
  GrDocumentPdf,
  GrDocument,
} from "react-icons/gr";
import FileViewer from "react-file-viewer";
import { FaEye } from "react-icons/fa";

function DocumentItem({ documents, deleteOrderDocument }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    asset: { url: "" },
    id: 0,
    order_id: 0,
    title: "",
    created_at: "",
    format: "",
  });

  const showModal = (document) => {
    setModalInfo(document);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (confirm("Ar tikrai norite ištrinti dokumentą ? ")) {
      deleteOrderDocument(modalInfo.order_id, modalInfo.id);
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const formatIconRender = (format, img) => {
    if (format === "xlsx") {
      return <GrDocumentExcel size={30} />;
    } else if (format === "csv") {
      return <GrDocumentCsv size={30} />;
    } else if (format == "pdf") {
      return <GrDocumentPdf size={30} />;
    } else if (["png", "jpeg", "gif"].includes(format)) {
      return <img className="img-fluid" style={{ height: 90 }} src={img} />;
    } else {
      return <GrDocument size={30} />;
    }
  };

  function downloadBase64File(base64Data, filename, format) {
    // const linkSource = `data:${contentType};base64,${base64Data}`;
    const linkSource = base64Data;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = `${filename?.split(" ").join("_") || ""}.${format}`;
    downloadLink.click();
  }

  return (
    <div className="col-12 pt-3">
      <div className="row">
        {documents.map((e) => (
          <div key={e.id} className="col-12 col-lg-6 mb-2">
            <div className="container-hover" onClick={() => showModal(e)}>
              <div className="card rounded p-3 cursor-pointer">
                <div className="d-flex justify-content-center">
                  {formatIconRender(e.format, e.asset.url)}
                </div>
                {!["png", "jpeg", "gif"].includes(e.format) && (
                  <>
                    <div className="card-body p-0 pt-3">
                      <small>
                        Pavadinimas: {e.title}
                        <br />
                        Formatas: {e.format}
                      </small>
                    </div>
                  </>
                )}
              </div>

              <div className="bg-white">
                <FaEye className="grow cursor-pointer" />
              </div>
            </div>
          </div>
        ))}
      </div>
      {isModalVisible && (
        <Modal
          title={modalInfo.title}
          open={isModalVisible}
          onOk={handleOk}
          okButtonProps={
            deleteOrderDocument == false && {
              style: {
                display: "none",
              },
            }
          }
          onCancel={handleCancel}
          width={1000}
          okText="Ištrinti"
          cancelText="Atšaukti"
        >
          <div className="d-flex justify-content-between">
            {["png", "jpeg", "gif"].includes(modalInfo.format) ? (
              <div style={{ flex: 4 }}>
                <img className="img-fluid" src={modalInfo.asset.url} />
              </div>
            ) : (
              <div style={{ flex: 4 }}>
                <FileViewer
                  fileType={modalInfo.format}
                  filePath={modalInfo.asset.url}
                  errorComponent={<div />}
                  onError={(e) => console.log("Error: : ", e)}
                />
              </div>
            )}
            <div className="pl-2" style={{ flex: 1 }}>
              <ul>
                <li>
                  <span className="font-weight-bold">Pavadinimas: </span>
                  {modalInfo.title}
                </li>
                <hr />
                <li>
                  <span className="font-weight-bold">Formatas: </span>
                  {modalInfo.format}
                </li>
                <hr />
                <li>
                  <span className="font-weight-bold">Įkeltas dokumentas: </span>
                  {modalInfo.created_at.substr(0, 10)}
                </li>
                <br />
                <button
                  className="btn btn-primary btn-block"
                  onClick={() =>
                    downloadBase64File(
                      modalInfo.asset.url,
                      modalInfo.title,
                      modalInfo.format
                    )
                  }
                >
                  Parsisiųsti
                </button>
              </ul>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default DocumentItem;
