import React, { useState, useEffect, useMemo } from "react";
import AdditionalServices from "./additionalServices";
import { FaTrash } from "react-icons/fa";
import { FiCheck } from "react-icons/fi";
import { Button1, MiniBlock } from "../../../../../common/Buttons";
import moment from "moment";
import {
  copyToClipboard,
  prettyDateFormat,
} from "../../../../../../helpers/Utils";
import { Tooltip } from "antd";
import {
  IconCopy,
  IconExclamation,
  IconMessage,
} from "../../../../../Layout/Icons";
import { getDocumentTypeOption } from "../../../../../../redux/Actions/documentTypeAction";
import { have_product_reference } from "../../../../../../helpers/OrdersFunctionsGlobal";

const warrantlyOption = [
  {
    label: "1 metai",
    value: 1,
  },
  {
    label: "2 metai",
    value: 2,
  },
  {
    label: "3 metai",
    value: 3,
  },
  {
    label: "4 metai",
    value: 4,
  },
  {
    label: "5 metai",
    value: 5,
  },
  {
    label: "Garantijos nėra",
    value: 0,
  },
];

const meaningsOptions = [
  {
    value: 0,
    label: "-- Pasirinkite --",
  },
  {
    value: 1,
    label: "IMEI",
  },
  {
    value: 2,
    label: "ID numeris",
  },
  {
    value: 3,
    label: "Kita...",
  },
];

function AdditionalServicesMain({
  orderId,
  orderId2,
  order_rows,
  contracts,
  addContract,
  updateContract,
  deleteContractItem,
  deleteContract,
  billing_address,
  errors,
  firstAddContract,
  firstDeleteContract,
  changeContractItems,
}) {
  const [OrderRow, setOrderRow] = useState([]);
  const [DocumentType, setDocumentType] = useState([]);
  const [DocumentPlan, setDocumentPlan] = useState([]);
  const [Open, setOpen] = useState([]);

  const fullName = (firstName, lastName) => {
    if (firstName == null) {
      return orderId2;
    }
    return firstName + " " + lastName;
  };

  const addMaster = () => {
    const randomId = Math.floor(Math.random() * 100);
    const variables = {
      id: randomId,
      customer: fullName(billing_address.firstname, billing_address.lastname),
      phone: billing_address.phone,
      email: billing_address.other,
      buy_date: moment(),
      contract_items: [],
      contract_emails: [],
      action_status: "new",
    };

    firstAddContract(variables);
    setOpen([randomId]);
  };
  useEffect(() => {
    let orderRow = [];
    order_rows.forEach((item) => {
      if (have_product_reference(item.product_reference)) {
        orderRow.push({
          value: item.id,
          label: item.product_name,
          product_price: item.unit_price_tax_incl,
          product_reference: item.product_reference,
          serial_number: item.required_serial_number ? item.serial_number : "",
          manufacturer: item.product_info?.manufacturer || "",
          category: item.product_info?.family?.name || "",
        });
      }
    });
    setOrderRow(orderRow);
  }, [order_rows]);

  useEffect(() => {
    getDocumentTypeOption().then((res) => {
      setDocumentType(res.documentType);
      setDocumentPlan(res.documentPlan);
    });
  }, []);

  const openMaster = (id) => {
    const currentIndex = Open.indexOf(id);
    const newChecked = [...Open];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setOpen(newChecked);
  };

  const onDelete = (item) => {
    if (item.action_status == "new") {
      firstDeleteContract(item.id);
    } else {
      deleteContract(orderId, item.id);
    }
  };

  const status_check = (status) => {
    let val = {
      color: "#BD00FF",
      text: "Galiojantis",
    };
    if (status == "cancel") {
      val = {
        color: "#EF3824",
        text: "Atšauktas",
      };
    }
    if (status == "refund") {
      val = {
        color: "#00A341",
        text: "Grąžintas",
      };
    }
    return val;
  };

  return (
    <div>
      <div className="py-4">
        <div style={{ position: "relative" }}>
          <Button1
            style={{
              position: "absolute",
              top: "-16px",
              left: "50%",
              transform: "translate(-50%, 0px)",
            }}
            text={"Kurti naują dokumentą"}
            option={"plus"}
            onClick={() => addMaster()}
          />
          <hr />
        </div>
      </div>

      {contracts.map((item, index) => {
        const itsOpen = Open.includes(item.id);
        const status = status_check(item.status);
        return (
          <React.Fragment key={index}>
            <MiniBlock
              color={"#e899ff"}
              action={itsOpen}
              onChange={() => openMaster(item.id)}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div
                  className="d-flex align-items-center"
                  style={{ width: "30%" }}
                >
                  {item?.number && (
                    <Tooltip
                      placement="top"
                      title={
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            copyToClipboard(item.number);
                          }}
                        >
                          {item.number}
                          <IconCopy className="text-sm cursor-pointer ml-2" />
                        </div>
                      }
                    >
                      <div
                        className="mr-1"
                        style={{
                          marginBottom: "0.125rem",
                        }}
                      >
                        <IconExclamation />
                      </div>
                    </Tooltip>
                  )}
                  <div>{item.customer}</div>
                </div>
                <div style={{ width: "20%" }}>
                  {item.updated_at && (
                    <div
                      className="d-flex align-items-center"
                      style={{
                        color: status.color,
                      }}
                    >
                      {status.text}
                      {item.canceled_at && (
                        <Tooltip
                          placement="top"
                          title={
                            <div className="text-center">
                              <div>{item.reason}</div>
                              <div
                                className="cursor-pointer"
                                onClick={() => {
                                  copyToClipboard(item.number);
                                }}
                              >
                                {item.number}
                                <IconCopy className="text-sm cursor-pointer ml-2" />
                              </div>
                            </div>
                          }
                        >
                          <div className="ml-2">
                            <IconMessage
                              className="text-base"
                              fill={status.color}
                            />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  )}
                </div>
                <div style={{ width: "20%" }}>{item.user_title}</div>
                <div style={{ width: "20%" }}>
                  {prettyDateFormat(item.created_at)}
                </div>
                <div style={{ width: "10%" }}>
                  {item?.editable ? (
                    <Tooltip
                      placement="top"
                      title="Kontrakto laiškas išsiųstas"
                    >
                      <FiCheck className="text-success ml-2" />
                    </Tooltip>
                  ) : (
                    item.contract_items.length == 0 &&
                    item.contract_emails.length == 0 && (
                      <FaTrash
                        className="ml-2"
                        title="Ištrinti"
                        style={{ cursor: "pointer", color: "#e1e1e1" }}
                        onClick={() => onDelete(item)}
                      />
                    )
                  )}
                </div>
              </div>
            </MiniBlock>

            {itsOpen && (
              <React.Fragment key={index}>
                <AdditionalServices
                  DocumentType={DocumentType}
                  DocumentPlan={DocumentPlan}
                  addContract={addContract}
                  updateContract={updateContract}
                  deleteContractItem={deleteContractItem}
                  orderId={orderId}
                  new={!item.created_at}
                  OrderRow={OrderRow}
                  item={item}
                  index={index}
                  errors={errors}
                  email={billing_address.other}
                  orderId2={orderId2}
                  warrantlyOption={warrantlyOption}
                  meaningsOptions={meaningsOptions}
                  setOpen={setOpen}
                  Open={Open}
                  changeContractItems={changeContractItems}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default AdditionalServicesMain;
