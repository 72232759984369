import React from "react";

function TableFilterBoxRivile(props) {
  return (
    <>
      <div className="row mx-1">{props.children}</div>
      {props.button1 &&
        props.button1
        // <button
        //   type="button"
        //   className="btn btn-sm btn-dark float-right mr-3"
        //   onClick={props.button1}
        // >
        //   {props.button1_text}
        // </button>
      }
      {props.filter && (
        <button
          className="btn btn-sm float-right mr-3 btn-danger"
          onClick={props.onFilter}
        >
          {props.filterText || "Filtruoti"}
        </button>
      )}
      {props.add && (
        <button
          type="button"
          className="btn btn-sm btn-primary float-right mr-3"
          data-toggle="modal"
          data-target={props.modalId || "#tableEdit"}
          onClick={() => props.add("new")}
        >
          {props.addText || "Sukurti naują"}
        </button>
      )}
      {props.clearFilter && (
        <button
          className="btn btn-sm float-right mr-3 btn-info"
          onClick={props.clearFilter}
        >
          Išvalyti filtravimą
        </button>
      )}
      {props.getInvoicesShow && (
        <button
        className="btn btn-sm float-right mr-3"
        style={{ backgroundColor: '#f7941d', color: "white" }}
          onClick={props.getInvoices}
        >
         Gauti sąskaitas
        </button>
      )}
      <hr />
    </>
  );
}

export default TableFilterBoxRivile;
