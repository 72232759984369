import React from "react";

export const Dot = props => {
  return (
    <>
      <div className={"dot-element " + props.className + " " + (props.value ? "active" : "") }>
      </div>
    </>
  );
};

