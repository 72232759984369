import React from 'react'
import Axios from "axios";
import { antdAsyncMessageError, antdAsyncMessageLoading, antdAsyncMessageSuccess } from "../../helpers/AsyncMessage";
import {
  antdMessageError,
  antdMessageSuccess,
  buildErrorObject,
  errorHandler,
} from "../../helpers/Utils";
import AntdConfirm from "../../helpers/AntdConfirm";


export const getCommerceList =
  (page = 1, pageCount = 50, filter = "") =>
    async (dispatch) => {
      try {
        dispatch({
          type: "LOADING_CUSTOM",
        });
        const { data } = await Axios.get(
          `/api/v1/commerce/lists?page=${page}&per_page=${pageCount}${filter}`
        );
        dispatch({
          type: "GET_CUSTOMS",
          payload: { ...data, current: "CommerceOrders" },
        });
        return { success: true };
      } catch (err) {
        dispatch({
          type: "STOP_LOADING_CUSTOM",
        });
        return { success: false };
      }
    };


export const getCommerceShowList =
  (show_id) =>
    async (dispatch) => {
      try {
        const { data } = await Axios.get(
          `/api/v1/commerce/lists/${show_id}`
        );
        dispatch({
          type: "GET_CUSTOM",
          payload: data,
        });
        return { success: true };
      } catch (err) {
        return { success: false };
      }
    };

export const getCommerceShowProductsList =
  (show_id, page = 1, pageCount = 50, filter = "") =>
    async (dispatch) => {
      try {
        dispatch({
          type: "LOADING_CUSTOM",
        });
        const { data } = await Axios.get(
          `/api/v1/commerce/lists/${show_id}/products?page=${page}&per_page=${pageCount}${filter}`
        );
        dispatch({
          type: "GET_CUSTOMS",
          payload: { data: data.data, totals: data.totals, current: "CommerceOrderShow" },
        });
        return { success: true };
      } catch (err) {
        dispatch({
          type: "STOP_LOADING_CUSTOM",
        });
        return { success: false };
      }
    };

export const updateCommerceList = (params) => async (dispatch) => {
  try {
    const api_url = params.id
      ? `/api/v1/commerce/lists/${params.id}`
      : "/api/v1/commerce/lists";
    delete params.category;
    delete params.products;
    const { data } = await Axios({
      url: api_url,
      data: {
        list: params,
      },
      method: params.id ? "PUT" : "POST",
    });

    dispatch({
      type: params.id ? "UPDATE_CUSTOM" : "POST_CUSTOM",
      payload: data,
    });
    antdMessageSuccess();
    return { success: true };
  } catch (err) {
    antdMessageError();
    errorHandler(err);
    return { success: false, errors: buildErrorObject(err.response?.data) };
  }
};

export const deleteCommerceList = (id) => async (dispatch) => {
  try {
    const { data } = await Axios.delete(`/api/v1/commerce/lists/${id}`);
    if (!data.status) throw new Error("err");
    antdMessageSuccess();
    dispatch({
      type: "REMOVE_CUSTOM",
      payload: id,
    });
    dispatch(getCommerceList())
    return { success: true };
  } catch (err) {
    antdMessageError();
    errorHandler(err);
    return { success: false };
  }
};



export const createInnerCommerceList = async (list_id, checked, protector = true) => {
  const key = "createInnerCommerceList"
  try {
    antdAsyncMessageLoading({ key, text: "Formuojamas vidinis" })
    const { data } = await Axios.post(`/api/v1/commerce/lists/${list_id}/create_inner`, {
      checked,
      protector
    });

    if (!data.success) {
      console.log('test')
      if (data.type == 'protector') {
        antdAsyncMessageSuccess({ key, text: "Vidiniai šiandienai jau yra sukurti." })
        return AntdConfirm({
          title: "Ar norite tęsti?",
          content: <span dangerouslySetInnerHTML={{ __html: data.message || "Įvyko klaida" }} />,
          onOk: () => {
            createInnerCommerceList(list_id, checked, false)
          },
        })
      }

      throw new Error(data.message)
    }
    antdAsyncMessageSuccess({ key, text: "Sėkmingai suformuotas vidinis" })
    return { success: true };
  } catch (err) {
    antdAsyncMessageError({ key, text: err?.message || "Formuojant vidini įvyko klaida" })
    errorHandler(err);
    return { success: false };
  }
};

export const importXlsCommerceList = async (list_id, file) => {
  const key = "importXlsCommerceList"
  try {
    antdAsyncMessageLoading({ key, text: "Importuojamas dokumentas" })

    var bodyFormData = new FormData();
    bodyFormData.append('file', file);

    await Axios({
      url: `/api/v1/commerce/lists/${list_id}/import_xls`,
      data: bodyFormData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    });
    antdAsyncMessageSuccess({ key, text: "Sėkmingai importuotas" })
    return { success: true };
  } catch (err) {
    antdAsyncMessageError({ key, text: "Importuojant įvyko klaida" })
    errorHandler(err);
    return { success: false };
  }
};


export const destroyProductsCommerceList = async (list_id, commerce_product_ids) => {
  try {
    await Axios.post(`/api/v1/commerce/lists/${list_id}/destroy_products`, {
      commerce_product_ids
    });
    antdMessageSuccess();
    return { success: true };
  } catch (err) {
    antdMessageError();
    errorHandler(err);
    return { success: false };
  }
};
