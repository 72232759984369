import React from "react";
import { Input } from "antd";
import { Label } from "../global";

const TextInputModal = (props) => {
  const renderErrors = () => {
    return props.error.map((err, index) => {
      if (props.error.length - 1 == index) {
        return <span key={index}>{err}</span>;
      }
      return (
        <span key={index} className="mr-1">
          {err},
        </span>
      );
    });
  };
  return (
    <>
      {props.text && (
        <Label
          error={props.error || props.warning}
          className={props.labelClass}
          disabled={props.disabled}
          required={props.required}
        >
          {props.text}
        </Label>
      )}
      <Input
        autoComplete="dummy-no-auto-complete-fix"
        type={props.type || "text"}
        min={props.min ?? null}
        className={`orange ${
          props.warning || props.error
            ? "error_border without_arrows"
            : "without_arrows"
        }`}
        //showCount={props.maxLength}
        maxLength={props.maxLength}
        style={props.style}
        name={props.name || ""}
        placeholder={props.placeholder || "Įveskite"}
        value={props.value}
        onFocus={props.onFocus}
        suffix={props.suffix}
        onChange={props.onChange}
        disabled={props.disabled}
        onKeyDown={props.onKeyDown}
      />
      {props.warning && <div className="text-danger">{props.warning}</div>}
      {props.error && <div className="text-danger">{renderErrors()}</div>}
    </>
  );
};

TextInputModal.defaultProps = {
  placeholder: "Įveskite",
};

export default TextInputModal;
