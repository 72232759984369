import React, {
  useState,
  useEffect,
  createRef,
  Fragment,
  useMemo,
} from "react";
import { SelectForm, Button2, CheckBoxInput } from "../../../common/Buttons";
import TextInputModal from "../../../common/TextInputModal";
import {
  getLandingTypes,
  getLandingShow,
} from "../../../../redux/Actions/landingAction";
import { Switch, Spin, Select, Tooltip } from "antd";
import { checkIsValid, log } from "../../../../helpers/Utils";
import { AiOutlinePlus, AiOutlineCheck } from "react-icons/ai";
import ActiveLanding from "./../ActiveLanding";
// import Spinner from "../../../common/Spinner";
import { FaTimes, FaTrash } from "react-icons/fa";
import { AiFillFileExcel } from "react-icons/ai";
import Axios from "axios";
import Spinner from "../../../common/Spinner";
import ModalSpecial from "../../../Layout/Modals/ModalSpecial";
import TextAreaFieldGroup from "../../../common/TextAreaFieldGroup";
import { DebounceSelect } from "../../../common";
import useFetch from "../../../../helpers/useFetch";

const test_options = [
  { value: "manufacturer", label: "Gamintojas" },
  { value: "category", label: "Kategorija" },
  { value: "property_filters", label: "Savybių filtrai" },
  { value: "product", label: "Prekė" },
];

const NewLanding = ({ saveLanding, id, onClose, oldData }) => {
  const [loading, setLoading] = useState(true);
  const [landingTypes, setLandingTypes] = useState([]);

  const [state, setState] = useState({
    title: "",
    address: "",
    landing_type_id: undefined,
    assign: undefined,
    active: false,
    from_active: false,
    from_at: "",
    to_active: false,
    to_at: "",
  });
  const [categories, setCategories] = useState([]);
  const [categoriesOld, setCategoriesOld] = useState([]);
  const [Images, setImages] = useState([]);
  const [assigns, setAssigns] = useState([]);
  const [assignsOld, setAssignsOld] = useState([]);

  const fileInput = createRef(null);
  const fileInputDocument = createRef(null);

  const type = id == 0 ? "create" : "update";
  useEffect(() => {
    getLandingTypes().then((res) => {
      if (res.success) {
        setLandingTypes(
          res.data.map((e) => ({
            label: e.title,
            value: e.id,
            illustrations: e.illustrations,
          }))
        );
      }
    });
  }, []);

  useEffect(() => {
    if (id) {
      getLandingShow(id).then((res) => {
        if (res.success) {
          const {
            title,
            description,
            slug,
            address,
            landing_type_id,
            active,
            from_active,
            from_at,
            to_active,
            to_at,
            landing_documents,
            landing_assigns,
            landing_categories,
          } = res.data;
          const assign = [...new Set(landing_assigns.map((e) => e.identifier))];
          setState({
            title,
            description,
            slug,
            address,
            landing_type_id,
            assign,
            from_active,
            from_at: from_at || "",
            to_active,
            to_at: to_at || "",
            active,
          });
          setImages(
            landing_documents.filter((e) =>
              e.image_file ? e.image_base64 !== "" : true
            )
          );
          setAssigns(
            landing_assigns
              .filter((e) => assign.includes(e.identifier))
              .map((e) => ({ value: e.value, identifier: e.identifier }))
          );
          setAssignsOld(landing_assigns);
          setCategories(landing_categories.map((e) => e.category_id));
          setCategoriesOld(landing_categories);
        }
        setLoading(false);
      });
    } else {
      if (Object.entries(oldData).length == 0) {
        setLoading(false);
      } else {
        setState(oldData.state);
        setCategories(oldData.categories);
        setImages(oldData.Images);
        setAssigns(oldData.assigns);
        setLoading(false);
      }
    }
  }, [id]);

  const [errors, setErrors] = useState({});
  const onChangeState = (e) => {
    const { name, value } = e.target;
    setState((prevProps) => ({ ...prevProps, [name]: value }));
  };

  const check_illustrations = useMemo(() => {
    return !!landingTypes.find((d) => d.value == state.landing_type_id)
      ?.illustrations;
  }, [state.landing_type_id, landingTypes.length]);

  const assigns_builder = () => {
    let assign_array = assignsOld.map((e) => {
      let old = { ...e };
      if (!state.assign.includes(old.identifier)) {
        old._destroy = true;
        return old;
      } else if (
        !assigns.find(
          (e) => e.value == old.value && e.identifier == old.identifier
        )
      ) {
        old._destroy = true;
        return old;
      }
      return old;
    });
    assigns.forEach((e) => {
      if (
        !assign_array.find(
          (fi) => fi.value == e.value && fi.identifier == e.identifier
        )
      ) {
        assign_array.push({
          value: e.value,
          identifier: e.identifier,
        });
      }
    });
    return assign_array;
  };

  const categories_builder = () => {
    let cat_array = categoriesOld.map((e) => {
      let old = {
        ...e,
      };
      delete old.category;
      if (!categories.includes(old.category_id)) {
        old._destroy = true;
        return old;
      }
      return old;
    });
    categories.forEach((e) => {
      if (!cat_array.find((fi) => fi.category_id == e)) {
        cat_array.push({
          category_id: e,
        });
      }
    });
    return cat_array;
  };

  const onSave = async () => {
    let errors = {};
    if (checkIsValid(state.title)) errors.title = "Įveskite pavadinimą";
    if (checkIsValid(state.description))
      errors.description = "Įveskite aprašymą";
    // if (checkIsValid(state.address)) errors.address = "Įveskite adresą";
    if (checkIsValid(state.slug)) errors.slug = "Įveskite slug";
    if (!state.landing_type_id) errors.landing_type_id = "Pasirinkti";
    if (!state.assign || state?.assign.length == 0)
      errors.assign = "Pasirinkti";
    if (Object.entries(errors).length > 0) {
      return setErrors(errors);
    }

    let landing_documents_attributes = [];
    if (check_illustrations) {
      // for destroy exist
      landing_documents_attributes = Images.filter((e) => e._destroy).map(
        (e) => {
          let obj = { ...e };
          delete obj.image_base64;
          return obj;
        }
      );
    } else {
      landing_documents_attributes = Images.filter((d) => d.id).map((d) => {
        let obj = { ...d };
        delete obj.image_base64;
        if (d.image_file) {
          d._destroy = true;
        }
        return obj;
      });
    }

    // for new uploads
    landing_documents_attributes = [
      ...landing_documents_attributes,
      ...Images.filter((d) => !d.id).map((e) => {
        let obj = { ...e };
        delete obj.image_base64;
        return obj;
      }),
    ];

    setErrors({});
    let obj_state = { ...state };
    delete obj_state.assign;
    setLoading(true);
    await saveLanding(id, {
      ...obj_state,
      landing_documents_attributes,
      landing_assigns_attributes: assigns_builder(),
      landing_categories_attributes: categories_builder(),
    });
    setLoading(false);
  };

  const updateActive = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  // Image upload

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const uploadImage = (e) => {
    const file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        setImages([
          ...Images,
          {
            asset: result,
            image_file: true,
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeAssigns = (value, identifier) => {
    setAssigns([
      ...assigns.filter((e) => e.identifier !== identifier),
      ...value.map((e) => ({ value: e, identifier })),
    ]);
  };

  console.log({ assignsOld });
  if (loading) {
    return (
      <ModalSpecial
        style={{ maxWidth: 600 }}
        text={
          type == "create" ? "Pridėti naują landingą" : "Redaguoti landingą"
        }
        backdrop={() => log("Close denied")}
        onClose={() => {
          return onClose({});
        }}
      >
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100%" }}
        >
          <Spinner />
        </div>
      </ModalSpecial>
    );
  }
  console.log({ check_illustrations });

  return (
    <ModalSpecial
      style={{ maxWidth: 600 }}
      text={type == "create" ? "Pridėti naują landingą" : "Redaguoti landingą"}
      backdrop={() => log("Close denied")}
      onClose={() => {
        if (type == "create") {
          return onClose({
            state,
            categories,
            Images,
            assigns,
          });
        }
        return onClose({});
      }}
    >
      <div>
        <div>
          <div className="text-bold my-1">Pridėti failą</div>
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              id="customFile"
              ref={fileInputDocument}
              onChange={(e) => {
                const file = e.target.files[0];
                getBase64(file)
                  .then((result) => {
                    file["base64"] = result;
                    setImages([
                      ...Images,
                      {
                        asset: result,
                        image_file: false,
                      },
                    ]);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
            {Images.some((e) => e.image_file == false) && (
              <small className="text-success">
                <AiOutlineCheck className="mr-2" />
                Failas yra prisegtas
              </small>
            )}
            {id && <UrlForPreview id={id} />}
            <label
              className="custom-file-label"
              htmlFor="customFile"
              style={{ borderRadius: 2, color: "#C2C2C2" }}
            >
              Pasirinkite failą...
            </label>
          </div>
        </div>
        <div className="py-1" />
        <TextInputModal
          text="Pavadinimas"
          name="title"
          value={state.title}
          onChange={onChangeState}
          warning={errors.title}
        />
        <div className="py-1" />
        <TextAreaFieldGroup
          placeholder="Aprašymas"
          name="description"
          value={state.description}
          onChange={onChangeState}
          error={errors.description}
        />
        {/* <div className="py-1" />
        <TextInputModal
          text="URL adresas"
          name="address"
          value={state.address}
          onChange={onChangeState}
          warning={errors.address}
        /> */}
        <div className="py-1" />
        <TextInputModal
          text="SLUG"
          name="slug"
          value={state.slug}
          onChange={onChangeState}
          warning={errors.slug}
        />
        <div className="py-1" />
        <SelectForm
          placeholder="Landingo tipas"
          onlyPlaceholder="Pasirinkti"
          options={landingTypes}
          value={state.landing_type_id}
          onChange={(value) =>
            onChangeState({ target: { value, name: "landing_type_id" } })
          }
          error={errors.landing_type_id}
        />
        <div className="py-1" />
        {/* <DebounceSelect
          label="Portalo kategorija"
          mode="multiple"
          value={categories}
          placeholder="Pasirinkti"
          options={categoriesOld.map((d) => ({
            value: d.category_id,
            label: d?.category?.name,
          }))}
          fetchOptions={fetchOptions}
          onChange={(newValue) => {
            // console.log({ newValue, test, test2 });
            setCategories(newValue.map((d) => d.value));
          }}
        />
        <div className="py-1" /> */}
        {/* imgblokc */}
        {check_illustrations && (
          <div>
            <label className={"editLabelForm text-bold my-1 "}>
              {"Iliustracijos"}
            </label>
            <div className="col-12">
              <div className="row">
                {Images.map((e, idx) =>
                  !e.image_file ? null : (
                    <div
                      key={idx}
                      className="col-4 position-relative pb-2 pl-0 pr-1"
                      style={{
                        height: 117,
                        // width: 185,
                        borderRadius: 3,
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          backgroundImage: `url(${e.image_base64 || e.asset})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                        }}
                      />
                      {/* <img
                    className="img-fluid"
                    style={{
                      // width: "100%",
                      // height: "100%",
                      width: "100%",
                      height: "100%",
                      borderRadius: 3,
                      opacity: e._destroy ? "0.2" : 1,
                    }}
                    src={`${e.image_base64 || e.asset}`}
                  /> */}
                      <div
                        className="position-absolute cursor-pointer"
                        style={{ right: 15, top: 15 }}
                      >
                        {e._destroy ? (
                          <div
                            className="bg-warning text-white  px-2 py-1"
                            style={{ borderRadius: 3 }}
                            onClick={() => {
                              let old = [...Images];
                              old[idx]._destroy = false;
                              setImages(old);
                            }}
                          >
                            <FaTimes />
                          </div>
                        ) : (
                          <div
                            className="bg-danger text-white px-2 py-1"
                            style={{ borderRadius: 3 }}
                            onClick={() => {
                              let old = [...Images];
                              if (old[idx].id) {
                                old[idx]._destroy = true;
                              } else {
                                old.splice(idx, 1);
                              }
                              setImages(old);
                            }}
                          >
                            <FaTrash />
                          </div>
                        )}
                      </div>
                    </div>
                  )
                )}
                <div
                  className="col-4 position-relative pb-2 pl-0 pr-1"
                  style={{
                    width: "100%",
                    height: 117,
                    borderRadius: 3,
                  }}
                  onClick={() => fileInput.current.click()}
                >
                  <div
                    className="d-flex flex-column align-items-center justify-content-center cursor-pointer h-100"
                    style={{
                      backgroundColor: "#F3F3F3",
                    }}
                  >
                    <span
                      className="d-flex justify-content-center align-items-center rounded-circle"
                      style={{
                        backgroundColor: "#BABABA",
                        width: 28,
                        height: 28,
                      }}
                    >
                      <AiOutlinePlus size={20} color="#fff" />
                    </span>
                    <span className="mt-1" style={{ fontSize: 13 }}>
                      Pridėti iliustraciją
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="py-1" />
        <div
          className="p-3"
          style={{
            border: "1px solid #EBEBEB",
            borderRadius: 4,
          }}
        >
          <SelectForm
            mode="multiple"
            placeholder="Priskirti landingą"
            onlyPlaceholder="Pasirinkti"
            options={test_options}
            value={state.assign}
            onChange={(value) => {
              const massArray = [...assignsOld, ...assigns].filter((e) =>
                value.includes(e.identifier)
              );
              const rebuild_array = [];
              massArray.forEach((e) => {
                if (
                  !rebuild_array.find(
                    (fi) => fi.identifier == e.identifier && fi.value == e.value
                  )
                ) {
                  rebuild_array.push({
                    value: e.value,
                    identifier: e.identifier,
                  });
                }
              });
              setAssigns(rebuild_array);
              onChangeState({ target: { value, name: "assign" } });
            }}
            error={errors.assign}
          />
          {state.assign?.length > 0 && <hr />}
          <RenderAssignLanding
            assign={state.assign}
            assigns={assigns}
            onChange={onChangeAssigns}
          />
          <div className="py-1" />
          <div className="d-flex justify-content-end mt-1">
            <button
              className="btn d-flex justify-content-center align-items-center px-3 py-2 text-orange"
              style={{
                borderRadius: 4,
                border: "1px solid #FE9C00",
              }}
              onClick={() => fileInputDocument.current.click()}
            >
              <AiFillFileExcel className="text-orange mr-2" />
              Įkelti excel dokumentą
            </button>
          </div>
        </div>
        <div className="py-1" />
        <div className="d-flex align-items-center mb-2 mt-2">
          <Switch
            checkedChildren="I"
            unCheckedChildren="I"
            checked={state.active}
            onChange={(value) =>
              onChangeState({ target: { name: "active", value } })
            }
          />
          <span className="ml-2">Aktyvus</span>
        </div>
        <div className="py-1" />
        {!!state.active && (
          <ActiveLanding
            updateActive={updateActive}
            active={state.active}
            state={{
              from_active: state.from_active,
              from_at: state.from_at,
              to_active: state.to_active,
              to_at: state.to_at,
            }}
          />
        )}
        <div className="mt-2 d-flex justify-content-end">
          <Button2 onClick={() => onSave()} text={id ? "Saugoti" : "Pridėti"} />
        </div>
        <input
          type="file"
          accept="image/*"
          hidden={true}
          ref={fileInput}
          onChange={uploadImage}
        />
      </div>
    </ModalSpecial>
  );
};

const RenderAssignLanding = ({ assign, assigns, onChange }) => {
  const [Category, setCategory] = useState([]);
  const [Manufacturer, setManufacturer] = useState([]);
  const [PropertyFilters, setPropertyFilters] = useState([]);
  const [Product, setProduct] = useState([]);
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (!assign || assign?.length == 0) return;
    if (assign.includes("category")) {
      const getCategories = () => {
        Axios.get(
          `/api/v1/shop_categories/list?q[id_in][]=${assigns
            .filter((d) => d.identifier == "category")
            .map((e) => `&q[id_in][]=${e.value}`)
            .join("")}&per_page=10000`
        ).then((res) => {
          if (res.data) {
            const category = res.data.map((item_each) => ({
              value: item_each.id,
              label: item_each.title,
            }));
            setCategory(category);
            setLoading(false);
          }
        });
      };
      getCategories();
    }
    if (assign.includes("manufacturer")) {
      const getBrands = () => {
        Axios.get(`/api/v1/brands/all_brands`).then((res) => {
          setManufacturer(
            res.data.data.map((e) => ({
              value: e.id,
              label: e.title,
            }))
          );
          setLoading(false);
        });
      };
      getBrands();
    }
    if (assign.includes("property_filters")) {
      Axios.get("/api/v1/property_filters").then((res) => {
        if (!res.data.success) {
          setPropertyFilters([]);
          setLoading(false);
          return;
        } else {
          setPropertyFilters(
            res.data.data.map((e) => ({
              value: e.id,
              label: e.title,
            }))
          );
        }
        setLoading(false);
      });
    }
    if (assign.includes("product")) {
      if (assigns.filter((e) => e.identifier == "product").length == 0) {
        setLoading(false);
        setProduct([]);
        return;
      }
      Axios.get(
        `/api/v1/products?per_page=100${assigns
          .filter((e) => e.identifier == "product")
          .map((e) => `&q[id_in][]=${e.value}`)
          .join("")}`
      ).then((response) => {
        if (response.data) {
          const product = response.data.data.map((e) => ({
            label: e.title,
            value: e.id,
          }));
          setProduct(product);
          setLoading(false);
        }
      });
    }
  }, [assign]);
  // Render

  if (!assign || assign?.length == 0) {
    return <div />;
  }
  // pakeist poto cia
  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center my-3">
        <Spin />
      </div>
    );
  }

  const renderManufacturer = () => {
    const checked = Manufacturer.every((e) =>
      assigns.find((d) => d.identifier == "manufacturer" && e.value == d.value)
    );
    return (
      <>
        <div
          className={
            "editLabelForm text-bold my-1 d-flex justify-content-between align-items-center"
          }
        >
          <span>Gamintojo pavadinimas</span>
          <div>
            <Tooltip
              placement="left"
              title={checked ? "Atžymėti visus" : "Pasirinkti visus"}
            >
              <div>
                <CheckBoxInput
                  checked={checked}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      return onChange([], "manufacturer");
                    }
                    return onChange(
                      Manufacturer.map((e) => e.value),
                      "manufacturer"
                    );
                  }}
                />
              </div>
            </Tooltip>
          </div>
        </div>
        <SelectForm
          mode="multiple"
          options={Manufacturer}
          value={assigns
            .filter((e) => e.identifier == "manufacturer")
            .map((e) => e.value)}
          onChange={(ar) => onChange(ar, "manufacturer")}
        />
      </>
    );
  };

  const renderCategory = () => {
    const fetchOptions = async (value) => {
      try {
        const { data } = await Axios.get(
          `/api/v1/shop_categories/list?[q][title_cont]=${value}`
        );
        console.log({ data });
        return data?.map((e) => ({
          value: e.id,
          label: e.title,
        }));
      } catch (err) {
        errorHandler(err);
        return [];
      }
    };

    console.log({ Category });
    return (
      <DebounceSelect
        label="Portalo kategorija"
        mode="multiple"
        placeholder="Pasirinkti"
        fetchOptions={fetchOptions}
        options={Category}
        value={assigns
          .filter((e) => e.identifier == "category")
          .map((e) => e.value)}
        onChange={(arr) =>
          onChange(
            arr.map((d) => d.value),
            "category"
          )
        }
      />
    );
  };
  return (
    <Fragment>
      {assign.includes("manufacturer") && renderManufacturer()}
      {assign.includes("category") && renderCategory()}
      {assign.includes("property_filters") && (
        <SelectForm
          placeholder="E-shop savybių filtrai"
          onlyPlaceholder="Pasirinkti"
          mode="multiple"
          options={PropertyFilters}
          value={assigns
            .filter((e) => e.identifier == "property_filters")
            .map((e) => e.value)}
          onChange={(ar) => onChange(ar, "property_filters")}
        />
      )}
      {assign.includes("product") && (
        <RenderAutoFill
          options={Product}
          onChange={(ar) => onChange(ar, "product")}
          assigns={assigns
            .filter((e) => e.identifier == "product")
            .map((e) => e.value)}
          value_old={assigns
            .filter((e) => e.identifier == "product")
            .map((e) => e.value)}
        />
      )}
    </Fragment>
  );
};

function RenderAutoFill({ onChange, assigns, options }) {
  const [value, setValue] = useState(assigns || undefined);
  const [array, setArray] = useState(options);
  useEffect(() => {
    if (array.length == 0) {
      setArray(options);
    }
  }, [options]);

  const [Loading, setLoading] = useState(false);
  const [Timer, setTimer] = useState(0);
  const fetchOrderRows = (value) => {
    if (!value || value?.length < 2) return;
    if (Timer) {
      clearTimeout(Timer);
    }
    setTimer(
      setTimeout(() => {
        if (value.length >= 3) {
          setLoading(true);
          setArray([]);
          Axios.get(
            `/api/v1/products?page=1&per_page=100&q[identifier_or_title_i_cont_any]=${value}`
          ).then((response) => {
            if (response.data) {
              const data = response.data.data.map((e) => ({
                label: e.title,
                value: e.id,
              }));
              setArray(data);
              setLoading(false);
            }
          });
        }
      }, 1000)
    );
  };
  return (
    <Fragment>
      <label className={"text-bold my-1"}>
        Prekės pavadinimo fragmentas arba Rivilės kodas
      </label>
      <Select
        showSearch
        mode="multiple"
        value={value}
        placeholder={"Pasirinkti"}
        dropdownMatchSelectWidth={true}
        filterOption={false}
        onSearch={fetchOrderRows}
        onChange={(a, b) => {
          onChange(a, b);
          setValue(a);
        }}
        style={{ width: "100%" }}
        notFoundContent={
          Loading ? (
            <div className="d-flex justify-content-center">
              <Spin size="small" />
            </div>
          ) : null
        }
      >
        {array &&
          array.map((d, idx) => (
            <Select.Option key={idx} value={d.value} {...d}>
              {d.label}
            </Select.Option>
          ))}
      </Select>
    </Fragment>
  );
}

export default NewLanding;

const UrlForPreview = ({ id }) => {
  const { value, error, loading } = useFetch(
    `/api/v1/landings/template_of_preview.json?id=${id}`
  );
  if (!loading && !error && value?.success) {
    return (
      <div>
        <a className="text-primary" href={value.url} target="_blank">
          Peržiūra: {value.url}
        </a>
      </div>
    );
  }
  return null;
};
