import React, { useState } from "react";

import { Input, Upload, message, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import DocumentItem from "./DocumentItem";
import { Button1 } from "../../../../../common/Buttons";
import { ClassicButton } from "../../../../ButtonWithLoading";
import { getUrlExtension } from "../../../../../../helpers/Utils";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function AttachOrderDocument({
  orderId,
  addOrderDocument,
  order_documents,
  deleteOrderDocument,
  disabled = false,
}) {
  const [CreateNew, setCreateNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState({
    title: "",
    image: false,
  });

  const attachDocument = () => {
    // setCreateNew(true);
    setLoading(true);
    const variables = {
      title: Data.title,
      asset: Data.image,
    };
    addOrderDocument(orderId, variables).then((res) => {
      if (res.success) {
        setData({
          title: "",
          image: false,
        });
        message.success("Sėkmingai išsaugota");
        setCreateNew(false);
        setLoading(false);
      } else {
        message.success("Įvyko klaida");
        setLoading(false);
      }
    });
  };

  const cancelCreate = () => {
    setData({
      title: "",
      image: false,
    });
    setCreateNew(false);
  };

  const propsImage = {
    name: "file",
    onChange(info) {
      if (info.file.status === "done") {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl) =>
          setData({ ...Data, image: imageUrl })
        );
      }
      if (info.file.status === "removed" && info.fileList.length === 0) {
        setData({ ...Data, image: false });
      }
    },
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <div>
      <DocumentItem
        documents={order_documents.map((e) => ({
          ...e,
          asset: { url: e.image_base64 },
          format: getUrlExtension(e.asset?.url),
        }))}
        deleteOrderDocument={deleteOrderDocument}
      />

      {CreateNew && (
        <>
          <div className="d-flex justify-content-between">
            <Input
              className="mr-2"
              placeholder="Dokumento pavadinimas"
              value={Data.title}
              onChange={(e) => setData({ ...Data, title: e.target.value })}
            />
            <div
              className="d-flex justify-content-end"
              style={{ width: "50%" }}
            >
              <Upload {...propsImage} customRequest={dummyRequest}>
                {Data.image == false && (
                  <Button style={{ width: 150 }}>
                    <UploadOutlined /> Įkelti dokumentą
                  </Button>
                )}
              </Upload>
            </div>
          </div>
          <div>
            <div className="d-flex justify-content-end mt-2 mb-4">
              <ClassicButton
                style={{ width: 150 }}
                loading={loading}
                text="Išsaugoti naują dokumentą"
                onClick={() => attachDocument()}
              />

              {/* <Button2
                text={"Išsaugoti naują dokumentą"}
                onClick={() => attachDocument()} */}
              {/* /> */}
            </div>
          </div>
        </>
      )}
      {!disabled && (
        <div className="my-4">
          <div style={{ position: "relative" }}>
            <Button1
              style={{
                position: "absolute",
                top: "-16px",
                left: "50%",
                transform: "translate(-50%, 0px)",
              }}
              text={CreateNew ? "Atšaukti" : "Įkelti naują dokumentą"}
              option={CreateNew ? "minus" : "upload"}
              onClick={
                CreateNew ? () => cancelCreate() : () => setCreateNew(true)
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}
export default AttachOrderDocument;
