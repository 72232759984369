import React, { useState, useEffect } from "react";
import { getRivileStoreOptions } from "../../../../../redux/Actions/rivileStoreAction";
import { LongButton } from "../../../Buttons";
import DeliveryBlockChange from "./delivery_block_change";

function DeliveryComponent({
  delivery_add_col,
  carrier,
  total_shipping,
  payment,
  parcel_terminals,
  parcel_terminal_id,
  shipping_address,
  orderId,
  updateShippingAddress,
}) {
  const [Parcels, setParcels] = useState([]);
  const [postalName, setPostalName] = useState("");

  const carrierModuleFilter = (carrier) => {
    let parcels = [];
    console.log({carrier})
    if (carrier.module_name == "omnivaltshipping") {
      parcels = parcel_terminals.omniva;
    } else if (carrier.module_name == "lpexpress") {
      parcels = parcel_terminals.lp_express;
    } else if (carrier.name == "DPD Paštomatas") {
      parcels = parcel_terminals.dpd;
    } else if (carrier.module_name == "fspickupatstorecarrier") {
      return getRivileStoreOptions().then((response) => setParcels(response));
    } else if (carrier.module_name == "itellashipping") {
      parcels = parcel_terminals.itella;
    } else if (
      carrier.module_name == "mijoravenipak" &&
      (carrier.name == "Venipak pickup point" ||
        carrier.name == "Venipak paštomatas")
    ) {
      parcels = parcel_terminals.venipak;
    } else {
      parcels = [];
    }
    if (Array.isArray(parcels)) {
      setParcels(parcels);
    } else {
      setParcels([]);
    }
    if (shipping_address?.parcel_terminal_id) {
      const finditem = parcels?.find(
        (i) => shipping_address.parcel_terminal_id == i.value
      );
      if (finditem) {
        setPostalName(finditem.label);
      } else {
        setPostalName("");
      }
    } else {
      setPostalName("");
    }
  };

  useEffect(() => {
    if (Object.keys(parcel_terminals).length > 0) {
      carrierModuleFilter(carrier, true);
    }
  }, [Object.keys(parcel_terminals).length]);

  return (
    <div className="bg-silver col-12 py-3">
      <div className="d-flex justify-content-center align-item-center mb-2">
        <LongButton
          text={` Pristatymo kaina: 
        ${total_shipping + " " + "\u20AC"}`}
        />
      </div>
      <div className="text-black">
        {carrier.module_name == "fspickupatstorecarrier" ? (
          <span>
            Atsiėmimas parduotuvėje:
            <br />
            {shipping_address.street}
          </span>
        ) : (
          <React.Fragment>
            <div>
              Pristatymo būdas:
              {carrier.name}
            </div>
            {postalName && (
              <div>
                Paštomatas:
                {postalName}
              </div>
            )}
          </React.Fragment>
        )}
      </div>
      <div>
        Mokėjimas: <span>{payment}</span>
      </div>
      {delivery_add_col && (
        <React.Fragment>
          <hr />
          <DeliveryBlockChange
            parcel_terminals={Parcels}
            parcel_terminal_id={parcel_terminal_id}
            shipping_address={shipping_address}
            orderId={orderId}
            carrier={carrier}
            updateShippingAddress={updateShippingAddress}
            carrierModuleFilter={carrierModuleFilter}
            setPostalName={setPostalName}
            total_shipping={total_shipping}
          />
        </React.Fragment>
      )}
    </div>
  );
}

export default DeliveryComponent;
