import React, { Fragment, useState } from "react";
import { Popover, Tooltip } from "antd";
import moment from "moment";
import ShippingInformation from "./ShippingInformation";
import { MainBlock } from "../../../../Buttons";
import {
  phonenumberValid,
  returnEuroString,
} from "../../../../../../helpers/Utils";
import { GoPackage, GoPrimitiveDot } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";
import SmsSending from "../SmallComp/sms_sending";
import { setPickupGiveAway } from "../../../../../../redux/Actions/shipmentsAction";
import { useDispatch } from "react-redux";
import { useMemo } from "react";

function ShippmentTable({ item, parcel_terminals, mode, order_id, id_order }) {
  const dispatch = useDispatch();
  const [MoreInformation, setMoreInformation] = useState(false);
  const [preparedPopover, setPreparedPopover] = useState(false);

  const order_return = useMemo(() => {
    return item.shipment_additional_services.some(
      (some) => some.service_key == "order_return" && some.service_value == 1
    );
  }, [item.shipment_additional_services.length]);

  const remark = () => {
    if (item.shipment_address.comment) {
      return (
        <div
          className="d-flex flex-column mt-1"
          style={{
            backgroundColor: "#F0FAF9",
            padding: "8px 8px 5px",
            borderRadius: 2,
          }}
        >
          <p
            className="mb-0"
            style={{
              color: "#00AAA0",
              fontWeight: 400,
              fontSize: 10,
              lineHeight: 1,
            }}
          >
            Pastaba kurjeriui
          </p>
          <p className="text-black mb-0 text-xs">
            {item.shipment_address.comment}
          </p>
        </div>
      );
    }
    return <div />;
  };

  const rivile_offer = () => {
    if (item.rivile_offer) {
      return (
        <div
          className="d-flex flex-column mt-1"
          style={{
            backgroundColor: "#fffaea",
            padding: "8px 8px 5px",
            borderRadius: 2,
          }}
        >
          <p
            className="mb-0"
            style={{
              color: "#bb7100",
              fontWeight: 400,
              fontSize: 10,
              lineHeight: 1,
            }}
          >
            Rivilės pasiūlymas
          </p>
          <p className="text-black mb-0 text-xs">
            {item.rivile_offer}
          </p>
        </div>
      );
    }
    return <div />;
  };

  const courier_tracking = () => {
    return (
      <>
        {item.identifier && (
          <div
            className="d-flex flex-column mt-1"
            style={{
              backgroundColor: "#FFF8EF",
              padding: "8px 8px 5px",
              borderRadius: 2,
            }}
          >
            <p
              className="mb-0"
              style={{
                color: "#F7951E",
                fontWeight: 400,
                fontSize: 10,
                lineHeight: 1,
              }}
            >
              Identifikatorius
            </p>
            <p className="text-black mb-0 text-xs">{item.identifier}</p>
          </div>
        )}
        {item.courier_trackings &&
          item.courier_trackings.map((courier) => {
            if (
              order_return &&
              item.shipment_type == "dpd" &&
              courier.number.length <= 6
            ) {
              return (
                <div className="text-danger">
                  Paėmimas su kurjerio lipduku, sękimo informacija negalima. (
                  {courier.number})
                </div>
              );
            }
            return (
              <div
                key={courier.number}
                className="d-flex flex-column mt-1"
                style={{
                  backgroundColor: "#FFF8EF",
                  padding: "8px 8px 5px",
                  borderRadius: 2,
                }}
              >
                <p
                  className="mb-0"
                  style={{
                    color: "#F7951E",
                    fontWeight: 400,
                    fontSize: 10,
                    lineHeight: 1,
                  }}
                >
                  Sekimo numeris
                </p>
                <a
                  className="text-black mb-0 text-xs"
                  style={{ pointerEvents: "all" }}
                  href={courier.url}
                  target="_blank"
                >
                  <u>{courier.number}</u>
                </a>
              </div>
            );
          })}
      </>
    );
  };
  return (
    <>
      {item.api_errors !== null && (
        <tr className="table-danger">
          <td colSpan="3">{item.api_errors}</td>
        </tr>
      )}
      {item.customer_pickup_called && (
        <div className="position-relative">
          <span
            className="badge badge-pill badge-success"
            style={{
              top: 5,
              right: 5,
              position: "absolute",
              zIndex: 1,
            }}
          >
            Kurjeris iškviestas
          </span>
        </div>
      )}
      <div className="position-relative">
        {mode !== "Servisas" && item.pickup_request && (
          <Fragment>
            <Popover
              placement="left"
              onClick={
                ["give_away", "partially_give_away"].includes(
                  item.pickup_request.status
                )
                  ? () => {}
                  : item.pickup_request.status == "unprepared"
                  ? () => setPreparedPopover(true)
                  : () => {
                      if (!confirm("Ar tikrai norite patvirtinti atidavimą?"))
                        return console.log("Denied");
                      dispatch(
                        setPickupGiveAway(
                          item.pickup_request.order_id,
                          item.id,
                          item.pickup_request.id
                        )
                      );
                    }
              }
              open={
                item.pickup_request.status == "unprepared" && preparedPopover
              }
              content={
                item.pickup_request.status == "unprepared" &&
                preparedPopover ? (
                  <>
                    <div style={{ width: 400 }}>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="font-weight-bold">
                          SMS siuntimas numeriui
                          {
                            <span
                              className={
                                phonenumberValid(item.shipment_address.phone)
                                  ? ""
                                  : "text-danger"
                              }
                            >
                              {item.shipment_address.phone}
                            </span>
                          }
                        </div>
                        <AiOutlineClose
                          className="pl-2 cursor-pointer"
                          size={25}
                          color={"#BFBFBF"}
                          onClick={() => setPreparedPopover(false)}
                        />
                      </div>
                      <SmsSending
                        shipment_id={item.id}
                        id={item.service_order_id}
                        phoneNumber={item.shipment_address.phone}
                        shipping_address_street={item.shipment_address.address}
                        orderId={id_order}
                        shipments={[item]}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )
              }
              trigger="click"
            >
              <Tooltip
                placement="left"
                title={
                  item.pickup_request.status == "unprepared"
                    ? "Nesuruošta"
                    : item.pickup_request.status == "prepared"
                    ? "Suruošta"
                    : item.pickup_request.status == "give_away"
                    ? "Atiduota"
                    : "Dalinai atiduota"
                }
              >
                <div
                  className={`btn-primary d-flex justify-content-center align-items-center m-auto cursor-pointer position-absolute ${
                    item.pickup_request.status == "unprepared"
                      ? "bg-danger"
                      : item.pickup_request.status == "prepared"
                      ? "bg-warning"
                      : "bg-success"
                  }`}
                  style={{
                    width: 29,
                    height: 29,
                    borderRadius: "50%",
                    top: 5,
                    right: 5,
                    zIndex: 1,
                  }}
                >
                  <GoPackage />
                </div>
              </Tooltip>
            </Popover>
          </Fragment>
        )}
      </div>
      <MainBlock
        className="pb-3"
        color={"#a8e2df"}
        height="auto"
        action={MoreInformation}
        onChange={() => setMoreInformation((prevState) => !prevState)}
      >
        <div>
          {order_return && (
            <span className="badge badge-pill badge-danger">
              Siuntos paėmimas iš kliento
            </span>
          )}
        </div>
        <div
          className="d-flex justify-content-between align-items-center px-2 "
          style={{ fontSize: "13px", fontWeight: 400 }}
        >
          <div>
            {item.pickup_address.company +
              " - " +
              item.pickup_address.address +
              " - " +
              item.pickup_address.city}
          </div>
          {item.user_title && <div className="px-2">{item.user_title}</div>}
          <div className="float-right">
            <div>{moment(item.created_at).format("YYYY-MM-DD")}</div>
            <div className="float-right">
              {moment(item.created_at).format("HH:mm")}
            </div>
          </div>
        </div>
        <div className="w-100" style={{ fontSize: "13px", fontWeight: 400 }}>
          {item.shipment_type === "at_the_store" || mode === "Servisas" ? (
            item.shipment_order_rows.length > 0 && (
              <>
                {item.shipment_order_rows
                  .filter((e) => !!e.title)
                  .map((order_item, index) => (
                    <div key={index}>
                      <GoPrimitiveDot className="mr-1" color="#a8e2df" />
                      <span className="border-bottom border-danger">
                        {order_item.quantity}x {order_item.title} -{" "}
                        {order_item.price} &#8364;{" "}
                      </span>
                    </div>
                  ))}
                {courier_tracking()}
                {remark()}
                {rivile_offer()}
                <div>
                  Viso:{" "}
                  <span className="font-weight-bold">
                    {returnEuroString(
                      item.shipment_order_rows
                        .filter((e) => !!e.title)
                        .reduce(
                          (cur, acc) => +acc.price * acc.quantity + cur,
                          0
                        )
                    )}
                  </span>
                </div>
              </>
            )
          ) : (
            <>
              {item.order_rows.map((order_item, index) => (
                <div key={index}>
                  <GoPrimitiveDot className="mr-1" color="#a8e2df" />
                  <span>{order_item.product_name}</span>
                </div>
              ))}
              {courier_tracking()}
              {remark()}
            </>
          )}
        </div>
        <div
          className="d-flex justify-content-between px-2"
          style={{ fontSize: "13px" }}
        >
          {item.picked_up_at && (
            <div>
              Paėmimo data:
              <br /> {moment(item.picked_up_at).format("YYYY-MM-DD HH:mm")}
            </div>
          )}
          {item.delivered_at && (
            <div>
              Pristatymo data:
              <br /> {moment(item.delivered_at).format("YYYY-MM-DD HH:mm")}
            </div>
          )}
        </div>
      </MainBlock>
      {MoreInformation && (
        <ShippingInformation
          key={item.id}
          information={item}
          parcel_terminals={parcel_terminals}
        />
      )}
    </>
  );
}

export default ShippmentTable;
