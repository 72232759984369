import React from "react";
import { DatePicker } from "antd";

const DataPickerInputModal = (props) => {
  return (
    <>
      {props.text && (
        <div className={`font-medium text-sm my-1 ${props.error ? "text-danger" : ""}`}>
          {props.text}{" "}
          {props.required && <span className="orange_color">*</span>}
        </div>
      )}
      <DatePicker
        format={props.format || "YYYY-MM-DD"}
        disabledDate={props.disabledDate || false}
        showToday={false}
        showTime={props.showTime || false}
        type={props.type || "text"}
        className={`orange ${props.warning ? "error_border" : ""}`}
        style={{ width: "100%" }}
        name={props.name || ""}
        placeholder={props.placeholder}
        value={props.value}
        onFocus={props.onFocus}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      {props.warning && <div className="text-danger">{props.warning}</div>}
    </>
  );
};

DataPickerInputModal.defaultProps = {
  placeholder: "Pasirinkti",
};

export default DataPickerInputModal;
