import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import EmailModal from "./EmailModal";
import { removeOverFlow, addOverFlow } from "../../helpers/modalFunc";

function ServiceModalEmail({
  showModal,
  modalInfo,
  closeModal,
  status_selected,
  status_onChange,
}) {
  const modalRef = useRef();
  useEffect(() => {
    if (["email"].includes(showModal)) {
      addOverFlow();
    } else {
      removeOverFlow();
    }
  }, [showModal]);

  const closeModalFunc = (e) => {
    if (modalRef.current == e.target) {
      closeModal(false);
    }
  };

  if (showModal === "email") {
    return ReactDOM.createPortal(
      <Background ref={modalRef} onClick={(e) => closeModalFunc(e)}>
        <ModalWrapper>
          <ModalContent>
            <EmailModal info={modalInfo} closeModal={closeModal} />
          </ModalContent>
        </ModalWrapper>
      </Background>,
      document.getElementById("custom_modal")
    );
  } else {
    return null;
  }
}

export default ServiceModalEmail;
const Background = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const ModalWrapper = styled.div`
  width: 550px;
  height: 280px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  padding: 20px 30px;
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  position: relative;
  z-index: 10;
  border-radius: 4px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
