import Axios from "axios";
import {
  GET_RIVILE_ITEMS,
  GET_RIVILE_ITEM_TYPES,
  LOADING_RIVILE_ITEMS,
  EDIT_RIVILE_ITEM,
  GET_RIVILE_ITEM,
  GET_RIVILE_ITEMS_SALES,
  GET_ERRORS,
  POST_COMMENT,
  CHANGE_STATUS,
  CHANGE_STATUS_TO_EXPORTED,
  EDIT_RIVILE_INVOICE,
  CHANGE_STATUS_MISMATCHED,
  GET_RIVILE_STORES
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

const success = () => {
  if (!document.getElementById("rivileAlert-s")) return;
  document.getElementById("rivileAlert-s").style.display = "block";
  setTimeout(
    () => (document.getElementById("rivileAlert-s").style.display = "none"),
    5000
  );
};

const error = (error) => {
  if (!document.getElementById("rivileAlert-e")) return;
  document.getElementById("rivileAlert-e").style.display = "block";
  document.getElementById("toast-error").innerHTML = error ? error : "Klaida"
  setTimeout(
    () => (document.getElementById("rivileAlert-e").style.display = "none"),
    5000
  );
};

const hideExportButton = (id) => {
  document.getElementById("invoiceExportBtn" + id).style.display = "none"
}

const hideImportButton = (id) => {
  document.getElementById("invoiceImportBtn" + id).style.display = "none"
}

export const postComment = (invoice_id, comment) => (dispatch) => {
  const postdata = {
    rivile_comment: {
      rivile_invoice_id: invoice_id,
      comment: comment
    },
  };
  Axios.post("/api/v1/rivile_comments", postdata)
    .then((res) => {
      dispatch({
        type: POST_COMMENT,
        payload: res.data,
      });
      success();
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const loadingRivileItems = () => dispatch => {
  dispatch(setRivileLoading());

}

export const getRivileItems = (filter, page, pageCount) => (dispatch) => {
  dispatch(setRivileLoading());
  Axios.get(
    `/api/v1/rivile_invoices?page=${page}&per_page=${pageCount}${filter}`
  )
    .then((res) => {
      dispatch({
        type: GET_RIVILE_ITEMS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getStores = () => (dispatch) => {
  Axios.get(`/api/v1/stores/rivile_sells_stores`)
    .then((res) => {
      dispatch({
        type: GET_RIVILE_STORES,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
    });
}

export const updateRivileInvoice = (id, value) => (dispatch) => {
  const params = {
    rivile_invoice: {
      notes: value
    },
  };

  return Axios.put(`/api/v1/rivile_invoices/${id}`, params).then(
    (res) => {
      dispatch({
        type: EDIT_RIVILE_INVOICE,
        payload: res.data,
      });
      success();
      return {
        success: true,
      };
    }
  ).catch(res => {
    return {success: false}
  })
};

export const getRivileItemsSales = (filter, page, pageCount) => (dispatch) => {
  dispatch(setRivileLoading());
  Axios.get(
    `/api/v1/rivile_invoices/exporting_status_invoices?page=${page}&per_page=${pageCount}${filter}`
  )
    .then((res) => {
      dispatch({
        type: GET_RIVILE_ITEMS_SALES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getRivileItemTypes = () => (dispatch) => {
  dispatch(setRivileLoading());
  Axios.get(`/api/v1/rivile_invoices/invoice_types`)
    .then((res) => {
      dispatch({
        type: GET_RIVILE_ITEM_TYPES,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
    });
};

export const getRivileItem = (id) => (dispatch) => {
  Axios.get(`/api/v1/rivile_invoices/${id}`)
    .then((res) => {
      dispatch({
        type: GET_RIVILE_ITEM,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateRivileItem = (id, bb_code) => (dispatch) => {
  let params = {
    rivile_invoiced_item: {
      bb_code,
    },
  };
  return Axios.put(`/api/v1/rivile_invoiced_items/${id}`, params).then(
    (res) => {
      dispatch({
        type: EDIT_RIVILE_ITEM,
        payload: res.data,
      });
      success();
      return {
        success: true,
      };
    }
  );
};

export const changeStatusAction = (arrayOfId, modalExport) => (dispatch) => {
  dispatch(setRivileLoading());
  dispatch({
    type: CHANGE_STATUS,
    payload: arrayOfId,
  });
  success();
  if(modalExport === true){
    hideExportButton(arrayOfId[0]);
  }
};

export const changeMismatchedAgatas = (mismatched, notification) => (dispatch) => {
  dispatch(setRivileLoading());
  dispatch({
    type: CHANGE_STATUS_MISMATCHED,
    payload: mismatched,
  });
  error(notification);
};

export const changeStatusToExported = (arrayOfId, modalExport, errors) => (dispatch) => {
  if(errors) {
    if(Array.isArray(errors) && errors.length > 0){
      error(errors[0]);
    } else {
      error(false)
    }
  } else {
    success();
    if(modalExport === true){
      hideImportButton(arrayOfId[0]);
    }
    dispatch(setRivileLoading());
    dispatch({
      type: CHANGE_STATUS_TO_EXPORTED,
      payload: arrayOfId,
    });
  }
};

export const forceStatusToExported = (arrayOfId, modalExport) => (dispatch) => {
  dispatch({
    type: CHANGE_STATUS_TO_EXPORTED,
    payload: arrayOfId,
  });
  if(modalExport === true){
    var importButton = document.getElementById("invoiceImportBtn" + arrayOfId[0]);
    if (importButton) {
      hideImportButton(arrayOfId[0]);
    } else{
      hideExportButton(arrayOfId[0]);
    }
  }
};

export const setRivileLoading = () => {
  return {
    type: LOADING_RIVILE_ITEMS,
  };
};
