import React from "react";
import Spinner from "../common/Spinner";

function InitialData({ loading, error, value }) {
  console.log({ loading, error, value });
  if (loading)
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spinner />
      </div>
    );
  if (error) return <p>{JSON.stringify(error)}</p>;
  if (!value) return <p>Nėra duomenų</p>;
  return <div>...</div>;
}

export default InitialData;
