import { Button, Popconfirm } from "antd";
import React from "react";
import { FaSearch } from "react-icons/fa";
import ButtonSecondary from "../../../common/ButtonSecondary";
import SwitchComp from "../../../common/SwitchComp";
import { IconTrash } from "../../../Layout/Icons";

const ItemsPreview = ({
  title,
  steps,
  setModalType,
  onChangeState,
  addStep,
  onHandleAdd,
  onHandleChange,
  onHandleDelete,
}) => {

  const available_steps_length = steps.filter((d) => !d._destroy).length;
  return (
    <div>
      <label className={"editLabelForm text-bold my-1 "}>{title}</label>
      <div
        style={{
          background: "#FFFFFF",
          border: "1px solid #EBEBEB",
          borderRadius: 4,
        }}
      >
        {available_steps_length == 0 ? (
          <div
            className="px-3 py-2 d-flex justify-content-between align-items-center"
            style={{
              background: "#FFFFFF",
              border: "1px solid #EBEBEB",
              borderRadius: 4,
            }}
          >
            <div>
              <h4 className="mb-0 text-black text-xs font-semi-bold">
                Kolkas nėra jokių žingsnių
              </h4>
              <p className="mb-0 text-black text-xs“">
                Pradėkite nuo mažų... 😊
              </p>
            </div>
            <div>
              {onHandleAdd && (
                <ButtonSecondary outline={true} onClick={onHandleAdd}>
                  Pridėti žingsnį
                </ButtonSecondary>
              )}
            </div>
          </div>
        ) : (
          steps.map((d, idx) =>
            d._destroy ? (
              <div key={`steps_${idx}`} />
            ) : (
              <div
                key={`steps_${idx}`}
                className="mx-4 row py-3"
                style={{
                  borderBottom: "2px solid #F9F9F9",
                }}
              >
                <div className="col-6 d-flex align-items-center">{d.title}</div>
                <div className="col-3 d-flex align-items-center">
                  <SwitchComp
                    className=" "
                    checkedChildren="I"
                    unCheckedChildren="I"
                    checked={d.active}
                    inlineText={d.active ? "Aktyvus" : "Neaktyvus"}
                    onChange={(value) =>
                      onHandleChange({ target: { value, name: "active" } }, idx)
                    }
                  />
                </div>
                <div className="col-3 d-flex align-items-center justify-content-end">
                  <Button
                    style={{
                      background: "#f29302",
                      borderRadius: 3,
                      width: 33,
                      height: 33,
                      padding: 0,
                    }}
                    onClick={() => {
                      setModalType((prev) => ({
                        type: "show",
                        steps: [...prev.steps, idx],
                      }));
                    }}
                  >
                    <FaSearch className="text-white" />
                  </Button>

                  <div className="mx-1" />
                  <Popconfirm
                    placement="topRight"
                    title={"Ar tikrai norite ištrinti šį elementą?"}
                    onConfirm={() => onHandleDelete(d, idx)}
                    okText="Taip"
                    cancelText="Ne"
                  >
                    <Button
                      style={{
                        background: "#E61D01",
                        borderRadius: 3,
                        width: 33,
                        height: 33,
                        padding: 0,
                      }}
                    >
                      <IconTrash color="#fff" />
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            )
          )
        )}
      </div>
      {onHandleAdd && available_steps_length > 0 && (
        <div className="d-flex justify-content-end mt-3">
          <div
            style={{
              width: 130,
            }}
          >
            <ButtonSecondary outline={true} onClick={onHandleAdd}>
              Pridėti žingsnį
            </ButtonSecondary>
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemsPreview;
