import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonSecondary from "../../components/common/ButtonSecondary";
import ContentValidation from "../../components/common/ContentValidation";
import TableFilterBox from "../../components/common/TableFilterBox";
import PaginationComp from "../../components/Dashboard/items/Pagination";
import Container from "../../components/Layout/Container";
import ContentBlock from "../../components/Layout/ContentBlock";
import FilterMenu from "../../components/Layout/FilterMenu";
import {
  handleKeyDown,
  onChangeUseState,
  onChangeUseState2,
} from "../../helpers/GlobalFunctions";
import {
  createInnerCommerceList,
  destroyProductsCommerceList,
  getCommerceShowList,
  getCommerceShowProductsList,
  importXlsCommerceList,
} from "../../redux/Actions/commerce_orders_action";
import ReactDOM from "react-dom";
import { addOverFlow, removeOverFlow } from "../../helpers/modalFunc";
import ModalSpecial from "../../components/Layout/Modals/ModalSpecial";
import InputSwitchComponent from "../../components/common/InputSwitchComponent";
import TableUi from "../../components/common/TableUi";
import useModal from "../../hooks/useModal";
import {
  IconAlternativeProduct,
  IconChevron,
  IconDotLine,
  IconExclamationColored,
} from "../../components/Layout/Icons";
import { CheckBoxInput } from "../../components/common/Buttons";
import {
  DropdownButton,
  Label,
  Input,
  ProductSku,
} from "../../components/global";
import {
  createAnalogProductCommerce,
  updateCommerceList,
} from "../../redux/Actions/commerce_products_action";
import CommerceTableColumn from "../../components/commerce/products/CommerceTableColumn";
import SelectInput from "../../components/common/SelectInput";
import { Checkbox, Input as InputAntd, Tooltip } from "antd";
import useChecked from "../../hooks/useChecked";
import {
  antdMessageError,
  exportDocument,
  uniqueArray,
} from "../../helpers/Utils";
import Axios from "axios";
import { getCommerceSortedSuppliersOption } from "../../redux/Actions/supplierAction";
import { InitialData } from "../../components/global";
import AntdConfirm from "../../helpers/AntdConfirm";
import { DebounceSelect } from "../../components/common";
import { createConsumer } from "@rails/actioncable";
import { changesCommerceStore } from "../../redux/Actions/commerce_stores_action";

const filtersInitial = {
  title: "",
  supplier_id: undefined,
  description: "",
  need_review: false,
};
export default function CommerceOrdersIdPage({ match, history }) {
  const dispatch = useDispatch();
  const fileRef = useRef();

  const { loading, data, totals, current, show_data } = useSelector(
    (state) => state.custom
  );

  const [filters, setFilters] = useState(filtersInitial);
  const [page, setPage] = useState(1);
  const [perCount, setPerPage] = useState(200);
  const [options, setOptions] = useState({});

  const { modalOpt, setModalOpt, closeModal } = useModal();
  const { onChangeChecked, checkedIncluded, setChecked, checkedAll, checked } =
    useChecked();

  useEffect(() => {
    console.log("First");
    const consumer = createConsumer();

    consumer.subscriptions.create(
      {
        channel: "CommerceProductsChannel",
      },
      {
        connected() {
          console.log("Connected WS CommerceProductsChannel");
        },
        received(data) {
          console.log("Received WS CommerceProductsChannel", data);
          dispatch(changesCommerceStore(data?.commerce_store));
        },
      }
    );
    dispatch(getCommerceShowList(match.params.id));
    Promise.all([getCommerceSortedSuppliersOption()]).then((res) => {
      setOptions({
        suppliers: [
          { label: "Visi", value: "" },
          { label: "Be tiekimo", value: "0" },
          ,
          ...res[0].data.data.map((d) => ({
            value: d.id,
            label: d.name,
          })),
        ],
      });
    });
  }, []);

  useEffect(() => {
    if (!modalOpt?.type) {
      startedPack(1);
    }
  }, [modalOpt?.type]);

  useEffect(() => {
    if (current !== "CommerceOrderShow") return;
    startedPack(1);
  }, [perCount]);

  useEffect(() => {
    if (current !== "CommerceOrderShow") return;
    startedPack();
  }, [page]);

  useEffect(() => {
    startedPack(1);
  }, [filters.supplier_id, filters.description, filters.need_review]);

  const startedPack = (defaultPage = page) => {
    if (defaultPage !== page) setPage(defaultPage);
    setChecked([]);
    dispatch(
      getCommerceShowProductsList(
        match.params.id,
        defaultPage,
        perCount,
        onFilter()
      )
    );
  };

  const onFilter = () => {
    const { title, supplier_id, description, need_review } = filters;

    let search_string = "";
    if (title) {
      search_string += `&q[product_title_or_product_identifier_cont]=${title}`;
    }
    if (supplier_id) {
      if (supplier_id == "0") {
        search_string += `&q[commerce_stores_supplier_id_null]=1`;
      } else {
        search_string += `&q[commerce_stores_supplier_id_eq]=${supplier_id}`;
      }
    }
    if (description) {
      search_string += `&q[commerce_product_id_null]=${description}`;
    }
    if (need_review) {
      search_string += `&q[commerce_stores_need_review_true]=1`;
    }

    return search_string;
  };

  const products = useMemo(() => {
    if (loading) return [];
    if (current !== "CommerceOrderShow") return [];
    console.log("fetched");
    return data
      .filter((d) => !d.parent_id)
      .map((d) => ({
        ...d,
        childs: data.filter((dd) => dd.parent_id == d.id),
      }));
  }, [loading, data]);

  const breadcrumbs = useMemo(() => {
    const breadcrumb_default = [
      {
        label: "Užsakymų sąrašas",
        value: "/commerce/orders",
      },
      {
        label: show_data?.title,
        value: match.url,
      },
    ];

    return breadcrumb_default;
  }, [loading]);

  const dropdownButtonOptions = useMemo(() => {
    if (!show_data?.id) return [];
    return [
      {
        label: "Importuoti iš XLSX",
        onFunction: () => {
          fileRef.current.click();
        },
      },
      {
        label: "Formuoti vidinį (Rivilė)",
        onFunction: () => {
          if (checked.length == 0)
            return antdMessageError("Nieko nepasirinkote");
          createInnerCommerceList(show_data?.id, checked).then((res) => {
            if (!res.success) return;
            startedPack(1);
          });
        },
      },
      {
        label: "Ekspotuoti tiekėjams XLSX",
        onFunction: () => {
          if (checked.length == 0)
            return antdMessageError("Nieko nepasirinkote");
          exportDocument(
            `/api/v1/commerce/lists/${show_data.id}/export_supplier_xls`,
            "commerce_suppliers.xlsx",
            "POST",
            {
              commerce_product_ids: checked,
            }
          );
        },
      },
      {
        label: "Trinti pažymėtus",
        onFunction: () => {
          if (checked.length == 0)
            return antdMessageError("Nieko nepasirinkote");

          AntdConfirm({
            onOk: () =>
              destroyProductsCommerceList(show_data?.id, checked).then(() =>
                startedPack(1)
              ),
          });
        },
      },
    ];
  }, [checked, show_data?.id]);

  const onHandleModalOptCallBack = useCallback(
    (record_type, record_id, record_value) =>
      setModalOpt(record_type, record_id, record_value),
    []
  );

  const onChangeCheckedCallBack = useCallback((id) => onChangeChecked(id), []);

  return (
    <Container
      location={{
        locationName: show_data?.title,
        locationUrl: match.url,
      }}
      breadcrumbs={breadcrumbs}
      goBack={() => history.push(breadcrumbs[0].value)}
    >
      <input
        hidden={true}
        ref={fileRef}
        type="file"
        onChange={(e) => {
          const file = e.target.files[0];
          importXlsCommerceList(show_data?.id, file).then(() => startedPack(1));
          e.target.value = null;
        }}
      />
      <ContentBlock>
        <FilterMenu
          totals={totals}
          data_totals={data?.length || 0}
          onChangeCountPage={setPerPage}
          countPage={perCount}
          page={page}
          text="užsakymų"
          text2="Užsakymų"
        />
      </ContentBlock>

      <ContentBlock>
        <TableFilterBox
          clearFilter={() =>
            setFilters({
              ...filtersInitial,
              supplier_id: filters.supplier_id === undefined ? null : undefined,
            })
          }
          button1={
            <>
              <div
                className="float-left ml-1"
                style={{
                  maxWidth: 150,
                }}
              >
                <Tooltip placement="right" title="Išskleisti pasirinktus">
                  <button
                    className="cursor-pointer btn btn-sm border bg-white"
                    onClick={() => {
                      document
                        .querySelectorAll(".auto_commerce_open_button")
                        .forEach((btn) => btn.click());
                    }}
                  >
                    <IconChevron up={true} />
                  </button>
                </Tooltip>
              </div>
              <div
                className="float-right mr-1"
                style={{
                  maxWidth: 150,
                }}
              >
                <ButtonSecondary
                  onClick={() =>
                    onHandleModalOptCallBack("add_products", match.params.id)
                  }
                >
                  Pridėti prekes
                </ButtonSecondary>
              </div>
              <div className="mr-3 float-right">
                <DropdownButton
                  text="Veiksmai"
                  color="#343A40"
                  minWidth={155}
                  actions={dropdownButtonOptions}
                />
              </div>
            </>
          }
        >
          <div className="px-1 col-3">
            <Input
              text="Prekės kodas/pavadinimas"
              name="title"
              onKeyDown={(e) => {
                handleKeyDown(e, () => startedPack(1));
              }}
              onChange={onChangeUseState2(setFilters)}
              value={filters.title}
            />
          </div>
          <div className="px-1 col-2-5">
            <SelectInput
              label="Tiekėjas"
              options={options?.suppliers || []}
              name="supplier_id"
              onChange={(value) =>
                onChangeUseState(
                  {
                    target: {
                      name: "supplier_id",
                      value,
                    },
                  },
                  setFilters
                )
              }
              value={filters.supplier_id}
            />
          </div>
          <div className="px-1 col-2-5">
            <InputSwitchComponent
              name="description"
              label="Aprašymas"
              options={[
                {
                  value: "",
                  label: "Visos",
                },
                {
                  value: "1",
                  label: "Prekės",
                },
                {
                  value: "0",
                  label: "Alternatyvos",
                },
              ]}
              value={filters.description}
              onChange={onChangeUseState2(setFilters)}
            />
          </div>
          <div className="px-1 col-2-5">
            <div className="form-group">
              <Label>Korekcijos</Label>
              <div className="mt-2" style={{ fontSize: "0.75rem" }}>
                <CheckBoxInput
                  text={
                    <div>
                      <IconExclamationColored />{" "}
                      <span
                        className="text-xs2"
                        style={{
                          color: "#C2C2C2",
                        }}
                      >
                        (Rodyti tik su korekcijų poreikiu)
                      </span>
                    </div>
                  }
                  name="need_review"
                  checked={filters.need_review}
                  onChange={() =>
                    setFilters((prev) => ({
                      ...prev,
                      need_review: !filters.need_review,
                    }))
                  }
                />
              </div>
            </div>
          </div>{" "}
        </TableFilterBox>
        <div>
          <ContentValidation loader={loading} array={data}>
            <TableUi
              className={"table-borders"}
              ths={[
                <div>
                  <Checkbox
                    checked={checkedAll(data)}
                    onChange={() => {
                      if (checkedAll(data)) {
                        setChecked([]);
                      } else {
                        setChecked(data.map((product) => product.id));
                      }
                    }}
                  />
                </div>,
                "Prekės kodas",
                "Prekės pavadinimas",
                "MPN",
                "Bendras likutis",
                "U080 likutis",
                "U0809 likutis",
                "U0711 likutis",
                "",
              ]}
            >
              {current == "CommerceOrderShow" &&
                products.map((product) => {
                  return (
                    <CommerceTableColumn
                      key={`product_${product.id}`}
                      product={product}
                      checkedIncluded={checkedIncluded(product.id)}
                      onHandleModalOptCallBack={onHandleModalOptCallBack}
                      onChangeCheckedCallBack={onChangeCheckedCallBack}
                    />
                  );
                })}
            </TableUi>
            <div className="table-responsive">
              <PaginationComp
                page={page}
                totals={totals}
                setPagination={setPage}
                pageCount={perCount}
              />
            </div>
          </ContentValidation>
        </div>
      </ContentBlock>
      {modalOpt?.type && (
        <ListModalWrapper modalOpt={modalOpt} closeModal={() => closeModal()} />
      )}
    </Container>
  );
}

// Modals
function ListModalWrapper({ modalOpt, closeModal }) {
  useEffect(() => {
    if (modalOpt) {
      addOverFlow();
    }
  }, []);

  const onClose = () => {
    removeOverFlow();
    closeModal();
  };

  if (modalOpt.type == "create_analog") {
    return ReactDOM.createPortal(
      <ModalSpecial
        text="Pridėti alternatyvią prekę"
        style={{
          width: 650,
        }}
        onClose={onClose}
      >
        <CreateAnalogModal modalOpt={modalOpt} onClose={onClose} />
      </ModalSpecial>,
      document.getElementById("custom_modal")
    );
  }
  if (modalOpt.type == "add_products") {
    return ReactDOM.createPortal(
      <ModalSpecial
        text="Pridėti naujas prekes"
        style={{
          width: 650,
        }}
        onClose={onClose}
      >
        <AddProductsModal modalOpt={modalOpt} onClose={onClose} />
      </ModalSpecial>,
      document.getElementById("custom_modal")
    );
  }
  return null;
}

const AddProductsModal = ({ modalOpt, onClose }) => {
  const dispatch = useDispatch();
  const [loadingApi, setLoadingApi] = useState(false);
  const [value, setValue] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetcher = () =>
    Axios.get(`/api/v1/commerce/lists/${modalOpt.record_id}`)
      .then((res) => {
        setValue({
          products: uniqueArray(res.data.products)?.map((sku) => ({
            sku,
            value: sku,
          })),
        });
      })
      .finally(() => setLoading(false));

  useEffect(() => {
    fetcher();
  }, []);

  console.log({ value });
  if (loading || loadingApi) {
    return <InitialData loading={loading || loadingApi} />;
  }
  return (
    <div className="pb-5">
      <ProductSku
        record_id={modalOpt.record_id}
        type="products"
        products={value.products}
        setValue={setValue}
        customFunction={() => fetcher()}
      />
      <div className="mt-5 d-flex justify-content-end">
        <div
          style={{
            width: 150,
          }}
        >
          <ButtonSecondary
            disabled={loadingApi || loading}
            onClick={async () => {
              try {
                setLoadingApi(true);
                await dispatch(updateCommerceList(modalOpt.record_id, value));
                onClose();
              } finally {
                setLoadingApi(false);
              }
            }}
          >
            Pridėti
          </ButtonSecondary>
        </div>
      </div>
    </div>
  );
};

const CreateAnalogModal = ({ modalOpt, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [analog_product_id, set_analog_product_id] = useState(undefined);
  const dispatch = useDispatch();

  return (
    <div className="pb-5">
      <div className="position-relative">
        <IconDotLine />
        <div>
          <Label required={true}>Prekės kodas</Label>
          <div
            style={{
              color: "#555580",
              fontWeight: 500,
            }}
          >
            Pirminė prekė
          </div>
          <InputAntd disabled={true} value={modalOpt.record.identifier} />
        </div>
        <div>
          <Label className={"mb-0"} required={true}>
            Prekės kodas
          </Label>
          <div
            style={{
              color: "#555580",
              fontWeight: 500,
            }}
          >
            Alternatyvi prekė
          </div>
          <DebounceSelect
            showSearch={true}
            dropdownMatchSelectWidth={true}
            placeholder="Pasirinkti"
            fetchOptions={async (value) => {
              try {
                const { data } = await Axios.get(
                  `/api/v1/products/simple_products.json?q[identifier_cont]=${value}`
                );
                return data?.data?.map((e) => ({
                  value: e.id,
                  label: (
                    <div>
                      <IconAlternativeProduct className="mr-1" />
                      {e.identifier} - {e.title}
                    </div>
                  ),
                }));
              } catch (err) {
                console.error(err);
                return [];
              }
            }}
            onChange={(value) => set_analog_product_id(value.value)}
          />
        </div>
      </div>

      <div className="mt-5 d-flex justify-content-end">
        <div
          style={{
            width: 150,
          }}
        >
          <ButtonSecondary
            disabled={loading}
            onClick={async () => {
              try {
                setLoading(true);
                if (!analog_product_id)
                  return antdMessageError("Pasirinkite prekę");
                await dispatch(
                  createAnalogProductCommerce(
                    modalOpt.record_id,
                    analog_product_id
                  )
                );
                onClose();
              } finally {
                setLoading(false);
              }
            }}
          >
            Pridėti
          </ButtonSecondary>
        </div>
      </div>
    </div>
  );
};
