import React from "react";
import { Select } from "antd";
import { Label } from "../global";
const { Option } = Select;

export default function SelectInput({
  label,
  labelClass = "",
  selectClass = "",
  required = false,
  placeholder = "",
  error = false,
  style = false,
  onChange,
  value,
  disabled = false,
  options = [],
  showSearch = true,
}) {
  return (
    <>
      {!!label && (
        <Label required={required} className={labelClass} error={error}>
          {label}
        </Label>
      )}
      <Select
        showSearch={showSearch}
        className={selectClass + " " + (error ? "error_border" : "")}
        style={style || { width: "100%" }}
        placeholder={placeholder || "Pasirinkti"}
        optionFilterProp="children"
        onChange={onChange}
        value={value}
        size="default"
        disabled={disabled}
      >
        {options
          ? options.map((item, index) => (
              <Option key={index} value={item.value} {...item}>
                {item.label}
              </Option>
            ))
          : []}
      </Select>
      {error && <div className="text-danger">{error}</div>}
    </>
  );
}
