import Axios from "axios";
import {
  GET_LOTTERY_TICKETS,
  POST_LOTTERY_TICKETS,
  DELETE_LOTTERY_PLAYERS,
  GET_LOTTERY_PLAYERS,
  PUT_LOTTERY_TICKETS,
  DELETE_LOTTERY_TICKETS,
  LOADING_LOTTERY_TICKETS,
  CLEAR_ERRORS,
  CLAIMED_LOTTERY,
  GET_ERRORS,
} from "./types";
import { success, error } from "./modalsFunctios";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

export const getLotteryTicket = (page, pageCount, filter, loading) => (
  dispatch
) => {
  if (!loading) {
    dispatch(setLoadingtickets());
  }
  Axios.get(
    `/api/v1/lottery_tickets?page=${page}&per_page=${pageCount}${filter}`
  )
    .then((res) => {
      dispatch({
        type: GET_LOTTERY_TICKETS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const getLotteryTicketWithoutReload = (page, pageCount, filter) => (
  dispatch
) => {
  Axios.get(
    `/api/v1/lottery_tickets?page=${page}&per_page=${pageCount}${filter}`
  )
    .then((res) => {
      dispatch({
        type: GET_LOTTERY_TICKETS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const postLotteryTicket = (id) => (dispatch) => {
  return Axios.get(`/api/v1/lottery_tickets_imports/${id}/confirm_import`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error("ticketAlert-e");
    });
};

export const putLotteryTicket = (id, body) => (dispatch) => {
  Axios.put(`/api/v1/lottery_tickets/${id}`, body)
    .then((res) => {
      dispatch({
        type: PUT_LOTTERY_TICKETS,
        payload: res.data,
      });
      success("ticketAlert-s", "closeModal0");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error("ticketAlert-e");
    });
};

export const deleteLotteryTicket = (id) => (dispatch) => {
  Axios.delete(`/api/v1/lottery_tickets/${id}`)
    .then((res) => {
      dispatch({
        type: DELETE_LOTTERY_TICKETS,
        payload: id,
      });
      success("ticketAlert-s");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error("ticketAlert-e");
    });
};

export const claimedTicket = (id) => (dispatch) => {
  Axios.get(`/api/v1/lottery_tickets/${id}/claim`)
    .then((res) => {
      dispatch({
        type: CLAIMED_LOTTERY,
        payload: res.data,
      });
      success("ticketAlert-s");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error("ticketAlert-e");
    });
};

//

export const getLotteryPlayers = (page, pageCount, filter) => (dispatch) => {
  Axios.get(
    `/api/v1/lottery_players?page=${page}&per_page=${pageCount}${filter}`
  )
    .then((res) => {
      dispatch({
        type: GET_LOTTERY_PLAYERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const deleteLotteryPlayers = (id) => (dispatch) => {
  Axios.delete(`/api/v1/lottery_players/${id}`)
    .then((res) => {
      dispatch({
        type: DELETE_LOTTERY_PLAYERS,
        payload: id,
      });
      success("ticketAlert-s");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error("ticketAlert-e");
    });
};

export const setLoadingtickets = () => {
  return {
    type: LOADING_LOTTERY_TICKETS,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
