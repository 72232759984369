import Axios from "axios";
import React, { useEffect, useState } from "react";
import ContentValidation from "../../components/common/ContentValidation";
import TableFilterBox from "../../components/common/TableFilterBox";
import PaginationComp from "../../components/Dashboard/items/Pagination";
import Container from "../../components/Layout/Container";
import ContentBlock from "../../components/Layout/ContentBlock";
import FilterMenu from "../../components/Layout/FilterMenu";
import { message, Input, Switch } from "antd";
import {
  Button1,
  CheckBoxInput,
  EditElementClick,
  MainBlockWithContext,
  SelectForm,
} from "../../components/common/Buttons";
import InputText from "../../components/orders/accepted_orders/InputFilter";
import ModalSpecial from "../../components/Layout/Modals/ModalSpecial";
import { Divs } from "../../components/common/StyledComponents";
import TableUi from "../../components/common/TableUi";
import moment from "moment";
import { FlopppyButton } from "../../components/common/ButtonWithLoading";
import RangePickerComp from "../../components/common/RangePickerComp";
import {
  exportDocument,
  getDateFormat,
  prettyDateFormat,
} from "../../helpers/Utils";
import { renderByColor } from "../../helpers/GlobalFunctions";

const { TextArea } = Input;

export default function Purchases3PL({ match }) {
  const [pageCount, setPageCount] = useState(100);
  const [page, setPage] = useState(1);
  const [filter_string, setFilterString] = useState("");
  const [statuses, setStatuses] = useState([]);
  const [filters, setfilters] = useState({
    delivery_at: ["", ""],
    operation_date: ["", ""],
    document_number: "",
    sku: "",
    status: "",
  });

  const fetchASC = async () => {
    setLoading(true);
    try {
      const { data } = await Axios.get(
        `/api/v1/purchases?page=${page}&per_page=${pageCount}${filter_string}`
      );
      if (!data || !data?.data) throw new Error("Err");
      setData(data.data);
      setTotals(data.totals);
      setStatuses(data.statuses);
      setLoading(false);
      setPage(1);
    } catch (err) {
      console.log("Error: ", err);
      setLoading(false);
      setPage(1);
    }
  };
  useEffect(() => {
    fetchASC();
  }, [page, pageCount, filter_string]);
  useEffect(
    () => onFilter(),
    [filters.delivery_at, filters.operation_date, filters.status]
  );

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totals, setTotals] = useState(0);

  const [showModal, setShowModal] = useState(false);

  const onFilter = () => {
    let filterParams = [];

    const { delivery_at, operation_date, document_number, sku, status } =
      filters;

    if (delivery_at[0].length > 0) {
      filterParams.push(`&q[delivery_at_gteq]=${delivery_at[0]}`);
    }
    if (delivery_at[1].length > 0) {
      filterParams.push(`&q[delivery_at_lteq]=${delivery_at[1]}`);
    }
    if (operation_date[0].length > 0) {
      filterParams.push(`&q[operation_date_gteq]=${operation_date[0]}`);
    }
    if (operation_date[1].length > 0) {
      filterParams.push(`&q[operation_date_lteq]=${operation_date[1]}`);
    }
    if (document_number) {
      filterParams.push(`&q[document_number_cont_any]=${document_number}`);
    }
    if (sku) {
      filterParams.push(`&q[purchase_items_sku_cont_any]=${sku}`);
    }
    if (status) {
      filterParams.push(`&q[status_cont_any]=${status}`);
    }

    setFilterString(filterParams.join(""));
    setPage(1);
  };

  return (
    <Container
      location={{
        locationName: "Pirkimai",
        locationUrl: match.url,
      }}
    >
      <ContentBlock>
        <FilterMenu
          length={`${totals || 0} / ${data.length || 0}`}
          onChangeCountPage={setPageCount}
          countPage={pageCount}
          text="pirkimų"
          text2="Pirkimų"
        />
      </ContentBlock>
      <ContentBlock>
        <TableFilterBox
          add={async () => {
            const operation_number = prompt("Įveskite numerį", "");
            if (!operation_number || operation_number?.trim() == "") return;
            await message.loading(
              `Importuojamas dokumentas: ${operation_number}`
            );

            try {
              // if (operation_number == "test") throw new Error("err");
              const { data } = await Axios({
                url: "/api/v1/callbacks/rivile_purchase",
                method: "POST",
                data: {
                  operation_number,
                },
                headers: { Authorization: "Bearer h3rs1lu8FJ" },
              });
              if (data.message == "success") {
                console.log("All Data", data);
                message.success(`Sėkmingai importuotas: ${operation_number}`);
                return fetchASC();
              }
              throw new Error("Err");
            } catch (err) {
              return message.error(
                err?.response?.data?.message
                  ? `${err?.response?.data?.message}: ${operation_number}`
                  : `Įvyko klaida: ${operation_number}`
              );
            }
          }}
        >
          <div className="px-1">
            <InputText
              value={filters.document_number}
              placeholder="Dokumento numeris"
              style={{ width: 150, fontSize: "0.75rem" }}
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  onFilter();
                }
              }}
              onChange={(e) => {
                setfilters({
                  ...filters,
                  document_number: e.target.value || "",
                });
              }}
            />
          </div>
          <div className="px-1">
            <div className="form-group">
              <label className="editLabelForm">Pristatyta</label>
              <div>
                <RangePickerComp
                  style={{ width: 250 }}
                  placeholder={["Pradžios laikas", "Pabaigos laikas"]}
                  disabledDate={() => {}}
                  value={[
                    getDateFormat(filters.delivery_at[0]),
                    getDateFormat(filters.delivery_at[1]),
                  ]}
                  onChange={(_, args) => {
                    setfilters({
                      ...filters,
                      delivery_at: args || "",
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="px-1">
            <div className="form-group">
              <label className="editLabelForm">Dokumento data</label>
              <div>
                <RangePickerComp
                  style={{ width: 250 }}
                  placeholder={["Pradžios laikas", "Pabaigos laikas"]}
                  disabledDate={() => {}}
                  value={[
                    getDateFormat(filters.operation_date[0]),
                    getDateFormat(filters.operation_date[1]),
                  ]}
                  onChange={(_, args) => {
                    setfilters({
                      ...filters,
                      operation_date: args || "",
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="px-1">
            <InputText
              value={filters.sku}
              placeholder="Prekės kodas"
              style={{ width: 150, fontSize: "0.75rem" }}
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  onFilter();
                }
              }}
              onChange={(e) => {
                setfilters({
                  ...filters,
                  sku: e.target.value || "",
                });
              }}
            />
          </div>
          <div className="px-1" style={{ minWidth: "150px" }}>
            <SelectForm
              placeholder="Būsena"
              dropdownMatchSelectWidth={false}
              options={[
                { label: "Visi", value: null },
                ...Object.entries(statuses).map(([key, val]) => ({
                  label: val,
                  value: key,
                })),
              ]}
              value={filters.status || undefined}
              onChange={(e) => {
                setfilters((prev) => ({
                  ...prev,
                  status: e,
                }));
              }}
            />
          </div>
        </TableFilterBox>
        <div>
          <ContentValidation
            loader={loading}
            array={data}
            alertId_s="ascAlert-s"
            alertId_e="ascAlert-e"
          >
            <div className="table-responsive">
              <table className="table table-sm table-hover mt-3">
                <thead>
                  <tr className="text-muted">
                    {[
                      "Dokumento data",
                      "Pristatyta",
                      "Padalinys",
                      "Dok. nr.",
                      "Tipas",
                      "Tiekėjas",
                      "P/N",
                      "Būsena",
                    ].map((title) => (
                      <th key={title || "button"} className="text-black">
                        {title}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((e) => {
                    return (
                      <tr className="align-middle tableColumn" key={e.id}>
                        <td className="align-middle" style={{ maxWidth: 400 }}>
                          {moment(e.operation_date).format("YYYY-MM-DD")}
                        </td>
                        <td className="align-middle">
                          {!e.delivery_at
                            ? "-"
                            : moment(e.delivery_at).format("YYYY-MM-DD")}
                        </td>
                        <td className="align-middle">{e.department}</td>
                        <td className="align-middle">
                          <EditElementClick onEdit={() => setShowModal(e.id)} name={e.document_number} />
                        </td>

                        <td className="align-middle">{e.type_text}</td>
                        <td className="align-middle">
                          {e.supplier}
                          {e.supplier_code && (
                            <span className="ml-1">
                              (
                              <span className="text-orange mx-1">
                                {e.supplier_code}
                              </span>
                              )
                            </span>
                          )}
                        </td>

                        <td className="align-middle">
                          <Switch
                            checked={e.pn}
                            checkedChildren={"|"}
                            unCheckedChildren={"0"}
                          />
                          {/* {e.pn ? (
                            <div className="d-flex align-items-center">
                              <GoPrimitiveDot className="text-success" />
                              Taip
                            </div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <GoPrimitiveDot className="text-danger" />
                              Ne
                            </div>
                          )} */}
                        </td>
                        <td className="align-middle">{statuses[e.status]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <PaginationComp
                setPagination={setPage}
                page={page}
                totals={totals}
                pageCount={pageCount}
              />
            </div>
          </ContentValidation>
        </div>
      </ContentBlock>
      {showModal && (
        <ModalPreview
          closeModal={() => setShowModal(false)}
          showModal={showModal}
          updateIndex={(response) => {
            setData(data.map((e) => (response.id == showModal ? response : e)));
          }}
        />
      )}
    </Container>
  );
}

const ModalPreview = ({ closeModal, showModal, updateIndex }) => {
  const [data, setData] = useState({});
  const [openedTabs, setOpenedTabs] = useState({
    goods: true,
    comments: true,
    states: true,
  });
  const fetchPurchaseById = async () => {
    try {
      const { data: response } = await Axios.get(
        `/api/v1/purchases/${showModal}`
      );
      if (!response || !response?.success) throw new Error("err");
      let res = response.data;
      if (data.purchase_items) {
        if (res.purchase_items.length <= data.purchase_items.length) {
          res.purchase_items = data.purchase_items;
        }
      }
      setData(res);
    } catch (err) {
      console.log("Error: ", err);
    }
  };
  useEffect(() => {
    fetchPurchaseById();
  }, [showModal]);
  if (Object.entries(data).length == 0) return <div>Loading...</div>;

  const send_to_warehouse = async () => {
    try {
      const { data: response } = await Axios.post(
        `/api/v1/purchases/${showModal}/send_to_warehouse`,
        {
          purchase: {
            purchase_items_attributes: data.purchase_items,
          },
        }
      );
      if (!response.success) throw new Error("Err");
      if (response.data?.status == data.status) {
        throw new Error("Err");
      }
      setData(response.data);
      updateIndex(response.data);
      message.success("Sėkmingai išsiųsta");
    } catch (err) {
      console.log("Error: ", err);
      message.error("Įvyko klaida");
    }
  };

  const renderTopInfo = (
    <div
      className="d-flex justify-content-between align-items-center w-100"
      style={{ borderRight: "2px solid #EDEDED" }}
    >
      <div className="d-flex align-items-center">
        <span className="text-black mr-2">
          <span className="text-orange">{data.document_number} </span>Tiekėjas:
          {data.supplier}
        </span>
      </div>
      <div>
        {data.id && (
          <button
            className="btn btn-sm btn-primary mr-2"
            style={{
              border: "none",
              padding: "6px 12px",
            }}
            onClick={() =>
              exportDocument(
                `/api/v1/purchases/${data.id}/download_purchase_sheet`,
                `purchase_${data.id}.xlsx`,
                "POST"
              )
            }
          >
            Ataskaita
          </button>
        )}
        {data.status == "saved" && (
          <button
            className="btn btn-sm btn-primary mr-2"
            style={{
              background: "#343A40",
              border: "none",
              padding: "6px 12px",
            }}
            onClick={() => send_to_warehouse()}
          >
            Siųsti į sandėlį
          </button>
        )}
      </div>
    </div>
  );

  const purchaseItemsUpdate = (item_id, serial_control) => {
    setData((prev) => ({
      ...prev,
      purchase_items: prev.purchase_items.map((e) =>
        e.id == item_id ? { ...e, serial_control } : e
      ),
    }));
  };
  return (
    <ModalSpecial
      text={renderTopInfo}
      handleSave={() => {}}
      onClose={() => closeModal()}
    >
      <Divs>
        <MainBlockWithContext
          text="Prekės"
          color={"#F7951E"}
          action={openedTabs.goods}
          onChange={() =>
            setOpenedTabs({ ...openedTabs, goods: !openedTabs.goods })
          }
        >
          {openedTabs.goods && (
            <TableUi
              ths={[
                "Prekės kodas",
                "Prekės pavadinimas",
                "MPN",
                "Kiekis",
                "SN",
                "",
                // "Klaidų pranešimai",
              ]}
            >
              {data.purchase_items?.map((e) => {
                return (
                  <TableUi.tr key={e.id}>
                    <TableUi.td className="p-left">{e.sku}</TableUi.td>
                    <TableUi.td>{e.product_name}</TableUi.td>
                    <TableUi.td>{e.title}</TableUi.td>
                    <TableUi.td>
                      {Number(e.quantity)}
                      <span
                        className={
                          Number(e.quantity) !== Number(e.received_quantity)
                            ? "text-danger ml-1"
                            : "ml-1"
                        }
                      >
                        ({Number(e.received_quantity)})
                      </span>
                    </TableUi.td>
                    <TableUi.td>{e.serial_numbers.join(", ")}</TableUi.td>
                    <TableUi.td>
                      <CheckBoxInput
                        disabled={data.status !== "saved"}
                        checked={e.serial_control}
                        onChange={() =>
                          purchaseItemsUpdate(e.id, !e.serial_control)
                        }
                      />
                    </TableUi.td>
                    {/* <TableUi.td>
                    {e.api_errors && (
                      <Fragment>
                        <GoPrimitiveDot className="text-danger mr-1" />
                        {e.api_errors}
                      </Fragment>
                    )}
                  </TableUi.td> */}
                  </TableUi.tr>
                );
              })}
            </TableUi>
          )}
        </MainBlockWithContext>
      </Divs>

      <Divs>
        <MainBlockWithContext
          text="Būsenos"
          color={"#BD00FF"}
          action={openedTabs.states}
          onChange={() =>
            setOpenedTabs({ ...openedTabs, states: !openedTabs.states })
          }
        >
          {openedTabs.states && (
            <>
              {data.purchase_states.length > 0 ? (
                <TableUi ths={["Būsena", "Data"]}>
                  {data.purchase_states
                    ?.sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
                    .map((e) => {
                      return (
                        <TableUi.tr
                          key={e.id}
                          key={e.id}
                          style={{
                            borderLeft: `3px solid ${renderByColor(
                              e.mscan_state_code
                            )}`,
                          }}
                        >
                          <TableUi.td className="p-left">
                            {e.state_text}
                          </TableUi.td>
                          <TableUi.td>
                            {prettyDateFormat(e.created_at)}
                          </TableUi.td>
                        </TableUi.tr>
                      );
                    })}
                </TableUi>
              ) : (
                <div className="col-12 font-weight-bold">Nėra būsenų.</div>
              )}
            </>
          )}
        </MainBlockWithContext>
      </Divs>

      <Divs className="bg-silver">
        <MainBlockWithContext
          text="Komentarai"
          color={"#06C76D"}
          action={openedTabs.comments}
          onChange={() =>
            setOpenedTabs({ ...openedTabs, comments: !openedTabs.comments })
          }
        >
          {openedTabs.comments && (
            <div className="col-12 mt-4">
              <div className="management">
                {data.purchase_comments?.map((e) => (
                  <div className="d-flex mb-2" key={e.id}>
                    <div className="block_one flex-column d-flex justify-content-center">
                      <div className="name text-center font-weight-bold">
                        {e.user_title}
                      </div>
                      <div className="time text-center">
                        {moment(e.created_at).format("YYYY MM DD")}
                        <br />
                        {moment(e.created_at).format("HH:mm")}
                      </div>
                    </div>
                    <div className="block_two_main">
                      <div className="block_two p-2">
                        <p
                          className="description"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          {e.body}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <SubmitComment
                id={showModal}
                fetchPurchaseById={fetchPurchaseById}
              />
            </div>
          )}
        </MainBlockWithContext>
      </Divs>
    </ModalSpecial>
  );
};

const SubmitComment = (props) => {
  const [addComment, setAddComment] = useState(false);
  const [InputValue, setInput] = useState("");
  const [ErrorText, setErrorText] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);

    if (InputValue == "") {
      setLoading(false);
      return setErrorText("Įrašykite komentarą");
    }

    let variablesBody = {
      purchase_comment: {
        body: InputValue,
      },
    };
    try {
      const { data } = await Axios.post(
        `/api/v1/purchases/${props.id}/add_comment`,
        variablesBody
      );
      setLoading(false);
      if (data.success) {
        setInput("");
        props.fetchPurchaseById();
      }
    } catch (err) {
      console.log("Error: ", err);
      setErrorText("Įvyko klaida");
      setLoading(false);
    }
  };

  return (
    <>
      {addComment && (
        <>
          <TextArea
            className="mb-2"
            placeholder="Komentaras"
            value={InputValue}
            onChange={(e) => setInput(e.target.value)}
          />
          <div className="d-flex justify-content-between">
            <div className="text-danger">{ErrorText}</div>

            <FlopppyButton loading={loading} onClick={() => onSubmit()} />
          </div>
        </>
      )}

      <div className="py-4">
        <div style={{ position: "relative" }}>
          <Button1
            style={{
              position: "absolute",
              top: "-16px",
              left: "50%",
              transform: "translate(-50%, 0px)",
            }}
            text={addComment ? "Atšaukti" : "Pridėti naują komentarą"}
            option={addComment ? "minus" : "plus"}
            onClick={() => setAddComment(!addComment)}
          />
          <hr />
        </div>
      </div>
    </>
  );
};
