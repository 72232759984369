import {
  SET_RIVILE_BIG_BOX_DATA,
  SET_RIVILE_BIG_BOX,
  LOADING_RIVILE_BIG_BOX,
  LOADING_RIVILE_BIG_BOX_DATA,
} from "../../Actions/types";

const initialState = {
  bigboxs: [],
  bigbox: [],
  totals: 0,
  show_id: { id: 0, created_at: new Date() },
  loading: false,
  loading_show: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_RIVILE_BIG_BOX_DATA:
      return {
        ...state,
        loading: true,
      };
    case LOADING_RIVILE_BIG_BOX:
      return {
        ...state,
        loading_show: true,
        bigbox: [],
      };
    case SET_RIVILE_BIG_BOX_DATA:
      return {
        ...state,
        bigboxs: action.payload.data,
        totals: action.payload.totals,
        loading: false,
      };
    case SET_RIVILE_BIG_BOX:
      return {
        ...state,
        bigbox: action.payload.data,
        show_id: action.payload.show_id,
        loading_show: false,
      };
    case "UPDATE_RIVILE_BIG_BOX":
      return {
        ...state,
        bigboxs: state.bigboxs.map((d) =>
          d.id == action.payload.id ? { ...d, ...action.payload } : d
        ),
      };
    case "REMOVE_RIVILE_BB": {
      return {
        ...state,
        bigbox: [...state.bigbox].filter((e) => e.id !== action.payload),
      };
    }
    default:
      return state;
  }
}
