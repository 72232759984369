import React from "react";
import styled from "styled-components";
import upload from "../../../assets/images/uploadOrange.svg";

export const AttachButton = ({ text }) => {
  return (
    <AttachButtonStyle>
      <img src={upload} width="12" />
      {text || "Įkelti dokumentą"}
    </AttachButtonStyle>
  );
};

const AttachButtonStyle = styled.span`
  border-bottom: 1px solid #f29302;
  color: #f29302;
  font-size: 12px;
  padding-bottom: 3px;
  cursor: pointer;
  & > img {
    margin-right: 6px;
  }
`;

export const RadiusCheckBox = ({ checked, onChange }) => {
  return (
    <RadiusCheckBoxStyle>
      <label className="container_check">
        <input
          type="radio"
          name="radio"
          checked={checked}
          onChange={onChange}
        />
        <span className="checkmark"></span>
      </label>
    </RadiusCheckBoxStyle>
  );
};

const RadiusCheckBoxStyle = styled.div`
  .container_check {
    display: block;
    position: relative;
    /* padding-left: 20px;
    margin-bottom: 6px; */
    cursor: pointer;
    user-select: none;
  }
  .container_check input {
    position: absolute;
    opacity: 0;
    width: 1px;
    cursor: pointer;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #eee;
  }
  .container_check:hover input ~ .checkmark {
    background-color: #eee;
  }
  .container_check input:checked ~ .checkmark {
    background-color: rgb(247, 147, 30);
  }
`;

export const LabelTextStyle = styled.h6`
  margin: 20px 0 5px;
  font-size: 16px;
  font-weight: bold;
`;

export const UlElements = styled.ul`
  margin: 0;
  list-style-type: none;
  padding: 0 1px;
  li {
    font-size: 15px;
    color: #000000;
    margin-bottom: 5px;
    word-wrap: break-word;
  }
`;

export const Divs = styled.div`
  display: block;
  color: #000000;
  font-size: 15px;
  font-weight: 400;
  & .text-bold {
    font-size: 14px;
    line-height: 20px;
    color: #030303;
    font-weight: 600;
  }

  & .residuesItem {
    font-size: 0.8rem;
    word-wrap: break-word;
  }

  & .orange_color {
    color: #f7951e;
  }

  & .addDoc {
    margin-top: 5px;
    color: #f7951e;
    &:hover {
      color: #bd6903;
      border-bottom: 1px solid #f7951e;
      cursor: pointer;
    }
  }
  & .input_text {
    right: 7%;
    top: 50%;
    font-weight: bold;
    text-transform: uppercase;
    transform: translate(-50%, -50%);
    z-index: 100;
  }
`;

export const Bubble = ({ children, up, active, onClick, width = 11 }) => {
  if (up) {
    return (
      <span className="position-relative">
        <BubbleStyled
          className="d-flex align-items-center justify-content-center font-semi-bold rounded-circle position-absolute"
          active={active}
          width={width}
        >
          <span>{children}</span>
        </BubbleStyled>
      </span>
    );
  }
  return (
    <BubbleStyledDiv
      className="font-semi-bold rounded-circle cursor-pointer"
      active={active}
      onClick={onClick}
    >
      <span>{children}</span>
    </BubbleStyledDiv>
  );
};

const BubbleStyled = styled.span`
  font-size: 7px;
  top: -4px;
  right: -10px;
  background-color: ${(props) => (props.active ? "#D4E8FB" : "#F29302")};
  color: ${(props) => (props.active ? "#007bff" : "#fff")};
  border: 1px solid #f29302;
  border-radius: 50%;
  min-width: ${(props) => `${props.width}px`};
  min-height: ${(props) => `${props.width}px`};
`;

const BubbleStyledDiv = styled.div`
  background-color: ${(props) => (props.active ? "#D4E8FB" : "#F29302")};
  color: ${(props) => (props.active ? "#007bff" : "#fff")};
  border: 1px solid #f29302;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RoundedBubble = ({ primary = true, children }) => (
  <RoundedBubbleStyled className="text-xs mr-2" primary={primary}>
    {children}
  </RoundedBubbleStyled>
);

const RoundedBubbleStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.primary ? "#F29302" : "#FEF5E8")};
  border: 1px solid #f29302;
  color: ${(props) => (props.primary ? "#FFFFFF" : "#212121")};
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;
