import {
  ADD_STATUS_JOB,
  GET_STATUS,
  GET_STATUSES,
  LOADING_STATUSES,
  UPDATE_STATUS_JOB,
  CHANDE_STATUS_JOB,
  REMOVE_STATUS_JOB,
} from "../Actions/types";

const initialState = {
  statuses: [],
  status_jobs: [],
  totals: 0,
  loading_statuses: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_STATUSES:
      return {
        ...state,
        loading_statuses: true,
      };
    case GET_STATUSES:
      return {
        ...state,
        statuses: action.payload.data,
        totals: action.payload.totals,
        loading_statuses: false,
      };
    case GET_STATUS:
      return {
        ...state,
        status_jobs: action.payload,
      };
    case ADD_STATUS_JOB:
      return {
        ...state,
        status_jobs: [...state.status_jobs, action.payload],
      };
    case CHANDE_STATUS_JOB:
      return {
        ...state,
        status_jobs: action.payload,
      };
    case UPDATE_STATUS_JOB:
      let status_job_update = [...state.status_jobs];
      status_job_update[action.payload.index] = action.payload.data;
      return {
        ...state,
        status_jobs: status_job_update,
      };
    case REMOVE_STATUS_JOB:
      return {
        ...state,
        status_jobs: [...state.status_jobs].filter(
          (filt) => filt.id !== action.payload
        ),
      };
    default:
      return state;
  }
}
