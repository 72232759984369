export const GET_TABLEDATA = "GET_TABLEDATA";
export const LOADING_TABLEDATA = "LOADING_TABLEDATA";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const GET_SMSITEM = "GET_SMSITEM";
export const GET_SMSITEMS = "GET_SMSITEMS";
export const POST_SMSITEM = "POST_SMSITEM";
export const DELETE_SMSITEM = "DELETE_SMSITEM";
export const UPDATE_SMSITEM = "UPDATE_SMSITEM";
export const LOADING_SMS = "LOADING_SMS";

export const GET_STORE = "GET_STORE";
export const GET_STORES = "GET_STORES";
export const POST_STORE = "POST_STORE";
export const DELETE_STORE = "DELETE_STORE";
export const UPDATE_STORE = "UPDATE_STORE";
export const LOADING_STORE = "LOADING_STORE";

export const GET_SUPPLIER = "GET_SUPPLIER";
export const GET_SUPPLIERS = "GET_SUPPLIERS";
export const POST_SUPPLIER = "POST_SUPPLIER";
export const DELETE_SUPPLIER = "DELETE_SUPPLIER";
export const UPDATE_SUPPLIER = "UPDATE_SUPPLIER";
export const LOADING_SUPPLIER = "LOADING_SUPPLIER";

export const GET_COUNTRY = "GET_COUNTRY";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const POST_COUNTRY = "POST_COUNTRY";
export const DELETE_COUNTRY = "DELETE_COUNTRY";
export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const LOADING_COUNTRY = "LOADING_COUNTRY";

export const GET_PICKUPADDRESS = "GET_PICKUPADDRESS";
export const GET_PICKUPADDRESSES = "GET_PICKUPADDRESSES";
export const POST_PICKUPADDRESS = "POST_PICKUPADDRESS";
export const DELETE_PICKUPADDRESS = "DELETE_PICKUPADDRESS";
export const UPDATE_PICKUPADDRESS = "UPDATE_PICKUPADDRESS";
export const LOADING_PICKUPADDRESS = "LOADING_PICKUPADDRESS";
export const GET_COUNTRIESOPTION = "GET_COUNTRIESOPTION";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const LOADING_CATEGORY = "LOADING_CATEGORY";

export const GET_USERS = "GET_USERS";
export const POST_USER = "POST_USER";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const LOADING_USER = "LOADING_USER";

export const GET_USERS_PICKUP = "GET_USERS_PICKUP";
export const POST_USER_PICKUP = "POST_USER_PICKUP";
export const DELETE_USER_PICKUP = "DELETE_USER_PICKUP";
export const UPDATE_USER_PICKUP = "UPDATE_USER_PICKUP";
export const LOADING_USER_PICKUP = "LOADING_USER_PICKUP";

export const POST_ORDER_SHIPPING = "POST_ORDER_SHIPPING";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const UPDATE_ORDER_ALL = "UPDATE_ORDER_ALL";
export const UPDATE_ORDER_SOCKETS = "UPDATE_ORDER_SOCKETS";
export const GET_ORDER = "GET_ORDER";
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_AMOUNT = "GET_ORDERS_AMOUNT";
export const GET_ACTIVE_NOT_READY_ORDERS = "GET_ACTIVE_NOT_READY_ORDERS";
export const UPDATE_SUPPLYORDERS = "UPDATE_SUPPLYORDERS";
export const POST_TO_RIVILE = "POST_TO_RIVILE";
export const UPDATE_SERIAL_NUMBER_STATE = "UPDATE_SERIAL_NUMBER_STATE";
export const LOADING_ORDERS = "LOADING_ORDERS";
export const LOADING_ORDER = "LOADING_ORDER";

export const GET_PRODUCTS = "GET_PRODUCTS";
export const LOADING_PRODUCTS = "LOADING_PRODUCTS";

export const GET_COMMENTSSTATE = "GET_COMMENTSSTATE";
export const POST_COMMENTSSTATE = "POST_COMMENTSSTATE";
export const DELETE_COMMENTSSTATE = "DELETE_COMMENTSSTATE";
export const UPDATE_COMMENTSSTATE = "UPDATE_COMMENTSSTATE";
export const LOADING_COMMENTSSTATE = "LOADING_COMMENTSSTATE";

export const GET_COMMENTS = "GET_COMMENTS";
export const POST_COMMENTS = "POST_COMMENTS";
export const DELETE_COMMENTS = "DELETE_COMMENTS";
export const UPDATE_COMMENTS = "UPDATE_COMMENTS";
export const LOADING_COMMENTS = "LOADING_COMMENTS";

export const POST_COMMENTS_TO_ORDERS = "POST_COMMENTS_TO_ORDERS";

export const ORDER_CREATE_INVOICE = "ORDER_CREATE_INVOICE";

export const GET_DOCUMENT_TYPE = "GET_DOCUMENT_TYPE";
export const POST_DOCUMENT_TYPE = "POST_DOCUMENT_TYPE";
export const UPDATE_DOCUMENT_TYPE = "UPDATE_DOCUMENT_TYPE";
export const DELETE_DOCUMENT_TYPE = "DELETE_DOCUMENT_TYPE";
export const LOADING_DOCUMENT_TYPE = "LOADING_DOCUMENT_TYPE";

export const POST_DOCUMENT_PLAN = "POST_DOCUMENT_PLAN";
export const UPDATE_DOCUMENT_PLAN = "UPDATE_DOCUMENT_PLAN";
export const DELETE_DOCUMENT_PLAN = "DELETE_DOCUMENT_PLAN";
export const LOADING_DOCUMENT_PLAN = "LOADING_DOCUMENT_PLAN";

// Order Row
export const POST_ORDER_ROW = "POST_ORDER_ROW";
export const DELETE_ORDER_ROW = "DELETE_ORDER_ROW";
export const POST_ORDER_ROW_GOODS = "POST_ORDER_ROW_GOODS";
export const UPDATE_ORDER_ROW_GOODS = "UPDATE_ORDER_ROW_GOODS";
export const DELETE_ORDER_ROW_GOODS = "DELETE_ORDER_ROW_GOODS";

// Payment
export const POST_PAYMENT_ORDER = "POST_PAYMENT_ORDER"
export const EDIT_PAYMENT_ORDER = "EDIT_PAYMENT_ORDER"
export const UPDATE_PAYMENT_ORDER = "UPDATE_PAYMENT_ORDER"
export const DELETE_PAYMENT_ORDER = "DELETE_PAYMENT_ORDER"

// Contracts
export const ADD_CONTRACT = "ADD_CONTRACT";
export const DELETE_CONTRACT = "DELETE_CONTRACT";
export const DELETE_CONTRACT_ITEM = "DELETE_CONTRACT_ITEM";
export const UPDATE_CONTRACT = "UPDATE_CONTRACT";
export const ADD_CONTRACT_ITEM = "ADD_CONTRACT_ITEM";
export const UPDATE_CONTRACT_ITEM = "UPDATE_CONTRACT_ITEM";

export const ADD_ORDER_DOCUMENT = "ADD_ORDER_DOCUMENT";
export const UPDATE_ORDER_DOCUMENT = "UPDATE_ORDER_DOCUMENT";
export const DELETE_ORDER_DOCUMENT = "DELETE_ORDER_DOCUMENT";

export const CREATE_SMS_ITEM = "CREATE_SMS_ITEM";

export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_DOCUMENT = "GET_DOCUMENT";
export const LOADING_DOCUMENTS = "LOADING_DOCUMENTS";


export const GET_SHIPMENT_DPD = "GET_SHIPMENT_DPD";
export const GET_SHIPMENT_OMNIVA = "GET_SHIPMENT_OMNIVA";
export const GET_SHIPMENT_LP_EXPRESS = "GET_SHIPMENT_LP_EXPRESS";
export const LOADING_SHIPMENT_DPD = "LOADING_SHIPMENT_DPD";
export const LOADING_SHIPMENT_OMNIVA = "LOADING_SHIPMENT_OMNIVA";
export const LOADING_SHIPMENT_LP_EXPRESS = "LOADING_SHIPMENT_LP_EXPRESS";
export const GET_SHIPMENT_OWN_TRANSPORT = "GET_SHIPMENT_OWN_TRANSPORT";

export const GET_MANIFEST_DPD = "GET_MANIFEST_DPD";
export const GET_MANIFEST_OMNIVA = "GET_MANIFEST_OMNIVA";
export const GET_MANIFEST_LP_EXPRESS = "GET_MANIFEST_LP_EXPRESS";
export const LOADING_MANIFEST_DPD = "LOADING_MANIFEST_DPD";
export const LOADING_MANIFEST_OMNIVA = "LOADING_MANIFEST_OMNIVA";
export const LOADING_MANIFEST_LP_EXPRESS = "LOADING_MANIFEST_LP_EXPRESS";

export const CREATE_ORDER_STATUS = "CREATE_ORDER_STATUS";

export const FIRST_ADD_CONTRACT = "FIRST_ADD_CONTRACT";
export const FIRST_DELETE_CONTRACT = "FIRST_DELETE_CONTRACT";
export const CHANGE_CONTRACT_ITEMS = "CHANGE_CONTRACT_ITEMS";

export const UPDATE_CONTRACT_ITEM_TEST = "UPDATE_CONTRACT_ITEM_TEST";
export const SEND_DOCUMENT_EMAIL = "SEND_DOCUMENT_EMAIL";

export const ADD_CONTRACT_DOCUMENT = "ADD_CONTRACT_DOCUMENT";
export const UPDATE_CONTRACT_ITEM_DOCUMENT = "UPDATE_CONTRACT_ITEM_DOCUMENT";

export const GET_LOTTERY_PLAYERS = "GET_LOTTERY_PLAYERS";
export const DELETE_LOTTERY_PLAYERS = "DELETE_LOTTERY_PLAYERS";

export const GET_LOTTERY_TICKETS = "GET_LOTTERY_TICKETS";
export const CLAIMED_LOTTERY = "CLAIMED_LOTTERY";
export const POST_LOTTERY_TICKETS = "POST_LOTTERY_TICKETS";
export const PUT_LOTTERY_TICKETS = "PUT_LOTTERY_TICKETS";
export const DELETE_LOTTERY_TICKETS = "DELETE_LOTTERY_TICKETS";
export const LOADING_LOTTERY_TICKETS = "LOADING_LOTTERY_TICKETS";

export const REMOVE_SHIPMENT = "REMOVE_SHIPMENT";
export const CANCEL_DOCUMENT = "CANCEL_DOCUMENT";

export const UPDATE_SERIAL_NUMBER = "UPDATE_SERIAL_NUMBER";

// Rivile

export const GET_RIVILE_SERVICE = "GET_RIVILE_SERVICE";
export const UPDATE_RIVILE_SERVICE = "UPDATE_RIVILE_SERVICE";
export const LOADING_RIVILE_SERVICE = "LOADING_RIVILE_SERVICE";
export const DELETE_RIVILE_SERVICE = "DELETE_RIVILE_SERVICE";
export const POST_RIVILE_SERVICE = "POST_RIVILE_SERVICE";

export const GET_RIVILE_STORE = "GET_RIVILE_STORE";
export const UPDATE_RIVILE_STORE = "UPDATE_RIVILE_STORE";
export const LOADING_RIVILE_STORE = "LOADING_RIVILE_STORE";



// Rivile BigBox

export const SET_RIVILE_BIG_BOX_DATA = "SET_RIVILE_BIG_BOX_DATA"
export const SET_RIVILE_BIG_BOX = "SET_RIVILE_BIG_BOX"
export const SET_RIVILE_BIG_BOX_COMMENTS = "SET_RIVILE_BIG_BOX_COMMENTS"
export const LOADING_RIVILE_BIG_BOX = "LOADING_RIVILE_BIG_BOX"
export const LOADING_RIVILE_BIG_BOX_DATA = "LOADING_RIVILE_BIG_BOX_DATA"



// Shop categories

export const GET_SHOP_CATEGORIES = "GET_SHOP_CATEGORIES";
export const UPDATE_SHOP_CATEGORIES = "UPDATE_SHOP_CATEGORIES";
export const LOADING_SHOP_CATEGORIES = "LOADING_SHOP_CATEGORIES";
// Statuses
export const ADD_STATUS_JOB = "ADD_STATUS_JOB";
export const UPDATE_STATUS_JOB = "UPDATE_STATUS_JOB";
export const CHANDE_STATUS_JOB = "CHANDE_STATUS_JOB";
export const REMOVE_STATUS_JOB = "REMOVE_STATUS_JOB";
export const GET_STATUSES = "GET_STATUSES";
export const GET_STATUS = "GET_STATUS";
export const LOADING_STATUSES = "LOADING_STATUSES";

// Payment types
export const GET_PAYMENT_TYPES = "GET_PAYMENT_TYPES";
export const PUT_PAYMENT_TYPES = "PUT_PAYMENT_TYPES";
export const LOADING_PAYMENT_TYPES = "LOADING_PAYMENT_TYPES";

// Rivile
export const GET_RIVILE_ITEMS = "GET_RIVILE_ITEMS";
export const GET_RIVILE_ITEM_TYPES = "GET_RIVILE_ITEM_TYPES";
export const LOADING_RIVILE_ITEMS = "LOADING_RIVILE_ITEMS";
export const GET_RIVILE_ITEM = "GET_RIVILE_ITEM";
export const EDIT_RIVILE_ITEM = "EDIT_RIVILE_ITEM";
export const EDIT_RIVILE_INVOICE = "EDIT_RIVILE_INVOICE";
export const GET_RIVILE_ITEMS_SALES = "GET_RIVILE_ITEMS_SALES";
export const CHANGE_STATUS = "CHANGE_STATUS";
export const POST_COMMENT = "POST_COMMENT";
export const CHANGE_STATUS_TO_EXPORTED = "CHANGE_STATUS_TO_EXPORTED";
export const GET_RIVILE_STORES = "GET_RIVILE_STORES";
export const CHANGE_STATUS_MISMATCHED = "CHANGE_STATUS_MISMATCHED";

// Pay Module
export const GET_MANAGERS = "GET_MANAGERS";
export const EDIT_MANAGER = "EDIT_MANAGER";
export const LOADING_MANAGERS = "LOADING_MANAGERS";
export const SHOW_MANAGER = "SHOW_MANAGER";

// Presta services
export const GET_PRESTA_SERVICES = "GET_PRESTA_SERVICES";
export const LOADING_PRESTA_SERVICES = "LOADING_PRESTA_SERVICES";
export const POST_SERVICE_ITEM = "POST_SERVICE_ITEM";
export const UPDATE_SERVICE_ITEM = "UPDATE_SERVICE_ITEM";
export const DELETE_SERVICE_ITEM = "DELETE_SERVICE_ITEM";

// 
export const UPDATE_CART_RULES = "UPDATE_CART_RULES";


// Service REPAIR
export const SET_LOADING_SERVICE = "SET_LOADING_SERVICE";
export const GET_SERVICE = "GET_SERVICE";
export const GET_SERVICE_SHOW = "GET_SERVICE_SHOW";
export const SET_LOADING_SERVICE_SHOW = "SET_LOADING_SERVICE_SHOW";
export const SET_SERVICE = "SET_SERVICE";
export const UPDATE_SERVICE_ITEM_SHOW = "UPDATE_SERVICE_ITEM_SHOW";
export const SERVICE_REPAIR_ADDRESS_UPDATE = "SERVICE_REPAIR_ADDRESS_UPDATE";

// Waiting payments
export const CREATE_WAITING_PAYMENT = "CREATE_WAITING_PAYMENT";
export const GET_WAITING_PAYMENTS = "GET_WAITING_PAYMENTS";

// Suppliers invoices
export const LOADING_SUPP_INVOICES = "LOADING_SUPP_INVOICES";
export const GET_SUPP_INVOICES = "GET_SUPP_INVOICES";
export const SHOW_SUPP_INVOICE = "SHOW_SUPP_INVOICE";
export const UPDATE_INVOICE_STATUS = "UPDATE_INVOICE_STATUS"; 
export const DELETE_SUPP_INVOICE = "DELETE_SUPP_INVOICE";
export const EDIT_SUPP_INVOICE = "EDIT_SUPP_INVOICE";

//Product brands
export const LOADING_BRANDS = "LOADING_BRANDS";
export const GET_BRANDS = "GET_BRANDS";
export const POST_BRAND = "POST_BRAND";
export const UPDATE_BRAND = "UPDATE_BRAND";

//Product families
export const LOADING_FAMILIES = "LOADING_FAMILIES";
export const LOADING_FAMILY = "LOADING_FAMILY";
export const GET_FAMILIES = "GET_FAMILIES";
export const UPDATE_FAMILY = "UPDATE_FAMILY";
export const GET_FAMILY = "GET_FAMILY";
export const UPDATE_MARKING = "UPDATE_MARKING";

//Leasings
export const LOADING_LEASINGS = "LOADING_LEASINGS";
export const LOADING_LEASING = "LOADING_LEASING";
export const GET_LEASINGS = "GET_LEASINGS";
export const UPDATE_LEASING = "UPDATE_LEASING";
export const CREATE_LEASING = "CREATE_LEASING";
export const GET_LEASING = "GET_LEASING";
export const DELETE_LEASING = "DELETE_LEASING";

//Product descriptions
export const LOADING_DESCRIPTIONS = "LOADING_DESCRIPTIONS";
export const LOADING_DESCRIPTION = "LOADING_DESCRIPTION";
export const GET_DESCRIPTIONS = "GET_DESCRIPTIONS";
export const GET_DESCRIPTION = "GET_DESCRIPTION";

//Repricings
export const LOADING_REPRICINGS = "LOADING_REPRICINGS";
export const LOADING_REPRICING = "LOADING_REPRICING";
export const GET_REPRICINGS = "GET_REPRICINGS";
export const GET_REPRICING = "GET_REPRICING";
