import Axios from "axios";
import { errorHandler } from "../../helpers/Utils";

export const getFamiliesOptions = async () => {
  try {
    const { data } = await Axios.get("/api/v1/families/all_families");
    return data.data.map((d) => ({
      value: d.id,
      label: d.name,
    }));
  } catch (err) {
    errorHandler(err);
    return [];
  }
};
