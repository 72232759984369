import React from "react";
import {
  ButtonEdit,
  ButtonDelete,
  EditElementClick,
} from "../../common/Buttons";
import { GoPrimitiveDot } from "react-icons/go";

function TableColumnSms({ item, onDelete, index, onAction }) {
  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle">{item.id}</td>
      <td className="align-middle">
        {" "}
        <EditElementClick
          onEdit={() => onAction("edit", index)}
          name={item.name}
        />
      </td>
      <td className="align-middle">{item.address}</td>
      <td className="align-middle">
        {item.rivile_code}
        {!!item.report_type && ` / ${item.report_type}`}
      </td>
      <td className="align-middle">
        {" "}
        <GoPrimitiveDot
          className={item.bigbox_import ? "text-success" : "text-danger"}
        />
      </td>
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonEdit onEdit={() => onAction("edit", index)} />
      </td>
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonDelete onDelete={() => onDelete(item.id)} />
      </td>
    </tr>
  );
}

export default TableColumnSms;
