import React from "react";

function BalancesIndex(props) {
  return (
    <>
      {props.item.product_info.hasOwnProperty("product_suppliers") &&
        props.item.product_info.product_suppliers.map((item, index) => {
          if (item.quantity > 0) {

            return (
              <tr key={index}>
                <td className="residuesItem">{item.quantity} * </td>
                <td className="residuesItem">{props.item.product_reference}</td>
              </tr>
            );
          }
        })}
    </>
  );
}

export default BalancesIndex;
