import React, { Fragment, useState, useEffect } from "react";
import { Select, Spin } from "antd";
import Axios from "axios";
import useDebounce from "../../hooks/useDebounce";
import { AiOutlinePlusCircle } from "react-icons/ai";
const { Option } = Select;

function AutoFillComponent({
  mode = "default",
  onChange,
  endPoint,
  arrayBuilder,
  label,
  placeholder = "Pasirinkti",
  defaultValue = "",
  disabled = false,
}) {
  const [searchValue, setSearchValue] = useState("");
  const [value, setValue] = useState(undefined);
  useDebounce(() => fetchAddress(searchValue, true), 1200, [searchValue]);

  const [array, setArray] = useState([]);
  const [Loading, setLoading] = useState(false);
  useEffect(() => {
    if (!defaultValue) return;
    if (value) return;
    Axios.get(`${endPoint("")}`).then((response) => {
      if (response.data) {
        const data = arrayBuilder(response.data.data);
        const findItem = data.find((d) => d.value == defaultValue);
        if (findItem) {
          setValue(findItem);
        }
      }
    });
  }, [defaultValue]);

  const fetchAddress = (res, fetch = false) => {
    if (!fetch) return;
    if (res && res?.length >= 3) {
      setLoading(true);
      setArray([]);
      Axios.get(`${endPoint(res)}`)
        .then((response) => {
          if (response.data) {
            const data = arrayBuilder(response.data.data);
            setArray(data);
            // setLoading(false);
            // setValue(undefined)
          }
        })
        .catch((d) => {
          setArray([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const setter = (a, b) => {
    if (mode == "default") {
      onChange(a, b);
      setValue(undefined);
    } else {
      setValue(b);
    }
    setSearchValue("");
  };
  return (
    <Fragment>
      {label && <label className={"text-bold my-1"}>{label}</label>}
      <div className="position-relative">
        <Select
          disabled={disabled}
          showSearch
          value={value?.label || undefined}
          placeholder={placeholder}
          dropdownMatchSelectWidth={false}
          notFoundContent={
            Loading ? (
              <div className="d-flex justify-content-center">
                <Spin size="small" />
              </div>
            ) : null
          }
          filterOption={false}
          onSearch={(val) => {
            setSearchValue(val);
          }}
          onChange={(a, b) => {
            setter(a, b);
          }}
          style={{ width: "100%" }}
        >
          {array &&
            array.map((d, idx) => (
              <Option key={idx} value={d.value} {...d}>
                {d.label}
              </Option>
            ))}
        </Select>
        {mode == "custom" && value?.value && (
          <AiOutlinePlusCircle
            className="position-absolute cursor-pointer"
            color="#f29302"
            fontSize={16}
            style={{
              right: 30,
              top: 8,
            }}
            onClick={() => {
              if (value) {
                onChange(value.label, value);
                setValue(undefined);
              }
            }}
          />
        )}
      </div>
      {value && value.length >= 1 && value.length < 3 && (
        <p className="text-danger">Min 3 simboliai</p>
      )}
    </Fragment>
  );
}

export default AutoFillComponent;
