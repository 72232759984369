import { Tooltip } from "antd";
import React, { useMemo } from "react";
import { colorBlockShipment } from "../../helpers/Utils";

const checkifHaveOrderReturn = (d) => Array.isArray(d.shipment_additional_services) ? d.shipment_additional_services.some(
  (some) => some.service_key == "order_return" && some.service_value == 1
) : true

function CourierLinksComponent({ shipments, order_row_id }) {
  const shipments_arr = useMemo(() => {
    let items = [];

    shipments
      .filter((d) => {
        if (!order_row_id) return true;
        return d.shipment_order_rows
          .map((d) => d.order_row_id)
          .includes(order_row_id);
      })
      .forEach((d) => {
        if (d.identifier) {
          items.push({
            picked_up_at: d.picked_up_at,
            delivered_at: d.delivered_at,
            identifier: d.identifier,
          });
        }
        d.courier_trackings.forEach((s) => {
          if(!s.number) return
          items.push({
            picked_up_at: d.picked_up_at,
            delivered_at: d.delivered_at,
            url: s.url,
            number: s.number,
            extra_text: d.shipment_type == "dpd" && s.number.length <= 6 && checkifHaveOrderReturn(d),
          });
        });
      });

    return items;
  }, [shipments.length]);
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridGap: 2,
      }}
    >
      {shipments_arr.map((d, idx) => (
        <React.Fragment key={`shipment_${idx}`}>
          {d.identifier ? (
            <div
              className="badge badge-pill badge-success text-xs"
              style={{ backgroundColor: "#f46c21" }}
            >
              {d.identifier}
            </div>
          ) : d.extra_text ? (
            <Tooltip key={d.url} placement="top" title="Paėmimas su kurjerio lipduku, sękimo informacija negalima.">
              <div
                className={`${colorBlockShipment(d)} text-xs mb-1`}
              >
                {d.number}
              </div>
            </Tooltip>
          ) : (
            <>
              <a
                className={`${colorBlockShipment(d)} text-xs mb-1`}
                key={d.url}
                href={d.url}
                target="_blank"
              >
                {d.number}
              </a>
            </>
          )}
          {/* {(idx + 1) % 3 === 0 && <br />} */}
        </React.Fragment>
      ))}
    </div>
  );
}

export default CourierLinksComponent;
