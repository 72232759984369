import React from "react";
import { FaTrash } from "react-icons/fa";

function SupplyingTable({
  item,
  deleteSupply,
  id_order_row,
  product_reference,
  mode,
  disabled = false,
}) {
  const [showRemove, setShowRemove] = React.useState(false);

  const renderCode = () => {
    if (item.supplier_code) {
      return <div className="text-danger">{item.supplier_code}</div>;
    } else if (product_reference) {
      return <div>{product_reference}</div>;
    } else if (id_order_row) {
      return <div>{id_order_row}</div>;
    }
  };

  if (mode === "Servisas") {
    return (
      <tr
        onMouseEnter={() => setShowRemove(true)}
        onMouseLeave={() => setShowRemove(false)}
      >
        <td className="residuesItem" colSpan="2">
          {item.date || " Nėra datos "}
        </td>
        <td className="residuesItem">{"Taisoma: "}</td>
        <td className="residuesItem" colSpan="2">
          {item.supplier?.name} {item.product_code && `(${item.product_code})`}
        </td>
        <td className="residuesItem">
          {item.amount == null ? 0 : item.amount}
        </td>
        <td className="residuesItem" colSpan={item.external_number ? 2 : 4}>
          {item.user_title}
        </td>
        {item.external_number && (
          <td className="residuesItem text-danger" colSpan="2">
            {item.external_number}
          </td>
        )}
        <td className="residuesItem">
          <FaTrash
            style={{
              color: "orange",
              cursor: "pointer",
              float: "right",
              visibility: showRemove ? "visible" : "hidden",
            }}
            onClick={() => deleteSupply(item.id, id_order_row)}
          />
        </td>
      </tr>
    );
  }

  return (
    <tr
      onMouseEnter={() => setShowRemove(true)}
      onMouseLeave={() => setShowRemove(false)}
    >
      <td className="residuesItem" colSpan="2">
        {item.date || " Nėra datos "}
      </td>
      <td className="residuesItem">{renderCode()}</td>
      <td className="residuesItem" colSpan="2">
        {item.supplier?.name} {item.product_code && `(${item.product_code})`}
      </td>
      <td className="residuesItem">{item.amount == null ? 0 : item.amount}</td>
      <td className="residuesItem" colSpan="2">
        {item.user_title}
      </td>
      {item.inner_document_number && (
          <td className="residuesItem text-danger" colSpan="2">
            {item.inner_document_number}
          </td>
      )}
      {!disabled && mode !== "Pickups" && (
        <td className="residuesItem">
          <FaTrash
            style={{
              color: "orange",
              cursor: "pointer",
              float: "right",
              visibility: showRemove ? "visible" : "hidden",
            }}
            onClick={() => deleteSupply(item.id, id_order_row)}
          />
        </td>
      )}
    </tr>
  );
}

export default SupplyingTable;
