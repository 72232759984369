import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { CheckBoxInput, SelectForm } from "../common/Buttons";
import TextInputModal from "../common/TextInputModal";
import { returnEuroString, toFixedNumber } from "../../helpers/Utils";
import { Tooltip } from "antd";

export function SbLeasingDocumentTypes({
  item,
  disabled,
  identifier,
  update_children,
  remove_children,
  price,
}) {
  const [documentType, setDocumentType] = useState({});
  const options = useSelector((state) => state.custom.options);
  const exist = useMemo(() => !!item, [item]);

  useEffect(() => {
    if (item?.document_plan_id && options?.documentPlan) {
      const find = findDocument((d) => d.value == item.document_plan_id);

      if (!find) return;
      if (documentType.document_type_id == find.document_type_id) return;
      setDocumentType(find);
      if (identifier == "PPG") return;
      const total = calculateItems(find.percentage, item.price);
      update_children({
        total,
      });
    } else if (identifier == "EK1" && options?.documentPlan) {
      if (exist) {
        const ek1_find_option = findDocument((d) => d.parent_prefix == "EK1");

        setDocumentType(ek1_find_option);
        const total = calculateItems(ek1_find_option.percentage, item.price);
        update_children({
          document_plan_id: ek1_find_option.value,
          total,
        });
      }
    }
  }, [item?.document_plan_id]);

  const calculateItems = (percentage, fee) => {
    const total = (Number(price) * percentage) / 100;
    return total + Number(fee || 0);
  };

  const documentTypeArr = options.documentType.filter((d) => {
    if (identifier == "SGG") {
      return d.prefix == "SGG";
    }
    if (identifier == "SGD") {
      return ["SG"].includes(d.prefix) || d.label.includes("draudimas");
    }
    if (identifier == "PPG") {
      return (
        ["VV-I", "VV-K", "VV-V"].includes(d.prefix) ||
        d.label.includes("Garantija")
      );
    }

    return true;
  });

  const findDocument = (caller) => {
    return options.documentPlan.find(caller);
  };
  return (
    <tr>
      <td className="pb-0 pl-0 align-middle">
        <Tooltip
          placement="top"
          title={
            identifier == "SGD"
              ? "Draudimas"
              : identifier == "EK1"
              ? "Ekrano draudimas"
              : "Garantija"
          }
        >
          <nobr>
            <CheckBoxInput
              disabled={disabled}
              checked={exist}
              text={identifier}
              onChange={
                exist ? () => remove_children() : () => update_children({})
              }
            />
          </nobr>
        </Tooltip>
      </td>
      {identifier == "EK1" ? (
        <td className="pb-0 align-middle" colSpan={2}></td>
      ) : (
        <>
          <td className="pb-0 align-middle">
            <SelectForm
              style={{
                width: 140,
              }}
              dropdownMatchSelectWidth={false}
              disabled={disabled || !exist}
              onlyPlaceholder="Tipas"
              options={documentTypeArr}
              value={documentType?.document_type_id || undefined}
              onChange={(value) => {
                const find = findDocument((d) => d.document_type_id == value);
                setDocumentType(find);
                update_children({
                  document_plan_id: null,
                  price: 0,
                  total: 0,
                });
              }}
              warning={exist && !documentType?.document_type_id}
            />
          </td>
          <td className="pb-0 align-middle">
            <SelectForm
              style={{
                width: 140,
              }}
              dropdownMatchSelectWidth={false}
              disabled={disabled || !documentType || !exist}
              onlyPlaceholder="Planas"
              options={options.documentPlan.filter(
                (d) => d.document_type_id == documentType?.document_type_id
              )}
              value={item?.document_plan_id}
              onChange={(value) => {
                const find = findDocument((d) => d.value == value);
                update_children({
                  document_plan_id: value,
                  price: 0,
                  total: calculateItems(find.percentage, 0),
                });
              }}
              warning={exist && !item?.document_plan_id}
            />
          </td>
        </>
      )}
      <td className="pb-0 align-middle">
        <TextInputModal
          type="number"
          disabled={disabled || !exist || !item?.document_plan_id}
          placeholder="Mokestis"
          value={item?.price}
          onChange={(e) => {
            // const find = findDocument((d) => d.value == item.document_plan_id);
            const fee = Number(e.target.value);
            let item_price = +item.price;
            let total = +item.total - +item_price;
            total += +fee;
            // const total = toFixedNumber(calculateItems(find.percentage, fee));
            update_children({ price: fee, total });
          }}
          warning={exist && !item?.price}
        />
      </td>
      <td className="pb-0 align-middle">
        {identifier == "PPG" ? (
          <TextInputModal
            type="number"
            disabled={disabled || !exist || !item?.document_plan_id}
            value={item?.total}
            onChange={(e) => {
              // const find = findDocument(
              //   (d) => d.value == item.document_plan_id
              // );

              const total = +e.target.value;
              // let item_price = +item.price;
              // if (total <= item.price) item_price = total;
              // const percentage = +find.percentage * 0.01;

              // const plan_percentage = +price * percentage;

              // const price_item = toFixedNumber(total - plan_percentage);

              update_children({ total });
            }}
            warning={exist && !item?.total}
          />
        ) : (
          <nobr>{returnEuroString(item?.total)}</nobr>
        )}
      </td>
    </tr>
  );
}
