import React, { useState, useEffect } from "react";
import { Alert, Spin, Select } from "antd";
import { SelectForm } from "../../../Buttons";
import { FlopppyButton } from "../../../ButtonWithLoading";
import { useDispatch } from "react-redux";
import { updateOrderAddress } from "../../../../../redux/Actions/orderAction";
import TextInputModal from "../../../TextInputModal";
import { getCarriers } from "../../../../../requests/Carriers";

function DeliveryBlockChange({
  shipping_address,
  parcel_terminals,
  orderId,
  updateShippingAddress,
  carrier,
  carrierModuleFilter,
  setPostalName,
  total_shipping,
}) {
  const dispatch = useDispatch();

  const [carrierId, setCarrierId] = useState(carrier.id);
  const [totalShipping, setTotalShipping] = useState(total_shipping);

  const [carrierType, setCarrierType] = useState(carrier);

  const [parcelId, setParcelId] = useState(shipping_address.parcel_terminal_id);

  const [parcelShop, setparcelShop] = useState({
    city: shipping_address.city,
    postal_code: shipping_address.postal_code,
    street: shipping_address.street,
  });

  const [loading, setLoading] = useState(false);
  const [loadingArr, setLoadingArr] = useState(true);
  const [carrierArr, setCarrierArr] = useState([]);
  const [message, setMessage] = useState({
    show: false,
  });

  useEffect(() => {
    const fetchCarriers = async () => {
      try {
        let data = await getCarriers();
        data = data
          .filter((carrier) => carrier.id)
          .map((carrier) => ({
            ...carrier,
            value: carrier.id,
            name: carrier.label,
          }));

        if (
          !data.find((f) => f.label.toLowerCase() == carrier.name.toLowerCase())
        ) {
          carrierArr.push({
            ...carrier,
            value: carrier.id,
            label: carrier.name,
            name: carrier.name,
            // module_name: findCarrier.module_name,
          });
        } else {
          const findCarrierIndex = data.findIndex(
            (f) => f.label.toLowerCase() == carrier.name.toLowerCase()
          );
          if (findCarrierIndex !== -1) {
            data[findCarrierIndex].value = carrier.id;
          }
        }

        setCarrierArr(data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingArr(false);
      }
    };
    fetchCarriers();
  }, []);

  const onChangeCarrier = (value) => {
    setCarrierId(value);
    setParcelId(undefined);
    const findCarrier = carrierArr.find((e) => e.value == value);
    if (findCarrier) {
      setCarrierType(findCarrier);
      carrierModuleFilter(findCarrier);
    }
  };

  const onChangeAddress = (value) => {
    setParcelId(value.id);
    setparcelShop({
      city: value.city,
      postal_code: value.postal_code,
      street: value.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    delete shipping_address.valid_phone;
    let params = {
      total_shipping: totalShipping || 0,
      total_shipping_tax_excl: totalShipping || 0,
      total_shipping_tax_incl: totalShipping || 0,
      carrier_id: carrierId,
    };
    if (carrierType.module_name === "fspickupatstorecarrier") {
      params = {
        ...params,
        shipping_address_attributes: {
          ...shipping_address,
          city: parcelShop.city,
          postal_code: parcelShop.postal_code,
          street: parcelShop.street,
          // firstname: "",
          // lastname: "",
          // phone: "",
          // other: "",
          parcel_terminal_id: null,
        },
      };
    } else {
      if (carrierType.name == "DPD kurjeris") {
        params = {
          ...params,
        };
      } else {
        params = {
          ...params,
          shipping_address_attributes: {
            ...shipping_address,
            parcel_terminal_id: parcelId,
          },
        };
      }
    }

    dispatch(updateOrderAddress(orderId, params)).then((res) => {
      if (res.ok) {
        if (carrierType.module_name !== "fspickupatstorecarrier") {
          updateShippingAddress(parcelId);
        }
        const findFromArrCarrier = parcel_terminals.find(
          (e) => e.value == parcelId
        );
        setPostalName(findFromArrCarrier ? findFromArrCarrier.label : "");
        setMessage({
          show: true,
          status: "success",
          message:
            carrierType && carrierType.label
              ? `Sėkmingai atnaujintas ${carrierType.label}`
              : "Sėkmingai atnaujinta",
        });
        setLoading(false);
      } else {
        setMessage({
          show: true,
          status: "error",
          message: "Atnaujinant įvyko klaida.",
        });
        setLoading(false);
      }
    });
  };

  if (loadingArr) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spin />
      </div>
    );
  }
  return (
    <React.Fragment>
      {message.show && (
        <Alert message={message.message} type={message.status} />
      )}

      <form className="row" onSubmit={onSubmit}>
        <div className="col-12">
          <SelectForm
            options={carrierArr}
            placeholder="Pristatymo būdas"
            selector="order_type"
            value={carrierId}
            dropdownMatchSelectWidth={false}
            onChange={(value) => onChangeCarrier(value)}
          />
        </div>
        {parcel_terminals?.length > 0 && (
          <>
            <div className="col-12">
              {carrierType.module_name == "fspickupatstorecarrier" ? (
                <>
                  <div className="text-bold my-1">Parduotuvės pakeitimas</div>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder={"Pasirintki"}
                    name="street"
                    // onChange={(value) => onChangeAddress(value)}
                    onChange={(e, arg) => onChangeAddress(arg)}
                    dropdownMatchSelectWidth={true}
                    value={parcelShop.street || undefined}
                    size="default"
                  >
                    {parcel_terminals.map((item) => (
                      <React.Fragment key={item.id}>
                        <Select.Option
                          id={item.id}
                          postal_code={item.postal_code}
                          city={item.city}
                          value={item.address}
                        >
                          {item.address}
                        </Select.Option>
                      </React.Fragment>
                    ))}
                  </Select>
                </>
              ) : (
                <SelectForm
                  options={parcel_terminals}
                  placeholder="Paštomato pakeitimas"
                  selector="order_type"
                  value={parcelId}
                  dropdownMatchSelectWidth={false}
                  onChange={(value) => setParcelId(value)}
                />
              )}
            </div>
          </>
        )}
        <div className="col-12">
          <TextInputModal
            type="number"
            text="Pristatymo kainos pakeitimas"
            placeholder="Įvesti"
            name="totalShipping"
            value={totalShipping}
            onChange={(e) => {
              const val = e.target.value;
              setTotalShipping(val);
            }}
          />
        </div>
        <div className="col-10"></div>
        <div className="col-2">
          <div className="mt-2 float-right">
            <FlopppyButton
              loading={loading}
              disabled={
                parcel_terminals?.length > 0
                  ? carrierType.module_name == "fspickupatstorecarrier"
                    ? !carrierId || !parcelShop?.street
                    : !carrierId || !parcelId
                  : !carrierId
              }
              style={{ height: 32 }}
              onClick={(e) => onSubmit(e)}
            />
          </div>
        </div>
      </form>
    </React.Fragment>
  );
}
export default DeliveryBlockChange;
