import React from "react";
import { phonenumberValid } from "../../../helpers/Utils";
import InputForm from "../../common/InputForm";
import ModalToChange from "../../Layout/ModalToChange";

class RivileStoreEditModal extends React.Component {
  constructor() {
    super();
    this.state = {
      id: 0,
      title: "",
      postal_code: "",
      city: "",
      phone: "",
      rivile_code: "",
      invoice_prefix: "",
      order_prefix: "",
      address: "",
      errors: {},
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      id: nextProps.objectData.id,
      title: nextProps.objectData.title,
      postal_code: nextProps.objectData.postal_code,
      city: nextProps.objectData.city,
      phone: nextProps.objectData.phone,
      rivile_code: nextProps.objectData.rivile_code,
      invoice_prefix: nextProps.objectData.invoice_prefix,
      order_prefix: nextProps.objectData.order_prefix,
      address: nextProps.objectData.address,
      errors: {},
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.title.length == 0 ||
      this.state.rivile_code.length == 0 ||
      this.state.address.length == 0 ||
      !phonenumberValid(this.state.phone)
    ) {
      let errors = {};
      if (this.state.title.length == 0)
        errors.title = "Neužpildytas pavadinimo laukelis";
      if (this.state.rivile_code.length == 0)
        errors.rivile_code = "Neužpildytas rivile kodo laukelis";
      if (this.state.address.length == 0)
        errors.address = "Neužpildytas adreso laukelis";
      if (this.state.postal_code.length == 0)
        errors.postal_code = "Neužpildytas pašto kodo laukelis";
      if (this.state.city.length == 0)
        errors.city = "Neužpildytas miesto laukelis";
      if (!phonenumberValid(this.state.phone))
        errors.phone = "Neužpildytas teisingai telefono laukelis";
      return this.setState({
        errors,
      });
    }
    const variables = {
      rivile_stores: {
        title: this.state.title,
        rivile_code: this.state.rivile_code,
        postal_code: this.state.postal_code,
        city: this.state.city,
        address: this.state.address,
        invoice_prefix: this.state.invoice_prefix,
        order_prefix: this.state.order_prefix,
        phone: this.state.phone,
      },
    };
    this.props.updateRivileStore(this.state.id, variables);
  };

  render() {
    const { errors } = this.state;
    return (
      <ModalToChange
        handleSave={(e) => this.onSubmit(e)}
        status="tableEdit"
        text={"Redaguoti"}
        buttonText={"Atnaujinti"}
      >
        <InputForm
          type="text"
          placeholder="Pavadinimas"
          name="title"
          value={this.state.title}
          error={errors.title}
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Miestas"
          name="city"
          value={this.state.city}
          error={errors.city}
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Adresas"
          name="address"
          value={this.state.address}
          error={errors.address}
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Pašto kodas"
          name="postal_code"
          value={this.state.postal_code}
          error={errors.postal_code}
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Telefono numeris"
          name="phone"
          value={this.state.phone}
          error={errors.phone}
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Rivile kodas"
          name="rivile_code"
          value={this.state.rivile_code}
          error={errors.rivile_code}
          onChange={(e) => this.handleChange(e)}
        />{" "}
        <InputForm
          type="text"
          placeholder="Sąskaitos prefix"
          name="invoice_prefix"
          value={this.state.invoice_prefix}
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Užsakymo prefix"
          name="order_prefix"
          value={this.state.order_prefix}
          onChange={(e) => this.handleChange(e)}
        />
      </ModalToChange>
    );
  }
}

export default RivileStoreEditModal;
