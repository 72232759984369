import React from "react";
import styled from "styled-components";
import { SelectForm, CheckBoxInput } from "../../../common/Buttons";
import InputForm from "../../../common/InputForm";
import { GiSave } from "react-icons/gi";
import moment from "moment";

class DocumentModal extends React.Component {
  constructor() {
    super();
    this.state = {
      id: null,
      type: "",
      name: "",
      start_date: 12,
      enddate: 24,
      cost: 0,
      percentage: 0,
      rivile_service_id: null,
      document_type: "",
      active: false,
      action: "new",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.documentId) {
      this.setState({
        document_type: nextProps.documentId.value,
      });
    }
    if (nextProps.action === "new" && this.state.action === "edit") {
      this.setState({
        type: "",
        name: "",
        start_date: 12,
        enddate: 24,
        cost: 0,
        percentage: 0,
        rivile_service_id: null,
        // document_type: nextProps.documentId.value,
        active: false,
        action: "new",
      });
      this.removeErrors(nextProps.errors);
    } else if (nextProps.action === "edit") {
      let item = nextProps.documentPlan;
      this.setState({
        id: item.id,
        name: item.name,
        start_date: item.start_date,
        enddate: item.end_date,
        cost: item.cost,
        percentage: item.percentage,
        rivile_service_id: item.rivile_service_id,
        // document_type: nextProps.document_type_id,
        active: item.active,
        action: "edit",
      });
      this.removeErrors(nextProps.errors);
    }
  }

  removeErrors = (errors) => {
    if (Object.keys(errors).length > 0) {
      this.props.clearErrors();
    }
  };

  onChange = (e, date) => {
    if (date) {
      this.setState({
        [date]: e,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  getDateFormat = (date) => {
    var d = moment(date);
    return date && d.isValid() ? d : "";
  };

  onSubmit = (e) => {
    e.preventDefault();
    const variables = {
      name: this.state.name,
      start_date: Number(this.state.start_date),
      end_date: Number(this.state.enddate),
      cost: Number(this.state.cost),
      percentage: Number(this.state.percentage),
      active: this.state.active,
      rivile_service_id: this.state.rivile_service_id,
    };
    if (this.props.action == "new") {
      this.props.postDocumentPlan(this.state.document_type, variables);
    } else if (this.props.action === "edit") {
      this.props.updateDocumentPlan(
        this.state.document_type,
        this.state.id,
        variables
      );
    }
  };

  render() {
    return (
      <div
        className="modal fade"
        id="tablePlans"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-edit" role="document">
          <div className="modal-content px-2 py-2" style={{ height: "100vh" }}>
            <div className="col">
              <span className="font-weight-bold d-block">
                {this.props.text}
                <button
                  type="button"
                  id="closeDocumentPlan"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </span>
            </div>
            <div
              className="modal-body"
              style={{ height: "100vh", overflow: "auto" }}
            >
              <DocumentModalStyle>
                <h3 className="modal_title">Dokumentų planas</h3>

                <div className="modal_mark">
                  <h3 className="modal_mark_text">Informacija</h3>
                </div>

                <div className="modal_content">
                  <SelectForm
                    placeholder="Dokumento tipas"
                    name="document_type"
                    options={[this.props.documentId]}
                    value={this.state.document_type}
                    onChange={(e) => this.onChange(e, "document_type")}
                    error={
                      this.props.errors.document_type &&
                      this.props.errors.document_type.map((item) => `${item} `)
                    }
                  />
                  <SelectForm
                    placeholder="Rivilė paslauga"
                    name="rivile_service_id"
                    options={this.props.rivile_services}
                    value={this.state.rivile_service_id}
                    onChange={(e) => this.onChange(e, "rivile_service_id")}
                  />
                  <InputForm
                    placeholder="Pavadinimas"
                    name="name"
                    value={this.state.name}
                    onChange={this.onChange}
                    error={
                      this.props.errors.name &&
                      this.props.errors.name.map((item) => `${item} `)
                    }
                  />
                  <div>
                    <InputForm
                      type="number"
                      name="start_date"
                      placeholder="Pradžios terminalas"
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        this.onChange(e.target.value, "start_date")
                      }
                      value={this.state.start_date}
                    />
                  </div>
                  <div>
                    <InputForm
                      type="number"
                      name="enddate"
                      placeholder="Pabaigos terminas"
                      style={{ width: "100%" }}
                      onChange={(e) => this.onChange(e.target.value, "enddate")}
                      value={this.state.enddate}
                    />
                  </div>
                  <InputForm
                    type="number"
                    name="cost"
                    placeholder="Mokestis"
                    value={this.state.cost}
                    onChange={(e) => this.onChange(e)}
                    error={
                      this.props.errors.cost &&
                      this.props.errors.cost.map((item) => `${item} `)
                    }
                  />
                  <InputForm
                    type="number"
                    name="percentage"
                    placeholder="% nuo kainos"
                    value={this.state.percentage}
                    onChange={(e) => this.onChange(e)}
                    error={
                      this.props.errors.percentage &&
                      this.props.errors.percentage.map((item) => `${item} `)
                    }
                  />
                  <div className="my-2 mx-2">
                    <CheckBoxInput
                      type="checkbox"
                      color="#f7951e"
                      text="Aktyvus"
                      checked={this.state.active}
                      onChange={(e) =>
                        this.setState({ active: e.target.checked })
                      }
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn modal_button_save"
                    onClick={this.onSubmit}
                  >
                    <GiSave /> Išsaugoti
                  </button>
                </div>
              </DocumentModalStyle>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DocumentModal;

const DocumentModalStyle = styled.div`
  .modal_title {
    font-size: 25px;
    line-height: 14px;
    color: #222222;
    font-family: "Barlow";
    font-weight: bold;
    margin-bottom: 30px;
  }
  .modal_mark {
    display: flex;
    align-items: center;
    width: 100%;
    height: 35px;
    background-color: #f2f2f2;
    margin-bottom: 15px;

    &_text {
      margin: 0;
      margin-left: 20px;
      font-size: 15px;
      line-height: 14px;
      color: #000000;
      font-family: "Barlow";
      font-weight: 700;
    }
  }
  .modal_button_save {
    width: 159px;
    height: 30px;
    border-radius: 4px;
    background-color: #ee3624;

    font-size: 10px;
    line-height: 14px;
    color: #ffffff;
    font-family: "Barlow";
    font-weight: 700;
  }
`;
