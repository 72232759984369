import React from "react";
import { Spin } from "antd";
import moment from "moment";
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";

function PaymentsBlockComponent({
  payment,
  orderId,
  deletePayment,
  paymentTypes,
  goodsAddRowClick,
  editPayment,
  paymentEdit,
  disabled = false,
}) {
  payment.price = Number(payment.price).toFixed(2);
  const [showRemove, setShowRemove] = React.useState(false);
  const [Loading, setLoading] = React.useState(false);

  const editHandler = () => {
    const variables = {
      id: payment.id,
      reference: payment.reference,
      payment_type: payment.payment_type,
      price: payment.price,
    };
    editPayment(variables);
    goodsAddRowClick(true);
  };

  const deletePaymentFunc = (orderId, itemID) => {
    if (confirm("Ar tikrai norite ištrinti ?")) {
      setLoading(true);
      deletePayment(orderId, itemID).then((response) => {
        if (response) {
          setLoading(false);
        }
      });
    }
  };

  const handlePaymentType = (value) => {
    const findPaymentType = paymentTypes.find((find) => find.value == value);
    return findPaymentType ? findPaymentType.label : "";
  };

  if (Loading) {
    return (
      <tr
        onMouseEnter={() => setShowRemove(true)}
        onMouseLeave={() => setShowRemove(false)}
      >
        <td colSpan="6">
          {" "}
          <div className="d-flex align-items-center justify-content-center">
            <Spin size="small" />
          </div>
        </td>
      </tr>
    );
  }
  const editElement = paymentEdit.id == payment.id;
  return (
    <tr
      className={editElement ? "blink" : ""}
      onMouseEnter={() => setShowRemove(true)}
      onMouseLeave={() => setShowRemove(false)}
    >
      <td style={{ fontSize: "0.8rem" }}>{payment.reference}</td>
      <td>{handlePaymentType(payment.payment_type)}</td>

      <td>
        {payment.user && `${payment.user.firstname} ${payment.user.lastname}`}
      </td>
      <td>{moment(payment.created_at).format("YYYY-MM-DD HH:mm")}</td>
      <td>{payment.price}</td>
      <td className="align-middle">
        {!disabled && (
          <MdEdit
            style={{
              color: "red",
              cursor: "pointer",
              visibility: showRemove ? "visible" : "hidden",
              // marginLeft: "5px",
            }}
            onClick={() => editHandler(true)}
          />
        )}
      </td>
      <td className="align-middle">
        {!disabled && (
          <FaTrash
            style={{
              color: "orange",
              cursor: "pointer",
              visibility: showRemove ? "visible" : "hidden",
              // marginLeft: "10px",
            }}
            onClick={() => deletePaymentFunc(orderId, payment.id)}
          />
        )}
      </td>
    </tr>
  );
}

export default PaymentsBlockComponent;
