import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { GoPrimitiveDot } from "react-icons/go";
import { RiErrorWarningLine } from "react-icons/ri";
import { FaCheck, FaTimes } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import TextInputModal from "../../common/TextInputModal";
import { updateSuppliersInvoiceItem } from "../../../redux/Actions/suppliersInvoicesAction";

function TableItem({ id, item, index }) {
  useEffect(() => {
  }, [item])

  const [edit, setEdit] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const dispatch = useDispatch();

  const onEdit = () => {
    setEdit(true);
    setInputValue(item.item_code_rivile);
  };

  const onSubmit = () => {
    dispatch(updateSuppliersInvoiceItem(id, inputValue)).then((res) => {
      if (res.success) {
        setEdit(false);
      }
    });
  };

  const onCancel = () => {
    setEdit(false);
  };

  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle">{item.number}</td>
      <td className="align-middle">
        {edit ? (
          <TextInputModal
            text=""
            placeholder="Įvesti"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        ) : item.item_code_rivile
        }
      </td>
      <td className="align-middle">{item.item_model_name}</td>
      <td className="align-middle">{item.description}</td>
      <td className="align-middle">{item.quantity}</td>
      <td className="align-middle">{item.unit_price}</td>
      <td className="align-middle">{item.amount}</td>
      <td className="align-middle">
        <div>
          {!edit ? (
            <MdEdit
              className="text-warning"
              size={20}
              style={{ cursor: "pointer" }}
              onClick={onEdit}
            />
          ) : (
            <div>
              <FaCheck
                className="text-success mr-1"
                size={20}
                style={{ cursor: "pointer" }}
                onClick={onSubmit}
              />
              <FaTimes
                className="text-danger"
                size={20}
                style={{ cursor: "pointer" }}
                onClick={onCancel}
              />
            </div>
          )}
        </div>
      </td>
    </tr>
  );
}

export default TableItem;