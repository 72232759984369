import React, { useEffect, useMemo, useState } from "react";
import { AiOutlineDownload, AiOutlineSend } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { onChangeUseState } from "../../helpers/GlobalFunctions";
import { Lock_svg } from "../../helpers/SvgStrings";
import useFetch from "../../helpers/useFetch";
import {
  exportDocument,
  prettyDateFormat,
  getUrlExtension,
  antdMessageSuccess,
  getDateFormat,
} from "../../helpers/Utils";
import {
  regenerateReport,
  sendEmailReport,
  updateReport,
} from "../../redux/Actions/reportAction";
import { CheckBoxInput, SelectForm } from "../common/Buttons";
import ButtonSecondary from "../common/ButtonSecondary";
import CalendarDayInput from "../common/CalendarDayInput";
import EmailSetterInput from "../common/EmailSetterInput";
import InputSwitchComponent from "../common/InputSwitchComponent";
import Spinner from "../common/Spinner";
import TableUi from "../common/TableUi";
import { Tooltip, Upload } from "antd";
import RangePickerComp from "../common/RangePickerComp";
import DataPickerInputModal from "../common/DataPickerInputModal";
import { GoPrimitiveDot } from "react-icons/go";
import { DebounceSelect } from "../common";
import Axios from "axios";
import { reportDepFullOptions } from "../../helpers/GlobalOptions";

function ReportModal({
  report_id,
  editable,
  setEditable,
  newValue,
  setNewValue,
}) {
  const dispatch = useDispatch();
  const { options } = useSelector((state) => state.custom);
  const [extraFetch, setExtraFetch] = useState(false);
  const [generatedReport, setGeneratedReport] = useState(false);
  const { value, loading, setValue, error } = useFetch(
    `/api/v1/reports/${report_id}`,
    {},
    [editable, extraFetch]
  );
  const [columns, setColumns] = useState({});
  const [errors, setErrors] = useState({});
  const report_emails = useMemo(() => {
    if (!value?.report_emails) return [];
    return value.report_emails.sort((a, b) => b.id - a.id);
  }, [value?.report_emails]);

  useEffect(() => {
    if (value) {
      setColumns(value.columns);
    }
  }, [value]);
  useEffect(() => {
    setErrors({});
  }, [editable, extraFetch]);

  useEffect(() => {
    console.log(newValue?.id, value?.id);
    if (newValue.id == value?.id) {
      setGeneratedReport(true);
      antdMessageSuccess("Sėkmingai sugeneruota");
      setValue(newValue);
      setNewValue({ id: false });
      dispatch({
        type: "UPDATE_CUSTOM",
        payload: newValue,
      });
    }
  }, [newValue]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spinner />
      </div>
    );
  }
  if (error) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        Įvyko klaida
      </div>
    );
  }

  const onChangeColumn = (name) => {
    setValue((prev) => ({
      ...prev,
      columns: {
        ...prev.columns,
        [name]: !prev.columns[name],
      },
    }));
  };

  const set_report_type = (val) => {
    let custom_value = value.report_type;
    if (custom_value == "all") {
      custom_value = val == "residues" ? "sales" : "residues";
    } else if (val == custom_value) {
      custom_value = "";
    } else {
      if (custom_value == "") {
        custom_value = val;
      } else if (custom_value !== val) {
        custom_value = "all";
      }
    }

    onChangeUseState(
      { target: { value: custom_value, name: "report_type" } },
      setValue
    );
  };

  const errorHandler = (val) => {
    let errors = {};
    console.log("wtf: ", val);
    if (
      [val.supplier_id, val.brand_id, val.family_id].filter((d) => d).length ==
      0
    ) {
      errors.supplier_id = "Pasirinkite bent vieną iš trijų";
      errors.family_id = "Pasirinkite bent vieną iš trijų";
      errors.brand_id = "Pasirinkite bent vieną iš trijų";
    }

    if (val.departments.length == 0) errors.departments = "Nepasirinkta";
    if (val.emails.length == 0) errors.emails = "Nepasirinkta";
    if (val.method == "auto") {
      if (!val.date) {
        errors.date = "Nepasirinkta";
      }
    }
    if (!val.report_type) errors.report_type = "Nepasirinkta";
    return errors;
  };

  const onUpdate = async () => {
    try {
      let params = {
        ...value,
      };

      delete params.brand;
      delete params.family;
      delete params.residues_asset;
      delete params.sales_asset;
      delete params.report_emails;
      delete params.supplier;

      if (params.period !== "other") {
        // params.period = null;
        params.dates_to_take = null;
      }

      const custom_errors = errorHandler(params);
      if (Object.entries(custom_errors).length > 0) {
        return setErrors(custom_errors);
      }

      const { success } = await dispatch(updateReport(params));
      if (success) {
        setErrors({});
        setEditable(false);
        setExtraFetch((prev) => !prev);
      }
      // setLoading(true);
      // startedPack();
    } catch (err) {
      // errorHandler(err);
    } finally {
      // setLoading(false);
    }
  };

  // const showDownloadBlock = () => {
  //   if (value.sales_asset.url || value.residues_asset.url) return true;
  //   if (generatedReport) return true;
  //   return false;
  // };
  const disabled = !editable;

  return (
    <div className="row">
      <div className="col-6">
        <InputSwitchComponent
          disabled={disabled}
          label="Aktyvi"
          name="active"
          options={[
            {
              label: "Taip",
              value: true,
            },
            {
              label: "Ne",
              value: false,
            },
          ]}
          value={value.active}
          onChange={(value) => onChangeUseState(value, setValue)}
        />
        <SelectForm
          disabled={disabled}
          label="Tiekėjas"
          value={value.supplier_id}
          options={options.suppliers}
          dropdownMatchSelectWidth={false}
          onChange={(value) =>
            onChangeUseState(
              { target: { value, name: "supplier_id" } },
              setValue
            )
          }
          error={errors.supplier_id}
        />
        <div className="mb-2" />
        <DebounceSelect
          disabled={disabled}
          options={
            value?.brand_id
              ? [
                  {
                    value: value?.brand?.id,
                    label: value?.brand?.title,
                  },
                ]
              : []
          }
          label="Prekės ženklas"
          showSearch={true}
          fetchOptions={async (value) => {
            try {
              let api_url = `/api/v1/brands/all_brands?[q][title_cont]=${value}`;
              const { data } = await Axios.get(api_url);
              const valArr = data?.data?.filter((d) => d.title) || [];
              return [
                {
                  label: "Nepasirinkti",
                  value: "",
                },
                ...valArr.map((e) => {
                  return {
                    ...e,
                    value: e.id,
                    label: e.title,
                  };
                }),
              ];
            } catch (err) {
              errorHandler(err);
              return [];
            }
          }}
          style={{
            width: "100%",
          }}
          value={value.brand_id || undefined}
          onChange={(value) => {
            onChangeUseState(
              {
                target: { value: value.value || null, name: "brand_id" },
              },
              setValue
            );
          }}
          error={errors.brand_id}
        />
        <div className="mb-2" />
        <EmailSetterInput
          disabled={disabled}
          emails={value.emails}
          onChangeEmails={(value) =>
            onChangeUseState(
              {
                target: {
                  name: "emails",
                  value,
                },
              },
              setValue
            )
          }
          error={errors.emails}
        />
        <div className="mb-2" />
        {value.period == "other" ? (
          <>
            <div className="mb-2">
              <DataPickerInputModal
                disabled={disabled}
                text="Diena"
                value={getDateFormat(value.date)}
                onChange={(_, value) =>
                  onChangeUseState(
                    { target: { value, name: "date" } },
                    setValue
                  )
                }
                error={errors.date}
              />
            </div>
            <div className="mb-2">
              <label className={`editLabelForm text-bold my-1 `}>
                Datos imtis
              </label>
              <RangePickerComp
                disabled={disabled}
                placeholder={["Nuo", "Iki"]}
                value={
                  !value.dates_to_take
                    ? ["", ""]
                    : [
                        getDateFormat(value.dates_to_take.split("_")[0]),
                        getDateFormat(value.dates_to_take.split("_")[1]),
                      ]
                }
                onChange={(args, value) => {
                  console.log({ args, value });
                  onChangeUseState(
                    {
                      target: {
                        value: value.join("_"),
                        name: "dates_to_take",
                      },
                    },
                    setValue
                  );
                }}
                disabledDate={(d) => !d}
              />
            </div>
          </>
        ) : (
          <CalendarDayInput
            disabled={disabled}
            period={value.period}
            method={value.method}
            value={value.date}
            onChange={(value) =>
              onChangeUseState({ target: { value, name: "date" } }, setValue)
            }
            error={errors.date}
          />
        )}
        <></>
      </div>
      <div className="col-6">
        <InputSwitchComponent
          disabled={disabled}
          label="Būdas"
          name="method"
          options={[
            {
              label: "Rankinis",
              value: "manual",
            },
            {
              label: "Automatinis",
              value: "auto",
            },
          ]}
          value={value.method}
          onChange={(e) => {
            const { name, value } = e.target;
            setValue((prev) => ({
              ...prev,
              [name]: value,
              date: "",
            }));
          }}
        />
        <DebounceSelect
          disabled={disabled}
          options={
            value?.family_id
              ? [
                  {
                    value: value?.family?.id,
                    label: value?.family?.name,
                  },
                ]
              : []
          }
          label="Prekių kategorija"
          showSearch={true}
          fetchOptions={async (value) => {
            try {
              let api_url = `/api/v1/families/all_families?[q][name_cont]=${value}`;
              const { data } = await Axios.get(api_url);
              const valArr = data?.data?.filter((d) => d.name) || [];
              return [
                {
                  label: "Nepasirinkti",
                  value: "",
                },
                ...valArr.map((e) => {
                  return {
                    ...e,
                    value: e.id,
                    label: e.name,
                  };
                }),
              ];
            } catch (err) {
              errorHandler(err);
              return [];
            }
          }}
          style={{
            width: "100%",
          }}
          value={value.family_id || undefined}
          onChange={(value) => {
            onChangeUseState(
              {
                target: { value: value.value || null, name: "family_id" },
              },
              setValue
            );
          }}
          error={errors.family_id}
        />
        <div className="mb-2" />

        <SelectForm
          disabled={disabled}
          label="Padalinys"
          value={value.departments}
          options={reportDepFullOptions}
          mode="multiple"
          dropdownMatchSelectWidth={false}
          onChange={(value) => {
            let val = value;
            if (val.includes("suminis")) {
              val = ["suminis"];
            }
            onChangeUseState(
              { target: { value: val, name: "departments" } },
              setValue
            );
          }}
          error={errors.departments}
        />
        <div className="mb-2" />
        <InputSwitchComponent
          disabled={disabled}
          label="Laikotarpis"
          name="period"
          options={[
            {
              value: "month",
              label: "Mėnesis",
            },
            {
              value: "week",
              label: "Savaitė",
            },
            {
              value: "other",
              label: "Kita",
            },
          ].filter((d) =>
            value.method == "auto" ? d.value !== "other" : true
          )}
          value={value.period}
          onChange={(value) => {
            onChangeUseState(value, setValue);
            onChangeUseState({ target: { value: "", name: "date" } }, setValue);
          }}
        />
        <div className="form-group">
          <label
            className={`editLabelForm text-bold my-1 ${
              errors.report_type ? "text-danger" : ""
            }`}
          >
            Tipas {disabled && Lock_svg()}
          </label>
          <div className="d-flex justify-content-between">
            <CheckBoxInput
              disabled={disabled}
              text="Likučiai"
              checked={["residues", "all"].includes(value.report_type)}
              onChange={() => set_report_type("residues")}
            />
            <CheckBoxInput
              disabled={disabled}
              text="Pardavimai"
              checked={["sales", "all"].includes(value.report_type)}
              onChange={() => set_report_type("sales")}
            />
          </div>
          {errors.report_type && (
            <div className="text-danger">{errors.report_type}</div>
          )}
        </div>
      </div>
      <div className="w-100">
        <div className="bg-silver">
          <div className="col-12">
            <div className="row">
              <div className="col-6">
                <div className="mb-2" />
                <CheckBoxInput
                  disabled={disabled}
                  text="Prekės kodas"
                  checked={columns.product_code}
                  onChange={() => onChangeColumn("product_code")}
                />
                <div className="mb-2" />
                <CheckBoxInput
                  disabled={disabled}
                  text="Prekės ilgas pavadinimas"
                  checked={columns.product_long_name}
                  onChange={() => onChangeColumn("product_long_name")}
                />
                <div className="mb-2" />
                <CheckBoxInput
                  disabled={disabled}
                  text="Barkodas"
                  checked={columns.barcode}
                  onChange={() => onChangeColumn("barcode")}
                />
                <div className="mb-2" />
                <CheckBoxInput
                  disabled={disabled}
                  text="Pajamavimo dok. data"
                  checked={columns.income_doc_date}
                  onChange={() => onChangeColumn("income_doc_date")}
                />
                <div className="mb-2" />
                <CheckBoxInput
                  disabled={disabled}
                  text="Suma be PVM"
                  checked={columns.amount_without_vat}
                  onChange={() => onChangeColumn("amount_without_vat")}
                />
                <div className="mb-2" />
                <CheckBoxInput
                  disabled={disabled}
                  text="Padalinys"
                  checked={columns.department}
                  onChange={() => onChangeColumn("department")}
                />
                <div className="mb-2" />
              </div>
              <div className="col-6">
                <div className="mb-2" />
                <CheckBoxInput
                  disabled={disabled}
                  text="Prekės pavadinimas"
                  checked={columns.product_name}
                  onChange={() => onChangeColumn("product_name")}
                />
                <div className="mb-2" />
                <CheckBoxInput
                  disabled={disabled}
                  text="Gamintojas"
                  checked={columns.manufacturer}
                  onChange={() => onChangeColumn("manufacturer")}
                />
                <div className="mb-2" />
                <CheckBoxInput
                  disabled={disabled}
                  text="Pajamavimo dok. nr."
                  checked={columns.income_doc_no}
                  onChange={() => onChangeColumn("income_doc_no")}
                />
                <div className="mb-2" />
                <CheckBoxInput
                  disabled={disabled}
                  text="Kiekis"
                  checked={columns.quantity}
                  onChange={() => onChangeColumn("quantity")}
                />
                <div className="mb-2" />
                <InputSwitchComponent
                  disabled={disabled}
                  options={[
                    {
                      value: "xls",
                      label: "XLS",
                    },
                    {
                      value: "xml",
                      label: "XML",
                    },
                    {
                      value: "csv",
                      label: "CSV",
                    },
                  ]}
                  name="format"
                  value={value.format}
                  onChange={(value) => onChangeUseState(value, setValue)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {editable ? (
        <div className="col-12 mt-3">
          <div
            style={{
              width: 217,
              height: 32,
            }}
          >
            <ButtonSecondary onClick={() => onUpdate()}>
              Išsaugoti
            </ButtonSecondary>
          </div>
        </div>
      ) : (
        <div className="col-12 d-flex mt-3">
          <div
            className="mr-2"
            style={{
              width: 134,
              height: 32,
            }}
          >
            <ButtonSecondary
              onClick={async () => {
                setGeneratedReport("loading");
                const { success } = await regenerateReport(value.id);
                if (!success) return setGeneratedReport(false);
              }}
              outline={true}
              loading={generatedReport == "loading"}
            >
              Generuoti ataskaitą
            </ButtonSecondary>
          </div>
          {/*  */}
          {/* {showDownloadBlock() && (
            <div
              className="mr-2"
              style={{
                width: 134,
                height: 32,
              }}
            >
              <Tooltip
                placement="top"
                title={
                  generatedReport == "loading" ? (
                    "Generuojama..."
                  ) : (
                    <div className="d-flex flex-wrap flex-column align-items-center">
                      Parsisiųsti ataskaitas
                      <div className="d-flex">
                        {value.residues_asset.url && (
                          <>
                            <div
                              className="text-orange cursor-pointer white-hover font-semi-bold ml-1"
                              onClick={() => {
                                const file_name = getUrlExtension(
                                  value.residues_asset.url || "/none",
                                  false
                                );
                                exportDocument(
                                  `/api/v1/reports/${value.id}/download_residues`,
                                  `${file_name}`,
                                  "POST"
                                );
                              }}
                            >
                              Likučiai
                            </div>
                            {"all" == value.report_type && (
                              <div className="mx-1">/</div>
                            )}
                          </>
                        )}
                        {value.sales_asset.url && (
                          <>
                            <div
                              className="text-orange cursor-pointer white-hover font-semi-bold ml-1"
                              onClick={() => {
                                const file_name = getUrlExtension(
                                  value.sales_asset.url || "/none",
                                  false
                                );
                                exportDocument(
                                  `/api/v1/reports/${value.id}/download_sales`,
                                  `${file_name}`,
                                  "POST"
                                );
                              }}
                            >
                              Pardavimai
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )
                }
              >
                <div
                  className="mr-2"
                  style={{
                    width: 134,
                    height: 32,
                  }}
                >
                  <ButtonSecondary
                    onClick={() => {}}
                    outline={true}
                    loading={generatedReport == "loading"}
                  >
                    Parsisiųsti ataskaitas
                  </ButtonSecondary>
                </div>
              </Tooltip>
            </div>
          )} */}
          {/* <div
            style={{
              width: 134,
              height: 32,
            }}
          >
            <ButtonSecondary
              onClick={async () => {
                const { success } = await sendEmailReport(value.id);
                if (success) {
                  setTimeout(() => {
                    setExtraFetch((prev) => !prev);
                  }, 1000);
                }
              }}
              outline={true}
            >
              Išsiųsti
            </ButtonSecondary>
          </div> */}
          <div
            className="mx-2"
            style={{
              width: 134,
              height: 32,
            }}
          >
            <Tooltip
              placement="top"
              title={
                generatedReport == "loading" ? (
                  "Generuojama..."
                ) : (
                  <div className="d-flex flex-wrap flex-column align-items-center">
                    Įkelti ataskaitą
                    <div className="d-flex">
                      {value.residues_asset.url && (
                        <>
                          <Upload
                            showUploadList={false}
                            name={"file"}
                            action={`/api/v1/reports/${value.id}/save_document?type=residues`}
                            className="w-100"
                            onChange={() => {
                              console.log("TEST");
                            }}
                          >
                            <div className="text-orange cursor-pointer white-hover font-semi-bold ml-1">
                              Likučiai
                            </div>
                          </Upload>
                          {"all" == value.report_type && (
                            <div className="mx-1">/</div>
                          )}
                        </>
                      )}
                      {value.sales_asset.url && (
                        <>
                          <Upload
                            showUploadList={false}
                            name={"file"}
                            action={`/api/v1/reports/${value.id}/save_document?type=sales`}
                            className="w-100"
                            onChange={() => {
                              console.log("TEST");
                            }}
                          >
                            <div className="text-orange cursor-pointer white-hover font-semi-bold ml-1">
                              Pardavimai
                            </div>
                          </Upload>
                        </>
                      )}
                    </div>
                  </div>
                )
              }
            >
              <div
                className="mr-2"
                style={{
                  width: 134,
                  height: 32,
                }}
              >
                <ButtonSecondary
                  onClick={() => {}}
                  outline={true}
                  loading={generatedReport == "loading"}
                >
                  Įkelti failą
                </ButtonSecondary>
              </div>
            </Tooltip>
          </div>
        </div>
      )}

      <div className="col-12">
        <h4 className="font-semi-bold text-base text-black mt-3 mb-3">
          Veiklos istorija
        </h4>

        <div className="bg-silver">
          <TableUi ths={["Data", "Stat.", "Failas", "Gavėjas", ""]}>
            {report_emails.map((e) => {
              const file_name_item = `${
                e.residues_asset.url
                  ? getUrlExtension(e.residues_asset.url, false)
                  : ""
              } ${e.residues_asset.url && e.sales_asset.url ? ", " : ""}
               ${
                 e.sales_asset.url
                   ? getUrlExtension(e.sales_asset.url, false)
                   : ""
               }`;
              return (
                <TableUi.tr key={e.id}>
                  <TableUi.td className="p-left">
                    {prettyDateFormat(e.created_at)}
                  </TableUi.td>
                  <TableUi.td>
                    <Tooltip
                      title={
                        e.status == "sent"
                          ? "Išsiųsta"
                          : e.status == "generated"
                          ? "Sugeneruotas"
                          : e.status == "uploaded"
                          ? "Įkeltas"
                          : "..."
                      }
                      placement="top"
                    >
                      <GoPrimitiveDot
                        style={
                          e.status == "sent"
                            ? { color: "#10CA00" }
                            : e.status == "generated"
                            ? { color: "#FE9C00" }
                            : { color: "#ef3824" }
                        }
                      />
                    </Tooltip>
                  </TableUi.td>
                  <TableUi.td>{file_name_item}</TableUi.td>
                  <TableUi.td>{e.recipients.join(", ")}</TableUi.td>
                  <TableUi.td>
                    <Tooltip
                      placement="left"
                      title={
                        <div className="d-flex flex-wrap flex-column align-items-center">
                          Parsisiųsti ataskaitas
                          <div className="d-flex">
                            {e.residues_asset.url && (
                              <>
                                <div
                                  className="text-orange cursor-pointer white-hover font-semi-bold ml-1"
                                  onClick={() => {
                                    const file_name = getUrlExtension(
                                      e.residues_asset.url || "/none",
                                      false
                                    );
                                    exportDocument(
                                      `/api/v1/reports/${value.id}/report_emails/${e.id}/download_residues`,
                                      `${file_name}`,
                                      "POST"
                                    );
                                  }}
                                >
                                  Likučiai
                                </div>
                                {e.residues_asset.url && e.sales_asset.url && (
                                  <div className="mx-1">/</div>
                                )}
                              </>
                            )}
                            {e.sales_asset.url && (
                              <>
                                <div
                                  className="text-orange cursor-pointer white-hover font-semi-bold ml-1"
                                  onClick={() => {
                                    const file_name = getUrlExtension(
                                      e.sales_asset.url || "/none",
                                      false
                                    );
                                    exportDocument(
                                      `/api/v1/reports/${value.id}/report_emails/${e.id}/download_sales`,
                                      `${file_name}`,
                                      "POST"
                                    );
                                  }}
                                >
                                  Pardavimai
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      }
                    >
                      <AiOutlineDownload
                        className="text-xl cursor-pointer"
                        color="#FE9C00"
                      />
                    </Tooltip>
                  </TableUi.td>
                  <TableUi.td>
                    <AiOutlineSend
                      className="text-xl cursor-pointer"
                      color="#FE9C00"
                      onClick={async () => {
                        const { success } = await sendEmailReport(
                          value.id,
                          e.id
                        );
                        if (success) {
                          // setTimeout(() => {
                          //   setExtraFetch((prev) => !prev);
                          // }, 1000);
                        }
                      }}
                    />
                  </TableUi.td>
                </TableUi.tr>
              );
            })}
          </TableUi>
        </div>
      </div>
    </div>
  );
}

export default ReportModal;
