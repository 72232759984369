import React from "react";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components";
import floppyDisk from "../../../assets/images/floppy-disk.svg";

export const FlopppyButton = ({
  onClick,
  style,
  loading,
  className,
  disabled,
  children,
}) => {
  return (
    <FlopppyButtonStyled
      className={`btn btn-sm shadow-sm grow ${className || ""}`}
      onClick={onClick}
      style={style || {}}
      role="status"
      disabled={disabled || loading}
      aria-hidden={loading}
    >
      {loading ? (
        <span
          className="spinner-border spinner-border-sm"
          style={{ width: 15, height: 15 }}
        ></span>
      ) : children ? (
        children
      ) : (
        <img
          src={floppyDisk}
          width="15"
          style={{
            filter:
              "invert(92%) sepia(94%) saturate(29%) hue-rotate(261deg) brightness(106%) contrast(100%)",
          }}
        />
      )}
    </FlopppyButtonStyled>
  );
};

export const FlopppyButtonCancel = ({ onClick, style, className }) => {
  return (
    <FlopppyButtonStyled
      className={`btn btn-sm shadow-sm ${className || ""}`}
      onClick={onClick}
      style={style || {}}
    >
      <FaTimes size={15} color="#fff" />
    </FlopppyButtonStyled>
  );
};
export const ClassicButton = ({
  onClick,
  text,
  style,
  loading,
  customClasses,
}) => {
  return (
    <ClassicButtonStyled
      className={customClasses || "btn btn-success"}
      style={style}
      onClick={onClick}
      role="status"
      disabled={loading}
      aria-hidden={loading}
    >
      {loading ? (
        <>
          <span className="spinner-border spinner-border-sm"></span>
          <span> Vykdoma...</span>
        </>
      ) : (
        text
      )}
    </ClassicButtonStyled>
  );
};

export const ClassicButtonOutline = ({ onClick, children, loading }) => {
  return (
    <button
      // className="btn btn-success"
      // style={style}
      className="btn btn-sm btn-orange-outline"
      style={{ minWidth: "50px", maxWidth: "150px", height: "28px" }}
      onClick={onClick}
      role="status"
      disabled={loading}
      aria-hidden={loading}
    >
      {loading ? (
        <>
          <span className="spinner-border spinner-border-sm"></span>
          <span> Vykdoma...</span>
        </>
      ) : (
        children
      )}
    </button>
  );
};
const FlopppyButtonStyled = styled.button`
  width: 32px;
  height: 32px;
  background-image: linear-gradient(
    to right bottom,
    rgb(239, 56, 36),
    rgb(247, 147, 30)
  );
`;

const ClassicButtonStyled = styled.button`
  font-size: 12px;
  background-image: linear-gradient(to bottom right, #ef3824, #f7931e);
  border: 0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  &:not(:disabled):not(.disabled).active:focus,
  .btn-success:not(:disabled):not(.disabled):active:focus,
  .show > .btn-success.dropdown-toggle:focus,
  .btn-success.focus,
  .btn-success:focus,
  .btn-success:not(:disabled):not(.disabled).active,
  .btn-success:not(:disabled):not(.disabled):active,
  .show > .btn-success.dropdown-toggle,
  .btn-success:hover {
    transform: translateY(-1px);
    background-image: linear-gradient(to bottom right, #e43421, #eb8b1b);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
  border-radius: 4px !important;
  min-width: 65px;
`;
