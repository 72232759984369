import {
  LOADING_LOTTERY_TICKETS,
  GET_LOTTERY_TICKETS,
  GET_LOTTERY_PLAYERS,
  POST_LOTTERY_TICKETS,
  CLAIMED_LOTTERY,
  PUT_LOTTERY_TICKETS,
  DELETE_LOTTERY_PLAYERS,
  DELETE_LOTTERY_TICKETS,
} from "../Actions/types";

const initialState = {
  tickets: [],
  players: [],
  loading_tickets: false,
  totals_players: 0,
  totals_tickets: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_LOTTERY_TICKETS:
      return {
        ...state,
        loading_tickets: true,
      };
    case CLAIMED_LOTTERY:
      return {
        ...state,
        tickets: changeLotteryTickets(action.payload, state),
      };
    case GET_LOTTERY_TICKETS:
      return {
        ...state,
        loading_tickets: false,
        tickets: action.payload.data,
        totals_tickets: action.payload.totals,
      };

    case GET_LOTTERY_PLAYERS:
      return {
        ...state,
        loading_tickets: false,
        players: action.payload.data,
        totals_players: action.payload.totals,
      };
    case POST_LOTTERY_TICKETS:
      return {
        ...state,
        tickets: [...action.payload],
      };
    case PUT_LOTTERY_TICKETS:
      return {
        ...state,
        tickets: changeLotteryTickets(action.payload, state),
      };
    case DELETE_LOTTERY_TICKETS:
      return {
        ...state,
        tickets: [...state.tickets].filter(
          (item) => item.id !== action.payload
        ),
      };
    case DELETE_LOTTERY_PLAYERS:
      return {
        ...state,
        players: [...state.players].filter(
          (item) => item.id !== action.payload
        ),
      };
    default:
      return state;
  }
}

const changeLotteryTickets = (data, state) => {
  let lotteryTickets = [...state.tickets];
  const findIndex = lotteryTickets.findIndex((find) => find.id == data.id);
  if (findIndex !== -1) {
    lotteryTickets[findIndex] = data;
  }

  return lotteryTickets;
};

