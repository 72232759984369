import { message } from "antd";
import Axios from "axios";
import {
  antdMessageError,
  antdMessageInfo,
  antdMessageSuccess,
  errorHandler,
} from "../../../helpers/Utils";
import {
  SET_RIVILE_BIG_BOX_DATA,
  SET_RIVILE_BIG_BOX,
  LOADING_RIVILE_BIG_BOX,
  LOADING_RIVILE_BIG_BOX_DATA,
} from "./../types";
import {
  antdAsyncMessageError,
  antdAsyncMessageLoading,
  antdAsyncMessageSuccess,
} from "../../../helpers/AsyncMessage";

// const token = document.querySelector('meta[name="csrf-token"]').content;
// Axios.defaults.headers.common["X-CSRF-Token"] = token;

export const getRivileBigBoxItems =
  (page, perPage, filter) => async (dispatch) => {
    dispatch(setRivileBigBoxLoadingData());
    try {
      const { data } = await Axios.get(
        `/api/v1/rivile_requests_documents?page=${page}&per_page=${perPage}${filter}`
      );
      dispatch({
        type: SET_RIVILE_BIG_BOX_DATA,
        payload: { data: data.data, totals: data.totals },
      });
    } catch (e) {
      console.log("Error: ", e);
    }
  };

export const getRivileBigBoxItem = (id) => async (dispatch) => {
  dispatch(setRivileBigBoxLoading());
  try {
    const { data } = await Axios.get(
      `/api/v1/rivile_requests/requests_by_document/${id}`
    );
    if (data.data) {
      // const dataSorted = data.sort((a,b) => +a.rivile_code - +b.rivile_code)
      dispatch({
        type: SET_RIVILE_BIG_BOX,
        payload: {
          data: data.data,
          show_id: {
            id: id,
            created_at: data.main.created_at,
            exported_at: data.main.exported_at,
            bb_number: data.main.bb_number,
            finished_at: data.main.finished_at,
          },
        },
      });
      dispatch({
        type: "UPDATE_RIVILE_BIG_BOX",
        payload: data.main,
      });
    }
  } catch (e) {
    console.log("Error: ", e);
  }
};
export const rivileBigBoxExportToBB = (id) => async (dispatch) => {
  try {
    antdMessageInfo();
    const { data } = await Axios.post(
      "/api/v1/rivile_requests_documents/export_to_bb",
      {
        id,
      }
    );
    if (data.success) {
      antdMessageSuccess();
      dispatch(getRivileBigBoxItem(id));
      dispatch(getRivileBigBoxItems(1, 100, ""));
      return;
    }
    throw new Error("Err");
  } catch (e) {
    antdMessageError();
    console.log("Error: ", e);
  }
};

export const updateRivileBBSockets = (data) => async (dispatch, getState) => {
  if (data.deleted_at) {
    return;
  }
  // console.log({ data });
  const state = getState();
  const { bigbox, show_id } = state.bigbox;
  const findItem = bigbox.find((e) => e.id === data.id);
  if (findItem) {
    // console.log(findItem.identifier , data.identifier, findItem.identifier !== data.identifier)
    if (findItem.identifier !== data.identifier) {
      // console.log('Praejo')
      const bigBoxMap = bigbox.map((e) =>
        e.id === data.id ? { ...e, ...data, dublicate: !!e.dublicate } : e
      );
      // return
      dispatch({
        type: SET_RIVILE_BIG_BOX,
        payload: {
          data: bigBoxMap,
          show_id,
        },
      });
    }
  }
};
export const createRivileInternalDocs = (ids) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { show_id } = state.bigbox;
    console.log(show_id);
    message.info("Vykdoma...");
    const { data } = await Axios.post(
      "/api/v1/rivile_requests/make_inner_documents/",
      {
        ids,
      }
    );
    if (data.success && data.ids.length > 0) {
      dispatch(getRivileBigBoxItem(show_id.id));
      antdMessageSuccess(`Priskirti ${data.ids.length}/${ids.length}`);
      if (data.bad_ids.length > 0) {
        antdMessageError(`Nepriskirti ${data.bad_ids.length}/${ids.length}`);
      }
    } else {
      antdMessageError(`Nepriskirti ${data.bad_ids.length}/${ids.length}`);
    }
    return { success: true, bad_ids: data.bad_ids };
  } catch (err) {
    errorHandler(err);
    antdMessageError();
    return { success: false };
  }
};

export const addRivileBB = (id, itemObj) => async (dispatch, getState) => {
  const state = getState();
  const { show_id } = state.bigbox;

  const item = {
    ...itemObj,
    rivile_requests_document_id: show_id.id,
    department_code: null,
    department_name: null,
    prime_cost: 0,
    stock_quantity: 0,
    total_cost: 0,
    inner_document_number: null,
  };

  delete item.created_at;
  delete item.updated_at;
  delete item.id;
  delete item.number;
  delete item.format_items_data;
  delete item.courier_trackings;
  delete item.tracking_numbers;
  delete item.dublicate;
  delete item.bb_identifier;

  try {
    const { data } = await Axios.post(`/api/v1/rivile_requests`, {
      rivile_request: item,
    });

    const { bigbox } = state.bigbox;
    let bigBoxArr = [...bigbox];
    const findIndex = bigBoxArr.findIndex((e) => e.id == id);

    let newItem = { ...data };
    newItem.dublicate = true;
    console.log(findIndex);
    if (findIndex !== -1) {
      bigBoxArr.splice(findIndex + 1, 0, newItem);
    } else {
      bigBoxArr.unshift(newItem);
    }

    dispatch({
      type: SET_RIVILE_BIG_BOX,
      payload: {
        data: bigBoxArr,
        show_id,
      },
    });

    message.success("Sėkmingai pridėta");
    if (newItem.id) {
      const yOffset = -75;
      const element = document.getElementById(`number_${newItem.id}`);
      if (!element) return;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  } catch (e) {
    console.log("Error: ", e);
    message.error("Įvyko klaida");
  }
};

export const removeRivileBB = (id) => async (dispatch) => {
  try {
    await Axios.delete(`/api/v1/rivile_requests/${id}`);
    dispatch({
      type: "REMOVE_RIVILE_BB",
      payload: id,
    });
    message.success("Sėkmingai pridėta");
  } catch (e) {
    console.log("Error: ", e);
    message.error("Įvyko klaida");
  }

  // .then((res) => {
  //   if (res.data) {
  //     setBigBoxArr(oldArr.filter((e) => e.id !== id));

  //     message.success("Sėkmingai pridėta");
  //   } else {
  //     message.error("Įvyko klaida");
  //   }
  // });
};

export const getRivileBigBoxItemsFromImport =
  (data) => async (dispatch, getState) => {
    const getStateData = getState();
    const bigboxs = [data, ...getStateData.bigbox.bigboxs];
    const totals = getStateData.bigbox.totals + 1;
    dispatch({
      type: SET_RIVILE_BIG_BOX_DATA,
      payload: { data: bigboxs, totals },
    });
  };

export const setRivileBigBoxLoadingData = () => {
  return {
    type: LOADING_RIVILE_BIG_BOX_DATA,
  };
};
export const setRivileBigBoxLoading = () => {
  return {
    type: LOADING_RIVILE_BIG_BOX,
  };
};

export const printBigBoxLabels = async (bb_identifier) => {
  try {
    antdMessageInfo();
    let api_url = "/api/v1/rivile_requests_documents/download_labels";
    // api_url += "?bb_identifier=" + bb_identifier;
    const { data } = await Axios.post(api_url, {
      bb_identifier,
    });
    if (data.success) {
      console.log({ data });
      return;
    }
    throw new Error("Err");
  } catch (err) {
    console.log({ err });
  }
};

export const sendMailTrRequest = async (
  rivile_requests_document_id,
  rivile_request_ids
) => {
  try {
    antdAsyncMessageLoading({ key: "sendMailTrRequest" });
    const { data } = await Axios.post(
      `/api/v1/rivile_requests_documents/${rivile_requests_document_id}/send_mail_tr_request`,
      {
        rivile_request_ids,
      }
    );
    console.log({ data });
    antdAsyncMessageSuccess({ key: "sendMailTrRequest", text: data?.message });
    return {
      success: true,
    };
  } catch (err) {
    errorHandler(err);
    antdAsyncMessageError({ key: "sendMailTrRequest" });
    return {
      success: false,
    };
  }
};
