import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Select } from "antd";

const options = [
  { label: "Eksp", value: "Eksp" },
  { label: "Užsakymo data", value: "Užsakymo data" },
  { label: "Užsakymo numeris", value: "Order numeris" },
  { label: "Klientas", value: "Klientas" },
  { label: "Gabenimo adresas", value: "Gabenimo adresas" },
  { label: "Prekės", value: "Prekes" },
  { label: "Likučiai", value: "Likučiai" },
  { label: "DOCS", value: "DOCS" },
  { label: "Mokėjimo būdas", value: "Mokėjimo būdas" },
  { label: "Pristatymas", value: "Pristatymas" },
  { label: "Statusas", value: "Statusas" },
  { label: "KA komentarai", value: "KA komentarai" },
  { label: "Logistikos komentarai", value: "Logistika" },
  { label: "Vadybos komentarai", value: "Vadyba" },
  { label: "Sukurė", value: "Sukurė" },
  { label: "Suma", value: "Suma" },
];

function LayoutOfTablesModal({ layoutOfTable, layoutOfTablesFunc }) {
  const [SelectItems, setSelectItems] = useState(layoutOfTable);
  useEffect(() => {
    layoutOfTablesFunc(SelectItems);
    localStorage.setItem("orderTable", JSON.stringify(SelectItems));
  }, [SelectItems]);

  const handleChange = (selectedItems) => setSelectItems(selectedItems);

  const selectedItems = SelectItems;
  const filteredOptions = options.filter(
    (o) => !selectedItems.includes(o.value)
  );
  return (
    <div className="row">
      <div className="col">
        <div>
          <Divs className="container-title-block">
            <MainBlock>
              <span>Pasirinkimai</span>
            </MainBlock>
            <Select
              mode="multiple"
              placeholder="Inserted are removed"
              value={selectedItems}
              onChange={handleChange}
              style={{ width: "100%" }}
            >
              {filteredOptions.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Divs>
        </div>
      </div>
    </div>
  );
}

const MainBlock = styled.div`
  background-color: #f2f2f2;
  padding: 8px 10px;
  display: block;
  font-weight: 700;
  color: #000000;
  font-size: 10px;
`;

const Divs = styled.div`
  margin: 6px 0 !important;
  display: block;
  color: #000000;
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
  & .text-bold {
    font-weight: 700;
  }
  & .form-input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 0.5px);
    padding: 0.1rem 0.2rem;
    font-size: 0.6rem;
    border: 1px solid silver;
    background-color: transparent;
    color: black;
    border-radius: 0.25rem;
    &:active {
      border: 1px solid #f7951e !important;
      border-radius: 0.25rem !important;
    }
    &:focus {
      border: 1px solid #f7951e !important;
      border-radius: 0.25rem !important;
      & input {
        outline: none !important;
      }
    }
  }
  & .orange_color {
    color: #f7951e;
  }
`;

export default LayoutOfTablesModal;
