import React from "react";
import Container from "../Layout/Container";
import { connect } from "react-redux";
import {
  getDocument,
  getDocumentShow,
  clearErrors,
} from "../../redux/Actions/documentAction";
import { getDocumentTypeOption } from "../../redux/Actions/documentTypeAction";
import { getStoreItems } from "../../redux/Actions/storeAction";
import {
  updateContractTest,
  cancelDocument,
  deleteContractItemTest,
  addContractDocument,
} from "../../redux/Actions/orderContractAction";
// import DocumentModal from "./Items/DocumentModal";
import ContentBlock from "../Layout/ContentBlock";
import TableColumnDocuments from "./items/TableColumnDocuments";
import FilterMenu from "../Layout/FilterMenu";
import TableFilterBox from "../common/TableFilterBox";
import ContentValidation from "../common/ContentValidation";
import { CheckBoxInput } from "../common/Buttons";
import Pagination from "../../components/Dashboard/items/Pagination";
import InputText from "../orders/accepted_orders/InputFilter";
import RangePickerComp from "../common/RangePickerComp";
import SwitchComp from "../common/SwitchComp";
import RegularOrderChildren from "../common/GlobalModals/Orders/RegularOrderChildren";
import { getOrder } from "../../redux/Actions/orderAction";

import { Select } from "antd";
const { Option } = Select;

import moment from "moment";
import Axios from "axios";
import { AlertSuccess, AlertError } from "../common/Alerts";
import { formatDatePlus } from "../../helpers/Utils";
import DocumentModalRef from "./items/DocumentModalRef";
import { Label } from "../global";

class ListOfDocuments extends React.Component {
  constructor() {
    super();
    this.state = {
      requiredOrderId: 0,
      page: 1,
      pageCount: 100,
      paramsFilter: "",
      checkedArray: [],
      checkedAll: false,
      // documents: [],
      documentType: [],
      documentPlan: [],
      openAddModal: false,
      created: false,
      filter: {
        client: "",
        email: "",
        created_at: [
          // "2022-01-01",
          moment().format("YYYY-MM-01"),
          moment().format("YYYY-MM-DD"),
        ],
        date: ["", ""],
        phone: "",
        sf_number: "",
        number: "",
        who_created: "",
        plan: undefined,
        accord_shop: undefined,
        canceled: false,
        id_number: "",
        goods: "",
        // status: undefined,
      },
    };
    this.modalRef = React.createRef();
    this.currentModalRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.match.params.action) {
      this.onEdit("t1", this.props.match.params.action);
      this.props.history.push("/documents");
    }

    this.onFilter();
    getDocumentTypeOption().then((res) => {
      this.setState({
        ...res,
      });
    });
    this.props.getStoreItems(1, 1000);
  }
  componentDidUpdate(_, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.props.getDocument(
        this.state.page,
        this.state.pageCount,
        this.state.paramsFilter
      );
    }
  }
  getDateFormat = (date) => {
    var d = moment(date);
    return date && d.isValid() ? d : "";
  };

  filterChange = (e, date) => {
    if (date) {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            [date]: e,
          },
        },
        () => this.onFilter()
      );
    } else {
      this.setState({
        filter: {
          ...this.state.filter,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  delayOnFilter = () => {
    if (this.props.loading_documents) {
      return setTimeout(() => {
        return this.delayOnFilter();
      }, 1000);
    }
    this.onFilter();
  };

  onFilter = () => {
    if (this.props.loading_documents) {
      return this.delayOnFilter();
    }
    let filterParams = [];
    if (this.state.filter.client.length > 0) {
      let clientArray = this.state.filter.client.split(" ");
      clientArray.forEach((item) => {
        filterParams.push(`&q[customer_cont_any]=${item}`);
      });
    }
    if (this.state.filter.email.length > 0)
      filterParams.push(`&q[email_cont]=${this.state.filter.email}`);

    // created_at
    if (this.state.filter.created_at[0].length > 0)
      filterParams.push(
        `&q[created_at_gteq]=${this.state.filter.created_at[0]}`
      );

    if (this.state.filter.created_at[1].length > 0)
      filterParams.push(
        `&q[created_at_lt]=${formatDatePlus(this.state.filter.created_at[1])}`
      );

    //
    if (this.state.filter.date[0].length > 0)
      filterParams.push(`&q[buy_date_gteq]=${this.state.filter.date[0]}`);

    if (this.state.filter.date[1].length > 0)
      filterParams.push(
        `&q[buy_date_lt]=${formatDatePlus(this.state.filter.date[1])}`
      );

    if (this.state.filter.phone.length > 0) {
      let phoneNumber = this.state.filter.phone;
      if (this.state.filter.phone.search(/[\[\]?*+|{}\\()@.\n\r]/) != -1) {
        phoneNumber = phoneNumber.split("+").join("");
      }
      filterParams.push(`&q[phone_i_cont_any]=${phoneNumber}`);
    }

    if (this.state.filter.sf_number.length > 0)
      filterParams.push(
        `&q[invoice_number_cont]=${this.state.filter.sf_number}`
      );
    if (
      this.state.filter.accord_shop !== undefined &&
      this.state.filter.accord_shop.length > 0
    ) {
      if (this.state.filter.accord_shop === "ONLINE") {
        filterParams.push(`&q[store_id_null]=true`);
      } else {
        filterParams.push(
          `&q[store_name_cont]=${this.state.filter.accord_shop}`
        );
      }
    }
    if (this.state.filter.who_created.length > 0)
      filterParams.push(`&q[user_title_cont]=${this.state.filter.who_created}`);

    if (this.state.filter.plan !== undefined)
      filterParams.push(`&q[document_plan_id_eq]=${this.state.filter.plan}`);
    // if (this.state.filter.status !== undefined)
    //   filterParams.push(`&q[total_paid_eq]=${this.state.filter.status}`);

    if (this.state.filter.canceled) {
      filterParams.push(`&q[canceled_at_not_null]=true`);
    } else {
      filterParams.push(`&q[canceled_at_null]=true`);
    }
    if (this.state.filter.id_number.length > 0) {
      filterParams.push(
        `&q[contract_items_id_number_or_contract_items_additional_data_raw_cont]=${this.state.filter.id_number}`
      );
    }

    if (this.state.filter.goods?.length > 0) {
      filterParams.push(
        `&q[contract_items_manufacturer_or_contract_items_model_or_contract_items_product_reference_or_contract_items_order_row_product_reference_i_cont_any]=${this.state.filter.goods}`
      );
    }
    if (this.state.filter.number.length > 0) {
      filterParams.push(`&q[number_i_cont_any]=${this.state.filter.number}`);
    }
    this.setState(
      {
        paramsFilter: filterParams.join(""),
        page: 1,
      },
      () => this.reloadData(filterParams.join(""))
    );
  };

  reloadData = (data) => {
    this.props.getDocument(this.state.page, this.state.pageCount, data);
  };

  setPagination = (page) => {
    this.setState({
      page,
    });
  };
  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  handleKeyDown = (e) => {
    if (e.key == "Enter") {
      this.onFilter();
    }
  };

  handleCheckboxChange = (value) => {
    const currentIndex = this.state.checkedArray.indexOf(value);
    const newChecked = [...this.state.checkedArray];
    const myData = this.props.documents;

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    let variableAll = myData.length === newChecked.length;

    this.setState({
      checkedArray: newChecked,
      checkedAll: variableAll,
    });
  };

  handleCheckboxAll() {
    const myData = this.props.documents;
    const newChecked = [...this.state.checkedArray];
    let newArray = [];
    if (myData.length === newChecked.length) {
      this.setState({
        checkedAll: false,
        checkedArray: [],
      });
    } else {
      myData.forEach((item) => newArray.push(item.id));
      this.setState({
        checkedAll: true,
        checkedArray: newArray,
      });
    }
  }

  onEdit = (mode, id) => {
    this.props.getDocumentShow(id);
    this.currentModalRef.current.openModal("DocumentShow");
    if (mode == "created") {
      this.setState({
        created: true,
      });
    }
  };

  openModal = () => {
    this.currentModalRef.current.openModal("Document_add");
    // this.setState({
    //   openAddModal: true,
    // });
  };

  createContract = (id, postVariables) => {
    return this.props.addContractDocument(id, postVariables).then((res) => {
      if (res !== undefined) {
        if (res.data) {
          document.getElementById("modalClose").click();
          document.getElementById(res.data.id).click();
          this.setState({
            created: true,
          });
        }
        return true;
      }
    });
  };

  exportContracts = (action) => {
    let url;
    if (action == "selected") {
      url = "/api/v1/contracts/download_summary";
    } else if (action == "all") {
      url = `/api/v1/contracts/download_summary_all?${this.state.paramsFilter}`;
    }
    Axios({
      url: url,
      method: "POST",
      responseType: "blob", // important
      headers: { Accept: "application/vnd.ms-excel" },
      data: {
        download_summary: this.state.checkedArray,
      },
    }).then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", `${"Contract_excel"}.xlsx`); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  };

  exportContractsPpg = (action) => {
    let url;
    if (action == "selected") {
      url = "/api/v1/contracts/download_ppg_grid";
    } else if (action == "all") {
      url = `/api/v1/contracts/download_ppg_grid_all?${this.state.paramsFilter}`;
    }
    Axios({
      url: url,
      method: "POST",
      responseType: "blob", // important
      headers: { Accept: "application/vnd.ms-excel" },
      data: {
        download_summary: this.state.checkedArray,
      },
    }).then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", `${"ppg_grid"}.xlsx`); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  };

  clearFilter = () => {
    this.setState(
      {
        filter: {
          client: "",
          email: "",
          date: ["", ""],
          created_at: ["", ""],
          phone: "",
          sf_number: "",
          number: "",
          who_created: "",
          plan: undefined,
          accord_shop: undefined,
          canceled: false,
          id_number: "",
          goods: "",
        },
      },
      () => this.reloadData("")
    );
  };

  renderCustomButton = () => {
    return (
      <>
        {["admin", "accounting"].includes(
          this.props.auth.user_role?.identifier
        ) && (
          <div className="dropdown">
            <button
              className="btn btn-sm btn-dark float-right mr-3 dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Eksportuoti
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <button
                className="dropdown-item"
                onClick={() => this.exportContracts("selected")}
              >
                Eksportuoti pasirinktus
              </button>
              <button
                className="dropdown-item"
                onClick={() => this.exportContracts("all")}
              >
                Eksportuoti išfiltruotus
              </button>
              <button
                className="dropdown-item"
                onClick={() => this.exportContractsPpg("selected")}
              >
                Eksportuoti pasirinktus PPG
              </button>
              <button
                className="dropdown-item"
                onClick={() => this.exportContractsPpg("all")}
              >
                Eksportuoti išfiltruotus PPG
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  orderModal = (order_id, id) => {
    this.setState({
      requiredOrderId: order_id,
    });
    this.props.getOrder(id);
    this.modalRef.current.openModal("OrderShowModal");
  };
  render() {
    const textOfDiv = (
      <div>
        <span className="filterMenuText first">Kontraktų suma:</span>
        <span className="firstLength">
          {this.props.total_price} {"\u20AC"}
        </span>
      </div>
    );
    return (
      <Container
        location={{
          locationName: "Dokumentai",
          locationUrl: this.props.match.url,
        }}
      >
        <ContentBlock>
          <FilterMenu
            length={`${this.props.totals || 0} / ${
              this.props.documents.length || 0
            }`}
            customText={textOfDiv}
            onChangeCountPage={this.setPageCount}
            countPage={this.state.pageCount}
            text="dokumentų"
            text2="Dokumentų"
          />
        </ContentBlock>
        <AlertSuccess alertId={"order-s"} />
        <AlertError alertId={"order-e"} />
        <ContentBlock>
          <TableFilterBox
            filterText={"Eksportuoti"}
            add={
              this.props.auth.user_role?.identifier == "staff"
                ? null
                : () => this.openModal()
            }
            button1={this.renderCustomButton()}
            clearFilter={() => this.clearFilter()}
          >
            <div className="px-1">
              <div className="form-group">
                <label className="editLabelForm">Sukūrimo data:</label>
                <div>
                  <RangePickerComp
                    placeholder={["Pradžios laikas", "Pabaigos laikas"]}
                    value={[
                      this.getDateFormat(this.state.filter.created_at[0]),
                      this.getDateFormat(this.state.filter.created_at[1]),
                    ]}
                    onChange={(_, args) =>
                      this.filterChange(args, "created_at")
                    }
                  />
                </div>
              </div>
            </div>
            <div className="px-1">
              <div className="form-group">
                <label className="editLabelForm">Pirkimo data:</label>
                <div>
                  <RangePickerComp
                    placeholder={["Pradžios laikas", "Pabaigos laikas"]}
                    value={[
                      this.getDateFormat(this.state.filter.date[0]),
                      this.getDateFormat(this.state.filter.date[1]),
                    ]}
                    onChange={(e, args) => this.filterChange(args, "date")}
                  />
                </div>
              </div>
            </div>

            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="client"
                placeholder="Vardas, pavardė"
                value={this.state.filter.client}
                onKeyDown={this.handleKeyDown}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="email"
                placeholder="El. paštas"
                value={this.state.filter.email}
                onKeyDown={this.handleKeyDown}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="phone"
                placeholder="Tel. Nr."
                onKeyDown={this.handleKeyDown}
                value={this.state.filter.phone}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="number"
                placeholder="Sertif. Nr."
                onKeyDown={this.handleKeyDown}
                value={this.state.filter.number}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="sf_number"
                placeholder="Pirk. Dok. Nr."
                onKeyDown={this.handleKeyDown}
                value={this.state.filter.sf_number}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                name="id_number"
                placeholder={"S/N ar IMEI"}
                value={this.state.filter.id_number}
                onKeyDown={this.handleKeyDown}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="goods"
                placeholder="Prekė"
                onKeyDown={this.handleKeyDown}
                value={this.state.filter.goods}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            {this.props.auth.user_role?.identifier !== "store_user" && (
              <>
                <div className="px-1">
                  <InputText
                    width={"0.75rem"}
                    number="1"
                    name="who_created"
                    placeholder="Darbuotojas"
                    onKeyDown={this.handleKeyDown}
                    value={this.state.filter.who_created}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
                <div className="px-1">
                  <Label>Parduotuvė</Label>
                  <div>
                    <Select
                      placeholder={"Parduotuvė"}
                      value={this.state.filter.accord_shop}
                      style={{ width: "200px" }}
                      onChange={(e) => this.filterChange(e, "accord_shop")}
                    >
                      {[
                        {
                          id: 1515156,
                          name: "",
                        },
                        { id: 151535, name: "ONLINE" },
                        ...this.props.storeItems,
                      ].map((item) => (
                        <Option key={item.id} value={item.name}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </>
            )}

            <SwitchComp
              name="Atšauktas"
              placeholder="Atšaukti/Grąžinti pinigai"
              checked={this.state.filter.canceled}
              onChange={() =>
                this.setState(
                  {
                    filter: {
                      ...this.state.filter,
                      canceled: !this.state.filter.canceled,
                    },
                  },
                  () => this.onFilter()
                )
              }
            />
          </TableFilterBox>
          <div>
            <ContentValidation
              loader={this.props.loading_documents}
              array={this.props.documents}
            >
              <div className="table-responsive">
                <table className="table table-sm table-hover mt-3">
                  <thead>
                    <tr className="text-muted">
                      <th className="text-black">
                        <div className="d-flex">
                          <CheckBoxInput
                            type="checkbox"
                            color="#f7951e"
                            checked={this.state.checkedAll}
                            onChange={() => this.handleCheckboxAll()}
                          />
                        </div>
                      </th>
                      <th className="text-black"></th>
                      <th className="text-black">Sukurta</th>
                      <th className="text-black">Pirkimo data</th>
                      {this.props.auth.user_role?.identifier !==
                        "store_user" && <th className="text-black">Sukūrė</th>}
                      <th className="text-black">Info.</th>
                      <th className="text-black">Išs.</th>
                      <th className="text-black">Atš.</th>
                      <th className="text-black">Paslauga</th>
                      <th className="text-black">Parduotuvė</th>
                      <th className="text-black">Sertif. Nr.</th>
                      <th className="text-black">Klientas</th>
                      <th className="text-black">El. p. adresas</th>
                      <th className="text-black">Tel. Nr.</th>
                      <th className="text-black">Pirk. Dok. Nr.</th>
                      <th className="text-black">Galioja nuo</th>
                      <th className="text-black">Galioja iki</th>
                      <th className="text-black">Suma</th>
                      <th className="text-black">PPG Remonto suma</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.documents.map((item, index) => {
                      return (
                        <TableColumnDocuments
                          key={index}
                          index={index}
                          id={item.id}
                          item={item}
                          role={this.props.auth.user_role?.identifier}
                          onDelete={this.props.deleteDocument}
                          handleCheckboxChange={this.handleCheckboxChange}
                          checkedArray={this.state.checkedArray}
                          onEdit={this.onEdit}
                          orderModal={this.orderModal}
                        />
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                  setPagination={this.setPagination}
                  page={this.state.page}
                  totals={this.props.totals}
                  pageCount={this.state.pageCount}
                />
              </div>
            </ContentValidation>
          </div>
        </ContentBlock>
        <div id="modal-roots" />
        <DocumentModalRef
          ref={this.currentModalRef}
          // Document show
          auth={this.props.auth}
          createdNew={this.state.created}
          stopCreateNew={(stop) => this.setState({ created: false })}
          documentType={this.state.documentType}
          documentPlan={this.state.documentPlan}
          updateContract={this.props.updateContractTest}
          deleteContractItem={this.props.deleteContractItemTest}
          cancelDocument={this.props.cancelDocument}
          getDocumentShow={this.props.getDocumentShow}
          // Document add
          onEdit={this.onEdit}
        />
        <RegularOrderChildren
          ref={this.modalRef}
          requiredOrderId={this.state.requiredOrderId}
        />
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth.user,
  storeItems: state.store.storeItems,
  documents: state.documents.documents,
  loading_documents: state.documents.loading_documents,
  totals: state.documents.totals,
  total_price: state.documents.total_price,
  // smsItems: state.sms.smsItems,
  // totals: state.sms.totals,
  // loading_sms: state.sms.loading_sms,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getOrder,
  getDocument,
  getStoreItems,
  cancelDocument,
  getDocumentShow,
  updateContractTest,
  deleteContractItemTest,
  addContractDocument,
  clearErrors,
})(ListOfDocuments);
//
