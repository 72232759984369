import React from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import TextInputModal from "../../../../common/TextInputModal";

function ServiceFormOrder({ onChangeHandler, errors, form }) {
  const onChange = (e, idx) => {
    const old = [...form.goods];
    old[idx][e.target.name] = e.target.value;

    return onChangeHandler(
      {
        target: {
          name: "goods",
          value: old,
        },
      },
      "form"
    );
  };
  return (
    <>
      <div className="mx-0">
        {form.goods.map((e, idx) => (
          <div className="d-block mb-2" key={idx}>
            <div className="d-flex flex-start align-items-center">
              <div style={{ width: 400 }}>
                <TextInputModal
                  name="item_name"
                  onChange={(e) => onChange(e, idx)}
                  value={e.item_name}
                />
              </div>
              <div className="mx-2" style={{ width: 200 }}>
                <TextInputModal
                  type="number"
                  name="item_quantity"
                  onChange={(e) => onChange(e, idx)}
                  value={e.item_quantity}
                />
              </div>
              <div style={{ width: 200 }}>
                <TextInputModal
                  type="number"
                  name="item_price"
                  onChange={(e) => onChange(e, idx)}
                  value={e.item_price}
                />
              </div>
              <button
                className="btn d-flex justify-content-center align-items-center mx-2"
                style={{
                  backgroundColor: "#F7931E",
                  width: 32,
                  height: 32,
                  borderRadius: 2,
                  padding: 5,
                }}
                onClick={() => {
                  const old = [...form.goods];
                  old.splice(idx + 1, 0, {
                    item_name: "",
                    item_price: "",
                    item_quantity: "",
                  });

                  return onChangeHandler(
                    {
                      target: {
                        name: "goods",
                        value: old,
                      },
                    },
                    "form"
                  );
                }}
              >
                <AiOutlinePlus style={{ color: "#fff" }} />
              </button>
              {form.goods.length > 1 && (
                <button
                  className="btn d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "#fff",
                    border: "1px solid #F7931E",
                    width: 32,
                    height: 32,
                    borderRadius: 2,
                    padding: 5,
                  }}
                  onClick={() => {
                    const old = [...form.goods];
                    old.splice(idx, 1);

                    return onChangeHandler(
                      {
                        target: {
                          name: "goods",
                          value: old,
                        },
                      },
                      "form"
                    );
                  }}
                >
                  <AiOutlineMinus
                    style={{
                      color: "#F7931E",
                    }}
                  />
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default ServiceFormOrder;
