import React from "react";
import Container from "../Layout/Container";
import { connect } from "react-redux";
import {
  getStoreItems,
  postStoreItem,
  deleteStoreItem,
  updateStoreItem,
  clearErrors,
} from "../../redux/Actions/storeAction";
import StoreEditModal from "./Items/StoreEditModal";
import ContentBlock from "../Layout/ContentBlock";
import Pagination from "../Dashboard/items/Pagination";
import TableColumnStore from "./Items/TableColumnStore";
import FilterMenu from "../Layout/FilterMenu";
import TableFilterBox from "../common/TableFilterBox";
import ContentValidation from "../common/ContentValidation";
import InputText from "../orders/accepted_orders/InputFilter";

class Stores extends React.Component {
  constructor() {
    super();
    this.state = {
      nameValue: "",
      addressValue: "",
      rivileValue: "",
      requiredId: 0,
      page: 1,
      pageCount: 100,
      paramsFilter: "",
      filter: {
        name: "",
        address: "",
        rivile_code: "",
      },
      action: "new",
    };

    this.handleChangeText = this.handleChangeText.bind(this);
  }
  componentDidMount() {
    this.reloadData(this.state.paramsFilter);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.reloadData(this.state.paramsFilter);
    }
  }
  reloadData = (paramsFilter) => {
    this.props.getStoreItems(
      this.state.page,
      this.state.pageCount,
      paramsFilter
    );
  };

  onSubmit = (e) => {
    e.preventDefault();
    let data = {
      store: {
        name: this.state.nameValue,
        address: this.state.addressValue,
        rivile_code: this.state.rivileValue,
      },
    };
    this.props.postStoreItem(data);
  };

  saveModalDetails = (item) => {
    const requiredItem = this.state.requiredId;
    const variables = {
      store: {
        id: item.id,
        name: item.name,
        address: item.address,
        rivile_code: item.rivile_code,
      },
    };
    this.props.updateStoreItem(item.id, variables, requiredItem);
  };

  onAction = (action, index) => {
    this.setState({
      requiredId: index,
      action: action,
    });
  };

  handleChangeText(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  filterChange = (e) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [e.target.name]: e.target.value,
      },
    });
  };
  delayOnFilter = () => {
    if (this.props.loading_store) {
      return setTimeout(() => {
        return this.delayOnFilter();
      }, 1000);
    }
    this.onFilter();
  };

  onFilter = () => {
    if (this.props.loading_store) {
      return this.delayOnFilter();
    }
    let filterParams = [];
    if (this.state.filter.name.length > 0)
      filterParams.push(`&q[name_i_cont_any]=${this.state.filter.name}`);
    if (this.state.filter.address.length > 0)
      filterParams.push(`&q[address_i_cont_any]=${this.state.filter.address}`);
    if (this.state.filter.rivile_code.length > 0)
      filterParams.push(
        `&q[rivile_code_i_cont_any]=${this.state.filter.rivile_code}`
      );
    this.setState(
      {
        paramsFilter: filterParams.join(""),
        page: 1,
      },
      () => this.reloadData(filterParams.join(""))
    );
  };
  setPagination = (page) => {
    this.setState({
      page,
    });
  };
  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  handleKeyDown = (e) => {
    if (e.key == "Enter") {
      this.onFilter();
    }
  };

  clearFilter = () => {
    this.setState(
      {
        filter: {
          name: "",
          address: "",
          rivile_code: "",
        },
      },
      () => this.onFilter()
    );
  };

  render() {
    const requiredItem = this.state.requiredId;
    let modalData = this.props.storeItems[requiredItem]
      ? this.props.storeItems[requiredItem]
      : [];
    return (
      <Container
        location={{
          locationName: "Parduotuvės",
          locationUrl: this.props.match.url,
        }}
      >
        <ContentBlock>
          <FilterMenu
            length={`${this.props.totals || 0} / ${
              this.props.storeItems.length || 0
            }`}
            onChangeCountPage={this.setPageCount}
            countPage={this.state.pageCount}
            text="parduotuvių"
            text2="Parduotuvių"
          />
        </ContentBlock>
        <ContentBlock>
          <TableFilterBox
            // onFilter={this.onFilter}
            // filter={true}
            add={this.onAction}
            clearFilter={() => this.clearFilter()}
          >
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="name"
                placeholder="Pavadinimas"
                onKeyDown={this.handleKeyDown}
                value={this.state.filter.name}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="address"
                placeholder="Adresas"
                onKeyDown={this.handleKeyDown}
                value={this.state.filter.address}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="rivile_code"
                placeholder="Rivilės kodas"
                onKeyDown={this.handleKeyDown}
                value={this.state.filter.rivile_code}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
          </TableFilterBox>
          <div>
            <ContentValidation
              loader={this.props.loading_store}
              array={this.props.storeItems}
              alertId_s="storeAlert-s"
              alertId_e="storeAlert-e"
            >
              <div className="table-responsive">
                <table className="table table-sm table-hover mt-3">
                  <thead>
                    <tr className="text-muted">
                      <th className="text-black">#</th>
                      <th className="text-black">Pavadinimas</th>
                      <th className="text-black">Adresas</th>
                      <th className="text-black">Rivilės kodas</th>
                      <th className="text-black">Bigbox Import</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.storeItems.map((item, index) => {
                      return (
                        <TableColumnStore
                          key={index}
                          index={index}
                          item={item}
                          onAction={this.onAction}
                          onDelete={this.props.deleteStoreItem}
                        />
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                  setPagination={this.setPagination}
                  page={this.state.page}
                  totals={this.props.totals}
                  pageCount={this.state.pageCount}
                />
              </div>
            </ContentValidation>
          </div>
        </ContentBlock>

        <StoreEditModal
          stores={this.props.storeItems.filter((d) => d.bigbox_import)}
          action={this.state.action}
          objectData={modalData}
          clearErrors={this.props.clearErrors}
          postStoreItem={this.props.postStoreItem}
          updateStoreItem={this.props.updateStoreItem}
          errors={this.props.errors}
        />
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  storeItems: state.store.storeItems,
  totals: state.store.totals,
  loading_store: state.store.loading_store,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getStoreItems,
  postStoreItem,
  deleteStoreItem,
  updateStoreItem,
  clearErrors,
})(Stores);
