import { Checkbox } from "antd";
import React from "react";
import { reportDepOptions } from "../../../helpers/GlobalOptions";
import { CheckBoxInput } from "../../common/Buttons";
import EmailSetterInput from "../../common/EmailSetterInput";
import InputForm from "../../common/InputForm";
import SelectInput from "../../common/SelectInput";
import ModalToChange from "../../Layout/ModalToChange";

class StoreEditModal extends React.Component {
  constructor() {
    super();
    this.state = {
      id: 0,
      name: "",
      address: "",
      rivile_code: "",
      bigbox_import: false,
      departments: [],
      emails: [],
      pickup_emails: [],
      action: "new",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.action === "new" && this.state.action === "edit") {
      this.setState({
        name: "",
        address: "",
        rivile_code: "",
        bigbox_import: false,
        departments: [],
        emails: [],
        pickup_emails: [],
        report_type: undefined,
        action: "new",
      });
      this.removeErrors(nextProps.errors);
    } else if (
      nextProps.action === "edit" &&
      nextProps.objectData.id !== this.state.id
    ) {
      let item = nextProps.objectData;
      this.setState({
        id: item.id || 0,
        name: item.name || "",
        address: item.address || "",
        rivile_code: item.rivile_code || "",
        bigbox_import: item.bigbox_import || false,
        departments: item.departments || [],
        emails: item.emails || [],
        pickup_emails: item.pickup_emails || [],
        report_type: item.report_type || undefined,
        action: "edit",
      });
      this.removeErrors(nextProps.errors);
    }
  }

  removeErrors = (errors) => {
    if (Object.keys(errors).length > 0) {
      this.props.clearErrors();
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const variables = {
      store: {
        name: this.state.name,
        address: this.state.address,
        rivile_code: this.state.rivile_code,
        bigbox_import: this.state.bigbox_import,
        departments: this.state.departments,
        emails: this.state.emails,
        pickup_emails: this.state.pickup_emails,
        report_type: this.state.report_type,
      },
    };
    if (this.props.action == "new") {
      this.props.postStoreItem(variables);
    } else if (this.props.action === "edit") {
      this.props.updateStoreItem(this.state.id, variables);
    }
  };

  render() {
    return (
      <ModalToChange
        handleSave={(e) => this.onSubmit(e)}
        status="tableEdit"
        text={this.props.action === "new" ? "Kurti naują" : "Redaguoti"}
        buttonText={this.props.action === "new" ? "Sukurti" : "Atnaujinti"}
      >
        <InputForm
          type="text"
          placeholder="Pavadinimas"
          name="name"
          value={this.state.name}
          error={
            this.props.errors.name &&
            this.props.errors.name.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Adresas"
          name="address"
          value={this.state.address}
          error={
            this.props.errors.address &&
            this.props.errors.address.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Rivilės kodas"
          name="rivile_code"
          value={this.state.rivile_code}
          error={
            this.props.errors.rivile_code &&
            this.props.errors.rivile_code.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />

        <div className="form-group py-2">
          <EmailSetterInput
            label="[Pickup] Padalinio el.paštai"
            emails={this.state.pickup_emails}
            onChangeEmails={(value) =>
              this.handleChange({ target: { value, name: "pickup_emails" } })
            }
          />
        </div>
        <div className="form-group py-2">
          <EmailSetterInput
            label="[BB] Padalinio el.paštai"
            emails={this.state.emails}
            onChangeEmails={(value) =>
              this.handleChange({ target: { value, name: "emails" } })
            }
          />
        </div>
        <div className="form-group py-2">
          <CheckBoxInput
            choised="bigbox_import"
            color="#f7951e"
            text="Bigbox Import"
            checked={this.state.bigbox_import}
            onChange={(e) =>
              this.handleChange({
                target: { name: "bigbox_import", value: e.target.checked },
              })
            }
          />
        </div>

        <label className="editLabelForm">Gali prekiauti iš</label>
        <div className="d-flex flex-wrap">
          {this.props.stores.map((d) => {
            const checked = this.state.departments.includes(d.rivile_code);
            const onClick = () => {
              this.setState((prev) => {
                let departments = prev.departments;
                if (departments.includes(d.rivile_code))
                  departments = departments.filter(
                    (val) => val !== d.rivile_code
                  );
                else departments.push(d.rivile_code);

                return {
                  departments,
                };
              });
            };
            return (
              <div
                key={d.id}
                className="mr-2 mb-2 cursor-pointer px-2 py-1"
                style={{
                  background: checked ? "#FFF6E8" : "#fff",
                  border: `1px solid ${checked ? "#FE9C00" : "#D9D9D9"}`,
                  borderRadius: 4,
                  fontSize: 15,
                  // lineHeight: 18,
                  textAlign: "center",
                  color: checked ? "#FE9C00" : "#6C6C6C",
                }}
                onClick={onClick}
              >
                <div className="d-flex align-items-center">
                  <Checkbox checked={checked} className="mr-2" />
                  {d.name}
                </div>
              </div>
            );
          })}
        </div>

        <SelectInput
          label="Ataskaitos"
          value={this.state.report_type}
          options={reportDepOptions}
          onChange={(value) => {
            this.handleChange({
              target: { name: "report_type", value },
            });
          }}
        />
      </ModalToChange>
    );
  }
}

export default StoreEditModal;
