import { 
  GET_LEASINGS, 
  LOADING_LEASINGS, 
  LOADING_LEASING, 
  UPDATE_LEASING,
  CREATE_LEASING, 
  GET_LEASING, 
  DELETE_LEASING 
} from "../Actions/types";

const initialState = {
  leasings: [],
  totals: 0,
  loading_leasings: false,
  loading_leasing: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_LEASINGS:
      return {
        ...state,
        loading_leasings: true,
      };
    case LOADING_LEASING:
      return {
        ...state,
        loading_leasing: true,
      };
    case GET_LEASINGS:
      return {
        ...state,
        leasings: action.payload.data,
        totals: action.payload.totals,
        loading_leasings: false,
      };
    case GET_LEASING:
        return {
          ...state,
          leasing: action.payload.data,
          loading_leasing: false,
        };
    case UPDATE_LEASING:
      let getData = [...state.leasings];
      const updateElementId = element => element.id === action.payload_id;
      const findIndex = getData.findIndex(updateElementId);
      getData[findIndex] = action.payload;
      return {
        ...state,
        leasing: action.paylo,
        leasings: getData,
        loading_leasing: false,
      };
    case DELETE_LEASING:
      return {
        ...state,
        leasings: [...state.leasings].filter(
          (item) => item.id !== action.payload_id
        ),
        loading_leasing: false,
      };
    case CREATE_LEASING:
      let getCrerateData = [...state.leasings];
      getCrerateData.unshift(action.payload)
      return {
        ...state,
        leasing: action.payload,
        leasings: getCrerateData,
        loading_leasing: false,
      };
    default:
      return state;
  }
}
