import React, { useRef } from "react";
import AntdConfirm from "../../helpers/AntdConfirm";
import { antdMessageError, getBase64WithCallback } from "../../helpers/Utils";
import { IconDelete, IconPlus } from "../Layout/Icons";
import { Label } from "../global";
import FileViewer from "react-file-viewer";

const DataSheetItem = ({ label, value, document, base64, setValue }) => {
  // console.count("DataSheetItem");
  const fileRef = useRef();
  const file_exist = document?.url || typeof document == "string";

  const onChange = () => {
    if (file_exist) {
      AntdConfirm({
        content: "Are you sure you want to delete this data sheet?",
        onOk: () => {
          console.log("remove");
          return setValue((prev) => ({
            ...prev,
            [value]: null,
          }));
        },
      });
    } else {
      fileRef.current.click();
    }
  };

  const onChangeFile = (e) => {
    const file = e.target.files[0];
    if (file.size == 0) return antdMessageError("Blogas failas");
    if (value == "label_image") {
      if (!file.type.includes("image")) {
        return antdMessageError("Blogas formatas");
      }
    } else {
      if (!file.type.includes("application/pdf")) {
        return antdMessageError("Blogas formatas");
      }
    }
    getBase64WithCallback(file, (imgObj) => {
      setValue((prev) => {
        return { ...prev, [value]: imgObj.url };
      });
    });
    e.target.value = "";
  };

  return (
    <>
      <Label>{label}</Label>
      <div
        className="position-relative d-flex align-items-center justify-content-center flex-column mb-3"
        style={{
          background: "#FAFAFA",
          border: "1px dashed #D9D9D9",
          borderRadius: 2,
          minHeight: 104,
        }}
        onClick={() => {
          if (file_exist) return;
          onChange();
        }}
      >
        {file_exist ? (
          <>
            <div
              className="my-2"
              style={{
                width: "100%",
                height: 140,
                backgroundImage: `url(${document?.url ? base64 : document})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
              }}
            >
              {value == 'data_sheet' && (
                <FileViewer
                  fileType={"pdf"}
                  filePath={document?.url ? base64 : document}
                  errorComponent={<div />}
                  onError={(e) => console.log("Error: : ", e)}
                />
              )}
            </div>
            <div
              className="position-absolute"
              style={{
                right: 10,
                top: 10,
              }}
              onClick={onChange}
            >
              <IconDelete className="cursor-pointer" />
            </div>
          </>
        ) : (
          <div className="d-flex flex-column align-items-center justify-content-center cursor-pointer">
            <IconPlus />
            <span
              className="text-base"
              style={{
                color: "rgba(0, 0, 0, 0.45)",
              }}
            >
              Įkelti
            </span>
          </div>
        )}
      </div>
      <input
        accept={value == 'label_image' ? "image/png, image/gif, image/jpeg" : "application/pdf"}
        ref={fileRef}
        type="file"
        hidden
        onChange={onChangeFile}
      />
    </>
  );
};

export default DataSheetItem;
