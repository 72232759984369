import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { MdEdit, MdSave } from "react-icons/md";
import { addOverFlow, removeOverFlow } from "../../helpers/modalFunc";
import ModalSpecial from "../Layout/Modals/ModalSpecial";
import ReportModal from "./ReportModal";

function ReportModalWrapper({
  reportSelected,
  closeModal,
  newValue,
  setNewValue,
}) {
  const [editable, setEditable] = useState(false);
  useEffect(() => {
    if (reportSelected) {
      addOverFlow();
    }
  }, []);

  if (reportSelected) {
    return ReactDOM.createPortal(
      <ModalSpecial
        style={{
          width: 800,
        }}
        text={
          <span
            style={{
              fontSize: "1.2rem",
              lineHeight: "27px",
              color: "#000000",
              fontWeight: "700",
            }}
          >
            Ataskaita
          </span>
        }
        extras={
          <div
            className="rounded text-center cursor-pointer d-flex align-items-center justify-content-center"
            style={{
              backgroundColor: "#FE9C00",
              width: 33,
              height: 33,
            }}
            onClick={() => setEditable((prev) => !prev)}
          >
            {editable ? <MdSave color="#FFFFFF" /> : <MdEdit color="#FFFFFF" />}
          </div>
        }
        handleSave={() => {}}
        onClose={() => {
          closeModal();
          removeOverFlow();
        }}
      >
        <ReportModal
          report_id={reportSelected}
          editable={editable}
          setEditable={setEditable}
          newValue={newValue}
          setNewValue={setNewValue}
        />
      </ModalSpecial>,
      document.getElementById("custom_modal")
    );
  }
  return null;
}

export default ReportModalWrapper;
