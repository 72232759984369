import React from "react";
import Container from "../Layout/Container";
import { connect } from "react-redux";
import {
  getLeasings,
  updateLeasing,
  deleteLeasing,
} from "../../redux/Actions/leasingsAction";
import { getBrands } from "../../redux/Actions/brandAction";
import { getFamilies } from "../../redux/Actions/familyAction";
import { getProducts } from "../../redux/Actions/productAction";
import ContentBlock from "../Layout/ContentBlock";
import TableColumnLeasing from "./Items/TableColumnLeasing";
import LeasingEditModal from "./Items/LeasingEditModal_v2";
import FilterMenu from "../Layout/FilterMenu";
import TableFilterBox from "../common/TableFilterBox";
import ContentValidation from "../common/ContentValidation";
import Pagination from "../Dashboard/items/Pagination";
import SwitchComp from "../common/SwitchComp";
import RangePickerComp from "../common/RangePickerComp";
import moment from "moment";
import { SelectForm } from "../common/Buttons";

const initialFilterState = {
  category: [],
  brand: [],
  product: [],
  start: "",
  end: "",
  enabled: "",
  months: "",
};

let brandSearchTimeout;
let productSearchTimeout;
let familySearchTimeout;

class Leasing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyValue: "",
      page: 1,
      pageCount: 100,
      paramsFilter: "",
      filter: initialFilterState,
      selected: [],
      selectedAll: false,
    };
    this.available_edit = props.auth.user_role.identifier !== "hosts";
  }

  componentDidMount() {
    this.reloadData(this.state.paramsFilter);
    this.loadData();
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.reloadData(this.state.paramsFilter);
    }
  }

  loadData = () => {};

  searchBrands = (value) => {
    clearTimeout(brandSearchTimeout);
    if (value) {
      brandSearchTimeout = setTimeout(() => {
        this.props.getBrands(1, 5, `&q[title_i_cont]=${value}`);
      }, 500);
    }
  };

  searchProducts = (value) => {
    clearTimeout(productSearchTimeout);
    if (value) {
      productSearchTimeout = setTimeout(() => {
        this.props.getProducts(1, 5, `&q[title_or_identifier_i_cont]=${value}`);
      }, 500);
    }
  };

  searchFamilies = (value) => {
    clearTimeout(familySearchTimeout);
    if (value) {
      familySearchTimeout = setTimeout(() => {
        this.props.getFamilies(1, 5, `&q[name_i_cont]=${value}`);
      }, 500);
    }
  };

  reloadData = (paramsFilter) => {
    this.props.getLeasings(this.state.page, this.state.pageCount, paramsFilter);
  };

  setPagination = (page) => {
    this.setState({
      page,
    });
  };

  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  onAction = (id) => {
    this.setState({
      requiredId: id,
    });
  };

  onDelete = (id) => {
    if (confirm("Ar tikrai norite ištrinti ?")) {
      this.props.deleteLeasing(id);
    }
  };

  onToggle = (value, index) => {
    let item = this.props.leasings[index] ? this.props.leasings[index] : false;
    if (item) {
      item.leasing_enabled = value;
      this.props.updateLeasing(item.id, item);
    }
  };

  delayOnFilter = () => {
    if (this.props.loading_brands) {
      return setTimeout(() => {
        return this.delayOnFilter();
      }, 1000);
    }
    this.onFilter();
  };

  onFilter = () => {
    if (this.props.loading_brands) {
      return this.delayOnFilter();
    }

    let filterParams = [];
    if (this.state.filter.enabled !== "") {
      filterParams.push(`&q[leasing_enabled_eq]=${this.state.filter.enabled}`);
    }
    if (this.state.filter.months) {
      filterParams.push(`&q[months_eq]=${this.state.filter.months}`);
    }
    if (
      this.state.filter.start.length > 0 ||
      this.state.filter.end.length > 0
    ) {
      //filterParams.push(`&q[g][0][m]=or`);
      if (
        this.state.filter.start.length > 0 &&
        this.state.filter.end.length > 0
      ) {
        filterParams.push(`&q[g][0][m]=and`);
        filterParams.push(
          `&q[g][0][leasing_start_or_leasing_end_gteq]=${this.state.filter.start}`
        );
        filterParams.push(
          `&q[g][0][leasing_start_or_leasing_end_lteq]=${this.state.filter.end}`
        );

        //filterParams.push(`&q[g][1][m]=and`);
        //filterParams.push(`&q[g][1][leasing_end_gteq]=${this.state.filter.start}`);
        //filterParams.push(`&q[g][1][leasing_end_lteq]=${this.state.filter.end}`);
        //filterParams.push(`&q[leasing_start_gteq]=${this.state.filter.start}`);
      }
      //if (this.state.filter.end.length > 0){
      //filterParams.push(`&q[leasing_end_lteq]=${this.state.filter.end}`);
      //filterParams.push(`&q[g][0][leasing_end_lteq]=${this.state.filter.end}`);
      //}
    }
    if (this.state.filter.category.length > 0) {
      filterParams.push(
        `&q[leasing_families_family_id_in]=${this.state.filter.category}`
      );
    }
    if (this.state.filter.brand.length > 0) {
      filterParams.push(
        `&q[leasing_brands_brand_id_in]=${this.state.filter.brand}`
      );
    }
    if (this.state.filter.product.length > 0) {
      filterParams.push(
        `&q[leasing_products_product_id_in]=${this.state.filter.product}`
      );
    }
    this.setState(
      {
        paramsFilter: filterParams.join(""),
        page: 1,
      },
      () => this.reloadData(filterParams.join(""))
    );
  };

  filterChange = (e) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [e.target.name]: e.target.value,
        },
      },
      () => this.onFilter()
    );
  };

  typeChange = (e) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [e.name]: e.value,
        },
      },
      () => this.onFilter()
    );
  };

  handleKeyDown = (e) => {
    if (e.key == "Enter") {
      this.onFilter();
    }
  };

  filterDateChange = (args) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          start: args[0],
          end: args[1],
        },
      },
      () => this.onFilter()
    );
  };

  getDateFormat = (date) => {
    var d = moment(date);
    return date && d.isValid() ? d : "";
  };

  clearFilter = () => {
    this.setState(
      {
        filter: initialFilterState,
      },
      () => this.onFilter()
    );
  };

  render() {
    const requiredItem = this.state.requiredId;
    let modalData = this.props.leasings[requiredItem]
      ? this.props.leasings[requiredItem]
      : false;
    return (
      <Container
        location={{
          locationName: "Lizingas",
          locationUrl: this.props.match.url,
        }}
      >
        <ContentBlock>
          <FilterMenu
            length={`${this.props.totals || 0} / ${
              this.props.leasings.length || 0
            }`}
            onChangeCountPage={this.setPageCount}
            countPage={this.state.pageCount}
            text="lizingų"
            text2="Lizingų"
          />
        </ContentBlock>
        <ContentBlock>
          <TableFilterBox
            filter={false}
            onFilter={this.onFilter}
            clearFilter={this.clearFilter}
          >
            <div className="d-flex w-100">
              <div className="px-1" style={{ width: "100%" }}>
                <SelectForm
                  placeholder="Kategorija"
                  mode="multiple"
                  onlyPlaceholder="Pasirinkti"
                  options={this.props.families.map((n) => ({
                    value: n.id,
                    label: n.name,
                  }))}
                  value={
                    this.state.filter.category ? this.state.filter.category : []
                  }
                  onChange={(e) =>
                    this.filterChange({
                      target: { name: "category", value: e },
                    })
                  }
                  onSearch={(e) => this.searchFamilies(e)}
                />
              </div>{" "}
              <div className="px-1" style={{ width: "100%" }}>
                <SelectForm
                  placeholder="Gamintojas"
                  mode="multiple"
                  onlyPlaceholder="Pasirinkti"
                  options={this.props.brands.map((n) => ({
                    value: n.id,
                    label: n.title,
                  }))}
                  value={this.state.filter.brand ? this.state.filter.brand : []}
                  onChange={(e) =>
                    this.filterChange({ target: { name: "brand", value: e } })
                  }
                  onSearch={(e) => this.searchBrands(e)}
                />
              </div>{" "}
              <div className="px-1" style={{ width: "100%" }}>
                <SelectForm
                  placeholder="Prekė"
                  mode="multiple"
                  onlyPlaceholder="Pasirinkti"
                  options={this.props.products.map((n) => ({
                    value: n.id,
                    label: n.title + ", " + n.identifier,
                  }))}
                  value={
                    this.state.filter.product ? this.state.filter.product : []
                  }
                  onChange={(e) =>
                    this.filterChange({ target: { name: "product", value: e } })
                  }
                  onSearch={(e) => this.searchProducts(e)}
                />
              </div>{" "}
              <div
                className="px-1"
                style={{ width: "100%", maxWidth: "290px" }}
              >
                <label className="editLabelForm my-1 text-bold">
                  Galiojimo data
                </label>
                <div>
                  <RangePickerComp
                    placeholder={["Pradžios laikas", "Pabaigos laikas"]}
                    value={[
                      this.getDateFormat(this.state.filter.start),
                      this.getDateFormat(this.state.filter.end),
                    ]}
                    onChange={(_, args) => this.filterDateChange(args)}
                    disabledDate={(d) => !d}
                  />
                </div>
              </div>{" "}
              <div className="px-1">
                <div className="form-group">
                  <div>
                    <label className={"editLabelForm my-1 text-bold"}>
                      Mėnesiai
                    </label>
                  </div>
                  <div className="d-flex">
                    <button
                      className={
                        "btn btn-custom-filter small " +
                        (!this.state.filter.months ? "active" : "")
                      }
                      onClick={() =>
                        this.typeChange({ name: "months", value: "" })
                      }
                    >
                      Visi
                    </button>
                    <button
                      className={
                        "btn btn-custom-filter small " +
                        (this.state.filter.months === 10 ? "active" : "")
                      }
                      onClick={() =>
                        this.typeChange({ name: "months", value: 10 })
                      }
                    >
                      10
                    </button>
                    <button
                      className={
                        "btn btn-custom-filter small " +
                        (this.state.filter.months === 24 ? "active" : "")
                      }
                      onClick={() =>
                        this.typeChange({ name: "months", value: 24 })
                      }
                    >
                      24
                    </button>
                  </div>
                </div>
              </div>{" "}
              <div className="px-1">
                <div>
                  <SwitchComp
                    name="Galioja"
                    placeholder="Galioja"
                    checked={this.state.filter.enabled}
                    onChange={() =>
                      this.filterChange({
                        target: {
                          name: "enabled",
                          value: !this.state.filter.enabled,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </TableFilterBox>
        </ContentBlock>
        <div className="col-12 my-3 text-right">
          <button
            type="button"
            className="btn btn-sm btn-primary mr-3"
            data-toggle="modal"
            data-target="#tableEdit"
            onClick={() => this.onAction(0)}
          >
            Sukurti taisyklę
          </button>
        </div>
        <ContentBlock>
          <div>
            <ContentValidation
              loader={this.props.loading}
              array={this.props.leasings}
              alertId_s="leasingsAlert-s"
              alertId_e="leasingsAlert-e"
            >
              <div className="table-responsive" style={{ overflow: "hidden" }}>
                <table className="table mt-3">
                  <thead>
                    <tr className="text-muted">
                      <th className="text-black">Galioja</th>
                      <th className="text-black">Prioritetas</th>
                      <th className="text-black">Pavadinimas</th>
                      <th className="text-black">Akc. pradžia</th>
                      <th className="text-black">Akc. pabaiga</th>
                      <th className="text-black">Mėnesiai</th>
                      <th className="text-black">Tekstas</th>
                      <th className="text-black">Taisyklė</th>
                      <th className="text-black"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.leasings &&
                      this.props.leasings.map((item, index) => {
                        return (
                          <TableColumnLeasing
                            key={index}
                            index={index}
                            item={item}
                            onEdit={this.onAction}
                            onDelete={this.onDelete}
                            onToggle={this.onToggle}
                          />
                        );
                      })}
                  </tbody>
                </table>
                <Pagination
                  setPagination={this.setPagination}
                  page={this.state.page}
                  totals={this.props.totals}
                  pageCount={this.state.pageCount}
                />
              </div>
            </ContentValidation>
          </div>
        </ContentBlock>
        {typeof this.state.requiredId == 'number' && (
          <LeasingEditModal
            leasing_id={this.state.requiredId}
            brands={this.props.brands}
            families={this.props.families}
            products={this.props.products}
            searchProducts={this.searchProducts}
            searchBrands={this.searchBrands}
            searchFamilies={this.searchFamilies}
            onClose={() => this.setState({
              requiredId: null
            })}
          />
        )}
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  leasings: state.leasings.leasings,
  totals: state.leasings.totals,
  loading: state.leasings.loading_leasings,
  errors: state.errors,
  auth: state.auth.user,
  brands: state.brand.brands,
  families: state.family.families,
  products: state.product.products,
});

export default connect(mapStateToProps, {
  getLeasings,
  updateLeasing,
  deleteLeasing,
  getBrands,
  getFamilies,
  getProducts,
})(Leasing);
