import Axios from "axios";
import {
  GET_COUNTRIES,
  POST_COUNTRY,
  DELETE_COUNTRY,
  UPDATE_COUNTRY,
  LOADING_COUNTRY,
  CLEAR_ERRORS,
  GET_ERRORS
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

const success = () => {
  if (!document.getElementById("countriesAlert-s")) return;
  document.getElementById("countriesAlert-s").style.display = "block";
  setTimeout(
    () =>
      (document.getElementById("countriesAlert-s").style.display = "none"),
    5000
  );
  document.getElementById('modalClose').click()
};

const error = () => {
  if(!document.getElementById("countriesAlert-e")) return;
  document.getElementById("countriesAlert-e").style.display = "block";
  setTimeout(
    () => (document.getElementById("countriesAlert-e").style.display = "none"),
    5000
  );
};

export const postCountry = CountryData => dispatch => {
  Axios.post("/api/v1/countries", CountryData)
    .then(res => {
      dispatch({
        type: POST_COUNTRY,
        payload: res.data
      });
      dispatch(clearErrors());
      success("post");
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      error();
    });
};

export const getCountries = (page, pageCount) => dispatch => {
  dispatch(setCountryLoading());
  Axios.get(`/api/v1/countries?page=${page}&per_page=${pageCount}`)
    .then(res => {
      dispatch({
        type: GET_COUNTRIES,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};



export const deleteCountry = _id => dispatch => {
  if (confirm("Ar tikrai norite ištrinti ? ")) {
    Axios.delete(`/api/v1/countries/${_id}`)
      .then(res => {
        dispatch({
          type: DELETE_COUNTRY,
          payload: _id
        });
        success();
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
        error();
      });
  }
};

export const updateCountry = (_id, body, indexOfArray) => dispatch => {
  Axios.put(`/api/v1/countries/${_id}`, body)
    .then(res => {
      dispatch(clearErrors());
      dispatch({
        type: UPDATE_COUNTRY,
        payload: res.data,
        payload_id: _id
      });
      success("update");
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      error();
    });
};

export const setCountryLoading = () => {
  return {
    type: LOADING_COUNTRY
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};

