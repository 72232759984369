import React from "react";
import {
  lengthOfCharactersDifference,
  returnEuroString,
} from "../../../helpers/Utils";

function TableChildrenColumnProduct(props) {
  const TableColumnProduct = props.TableColumnProduct;
  const DivLine = props.DivLine;
  const font_weight =
    lengthOfCharactersDifference(
      props.main_supplier?.toLowerCase() || "",
      props.item.supplier?.name.toLowerCase() || ""
    ) <= 2
      ? "align-middle font-weight-bold"
      : "align-middle";
  return (
    <TableColumnProduct className="align-middle" parentItem="#FCFCFC">
      <td className="align-middle">{props.item.product_identifier}</td>
      <td className="align-middle open" colSpan={2}></td>
      <td
        className="align-middle d-flex no-padding-top mr-2"
        style={{ overflow: "hidden" }}
      >
        <>
          <span style={{ width: "120px", height: "30px" }} />
          <DivLine style={{ height: "45px", width: "120px!important" }}>
            <svg
              width="300"
              height="100"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="30"
                x2="30"
                y1="0"
                y2="25"
                stroke="orange"
                strokeWidth="2"
              />
              <line
                x1="31"
                x2="100%"
                y1="25"
                y2="25"
                stroke="orange"
                strokeWidth="2"
              />
              {props.total !== props.indexN && (
                <line
                  x1="30"
                  x2="30"
                  y1="0"
                  y2="80"
                  stroke="orange"
                  strokeWidth="2"
                />
              )}
            </svg>
          </DivLine>
        </>
      </td>
      <td className={font_weight}>{props.item.quantity}</td>
      <td className={font_weight}>
        {props.item.hasOwnProperty("supplier") && props.item.supplier.name}
      </td>
      <td className={font_weight}>{props.item.code}</td>
      <td className={font_weight}>{returnEuroString(props.item.wholesale_price)}</td>
      <td className={font_weight}>{returnEuroString(props.e_price)}</td>
      <td className={font_weight}>{returnEuroString(props.r_price)}</td>
    </TableColumnProduct>
  );
}

export default TableChildrenColumnProduct;
