import React from "react";
import InputForm from "../../common/InputForm";
import ModalToChange from "../../Layout/ModalToChange";
import TextAreaFieldGroup from "../../common/TextAreaFieldGroup";

class ServiceEditModal extends React.Component {
  constructor() {
    super();
    this.state = {
      id: 0,
      category: "",
      sub_category: "",
      manufacturer: "",
      service_center: "",
      phone: "",
      address: "",
      email: "",
      comments: "",
      action: "new",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.action === "new" && this.state.action === "edit") {
      this.setState({
        id: 0,
        category: "",
        sub_category: "",
        manufacturer: "",
        service_center: "",
        phone: "",
        address: "",
        email: "",
        comments: "",
        action: "new",
      });
      this.removeErrors(nextProps.errors);
    } else if (nextProps.action === "edit" && nextProps.objectData.id !== this.state.id) {
      let item = nextProps.objectData;
      this.setState({
        id: item.id || 0,
        category: item.category || "",
        sub_category: item.sub_category || "",
        manufacturer: item.manufacturer || "",
        service_center: item.service_center || "",
        phone: item.phone || "",
        address: item.address || "",
        email: item.email || "",
        comments: item.comments || "",
        action: "edit",
      });
      this.removeErrors(nextProps.errors);
    }
  }

  removeErrors = (errors) => {
    if (Object.keys(errors).length > 0) {
      this.props.clearErrors();
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const variables = {
      presta_service: {
        category: this.state.category,
        sub_category: this.state.sub_category,
        manufacturer: this.state.manufacturer,
        service_center: this.state.service_center,
        phone: this.state.phone,
        address: this.state.address,
        email: this.state.email,
        comments: this.state.comments
      },
    };
    if (this.props.action == "new") {
      this.props.postServiceItem(variables).then(res => {
        if(res && res.success) {
          this.setState({
            id: 0,
            category: "",
            sub_category: "",
            manufacturer: "",
            service_center: "",
            phone: "",
            address: "",
            email: "",
            comments: "",
            action: "new",
          })
        }
      })
    } else if (this.props.action === "edit") {
      this.props.updateServiceItem(this.state.id, variables);
    }
  };

  render() {
    return (
      <ModalToChange
        handleSave={(e) => this.onSubmit(e)}
        status="tableEdit"
        text={this.props.action === "new" ? "Kurti naują" : "Redaguoti"}
        buttonText={this.props.action === "new" ? "Sukurti" : "Atnaujinti"}
      >
        <InputForm
          type="text"
          placeholder="Kategorija"
          name="category"
          value={this.state.category}
          error={
            this.props.errors.category &&
            this.props.errors.category.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Sub. kategorija"
          name="sub_category"
          value={this.state.sub_category}
          error={
            this.props.errors.sub_category &&
            this.props.errors.sub_category.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Gamintojas"
          name="manufacturer"
          value={this.state.manufacturer}
          error={
            this.props.errors.manufacturer &&
            this.props.errors.manufacturer.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Garantinio aptarnavimo centras"
          name="service_center"
          value={this.state.service_center}
          error={
            this.props.errors.service_center &&
            this.props.errors.service_center.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Telefonas"
          name="phone"
          value={this.state.phone}
          error={
            this.props.errors.phone &&
            this.props.errors.phone.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Adresas"
          name="address"
          value={this.state.address}
          error={
            this.props.errors.address &&
            this.props.errors.address.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="El.Paštas"
          name="email"
          value={this.state.email}
          error={
            this.props.errors.email &&
            this.props.errors.email.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        <TextAreaFieldGroup
          type="text"
          placeholder="Komentarai"
          name="comments"
          value={this.state.comments}
          error={
            this.props.errors.comments &&
            this.props.errors.comments.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
      </ModalToChange>
    );
  }
}

export default ServiceEditModal;
