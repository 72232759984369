import Axios from "axios";
import {
  antdMessageError,
  antdMessageSuccess,
  errorHandler,
} from "../../helpers/Utils";
import { getCommerceShowProductsList } from "./commerce_orders_action";




export const updateCommerceList = (list_id, params) => async (dispatch) => {
  try {
    const api_url = list_id
      ? `/api/v1/commerce/lists/${list_id}`
      : "/api/v1/commerce/lists/";
    delete params.category;
    console.log({ params })
    params.products = params.products.map(d => typeof d == 'string' ? d : d.sku).filter(value => value)
    const { data } = await Axios({
      url: api_url,
      data: {
        list: params,
      },
      method: list_id ? "PUT" : "POST",
    });

    dispatch({
      type: "GET_CUSTOM",
      payload: data,
    });
    dispatch(getCommerceShowProductsList(list_id, 1, 50, ""))
    antdMessageSuccess();
    return { success: true, data: data.data };
  } catch (err) {
    antdMessageError();
    errorHandler(err);
    return { success: false };
  }
};

export const updateCommerceStoreIds = (product_id, params) => async (dispatch) => {
  try {
    const { data } = await Axios({
      url: `/api/v1/commerce/products/${product_id}`,
      method: "PUT",
      data: params,
    });

    console.log('wtf: ',
      { ...data, update_at: new Date().toISOString() })
    dispatch({
      type: "UPDATE_CUSTOM",
      payload: { ...data, update_at: new Date().toISOString() },
    });

    return
  } catch (err) {
    antdMessageError();
    errorHandler(err);
    return { success: false };
  }
};


export const deleteCommerceProduct = (product_id) => async (dispatch) => {
  try {
    const { data } = await Axios.delete(`/api/v1/commerce/products/${product_id}`);
    if (!data.status) throw new Error("err");
    antdMessageSuccess();

    dispatch({
      type: "REMOVE_CUSTOM",
      payload: product_id
    });

    return { success: true };
  } catch (err) {
    antdMessageError();
    errorHandler(err);
    return { success: false };
  }
};


// 
export const createAnalogProductCommerce = (product_id, analog_product_id) => async (dispatch) => {
  try {
    const { data } = await Axios({
      url: `/api/v1/commerce/products/${product_id}/create_analog`,
      method: "POST",
      params: {
        analog_product_id
      }
    });
    dispatch({
      type: "POST_CUSTOM",
      payload: data
    });
    antdMessageSuccess();
  } catch (err) {
    antdMessageError();
    errorHandler(err);
  }
};

