import React, { useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { Modal } from "antd";
import EditAdditional from "./EditAdditional";
import { RiArrowGoBackLine } from "react-icons/ri";
import {
  Button1,
  LongButton,
  MainBlock,
  MiniBlock,
} from "../../common/Buttons";
import { UlElements as UlElement2 } from "../../common/StyledComponents";
import {
  antdMessageError,
  exportDocument,
  returnEuroString,
} from "../../../helpers/Utils";
import useFetch from "../../../helpers/useFetch";
import Spinner from "../../common/Spinner";
import SelectInput from "../../common/SelectInput";
import { useDispatch } from "react-redux";
import { putContract } from "../../../redux/Actions/documentAction";
import SendContractEmail from "./SendContractEmail";
import { DropdownButton } from "../../global";

class ListOfDocumentsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contract_items: [],
      created_at: "",
      customer: "",
      email: "",
      document_plan: {
        asset: {},
        document_type: {},
      },
      end_date: null,
      id: 0,
      invoice_number: "",
      number: "",
      order: {},
      order_id: 0,
      phone: "",
      price: 0,
      start_date: null,
      total: 0,
      updated_at: "",
      warranty: null,
      editmodal: false,
      created_at: null,
      canceled: false,
      editDetails: {
        contract_items: [],
      },
      contract_emails: [],
      accepted_rules: false,
      sendMail: false,
      preview_document: true,
      preview_information: true,
      preview_mail: true,
      preview_repair: true,
      preview_other: true,
      preview_cancelled: true,
      change_user: false,
    };
  }

  getNum = (val) => {
    val = +val || 0;
    return val.toFixed(2);
  };
  frompercentage = (partialValue, totalValue) => {
    return ((100 * partialValue) / totalValue).toFixed(2);
  };
  topercentage = (percent, total) => {
    return ((percent / 100) * total).toFixed(2);
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.objectData).length > 0 && !this.state.editmodal) {
      this.updateState(nextProps.objectData)
    }
    if (nextProps.objectData.id !== this.state.id) {
      if (this.props.createdNew) {
        this.setState(
          {
            editmodal: true,
          },
          () => this.props.stopCreateNew()
        );
      } else {
        this.setState({
          editmodal: false,
        });
      }
    }
  }

  componentDidUpdate(_, prevState) {
    if (this.state.editDetails.price !== prevState.editDetails.price) {
      this.setTotalPrice();
    }
  }

  handleSave = () => { };

  warrantlyOption = (key) => {
    const warrantlyOption = [
      {
        label: "1 metai",
        value: 1,
      },
      {
        label: "2 metai",
        value: 2,
      },
      {
        label: "3 metai",
        value: 3,
      },
      {
        label: "4 metai",
        value: 4,
      },
      {
        label: "5 metai",
        value: 5,
      },
      {
        label: "Garantijos nėra",
        value: 0,
      },
    ];

    const findWarrantly = warrantlyOption.find((find) => find.value == key);
    return findWarrantly ? findWarrantly.label : "Garantijos nėra";
  };

  additionalOption = (key) => {
    const meaningsOptions = [
      {
        value: 0,
        label: "-- Pasirinkite --",
      },
      {
        value: 1,
        label: "IMEI",
      },
      {
        value: 2,
        label: "Serijinis numeris",
      },
      {
        value: 3,
        label: "Kita...",
      },
    ];

    const findAdditional = meaningsOptions.find((find) => find.value == key);
    return findAdditional ? findAdditional.label : "Kita...";
  };

  startEdit = () => {
    this.setState({
      editmodal: !this.state.editmodal,
    });
  };

  onChangeContract = (e, select) => {
    // const editDetailsObj = { ...editDetails };
    if (select === "document_type_id") {
      this.setState({
        editDetails: {
          ...this.state.editDetails,
          document_type_id: e,
          document_plan_id: undefined,
        },
      });
    } else if (select) {
      this.setState({
        editDetails: {
          ...this.state.editDetails,
          [select]: e,
        },
      });
    } else if (e.target.name === "price") {
      let editDetailsObj = { ...this.state.editDetails };
      editDetailsObj.price = e.target.value;

      this.setState({
        editDetails: editDetailsObj,
      });
    } else {
      this.setState({
        editDetails: {
          ...this.state.editDetails,
          [e.target.name]: e.target.value,
        },
      });
    }
  };
  onChangeContractItem = (e, index) => {
    let editDetailsObj = { ...this.state.editDetails };
    const parentName = e.target.name;
    const parentNumber = +e.target.value || 0;

    if (parentName === "item_price") {
      editDetailsObj.contract_items[index].item_pricePerc = this.frompercentage(
        e.target.value,
        editDetailsObj.contract_items[index].product_price
      );
      editDetailsObj.contract_items[index].item_price = parentNumber;
    } else if (parentName == "item_pricePerc") {
      editDetailsObj.contract_items[index].item_pricePerc = parentNumber;
      editDetailsObj.contract_items[index].item_price = this.topercentage(
        parentNumber,
        editDetailsObj.contract_items[index].product_price
      );
    } else if (parentName == "item_discount") {
      editDetailsObj.contract_items[index].item_discount = parentNumber;
    } else if (parentName == "product_price") {
      editDetailsObj.contract_items[index].product_price = parentNumber;
      editDetailsObj.contract_items[index].item_price = this.topercentage(
        editDetailsObj.contract_items[index].item_pricePerc,
        parentNumber
      );
    } else {
      editDetailsObj.contract_items[index][e.target.name] = e.target.value;
    }

    const itemPrice = this.getNum(
      editDetailsObj.contract_items[index].item_price
    );
    const discount = this.getNum(
      editDetailsObj.contract_items[index].item_discount
    );
    const product_price = this.getNum(
      editDetailsObj.contract_items[index].product_price
    );
    const counter = itemPrice - discount;
    editDetailsObj.contract_items[index].item_total = this.getNum(counter);

    this.setState(
      {
        editDetails: editDetailsObj,
      },
      () => this.setTotalPrice()
    );
  };

  addContractItem = (newObj) => {
    this.setState({
      editDetails: {
        ...this.state.editDetails,
        contract_items: [newObj, ...this.state.editDetails.contract_items],
      },
    });
  };

  addAdditionalItem = (index) => {
    const editDetailsObj = { ...this.state.editDetails };

    const newObj = {
      key: undefined,
      text: "",
    };

    editDetailsObj.contract_items[index].additional_data.push(newObj);
    this.setState({
      editDetails: editDetailsObj,
    });
  };

  changeAdditionalItem = (index, itemIndex, value, input) => {
    const editDetailsObj = { ...this.state.editDetails };

    editDetailsObj.contract_items[index].additional_data[itemIndex][input] =
      value;
    this.setState({
      editDetails: editDetailsObj,
    });
  };

  removeAdditionalItem = (index, itemIndex) => {
    const editDetailsObj = { ...this.state.editDetails };

    editDetailsObj.contract_items[index].additional_data.splice(itemIndex, 1);
    this.setState({
      editDetails: editDetailsObj,
    });
  };

  onSubmitHandler = () => {
    const editDetailsObj = { ...this.state.editDetails };

    const postVariables = {
      contract: {
        document_plan_id: editDetailsObj.document_plan_id,
        number: editDetailsObj.number,
        phone: editDetailsObj.phone,
        customer: editDetailsObj.customer,
        buy_date: editDetailsObj.buy_date,
        email: editDetailsObj.email,
        invoice_number: editDetailsObj.invoice_number,
        warranty: editDetailsObj.warranty,
        price: editDetailsObj.price,
        total: editDetailsObj.total,
      },
    };
    return this.props
      .updateContract(
        editDetailsObj.order_id,
        editDetailsObj.id,
        postVariables,
        editDetailsObj.contract_items
      )
      .then((response) => {
        if (response.success) {
          this.setState({
            ...response.data,
            editmodal: false,
          }, () => this.updateState(this.props.objectData));
        }
      });
  };

  setContractItems = (items) => {
    const editDetailsObj = { ...this.state.editDetails };
    editDetailsObj.contract_items = items;
    this.setState({
      editDetails: editDetailsObj,
    });
  };

  setTotalPrice = () => {
    const editDetailsObj = { ...this.state.editDetails };
    if (editDetailsObj.contract_items.length > 0) {
      let reducer = editDetailsObj.contract_items.reduce(
        (acc, cur) => acc + Number(cur.item_total == NaN ? 0 : cur.item_total),
        0
      );
      let total = +reducer + +editDetailsObj.price;
      editDetailsObj.total = total;
    } else {
      editDetailsObj.total = editDetailsObj.price;
    }
    this.setState({
      editDetails: editDetailsObj,
    });
  };

  deleteContractItem = (index, created_at, id) => {
    const editDetailsObj = { ...this.state.editDetails };

    if (created_at) {
      this.props
        .deleteContractItem(editDetailsObj.order_id, editDetailsObj.id, id)
        .then((res) => {
          editDetailsObj.contract_items.splice(index, 1);
          this.setState(
            {
              editDetails: editDetailsObj,
            },
            () => this.recalculate()
          );
        });
    } else {
      editDetailsObj.contract_items.splice(index, 1);
      this.setState(
        {
          editDetails: editDetailsObj,
        },
        () => this.recalculate()
      );
    }
  };

  recalculate = () => {
    const editDetailsObj = { ...this.state.editDetails };
    if (editDetailsObj.contract_items.length > 0) {
      let reducer = editDetailsObj.contract_items.reduce(
        (acc, cur) => acc + Number(cur.item_total == NaN ? 0 : cur.item_total),
        0
      );
      let total = +reducer - +editDetailsObj.price;
      editDetailsObj.total = total;
    } else {
      editDetailsObj.total = editDetailsObj.price;
    }
    this.setState(
      {
        editDetails: editDetailsObj,
      },
      () => this.onSubmitHandler()
    );
  };

  checkedPrivilegies = () => {
    if (this.props.auth?.user_role?.identifier == "store_user") {
      const diff = moment().diff(this.state.created_at, "days");
      return diff < 7;
    } else {
      return true;
    }
  };

  openBlock = (block) => {
    this.setState({
      [block]: !this.state[block],
    });
  };

  blocksRender() {
    let emailBlock = (
      <>
        <MainBlock
          color={"#00aaa0"}
          action={this.state.preview_mail}
          onChange={() => this.openBlock("preview_mail")}
        >
          Susisiekimas elektroniniu paštu
        </MainBlock>
        {this.state.preview_mail && (
          <SendContractEmail
            contract={this.props.objectData}
            contract_emails={this.state.contract_emails}
            setValues={(val) =>
              this.setState({
                contract_emails: [...this.state.contract_emails, val],
                editDetails: { ...this.state.editDetails, editable: true },
              })
            }
          />
        )}
      </>
    );
    return emailBlock;
  }

  renderHistory = () => {
    const { document_history } = this.props;
    const renderMicroInfo = () => {
      return (
        <div
          style={{
            backgroundColor: "rgb(251, 251, 251)",
          }}
        >
          <MiniBlock
            color={"#629acb"}
            action={this.state.preview_repair}
            onChange={() => this.openBlock("preview_repair")}
          >
            Remontų istoriją (PPG)
          </MiniBlock>
          {this.state.preview_repair && (
            <div className="px-3 py-2">
              {document_history?.services?.length > 0 ? (
                document_history?.services?.map((e) => (
                  <UlElement2 className="border-bottom" key={e.id}>
                    <li className="mb-0 font-weight-bold d-flex justify-content-between">
                      <div>
                        Servisas:{" "}
                        <span
                          className="cursor-pointer text-primary"
                          onClick={() =>
                            window.open(`/service/repair/${e.id}`, "_blank")
                          }
                        >
                          {e.number}
                        </span>
                      </div>
                      <div>{returnEuroString(e.total_price)}</div>
                    </li>
                    <li>Sukurtas: {e.created_at}</li>
                  </UlElement2>
                ))
              ) : (
                <b>Nėra istorijos</b>
              )}
            </div>
          )}
          <MiniBlock
            color={"#629acb"}
            action={this.state.preview_cancelled}
            onChange={() => this.openBlock("preview_cancelled")}
          >
            Anuliuoti polisai
          </MiniBlock>
          {this.state.preview_cancelled && (
            <div className="px-3 py-2">
              {document_history?.documents?.length > 0 ? (
                document_history?.documents?.map((e) => (
                  <UlElement2 className="border-bottom" key={e.id}>
                    <li className="mb-0 font-weight-bold">
                      Dokumentas:{" "}
                      <span
                        className="cursor-pointer text-primary"
                        onClick={() =>
                          window.open(`/documents/${e.id}`, "_blank")
                        }
                      >
                        {e.number}
                      </span>
                    </li>
                    <li>Data: {e.date}</li>
                    <li>Priežastis: {e.reason}</li>
                  </UlElement2>
                ))
              ) : (
                <b>Nėra istorijos</b>
              )}
            </div>
          )}
        </div>
      );
    };
    return (
      <>
        <MainBlock
          color={"#428dcc"}
          action={this.state.preview_other}
          onChange={() => this.openBlock("preview_other")}
        >
          Kita
        </MainBlock>
        {this.state.preview_other && renderMicroInfo()}
      </>
    );
  };

  cancelDocument = (status) => {
    let promtCancelDocument = prompt(
      `Priežastis dėl ko ${status == "cancel"
        ? "norite atšaukti dokumentą"
        : "grąžinami pinigai dokumentio"
      } ${this.state.number} ?`,
      ""
    );

    if (promtCancelDocument) {
      this.props
        .cancelDocument(this.state.id, { reason: promtCancelDocument, status })
        .then((response) =>
          this.setState({
            ...response,
          })
        );
    }
  };

  actionArray = () => {
    const actionArr = [];
    if (this.checkedPrivilegies()) {
      actionArr.push({
        label: "Redaguoti",
        onFunction: () => {
          this.startEdit();
        },
      });
    }
    if (
      ["accounting", "admin"].includes(this.props.auth?.user_role?.identifier)
    ) {
      actionArr.push({
        label: "Pardavėjo koregavimas",
        onFunction: () => {
          this.setState({
            change_user: true,
          });
        },
      });
    }

    return actionArr;
  };

  updateState = (item) => {
    let contractItemsArray = [];
    if (item.contract_items !== undefined) {
      item.contract_items.forEach((item) => {
        contractItemsArray.push({
          ...item,
          item_pricePerc: this.frompercentage(
            item.item_price,
            item.product_price
          ),
        });
      });
    }
    this.setState({
      ...item,
      preview_document: true,
      editDetails: {
        id: item.id,
        order_id: item.order_id,
        order_id2: item.hasOwnProperty("order") ? item.order.id_order : "",
        document_plan_id: item.document_plan_id,
        document_type_id: item.document_type_id,
        phone: item.phone || "+370",
        buy_date: item.buy_date ? moment(item.buy_date) : moment(),
        customer: item.customer || "",
        email: item.email || "",
        number: item.number || 0,
        invoice_number: item.invoice_number || "",
        warranty: +item.warranty || undefined,
        charge: item.charge || 0,
        price: item.price || 0,
        total: item.total || 0,
        contract_items: contractItemsArray,
        editable: item.editable,
      },
    });
  }

  render() {
    console.log("PROPS: ",this.props)
    const its_canceled =
      this.props.auth.user_role?.identifier == "staff"
        ? false
        : !this.state.canceled_at;
    return (
      // <BasicModalOrder
      //   handleSave={() => this.handleSave()}
      //   status="documentModal"
      //   text={tilteSpan}
      //   // text={`Dokumentas #${this.state.number}`}
      // >
      <div className="row">
        <div className="col">
          <MainBlock
            color={"#ffa800"}
            action={this.state.preview_document}
            onChange={() => this.openBlock("preview_document")}
          >
            Dokumentas
          </MainBlock>
          {this.state.preview_document && (
            <>
              {this.state.editmodal ? (
                <div>
                  <div className="my-2" style={{ fontSize: "13px" }}>
                    <div className="border">
                      <div
                        style={{
                          backgroundColor: "#f7f7f7",
                          width: "100%",
                          height: "38px",
                          padding: " 0 10px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "13px",
                            color: "black",
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {this.state.customer}
                          </span>
                          {this.checkedPrivilegies() && (
                            <button
                              className="btn btm-sm color-orange"
                              onClick={() => this.startEdit()}
                            >
                              <RiArrowGoBackLine color="orange" />
                            </button>
                          )}
                        </span>
                      </div>
                      <EditAdditional
                        data={this.state.editDetails}
                        getNum={this.getNum}
                        documentPlan={this.props.documentPlan}
                        documentType={this.props.documentType}
                        addContractItem={this.addContractItem}
                        onChangeContract={this.onChangeContract}
                        onChangeContractItem={this.onChangeContractItem}
                        addAdditionalItem={this.addAdditionalItem}
                        changeAdditionalItem={this.changeAdditionalItem}
                        removeAdditionalItem={this.removeAdditionalItem}
                        setContractItems={this.setContractItems}
                        topercentage={this.topercentage}
                        deleteContractItem={this.deleteContractItem}
                        contract_emails={this.state.contract_emails}
                        onSubmitHandler={this.onSubmitHandler}
                        setTotalPrice={this.setTotalPrice}
                        auth={this.props.auth}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="col-12 mb-3"
                  style={{ backgroundColor: "#fbfbfb" }}
                >
                  <div className="d-flex justify-content-between pt-3 mb-1">
                    <div
                      style={{
                        fontSize: "19px",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      {this.state.customer}
                    </div>

                    {its_canceled && (
                      <div className="d-flex">
                        <>
                          <div className="mx-2" />
                          <DropdownButton
                            color="#EF3824"
                            text="Veiksmai"
                            actions={this.actionArray()}
                          />
                        </>

                        {["accounting", "admin"].includes(
                          this.props.auth?.user_role?.identifier
                        ) && (
                            <>
                              <div className="mx-2" />
                              <DropdownButton
                                text="Statusas"
                                actions={[
                                  {
                                    label: "Atšaukti",
                                    onFunction: () => {
                                      this.cancelDocument("cancel");
                                    },
                                  },
                                  {
                                    label: "Grąžinti pinigai",
                                    onFunction: () => {
                                      this.cancelDocument("refund");
                                    },
                                  },
                                ]}
                              />
                            </>
                          )}
                      </div>
                    )}
                  </div>

                  {this.state.reason && (
                    <div
                      className="d-flex flex-column mt-1"
                      style={{
                        backgroundColor: "#FCDFDC",
                        padding: "8px 8px 5px",
                        borderRadius: 2,
                      }}
                    >
                      <p
                        className="mb-0"
                        style={{
                          color: "#EF3824",
                          fontWeight: 400,
                          fontSize: 10,
                          lineHeight: 1,
                        }}
                      >
                        {this.state.status == "refund"
                          ? "Grąžinti pinigai"
                          : "Atšauktas"}
                      </p>
                      <div className="text-black mb-0 text-xs">
                        {this.state.reason}
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-6 my-3">
                      {this.state.buy_date && (
                        <UlElements>
                          <li className="font-weight-bold">Pirkimo data</li>
                          <li className="li-font">
                            {moment(this.state.buy_date).format("YYYY-MM-DD")}
                          </li>
                        </UlElements>
                      )}
                      <UlElements>
                        <li
                          className={`font-weight-bold ${!this.state.number && "text-danger"
                            }`}
                        >
                          Sertif. Nr.
                        </li>
                        <li className="li-font">{this.state.number}</li>
                      </UlElements>

                      <UlElements>
                        <li
                          className={`font-weight-bold ${!this.state.invoice_number && "text-danger"
                            }`}
                        >
                          Pirk. Dok. Nr.
                        </li>
                        <li className="li-font">{this.state.invoice_number}</li>
                      </UlElements>
                      <UlElements>
                        <li className="font-weight-bold">Tipas</li>
                        <li className="li-font">
                          {this.state.document_plan.document_type.name}
                        </li>
                      </UlElements>
                      <UlElements>
                        <li className="font-weight-bold">Planas</li>
                        <li className="li-font">
                          {this.state.document_plan.name}
                        </li>
                      </UlElements>
                      <UlElements>
                        <li
                          className={`font-weight-bold ${!this.state.customer && "text-danger"
                            }`}
                        >
                          Klientas
                        </li>
                        <li className="li-font">{this.state.customer}</li>
                      </UlElements>
                      <UlElements>
                        <li
                          className={`font-weight-bold ${!this.state.phone && "text-danger"
                            }`}
                        >
                          Telefonas
                        </li>
                        <li className="li-font">{this.state.phone}</li>
                      </UlElements>
                      <UlElements>
                        <li
                          className={`font-weight-bold ${!this.state.email && "text-danger"
                            }`}
                        >
                          El. paštas
                        </li>
                        <li className="li-font">{this.state.email}</li>
                      </UlElements>
                    </div>
                    <div
                      className="col-6 my-3"
                      style={{ borderLeft: "1px solid #e3e3e3" }}
                    >
                      {this.state.contract_items.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            {item.order_row && (
                              <UlElements>
                                <li className="font-weight-bold">Prekės</li>
                                <li className="li-font">
                                  {item.order_row.product_name}
                                </li>
                              </UlElements>
                            )}
                            <UlElements>
                              <li
                                className={`font-weight-bold ${!item.product_price && "text-danger"
                                  }`}
                              >
                                Prekės kaina
                              </li>
                              <li className="li-font">{item.product_price}</li>
                            </UlElements>
                            <UlElements>
                              <li
                                className={`font-weight-bold ${!item.category && "text-danger"
                                  }`}
                              >
                                Kategorija
                              </li>
                              <li className="li-font">{item.category}</li>
                            </UlElements>
                            <UlElements>
                              <li
                                className={`font-weight-bold ${!item.model && "text-danger"
                                  }`}
                              >
                                Modelis
                              </li>
                              <li className="li-font">{item.model}</li>
                            </UlElements>
                            <UlElements>
                              <li
                                className={`font-weight-bold ${!item.manufacturer && "text-danger"
                                  }`}
                              >
                                Gamintojas
                              </li>
                              <li className="li-font">{item.manufacturer}</li>
                            </UlElements>
                            <UlElements>
                              <li
                                className={`font-weight-bold ${!item.id_number && "text-danger"
                                  }`}
                              >
                                S/N ar IMEI
                              </li>
                              <li className="li-font">{item.id_number}</li>
                            </UlElements>
                            {(item.product_reference ||
                              item.order_row?.product_reference) && (
                                <UlElements>
                                  <li className={"font-weight-bold"}>
                                    Prekės kodas
                                  </li>
                                  <li className="li-font">
                                    {item.product_reference ||
                                      item.order_row?.product_reference}
                                  </li>
                                </UlElements>
                              )}
                            <UlElements>
                              <li
                                className={`font-weight-bold ${!this.state.warranty && "text-danger"
                                  }`}
                              >
                                Gamintojo garantija
                              </li>
                              <li className="li-font">
                                {this.warrantlyOption(this.state.warranty)}
                              </li>
                            </UlElements>
                            <UlElements>
                              <li
                                className={`font-weight-bold ${!this.state.price && "text-danger"
                                  }`}
                              >
                                Sutarties administracinis mokestis
                              </li>
                              <li className="li-font">
                                {this.state.price} {"\u20AC"}
                              </li>
                            </UlElements>
                            <UlElements>
                              <li
                                className={`font-weight-bold ${!item.item_price && "text-danger"
                                  }`}
                              >
                                Plano kaina
                              </li>
                              <li className="li-font">
                                {item.item_price} {"\u20AC"}
                              </li>
                            </UlElements>
                            <UlElements>
                              <li
                                className={`font-weight-bold ${!item.item_discount && "text-danger"
                                  }`}
                              >
                                Nuolaida
                              </li>
                              <li className="li-font">
                                {item.item_discount} {"\u20AC"}
                              </li>
                            </UlElements>
                            <UlElements>
                              <li
                                className={`font-weight-bold ${!this.state.start_date && "text-danger"
                                  }`}
                              >
                                Pradžios data
                              </li>
                              <li className="li-font">
                                {this.state.start_date}
                              </li>
                            </UlElements>
                            <UlElements>
                              <li
                                className={`font-weight-bold ${!this.state.end_date && "text-danger"
                                  }`}
                              >
                                Pabaigos data
                              </li>
                              <li className="li-font">{this.state.end_date}</li>
                            </UlElements>

                            <UlElements>
                              {item.additional_data.length > 0 && (
                                <li>
                                  <div className="fontBold">
                                    Papildoma informacija
                                  </div>
                                  <div className="border p-2">
                                    {item.additional_data.map(
                                      (additional, index) => {
                                        return (
                                          <div key={index} className="mb-1">
                                            <div className="fontBold">
                                              {this.additionalOption(
                                                additional.key
                                              )}
                                            </div>
                                            <div className="liText">
                                              {additional.text}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </li>
                              )}
                            </UlElements>
                          </React.Fragment>
                        );
                      })}
                    </div>
                    <div className="col-12 mb-3">
                      <div className="d-flex justify-content-center">
                        <LongButton
                          style={{ marginRight: "5px" }}
                          text={`Galutinė suma: ${+this.state.total || 0
                            } ${"\u20AC"}`}
                        />
                        <Button1
                          style={{ marginLeft: "5px" }}
                          option={"upload"}
                          text={"Parsisiųskite"}
                          onClick={() =>
                            exportDocument(
                              `/api/v1/contracts/${this.state.id}/download`,
                              `Contract_${this.state.number}.pdf`,
                              "POST"
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="col">
          {this.blocksRender()}
          {this.renderHistory()}
        </div>
        {this.state.change_user && (
          <ModalChangeUser
            old_user_id={this.props.objectData?.user_id}
            id={this.state.id}
            number={this.state.number}
            onClose={() =>
              this.setState({
                change_user: false,
              })
            }
          />
        )}
      </div>
    );
  }
}

export default ListOfDocumentsModal;

const ModalChangeUser = ({ onClose, id, number, old_user_id }) => {
  const dispatch = useDispatch();
  const { loading, error, value } = useFetch("api/v1/users/store_users");
  const [user_id, set_user_id] = useState(old_user_id);

  const onSubmit = async () => {
    try {
      if (!user_id) return antdMessageError("Pasirinkite darbuotoja");
      const findItem = value?.data?.find((d) => d.id === user_id);
      if (!findItem) return antdMessageError();

      const params = {
        user_id,
        store_id: findItem.store_id,
        user_title: findItem.full_name,
      };
      const { success } = await dispatch(putContract(id, params));
      if (success) return onClose();
    } catch (err) {
      antdMessageError();
    }
  };

  return (
    <Modal
      title={`Dokumento ${number} pardavėjo koregavimas`}
      open
      onOk={onSubmit}
      onCancel={onClose}
      okText="Išsaugoti"
      cancelText="Atšaukti"
    >
      {loading ? (
        <Spinner />
      ) : error ? (
        JSON.stringify(error)
      ) : (
        <SelectInput
          label="Darbuotojas"
          value={user_id}
          options={
            value.success
              ? value.data.map((d) => ({
                label: `${d.full_name} (${d.store})`,
                value: d.id,
              }))
              : []
          }
          onChange={(val) => set_user_id(val)}
        />
      )}
    </Modal>
  );
};

const UlElements = styled.ul`
  margin-bottom: 8px;
  list-style-type: none;
  padding: 0px;
  li {
    word-wrap: break-word;
    font-size: 10px;
    line-height: 14px;
    color: #000000;
  }
  .li-font {
    font-size: 14px;
  }
`;
