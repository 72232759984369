import React, { useMemo, useRef, useState } from "react";
import Axios from "axios";
import { Popover, Button } from "antd";
import { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import readXlsxFile from "read-excel-file";
import { antdMessageSuccess } from "../../helpers/Utils";
import { DebounceSelect } from "../common";
import TextInputModal from "../common/TextInputModal";
import { Label } from ".";
import { IconExpand, IconImport, IconSearch } from "../Layout/Icons";
import { importXlsCommerceList } from "../../redux/Actions/commerce_orders_action";

export default function ProductSku({
  record_id = null,
  type = "autosys",
  products = [],
  setValue,
  warning,
  customFunction,
}) {
  const fileRef = useRef();
  const rebuild_type = `${type == "products" ? type : `${type}_products`}`;
  useEffect(() => {
    // hacking with dom elements
    const items = document
      .querySelector(".select_with_hidden_items")
      .querySelectorAll(".ant-select-selection-item");
    if (items.length < 15)
      return items.forEach((d) => (d.style.display = "flex"));
    items.forEach((d) => (d.style.display = "none"));
    [...items]
      .slice(items.length - 15, items.length)
      .forEach((d) => (d.style.display = "flex"));
  }, [products.length]);
  const [open, setOpen] = useState(open);

  const onChange = async (e) => {
    try {
      const file = e.target.files[0];
      if (!!record_id) {
        importXlsCommerceList(record_id, file).then(({ success }) => {
          if (success) customFunction();
        });
        return;
      }
      e.target.value = null;
      const rows = await readXlsxFile(file);
      const old_products = products.map((product) => product.sku + "");
      const arr = rows
        .flat()
        .filter((d) => d)
        .filter((sku) => !old_products.includes(sku + ""));
      setValue((prev) => ({
        ...prev,
        [rebuild_type]: [
          ...prev[rebuild_type],
          ...arr.map((d) => ({
            id: null,
            sku: d,
          })),
        ],
      }));
      antdMessageSuccess("Sėkmingai importuota");
    } catch (err) {
      console.log(err);
    } finally {
      e.target.value = null;
    }
  };

  const fetchOptions = async (value) => {
    try {
      const { data } = await Axios.get(
        `/api/v1/products/simple_products.json?q[identifier_cont]=${value}`
      );
      return (
        data?.data
          ?.map((e) => ({
            sku: e.identifier,
            value: e.identifier,
            label: `${e.identifier} - ${e.title}`,
          }))
          .filter((d) => !products.find((s) => s.sku == d.sku)) || []
      );
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const onRemove = (val) => {
    const make_arr = products
      .map((d) => {
        if (d.sku == val) {
          if (d.id) return { ...d, _destroy: true };
          return { ...d, local_delete: true };
        }
        return d;
      })
      .filter((d) => !d.local_delete);
    setValue((prev) => ({
      ...prev,
      [rebuild_type]: make_arr,
    }));
  };

  const arr = products
    .map((d) => ({
      ...d,
      label: d.label || d.sku,
      value: d.value || d.sku,
    }))
    .filter((d) => !d._destroy);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <Label error={warning} required={true}>
          Prekės kodas
        </Label>
        <div>
          <IconExpand
            className={open ? "text-orange" : ""}
            onClick={() => setOpen(true)}
          />
        </div>
      </div>
      <Popover
        overlayStyle={{
          width: 480,
        }}
        placement="left"
        title={
          <div className="d-flex align-items-center justify-content-between py-2">
            <div className="font-semi-bold text-base text-black">
              Prekių kodai
            </div>
            <div>{arr?.length} prekių sąraše</div>
            <AiOutlineClose
              className="cursor-pointer text-black"
              onClick={() => setOpen(false)}
            />
          </div>
        }
        content={
          open ? (
            <PopoverModal products={arr} remove={true} onRemove={onRemove} />
          ) : (
            ""
          )
        }
        trigger="click"
        visible={open || false}
      >
        <div
          style={{
            border: arr.length > 15 ? "1px solid #d9d9d9" : "none",
          }}
        >
          <DebounceSelect
            className={`select_with_hidden_items ${
              arr.length > 15 ? "antd-select-hidden-border" : ""
            }`}
            mode="multiple"
            style={{
              filter: open ? "blur(3px)" : "none",
              width: "100%",
            }}
            dropdownMatchSelectWidth={true}
            value={arr}
            placeholder="Įvesti"
            fetchOptions={fetchOptions}
            onChange={(newValue, test, test2) => {
              console.log({ newValue, test, test2 });
              // mums senus reikia priskirt _destroy: 1,
              const make_arr = newValue.map((d) => {
                const find = products.find((e) => e.sku == d.value);
                if (find) return find;
                return {
                  id: null,
                  [`${type == "energy" ? "energy_label" : type}_id`]: null,
                  sku: d.value,
                };
              });
              if (
                products.filter((d) => d.id).length !==
                newValue.filter((d) => d.id).length
              ) {
                products
                  .filter((d) => d.id)
                  .filter((d) => !make_arr.find((s) => s.id == d.id))
                  .forEach((d) => {
                    make_arr.push({ ...d, _destroy: true });
                  });
              }

              setValue((prev) => ({
                ...prev,
                [rebuild_type]: make_arr,
              }));
            }}
          />
          {arr.length > 15 && (
            <div>
              <div
                className="d-flex align-items-center justify-content-center cursor-pointer text-orange pt-3 pb-2"
                onClick={() => setOpen(true)}
              >
                <IconExpand className="text-xl text-orange mr-2" />
                Spustelėkite čia norėdami išplėsti vaizdą
              </div>
            </div>
          )}
        </div>
      </Popover>
      {warning && <div className="text-danger">{warning}</div>}
      <div className="mr-3" onClick={() => fileRef.current.click()}>
        <p className="mt-1 mb-1 text-orange text-xs">
          <IconImport className="mr-2 text-orange" />
          Importuoti iš XSLX
        </p>
      </div>
      <input hidden={true} ref={fileRef} type="file" onChange={onChange} />{" "}
    </>
  );
}

export const PopoverModal = ({ products, remove = false, onRemove }) => {
  const [searchVal, setSearchVal] = useState("");
  const [active, setActive] = useState(false);
  const filtered = useMemo(() => {
    if (!searchVal) return products;
    console.log({ products });
    return products.filter(
      (d) =>
        d.value.toString().toUpperCase().indexOf(searchVal.toUpperCase()) > -1
    );
  }, [active]);

  return (
    <div
      style={{
        minwidth: 525,
      }}
    >
      <div className="d-flex align-items-center mb-2">
        <div className="flex-1">
          <TextInputModal
            style={{
              width: "100%",
            }}
            placeholder="Kodas"
            value={searchVal}
            onChange={(e) => {
              const val = e.target.value;
              setSearchVal(val);
            }}
          />
        </div>
        <div className="ml-2">
          <Button onClick={() => setActive((prev) => !prev)}>
            <IconSearch />
          </Button>
        </div>
      </div>

      <div
        style={{
          border: "1px solid #F2F2F2",
          borderRadius: "4px 2px 2px 4px",
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          gap: 5,
          padding: "5px 4px",
        }}
      >
        {filtered.map((d, idx) => (
          <div
            key={`${d?.value}_${idx}`}
            className="d-flex align-items-center justify-content-center cursor-pointer text-sm"
            style={{
              backgroundColor: "#F5F5F5",
              borderRadius: 2,
              padding: "4px 5px 4px 6px",
            }}
            onClick={
              remove
                ? () => {
                    onRemove(d.value);
                    setActive((prev) => !prev);
                  }
                : () => console.log("Remove handler")
            }
          >
            {d?.value}
            {remove && <AiOutlineClose />}
          </div>
        ))}
      </div>
    </div>
  );
};
