import Axios from "axios";
import { SET_CURRENT_USER, GET_ERRORS } from "./types";
import jwt_decode from "jwt-decode";
import HistoryRedirect from "../../helpers/HistoryRedirect";

const error = () => {
  if (!document.getElementById("loginError")) return;
  document.getElementById("loginError").style.display = "block";
  setTimeout(
    () => (document.getElementById("loginError").style.display = "none"),
    5000
  );
};

export const loginUser = (userData, history) => (dispatch) => {
  Axios.post("/login.json", userData)
    .then((res) => {
      localStorage.setItem("trToken", res.headers.authorization);
      const data = res.data;
      const decoded = jwt_decode(res.headers.authorization);
      const user_data = { ...decoded, ...data };
      dispatch(setCurrentUser(user_data));

      HistoryRedirect(user_data.user_role.permissions, history);
    })
    .catch((err) => {
      error();
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const loginUserWithAzure = () => () => {
  Axios.get("/azure_login.json")
    .then((response) => {
      if (response.status === 200 && response.data?.url) {
        window.location.href = response.data.url;
      }
    });
};

export const getOmniauthData = (history) => (dispatch) => {
  Axios.get("/api/v1/logged_in_user?get_token=1").then(({ data, headers, status }) => {
    if (status == 302) {
      return dispatch(logoutUser());
    }

    if (data) {
      if (data.id === null) {
        return dispatch(logoutUser());
      }

      const decoded = jwt_decode(headers.authorization);
      const userData = { ...decoded, ...data };

      dispatch(setCurrentUser(userData));
      dispatch(setAuthenticate());

      const authenticate = userData.iat + "" + userData.exp;

      localStorage.setItem("trAuthenticate", authenticate);
      localStorage.setItem("trToken", headers.authorization);

      HistoryRedirect(data.user_role.permissions, history);
    }
  });
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};
export const setAuthenticate = () => {
  return {
    type: "SUCCESS_AUTHENTICATED",
  };
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("redirectToUsers");
  localStorage.removeItem("trToken");
  localStorage.removeItem("trAuthenticate");

  dispatch(setCurrentUser({}));

  Axios.delete("/logout.json").then((response) => {
    if (response.data?.redirect_to) {
      window.location.href = response.data.redirect_to;
    } else {
      window.location.href = "/sign_in";
    }
  });
};

export const autoLogout = (userId) => async (dispatch, getState) => {
  try {
    const { user, isAuthenticated } = getState().auth;

    if (!isAuthenticated || user.id !== userId) return;

    localStorage.removeItem("redirectToUsers");
    localStorage.removeItem("trToken");
    localStorage.removeItem("trAuthenticate");

    dispatch(setCurrentUser({}));

    window.location.href = "/sign_in";
  } catch (err) {
    console.log("ERROR:", err);
  }
};

export const userAdditionalInformation =
  (user_id) => async (dispatch, getState) => {
    try {
      const { user, isAuthenticated } = getState().auth;
      if (!isAuthenticated) return;
      if (user_id) {
        if (user.id !== user_id) return;
      }

      const { data } = await Axios.get("/api/v1/users/additional_information");
      dispatch({
        type: "SET_ADDITIONAL_INFO",
        payload: data,
      });
    } catch (err) {
      console.log("ERROR:", err);
    }
  };
