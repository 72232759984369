import { Select, Spin } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import useDebounce from "../../../../hooks/useDebounce";
import { postServiceRowChanges } from "../../../../redux/Actions/Service/serviceAction";
import { FlopppyButton } from "../../../common/ButtonWithLoading";
import TextInputModal from "../../../common/TextInputModal";

const { Option } = Select;
const initialState = {
  prime_cost: "",
  product_id: undefined,
};

export default function ServiceChangeProduct({ service_row_id, service_id }) {
  const dispatch = useDispatch();
  const [SearchText, setSearchText] = useState("");
  useDebounce(() => fetchGoods(SearchText, true), 600, [SearchText]);
  const [order_row, set_order_row] = useState(initialState);
  const [Goods, setGoods] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (name, value) => {
    if (name == "product_id") {
      return set_order_row((prev) => ({
        ...prev,
        [name]: value.value,
        prime_cost: value.prime_cost,
      }));
    }
    set_order_row((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const fetchGoods = (value, fetch = false) => {
    setSearchText(value);
    if (fetch && value.length >= 3) {
      setLoading(true);
      axios
        .get(
          `/api/v1/products?page=1&per_page=9999999&q[title_or_identifier_i_cont_any]=${value}`
        )
        .then((response) => {
          if (response.data) {
            const data = response.data.data.map((product) => ({
              wholsale_price: product.wholsale_price,
              product_name: product.title,
              label: product.title,
              value: product.id,
            }));
            setGoods(data);
            setLoading(false);
          }
        });
    }
  };
  const createNewOrderRow = async () => {
    try {
      setError("");
      if (!order_row.prime_cost || !order_row.product_id) {
        return setError("Užpildykite laukelius");
      }
      const { success } = await dispatch(
        postServiceRowChanges(order_row, service_id, service_row_id)
      );
      if (!success) throw new Error("ERR");
      set_order_row(initialState);
    } catch (err) {
      console.log("ERROR: ", err);
      setError("Įvyko klaida");
    }
  };
  return (
    <div className="col-12 bg-silver">
      <div className="d-flex mt-3">
        <span className="pr-1" style={{ width: "70%" }}>
          <div className="text-bold my-1">Prekės paieška</div>
          <Select
            showSearch
            value={order_row.product_id}
            placeholder={"Pasirinkti"}
            dropdownMatchSelectWidth={false}
            notFoundContent={
              Loading ? (
                <div className="d-flex justify-content-center">
                  <Spin size="small" />
                </div>
              ) : null
            }
            filterOption={false}
            onSearch={fetchGoods}
            onChange={(_, value) => handleChange("product_id", value)}
            style={{ width: "100%" }}
          >
            {Goods.map((d) => (
              <Option
                key={d.value}
                prime_cost={d.wholsale_price}
                value={d.value}
              >
                {d.label}
              </Option>
            ))}
          </Select>
        </span>

        <span className="pl-1" style={{ width: "30%" }}>
          <TextInputModal
            text="Pr. savikaina"
            type="number"
            name="prime_cost"
            value={order_row.prime_cost}
            onChange={(e) => handleChange("prime_cost", e.target.value)}
          />
        </span>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="text-danger">{error}</div>
        <span
          className="pl-1 pt-2 pb-3 d-flex justify-content-end"
          style={{ width: "25%" }}
        >
          <FlopppyButton
            loading={Loading}
            onClick={() => createNewOrderRow()}
          />
        </span>
      </div>
    </div>
  );
}
