import React from "react";
import moment from "moment";
import styled from "styled-components";

const PreviewComment = (props) => {
  const textBuilder = (val) => {
    const textValue = val || "";
    const replacePattern1 =
      /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;

    return textValue.replace(
      replacePattern1,
      '<a href="$1" target="_blank">$1</a>'
    );
  };
  
  return (
    <Management className="mt-3">
      <div>
        {props.comments
          .filter((comFilter) => comFilter.comment_type === props.type)
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .map((comment, index) => {
            return (
              <div className="d-flex mb-2" key={index}>
                <div className="block_one flex-column d-flex justify-content-center">
                  <div className="name text-center font-weight-bold">
                    {comment.user_title}
                  </div>
                  <div className="time text-center">
                    {moment(comment.created_at).format("YYYY MM DD")}
                    <br />
                    {moment(comment.created_at).format("HH:mm")}
                  </div>
                </div>
                <div className="block_two_main">
                  <div className="block_two p-2">
                    <div className="title">{comment.comment_state?.name}</div>
                    {comment.date !== null && (
                      <>
                        <div className="name">
                          {moment(comment.date).format("YYYY MM DD")}
                        </div>
                      </>
                    )}

                    <div
                      className="description"
                      style={{ whiteSpace: "pre-wrap" }}
                      dangerouslySetInnerHTML={{
                        __html: `<p>${textBuilder(comment.body)}</p>`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </Management>
  );
};

export default PreviewComment;

const Management = styled.div`
  font-size: 0.75rem;
  td {
    margin-bottom: 3px;
  }
  & .block_one {
    width: 25%;
  }
  & .block_two_main {
    width: 75%;
  }
  & .block_two {
    background-color: #f2f2f2 !important;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 8px;
    .title {
      font-weight: bold;
    }
  }
`;
