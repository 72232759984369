import React from "react";
import Container from "../Layout/Container";
import { connect } from "react-redux";
import {
  getServices,
  getServiceRepairSms,
  getStoresOwnedByService,
} from "../../redux/Actions/Service/serviceAction";
import ContentBlock from "../Layout/ContentBlock";
import Pagination from "../Dashboard/items/Pagination";
import TableColumnService from "./Items/TableColumnService";
import FilterMenu from "../Layout/FilterMenu";
import TableFilterBox from "../common/TableFilterBox";
import ContentValidation from "../common/ContentValidation";
import InputText from "../orders/accepted_orders/InputFilter";
import { ButtonCenter, CheckBoxInput, SelectForm } from "../common/Buttons";
import ServiceScreenAdd from "./Items/ServiceScreen_add";
import ServiceModal from "./Items/ServiceRepairModal";
import {
  exportDocument,
  formatDatePlus,
  getDateFormat,
} from "../../helpers/Utils";
import RangePickerComp from "../common/RangePickerComp";
import { service_type_options } from "../../helpers/GlobalOptions";
import { AlertError, AlertSuccess } from "../common/Alerts";
import { getSuppliersOption } from "../../redux/Actions/supplierAction";
import {
  updateServiceFilter,
  resetServiceFilter,
  valueServiceFilter,
} from "../../redux/Actions/filtersAction";
import Axios from "axios";
import { Label } from "../global";

class ServiceScreen extends React.Component {
  constructor() {
    super();
    this.state = {
      serviceControl: true,
      showModal: false,
      modalInfo: { id: 0, number: 0 },

      page: 1,
      pageCount: 100,
      paramsFilter: "",
      suppliers: [],
      stores: [],
      rivile_stores: [],
      comment_state: [],
      //
      checkedArray: [],
      checkedAll: false,
    };
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    this.onFilter();
    this.props.getServiceRepairSms();
    this.getStores();
    getSuppliersOption().then((response) => {
      if (response.data) {
        let newArray = [{ label: "Visi", value: "" }];
        response.data.data.forEach((item) => {
          const findIndex = newArray.findIndex(
            (find) => find.name == item.name
          );
          if (findIndex == -1) {
            return newArray.push({
              value: item.id,
              label: item.name,
            });
          }
        });
        this.setState({
          suppliers: newArray,
        });
      }
    });
    const fetch_comment_state = async () => {
      try {
        const { data } = await Axios.get(
          `/api/v1/comment_states/search_by_type?&q[comment_type_eq]=comment_ka`
        );
        const comment_state = data.map((e) => ({
          value: e.id,
          label: e.name,
        }));
        this.setState({
          comment_state: [{ label: "Visi", value: null }, ...comment_state],
        });
      } catch (err) {
        console.log("Error:  ", err);
      }
    };
    fetch_comment_state();

    //
    const paramsAction = this.props.match.params?.action;
    if (paramsAction) {
      if (paramsAction === "new") {
        this.setState({
          serviceControl: false,
        });
      } else {
        const buildNumber = +paramsAction + 100;
        this.openModal("modal", {
          id: paramsAction,
          number: "R" + buildNumber,
        });
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.reloadData(this.state.paramsFilter);
    }

    const paramsId = this.props.match.url;
    if (prevProps.match.url !== paramsId) {
      this.setState({
        serviceControl: paramsId === "/service/repair/new" ? false : true,
      });
    }
  }

  getStores = () => {
    getStoresOwnedByService().then((res) => {
      this.setState({
        stores: [{ label: "Visi", value: 0 }, ...res.stores],
        rivile_stores: [{ label: "Visi", value: 0 }, ...res.rivile_stores],
      });
    });
  };
  reloadData = (paramsFilter) => {
    this.props.getServices(this.state.page, this.state.pageCount, paramsFilter);
  };

  filterChange = (e) => {
    this.props.valueServiceFilter({
      name: e.target.name,
      value: e.target.value,
    });
  };

  handleChangeSelect = async (value, name) => {
    value = value || undefined;
    await this.props.valueServiceFilter({ name, value });
    await this.onFilter();
  };

  delayOnFilter = () => {
    if (this.props.loading_store) {
      return setTimeout(() => {
        return this.delayOnFilter();
      }, 1000);
    }
    this.onFilter();
  };

  onFilter = () => {
    if (this.props.loading_store) {
      return this.delayOnFilter();
    }
    let filterParams = [];
    if (this.props.serviceFilter.number.length > 0)
      filterParams.push(
        `&q[number_i_cont_any]=${this.props.serviceFilter.number}`
      );
    if (this.props.serviceFilter.external_number?.length > 0)
      filterParams.push(
        `&q[service_rows_supplyings_external_number_i_cont_any]=${this.props.serviceFilter.external_number}`
      );
    if (this.props.serviceFilter.type)
      filterParams.push(
        `&q[service_type_i_cont_any]=${this.props.serviceFilter.type}`
      );
    if (this.props.serviceFilter.client.length > 0) {
      let clientArray = this.props.serviceFilter.client.split(" ");
      clientArray.forEach((item) => {
        filterParams.push(
          `&q[address_firstname_or_address_lastname_or_address_phone_or_address_other_cont_any]=${item}`
        );
      });
    }

    if (this.props.serviceFilter.goods.length > 0)
      filterParams.push(
        `&q[service_rows_item_name_cont]=${this.props.serviceFilter.goods}
    `
      );

    if (this.props.serviceFilter.status !== undefined) {
      this.props.serviceFilter.status.forEach((each) => {
        filterParams.push(`&q[status_in][]=${each}`);
      });
    }
    // if (this.props.serviceFilter.status) {
    //   filterParams.push(`&q[status_cont]=${this.props.serviceFilter.status}`);
    // }
    if (this.props.serviceFilter.date[0].length > 0)
      filterParams.push(
        `&q[created_at_gteq]=${this.props.serviceFilter.date[0]}`
      );

    if (this.props.serviceFilter.date[1].length > 0) {
      filterParams.push(
        `&q[created_at_lt]=${formatDatePlus(this.props.serviceFilter.date[1])}`
      );
    }
    if (this.props.serviceFilter.supplier) {
      filterParams.push(
        `&q[service_rows_supplyings_supplier_id_eq]=${this.props.serviceFilter.supplier}`
      );
    }
    if (this.props.serviceFilter.supp_date) {
      if (this.props.serviceFilter.supp_date[0].length > 0) {
        filterParams.push(
          `&q[service_rows_supplyings_date_gteq]=${this.props.serviceFilter.supp_date[0]}`
        );
      }

      if (this.props.serviceFilter.supp_date[1].length > 0) {
        filterParams.push(
          `&q[service_rows_supplyings_date_lteq]=${this.props.serviceFilter.supp_date[1]}`
        );
      }
    }
    if (this.props.serviceFilter.stores) {
      filterParams.push(`&q[store_id_eq]=${this.props.serviceFilter.stores}`);
    }

    if (this.props.serviceFilter.rivile_stores) {
      filterParams.push(
        `&q[rivile_store_id_eq]=${this.props.serviceFilter.rivile_stores}`
      );
    }

    if (this.props.serviceFilter.comment.length > 0) {
      this.props.serviceFilter.comment.split(" ").forEach((item) => {
        filterParams.push(`&q[service_comments_comment_cont]=${item}`);
      });
    }
    if (this.props.serviceFilter.comment_state) {
      filterParams.push(
        `&q[service_comments_comment_state_id_eq]=${this.props.serviceFilter.comment_state}`
      );
    }
    if (this.props.serviceFilter.not_closed) {
      filterParams.push(`&q[problems]=${this.props.serviceFilter.not_closed}`);
    }

    this.setState(
      {
        paramsFilter: filterParams.join(""),
        page: 1,
      },
      () => {
        this.reloadData(filterParams.join(""));
        // updateServiceFilter();
      }
    );
  };
  setPagination = (page) => {
    this.setState({
      page,
    });
  };
  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  handleKeyDown = (e) => {
    if (e.key == "Enter") {
      this.onFilter();
    }
  };

  clearFilter = async () => {
    await this.props.resetServiceFilter();
    await this.onFilter();
  };

  renderCustomButton = () => {
    return (
      <>
        <button
          type="button"
          className="rounded-circle float-center mr-3"
          data-toggle="modal"
        >
          {"+"}
        </button>
        <ButtonCenter
          style={{ marginLeft: "5px" }}
          text={"Pridėti naują servisą"}
          onClick={() => this.serviceControl(true)}
        />
        <div className="dropdown">
          <button
            className="btn btn-sm btn-dark float-right mr-3 dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Eksportuoti
          </button>
          <div
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuButton"
          >
            <button
              className="dropdown-item"
              onClick={() =>
                exportDocument(
                  `/api/v1/repair_services/export_documents_exls?${this.state.paramsFilter}`,
                  `export_documents_exls.xlsx`,
                  "POST"
                )
              }
            >
              RMA ataskaita (XLS)
            </button>

            <button
              className="dropdown-item"
              onClick={() =>
                exportDocument(
                  "/api/v1/repair_services/export_ppg_xls",
                  `exported_ppg.xlsx`,
                  "POST"
                )
              }
            >
              PPG remonto ataskaita
            </button>

            <button
              className="dropdown-item"
              onClick={() =>
                exportDocument(
                  "/api/v1/repair_services/export_documents_rma",
                  `exported_rma_filtered.pdf`,
                  "POST",
                  {
                    ids: this.props.service.map((s_item) => s_item.id),
                  }
                )
              }
            >
              Išfiltruotus kaip išvežimus
            </button>
            <button
              className="dropdown-item"
              onClick={() =>
                exportDocument(
                  "/api/v1/repair_services/export_documents_rma",
                  `exported_rma_filtered.pdf`,
                  "POST",
                  {
                    ids: this.props.service.map((s_item) => s_item.id),
                    transfer_back: true,
                  }
                )
              }
            >
              Išfiltruotus kaip parvežimus
            </button>
            <button
              className="dropdown-item"
              onClick={() =>
                exportDocument(
                  "/api/v1/repair_services/export_documents_rma",
                  `exported_rma_filtered.pdf`,
                  "POST",
                  {
                    ids: this.props.service.map((s_item) => s_item.id),
                    akropolis: true,
                  }
                )
              }
            >
              Išfiltruotus kaip į akropoli
            </button>
            <button
              className="dropdown-item"
              onClick={() =>
                exportDocument(
                  "/api/v1/repair_services/export_documents_rma",
                  `exported_rma_filtered.pdf`,
                  "POST",
                  {
                    ids: this.props.service.map((s_item) => s_item.id),
                    akropolis: true,
                    transfer_back: true,
                  }
                )
              }
            >
              Išfiltruotus kaip iš akropolio atgal
            </button>
            <button
              className="dropdown-item"
              onClick={() =>
                exportDocument(
                  "/api/v1/repair_services/export_documents_rma",
                  `exported_rma_selected.pdf`,
                  "POST",
                  {
                    ids: this.state.checkedArray,
                  }
                )
              }
            >
              Pasirinktus kaip išvežimus
            </button>
            <button
              className="dropdown-item"
              onClick={() =>
                exportDocument(
                  "/api/v1/repair_services/export_documents_rma",
                  `exported_rma_selected.pdf`,
                  "POST",
                  {
                    ids: this.state.checkedArray,
                    transfer_back: true,
                  }
                )
              }
            >
              Pasirinktus kaip parvežimus
            </button>
            <button
              className="dropdown-item"
              onClick={() =>
                exportDocument(
                  "/api/v1/repair_services/export_documents_rma",
                  `exported_rma_selected.pdf`,
                  "POST",
                  {
                    ids: this.state.checkedArray,
                    akropolis: true,
                  }
                )
              }
            >
              Pasirinktus kaip į akropolį
            </button>
            <button
              className="dropdown-item"
              onClick={() =>
                exportDocument(
                  "/api/v1/repair_services/export_documents_rma",
                  `exported_rma_selected.pdf`,
                  "POST",
                  {
                    ids: this.state.checkedArray,
                    transfer_back: true,
                    akropolis: true,
                  }
                )
              }
            >
              Pasirinktus kaip iš akropolio
            </button>
          </div>
        </div>
      </>
    );
  };

  serviceControl = (action) => {
    if (action) {
      this.props.history.push(`/service/repair/new`);
    } else {
      this.props.history.push(`/service/repair`);
      this.getStores();
    }
  };

  openModal = (type, info) => {
    this.setState({
      showModal: type,
    });
    if (info) {
      this.setState({
        modalInfo: info,
      });
    }

    if (type == false) {
      this.props.history.push("/service/repair");
    } else if (type == "modal") {
      this.props.history.push(`/service/repair/${info.id}`);
    }
  };

  notClosedFilter = (type) => {
    const type_redux = this.props.serviceFilter.not_closed;
    if (type == type_redux) {
      this.handleChangeSelect("", "not_closed");
    } else {
      this.handleChangeSelect(type, "not_closed");
    }
  };

  handleCheckboxChange = (value) => {
    const currentIndex = this.state.checkedArray.indexOf(value);
    const newChecked = [...this.state.checkedArray];
    const myData = this.props.service;

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    let variableAll = myData.length === newChecked.length;

    this.setState({
      checkedArray: newChecked,
      checkedAll: variableAll,
    });
  };

  handleCheckboxAll() {
    const myData = this.props.service;
    const newChecked = [...this.state.checkedArray];
    let newArray = [];
    if (myData.length === newChecked.length) {
      this.setState({
        checkedAll: false,
        checkedArray: [],
      });
    } else {
      myData.forEach((item) => newArray.push(item.id));
      this.setState({
        checkedAll: true,
        checkedArray: newArray,
      });
    }
  }

  render() {
    const { serviceControl } = this.state;
    return (
      <Container
        location={{
          locationName: "Servisas",
          locationUrl: this.props.match.url,
        }}
      >
        <AlertSuccess alertId={"service-s"} />
        <AlertError alertId={"service-e"} />
        {serviceControl ? (
          <>
            <ContentBlock>
              <FilterMenu
                length={`${this.props.totals || 0} / ${
                  this.props.service.length || 0
                }`}
                onChangeCountPage={this.setPageCount}
                countPage={this.state.pageCount}
                text="servisų"
                text2="Servisų"
                statusFilterOpen={() => this.openModal("status_modal")}
              />
            </ContentBlock>
            <ContentBlock>
              <TableFilterBox
                clearFilter={() => this.clearFilter()}
                // extraClass="justify-content-between"
                button1={this.renderCustomButton()}
              >
                <div className="px-1">
                  <InputText
                    width={"0.75rem"}
                    number="1"
                    name="number"
                    placeholder="Numeris"
                    onKeyDown={this.handleKeyDown}
                    value={this.props.serviceFilter.number}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
                <div className="px-1" style={{ width: "150px" }}>
                  <SelectForm
                    options={[
                      { label: "Visi", value: "" },
                      ...service_type_options,
                    ]}
                    dropdownMatchSelectWidth={false}
                    placeholder="Tipas"
                    value={this.props.serviceFilter.type}
                    onChange={(e) => this.handleChangeSelect(e, "type")}
                  />
                </div>
                <div className="px-1" style={{ width: "150px" }}>
                  <InputText
                    width={"0.75rem"}
                    number="1"
                    name="external_number"
                    placeholder="Remonto numeris"
                    onKeyDown={this.handleKeyDown}
                    value={this.props.serviceFilter.external_number}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
                <div className="px-1">
                  <div className="form-group">
                    <label className="editLabelForm">Data:</label>
                    <div>
                      <RangePickerComp
                        style={{ width: 250 }}
                        placeholder={["Pradžios laikas", "Pabaigos laikas"]}
                        value={[
                          getDateFormat(this.props.serviceFilter.date[0]),
                          getDateFormat(this.props.serviceFilter.date[1]),
                        ]}
                        onChange={(e, args) =>
                          this.handleChangeSelect(args, "date")
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="px-1">
                  <InputText
                    width={"0.75rem"}
                    number="1"
                    name="client"
                    placeholder="Kliento informacija"
                    onKeyDown={this.handleKeyDown}
                    value={this.props.serviceFilter.client}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
                <div className="px-1">
                  <InputText
                    width={"0.75rem"}
                    number="1"
                    name="goods"
                    placeholder="Prekė"
                    onKeyDown={this.handleKeyDown}
                    value={this.props.serviceFilter.goods}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
                <div className="px-1" style={{ width: "150px" }}>
                  <SelectForm
                    options={this.state.suppliers}
                    dropdownMatchSelectWidth={false}
                    placeholder="Servisas"
                    value={this.props.serviceFilter.supplier}
                    onChange={(e) => this.handleChangeSelect(e, "supplier")}
                  />
                </div>
                <div className="px-1">
                  <div className="form-group">
                    <Label>Serviso data</Label>
                    <div>
                      <RangePickerComp
                        style={{ width: 250 }}
                        placeholder={["Pradžios laikas", "Pabaigos laikas"]}
                        value={
                          this.props.serviceFilter.supp_date
                            ? [
                                getDateFormat(
                                  this.props.serviceFilter.supp_date[0] || ""
                                ),
                                getDateFormat(
                                  this.props.serviceFilter.supp_date[1] || ""
                                ),
                              ]
                            : []
                        }
                        onChange={(_, args) =>
                          this.handleChangeSelect(args, "supp_date")
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="px-1" style={{ width: "150px" }}>
                  <SelectForm
                    options={this.state.stores}
                    dropdownMatchSelectWidth={false}
                    placeholder="Parduotuvė"
                    value={this.props.serviceFilter.stores}
                    onChange={(e) => this.handleChangeSelect(e, "stores")}
                  />
                </div>
                <div className="px-1" style={{ width: "150px" }}>
                  <SelectForm
                    options={this.state.rivile_stores}
                    dropdownMatchSelectWidth={false}
                    placeholder="Padalinys"
                    value={this.props.serviceFilter.rivile_stores}
                    onChange={(e) =>
                      this.handleChangeSelect(e, "rivile_stores")
                    }
                  />
                </div>
                <div className="px-1">
                  <InputText
                    style={{ width: 150, fontSize: "0.75rem" }}
                    number="1"
                    name="comment"
                    placeholder="Komentarai"
                    onKeyDown={this.handleKeyDown}
                    onChange={(e) => this.filterChange(e)}
                    value={this.props.serviceFilter.comment}
                  />
                </div>
                <div className="px-1" style={{ width: "150px" }}>
                  <SelectForm
                    options={this.state.comment_state}
                    dropdownMatchSelectWidth={false}
                    placeholder="Komentarų statusai"
                    value={this.props.serviceFilter.comment_state}
                    onChange={(e) =>
                      this.handleChangeSelect(e, "comment_state")
                    }
                  />
                </div>
                {/*  */}
                <div className="px-1 d-flex">
                  <div className="mr-3 d-flex justify-content-center align-items-center">
                    <span
                      className="filter-order-span"
                      style={{
                        color:
                          this.props.serviceFilter.not_closed == "late"
                            ? "#FF0000"
                            : "#bfbfbf",
                      }}
                      onClick={() => this.notClosedFilter("late")}
                    >
                      Vėluojantys
                    </span>
                    <div
                      className="ml-2 text-center filter-orders-active"
                      style={{ background: "#FF0000" }}
                    >
                      {this.props.total_late}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <span
                      className="filter-order-span"
                      style={{
                        color:
                          this.props.serviceFilter.not_closed == "complaints"
                            ? "green"
                            : "#bfbfbf",
                      }}
                      onClick={() => this.notClosedFilter("complaints")}
                    >
                      Skundai
                    </span>
                    <div
                      className="ml-2 text-center filter-orders-active"
                      style={{ background: "green" }}
                    >
                      {this.props.total_complaints}
                    </div>
                  </div>
                </div>
                {/*  */}
              </TableFilterBox>
              <div>
                <ContentValidation
                  loader={this.props.loading}
                  array={this.props.service}
                >
                  <div className="pt-3">
                    <Pagination
                      setPagination={this.setPagination}
                      page={this.state.page}
                      totals={this.props.totals}
                      pageCount={this.state.pageCount}
                    />
                  </div>
                  <div className="table-responsive">
                    <table className="table table-sm table-hover mt-3">
                      <thead>
                        <tr className="text-muted">
                          <th className="text-black">
                            <div className="d-flex">
                              <CheckBoxInput
                                type="checkbox"
                                color="#f7951e"
                                checked={this.state.checkedAll}
                                onChange={() => this.handleCheckboxAll()}
                              />
                            </div>
                          </th>
                          <th className="text-black">Data</th>
                          <th className="text-black">Numeris</th>
                          <th className="text-black">Tipas</th>
                          <th className="text-black">Kliento informacija</th>
                          <th className="text-black">Prekė</th>
                          <th className="text-black">Gedimas</th>
                          <th className="text-black">Parduotuvė</th>
                          <th className="text-black">Kurjeris</th>
                          <th className="text-black">Pristatymas</th>
                          <th className="text-black">Statusas</th>
                          <th className="text-black">KA komentarai</th>
                          <th className="text-black">Logistikos komentarai</th>
                          <th className="text-black">Suma</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.service.map((item, index) => {
                          return (
                            <TableColumnService
                              key={index}
                              index={index}
                              item={item}
                              openModal={this.openModal}
                              checked_in_array={this.state.checkedArray.includes(
                                item.id
                              )}
                              handleCheckboxChange={this.handleCheckboxChange}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                    <Pagination
                      setPagination={this.setPagination}
                      page={this.state.page}
                      totals={this.props.totals}
                      pageCount={this.state.pageCount}
                    />
                  </div>
                </ContentValidation>
              </div>
            </ContentBlock>
          </>
        ) : (
          <ServiceScreenAdd onClose={this.serviceControl} />
        )}

        <ServiceModal
          status_selected={this.props.serviceFilter.status}
          status_onChange={(e) => this.handleChangeSelect(e, "status")}
          showModal={this.state.showModal}
          modalInfo={this.state.modalInfo}
          closeModal={this.openModal}
        />
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  service: state.service.service,
  totals: state.service.totals,
  total_complaints: state.service.total_complaints,
  total_late: state.service.total_late,
  loading: state.service.loading,
  serviceFilter: state.filters.service,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getServices,
  getServiceRepairSms,
  updateServiceFilter,
  resetServiceFilter,
  valueServiceFilter,
})(ServiceScreen);
