import React from "react";
import moment from "moment";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

function RangePickerComp({
  disabled,
  format,
  showTime,
  disabledHours,
  hideDisabledOption,
  placeholder,
  value,
  onChange,
  style,
  disabledDate,
}) {
  const disabledDate2 = (current) => current && current.isAfter(moment());

  moment.locale("en", {
    week: {
      dow: 1,
    },
  });

  return (
    <RangePicker
      disabled={disabled}
      className="w-100"
      format={format}
      showTime={showTime}
      style={style}
      disabledHours={disabledHours}
      hideDisabledOption={hideDisabledOption}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabledDate={!!disabledDate ? disabledDate : disabledDate2}
    />
  );
}

export default RangePickerComp;
