import React from "react";
import { connect } from "react-redux";
import InputText from "../../orders/accepted_orders/InputFilter";
import Container from "../../Layout/Container";
import ContentBlock from "../../Layout/ContentBlock";
import FilterMenu from "../../Layout/FilterMenu";
import TableFilterBox from "../../common/TableFilterBox";
import TableColumnTickets from "./items/TableColumnTickets";
import TicketEditModal from "./items/TicketEditModal";
import ContentValidation from "../../common/ContentValidation";
import {
  getLotteryTicket,
  getLotteryTicketWithoutReload,
  postLotteryTicket,
  putLotteryTicket,
  deleteLotteryTicket,
  claimedTicket,
  clearErrors,
} from "../../../redux/Actions/lotteryAction";
import PaginationComp from "../../Dashboard/items/Pagination";
import TicketNewModal from "./items/TicketNewModal";
import bilietaiSvg from "../../../../assets/images/bilietai.svg";

class Tickets extends React.Component {
  constructor() {
    super();
    this.state = {
      requiredId: 0,
      page: 1,
      pageCount: 100,
      paramsFilter: "",
      filter: {
        code: "",
        prize: "",
        project: "",
      },
    };
  }

  componentDidMount() {
    this.reloadData(this.state.paramsFilter);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.reloadData(this.state.paramsFilter);
    }
  }

  reloadData = (paramsFilter) => {
    this.props.getLotteryTicket(
      this.state.page,
      this.state.pageCount,
      paramsFilter
    );
  };

  onAction = (index) => {
    this.setState({
      requiredId: index,
    });
  };

  delayOnFilter = () => {
    if (this.props.loading_tickets) {
      return setTimeout(() => {
        return this.delayOnFilter();
      }, 1000);
    }
    this.onFilter();
  };


  onFilter = () => {
    if (this.props.loading_tickets) {
      return this.delayOnFilter();
    }
    let filterParams = [];
    if (this.state.filter.code.length > 0)
      filterParams.push(`&q[code_i_cont_any]=${this.state.filter.code}`);
    if (this.state.filter.prize.length > 0) {
      filterParams.push(`&q[prize_i_cont_any]=${this.state.filter.prize}`);
    }
    if (this.state.filter.project.length > 0) {
      filterParams.push(`&q[project_i_cont_any]=${this.state.filter.project}`);
    }
    this.setState(
      {
        paramsFilter: filterParams.join(""),
        page: 1
      },
      () => this.reloadData(filterParams.join(""))
    );
  };

  filterChange = (e) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [e.target.name]: e.target.value,
      },
    });
  };

  setPagination = (page) => {
    this.setState({
      page,
    });
  };
  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };
  handleKeyDown = (e) => {
    if (e.key == "Enter") {
      this.onFilter();
    }
  };

  getData = () => {
    this.props.getLotteryTicketWithoutReload(
      this.state.page,
      this.state.pageCount
    );
  };

  clearFilter = () => {
    this.setState(
      {
        filter: {
          code: "",
          prize: "",
        },
      },
      () => this.onFilter()
    );
  };

  render() {
    const { errors, tickets } = this.props;
    const requiredItem = this.state.requiredId;
    let modalData = tickets[requiredItem] ? tickets[requiredItem] : {};
    return (
      <>
        <Container
          location={{
            locationName: "Bilietai",
            locationUrl: this.props.match.url,
          }}
        >
          <>
            <ContentBlock>
              <FilterMenu
                length={`${this.props.totals_tickets || 0} / ${
                  this.props.tickets.length || 0
                }`}
                onChangeCountPage={this.setPageCount}
                countPage={this.state.pageCount}
                text="bilietų"
                text2="Bilietų"
                icon={bilietaiSvg}
              />
            </ContentBlock>
            <ContentBlock>
              <TableFilterBox
                // filter={true}
                add={this.onAction}
                addText={"Įkelti bilietus"}
                // onFilter={this.onFilter}
                modalId={"#tableNew"}
                clearFilter={() => this.clearFilter()}
              >
                <div className="px-1">
                  <InputText
                    width={"0.75rem"}
                    number="1"
                    name="code"
                    placeholder="Žaidimo kodas"
                    onKeyDown={this.handleKeyDown}
                    value={this.state.filter.code}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
                <div className="px-1">
                  <InputText
                    width={"0.75rem"}
                    number="1"
                    name="project"
                    placeholder="Projektas"
                    onKeyDown={this.handleKeyDown}
                    value={this.state.filter.project}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
                <div className="px-1">
                  <InputText
                    width={"0.75rem"}
                    number="1"
                    name="prize"
                    placeholder="Prizas"
                    onKeyDown={this.handleKeyDown}
                    value={this.state.filter.prize}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
              </TableFilterBox>
              <div>
                <ContentValidation
                  loader={this.props.loading_tickets}
                  array={this.props.tickets}
                  alertId_s="ticketAlert-s"
                  alertId_e="ticketAlert-e"
                >
                  <div className="table-responsive">
                    <table className="table table-sm table-hover mt-3">
                      <thead>
                        <tr className="text-muted">
                          <th className="text-black"></th>
                          <th className="text-black">Žaidimo kodas</th>
                          <th className="text-black">Projektas</th>
                          <th className="text-black">Prizas</th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.tickets.map((item, index) => {
                          return (
                            <TableColumnTickets
                              key={index}
                              index={index}
                              item={item}
                              claimed={this.props.claimedTicket}
                              onEdit={this.onAction}
                              onDelete={this.props.deleteLotteryTicket}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                    <PaginationComp
                      setPagination={this.setPagination}
                      page={this.state.page}
                      totals={this.props.totals_tickets}
                      pageCount={this.state.pageCount}
                    />
                  </div>
                </ContentValidation>
              </div>
            </ContentBlock>
          </>
          {this.props.tickets.length > 0 && (
            <TicketEditModal
              putLotteryTicket={this.props.putLotteryTicket}
              objectData={modalData}
              clearErrors={this.props.clearErrors}
              errors={errors}
            />
          )}
          <TicketNewModal
            postLotteryTicket={this.props.postLotteryTicket}
            getLotteryTicket={() => this.getData()}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  tickets: state.lottery.tickets,
  totals_tickets: state.lottery.totals_tickets,
  loading_tickets: state.lottery.loading_tickets,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getLotteryTicket,
  getLotteryTicketWithoutReload,
  postLotteryTicket,
  putLotteryTicket,
  claimedTicket,
  deleteLotteryTicket,
  clearErrors,
})(Tickets);
