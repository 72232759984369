import { message } from "antd";
import Axios from "axios";
import {
  GET_MANAGERS,
  EDIT_MANAGER,
  LOADING_MANAGERS,
  SHOW_MANAGER,
  CLEAR_ERRORS,
  GET_ERRORS,
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

const success = () => {
  if (!document.getElementById("managerAlert-s")) return;
  document.getElementById("managerAlert-s").style.display = "block";
  setTimeout(
    () => (document.getElementById("managerAlert-s").style.display = "none"),
    5000
  );
  document.getElementById("modalClose").click();
};

const error = () => {
  if (!document.getElementById("managerAlert-e")) return;
  document.getElementById("managerAlert-e").style.display = "block";
  setTimeout(
    () => (document.getElementById("managerAlert-e").style.display = "none"),
    5000
  );
};

export const getManagers = (page, pageCount, filter) => (dispatch) => {
  dispatch(setManagersLoading());
  Axios.get(`/api/v1/managers?page=${page}&per_page=${pageCount}${filter}`)
    .then((res) => {
      let response = res.data;
      if (!res.data && !res.data.data) {
        response = {
          managers: [],
          totals: 0,
        };
      }
      dispatch({
        type: GET_MANAGERS,
        payload: response,
      });
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const syncPayModule = async () => {
  try {
    message.info("Algų modulis: SYNC vykdoma");
    const { data } = await Axios.post("/api/v1/managers/sync_managers");
    console.log({ data });
  } catch (err) {
    console.error(err);
    message.error("Algų modulis: SYNC klaida");
  }
};

export const syncPayModuleRivile = async () => {
  try {
    message.info("Algų modulis: Rivilė SYNC vykdoma");
    const { data } = await Axios.post("/api/v1/managers/sync_managers_rivile");
    console.log({ data });
  } catch (err) {
    console.error(err);
    message.error("Algų modulis: Rivilė SYNC klaida");
  }
};

export const getManager = (id, filter) => (dispatch) => {
  Axios.get(`/api/v1/managers/${id}?${filter}`)
    .then((res) => {
      dispatch({
        type: SHOW_MANAGER,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateManager = (_id, body, indexOfArray) => (dispatch) => {
  Axios.put(`/api/v1/managers/${_id}`, body)
    .then((res) => {
      dispatch(clearErrors());
      dispatch({
        type: EDIT_MANAGER,
        payload: res.data,
        payload_id: _id,
      });
      success("update");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const setManagersLoading = () => {
  return {
    type: LOADING_MANAGERS,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
