import React, { useState, useEffect } from "react";
import BasicModal from "../../../Layout/BasicModal";

import styled from "styled-components";
import Axios from "axios";
import CategoryEach from "./categoryEach";
import { CheckBoxInput } from "../../../common/Buttons";
import { FaPlus, FaMinus } from "react-icons/fa";

function CategoryFilterModal(props) {
  const [Category, setCategory] = useState([]);
  // const [Checked, setChecked] = useState([]);
  const [CurrentOpen, setCurrentOpen] = useState([]);

  useEffect(() => {
    Axios.get(`/api/v1/orders/shop_categories`).then((res) => {
      if (typeof res.data[0] !== "undefined") {
        if (typeof res.data[0].children !== "undefined") {
          if (typeof res.data[0].children[0].children !== "undefined") {
            setCategory(res.data[0].children[0].children);
          }
        }
      }
    });
  }, []);
  const handleCheckboxChange = (value, array) => {
    const currentIndex = props.categoryFilter.indexOf(value);
    let newChecked = [...props.categoryFilter];
    let control;
    if (currentIndex === -1) {
      newChecked.push(value);
      control = "add";
    } else {
      newChecked.splice(currentIndex, 1);
      control = "remove";
    }

    if (array.length > 0) {
      let myData = checkboxMassFunction(array, newChecked, control);
      props.filterCategoryChange(myData);
      // setChecked(labas);
    } else {
      props.filterCategoryChange(newChecked);
      // setChecked(newChecked);
    }
  };

  const checkboxMassFunction = (array, correctArray, control) => {
    let newObj = correctArray;
    array.forEach((element) => {
      const currentIndex = newObj.indexOf(element.id_category);
      if (currentIndex == -1 && control == "add") {
        newObj.push(element.id_category);
      }
      if (currentIndex !== -1 && control == "remove") {
        newObj.splice(currentIndex, 1);
      }

      if (element.children.length > 0) {
        return checkboxMassFunction(element.children, newObj, control);
      }
    });
    return newObj;
  };

  const handleToggle = (value) => {
    const currentIndex = CurrentOpen.indexOf(value);
    const newChecked = [...CurrentOpen];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCurrentOpen(newChecked);
  };

  return (
    <div className="row">
      <div className="col">
        <div>
          <Divs className="container-title-block">
            <MainBlock>
              <span>Pasirinkimai</span>
            </MainBlock>
            <ul className="list-group">
              {Category.map((item, index) => {
                let lengthItems = item.children.length > 0;

                return (
                  <React.Fragment key={index}>
                    <li className="list-group-item node-treeview mb-2 d-flex justify-content-between">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => handleToggle(item.id_category)}
                      >
                        {CurrentOpen.includes(item.id_category) ? (
                          <FaMinus
                            title="Uždaryti"
                            className="mr-2 text-danger"
                            style={{
                              visibility: lengthItems ? "visible" : "hidden",
                            }}
                          />
                        ) : (
                          <FaPlus
                            title="Atidaryti"
                            className="mr-2 text-success"
                            style={{
                              visibility: lengthItems ? "visible" : "hidden",
                            }}
                          />
                        )}
                        {item.title}{" "}
                        {lengthItems && (
                          <span className="font-weight-bold">
                            ({item.children.length})
                          </span>
                        )}
                      </div>
                      <div>
                        <CheckBoxInput
                          type="checkbox"
                          color="#f7951e"
                          checked={props.categoryFilter.includes(
                            item.id_category
                          )}
                          onChange={() =>
                            handleCheckboxChange(
                              item.id_category,
                              item.children
                            )
                          }
                        />
                      </div>
                    </li>
                    {CurrentOpen.includes(item.id_category) &&
                      typeof item.children !== "undefined" &&
                      item.children.length > 0 &&
                      item.children.map((item, index) => {
                        return (
                          <CategoryEach
                            key={index}
                            item={item}
                            Checked={props.categoryFilter}
                            handleCheckboxChange={handleCheckboxChange}
                            CurrentOpen={CurrentOpen}
                            handleToggle={handleToggle}
                            level={7}
                          />
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </ul>
          </Divs>
        </div>
      </div>
    </div>
  );
}

const MainBlock = styled.div`
  background-color: #f2f2f2;
  padding: 8px 10px;
  display: block;
  font-weight: 700;
  color: #000000;
  font-size: 10px;
`;

const Divs = styled.div`
  margin: 6px 0 !important;
  display: block;
  color: #000000;
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
  & .text-bold {
    font-weight: 700;
  }
  & .form-input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 0.5px);
    padding: 0.1rem 0.2rem;
    font-size: 0.6rem;
    border: 1px solid silver;
    background-color: transparent;
    color: black;
    border-radius: 0.25rem;
    &:active {
      border: 1px solid #f7951e !important;
      border-radius: 0.25rem !important;
    }
    &:focus {
      border: 1px solid #f7951e !important;
      border-radius: 0.25rem !important;
      & input {
        outline: none !important;
      }
    }
  }
  & .orange_color {
    color: #f7951e;
  }
`;

export default CategoryFilterModal;
