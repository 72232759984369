import {
  LOADING_RIVILE_SERVICE,
  UPDATE_RIVILE_SERVICE,
  GET_RIVILE_SERVICE,
  DELETE_RIVILE_SERVICE,
  POST_RIVILE_SERVICE,
} from "../Actions/types";

const initialState = {
  rivile_service: [],
  totals: 0,
  loading_rivile_service: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_RIVILE_SERVICE:
      return {
        ...state,
        loading_rivile_service: true,
      };
    case POST_RIVILE_SERVICE:
      let updateData = [...state.rivile_service];
      updateData.push(action.payload);
      return {
        ...state,
        rivile_service: updateData
      };
    case GET_RIVILE_SERVICE:
      return {
        ...state,
        rivile_service: action.payload.data,
        totals: action.payload.totals,
        loading_rivile_service: false,
      };
    case UPDATE_RIVILE_SERVICE:
      const updatedRivileServiceArray = [...state.rivile_service];
      const findIndex = updatedRivileServiceArray.findIndex(
        (find) => find.id == action.payload.id
      );
      if (findIndex !== -1) {
        updatedRivileServiceArray[findIndex] = action.payload;
      }
      return {
        ...state,
        rivile_service: updatedRivileServiceArray,
      };
    case DELETE_RIVILE_SERVICE:
      return {
        ...state,
        rivile_service: [...state.rivile_service].filter(
          filterItem => filterItem.id !== action.payload
        ),
        loading_rivile_service: false
      };
    default:
      return state;
  }
}
