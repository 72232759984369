import React, { Component } from "react";
import Container from "../../Layout/Container";
import TableFilterBox from "../../common/TableFilterBox";
import ContentBlock from "../../Layout/ContentBlock";
import FilterMenu from "../../Layout/FilterMenu";
import ContentValidation from "../../common/ContentValidation";
import TableColumnShipment from "./item/TableColumnShipment";
import {
  getCourrierSummary,
  getShipmentsOmniva,
  removeShipmentBeforeCalled,
} from "../../../redux/Actions/shipmentsAction";
import { getPickupAddresses } from "../../../redux/Actions/pickupAddressAction";
import { getOrder } from "../../../redux/Actions/orderAction";
import { connect } from "react-redux";
import InputText from "../../orders/accepted_orders/InputFilter";
import PaginationComp from "../../Dashboard/items/Pagination";
import { SelectForm } from "../../common/Buttons";
import RangePickerComp from "../../common/RangePickerComp";
import { AlertSuccess, AlertError } from "../../common/Alerts";
import RegularOrderChildren from "../../common/GlobalModals/Orders/RegularOrderChildren";
import { formatDatePlus } from "../../../helpers/Utils";

class OMNIVA_Shipment extends Component {
  constructor() {
    super();
    this.state = {
      paramsFilter: "",
      requiredOrderId: 0,
      page: 1,
      pageCount: 100,
      filter: {
        date: ["", ""],
        orderId: "",
        supplier: "",
        client: "",
        client_address: "",
        tracking_number: "",
      },
      optionArray: [
        {
          value: "",
          label: "Visi",
        },
      ],
    };
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    this.reloadData(this.state.paramsFilter);
    this.props.getPickupAddresses(1, 10000);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.reloadData(this.state.paramsFilter);
    }
    if (
      this.state.filter.date !== prevState.filter.date ||
      this.state.filter.supplier !== prevState.filter.supplier
    ) {
      this.onFilter();
    }
  }

  reloadData = (paramsFilter) => {
    this.props.getShipmentsOmniva(
      this.state.page,
      this.state.pageCount,
      paramsFilter
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pickupAddressesItems) {
      const optionArray = [...this.state.optionArray];
      nextProps.pickupAddressesItems.forEach((item) => {
        if (!optionArray.some((some) => some.label == item.code)) {
          optionArray.push({
            value: item.code,
            label: item.code,
          });
        }
      });
      this.setState({
        optionArray,
      });
    }
  }

  handleKeyDown = (e) => {
    if (e.key == "Enter") {
      this.onFilter();
    }
  };

  filterChange = (e, date) => {
    if (date) {
      this.setState({
        filter: {
          ...this.state.filter,
          [date]: e,
        },
      });
    } else {
      this.setState({
        filter: {
          ...this.state.filter,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  setPagination = (page) => {
    this.setState({
      page,
    });
  };

  onFilter = () => {
    let filterParams = [];
    // Order ID
    if (this.state.filter.orderId.length > 0) {
      let order_id_string = this.state.filter.orderId.toLowerCase();
      if (order_id_string.startsWith("r")) {
        order_id_string = `&q[service_order_of_Service_type_number_cont]=${order_id_string}`;
      } else if (order_id_string.startsWith("t")) {
        order_id_string = `&q[service_order_of_Order_type_id_matches]=${order_id_string.split("t").join("")}&q[service_order_of_Order_type_created_cont]=užsakymai`;
      } else {
        order_id_string = `&q[service_order_of_Order_type_id_order_cont]=${order_id_string}`;
      }
      filterParams.push(order_id_string);
    }
    // Client
    if (this.state.filter.client.length > 0) {
      let clientArray = this.state.filter.client.split(" ");
      clientArray.forEach((item) => {
        filterParams.push(`&q[shipment_address_name_cont_any]=${item}`);
      });
    }
    // Client address
    if (this.state.filter.client_address.length > 0)
      filterParams.push(
        `&q[shipment_address_address_cont_any]=${this.state.filter.client_address}`
      );
    // Tracking
    if (this.state.filter.tracking_number.length > 0)
      filterParams.push(
        `&q[tracking_numbers_raw_cont]=${this.state.filter.tracking_number}`
      );
    // Date
    if (this.state.filter.date[0].length > 0)
      filterParams.push(`&q[created_at_gteq]=${this.state.filter.date[0]}`);

    if (this.state.filter.date[1].length > 0)
      filterParams.push(
        `&q[created_at_lt]=${formatDatePlus(this.state.filter.date[1])}`
      );

    // Supplier
    if (this.state.filter.supplier.length > 0)
      filterParams.push(
        `&q[pickup_address_code_cont_any]=${this.state.filter.supplier}`
      );

    this.setState(
      {
        paramsFilter: filterParams.join(""),
        page: 1
      },
      () => this.reloadData(filterParams.join(""))
    );
  };

  clearFilter = () => {
    this.setState(
      {
        filter: {
          date: ["", ""],
          orderId: "",
          supplier: "",
          client: "",
          client_address: "",
          tracking_number: "",
        },
      },
      () => this.onFilter()
    );
  };
  orderModal = (order_id, id, type) => {
    this.setState({
      requiredOrderId: order_id,
    });
    if(type === 'Service') {
      return this.modalRef.current.openModal("ServiceShowModal");
      
    }
    this.props.getOrder(id);
    this.modalRef.current.openModal("OrderShowModal");
  };

  render() {
    return (
      <Container
        location={{
          locationName: "OMNIVA siuntos",
          locationUrl: this.props.match.url,
        }}
      >
        <ContentBlock>
          <FilterMenu
            length={`${this.props.totals_omniva || 0} / ${
              this.props.shipment_omniva.length || 0
            }`}
            onChangeCountPage={this.setPageCount}
            countPage={this.state.pageCount}
            text="OMNIVA siuntų"
            text2="OMNIVA siuntų"
          />
        </ContentBlock>
        <ContentBlock>
          <TableFilterBox
            add={false}
            onFilter={this.onFilter}
            filter={true}
            clearFilter={() => this.clearFilter()}
            button1={
              <div>
                <button
                  className="btn btn-sm float-right mr-3 btn-dark"
                  onClick={() =>
                    getCourrierSummary("omniva", this.state.paramsFilter)
                  }
                >
                  Ataskaita
                </button>
              </div>
            }
          >
            <div className="px-1" style={{ width: "20rem" }}>
              <div className="form-group">
                <label className="editLabelForm">Data:</label>
                <div>
                  <RangePickerComp
                    placeholder={["Pradžios laikas", "Pabaigos laikas"]}
                    // value={this.state.filter.date}
                    onChange={(e, args) => this.filterChange(args, "date")}
                  />
                </div>
              </div>
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="orderId"
                placeholder="Užsakymo nr."
                onKeyDown={this.handleKeyDown}
                value={this.state.filter.orderId}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1">
              <SelectForm
                placeholder={"Tiekėjas"}
                dropdownMatchSelectWidth={false}
                onChange={(e) => this.filterChange(e, "supplier")}
                value={this.state.filter.supplier}
                options={this.state.optionArray}
              />
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="client"
                placeholder="Gavėjas"
                onKeyDown={this.handleKeyDown}
                value={this.state.filter.client}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="client_address"
                placeholder="Gavėjo adresas"
                onKeyDown={this.handleKeyDown}
                value={this.state.filter.client_address}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="tracking_number"
                placeholder="Sekimo nr."
                onKeyDown={this.handleKeyDown}
                value={this.state.filter.tracking_number}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
          </TableFilterBox>
          <AlertSuccess alertId={"shipment-s"} />
          <AlertError alertId={"shipment-e"} />
          <div>
            <ContentValidation
              loader={this.props.loading_shipment_omniva}
              array={this.props.shipment_omniva}
            >
              <div className="table-responsive">
                <table className="table table-sm table-hover mt-3">
                  <thead>
                    <tr className="text-muted">
                      <th className="text-black">Atsp.</th>
                      <th className="text-black">Perd.</th>
                      <th className="text-black">Sukurta</th>
                      <th className="text-black">Užs.nr.</th>
                      <th className="text-black">Tiekėjas</th>
                      <th className="text-black">Paketai</th>
                      <th className="text-black">Svoris</th>
                      <th className="text-black">Gavėjas</th>
                      <th className="text-black">Gavėjo adresas</th>
                      <th className="text-black">Komentaras</th>
                      <th className="text-black">Numeriai</th>
                      <th className="text-black">Parsisiųsti</th>
                      <th className="text-black"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.shipment_omniva.map((item, index) => {
                      return (
                        <TableColumnShipment
                          item={item}
                          key={index}
                          removeShipmentBeforeCalled={
                            this.props.removeShipmentBeforeCalled
                          }
                          orderModal={this.orderModal}
                        />
                      );
                    })}
                  </tbody>
                </table>
                <PaginationComp
                  setPagination={this.setPagination}
                  page={this.state.page}
                  totals={this.props.totals_omniva}
                  pageCount={this.state.pageCount}
                />
              </div>
            </ContentValidation>
          </div>
        </ContentBlock>
        <div id="modal-roots" />
        <RegularOrderChildren
          ref={this.modalRef}
          requiredOrderId={this.state.requiredOrderId}
        />
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  pickupAddressesItems: state.pickupAddress.pickupAddresses,
  shipment_omniva: state.shipment.shipment_omniva,
  totals_omniva: state.shipment.totals_omniva,
  loading_shipment_omniva: state.shipment.loading_shipment_omniva,
});

export default connect(mapStateToProps, {
  getOrder,
  getShipmentsOmniva,
  getPickupAddresses,
  removeShipmentBeforeCalled,
})(OMNIVA_Shipment);
