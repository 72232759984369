import Axios from "axios";
import moment from "moment";
import { message } from "antd";

export const getDateFormat = (date) => {
  if (!date) return "";
  var d = moment(date);
  return date && d.isValid() ? d : "";
};

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const antdMessageSuccess = (text) => {
  message.success(text || "Sėkmingai atlikta");
};

export const antdMessageInfo = (text) => {
  message.info(text || "Generuoja");
};
export const antdMessageError = (text) => {
  message.error(text || "Įvyko klaida");
};

export const colorBlockShipment = (item) => {
  if (item.picked_up_at && !item.delivered_at) {
    return "badge badge-pill badge-warning";
  } else if (item.picked_up_at && item.delivered_at) {
    return "badge badge-pill badge-success";
  } else if (item.delivered_at) {
    return "badge badge-pill badge-success";
  } else {
    return "badge badge-pill badge-light";
  }
};

export const phonenumberValid = (inputtxt) => {
  if (!inputtxt) {
    return false;
  }
  const inputStarts = inputtxt.startsWith("+370");
  if (!inputStarts) {
    return false;
  }
  const inputLength = inputtxt.split("+370")[1].trim();
  if (inputLength.length === 8) {
    let validAccess = true;
    inputLength.split("").forEach((item) => {
      if (isNaN(Number(item))) {
        validAccess = false;
      }
    });

    if (validAccess) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};
export const emailValid = (str) => {
  const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return pattern.test(str);
};

export const toFixedNumber = (number) => {
  number = +number;
  if (!number) return 0;
  return number.toFixed(2).replace(".00", "");
};

export const exportDocument = (url, docName, method = "GET", data = {}) => {
  antdMessageInfo("Ruošiama parsisiųsti");
  return Axios({
    url: url,
    method,
    data,
    responseType: "blob", // important
    headers: { Accept: "application/vnd.ms-excel" },
  })
    .then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", docName); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
      antdMessageSuccess(`Sėkmingai parsiunčiama ${docName}`);
      return { success: true };
    })
    .catch((err) => {
      errorHandler(err);
      antdMessageError(err?.response?.data?.message);
      return { success: false };
    });
};

export function getUrlExtension(url, format = true) {
  try {
    const url_extension = url.split("/").pop();
    if (!format) return url_extension;
    return url_extension.match(/^[\w\s-,.]+\.([a-zA-Z0-9]{3,4})/)[1];
  } catch (ignored) {
    return false;
  }
}
export const formatDatePlus = (date) =>
  moment(date).add(1, "days").format("YYYY-MM-DD");

export const url_to_store_by_reference = (ref) => {
  const url = "https://www.technorama.lt";
  return (
    url +
    `/module/mijoratools/openproduct?token=cdb9c3212cbe04eb5bd61803af6ca766&sku=${ref}`
  );
};

export const prettyDateFormat = (date) => {
  if (date) {
    return moment(date).format("YYYY-MM-DD HH:mm");
  }
  return moment().format("YYYY-MM-DD HH:mm");
};

export function makeid(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const parcelTerminalBulder = (items) => {
  let lp_express = [];
  let omniva = [];
  let dpd = [];
  let itella = [];
  let venipak = [];
  if (items.lp_express?.length > 0) {
    items.lp_express.forEach((item) => {
      const parcel = {
        label: item.title + ", " + item.address + ", " + item.city,
        value: item.id,
      };
      return lp_express.push(parcel);
    });
  }
  if (items.omniva?.length > 0) {
    items.omniva.forEach((item) => {
      const parcel = {
        label: item.title,
        value: item.id,
      };
      return omniva.push(parcel);
    });
  }
  if (items.dpd?.length > 0) {
    items.dpd.forEach((item) => {
      const parcel = {
        label: item.title + ", " + item.city,
        value: item.id,
      };
      return dpd.push(parcel);
    });
  }

  if (items.itella?.length > 0) {
    items.itella.forEach((item) => {
      const parcel = {
        label: item.title + ", " + item.city,
        value: item.id,
      };
      return itella.push(parcel);
    });
  }

  if (items.venipak?.length > 0) {
    items.venipak.forEach((item) => {
      const parcel = {
        label: item.title + ", " + item.city,
        value: item.id,
      };
      return venipak.push(parcel);
    });
  }
  return { omniva, lp_express, dpd, itella, venipak };
};
// export const getDateFormat = (date) => {
//   var d = moment(date);
//   return date && d.isValid() ? d : "";
// };

export const numberRound = (number) => {
  number = +number;
  if (!number) return "0";
  return number.toFixed(2).replace(".00", "");
};

export const returnEuroString = (number) => {
  const num = +number || 0;
  if (num == 0) return num + " €";
  return numberRound(num) + " €";
};

export function lengthOfCharactersDifference(a, b) {
  if (a.length == 0) return b.length;
  if (b.length == 0) return a.length;

  var matrix = [];

  var i;
  for (i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  var j;
  for (j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  for (i = 1; i <= b.length; i++) {
    for (j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) == a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1,
          Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
        );
      }
    }
  }

  return matrix[b.length][a.length];
}

export function getFromPercentage(percent, total) {
  return (percent / 100) * total;
}

export function checkIsValid(string) {
  return string?.trim() == "" || !string;
}

export const numberCheckERS = (str) => {
  if (!str) return false;
  const regex = /["ERS"]{3}\d.{8,12}/;
  const result = str.toUpperCase().match(regex);
  if (result && result.length > 0) return result[0].trim();
  else return false;
};

export const errorHandler = (err) => {
  console.log("ERR__start");
  console.error(err);
  console.log("ERR__end");
};
export const first_element_array = (arr) => {
  if (Array.isArray(arr)) {
    if (arr.length > 0) {
      return arr[0];
    }
  }
  return false;
};

export function copyToClipboard(text) {
  const elem = document.createElement("textarea");
  elem.value = text;
  document.body.appendChild(elem);
  elem.select();
  document.execCommand("copy");
  document.body.removeChild(elem);
  antdMessageSuccess(`Nukopijuotas tekstas: ${text}`);
}

export const log = (val) => {
  console.count("Called");
  console.log(val);
};

export const first_arr = (arr) => {
  if (arr.length > 0) return arr[0];
  return null;
};

export const last_arr = (arr) => {
  if (arr.length > 0) return arr[arr.length - 1];
  return null;
};

export const getBase64 = (file) => {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      return resolve(reader.result);
    };
  });
};

export function getBase64WithCallback(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () =>
    callback({ name: img.name, url: reader.result })
  );
  reader.readAsDataURL(img);
}


export const buildErrorObject = (obj) => {
  if (typeof obj !== "object") return {};
  let errors = {};
  Object.entries(obj).forEach((d) => (errors[d[0]] = d[1].join(", ")));
  return errors;
};

export const dateDayLongs = (date) => {
  var date0 = new Date();
  var date1 = new Date(date);

  var numberOfDays = Math.ceil((date1 - date0) / 8.64e7); // 31
  return numberOfDays;
};

export const uniqueArray = (arr) => arr ? [...new Set(arr)] : [];
