import React from "react";
import { Label } from "../global";

const InputText = ({width, name, number, placeholder, onChange, onKeyDown, inline = false, className = "", value = ""}) => {
  return (
    <div className={`form-group ${className}`}>
      { inline || <Label>{placeholder}</Label> }
          <input
            type="text"
            name={name}
            className="form-control"
            id={`${name + number}`}
            placeholder={placeholder}
            style={{fontSize: `${width}`}}
            value = {value}
            onKeyDown={onKeyDown}
            onChange={onChange}
          />
    </div>
  );
};

export default InputText;
