import { message } from "antd";
import Axios from "axios";
import { errorHandler } from "../../helpers/Utils";
import {
  GET_BRANDS,
  CLEAR_ERRORS,
  GET_ERRORS,
  LOADING_BRANDS,
  POST_BRAND,
  UPDATE_BRAND
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

const success = () => {
  if (!document.getElementById("brandsAlert-s")) return;
  document.getElementById("brandsAlert-s").style.display = "block";
  setTimeout(
    () =>
      (document.getElementById("brandsAlert-s").style.display = "none"),
    5000
  );
  document.getElementById('modalClose').click()
};

const error = () => {
  if(!document.getElementById("brandsAlert-e")) return;
  document.getElementById("brandsAlert-e").style.display = "block";
  setTimeout(
    () => (document.getElementById("brandsAlert-e").style.display = "none"),
    5000
  );
};

export const getBrands = (page, pageCount, filterParams) => (dispatch) => {
  dispatch(setBrandsLoading());
  Axios.get(`/api/v1/brands?page=${page}&per_page=${pageCount}${filterParams}`)
    .then((res) => {
      dispatch({
        type: GET_BRANDS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const createBrand = BrandData => dispatch => {
  Axios.post("/api/v1/brands", BrandData)
    .then(res => {
      dispatch({
        type: POST_BRAND,
        payload: res.data
      });
      dispatch(clearErrors());
      success("post");
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      error();
    });
};

export const updateBrand = (_id, body, indexOfArray) => dispatch => {
  Axios.put(`/api/v1/brands/${_id}`, body)
    .then(res => {
      dispatch(clearErrors());
      dispatch({
        type: UPDATE_BRAND,
        payload: res.data,
        payload_id: _id
      });
      success("update");
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      error();
    });
};

export const setBrandsLoading = () => {
  return {
    type: LOADING_BRANDS,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
