import { message } from "antd";
import Axios from "axios";
import { notifications } from "../../../helpers/Alerts";
import { antdMessageError, antdMessageSuccess } from "../../../helpers/Utils";
import {
  GET_SERVICE,
  SET_LOADING_SERVICE,
  CLEAR_ERRORS,
  GET_SERVICE_SHOW,
  SET_LOADING_SERVICE_SHOW,
  SET_SERVICE,
  UPDATE_SERVICE_ITEM_SHOW,
  SERVICE_REPAIR_ADDRESS_UPDATE,
  GET_ERRORS,
} from "./../types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

const success = () => {
  if (!document.getElementById("service-s")) return;
  document.getElementById("service-s").style.display = "block";
  setTimeout(
    () => (document.getElementById("service-s").style.display = "none"),
    5000
  );
};
const error = () => {
  if (!document.getElementById("service-e")) return;
  document.getElementById("service-e").style.display = "block";
  setTimeout(
    () => (document.getElementById("service-e").style.display = "none"),
    5000
  );
};

export const postService = (information, attachments) => async (dispatch) => {
  const form = information.form;
  const address = information.client;
  const description = information.description;

  const params = {
    service: {
      buy_date: form.buy_date,
      buy_document: form.buy_document,
      item_name: form.item_name,
      item_price: form.item_price || 0,
      item_code: form.item_code,
      serial_number: form.serial_number,
      document_type: form.document_type,
      certificate_date: form.certificate_date,
      certificate_number: form.certificate_number,
      invoice: form.form_pvm,
      service_type: form.type,
      rivile_store_id: form.rivile_store_id || null,
      service_description_attributes: {
        description: description.breaks,
        item_description: description.goods_outside,
        item_set: description.complete_set,
        custom: description.custom,
      },
      address_attributes: {
        firstname: address.client_name,
        lastname: address.client_lastname,
        phone: "+370" + address.client_phone,
        other: address.client_mail,
        city: address.client_city,
        street: address.client_street,
        postal_code: address.client_postal_code,
      },
      service_rows_attributes:
        form.type == "order"
          ? form.goods.map((e) => ({
              item_name: e.item_name,
              item_quantity: e.item_quantity,
              manual: false,
              item_price: e.item_price ? e.item_price + "" : 0,
              item_code: "",
            }))
          : [
              {
                item_name: form.item_name,
                item_quantity: 1,
                manual: false,
                item_price: form.item_price ? form.item_price + "" : 0,
                item_code: form.item_code || "",
              },
            ],
    },
  };

  try {
    const { data } = await Axios.post("/api/v1/repair_services.json", params);

    console.log("Request data", data);
    if (data) {
      const { defect_act, goods_appearance, complaint, custom } = attachments;
      if (defect_act.boolean && defect_act.docs.length > 0) {
        defect_act.docs.forEach((e) => {
          dispatch(requestDocument(data.id, e.url, "defect_act"));
        });
      }

      if (goods_appearance.boolean && goods_appearance.docs.length > 0) {
        goods_appearance.docs.forEach((e) => {
          dispatch(requestDocument(data.id, e.url, "goods_appearance"));
        });
      }
      if (complaint.boolean && complaint.docs.length > 0) {
        complaint.docs.forEach((e) => {
          dispatch(requestDocument(data.id, e.url, "complaint"));
        });
      }
      if (form.type == "order" && custom.docs.length > 0) {
        custom.docs.forEach((e) => {
          dispatch(requestDocument(data.id, e.url, "custom"));
        });
      }

      dispatch({
        type: SET_SERVICE,
        payload: data,
      });
      return { success: true };
    } else {
      throw new error("Error");
    }
  } catch (e) {
    console.log("Error: ", e);
    message.error("Įvyko klaida");

    return { success: false };
  }
};

export const getStoresOwnedByService = async () => {
  try {
    const { data } = await Axios.get("/api/v1/repair_services/stores_id");
    return {
      stores: data?.stores.map((e) => ({ value: e.id, label: e.name })),
      rivile_stores: data?.rivile_stores.map((e) => ({
        value: e.id,
        label: e.title,
      })),
    };
  } catch (err) {
    console.log("getStoresOwnedByService", err);
  }
};

export const createServiceRepairSms = (id, params) => async (dispatch) => {
  try {
    const { success } = Axios.post("/api/v1/repair_services/send_sms", params);
    params.created_at = new Date();
    if (success == false) {
      throw new Error("Error");
    }
    dispatch({
      type: "SERVICE_REPAIR_SMS_CREATED",
      payload: params,
    });
    return success;
  } catch (e) {
    console.log("Error: ", e);
    return { success: false };
  }
};

export const logServiceRepairUpdate = (id) => async (dispatch) => {
  try {
    const { data: logs } = await Axios.get(
      `/api/v1/repair_services/activity_log/${id}`
    );
    const { data: history } = await Axios.get(
      `/api/v1/repair_services/repair_history/${id}`
    );
    // console.log(data);
    // console.log(data2);
    dispatch({
      type: "SERVICE_REPAIR_ACTIVE_LOG_UPDATE",
      payload: { history, logs },
    });
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const postToRivile = (ID) => (dispatch) => {
  return Axios.post(
    `/api/v1/repair_services/${ID}/repair_rivile/register_address`
  )
    .then((response) => {
      dispatch({
        type: "SERVICE_POST_TO_RIVILE",
        payload: { data: response.data, id: ID },
      });
      return { success: true };
    })
    .catch((err) => {
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data,
      // });
      return { success: false, error: err.response.data };
    });
};

export const getServiceRepairSms = () => async (dispatch) => {
  try {
    const { data } = await Axios.get("/api/v1/repair_services/sms_templates");
    dispatch({
      type: "SERVICE_REPAIR_SMS_TEMPLATE",
      payload: data,
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const updateServiceRepairAddress = (id, params) => (dispatch) => {
  const variables = {
    address: {
      ...params,
    },
  };
  return Axios.put(`/api/v1/addresses/${id}`, variables)
    .then((res) => {
      dispatch({
        type: SERVICE_REPAIR_ADDRESS_UPDATE,
        payload: res.data,
      });
      antdMessageSuccess();
      return { ok: true, data: res.data };
    })
    .catch((err) => {
      antdMessageError();
      return { ok: false };
    });
};

export const updateService = (state) => async (dispatch) => {
  try {
    const { data } = await Axios.put(`/api/v1/repair_services/${state.id}`, {
      service: state,
    });
    dispatch({
      type: UPDATE_SERVICE_ITEM_SHOW,
      payload: data,
    });
    antdMessageSuccess();
    dispatch(logServiceRepairUpdate(state.id));
    return { success: true };
  } catch (e) {
    // antdMessageError();
    return { success: false };
  }
};

export const requestDocument =
  (id, file, document_type) => async (dispatch) => {
    const body = {
      service_document: {
        service_id: id,
        attachment: file,
        document_type,
      },
    };
    try {
      const { data } = await Axios.post(`/api/v1/service_documents`, body);
      if (data.document_type === "custom") {
        dispatch({
          type: "SERVICE_REPAIR_CUSTOM_DOCUMENT_UPLOAD",
          payload: data,
        });
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  };

export const addServiceItemComment = (params) => async (dispatch) => {
  try {
    const { data } = await Axios.post(`/api/v1/service_comments`, params);
    if (data) {
      dispatch({
        type: "SET_SERVICE_COMMENTS",
        payload: data,
      });
      return { success: true };
    }
  } catch (e) {
    console.log("Error: ", e);
    return { success: false };
  }
};

export const getServices = (page, pageCount, filter) => (dispatch) => {
  dispatch(setServiceLoading());
  Axios.get(
    `/api/v1/repair_services.json?page=${page}&per_page=${pageCount}${filter}`
  )
    .then((res) => {
      dispatch({
        type: GET_SERVICE,
        payload: res.data,
      });
    })
    .catch((err) => {
      //   dispatch({
      //     type: GET_ERRORS,
      //     payload: err.response.data,
      //   });
    });
};

export const getServicesShow = (id) => async (dispatch) => {
  dispatch(setServiceShowLoading());
  try {
    const { data } = await Axios.get(`/api/v1/repair_services/${id}`);
    dispatch({
      type: GET_SERVICE_SHOW,
      payload: data,
    });
    // await Axios.get(`/api/v1/services/description_by_service/${id}`);
    // await Axios.get(`/api/v1/services/address_by_service/${id}`);
    // await Axios.get(`/api/v1/services/documents_by_service/${id}`);
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const postServiceShiping = (id, orderBody) => (dispatch) => {
  return Axios.post(`/api/v1/repair_services/${id}/repair_shipments`, orderBody)
    .then((res) => {
      dispatch({
        type: "SERVICE_REPAIR_ADD_SHIPMENTS",
        payload: res.data,
      });
      // notifications("success");
      success();
      return { success: true };
    })
    .catch((err) => {
      console.log("Error: ", err.response.data);
      // notifications("error");
      error();
      return { success: false, errors: err.response.data };
    });
};

// Row goods

export const postServiceRowGoods = (body, id) => async (dispatch) => {
  console.log(body);
  const bodyData = {
    service_row: {
      item_name: body.order_row.product_name,
      item_price: body.order_row.product_price,
      item_quantity: body.order_row.quantity,
      item_code: body.order_row.barcode,
      service_id: id,
      manual: true,
    },
  };
  try {
    const { data } = await Axios.post("/api/v1/service_rows", bodyData);
    console.log("Trye: ", data);
    dispatch({
      type: "POST_SERVICE_ROW_GOODS",
      payload: { ...data, supplyings: [] },
    });
    antdMessageSuccess();
    return { ok: true, service: true };
  } catch (err) {
    return { ok: false };
    console.log("Error");
  }
};

export const updateServiceRowGoods =
  (body, orderId, itemId) => async (dispatch) => {
    console.log(body, orderId, itemId);
    const bodyData = {
      service_row: {
        item_price: body.order_row.product_price,
        item_quantity: body.order_row.quantity,
        // manual: true,
      },
    };
    try {
      const { data } = await Axios.put(
        `/api/v1/service_rows/${itemId}`,
        bodyData
      );
      console.log("Trye: ", data);
      dispatch({
        type: "UPDATE_SERVICE_ROW_GOODS",
        payload: data,
      });
      return { ok: true, service: true };
    } catch (err) {
      return { ok: false };
      console.log("Error");
    }
  };

export const removeServiceRowGoods = (orderId, rowId) => async (dispatch) => {
  console.log("ip");
  console.log(orderId, rowId);
  try {
    const { data } = await Axios.delete(`/api/v1/service_rows/${rowId}`);
    dispatch({
      type: "REMOVE_SERVICE_ROW_GOODS",
      payload: { rowId, orderId, total_price: data.total_price },
    });
    return { ok: true, service: true };
  } catch (err) {
    return { ok: false };
    console.log("Error");
  }
};
// Suppyings
export const postServiceRowSupplyings =
  (PARAMS, orderId, orderRowId) => async (dispatch) => {
    try {
      const { data } = await Axios.post(
        `/api/v1/service_rows/${orderRowId}/supplyings`,
        {
          supplying: { ...PARAMS },
        }
      );
      dispatch({
        type: "ADD_SERVICE_ROW_SUPPLY",
        payload: { ...data, service_id: orderId },
      });
      antdMessageSuccess();
      return { success: true };
    } catch (err) {
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data,
      // });
      message.error("Įvyko klaida");

      console.log("Error: ", err);
      return { success: false };
    }
  };

export const postServiceRowChanges =
  (PARAMS, service_id, orderRowId) => async (dispatch) => {
    console.log({ service_id, orderRowId });
    try {
      const { data } = await Axios.post(
        `/api/v1/service_rows/${orderRowId}/service_row_changes`,
        {
          service_row_changes: PARAMS,
        }
      );
      console.log(data);
      dispatch({
        type: "ADD_SERVICE_CHANGES_TO_SERVICE_ROW",
        payload: { ...data, service_id },
      });
      antdMessageSuccess();
      return { success: true };
    } catch (err) {
      message.error("Įvyko klaida");
      console.log("Error: ", err);
      return { success: false };
    }
  };

export const removeServiceRowChanges =
  (itemId, orderId, service_id) => async (dispatch) => {
    console.log(itemId, orderId, service_id);
    try {
      if(!confirm("Ar tikrai norite ištrinti?")) return
      const data = await Axios.delete(
        `/api/v1/service_rows/${orderId}/service_row_changes/${itemId}`
      );
      console.log({ data });

      dispatch({
        type: "REMOVE_SERVICE_CHANGES_TO_SERVICE_ROW",
        payload: { itemId, orderId, service_id },
      });
      return { success: true };
    } catch (err) {
      console.log("Error: ", err);
      message.error("Įvyko klaida");
      return { success: false };
    }
  };

export const removeServiceRowSupplyings =
  (itemId, orderId, service_id) => async (dispatch) => {
    console.log(itemId, orderId, service_id);
    try {
      const data = await Axios.delete(
        `/api/v1/service_rows/${orderId}/supplyings/${itemId}`
      );

      dispatch({
        type: "REMOVE_SERVICE_ROW_SUPPLY",
        payload: { itemId, orderId, service_id },
      });
      return { success: true };
    } catch (err) {
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data,
      // });
      console.log("Error: ", err);
      return { success: false };
    }
  };

export const updateServiceDescription = (id, params) => async (dispatch) => {
  try {
    const { data } = await Axios.put(
      `/api/v1/service_descriptions/${id}`,
      params
    );
    dispatch({
      type: "SERVICE_DESCRIPTION_UPDATE",
      payload: data,
    });
    antdMessageSuccess();
    return true;
  } catch (err) {
    antdMessageError();
    return true;
  }
};

export const setServiceLoading = () => {
  return {
    type: SET_LOADING_SERVICE,
  };
};

export const setServiceShowLoading = () => {
  return {
    type: SET_LOADING_SERVICE_SHOW,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
