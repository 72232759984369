import React, { memo, useCallback, useState } from "react";
import CommerceTableColumnOpen from "./CommerceTableColumnOpen";
import CommerceTableProductColumn from "./CommerceTableProductColumn";

const CommerceTableColumn = ({
  product,
  checkedIncluded,
  onHandleModalOptCallBack,
  onChangeCheckedCallBack,
}) => {
  const [open, setOpen] = useState(false);
  console.count("CommerceTableColumn 111111111");

  const setOpenCallBack = useCallback(() => setOpen((prev) => !prev), []);
  if (open) {
    return (
      <CommerceTableColumnOpen
      product={product}
      checkedIncluded={checkedIncluded}
      onChangeCheckedCallBack={onChangeCheckedCallBack}
      onHandleModalOptCallBack={onHandleModalOptCallBack}
      setOpenCallBack={setOpenCallBack}
      />
    );
  }
  return (
    <CommerceTableProductColumn
      open={false}
      product={product}
      checkedIncluded={checkedIncluded}
      onHandleModalOptCallBack={onHandleModalOptCallBack}
      onChangeCheckedCallBack={onChangeCheckedCallBack}
      setOpenCallBack={setOpenCallBack}
    />
  );
};

export default memo(CommerceTableColumn);
