import React from "react";
import spinner from "../../assets/images/loading.svg";

function LoadingPage() {
  return (
    <div
      className="align-items-center bg-silver d-flex justify-content-center"
      style={{ height: "100vh" }}
    >
      <img
        src={spinner}
        style={{ width: "150px", padding: "15px" }}
        alt="Loading..."
      />
    </div>
  );
}

export default LoadingPage;
