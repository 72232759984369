import React from "react";
import InputForm from "../../common/InputForm";
import ModalToChange from "../../Layout/ModalToChange";
import { CheckBoxInput, SelectForm } from "../../common/Buttons";
import { MdSecurity } from "react-icons/md";
class UsersEditModal extends React.Component {
  constructor() {
    super();
    this.state = {
      id: 0,
      email: "",
      firstname: "",
      lastname: "",
      bigbox_import: false,
      password: "",
      password2: "",
      sb_leasing_user: "",
      sb_leasing_api_key: "",
      tabel_number: "",
      manager_code: "",
      store_id: "",
      user_role_id: "",
      rline_store_code: "",
      action: "new",
      passwordFields: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.action === "new" && this.state.action === "edit") {
      this.setState({
        email: "",
        firstname: "",
        lastname: "",
        password: "",
        password2: "",
        sb_leasing_user: "",
        sb_leasing_api_key: "",
        bigbox_import: false,
        tabel_number: "",
        manager_code: "",
        store_id: "",
        user_role_id: "",
        rline_store_code: "",
        passwordFields: false,
        action: "new",
      });
      this.removeErrors(nextProps.errors);
    } else if (
      nextProps.action === "edit" &&
      nextProps.objectData.id !== this.state.id
    ) {
      let item = nextProps.objectData;
      this.setState({
        id: item.id || 0,
        email: item.email || "",
        firstname: item.firstname || "",
        lastname: item.lastname || "",
        password: "",
        password2: "",
        sb_leasing_user: item.sb_leasing_user || "",
        sb_leasing_api_key: item.sb_leasing_api_key || "",
        bigbox_import: item.bigbox_import || false,
        tabel_number: item.tabel_number || "",
        manager_code: item.manager_code || "",
        store_id: item.store_id || "",
        user_role_id: item.user_role_id || undefined,
        rline_store_code: item.rline_store_code || "",
        passwordFields: false,
        action: "edit",
      });
      this.removeErrors(nextProps.errors);
    }
  }

  removeErrors = (errors) => {
    if (Object.keys(errors).length > 0) {
      this.props.clearErrors();
    }
  };

  handleChange = (e, select) => {
    if (select) {
      this.setState({
        [select]: e,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const variables = {
      user: {
        email: this.state.email,
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        password: this.state.password,
        password_confirmation: this.state.password2,
        bigbox_import: this.state.bigbox_import,
        tabel_number: this.state.tabel_number,
        manager_code: this.state.manager_code,
        user_role_id: this.state.user_role_id,
        rline_store_code: this.state.rline_store_code,
        store_id: this.state.store_id || null,
        sb_leasing_user: this.state.sb_leasing_user || null,
        sb_leasing_api_key: this.state.sb_leasing_api_key || null,
      },
    };

    if (this.props.action === "edit") {
      this.props.updateUser(this.state.id, variables).then((res) => {
        if (this.props.auth.email == res.email) {
          this.props.logoutUser();
        }
      });
    }
    if (this.props.action == "new") {
      if (
        this.state.password.length !== 0 &&
        this.state.password2.length !== 0
      ) {
        this.props.postUser(variables);
        this.setState({
          passwordFields: false,
        });
      } else {
        this.setState({
          passwordFields: true,
        });
      }
    }
  };

  removeAuthorization = async () => {
    if (confirm("Ar tikrai norite atnaujinti 2FA autorizaciją ?")) {
      const user = await this.props.authRemove(this.state.id);
      if (user) {
        // success
        if (user.email == this.props.auth.email) {
          this.props.logoutUser();
        }
      } else {
        // error
      }
    }
  };

  storePerm(role) {
    if (role == "store_user" || role == "store_manager") {
      return false;
    }
    return true;
  }
  render() {
    return (
      <ModalToChange
        handleSave={(e) => this.onSubmit(e)}
        status="tableEdit"
        text={this.props.action === "new" ? "Kurti naują" : "Redaguoti"}
        buttonText={this.props.action === "new" ? "Sukurti" : "Atnaujinti"}
      >
        <InputForm
          type="email"
          placeholder="Elektroninis paštas"
          name="email"
          value={this.state.email}
          error={
            this.props.errors.email &&
            this.props.errors.email.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Vardas"
          name="firstname"
          value={this.state.firstname}
          error={
            this.props.errors.firstname &&
            this.props.errors.firstname.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Pavardė"
          name="lastname"
          value={this.state.lastname}
          error={
            this.props.errors.lastname &&
            this.props.errors.lastname.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        {this.storePerm(this.props.auth?.user_role?.identifier) && (
          <>
            <SelectForm
              placeholder="Rolė"
              options={this.props.user_roles}
              value={this.state.user_role_id}
              onChange={(e) => this.handleChange(e, "user_role_id")}
            />

            <SelectForm
              placeholder="Parduotuvė"
              options={[
                {
                  label: "Neturi",
                  value: null,
                },
                ...this.props.stores,
              ]}
              value={this.state.store_id || undefined}
              onChange={(e) => this.handleChange(e, "store_id")}
            />
          </>
        )}
        <InputForm
          type="text"
          placeholder="Tabelio nr."
          name="tabel_number"
          value={this.state.tabel_number}
          error={
            this.props.errors.tabel_number &&
            this.props.errors.tabel_number.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Menedžerio kodas"
          name="manager_code"
          value={this.state.manager_code}
          onChange={(e) => this.handleChange(e)}
        />{" "}
        <InputForm
          type="text"
          placeholder="Rline parduotuvės kodas"
          name="rline_store_code"
          value={this.state.rline_store_code}
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="password"
          placeholder="Slaptažodis"
          name="password"
          value={this.state.password}
          error={
            this.props.errors.password &&
            this.props.errors.password.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="password"
          placeholder="Pakartoti slaptažodį"
          name="password2"
          value={this.state.password2}
          error={
            this.props.errors.password_confirmation &&
            this.props.errors.password_confirmation.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        {this.state.passwordFields && (
          <div
            className="invalid-feedback d-block"
            style={{ fontSize: "1rem" }}
          >
            {"Slaptažodžių laukai privalomi!"}
          </div>
        )}
        <InputForm
          placeholder="SB lizingas vartotojas"
          name="sb_leasing_user"
          value={this.state.sb_leasing_user}
          onChange={(e) => this.handleChange(e)}
        />{" "}
        <InputForm
          placeholder="SB lizingas API KEY"
          name="sb_leasing_api_key"
          value={this.state.sb_leasing_api_key}
          onChange={(e) => this.handleChange(e)}
        />
        <CheckBoxInput
          choised="bigbox_import"
          color="#f7951e"
          text="Bigbox Import"
          checked={this.state.bigbox_import}
          onChange={(e) =>
            this.handleChange({
              target: { name: "bigbox_import", value: e.target.checked },
            })
          }
        />
        {this.props.action === "edit" && (
          <button
            className="btn btn-danger mt-4"
            onClick={() => this.removeAuthorization()}
          >
            <span className="d-flex align-items-center">
              <MdSecurity className="mr-" />
              Atnaujinti 2FA autorizaciją
            </span>
          </button>
        )}
      </ModalToChange>
    );
  }
}

export default UsersEditModal;
