import React, { useState, useEffect, Fragment } from "react";
import {
  ButtonEdit,
  SelectForm,
  ButtonGradientAdd,
  ButtonDelete,
} from "../../common/Buttons";
import ContentValidation from "../../common/ContentValidation";
import TextInputModal from "../../common/TextInputModal";
import ContentBlock from "../../Layout/ContentBlock";
import Table from "../../common/TableUi";
import { Modal } from "antd";
import SimplePagination from "../../common/SimplePagination";
import GuideBuyersGuidesModal from "./GuideFirst/GuideBuyersGuidesModal";
import InputSwitchComponent from "../../common/InputSwitchComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteGuideBuyer,
  getGuideBuyers,
  getGuidesBuyersCategories,
} from "../../../redux/Actions/guidesBuyersAction";
import SwitchComp from "../../common/SwitchComp";
import { IconExclamation, XIcon } from "../../Layout/Icons";

const { confirm } = Modal;

export default function GuideBuyersGuides() {
  const dispatch = useDispatch();
  const { loading, data, current, totals } = useSelector(
    (state) => state.custom
  );

  const [landings, setLandings] = useState([]);
  const [page, setPage] = useState(1);
  const [modal, setModal] = useState(false);

  const [filter, setFilter] = useState({
    title: "",
    shop_category_id: undefined,
    active: "",
  });
  const [filterString, setfilterString] = useState("");
  //
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // dispatch(getGuideBuyers());
    getGuidesBuyersCategories().then((res) => {
      if (res.success) {
        setCategories([
          { value: null, label: "Visi" },
          ...res.data.map((e) => ({ value: e.id, label: e.name })),
        ]);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(getGuideBuyers(page, filterString));
  }, [filterString, page]);

  const onChange = (e) => {
    const { value, name } = e.target;
    const filters = { ...filter, [name]: value };
    setFilter(filters);
    onFilter(filters);
  };

  const onFilter = (filter) => {
    let filterParams = [];

    if (filter.title) {
      filterParams.push(`&q[title_cont_any]=${filter.title}`);
    }
    if (filter.shop_category_id) {
      filterParams.push(`&q[shop_category_id_eq]=${filter.shop_category_id}`);
    }
    if (filter.active) {
      if (filter.active == "active") {
        filterParams.push(`&q[active_true]=1`);
      } else if (filter.active == "inactive") {
        filterParams.push(`&q[active_false]=1`);
      }
    }

    setfilterString(filterParams.join(""));
    setPage(1);
  };

  return (
    <div>
      <ContentBlock>
        <div className={"bg-white d-flex flex-wrap pl-1 pr-1"}>
          <div className="col-12 mb-1">
            <div className="row">
              <div className="col-3">
                <TextInputModal
                  text="Pavadinimas"
                  name="title"
                  value={filter.title}
                  onChange={(e) =>
                    setFilter({ ...filter, title: e.target.value })
                  }
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      onFilter(filter);
                    }
                  }}
                />
              </div>
              <div className="col-3">
                <SelectForm
                  placeholder="Kategorija"
                  onlyPlaceholder="Pasirinkti"
                  value={filter.shop_category_id}
                  options={categories}
                  onChange={(value) =>
                    onChange({ target: { value, name: "shop_category_id" } })
                  }
                />
              </div>
              <div className="col-3">
                <InputSwitchComponent
                  touches={true}
                  label="Statusas"
                  options={[
                    {
                      label: "Visi",
                      value: "",
                    },
                    {
                      label: "Aktyvus",
                      value: "active",
                    },
                    {
                      label: "Neaktyvus",
                      value: "inactive",
                    },
                  ]}
                  name="active"
                  value={filter.active}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </div>
      </ContentBlock>
      <ContentBlock extraClass={"mt-2"}>
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center">
            <h4
              className="font-weight-bold mb-0"
              style={{ fontSize: 18, color: "#262626" }}
            >
              Gidas
            </h4>
            <span>
              <ButtonGradientAdd
                text="Pridėti naują gidą"
                onClick={() => setModal(0)}
              />
            </span>
          </div>
        </div>
        <ContentValidation loader={loading} array={data}>
          <Table ths={["Pavadinimas", "Kategorija", "Statusas", ""]}>
            {current == "Guide buyers" &&
              data?.map((e) => (
                <Table.tr key={e.id}>
                  <Table.td className="p-left">{e.title}</Table.td>
                  <Table.td>{e.shop_category?.title}</Table.td>

                  <Table.td>
                    <SwitchComp
                      className=""
                      checkedChildren="I"
                      unCheckedChildren="0"
                      checked={e.active}
                      disabled={true}
                      inlineText={e.active ? "Aktyvus" : "Neaktyvus"}
                    />
                  </Table.td>
                  <Table.td className="d-flex justify-content-end p-right">
                    <ButtonEdit onEdit={() => setModal(e.id)} />
                    <div className="mx-1" />
                    <ButtonDelete
                      onDelete={() => {
                        confirm({
                          title: "Ar tikrai norite ištrinti?",
                          icon: <IconExclamation className="text-orange" />,
                          content: "Šio veiksmo anuliuoti negalėsite",
                          okText: "Sutikti",
                          cancelText: "Atšaukti",
                          onOk() {
                            dispatch(deleteGuideBuyer(e.id));
                            console.log("OK");
                          },
                          onCancel() {
                            console.log("Cancel");
                          },
                        });
                      }}
                    />
                  </Table.td>
                </Table.tr>
              ))}
          </Table>
        </ContentValidation>
      </ContentBlock>
      <div className="mt-2">
        <SimplePagination
          setPagination={(pg) => setPage(pg)}
          pageCount={10}
          page={page}
          totals={totals}
        />
      </div>

      {typeof modal == "number" && (
        <GuideBuyersGuidesModal
          id={modal}
          setModal={setModal}
          landings={landings}
          setLandings={setLandings}
          onClose={() => setModal(false)}
        />
      )}
    </div>
  );
}

// Modalas
