import {
  LOADING_MANAGERS,
  GET_MANAGERS,
  SHOW_MANAGER,
} from "../Actions/types";

const initialState = {
  manager: {},
  managers: [],
  totals: 0,
  last_sync_sale_day: "",
  last_sync_sale_month: "",
  loading_managers: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_MANAGERS:
      return {
        ...state,
        loading_managers: true
      };
    case GET_MANAGERS:
      return {
        ...state,
        managers: action.payload.data,
        totals: action.payload.totals,
        last_sync_sale_day: action.payload.last_sync_sale_day,
        last_sync_sale_month: action.payload.last_sync_sale_month,
        loading_managers: false
      };
    case SHOW_MANAGER:
      return {
        ...state,
        manager: action.payload,
      };
    default:
      return state;
  }
}
