import React, { Fragment, useState, useEffect } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { FaTrash, FaTimes, FaCheck } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { MainBlock } from "../../Buttons";
import { Divs } from "../../StyledComponents";
import TextInputModal from "../../TextInputModal";
import OrderRowAdd from "./editModal/order_row_add";
import RenderDelivery from "./OrderCreateModal/RenderDelivery";
import RenderStatus from "./OrderCreateModal/RenderStatus";
import { createOrder } from "../../../../redux/Actions/orderAction";
import { getRivileStoreOptions } from "../../../../redux/Actions/rivileStoreAction";
import OrderTotalComponent from "./editModal/SmallComp/order_total";
import {
  getFromPercentage,
  phonenumberValid,
  validateEmail,
} from "../../../../helpers/Utils";
import { unsuccessToast } from "../../../../helpers/ErrorsToasts";
import { useDispatch, useSelector } from "react-redux";
import RenderAddress from "./OrderCreateModal/RenderAddress";
import { getCarriers } from "../../../../requests/Carriers";
import { have_product_reference } from "../../../../helpers/OrdersFunctionsGlobal";

function OrderCreateModal({
  parcel_terminals,
  payment_types,
  avalaibleStatus,
  onClose,
  rline = false,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [total_price, setTotalPrice] = useState(0);
  const [totals, setTotals] = useState({
    total_products: 0,
    total_shipping: 0,
  });
  const [carriers, setCarriers] = useState([]);

  useEffect(() => {
    const fetchCarriers = async () => {
      let data = await getCarriers();
      data = data
        .filter((carrier) => carrier.id)
        .map((carrier) => ({
          ...carrier,
          value: carrier.id,
        }));
      if (data) {
        setCarriers(data);
      }
      if (!general.carrier_id) {
        const findDPD = data.find(
          (e) =>
            e.label === "DPD kurjeris" || e.options.includes("DPD kurjeris")
        );
        if (findDPD) {
          setGeneral({
            ...general,
            carrier_id: findDPD.value,
          });
        }
      }
    };
    fetchCarriers();
  }, []);

  useEffect(() => {
    const { total_products, total_shipping } = totals;
    const plusTotals = +total_products + +total_shipping;
    setTotalPrice(plusTotals);
  }, [totals]);

  const [collapse, setCollapse] = useState({
    general_info: true,
    general_goods: true,
    general_goods_add: true,
    general_delivery: true,
    general_status: true,
    general_other: true,
  });

  const findCarrierDefault = () => {
    const findCarrier = carriers.find(
      (e) => e.label == "DPD kurjeris" || e.module_name == "technocarrier"
    );
    if (findCarrier) {
      return findCarrier.id;
    }
    return undefined;
  };

  const [general, setGeneral] = useState({
    payment: undefined,
    status_id: 13,
    rline,
    rline_code: user.rline_customer?.rline_code || user.rline_store_code || "",
    consultant: rline,
    consultant_code: user.rline_customer?.consultant_code || user.manager_code,
    consultant_email: user.email,
    client_comment: "",
    carrier_id: findCarrierDefault(),
    // carrier_id: undefined,
    delivery_children: {},
  });

  const [shippingAddress, setShippingAddress] = useState({
    firstname: "",
    lastname: "",
    street: "",
    street2: "",
    city: "",
    postal_code: "",
    phone: "",
    parcel_terminal_id: null,
    other: "",
  });
  const [billingAddress, setBillingAddress] = useState({
    firstname: "",
    lastname: "",
    street: "",
    street2: "",
    city: "",
    postal_code: "",
    phone: "",
    other: "",
    company: "",
    company_code: "",
    vat_code: "",
  });

  const [orderRows, setOrderRows] = useState([]);

  const [errors, setErrors] = useState({});

  // State End

  useEffect(() => {
    if (general.rline && !general.rline_code) {
      getRivileStoreOptions().then((response) => {
        const store =
          response.find((shop) =>
            user.store.name.toLowerCase().match(shop.title.toLowerCase())
          ) || user.store;

        setGeneral({ ...general, rline_code: `${store.rivile_code}R` });
      });
    }
  }, [general.rline, general.rline_code]);

  const onHandleChangeAddress = (e, full, updateNext) => {
    if (full) {
      if (full === "billingAddress") {
        if (!general.carrier_id || updateNext) {
          setShippingAddress({
            ...shippingAddress,
            ...e,
          });
        }
        return setBillingAddress({
          ...billingAddress,
          ...e,
        });
      } else {
        return setShippingAddress({
          ...shippingAddress,
          ...e,
        });
      }
    }
    let { value, name } = e.target;

    if (name.startsWith("[billingAddress]")) {
      name = name.split("[billingAddress]")[1];
      setBillingAddress({
        ...billingAddress,
        [name]: value,
      });
      if (
        !shippingAddress[name] ||
        shippingAddress[name] == "" ||
        shippingAddress[name] == billingAddress[name]
      ) {
        return setShippingAddress({
          ...shippingAddress,
          [name]: value,
        });
      } else {
        return;
      }
    } else {
      name = name.split("[shippingAddress]")[1];
      return setShippingAddress({
        ...shippingAddress,
        [name]: value,
      });
    }
  };

  const onChangeGeneral = (e, massUpdate) =>
    massUpdate
      ? setGeneral({ ...general, ...e })
      : setGeneral({ ...general, [e.target.name]: e.target.value });

  const onChangeTotals = (e) => {
    const val = +e.target.value || "";
    setTotals({ ...totals, [e.target.name]: val });
  };

  const onChangeOrderRows = (arr) => setOrderRows(arr);

  const collapseBlock = (name) =>
    setCollapse({ ...collapse, [name]: !collapse[name] });

  const validationsOnSubmitForm = () => {
    let errors = {};
    let collapse = {};
    const { status_id, payment, carrier_id } = general;
    const { phone: ship_phone } = shippingAddress;
    const {
      phone: bill_phone,
      other: email,
      firstname,
      lastname,
      company,
      company_code,
    } = billingAddress;

    if (!carrier_id) {
      errors.carrier = "Užpildykite laukelį";
      collapse.general_delivery = true;
    } else {
      const findCarrier = carriers.find((e) => e.value == carrier_id);
      if (
        [
          "OMNIVA Paštomatas",
          "LP Express 24 pristatymas į paštomatą",
          "DPD Paštomatas",
          "Atsiėmimas parduotuvėje",
          "Itella paštomatas",
          "Venipak pickup point",
          "Venipak paštomatas",
        ].includes(findCarrier?.label || "") &&
        !general.delivery_children?.value
      ) {
        errors.delivery = "Užpildykite laukelį";
        collapse.general_delivery = true;
      }
    }

    if (!payment) {
      errors.payment = "Užpildykite laukelį";
      collapse.general_delivery = true;
    }
    if (!status_id) {
      errors.status = "Užpildykite laukelį";
      collapse.general_status = true;
    }
    if (!phonenumberValid(ship_phone)) {
      errors["shipping_address.phone"] = ["Pasitikrinkite numerį"];
      collapse.general_info = true;
    }
    if (!phonenumberValid(bill_phone)) {
      errors["billing_address.phone"] = ["Pasitikrinkite numerį"];
      collapse.general_info = true;
    }
    if (!firstname || firstname.length < 2) {
      errors["billing_address.firstname"] = [
        "Užpildykite laukelį, min 3 simboliai",
      ];
      collapse.general_info = true;
    }
    if (!lastname || lastname.length < 3) {
      errors["billing_address.lastname"] = [
        "Užpildykite laukelį, min 4 simboliai",
      ];
      collapse.general_info = true;
    }
    if (company && !company_code) {
      errors["billing_address.company_code"] = ["Užpildykite laukelį"];
      collapse.general_info = true;
    }

    if (!email || !validateEmail(email)) {
      errors["billing_address.email"] = ["Blogai suvestas el. paštas"];
      collapse.general_info = true;
    }
    if (typeof totals.total_shipping !== "number") {
      errors.total_shipping = "Užpildykite laukelį";
      collapse.general_delivery = true;
    }

    return { errors, collapse };
  };

  const onSubmitForm = async () => {
    const { errors, collapse: collapseFFunc } = validationsOnSubmitForm();

    if (Object.entries(errors).length > 0) {
      setCollapse({ ...collapse, ...collapseFFunc });
      unsuccessToast("order-e");

      return setErrors(errors);
    }
    setErrors({});

    let billing_address_attributes = {
      ...billingAddress,
      customer_id: null,
      country_id: null,
    };
    let shipping_address_attributes = {
      ...shippingAddress,
      customer_id: null,
      country_id: null,
    };

    delete billing_address_attributes.key;
    delete billing_address_attributes.value;
    delete billing_address_attributes.children;
    delete billing_address_attributes.label;
    delete shipping_address_attributes.key;
    delete shipping_address_attributes.value;
    delete shipping_address_attributes.children;
    delete shipping_address_attributes.label;
    // id pasalint ir kurti naujsu juos

    const {
      status_id,
      payment,
      carrier_id,
      rline_code,
      rline,
      consultant,
      consultant_code,
      consultant_email,
      client_comment,
    } = general;

    const { total_products, total_shipping } = totals;

    const customerAttributes = (() => {
      if (rline && user.rline_customer) {
        if (user.rline_customer.rline_code) {
          return { customer_id: user.rline_customer.id };
        }

        return {
          customer_id: user.rline_customer.id,
          customer_attributes: {
            consultant_code,
            id: user.rline_customer.id,
            rline_code,
          },
        };
      }

      if (rline) {
        return {
          customer_attributes: {
            consultant_code,
            email: consultant_email,
            firstname: user.firstname,
            lastname: user.lastname,
            rline: 1,
            rline_code,
          },
        };
      }

      return {
        customer_attributes: {
          company_code: billingAddress.company_code || null,
          consultant_code: consultant ? consultant_code : "",
          email: consultant ? consultant_email : "",
          firstname: billingAddress.firstname,
          lastname: billingAddress.lastname,
          rline: 0,
          rline_code: "",
        },
      };
    })();

    const params = {
      order: {
        created: "užsakymai",
        // invoice_address_id: 21037,
        // shipping_address_id: 21036,
        client_comment,
        billing_address_attributes,
        shipping_address_attributes,
        ...customerAttributes,
        order_rows_attributes: orderRows,
        id_order: null,
        // id_order: Math.floor(Math.random() * 1000000),

        carrier_id,
        order_date: new Date(),
        // General
        // Warning !!!
        payment: payment || null,
        payment_module:
          payment_types.find((e) => e.value == payment)?.module_name || null,
        status_id: status_id || null,
        order_histories_attributes: [
          {
            title: "User",
            status_id,
            datetime: new Date(),
          },
        ],
        // Totals
        total_discounts: 0,
        total_discounts_tax_incl: 0,
        total_discounts_tax_excl: 0,
        total_products: Number(
          total_products - getFromPercentage(21, total_products)
        ).toFixed(2),
        total_products_wt: total_products,
        // cia atsiemimas 1.5
        total_shipping: total_shipping || 0,
        total_shipping_tax_incl: total_shipping || 0,
        total_shipping_tax_excl: total_shipping || 0,

        total_paid: 0,
        total_paid_tax_incl: 0,
        total_paid_tax_excl: 0,
        total_paid_real: 0,
        // total_paid_tax_excl
      },
    };

    try {
      const data = await dispatch(createOrder(params));
      if (data.success) {
        onClose();
      } else {
        setErrors(data.errors);
      }
    } catch (err) {
      console.log("Error:", err);
    }
  };

  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{ minHeight: "100%" }}
    >
      <div className="row">
        <div className="col-12 col-lg-6">
          <Divs className="container-title-block">
            <MainBlock
              color={"#ffa800"}
              action={collapse.general_info}
              onChange={() => collapseBlock("general_info")}
            >
              Užsakymo informacija
            </MainBlock>
          </Divs>

          {collapse.general_info && (
            <Fragment>
              <RenderAddress
                delivery_children={general.delivery_children?.children}
                carriers={carriers}
                carrier_id={general.carrier_id}
                rline={general.rline}
                rline_code={general.rline_code}
                customer_email={general.customer_email}
                onChange={onHandleChangeAddress}
                shippingAddress={shippingAddress}
                billingAddress={billingAddress}
                errors={errors}
                general={general}
              />

              <Divs className="p-4">
                <OrderTotalComponent
                  order_id={""}
                  total_price={total_price}
                  payments={[]}
                />
              </Divs>

              <MainBlock
                color={"#ffcc69"}
                action={collapse.general_goods}
                onChange={() => collapseBlock("general_goods")}
              >
                Prekės
              </MainBlock>

              {collapse.general_goods && (
                <RenderGoods
                  onChangeOrderRows={onChangeOrderRows}
                  onChangeTotals={onChangeTotals}
                  order_rows={orderRows}
                  rline={general.rline}
                  rline_code={general.rline_code}
                  setOrderRows={setOrderRows}
                />
              )}
            </Fragment>
          )}
        </div>

        <div className="col-12 col-lg-6">
          <MainBlock
            color={"#00aaa0"}
            action={collapse.general_delivery}
            onChange={() => collapseBlock("general_delivery")}
          >
            Pristatymas
          </MainBlock>

          {collapse.general_delivery && (
            <Divs className="bg-silver mb-4 py-3">
              <RenderDelivery
                onHandleChangeAddress={onHandleChangeAddress}
                carriers={carriers}
                shipping_address={shippingAddress}
                billing_address={billingAddress}
                parcel_terminals={parcel_terminals}
                payment_types={payment_types}
                general={general}
                onChange={onChangeGeneral}
                shippingAddressParcel={shippingAddress.parcel_terminal_id}
                total_shipping={totals.total_shipping}
                onChangeTotals={onChangeTotals}
                errors={errors}
              />
            </Divs>
          )}

          <MainBlock
            color={"#adcb35"}
            action={collapse.general_status}
            onChange={() => collapseBlock("general_status")}
          >
            Statusas
          </MainBlock>

          {collapse.general_status && (
            <Divs className="py-4 bg-silver">
              <RenderStatus
                avalaibleStatus={avalaibleStatus}
                status_id={general.status_id}
                onChange={onChangeGeneral}
                errors={errors}
              />
            </Divs>
          )}

          {rline && (
            <div className="mt-4">
              <MainBlock
                color={"#00b3a9"}
                action={collapse.general_other}
                onChange={() => collapseBlock("general_other")}
              >
                Kita
              </MainBlock>

              {collapse.general_other && (
                <Divs className="col-12 bg-silver py-3">
                  <TextInputModal
                    text="Pastaba"
                    name="client_comment"
                    value={general.client_comment}
                    onChange={onChangeGeneral}
                  />
                </Divs>
              )}
            </div>
          )}
        </div>
      </div>

      <div>
        <button
          type="button"
          className="btn btn-primary mt-3 float-right"
          style={{ width: "50%" }}
          onClick={() => onSubmitForm()}
        >
          Sukurti
        </button>
      </div>
    </div>
  );
}

export default OrderCreateModal;

// Componetai
const RenderGoods = ({
  onChangeOrderRows,
  onChangeTotals,
  order_rows,
  rline,
  rline_code,
  setOrderRows,
}) => {
  const postOrderRowGoods = async (object) => {
    try {
      const rows = [...order_rows, object.order_row];
      let promise = new Promise((resolve) => {
        onChangeOrderRows(rows);
        const reduxProducts = rows.reduce(
          (cur, acc) => +acc.product_price * +acc.quantity + cur,
          0
        );
        onChangeTotals({
          target: { name: "total_products", value: reduxProducts },
        });
        return resolve({ ok: true });
      });

      const result = await promise;
      return result;
    } catch (err) {}
  };
  const deleteOrderRow = (idx) => {
    const rows = [...order_rows];
    rows.splice(idx, 1);
    const reduxProducts = rows.reduce(
      (cur, acc) => +acc.product_price * +acc.quantity + cur,
      0
    );
    onChangeTotals({
      target: { name: "total_products", value: reduxProducts },
    });
    onChangeOrderRows(rows);
  };

  const updateOrderRow = (idx, newObj) => {
    const oldOrderRows = [...order_rows];
    oldOrderRows[idx] = {
      ...oldOrderRows[idx],
      ...newObj,
    };
    const reduxProducts = oldOrderRows.reduce(
      (cur, acc) => +acc.product_price * +acc.quantity + cur,
      0
    );
    onChangeTotals({
      target: { name: "total_products", value: reduxProducts },
    });
    setOrderRows(oldOrderRows);
  };

  return (
    <div className="bg-silver py-3 m-0">
      <React.Fragment>
        <Divs>
          <div className="table-responsive bg-silver">
            <div
              className="col-12"
              style={{
                marginBottom: "1rem",
              }}
            >
              <table
                className="table table-sm"
                style={{ marginBottom: "0px!important" }}
              >
                {/*  */}
                {order_rows.map((item, idx) => (
                  <OrderRow
                    key={idx}
                    item={item}
                    updateOrderRow={(item) => updateOrderRow(idx, item)}
                    deleteOrderRow={() => deleteOrderRow(idx)}
                  />
                ))}
                {/*  */}
              </table>
            </div>
          </div>
        </Divs>

        <OrderRowAdd
          mode={"Order_create"}
          orderId={""}
          postOrderRowGoods={postOrderRowGoods}
          rline={rline}
          rline_code={rline_code}
        />
      </React.Fragment>
    </div>
  );
};

function OrderRow({ item, updateOrderRow, deleteOrderRow }) {
  const [showRemove, setShowRemove] = useState(false);
  const [update, setUpdate] = useState(false);
  const [errors, setErrors] = useState({});
  const [variables, setVariables] = useState({
    product_name: item.product_name,
    quantity: item.quantity,
    product_price: item.product_price,
  });

  const onChange = (e) =>
    setVariables({ ...variables, [e.target.name]: e.target.value });
  const updateFunc = () => {
    if (update) {
      const { product_name, quantity, product_price } = variables;
      if (product_name && quantity && product_price) {
        setUpdate(false);
        updateOrderRow(variables);
      } else {
        let errors = {};
        if (!product_name) {
          errors.product_name = "Įveskite tekstą";
        }
        if (!quantity) {
          errors.quantity = "Įveskite kiekį";
        }
        if (!product_price) {
          errors.product_price = "Įveskite kainą";
        }
        return setErrors(errors);
      }
    } else {
      setUpdate(true);
      setVariables({
        product_name: item.product_name,
        quantity: item.quantity,
        product_price: item.product_price,
      });
    }
  };
  return (
    <thead
      onMouseEnter={() => setShowRemove(true)}
      onMouseLeave={() => setShowRemove(false)}
    >
      <tr>
        <td className="align-middle" style={{ fontSize: "0.8rem" }}>
          {!have_product_reference(item.product_reference) ? (
            <div className="text-center m-auto">
              <AiOutlineCheckCircle
                className={`text-${
                  item.product_reference == "1234" ? "warning" : "success"
                } mr-1`}
              />
            </div>
          ) : (
            item.product_reference
          )}
        </td>
        <td className="align-middle" colSpan="4">
          {update ? (
            <span className="d-flex">
              <div style={{ maxWidth: 100 }}>
                <TextInputModal
                  type="number"
                  name="quantity"
                  value={variables.quantity}
                  onChange={onChange}
                  warning={errors.quantity}
                />
              </div>
              <div>
                <TextInputModal
                  name="product_name"
                  value={variables.product_name}
                  onChange={onChange}
                  warning={errors.product_name}
                />
              </div>
            </span>
          ) : (
            <span style={{ fontSize: "0.8rem" }}>
              {parseInt(item.quantity)}
              &nbsp;*&nbsp;
              <span
                className={item.manual ? "border-bottom border-danger" : null}
              >
                {item.product_name}
              </span>
            </span>
          )}
        </td>
        <td className="align-middle">{item.barcode}</td>
        <td
          className="align-middle"
          style={{
            float: "right",
            fontSize: "0.8rem",
          }}
        >
          {update ? (
            <TextInputModal
              type="number"
              name="product_price"
              value={variables.product_price}
              onChange={onChange}
              warning={errors.product_price}
            />
          ) : (
            <span className="d-flex">
              <span>{Number(item.product_price).toFixed(2) || 0}</span>
              <span> &#8364;</span>
            </span>
          )}
        </td>
        <td
          className="align-middle text-right"
          style={{ whiteSpace: "nowrap" }}
        >
          {update ? (
            <Fragment>
              <FaCheck
                className="text-success"
                style={{
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
                onClick={updateFunc}
              />
              <FaTimes
                className="text-danger"
                style={{
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
                onClick={() => {
                  setUpdate(false);
                  setErrors({});
                }}
              />
            </Fragment>
          ) : (
            <Fragment>
              <MdEdit
                className="text-warning"
                style={{
                  cursor: "pointer",
                  visibility: showRemove ? "visible" : "hidden",
                  marginLeft: "10px",
                }}
                onClick={updateFunc}
              />
              <FaTrash
                className="text-danger"
                style={{
                  cursor: "pointer",
                  visibility: showRemove ? "visible" : "hidden",
                  marginLeft: "10px",
                }}
                onClick={deleteOrderRow}
              />
            </Fragment>
          )}
        </td>
      </tr>
    </thead>
  );
}
