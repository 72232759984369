import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Container from "../../components/Layout/Container";
import PosterCreate from "../../components/Marketing/Posters/PosterCreate";
import PosterFirst from "../../components/Marketing/Posters/PosterFirst";
import { useDispatch } from "react-redux";
import {
  downloadPoster,
  getPoster,
  getPosterOptions,
  massDestroy,
  updatePostersDate,
} from "../../redux/Actions/postersAction";
import ReactDOM from "react-dom";
import { AiOutlineClose } from "react-icons/ai";
import InputSwitchComponent from "../../components/common/InputSwitchComponent";
import {
  MdDashboard,
  MdEditNote,
  MdOutlineLibraryAddCheck,
} from "react-icons/md";
import DataPickerInputModal from "../../components/common/DataPickerInputModal";
import {
  antdMessageError,
  antdMessageSuccess,
  getDateFormat,
} from "../../helpers/Utils";
import { Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { SelectForm } from "../../components/common/Buttons";

export default function PostersScreen({ match }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPosterOptions());
  }, []);
  const [action, setAction] = useState("index");
  const [modal, setModal] = useState(false);

  const sectionRender = () => {
    switch (action) {
      case "index":
        return <PosterFirst setAction={setAction} setModal={setModal} />;
      default:
        return (
          <PosterCreate
            action={action}
            setAction={setAction}
            setModal={setModal}
          />
        );
    }
  };
  return (
    <Container
      location={{
        locationName: "Plakatai",
        locationUrl: match.url,
      }}
    >
      <div className="col-12 d-flex">
        {[
          {
            title: "Visi plakatai",
            action: "index",
            Image: MdDashboard,
          },
          {
            title: "Kurti plakatą",
            action: "create",
            Image: MdOutlineLibraryAddCheck,
          },
          {
            title: "Redaguojama",
            action,
            Image: MdEditNote,
          },
        ]
          .filter((d) =>
            d.title == "Redaguojama" ? typeof action == "number" : true
          )
          .map(({ title, action: prop_action, Image }) => (
            <ButtonMenuLanding
              key={prop_action}
              active={prop_action == action}
              onClick={() => setAction(prop_action)}
            >
              <Image className="mr-2 text-xl" />
              {title}
            </ButtonMenuLanding>
          ))}
      </div>
      {sectionRender()}
      {modal && <Modal modal={modal} setModal={setModal} />}
    </Container>
  );
}

const ButtonMenuLanding = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fcfcfc;
  border-radius: 4px;
  border: ${(props) => (props.active ? "1px solid #FE9C00" : "none")};
  color: ${(props) => (props.active ? "#FE9C00" : "#A0A0A0")};
  padding: 10px 15px;
  margin-bottom: 9px;
  margin-right: 9px;
  padding: 10px 51px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  & img {
    ${(props) =>
      props.active &&
      "filter: invert(50%) sepia(89%) saturate(748%) hue-rotate(2deg) brightness(107%) contrast(103%);"}
  }
  &:focus {
    outline: none;
  }
`;

const Modal = ({ modal, setModal }) => {
  console.log({ modal });
  const modalRef = useRef();

  const closeModalFunc = (e) => {
    if (modalRef.current == e.target) {
      setModal(false);
      // closeModal(false);
      console.log("CLOSE");
    }
  };
  return ReactDOM.createPortal(
    <Background ref={modalRef} onClick={(e) => closeModalFunc(e)}>
      <ModalWrapper>
        <ModalContent>
          {modal.type == "print" ? (
            <ModalPreview
              modal={modal.selected}
              onClose={() => setModal(false)}
            />
          ) : modal.type == "submit_csv" ? (
            <ModalPreview3 modal={modal} onClose={() => setModal(false)} />
          ) : (
            <ModalPreview2 modal={modal} onClose={() => setModal(false)} />
          )}
        </ModalContent>
      </ModalWrapper>
    </Background>,
    document.getElementById("custom_modal")
  );
};
// Modalas

const ModalPreview2 = ({ modal, onClose }) => {
  console.log({ modal });
  const dispatch = useDispatch();
  const [date, setDate] = useState(undefined);
  const [legalText, setLegalText] = useState("");

  const onSubmitModal = async () => {
    try {
      if (modal.type == "mass_remove") {
        const { success } = await dispatch(massDestroy(modal.selected));
        if (success) return onClose();
        throw new Error("ERR");
      }

      if (modal.type !== "legal_text" && !date)
        return antdMessageError("Nepasirinkta data");
      const params = {
        ids: modal.selected,
      };
      if (modal.type == "legal_text") {
        params.legal_text = legalText;
      } else if (modal.type == "discount_date_from") {
        params.discount_date_from = date;
      } else {
        params.discount_date_to = date;
      }
      const { success } = await dispatch(updatePostersDate(params));
      if (success) onClose();
    } catch (err) {
      console.log("ERROR: ", err);
    }
  };

  const legal_texts_option = [
    "",
    "Akcinių prekių kiekis ribotas.",
    "Akcijos ir nuolaidos nesumuojamos.",
    "Nuolaida taikoma akcijos skirtukais pažymėtoms prekėms.",
    "Nuolaida taikoma kasoje.",
  ].map((value) => ({ value, label: value }));

  return (
    <>
      <div className="d-flex align-items-center justify-content-between w-100">
        <h2 className="text-black text-xl font-semi-bold mb-0">
          {modal.type == "mass_remove"
            ? "Pašalinti "
            : `Pakeisti ${
                modal.type == "legal_text" ? "teisinį tekstą" : "datą"
              } `}
          {modal.selected.length} element
          {modal.selected.length == 1 ? "ą" : "us"}
        </h2>
        <AiOutlineClose
          className="cursor-pointer text-xl"
          color="#D2D2D2"
          onClick={onClose}
        />
      </div>
      <div className="w-100 mt-2 mb-4">
        {modal.type == "mass_remove" ? null : modal.type == "legal_text" ? (
          <SelectForm
            label="Teisinis tekstas"
            onlyPlaceholder="Pasirinkti"
            options={legal_texts_option}
            name="legal_text"
            value={legalText || undefined}
            onChange={(legal_text) => setLegalText(legal_text)}
          />
        ) : (
          <DataPickerInputModal
            text={
              modal.type == "discount_date_from"
                ? "Akcijos data nuo"
                : "Akcijos data iki"
            }
            name="date"
            value={getDateFormat(date)}
            onChange={(_, ar) => setDate(ar)}
          />
        )}
      </div>

      <button
        className="btn btn-lg btn-danger w-100 text-xs font-normal"
        onClick={() => onSubmitModal()}
      >
        {modal.type == "mass_remove" ? "Pašalinti" : "Keisti"}
      </button>
    </>
  );
};

const ModalPreview3 = ({ modal, onClose }) => {
  const dispatch = useDispatch();

  const props = {
    name: "file",
    action: "/api/v1/posters/mass_import_from_xlsx",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        antdMessageSuccess(`${info.file.name} sėkmingai įkeltas`);
        dispatch(getPoster(1, ""));
      } else if (info.file.status === "error") {
        antdMessageError(`${info.file.name} klaida`);
      }
    },
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-between w-100">
        <h2 className="text-black text-xl font-semi-bold mb-0">Importuoti</h2>
        <AiOutlineClose
          className="cursor-pointer text-xl"
          color="#D2D2D2"
          onClick={onClose}
        />
      </div>
      <div className="w-100 mt-3">
        <Upload className="w-100" {...props}>
          <Button className="w-100" icon={<UploadOutlined />}>
            Įkelti dokumentą
          </Button>
        </Upload>
      </div>
    </>
  );
};

const ModalPreview = ({ modal, onClose }) => {
  const [type, setType] = useState("A4");

  const onSubmitModal = () => {
    return downloadPoster(
      modal.map((d) => d.id),
      type
    );
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-between w-100 mb-3">
        <h2 className="text-black text-xl font-semi-bold mb-0">
          Spausdinti {modal.length} element{modal.length == 1 ? "ą" : "us"}
        </h2>
        <AiOutlineClose
          className="cursor-pointer text-xl"
          color="#D2D2D2"
          onClick={onClose}
        />
      </div>
      <div className="w-100">
        <InputSwitchComponent
          label="Formatas"
          options={[
            {
              label: "A6",
              value: "A6",
            },
            {
              label: "A4",
              value: "A4",
            },
            {
              label: "A3",
              value: "A3",
            },
          ]}
          name="type"
          value={type}
          onChange={(e) => setType(e.target.value)}
        />
      </div>
      {/* <div className="w-100">
        <TextInputModal
          type="number"
          text="Kiekis"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </div> */}

      <button
        className="btn btn-lg btn-danger w-100 text-xs font-normal mt-3"
        onClick={() => onSubmitModal()}
      >
        Spausdinti
      </button>
    </>
  );
};

const Background = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const ModalWrapper = styled.div`
  min-width: 400px;
  // height: 280px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  padding: 29px 28px;
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  position: relative;
  z-index: 10;
  border-radius: 4px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
