import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  createWaitingPayment,
  resendWaitingPayment,
} from "../../../../../../redux/Actions/paymentAction";
import { prettyDateFormat } from "../../../../../../helpers/Utils";
import TextInputModal from "../../../../TextInputModal";
import { FlopppyButton } from "../../../../ButtonWithLoading";
import { message } from "antd";
import { IoIosMail, IoIosRefreshCircle } from "react-icons/io";

function PaymentUpsale({ waiting_payments, orderId, type, disabled = false }) {
  const dispatch = useDispatch();

  const [service, setService] = useState("");
  const [errors, setErrors] = useState({});
  const [price, setPrice] = useState(0);

  const [creatingWaitingPayment, setCreatingWaitingPayment] = useState(false);

  const createWaitingPaymentComponent = () => {
    setCreatingWaitingPayment(true);
    const request_type = type == "Order" ? "orders" : "repair_services";
    dispatch(createWaitingPayment(orderId, service, price, request_type)).then(
      (response) => {
        if (response.success) {
          message.success("Mokėjimas pridėtas");
          setService("");
          setPrice(0);
          setErrors({});
          setCreatingWaitingPayment(false);
        } else {
          message.error("Įvyko klaida");
          setErrors(response.errors);
          setCreatingWaitingPayment(false);
        }
      }
    );
  };

  const resendWaitingPaymentComponent = (id) => {
    dispatch(
      resendWaitingPayment(
        orderId,
        id,
        type == "Order" ? "orders" : "repair_services"
      )
    ).then((response) => {
      if (response.success) {
        message.success("Mokėjimas pakartotinai išsiųstas");
      } else {
        message.error("Įvyko klaida");
      }
    });
  };

  return (
    <React.Fragment>
      <hr className="mt-1 mb-1" />
      {waiting_payments.length == 0 ? (
        "Nėra laukiamų apmokėjimų"
      ) : (
        <table
          className="table table-sm"
          style={{ marginBottom: "0px!important" }}
        >
          <thead className="border-bottom mb-1">
            <tr>
              <th className="text-black">Paslauga ar prekė</th>
              <th className="text-black w-33">Suma</th>
              <th className="text-black w-33">Išsiuntimo data</th>
              <th className="text-black w-33">Mokėjimo data</th>
              <th>Statusas</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {waiting_payments.map((payment, index) => (
              <tr key={index}>
                <td>{payment.service}</td>
                <td>{Number(payment.price).toFixed(2)} €</td>
                <td>{prettyDateFormat(payment.created_at)}</td>
                <td>
                  {payment.created_at !== payment.updated_at &&
                    prettyDateFormat(payment.updated_at)}
                </td>
                <td>
                  {payment.status == "unpaid"
                    ? "Neapmokėta"
                    : payment.status == "paid_temp"
                    ? "Mokėjimas inicijuotas"
                    : "Apmokėta"}
                </td>
                <td>
                  {payment.status == "unpaid" ? (
                    <div
                      onClick={() => resendWaitingPaymentComponent(payment.id)}
                    >
                      <IoIosRefreshCircle
                        color={"#8678AB"}
                        style={{ cursor: "pointer" }}
                        size={20}
                      />
                    </div>
                  ) : (
                    <div className="text-success">OK</div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {!disabled && (
        <>
          <p className="bg-silver d-flex mb-1 mt-3">Siųsti laišką pavedimui</p>
          <div className="d-flex">
            <div className="w-50 mr-2">
              <TextInputModal
                type="text"
                text="Prekė ar paslauga"
                placeholder="Įvesti"
                name="[payment_auto]service"
                value={service}
                onChange={(e) => setService(e.target.value)}
                error={errors.service}
                required={true}
              />
            </div>
            <div className="w-50">
              <div className="d-flex justify-content-between">
                <div>
                  <TextInputModal
                    type="number"
                    text="Suma"
                    placeholder="Įvesti"
                    name="[payment_auto]price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    error={errors.price}
                    required={true}
                  />
                </div>
                <div className="ml-3">
                  <div className="text-bold my-1 invisible">Save</div>

                  <FlopppyButton
                    className="p-0"
                    loading={creatingWaitingPayment}
                    onClick={() => createWaitingPaymentComponent()}
                  >
                    <IoIosMail color={"#fff"} size={20} />
                  </FlopppyButton>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
}

export default PaymentUpsale;
