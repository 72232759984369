import React from "react";
import { Switch, Route } from "react-router-dom";
// Components
import SmsTemplate from "../components/SmsTemplate/SmsTemplate";
import Stores from "../components/Stores/Stores";
import Brands from "../components/Brands/Brands";
import Families from "../components/Families/Families";
import Leasings from "../components/Leasings/Leasings";
import Descriptions from "../components/Descriptions/Descriptions";
import Description from "../components/Descriptions/Description";
import Repricings from "../components/Repricings/Repricings";
import Repricing from "../components/Repricings/Repricing";
import Suppliers from "../components/Suppliers/Suppliers";
import Countries from "../components/Countries/Countries";
import PickupAddresses from "../components/Pickup_Addresses/PickupAddresses";
import ListsOfDocuments from "../components/ListsOfDocuments/ListsOfDocuments";
import Users from "../components/Users/Users";
import DPD_manifest from "../components/dpd_pickup/dpd_manifest/DPD_manifest";
import DPD_Shipment from "../components/dpd_pickup/dpd_shipment/DPD_shipment";
import OMNIVA_manifest from "../components/omniva_pickup/omniva_manifest/OMNIVA_manifest";
import OMNIVA_Shipment from "../components/omniva_pickup/omniva_shipment/OMNIVA_shipment";
import venipak_manifest from "../components/venipak_pickup/venipak_manifest/venipak_manifest";
import venipak_shipment from "../components/venipak_pickup/venipak_shipment/venipak_shipment";
import Login from "../components/Authorization/Login/Login";
import Omniauth from "../components/Authorization/Omniauth";
import AcceptedOrders from "../components/orders/accepted_orders/AcceptedOrders";
import PrivateRoute from "../components/common/PrivateRoute";
import Products from "../components/Products/Products";
import Pickup_Users from "../components/Pickup users/Pickup_Users";
import CommentsState from "../components/CommentsStates/comments_State";
import DocumentOption from "../components/documents/options/DocumentOption";
import OwnTransport_pickup from "../components/ownTransport_pickup/shipment/OwnTransport_shipment";
import Tickets from "../components/Lottery/Tickets/Tickets";
import Participans from "../components/Lottery/Participants/Participants";
import lp_express_shipment from "../components/lp_express_pickup/lp_express_shipment/lp_express_shipment";
import lp_express_manifest from "../components/lp_express_pickup/lp_express_manifest/lp_express_manifest";
import RivileServices from "../components/rivile_services/RivileServices";
import RivilePvm25 from "../components/rivile_services/RivilePvm25";
import RivileStores from "../components/rivile_services/RivileStores";
import Statuses from "../components/statuses/Statuses";
import RivilePaymentType from "../components/rivile_services/RivilePaymentType";
import Rivile from "../components/Rivile/Rivile";
import PrestaServices from "../components/PrestaServices/PrestaServices";
import PayModule from "../components/PayModule/PayModule";
import RivileBigBoxScreen from "../components/Rivile/Rivile_BigBox/RivileBigBoxScreen";
import ServiceScreen from "../components/ServicePage/ServiceScreen";
import SuppliersInvoices from "../components/suppliers_invoices/suppliers_invoices";
import ItellaShipments from "../pages/itella/shipment/itella_shipment";
import ItellaManifest from "../pages/itella/manifest/itella_manifest";
import Guide from "../page/marketing/Guide";
import PostersScreen from "../page/marketing/PostersScreen";
import Purchases3PL from "../page/3pl/Purchases3PL";
import Sales3Pl from "../page/3pl/Sales3Pl";
import UserPmissionsScreen from "../page/User/UserPermissionsScreen";
import UserPermissionsOption from "../helpers/UserPermissionsOption";
import TrashFeeCategoriesScreen from "../page/TrashFee/TrashFeeCategoriesScreen";
import ReportScreen from "../page/reports/ReportScreen";
import NotFound404 from "../page/NotFound404";
import LeasingScreen from "../page/LeasingScreen";
import CommerceOrdersPage from "../pages/commerce/CommerceOrdersPage";
import CommerceOrdersIdPage from "../pages/commerce/CommerceOrdersIdPage";
import EnergyLabelsPage from "../page/EnergyLabelsPage";

export default function OrderRoutesLogic({ user_id, permissions }) {
  const rebuildTree = () => {
    const componentSetter = (uuid) => {
      switch (uuid) {
        case "orders":
          return AcceptedOrders;
        case "service_repair":
          return ServiceScreen;
        case "service_contacts":
          return PrestaServices;
        case "products":
          return Products;
        case "documents":
          return ListsOfDocuments;
        case "supplier_invoices":
          return SuppliersInvoices;
        case "logistic_dpd_manifest":
          return DPD_manifest;
        case "logistic_dpd_shipment":
          return DPD_Shipment;
        case "logistic_omniva_manifest":
          return OMNIVA_manifest;
        case "logistic_omniva_shipment":
          return OMNIVA_Shipment;
        case "logistic_lp_express_manifest":
          return lp_express_manifest;
        case "logistic_lp_express_shipment":
          return lp_express_shipment;
        case "logistic_itella_manifest":
          return ItellaManifest;
        case "logistic_itella_shipment":
          return ItellaShipments;
        case "logistic_venipak_manifest":
          return venipak_manifest;
        case "logistic_venipak_shipment":
          return venipak_shipment;
        case "logistic_technorama_shipment":
          return OwnTransport_pickup;
        case "logistic_dpd_shipment":
          return DPD_Shipment;

        case "logistic_3pl_purchases":
          return Purchases3PL;
        case "logistic_3pl_sales":
          return Sales3Pl;
        case "logistic_3pl_internal":
          return Sales3Pl;
        case "logistic_3pl_shipments":
          return Sales3Pl;

        case "rivile_bigbox_sales":
          return Rivile;
        case "rivile_bigbox_purchases":
          return Rivile;
        case "rivile_bigbox_orders":
          return RivileBigBoxScreen;

        case "marketing_lottery_tickets":
          return Tickets;
        case "marketing_lottery_participants":
          return Participans;
        case "marketing_guide":
          return Guide;
        case "marketing_posters":
          return PostersScreen;
        case "staff_pay":
          return PayModule;
        case "reports":
          return ReportScreen;

        case "options_trash_fee":
          return TrashFeeCategoriesScreen;
        case "options_sms_templates":
          return SmsTemplate;
        case "options_stores":
          return Stores;
        case "options_suppliers":
          return Suppliers;
        case "options_countries":
          return Countries;

        case "options_pickup_addresses":
          return PickupAddresses;
        case "options_users":
          return Users;
        case "options_user_permissions":
          return UserPmissionsScreen;
        case "options_pickup_users":
          return Pickup_Users;
        case "options_comment_states":
          return CommentsState;

        case "options_rivile_store":
          return RivileStores;
        case "options_rivile_service":
          return RivileServices;
        case "options_rivile_pvm":
          return RivilePvm25;
        case "options_rivile_payment":
          return RivilePaymentType;
        case "options_statuses":
          return Statuses;
        case "options_documents_options":
          return DocumentOption;
        case "goods_brands":
          return Brands;
        case "goods_families":
          return Families;
        case "goods_leasings":
          return Leasings;
        case "goods_descriptions":
          return Descriptions;
        case "goods_description":
          return Description;
        case "goods_repricings":
          return Repricings;
        case "goods_repricing":
          return Repricing;
        case "leasings":
          return LeasingScreen;
        case "commerce_orders":
          return CommerceOrdersPage
        case "commerce_order_show":
          return CommerceOrdersIdPage
        case "energy_labels":
          return EnergyLabelsPage;
        default:
          return Login;
      }
    };
    const tree = UserPermissionsOption({ id: user_id });
    const available = [];
    const tree_setter = (arr) => {
      arr.forEach((d) => {
        const its_available = permissions[d.uuid];
        if (!its_available) return;
        if (d.url) {
          available.push({
            uuid: d.uuid,
            title: d.title,
            url: d.url,
            component: componentSetter(d.uuid),
          });
          if (d.action) {
            available.push({
              uuid: d.uuid,
              title: d.title,
              url: d.url + "/:action",
              component: componentSetter(d.uuid),
            });
          }
        }
        if (d.options && d.options.length > 0) {
          return tree_setter(d.options);
        }
      });
    };
    tree_setter(tree);
    // Custom add wihout sidebar
    // console.log({available});
    // available.push({
    //   uuid: 'guide_rec_001',
    //   title: "Guide recomendations",
    //   url: `/marketing/guide/recomendations/:action`,
    //   component: GuideRecommendationIndex,
    // })
    //
    return available;
  };
  return (
    <Switch>
      <Route exact path="/sign_in" component={Login} />
      <Route exact path="/omniauth" component={Omniauth} />
      {rebuildTree().map((e) => (
        <PrivateRoute key={e.uuid} exact path={e.url} component={e.component} />
      ))}
      {/* <Route exact path="*" component={Login} /> */}
      <Route exact path="*" component={NotFound404} />
    </Switch>
  );
}
