import { message } from "antd";
import Axios from "axios";
import {
  antdMessageError,
  antdMessageInfo,
  antdMessageSuccess,
  errorHandler,
} from "../../helpers/Utils";
import {
  GET_REPRICINGS,
  CLEAR_ERRORS,
  GET_ERRORS,
  LOADING_REPRICINGS,
  LOADING_REPRICING,
  GET_REPRICING,
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

export const getRepricings = (page, pageCount, filterParams) => (dispatch) => {
  dispatch(setRepricingsLoading());
  Axios.get(
    `/api/v1/repricings?page=${page}&per_page=${pageCount}${filterParams}`
  )
    .then((res) => {
      dispatch({
        type: GET_REPRICINGS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getRepricing = (id, filterParams) => (dispatch) => {
  dispatch(setRepricingLoading());
  Axios.get(`/api/v1/repricings/${id}?${filterParams}`)
    .then((res) => {
      dispatch({
        type: GET_REPRICING,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
    });
};

export const confirmRepricings = () => async (_, getState) => {
  try {
    antdMessageInfo("Kviečiama");
    const repricing_id = getState().repricing?.repricing?.info?.id;
    console.log({ repricing_id });
    if (!repricing_id) throw new Error("Something wrong");
    const { data } = await Axios.post(
      `/api/v1/repricings/${repricing_id}/confirm_repricings`
    );
    if (!data.success) throw new Error("Something wrong");
    antdMessageSuccess();
  } catch (err) {
    antdMessageError(err?.response?.data?.error || err?.message);
  }
};

export const setRepricingsLoading = () => {
  return {
    type: LOADING_REPRICINGS,
  };
};

export const setRepricingLoading = () => {
  return {
    type: LOADING_REPRICING,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
