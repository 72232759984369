import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { CheckBoxInput, SelectForm } from "../../../Buttons";
import { getRivileStoreOptions } from "../../../../../redux/Actions/rivileStoreAction";
import TextInputModal from "../../../TextInputModal";

const STATUS_BY_PAYMENT = {
  "Apmokėjimas grynais parduotuvėje": 31,
  "Lizingas": 10,
  "Mokėjimas pavedimu": 19,
  "Paysera": 6,
};

function RenderDelivery({
  onHandleChangeAddress,
  parcel_terminals,
  shipping_address,
  billing_address,
  carriers,
  general,
  onChange,
  payment_types,
  total_shipping,
  onChangeTotals,
  errors,
}) {
  const [carrierType, setCarrierType] = useState({});

  const [Parcels, setParcels] = useState([]);

  const carrierModuleFilter = (carrier) => {
    let parcels = [];
    if (carrier.label == "OMNIVA Paštomatas") {
      parcels = parcel_terminals.omniva;
    } else if (carrier.label == "LP Express 24 pristatymas į paštomatą") {
      parcels = parcel_terminals.lp_express;
    } else if (carrier.label == "DPD Paštomatas") {
      parcels = parcel_terminals.dpd;
    } else if (carrier.label == "Atsiėmimas parduotuvėje") {
      return getRivileStoreOptions().then((response) => setParcels(response));
    } else if (carrier.label == "Itella paštomatas") {
      parcels = parcel_terminals.itella;
    } else if (carrier.label == "Venipak pickup point") {
      parcels = parcel_terminals.venipak;
    } else if (carrier.label == "Venipak paštomatas") {
      parcels = parcel_terminals.venipak;
    }  else {
      parcels = [];
    }
    setParcels(parcels);
  };

  useEffect(() => {
    onChange({ target: { name: "delivery_children", value: {} } });
    onHandleChangeAddress(
      {
        parcel_terminal_id: null,
      },
      "shippingAddress"
    );
  }, [general.carrier_id]);

  const onChangePayment = (value) => {
    onChange(
      {
        payment: value,
        status_id: STATUS_BY_PAYMENT[value] || 13,
      },
      true
    );
  };

  const onChangeCarrier = (value) => {
    onChange(
      {
        carrier_id: value,
      },
      true
    );

    onHandleChangeAddress({
      target: {
        name: "[billingAddress]street2",
        value: null,
      },
    });

    const findCarrier = carriers.find((e) => e.value == value);
    if (findCarrier) {
      setCarrierType(findCarrier);
      carrierModuleFilter(findCarrier);
    }
  };

  const onChangeAddress = (value) => {
    onHandleChangeAddress(
      {
        street2: "",
        city: value.city,
        postal_code: value.postal_code,
        street: value.value,
        parcel_terminal_id: null,
      },
      "shippingAddress"
    );
    onChange(
      {
        delivery_children: value,
      },
      true
    );
  };

  return (
    <React.Fragment>
      <div className="col-12">
        <SelectForm
          require={true}
          placeholder="Mokėjimas"
          options={payment_types.filter((e) => e.value !== undefined)}
          dropdownMatchSelectWidth={false}
          value={general.payment}
          onChange={onChangePayment}
          error={errors?.payment?.length > 0 ? "Privalomas laukelis" : ""}
        />
      </div>
      <div className="col-12">
        <TextInputModal
          require={true}
          type="number"
          text="Pristatymo kaina"
          placeholder="Įvesti"
          name="total_shipping"
          value={total_shipping}
          onChange={onChangeTotals}
          warning={errors.total_shipping}
        />
      </div>
      <div className="col-12">
        <SelectForm
          require={true}
          placeholder="Pristatymo būdas"
          options={carriers}
          dropdownMatchSelectWidth={false}
          value={general.carrier_id}
          onChange={(value) => onChangeCarrier(value)}
          error={errors?.carrier?.length > 0 ? "Privalomas laukelis" : ""}
        />
      </div>
      {Parcels.length > 0 && (
        <>
          <div className="col-12">
            {carrierType.label == "Atsiėmimas parduotuvėje" ? (
              <>
                <div
                  className={`text-bold my-1 ${
                    errors.delivery ? "text-danger" : ""
                  }`}
                >
                  Parduotuvės pakeitimas
                </div>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder={"Pasirintki"}
                  name="street"
                  onChange={(e, arg) => onChangeAddress(arg)}
                  dropdownMatchSelectWidth={true}
                  size="default"
                  value={general.delivery_children?.value}
                >
                  {Parcels &&
                    Parcels.map((item) => (
                      <Select.Option
                        key={item.id}
                        id={item.id}
                        postal_code={item.postal_code}
                        city={item.city}
                        value={item.address}
                        rivile_code={item.rivile_code}
                      >
                        {item.address}
                      </Select.Option>
                    ))}
                </Select>
                {errors.delivery && (
                  <div className="text-danger">{errors.delivery}</div>
                )}
                {general.delivery_children?.value && (
                  <div className="mt-2">
                    <CheckBoxInput
                      text="Express"
                      checked={billing_address.street2}
                      onChange={() =>
                        onHandleChangeAddress({
                          target: {
                            name: "[billingAddress]street2",
                            value:
                              billing_address.street2 == "express"
                                ? null
                                : "express",
                          },
                        })
                      }
                    />
                  </div>
                )}
              </>
            ) : (
              <SelectForm
                placeholder="Paštomato pakeitimas"
                options={Parcels}
                dropdownMatchSelectWidth={false}
                value={general.delivery_children?.value}
                error={errors.delivery}
                onChange={(value, arg) => {
                  onChange({
                    target: { name: "delivery_children", value: arg },
                  });
                  onHandleChangeAddress(
                    {
                      city: "",
                      postal_code: "",
                      street: "",
                      parcel_terminal_id: value,
                    },
                    "shippingAddress"
                  );
                }}
              />
            )}
          </div>
        </>
      )}
    </React.Fragment>
  );
}
export default RenderDelivery;
