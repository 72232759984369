import React from "react";
import Axios from "axios";
import { DebounceSelect } from "../../../common";
import SwitchComp from "../../../common/SwitchComp";
import TextInputModal from "../../../common/TextInputModal";
import GuideIllustration from "../GuideIllustration";
import ItemsPreview from "./ItemsPreview.jsx";

const FirstModalPreview = ({
  value,
  onChangeState,
  Images,
  setImages,
  fileInput,
  setModalType,
  setValue,
  errors,
}) => {
  const onHandleChange = (e, idx) => {
    const { value, name } = e.target;
    setValue((prev) => {
      let obj = { ...prev };
      obj.steps[idx][name] = value;
      return obj;
    });
  };
  const onHandleAdd = (d) => {
    setValue((prev) => {
      return {
        ...prev,
        steps: [
          ...prev.steps,
          {
            title: "Be pavadinimo",
            active: false,
            description: "",
            answers: [],
          },
        ],
      };
    });
  };

  const onHandleDelete = (d, idx) => {
    setValue((prev) => {
      let steps = [...prev.steps];
      steps[idx]._destroy = 1;
      return {
        ...prev,
        steps,
      };
    });
  };
  console.log(
    value?.shop_category_id
      ? [
          {
            value: value?.shop_category?.id,
            label: value?.shop_category?.title,
          },
        ]
      : []
  );
  return (
    <div>
      <TextInputModal
        text="Pavadinimas"
        name="title"
        value={value.title}
        onChange={onChangeState}
        warning={errors.title}
      />

      <div className="py-1" />
      <DebounceSelect
        label="Prekių kategorija"
        showSearch={true}
        options={
          value?.shop_category_id
            ? [
                {
                  value: value?.shop_category?.id,
                  label: value?.shop_category?.title,
                },
              ]
            : []
        }
        fetchOptions={async (value) => {
          try {
            let api_url = `/api/v1/shop_categories/list?q[title_i_cont_any]=${value}`;

            const { data } = await Axios.get(api_url);
            const valArr = data?.filter((d) => d.title) || [];
            return (
              valArr.map((e) => {
                return {
                  ...e,
                  value: e.id,
                  label: e.title,
                };
              }) || []
            );
          } catch (err) {
            errorHandler(err);
            return [];
          }
        }}
        style={{
          width: "100%",
        }}
        //
        placeholder="Kategorija"
        value={value.shop_category_id || undefined}
        onChange={(value) => {
          onChangeState({
            target: {
              name: "shop_category_id",
              value: value.value,
            },
          });
        }}
        error={errors.shop_category}
      />
      <div className="py-1" />
      <GuideIllustration
        Images={Images}
        setImages={setImages}
        fileInput={fileInput}
      />
      <div className="py-1" />

      <ItemsPreview
        title="Žingsniai"
        steps={value?.steps || []}
        setModalType={setModalType}
        onChangeState={(idx) => {
          setValue((prev) => {
            prev.steps[idx].active = !prev.steps[idx].active;
            return prev;
          });
        }}
        onHandleDelete={onHandleDelete}
        onHandleChange={onHandleChange}
        onHandleAdd={onHandleAdd}
        addStep={() => {
          setValue((prev) => {
            prev.steps.push({
              title: "Be pavadinimo",
              active: false,
              description: "",
              answers: [],
            });
            return prev;
          });
        }}
      />

      <div className="py-1" />
      <div className="d-flex align-items-center mb-2 mt-2">
        <SwitchComp
          placeholder="Statusas"
          checkedChildren="I"
          unCheckedChildren="0"
          checked={value.active}
          onChange={(value) =>
            onChangeState({ target: { name: "active", value } })
          }
          inlineText={value.active ? "Aktyvus" : "Neaktyvus"}
        />
      </div>
      <div className="py-1" />
    </div>
  );
};

export default FirstModalPreview;
