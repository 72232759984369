import React, { useState, useEffect } from "react";
import { DatePicker, Input, message } from "antd";
import { useDispatch } from "react-redux";
import { FlopppyButton } from "../../../common/ButtonWithLoading";
import { addServiceItemComment } from "../../../../redux/Actions/Service/serviceAction";
import { SelectForm } from "../../../common/Buttons";
import Axios from "axios";
import moment from "moment";
import { getDateFormat } from "../../../../helpers/Utils";

const { TextArea } = Input;

const SubmitComment = ({ identifier, service_id }) => {
  const dispatch = useDispatch();
  const [InputValue, setInput] = useState("");
  const [options, setOptions] = useState([]);
  const [selectValue, setSelectValue] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  //
  const [dateInput, setDateInput] = useState(undefined);
  let dateShow = options.find((item) => item.value == selectValue)
    ? options.find((item) => item.value == selectValue).show_date
    : false;


  useEffect(() => {
    const fetchCommentState = async () => {
      try {
        const { data } = await Axios.get(
          `/api/v1/comment_states/search_by_type?&q[comment_type_eq]=${identifier}`
        );
        // if (data) {
        const comment_state_data = data.map((e) => ({
          value: e.id,
          label: e.name,
          show_date: e.show_date,
        }));
        setOptions(comment_state_data);
        // }
      } catch (err) {
        console.log("Error: ", err);
      }
    };
    if (identifier == "comment_ka") {
      fetchCommentState();
    }
  }, []);

  const onSubmit = () => {
    let dateTime = dateShow ? dateInput : false;
    if (InputValue.trim() === "") {
      return setError(true);
    } else if (identifier === "comment_ka" && !selectValue) {
      return setError(true);
    } else if (dateShow && dateInput === undefined) {
      return setError(true);
    }

    setLoading(true);
    let params = {
      service_comment: {
        identifier,
        comment: InputValue.trim(),
        service_id,
      },
    };
    if (selectValue) {
      params.service_comment.comment_state_id = selectValue;
    }

    if (dateTime) {
      params.service_comment.date = moment(dateInput);
    }

    dispatch(addServiceItemComment(params)).then((res) => {
      setLoading(false);
      if (res.success) {
        message.success("Sėkmingai pridėtas komentaras.");
        setInput("");
        setError(false);
      } else {
        // message.error("Įvyko klaida.");
        setError(true);
      }
    });
  };
  return (
    <div className="mx-2 mb-2">
      {options?.length > 0 && (
        <div className="mb-2">
          <SelectForm
            onlyPlaceholder="Komentarų būsenos"
            options={options}
            value={selectValue}
            onChange={(e) => setSelectValue(e)}
          />
        </div>
      )}
      {selectValue !== undefined && dateShow && (
        <div className="mb-2">
          <DatePicker
            placeholder="Data"
            style={{ width: "100%" }}
            disabledDate={(d) =>
              !d || d.isBefore(moment().format("YYYY-MM-DD"))
            }
            value={getDateFormat(dateInput)}
            onChange={(_, arg) => setDateInput(arg)}
          />
        </div>
      )}
      <TextArea
        className="mb-2"
        placeholder="Komentaras"
        value={InputValue}
        onChange={(e) => setInput(e.target.value)}
      />
      <div className="d-flex justify-content-between">
        <div className="text-danger">{error && "Įveskite tekstą"}</div>
        <FlopppyButton loading={loading} onClick={onSubmit} />
      </div>
    </div>
  );
};

export default SubmitComment;
