import React from "react";
import InputForm from "../../common/InputForm";
import ModalToChange from "../../Layout/ModalToChange";
import { SelectForm } from "../../common/Buttons";
class CountryEditModal extends React.Component {
  constructor() {
    super();
    this.state = {
      id: 0,
      name: "",
      en_name: "",
      currency: "",
      code: "",
      eu: false,
      action: "new",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.action === "new" && this.state.action === "edit") {
      this.setState({
        name: "",
        en_name: "",
        currency: "",
        code: "",
        eu: false,
        action: "new"
      });
      this.removeErrors(nextProps.errors);
    } else if (nextProps.action === "edit" && nextProps.objectData.id !== this.state.id) {
      let item = nextProps.objectData;
      this.setState({
        id: item.id || 0,
        name: item.name || "",
        en_name: item.en_name || "",
        currency: item.currency || "",
        code: item.code || "",
        eu: item.eu || false,
        action: "edit"
      });
      this.removeErrors(nextProps.errors);
    }
  }

  removeErrors = (errors) => {
    if (Object.keys(errors).length > 0) {
      this.props.clearErrors();
    }
  };

  handleChangeText = (e, select) => {
    if (select) {
      this.setState({
        [select]: e,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const variables = {
      country: {
        id: this.state.id,
        name: this.state.name,
        en_name: this.state.en_name,
        currency: this.state.currency,
        code: this.state.code,
        eu: this.state.eu,
      },
    };
    if (this.props.action == "new") {
      this.props.postCountry(variables);
    } else if (this.props.action === "edit") {
      this.props.updateCountry(this.state.id, variables);
    }
  };

  render() {
    let euOption = [
      { label: "TAIP", value: true },
      { label: "NE", value: false },
    ];
    return (
      <ModalToChange
        handleSave={(e) => this.onSubmit(e)}
        status="tableEdit"
        text={this.props.action === "new" ? "Kurti naują" : "Redaguoti"}
        buttonText={this.props.action === "new" ? "Sukurti" : "Atnaujinti"}
      >
        <InputForm
          type="text"
          placeholder="Šalies pavadinimas"
          name="name"
          value={this.state.name}
          error={
            this.props.errors.name &&
            this.props.errors.name.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChangeText(e)}
        />
        <InputForm
          type="text"
          placeholder="Šalies pavadinimas angliškai"
          name="en_name"
          value={this.state.en_name}
          error={
            this.props.errors.en_name &&
            this.props.errors.en_name.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChangeText(e)}
        />
        <InputForm
          type="text"
          placeholder="Valiuta"
          name="currency"
          value={this.state.currency}
          onChange={(e) => this.handleChangeText(e)}
        />
        <InputForm
          type="text"
          placeholder="Šalies kodas"
          name="code"
          value={this.state.code}
          error={
            this.props.errors.code &&
            this.props.errors.code.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChangeText(e)}
        />

        <SelectForm
          placeholder={"Ar Europos Sąjungoje?"}
          selector="eu"
          onChange={(e) => this.handleChangeText(e, "eu")}
          value={this.state.eu}
          options={euOption}
        />
      </ModalToChange>
    );
  }
}

export default CountryEditModal;
