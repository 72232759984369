import React from "react";
import { ButtonEdit, EditElementClick } from "../../common/Buttons";

function TableColumnStatuses({ item, index, onEdit }) {
  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle">{item.id}</td>
      <td className="align-middle">
        <EditElementClick name={item.id_status} onEdit={() => onEdit(index, item.id)} />
      </td>
      <td className="align-middle">{item.title}</td>
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonEdit onEdit={() => onEdit(index, item.id)} />
      </td>
    </tr>
  );
}

export default TableColumnStatuses;
