import React from "react";
import { OrderTableFooterButton } from "../common/Buttons";
import { RiArrowRightLine } from "react-icons/ri";

function BasicModalOrder(props) {
  const exportInvoice = () => {
    props.exportInvoice();
  };

  const importInvoice = () => {
    props.importInvoice();
  };

  const forceExportInvoice = () => {
    props.forceExportInvoice();
  };

  const waitForElement = (id, callback) => {
    var poops = setInterval(function () {
      if (document.getElementById(id)) {
        clearInterval(poops);
        callback();
      }
    }, 100);
  };

  if (props.export == "new") {
    waitForElement("invoiceExportBtn" + props.invoiceId, function () {
      document.getElementById(
        "invoiceExportBtn" + props.invoiceId
      ).style.display = "block";
    });
    waitForElement("invoiceImportBtn" + props.invoiceId, function () {
      document.querySelector(".force-export-" + props.invoiceId).style.display =
        "block";
    });
  }

  if (props.export == "exporting") {
    waitForElement("invoiceImportBtn" + props.invoiceId, function () {
      document.getElementById(
        "invoiceImportBtn" + props.invoiceId
      ).style.display = "block";
    });
    waitForElement("invoiceImportBtn" + props.invoiceId, function () {
      document.querySelector(".force-export-" + props.invoiceId).style.display =
        "block";
    });
  }

  return (
    <div
      className="modal fade"
      id={props.status}
      tabIndex="-1"
      role="dialog"
      // aria-labelledby={props.status}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-edit modal-editOrders" role="document">
        <div className="modal-content px-2 py-2" style={{ height: "100vh" }}>
          <div className="col">
            <div className="row">
              <div className="col-md-6">
                <span
                  className={`font-weight-bold d-block modal-title ${
                    props.color ? props.color : "text-orange"
                  }`}
                >
                  {props.text}
                </span>
                {props.notes}
              </div>
              <div className="col-md-6">
                {(props.url == "/rivile-pardavimai" &&
                  (props.export == "exporting" ||
                    props.export == "exported")) ||
                props.export == "exported" ? (
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                ) : props.export == "exporting" ? (
                  <>
                    <button
                      type="button"
                      className="close float-right"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <button
                      id={`invoiceImportBtn${props.invoiceId}`}
                      className="btn btn-sm btn-dark float-right mr-3"
                      onClick={() => importInvoice()}
                    >
                      Importuoti
                    </button>
                    <button
                      className={`force-export-${props.invoiceId} btn btn-sm float-right mr-3 d-flex justify-content-between`}
                      style={{ backgroundColor: "#f7941d", color: "white" }}
                      onClick={() => forceExportInvoice()}
                    >
                      <span>Perkelti sąskaitą</span>
                      <span className="ml-1">
                        <RiArrowRightLine color="white" />
                      </span>
                    </button>
                  </>
                ) : props.export == "new" ? (
                  <>
                    <button
                      type="button"
                      className="close float-right"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <button
                      id={`invoiceExportBtn${props.invoiceId}`}
                      className="btn btn-sm btn-dark float-right mr-3"
                      onClick={() => exportInvoice()}
                    >
                      Exportuoti
                    </button>
                    <button
                      className={`force-export-${props.invoiceId} btn btn-sm float-right mr-3 d-flex justify-content-between`}
                      style={{ backgroundColor: "#f7941d", color: "white" }}
                      onClick={() => forceExportInvoice()}
                    >
                      <span>Perkelti sąskaitą</span>
                      <span className="ml-1">
                        <RiArrowRightLine color="white" />
                      </span>
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div
            className="modal-body"
            style={{ height: "100vh", overflow: "auto" }}
          >
            {props.children}
          </div>
          {props.bottoms && (
            <div className="modal-footer justify-content-center">
              <OrderTableFooterButton
                color="#ee3624"
                text="Išsaugoti"
                handleSave={props.handleSave}
              />

              <OrderTableFooterButton
                color="silver"
                text="Uždaryti"
                handleSave={() => {}}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BasicModalOrder;
