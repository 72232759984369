import React from "react";
import * as Sentry from '@sentry/browser';
import { render } from "react-dom";
import Index from "../routes/Index";

document.addEventListener("DOMContentLoaded", () => {
  if(env == 'production') {
    Sentry.init({dsn: "https://bde8217312284f54a55cd9f2223cb1fe@sentry.mijora.lt/6"});
  }
 
  render(<><Index /></>, document.body.appendChild(document.createElement("div")));
});
