import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { addOverFlow, removeOverFlow } from "../../helpers/modalFunc";
import ModalSpecial from "../Layout/Modals/ModalSpecial";
import LeasingModal from "./LeasingModal";

function LeasingModalWrapper({
  leasingSelected,
  closeModal,
  setLeasingSelected,
}) {
  useEffect(() => {
    if (leasingSelected) {
      addOverFlow();
    }
  }, []);

  const onClose = () => {
    closeModal();
    removeOverFlow();
  };

  if (leasingSelected) {
    return ReactDOM.createPortal(
      <ModalSpecial
        style={{
          width: 750,
        }}
        onClose={onClose}
      >
        <LeasingModal
          leasing_id={leasingSelected}
          setLeasingSelected={setLeasingSelected}
          onClose={onClose}
        />
      </ModalSpecial>,
      document.getElementById("custom_modal")
    );
  }
  return null;
}

export default LeasingModalWrapper;
