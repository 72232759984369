import React from "react";
import Container from "../Layout/Container";
import { connect } from "react-redux";
import {
  getManagers,
  updateManager,
  getManager,
  clearErrors,
  syncPayModule,
  syncPayModuleRivile,
} from "../../redux/Actions/payModuleAction";
import ManagersEditModal from "./Items/ManagersEditModal";
import ContentBlock from "../Layout/ContentBlock";
import TableColumnManagers from "./Items/TableColumnManagers";
import FilterMenu from "../Layout/FilterMenu";
import TableFilterBox from "../common/TableFilterBox";
import ContentValidation from "../common/ContentValidation";
import PaginationComp from "../Dashboard/items/Pagination";
import RangePickerComp from "../common/RangePickerComp";
import moment from "moment";
import Axios from "axios";
import { Upload, message } from "antd";
import { ClassicButton } from "../common/ButtonWithLoading";
import InputText from "../orders/accepted_orders/InputFilter";
import { removeOverFlow } from "../../helpers/modalFunc";
import { AiOutlineCloudSync } from "react-icons/ai";
import { ExportDocumentButton } from "../common/Buttons";

class PayModule extends React.Component {
  constructor() {
    super();
    this.state = {
      requiredId: 0,
      page: 1,
      pageCount: 100,
      paramsFilter: "",
      action: "new",
      excel_loading: false,
      loading_plan_with_fact: false,
      filter: {
        sales_date: [
          moment().format("YYYY-MM-01"),
          // "2020-05-01",
          moment().subtract(1, "d").format("YYYY-MM-DD"),
        ],
        manager_id: "",
        firstname: "",
        lastname: "",
        department: "",
      },
    };
  }

  componentDidMount() {
    this.onFilter();
  }
  componentDidUpdate(_, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.props.getManagers(
        this.state.page,
        this.state.pageCount,
        this.state.paramsFilter
      );
    }
  }

  onAction = (action, index) => {
    this.setState({
      requiredId: index,
      action: action,
    });
    this.props.getManager(index, this.state.paramsFilter);
  };

  setPagination = (page) => {
    this.setState({
      page,
    });
  };

  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  renderCustomButton = () => {
    return (
      <>
        {this.props.user.user_role.identifier == "admin" && (
          <button
            className="btn btn-sm btn-dark float-right mr-3"
            onClick={() => syncPayModule()}
          >
            Algų XML SYNC
          </button>
        )}
        {this.props.user.user_role.identifier == "admin" && (
          <button
            className="btn btn-sm btn-dark float-right mr-3"
            onClick={() => syncPayModuleRivile()}
          >
            Algų Rivilė SYNC
          </button>
        )}
        <div className="dropdown">
          <button
            className="btn btn-sm btn-dark float-right mr-3 dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Eksportuoti
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <ExportDocumentButton
              api_url={`/api/v1/managers/export_calculated_sales?${this.state.paramsFilter}`}
              file_name={"Pardavimai.xlsx"}
              method="POST"
            >
              Pardavimai
            </ExportDocumentButton>
            <ExportDocumentButton
              api_url={'/api/v1/managers/export_plan_with_fact_template"'}
              file_name={`plano_fakto_šablonas.xlsx`}
              method={"POST"}
            >
              Plano ir fakto šablonas
            </ExportDocumentButton>
          </div>
        </div>
      </>
    );
  };

  renderImportButton = () => {
    const props = {
      name: "file",
      action: `/api/v1/managers_documents/`,
      showUploadList: false,
      beforeUpload: this.beforeUpload,
      onChange: this.onChange,
    };

    return (
      <>
        <Upload {...props} className="float-right">
          <ClassicButton
            loading={this.state.excel_loading}
            text="Importuoti planus"
            customClasses="btn btn-sm btn-dark float-right mr-3 null-styles"
          />
        </Upload>
      </>
    );
  };

  beforeUpload = (file) => {
    const isExcel =
      file.type.includes("application") && file.name.split(".")[1] === "xlsx";
    if (!isExcel) {
      message.error("Failas privalo būti .xlsx formato!");
    } else {
      this.setState({
        // start loading
        excel_loading: true,
      });
    }

    return isExcel;
  };

  onChange = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} failas sėkmingai įkeltas!`);
      this.setState({
        // stop loading
        excel_loading: false,
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} failo įkėlimas nepavyko!`);
      this.setState({
        // stop loading
        excel_loading: false,
      });
    }
  };

  exportPlanWithFact = () => {
    this.setState({
      loading_plan_with_fact: true,
    });
    let url;
    url = `/api/v1/managers/export_plan_with_fact?${this.onFilterForPlanFact()}`;
    Axios({
      url: url,
      method: "POST",
      responseType: "blob", // important
      headers: { Accept: "application/vnd.ms-excel" },
    }).then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", `${"planas_faktas"}.xlsx`); //any other extension

      document.body.appendChild(link);

      link.click();

      this.setState({
        loading_plan_with_fact: false,
      });
      link.remove();
    });
  };

  clearFilter = () => {
    this.setState(
      {
        paramsFilter: "",
        filter: {
          sales_date: ["", ""],
          manager_id: "",
          firstname: "",
          lastname: "",
          department: "",
        },
      },
      () => this.reloadData("")
    );
  };

  getDateFormat = (date) => {
    var d = moment(date);
    return date && d.isValid() ? d : "";
  };

  filterChange = (e, date) => {
    if (date) {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            [date]: e,
          },
        },
        () => this.onFilter()
      );
    } else {
      this.setState({
        filter: {
          ...this.state.filter,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  handleKeyDown = (e) => {
    if (e.key == "Enter") {
      this.onFilter();
    }
  };

  delayOnFilter = () => {
    if (this.props.loading_managers) {
      return setTimeout(() => {
        return this.delayOnFilter();
      }, 1000);
    }
    this.onFilter();
  };

  onFilter = () => {
    let filterParams = [];

    if (this.props.loading_managers) {
      return this.delayOnFilter();
    }

    if (this.state.filter.sales_date[0].length > 0) {
      filterParams.push(
        `&q[managers_sales_operation_date_gteq]=${this.state.filter.sales_date[0]}`
      );
    }

    if (this.state.filter.sales_date[1].length > 0) {
      filterParams.push(
        `&q[managers_sales_operation_date_lteq]=${this.state.filter.sales_date[1]}`
      );
    }

    if (this.state.filter.firstname.length > 0) {
      filterParams.push(`&q[firstname_cont]=${this.state.filter.firstname}`);
    }

    if (this.state.filter.lastname.length > 0) {
      filterParams.push(`&q[lastname_cont]=${this.state.filter.lastname}`);
    }

    if (this.state.filter.department.length > 0) {
      filterParams.push(`&q[department_cont]=${this.state.filter.department}`);
    }

    if (this.state.filter.manager_id.length > 0) {
      filterParams.push(`&q[manager_id_eq]=${this.state.filter.manager_id}`);
    }

    this.setState(
      {
        paramsFilter: filterParams.join(""),
        page: 1,
      },
      () => this.reloadData(filterParams.join(""))
    );
  };

  onFilterForPlanFact = () => {
    let filterParams = [];

    if (this.state.filter.sales_date[0].length > 0) {
      const thisMonth = moment(this.state.filter.sales_date[0]).format(
        "YYYY-MM-01"
      );
      filterParams.push(`&q[managers_sales_operation_date_gteq]=${thisMonth}`);
      filterParams.push(
        `&q[managers_sales_operation_date_lteq]=${moment(thisMonth)
          .endOf("month")
          .format("YYYY-MM-DD")}`
      );
    } else {
      filterParams.push(
        `&q[managers_sales_operation_date_gteq]=${moment().format(
          "YYYY-MM-01"
        )}`
      );
      filterParams.push(
        `&q[managers_sales_operation_date_lteq]=${moment()
          .endOf("month")
          .format("YYYY-MM-DD")}`
      );
    }

    if (this.state.filter.firstname.length > 0) {
      filterParams.push(`&q[firstname_cont]=${this.state.filter.firstname}`);
    }

    if (this.state.filter.lastname.length > 0) {
      filterParams.push(`&q[lastname_cont]=${this.state.filter.lastname}`);
    }

    if (this.state.filter.department.length > 0) {
      filterParams.push(`&q[department_cont]=${this.state.filter.department}`);
    }

    if (this.state.filter.manager_id.length > 0) {
      filterParams.push(`&q[manager_id_eq]=${this.state.filter.manager_id}`);
    }
    return filterParams.join("");
  };

  reloadData = (data) => {
    this.props.getManagers(this.state.page, this.state.pageCount, data);
  };

  render() {
    return (
      <Container
        location={{
          locationName: "Algų modulis",
          locationUrl: this.props.match.url,
        }}
      >
        <ContentBlock>
          <FilterMenu
            length={`${this.props.totals || 0} / ${
              this.props.managers.length || 0
            }`}
            onChangeCountPage={this.setPageCount}
            countPage={this.state.pageCount}
            text="menedžerių"
            text2="Menedžerių"
          />
        </ContentBlock>
        <ContentBlock>
          <TableFilterBox
            clearFilter={() => this.clearFilter()}
            button1={this.renderCustomButton()}
            importPayModulePlans={this.renderImportButton()}
          >
            <div className="d-flex justify-content-between flex-1 flex-wrap">
              <div className="d-flex flex-wrap">
                <div className="px-1" style={{ width: "20rem" }}>
                  <div className="form-group">
                    <label className="editLabelForm">Data:</label>
                    <div>
                      <RangePickerComp
                        placeholder={["Nuo", "Iki"]}
                        value={[
                          this.getDateFormat(this.state.filter.sales_date[0]),
                          this.getDateFormat(this.state.filter.sales_date[1]),
                        ]}
                        onChange={(e, args) =>
                          this.filterChange(args, "sales_date")
                        }
                        disabledDate={(current) => {
                          // Can not select days after today and today
                          return current > moment().subtract(1, "days");
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="px-1">
                  <InputText
                    style={{ width: 150, fontSize: "0.75rem" }}
                    number="1"
                    name="firstname"
                    placeholder="Vardas"
                    value={this.state.filter.firstname}
                    onKeyDown={this.handleKeyDown}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
                <div className="px-1">
                  <InputText
                    style={{ width: 150, fontSize: "0.75rem" }}
                    number="1"
                    name="lastname"
                    placeholder="Pavardė"
                    value={this.state.filter.lastname}
                    onKeyDown={this.handleKeyDown}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
                <div className="px-1">
                  <InputText
                    style={{ width: 150, fontSize: "0.75rem" }}
                    number="1"
                    name="department"
                    placeholder="Padalinys"
                    value={this.state.filter.department}
                    onKeyDown={this.handleKeyDown}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
                <div className="px-1">
                  <InputText
                    style={{ width: 150, fontSize: "0.75rem" }}
                    number="1"
                    name="manager_id"
                    placeholder="Menedžerio kodas"
                    value={this.state.filter.manager_id}
                    onKeyDown={this.handleKeyDown}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-start px-1">
                {this.props.last_sync_sale_day && (
                  <div className="d-flex align-items-center">
                    <AiOutlineCloudSync
                      className="mr-2"
                      color={"#C3C3C3"}
                      size={28}
                    />
                    <span style={{ color: "#C3C3C3", fontSize: 17 }}>
                      <b>Dienos:</b>{" "}
                      {moment(this.props.last_sync_sale_day).format(
                        "YYYY-MM-DD HH:mm"
                      )}
                    </span>
                  </div>
                )}
                {this.props.last_sync_sale_month && (
                  <div className="d-flex align-items-center ml-2">
                    <span style={{ color: "#C3C3C3", fontSize: 17 }}>
                      <b>Mėnesio:</b>{" "}
                      {moment(this.props.last_sync_sale_month).format(
                        "YYYY-MM-DD HH:mm"
                      )}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </TableFilterBox>
          <div>
            <ContentValidation
              loader={this.props.loading_managers}
              array={this.props.managers}
              alertId_s="managerAlert-s"
              alertId_e="managerAlert-e"
            >
              <div className="table-responsive">
                <table className="table table-sm table-hover mt-3">
                  <thead>
                    <tr className="text-muted">
                      {/* <th className="text-black">Numeris</th> */}
                      <th className="text-black">Menedžerio kodas</th>
                      <th className="text-black">Vardas</th>
                      <th className="text-black">Pavardė</th>
                      <th className="text-black">Padalinys</th>
                      {/* <th className="text-black">Dirba nuo</th> */}
                      {/* <th className="text-black">Statusas</th> */}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.managers.map((item, index) => {
                      return (
                        <TableColumnManagers
                          key={index}
                          index={index}
                          id={item.id}
                          item={item}
                          onAction={this.onAction}
                        />
                      );
                    })}
                  </tbody>
                </table>
                <PaginationComp
                  setPagination={this.setPagination}
                  page={this.state.page}
                  totals={this.props.totals}
                  pageCount={this.state.pageCount}
                />
              </div>
            </ContentValidation>
          </div>
        </ContentBlock>
        {this.state.action == "edit" && (
          <ManagersEditModal
            action={this.state.action}
            updateManager={this.props.updateManager}
            objectData={this.props.manager}
            clearErrors={this.props.clearErrors}
            errors={this.props.errors}
            onClose={() =>
              this.setState({ action: "" }, () => removeOverFlow())
            }
          />
        )}
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  managers: state.payModule.managers,
  manager: state.payModule.manager,
  last_sync_sale_month: state.payModule.last_sync_sale_month,
  last_sync_sale_day: state.payModule.last_sync_sale_day,
  totals: state.payModule.totals,
  loading_managers: state.payModule.loading_managers,
  errors: state.errors,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getManagers,
  updateManager,
  getManager,
  clearErrors,
})(PayModule);
