import React from "react";
import BasicModalTickets from "../../../Layout/ModalToChangeTickets";
import { OrderTableFooterButton } from "../../../common/Buttons";
import { Upload } from "antd";
import { FiUpload } from "react-icons/fi";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import Axios from "axios";
import { success } from "../../../../redux/Actions/modalsFunctios";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  // const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  // if (!isJpgOrPng) {
  //   message.error("You can only upload JPG/PNG file!");
  // }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  // return isJpgOrPng && isLt2M;
  return isLt2M;
}

class TicketNewModal extends React.Component {
  constructor() {
    super();
    this.state = {
      ticketID: null,
      loading: false,
      ticketsData: [],
    };
  }

  handleChange = (e, select) => {
    if (select) {
      this.setState({
        [select]: e,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  submitDoc = () => {
    this.props.postLotteryTicket(this.state.ticketID).then((response) => {
      if (response.success) {
        this.setState({
          ticketID: null,
          loading: false,
          ticketsData: [],
        });
        success("ticketAlert-s", "closeModal1");
        this.props.getLotteryTicket();
      }
    });
  };

  handleChangeImageUpload = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        const body = {
          lottery_tickets_import: {
            asset: imageUrl,
          },
        };
        Axios.post(`/api/v1/lottery_tickets_imports`, body).then((res) => {
          Axios.get(
            `/api/v1/lottery_tickets_imports/${res.data.id}/lottery_tickets_data`
          ).then((response) => {
            this.setState({
              ticketsData: response.data,
              ticketID: res.data.id,
            });
          });
        });
      });
    }
  };

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  render() {
    const uploadButton = (
      <div>
        {this.state.loading && <LoadingOutlined />}
        <div className="ant-upload-text">
          <FiUpload className="mr-2" /> Įkelkite failą čia.
        </div>
      </div>
    );

    return (
      <BasicModalTickets
        handleSave={(e) => this.onSubmit(e)}
        status="tableNew"
        text={"Bilietų įkėliams"}
        close={"closeModal1"}
      >
        <div
          style={{
            width: "100%",
            border: "1px dashed #d9d9d9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "15px",
            borderRadius: "2px",
            background: "#fafafa",
            cursor: "pointer",
            WebkitTransition: "border-color 0.3s",
            transition: "border-color 0.3s",
          }}
        >
          <Upload
            name="document"
            showUploadList={false}
            customRequest={this.dummyRequest}
            beforeUpload={beforeUpload}
            onChange={this.handleChangeImageUpload}
          >
            {this.state.ticketsData.length > 0 ? (
              <div className="row" style={{fontSize: "9px"}}>
                {this.state.ticketsData.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="col-2">
                        {item.code}
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
        <div className="d-block d-flex justify-content-center mt-3">
          <OrderTableFooterButton
            id={"ticketAddNew"}
            color="#ee3624"
            text="Išsaugoti"
            handleSave={() => this.submitDoc()}
          />
        </div>
      </BasicModalTickets>
    );
  }
}

export default TicketNewModal;
