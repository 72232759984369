import React from "react";
import ModalToChange from "../../../Layout/ModalToChange";
import InputForm from "../../../common/InputForm";
import TextAreaFieldGroup from "../../../common/TextAreaFieldGroup";

class VenipakManifestModal extends React.Component {
  constructor() {
    super();
    this.state = {
      id: 0,
      pickup_address: {},
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.actionId) {
      this.setState({
        id: nextProps.actionId,
      });
    }
    if (nextProps.objectData) {
      this.setState({
        pickup_address: nextProps.objectData.pickup_address,
      });
    }
  }
  onSubmit = (e) => {
    this.props.callCourrierManifest(this.state.id);
  };
  render() {
    const { pickup_address } = this.state;
    return (
      <ModalToChange
        handleSave={(e) => this.onSubmit(e)}
        status="VenipakManifestModal"
        text={"Paėmimo adresas"}
        buttonText={"Kviesti kurjerį"}
      >
        <InputForm
          placeholder="Adresas"
          value={pickup_address.address}
          disabled={true}
        />
        {pickup_address.address2 && (
          <InputForm
            placeholder="Adresas 2"
            value={pickup_address.address2}
            disabled={true}
          />
        )}
        <InputForm
          placeholder="Miestas"
          value={pickup_address.city}
          disabled={true}
        />
        <InputForm
          placeholder="Kodas"
          value={pickup_address.code}
          disabled={true}
        />
        <InputForm
          placeholder="Įmonė"
          value={pickup_address.company}
          disabled={true}
        />
        <InputForm
          placeholder="Kontaktinis asmuo"
          value={pickup_address.contact_person}
          disabled={true}
        />
        {pickup_address.email && (
          <InputForm
            placeholder="El. paštas	"
            value={pickup_address.email}
            disabled={true}
          />
        )}
        <InputForm
          placeholder="Telefonas"
          value={pickup_address.phone}
          disabled={true}
        />
        <InputForm
          placeholder="Darbo laikas"
          value={
            pickup_address.pickup_time_from +
            " - " +
            pickup_address.work_time_to
          }
          disabled={true}
        />
        <InputForm
          placeholder="Pašto kodas	"
          value={pickup_address.postal_code}
          disabled={true}
        />
        {pickup_address.comment && (
          <TextAreaFieldGroup
            placeholder="Komentarai"
            value={pickup_address.comment}
            disabled={true}
          />
        )}
      </ModalToChange>
    );
  }
}

export default VenipakManifestModal;
