import { errorHandler, exportDocument } from "../../helpers/Utils";

export const exportedDocumentAction = (params) => async (_, getState) => {
  try {
    const user_id = getState().auth.user.id;
    if (user_id !== params.user_id) return;
    exportDocument(params.api_url, params.name, "POST");

    console.log({ params });
  } catch (err) {
    errorHandler(err);
    return "";
  }
};
