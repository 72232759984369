import React, { useState, useEffect } from "react";
import ContentValidation from "../../common/ContentValidation";

import {
  getLandingCategories,
  getLandings,
  getLandingShow,
  getLandingTypes,
  putLanding,
} from "../../../redux/Actions/landingAction";
import { Button2, SelectForm } from "../../common/Buttons";
import TextInputModal from "../../common/TextInputModal";
import ContentBlock from "../../Layout/ContentBlock";
import { TiMediaPlayOutline } from "react-icons/ti";
import styled from "styled-components";
import Table from "../../../components/common/TableUi";
import SimplePagination from "../../common/SimplePagination";
import { Switch } from "antd";
import ActiveLanding from "./ActiveLanding";
import ModalSpecial from "../../Layout/Modals/ModalSpecial";
import Spinner from "../../common/Spinner";
import { successToast, unsuccessToast } from "../../../helpers/ErrorsToasts";

export default function GuideSecond() {
  //
  const [modal, setModal] = useState(false);
  //

  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);

  // data
  const [landings, setLandings] = useState([]);
  const [totals, setTotals] = useState([]);
  const [loading, setLoading] = useState(false);
  //

  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
    title: "",
    type: undefined,
    category: null,
    status: undefined,
  });

  useEffect(() => {
    getLandingCategories().then((res) => {
      if (res.success) {
        setCategories(res.data);
        // setSelected(null);
      }
    });
    getLandingTypes().then((res) => {
      if (res.success) {
        setTypes([
          { value: null, label: "Visi" },
          ...res.data.map((e) => ({
            label: e.title,
            value: e.id,
          })),
        ]);
      }
    });
  }, []);
  useEffect(() => {
    onFilter();
  }, [filter.category, filter.type, filter.status]);

  const onChange = (e) => {
    const { value, name } = e.target;
    setFilter((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  const onFilter = async () => {
    const { title, type, category, status } = filter;
    try {
      setLoading(true);
      let searchParams = [];
      if (title) searchParams.push(`&q[title_cont]=${title}`);
      if (type) searchParams.push(`&q[landing_type_id_eq]=${type}`);
      if (category) searchParams.push(`&q[category_id_eq]=${category}`);
      if (status) searchParams.push(`&q[active_true]=${status}`);
      setPage(1);
      const data = await getLandings(1, searchParams.join(""));
      if (data.success) {
        setLandings(data.data);
        setTotals(data.totals);
        setLoading(false);
        return;
      }
      throw new Error("Ups!");
    } catch (err) {
      console.log("Error: ", err);
      setLoading(false);
    }
  };

  const selectCategory = async (value) => {
    onChange({ target: { value, name: "category" } });
  };

  const saveLanding = async (id, landing) => {
    try {
      let { data, success } = await putLanding(id, { landing });
      if (success) {
        setModal(false);
        let old_landings = [...landings];
        const findIndex = old_landings.findIndex((e) => e.id == data.id);
        if (findIndex !== -1) {
          old_landings[findIndex] = data;
        }
        setModal(false);

        successToast("landing-s");
        return setLandings(old_landings);
      }
      throw new Error("Err");
    } catch (err) {
      unsuccessToast("landing-e");
      console.log("Error: ", err);
    }
  };

  return (
    <div>
      <ContentBlock>
        <div className={"bg-white d-flex flex-wrap pl-1 pr-1"}>
          <div className="col-12">
            <div className="row">
              <div className="col-4">
                <TextInputModal
                  text="Pavadinimas"
                  name="title"
                  value={filter.title}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      onFilter();
                    }
                  }}
                  onChange={onChange}
                />
              </div>
              <div className="col-4">
                <SelectForm
                  placeholder="Landingo tipas"
                  onlyPlaceholder="Pasirinkti"
                  value={filter.type}
                  options={types}
                  onChange={(value) =>
                    onChange({ target: { value, name: "type" } })
                  }
                />
              </div>
              <div className="col-4">
                <SelectForm
                  placeholder="Landingo statusas"
                  onlyPlaceholder="Pasirinkti"
                  value={filter.status}
                  options={[
                    { label: "Visi", value: "" },
                    { label: "Aktyvus", value: "true" },
                    { label: "Neaktyvus", value: "false" },
                  ]}
                  onChange={(value) =>
                    onChange({ target: { value, name: "status" } })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </ContentBlock>
      <div className="col-12 mt-2">
        <div className="row">
          <div className="col-4 px-0">
            <ContentBlock>
              <ul className="px-2" style={{ listStyle: "none" }}>
                {categories.map((e) => (
                  <StyledLi
                    key={e.id}
                    active={e.id == filter.category}
                    onClick={() => selectCategory(e.id)}
                  >
                    {e.id == filter.category && (
                      <TiMediaPlayOutline color="#FE9C00" fontSize={14} />
                    )}
                    {e.name}
                  </StyledLi>
                ))}
              </ul>
            </ContentBlock>
          </div>
          <div className="col-8  px-0">
            <ContentBlock>
              <ContentValidation loader={loading} array={landings}>
                <Table ths={["Pavadinimas", "Tipas", "Statusas"]}>
                  {landings?.map((e) => (
                    <Table.tr key={e.id}>
                      <Table.td className="p-left">{e.title}</Table.td>
                      <Table.td>{e.landing_type?.title}</Table.td>
                      <Table.td>
                        <div>
                          <Switch
                            checkedChildren={"I"}
                            unCheckedChildren={"O"}
                            checked={e.active}
                            onChange={() => setModal(e.id)}
                          />
                        </div>
                      </Table.td>
                    </Table.tr>
                  ))}
                </Table>
              </ContentValidation>
            </ContentBlock>

            <SimplePagination
              setPagination={(pg) => setPage(pg)}
              pageCount={10}
              page={page}
              totals={totals}
            />
          </div>
        </div>
      </div>
      {typeof modal == "number" && (
        <ModalSpecial
          style={{ width: "600px!important" }}
          text={"Keisti statusą"}
          onClose={() => setModal(false)}
        >
          <StatusChange saveLanding={saveLanding} id={modal} />
          {/* <ServiceModalComponent id={modalInfo.id} /> */}
        </ModalSpecial>
      )}
    </div>
  );
}

const StatusChange = ({ saveLanding, id }) => {
  const [loading, setLoading] = useState(true);

  const [state, setState] = useState({
    active: false,
    from_active: false,
    from_at: "",
    to_active: false,
    to_at: "",
  });
  useEffect(() => {
    if (id) {
      getLandingShow(id).then((res) => {
        if (res.success) {
          const { active, from_active, from_at, to_active, to_at } = res.data;
          setState({
            from_active,
            from_at: from_at || "",
            to_active,
            to_at: to_at || "",
            active,
          });
        }
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [id]);
  if (loading) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "100%" }}
      >
        <Spinner />
      </div>
    );
  }
  const updateActive = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };
  const onChangeState = (e) => {
    const { name, value } = e.target;
    setState((prevProps) => ({ ...prevProps, [name]: value }));
  };
  return (
    <div>
      <div className="d-flex align-items-center mb-2">
        <Switch
          checkedChildren={"I"}
          unCheckedChildren={"O"}
          checked={state.active}
          onChange={(value) =>
            onChangeState({ target: { name: "active", value } })
          }
        />
        <span className="ml-2">Aktyvus</span>
      </div>
      {!!state.active && (
        <ActiveLanding
          updateActive={updateActive}
          active={state.active}
          state={{
            from_active: state.from_active,
            from_at: state.from_at,
            to_active: state.to_active,
            to_at: state.to_at,
          }}
        />
      )}
      <div className="mt-2 d-flex justify-content-end">
        <Button2 onClick={() => saveLanding(id, state)} text={"Saugoti"} />
      </div>
    </div>
  );
};

const StyledLi = styled.li`
  cursor: pointer;
  font-size: 14px;
  color: ${(props) => (props.active ? "#FE9C00" : "#A6A6A6")};
  font-weight: ${(props) => (props.active ? 500 : 400)};
  &:hover {
    color: #fe9c00;
    font-weight: 500;
  }
`;
