import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { SelectForm } from "../../../../../common/Buttons";
import { Input, Alert } from "antd";
import { phonenumberValid } from "../../../../../../helpers/Utils";
import { createSmsItem } from "../../../../../../redux/Actions/orderAction";
import { ClassicButton } from "../../../../ButtonWithLoading";
import { getSmsItemsOption } from "../../../../../../redux/Actions/smsAction";

const { TextArea } = Input;

function SmsSending({
  disabled = false,
  id,
  shipment_id,
  phoneNumber,
  shipping_address_street,
  orderId,
  shipments,
  custom_function,
  custom_sms_sendings,
}) {
  let sms_sendings = useSelector((state) => state.orders.sms_sendings);
  const sms_items = custom_sms_sendings || sms_sendings;
  const [smsData, setSmsData] = useState([]);
  const [bodyText, setBodyText] = useState("");
  const [bodyTextID, setBodyTextID] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSmsItemsOption().then((response) => {
      if (response.data) {
        let newSmsItem = [];
        response.data.data.forEach((item) => {
          return newSmsItem.push({
            value: item.body,
            label: item.message,
            identifier: item.identifier,
          });
        });
        setSmsData(newSmsItem);
        if (shipment_id) {
          const find_with_pickup_sms = newSmsItem.find(
            (e) => e.identifier == "pickup_sms"
          );
          if (find_with_pickup_sms) {
            setSmsText(find_with_pickup_sms.value, find_with_pickup_sms);
          }
        }
      }
    });
  }, []);

  const [alertText, setAlertText] = useState({
    success: "success",
    text: "",
    show: false,
  });
  const dispatch = useDispatch();

  const sendSms = () => {
    setLoading(true);
    const variables = {
      body: bodyText,
      number: phoneNumber,
    };
    let func = dispatch(createSmsItem(id, variables, shipment_id));
    if (custom_function) {
      func = custom_function(id, variables, shipment_id);
    }

    func.then((res) => {
      if (res.success) {
        setLoading(false);
        setAlertText({
          success: "success",
          text: "Sėkmingai išsiųsta sms žinutė.",
          show: true,
        });
        setBodyText("");
      } else {
        setLoading(false);
        setAlertText({
          success: "error",
          text: "Įvyko klaida atnaujinant.",
          show: true,
        });
      }
    });
  };

  const setSmsText = (evt, arg) => {
    let value = evt;
    if (value.search("[SHOP]") !== -1) {
      value = value.split("#[SHOP]").join(shipping_address_street);
    }
    if (value.search("[uzsakymo nr]") !== -1) {
      value = value.split("#[uzsakymo nr]").join(orderId);
    }
    if (value.search("[tracking link]") !== -1) {
      let courier_trackings = [];
      let courier_trackings_number = [];
      shipments.forEach((ship) => {
        ship.courier_trackings.forEach((track) => {
          courier_trackings.push(track.url);
          courier_trackings_number.push(track.number);
        });
      });
      if (courier_trackings.length === 1) {
        value = value.split("#[tracking link]").join(courier_trackings[0]);
        if (value.search("[tracking number]")) {
          value = value
            .split("#[tracking number]")
            .join(courier_trackings_number[0]);
        }
      } else if (courier_trackings.length > 1) {
        value = value
          .split("#[tracking link]")
          .join(courier_trackings.join(", "));
        if (value.search("[tracking number]")) {
          value = value
            .split("#[tracking number]")
            .join(courier_trackings_number.join(", "));
        }
      }
    }

    setBodyTextID(arg.value);
    setBodyText(value);
  };

  return (
    <div className="col-12 bg-silver">
      {alertText.show && (
        <Alert
          className="mt-2"
          message={alertText.text}
          type={alertText.success}
          showIcon
        />
      )}
      <div className="pt-2 mb-2">
        <SelectForm
          disabled={disabled}
          onlyPlaceholder="Šablonai"
          options={smsData}
          onChange={(evt, arg) => setSmsText(evt, arg)}
          value={bodyTextID}
        />
      </div>
      <div>
        <div style={{ width: "100%" }}>
          <TextArea
            disabled={disabled}
            placeholder="Tekstas"
            value={bodyText}
            autoSize={{ minRows: 2, maxRows: 6 }}
            onChange={(e) => setBodyText(e.target.value)}
          />
        </div>
        <div
          style={{
            width: "100%",
            marginTop: "5px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          {!disabled && phonenumberValid(phoneNumber) && (
            <ClassicButton loading={loading} text="Siųsti" onClick={sendSms} />
          )}
        </div>
      </div>
      {!shipment_id && (
        <table className="table table-hover mt-3">
          {sms_items
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            .map((item, index) => {
              return (
                <tbody key={index}>
                  <tr>
                    <td style={{ width: "20%" }}>
                      {moment(item.created_at).format("YYYY-MM-DD HH:mm")}
                      <br />
                      <div>{item.user_title}</div>
                    </td>
                    <td>{item.body}</td>
                  </tr>
                </tbody>
              );
            })}
        </table>
      )}
    </div>
  );
}

export default SmsSending;
