import React from "react";
import { ButtonEdit, CheckBoxInput } from "../common/Buttons";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { GoPrimitiveDot } from "react-icons/go";
import { ButtonDelete } from "../common/Buttons";
import moment from "moment";
import { Tooltip } from "antd";
import { removeReport } from "../../redux/Actions/reportAction";
import { useDispatch } from "react-redux";

function TableColumnReport({
  item,
  index,
  selected,
  handleCheckboxChange,
  show_more,
}) {
  const dispatch = useDispatch();
  let current_week_day = "";
  const date_splited = item.date?.split(" ") || ["-", "-"];
  if (item.period == "month") {
    const date = new Date();
    const today = date.getDate();
    date.setDate(item.date ? date_splited[0] : "01");
    if (+date_splited[0] < today) {
      date.setMonth(date.getMonth() + 1);
    }
    current_week_day = moment(date).day();
  } else {
    current_week_day = date_splited[0][1];
  }

  // const showDownload = () => {
  //   if (item.sales_asset.url) return true;
  //   if (item.residues_asset.url) return true;
  //   return false;
  // };
  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle">
        <div className="d-flex">
          <CheckBoxInput
            type="checkbox"
            color="#f7951e"
            checked={selected}
            onChange={handleCheckboxChange}
          />
        </div>
      </td>
      <td className="align-middle">
        {item.active ? (
          <AiOutlineCheck color={"#10CA00"} />
        ) : (
          <AiOutlineClose color="#E61D01" />
        )}
      </td>
      <td className="align-middle">
        <span
          className="cursor-pointer text-primary"
          onClick={() => show_more()}
        >
          {item.method == "auto" ? "Automatinis" : "Rankinis"}
        </span>
      </td>
      <td className="align-middle">
        {item.report_type == "sales"
          ? "Pardavimai"
          : item.report_type == "residues"
          ? "likučiai"
          : "Pardavimai/Likučiai"}
      </td>
      <td className="align-middle">
        <div>{item.supplier?.code}</div>
        <div>{item.supplier?.name}</div>
      </td>
      <td className="align-middle">{item.family?.name}</td>
      <td className="align-middle">{item.brand?.title}</td>
      <td className="align-middle">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 3,
          }}
        >
          {item.departments.map((d, idx) => (
            <div
              key={`${d}_${idx}`}
              className="d-flex align-items-center justify-content-center cursor-pointer text-sm"
              style={{
                backgroundColor: "#F5F5F5",
                borderRadius: 2,
                padding: "2px 3px 2px 4px",
              }}
            >
              {d}
            </div>
          ))}
        </div>
      </td>
      <td className="align-middle">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(1, 1fr)",
            gap: 3,
          }}
        >
          {item.emails.map((d, idx) => (
            <div
              key={`${d}_${idx}`}
              className="d-flex align-items-center justify-content-center cursor-pointer text-sm"
              style={{
                backgroundColor: "#F5F5F5",
                borderRadius: 2,
                padding: "2px 3px 2px 4px",
              }}
            >
              {d}
            </div>
          ))}
        </div>
      </td>
      <td className="align-middle">
        <Tooltip
          title={
            item.period == "month"
              ? "Mėnesis"
              : item.period == "week"
              ? "Savaitė"
              : "Kita"
          }
          placement="top"
        >
          <span
            className="d-flex align-items-center justify-content-center badge badge-pill badge-primary"
            style={{
              width: 20,
              height: 20,
            }}
          >
            {item.period == "month" ? "M" : item.period == "week" ? "S" : "K"}
          </span>
        </Tooltip>
      </td>
      <td className="align-middle">
        {item.period == "month" ? date_splited[0] : "-"}
      </td>
      <td className="align-middle d-flex py-0">
        {item.method == "auto" &&
          [...new Array(7)].map((_, idx) => (
            <RenderWeekDay
              key={idx}
              current_week_day={current_week_day}
              idx={idx + 1}
            />
          ))}
      </td>

      <td className="align-middle">{date_splited[1]}</td>
      <td className="align-middle">
        {/* {showDownload() && (
          <Tooltip
            placement="top"
            title={
              <div className="d-flex flex-wrap flex-column align-items-center">
                Parsisiųsti ataskaitas
                <div className="d-flex">
                  {item.residues_asset.url && (
                    <>
                      <div
                        className="text-orange cursor-pointer white-hover font-semi-bold ml-1"
                        onClick={() => {
                          const file_name = getUrlExtension(
                            item.residues_asset.url || "/none",
                            false
                          );
                          exportDocument(
                            `/api/v1/reports/${item.id}/download_residues`,
                            `${file_name}`,
                            "POST"
                          );
                        }}
                      >
                        Likučiai
                      </div>
                      {"all" == item.report_type && (
                        <div className="mx-1">/</div>
                      )}
                    </>
                  )}
                  {item.sales_asset.url && (
                    <>
                      <div
                        className="text-orange cursor-pointer white-hover font-semi-bold ml-1"
                        onClick={() => {
                          const file_name = getUrlExtension(
                            item.sales_asset.url || "/none",
                            false
                          );
                          exportDocument(
                            `/api/v1/reports/${item.id}/download_sales`,
                            `${file_name}`,
                            "POST"
                          );
                        }}
                      >
                        Pardavimai
                      </div>
                    </>
                  )}
                </div>
              </div>
            }
          >
            <div
              className="d-flex align-items-center justify-content-center rounded mr-2 bg-white"
              style={{
                border: "1px solid #FE9C00",
                width: 33,
                height: 33,
              }}
            >
              <FaDownload className="text-sm" color="#FE9C00" />
            </div>
          </Tooltip>
        )} */}
        <div className="d-flex justify-content-end">
          <ButtonEdit onEdit={() => show_more()} />
          <div className="mx-2"></div>
          <ButtonDelete
            onDelete={() => {
              if (confirm("Ar tikrai norite ištrinti?")) {
                dispatch(removeReport(item.id));
              }
            }}
          />
        </div>
      </td>
    </tr>
  );
}

const RenderWeekDay = ({ current_week_day, idx }) => {
  return (
    <div
      className="py-3 text-center"
      style={{
        background: idx % 2 == 0 ? "none" : "rgba(214, 214, 214, 0.13)",
        flex: 1,
        minWidth: 25,
      }}
    >
      <GoPrimitiveDot color={current_week_day == idx ? "#10CA00" : "#E61D01"} />
    </div>
  );
};

export default TableColumnReport;
