import React from "react";
import InputForm from "../../common/InputForm";
import ModalToChange from "../../Layout/ModalToChange";
import TextAreaFieldGroup from "../../common/TextAreaFieldGroup";

class SmsEditModal extends React.Component {
  constructor() {
    super();
    this.state = {
      id: 0,
      body: "",
      message: "",
      identifier: "",
      action: "new",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.action === "new" && this.state.action === "edit") {
      this.setState({
        body: "",
        message: "",
        identifier: "",
        action: "new",
      });
      this.removeErrors(nextProps.errors);
    } else if (nextProps.action === "edit" && nextProps.objectData.id !== this.state.id) {
      let item = nextProps.objectData;
      this.setState({
        id: item.id || 0,
        body: item.body || "",
        message: item.message || "",
        identifier: item.identifier || "",
        action: "edit",
      });
      this.removeErrors(nextProps.errors);
    }
  }

  removeErrors = (errors) => {
    if (Object.keys(errors).length > 0) {
      this.props.clearErrors();
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const variables = {
      sms_template: {
        body: this.state.body,
        message: this.state.message,
        identifier: this.state.identifier,
      },
    };
    if (this.props.action == "new") {
      this.props.postSmsItem(variables);
    } else if (this.props.action === "edit") {
      this.props.updateSmsItem(this.state.id, variables);
    }
  };

  render() {
    return (
      <ModalToChange
        handleSave={(e) => this.onSubmit(e)}
        status="tableEdit"
        text={this.props.action === "new" ? "Kurti naują" : "Redaguoti"}
        buttonText={this.props.action === "new" ? "Sukurti" : "Atnaujinti"}
      >
        <InputForm
          type="text"
          placeholder="Pavadinimas"
          name="message"
          value={this.state.message}
          error={
            this.props.errors.message &&
            this.props.errors.message.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        <TextAreaFieldGroup
          type="text"
          placeholder="Žinutė"
          name="body"
          value={this.state.body}
          error={
            this.props.errors.body &&
            this.props.errors.body.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChange(e)}
        />
        
        <InputForm
          type="text"
          placeholder="Identifikatorius"
          name="identifier"
          value={this.state.identifier}
          onChange={(e) => this.handleChange(e)}
        />
      </ModalToChange>
    );
  }
}

export default SmsEditModal;
