import React from "react";
import { Select, Spin } from "antd";
import useFetch from "../../helpers/useFetch";
const { Option } = Select;

export default function SelectInputAsync({
  label,
  required = false,
  placeholder = "",
  style = false,
  onChange,
  value,
  disabled = false,
  api_url,
  rebuildArray,
  mode = "normal",
  extra_label,
  warning,
}) {
  const { loading, error, value: res_data } = useFetch(api_url);
  const options = loading || error ? [] : rebuildArray(res_data);
  return (
    <>
      {label && (
        <div className="d-flex justify-content-between align-items-center">
          <label
            className={`editLabelForm font-medium text-sm my-1 ${
              error || warning ? "text-danger" : ""
            }`}
          >
            {label} {required && <span className="orange_color">*</span>}
          </label>
          <div>{extra_label}</div>
        </div>
      )}
      <Select
        showSearch
        mode={mode}
        className={error || warning ? "error_border" : ""}
        style={style || { width: "100%" }}
        placeholder={placeholder || "Pasirinkti"}
        optionFilterProp="children"
        onChange={onChange}
        value={value || undefined}
        size="default"
        disabled={disabled}
        dropdownMatchSelectWidth={false}
        notFoundContent={loading ? <Spin size="small" /> : null}
      >
        {!loading && options
          ? [
              {
                label: `No ${label?.toLowerCase() || "item"}`,
                value: "",
              },
              ...options,
            ].map((item, index) => (
              <Option key={index} value={item.value} {...item}>
                {item.preview || item.label}
              </Option>
            ))
          : []}
      </Select>
      {error && <div className="text-danger">{JSON.stringify(error)}</div>}
      {warning && <div className="text-danger">{warning}</div>}
    </>
  );
}
