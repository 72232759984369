import React from "react";
import { Input } from "antd";
import { Label } from "../../global";

const InputText = ({
  width,
  name,
  number,
  placeholder,
  onChange,
  blockSize,
  onKeyDown,
  value,
  style,
  error,
  readonly = false,
  label = false,
  disabled = false,
  type = "text",
}) => {
  return (
    <div className="form-group" style={{ width: `${blockSize}` }}>
      <div>
        <Label error={error}>{label ? label : placeholder}</Label>
      </div>
      <Input
        type={type}
        name={name}
        className="form-control"
        id={`${name + number}`}
        placeholder={placeholder}
        style={style || { fontSize: `${width}` }}
        onKeyDown={onKeyDown}
        value={value}
        onChange={onChange}
        readOnly={readonly}
        disabled={disabled}
      />
    </div>
  );
};

export default InputText;
