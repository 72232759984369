import React, { useState } from "react";
import TextInputModal from "../../common/TextInputModal";
import { Input } from "antd";
const { TextArea } = Input;
import EmailBlockEach from "../../common/GlobalModals/Orders/editModal/AdditionalServices/EmailBlockEach";
import { Button2, Button1 } from "../../common/Buttons";
import { onChangeUseState2 } from "../../../helpers/GlobalFunctions";
import { useDispatch } from "react-redux";
import { sendContractEmailTest } from "../../../redux/Actions/orderContractAction";
import styled from "styled-components";
import { useEffect } from "react";

export default function SendContractEmail({
  contract_emails,
  contract,
  setValues = (val) => {
    console.log(val);
  },
}) {
  const dispatch = useDispatch();
  const [variables, setVariables] = useState({
    receiver_email: "",
    subject: "",
    body: "",
  });

  const [preview, setPreview] = useState(false);

  useEffect(() => {
    if (!preview) return;

    const textariaText = `Gerb. Kliente,

Sveikiname, įsigijus pratęsto garantinio aptarnavimo sertifikatą (PPG).
Prašome iššsaugoti šį sertifikatą.`;

    let subjectText;
    if (contract.store_id == null) {
      subjectText = `Užsakymas #${contract.invoice_number}. Sertifikatas ${contract.number}`;
    } else {
      subjectText = `Sertifikatas ${contract.number}`;
    }

    setVariables({
      receiver_email: contract.email,
      subject: subjectText,
      body: textariaText,
    });
  }, [preview]);

  const sendEmailMessage = () => {
    const variablesMail = {
      contract_email: variables,
    };
    dispatch(sendContractEmailTest(contract.id, variablesMail)).then((res) => {
      if (res) {
        setValues(res);
        setPreview(false);
      }
    });
  };

  return (
    <EmailBlock>
      {preview && (
        <div className="mb-3">
          <div className="info_block"></div>
          <TextInputModal
            required="true"
            text="Siuntėjas"
            placeholder="Siuntėjas"
            value="TECHNORAMA.LT <garantija@technonet.lt>"
            disabled={true}
          />
          <TextInputModal
            required="true"
            text="Gavėjas"
            placeholder="Gavėjas"
            name="receiver_email"
            value={variables.receiver_email}
            onChange={onChangeUseState2(setVariables)}
          />
          <TextInputModal
            required="true"
            text="Tema"
            placeholder="Tema"
            name="subject"
            value={variables.subject}
            onChange={onChangeUseState2(setVariables)}
          />
          <div className="text-bold my-1">
            Tekstas <span className="orange_color">*</span>
          </div>
          <TextArea
            rows={4}
            placeholder="Tekstas"
            name="body"
            value={variables.body}
            onChange={onChangeUseState2(setVariables)}
          />
          <div className="d-flex justify-content-center my-2">
            <Button2 text={"Siųsti"} onClick={() => sendEmailMessage()} />
          </div>
        </div>
      )}
      {contract_emails.map((item, index) => (
        <EmailBlockEach key={index} item={item} />
      ))}
      <br />
      <div style={{ position: "relative" }}>
        <Button1
          style={{
            position: "absolute",
            top: "-16px",
            left: "50%",
            transform: "translate(-50%, 0px)",
          }}
          text={preview ? "Sutraukti" : "Siųsti laišką"}
          option={preview ? "minus" : "plus"}
          onClick={() => setPreview((prev) => !prev)}
        />
        <hr />
      </div>
    </EmailBlock>
  );
}

const EmailBlock = styled.div`
  border-radius: 3px;
  padding: 10px 10px;
  margin-bottom: 5px;
  background-color: rgb(251, 251, 251);
  .receiver {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
`;
