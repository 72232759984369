import React, { Fragment, useEffect, useState } from "react";
import { Input, Select, Spin } from "antd";
import { CheckBoxInput } from "../../Buttons";
import RangePickerComp from "../../RangePickerComp";
import { Divs } from "../../StyledComponents";
import { first_element_array } from "../../../../helpers/Utils";
import { ruleCarrying } from "../../../../helpers/ShipmentRules";
import axios from "axios";

const { Option } = Select;
export default function ShipmentAdditionalServices({
  order_id,
  order_type,
  delivery,
  variables,
  handleChangeText,
  shipmentRowForStore,
  onChangeDate,
  errors,
  postal_code,
}) {
  return (
    <>
      <Divs className="d-flex justify-content-between">
        {order_type !== "at_the_store" && (
          <>
            <div style={{ width: "47%" }}>
              <span className="text-bold">Paketai</span>
              <Input
                type="number"
                min={0}
                className={`ml-1 ${errors?.packets ? "error_border" : ""}`}
                placeholder="Įvesti"
                disabled={
                  delivery == "omniva_postMachine" ||
                  delivery == "dpd_postMachine" ||
                  delivery == "lp_express_postMachine" ||
                  delivery == "venipak_postMachine"
                }
                name="pack"
                value={variables.pack}
                onChange={(e) => handleChangeText(e)}
              />
              {errors?.packets && (
                <div className="text-danger">{errors?.packets}</div>
              )}
            </div>
            <div style={{ width: "47%" }}>
              <span className="text-bold">Svoris</span>
              <Input
                type="number"
                min={0}
                className={`ml-1 ${errors?.weight ? "error_border" : ""}`}
                placeholder="Įvesti"
                name="weight"
                value={variables.weight}
                onChange={(e) => handleChangeText(e)}
              />
              {errors?.weight && (
                <div className="text-danger">
                  {errors?.weight.map((e) => (
                    <div>{e}</div>
                  ))}
                </div>
              )}
            </div>
          </>
        )}
      </Divs>
      {order_type == "lp_express" && delivery == "lp_express_postMachine" ? (
        <Divs>
          <span className="text-bold">
            {variables.pack == 1 ? "Paketo dydis" : "Paketų dydžiai"}
          </span>

          <Select
            style={{ width: "100%" }}
            placeholder="Pasirinkti"
            value={variables.lp_express_sizes}
            onChange={(e) =>
              handleChangeText({
                target: { name: "lp_express_sizes", value: e },
              })
            }
          >
            <Option value="XSmall">XSmall</Option>
            <Option value="Small">Small</Option>
            <Option value="Medium">Medium</Option>
            <Option value="Large">Large</Option>
            <Option value="XLarge">XLarge</Option>
          </Select>
        </Divs>
      ) : null}

      <table className="table table-borderless">
        <tbody>
          {ruleCarrying({
            order_type,
            delivery,
            skipCarrying: variables.skipCarrying,
          }) && (
            <Carrying
              order_id={order_id}
              postal_code={postal_code}
              order_type={order_type}
              delivery={delivery}
              value={variables.carrying}
              onChange={handleChangeText}
            />
          )}
          {order_type === "dpd" && variables.suppliers === 20 && (
            <tr>
              <td className="align-middle px-0 pb-1" colSpan={2}>
                <CheckBoxInput
                  choised="same_day_delivery"
                  color="#f7951e"
                  text="Pristatymas tą pačią dieną"
                  checked={variables.same_day_delivery}
                  onChange={(e) =>
                    handleChangeText({
                      target: {
                        name: "same_day_delivery",
                        value: e.target.checked,
                      },
                    })
                  }
                />
              </td>
            </tr>
          )}
          <tr>
            {order_type !== "at_the_store" && !variables.skipCod && (
              <td className="align-middle px-0 pb-1">
                <CheckBoxInput
                  choised="cod"
                  color="#f7951e"
                  text="COD"
                  checked={variables.cod}
                  onChange={(e) =>
                    handleChangeText({
                      target: { name: "cod", value: e.target.checked },
                    })
                  }
                />
              </td>
            )}
            {variables.cod && (
              <td className="pr-0 pb-1">
                <div className="row">
                  <div className="col-5 my-auto text-right pr-0">
                    <span className="text-bold my-auto pr-2">Suma EUR</span>
                  </div>
                  <div className="col-7">
                    <Input
                      placeholder="Įvesti"
                      type="number"
                      name="cod_amount"
                      value={variables.cod_amount}
                      onChange={(e) => handleChangeText(e)}
                    />
                  </div>
                </div>
              </td>
            )}
          </tr>
          {order_type === "dpd" && !variables.skipSendingDocuments && (
            <tr>
              <td className="align-middle px-0 pb-1">
                <CheckBoxInput
                  choised="sending_doc"
                  color="#f7951e"
                  text="Siunčiami dokumentai"
                  checked={variables.sendingDocuments}
                  onChange={(e) =>
                    handleChangeText({
                      target: {
                        name: "sendingDocuments",
                        value: e.target.checked,
                      },
                    })
                  }
                />
              </td>
              {order_type === "dpd" && variables.sendingDocuments && (
                <td className="pr-0 pb-1">
                  <div className="row">
                    <div className="col-5 my-auto text-right pr-0">
                      <span className="text-bold my-auto pr-2">Kas?</span>
                    </div>
                    <div className="col-7">
                      <Input
                        placeholder="Įvesti"
                        name="who"
                        value={variables.who}
                        onChange={(e) => handleChangeText(e)}
                      />
                    </div>
                  </div>
                </td>
              )}
            </tr>
          )}
          {(order_type === "dpd" || order_type === "own_transport") &&
            !variables.skipDeliveryTime && (
              <tr>
                <td className="align-middle px-0 pb-1">
                  <CheckBoxInput
                    choised="delivery_time"
                    color="#f7951e"
                    text="Pristatymo laikas"
                    checked={variables.deliveryTime}
                    onChange={(e) =>
                      handleChangeText({
                        target: {
                          name: "deliveryTime",
                          value: e.target.checked,
                        },
                      })
                    }
                  />
                </td>
                {order_type !== "omniva" && variables.deliveryTime && (
                  <td className="pr-0 pb-1">
                    <div className="row">
                      <div className="col-5 my-auto text-right pr-0">
                        <span className="text-bold my-auto pr-2">Kada?</span>
                      </div>
                      <div className="col-7 my-auto">
                        <RangePickerComp
                          format="HH:mm"
                          showTime={{ format: "HH:mm" }}
                          placeholder={["Nuo", "Iki"]}
                          disabledHours={() => [
                            0, 1, 2, 3, 4, 5, 6, 7, 19, 20, 21, 22, 23,
                          ]}
                          hideDisabledOptions={true}
                          // allowClear={false}
                          // value={moment(deliveryTimeHours, "HH:MM")}
                          onChange={(e, args) =>
                            onChangeDate(args, "deliveryTimeHours")
                          }
                        />
                      </div>
                    </div>
                  </td>
                )}
              </tr>
            )}
          {order_type === "dpd" && !variables.skipDeliveryOnSaturday && (
            <tr>
              <td className="align-middle px-0 pb-1" colSpan={2}>
                <CheckBoxInput
                  choised="delivery_saturday"
                  color="#f7951e"
                  text="Pristatymas šeštadienį"
                  checked={variables.deliveryOnSaturday}
                  onChange={(e) =>
                    handleChangeText({
                      target: {
                        name: "deliveryOnSaturday",
                        value: e.target.checked,
                      },
                    })
                  }
                />
              </td>
            </tr>
          )}{" "}
          {order_type === "dpd" && !variables.skipOrderReturn && (
            <tr>
              <td className="align-middle px-0 pb-1" colSpan={2}>
                <CheckBoxInput
                  choised="order_return"
                  color="#f7951e"
                  text="Siuntos paėmimas iš kliento"
                  checked={variables.order_return}
                  onChange={(e) =>
                    handleChangeText({
                      target: {
                        name: "order_return",
                        value: e.target.checked,
                      },
                    })
                  }
                />
              </td>
            </tr>
          )}
          {(order_type === "own_transport" ||
            (order_type === "dpd" && delivery !== "dpd_postMachine")) &&
            !variables.skipSwap && (
              <tr>
                <td className="align-middle px-0 pb-1" colSpan={2}>
                  <CheckBoxInput
                    choised="swap"
                    name="swap"
                    color="#f7951e"
                    text="SWAP"
                    checked={variables.swap}
                    onChange={(e) =>
                      handleChangeText({
                        target: { name: "swap", value: e.target.checked },
                      })
                    }
                  />
                </td>
              </tr>
            )}
        </tbody>
      </table>
      <div className="d-flex justify-content-between align-items-centrer pb-2">
        <div>
          {order_type == "at_the_store" && (
            <Fragment>
              <span>Iš viso krepšelyje: </span>
              <span className="text-bold">
                {shipmentRowForStore &&
                  Number(
                    shipmentRowForStore.reduce(
                      (acc, cur) => +acc + +cur.price * +cur.quantity,
                      0
                    )
                  ).toFixed(2)}{" "}
                &#8364;
              </span>
            </Fragment>
          )}
        </div>
      </div>
      <div className="text-danger text-sm">
        {first_element_array(errors?.api_cod)}
      </div>
    </>
  );
}

const Carrying = ({
  order_id,
  order_type,
  delivery,
  postal_code,
  value,
  onChange,
}) => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    // if (order_type == "venipak" && !!postal_code) {
    const fetchVenipakServices = async () => {
      setLoading(true);
      setMessage("");
      try {
        const { data } = await axios.get(
          `/api/v1/orders/${order_id}/shipments/is_carrying_available`,
          {
            params: {
              order_type,
              delivery,
              postal_code,
            },
          }
        );

        onChange({
          target: { name: "carrying", value: data.success || false },
        });
        if (!data.success && data.message) {
          setMessage(data.message);
        }
      } catch (err) {
        console.log("ERROR: ", err);
      } finally {
        setLoading(false);
      }
    };
    fetchVenipakServices();
    // } else {
    //   setLoading(false);
    // }
  }, [order_type, delivery, postal_code]);

  if (loading) {
    <tr>
      <td className="align-middle px-0 pb-1" colSpan={2}>
        <Spin /> Kraunama informacija...
      </td>
    </tr>;
  }
  return (
    <tr>
      <td className="align-middle px-0 pb-1" colSpan={2}>
        <CheckBoxInput
          choised="carrying"
          text="Užnešimas"
          checked={value}
          onChange={(e) =>
            onChange({
              target: { name: "carrying", value: e.target.checked },
            })
          }
        />
        {message && <div className="text-danger text-sm">{message}</div>}
      </td>
    </tr>
  );
};
