import React from "react";
import TextInputModal from "../../../../common/TextInputModal";

function ServiceFormChildren2({ onChangeHandler, errors, form }) {
  return (
    <>
      <div className="row mx-0">
        <div className="pr-3" style={{ width: "32%" }}>
          <TextInputModal
            text="Prekės pavadinimas"
            name="item_name"
            onChange={(e) => onChangeHandler(e, "form")}
            value={form.item_name}
            warning={errors.item_name}
            />
        </div>
        <div className="px-3" style={{ width: "32%" }}>
          <TextInputModal
            required={true}
            text="Serijinis numeris"
            name="serial_number"
            onChange={(e) => onChangeHandler({target: {name: e.target.name ,value: e.target.value.toUpperCase()}}, "form")}
            value={form.serial_number}
            // warning={errors.serial_number}
          />
        </div>
      </div>
    </>
  );
}

export default ServiceFormChildren2;
