import React from "react";
import Container from "../Layout/Container";
import { connect } from "react-redux";
import {
  getStatuses,
  getStatusShow,
  changeStatusJob,
  addStatusJob,
  updateStatusJob,
  removeStatusJob,
} from "../../redux/Actions/statusesAction";
import StatusesEditModal from "./Items/StatusesEditModal";
import ContentBlock from "../Layout/ContentBlock";
import Pagination from "../Dashboard/items/Pagination";
import TableColumnStatuses from "./Items/TableColumnStatuses";
import FilterMenu from "../Layout/FilterMenu";
import TableFilterBox from "../common/TableFilterBox";
import ContentValidation from "../common/ContentValidation";

class Statuses extends React.Component {
  constructor() {
    super();
    this.state = {
      requiredId: 0,
      page: 1,
      pageCount: 100,
    };
  }
  componentDidMount() {
    this.props.getStatuses(this.state.page, this.state.pageCount);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.props.getStatuses(this.state.page, this.state.pageCount);
    }
  }

  onAction = (index, showId) => {
    this.setState({
      requiredId: index,
    });
    this.props.getStatusShow(showId);
  };

  setPagination = (page) => {
    this.setState({
      page,
    });
  };
  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  render() {
    const requiredItem = this.state.requiredId;
    let modalData = this.props.statuses[requiredItem]
      ? this.props.statuses[requiredItem]
      : [];
    return (
      <Container
        location={{
          locationName: "Statusai",
          locationUrl: this.props.match.url,
        }}
      >
        <ContentBlock>
          <FilterMenu
            length={`${this.props.totals || 0} / ${
              this.props.statuses.length || 0
            }`}
            onChangeCountPage={this.setPageCount}
            countPage={this.state.pageCount}
            text="statusų"
            text2="Statusų"
          />
        </ContentBlock>
        <ContentBlock>
          <TableFilterBox></TableFilterBox>
          <div>
            <ContentValidation
              loader={this.props.loading_statuses}
              array={this.props.statuses}
              alertId_s="statusAlert-s"
              alertId_e="statusAlert-e"
            >
              <div className="table-responsive">
                <table className="table table-sm table-hover mt-3">
                  <thead>
                    <tr className="text-muted">
                      <th className="text-black">#</th>
                      <th className="text-black">Statuso ID</th>
                      <th className="text-black">Pavadinimas</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.statuses.map((item, index) => {
                      return (
                        <TableColumnStatuses
                          key={index}
                          index={index}
                          item={item}
                          onEdit={this.onAction}
                        />
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                  setPagination={this.setPagination}
                  page={this.state.page}
                  totals={this.props.totals}
                  pageCount={this.state.pageCount}
                />
              </div>
            </ContentValidation>
          </div>
        </ContentBlock>

        <StatusesEditModal
          objectData={modalData}
          addStatusJob={this.props.addStatusJob}
          changeStatusJob={this.props.changeStatusJob}
          updateStatusJob={this.props.updateStatusJob}
          removeStatusJob={this.props.removeStatusJob}
          status_jobs={this.props.status_jobs}
        />
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  statuses: state.statuses.statuses,
  status_jobs: state.statuses.status_jobs,
  loading_statuses: state.statuses.loading_statuses,
  totals: state.statuses.totals,
  //   errors: state.errors,
});

export default connect(mapStateToProps, {
  getStatuses,
  getStatusShow,
  addStatusJob,
  updateStatusJob,
  changeStatusJob,
  removeStatusJob,
})(Statuses);
