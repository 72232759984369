import React, { useMemo } from "react";
import Axios from "axios";
import { FaDownload } from "react-icons/fa";
import { GoPrimitiveDot } from "react-icons/go";
import moment from "moment";
import { ButtonDelete } from "../../../common/Buttons";

function TableColumnShipment(props) {
  const downloadButton = () => {
    Axios({
      url: `/api/v1/order_shipments/${props.item.id}/download`,
      method: "POST",
      responseType: "blob", // important
      headers: { Accept: "application/vnd.ms-excel" },
    }).then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute(
        "download",
        `${
          "OMNIVA_" + (props.item.order.id_order || props.item.order.number)
        }.pdf`
      ); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  };
  const document_exist = useMemo(
    () => props.item.shipment_documents.find((d) => d.identifier == "labels"),
    [props.item.shipment_documents]
  );

  return (
    <tr className="align-middle tableColumn">
      <td className="align-middle text-center">
        <GoPrimitiveDot
          style={props.item.printed ? { color: "green" } : { color: "red" }}
        />
      </td>
      <td className="align-middle text-center">
        <GoPrimitiveDot
          style={
            props.item.courier_called ? { color: "green" } : { color: "red" }
          }
        />
      </td>
      <td className="align-middle">
        {moment(props.item.created_at).format("YYYY-MM-DD HH:mm")}
      </td>
      <td className="align-middle">
        {" "}
        {props.item.service_order_type === "Service" ? (
          <a
            className="text-primary"
            onClick={() =>
              props.orderModal(
                props.item.order.id,
                props.item.order.id,
                props.item.service_order_type
              )
            }
          >
            {props.item.order.number}
          </a>
        ) : (
          <a
            className="text-primary"
            onClick={() =>
              props.orderModal(
                props.item.order.id_order || `T${props.item.order.id}`,
                props.item.order.id,
                props.item.service_order_type
              )
            }
          >
            {props.item.order.id_order || `T${props.item.order.id}`}
          </a>
        )}
      </td>
      <td className="align-middle">{props.item.pickup_address.code}</td>
      <td className="align-middle">{props.item.packets}</td>
      <td className="align-middle">{props.item.weight} </td>
      <td className="align-middle">{props.item.shipment_address.name}</td>
      <td className="align-middle">{props.item.shipment_address.address}</td>
      <td className="align-middle">{props.item.shipment_address.comment}</td>
      <td className="align-middle">
        {props.item.tracking_numbers.map((tracking_number, index) => (
          <span key={index}>
            <a
              href={`https://www.omniva.lt/verslo/siuntos_sekimas?barcode=${tracking_number}`}
              target="_blank"
            >
              {tracking_number}
            </a>
            <br />
          </span>
        ))}
      </td>
      <td>
        {document_exist ? (
          <button
            className="btn btn-primary btn-sm"
            onClick={() => downloadButton()}
          >
            <FaDownload className="mr-2" title="Parsisiųsti" />
            Parsisiųsti
          </button>
        ) : null}
      </td>
      <td className="align-middle text-center">
        {props.item.courier_called == false && (
          <ButtonDelete
            onDelete={() =>
              props.removeShipmentBeforeCalled(
                props.item.id,
                props.item.order.id,
                "omniva"
              )
            }
          />
        )}
      </td>
    </tr>
  );
}

export default TableColumnShipment;
