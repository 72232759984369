import React, { useState } from "react";
import { Button1, SelectForm } from "../../Buttons";
import OrderHistory from "./editModal/OrderHistory";
import { createOrderStatus } from "../../../../redux/Actions/orderAction";
import { useDispatch } from "react-redux";
import { ClassicButton } from "../../ButtonWithLoading";
import { updateService } from "../../../../redux/Actions/Service/serviceAction";

function StatusComponent({
  disabled = false,
  avalaibleStatus,
  order_histories,
  orderId,
  mode,
}) {
  const dispatch = useDispatch();
  const [newStatus, setNewStatus] = useState(false);
  const [status, setStatus] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const addNewStatus = () => {
    setLoading(true);
    const variables = {
      status_id: status,
    };

    if (mode == "Servisas") {
      return onSaveService();
    }

    dispatch(createOrderStatus(orderId, variables, avalaibleStatus)).then(
      (res) => {
        if (res.success) {
          setStatus(undefined);
          setNewStatus(false);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    );
  };

  const onSaveService = () => {
    if ([undefined, null, "", false].includes(status)) {
      setLoading(false);
      return setErrors({ status: "Pasirinkite statusą." });
    }
    dispatch(updateService({ status, id: orderId })).then((res) => {
      if (res.success) {
        setStatus(undefined);
        setNewStatus(false);
        setLoading(false);
        setErrors({});
      } else {
        setLoading(false);
        setErrors({ error: "Įvyko klaida" });
      }
    });
  };

  return (
    <div className="col-12 bg-silver">
      <div className="table-responsive">
        <table className="table table-sm">
          <tbody>
            {order_histories.length > 0 &&
              order_histories.map((item, index) => (
                <OrderHistory key={index} index={index} item={item} />
              ))}
          </tbody>
        </table>
      </div>

      <div className="d-flex">
        {newStatus && (
          <>
            <div className="w-75">
              <SelectForm
                options={avalaibleStatus}
                value={status}
                onlyPlaceholder="Statusas"
                onChange={(e) => setStatus(e)}
                error={errors.status}
              />
            </div>
            <div className="w-25 text-right">
              <ClassicButton
                loading={loading}
                text="Saugoti"
                onClick={() => addNewStatus()}
              />
            </div>
          </>
        )}
      </div>
      {newStatus && <div className="text-danger">{errors.error}</div>}
      {!disabled && orderId && (
        <div className="py-4">
          <div style={{ position: "relative" }}>
            <Button1
              style={{
                position: "absolute",
                top: "-16px",
                left: "50%",
                transform: "translate(-50%, 0px)",
              }}
              text={newStatus ? "Atšaukti" : "Pridėti naują statusą"}
              option={newStatus ? "minus" : "plus"}
              onClick={() => setNewStatus(!newStatus)}
            />
            <hr />
          </div>
        </div>
      )}
    </div>
  );
}

export default StatusComponent;
