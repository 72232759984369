import React from "react";
import { GoPrimitiveDot } from "react-icons/go";
import styled from "styled-components";
import DocumentPlan from "./document_plan";
import moment from "moment";
import { Tooltip } from "antd";

function DocumentBlock(props) {
  return (
    <ElementBlock className="col-6" key={props.index}>
      <div className="bg-white px-1 py-1 mb-2 shadow-sm rounded">
        <div className="d-flex mt-1 justify-content-between align-items-center">
          <div className="title">
            <GoPrimitiveDot
              className={props.item.active ? "text-success" : "text-danger"}
            />
            <span className="block_title">{props.item.name}</span>
          </div>
          <div className="buttons">
            <button
              className="btn block_button block_orange"
              data-toggle="modal"
              data-target="#tabletype"
              onClick={() => props.onAction("edit", props.index)}
            >
              Redaguoti
            </button>
            <button
              className="btn block_button block_red"
              onClick={() => props.deleteDocumentType(props.item.id)}
            >
              Ištrinti
            </button>
          </div>
        </div>
        <hr />
        <div className="block">
          <p className="block_pagragraph">
            Prefiksas:
            <span className="block_pagragraph_text">{props.item.prefix}</span>
          </p>

          <p className="block_pagragraph">
            Taisyklių planas pagal:
            <span className="block_pagragraph_link">
              <a
                href={`/api/v1/document_types/${props.item.id}/download_asset`}
              >
                {props.item.asset || "Nuoroda"}
              </a>
            </span>
            <span className="block_pagragraph_date">
              ({moment(props.item.updated_at).format("YYYY MM DD HH:mm")})
            </span>
          </p>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="block_table_text">Planai</h4>
          <button
            className="btn block_table_addButton"
            data-toggle="modal"
            data-target="#tablePlans"
            onClick={() =>
              props.createNewPlan({
                label: props.item.name,
                value: props.item.id,
              })
            }
          >
            Pridėti naują planą
          </button>
        </div>
        <div className="table-responsive">
          <table className="table table-sm table-hover mt-3">
            <thead>
              <tr className="text-muted">
                <th></th>
                <th>Pavadinimas</th>
                <th>Data</th>
                <th>Pradžia po</th>
                <th>Pabaiga po</th>
                <th>Mokestis</th>
                <th>%</th>
                <th>
                  <Tooltip placement="top" title={"Rivilė paslauga"}>
                    RP
                  </Tooltip>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {props.item.document_plans.length > 0 &&
                props.item.document_plans.map((planItem, i) => (
                  <DocumentPlan
                    index={i}
                    key={i}
                    planItem={planItem}
                    documentId={{
                      label: props.item.name,
                      value: props.item.id,
                    }}
                    updateDocumentPlan={props.updateDocumentPlan}
                    deleteDocumentPlan={props.deleteDocumentPlan}
                    rivile_services={props.rivile_services}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </ElementBlock>
  );
}

export default DocumentBlock;

const ElementBlock = styled.div`
  .block_ball {
    width: 1rem;
    height: 1rem;
    color: #529f00;
  }
  .block_title {
    font-size: 18px;
    line-height: 14px;
    color: #000000;
    font-family: "Barlow";
    font-weight: 700;
  }
  .block_button {
    width: 81px;
    height: 37.5px;
    border-radius: 3px;

    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    font-family: "Barlow";
    font-weight: 900;
  }
  .block_orange {
    background-color: #f7931e;
    margin-right: 5px;
  }
  .block_red {
    background-color: #ef3824;
  }
  .block_pagragraph {
    font-size: 15px;
    line-height: 14px;
    color: #000000;
    font-family: "Barlow";
    font-weight: bold;

    &_text {
      font-weight: 600;
      margin-left: 5px;
    }
    &_link {
      font-weight: 600;
      border-bottom: 1px solid black;
      margin-left: 5px;
    }
    &_date {
      color: #959595;
      margin-left: 5px;
    }
  }
  .block_table_text {
    margin: 0;
    margin-top: 10px;
    font-size: 16px;
    line-height: 14px;
    color: #000000;
    font-family: "Barlow";
    font-weight: 700;
  }
  .block_table_addButton {
    width: 129px;
    height: 37.5px;
    border-radius: 4px;
    /* background-color: #ef3824; */
    border: 1px solid #c8c8c8;
    font-size: 12px;
    line-height: 14px;
    color: #676767;
    font-family: "Barlow";
    font-weight: 900;
  }

  .table-responsive {
    th {
      font-size: 15px;
      line-height: 14px;
      color: #212121;
      font-family: "Barlow";
      font-weight: 900;
    }
    td {
      font-size: 15px;
      color: #212121;
      font-family: "Barlow";
      font-weight: 400;
    }
    .table_button {
      padding: 0;
      width: 29px;
      height: 29px;
      border-radius: 3px;
      color: #ffffff;
    }
  }
`;
