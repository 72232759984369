import { combineReducers } from "redux";
import authReducer from "./authReducer";
import smsReducer from "./smsReducer";
import errorReducer from "./errorReducer";
import storeReducer from "./storeReducer";
import supplierReducer from "./supplierReducer";
import categoryReducer from "./categoryReducer";
import countryReducer from "./countryReducer";
import pickupAddressReducer from "./pickupAddressReducer";
import userReducer from "./userReducer";
import pickupReducer from "./pickup_userReducer";
import productsReducer from "./productReducer";
import ordersReducer from "./orderReducer";
import comment_stateReducer from "./comment_stateReducer";
import documentTypeReducer from "./documentTypeReducer";
import documentReducer from "./documentReducer";
import shipmentReducer from "./shipmentReducer";
import manifestReducer from "./manifestReducer";
import lotteryReducer from "./lotteryReducer";
import rivileServiceReducer from "./rivileServiceReducer";
import rivileStoreReducer from "./rivileStoreReducer";
import shopCategoriesReducer from "./shopCategoriesReducer";
import statusesReducer from "./statusesReducer";
import paymentTypesReducer from "./paymentTypesReducer";
import rivileReducer from "./rivileReducer";
import payModuleReducer from "./payModuleReducer";
import prestaReducer from "./prestaReducer";
import BigboxRivileReducer from "./rivile/BigboxRivileReducer";
import serviceReducer from "./service/serviceReducer";
import suppliersInvoices from "./suppliersInvoicesReducer";
import filtersReducer from "./filtersReducer";
import customReducer from "./customReducer";
import brandReducer from "./brandReducer";
import familyReducer from "./familyReducer";
import leasingsReducer from "./leasingsReducer";
import descriptionReducer from "./descriptionReducer";
import repricingReducer from "./repricingReducer";

export default combineReducers({
  auth: authReducer,
  sms: smsReducer,
  user: userReducer,
  orders: ordersReducer,
  pickup_user: pickupReducer,
  product: productsReducer,
  lottery: lotteryReducer,
  shipment: shipmentReducer,
  manifest: manifestReducer,
  comment_state: comment_stateReducer,
  documents: documentReducer,
  store: storeReducer,
  supplier: supplierReducer,
  category: categoryReducer,
  country: countryReducer,
  pickupAddress: pickupAddressReducer,
  documentType: documentTypeReducer,
  rivileService: rivileServiceReducer,
  rivileStore: rivileStoreReducer,
  paymentTypes: paymentTypesReducer,
  shopCategories: shopCategoriesReducer,
  statuses: statusesReducer,
  errors: errorReducer,
  rivile: rivileReducer,
  payModule: payModuleReducer,
  presta: prestaReducer,
  bigbox: BigboxRivileReducer,
  service: serviceReducer,
  supplierInvoices: suppliersInvoices,
  filters: filtersReducer,
  custom: customReducer,
  brand: brandReducer,
  family: familyReducer,
  leasings: leasingsReducer,
  description: descriptionReducer,
  repricing: repricingReducer,
});
