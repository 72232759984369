import React from "react";
import ShipmentStoreSvg from "../../../../../assets/images/document.svg";
import { Tooltip } from "antd";
import Axios from "axios";
import { FiTruck } from "react-icons/fi";
import { exportDocument } from "../../../../helpers/Utils";

function SupplyingTableIndex({
  item,
  id_order_row,
  product_reference,
  type,
  service_id,
}) {
  const renderCode = () => {
    if (item.supplier_code) {
      return <div className="text-danger">{item.supplier_code}</div>;
    } else if (product_reference) {
      return <div>{product_reference}</div>;
    } else if (id_order_row) {
      return <div>{id_order_row}</div>;
    }
  };

  const downloadLabelsButton = () => {
    let url = `/api/v1/service_rows/${id_order_row}/supplyings/${item.id}/download`;
    Axios({
      url,
      method: "POST",
      responseType: "blob", // important
      headers: { Accept: "application/vnd.ms-excel" },
    }).then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", `${`${item.id}_tiekimo_lipdukas`}.pdf`); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  };

  return (
    <tr style={{ fontSize: "0.75rem", backgroundColor: "transparent" }}>
      <td style={{ fontSize: "0.75rem" }}>
        {item.amount == null ? 0 : item.amount}
        {"* "}
      </td>
      <td style={{ fontSize: "0.75rem" }}>{renderCode()}</td>
      <td style={{ fontSize: "0.75rem" }} colSpan={2}>{item.supplier?.name || ""}</td>
      <td style={{ fontSize: "0.75rem" }} colSpan={2}>{item.date}</td>
      <td className="text-danger" style={{ fontSize: "0.75rem" }} colSpan={2}>
        {item.external_number || ""}
        {item.inner_document_number || ""}
      </td>
      {type !== "Order" && (
        <td className="d-flex">
          <Tooltip placement="top" title={"Spausdinti tiekimo lipduką"}>
            <div
              className="btn-primary d-flex justify-content-center align-items-center m-auto cursor-pointer"
              style={{ width: 29, height: 29, borderRadius: "50%" }}
              onClick={() => downloadLabelsButton()}
            >
              <img src={ShipmentStoreSvg} style={{ width: 13, height: 13 }} />
            </div>
          </Tooltip>
          <Tooltip
            placement="top"
            title={
              <div className="d-flex flex-wrap">
                Spausdinti dokumentą kaip
                <div
                  className="text-orange cursor-pointer white-hover font-semi-bold ml-1"
                  onClick={() =>
                    exportDocument(
                      "/api/v1/repair_services/export_documents_rma",
                      `exported_rma_${service_id}.pdf`,
                      "POST",
                      {
                        ids: service_id,
                        supplier_name: item.supplier?.name || "",
                      }
                    )
                  }
                >
                  išvežimą
                </div>
                <div className="mx-1">/</div>
                <div
                  className="text-orange cursor-pointer white-hover font-semi-bold"
                  onClick={() =>
                    exportDocument(
                      "/api/v1/repair_services/export_documents_rma",
                      `exported_rma_${service_id}.pdf`,
                      "POST",
                      {
                        ids: service_id,
                        supplier_name: item.supplier?.name || "",
                        transfer_back: true,
                      }
                    )
                  }
                >
                  parvežimą
                </div>
                <span>?</span>
              </div>
            }
          >
            <div
              className="btn-primary d-flex justify-content-center align-items-center m-auto cursor-pointer ml-2"
              style={{ width: 29, height: 29, borderRadius: "50%" }}
            >
              <FiTruck width={13} />
            </div>
          </Tooltip>
        </td>
      )}
    </tr>
  );
}

export default SupplyingTableIndex;
