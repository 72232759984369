import React, { useEffect, useState } from "react";
import ModalToChange from "../../Layout/ModalToChange";
import { CheckBoxInput } from "../../common/Buttons";
import { DebounceSelect } from "../../common";
import { errorHandler } from "../../../helpers/Utils";
import Axios from "axios";
import { Input } from "../../global";

class RivileServiceEditModal extends React.Component {
  constructor() {
    super();
    this.state = {
      id: 0,
      id_product: "",
      title: "",
      identifier: "",
      rivile_code: "",
      alt_code: "",
      alt_price: "",
      has_vat: false,
      limitation_shop_categories: [],
      limitation_brands: [],
      limitation_products: [],
      action: "new",
      errors: {},
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.action === "new" && this.state.action === "edit") {
      this.setState({
        id: 0,
        id_product: "",
        title: "",
        identifier: "",
        rivile_code: "",
        has_vat: "",
        alt_code: "",
        alt_price: "",
        limitation_shop_categories: [],
        limitation_brands: [],
        limitation_products: [],
        action: "new",
        errors: {},
      });
    } else if (
      nextProps.action === "edit" &&
      nextProps.objectData.id !== this.state.id
    ) {
      this.setState({
        id: nextProps.objectData.id,
        id_product: nextProps.objectData.id_product,
        title: nextProps.objectData.title,
        identifier: nextProps.objectData.identifier,
        rivile_code: nextProps.objectData.rivile_code,
        has_vat: nextProps.objectData.has_vat,
        alt_code: nextProps.objectData.alt_code,
        alt_price: nextProps.objectData.alt_price,
        limitation_shop_categories:
          nextProps.objectData.limitation_shop_categories,
        limitation_brands: nextProps.objectData.limitation_brands,
        limitation_products: nextProps.objectData.limitation_products,
        action: "edit",
        errors: {},
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.title.length == 0 ||
      this.state.rivile_code.length == 0 ||
      this.state.id_product.length == 0
    ) {
      let errors = {};
      if (this.state.title.length == 0)
        errors.title = "Neužpildytas pavadinimas";
      if (this.state.rivile_code.length == 0)
        errors.rivile_code = "Neužpildytas rivile kodas";
      if (this.state.id_product.length == 0)
        errors.id_product = "Neužpildytas produkto ID";
      return this.setState({
        errors,
      });
    }
    const variables = {
      rivile_service: {
        id_product: this.state.id_product,
        title: this.state.title,
        identifier: this.state.identifier,
        rivile_code: this.state.rivile_code,
        alt_code: this.state.alt_code,
        alt_price: this.state.alt_price,
        has_vat: this.state.has_vat,
        limitation_shop_categories: this.state.limitation_shop_categories,
        limitation_brands: this.state.limitation_brands,
        limitation_products: this.state.limitation_products,
      },
    };
    if (this.props.action == "new") {
      this.props.postRivileService(variables).then((res) => {
        if (res.success) {
          this.setState({
            id: 0,
            id_product: "",
            title: "",
            identifier: "",
            rivile_code: "",
            has_vat: "",
            alt_code: "",
            alt_price: "",
            limitation_shop_categories: [],
            limitation_brands: [],
            limitation_products: [],
            action: "new",
            errors: {},
          });
        }
      });
    } else if (this.props.action === "edit") {
      this.props.updateRivileServices(this.state.id, variables).then((res) => {
        if (res.success) {
          this.setState({
            errors: {},
          });
        }
      });
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <ModalToChange
        handleSave={(e) => this.onSubmit(e)}
        status="tableEdit"
        text={this.props.action === "new" ? "Kurti naują" : "Redaguoti"}
        buttonText={this.props.action === "new" ? "Sukurti" : "Atnaujinti"}
      >
        <Input
          text="Produkto ID"
          name="id_product"
          value={this.state.id_product}
          warning={errors.id_product}
          onChange={(e) => this.handleChange(e)}
        />
        <Input
          text="Pavadinimas"
          name="title"
          value={this.state.title}
          warning={errors.title}
          onChange={(e) => this.handleChange(e)}
        />
        <Input
          text="Rivile kodas"
          name="rivile_code"
          value={this.state.rivile_code}
          warning={errors.rivile_code}
          onChange={(e) => this.handleChange(e)}
        />
        <Input
          type="number"
          text="Alternatyvos kaina"
          name="alt_price"
          value={this.state.alt_price}
          onChange={(e) => this.handleChange(e)}
        />
        <Input
          text="Alternatyvos kodas"
          name="alt_code"
          value={this.state.alt_code}
          onChange={(e) => this.handleChange(e)}
        />
        <InitialDebounceSelect
          initial_id={this.state.id}
          label="Kategorijų apribojimas"
          name="limitation_shop_categories"
          value={this.state.limitation_shop_categories}
          onChange={(value) => this.handleChange(value)}
        />
        <InitialDebounceSelect
          initial_id={this.state.id}
          label="Gamintojų apribojimas"
          name="limitation_brands"
          value={this.state.limitation_brands}
          onChange={(value) => this.handleChange(value)}
        />
        <InitialDebounceSelect
          initial_id={this.state.id}
          label="Produktų apribojimas"
          name="limitation_products"
          value={this.state.limitation_products}
          onChange={(value) => this.handleChange(value)}
        />
        <Input
          disabled={true}
          text="Identifikatorius"
          name="identifier"
          value={this.state.identifier}
          onChange={(e) => this.handleChange(e)}
        />
        <div className="my-2">
          <CheckBoxInput
            checked={this.state.has_vat}
            name={"has_vat"}
            onChange={(e) => this.setState({ has_vat: e.target.checked })}
            text="PVM taikomas"
          />
        </div>
      </ModalToChange>
    );
  }
}

export default RivileServiceEditModal;

const InitialDebounceSelect = ({
  initial_id,
  label,
  name,
  value,
  onChange,
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchOptions(value, "options");
  }, [initial_id]);

  const fetchOptions = async (value, type = "basic") => {
    try {
      let api_url = `/api/v1/`;
      if (name == "limitation_brands") {
        api_url += `brands?${
          type == "options"
            ? value.map((d) => `&[q][id_in][]=${d}`).join("")
            : `[q][title_cont]=${value}`
        }`;
      } else if (name == "limitation_shop_categories") {
        api_url += `shop_categories/list?${
          type == "options"
            ? value.map((d) => `&[q][id_in][]=${d}`).join("")
            : `[q][title_cont]=${value}`
        }`;
      } else {
        api_url += `products/simple_products.json?${
          type == "options"
            ? value.map((d) => `&[q][id_in][]=${d}`).join("")
            : `[q][title_or_identifier_cont]=${value}`
        }`;
      }
      const { data } = await Axios.get(api_url);
      const array = Array.isArray(data) ? data : data?.data;
      if (!array) return [];
      const mappedArray =
        array.map((e) => ({
          ...e,
          value: e.id,
          label:
            name == "limitation_products"
              ? `${e.identifier} - ${e.title}`
              : e.title || e.name,
        })) || [];
      if (type == "options") {
        return setOptions(mappedArray);
      }
      return mappedArray;
    } catch (err) {
      errorHandler(err);
      return [];
    }
  };

  return (
    <DebounceSelect
      options={options}
      mode="multiple"
      label={label}
      showSearch={true}
      fetchOptions={fetchOptions}
      style={{
        width: "100%",
      }}
      value={value || undefined}
      onChange={(value) => {
        console.log({ value });
        onChange({
          target: {
            value: value.map(({ value }) => value) || null,
            name,
          },
        });
      }}
      // error={errors.family_id}
    />
  );
};
