import { Checkbox, Tooltip } from "antd";
import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

import styled from "styled-components";
import { returnEuroString } from "../../../helpers/Utils";
import TableChildrenColumnProduct from "./TableChildrenColumnProduct";
import TableFocusColumnProduct from "./TableFocusColumnProduct";

function TableColumnSMS(props) {
  const [showBlock, setShowBlock] = useState(false);
  const reducer = (accumulator, currentValue) =>
    accumulator + currentValue.quantity;
  let countingAll = props.item.hasOwnProperty("product_suppliers")
    ? props.item.product_suppliers.reduce(reducer, 0)
    : props.item.quantity;

  const findMainSupplierCode = () => {
    const name = props.item.main_supplier;
    if (name == "none") return "";
    if (!name) return "";
    return (
      props.item?.product_suppliers?.find((d) => d?.supplier.name?.toLowerCase().trim() == name.toLowerCase().trim())
        ?.code || ""
    );
  };
  return (
    <>
      <TableColumnProduct
        className="align-middle"
        key={props.item.product_identifier}
        // parentItem={
        //   props.itsparent ? "#fbfbfb" : validParent ? "#f9f9f9" : "white"
        // }
      >
        <>
          <td className="align-middle">
            <Checkbox onChange={props.setSelected} selected={props.selected} />
          </td>
          <td className="align-middle d-flex">
            <Tooltip placement="top" title={props.item.identifier}>
              <ButtomSuplSize
                className="btn"
                colorLength={
                  props.item.upsell.length == 0 ? "#cbcbcb" : "#8678AB"
                }
                colorRadius={
                  props.item.upsell.length == 0 ? "#b1b1b1" : "#645B7A"
                }
                onClick={() =>
                  setShowBlock((prev) => (prev == "FOCUS" ? false : "FOCUS"))
                }
              >
                <span className="one"> {props.item.upsell.length}</span>
                <span className="two">{props.item.identifier}</span>
                <span className="free">
                  {props.item.product_suppliers.length === 0 ? (
                    " "
                  ) : showBlock == "FOCUS" ? (
                    <FaChevronUp />
                  ) : (
                    <FaChevronDown />
                  )}
                </span>
              </ButtomSuplSize>
            </Tooltip>
            <DivLine
              style_hidden={showBlock == "FOCUS" ? "visible" : "hidden"}
              width="33px"
            >
              <svg
                width="30"
                height="100"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="10"
                  x2="25"
                  y1="20"
                  y2="20"
                  stroke="#8678AB"
                  strokeWidth="2"
                />
                <line
                  x1="25"
                  x2="25"
                  y1="20"
                  y2="75"
                  stroke="#8678AB"
                  strokeWidth="2"
                />
              </svg>
            </DivLine>
          </td>
          <td className="align-middle open">
            {props.item.image_url && (
              <img
                className="mr-2"
                src={`https://pim.technorama.lt/media/cache/thumbnail_small/${props.item.image_url}`}
                alt={`product_${props.item.title}`}
                style={{
                  width: 38,
                  height: 38,
                }}
              />
            )}
            {props.item.title}
          </td>
          <td className="align-middle d-flex">
            {props.item.hasOwnProperty("product_suppliers") && (
              <>
                <ButtomSuplSize
                  className="btn"
                  colorLength={
                    props.item.product_suppliers.length == 0
                      ? "#cbcbcb"
                      : "#f7941d"
                  }
                  colorRadius={
                    props.item.product_suppliers.length == 0
                      ? "#b1b1b1"
                      : "#e18312"
                  }
                  onClick={() =>
                    setShowBlock((prev) =>
                      prev == "SUPPLIERS" ? false : "SUPPLIERS"
                    )
                  }
                >
                  <span className="one">
                    {" "}
                    {props.item.product_suppliers.length}
                  </span>
                  <span className="two">
                    {showBlock || !props.item.main_supplier
                      ? props.item.product_suppliers.length <= 1
                        ? "  Tiekėjas"
                        : "  Tiekėjai"
                      : props.item.main_supplier}
                  </span>
                  <span className="free">
                    {props.item.product_suppliers.length === 0 ? (
                      " "
                    ) : showBlock == "SUPPLIERS" ? (
                      <FaChevronUp />
                    ) : (
                      <FaChevronDown />
                    )}
                  </span>
                </ButtomSuplSize>
                <DivLine
                  style_hidden={showBlock == "SUPPLIERS" ? "visible" : "hidden"}
                  style={{ height: "1px", width: "1px" }}
                  width="75px"
                >
                  <svg
                    width="100"
                    height="100"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="10"
                      x2="30"
                      y1="20"
                      y2="20"
                      stroke="orange"
                      strokeWidth="2"
                    />
                    <line
                      x1="30"
                      x2="30"
                      y1="20"
                      y2="50"
                      stroke="orange"
                      strokeWidth="2"
                    />
                  </svg>
                </DivLine>
              </>
            )}
          </td>
          {showBlock == "SUPPLIERS" ? (
            <>
              <td className="align-middle openSilver">Kiekis</td>
              <td className="align-middle openSilver">Tiekėjas</td>
              <td className="align-middle openSilver">Tiekėjo kodas</td>
              <td className="align-middle openSilver">Tiekėjo kaina</td>
              <td className="align-middle openSilver">E-kaina</td>
              <td className="align-middle openSilver">R-kaina</td>
            </>
          ) : (
            <>
              <td className="align-middle">{countingAll}</td>
              <td className="align-middle">{props.item.main_supplier}</td>
              <td className="align-middle">{findMainSupplierCode()}</td>
              <td className="align-middle">
                {returnEuroString(props.item.wholsale_price)}
              </td>
              <td className="align-middle">
                {returnEuroString(props.item.e_price)}
              </td>
              <td className="align-middle">
                {returnEuroString(props.item.r_price)}
              </td>
            </>
          )}
        </>
      </TableColumnProduct>

      {props.item.hasOwnProperty("product_suppliers") &&
        showBlock == "SUPPLIERS" &&
        props.item.product_suppliers.map((parentItem, index) => (
          <TableChildrenColumnProduct
            key={index}
            TableColumnProduct={TableColumnProduct}
            DivLine={DivLine}
            ButtomSuplSize={ButtomSuplSize}
            item={parentItem}
            itsparent="true"
            total={props.item.product_suppliers.length - 1}
            indexN={index}
            r_price={props.item.r_price}
            e_price={props.item.e_price}
            wholsale_price={props.item.wholsale_price}
            main_supplier={props.item.main_supplier}
          />
        ))}

      {showBlock == "FOCUS" && (
        <TableFocusColumnProduct
          DivLine={DivLine}
          item_id={props.item.id}
          TableColumnProduct={TableColumnProduct}
          available_edit={props.available_edit}
        />
      )}
    </>
  );
}

const DivLine = styled.div`
  width: ${(props) => props.width || "120px"}!important;
  height: 1px;
  visibility: ${(props) => props.style_hidden};
`;

const ButtomSuplSize = styled.button`
  padding: 0;
  margin: 5px 0 0 0;
  width: 120px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  color: white;
  background-color: ${(props) => props.colorLength};
  .one {
    background-color: ${(props) => props.colorRadius};
    border-radius: 50%;
  }
  .two {
    margin: 0 4px;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
  }
  .free {
    width: 24px;
    border-radius: 20px;
    margin-right: 3px;
  }
  &:hover {
    color: white;
  }
`;

const TableColumnProduct = styled.tr`
  &:nth-child(odd) {
    background: #f3f3f3;
  }
  background-color: ${(props) => props.parentItem || "#fff"}!important;
  td {
    font-size: 0.7rem;
    line-height: 0.8rem;
    color: #212121;
    font-weight: 500;
  }
  .openSilver {
    font-weight: 900;
    color: #d2d2d2;
  }
`;

export default TableColumnSMS;
