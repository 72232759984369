import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { FlopppyButton } from "../../../ButtonWithLoading";
import { Input, DatePicker, message, Spin } from "antd";
import moment from "moment";
import { CheckBoxInput, SelectForm } from "../../../Buttons";
import { getDateFormat } from "../../../../../helpers/Utils";
import { have_product_reference } from "../../../../../helpers/OrdersFunctionsGlobal";
import useFetch from "../../../../../helpers/useFetch";

function SupplyComponent({
  order_rows,
  orderId,
  postOrderRow,
  mode,
}) {
  const dispatch = useDispatch();
  const [variables, setVariables] = useState({
    date: "",
    amount: 1,
    product_code: "",
    supplier_id: undefined,
    supplier_code_boolean: false,
    supplier_code: "",
    supplier_selected: undefined,
    supplierCode: false,
  });
  const [loading, setLoading] = useState(false);

  const {
    value,
    error,
    loading: suppliers_loading,
  } = useFetch(`/api/v1/suppliers?q[is_active_true]=1`);

  useEffect(() => {
    inputChecker();
  }, [variables.supplier_id, variables.supplier_selected]);

  const supply_items = useMemo(() => {
    console.count("supply_items: ");
    return order_rows
      .filter(
        (d) =>
          have_product_reference(d.product_reference) ||
          d.product_reference == "1234"
      )
      .map((d) => ({
        value: d.id,
        label: d.product_reference,
      }));
  }, [order_rows?.length]);

  const supplier_options = useMemo(() => {
    if(error) return []
    if (!suppliers_loading && value?.data && value?.totals) {
      let newArray = [];
      value.data.forEach((item) => {
        const findIndex = newArray.findIndex((find) => find.name == item.name);
        if (findIndex == -1) {
          return newArray.push({
            value: item.id,
            name: item.name,
            code: item.code,
            label: item.name,
            is_code: item.is_code,
          });
        }
      });
      return newArray
    }
    return [];
  }, [suppliers_loading]);

  const inputChecker = () => {
    const findItem2 = order_rows.findIndex(
      (find) => find.id === variables.supplier_selected
    );
    if (findItem2 !== -1) {
      let findProductInfo = order_rows[findItem2].product_info;
      if (order_rows[findItem2].product_info == null) {
        return;
      }
      if (findProductInfo.hasOwnProperty("product_suppliers")) {
        findProductInfo = findProductInfo.product_suppliers.find(
          (find) => find.supplier_id === variables.supplier_id
        );
      } else {
        return;
      }
      if (findProductInfo && findProductInfo.quantity > 0) {
        setVariables({
          ...variables,
          product_code: findProductInfo.supplier.code,
        });
      } else {
        setVariables({
          ...variables,
          product_code: "",
        });
      }
    }

    const lookingForSupplier = supplier_options.find(
      (item) => item.value === variables.supplier_id
    );
    if (lookingForSupplier) {
      setVariables({
        ...variables,
        supplierCode: lookingForSupplier.is_code,
      });
    }
  };


  const createNewOrderRow = () => {
    const {
      date,
      amount,
      product_code,
      supplier_id,
      supplier_code_boolean,
      supplier_code,
      supplier_selected,
    } = variables;
    if (!supplier_id || !supplier_selected) {
      return message.error("Pasirinkite prekę, bei tiekėją.");
    }
    setLoading(true);
    const resVariables = {
      date,
      amount,
      product_code,
      supplier_id,
    };
    if (supplier_code_boolean) {
      resVariables.supplier_code = supplier_code;
    }
    dispatch(postOrderRow(resVariables, orderId, supplier_selected)).then(
      (res) => {
        if (res.success) {
          setVariables({
            date: "",
            amount: 1,
            product_code: "",
            supplier_id: undefined,
            supplier_code_boolean: false,
            supplier_code: "",
            supplier_selected: undefined,
            supplierCode: false,
          });
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    );
  };
  const handleSupplyBlock = (name, value) => {
    setVariables({
      ...variables,
      [name]: value,
    });
  };

  if (suppliers_loading) {
    return (
      <div>
        <div className="d-flex align-items-center justify-content-center my-4">
          <Spin tip="Kraunama..." />
        </div>
      </div>
    );
  }
  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  const {
    date,
    amount,
    product_code,
    supplierCode,
    supplier_id,
    supplier_code_boolean,
    supplier_selected,
    supplier_code,
  } = variables;

  return (
    <div className="col-12 bg-silver">
      <div className="d-flex pt-3">
        <span style={{ width: "15%" }}>
          <Input
            type="number"
            min={0}
            disabled={mode === "Servisas"}
            value={amount}
            onChange={(e) => handleSupplyBlock("amount", e.target.value)}
          />
        </span>
        <span
          className="d-flex justify-content-center align-items-center"
          style={{ width: "5%" }}
        >
          *
        </span>
        <span style={{ width: "25%" }}>
          <SelectForm
            options={supply_items}
            onlyPlaceholder="Prekės"
            name="supplier_selected"
            value={supplier_selected}
            onChange={(e) => handleSupplyBlock("supplier_selected", e)}
          />
        </span>
        <span
          className="d-flex justify-content-center align-items-center"
          style={{ width: "5%" }}
        >
          <CheckBoxInput
            checked={supplier_code_boolean}
            onChange={(e) =>
              handleSupplyBlock("supplier_code_boolean", !supplier_code_boolean)
            }
          />
        </span>
        <span className="pr-1" style={{ width: "25%" }}>
          <SelectForm
            options={supplier_options}
            onlyPlaceholder="Tiekėjai"
            name="supplier_id"
            value={supplier_id}
            onChange={(e) => handleSupplyBlock("supplier_id", e)}
          />
        </span>
        <span className="pl-1" style={{ width: "25%" }}>
          <DatePicker
            style={{ width: "100%" }}
            placeholder="Data"
            disabledDate={(d) =>
              !d || d.isBefore(moment().format("YYYY-MM-DD"))
            }
            value={getDateFormat(date)}
            onChange={(e, arg) => handleSupplyBlock("date", arg)}
          />
        </span>
      </div>
      <div className="d-flex justify-content-between">
        <span style={{ width: "20%" }}></span>
        <span style={{ width: "25%" }}>
          {supplier_code_boolean && (
            <Input
              placeholder="ID"
              value={supplier_code}
              onChange={(e) =>
                handleSupplyBlock("supplier_code", e.target.value)
              }
            />
          )}
        </span>
        <span style={{ width: "5%" }}></span>
        <span></span>
        <span style={{ width: "25%" }}>
          {supplierCode && (
            <Input
              placeholder="ID"
              value={product_code}
              onChange={(e) =>
                handleSupplyBlock("product_code", e.target.value)
              }
            />
          )}
        </span>
        <span className="pl-1 pt-2 pb-3 text-right" style={{ width: "25%" }}>
          <FlopppyButton
            loading={loading}
            onClick={() => createNewOrderRow()}
          />
        </span>
      </div>
    </div>
  );
}

export default SupplyComponent;
