import {
  GET_SHOP_CATEGORIES,
  UPDATE_SHOP_CATEGORIES,
  LOADING_SHOP_CATEGORIES,
} from "../Actions/types";

const initialState = {
  shop_categories: [],
  loading_shop_categories: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_SHOP_CATEGORIES:
      return {
        ...state,
        loading_shop_categories: true,
      };
    case GET_SHOP_CATEGORIES:
      return {
        ...state,
        shop_categories: action.payload[0].children[0].children,
        loading_shop_categories: false,
      };
    case UPDATE_SHOP_CATEGORIES:
      return {
        ...state,
        shop_categories: action.payload,
      };
    default:
      return state;
  }
}