import React, { memo } from "react";
import { FaBox } from "react-icons/fa";
import styled from "styled-components";

function FilterMenu(props) {
  const pageCount = [5, 25, 50, 75, 100, 150, 200];

  return (
    <FilterMenuStyle className="row mx-2 ">
      <div className="px-1 filterMenu">
        <div className="firstBlock">
          {props.iconComponent ? (
            props.iconComponent
          ) : props.icon ? (
            <img
              src={props.icon}
              width="30"
              style={{ color: "#cacaca", marginRight: "4px", opacity: "0.5" }}
            />
          ) : (
            <FaBox size={30} style={{ color: "#cacaca", marginRight: "4px" }} />
          )}
          {props.length ? (
            <>
              <span className="filterMenuText first">Viso {props.text}</span>
              <span className="firstLength">{props.length}</span>
            </>
          ) : (
            <>
              <span className="filterMenuText first">Viso {props.text}</span>
              <span className="firstLength">
                {props.countPage * props.page + props.data_totals - props.countPage}/
                {props.totals}
              </span>
            </>
          )}
          {props.customText && <div className="ml-4">{props.customText}</div>}
        </div>
        <div>
          <span className="filterMenuText">{props.text2} puslapyje </span>
          <select
            value={props.countPage}
            onChange={(e) => props.onChangeCountPage(e.target.value)}
          >
            {pageCount.map((item) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        {props.tableLayout && (
          <div>
            <span className="filterMenuText">
              Lentelės{" "}
              <span
                className="underline"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  props.tableLayout.openModal("LayoutOfTablesModal")
                }
              >
                išdėstymas
              </span>
            </span>
          </div>
        )}
        {props.categoryFilterModal && (
          <div>
            <span className="filterMenuText">
              Filtruoti pagal{" "}
              <span
                className="underline"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  props.tableLayout.openModal("CategoryFilterModal")
                }
              >
                kategorijas
              </span>
            </span>
          </div>
        )}
        {props.statusFilter && (
          <div>
            <span className="filterMenuText">
              Filtruoti pagal{" "}
              <span
                className="underline"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  props.statusFilter.openModal("StatusFilterModal")
                }
              >
                statusus
              </span>
            </span>
          </div>
        )}
        {props.statusFilterOpen && (
          <div>
            <span className="filterMenuText">
              Filtruoti pagal{" "}
              <span
                className="underline"
                style={{ cursor: "pointer" }}
                onClick={props.statusFilterOpen}
              >
                statusus
              </span>
            </span>
          </div>
        )}
      </div>
    </FilterMenuStyle>
  );
}

const FilterMenuStyle = styled.div`
  .filterMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    .filterMenuText {
      font-size: 10px;
      line-height: 14px;
      color: #212121;
      font-weight: 400;
      padding-right: 9px;
      & .underline {
        font-weight: 900;
        border-bottom: 1px solid #212121;
      }
    }

    & select {
      border: 1px solid #f2f2f2;
      background-color: transparent;
      border-radius: 5px;
      padding: 4px 4px;
      font-size: 10px;

      line-height: 14px;
      color: #454545;
      font-weight: 400;
    }

    .firstBlock {
      width: 40%;
      display: flex;
      align-items: center;
      & .first {
        font-size: 13px;
        line-height: 14px;
        color: #727272;
        font-weight: 400;
        padding-right: 9px;
      }
      & .firstLength {
        color: #212121;
        font-weight: 400;
        font-size: 18px;
      }
    }
  }
`;

export default memo(FilterMenu);
