import React from "react";
import { connect } from "react-redux";
import {
  getCategories,
  getCategoryGarbageAmounts,
  setCategoryGarbageAmount,
} from "../../redux/Actions/categoryAction";
import CategoryChildren from "./item/categoryChildren";
import { FaPlus, FaMinus } from "react-icons/fa";
import { errorHandler } from "../../helpers/Utils";
import Container from "../../components/Layout/Container";
import ContentBlock from "../../components/Layout/ContentBlock";
import ContentValidation from "../../components/common/ContentValidation";
class TrashFeeCategoriesScreen extends React.Component {
  constructor() {
    super();
    this.state = {
      currentOpen: [],
      values: {},
    };
  }
  componentDidMount() {
    this.props.getCategories();
    getCategoryGarbageAmounts().then((res) => {
      if (res.success) {
        this.setState({
          values: res.data,
        });
      }
    });
  }

  handleToggle = (value) => {
    const currentIndex = this.state.currentOpen.indexOf(value);
    const newChecked = [...this.state.currentOpen];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({
      currentOpen: newChecked,
    });
  };

  updateGarbageCategory = async (value) => {
    try {
      this.setState({
        values: {
          ...this.state.values,
          [value.category_id]: value.amount,
        },
      });
      setCategoryGarbageAmount(value);
    } catch (err) {
      errorHandler(err);
    }
  };
  render() {
    return (
      <Container
        location={{
          locationName: "Šiukšlių mokestis",
          locationUrl: this.props.match.url,
        }}
      >
        <ContentBlock>
          <ContentValidation
            loader={this.props.loading_category}
            array={this.props.categories}
          >
            <div className="col-12">
              <ul className="list-group">
                {this.props.categories &&
                  this.props.categories.map((item) => {
                    return (
                      <React.Fragment key={item.id}>
                        <li
                          className="list-group-item node-treeview mb-2"
                          key={item.id}
                        >
                          <span
                            type="button"
                            onClick={() => {
                              this.handleToggle(item.id);
                            }}
                          >
                            {!this.state.currentOpen.includes(item.id) ? (
                              <FaPlus className="mr-2" />
                            ) : (
                              <FaMinus className="mr-2" />
                            )}

                            {item.name}
                          </span>
                          {typeof item.children !== undefined && (
                            <span
                              className="badge badge-primary badge-pill"
                              style={{ float: "right" }}
                            >
                              {item.hasOwnProperty("children") &&
                                item.children.length}
                            </span>
                          )}
                        </li>
                        {item.hasOwnProperty("children") &&
                          item.children.length > 0 &&
                          this.state.currentOpen.includes(item.id) && (
                            <CategoryChildren
                              children={item.children || []}
                              openID={item.id}
                              currentOpen={this.state.currentOpen}
                              handleToggle={this.handleToggle}
                              level={2}
                              values={this.state.values}
                              onChange={this.updateGarbageCategory}
                            />
                          )}
                      </React.Fragment>
                    );
                  })}
              </ul>
            </div>
          </ContentValidation>
        </ContentBlock>
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  categories: state.category.categories,
  loading_category: state.category.loading_category,
});

export default connect(mapStateToProps, {
  getCategories,
})(TrashFeeCategoriesScreen);
