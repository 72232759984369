import React, { useState, useMemo } from "react";
import { MainBlock } from "../../common/Buttons";
import {
  prettyDateFormat,
  returnEuroString,
  toFixedNumber,
} from "../../../helpers/Utils";
import TextInputModal from "../../common/TextInputModal";
import styled from "styled-components";
import { Fragment } from "react";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import MoneyBagSvg from "../../../../assets/images/money-bag.svg";
import SurveyorSvg from "../../../../assets/images/surveyor.svg";
import SalesSvg from "../../../../assets/images/sales.svg";
import FileSvg from "../../../../assets/images/file.svg";
import RenderChart from "./SalaryBlock/SalaryChart";
import ModalSpecial from "../../Layout/Modals/ModalSpecial";
import { addOverFlow } from "../../../helpers/modalFunc";
import { FiBox } from "react-icons/fi";
import BarankaUpSvg from "../../../../assets/images/baranka_up.svg";
import BarankaDownSvg from "../../../../assets/images/baranka_down.svg";
import { months_lt } from "../../../helpers/GlobalOptions";
import { connect } from "react-redux";
import Spinner from "../../common/Spinner";
import { Tooltip } from "antd";
import { IconExclamation, IconService } from "../../Layout/Icons";
import { salary_permissions_ids } from "../../../helpers/UserPermissionsOption";
// import snow_flake from "../../../../assets/images/snowflake.png";

class ManagersEditModal extends React.Component {
  constructor() {
    super();
    this.state = {
      action: "edit",
      preview_manager: false,
      preview_sales: false,
      // preview_items: true,
      preview_items: false,
      preview_salary: true,
      month: new Date().getMonth() + 1,
      filterItems: "",
    };
  }

  componentDidMount() {
    addOverFlow();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.objectData).length > 0) {
      const data = nextProps.objectData.data[0];

      if (data.length == 0) {
        return null;
      }
      const item = data.manager;
      const item1 = data.calculations;
      const item3 = data.SGG;
      const item4 = data.PAID_SUM;

      let newObj = {};

      if (item) {
        newObj = {
          preview_sales: true,
          table_number: item.table_number || "",
          manager_id: item.manager_id || "",
          firstname: item.firstname || "",
          lastname: item.lastname || "",
          department: item.department || "",
          working_since: item.working_since || "",
          status: item.status || "",
          left_work: item.left_work,
          vacations: item.vacations || "",
          filterItems: "",
        };
      }
      if (item1) {
        newObj = {
          ...newObj,
          rule_100_items_count: item1["100"].items_count || 0,
          rule_100_sum_of_sales: item1["100"].sum_of_sales.toFixed(2) || 0,

          rule_101_items_count: item1["101"].items_count || 0,
          rule_101_sum_of_sales: item1["101"].sum_of_sales.toFixed(2) || 0,

          rule_114_items_count: item1["114"].items_count || 0,
          rule_114_sum_of_sales: item1["114"].sum_of_sales.toFixed(2) || 0,

          rule_115_items_count: item1["115"].items_count || 0,
          rule_115_sum_of_sales: item1["115"].sum_of_sales.toFixed(2) || 0,

          rule_110_items_count: item1["110"].items_count || 0,
          rule_110_sum_of_sales: item1["110"].sum_of_sales.toFixed(2) || 0,

          rule_111_items_count: item1["111"].items_count || 0,
          rule_111_sum_of_sales: item1["111"].sum_of_sales.toFixed(2) || 0,

          rule_112_items_count: item1["112"].items_count || 0,
          rule_112_sum_of_sales: item1["112"].sum_of_sales.toFixed(2) || 0,

          rule_113_items_count: item1["113"].items_count || 0,
          rule_113_sum_of_sales: item1["113"].sum_of_sales.toFixed(2) || 0,

          rule_200_items_count: item1["200"].items_count || 0,
          rule_200_sum_of_sales: item1["200"].sum_of_sales.toFixed(2) || 0,

          rule_214_items_count: item1["214"].items_count || 0,
          rule_214_sum_of_sales: item1["214"].sum_of_sales.toFixed(2) || 0,

          rule_215_items_count: item1["215"].items_count || 0,
          rule_215_sum_of_sales: item1["215"].sum_of_sales.toFixed(2) || 0,
        };
        if (item1.totals) {
          const total_earnings = item1.totals.total_earnings;
          const total_items_ppg = item1.totals.total_items_ppg;
          const total_turnover = item1.totals.total_turnover;
          newObj = {
            ...newObj,
            total_earnings: Number(total_earnings)
              ? Number(total_earnings).toFixed(2)
              : 0,
            total_items: item1.totals.total_items || 0,
            total_earnings_ppg: item1.totals.total_earnings_ppg || 0,
            total_turnover: Number(total_turnover)
              ? Number(total_turnover).toFixed(2)
              : 0,
            total_items_ppg: Number(total_items_ppg)
              ? Number(total_items_ppg).toFixed(2)
              : 0,
            monthly_ssg_without_pvm: Number(item3)
              ? Number(item3).toFixed(2)
              : 0,
            paid_sum: Number(item4) ? Number(item4).toFixed(2) : 0,
          };
        }
      }

      this.setState({
        ...newObj,
        action: "edit",
      });
    }
  }

  openBlock = (block) => {
    this.setState({
      [block]: !this.state[block],
    });
  };

  findCurrentMonth = (month) => {
    const findMoth = months_lt.find((e) => e.value == month);
    return findMoth ? findMoth.label : "";
  };

  rulesBlock = () => {
    const infoBlocks = [
      {
        number: "00",
        title: "Seniena",
      },
      {
        number: "11",
        title: "BRAND su galiojimu",
      },
      {
        number: "14",
        title: "BRAND be galiojimo",
      },
      {
        number: "01",
        title: "Savikaina didesnė arba lygi pardavimo sumai",
      },
      {
        number: "12",
        title: "Kategorija su galiojimu",
      },
      {
        number: "15",
        title: "Kategorija be galiojimo",
      },
      {
        number: "10",
        title: "Prekė su galiojimu",
      },
      {
        number: "13",
        title: "Prekė be galiojimo",
      },
      {
        number: "999",
        title: "Nepritaikyta jokia taisyklė",
      },
    ];
    return (
      <div
        className="p-3 mt-1"
        style={{ backgroundColor: "#fafafa", borderRadius: 6 }}
      >
        <h6 className="font-weight-bold">Taisyklių paaiškinimai</h6>

        <p className="pb-2">
          <span className="pr-2">Pardavimai (1)</span>
          Grąžinimai (2)
        </p>

        <div className="d-flex flex-wrap">
          {infoBlocks.map((i) => (
            <div
              key={i.title}
              className="col-4 d-flex align-items-center pl-0 pb-2"
              // style={{ paddingRight: 15 }}
            >
              <div
                className="bg-light-orange rounded d-flex text-white justify-content-center align-items-center badge-primary"
                style={{ width: 36, height: 36 }}
              >
                <span className="font-weight-bold" style={{ fontSize: 15 }}>
                  {i.number}
                </span>
              </div>
              <span className="pl-3 text-black" style={{ fontSize: 15 }}>
                {i.title}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };
  itemsBlock = () => {
    const { filterItems } = this.state;
    return (
      <>
        <br />
        <div className="row">
          <div className="col-md-12">
            <MainBlock
              action={this.state.preview_items}
              onChange={() => this.openBlock("preview_items")}
              color="#ffcc69"
            >
              Prekės
            </MainBlock>
            {this.state.preview_items && (
              <>
                <TextInputModal
                  text="Filtravimas pagal pavadinimą arba kodą"
                  placeholder="Įvesti"
                  name="[pay_module]filter_item"
                  value={filterItems}
                  onChange={(e) => {
                    this.setState({
                      filterItems: e.target.value,
                    });
                  }}
                />
                <div className="table-responsive">
                  <table className="table table-sm table-hover mt-3">
                    <thead>
                      <tr className="text-muted">
                        <th className="text-black">Kodas</th>
                        <th className="text-black">Pavadinimas</th>
                        <th className="text-black">Pardavimo d.</th>
                        <th className="text-black">Taisyklė</th>
                        <th className="text-black float-right">Atlyginimas</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.objectData?.sale_items &&
                        this.props.objectData?.sale_items
                          .filter(
                            (e) =>
                              e.item_id
                                .toUpperCase()
                                .indexOf(filterItems.toUpperCase()) > -1 ||
                              e.name
                                .toUpperCase()
                                .indexOf(filterItems.toUpperCase()) > -1
                          )
                          .map((e) => (
                            <tr key={e.id}>
                              <td>{e.item_id}</td>
                              <td>{e.quantity * -1 + "*  " + e.name}</td>
                              <td>{prettyDateFormat(e.operation_date)}</td>
                              <td>{e.rule_code}</td>
                              <td className="float-right">
                                {returnEuroString(e.earnings)}
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  salaryBlock = () => {
    const {
      salary = {},
      related_percent = 0,
      position_in_filial = {},
      position_in_company = {},
      position_in_filial_services = {},
      current_month = 0,
      service_coeff = 0
    } = this.props.objectData;
    return (
      <>
        <br />
        <div className="row">
          <div className="col-md-12 bg-silver">
            <MainBlock
              action={this.state.preview_salary}
              onChange={() => this.openBlock("preview_salary")}
              color="#7EBFF7"
            >
              Darbuotojo alga
            </MainBlock>
            {this.state.preview_salary && (
              <>
                <div className="col-12 mt-4">
                  <div className="row">
                    <div className="col-6">
                      <CurrentPosition
                        title={"Pardavimų planas/faktas"}
                        fact={salary?.sales?.fact}
                        plan={salary?.sales?.plan}
                      />
                      <CurrentPosition
                        title={"Draudimo planas/faktas"}
                        fact={salary?.insurance?.fact}
                        plan={salary?.insurance?.plan}
                      />
                      <CurrentPosition
                        title={"Pratęstų garantijų planas/faktas"}
                        fact={salary?.warranty?.fact}
                        plan={salary?.warranty?.plan}
                      />
                      <h3
                        className="mt-3 font-weight-bold"
                        style={{ fontSize: 18 }}
                      >
                        {" "}
                        {this.findCurrentMonth(current_month)}
                      </h3>
                      {position_in_filial?.my_position && (
                        <RaceInfo
                          position={position_in_filial?.my_position}
                          position_title={"PADALINYJE"}
                          title={"Pirmas padalinyje"}
                          user={position_in_filial?.first}
                          block_text="Apyvarta"
                        />
                      )}
                      {position_in_company?.my_position && (
                        <RaceInfo
                          position={position_in_company?.my_position}
                          position_title={"ĮMONĖJE"}
                          title={"Pirmas įmonėje"}
                          user={position_in_company?.first}
                          block_text="Apyvarta"
                        />
                      )}
                      {position_in_filial_services?.my_position && (
                        <RaceInfo
                          position={position_in_filial_services?.my_position}
                          position_title={"PADALINYJE"}
                          title={"Pirmas padalinyje"}
                          user={position_in_filial_services?.first}
                          block_text="Paslaugos"
                        />
                      )}
                    </div>
                    <div className="col-6">
                      <SalaryInfo
                        Icon={SalesSvg}
                        title={"Pardavimai"}
                        childrenName={["Prekės", "Senienos"]}
                        totals={salary?.details?.products?.totals}
                        first={salary?.details?.products?.products}
                        second={salary?.details?.products?.olds}
                      />
                      <SalaryInfo
                        Icon={FileSvg}
                        title={"Paslaugos"}
                        childrenName={["Pratęstos garantijos", "Draudimas", "Sutarties administravimo mokestis"]}
                        totals={salary?.details?.services?.totals}
                        first={salary?.details?.services?.warranty}
                        second={salary?.details?.services?.insurance}
                        third={salary?.details?.services?.admin}
                      />
                      <SalaryInfoBlock className="average_text_size">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center font-weight-bold title mb-1">
                            <FiBox className="text-orange mr-2" />
                            Palydinčių prekių %
                          </div>
                          {related_percent} %
                        </div>
                        <ServiceCoefficient number={Number(service_coeff)} />
                      </SalaryInfoBlock>

                      <SalaryInfo
                        Icon={SurveyorSvg}
                        title={"Kita"}
                        childrenName={[]}
                        totals={salary?.details?.other?.totals}
                      />

                      <div
                        style={{ borderRadius: 40, height: 50, fontSize: 14 }}
                        className="btn btn-primary btn-lg btn-block d-flex justify-content-between align-items-center py-2"
                      >
                        <div className="d-flex align-items-center">
                          <img src={MoneyBagSvg} className="mr-2" />
                          <span>Suma</span>
                        </div>
                        <div>{toFixedNumber(salary?.earnings)} €</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <RenderChart history={salary?.history || []} />
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  render() {
    const { salary = {} } = this.props.objectData;

    const allowed_users = salary_permissions_ids

    if (!this.state.firstname) {
      return (
        <ModalSpecial text={"Kraunama"} onClose={this.props.onClose}>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "100%" }}
          >
            <Spinner />
          </div>
        </ModalSpecial>
      );
    }
    return (
      <ModalSpecial
        text={this.state.firstname + " " + this.state.lastname}
        onClose={this.props.onClose}
      >
        <div className="row">
          <div className="col-md-12">
            <MainBlock
              color={"#00aaa0"}
              action={this.state.preview_manager}
              onChange={() => this.openBlock("preview_manager")}
            >
              Menedžerio informacija
            </MainBlock>
            {this.state.preview_manager && (
              <div className="table-responsive">
                <table className="table table-sm table-hover mt-3">
                  <thead>
                    <tr className="text-muted">
                      <th className="text-black">Numeris</th>
                      <th className="text-black">Menedžerio kodas</th>
                      <th className="text-black">Vardas</th>
                      <th className="text-black">Pavardė</th>
                      <th className="text-black">Padalinys</th>
                      <th className="text-black">Dirba nuo</th>
                      <th className="text-black">Atostogų likutis</th>
                      <th className="text-black">Statusas</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{this.state.table_number}</td>
                      <td>{this.state.manager_id}</td>
                      <td>{this.state.firstname}</td>
                      <td>{this.state.lastname}</td>
                      <td>{this.state.department}</td>
                      <td>{this.state.working_since}</td>
                      <td>{this.state.vacations} d.</td>
                      <td className="d-flex align-items-center">
                        {this.state.status}
                        {this.state.left_work && (
                          <Tooltip
                            placement="top"
                            title={`Nuo ${this.state.left_work}`}
                          >
                            <IconExclamation className="text-sm ml-1" 
                            style={{
                              color: "#AFAFAF"
                            }}
                            />
                          </Tooltip>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <MainBlock
              action={this.state.preview_sales}
              onChange={() => this.openBlock("preview_sales")}
              color="red"
            >
              Pardavimai
            </MainBlock>
            {this.state.preview_sales && (
              <div className="table-responsive">
                <table className="table table-sm table-hover mt-3">
                  <thead>
                    <tr className="text-muted">
                      <th className="text-black">Taisyklė</th>
                      <th className="text-black">100</th>
                      <th className="text-black">101</th>
                      <th className="text-black">114</th>
                      <th className="text-black">115</th>
                      <th className="text-black">110</th>
                      <th className="text-black">111</th>
                      <th className="text-black">112</th>
                      <th className="text-black">113</th>
                      <th className="text-black">200</th>
                      <th className="text-black">214</th>
                      <th className="text-black">215</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b>Kiekis</b>
                      </td>
                      <td>{this.state.rule_100_items_count * -1}</td>
                      <td>{this.state.rule_101_items_count * -1}</td>
                      <td>{this.state.rule_114_items_count * -1}</td>
                      <td>{this.state.rule_115_items_count * -1}</td>
                      <td>{this.state.rule_110_items_count * -1}</td>
                      <td>{this.state.rule_111_items_count * -1}</td>
                      <td>{this.state.rule_112_items_count * -1}</td>
                      <td>{this.state.rule_113_items_count * -1}</td>
                      <td>{this.state.rule_200_items_count}</td>
                      <td>{this.state.rule_214_items_count}</td>
                      <td>{this.state.rule_215_items_count}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Suma</b>
                      </td>
                      <td>{this.state.rule_100_sum_of_sales}</td>
                      <td>{this.state.rule_101_sum_of_sales}</td>
                      <td>{this.state.rule_114_sum_of_sales}</td>
                      <td>{this.state.rule_115_sum_of_sales}</td>
                      <td>{this.state.rule_110_sum_of_sales}</td>
                      <td>{this.state.rule_111_sum_of_sales}</td>
                      <td>{this.state.rule_112_sum_of_sales}</td>
                      <td>{this.state.rule_113_sum_of_sales}</td>
                      <td>{this.state.rule_200_sum_of_sales}</td>
                      <td>{this.state.rule_214_sum_of_sales}</td>
                      <td>{this.state.rule_215_sum_of_sales}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="d-flex justify-content-between">
                  <div></div>
                  <div className="d-flex">
                    <p className="mt-2 mr-4 mt-2">
                      <span className="text-black" style={{ fontSize: "14px" }}>
                        <b>Visas kiekis:</b> {this.state.total_items * -1}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  {salary?.sales ? (
                    <div
                      className="d-flex mt-2 "
                      style={{ paddingLeft: "0.3rem" }}
                    >
                      <span
                        className="text-black mr-3"
                        style={{ fontSize: "14px" }}
                      >
                        <b>Pardavimų planas:</b>{" "}
                        {returnEuroString(salary?.sales?.plan)}
                      </span>
                      <span
                        className="text-black mr-3"
                        style={{ fontSize: "14px" }}
                      >
                        <b>Pratęstos garantijos planas:</b>{" "}
                        {returnEuroString(salary?.warranty?.plan)}
                      </span>
                      <span className="text-black" style={{ fontSize: "14px" }}>
                        <b>Draudimo planas:</b>{" "}
                        {returnEuroString(salary?.insurance?.plan)}
                      </span>
                    </div>
                  ) : (
                    <span
                      className="text-black mt-2"
                      style={{ fontSize: "14px", paddingLeft: "0.3rem" }}
                    >
                      <b>Plano nėra</b>
                    </span>
                  )}
                  <p className="float-right mt-2 mr-2 mt-2">
                    <span
                      className="text-black mr-3"
                      style={{ fontSize: "14px" }}
                    >
                      <b>Bendra mėnesio apyvarta:</b>{" "}
                      {this.state.total_turnover}
                    </span>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        {/*  */}
        {this.rulesBlock()}
        {this.itemsBlock()}
        {(allowed_users.includes(this.props.user.id) ||
          this.props.auth.user?.user_role?.identifier == "store_manager") &&
          this.salaryBlock()}
      </ModalSpecial>
    );
  }
}

const ServiceCoefficient = ({ number = 0 }) => {
  const number_color = useMemo(() => {
    if (number > 5) return "#29B6F6";
    if (number > 3.8) return "#4CAF50";
    if (number > 3) return "#FFEB3B";
    return "#EF5050";
  }, [number]);
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center font-weight-bold title">
        <IconService className="text-orange mr-1" />
        Paslaugos koef.
      </div>
      <div
        className="badge badge-pill badge-danger average_text_size"
        style={{ backgroundColor: number_color }}
      >
        {number}%
      </div>
    </div>
  );
};
function RaceInfo({ position, position_title, title, user, block_text }) {
  return (
    <div
      className="d-flex mb-3"
      style={{
        borderRadius: 10,
        backgroundColor: "#fff",
        boxShadow: "0px 0px 16px #00000012",
        padding: "12px 20px",
      }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center mr-2">
        <div>
          <img
            src={BarankaUpSvg}
            style={{ marginBottom: "-15px", width: 65 }}
          />
        </div>
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{
            // background: `url(${snow_flake})`,
            // backgroundPosition: "center",
            // backgroundSize: "cover",
            width: "80%",
          }}
        >
          <span className="font-weight-bold" style={{ fontSize: 24 }}>
            {position || 0}
          </span>
          <span className="font-weight-bold" style={{ fontSize: 9 }}>
            {position_title}
          </span>
        </div>
        <img src={BarankaDownSvg} style={{ width: 65 }} />
      </div>
      <div
        className="d-flex flex-column justify-content-center"
        style={{ flex: 1 }}
      >
        <p className="mb-0" style={{ fontSize: 15 }}>
          {title}
        </p>
        <h3 className="font-weight-bold mb-0" style={{ fontSize: 15 }}>
          {user || ""}
        </h3>
      </div>
      <div className="d-">
        <p className="mb-0" style={{ fontSize: 15, color: "silver" }}>
          {block_text}
        </p>
      </div>
    </div>
  );
}

function CurrentPosition({ title, plan, fact }) {
  const existing = plan;
  const total = fact;
  if (existing == 0 && total == 0) {
    return <div />;
  }

  const total_percentage_count = () => {
    if (existing == 0 && total > 0) {
      return 0;
    }
    if (existing == 0 && total == 0) {
      return 100;
    }
    const count = (Math.floor(total) / Math.floor(existing)) * 100;
    if (!count || count == "Infinity") {
      return 0;
    }
    return count;
  };
  const total_percentage = total_percentage_count();
  const renderPercentages = (value) => {
    let arr = [0, 25, 50, 75];
    if (value > 100) {
      let counter = 0;
      const totals = Math.floor(value / 25);

      [...new Array(totals)].forEach(() => {
        if (value > counter) {
          counter = counter + 25;
          if (arr.includes(counter)) return;
          arr.push(counter);
        }
      });
    }
    const lengthArr = arr.length;
    return (
      <div style={{ flexDirection: "row", position: "relative" }}>
        {arr.map((e, idx) => (
          <div
            className="light-size"
            key={idx}
            style={{
              marginRight: 25,
              position: "absolute",
              left: `${(idx / lengthArr) * 100}%`,
              fontWeight: 300,
            }}
          >
            {e}
            {" %"}
          </div>
        ))}
      </div>
    );
  };
  const prettierNumber = (value) => {
    if (Number.isInteger(value)) {
      return value;
    } else {
      return toFixedNumber(value);
    }
  };
  return (
    <CurrentPositionStyled
      className="average_text_size"
      width={total_percentage}
    >
      <div className="d-flex justify-content-between align-items-center medium-size mb-1">
        <div className="font-medium">{title}</div>
        <div>
          {`${toFixedNumber(existing)} €`} / {`${toFixedNumber(total)} €`}
        </div>
      </div>

      <div className="full_tank">
        <div className="current_tank">
          <div className="percentages_of_tank">
            {prettierNumber(total_percentage)} %
          </div>
        </div>
      </div>
      {renderPercentages(total_percentage)}
    </CurrentPositionStyled>
  );
}

const CurrentPositionStyled = styled.div`
  margin-bottom: 35px;
  .medium-size {
    font-size: 13px;
  }
  .light-size {
    font-size: 11px;
    color: #afafaf;
    border-left: 1px solid #00000019;
  }
  & .full_tank {
    background-color: #f3f4f8;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    position: relative;
    height: 27px;
    & .current_tank {
      background-color: #f7941d;
      width: ${(props) => `${props.width >= 100 ? 100 : props.width}%`};
      height: 100%;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      & .percentages_of_tank {
        position: absolute;
        font-weight: bold;
        font-size: 12px;
        padding-left: 3px;
        top: 5px;
        left: 8px;
        color: #000;
        /* color: ${(props) => (props.width > 25 ? "#fff" : "#000")}; */
      }
    }
  }
`;

function SalaryInfo({
  title,
  childrenName,
  Icon,
  totals = {},
  first = {},
  second = {},
  third = {},
}) {
  const [showDetails, setShowDetails] = useState(false);
  return (
    <SalaryInfoBlock className="average_text_size">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex align-items-center font-weight-bold title">
          <img src={Icon} className="mr-2" />
          {title}
        </div>
        {childrenName.length == 0 ? (
          <div></div>
        ) : showDetails ? (
          <div className="cursor-pointer" onClick={() => setShowDetails(false)}>
            <IoIosEyeOff className="mr-1" color={"#F3F4F8"} size={21} />
            <span className="color-silver">Bendras</span>
          </div>
        ) : (
          <div className="cursor-pointer" onClick={() => setShowDetails(true)}>
            <IoIosEye className="mr-1" color={"#F3F4F8"} size={21} />
            <span className="color-silver">Detalus</span>
          </div>
        )}
      </div>
      <div>
        {childrenName.length == 0 ? (
          <li className="d-flex justify-content-between">
            <div>DU Dalis</div>
            <div>{toFixedNumber(totals?.pay_part)} €</div>
          </li>
        ) : showDetails ? (
          <Fragment>
            <p className="label_margin">{childrenName[0]}</p>
            <ul>
              {[
                {
                  label: "Planas",
                  value: first?.plan,
                },
                {
                  label: "Faktas",
                  value: first?.fact,
                },
                {
                  label: "DU Dalis",
                  value: first?.pay_part,
                },
              ].map((e) => (
                <li key={e.label} className="d-flex justify-content-between">
                  <div className="font-medium">{e.label}</div>
                  <div>{toFixedNumber(e.value)} €</div>
                </li>
              ))}
            </ul>
            <hr />
            <p className="label_margin">{childrenName[1]}</p>
            <ul>
              {[
                {
                  label: "Planas",
                  value: second?.plan,
                },
                {
                  label: "Faktas",
                  value: second?.fact,
                },
                {
                  label: "DU Dalis",
                  value: second?.pay_part,
                },
              ].map((e) => (
                <li key={e.label} className="d-flex justify-content-between">
                  <div className="font-medium">{e.label}</div>
                  <div>{toFixedNumber(e.value)} €</div>
                </li>
              ))}
            </ul>
            { childrenName.length == 3 && 
            <div>
              <hr />
              <p className="label_margin">{childrenName[2]}</p>
              <ul>
                {[
                  {
                    label: "Planas",
                    value: third?.plan,
                  },
                  {
                    label: "Faktas",
                    value: third?.fact,
                  },
                  {
                    label: "DU Dalis",
                    value: third?.pay_part,
                  },
                ].map((e) => (
                  <li key={e.label} className="d-flex justify-content-between">
                    <div className="font-medium">{e.label}</div>
                    <div>{toFixedNumber(e.value)} €</div>
                  </li>
                ))}
              </ul>
            </div>
            }
          </Fragment>
        ) : (
          <ul>
            {[
              {
                label: "Planas",
                value: totals.plan,
              },
              {
                label: "Faktas",
                value: totals.fact,
              },
              {
                label: "DU Dalis",
                value: totals.pay_part,
              },
            ].map((e) => (
              <li key={e.label} className="d-flex justify-content-between">
                <div className="font-medium">{e.label}</div>
                <div>{toFixedNumber(e.value)} €</div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </SalaryInfoBlock>
  );
}

const SalaryInfoBlock = styled.div`
  padding: 19px 14px;
  background-color: #fff;
  box-shadow: 0px 0px 16px #00000012;
  border-radius: 10px;
  margin-bottom: 20px;
  color: #000;

  & .color-silver {
    color: #bcbcbc;
  }
  & ul {
    padding: 0;
    margin-bottom: 0px;
    & li {
      margin-top: 4px;
      margin-bottom: 4px;
      /* font: normal normal medium 15px/15px Barlow; */
    }
  }
  .label_margin {
    /* font: normal normal 600 15px/15px Barlow; */
    margin-top: 4px;
    /* margin-bottom: 4px; */
    padding-bottom: 0px;
  }
`;

const reduxProps = (state) => ({
  user: state.auth.user,
});
export default connect(reduxProps, {})(ManagersEditModal);
