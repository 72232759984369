import React from "react";
import { Input } from "antd";
import { AiOutlineClose, AiOutlinePlusCircle } from "react-icons/ai";
import { validateEmail } from "../../helpers/Utils";
import { Lock_svg } from "../../helpers/SvgStrings";
import { useState } from "react";

function EmailSetterInput({
  label = "El. paštas",
  onChangeEmails,
  emails,
  error,
  disabled,
}) {
  const [value, setValue] = useState("");
  let custom_emails = emails || [];
  return (
    <>
      <label
        htmlFor=""
        className={`editLabelForm text-bold my-1 ${error ? "text-danger" : ""}`}
      >
        {label} {disabled && Lock_svg()}
      </label>
      <Input
        disabled={disabled}
        className={error ? "error_border" : ""}
        placeholder="Įvesti"
        name="email"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        suffix={
          disabled ? (
            ""
          ) : (
            <AiOutlinePlusCircle
              className="cursor-pointer"
              onClick={() => {
                const trim_value = value?.trim() || "";
                if (!trim_value) {
                  return alert("Negalima priskirti su tuščios reikšmės.");
                }
                if (!validateEmail(trim_value)) {
                  return alert("Blogas el.pašto adresas");
                }
                if (
                  custom_emails.indexOf((d) =>
                    d.toLowerCase().includes(trim_value.toLowerCase())
                  ) !== -1
                ) {
                  return alert("El.pašto adresas duplikuojasi");
                }

                onChangeEmails([...custom_emails, trim_value]);

                setValue("");
              }}
              color="#FE9C00"
            />
          )
        }
      />
      {custom_emails.length > 0 && (
        <div className="my-2">
          {custom_emails.map((d) => (
            <span
              key={d}
              className="text-xs px-2 py-1 mb-2 cursor-pointer"
              style={{
                backgroundColor: "#FFF6E8",
                color: "#FE9C00",
                borderRadius: 3,
                marginRight: 4,
              }}
              onClick={() =>
                disabled
                  ? () => {}
                  : onChangeEmails(custom_emails.filter((email) => email !== d))
              }
            >
              {d}

              <AiOutlineClose color="#FE9C00" />
            </span>
          ))}
        </div>
      )}
      {error && <div className="text-danger">{error}</div>}
    </>
  );
}

export default EmailSetterInput;
