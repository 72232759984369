import React from "react";
import {
  ButtonDelete,
  ButtonEdit,
  EditElementClick,
} from "../../common/Buttons";
import { CommentStateType } from "../../common/CommentState_type";

function TableColumnCommentsState({ item, onDelete, index, onEdit }) {
  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle">
        <EditElementClick onEdit={() => onEdit("edit",index)} name={item.value} />
      </td>
      <td className="align-middle">
        <EditElementClick onEdit={() => onEdit("edit",index)} name={item.name} />
      </td>
      <td>
        <span style={{ color: `${item.color}` }}>{item.color}</span>
      </td>
      <td>
        <span>{item.identifier}</span>
      </td>
      <td>
        <span>{CommentStateType(item.comment_type)}</span>
      </td>
      <td>
        <span>{item.ordering}</span>
      </td>
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonEdit onEdit={() => onEdit("edit",index)} />
      </td>
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonDelete onDelete={() => onDelete(item.value)} />
      </td>
    </tr>
  );
}

export default TableColumnCommentsState;
