import React, { Fragment } from "react";
import { Switch } from "antd";
import moment from "moment";
import { EditElementClick } from "../../common/Buttons";
import { renderByColor } from "../../../helpers/GlobalFunctions";

export default function TableColumnSales3PL({
  item = {},
  statuses = {},
  setShowModal,
  returnValueByType,
}) {
  return (
    <tr className="align-middle tableColumn">
      <td className="align-middle" style={{ maxWidth: 400 }}>
        {moment(item.operation_date).format("YYYY-MM-DD")}
      </td>
      <td className="align-middle">
        {!item.delivered_at
          ? "-"
          : moment(item.delivered_at).format("YYYY-MM-DD")}
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => setShowModal(item.id)}
          name={item.document_number}
        />
      </td>
      <td className="align-middle">
        <small>{item.address?.company}</small>
        <div>
          <small>{item.address?.phone}</small>
        </div>
      </td>
      {returnValueByType(true, true, false) && (
        <td className="align-middle">{item.store_code}</td>
      )}
      <td className="align-middle">
        {item.courier_trackings.map((val, idx) => (
          <Fragment key={`${val.number}_${idx}`}>
            <a
              className={`badge badge-pill mr-1 ${
                item.delivered_at
                  ? "badge-success"
                  : item.picked_up_at
                  ? "badge-warning"
                  : "badge-light"
              }`}
              href={val.url}
              target="_blank"
            >
              {val.number}
            </a>
            {Number.isInteger((idx + 1) / 4) && <br />}
          </Fragment>
        ))}
      </td>
      {returnValueByType(true, true, false) && (
        <td className="align-middle">
          <Switch
            checked={item.pn}
            checkedChildren={"|"}
            unCheckedChildren={"0"}
          />
        </td>
      )}
      <td className="align-middle">{statuses[item.status]}</td>
      {returnValueByType(true, true, false) && (
        <td className="align-middle">
          {item.sale_states.length > 0 && (
            <div
              className="badge badge-pill badge-warning px-3 py-1 font-weight-bold"
              style={{
                backgroundColor: renderByColor(
                  item.sale_states[item.sale_states.length - 1].mscan_state_code
                ),
              }}
            >
              {item.sale_states[item.sale_states.length - 1].state_text}
            </div>
          )}
        </td>
      )}
    </tr>
  );
}
