import React from "react";
import { MdPeople } from "react-icons/md";
import {
  FaAddressCard,
  FaGlobeAfrica,
  FaBoxOpen,
  FaUser,
  FaComment,
  FaWaveSquare,
  FaShippingFast,
} from "react-icons/fa";
import { AiOutlineShopping } from "react-icons/ai";
import { GoPrimitiveDot } from "react-icons/go";
import { FiPackage } from "react-icons/fi";
import {
  TiMessages,
  TiDocument,
  TiDocumentText,
  TiFolder,
} from "react-icons/ti";
import { FiShoppingCart, FiSettings } from "react-icons/fi";
import { IoIosDocument } from "react-icons/io";
import dpdIcon from "../../assets/images/dpd.svg";
import omnivaIcon from "../../assets/images/omniva.svg";
import venipakIcon from "../../assets/images/venipak.svg";
import Loterija from "../../assets/images/loterija.svg";
import Bilietai from "../../assets/images/bilietai.svg";
import Dalyviai from "../../assets/images/dalyviai.svg";
import lpexpress from "../../assets/images/lpexpress.svg";
import rivilePardavimai from "../../assets/images/pardavimai.svg";
import rivilePirkimai from "../../assets/images/pirkimai.svg";
import rivileLogo from "../../assets/images/rivile_logo.svg";
import bigboxicon from "../../assets/images/bigboxicon.svg";
import GuideSvg from "../../assets/images/guide.svg";
import MarketingSvg from "../../assets/images/marketing.svg";
import sales_svg from "../../assets/images/sidebar/sales.svg";
import internal_svg from "../../assets/images/sidebar/internal.svg";
import sb_leasing_svg from "../../assets/images/sidebar/sb_leasing.svg";
import { IconEnergyLabel } from "../components/Layout/Icons";

const UserPermissionsOption = (auth, additional_information = {}) => {
  return [
    {
      uuid: "orders",
      title: "Užsakymai",
      url: "/orders",
      icon: <FaBoxOpen className="mr-3" />,
      action: true,
    },
    {
      uuid: "service",
      title: "Servisas",
      icon: <TiFolder className="mr-3" />,
      options: [
        {
          uuid: "service_repair",
          title: "Servisai",
          url: "/service/repair",
          action: true,
        },
        {
          uuid: "service_contacts",
          title: "Servisų kontaktai",
          url: "/service/contacts",
        },
      ],
    },
    {
      uuid: "products",
      title: "Produktai",
      url: "/products",
      icon: <TiMessages className="mr-3" />,
    },
    {
      uuid: "documents",
      title: "Dokumentai",
      url: "/documents",
      icon: <IoIosDocument className="mr-3" />,
      action: true,
    },
    {
      uuid: "leasings",
      title: "Lizingas",
      url: "/leasings",
      icon: (
        <img
          src={sb_leasing_svg}
          className="mr-3"
          style={{ width: "16px", height: "16px" }}
        />
      ),
      action: true,
    },
    {
      uuid: "supplier_invoices",
      title: "Tiekėjų sąskaitos",
      url: "/suppliers-invoices",
      icon: <TiDocument className="mr-3" />,
    },
    {
      uuid: "logistic",
      title: "Logistika",
      icon: <FiPackage className="mr-3" />,
      options: [
        {
          uuid: "logistic_dpd",
          title: "DPD",
          icon: (
            <img
              src={dpdIcon}
              className="mr-3"
              style={{ width: "16px", height: "16px" }}
            />
          ),
          options: [
            {
              uuid: "logistic_dpd_manifest",
              title: "DPD Manifestai",
              url: "/dpd/manifest",
            },
            {
              uuid: "logistic_dpd_shipment",
              title: "DPD Siuntos",
              url: "/dpd/shipment",
            },
          ],
        },
        {
          uuid: "logistic_omniva",
          title: "Omniva",
          icon: (
            <img
              src={omnivaIcon}
              className="mr-3"
              style={{ width: "16px", height: "16px" }}
            />
          ),
          options: [
            {
              uuid: "logistic_omniva_manifest",
              title: "Omniva Manifestai",
              url: "/omniva/manifest",
            },
            {
              uuid: "logistic_omniva_shipment",
              title: "Omniva Siuntos",
              url: "/omniva/shipment",
            },
          ],
        },
        {
          uuid: "logistic_lp_express",
          title: "LP express",
          icon: (
            <img
              src={lpexpress}
              className="mr-3"
              style={{ width: "16px", height: "16px" }}
            />
          ),
          options: [
            {
              uuid: "logistic_lp_express_manifest",
              title: "LP Express Manifestai",
              url: "/lp_express/manifest",
            },
            {
              uuid: "logistic_lp_express_shipment",
              title: "LP Express Siuntos",
              url: "/lp_express/shipment",
            },
          ],
        },
        {
          uuid: "logistic_itella",
          title: "Itella",
          icon: <GoPrimitiveDot className="mr-3" />,
          options: [
            {
              uuid: "logistic_itella_manifest",
              title: "Itella Manifestai",
              url: "/itella/manifest",
            },
            {
              uuid: "logistic_itella_shipment",
              title: "Itella Siuntos",
              url: "/itella/shipment",
            },
          ],
        },
        {
          uuid: "logistic_venipak",
          title: "Venipak",
          icon: (
            <img
              src={venipakIcon}
              className="mr-3"
              style={{ width: "16px", height: "16px" }}
            />
          ),
          options: [
            {
              uuid: "logistic_venipak_manifest",
              title: "Venipak Manifestai",
              url: "/venipak/manifest",
            },
            {
              uuid: "logistic_venipak_shipment",
              title: "Venipak Siuntos",
              url: "/venipak/shipment",
            },
          ],
        },
        {
          uuid: "logistic_technorama",
          title: "Technorama",
          icon: <FaBoxOpen className="mr-3" />,
          options: [
            {
              uuid: "logistic_technorama_shipment",
              title: "Atsiėmimai",
              url: "/own_transport/shipment",
            },
          ],
        },
        {
          uuid: "logistic_3pl",
          title: "3PL",
          icon: <span className="mr-3">{renderPl3()}</span>,
          options: [
            {
              uuid: "logistic_3pl_purchases",
              title: "Pirkimai",
              icon: (
                <img
                  src={rivilePardavimai}
                  className="mr-3"
                  style={{ width: "16px", height: "16px" }}
                />
              ),
              url: "/3pl/purchases",
            },
            {
              uuid: "logistic_3pl_sales",
              title: "Pardavimai",
              icon: (
                <img
                  src={sales_svg}
                  className="mr-3"
                  style={{ width: "16px", height: "16px" }}
                />
              ),
              url: "/3pl/sales",
            },
            {
              uuid: "logistic_3pl_internal",
              title: "Vidiniai",
              icon: (
                <img
                  src={internal_svg}
                  className="mr-3"
                  style={{ width: "16px", height: "16px" }}
                />
              ),
              url: "/3pl/internal",
            },
            {
              uuid: "logistic_3pl_shipments",
              title: "Siuntos",
              icon: <FaShippingFast className="mr-3 text-l" />,
              url: "/3pl/shipments",
            },
          ],
        },
      ],
    },
    {
      uuid: "commerce",
      title: "Komercija",
      icon: <TiFolder className="mr-3" />,
      options: [
        {
          uuid: "commerce_orders",
          title: "Užsakymai",
          url: "/commerce/orders"
        },
        {
          uuid: "commerce_order_show",
          title: "Užsakymai show",
          url: "/commerce/orders/:id",
          visible: false,
        }
      ],
    },
    {
      uuid: "rivile",
      title: "Rivilė",
      icon: (
        <img
          src={rivileLogo}
          className="mr-3"
          style={{ width: "16px", height: "16px" }}
        />
      ),
      dot: additional_information?.rivile_request_documents,
      options: [
        {
          uuid: "rivile_bigbox",
          title: "BigBox",
          icon: (
            <img
              src={bigboxicon}
              className="mr-3"
              style={{ width: "16px", height: "16px" }}
            />
          ),
          options: [
            {
              uuid: "rivile_bigbox_sales",
              title: "Pardavimai",
              url: "/rivile-pardavimai",
              icon: (
                <img
                  src={rivilePardavimai}
                  className="mr-3"
                  style={{ width: "16px", height: "16px" }}
                />
              ),
            },
            {
              uuid: "rivile_bigbox_purchases",
              title: "Pirkimai",
              url: "/rivile-pirkimai",
              icon: (
                <img
                  src={rivilePirkimai}
                  className="mr-3"
                  style={{ width: "16px", height: "16px" }}
                />
              ),
            },
            {
              uuid: "rivile_bigbox_orders",
              title: "Užsakymai",
              url: "/rivile/bigbox",
              action: true,
              dot: additional_information?.rivile_request_documents,
            },
          ],
        },
      ],
    },
    {
      uuid: "reports",
      title: "Ataskaitos",
      icon: (
        <img
          src={GuideSvg}
          className="mr-3"
          style={{ width: "16px", height: "16px" }}
        />
      ),
      url: "/reports",
      action: true,
    },
    {
      uuid: "marketing",
      title: "Marketingas",
      icon: (
        <img
          src={MarketingSvg}
          className="mr-3"
          style={{ width: "16px", height: "16px" }}
        />
      ),
      options: marketingRender(auth),
    },
    {
      uuid: "goods",
      title: "Prekės",
      icon: <FiShoppingCart className="mr-3" />,
      options: [
        {
          uuid: "goods_descriptions",
          title: "Aprašymai",
          url: "/goods/descriptions/:page?",
          icon: false,
        },
        {
          uuid: "goods_repricings",
          title: "Perkainavimai",
          url: "/goods/repricings",
          icon: false,
        },
        {
          uuid: "goods_repricing",
          title: "Perkainavimas",
          url: "/goods/repricings/:action",
          icon: false,
          visible: false,
        },
        {
          uuid: "goods_families",
          title: "Kategorijos",
          url: "/goods/families",
          icon: false,
        },
        {
          uuid: "goods_leasings",
          title: "Lizingas",
          url: "/goods/leasings",
          icon: false,
        },
        {
          uuid: "goods_brands",
          title: "Pr. ženklai",
          url: "/goods/brands",
          icon: false,
        },
      ],
    },
    ...withPermissions(auth),
    {
      uuid: "options",
      title: "Nustatymai",
      icon: <FiSettings className="mr-3" />,
      options: [
        {
          uuid: "options_trash_fee",
          title: "Šiukšlių mokestis",
          icon: <FiSettings className="mr-3" />,
          url: "/options/trash-fee",
        },
        {
          uuid: "options_sms_templates",
          title: "Žinučių šablonai",
          icon: <TiMessages className="mr-3" />,
          url: "/options/sms-templates",
        },
        {
          uuid: "options_stores",
          title: "Parduotuvės",
          icon: <AiOutlineShopping className="mr-3" />,
          url: "/options/stores",
        },
        {
          uuid: "options_suppliers",
          title: "Tiekėjai",
          icon: <MdPeople className="mr-3" />,
          url: "/options/suppliers",
        },
        {
          uuid: "options_countries",
          title: "Šalys",
          icon: <FaGlobeAfrica className="mr-3" />,
          url: "/options/countries",
        },
        {
          uuid: "options_pickup_addresses",
          title: "Paėmimo adresai",
          icon: <FaAddressCard className="mr-3" />,
          url: "/options/pickup-addresses",
        },
        {
          uuid: "options_users",
          title: "Vartotojai",
          icon: <FaUser className="mr-3" />,
          url: "/options/users",
        },
        {
          uuid: "options_user_permissions",
          title: "Rolės",
          icon: <FaUser className="mr-3" />,
          url: "/options/user_permissions",
        },
        {
          uuid: "options_pickup_users",
          title: "Atsiėmimų vartotojai",
          icon: <FaUser className="mr-3" />,
          url: "/options/pickup_users",
        },
        {
          uuid: "options_comment_states",
          title: "Komentarų būsenos",
          icon: <FaComment className="mr-3" />,
          url: "/options/comments_state",
        },
        {
          uuid: "options_rivile",
          title: "Rivile",
          icon: (
            <img
              src={rivileLogo}
              className="mr-3"
              style={{ width: "16px", height: "16px" }}
            />
          ),
          options: [
            {
              uuid: "options_rivile_store",
              title: "Parduotuvės",
              url: "/options/rivile/stores",
            },
            {
              uuid: "options_rivile_service",
              title: "Paslaugos",
              url: "/options/rivile/services",
            },
            {
              uuid: "options_rivile_pvm",
              title: "PVM 25",
              url: "/options/rivile/pvm",
            },
            {
              uuid: "options_rivile_payment",
              title: "Mokėjimo tipai",
              url: "/options/rivile/payment",
            },
          ],
        },
        {
          uuid: "options_statuses",
          title: "Statusai",
          icon: <FaWaveSquare className="mr-3" />,
          url: "/options/statuses",
        },
        {
          uuid: "options_documents_options",
          title: "Dokumentų nustatymai",
          icon: <TiDocument className="mr-3" />,
          url: "/options/documents",
        },
      ],
    },
    {
      uuid: "exports",
      title: "Eksportavimai",
      icon: <TiFolder className="mr-3" />,
      options: [
        {
          uuid: "exports_minus",
          title: "Minusas",
          url: "",
        },
        {
          uuid: "exports_price_protection",
          title: "Kainos apsauga",
          url: "",
        },
        {
          uuid: "exports_adrem_balances",
          title: "ADREM likučiai",
          url: "",
        },
        {
          uuid: "exports_balances_of_suppliers",
          title: "Tiekėjų likučiai",
          url: "",
        },
        {
          uuid: "sku_url_export",
          title: "SKU + URL eksportavimas",
          url: "",
        },
        {
          uuid: "exports_orders",
          title: "Užsakymai",
          url: "",
        },
        {
          uuid: "exports_retail_vs_online",
          title: "Retail vs Online (Kaina)",
          url: "",
        },
      ],
    },
    {
      uuid: "energy_labels",
      title: "Energijos efektyvumo etiketės",
      url: "/energy_labels",
      icon: <IconEnergyLabel
        className="mr-3"
      // style={{
      //   width: 32,
      //   height: 32,
      // }}
      />,
      // icon: <FaBoxOpen className="mr-3" />,
    },
  ];
};

export default UserPermissionsOption;

const marketingRender = (auth) => {
  const arr = [
    {
      uuid: "marketing_lottery",
      title: "Loterija",
      icon: (
        <img
          src={Loterija}
          className="mr-3"
          style={{ width: "16px", height: "16px" }}
        />
      ),
      options: [
        {
          uuid: "marketing_lottery_tickets",
          title: "Bilietai",
          icon: (
            <img
              src={Bilietai}
              className="mr-3"
              style={{ width: "16px", height: "16px" }}
            />
          ),
          url: "/lottery/tickets",
        },
        {
          uuid: "marketing_lottery_participants",
          title: "Dalyviai",
          icon: (
            <img
              src={Dalyviai}
              className="mr-3"
              style={{ width: "16px", height: "16px" }}
            />
          ),
          url: "/lottery/participants",
        },
      ],
    },
    {
      uuid: "marketing_guide",
      title: "Gidas",
      icon: (
        <img
          src={GuideSvg}
          className="mr-3"
          style={{ width: "16px", height: "16px" }}
        />
      ),
      url: "/marketing/guide",
    },
    {
      uuid: "marketing_posters",
      title: "Plakatai",
      icon: (
        <span style={{ height: 16 }} className="mr-3">
          {renderPosterSvg()}
        </span>
      ),
      url: "/marketing/posters",
    },
  ];
  return arr;
};

const withPermissions = (auth) => {
  if (salary_permissions_ids.includes(auth.id)) {
    return [
      {
        uuid: "staff",
        title: "Personalas",
        permissions: salary_permissions_ids.includes(auth.id),
        icon: <TiDocumentText className="mr-3" />,
        options: [
          {
            uuid: "staff_pay",
            title: "Algų modulis",
            permissions: salary_permissions_ids.includes(auth.id),
            url: "/pay-module",
          },
        ],
      },
    ];
  }
  return [];
};

const renderPosterSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="19"
    viewBox="0 0 13 19"
    fill="none"
  >
    <g clipPath="url(#clip0_93_3372)">
      <path
        d="M12.996 18.9987H0V3.53437C0 3.15145 -2.74384e-08 3.15145 0.378054 3.15086C0.693387 3.15086 1.01102 3.14609 1.32405 3.15086C1.47021 3.15086 1.53293 3.09479 1.52775 2.94151C1.52142 2.75662 1.52775 2.57112 1.52775 2.36416H2.28789V-0.00189209H3.05263V2.34925H3.8237C3.8237 2.56456 3.82773 2.74827 3.8237 2.93137C3.81967 3.07451 3.85995 3.15145 4.01647 3.15145C5.69747 3.14668 7.37829 3.1439 9.05891 3.1431C9.09547 3.13547 9.13088 3.12284 9.16422 3.10553V2.37549H9.92838V-0.00129553H10.7029V2.35879H11.451C11.4567 2.48464 11.4648 2.59021 11.4659 2.69578C11.4711 3.20036 11.4187 3.14728 11.9194 3.15026C12.1961 3.15026 12.4735 3.1598 12.7503 3.15026C12.9384 3.14191 13.015 3.21706 12.9942 3.40911C12.9911 3.46871 12.9911 3.52845 12.9942 3.58804V18.9987H12.996ZM0.787757 3.99004V18.2019H12.2128V3.95008C12.0367 3.95008 11.8768 3.95843 11.718 3.95008C11.535 3.93755 11.4533 4.00495 11.4665 4.20297C11.478 4.37653 11.4665 4.55129 11.4665 4.72246H9.17227C9.17227 4.53399 9.16307 4.3646 9.17227 4.20058C9.18608 4.00078 9.10207 3.93934 8.92024 3.94948C8.71021 3.96141 8.4996 3.94948 8.28727 3.94948C6.90222 3.94948 5.51717 3.95664 4.13213 3.94352C3.87491 3.94352 3.78687 4.02165 3.81507 4.2805C3.82413 4.42805 3.82509 4.576 3.81794 4.72365H1.54962C1.54099 4.65984 1.53121 4.61212 1.5289 4.56441C1.49783 3.8797 1.49841 3.8797 0.828612 3.96797C0.819981 3.96797 0.811925 3.97573 0.787757 3.99004ZM2.32242 3.17531V3.91907H3.0417V3.17531H2.32242ZM9.9537 3.90952H10.6839V3.17531H9.9537V3.90952Z"
        fill="black"
      />
      <path
        d="M10.4987 12.6498H2.50024L4.77605 8.70793L5.70306 10.295L7.4558 7.12201C8.47833 8.98109 9.47612 10.7919 10.4987 12.6498ZM7.45235 8.72343C6.86196 9.79701 6.3015 10.811 5.72608 11.8577H9.17863L7.45235 8.72343ZM3.84846 11.8607C4.81748 11.9293 4.96709 11.8815 5.18748 11.2046C5.30832 10.8312 4.92739 10.6082 4.77605 10.2575L3.84846 11.8607Z"
        fill="black"
      />
      <path
        d="M6.49764 14.2601C7.45285 14.2601 8.40806 14.2601 9.36326 14.2601C9.45821 14.2601 9.58537 14.2339 9.64177 14.2858C9.75685 14.3931 9.90589 14.5417 9.91049 14.6782C9.91452 14.7928 9.74937 14.9377 9.62911 15.0242C9.55545 15.0767 9.42541 15.051 9.32068 15.051C7.43904 15.051 5.55721 15.051 3.67519 15.051C3.24937 15.051 3.05718 14.9234 3.06236 14.6478C3.06754 14.3723 3.26145 14.2601 3.689 14.2601C4.62579 14.2582 5.562 14.2582 6.49764 14.2601Z"
        fill="black"
      />
      <path
        d="M6.49714 16.6375C6.02068 16.6375 5.54423 16.6411 5.06778 16.6375C4.76626 16.6375 4.59766 16.4878 4.59766 16.2421C4.59766 15.9963 4.7628 15.849 5.06893 15.8472C6.02183 15.8425 6.97493 15.8425 7.92822 15.8472C8.23319 15.8472 8.39892 15.9999 8.39834 16.2433C8.39777 16.4866 8.22571 16.6351 7.92707 16.6375C7.45062 16.6411 6.97359 16.6375 6.49714 16.6375Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_93_3372">
        <rect width="13" height="19" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const renderPl3 = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.55143 12.3194V9.84503H9.15152V16.367C9.0914 16.3707 9.03239 16.3774 8.97338 16.3774C7.92793 16.3774 6.88248 16.3774 5.8374 16.3807C5.70862 16.3807 5.64478 16.3381 5.58095 16.2268C4.69508 14.6888 2.49692 14.6906 1.61216 16.2305C1.54759 16.3418 1.48116 16.3859 1.3546 16.3788C1.12822 16.3696 0.900722 16.3763 0.663574 16.3763V9.84614H3.25809V12.322C3.34308 12.2849 3.40357 12.2604 3.46147 12.2315C3.88158 12.0227 4.30503 11.8199 4.71846 11.5989C4.86134 11.5229 4.96897 11.5314 5.10703 11.6041C5.51526 11.8195 5.93463 12.0198 6.34954 12.2255C6.40299 12.2519 6.45828 12.276 6.55143 12.3194Z"
      fill="black"
    />
    <path
      d="M13.0947 8.63993C12.7533 8.20647 12.4248 7.79043 12.0975 7.37366C11.5668 6.69844 11.0305 6.02766 10.508 5.34651C9.05911 3.4573 9.96836 0.549513 12.5336 0.0556113C14.2979 -0.284039 16.0555 0.972962 16.3279 2.75724C16.4764 3.7265 16.2678 4.61122 15.6599 5.38767C14.8385 6.43925 14.0146 7.4891 13.1882 8.53722C13.1645 8.56428 13.1385 8.59061 13.0947 8.63993ZM13.0921 5.57307C13.7014 5.57307 14.2858 5.33162 14.7172 4.90169C15.1485 4.47176 15.3916 3.88844 15.3931 3.27969C15.3931 2.02454 14.368 0.99966 13.104 0.990761C11.8462 0.982232 10.796 2.02899 10.7997 3.28562C10.8034 4.54225 11.8377 5.57084 13.0921 5.57307Z"
      fill="black"
    />
    <path
      d="M13.327 12.4506H13.0694C12.6612 12.4506 12.253 12.4469 11.8447 12.4506C11.3946 12.4558 11.1329 12.7224 11.13 13.1696C11.13 13.3364 11.13 13.5033 11.13 13.6705C11.13 14.1526 11.3875 14.4092 11.8759 14.4121C12.4512 14.4121 13.0264 14.4121 13.6016 14.4121H13.8243C13.9876 15.0625 14.1487 15.7051 14.3172 16.3773C14.0618 16.3773 13.8239 16.3811 13.586 16.3736C13.5489 16.3736 13.5025 16.3254 13.4829 16.2876C12.823 15.0225 11.0598 14.6075 9.87964 15.7058C9.87259 15.7125 9.85849 15.711 9.83771 15.7155V11.8002H12.7982C13.1118 11.8002 13.183 11.8551 13.2617 12.1584C13.2832 12.247 13.2995 12.336 13.327 12.4506Z"
      fill="black"
    />
    <path
      d="M4.25049 6.34655C3.76506 5.68765 3.29114 5.05729 2.83466 4.41507C2.74188 4.28455 2.6966 4.11287 2.658 3.95306C2.45091 3.08836 3.03877 2.18176 3.91017 2.0112C4.83575 1.82951 5.7064 2.41907 5.86413 3.32048C5.93836 3.75023 5.86821 4.15218 5.62067 4.50147C5.18498 5.1144 4.72664 5.70952 4.25049 6.34655ZM4.90218 3.60266C4.90208 3.51763 4.88519 3.43345 4.85248 3.35496C4.81977 3.27646 4.77187 3.20518 4.71154 3.14521C4.6512 3.08524 4.57962 3.03776 4.50089 3.00548C4.42215 2.9732 4.33782 2.95676 4.25271 2.9571C4.07758 2.95798 3.90996 3.02832 3.78671 3.15264C3.66346 3.27696 3.59467 3.4451 3.59546 3.62008C3.60065 3.79156 3.67155 3.95449 3.79351 4.07525C3.91548 4.196 4.07919 4.26535 4.25086 4.26898C4.33757 4.26816 4.42326 4.25021 4.503 4.21617C4.58274 4.18214 4.65496 4.13268 4.7155 4.07064C4.77603 4.00861 4.82369 3.93523 4.85573 3.85472C4.88777 3.77421 4.90355 3.68817 4.90218 3.60154V3.60266Z"
      fill="black"
    />
    <path
      d="M17.3418 14.8367C16.8813 14.2164 16.4036 13.6131 15.9687 12.9772C15.5975 12.4365 15.6462 11.7035 16.0299 11.1684C16.4044 10.6467 17.0865 10.3745 17.694 10.5232C18.388 10.6934 18.8189 11.1302 18.9651 11.8355C19.052 12.2556 18.9766 12.6564 18.7339 13.0001C18.3034 13.6097 17.8432 14.1989 17.3979 14.7963C17.3808 14.8119 17.362 14.8254 17.3418 14.8367ZM17.3645 12.7732C17.4496 12.7738 17.534 12.7575 17.6128 12.7251C17.6916 12.6927 17.7631 12.645 17.8232 12.5848C17.8833 12.5245 17.9308 12.4529 17.9629 12.3741C17.995 12.2953 18.0111 12.2109 18.0102 12.1258C18.0111 12.0391 17.9946 11.9532 17.9617 11.873C17.9288 11.7928 17.8801 11.72 17.8186 11.6589C17.7572 11.5978 17.684 11.5496 17.6036 11.5171C17.5232 11.4846 17.4371 11.4686 17.3504 11.4699C17.2635 11.469 17.1774 11.4854 17.097 11.5182C17.0166 11.551 16.9435 11.5995 16.8821 11.6608C16.8207 11.7222 16.7722 11.7952 16.7394 11.8755C16.7066 11.9559 16.6902 12.042 16.6911 12.1287C16.6921 12.2155 16.7104 12.3012 16.7449 12.3808C16.7794 12.4604 16.8295 12.5324 16.8922 12.5924C16.9549 12.6524 17.029 12.6993 17.1101 12.7304C17.1912 12.7614 17.2777 12.776 17.3645 12.7732Z"
      fill="black"
    />
    <path
      d="M11.4658 15.7307C11.7896 15.7312 12.1059 15.8277 12.3748 16.008C12.6436 16.1882 12.8529 16.4441 12.9761 16.7433C13.0992 17.0425 13.1308 17.3714 13.0668 17.6885C13.0027 18.0056 12.8459 18.2966 12.6163 18.5246C12.3866 18.7526 12.0944 18.9074 11.7766 18.9694C11.4588 19.0313 11.1298 18.9977 10.8311 18.8727C10.5325 18.7478 10.2777 18.537 10.099 18.2673C9.92031 17.9975 9.82575 17.6809 9.82729 17.3574C9.82694 17.1428 9.86916 16.9303 9.95151 16.7321C10.0339 16.5339 10.1547 16.3539 10.3071 16.2027C10.4594 16.0514 10.6403 15.9318 10.8391 15.8508C11.038 15.7698 11.251 15.729 11.4658 15.7307ZM11.4658 18.0144C11.6376 18.0124 11.8019 17.9435 11.9236 17.8223C12.0453 17.7011 12.1149 17.5372 12.1175 17.3655C12.118 17.2793 12.1015 17.1937 12.0689 17.1138C12.0363 17.0339 11.9883 16.9612 11.9276 16.8998C11.8669 16.8384 11.7947 16.7896 11.7151 16.7561C11.6355 16.7226 11.5501 16.7051 11.4638 16.7046C11.3774 16.7041 11.2918 16.7206 11.2118 16.7531C11.1318 16.7857 11.059 16.8336 10.9976 16.8943C10.9362 16.9549 10.8873 17.0271 10.8538 17.1066C10.8203 17.1861 10.8027 17.2715 10.8022 17.3578C10.8032 17.5328 10.8736 17.7003 10.998 17.8236C11.1224 17.9469 11.2906 18.0159 11.4658 18.0156V18.0144Z"
      fill="black"
    />
    <path
      d="M3.58948 15.7307C4.01927 15.7348 4.43016 15.9078 4.73332 16.2122C5.03648 16.5166 5.20755 16.928 5.20955 17.3575C5.21155 17.7869 5.04431 18.1999 4.744 18.5071C4.44369 18.8143 4.03443 18.9911 3.6047 18.9993C3.38793 19.0034 3.17251 18.9644 2.97096 18.8846C2.76941 18.8048 2.58574 18.6857 2.43064 18.5344C2.27554 18.383 2.15209 18.2024 2.06748 18.003C1.98286 17.8035 1.93877 17.5893 1.93776 17.3727C1.93675 17.1561 1.97885 16.9414 2.0616 16.7412C2.14436 16.541 2.26611 16.3592 2.4198 16.2064C2.57349 16.0536 2.75604 15.9329 2.95684 15.8512C3.15764 15.7696 3.37269 15.7286 3.58948 15.7307ZM3.60136 18.0144C3.77316 18.0123 3.93743 17.9437 4.0595 17.8229C4.18158 17.7021 4.25191 17.5386 4.25565 17.367C4.25326 17.1923 4.18282 17.0253 4.05928 16.9016C3.93574 16.7779 3.76882 16.7072 3.59393 16.7044C3.41891 16.7079 3.25235 16.7803 3.13046 16.9058C3.00856 17.0314 2.94119 17.1999 2.94299 17.3748C2.94611 17.5467 3.01701 17.7104 3.14029 17.8304C3.26357 17.9504 3.42927 18.0169 3.60136 18.0155V18.0144Z"
      fill="black"
    />
    <path
      d="M16.1089 5.89454L16.5172 5.30127C17.3489 6.70844 17.7337 8.20795 17.6766 9.83574H17.0264C17.0836 8.46323 16.7665 7.10096 16.1089 5.89454Z"
      fill="black"
    />
    <path
      d="M2.55187 5.16742C2.69215 5.35282 2.81796 5.51115 2.93115 5.67764C2.95305 5.70989 2.94191 5.78405 2.91928 5.82595C2.48138 6.60753 2.18844 7.46172 2.05456 8.34737C2.01411 8.61546 1.99147 8.88614 1.95955 9.16312H1.32159C1.37615 7.73148 1.7881 6.41775 2.55187 5.16742Z"
      fill="black"
    />
    <path
      d="M3.9328 11.2618V9.84872H5.88268V11.2578C5.6036 11.1191 5.33305 10.9919 5.07029 10.8499C4.95413 10.7868 4.86246 10.7898 4.74742 10.8517C4.48503 10.9952 4.21448 11.122 3.9328 11.2618Z"
      fill="black"
    />
    <path
      d="M9.67778 1.32485C9.58574 1.53213 9.51077 1.71975 9.41799 1.89773C9.39572 1.94038 9.31371 1.97189 9.25767 1.97375C8.39323 2.00676 7.54034 2.18346 6.73403 2.49657C6.67094 2.52104 6.60934 2.54996 6.54847 2.57666L6.3592 2.65712C6.24007 2.47173 6.12762 2.29856 6.00366 2.10575C7.16379 1.56068 8.38219 1.30668 9.67778 1.32485Z"
      fill="black"
    />
    <path d="M5.88153 17.6803V17.0466H9.17636V17.6803H5.88153Z" fill="black" />
    <path
      d="M11.7999 13.7536V13.1192H13.4978C13.5482 13.3172 13.6024 13.527 13.6603 13.7536H11.7999Z"
      fill="black"
    />
    <path
      d="M16.1468 14.3339L14.839 15.7518C14.7689 15.4811 14.7143 15.2816 14.6672 15.0799C14.6626 15.06 14.6621 15.0393 14.6658 15.0191C14.6695 14.999 14.6773 14.9798 14.6887 14.9628C15.0338 14.5704 15.3827 14.1819 15.7375 13.7847L16.1468 14.3339Z"
      fill="black"
    />
    <path d="M0 17.6807V17.0504H1.31229V17.6807H0Z" fill="black" />
    <path d="M7.20459 8.50977V7.88275H7.84589V8.50977H7.20459Z" fill="black" />
    <path d="M14.3932 17.0511V17.684H13.7449V17.0511H14.3932Z" fill="black" />
    <path d="M9.1519 8.51276H8.52618V7.88538H9.1519V8.51276Z" fill="black" />
    <path d="M9.84033 7.87683H10.4612V8.51015H9.84033V7.87683Z" fill="black" />
  </svg>
);


export const salary_permissions_ids = [119, 41, 190, 57, 62, 69, 1, 3, 28, 129, 9, 134, 198, 200, 192, 222, 334, 123, 425];
