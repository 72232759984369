import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FamilyAttributeListItem from "./FamilyAttributeListItem";



class FamilyAttributeList extends React.Component {
  constructor() {
    super();
    this.state = {
      items: [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
      this.setState({
        items: nextProps.items
      });
  };


  totalAttributes = () => {
    if (this.props.maxAttributes > 0) {
      return this.props.maxAttributes;
    }
    return this.state.items.length + "";
  };
 
  checkedAttributes = () => {
    //return this.state.items.filter(i => (i.enabled === true || i.active === true)).length;
    return this.state.items.filter(i => (i.enabled === true)).length;
  };

  render() {
    return (
      <div className = "family-attributes list-group">
          <div className="list-group-item header">
            <div className = "row">
              <div className = "col-6  text-bold">Atributai</div>
              <div className = "col-6  text-right">{this.checkedAttributes() + " / " + this.totalAttributes()}</div>
            </div>
          </div>
          <DragDropContext onDragEnd={this.props.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {this.state.items.map((item, index) => (
                  <FamilyAttributeListItem
                    key = {item.temp_id ? item.temp_id : item.family_attribute_id}
                    id = {item.temp_id ? item.temp_id : item.family_attribute_id}
                    name = {item.family_attribute ? item.family_attribute.title: ""}
                    synonim = {item.synonim}
                    enabled = {item.enabled}
                    active = {item.active}
                    index = {index}
                    type = {item.type === "custom" || item.custom === true ? "custom": ""}
                    onAttributeDelete = {this.props.onAttributeDelete}
                    onAttributeUpdate ={this.props.onAttributeUpdate}
                    attributes = {this.state.items}
                    selectedAtributes = {item.custom_attributes ? item.custom_attributes : [] }
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
            </Droppable>
          </DragDropContext>
          <div className="list-group-item footer">
            <button className = "btn btn-outline-primary btn-md d-block mx-auto px-3" onClick={this.props.addCustomAttribute}>Pridėti naują atributą</button>
          </div>
      </div>
    );
  }
}

export default FamilyAttributeList;
