import React, { useState } from "react";
import { Input, message, Spin } from "antd";
import Axios from "axios";
import { validateEmail } from "../../helpers/Utils";

function EmailModal({ info, closeModal }) {
  const [email, setEmail] = useState(info.email);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const sendEmailRequest = () => {
    setLoading(true);
    if (!validateEmail(email)) {
      message.error("Klaidingas elektroninis paštas įvestas.");
      setLoading(false);
      setError(true);
      return
    }
    const params = { description: { url: info.manual_url, email }};
    Axios.post(`/api/v1/descriptions/${info.id}/send_email`, params)
      .then((res) => {
        setLoading(false);
        setError(false);
        setEmail("");
        message.success("Sėkmingai išsiųsta.");
        closeModal(false)
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
        message.error("Įvyko klaida.");
      });
  };
  return (
    <>
      <h4 className="font-weight-bold">Instrukcijos siuntimas el.paštu</h4>
      <span
        className="badge badge-pill badge-info"
        style={{
          fontSize: "18px",
          padding: "10px 25px",
          backgroundColor: "#8678ab",
          marginTop: "-8px",
        }}
      >
        Instrukcijos pav. {info.identifier}
      </span>
      <div className="d-flex flex-column align-items-center w-100 py-4">
        <h5>
          <b>Gavėjo el.paštas</b>
        </h5>
        <Input
          className={error ? "border border-danger" : ""}
          placeholder="Įveskite"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <button
        className="btn btn-primary font-weight-bold grow"
        style={{
          paddingRight: "2rem",
          paddingLeft: "2rem",
        }}
        onClick={() => sendEmailRequest()}
        disabled={loading}
      >
        {loading ? <Spin /> : "Siųsti"}
      </button>
    </>
  );
}

export default EmailModal;
