import React from "react";
import {
  ButtonEdit,
  EditElementClick,
  ButtonDelete,
} from "../../common/Buttons";
import moment from "moment";
import { GoPrimitiveDot } from "react-icons/go";
import { useSelector } from "react-redux";

function TableColumnManagers({ id, item, index, onAction, onDelete }) {
  const {
    user_role: { identifier },
  } = useSelector((st) => st.auth.user);
  console.log({ identifier });
  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle  text-center">
        <GoPrimitiveDot
          style={item.exported == true ? { color: "green" } : { color: "red" }}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", id)}
          name={item.supplier_name}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", id)}
          name={item.invoice_number}
        />
      </td>
      <td className="align-middle ">
        <EditElementClick
          onEdit={() => onAction("edit", id)}
          name={moment(item.invoice_date).format("YYYY-MM-DD")}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", id)}
          name={moment(item.pay_due_date).format("YYYY-MM-DD")}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", id)}
          name={item.items_quantity}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", id)}
          name={item.price_without_pvm}
        />
      </td>
      <td className="align-middle">
        <EditElementClick onEdit={() => onAction("edit", id)} name={item.pvm} />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", id)}
          name={item.discount}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", id)}
          name={item.price_total}
        />
      </td>
      <td className="align-middle">
        <div className="d-flex align-items-center justify-content-between">
          <ButtonEdit onEdit={() => onAction("edit", id)} />
          {identifier == "admin" && (
            <>
              <div className="mx-2"></div>
              <ButtonDelete onDelete={() => onDelete(id)} />
            </>
          )}
        </div>
      </td>
    </tr>
  );
}

export default TableColumnManagers;
