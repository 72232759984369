import React, { useEffect, useState } from "react";
import { Spin, Select, Checkbox } from "antd";
const { Option } = Select;

import Axios from "axios";
import { Button1 } from "../../../Buttons";
import TextInputModal from "../../../TextInputModal";
import SelectInput from "../../../SelectInput";
import { antdMessageError, returnEuroString } from "../../../../../helpers/Utils";
// Custom

function OrderRowAdd({ postOrderRowGoods, orderId, rline = false, rline_code, mode }) {
  const [LoadingRes, setLoadingRes] = useState(false);
  const [Goods, setGoods] = useState([]);
  const [SearchText, setSearchText] = useState("");
  const [Loading, setLoading] = useState(false);
  const [Timer, setTimer] = useState(0);
  const [SelectedItem, setSelectedItem] = useState(undefined);
  const [rowFromServer, setRowFromServer] = useState("goods");
  const [errors, setErrors] = useState({});

  const [stores, setStores] = useState([]);

  useEffect(() => {
    if (mode == "Servisas") {
      handleChangeCheckBox("other");
    }
  }, [mode]);

  const fetchGoods = (value) => {
    setSearchText(value);
    if (Timer) {
      clearTimeout(Timer);
    }
    setTimer(
      setTimeout(() => {
        if (value.length >= 3) {
          setLoading(true);
          Axios.get(
            `/api/v1/products?page=1&per_page=9999999&q[title_or_identifier_i_cont_any]=${value}`
          ).then((response) => {
            if (response.data) {
              const data = response.data.data.map((product) => ({
                quantity: product.quantity,
                product_name: product.title,
                product_price: product.e_price,
                unit_price_tax_incl: product.e_price,
                unit_price_tax_excl: product.e_price,
                product_reference: product.identifier,
                id_product: product.id,
                barcode: "",
              }));
              setGoods(data);
              // setSearchText("");
              setLoading(false);
            }
          });
        } else {
          // setSearchText("Minimalus 3 simboliai");
        }
      }, 1000)
    );
  };

  const fetchRivileService = (value) => {
    setSearchText(value);
    if (Timer) {
      clearTimeout(Timer);
    }
    setTimer(
      setTimeout(() => {
        if (value.length >= 3) {
          setLoading(true);
          Axios.get(
            `/api/v1/rivile_services?page=1&per_page=9999999&q[title_i_cont_any]=${value}`
          ).then((response) => {
            if (response.data) {
              const data = response.data.data.map((product) => ({
                quantity: 1,
                product_name: product.title,
                product_reference: "{}",
                product_price: product.alt_price,
                unit_price_tax_incl: product.alt_price,
                unit_price_tax_excl: product.alt_price,
                id_product: product.id_product,
                barcode: "",
              }));
              setGoods(data);
              // setSearchText("");
              setLoading(false);
            }
          });
        } else {
          // setSearchText("Minimalus 3 simboliai");
        }
      }, 1000)
    );
  };

  const handleChange = async (value) => {
    if (rline && rowFromServer == 'goods' && value.value) {
      setStores([])

      const fetcherOfProductPrices = async () => {
        try {

          const code = value.product_reference;
          const { data } = await Axios.get(
            `/api/v1/products/has_product_quantities?code=${code}`
          );
          if (!data.success) antdMessageError(data.message)
          value.unit_price_tax_incl = 0;

          let r_stores = !data.success ? [] : Object.entries(data.data).map(([key, values]) => {
            if (rline_code && rline_code.replace("R", "") == key) {
              value.unit_price_tax_incl = values.price
            }
            return {
              label: `${key} (${values.qty}): ${returnEuroString(values.price)}`,
              value: values.price + ""
            }
          })

          r_stores.push({
            label: `Eshop: ${returnEuroString(value.product_price)}`,
            value: value.product_price + "",
          })

          setStores(r_stores)
        } catch (err) {
          console.error("Error: ", err)
        }
      };
      await fetcherOfProductPrices()
    }
    setSelectedItem(prev => ({ ...prev, ...value }));
  };

  const onUpdate = () => {
    if (SelectedItem.product_name.trim() === "") {
      return setErrors({ title: "Įveskite tekstą" });
    }
    if (!SelectedItem.unit_price_tax_incl) {
      return setErrors({ unit_price_tax_incl: "Įveskite kainą" });
    }
    setLoadingRes(true);
    const body = {
      order_row: {
        quantity: SelectedItem.quantity,
        order_id: orderId,
        id_product: SelectedItem.id_product,
        product_name: SelectedItem.product_name,
        product_price: SelectedItem.unit_price_tax_incl,
        product_reference: SelectedItem.product_reference,
        unit_price_tax_incl: SelectedItem.unit_price_tax_incl,
        unit_price_tax_excl: SelectedItem.unit_price_tax_incl,
        barcode: SelectedItem.barcode,
        manual: 1,
      },
    };
    postOrderRowGoods(body, orderId, rowFromServer == "goods").then((res) => {
      if (res.ok) {
        if (res.service) {
          setLoadingRes(false);
          handleChangeCheckBox("other");
          setErrors({});
        } else {
          setErrors({});
          setSelectedItem(undefined);
          setRowFromServer(true);
          setLoadingRes(false);
        }
      } else {
        setLoadingRes(false);
        setErrors({ error: "Įvyko klaida" });
        // notifications("error", "Įvyko klaida");
      }
    });
  };

  const handleChangeCheckBox = (boolean) => {
    if (boolean !== "other") {
      setSelectedItem(undefined);
      setRowFromServer(boolean);
      setGoods([]);
    } else {
      setSelectedItem({
        quantity: 1,
        id_product: "",
        product_name: rline ? "Rankinė prekė" : "",
        product_price: 0,
        product_reference: rline ? "1234" : "",
        unit_price_tax_incl: 0,
        unit_price_tax_excl: 0,
        barcode: "",
      });
      setRowFromServer(boolean);
    }
  };

  if (LoadingRes) {
    return (
      <div className="d-flex align-items-center justify-content-center my-4">
        <Spin tip="Kraunama..." />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="col-12 bg-silver pt-2 pb-3">
        {mode !== "Servisas" && (
          <div className="pb-3 pt-2">
            <Checkbox
              checked={rowFromServer == "goods"}
              onChange={() => handleChangeCheckBox("goods")}
            >
              Prekės paieška
            </Checkbox>
            <Checkbox
              checked={rowFromServer == "service"}
              onChange={() => handleChangeCheckBox("service")}
            >
              Paslaugos paieška
            </Checkbox>
            <Checkbox
              checked={rowFromServer == "other"}
              onChange={() => handleChangeCheckBox("other")}
            >
              Kita
            </Checkbox>
            {/* {!rline && (
              <Checkbox
                checked={rowFromServer == "other"}
                onChange={() => handleChangeCheckBox("other")}
              >
                Kita
              </Checkbox>
            )} */}
          </div>
        )}

        {rowFromServer !== "other" && (
          <Select
            showSearch
            // mode="multiple"
            labelInValue
            value={SelectedItem}
            placeholder={"Pasirinkti"}
            notFoundContent={
              Loading ? (
                <div className="d-flex justify-content-center">
                  <Spin size="small" />
                </div>
              ) : null
            }
            filterOption={false}
            onSearch={
              rowFromServer == "goods" ? fetchGoods : fetchRivileService
            }
            onChange={(e, value) => handleChange(value)}
            style={{ width: "100%" }}
          >
            {Goods.map((d) => (
              <Option
                key={d.id_product}
                id_product={d.id_product}
                quantity={1}
                product_name={d.product_name}
                product_price={d.product_price}
                unit_price_tax_incl={d.unit_price_tax_incl}
                product_reference={d.product_reference}
                unit_price_tax_excl={d.unit_price_tax_excl}
              >
                {d.product_name}
              </Option>
            ))}
          </Select>
        )}
        {SearchText.length >= 1 && SearchText.length < 3 && (
          <p className="text-danger">Min 3 simboliai</p>
        )}
        {typeof SelectedItem !== "undefined" && (
          <React.Fragment>
            <div className="d-flex">
              <div style={{ width: "50%" }}>
                <TextInputModal
                  text="Pavadinimas"
                  placeholder="Įvesti"
                  value={SelectedItem.product_name}
                  onChange={(e) =>
                    handleChange({ product_name: e.target.value })
                  }
                  warning={errors.title}
                />
              </div>
              <div className="px-2" style={{ width: "25%" }}>
                <TextInputModal
                  // disabled={mode === "Servisas"}
                  type="number"
                  text="Kiekis"
                  placeholder="Įvesti"
                  value={SelectedItem.quantity}
                  onChange={(e) => handleChange({ quantity: e.target.value })}
                />
              </div>
              <div style={{ width: "25%" }}>
                <TextInputModal
                  type="number"
                  text="Kaina"
                  placeholder="Įvesti"
                  value={SelectedItem.unit_price_tax_incl}
                  onChange={(e) =>
                    handleChange({ unit_price_tax_incl: e.target.value })
                  }
                  warning={errors.unit_price_tax_incl}
                />
              </div>
            </div>
            {stores.length > 0 && (
              <div>
                <SelectInput
                  label="Parduotuvės"
                  options={stores}
                  value={stores.find(d => +d.value == +SelectedItem?.unit_price_tax_incl)?.value || undefined}
                  onChange={(unit_price_tax_incl) => setSelectedItem(prev => ({
                    ...prev,
                    unit_price_tax_incl
                  }))
                  }
                />
              </div>
            )}
            {errors.error && <div className="text-danger">{errors.error}</div>}
            {rline && rowFromServer == "other" && (
              <p className="text-silver text-xs">
                Paslauga bus priskirta su kodu 1234 !
              </p>
            )}
            <div
              className="my-2"
              style={{ position: "relative", height: "35px" }}
            >
              <Button1
                style={{
                  position: "absolute",
                  left: "50%",
                  transform: "translate(-50%, 0px)",
                }}
                text={"Pridėti"}
                option={"plus"}
                onClick={() => onUpdate()}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}
export default OrderRowAdd;
