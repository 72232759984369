import Axios from "axios";

export const getSettingDetails = async (value) => {
  try {
    const { data } = await Axios.get(`/api/v1/settings?value=${value}`);
    if (!data.success) throw new Error(data.message);

    return data;
  } catch (err) {
    console.log("ERROR: ", err);
    return { success: false };
  }
};
