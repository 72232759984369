import React, { Fragment, useState, useEffect } from "react";
import Container from "../../components/Layout/Container";
import styled from "styled-components";
import {
  ButtonGradientAdd,
  CheckBoxInput,
} from "../../components/common/Buttons";
import UserPermissionsOption from "../../helpers/UserPermissionsOption";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FiTrash } from "react-icons/fi";
import TextInputModal from "../../components/common/TextInputModal";
import ModalSpecial from "../../components/Layout/Modals/ModalSpecial";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserRole,
  postUserRole,
  removeUserRole,
  updateUserRole,
} from "../../redux/Actions/user_roles_action";
import { Tooltip } from "antd";

export default function UserPmissionsScreen() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUserRole());
  }, []);

  const { data, current } = useSelector((state) => state.custom);
  const [modal, setModal] = useState(false);
  const [Open, setOpen] = useState([]);

  const medis = UserPermissionsOption({ id: 1 });

  const onUpdate = async (role_id, params) => {
    try {
      if (!confirm("Ar tikrai norite atnaujinti?")) return;

      dispatch(updateUserRole(role_id, params));
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const onDelete = async (role_id) => {
    try {
      if (!confirm("Ar tikrai norite ištrinti?")) return;
      dispatch(removeUserRole(role_id));
    } catch (err) {
      console.log("ERROR: ", err);
    }
  };
  return (
    <Container
      location={{
        locationName: "Rolės",
        locationUrl: "/",
      }}
    >
      <div className="col-12">
        <div className="bg-white d-flex">
          <Table type="main">
            <TableMain>
              <ButtonGradientAdd
                text="Pridėti rolę"
                onClick={() => setModal(true)}
              />
            </TableMain>
            {medis.map((d) => (
              <RenderTableLinePossibleOptions
                key={d.uuid}
                {...d}
                setOpen={setOpen}
                Open={Open}
              />
            ))}
          </Table>
          <TableSwitch>
            {current == "Rolės" && data?.map((e, idx) => (
              <Fragment key={idx}>
                <Table>
                  <TableMain its_even={idx & 1 ? true : false}>
                    {e.title}
                    <Tooltip
                      placement="top"
                      title={
                        e.used
                          ? "Trinimas negalimas, nes yra priskirtų vartotojų su šią rolę."
                          : "Rolės trinimas"
                      }
                    >
                      <div
                        className={`d-flex justify-content-center align-items-center ${
                          e.used ? "btn-secondary" : "btn-danger"
                        } border-radius-3 cursor-pointer ml-2`}
                        style={{
                          width: 30,
                          height: 30,
                        }}
                        onClick={e.used ? () => {} : () => onDelete(e.id)}
                      >
                        <FiTrash />
                      </div>
                    </Tooltip>
                  </TableMain>
                  {medis.map((d) => (
                    <RenderTableLinePermissions
                      key={e.uuid}
                      {...d}
                      parent_uuid={e.uuid}
                      Open={Open}
                      permissions={e.permissions}
                      its_even={idx & 1}
                      onUpdate={(uuid, extras) => {
                        let obj = e;
                        const first_uid = uuid[0];
                        const parent_uid = uuid[1];
                        const value = !obj.permissions[first_uid];
                        obj.permissions[first_uid] = value;
                        if (extras && extras.length > 0) {
                          extras.forEach((e) => (obj.permissions[e] = value));
                        }
                        if (parent_uid && value) {
                          obj.permissions[parent_uid] = true;
                        }
                        delete obj.used;
                        onUpdate(e.id, obj);
                      }}
                    />
                  ))}
                </Table>
              </Fragment>
            ))}
          </TableSwitch>
        </div>
      </div>
      {modal && <ModalUserRoleCreate setModal={setModal} />}
    </Container>
  );
}

const ModalUserRoleCreate = ({ setModal }) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [permissions, setPermissions] = useState({});
  const [Open, setOpen] = useState([]);

  const menu_tree = UserPermissionsOption({ id: 1 });
  const [errors, setErrors] = useState({});

  const onSave = async () => {
    try {
      if (!title) return setErrors({ title: "Įveskite pavadinimą" });
      const { success } = await dispatch(
        postUserRole({
          title,
          identifier,
          permissions,
        })
      );
      if (success) {
        setModal(false);
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  };
  console.log({ permissions });

  return (
    <ModalSpecial
      style={{
        maxWidth: 370,
      }}
      modal_body={{
        padding: 0,
        margin: "0 -6px",
      }}
      text={"Sukurti naują rolę"}
      onClose={() => {
        setModal(false);
      }}
    >
      <div
        className="d-flex flex-column justify-content-between"
        style={{ height: "100%" }}
      >
        <div className="">
          <div className="col-12 mt-4 mb-2">
            <TextInputModal
              required="true"
              text="Rolės pavadinimas"
              placeholder="Įvesti"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              warning={errors.title}
            />
          </div>
          <div className="col-12 mb-3">
            <TextInputModal
              // required="true"
              text="Rolės identifikatorius"
              placeholder="Įvesti"
              name="identifier"
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
              warning={errors.identifier}
            />
          </div>

          {menu_tree.map((e) => (
            <PermissionBlock
              key={e.uuid}
              parent_uuid={e.uuid}
              {...e}
              setOpen={setOpen}
              Open={Open}
              permissions={permissions}
              setPermissions={(uid, extras) => {
                setPermissions((prev) => {
                  const first_uid = uid[0];
                  const parent_uid = uid[1];
                  const value = !prev[first_uid];
                  let extras_obj = {};
                  if (extras && extras.length > 0) {
                    extras.forEach((e) => (extras_obj[e] = value));
                  }
                  if (parent_uid && value) {
                    extras_obj[parent_uid] = true;
                  }
                  console.log(extras_obj);
                  console.log({ parent_uid, uid });
                  return { ...prev, ...extras_obj, [first_uid]: value };
                });
              }}
            />
          ))}
        </div>

        <div className="p-left p-right">
          <button
            className="btn btn-primary btn-block mt-4 mb-2"
            onClick={onSave}
          >
            Sukurti
          </button>
        </div>
      </div>
    </ModalSpecial>
  );
};

const PermissionBlock = ({
  title,
  uuid,
  parent_uuid,
  options,
  setOpen,
  Open,
  setPermissions,
  permissions,
  padding_left = 0,
}) => {
  const shrinkClick = () => {
    setOpen((prev) => {
      if (prev.includes(title)) {
        return prev.filter((e) => e !== title);
      } else {
        return [...prev, title];
      }
    });
  };

  const its_openned = Open.includes(title);
  return (
    <Fragment>
      <PermissionBlockPreview
        className={padding_left ? "children" : "font-weight-bold"}
        padding_left={padding_left + 15}
      >
        <div>
          {title}
          {options && options.length > 0 && (
            <Fragment>
              {its_openned ? (
                <IoIosArrowUp
                  className="cursor-pointer ml-2"
                  title="Sutraukti"
                  color="silver"
                  onClick={() => shrinkClick()}
                />
              ) : (
                <IoIosArrowDown
                  className="cursor-pointer ml-2"
                  color="silver"
                  title="Išskleisti"
                  onClick={() => shrinkClick()}
                />
              )}
            </Fragment>
          )}
        </div>
        <div>
          <CheckBoxInput
            checked={permissions[uuid] || false}
            onChange={() =>
              setPermissions(
                [uuid, parent_uuid],
                options?.filter((e) => !e?.options).map((e) => e.uuid)
              )
            }
          />
        </div>
      </PermissionBlockPreview>
      {its_openned &&
        options?.map((d) => (
          <PermissionBlock
            key={d.uuid}
            parent_uuid={uuid}
            {...d}
            setOpen={setOpen}
            Open={Open}
            setPermissions={setPermissions}
            permissions={permissions}
            padding_left={padding_left + 5}
          />
        ))}
    </Fragment>
  );
};

const PermissionBlockPreview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-weight: bold; */
  height: 54px;
  & div:first-child {
    padding-left: ${(props) =>
      props.padding_left ? `${props.padding_left}px` : `0px`};
  }
  & div {
    width: 50%;
  }
  &:nth-child(even) {
    background-color: #f3f3f3cc;
  }
  &.children:nth-child(even) {
    background-color: rgba(242, 147, 2, 0.07) !important;
  }
`;

const RenderTableLinePermissions = ({
  title,
  uuid,
  parent_uuid,
  options,
  Open,
  children = false,
  permissions,
  onUpdate,
  its_even,
}) => {
  return (
    <Fragment>
      <TableLine
        className={
          children
            ? `table-line children ${
                its_even ? "its-event" : ""
              } justify-content-center`
            : `table-line ${its_even ? "its-event" : ""} justify-content-center`
        }
      >
        <CheckBoxInput
          checked={permissions[uuid] || false}
          onChange={() => {
            onUpdate(
              [uuid, parent_uuid],
              options?.filter((e) => !e?.options).map((e) => e.uuid)
            );
          }}
        />
      </TableLine>
      {Open.includes(title) &&
        options.length > 0 &&
        options.map((e) => (
          <RenderTableLinePermissions
            key={e.uuid}
            {...e}
            parent_uuid={uuid}
            Open={Open}
            children={true}
            permissions={permissions}
            onUpdate={onUpdate}
            its_even={its_even}
          />
        ))}
    </Fragment>
  );
};

const RenderTableLinePossibleOptions = ({
  title,
  options,
  setOpen,
  Open,
  padding_left = 0,
  uuid,
}) => {
  const shrinkClick = () => {
    setOpen((prev) => {
      if (prev.includes(title)) {
        return prev.filter((e) => e !== title);
      } else {
        return [...prev, title];
      }
    });
  };
  const its_openned = Open.includes(title);
  return (
    <Fragment>
      <TableLine
        className={
          !uuid
            ? "table-line text-danger"
            : padding_left
            ? "table-line children"
            : "table-line font-weight-bold"
        }
        padding_left={padding_left + 15}
      >
        {title}
        {options && options.length > 0 && (
          <Fragment>
            {its_openned ? (
              <IoIosArrowUp
                className="cursor-pointer ml-2"
                title="Sutraukti"
                color="silver"
                onClick={() => shrinkClick()}
              />
            ) : (
              <IoIosArrowDown
                className="cursor-pointer ml-2"
                color="silver"
                title="Išskleisti"
                onClick={() => shrinkClick()}
              />
            )}
          </Fragment>
        )}
      </TableLine>
      {its_openned &&
        options.length > 0 &&
        options.map((e) => (
          <RenderTableLinePossibleOptions
            key={e.uuid}
            {...e}
            setOpen={setOpen}
            Open={Open}
            padding_left={padding_left + 5}
          />
        ))}
    </Fragment>
  );
};

const Table = styled.div`
  width: 200px;

  ${(props) =>
    props.type == "main" && "box-shadow: 5px 0px 21px rgba(0, 0, 0, 0.09);"}
  ${(props) => props.type == "main" && "z-index: 1;"}
  
  .table-line:nth-child(even) {
    background-color: #f8f8f8;
  }
  .table-line.children:nth-child(even) {
    background-color: rgba(242, 147, 2, 0.07) !important;
  }
  .table-line.its-event:nth-child(even) {
    background-color: #f6f6f6;
  }
  .table-line.children.its-event:nth-child(even) {
    background-color: rgba(242, 147, 2, 0.14) !important;
  }
`;
const TableMain = styled.div`
  /* ${(props) => props.its_even && "background-color: #f6f6f6"} */
  min-width: 191px;
  height: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;
const TableSwitch = styled.div`
  display: flex;
  /* height: 77px; */
  overflow-x: scroll;
  width: 100%;
`;
const TableLine = styled.div`
  min-width: 191px;
  /* height: 77px; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 15px 0 15px
    ${(props) => (props.padding_left ? `${props.padding_left}px` : `0px`)};
`;
