import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectForm } from "../components/common/Buttons";
import ButtonSecondary from "../components/common/ButtonSecondary";
import ContentValidation from "../components/common/ContentValidation";
import RangePickerComp from "../components/common/RangePickerComp";
import TableFilterBox from "../components/common/TableFilterBox";
import TextInputModal from "../components/common/TextInputModal";
import PaginationComp from "../components/Dashboard/items/Pagination";
import Container from "../components/Layout/Container";
import ContentBlock from "../components/Layout/ContentBlock";
import FilterMenu from "../components/Layout/FilterMenu";
import LeasingModalWrapper from "../components/leasing/LeasingModalWrapper";
import TableColumnLeasing from "../components/leasing/TableColumnLeasing";
import { handleKeyDown, onChangeUseState } from "../helpers/GlobalFunctions";
import { formatDatePlus, getDateFormat } from "../helpers/Utils";
import { getLeasingOptions, getLeasings } from "../redux/Actions/leasingAction";
import { createConsumer } from "@rails/actioncable";
import { leasing_options } from "../helpers/GlobalOptions";
import sb_leasing_svg from "../../assets/images/sidebar/sb_leasing.svg";

const filtersInitial = {
  contract_number: "",
  identifier: "",
  client: "",
  product: "",
  date: ["", ""],
  status: undefined,
  user: "",
};
export default function LeasingScreen({ match }) {
  const dispatch = useDispatch();
  const { loading, data, totals, current } = useSelector(
    (state) => state.custom
  );
  const {
    user: { user_role },
  } = useSelector((state) => state.auth);

  const [filters, setFilters] = useState(filtersInitial);
  const [page, setPage] = useState(1);
  const [perCount, setPerPage] = useState(50);
  const [leasingSelected, setLeasingSelected] = useState(false);
  const store_manager = user_role?.identifier == "store_manager";
  const startedPack = () => {
    dispatch(getLeasings(page, perCount, onFilter()));
  };
  useEffect(() => {
    startedPack();
    dispatch(getLeasingOptions());

    const consumer = createConsumer();
    consumer.subscriptions.create(
      { channel: "SbLeasingChannel" },
      {
        connected() {
          console.log("Connected WS SbLeasingChannel");
        },
        received(data) {
          console.log("Data", data);
          if (typeof data.sb_leasing !== "undefined") {
            dispatch({
              type: "UPDATE_CUSTOM",
              payload: data.sb_leasing,
            });
            // setLeasingSelected((prev) => {
            //   if (prev == data.sb_leasing.id) {
            //     return false;
            //   }
            //   return prev;
            // });
          }
        },
      }
    );

    const paramsAction = match.params?.action;
    if (paramsAction) {
      setLeasingSelected(paramsAction);
    }
  }, []);

  useEffect(() => {
    console.count("FETCH");
    setPage(1);
    startedPack();
  }, [filters.status, filters.date, perCount]);

  useEffect(() => {
    console.count("FETCH");
    startedPack();
  }, [page]);

  const onFilter = () => {
    const { contract_number, identifier, client, product, date, status, user } =
      filters;

    let search_string = "";
    if (contract_number) {
      search_string =
        search_string + `&q[contract_number_cont]=${contract_number}`;
    }
    if (identifier) {
      search_string =
        search_string +
        `&q[request_identifier_or_identifier_cont]=${identifier}`;
    }
    if (client) {
      search_string = search_string + `&q[name_or_last_name_cont]=${client}`;
    }
    if (product) {
      search_string =
        search_string + `&q[items_identifier_or_items_title_cont]=${product}`;
    }
    if (date[0].length > 0)
      search_string = search_string + `&q[order_date_gteq]=${date[0]}`;
    if (date[1].length > 0) {
      search_string =
        search_string + `&q[order_date_lt]=${formatDatePlus(date[1])}`;
    }
    //
    if (status) {
      search_string = search_string + `&q[state_eq]=${status}`;
    }
    if (user) {
      search_string =
        search_string + `&q[user_firstname_or_user_lastname_cont]=${user}`;
    }

    return search_string;
  };

  return (
    <Container
      location={{
        locationName: "Lizingas",
        locationUrl: match.url,
      }}
    >
      <ContentBlock>
        <FilterMenu
          icon={sb_leasing_svg}
          length={`${totals || 0} / ${data.length || 0}`}
          onChangeCountPage={setPerPage}
          countPage={perCount}
          text="lizingų"
          text2="Lizingų"
        />
      </ContentBlock>

      <ContentBlock>
        <TableFilterBox
          clearFilter={() => {
            setFilters(filtersInitial);
            setPage(1);
            setTimeout(() => startedPack(), 1000);
          }}
        >
          <div className="px-1">
            <TextInputModal
              text="Paraiška"
              name="identifier"
              onKeyDown={(e) => handleKeyDown(e, startedPack)}
              onChange={(e) => onChangeUseState(e, setFilters)}
              value={filters.identifier}
            />
          </div>
          <div className="px-1">
            <TextInputModal
              text="Sutartis"
              name="contract_number"
              onKeyDown={(e) => handleKeyDown(e, startedPack)}
              onChange={(e) => onChangeUseState(e, setFilters)}
              value={filters.contract_number}
            />
          </div>
          <div className="px-1">
            <TextInputModal
              text="Klientas"
              name="client"
              onKeyDown={(e) => handleKeyDown(e, startedPack)}
              onChange={(e) => onChangeUseState(e, setFilters)}
              value={filters.client}
            />
          </div>
          <div className="px-1">
            <TextInputModal
              text="Prekė"
              name="product"
              onKeyDown={(e) => handleKeyDown(e, startedPack)}
              placeholder="Kodas arba pavadinimas"
              onChange={(e) => onChangeUseState(e, setFilters)}
              value={filters.product}
            />
          </div>
          <div className="px-1">
            <div className="form-group">
              <label className="editLabelForm">Dok. data</label>
              <div>
                <RangePickerComp
                  style={{ width: 250 }}
                  placeholder={["Nuo", "Iki"]}
                  value={[
                    getDateFormat(filters.date[0]),
                    getDateFormat(filters.date[1]),
                  ]}
                  onChange={(_, value) =>
                    onChangeUseState(
                      { target: { name: "date", value } },
                      setFilters
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="px-1">
            <SelectForm
              placeholder="Statusas"
              dropdownMatchSelectWidth={false}
              options={leasing_options}
              onChange={(value) =>
                onChangeUseState(
                  { target: { name: "status", value } },
                  setFilters
                )
              }
              value={filters.status}
            />
          </div>
          {!store_manager && (
            <div className="px-1">
              <TextInputModal
                text="Vartotojas"
                name="user"
                onKeyDown={(e) => handleKeyDown(e, startedPack)}
                onChange={(e) => onChangeUseState(e, setFilters)}
                value={filters.user}
              />
            </div>
          )}
        </TableFilterBox>
      </ContentBlock>
      <div className="col-12 my-2 d-flex justify-content-end">
        {/* <div
          style={{
            width: 200,
            marginRight: 10,
          }}
        >
          <ButtonSecondary
            outline={true}
            onClick={() =>
              window.open("https://e.sblizingas.lt/login", "_blank")
            }
          >
            Prisijungimas prie sblizingas
          </ButtonSecondary>
        </div> */}
        <div
          style={{
            width: 150,
          }}
        >
          <ButtonSecondary onClick={() => setLeasingSelected("create")}>
            Naujas krepšelis
          </ButtonSecondary>
        </div>
      </div>
      <ContentBlock>
        <div>
          <ContentValidation loader={loading} array={data}>
            <div className="table-responsive">
              <table className="table table-sm table-hover mt-3">
                <thead>
                  <tr className="text-muted">
                    <th className="text-black">Data</th>
                    <th className="text-black">Paraiška</th>
                    <th className="text-black">Sutartis</th>
                    <th className="text-black">Klientas</th>
                    <th className="text-black">Prekės</th>
                    <th className="text-black">Statusas</th>
                    {!store_manager && (
                      <th className="text-black">Vartotojas</th>
                    )}
                    <th className="text-black">Suma</th>
                    <th className="text-black">Avansas</th>
                    <th className="text-black">Lizinguojama</th>
                    <th className="text-black"></th>
                  </tr>
                </thead>
                <tbody>
                  {current == "Lizingai" &&
                    data.map((e) => {
                      return (
                        <TableColumnLeasing
                          key={e.id}
                          item={e}
                          onShow={() => setLeasingSelected(e.id)}
                          store_manager={store_manager}
                          permissionDelete={
                            user_role.identifier == "admin" &&
                            !e.contract_number
                          }
                        />
                      );
                    })}
                </tbody>
              </table>
              <PaginationComp
                page={page}
                totals={totals}
                setPagination={setPage}
                pageCount={perCount}
              />
            </div>
          </ContentValidation>
        </div>
      </ContentBlock>
      {leasingSelected && (
        <LeasingModalWrapper
          leasingSelected={leasingSelected}
          setLeasingSelected={setLeasingSelected}
          closeModal={() => setLeasingSelected(false)}
        />
      )}
    </Container>
  );
}
