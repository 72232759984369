export const status_options = [
  {
    label: "Sukurtas",
    value: "created",
  },
  {
    label: "Keitimas",
    value: "change",
  },
  {
    label: "Paimta iš kliento",
    value: "taken_from_client",
  },
  {
    label: "Servise",
    value: "sent_to_service",
  },
  {
    label: "Pinigų grąžinimas",
    value: "money_refund",
  },
  {
    label: "Grįžo iš serviso",
    value: "taken_from_service",
  },
  {
    label: "Pabaigtas",
    value: "closed",
  },
  {
    label: "Laukia kreditinės",
    value: "waiting_credit",
  },
];

export const service_type_options = [
  {
    label: "PPG",
    value: "PPG",
  },
  {
    label: "Garantinis",
    value: "Garantinis",
  },
  {
    label: "Negarantinis",
    value: "Negarantinis",
  },
  {
    label: "Užsakymas",
    value: "order",
  },
  {
    label: "Priešprekybinis",
    value: "Priešprekybinis",
  },
];

export const leasing_options = [
  {
    value: "unprepared",
    label: "Nepasiruošęs",
  },
  {
    value: "generated",
    label: "Sugeneruotas",
  },
  {
    value: "signed",
    label: "Pasirašė",
  },
  {
    value: "archived",
    label: "Suarchyvuota",
  },
  {
    value: "cancelled",
    label: "Atšauktas",
  },
];

export const shipment_types_options = [
  { label: "DPD siunta", value: "dpd" },
  { label: "OMNIVA siunta", value: "omniva" },
  { label: "ITELLA siunta", value: "itella" },
  { label: "VENIPAK siunta", value: "venipak" },
  {
    label: "Technorama transportu",
    value: "own_transport",
  },
  {
    label: "LP express",
    value: "lp_express",
  },
  {
    label: "Atsiemimas parduotuvėje",
    value: "at_the_store",
  },
];

export const months_lt = [
  { label: "Sausis", value: 1 },
  { label: "Vasaris", value: 2 },
  { label: "Kovas", value: 3 },
  { label: "Balandis", value: 4 },
  { label: "Gegužė", value: 5 },
  { label: "Birželis", value: 6 },
  { label: "Liepa", value: 7 },
  { label: "Rugpjūtis", value: 8 },
  { label: "Rugsėjis", value: 9 },
  { label: "Spalis", value: 10 },
  { label: "Lapkritis", value: 11 },
  { label: "Gruodis", value: 12 },
];

export const week_lt_short = [
  {
    value: 1,
    label: "Pr.",
  },
  {
    value: 2,
    label: "A.",
  },
  {
    value: 3,
    label: "T.",
  },
  {
    value: 4,
    label: "K.",
  },
  {
    value: 5,
    label: "P.",
  },
  {
    value: 6,
    label: "Š.",
  },
  {
    value: 7,
    label: "S.",
  },
];

export const dummyDateGuide = [
  {
    title: "Loremx",
    category: "test",
    status: true,
    images: [],
    steps: [
      {
        title: "test",
        active: true,
        description: "",
        answers: [
          {
            title: "test",
            description: "test",
            attributes: [],
          },
        ],
      },
      {
        title: "tes2t",
        active: false,
        description: "",
        answers: [],
      },
    ],
    active: false,
  },
];

export const reportDepOptions = [
  "Aitvaras",
  "Akropolis",
  "Molas",
  "Šilutė",
  "Plungė",
  "Gargždai",
  "E-Shop",
  "Didmena",
  "Kiti",
].map((d) => ({ value: d, label: d }));

export const reportDepFullOptions = [
  { label: "Suminis", value: "suminis" },
  ...reportDepOptions,
];


export const mainStoresWithDepartments = {
  U010: "Aitvaras",
  U101: "Akropolis",
  // U31: "Molas", 
  U40: "Šilutė",
  U21: "Plunge",
  U71: "Gargždai",
  U080: "Paryžius"
};