import React, { useState, useEffect } from "react";
import TextInputModal from "../../../../TextInputModal";
import { SelectForm } from "../../../../Buttons";
import { FaTimes } from "react-icons/fa";
import { FlopppyButton } from "../../../../ButtonWithLoading";

function PaymentAddComponent({
  orderId,
  postPayment,
  paymentTypes,
  updatePayment,
  editPayment,
  payment,
  payments,
  total_price,
  goodsAddRowClick,
  paymentBlock,
}) {
  const [values, setValues] = useState({
    reference: "",
    payment_type: undefined,
    price: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setValues(payment);
  }, [payment]);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    if (
      countOverPrice() ||
      confirm(
        "Pridėjus būs apmokėjimo suma didesnė už užsakymo suma. Ar pridėti ?"
        )
        ) {
      if (payment.id) {
        return updatePayment(orderId, payment.id, values).then((res) => {
          if (res.success) {
            editPayment({ reference: "", payment_type: undefined, price: "" });
            goodsAddRowClick(false);
            setLoading(false)
          } else {
            setErrors(res.errors);
            setLoading(false)

          }
        });
      }
      postPayment(orderId, values).then((res) => {
        if (res.success) {
          setValues({
            reference: "",
            payment_type: undefined,
            price: "",
          });
          goodsAddRowClick(false);
          setLoading(false)
          setErrors({});
        } else {
          setErrors(res.errors);
          setLoading(false)
        }
      });
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    const nameVal = name.split("[payment]")[1];

    setValues({
      ...values,
      [nameVal]: value,
    });
  };

  const countOverPrice = () => {
    const total = payments.reduce((acc, cur) => +acc + +cur.price, 0);
    const total_with_new = +total + +values.price;
    if (+total_price < +total_with_new) {
      return false;
    }
    return true;
  };

  return (
    <React.Fragment>
      <p className="bg-silver d-flex mb-1">
        Apmokėjimo {payment.id ? "redagavimas" : "pridėjimas"}
      </p>

      <form onSubmit={onSubmit}>
        <div className="d-flex">
          <div className="w-33">
            <TextInputModal
              text="Mok. nr."
              placeholder="Įvesti"
              name="[payment]reference"
              value={values.reference}
              onChange={(e) => handleChange(e)}
              error={errors.reference}
              required={true}
            />
          </div>
          <div className="mx-2 w-33">
            <SelectForm
              // style={{ width: "150px" }}
              options={paymentTypes}
              placeholder="Mokėjimo būdas"
              value={values.payment_type}
              onChange={(e) =>
                handleChange({
                  target: { value: e, name: "[payment]payment_type" },
                })
              }
              error={errors.payment_type}
              required={true}
            />
          </div>
          <div className="w-33 mr-3">
            <TextInputModal
              text="Suma"
              placeholder="Įvesti"
              type="number"
              name="[payment]price"
              value={values.price}
              onChange={(e) => handleChange(e)}
              error={errors.price}
              required={true}
            />
          </div>
          <div>
            <div className="text-bold my-1 invisible">Save</div>

            <div className="d-flex">
              <FlopppyButton
              loading={loading}
                // style={{ background: "#f57920", height: "32px" }}
                onClick={(e) => onSubmit(e)}
              />

              <button
                className="btn btn-sm shadow-sm ml-1 bg-danger"
                style={{ background: "#f57920", height: "32px", width: "32px" }}
                onClick={(e) => paymentBlock(e)}
              >
                <FaTimes color="white" size="15" />
              </button>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
}
export default PaymentAddComponent;
