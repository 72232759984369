import React, { useState, useEffect, createRef } from "react";
import {
  buildErrorObject,
  first_arr,
  last_arr,
  log,
} from "../../../../helpers/Utils";
import Spinner from "../../../common/Spinner";
import ModalSpecial from "../../../Layout/Modals/ModalSpecial";
import { IconChevronLeft } from "../../../Layout/Icons";
import {
  getGuideBuyersShow,
  postGuideBuyers,
} from "../../../../redux/Actions/guidesBuyersAction";
import FirstModalPreview from "../GuideBuyersGuides/FirstModalPreview";
import DoubleButton from "../GuideBuyersGuides/DoubleButton";
import ThirdModalPreview from "../GuideBuyersGuides/ThirdModalPreview";
import SecondModalPreview from "../GuideBuyersGuides/SecondModalPreview";
import { useDispatch } from "react-redux";
import useErrors from "../../../../hooks/useErrors";

const defaultModalType = {
  type: "basic",
  steps: [],
};

const arrayBuilder = (objOld, its_request = false) => {
  let checkerBy = {
    first: "steps",
    second: "guide_buyer_items",
    third: "answers",
    last: "guide_buyer_items",
  };
  if (its_request) {
    checkerBy.first = "guide_buyer_items_attributes";
    checkerBy.second = "steps";
    checkerBy.third = "guide_buyer_items_attributes";
    checkerBy.last = "answers";
  }

  let obj = { ...objOld };
  obj[checkerBy.first] = obj[checkerBy.second].map((s) => {
    let objChild = { ...s };
    objChild[checkerBy.third] = s[checkerBy.last];
    if (s._destroy) {
      objChild[checkerBy.third] = objChild[checkerBy.third].map((d) => ({
        ...d,
        _destroy: 1,
      }));
    }
    delete objChild[checkerBy.last];
    return objChild;
  });
  delete obj[checkerBy.second];

  return obj;
};

const GuideBuyersGuidesModal = ({ id, onClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState({});
  const [Images, setImages] = useState([]);
  const [modalType, setModalType] = useState(defaultModalType);
  const { errors, setErrors } = useErrors();

  const fileInput = createRef(null);

  const type = id == 0 ? "create" : "update";

  const onChangeState = (e) => {
    const { name, value } = e.target;
    setValue((prevProps) => ({ ...prevProps, [name]: value }));
  };
  //

  useEffect(() => {
    getGuideBuyersShow(id).then((res) => {
      if (res.success) {
        setValue(arrayBuilder(res.data));
        setImages(
          res.data.landing_documents.filter((e) => e.image_base64 !== "")
        );
      }
      setLoading(false);
    });
  }, [id]);
  //

  const onSave = async () => {
    console.error("REQUEST STARTED");
    // console.log(value);
    // console.log(Images);
    // console.log(modalType);
    try {
      setErrors({});
      const { success, errors } = await dispatch(
        postGuideBuyers({
          ...arrayBuilder(value, true),

          landing_documents_attributes: Images.filter(
            (e) => e._destroy || !e.id
          ).map((e) => {
            let obj = { ...e };
            delete obj.image_base64;
            return obj;
          }),
        })
      );

      if (success) {
        onClose();
      } else {
        setErrors(buildErrorObject(errors));
        setModalType(defaultModalType);
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const onBack = () => {
    if (modalType.steps.length > 1)
      return setModalType((prev) => ({
        type: "show",
        steps: [first_arr(prev.steps)],
      }));

    if (modalType.steps.length == 1) return setModalType(defaultModalType);
  };

  return (
    <ModalSpecial
      style={{ maxWidth: 600 }}
      text={
        modalType.type == "basic" ? (
          <span className="text-black text-xl">
            {type == "create" ? "Pridėti naują gidą" : "Redaguoti gidą"}
          </span>
        ) : (
          ""
        )
      }
      backdrop={() => log("Close denied")}
      onClose={() => {
        // if (type == "create") {
        //   return onClose({
        //     state,
        //     categories,
        //     Images,
        //     assigns,
        //   });
        // }
        return onClose({});
      }}
    >
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100%" }}
        >
          <Spinner />
        </div>
      ) : modalType.type == "basic" ? (
        <FirstModalPreview
          value={value}
          onChangeState={onChangeState}
          Images={Images}
          setImages={setImages}
          fileInput={fileInput}
          setModalType={setModalType}
          setValue={setValue}
          errors={errors}
        />
      ) : modalType.steps.length > 1 ? (
        <>
          <Virsus
            onBack={onBack}
            text={
              value.steps[first_arr(modalType.steps)].answers[
                last_arr(modalType.steps)
              ].title
            }
            subText="Atsakymo pridėjimas"
          />
          <ThirdModalPreview
            value={
              value.steps[first_arr(modalType.steps)].answers[
                last_arr(modalType.steps)
              ]
            }
            setValue={setValue}
            modalType={modalType}
          />
        </>
      ) : (
        <>
          <Virsus
            onBack={onBack}
            text={value.steps[first_arr(modalType.steps)].title}
          />
          <SecondModalPreview
            value={value.steps[first_arr(modalType.steps)]}
            modalType={modalType}
            setModalType={setModalType}
            setValue={setValue}
          />
        </>
      )}

      <DoubleButton
        showBack={modalType.type == "basic" ? false : true}
        onSave={onSave}
        onBack={onBack}
      />
    </ModalSpecial>
  );
};

export default GuideBuyersGuidesModal;

const Virsus = ({ onBack, text, subText = "Žingsnio pridėjimas" }) => {
  return (
    <div
      className="d-flex align-items-center mb-4 cursor-pointer"
      onClick={onBack}
    >
      <div className="mr-2">
        <IconChevronLeft />
      </div>
      <div>
        <div className="font-semi-bold text-xl text-black ">{text}</div>
        <div className="text-xs text-gray-500">{subText}</div>
      </div>
    </div>
  );
};
