import React, { useState } from "react";
import { TiFolder } from "react-icons/ti";
import styled from "styled-components";
import Logo from "../../../../assets/images/techno_logo_black_main.png";
import SidebarNavLink from "./SidebarNavLink";
import UserPermissionsOption from "../../../helpers/UserPermissionsOption";
import { Bubble } from "../../common/StyledComponents";

class Sidebar extends React.Component {
  componentDidMount() {
    console.count("Sidebar render");
  }
  render() {
    const sidebar_tree = UserPermissionsOption(
      this.props.auth,
      this.props.additional_information
    );
    const permissions = this.props.auth?.user_role?.permissions || false;
    return (
      <div className="bg-light border-right bg-white" id="sidebar-wrapper">
        <div
          className="sticky-top"
          style={{ zIndex: 10, maxHeight: "100vh", overflowY: "auto" }}
        >
          <div className="sidebar-heading text-center">
            <img className="d-block text-center" src={Logo} width="200" />
          </div>
          <SideBarMenu className="list-group list-group-flush">
            <ul className="navbar-nav flex-column mt-4 mb-5">
              {permissions && (
                <>
                  {sidebar_tree.map((e, idx) => (
                    <RenderSidebarMenu
                      key={`${e?.title}_${idx}`}
                      e={e}
                      second={false}
                      locationUrl={this.props.location.locationUrl}
                      permissions={permissions}
                    />
                  ))}
                </>
              )}
            </ul>
          </SideBarMenu>
        </div>
      </div>
    );
  }
}

const RenderSidebarMenu = ({
  e,
  second = true,
  old_icon = false,
  locationUrl,
  permissions,
}) => {
  if (!permissions[e.uuid] || e.visible === false) return <div />;
  if (e.options?.length > 0) {
    return (
      <RenderSidebarMenuChildrens
        e={e}
        second={second}
        old_icon={e.icon}
        locationUrl={locationUrl}
        permissions={permissions}
      />
    );
  }
  return (
    <SidebarNavLink name={e.title} url={e.url} sub={second} uuid={e.uuid} dot={e.dot}>
      {e.icon !== false &&
        (e.icon || old_icon || <TiFolder className="mr-3" />)}
    </SidebarNavLink>
  );
};

const RenderSidebarMenuChildrens = ({
  e,
  second = true,
  old_icon = false,
  locationUrl,
  permissions,
}) => {
  const byDefaultOpen = () => {
    function checked(array, accum = []) {
      array.forEach((f) => {
        if (f.options) {
          return checked(f.options, accum);
        }
        const its_string = typeof f.url;
        if (
          f.url &&
          its_string == "string" &&
          f.url.toLowerCase() == locationUrl.toLowerCase()
        ) {
          accum.push(f);
        }
      });
      return accum?.length > 0 || false;
    }
    return checked(e.options);
  };
  const [open, setOpen] = useState(byDefaultOpen());

  return (
    <li className="active cursor-pointer">
      <div
        className={`${
          second ? "subnav-item ml-1 pl-1" : "px-3 mb-1"
        }  sidebar-link dropdown-toggle nav-link`}
        onClick={() => setOpen((prev) => !prev)}
      >
        {e.icon !== false &&
          (e.icon || old_icon || <TiFolder className="mr-3" />)}{" "}
        {e.title}
        {!!e.dot && <Bubble up={true}>{""}</Bubble>}
      </div>
      <ul
        className={`list-unstyled navbar-nav flex-column navbar-ul-sidebar border-left ${
          second ? "ml-1 pl-1 " : "pl-2 ml-5"
        }`}
      >
        {open &&
          e.options?.map((ep, idx) => (
            <RenderSidebarMenu
              key={`${ep?.title}_${idx}`}
              e={ep}
              second={true}
              old_icon={e.icon}
              locationUrl={locationUrl}
              permissions={permissions}
            />
          ))}
      </ul>
    </li>
  );
};

const SideBarMenu = styled.div`
  .sidebar-link {
    color: #5f5f5f;
    padding: 0.2rem 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }
  .sidebar-link:hover {
    color: #212121 !important;
    font-size: 1rem;
    font-weight: 500;
    transform: translateY(-1px);
  }

  .current-link {
    color: #212121 !important;
    padding: 0.2rem 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.3s;
  }

  .subnav-item {
    font-size: 0.8rem !important;
  }
`;

export default Sidebar;
