import React, { forwardRef, useImperativeHandle } from "react";
import ReactDOM from "react-dom";
import ModalSpecial from "../../Layout/Modals/ModalSpecial";
import { addOverFlow, removeOverFlow } from "../../../helpers/modalFunc";
import DocumentAddModal from "./documentAddModal";
import DocumentShowRender from "./DocumentShowRender";

const DocumentModalRef = forwardRef((props, ref) => {
  const [display, setDisplay] = React.useState("first");

  useImperativeHandle(ref, () => {
    return {
      openModal: (active) => open(active),
      close: () => close(),
      active: display,
    };
  });

  const open = (active) => {
    addOverFlow();
    setDisplay(active);
  };

  const close = () => {
    removeOverFlow();
    setDisplay(false);
  };

  if (display == "DocumentShow") {
    return ReactDOM.createPortal(
      <DocumentShowRender {...props} onClose={() => close()} />,
      document.getElementById("modal-roots")
    );
  }

  if (display == "Document_add") {
    const tilteSpan = (
      <span
        style={{
          fontSize: "1.2rem",
          lineHeight: "27px",
          color: "#000000",
          fontWeight: "700",
        }}
      >
        Dokumento kūrimas
      </span>
    );

    return ReactDOM.createPortal(
      <ModalSpecial
        text={tilteSpan}
        handleSave={() => {}}
        onClose={() => close()}
      >
        <DocumentAddModal
          documentPlan={props.documentPlan}
          document_types={props.documentType.map((e) => ({
            ...e,
            plans: props.documentPlan.filter(
              (fi) => fi.document_type_id == e.value
            ),
          }))}
          onEdit={props.onEdit}
        />
      </ModalSpecial>,
      document.getElementById("modal-roots")
    );
  }

  return null;
});
export default DocumentModalRef;
