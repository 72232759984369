import React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { FaTimes, FaTrash } from "react-icons/fa";
import { getBase64 } from "../../../helpers/Utils";

export default function GuideIllustration({ Images, setImages, fileInput }) {
  const uploadImage = (e) => {
    const file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        setImages([
          ...Images,
          {
            asset: result,
            image_file: true,
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <label className={"editLabelForm text-bold my-1 "}>
        {"Iliustracijos"}
      </label>
      <div className="col-12">
        <div className="row">
          {Images.filter((e) => e.image_file).map((e, idx) => (
            <div
              key={idx}
              className="col-4 position-relative pb-2 pl-0 pr-1"
              style={{
                height: 117,
                // width: 185,
                borderRadius: 3,
              }}
            >
              <div
                style={{
                  height: "100%",
                  backgroundImage: `url(${e.image_base64 || e.asset})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              />
              {/* <img
          className="img-fluid"
          style={{
            // width: "100%",
            // height: "100%",
            width: "100%",
            height: "100%",
            borderRadius: 3,
            opacity: e._destroy ? "0.2" : 1,
          }}
          src={`${e.image_base64 || e.asset}`}
        /> */}
              <div
                className="position-absolute cursor-pointer"
                style={{ right: 15, top: 15 }}
              >
                {e._destroy ? (
                  <div
                    className="bg-warning text-white  px-2 py-1"
                    style={{ borderRadius: 3 }}
                    onClick={() => {
                      let old = [...Images];
                      old[idx]._destroy = false;
                      setImages(old);
                    }}
                  >
                    <FaTimes />
                  </div>
                ) : (
                  <div
                    className="bg-danger text-white px-2 py-1"
                    style={{ borderRadius: 3 }}
                    onClick={() => {
                      let old = [...Images];
                      if (old[idx].id) {
                        old[idx]._destroy = true;
                      } else {
                        old.splice(idx, 1);
                      }
                      setImages(old);
                    }}
                  >
                    <FaTrash />
                  </div>
                )}
              </div>
            </div>
          ))}
          {Images.filter((e) => e.image_file).filter((d) => !d._destroy)
            .length < 1 && (
            <div
              className="col-4 position-relative pb-2 pl-0 pr-1"
              style={{
                width: "100%",
                height: 117,
                borderRadius: 3,
              }}
              onClick={() => fileInput.current.click()}
            >
              <div
                className="d-flex flex-column align-items-center justify-content-center cursor-pointer h-100"
                style={{
                  backgroundColor: "#F3F3F3",
                }}
              >
                <span
                  className="d-flex justify-content-center align-items-center rounded-circle"
                  style={{
                    backgroundColor: "#BABABA",
                    width: 28,
                    height: 28,
                  }}
                >
                  <AiOutlinePlus size={20} color="#fff" />
                </span>
                <span className="mt-1" style={{ fontSize: 13 }}>
                  Pridėti iliustraciją
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <input
        type="file"
        accept="image/*"
        hidden={true}
        ref={fileInput}
        onChange={uploadImage}
      />
    </div>
  );
}
