import React from "react";
import moment from "moment";

function Comments({ comment }) {

  return (
    <div>
      <span style={{fontSize: '11px'}}><b>{comment.user_name}</b></span> <br />
      <span style={{fontSize: '11px'}}>{moment(comment.created_at).format("YYYY-MM-DD H:MM")}</span> <br />
      <span style={{fontSize: '14px'}}>{comment.comment}</span> <hr />
    </div>
  );
}

export default Comments;
