import React, { useEffect, useState } from "react";
import useFetch from "../../helpers/useFetch";
import Spinner from "../common/Spinner";
import { AiOutlineClose } from "react-icons/ai";
import TextInputModal from "../common/TextInputModal";
import TextAreaFieldGroup from "../common/TextAreaFieldGroup";
import { MdInfo } from "react-icons/md";
import { getStoreItem } from "../../redux/Actions/storeAction";
import { antdMessageInfo } from "../../helpers/Utils";
import { descriptionCallESL } from "../../redux/Actions/descriptionAction";
import { useSelector } from "react-redux";

function DescriptionModal({ description_id, onClose, store_id, repricing_id }) {
  const [rivileCode, setRivileCode] = useState("");
  const [previewImage, setPreviewImage] = useState(false);
  const { value, loading, error } = useFetch(
    `/api/v1/descriptions/${description_id}${
      store_id ? `?store_id=${store_id}` : ""
    }`,
    {},
    [description_id]
  );

  useEffect(() => {
    loadImage();
  }, [value?.id]);

  useEffect(() => {
    if (!store_id) return;
    getStoreItem(store_id).then((d) => {
      if (d.success) setRivileCode(d?.data?.rivile_code || "");
    });
  }, [store_id]);

  const loadImage = () => {
    if (value?.id) {
      let data = {
        description: {
          type: "image",
          object: "repricing",
        },
      };
      fetch(
        repricing_id
          ? `/api/v1/repricings/${repricing_id}/pdf_description`
          : `/api/v1/descriptions/${value.id}/pdf_description?store_id=${store_id}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      )
        .then((response) => {
          if (!response.ok) throw new Error(response.status);
          else return response.blob();
        })
        .then((response) => {
          if (response.size > 80) {
            let objectURL = URL.createObjectURL(response);
            let myImage = new Image();
            myImage.src = objectURL;
            setPreviewImage(objectURL);
          }
        })
        .catch((error) => {
          console.log("error: " + error);
        });
    }
  };
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spinner />
      </div>
    );
  }
  if (error) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        Įvyko klaida
      </div>
    );
  }

  const getFormatName = () => {
    if (value.family && value.family.format_id) {
      let format = value.family.print_formats.find(
        (i) => i.id == value.family.format_id
      );
      if (format) {
        return format.print_size;
      }
    }
    return "-";
  };

  const print = () => {
    let data = {
      description: {
        ids: [value.id],
        type: "pdf",
      },
    };
    if (repricing_id) {
      data.description.repricing_id = repricing_id;
      data.repricing = {
        ids: [repricing_id],
        type: "pdf",
      };
    }
    fetch(
      repricing_id
        ? `/api/v1/repricings/${repricing_id}/pdf_description`
        : `/api/v1/descriptions/${value.id}/pdf_description?store_id=${store_id}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.blob())
      .then((response) => {
        const fileURL = URL.createObjectURL(response);
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
      });
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-12 d-flex align-items-center justify-content-between">
          <div className="font-semi-bold text-black text-xl mb-3">
            {value.title}
          </div>
          <div>
            <AiOutlineClose
              className="pl-2 cursor-pointer"
              size={25}
              color={"#BFBFBF"}
              onClick={() => onClose()}
            />
          </div>
        </div>
        <div className="col-6"></div>
        {/* Content */}
        <div className="container-fluid">
          <div className={"row"}>
            <div className="col-12">
              <div className="bg-white p-3">
                <div className="col-12">
                  <div className="row">
                    <div className="">
                      <div className="description-image-container">
                        <div
                          className="description-image"
                          style={{
                            backgroundImage: `url("${value.image_url}")`,
                            maxWidth: "600px",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="flex-1 ml-4">
                      <div className="row small-label">
                        <div className="col-md-12 mb-2">
                          <TextInputModal
                            style={{ fontSize: `14px`, color: `#000000` }}
                            number="1"
                            disabled={true}
                            text="Prekės kodas"
                            value={value.identifier}
                          />
                        </div>
                        <div className="col-md-6 mb-2">
                          <TextInputModal
                            style={{ fontSize: `14px`, color: `#000000` }}
                            number="1"
                            disabled={true}
                            text="Prekės barkodas"
                            value={value.barcode || ""}
                          />
                        </div>
                        <div className="col-md-6 mb-2">
                          <TextInputModal
                            style={{ fontSize: `14px`, color: `#000000` }}
                            number="1"
                            disabled={true}
                            text="Prekės gamintojas"
                            value={
                              value.brand_title || value.manufacturer || ""
                            }
                          />
                        </div>
                        <div className="col-md-6 mb-2">
                          <TextInputModal
                            style={{ fontSize: `14px`, color: `#000000` }}
                            number="1"
                            disabled={true}
                            text="Prekės kategorija"
                            value={
                              value.family
                                ? value.family.synonim
                                  ? value.family.synonim
                                  : value.family.name
                                : ""
                            }
                          />
                        </div>
                        <div className="col-md-3 mb-2">
                          <div className="form-group mb-0">
                            <div>
                              <label className={"editLabelForm"}>
                                Logotipas
                              </label>
                            </div>
                            {value.brand_logo ? (
                              <img
                                src={value.brand_logo}
                                className="img-fluid"
                                style={{ maxHeight: "30px" }}
                              />
                            ) : (
                              "-"
                            )}
                          </div>
                        </div>
                        <div className="col-md-3 mb-2">
                          <div className="form-group mb-0">
                            <div>
                              <label className={"editLabelForm"}>
                                QR kodas
                              </label>
                            </div>
                            {value.qr_code_image ? (
                              <img
                                src={value.qr_code_image}
                                className="img-fluid"
                                style={{ maxHeight: "100px" }}
                              />
                            ) : (
                              "-"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-7">
                    <div className="row">
                      <div className="col-12">
                        <ul className="list-group">
                          <li className="list-group-item ">
                            <b>Atributai</b>
                          </li>
                          {value.product_attribute_values &&
                            value.product_attribute_values.map(
                              (item, index) => (
                                <li className="list-group-item" key={index}>
                                  <div className="row">
                                    <div className="col-6">
                                      {item.synomim ? item.synomim : item.title}
                                    </div>
                                    <div className="col-6 text-right">
                                      {item.value}
                                    </div>
                                  </div>
                                </li>
                              )
                            )}
                        </ul>
                      </div>
                      <div className="col-12">
                        <TextAreaFieldGroup
                          placeholder="Ypatingos savybės"
                          value={value.spec_details}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="row small-label">
                      <div className="col-12 mb-2">
                        <TextInputModal
                          style={{ fontSize: `14px`, color: `#000000` }}
                          number="1"
                          disabled={true}
                          text="Extra fee (AGATA)"
                          value={value.fee ? value.fee : "-"}
                        />
                      </div>
                      <div className="col-12 mb-2">
                        <TextInputModal
                          style={{ fontSize: `14px`, color: `#000000` }}
                          number="1"
                          disabled={true}
                          text="Formatas"
                          value={getFormatName()}
                        />
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-12 d-flex">
                        {value.family && value.family.printed && previewImage && (
                          <div>
                            <button
                              className="btn btn-danger"
                              style={{ width: `112px` }}
                              onClick={() => print()}
                              // onClick={() => {
                              //   const pdfWindow = window.open();
                              //   pdfWindow.location.href = previewImage;
                              // }}
                              disabled={!value.family?.format_id}
                            >
                              Spausdinti
                            </button>
                          </div>
                        )}
                        <SyncButton
                          value={value}
                          rivileCode={rivileCode}
                          repricing_id={repricing_id}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-5">
                    {previewImage && (
                      <div id="description-image-container" className="mb-3">
                        <h5>Numatomas atvaizdavimas</h5>
                        <div className="mb-2" id="description-image">
                          <img src={previewImage} alt="" />
                        </div>
                        <span>
                          <MdInfo /> Realus vaizdas gali skirtis nuo numatomo
                        </span>
                      </div>
                    )}
                    <div className="">
                      {value.store_prices && value.store_prices.length > 0 && (
                        <div className="list-group store-prices">
                          <div className="list-group-item store-price-header">
                            Prekės kaina skirtinguose padaliniuose
                          </div>
                          <div className="store-prices-container">
                            {value.store_prices.map(
                              (i, index) =>
                                i[0] &&
                                i[1] && (
                                  <div
                                    className="list-group-item store-price"
                                    key={index}
                                  >
                                    <div className="row">
                                      <div className="col-6">{i[0]}</div>
                                      <div className="col-6 text-right">
                                        {i[1]}
                                      </div>
                                    </div>
                                  </div>
                                )
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Conten end */}
      </div>
    </div>
  );
}

const SyncButton = ({ value, repricing_id, rivileCode }) => {
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [available, setAvailable] = useState(false);
  const esl = () => {
    let data = {
      description: {
        ids: [value.id],
        type: "esl",
      },
    };
    if (repricing_id) {
      data.description.repricing_id = repricing_id;
      data.repricing = {
        ids: [repricing_id],
        type: "esl",
      };
    }
    setLoading(true);
    antdMessageInfo("Generuojama");
    descriptionCallESL([value.id]).finally(() => {
      setLoading(false);
    });
    // fetch(`/api/v1/descriptions/${value.id}/esl_update?store_id=${store_id}`, {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(data),
    // })
    //   .then((res) => res.json())
    //   .then((response) => {
    //     if(response.error) throw new Error(response.error)
    //     antdMessageSuccess();
    //   })
    //   .catch((err) => {
    //     antdMessageError(err?.response?.data?.error || err?.message);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  useEffect(() => {
    if (["admin"].includes(user.user_role.identifier)) {
      if (rivileCode == "U111") return setAvailable(true);
    }
    if (["manager", "store_manager"].includes(user.user_role.identifier)) {
      if (user?.store?.rivile_code == "U111") return setAvailable(true);
    }
  }, [rivileCode]);

  if (!available) return <div />;
  return (
    <button
      className="btn btn-warning"
      style={{ width: `112px`, marginLeft: 10, color: "#fff" }}
      onClick={() => esl()}
      // onClick={() => {
      //   const pdfWindow = window.open();
      //   pdfWindow.location.href = previewImage;
      // }}
      disabled={value.family.format_id === null}
    >
      {loading ? (
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <span className="spinner-border spinner-border-sm"></span>
          </div>
          <span> Vykdoma...</span>
        </div>
      ) : (
        "ESL"
      )}
    </button>
  );
};

export default DescriptionModal;
