import {
  GET_DOCUMENT_TYPE,
  POST_DOCUMENT_TYPE,
  UPDATE_DOCUMENT_TYPE,
  DELETE_DOCUMENT_TYPE,
  LOADING_DOCUMENT_TYPE,
  DELETE_DOCUMENT_PLAN,
  POST_DOCUMENT_PLAN,
  UPDATE_DOCUMENT_PLAN,
} from "../Actions/types";

const initialState = {
  documentType: [],
  loading_documentType: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_DOCUMENT_TYPE:
      return {
        ...state,
        loading_documentType: true,
      };
    case GET_DOCUMENT_TYPE:
      return {
        ...state,
        documentType: action.payload,
        loading_documentType: false,
      };
    case POST_DOCUMENT_TYPE:
      let postData = [...state.documentType];
      postData.push(action.payload);
      return {
        ...state,
        documentType: postData,
      };
    case POST_DOCUMENT_PLAN:
      let documentsForPlan = [...state.documentType];
      let findDocument = documentsForPlan.findIndex(
        (item) => item.id == action.payload.document_type_id
      );
      if (findDocument !== -1) {
        documentsForPlan[findDocument].document_plans.push(action.payload);
      }
      return {
        ...state,
        documentType: documentsForPlan,
      };
    case UPDATE_DOCUMENT_TYPE:
      let updateData = [...state.documentType];
      const updateElementId = (element) => element.id === action.payload.id;
      const findIndex = updateData.findIndex(updateElementId);
      updateData[findIndex] = action.payload;
      return {
        ...state,
        documentType: updateData,
      };
    case UPDATE_DOCUMENT_PLAN:
      return {
        ...state,
        documentType: updateDocumentPlan(action.payload, state),
      };
    case DELETE_DOCUMENT_TYPE:
      return {
        ...state,
        documentType: [...state.documentType].filter(
          (filterItem) => filterItem.id !== action.payload
        ),
        loading_documentType: false,
      };
    case DELETE_DOCUMENT_PLAN:
      return {
        ...state,
        documentType: deleteDocumentPlan(action.payload, state),
      };
    default:
      return state;
  }
}

const deleteDocumentPlan = (itemAction, state) => {
  let deleteDocPlanArray = [...state.documentType];
  const findIdDoc = deleteDocPlanArray.findIndex(
    (item) => item.id == itemAction.document_type_id
  );
  if (findIdDoc !== -1) {
    let haveObj = deleteDocPlanArray[findIdDoc];
    haveObj.document_plans = haveObj.document_plans.filter(
      (filterItem) => filterItem.id !== itemAction.id
    );
  }

  return deleteDocPlanArray;
};

const updateDocumentPlan = (itemAction, state) => {
  let allItems = [...state.documentType];
  const findDocument = allItems.findIndex(
    (item) => item.id == itemAction.document_type_id
  );

  if (findDocument !== -1) {
    let document = allItems[findDocument];
    const findItem = document.document_plans.findIndex(
      (item) => item.id == itemAction.id
    );
    if (findItem !== -1) {
      allItems[findDocument].document_plans[findItem] = itemAction;
    }
  }
  return allItems;
};
