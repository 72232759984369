import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MdTimer } from "react-icons/md";

export default function MiniTimeTracking({ number = 0 }) {
  const firstState = () => {
    const number_to_string = number + "";
    const its_past_due = number_to_string.startsWith("-");
    return its_past_due;
  };

  const [count, setCount] = useState(0);
  const [pastdue, setPastdue] = useState(firstState());
  const [value, setValue] = useState(0);
  const [reNumber, setReNumber] = useState(number);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => prevCount + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const check_number = reNumber + "";
    const re_number = check_number.startsWith("-")
      ? Number(check_number.substr(1))
      : Number(check_number);

    const total = pastdue
      ? re_number + count
      : re_number > count
      ? re_number - count
      : count - re_number;
      if (total == 0) {
        setReNumber(0)
        setCount(0)
        setPastdue(true);
        return setValue(0);
      }
      setValue(total);
  }, [count]);

  const prettierNumber = () => {
    let time = value;
    const HOURS = Math.floor(time / 3600);
    if (HOURS > 0) {
      time = time - HOURS * 3600;
    }
    const MINUTES = Math.floor(time / 60);
    if (MINUTES > 0) {
      time = time - MINUTES * 60;
    }
    const SECONDS = time;

    return `${HOURS}:${MINUTES > 9 ? MINUTES : `0${MINUTES}`}:${
      SECONDS > 9 ? SECONDS : `0${SECONDS}`
    }`;
  };
  return (
    <div>
      <Container>
        <MdTimer
          color="#979797"
          style={{
            width: 10,
            height: 11,
          }}
        />
        <Paragraph pastdue={pastdue}>{prettierNumber()}</Paragraph>
      </Container>
    </div>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 2px;
  padding: 3px 4px;
`;

const Paragraph = styled.div`
  margin-left: 2px;
  color: ${(props) => (props.pastdue ? "#FF0000" : "#000")};
  font-size: 11px;
  line-height: 12.8px;
`;
