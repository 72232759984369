import Axios from "axios";
import {
  GET_USERS,
  POST_USER,
  DELETE_USER,
  UPDATE_USER,
  LOADING_USER,
  CLEAR_ERRORS,
  GET_ERRORS,
  SET_CURRENT_USER,
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

const success = () => {
  if (!document.getElementById("userAlert-s")) return;
  document.getElementById("userAlert-s").style.display = "block";
  setTimeout(
    () => (document.getElementById("userAlert-s").style.display = "none"),
    5000
  );
  document.getElementById("modalClose").click();
};
const error = () => {
  if (!document.getElementById("userAlert-e")) return;
  document.getElementById("userAlert-e").style.display = "block";
  setTimeout(() => {
    if (document.getElementById("userAlert-e")) {
      document.getElementById("userAlert-e").style.display = "none";
    }
  }, 5000);
};

export const postUser = (dataBody) => (dispatch) => {
  Axios.post("/api/v1/users", dataBody)
    .then((res) => {
      dispatch({
        type: POST_USER,
        payload: res.data,
      });
      dispatch(clearErrors());
      success("post");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const getUsers = (page, pageCount, filter) => (dispatch) => {
  dispatch(setUserLoading());
  Axios.get(`/api/v1/users?page=${page}&per_page=${pageCount}${filter}`)
    .then((res) => {
      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getUserOptions = async (filter = "") => {
  try {
    const { data } = await Axios.get(`/api/v1/users?${filter}`);
    return {
      success: true,
      data: data?.data || [],
    };
  } catch (err) {
    return {
      success: false,
      data: [],
    };
  }
};

export const updateUser = (_id, body, indexOfArray) => (dispatch) => {
  return Axios.put(`/api/v1/users/${_id}`, body)
    .then((res) => {
      dispatch(clearErrors());
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });

      success("update");
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};
export const authRemove = (_id) => async (dispatch) => {
  try {
    const { data } = await Axios.delete(`/api/v1/two_factor_settings/${_id}`);
    if (data.success) {
      dispatch(clearErrors());
      dispatch({
        type: UPDATE_USER,
        payload: data.user,
      });
      success();
      return data.user;
    }
  } catch (err) {
    error();
    console.log("Error:authRemove ", err);
  }
};
export const updateUserCurrent = (_id, body, indexOfArray) => (dispatch) => {
  Axios.put(`/api/v1/users/${_id}`, body)
    .then((res) => {
      dispatch(clearErrors());
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
        payload_id: _id,
      });
      dispatch({
        type: SET_CURRENT_USER,
        payload: res.data,
      });
      success("update");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const deleteUer = (_id) => (dispatch) => {
  if (confirm("Ar tikrai norite ištrinti ? ")) {
    Axios.delete(`/api/v1/users/${_id}`)
      .then((res) => {
        dispatch({
          type: UPDATE_USER,
          payload: res.data,
        });
        success();
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        error();
      });
  }
};

export const setUserLoading = () => {
  return {
    type: LOADING_USER,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
