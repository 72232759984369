import React, { useState, useEffect } from "react";
import { Alert } from "antd";
import { RiErrorWarningLine } from "react-icons/ri";
import FormWithMultipleInputs from "../../common/FormWithMultipleInputs";
import { FiEdit2 } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { updateRivileInvoice } from "../../../redux/Actions/rivileAction";

function StoreComponent({ accessEdit, rivileInvoiceId, store, stores }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(store);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (accessEdit) {
      setValue(store);
    }
  }, [store]);

  const onChangeHandler = (e) => {
    setValue(e);
  };
  const onClickSave = () => {
    setLoading(true);
    dispatch(updateRivileInvoice(rivileInvoiceId, value, 'notes')).then((res) => {
      if (res.success) {
        setEdit(false);
        setLoading(false);
        setError(false);
      } else {
        setLoading(false);
        setError(true);
      }
    });
  };
  const onEdit = () => {
    setEdit(!edit);
  };

  const renderShopName = (store) => {
    const findStore = stores.find(e => e.value == store)
    if(findStore) {
      return findStore.label
    } else {
      return ""
    }
  }
  return (
    <span
      className="d-flex align-items-center font-weight-bold d-block modal-title text-black"
      style={{ fontSize: "13px", minHeight: 32 }}
    >
      {!edit ? (
        <>
          <RiErrorWarningLine title="Pastaba" className="text-black mr-1" />
          <b>{store ? `Padalinys: ${renderShopName(store)}` : "Nėra padalinio!"}</b>
          {accessEdit && (
            <FiEdit2
              className="ml-2 icon_hover"
              title="Redaguoti"
              style={{ cursor: "pointer", color: "silver" }}
              size="13"
              onClick={() => onEdit()}
            />
          )}
        </>
      ) : (
        <>
          <FormWithMultipleInputs
            options={stores}
            value={renderShopName(value)}
            loading={loading}
            onChange={onChangeHandler}
            onSave={onClickSave}
            onClose={onEdit}
          />
          {error && (
            <Alert className="mt-1" message="Įvyko klaida." type="error" />
          )}
        </>
      )}
    </span>
  );
}

export default StoreComponent;