import React, { useEffect, useState } from "react";
import { errorHandler, getDateFormat } from "../../../helpers/Utils";
import InputSwitchComponent from "../../common/InputSwitchComponent";
import TextInputModal from "../../common/TextInputModal";
import RangePickerComp from "../../common/RangePickerComp";
import { postPoster } from "../../../redux/Actions/postersAction";
import PosterPreview from "./PosterPreview";
import { searchProduct } from "../../../redux/Actions/productAction";
import useDebounce from "../../../hooks/useDebounce";
import { useSelector } from "react-redux";
import { CheckBoxInput, SelectForm } from "../../common/Buttons";
import { AutoComplete, Spin, Modal } from "antd";
import useFetch from "../../../helpers/useFetch";
import { elementToSVG } from "dom-to-svg";
import { IconExclamation } from "../../Layout/Icons";
const { confirm } = Modal;

export default function PosterCreate({ action, setAction, setModal }) {
  const [firstRender, setFirstRender] = useState(true);
  const { value, setValue, loading, error } = useFetch(
    `/api/v1/posters/${action == "create" ? "new" : action}`,
    {},
    [action]
  );
  const [loadingCustom, setLoadingCustom] = useState(false);
  const variables = value;
  const setVariables = (func) => setValue(func);

  useDebounce(() => searchProductByProductCode(), 1000, [
    variables?.product_code,
  ]);

  useEffect(() => {
    if (!loading && action == "create") {
      setValue((prev) => ({
        ...prev,
        poster_type: "product",
      }));
    }
  }, [loading]);

  const searchProductByProductCode = () => {
    if (!variables?.product_code) return;
    if (firstRender && action !== "create") return setFirstRender(false);
    if (variables.poster_type == "product" && variables.product_code) {
      searchProduct(variables.product_code).then((res) => {
        if (res.length > 0) {
          const item = res[0];
          setVariables((prev) => ({
            ...prev,
            product_title: item.title,
            product_code: item.identifier,
            product_category: item.family.name,
            price: item.r_price || 0,
            discount_type_from: 0,
            discount_price: 0,
            discount_type_symbol: prev.discount_type_symbol || "%",
          }));
        }
      });
    }
  };

  const createImage = async (only_print = false) => {
    try {
      setLoadingCustom(true);
      const svgDocument = elementToSVG(
        document.getElementById("create_poster")
      );

      const svgString = new XMLSerializer().serializeToString(svgDocument);
      const dataUrl = svgString;

      // return;
      let params = {
        ...variables,
        poster_image: dataUrl,
      };
      delete params.product_image_base64;
      delete params.brand;
      if (params.product_image?.url) {
        delete params.product_image;
      }
      if (params.poster_image?.url) {
        delete params.poster_image;
      }
      if (!params.discount_title) {
        params.discount_title = "Be pavadinimo";
      }

      const { success } = await postPoster({ poster: params });
      if (success) {
        if (only_print) {
          return setModal({
            type: "print",
            selected: [{ id: variables.id }],
          });
        } else {
          setAction("index");
        }
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoadingCustom(false);
    }
  };
  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spin />
      </div>
    );
  }
  if (error) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        {JSON.stringify(error)}
      </div>
    );
  }
  const legal_texts_option = [
    "",
    "Akcinių prekių kiekis ribotas.",
    "Akcijos ir nuolaidos nesumuojamos.",
    "Nuolaida taikoma akcijos skirtukais pažymėtoms prekėms.",
    "Nuolaida taikoma kasoje.",
  ].map((value) => ({ value, label: value }));
  return (
    <div className="col-12 d-flex mb-5">
      <div className="">
        <div
          className={"bg-white"}
          style={{
            padding: "17px 21px",
          }}
        >
          <h2 className="text-xl text-black font-weight-bold">
            Plakato kūrimas
          </h2>

          <div
            className="row"
            style={{
              padding: "10px 0px",
            }}
          >
            <div className="col-6">
              <InputSwitchComponent
                name="type"
                options={[
                  {
                    label: "Prekei",
                    value: "product",
                  },
                  {
                    label: "Prekių kategorijai",
                    value: "product_category",
                  },
                ]}
                value={variables.poster_type}
                onChange={(e) => {
                  console.log("E", e);
                  setVariables((prev) => ({
                    ...prev,
                    poster_type: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
          {variables.poster_type == "product" ? (
            <Product variables={variables} setVariables={setVariables} />
          ) : variables.poster_type == "product_category" ? (
            <ProductCategory
              variables={variables}
              setVariables={setVariables}
            />
          ) : null}
          <div className="col-12 px-0">
            {/* <div className={"text-bold my-1"}>Teisinis tekstas</div> */}
            {/* <div className="bg-silver text-black rounded text-sm px-2 py-1">
              {variables.legal_text || legal_text}
            </div> */}
            <SelectForm
              placeholder="Teisinis tekstas"
              onlyPlaceholder="Pasirinkti"
              options={legal_texts_option}
              name="legal_text"
              value={variables.legal_text}
              onChange={(legal_text) =>
                setVariables((prev) => ({
                  ...prev,
                  legal_text,
                }))
              }
            />
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <button
              className="btn btn-primary text-xs font-weight-bold px-4 mt-3 mb-2"
              onClick={() => createImage()}
              disabled={loadingCustom}
            >
              {loadingCustom ? (
                <>
                  <span className="spinner-border spinner-border-sm"></span>
                  <span> Vykdoma...</span>
                </>
              ) : (
                "Išsaugoti"
              )}
            </button>
            {variables.id && (
              <button
                className="btn btn-danger text-xs font-weight-bold px-4 mt-3 mb-2"
                // onClick={() => createImage(true)}
                onClick={() => {
                  confirm({
                    title: "Ar tikrai norite spausdinti?",
                    icon: <IconExclamation className="text-orange" />,
                    content: "Sutikus automatiškai išsaugos plakato suvestus į laukelius duomenis.",
                    okText: "Sutikti",
                    cancelText: "Atšaukti",
                    onOk() {
                      createImage(true);
                      console.log("OK");
                    },
                    onCancel() {
                      console.log("Cancel");
                    },
                  });
                }}
                disabled={loadingCustom}
              >
                Spausdinti
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="mx-5">
        <div className={"d-flex flex-wrap"}>
          <h2 className="text-xl text-black font-weight-bold">
            Numatomas atvaizdavimas
          </h2>
          <PosterPreview
            type={variables.poster_type}
            variables={variables}
            setProductImage={(file) => {
              setVariables((prev) => ({
                ...prev,
                product_image: file,
              }));
            }}
          />
        </div>
      </div>
    </div>
  );
}

const Product = ({ variables, setVariables }) => {
  const options = useSelector((state) => state.custom.options);

  const onChange = (e) => {
    const { name, value } = e.target;

    setVariables((prev) => {
      let obj = { ...prev, [name]: value };
      const product_price = Number(obj.price);
      if (product_price) {
        obj = {
          ...obj,
          ...calculateDiscounts(obj, name),
        };
      }

      return { ...obj };
    });
  };
  const calculateDiscounts = (obj, name) => {
    const product_price = Number(obj.price);
    if (!product_price) return obj;
    const discount_type_from = Number(obj.discount_type_from) || 0;
    const discount_price = Number(obj.discount_price) || 0;

    if (obj["discount_type_symbol"] == "€") {
      if (name == "discount_price") {
        obj.discount_type_from = parseFloat(Number(product_price - discount_price).toFixed(2));
      } else {
        obj.discount_price = parseFloat(Number(product_price - discount_type_from).toFixed(
          2
        ));
      }
    } else {
      if (name == "discount_price") {
        obj.discount_type_from = parseFloat(Number(parseInt(
          Math.round(100.0 - (100.0 * discount_price) / product_price)
        )).toFixed(2))
      } else {
        const total = (1 - Number(discount_type_from / 100.0)) * product_price;
        obj.discount_price = parseFloat(total.toFixed(2));
      }
    }
    return obj;
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-6">
          <TextInputModal
            text="Akcijos pavadinimas"
            name="discount_title"
            value={variables.discount_title}
            onChange={onChange}
          />
        </div>
        <div className="col-6">
          <label className="text-bold my-1">Akcijos laikotarpis</label>
          <div>
            <RangePickerComp
              placeholder={["Pasirinkti", "Pasirinkti"]}
              disabledDate={() => {}}
              value={[
                getDateFormat(variables.discount_date_from),
                getDateFormat(variables.discount_date_to),
              ]}
              onChange={(_, args) => {
                onChange({
                  target: {
                    name: "discount_date_from",
                    value: args[0],
                  },
                });
                onChange({
                  target: {
                    name: "discount_date_to",
                    value: args[1],
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="col-6">
          <TextInputModal
            text="Prekės kodas"
            name="product_code"
            value={variables.product_code}
            onChange={onChange}
          />
        </div>
        <div className="col-6">
          <TextInputModal
            text="Prekės pavadinimas"
            name="product_title"
            value={variables.product_title}
            onChange={onChange}
          />
        </div>
        <div className="col-6">
          <div className={`text-bold my-1 ${false ? "text-danger" : ""}`}>
            {"Prekės kategorija "}
            {true && <span className="orange_color">*</span>}
          </div>
          <AutoComplete
            style={{ width: "100%" }}
            options={options?.families || []}
            value={variables.product_category}
            onChange={(value) => {
              onChange({
                target: {
                  name: "product_category",
                  value,
                },
              });
            }}
            placeholder="Įvesti"
            filterOption={(inputValue, option) => {
              if (!!option && !!inputValue && option.value) {
                return (
                  option.label
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                );
              }
            }}
          />
        </div>
        <div className="col-6">
          <div className="d-flex">
            <div
              style={{
                width: "75%",
              }}
            >
              <TextInputModal
                style={{
                  borderRadius: "2px 0px 0px 2px",
                }}
                text="Nuolaidos tipas"
                name="discount_type_from"
                value={variables.discount_type_from}
                onChange={onChange}
              />
            </div>

            <div
              style={{
                width: "25%",
              }}
            >
              <InputSwitchComponent
                touches={true}
                label="-"
                options={[
                  {
                    label: "€",
                    value: "€",
                  },
                  {
                    label: "%",
                    value: "%",
                  },
                ]}
                name="discount_type_symbol"
                value={variables.discount_type_symbol}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
        <div className="col-6">
          <TextInputModal
            text="Kaina"
            name="price"
            value={variables.price}
            onChange={onChange}
          />
        </div>
        <div className="col-6">
          <TextInputModal
            text="Akcijinė kaina"
            name="discount_price"
            value={variables.discount_price}
            onChange={onChange}
          />
        </div>
        <div className="col-12">
          <TextInputModal
            text="Ypatinga savybė"
            name="special_feature"
            value={variables.special_feature}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};

const ProductCategory = ({ variables, setVariables }) => {
  const [freeText, setFreeText] = useState(!!variables.free_text);
  const options = useSelector((state) => state.custom.options);

  const onChange = (e) => {
    const { name, value } = e.target;
    setVariables((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <div className="">
      <div className="row">
        <div className="col-6">
          <TextInputModal
            text="Akcijos pavadinimas"
            name="discount_title"
            value={variables.discount_title}
            onChange={onChange}
          />
        </div>
        <div className="col-6">
          <label className="text-bold my-1">Akcijos laikotarpis</label>
          <div>
            <RangePickerComp
              placeholder={["Pasirinkti", "Pasirinkti"]}
              disabledDate={() => {}}
              value={[
                getDateFormat(variables.discount_date_from),
                getDateFormat(variables.discount_date_to),
              ]}
              onChange={(_, args) => {
                onChange({
                  target: {
                    name: "discount_date_from",
                    value: args[0],
                  },
                });
                onChange({
                  target: {
                    name: "discount_date_to",
                    value: args[1],
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="col-6">
          <div className={`text-bold my-1 ${false ? "text-danger" : ""}`}>
            {"Prekės kategorija "}
            {true && <span className="orange_color">*</span>}
          </div>
          <AutoComplete
            style={{ width: "100%" }}
            options={options?.families || []}
            value={variables.product_category}
            onChange={(value) => {
              onChange({
                target: {
                  name: "product_category",
                  value,
                },
              });
            }}
            placeholder="Įvesti"
            filterOption={(inputValue, option) => {
              if (!!option && !!inputValue && option.value) {
                return (
                  option.label
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                );
              }
            }}
          />
        </div>
        <div className="col-6">
          <SelectForm
            placeholder="Prekės ženklas"
            onlyPlaceholder="Pasirinkti"
            options={options?.brands || []}
            name="brand_id"
            value={variables.brand_id}
            onChange={(value) =>
              onChange({
                target: {
                  name: "brand_id",
                  value,
                },
              })
            }
          />
        </div>
        <div className="col-12">
          <div
            className="d-flex align-items-center mt-2 cursor-pointer"
            onClick={() =>
              setFreeText((prev) => {
                const val = !prev;
                if (val) {
                } else {
                  onChange({ target: { value: "", name: "free_text" } });
                }

                return val;
              })
            }
          >
            <CheckBoxInput checked={freeText} />
            <div className="d-flex flex-column">
              <div
                className="text-sm"
                style={{
                  lineHeight: 1,
                }}
              >
                Įgalinti laisvą tekstą
              </div>
              <div
                className="text-xs"
                style={{
                  color: "#bfbfbf",
                  lineHeight: 1,
                }}
              >
                (Šis tekstas bus atvaizduojamas vietoje prekių kategorijos
                laukelio)
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <TextInputModal
            text="Laisvas tekstas"
            name="free_text"
            value={variables.free_text}
            onChange={(e) => {
              onChange(e);
              setFreeText(!!e.target.value);
            }}
          />
        </div>
        <div className="col-12">
          <hr
            className="mb-2 mt-3 w-100"
            style={{
              height: 1,
              color: "#D9D9D9",
            }}
          />
        </div>
        <div className="col-6">
          <InputSwitchComponent
            label="Pasirinkimai"
            options={[
              {
                label: "Visai",
                value: "Visai",
              },
              {
                label: "Iki",
                value: "Iki",
              },
              {
                label: "Nuo... iki",
                value: "Nuo... iki",
              },
            ]}
            name="discount_type"
            value={variables.discount_type}
            onChange={onChange}
          />
        </div>
        <div className="col-6">
          <div className="d-flex">
            <div
              className="d-flex"
              style={{
                width: variables.discount_type == "Nuo... iki" ? "100%" : "75%",
              }}
            >
              {variables.discount_type == "Nuo... iki" && (
                <div className="w-100">
                  <TextInputModal
                    style={{
                      borderRadius: "2px 0px 0px 2px",
                    }}
                    text="Nuolaidos tipas"
                    name="discount_type_from"
                    placeholder="Nuo"
                    value={variables.discount_type_from}
                    onChange={onChange}
                  />
                </div>
              )}
              <div className="w-100">
                <TextInputModal
                  style={{
                    borderRadius:
                      variables.discount_type == "Nuo... iki"
                        ? "0px"
                        : "2px 0px 0px 2px",
                  }}
                  text={
                    variables.discount_type == "Nuo... iki"
                      ? "-"
                      : "Nuolaidos tipas"
                  }
                  name="discount_type_to"
                  placeholder="Iki"
                  value={variables.discount_type_to}
                  onChange={onChange}
                />
              </div>
            </div>

            <div
              style={{
                width: "25%",
              }}
            >
              <InputSwitchComponent
                touches={true}
                label="-"
                options={[
                  {
                    label: "€",
                    value: "€",
                  },
                  {
                    label: "%",
                    value: "%",
                  },
                ]}
                name="discount_type_symbol"
                value={variables.discount_type_symbol}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
