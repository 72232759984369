import Axios from "axios";
import { parcelTerminalBulder } from "../../helpers/Utils";


export const getParcelTerminals = async () => {
  try {
    const { data } = await Axios.get("/api/v1/parcel_terminals");
    return { success: true, data: parcelTerminalBulder(data) };
  } catch (e) {
    console.log("Error: ", e);
    return { suceess: false };
  }
};
