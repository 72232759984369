import React, { useState, useEffect } from "react";
import { CheckBoxInput } from "../../common/Buttons";
import { getDateFormat } from "../../../helpers/Utils";
import { DatePicker, TimePicker } from "antd";
import moment from "moment";

const ActiveLanding = ({ updateActive, state, active }) => {
  const [activeLanding, setActiveLanding] = useState({
    from: "",
    to: "",
  });

  useEffect(() => {
    setActiveLanding({
      from: !!state.from_at,
      to: !!state.to_at,
    });
  }, [active]);

  return (
    <div className="my-3">
      {/*  */}
      <div className="row mb-2">
        <div className="col-6 pr-1">
          <div className="d-flex align-items-center">
            <div
              style={{
                width: 70,
              }}
            >
              <CheckBoxInput
                text="Nuo"
                checked={activeLanding.from}
                onChange={() => {
                  setActiveLanding((prev) => {
                    const val = !prev["from"];
                    if (!val) {
                      updateActive("from_at", null);
                    }

                    return {
                      ...prev,
                      from: val,
                    };
                  });
                }}
              />
            </div>
            <DatePicker
              style={{
                flex: 1,
              }}
              placeholder="Data"
              format="YYYY-MM-DD"
              disabled={!activeLanding.from}
              name="from_at"
              value={getDateFormat(state.from_at)}
              onChange={(e, ar) => updateActive("from_at", ar)}
            />
          </div>
        </div>
        <div className="col-4 px-1">
          <TimePicker
            style={{
              flex: 1,
            }}
            disabled={!activeLanding.from || !state.from_at}
            placeholder="Laikas"
            format="HH:mm"
            type={"date"}
            value={getDateFormat(state.from_at)}
            onChange={(value, ar) => {
              if (!ar)
                return updateActive("from_at", state.from_at.substr(0, 10));
              updateActive("from_at", moment(value).format("YYYY-MM-DD HH:mm"));
            }}
          />
        </div>
      </div>
      {/*  */}
      <div className="row mb-2">
        <div className="col-6 pr-1">
          <div className="d-flex align-items-center">
            <div
              style={{
                width: 70,
              }}
            >
              <CheckBoxInput
                text="Iki"
                checked={activeLanding.to}
                onChange={() => {
                  setActiveLanding((prev) => {
                    const val = !prev["to"];
                    if (!val) {
                      updateActive("to_at", null);
                    }

                    return {
                      ...prev,
                      to: val,
                    };
                  });
                }}
              />
            </div>
            <DatePicker
              style={{
                flex: 1,
              }}
              placeholder="Data"
              format="YYYY-MM-DD"
              disabled={!activeLanding.to}
              name="to_at"
              value={getDateFormat(state.to_at)}
              onChange={(e, ar) => updateActive("to_at", ar)}
            />
          </div>
        </div>
        <div className="col-4 px-1">
          <TimePicker
            style={{
              flex: 1,
            }}
            disabled={!activeLanding.to || !state.to_at}
            placeholder="Laikas"
            format="HH:mm"
            type={"date"}
            value={getDateFormat(state.to_at)}
            onChange={(value, ar) => {
              if (!ar) return updateActive("to_at", state.to_at.substr(0, 10));
              updateActive("to_at", moment(value).format("YYYY-MM-DD HH:mm"));
            }}
          />
        </div>
      </div>
      {/*  */}
      {/* <div>
        <div className="d-flex align-items-center mb-2">
          <div
            style={{
              width: 70,
            }}
          >
            <CheckBoxInput
              text="Nuo"
              checked={activeLanding.from}
              onChange={() => {
                setActiveLanding((prev) => {
                  const val = !prev["from"];
                  if (!val) {
                    updateActive("from_at", null);
                  }

                  return {
                    ...prev,
                    from: val,
                  };
                });
              }}
            />
          </div>
          <div
            style={{
              flex: 1,
            }}
          >
            <DataPickerInputModal
              format="YYYY-MM-DD HH:mm"
              disabled={!activeLanding.from}
              showTime={{
                format: "HH:mm",
              }}
              name="from_at"
              value={getDateFormat(state.from_at)}
              onChange={(e, ar) => updateActive("from_at", ar)}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="d-flex align-items-center">
          <div
            style={{
              width: 70,
            }}
          >
            <CheckBoxInput
              text={"Iki"}
              checked={activeLanding.to}
              onChange={() => {
                setActiveLanding((prev) => {
                  const val = !prev["to"];
                  if (!val) {
                    updateActive("to_at", null);
                  }

                  return {
                    ...prev,
                    to: val,
                  };
                });
              }}
            />
          </div>
          <div
            style={{
              flex: 1,
            }}
          >
            <DataPickerInputModal
              format="YYYY-MM-DD HH:mm"
              disabled={!activeLanding.to}
              showTime={{
                format: "HH:mm",
              }}
              name="to_at"
              value={getDateFormat(state.to_at)}
              onChange={(e, ar) => updateActive("to_at", ar)}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ActiveLanding;
