import UserPermissionsOption from "./UserPermissionsOption";

function HistoryRedirect(permissions, history) {
  if (!permissions) return;

  if (permissions.orders) {
    if (localStorage.getItem("redirectToUsers")) {
      return history.push("/options/users");
    }

    return history.push("/orders");
  }

  if (permissions.documents) {
    return history.push("/documents");
  }
  if (permissions.rivile_bigbox_purchases) {
    return history.push("/rivile-pirkimai");
  }

  const tree = UserPermissionsOption({ id: null });
  const data = tree_setter([], tree, permissions);
  if (data.length > 0) {
    return history.push(data[0].url);
  }
}

export default HistoryRedirect;

export const tree_setter = (available_items = [], arr = [], permissions) => {
  let available = available_items;
  arr.forEach((d) => {
    const its_available = permissions[d.uuid];
    if (!its_available) return;
    if (d.url) {
      available.push({
        uuid: d.uuid,
        title: d.title,
        url: d.url,
      });
    }
    if (d.options && d.options.length > 0) {
      return tree_setter(available, d.options, permissions);
    }
  });
  return available;
};
