import { GET_DESCRIPTIONS, LOADING_DESCRIPTIONS, LOADING_DESCRIPTION, GET_DESCRIPTION} from "../Actions/types";

const initialState = {
  descriptions: [],
  description: {},
  totals: 0,
  loading_descriptions: false,
  loading_description: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_DESCRIPTIONS:
      return {
        ...state,
        loading_descriptions: true,
      };
    case LOADING_DESCRIPTION:
      return {
        ...state,
        loading_description: true,
      };
    case GET_DESCRIPTIONS:
      return {
        ...state,
        descriptions: action.payload.data,
        totals: action.payload.totals,
        loading_descriptions: false,
      };
    case GET_DESCRIPTION:
        return {
          ...state,
          description: action.payload.data,
          loading_description: false,
        };
    default:
      return state;
  }
}
