import React from "react";
import { useSelector } from "react-redux";
import ModalSpecial from "../../Layout/Modals/ModalSpecial";
import ListOfDocumentsModal from "./ListOfDocumentsModal";

export default function DocumentShowRender(props) {
  const { number } = useSelector((state) => state.documents.document);
  const user = useSelector((state) => state.auth.user);
  const { document_history, document } = useSelector(
    (state) => state.documents
  );
  const tilteSpan = (
    <span
      style={{
        fontSize: "1.2rem",
        lineHeight: "27px",
        color: "#000000",
        fontWeight: "700",
      }}
    >
      Dokumentas <span className="text-orange">#</span>
      {number || ""}
    </span>
  );
  return (
    <ModalSpecial text={tilteSpan} onClose={props.onClose}>
      <ListOfDocumentsModal
        {...props}
        auth={user}
        document_history={document_history}
        objectData={document}
      />
    </ModalSpecial>
  );
}
