import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaymentsBlockComponent from "./payments_table_block";
import PaymentAddBlockComponent from "./payments_add_block";
import Axios from "axios";
import {
  postPayment,
  updatePayment,
  deletePayment,
  editPayment,
  getWaitingPayments,
} from "../../../../../../redux/Actions/paymentAction";
import { connect } from "react-redux";
import { toFixedNumber } from "../../../../../../helpers/Utils";
import PaymentUpsale from "./payment_upsale";

function PaymentBlockComponent({
  orderId,
  payments_add_col,
  payment,
  payments,
  goodsAddRowClick,
  editPayment,
  postPayment,
  updatePayment,
  deletePayment,
  total_price,
  paymentBlock,
  disabled = false,
}) {
  total_price = toFixedNumber(total_price);

  const dispatch = useDispatch();
  const [paymentTypes, setPaymentTypes] = useState([]);
  const showWaitingPayments = true;
  const waiting_payments = useSelector(
    (state) => state.orders.waiting_payments
  );

  useEffect(() => {
    if (!orderId) return;
    if (paymentTypes.length == 0) {
      Axios.get(`/api/v1/orders/${orderId}/payments/payment_types`).then(
        (res) => {
          const newArray = res.data.map((item) => {
            return {
              value: item[1],
              label: item[0],
            };
          });
          setPaymentTypes(newArray);
        }
      );
    }
    dispatch(getWaitingPayments(orderId)).then((res) => {});
  }, [orderId]);

  const dangerColor = () => {
    const total = toFixedNumber(
      payments.reduce((acc, cur) => +acc + +cur.price, 0)
    );
    const totalPrice = total_price;
    if (+totalPrice == +total) {
      return <span className="font-weight-bold text-black">{total}</span>;
    }
    return <span className="font-weight-bold text-danger">{total}</span>;
  };

  return (
    <React.Fragment>
      <div className="table-responsive bg-silver pt-2">
        <div
          className="col-12"
          style={{
            marginBottom: "1rem",
          }}
        >
          <table
            className="table table-sm"
            style={{ marginBottom: "0px!important" }}
          >
            <thead className="border-bottom mb-1">
              <tr>
                <th className="text-black">Mok. nr.</th>
                <th className="text-black w-33">Mokėjimo būdas</th>
                <th className="text-black w-33">Vartotojas</th>
                <th className="text-black w-33">Data</th>
                <th className="text-black">Suma</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {payments.map((paymentVal, index) => (
                <PaymentsBlockComponent
                  key={index}
                  payment={paymentVal}
                  paymentEdit={payment}
                  orderId={orderId}
                  paymentTypes={paymentTypes}
                  // updatePayment={updatePayment}
                  goodsAddRowClick={goodsAddRowClick}
                  deletePayment={deletePayment}
                  payments={payments}
                  total_price={total_price}
                  editPayment={editPayment}
                  disabled={disabled}
                />
              ))}
              <tr className="border-top mt-1">
                <td colSpan="4">Iš viso apmokėta:</td>
                <td colSpan="3">{dangerColor()}</td>
              </tr>
            </tbody>
          </table>

          {payments_add_col && (
            <React.Fragment>
              <hr />
              <PaymentAddBlockComponent
                orderId={orderId}
                paymentTypes={paymentTypes}
                postPayment={postPayment}
                updatePayment={updatePayment}
                payments={payments}
                editPayment={editPayment}
                goodsAddRowClick={goodsAddRowClick}
                total_price={total_price}
                payment={payment}
                paymentBlock={paymentBlock}
              />
            </React.Fragment>
          )}
          {showWaitingPayments && (
            <PaymentUpsale
              waiting_payments={waiting_payments}
              orderId={orderId}
              type="Order"
              disabled={disabled}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  payments: state.orders.payments,
  payment: state.orders.payment,
});
export default connect(mapStateToProps, {
  postPayment,
  updatePayment,
  deletePayment,
  editPayment,
})(PaymentBlockComponent);
