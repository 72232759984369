import Axios from "axios";
import {
  antdMessageError,
  antdMessageSuccess,
  errorHandler,
} from "../../helpers/Utils";
import { getDocumentTypeOption } from "./documentTypeAction";
import { getStoreOptions } from "./storeAction";

export const getLeasings =
  (page = 1, pageCount = 50, filter = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: "LOADING_CUSTOM",
      });
      const { data } = await Axios.get(
        `/api/v1/sb_leasings?page=${page}&per_page=${pageCount}${filter}`
      );
      dispatch({
        type: "GET_CUSTOMS",
        payload: { ...data, current: "Lizingai" },
      });
      return { success: true };
    } catch (err) {
      dispatch({
        type: "STOP_LOADING_CUSTOM",
      });
      return { success: false };
    }
  };

export const getLeasingOptions = () => async (dispatch, getState) => {
  try {
    const user_state = getState().auth.user?.store?.departments;

    Promise.all([getDocumentTypeOption("leasing"), getStoreOptions()]).then((res) => {
      dispatch({
        type: "SET_OPTIONS",
        payload: {
          ...res[0],
          stores: res[1]
            .map((d) => ({
              value: d.rivile_code,
              label: d.name,
            }))
            .filter((d) => (user_state ? user_state.includes(d.value) : false)),
        },
      });
    });
    return { success: true };
  } catch (err) {
    console.log("ERROR ", err);
    return { success: false };
  }
};

export const updateLeasing = (params) => async (dispatch) => {
  try {
    const api_url = params.id
      ? `/api/v1/sb_leasings/${params.id}`
      : "/api/v1/sb_leasings";
    const { data } = await Axios({
      url: api_url,
      data: {
        sb_leasing: params,
      },
      method: params.id ? "PUT" : "POST",
    });
    if (data.errors) {
      const api_errors = {};
      Object.entries(data.errors).forEach((d) => (api_errors[d[0]] = d[1][0]));
      if (api_errors.base) {
        antdMessageError(api_errors.base);
      }
      return { success: false, errors: api_errors };
    }

    dispatch({
      type: params.id ? "UPDATE_CUSTOM" : "POST_CUSTOM",
      payload: data.data,
    });
    antdMessageSuccess();
    return { success: true, data: data.data };
  } catch (err) {
    antdMessageError();
    errorHandler(err);
    return { success: false };
  }
};

export const setContractIdentifier = async (service_id, contract_id, values) => {
  try {
    await Axios({
      url: "/api/v1/sb_leasings/set_contract_to_sb_leasing_item",
      data: {
        sb_leasing: {
          service_id,
          contract_id,
          values
        },
      },
      method: "PUT",
    });

    antdMessageSuccess();
    return { success: true };
  } catch (err) {
    antdMessageError();
    errorHandler(err);
    return { success: false };
  }
};

export const deleteLeasing = (id) => async (dispatch) => {
  try {
    const { data } = await Axios.delete(`/api/v1/sb_leasings/${id}`);
    if (!data.success) throw new Error("err");
    antdMessageSuccess();
    dispatch({
      type: "REMOVE_CUSTOM",
      payload: id,
    });
    return { success: true };
  } catch (err) {
    antdMessageError();
    errorHandler(err);
    return { success: false };
  }
};

export const sendLeasingRequest =
  (leasing_id, identification_number) => async (dispatch) => {
    try {
      const { data } = await Axios({
        url: `/api/v1/sb_leasings/${leasing_id}/send_leasing_request`,
        method: "POST",
        params: {
          identification_number,
        },
      });
      if (data.success) {
        antdMessageSuccess();
        dispatch({
          type: "UPDATE_CUSTOM",
          payload: data.data,
        });

        return { success: true, data: data.data };
      }
      throw new Error("ERR");
    } catch (err) {
      antdMessageError();
      errorHandler(err);
      return { success: false };
    }
  };

export const sendLeasingToRivile = (leasing_id) => async (dispatch) => {
  try {
    const { data } = await Axios({
      url: `/api/v1/sb_leasings/${leasing_id}/send_to_rivile`,
      method: "POST",
    });
    if (data.success) {
      antdMessageSuccess();
      dispatch({
        type: "UPDATE_CUSTOM",
        payload: data.data,
      });

      return { success: true };
    }
    throw new Error("ERR");
  } catch (err) {
    antdMessageError();
    errorHandler(err);
    return { success: false };
  }
};

export const sbLeasingOpenSession =
  (leasing_id, identification_number) => async (dispatch) => {
    try {
      const { data } = await Axios({
        url: `/api/v1/sb_leasings/${leasing_id}/open_session`,
        method: "POST",
        params: {
          identification_number,
        },
      });
      if (data.success) {
        antdMessageSuccess();
        dispatch({
          type: "UPDATE_CUSTOM",
          payload: data.data,
        });

        return { success: true, data: data.data };
      }
      throw new Error("ERR");
    } catch (err) {
      antdMessageError();
      errorHandler(err);
      return { success: false };
    }
  };
