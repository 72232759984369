import React, { memo, useState, useRef } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import styled from "styled-components";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
// cia
const DropdownButton = ({
  text,
  actions,
  color = "#f29302",
  minWidth = "auto",
}) => {
  const [active, setActive] = useState(false);
  const wrapperRef = useRef(null);

  return (
    <ButtonMassActionsStyled
      ref={wrapperRef}
      active={active}
      color={color}
      className="position-relative"
      onClick={() => setActive((prev) => !prev)}
    >
      <button
        className="d-flex justify-content-between btn btn-sm font-normal"
        style={{
          minWidth,
        }}
      >
        <span>{text}</span>
        <span className="mx-3" />
        <span>{active ? <FaChevronUp /> : <FaChevronDown />}</span>
      </button>
      {active && (
        <ActiveBlock
          wrapperRef={wrapperRef}
          actions={actions}
          onClose={() => setActive(false)}
        />
      )}
    </ButtonMassActionsStyled>
  );
};

export default memo(DropdownButton);

const ActiveBlock = ({ wrapperRef, actions, onClose }) => {
  useOutsideAlerter(wrapperRef, onClose);
  return (
    <div className="select_box">
      <ul className="">
        {actions.map((d) => (
          <li
            key={d.label}
            className="cursor-pointer py-2"
            onClick={d.onFunction}
          >
            {d.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

const ButtonMassActionsStyled = styled.div`
  .btn {
    background: ${(props) => props.color};
    border-radius: ${(props) => (props.active ? "4px 4px 0px 0px" : "4px")};
    border-radius: 3px;
    font-style: normal;
    text-align: center;
    color: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  .select_box {
    position: absolute;
    width: 100%;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 4px 4px;
    z-index: 15;
    & ul {
      margin: 4px 8px 5px 8px;
    }
    & li {
      font-style: normal;
      font-size: 12px;
      line-height: 13px;
      color: #000000;
    }
  }
`;
