import Axios from "axios";
import {
  GET_SHOP_CATEGORIES,
  UPDATE_SHOP_CATEGORIES,
  LOADING_SHOP_CATEGORIES,
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

export const getShopCategories = () => (dispatch) => {
  dispatch(setShopCategoriesLoading());
  Axios.get("/api/v1/shop_categories").then((res) => {
    dispatch({
      type: GET_SHOP_CATEGORIES,
      payload: res.data,
    });
  });
};

export const updateShopCategories = (_id, body) => (dispatch) => {
  Axios.put(`/api/v1/shop_categories/${_id}`, body).then((res) => {
    dispatch({
      type: UPDATE_SHOP_CATEGORIES,
      payload: res.data[0].children[0].children,
    });
  });
};

export const setShopCategoriesLoading = () => {
  return {
    type: LOADING_SHOP_CATEGORIES,
  };
};
