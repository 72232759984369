import React from "react";
import { Modal } from "antd";
import { IconExclamation } from "../components/Layout/Icons";

const { confirm } = Modal;
const AntdConfirm = ({
  title = "Ar tikrai norite ištrinti?",
  content = "Šio veiksmo anuliuoti negalėsite",
  onOk,
  onCancel = () => console.log('CancelDefault'),
}) =>
  confirm({
    title,
    icon: <IconExclamation className="text-orange" />,
    content,
    okText: "Sutikti",
    cancelText: "Atšaukti",
    onOk() {
      onOk();
      console.log("OK");
    },
    onCancel() {
      onCancel();
      console.log("Cancel");
    },
  });

export default AntdConfirm;
