import React from "react";
import InputForm from "../../common/InputForm";
import ModalToChange from "../../Layout/ModalToChange";
import { SelectForm } from "../../common/Buttons";
class SupplierEditModal extends React.Component {
  constructor() {
    super();
    this.state = {
      id: 0,
      name: "",
      code: "",
      is_code: false,
      is_active: false,
      email: "",
      comment: "",
      company_code: null,
      action: "new",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.action === "new" && this.state.action === "edit") {
      this.setState({
        name: "",
        code: "",
        is_code: false,
        is_active: false,
        email: "",
        comment: "",
        company_code: null,
      });
      this.removeErrors(nextProps.errors);
    } else if (
      nextProps.action === "edit" &&
      nextProps.objectData.id !== this.state.id
    ) {
      let item = nextProps.objectData;
      this.setState({
        id: item.id || 0,
        name: item.name || "",
        code: item.code || "",
        is_code: item.is_code || false,
        is_active: item.is_active || false,
        email: item.email || "",
        comment: item.comment || "",
        company_code: item.company_code || null,
      });
      this.removeErrors(nextProps.errors);
    }
  }

  removeErrors = (errors) => {
    if (Object.keys(errors).length > 0) {
      this.props.clearErrors();
    }
  };

  handleChangeText = (e, select) => {
    if (select) {
      this.setState({
        [select]: e,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const variables = {
      supplier: {
        name: this.state.name,
        code: this.state.code,
        is_code: this.state.is_code,
        is_active: this.state.is_active,
        email: this.state.email,
        comment: this.state.comment,
        company_code: this.state.company_code,
      },
    };
    if (this.props.action == "new") {
      this.props.postSupplier(variables);
    } else if (this.props.action === "edit") {
      this.props.updateSupplier(this.state.id, variables);
    }
  };

  render() {
    let activeOption = [
      { label: "Aktyvus", value: true },
      { label: "Neaktyvus", value: false },
    ];
    let codeOption = [
      { label: "TAIP", value: true },
      { label: "NE", value: false },
    ];

    return (
      <ModalToChange
        handleSave={(e) => this.onSubmit(e)}
        status="tableEdit"
        text={this.props.action === "new" ? "Kurti naują" : "Redaguoti"}
        buttonText={this.props.action === "new" ? "Sukurti" : "Atnaujinti"}
      >
        <InputForm
          type="text"
          placeholder="Pavadinimas"
          name="name"
          value={this.state.name}
          error={
            this.props.errors.name &&
            this.props.errors.name.map((item) => `${item} `)
          }
          onChange={(e) => this.handleChangeText(e)}
        />
        <InputForm
          type="text"
          placeholder="Padalinio kodas"
          name="code"
          value={this.state.code}
          onChange={(e) => this.handleChangeText(e)}
        />
        <SelectForm
          placeholder={"Ar kodas?"}
          selector={"is_code"}
          value={this.state.is_code}
          options={codeOption}
          onChange={(e) => this.handleChangeText(e, "is_code")}
        />
        <SelectForm
          placeholder={"Ar aktyvus?"}
          selector={"is_active"}
          value={this.state.is_active}
          options={activeOption}
          onChange={(e) => this.handleChangeText(e, "is_active")}
        />
        <InputForm
          type="text"
          placeholder="Įmonės kodas"
          name="company_code"
          value={this.state.company_code}
          onChange={(e) => this.handleChangeText(e)}
        />
        <InputForm
          type="text"
          placeholder="El. paštas"
          name="email"
          value={this.state.email}
          onChange={(e) => this.handleChangeText(e)}
        />
        <InputForm
          type="text"
          placeholder="Komentaras"
          name="comment"
          value={this.state.comment}
          onChange={(e) => this.handleChangeText(e)}
        />
      </ModalToChange>
    );
  }
}

export default SupplierEditModal;
