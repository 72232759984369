const removeOverFlow = () => {
  const getBodyEl = document.getElementById("main_body");
  getBodyEl.classList.remove("modal-open");
};
const addOverFlow = () => {
  const getBodyEl = document.getElementById("main_body");
  getBodyEl.classList.add("modal-open");
};

export { removeOverFlow, addOverFlow };
