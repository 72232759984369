import React from "react";
import { CheckBoxInput } from "../../../common/Buttons";
import { FaPlus, FaMinus } from "react-icons/fa";

function CategoryEach(props) {
  let lengthItems = props.item.children.length > 0;
  return (
    <>
      <li className="list-group-item node-treeview mb-2 d-flex justify-content-between">
        <div
          style={{ cursor: "pointer", marginLeft: props.level }}
          onClick={() => props.handleToggle(props.item.id_category)}
        >
          {props.CurrentOpen.includes(props.item.id_category) ? (
            <FaMinus
              title="Uždaryti"
              className="mr-2 text-danger"
              style={{ visibility: lengthItems ? "visible" : "hidden" }}
            />
          ) : (
            <FaPlus
              title="Atidaryti"
              className="mr-2 text-success"
              style={{ visibility: lengthItems ? "visible" : "hidden" }}
            />
          )}
          {props.item.title}{" "}
          {lengthItems && (
            <span className="font-weight-bold">
              ({props.item.children.length})
            </span>
          )}
        </div>
        <div>
          <CheckBoxInput
            type="checkbox"
            color="#f7951e"
            checked={props.Checked.includes(props.item.id_category)}
            onChange={() =>
              props.handleCheckboxChange(
                props.item.id_category,
                props.item.children
              )
            }
          />
        </div>
      </li>
      {props.CurrentOpen.includes(props.item.id_category) &&
        typeof props.item.children !== "undefined" &&
        lengthItems &&
        props.item.children.map((item, index) => {
          return (
            <CategoryEach
              key={index}
              item={item}
              Checked={props.Checked}
              handleCheckboxChange={props.handleCheckboxChange}
              CurrentOpen={props.CurrentOpen}
              handleToggle={props.handleToggle}
              level={props.level + 7}
            />
          );
        })}
    </>
  );
}

export default CategoryEach;
