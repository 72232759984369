import Axios from "axios";
import {
  CLEAR_ERRORS,
  GET_DOCUMENT,
  GET_ERRORS,
  GET_DOCUMENTS,
  LOADING_DOCUMENTS,
  UPDATE_CONTRACT_ITEM_DOCUMENT,
} from "./types";
import { logoutUser } from "./authAction";
import {
  antdMessageError,
  antdMessageInfo,
  antdMessageSuccess,
} from "../../helpers/Utils";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

export const postDocument = (variables) => (dispatch) => {
  Axios.post("/api/v1/contracts", variables)
    .then((res) => {
      //   dispatch({
      //     type: POST_COMMENTS,
      //     payload: res.data
      //   });
      //   dispatch(clearErrors());
      //   success("post");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      //   error();
    });
};

export const getDocument = (page, pageCount, filter) => (dispatch) => {
  dispatch(setDocumentLoading());
  Axios.get(
    `/api/v1/contracts.json?page=${page}&per_page=${pageCount}${filter}`
  )
    .then((res) => {
      if (res.data == undefined || Object.keys(res.data).length === 0) {
        return dispatch(logoutUser());
      }
      dispatch({
        type: GET_DOCUMENTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getDocumentShow = (id) => async (dispatch) => {
  try {
    const { data } = await Axios.get(`/api/v1/contracts/${id}`);
    dispatch({
      type: GET_DOCUMENT,
      payload: data,
    });
    const { data: history_data } = await Axios.get(
      `/api/v1/contracts/history?document_id=${id}`
    );
    dispatch({
      type: "GET_DOCUMENT_HISTORY",
      payload: history_data,
    });
  } catch (err) {
    console.log("Error: ", err);
    if (err?.response?.data) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    }
  }
};

export const deleteDocument = (_id) => (dispatch) => {
  if (confirm("Ar tikrai norite ištrinti ? ")) {
    Axios.delete(`/api/v1/contracts/${_id}`)
      .then((res) => {
        // dispatch({
        //   type: DELETE_COMMENTS,
        //   payload: _id,
        // });
        // success();
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        // error();
      });
  }
};

export const putContract = (id, params) => async (dispatch) => {
  try {
    antdMessageInfo("Kviečiama");
    const { data } = await Axios.put(`/api/v1/contracts/${id}`, {
      contract: params,
    });
    if (!data) throw new Error("Err");
    dispatch({
      type: UPDATE_CONTRACT_ITEM_DOCUMENT,
      payload: data,
    });
    antdMessageSuccess();
    return { success: true };
  } catch (err) {
    antdMessageError();
    return { success: false };
  }
};

export const setDocumentLoading = () => {
  return {
    type: LOADING_DOCUMENTS,
  };
};

export const clearErrors = () => {
  //   return {
  //     type: CLEAR_ERRORS,
  //   };
};
