import Axios from "axios";
import { lengthOfCharactersDifference } from "../helpers/Utils";

const main_carriers = [
  {
    label: "Atsiėmimas parduotuvėje",
    value: "Atsiėmimas parduotuvėje",
    options: ["Atsiėmimas parduotuvėje"],
  },
  {
    label: "OMNIVA Paštomatas",
    value: "OMNIVA Paštomatas",
    options: ["OMNIVA Paštomatas", "Paštomatas OMNIVA"],
  },
  {
    label: "DPD Paštomatas",
    value: "DPD Paštomatas",
    options: ["DPD Paštomatas", "Paštomatas DPD"],
  },
  {
    label: "DPD kurjeris",
    value: "DPD kurjeris",
    options: ["DPD kurjeris", "Kurjeris DPD"],
  },
  {
    label: "LP Express 24 pristatymas į paštomatą",
    value: "LP Express 24 pristatymas į paštomatą",
    options: [
      "LP Express 24. PAŠTOMATAS",
      "LP Express 24 pristatymas į paštomatą",
      "Paštomatas LP EXPRESS",
    ],
  },
  {
    label: "LP Express 24 pristatymas į namus",
    value: "LP Express 24 pristatymas į namus",
    options: ["LP Express 24 pristatymas į namus"],
  },
  {
    label: "Pristatymas Klaipėdos apskrityje iki Kalėdų",
    value: "Pristatymas Klaipėdos apskrityje iki Kalėdų",
    options: ["Pristatymas Klaipėdos apskrityje iki Kalėdų"],
  },
  {
    label: "Itella paštomatas",
    value: "Itella paštomatas",
    options: [
      "Itella paštomatas",
      "Itella pristatymas į atsiėmimo tašką",
      "Smartpost pristatymas į atsiėmimo tašką",
      "Paštomatas ITELLA",
      "Itella pristatymas į nurodytą adresą",
    ],
  },
  {
    label: "Venipak paštomatas",
    value: "Venipak  paštomatas",
    options: [
      "Venipak pickup point",
      "Paštomatas VENIPAK",
      "Venipak paštomatas",
      "Venipak  paštomatas",
    ],
  },
  {
    label: "Venipak courier",
    value: "Venipak courier",
    options: [
      "VENIPAK kurjeris",
      "Kurjeris VENIPAK",
      "Venipak courier",
      "Kurjeris Venipak",
    ],
  },
  {
    label: "Skubus atsiėmimas (tik su Galimybių Pasu)",
    value: "Skubus atsiėmimas (tik su Galimybių Pasu)",
    options: [
      "Skubus atsiėmimas (tik su Galimybių Pasu)",
      "Express Atsiėmimas",
    ],
  },
];

export const getCarriers = async () => {
  try {
    const { data } = await Axios.get("/api/v1/carriers");
    if (data) {
      let newArray = [...main_carriers];
      data.forEach((item) => {
        if (!item.name) return;
        const findIndexFirst = newArray.findIndex(
          (old) => old.value.toLowerCase() == item.name.toLowerCase()
        );
        if (findIndexFirst !== -1) {
          if (
            !newArray[findIndexFirst].id ||
            !newArray[findIndexFirst].module_name
          ) {
            newArray[findIndexFirst].id = item.id;
            newArray[findIndexFirst].module_name = item.module_name;
            return;
          }
        }

        if (
          [
            "DPD",
            "DPD Pick Up",
            "Omniva",
            "My carrier",
            "Pickup at Store",
            "DPD Carrier",
            "Omnivos paštomatai",
            "LP Express 24. PAŠTOMATAS",
            "Itella pristatymas į atsiėmimo tašką",
            "Smartpost pristatymas į atsiėmimo tašką",
            "Smartpost pristatymas į nurodytu adresu",
          ].includes(item.name) ||
          item.module_name == null ||
          newArray.find((e) =>
            e.options
              .map((option) => option.toLowerCase())
              .includes(item.name.toLowerCase())
          )
        ) {
          return;
        }

        const finIndex = newArray.findIndex(
          (e) =>
            lengthOfCharactersDifference(
              e.label.toLowerCase(),
              item.name.toLowerCase()
            ) < 4
        );
        if (finIndex !== -1) {
          if (
            !newArray[finIndex].options.find(
              (fi) => fi.toLowerCase() == item.name.toLowerCase()
            )
          )
            return (newArray[finIndex].options = [
              ...newArray[finIndex].options,
              item.name,
            ]);
          newArray[finIndex].id = item.id;
          newArray[finIndex].module_name = item.module_name;
        } else {
          return newArray.push({
            test: true,
            id: item.id,
            module_name: item.module_name,
            label: item.name,
            value: item.name,
            options: [item.name],
          });
        }
      });
      return newArray;
    }
  } catch (e) {
    console.log(e);
  }
};
