import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { ButtonEdit } from "../../../../common/Buttons";
import TextInputModal from "../../../../common/TextInputModal";
import DataPickerInputModal from "../../../../common/DataPickerInputModal";
import {
  LabelTextStyle,
  RadiusCheckBox,
} from "../../../../common/StyledComponents";
import { getRivileStoreOptions } from "../../../../../redux/Actions/rivileStoreAction";
import { getDateFormat } from "../../../../../helpers/Utils";
import moment from "moment";
const { Option } = Select;

function ServiceFormChildren1({
  form,
  onChangeHandler,
  onChangeHandlerMass,
  onSelectType,
  errors,
  searching,
}) {
  const [stores, setStores] = useState([]);
  useEffect(() => {
    if (form.type == "Priešprekybinis") {
      getRivileStoreOptions().then((res) => setStores(res));
    }
  }, [form.type]);
  function disabledDate(current) {
    return current > moment().endOf("day");
  }
  const pirkimoData = () => {
    return (
      <>
        <DataPickerInputModal
          disabledDate={disabledDate}
          required={true}
          text="Pirkimo data"
          name="buy_date"
          value={getDateFormat(form.buy_date)}
          // value={moment(form.buy_date)}
          onChange={(e, r) =>
            onChangeHandler({ target: { name: "buy_date", value: r } }, "form")
          }
          warning={errors.buy_date}
        />
      </>
    );
  };
  const pirkimo_dokumentas = () => {
    return (
      <TextInputModal
        required={true}
        text="Pirkimo dokumentas"
        name="buy_document"
        value={form.buy_document}
        onChange={(e) => onChangeHandler(e, "form")}
        warning={errors.buy_document}
      />
    );
  };
  const dokumento_tipas = () => {
    return (
      <>
        <div className={"text-bold my-1"}>
          Dokumento tipas <span className="orange_color">*</span>
        </div>
        <Select
          name="document_type"
          className={`${errors.document_type ? "error_border" : ""}`}
          value={form.document_type || undefined}
          style={{ width: "100%" }}
          placeholder="Pasirinkti"
          onChange={(value) =>
            onChangeHandler(
              { target: { name: "document_type", value } },
              "form"
            )
          }
        >
          <Option value="invoice">Sąskaita faktūra</Option>
          <Option value="cheque">Čekis</Option>
        </Select>
        {errors.document_type && (
          <div className="text-danger">{errors.document_type}</div>
        )}
      </>
    );
  };

  const padalinys = () => {
    return (
      <>
        <div className={"text-bold my-1"}>
          Padalinys <span className="orange_color">*</span>
        </div>
        <Select
          name="rivile_store_id"
          className={`${errors.rivile_store_id ? "error_border" : ""}`}
          value={form.rivile_store_id || undefined}
          style={{ width: "100%" }}
          placeholder="Pasirinkti"
          onChange={(value, ar) => {
            onChangeHandler(
              { target: { name: "rivile_store_id", value } },
              "form"
            );
           return onChangeHandlerMass({
              client_phone: ar.phone ? ar.phone?.split("+370").pop() : "",
              client_city: ar.city,
              client_street: ar.address,
              client_postal_code:ar.postal_code,
            })
          }}
        >
          {stores.map((e) => (
            <Option
              key={e.id}
              value={e.id}
              city={e.city}
              address={e.address}
              postal_code={e.postal_code}
              phone={e.phone}
            >
              {`${e.title} - ${e.address}`}
            </Option>
          ))}
        </Select>
        {errors.rivile_store_id && (
          <div className="text-danger">{errors.rivile_store_id}</div>
        )}
      </>
    );
  };

  const sertifikato_data = () => {
    return (
      <>
        <DataPickerInputModal
          disabledDate={disabledDate}
          // required={true}
          text="Sertifikato data"
          name="certificate_date"
          value={getDateFormat(form.certificate_date)}
          // value={moment(form.certificate_date)}
          onChange={(e, ar) =>
            onChangeHandler(
              { target: { name: "certificate_date", value: ar } },
              "form"
            )
          }
          warning={errors.certificate_date}
        />
      </>
    );
  };
  const prekes_kodas = () => {
    return (
      <TextInputModal
        required={true}
        text="Prekes kodas"
        name="item_code"
        value={form.item_code}
        onChange={(e) => onChangeHandler(e, "form")}
        warning={errors.item_code}
      />
    );
  };
  const setifikato_numeris = () => {
    return (
      <TextInputModal
        required={true}
        text="Setifikato numeris"
        name="certificate_number"
        value={form.certificate_number}
        onChange={(e, ar) => onChangeHandler(e, "form")}
        warning={errors.certificate_number}
      />
    );
  };
  const pvm_sask = () => {
    return (
      <TextInputModal
        text="PVM sąsk.-fakt."
        name="form_pvm"
        value={form.form_pvm}
        onChange={(e) => onChangeHandler(e, "form")}
        warning={errors.form_pvm}
      />
    );
  };
  const paieska = () => {
    return (
      <>
        <div className="text-bold my-1 invisible">Search..</div>
        <div className="d-flex justify-content-end">
          <ButtonEdit
            disabled={searching}
            style={{
              width: "32px",
              height: "32px",
              backgroundColor: "#f7931e",
            }}
            onEdit={() => onSelectType()}
          />
        </div>
      </>
    );
  };

  const invoice_items = () => {
    return (
      <TextInputModal
        required={true}
        text="Sąskaitos faktūros numeris"
        name="buy_document"
        value={form.buy_document}
        onChange={(e) =>
          onChangeHandler(
            {
              target: {
                name: e.target.name,
                value: e.target.value.toUpperCase(),
              },
            },
            "form"
          )
        }
        // onChange={(e) => onChangeHandler(e, "form")}
        warning={errors.buy_document}
      />
    );
  };

  const cheque_items = () => {
    return (
      <TextInputModal
        required={true}
        text="Čekio numeris"
        name="buy_document"
        value={form.buy_document}
        onChange={(e) =>
          onChangeHandler(
            {
              target: {
                name: e.target.name,
                value: e.target.value.toUpperCase(),
              },
            },
            "form"
          )
        }
        // onChange={(e) => onChangeHandler(e, "form")}
        warning={errors.buy_document}
      />
    );
  };

  const formBuilder = () => {
    switch (form.type) {
      case "Garantinis":
        return (
          <>
            <div className="pr-2" style={{ width: "32%" }}>
              {pirkimoData()}
            </div>
            <div className="px-2" style={{ width: "32%" }}>
              {dokumento_tipas()}
            </div>
            <div className="px-2" style={{ width: "32%" }}>
              {form.document_type === "cheque"
                ? cheque_items()
                : form.document_type === "invoice"
                ? invoice_items()
                : null}
            </div>
            <div className="pl-2" style={{ width: "4%" }}>
              {form.document_type && paieska()}
            </div>
          </>
        );
      case "Defekto aktas":
        return (
          <>
            <div className="pr-2" style={{ width: "32%" }}>
              {pirkimoData()}
            </div>
            <div className="px-2" style={{ width: "32%" }}>
              {dokumento_tipas()}
            </div>
            <div className="px-2" style={{ width: "32%" }}>
              {form.document_type === "cheque"
                ? cheque_items()
                : form.document_type === "invoice"
                ? invoice_items()
                : null}
            </div>
            <div className="pl-2" style={{ width: "4%" }}>
              {form.document_type && paieska()}
            </div>
          </>
        );
      case "PPG":
        return (
          <>
            <div className="pr-2" style={{ width: "32%" }}>
              {sertifikato_data()}
            </div>
            <div className="px-2" style={{ width: "32%" }}>
              {setifikato_numeris()}
            </div>
            <div className="pl-2" style={{ width: "4%" }}>
              {paieska()}
            </div>
          </>
        );
      case "Priešprekybinis":
        return (
          <>
            <div className="pr-2" style={{ width: "32%" }}>
              {prekes_kodas()}
            </div>
            <div className="px-2" style={{ width: "32%" }}>
              {/* {pvm_sask()} */}
              {padalinys()}
            </div>
            <div className="pl-2" style={{ width: "4%" }}>
              {paieska()}
            </div>
          </>
        );

      default:
        return <div>Loading...</div>;
    }
  };

  const onSelectItem = (i) => {
    onChangeHandler(
      { target: { name: "item_price", value: i.price } },
      "form",
      i
    );
  };

  return (
    <>
      <div>
        <div className="row mx-0">{formBuilder()}</div>
      </div>
      {form.goods.length > 0 && (
        <div className="my-3">
          <LabelTextStyle className={!form.item_name ? "text-danger" : ""}>
            Pasirinkite prekę
          </LabelTextStyle>
          <div style={{ width: "33%" }}>
            <table className="table table-sm">
              <tbody>
                {form.goods.map((i, index) => (
                  <tr key={index}>
                    <td style={{ width: 25 }}>
                      <RadiusCheckBox
                        checked={form.item_name === i.title}
                        onChange={() => onSelectItem(i)}
                      />
                    </td>
                    <td>{i.title}</td>
                    <td>
                      {i.price}
                      {" Eur."}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div style={{ width: "75%" }}>
              <TextInputModal
                required={form.type !== "Priešprekybinis"}
                text="Serijinis numeris"
                name="serial_number"
                value={form.serial_number}
                onChange={(e) =>
                  onChangeHandler(
                    {
                      target: {
                        name: e.target.name,
                        value: e.target.value.toUpperCase(),
                      },
                    },
                    "form"
                  )
                }
                // onChange={(e) => onChangeHandler(e, "form")}
                // warning={errors.serial_number}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ServiceFormChildren1;
