import React from "react";
import { useState } from "react";

//
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useEffect } from "react";
import Axios from "axios";
import { getBase64 } from "../../../../helpers/Utils";

export default function WYSIWYG({ value = "", onChange = () => {}, label }) {
  // const html = "<p>Hey this <strong>editor</strong> rocks 😀</p>";
  console.log({ value });
  const contentBlock = htmlToDraft(typeof value === "string" ? value : "");
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState)
  );

  useEffect(() => {
    onChange({
      target: {
        name: "description",
        value: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      },
    });
    // onChange({ target: { name: "description", value: editorState } });
  }, [editorState]);

  const uploadCallback = async (uploaded_file) => {
    try {
      const result = await getBase64(uploaded_file);
      file["base64"] = result;
      console.log({ result });

      const { data } = await Axios.post(
        "/api/v1/guide_products/save_image_for_description",
        {
          asset: result,
          asset_name:
            Math.random().toString(36).substring(4) +
            uploaded_file.name
              .toLowerCase()
              .replace(/\(/g, "")
              .replace(/\)/g, "")
              .replace(/ /g, "-"),
        }
      );
      console.log(data);
      return { data: { link: data.url } };
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  return (
    <>
      <div className="bg-white my-2">
        {label && <div className="text-bold my-1 ">{label}</div>}
        <Editor
          toolbar={{ image: { uploadCallback: uploadCallback } }}
          editorState={editorState}
          onEditorStateChange={setEditorState}
        />
      </div>
    </>
  );
}
