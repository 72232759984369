import React, { useState, useEffect } from "react";
import DPDSVG from "../../../../../assets/images/shipment/DPD.svg";
import OMNIVASVG from "../../../../../assets/images/shipment/OMNIVA.svg";
import LPEXPRESSSVG from "../../../../../assets/images/shipment/LPEXPRESS.svg";
import TextInputModal from "../../../common/TextInputModal";
import { CheckBoxInput, SelectForm } from "../../../common/Buttons";
import { Divs } from "../../../common/StyledComponents";
import { FcCheckmark } from "react-icons/fc";
import ShipmentAdditionalServices from "../../../common/GlobalModals/Orders/ShipmentAdditionalServices";
import RowsInShipment from "../../../common/GlobalModals/Orders/editModal/RowsInShipment";
import {
  handleChangeOrdertypeGlobal,
  have_product_reference,
  onChangeCheckBoxDeliveryGlobal,
  orCorrectDeliveryMethod,
  orderDeliveryMount,
  orderTypeMount,
  setterOrderCod,
} from "../../../../helpers/OrdersFunctionsGlobal";
import { getPickupAddressesOption } from "../../../../redux/Actions/pickupAddressAction";
import { getParcelTerminals } from "../../../../redux/Actions/parcelTerminalAction";
import { ClassicButton } from "../../../common/ButtonWithLoading";
import { useDispatch } from "react-redux";
import { postOrderShipping } from "../../../../redux/Actions/orderAction";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { shipment_types_options } from "../../../../helpers/GlobalOptions";
import { FaBoxOpen, FaStore } from "react-icons/fa";
import { GoPrimitiveDot } from "react-icons/go";
import { lengthOfCharactersDifference } from "../../../../helpers/Utils";
import { ruleCarrying } from "../../../../helpers/ShipmentRules";

const defaultGeneral = {
  weight: 0.1,
  pack: 1,
  order_return: false,
  cod: "",
  cod_amount: 0,
  deliveryOnSaturday: false,
  sendingDocuments: false,
  who: "",
  deliveryTime: false,
  deliveryTimeHours: ["", ""],
  carrying: false,
  swap: false,
  lp_express_sizes: undefined,
  suppliers: undefined,
};
const defaultAddress = {
  parcel_terminal_id: undefined,
  firstname: "",
  lastname: "",
  postal_code: "",
  street: "",
  city: "",
  phone: "",
  other: "",
  comment: "",
};

export default function ShipmentComponentIndex({
  order_rows,
  id_status,
  id,
  remark,
  shipping_address,
  total_shipping,
  carrier,
  billing_address,
  total_price,
  payment_module,
  onClose,
  cod_default,
}) {
  const dispatch = useDispatch();
  const [action, setAction] = useState("creating");
  const [activeKey, setActiveKey] = useState([2]);
  const [pickupAddressData, setPickupAddressData] = useState([]);
  const [parcels, setParcels] = useState({
    omniva: [],
    dpd: [],
    lp_express: [],
    itella: [],
  });
  const [selectedOrderRows, setSelectedOrderRows] = useState(
    order_rows.map((e) => e.id)
  );
  const [shipmentRowForStore, setShipmentRowForStore] = useState([]);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("dpd");
  const [delivery, setDelivery] = useState("dpd_b2c");
  const [address, setAddress] = useState({
    ...defaultAddress,
    ...shipping_address,
  });
  const [general, setGeneral] = useState(defaultGeneral);
  //
  const [errors, setErrors] = useState({});
  useEffect(() => {
    const generate_type = orderTypeMount(carrier);
    let generate_delivery = orderDeliveryMount(generate_type);

    if (shipping_address.parcel_terminal_id !== null) {
      if (generate_type == "omniva") {
        generate_delivery = "omniva_postMachine";
      } else if (generate_type == "lp_express") {
        generate_delivery = "lp_express_postMachine";
      } else if (generate_type == "dpd") {
        generate_delivery = "dpd_postMachine";
      } else if (generate_type == "itella") {
        generate_delivery = "itella_postMachine";
      } else if (generate_type == "venipak") {
        generate_delivery = "venipak_postMachine";
      }
    }

    setGeneral((prev) => ({
      ...prev,
      cod:
        generate_type === "at_the_store"
          ? false
          : setterOrderCod({
              payment_module,
              cod: cod_default,
              id_status,
            }),
      carrying: ruleCarrying({
        order_type: generate_type,
        delivery: generate_delivery,
        skipCarrying: false,
      })
        ? order_rows.some((item) => item.product_name === "Užnešimas")
        : false,
      cod_amount: total_price,
      weight: countWeight(order_rows),
    }));

    setAddress(
      generate_type === "at_the_store"
        ? { ...billing_address, comment: remark }
        : { ...shipping_address, comment: remark }
    );

    setType(generate_type);
    setDelivery(generate_delivery);

    getParcelTerminals().then((res) => {
      if (res.success) {
        setParcels(res.data);
      }
    });
    getPickupAddressesOption().then((response) => {
      if (response.data) {
        let newPickupAdress = [];
        response.data.data.forEach((address) => {
          let newAddressObj = {
            address: address.address,
            company: address.company,
            value: address.id,
            label: `${address.company} - ${address.address} - ${address.city} `,
          };
          return newPickupAdress.push(newAddressObj);
        });
        setPickupAddressData(newPickupAdress);
      }
    });
  }, [action]);

  useEffect(() => {
    setGeneral((prev) => ({
      ...prev,
      carrying: ruleCarrying({
        order_type: type,
        delivery,
        skipCarrying: false,
      })
        ? order_rows.some((item) => item.product_name === "Užnešimas")
        : false,
    }));
  }, [delivery]);

  useEffect(() => {
    setGeneral((prev) => ({
      ...prev,
      weight: countWeight(order_rows),
    }));
  }, [type]);

  useEffect(() => {
    const generate_type = orderTypeMount(carrier);

    if (generate_type == "at_the_store") {
      const address = shipping_address;
      const takeFromArray = pickupAddressData
        .filter(
          (e) =>
            e.company?.toUpperCase().split(" ").join("") == "UABVERSLOVARTAI"
        )
        .map((e) => ({
          value: e.value,
          address: lengthOfCharactersDifference(
            e.address.toLowerCase().split(" ").join(""),
            address.street.toLowerCase().split(" ").join("")
          ),
        }))
        .sort((a, b) => (a.address > b.address ? 1 : -1));
      if (takeFromArray.length > 0) {
        setGeneral((prevProps) => ({
          ...prevProps,
          suppliers: takeFromArray[0].value,
        }));
      }
    }
  }, [pickupAddressData]);
  const countWeight = (items) => {
    if (type == "at_the_store") {
      return 1;
    }
    const reducer = (accumulator, currentValue) => {
      if (
        typeof currentValue.product_info === "object" &&
        currentValue.product_info !== null
      ) {
        return accumulator + +currentValue.product_info.weight;
      } else {
        return accumulator + 0;
      }
    };
    const total = items.reduce(reducer, 0);
    return !total ? 0.1 : total > 0.1 ? total : 0.1;
  };
  const order_rows_for_store = () => {
    let shipmentRowForStoreArr = order_rows.map((e) => ({
      id: e.id,
      product_reference: !have_product_reference(e.product_reference)
        ? null
        : e.product_reference,
      title: e.product_name,
      price: e.unit_price_tax_incl,
      quantity: e.quantity,
      edit: false,
      changes: false,
    }));
    if (total_shipping > 0) {
      shipmentRowForStoreArr.push({
        id: null,
        title: `Atsiėmimas parduotuvėje${
          carrier.module_name !== "fspickupatstorecarrier"
            ? ": " + carrier.name
            : ""
        }`,
        price: total_shipping,
        quantity: 1,
        edit: false,
        delivery: true,
        changes: true,
      });
    }
    setShipmentRowForStore(shipmentRowForStoreArr);
  };

  useEffect(() => {
    order_rows_for_store();
  }, [order_rows]);

  const onChangeAddress = (e) => {
    const { value, name } = e.target;
    setAddress({
      ...address,
      [name]: value,
    });
  };

  const onChangeGeneral = (e) => {
    const { value, name } = e.target;
    setGeneral((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleCheckBoxOrdersSelected = (value) => {
    const newChecked = [...selectedOrderRows];
    const currentIndex = newChecked.indexOf(value);

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    const weight =
      countWeight(order_rows.filter((d) => newChecked.includes(d.id))) || 0.1;

    setGeneral((prev) => ({
      ...prev,
      weight,
    }));

    setSelectedOrderRows(newChecked);
  };

  const onChangeType = (type) => {
    const {
      carrying,
      cod,
      delivery,
      deliveryOnSaturday,
      deliveryTime,
      deliveryTimeHours,
      // order_type,
      pack,
      sendingDocuments,
      shipping_address_edit,
      swap,
      // terminalSelected,
      weight,
      who,
    } = handleChangeOrdertypeGlobal(
      type,
      "order_type",
      { ...shipping_address, comment: address.comment },
      { ...billing_address, comment: address.comment }
    );
    setGeneral((prev) => ({
      ...prev,
      carrying,
      cod,
      deliveryOnSaturday,
      deliveryTime,
      deliveryTimeHours,
      pack,
      sendingDocuments,
      swap,
      weight,
      who,
    }));
    setDelivery(delivery);
    setAddress({
      ...shipping_address_edit,
      parcel_terminal_id: null,
    });
    setType(type);
  };

  const onChangeDelivery = (name) => {
    const { delivery, pack, swap, terminalSelected } =
      onChangeCheckBoxDeliveryGlobal({ target: { name } }, delivery);

    setAddress({
      ...address,
      parcel_terminal_id: terminalSelected || null,
    });
    setGeneral((prev) => ({
      ...prev,
      pack,
      swap,
    }));
    setDelivery(delivery);
  };

  const createShipping = async () => {
    setLoading(true);

    const {
      weight,
      pack: packets,
      order_return,
      cod,
      cod_amount,
      deliveryOnSaturday,
      sendingDocuments,
      who,
      deliveryTime,
      deliveryTimeHours,
      carrying,
      swap,
      lp_express_sizes,
      suppliers,
    } = general;
    const {
      parcel_terminal_id,
      firstname,
      lastname,
      postal_code,
      street,
      city,
      phone,
      other,
      comment,
    } = address;
    const orderTypeAtTheStore = type === "at_the_store";

    let orderVariables = {
      shipment: {
        order_row_ids: orderTypeAtTheStore ? [] : selectedOrderRows,
        shipment_type: type,
        packets,
        weight: orderTypeAtTheStore ? 0.1 : weight,
        pickup_address_id: suppliers,
        shipment_address_attributes: {
          company: "",
          name: firstname + " " + lastname,
          address: street,
          city,
          postal_code,
          parcel_terminal_id,
          phone,
          email: other,
          comment,
        },
        shipment_order_rows_attributes: orderTypeAtTheStore
          ? shipmentRowForStore.map((e) => ({
              quantity: e.quantity,
              title: e.title,
              price: e.price,
              order_row_id: e.id,
            }))
          : [],
        shipment_additional_services_attributes: [
          {
            service_key: "order_return",
            service_value: order_return ? 1 : 0,
          },
          {
            service_key: "cod",
            service_value: cod ? cod_amount : null,
          },
          {
            service_key: "sat",
            service_value: deliveryOnSaturday,
          },
          {
            service_key: "docret",
            service_value: sendingDocuments ? who : false,
          },
          {
            service_key: "time",
            service_value: deliveryTime
              ? `${deliveryTimeHours[0]} - ${deliveryTimeHours[1]}`
              : null,
          },
          {
            service_key: "delivery",
            service_value: delivery,
          },
          {
            service_key: "carrying",
            service_value: carrying,
          },
          {
            service_key: "swap",
            service_value: swap,
          },
          {
            service_key: "weight",
            service_value: weight,
          },
          {
            service_key: "pack",
            service_value: packets,
          },
          {
            service_key: "lp_express_sizes",
            service_value: lp_express_sizes,
          },
        ],
      },
    };

    dispatch(postOrderShipping(id, orderVariables)).then((res) => {
      if (res.success) {
        setLoading(false);
        setAction("created");
      } else {
        setLoading(false);
        setErrors(res.errors);
      }
    });
  };
  console.log({ general });
  const createNewShipping = () => {
    setAction("creating");
    setActiveKey([1, 2]);
    setSelectedOrderRows(order_rows.map((e) => e.id));
    order_rows_for_store();
    setType("dpd");
    setDelivery("dpd_b2c");
    setAddress({ ...defaultAddress, ...shipping_address });
    setGeneral(defaultGeneral);
    setErrors({});
    setLoading(false);
  };

  const collapseBlock = (item) => {
    let allActives = [...activeKey];
    if (allActives.includes(item)) {
      allActives = allActives.filter((e) => e != item);
    } else {
      allActives.push(item);
    }
    setActiveKey(allActives);
  };

  const shipmentRowForStoreFunction = (action, index, e) => {
    let shipmentRowForStoreArr = [...shipmentRowForStore];
    if (action === "push") {
      shipmentRowForStoreArr = [
        ...shipmentRowForStoreArr,
        {
          id: null,
          product_reference: null,
          quantity: 1,
          title: "",
          price: 0,
          edit: true,
          changes: false,
        },
      ];
    } else if (action === "edit") {
      shipmentRowForStoreArr[index].changes = true;
      shipmentRowForStoreArr[index][e.target.name] = e.target.value;
    } else {
      shipmentRowForStoreArr.splice(index, 1);
    }
    setShipmentRowForStore(shipmentRowForStoreArr);
  };

  const renderTextInBlock = (e) => {
    switch (e.value) {
      case "at_the_store":
        return (
          <span>
            <FaStore className="mr-1" />
            Ats. pard.
          </span>
        );
      case "omniva":
        return <img src={OMNIVASVG} />;
      case "dpd":
        return <img src={DPDSVG} />;
      case "itella":
        return (
          <>
            <GoPrimitiveDot className="mr-1" style={{ color: "#005dc0" }} />{" "}
            ITELLA
          </>
        );
      case "lp_express":
        return <img src={LPEXPRESSSVG} style={{ width: 65 }} />;
      case "own_transport":
        return (
          <span>
            <FaBoxOpen className="mr-1" />
            Technorama
          </span>
        );
      default:
        return e.label;
    }
  };

  if (action == "created") {
    return <SuccessCreated createNewShipping={createNewShipping} />;
  }
  // MAIN
  return (
    <>
      <div
        style={{
          width: 400,
          // minHeight: 500,
        }}
      >
        <div className="col-12">
          <span className="font-weight-bold d-block modal-title text-orange mb-2">
            <span className="font-weight-bold text-black">Siuntimai</span>
            <button type="button" className="close" onClick={() => onClose()}>
              <span aria-hidden="true">×</span>
            </button>
          </span>
        </div>
        <div className="col-12 mb-2">
          <div className="d-flex flex-wrap minus-margin">
            {shipment_types_options.map((e) => (
              <div
                className={`d-flex align-items-center justify-content-center cursor-pointer mx-1
            ${
              e.value === type
                ? "border-orange bg-white border"
                : "bg-silver-light"
            }
            `}
                style={{
                  width: "30%",
                  minHeight: 35,
                  borderRadius: 2,
                  borderWidth: 0.5,
                  marginBottom: 5,
                }}
                onClick={() => onChangeType(e.value)}
              >
                {renderTextInBlock(e)}
              </div>
            ))}
          </div>
        </div>

        <div className="col-12">
          <div className="border-bottom py-1">
            <div
              className="d-flex justify-content-between align-items-center cursor-pointer"
              onClick={() => collapseBlock(1)}
            >
              <div
                className="text-black font-weight-bold"
                style={{ fontSize: 16, letterSpacing: "0.4px" }}
              >
                Adresas
              </div>
              {activeKey.includes(1) ? (
                <MdKeyboardArrowUp color="#707070" size={20} />
              ) : (
                <MdKeyboardArrowDown color="#707070" size={20} />
              )}
            </div>
            {activeKey.includes(1) && (
              <div className="mb-2">
                <RenderAddress
                  delivery={delivery}
                  onChangeDelivery={onChangeDelivery}
                  type={type}
                  address={address}
                  onChangeAddress={onChangeAddress}
                  parcels={parcels}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-12 ">
          <div className="border-bottom  py-1">
            <div
              className="d-flex justify-content-between align-items-center cursor-pointer"
              onClick={() => collapseBlock(2)}
            >
              <div
                className="text-black font-weight-bold"
                style={{ fontSize: 16, letterSpacing: "0.4px" }}
              >
                Informacija
              </div>
              {activeKey.includes(2) ? (
                <MdKeyboardArrowUp color="#707070" size={20} />
              ) : (
                <MdKeyboardArrowDown color="#707070" size={20} />
              )}
            </div>
            {activeKey.includes(2) && (
              <div className="mb-2">
                <RenderInformation
                  order_rows={order_rows}
                  selectedOrderRows={selectedOrderRows}
                  handleCheckBoxOrdersSelected={handleCheckBoxOrdersSelected}
                  type={type}
                  delivery={delivery}
                  general={general}
                  onChangeGeneral={onChangeGeneral}
                  errors={errors}
                  pickupAddressData={pickupAddressData}
                  shipmentRowForStore={shipmentRowForStore}
                  shipmentRowForStoreFunction={shipmentRowForStoreFunction}
                  address={address}
                  onChangeAddress={onChangeAddress}
                  order_id={id}
                />
              </div>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end mt-2">
          <ClassicButton
            loading={loading}
            text={"Sukurti siuntą"}
            onClick={() => createShipping()}
          />
        </div>
      </div>
    </>
  );
}

const RenderInformation = ({
  order_rows,
  selectedOrderRows,
  handleCheckBoxOrdersSelected,
  type,
  delivery,
  general,
  onChangeGeneral,
  errors,
  pickupAddressData,
  shipmentRowForStore,
  shipmentRowForStoreFunction,
  address,
  onChangeAddress,
  order_id
}) => {
  return (
    <>
      <Divs>
        <RowsInShipment
          at_the_store={type === "at_the_store"}
          order_rows={order_rows}
          shipmentRowForStore={shipmentRowForStore}
          shipmentRowForStoreFunction={shipmentRowForStoreFunction}
          selectedOrderRows={selectedOrderRows}
          handleCheckBoxOrdersSelected={handleCheckBoxOrdersSelected}
        />
        <div className="pb-1 ">
          <SelectForm
            options={pickupAddressData}
            placeholder={"Tiekėjas"}
            onlyPlaceholder="Pasirinkti"
            selector="suppliers"
            value={general.suppliers}
            dropdownMatchSelectWidth={false}
            onChange={(value) =>
              onChangeGeneral({ target: { value, name: "suppliers" } })
            }
            error={
              errors?.pickup_address &&
              errors?.pickup_address.map((item) => `${item} `)
            }
          />
          {!orCorrectDeliveryMethod(type, general.suppliers, [
            { service_key: "delivery", service_value: delivery },
          ]) && (
            <div className="text-danger">
              <div>Neteisingai pasirinktas pristatymo būdas</div>
            </div>
          )}
          <TextInputModal
            text="Pastaba kurjeriui"
            placeholder="Įvesti"
            name="comment"
            value={address.comment}
            onChange={onChangeAddress}
          />
        </div>
        <ShipmentAdditionalServices
          order_id={order_id}
          itsTypeStore={false}
          order_type={type}
          delivery={delivery}
          variables={general}
          handleChangeText={onChangeGeneral}
          shipmentRowForStore={shipmentRowForStore}
          onChangeDate={(value) =>
            onChangeGeneral({ target: { name: "deliveryTimeHours", value } })
          }
          errors={errors}
          postal_code={address.postal_code}
        />
      </Divs>
    </>
  );
};

const RenderAddress = ({
  delivery,
  onChangeDelivery,
  type,
  address,
  onChangeAddress,
  parcels,
}) => {
  return (
    <Divs>
      <div className="row">
        <div className="col-12">
          {type === "dpd" && (
            <>
              <CheckBoxInput
                choised="dpd_b2c"
                name="dpd_b2c"
                color="#f7951e"
                text="B2C siunta (privačiam asmeniui)"
                checked={delivery == "dpd_b2c"}
                onChange={() => onChangeDelivery("dpd_b2c")}
              />
              <CheckBoxInput
                choised="dpd_postMachine"
                name="dpd_postMachine"
                color="#f7951e"
                text="Pristatymas į paštomatą"
                checked={delivery == "dpd_postMachine"}
                onChange={() => onChangeDelivery("dpd_postMachine")}
              />
            </>
          )}
          {type === "itella" && (
            <CheckBoxInput
              choised="itella_postMachine"
              name="itella_postMachine"
              color="#f7951e"
              text="Pristatymas į paštomatą"
              checked={delivery == "itella_postMachine"}
              onChange={(e) => onChangeDelivery(e)}
            />
          )}

          {type === "omniva" && (
            <>
              <CheckBoxInput
                choised="omniva_home"
                name="omniva_home"
                color="#f7951e"
                text="Pristatymas į namus"
                checked={delivery == "omniva_home"}
                onChange={() => onChangeDelivery("omniva_home")}
              />
              <CheckBoxInput
                choised="omniva_postMachine"
                name="omniva_postMachine"
                color="#f7951e"
                text="Pristatymas į paštomatą"
                checked={delivery == "omniva_postMachine"}
                onChange={() => onChangeDelivery("omniva_postMachine")}
              />
            </>
          )}

          {type === "venipak" && (
            <>
              <CheckBoxInput
                choised="venipak_home"
                name="venipak_home"
                color="#f7951e"
                text="Pristatymas į namus"
                checked={delivery == "venipak_home"}
                onChange={() => onChangeDelivery("venipak_home")}
              />
              <CheckBoxInput
                choised="venipak_postMachine"
                name="venipak_postMachine"
                color="#f7951e"
                text="Pristatymas į paštomatą"
                checked={delivery == "venipak_postMachine"}
                onChange={() => onChangeDelivery("venipak_postMachine")}
              />
            </>
          )}

          {type === "lp_express" && (
            <>
              <CheckBoxInput
                choised="lp_express_home"
                name="lp_express_home"
                color="#f7951e"
                text="Pristatymas į namus"
                checked={delivery == "lp_express_home"}
                onChange={() => onChangeDelivery("lp_express_home")}
              />
              <CheckBoxInput
                choised="lp_express_postMachine"
                name="lp_express_postMachine"
                color="#f7951e"
                text="Pristatymas į paštomatą"
                checked={delivery == "lp_express_postMachine"}
                onChange={() => onChangeDelivery("lp_express_postMachine")}
              />
            </>
          )}
        </div>
        <div className="col-6">
          <TextInputModal
            text="Gavėjas"
            placeholder="Įvesti"
            name="firstname"
            value={address.firstname}
            onChange={onChangeAddress}
          />
        </div>
        <div className="col-6">
          <TextInputModal
            text="Kontaktinis asmuo"
            placeholder="Įvesti"
            name="lastname"
            value={address.lastname}
            onChange={onChangeAddress}
          />
        </div>
        <div className="col-6">
          <TextInputModal
            text="Adresas"
            placeholder="Įvesti"
            name="street"
            value={address.street}
            onChange={onChangeAddress}
          />
        </div>

        {/*  */}
        {type === "omniva" && delivery === "omniva_postMachine" && (
          <div className="col-6">
            <SelectForm
              options={parcels.omniva}
              placeholder="Paštomatas"
              onlyPlaceholder="Pasirinkti"
              value={address.parcel_terminal_id}
              onChange={(e) =>
                onChangeAddress({
                  target: { name: "parcel_terminal_id", value: e },
                })
              }
            />
          </div>
        )}
        {/* cia dar */}

        {type === "dpd" && delivery === "dpd_postMachine" && (
          <div className="col-6">
            <SelectForm
              options={parcels.dpd}
              placeholder="Paštomatas"
              onlyPlaceholder="Pasirinkti"
              value={address.parcel_terminal_id}
              onChange={(e) =>
                onChangeAddress({
                  target: { name: "parcel_terminal_id", value: e },
                })
              }
            />
          </div>
        )}

        {type === "itella" && (
          <div className="col-6">
            <SelectForm
              options={parcels.itella}
              placeholder="Paštomatas"
              onlyPlaceholder="Pasirinkti"
              value={address.parcel_terminal_id}
              onChange={(e) =>
                onChangeAddress({
                  target: { name: "parcel_terminal_id", value: e },
                })
              }
            />
          </div>
        )}
        {type === "lp_express" && delivery === "lp_express_postMachine" && (
          <div className="col-6">
            <SelectForm
              options={parcels.lp_express}
              placeholder="Paštomatas"
              onlyPlaceholder="Pasirinkti"
              value={address.parcel_terminal_id}
              onChange={(e) =>
                onChangeAddress({
                  target: { name: "parcel_terminal_id", value: e },
                })
              }
            />
          </div>
        )}

        {/*  */}

        <div className="col-6">
          <TextInputModal
            text="Pašto kodas"
            placeholder="Įvesti"
            name="postal_code"
            value={address.postal_code}
            onChange={onChangeAddress}
          />
        </div>
        <div className="col-6">
          <TextInputModal
            text="Telefonas"
            placeholder="Įvesti"
            name="phone"
            value={address.phone}
            onChange={onChangeAddress}
          />
        </div>
        <div className="col-6">
          <TextInputModal
            text="El. paštas"
            placeholder="Įvesti"
            name="other"
            value={address.other}
            onChange={onChangeAddress}
          />
        </div>
        {/* <div className="col-6">
          <TextInputModal
            text="Pastaba kurjeriui"
            placeholder="Įvesti"
            name="comment"
            value={address.comment}
            onChange={onChangeAddress}
          />
        </div> */}
      </div>
    </Divs>
  );
};

const SuccessCreated = ({ createNewShipping }) => {
  return (
    <div
      style={{
        width: 400,
        // minHeight: 500,
      }}
    >
      <div className="d-flex align-items-center justify-content-center">
        <FcCheckmark />
        <p className="mb-0 ml-2">Sėkmingai sukurta!</p>
        <div className="float-right ml-3">
          <ClassicButton
            loading={false}
            text={"Pildyti nauja forma"}
            onClick={() => createNewShipping()}
          />
        </div>
      </div>
    </div>
  );
};
