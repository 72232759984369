import React from "react";
import { ButtonEdit, ButtonDelete2 } from "../../common/Buttons";
import SwitchComp from "../../common/SwitchComp";

function displayLeasingRules(item) {
  let data = [];
  //item.leasing_products_attributes.map(i => {
  //  data.push(i.title);
  //});
  item.leasing_brands_attributes.map(i => {
    data.push(i.title);
  });
  item.leasing_families_attributes.map(i => {
    data.push(i.title);
  });
  return data;
}

function TableColumnSms({ item, index, onEdit, onDelete, onToggle }) {

  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle">
        <SwitchComp
              name="Galioja"
              placeholder=""
              checked={item.leasing_enabled}
              onChange={() => onToggle(!item.leasing_enabled, index)
              }
            />
      </td>
      <td className="align-middle">{item.priority}</td>
      <td className="align-middle">{item.title}</td>
      <td className="align-middle">{item.leasing_start}</td>
      <td className="align-middle">{item.leasing_end}</td>
      <td className="align-middle">{item.months}</td>
      <td className="align-middle">{item.leasing_text}</td>
      <td className="align-middle">{displayLeasingRules(item).map((i, index) => (<span className = "leasing-rule" key = {"rule-" + index}>{i}</span>) )}</td>
      <td className="align-middle" style={{ width: "100px" }}>
        <ButtonEdit onEdit={() => onEdit(item.id)} />{" "}
        <ButtonDelete2 onDelete={() => onDelete(item.id)} />
      </td>
    </tr>
  );
}

export default TableColumnSms;
