import React from "react";
import { ButtonEdit, ButtonDelete } from "../../common/Buttons";
import moment from "moment";
import { GoPrimitiveDot } from "react-icons/go";


function TableColumnDocuments({
  id,
  item,
  index,
  onEdit,
  onDelete,
  stores,
}) {
  const findStore = stores.find((find) => find.value === item.store_id);
  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle">{item.id}</td>
      <td className="align-middle">{item.email}</td>
      <td className="align-middle">{item.firstname}</td>
      <td className="align-middle">{item.lastname}</td>
      <td className="align-middle">{item.user_role?.title}</td>
      <td className="align-middle">{findStore?.label}</td>
      <td className="align-middle">{item.tabel_number}</td>
      <td className="align-middle">{item.manager_code}</td>
      <td className="align-middle">
        <GoPrimitiveDot
          className={item.sb_leasing_user ? "text-success" : "text-danger"}
        />
      </td>
      <td className="align-middle">
        <GoPrimitiveDot
          className={item.bigbox_import ? "text-success" : "text-danger"}
        />
      </td>
      <td className="align-middle">
        <GoPrimitiveDot
          className={item.otp_required_for_login ? "text-success" : "text-danger"}
        />
      </td>
      <td className="align-middle">
        {item.deleted_at
          ? moment(item.deleted_at).format("YYYY-MM-DD HH:mm")
          : ""}
      </td>
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonEdit id={item.id} onEdit={() => onEdit("edit", index)} />
      </td>
      <td className="align-middle" style={{ width: "50px" }}>
        {!item.deleted_at && (
          <ButtonDelete onDelete={() => onDelete(id)} />
        )}
      </td>
    </tr>
  );
}

export default TableColumnDocuments;
