import React from "react";
import { Input } from "antd";

const InputPhone = (props) => {
  const renderErrors = () => {
    return props.error.map((err, index) => {
      if (props.error.length - 1 == index) {
        return <span>{err}</span>;
      }
      return <span className="mr-1">{err},</span>;
    });
  };
  return (
    <>
      <div className={`text-bold my-1 ${props.error ? "text-danger" : ""}`}>
        {props.text} {props.required && <span className="orange_color">*</span>}
      </div>
      <div className="d-flex">
        <Input
          value={"+370"}
          style={{
            width: 65,
            borderRadius: "2px 0px 0px 2px",
          }}
          onChange={() => {}}
          disabled={true}
        />
        <Input
          autoComplete="dummy-no-auto-complete-fix"
          type={props.type || "text"}
          className={`orange ${
            props.warning || props.error ? "error_border" : ""
          }`}
          min={0}
          style={{
            borderRadius: "0px 2px 2px 0px",
          }}
          name={props.name || ""}
          placeholder={props.placeholder}
          value={props.value}
          onFocus={props.onFocus}
          onChange={props.onChange}
          disabled={props.disabled}
        />
      </div>
      {props.warning && <div className="text-danger">{props.warning}</div>}
      {props.error && <div className="text-danger">{renderErrors()}</div>}
    </>
  );
};

InputPhone.defaultProps = {
  placeholder: "Įveskite",
};

export default InputPhone;
