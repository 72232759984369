import React, { useState } from "react";
import TextInputModal from "../../../../TextInputModal";
import { FaInfo } from "react-icons/fa";
import styled from "styled-components";
import { Input } from "antd";
import EmailBlockEach from "./EmailBlockEach";
import { sendContractEmailTest } from "../../../../../../redux/Actions/orderContractAction";
import { useDispatch } from "react-redux";
const { TextArea } = Input;

function ContactContract({
  email,
  orderId2,
  history,
  contractId,
  contractsItems,
  certificateNr,
}) {
  const dispatch = useDispatch()
  const textariaText = `Gerb. Kliente,

Sveikiname, įsigijus pratęsto garantinio aptarnavimo sertifikatą (PPG).
Prašome iššsaugoti šį sertifikatą.`;
  const subjectText = `Užsakymas #${orderId2}. Sertifikatas ${certificateNr}`;
  const [Gavejas, setGavejas] = useState(email || "");
  const [Subject, setSubject] = useState(subjectText);
  const [Text, setText] = useState(textariaText);

  const handleSubmit = () => {
    const variablesMail = {
      contract_email: {
        receiver_email: Gavejas,
        subject: Subject,
        body: Text,
      },
    };
    dispatch(sendContractEmailTest(contractId, variablesMail))
  };
  return (
    <>
      {contractsItems.length > 0 && (
        <EmailBlock className="border rounded">
          {contractsItems.map((item, index) => (
            <EmailBlockEach key={index} item={item} />
          ))}
        </EmailBlock>
      )}
      <ContractForm className="border rounded">
        <div className="info_block">
          <FaInfo title="Informacija" size="16" style={{ color: "#fe4a49" }} />{" "}
          Prie siunčiamo email bus prisegtas sutarties failas
        </div>
        <TextInputModal
          required="true"
          text="Siuntėjas"
          placeholder="Siuntėjas"
          value="TECHNORAMA.LT <garantija@technonet.lt>"
          disabled={true}
        />
        <TextInputModal
          required="true"
          text="Gavėjas"
          placeholder="Gavėjas"
          disabled={history}
          value={Gavejas}
          onChange={(e) => setGavejas(e.target.value)}
        />
        <TextInputModal
          required="true"
          text="Subject"
          placeholder="Subject"
          disabled={history}
          value={Subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <div className="text-bold my-1">
          Tekstas <span className="orange_color">*</span>
        </div>
        <TextArea
          placeholder="Tekstas"
          value={Text}
          disabled={history}
          onChange={(e) => setText(e.target.value)}
          rows={4}
        />
        <div>
          <button
            className="btn btn-primary btn-sm"
            style={{
              marginTop: "4px",
              height: "30px!important",
              width: "100%",
              fontSize: "12px",
            }}
            onClick={() => handleSubmit()}
          >
            Siųsti
          </button>
        </div>
      </ContractForm>
    </>
  );
}

const EmailBlock = styled.div`
  border-radius: 3px;
  padding: 10px 10px;
  margin-bottom: 5px;
  background-color: rgb(248, 248, 248);
  .receiver {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
`;

const ContractForm = styled.div`
  /* border: 1px solid black; */
  border-radius: 3px;
  padding: 10px 10px;
  margin-bottom: 5px;
  background-color: rgb(248, 248, 248);

  .info_block {
    border: 1px solid #fe4a49;
    border-radius: 3px;
    padding: 7px 0;
    margin: 7px 0;
    font-size: 0.9rem;
    background-color: #fff;
  }
`;

export default ContactContract;
