import React, { Component } from "react";
import Container from "../../Layout/Container";
import TableFilterBox from "../../common/TableFilterBox";
import ContentBlock from "../../Layout/ContentBlock";
import FilterMenu from "../../Layout/FilterMenu";
import ContentValidation from "../../common/ContentValidation";
import TableColumnShipment from "./item/TableColumnShipment";
import {
  getCourrierSummary,
  getOwnTransportSummary,
  getShipmentsOwnTransport,
  removeShipmentBeforeCalled,
} from "../../../redux/Actions/shipmentsAction";
import { getPickupAddresses } from "../../../redux/Actions/pickupAddressAction";
import { connect } from "react-redux";
import InputText from "../../orders/accepted_orders/InputFilter";
import PaginationComp from "../../Dashboard/items/Pagination";
import { SelectForm } from "../../common/Buttons";
import RangePickerComp from "../../common/RangePickerComp";
import { AlertSuccess, AlertError } from "../../common/Alerts";
import { getOrder } from "../../../redux/Actions/orderAction";
import RegularOrderChildren from "../../common/GlobalModals/Orders/RegularOrderChildren";
import { formatDatePlus } from "../../../helpers/Utils";
import { InputNumber } from "antd";

class OwnTransport_Shipment extends Component {
  constructor() {
    super();
    this.state = {
      paramsFilter: "",
      requiredOrderId: 0,
      page: 1,
      pageCount: 100,
      filter: {
        date: ["", ""],
        orderId: "",
        supplier: "",
        client: "",
        comment_date: undefined,
      },
      optionArray: [
        {
          value: "",
          label: "Visi",
        },
      ],
    };
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    this.reloadData(this.state.paramsFilter);
    this.props.getPickupAddresses(1, 10000);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.reloadData(this.state.paramsFilter);
    }
    if (
      this.state.filter.date !== prevState.filter.date ||
      this.state.filter.supplier !== prevState.filter.supplier
    ) {
      this.onFilter();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pickupAddressesItems) {
      const optionArray = [...this.state.optionArray];
      nextProps.pickupAddressesItems.forEach((item) => {
        if (!optionArray.some((some) => some.label == item.code)) {
          optionArray.push({
            value: item.code,
            label: item.code,
          });
        }
      });
      this.setState({
        optionArray,
      });
    }
  }

  reloadData = (paramsFilter) => {
    this.props.getShipmentsOwnTransport(
      this.state.page,
      this.state.pageCount,
      paramsFilter
    );
  };

  handleKeyDown = (e) => {
    if (e.key == "Enter") {
      this.onFilter();
    }
  };

  filterChange = (e, date) => {
    if (date) {
      this.setState({
        filter: {
          ...this.state.filter,
          [date]: e,
        },
      });
    } else {
      this.setState({
        filter: {
          ...this.state.filter,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  setPagination = (page) => {
    this.setState({
      page,
    });
  };

  delayOnFilter = () => {
    if (this.props.loading_shipment_own_transport) {
      return setTimeout(() => {
        return this.delayOnFilter();
      }, 1000);
    }
    this.onFilter();
  };

  onFilter = () => {
    if (this.props.loading_shipment_own_transport) {
      return this.delayOnFilter();
    }
    let filterParams = [];
    // Order ID
    if (this.state.filter.orderId.length > 0) {
      let order_id_string = this.state.filter.orderId.toLowerCase();
      if (order_id_string.startsWith("r")) {
        order_id_string = `&q[service_order_of_Service_type_number_cont]=${order_id_string}`;
      } else if (order_id_string.startsWith("t")) {
        order_id_string = `&q[service_order_of_Order_type_id_matches]=${order_id_string
          .split("t")
          .join("")}&q[service_order_of_Order_type_created_cont]=užsakymai`;
      } else {
        order_id_string = `&q[service_order_of_Order_type_id_order_cont]=${order_id_string}`;
      }
      filterParams.push(order_id_string);
    }
    // Client
    if (this.state.filter.client.length > 0) {
      let clientArray = this.state.filter.client.split(" ");
      clientArray.forEach((item) => {
        filterParams.push(`&q[shipment_address_name_cont_any]=${item}`);
      });
    }

    // Date
    if (this.state.filter.date[0].length > 0)
      filterParams.push(`&q[created_at_gteq]=${this.state.filter.date[0]}`);

    if (this.state.filter.date[1].length > 0)
      filterParams.push(
        `&q[created_at_lt]=${formatDatePlus(this.state.filter.date[1])}`
      );

    // Supplier
    if (this.state.filter.supplier.length > 0)
      filterParams.push(
        `&q[pickup_address_code_cont_any]=${this.state.filter.supplier}`
      );

    // bandom
    if (typeof this.state.filter.comment_date == "number") {
      // `&q[order_status_cont]=13&q[date_filter]=${this.state.filter.comment_date}`
      filterParams.push(
        `&q[date_filter]=${
          this.state.filter.comment_date
            ? this.state.filter.comment_date - 1
            : 0
        }`
      );
    }
    this.setState(
      {
        paramsFilter: filterParams.join(""),
        page: 1,
      },
      () => this.reloadData(filterParams.join(""))
    );
  };
  clearFilter = () => {
    this.setState(
      {
        filter: {
          date: ["", ""],
          orderId: "",
          supplier: "",
          client: "",
          comment_date: undefined,
        },
      },
      () => this.onFilter()
    );
  };

  orderModal = (order_id, id) => {
    this.setState({
      requiredOrderId: order_id,
    });
    this.props.getOrder(id);
    this.modalRef.current.openModal("OrderShowModal");
  };
  render() {
    return (
      <Container
        location={{
          locationName: "Technorama atsiėmimai",
          locationUrl: this.props.match.url,
        }}
      >
        <ContentBlock>
          <FilterMenu
            length={`${this.props.totals_own_transport || 0} / ${
              this.props.shipment_own_transport.length || 0
            }`}
            onChangeCountPage={this.setPageCount}
            countPage={this.state.pageCount}
            text="technorama atsiėmimų"
            text2="Technorama atsiėmimų"
          />
        </ContentBlock>
        <ContentBlock>
          <TableFilterBox
            add={false}
            onFilter={this.onFilter}
            filter={true}
            clearFilter={() => this.clearFilter()}
            button1={
              <div>
                {/* <button
                  className="btn btn-sm float-right mr-3 btn-dark"
                  onClick={() =>
                    getCourrierSummary("at_the_store", this.state.paramsFilter)
                  }
                >
                  Ataskaita
                </button> */}
                <button
                  className="btn btn-sm float-right mr-3 btn-dark"
                  onClick={() =>
                    getOwnTransportSummary(
                      this.state.page,
                      this.state.pageCount,
                      this.state.paramsFilter
                    )
                  }
                >
                  Atsiėmimų ataskaita
                </button>
              </div>
            }
          >
            <div className="px-1">
              <div className="form-group">
                <label className="editLabelForm">Data:</label>
                <div>
                  <RangePickerComp
                    placeholder={["Pradžios laikas", "Pabaigos laikas"]}
                    // value={this.state.filter.date}
                    onChange={(e, args) => this.filterChange(args, "date")}
                  />
                </div>
              </div>
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="orderId"
                placeholder="Užsakymo nr."
                onKeyDown={this.handleKeyDown}
                value={this.state.filter.orderId}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1">
              {/* <InputText
                width={"0.75rem"}
                number="1"
                name="supplier"
                placeholder="Tiekėjas"
                onKeyDown={this.handleKeyDown}
                onChange={(e) => this.filterChange(e)}
              /> */}
              <SelectForm
                placeholder={"Tiekėjas"}
                dropdownMatchSelectWidth={false}
                value={this.state.filter.supplier}
                onChange={(e) => this.filterChange(e, "supplier")}
                options={this.state.optionArray}
              />
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="client"
                placeholder="Gavėjas"
                onKeyDown={this.handleKeyDown}
                value={this.state.filter.client}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1">
              <div className="form-group">
                <div>
                  <label className={"editLabelForm"}>{"Suruošimas iki"}</label>
                </div>
                <InputNumber
                  min={1}
                  max={100}
                  placeholder="Įvesti"
                  name="comment_date"
                  onKeyDown={this.handleKeyDown}
                  value={this.state.filter.comment_date}
                  onChange={(e) => this.filterChange(e, "comment_date")}
                />
              </div>
            </div>
          </TableFilterBox>
          <AlertSuccess alertId={"shipment-s"} />
          <AlertError alertId={"shipment-e"} />
          <div>
            <ContentValidation
              loader={this.props.loading}
              array={this.props.shipment_own_transport}
            >
              <div className="table-responsive">
                <table className="table table-sm table-hover mt-3">
                  <thead>
                    <tr className="text-muted">
                      <th className="text-black">Atsp.</th>
                      <th className="text-black">Sukurta</th>
                      <th className="text-black">Užs.nr.</th>
                      <th className="text-black">Tiekėjas</th>
                      <th className="text-black">Paketai</th>
                      <th className="text-black">Svoris</th>
                      <th className="text-black">Gavėjas</th>
                      <th className="text-black">Gavėjo adresas</th>
                      <th className="text-black">Komentaras</th>
                      <th className="text-black">Parsisiųsti</th>
                      <th className="text-black"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.shipment_own_transport.map((item, index) => {
                      return (
                        <TableColumnShipment
                          item={item}
                          key={index}
                          removeShipmentBeforeCalled={
                            this.props.removeShipmentBeforeCalled
                          }
                          orderModal={this.orderModal}
                        />
                      );
                    })}
                  </tbody>
                </table>
                <PaginationComp
                  setPagination={this.setPagination}
                  page={this.state.page}
                  totals={this.props.totals_own_transport}
                  pageCount={this.state.pageCount}
                />
              </div>
            </ContentValidation>
          </div>
        </ContentBlock>
        <div id="modal-roots" />
        <RegularOrderChildren
          ref={this.modalRef}
          requiredOrderId={this.state.requiredOrderId}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  pickupAddressesItems: state.pickupAddress.pickupAddresses,
  totals_own_transport: state.shipment.totals_own_transport,

  shipment_own_transport: state.shipment.shipment_own_transport,
  loading: state.shipment.loading,
});

export default connect(mapStateToProps, {
  getOrder,
  getShipmentsOwnTransport,
  getPickupAddresses,
  removeShipmentBeforeCalled,
})(OwnTransport_Shipment);
