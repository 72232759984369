import React from "react";
import OrderAdditionals from "../common/GlobalModals/Orders/OrderAdditional";

function ClientInfoComponent({ client, customer, order_additionals = [] }) {
  return (
    <>
      {customer?.rline && (
        <div style={{ fontSize: "0.8rem", fontWeight: "900" }}>
          <span className="text-danger">[R-Line]:</span>
          {customer.rline_code} - {customer.consultant_code}
          <br />
        </div>
      )}
      <div className="d-flex font-weight-bold">
        {order_additionals.map((d) => (
          <OrderAdditionals key={d.id} action={d.key} value={d.value} />
        ))}
        {client.firstname + " " + client.lastname}
      </div>
      {client.company && (
        <>
          <span style={{ fontSize: "0.6rem" }}>{client.company}</span>
          <br />
        </>
      )}
      {client.company_code && (
        <>
          <span style={{ fontSize: "0.55rem" }}>
            Įm. kodas: {client.company_code}
          </span>
          <br />
        </>
      )}
      {client.vat_code && (
        <>
          <span style={{ fontSize: "0.55rem" }}>PVM: {client.vat_code}</span>
          <br />
        </>
      )}
      <span style={{ fontSize: "0.6rem" }}>
        {client.other}
        <br />
        {client.phone}
      </span>
    </>
  );
}

export default ClientInfoComponent;
