import Axios from "axios";
import {
  GET_SUPPLIER,
  GET_SUPPLIERS,
  POST_SUPPLIER,
  DELETE_SUPPLIER,
  UPDATE_SUPPLIER,
  LOADING_SUPPLIER,
  CLEAR_ERRORS,
  GET_ERRORS,
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

const success = () => {
  if (!document.getElementById("supAlert-s")) return;
  document.getElementById("supAlert-s").style.display = "block";
  setTimeout(
    () => (document.getElementById("supAlert-s").style.display = "none"),
    5000
  );
  document.getElementById("modalClose").click();
};

const error = () => {
  if (!document.getElementById("supAlert-e")) return;
  document.getElementById("supAlert-e").style.display = "block";
  setTimeout(
    () => (document.getElementById("supAlert-e").style.display = "none"),
    5000
  );
};

export const postSupplier = (storeData) => (dispatch) => {
  Axios.post("/api/v1/suppliers", storeData)
    .then((res) => {
      dispatch({
        type: POST_SUPPLIER,
        payload: res.data,
      });
      dispatch(clearErrors());
      success("post");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      error();
    });
};

export const getSuppliers = (page, pageCount, filter) => (dispatch) => {
  dispatch(setSupplierLoading());
  Axios.get(`/api/v1/suppliers?page=${page}&per_page=${pageCount}${filter}`)
    .then((res) => {
      dispatch({
        type: GET_SUPPLIERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getSuppliersOption = () => {
  return Axios.get(`/api/v1/suppliers?q[is_active_true]=1`).then((res) => {
    return { data: { data: res.data.data } };
  });
};

export const getCommerceSortedSuppliersOption = () => {
  return Axios.get('/api/v1/commerce/lists/sorted_suppliers').then((res) => {
    return {data: {data: res.data}};
  });
};

export const deleteSupplier = (_id) => (dispatch) => {
  if (confirm("Ar tikrai norite ištrinti ? ")) {
    Axios.delete(`/api/v1/suppliers/${_id}`)
      .then((res) => {
        dispatch({
          type: DELETE_SUPPLIER,
          payload: _id,
        });
        success();
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        error();
      });
  }
};

export const updateSupplier = (_id, body) => (dispatch) => {
  Axios.put(`/api/v1/suppliers/${_id}`, body)
    .then((res) => {
      dispatch(clearErrors());
      dispatch({
        type: UPDATE_SUPPLIER,
        payload: res.data,
        payload_id: _id,
      });
      success("update");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const setSupplierLoading = () => {
  return {
    type: LOADING_SUPPLIER,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
