import {
  LOADING_STORE,
  GET_STORE,
  GET_STORES,
  DELETE_STORE,
  UPDATE_STORE,
  POST_STORE
} from "../Actions/types";

const initialState = {
  storeItems: [],
  totals: 0,
  loading_store: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_STORE:
      return {
        ...state,
        loading_store: true
      };
    case GET_STORES:
      return {
        ...state,
        storeItems: action.payload.data,
        totals: action.payload.totals,
        loading_store: false
      };
 
    case POST_STORE:
      let updateData = [...state.storeItems];
      updateData.push(action.payload);
      return {
        ...state,
        storeItems: updateData
      };
    case UPDATE_STORE:
      let getData = [...state.storeItems];
      const updateElementId = element => element.id === action.payload_id;
      const findIndex = getData.findIndex(updateElementId);
      getData[findIndex] = action.payload;
      return {
        ...state,
        storeItems: getData
      };
    case DELETE_STORE:
      return {
        ...state,
        storeItems: [...state.storeItems].filter(
          filterItem => filterItem.id !== action.payload
        ),
        loading_store: false
      };
    default:
      return state;
  }
}
