import React from "react";
import { ButtonEdit, ButtonDelete } from "../../../common/Buttons";

function TableColumnParticipants({ id, item, index, onEdit, onDelete, stores }) {
  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle">{item.id}</td>
      <td className="align-middle">{item.name}</td>
      <td className="align-middle">{item.email}</td>
      <td className="align-middle">{item.phone}</td>
      <td className="align-middle">{item.code}</td>
      <td className="align-middle">{item.lottery_ticket && item.lottery_ticket.prize}</td>
      <td className="align-middle">{item.lottery_ticket && item.lottery_ticket.project}</td>
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonDelete onDelete={() => onDelete(id)} />
      </td>
    </tr>
  );
}

export default TableColumnParticipants;
