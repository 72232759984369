import React from "react";
import { Switch } from "antd";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { Label } from "../global";

const SwitchComp = ({
  name,
  disabled = false,
  number,
  placeholder,
  blockSize,
  checked,
  onChange,
  checkedChildren,
  unCheckedChildren,
  inlineText,
  className = "form-group",
}) => {
  return (
    <div
      className={className}
      style={blockSize ? { width: `${blockSize}` } : {}}
    >
      {placeholder && <Label>{placeholder}</Label>}
      <div className="d-flex align-items-center">
        <Switch
          disabled={disabled}
          checkedChildren={checkedChildren || <AiOutlineCheck />}
          unCheckedChildren={unCheckedChildren || <AiOutlineClose />}
          checked={checked}
          onChange={onChange}
        />
        {inlineText && (
          <div className="text-sm text-black ml-2">{inlineText} </div>
        )}
      </div>
    </div>
  );
};

export default SwitchComp;
