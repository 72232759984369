import React, { useState, useEffect } from "react";
import {
  ButtonEdit,
  SelectForm,
  ButtonGradientAdd,
  ButtonDelete,
} from "../../../common/Buttons";
import ContentValidation from "../../../common/ContentValidation";
import ContentBlock from "../../../Layout/ContentBlock";
import Table from "../../../common/TableUi";
import InputSwitchComponent from "../../../common/InputSwitchComponent";
import { useDispatch, useSelector } from "react-redux";
import SwitchComp from "../../../common/SwitchComp";
import { IconEye } from "../../../Layout/Icons";
import ModalGuideRecommendation from "./ModalGuideRecommendation";
import {
  deleteGuideRecommendations,
  getGuideRecommendation,
  getGuidesRecommendationsShopCategories,
} from "../../../../redux/Actions/guidesRecommendationAction";
import ButtonSecondary from "../../../common/ButtonSecondary";
import { ActiveDot } from "../../../utils";

export default function GuideRecommendation({ history, setExtraShow }) {
  const dispatch = useDispatch();
  const { loading, data, current, totals } = useSelector(
    (state) => state.custom
  );

  const [landings, setLandings] = useState([]);
  const [page, setPage] = useState(1);
  const [modal, setModal] = useState(false);

  const [filter, setFilter] = useState({
    shop_category_id: undefined,
    active: "",
    current_balance: "",
  });
  const [filterString, setfilterString] = useState("");
  //
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // dispatch(getGuideBuyers());
    getGuidesRecommendationsShopCategories().then((res) => {
      if (res.success) {
        setCategories([
          { value: null, label: "Visi" },
          ...res.data.map((e) => ({ value: e.id, label: e.title })),
        ]);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(getGuideRecommendation(page, filterString));
  }, [filterString, page]);

  const onChange = (e) => {
    const { value, name } = e.target;
    const filters = { ...filter, [name]: value };
    setFilter(filters);
    onFilter(filters);
  };

  const onFilter = (filter) => {
    let filterParams = [];

    if (filter.shop_category_id) {
      filterParams.push(`&q[shop_category_id_eq]=${filter.shop_category_id}`);
    }
    if (filter.active) {
      if (filter.active == "active") {
        filterParams.push(`&q[active_true]=1`);
      } else if (filter.active == "inactive") {
        filterParams.push(`&q[active_false]=1`);
      }
    }
    if (filter.current_balance)
      filterParams.push(`&q[current_balance]=${filter.current_balance}`);

    setfilterString(filterParams.join(""));
    setPage(1);
  };

  return (
    <div>
      <ContentBlock>
        <div className={"bg-white d-flex flex-wrap pl-1 pr-1"}>
          <div className="col-12 mb-1">
            <div className="row">
              <div className="col-3 pr-1">
                <SelectForm
                  placeholder="Aktyvios kategorijos"
                  onlyPlaceholder="Pasirinkti"
                  value={filter.shop_category_id}
                  options={categories}
                  onChange={(value) =>
                    onChange({ target: { value, name: "shop_category_id" } })
                  }
                />
              </div>
              <div className="col-3 px-1">
                <InputSwitchComponent
                  touches={true}
                  label="Statusas"
                  options={[
                    {
                      label: "Visi",
                      value: "",
                    },
                    {
                      label: "Aktyvus",
                      value: "active",
                    },
                    {
                      label: "Neaktyvus",
                      value: "inactive",
                    },
                  ]}
                  name="active"
                  value={filter.active}
                  onChange={onChange}
                />
              </div>
              <div className="col-3 px-1">
                <InputSwitchComponent
                  touches={true}
                  label="Likutis"
                  options={[
                    {
                      label: "Visi",
                      value: "",
                    },
                    {
                      label: (
                        <NumberWithDot title="Pakankamai">20</NumberWithDot>
                      ),
                      value: "20",
                    },
                    {
                      label: (
                        <NumberWithDot title="Vidutiniškai">3</NumberWithDot>
                      ),
                      value: "3",
                    },
                    {
                      label: <NumberWithDot title="Mažai">0</NumberWithDot>,
                      value: "0",
                    },
                  ]}
                  name="current_balance"
                  value={filter.current_balance}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </div>
      </ContentBlock>
      <ContentBlock extraClass={"mt-2"}>
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center">
            <h4
              className="font-weight-bold mb-0"
              style={{ fontSize: 18, color: "#262626" }}
            >
              Kategorijos
            </h4>
            <span>
              <ButtonGradientAdd
                text="Pridėti kategoriją"
                onClick={() => setModal(0)}
              />
            </span>
          </div>
        </div>
        <ContentValidation loader={loading} array={data}>
          <Table
            ths={["Pavadinimas", "Top 5", "Rekomendacijos", "Statusas", ""]}
          >
            {current == "Guide buyers" &&
              data?.map((e) => (
                <Table.tr key={e.id}>
                  <Table.td className="p-left">
                  <div
                    className="text-decoration-underline cursor-pointer"
                    onClick={() => {
                      return setExtraShow({
                        id: e.id,
                        title:
                          e.shop_category?.title ||
                          "Be kategorijos pavadinimo",
                      });
                    }}
                  >
                    {e.shop_category?.title || "Be kategorijos pavadinimo"}
                  </div>
                  </Table.td>
                  <Table.td>
                    <NumberWithDot>{e.top_5 || 0}</NumberWithDot>
                  </Table.td>
                  <Table.td>
                    <NumberWithDot>{e.recommendation || 0}</NumberWithDot>
                  </Table.td>

                  <Table.td>
                    <ActiveDot active={e.active} />
                  </Table.td>
                  <Table.td className="d-flex justify-content-end p-right">
                    {/* <div
                      className="mr-2"
                      style={{
                        width: 33,
                      }}
                    >
                      <ButtonSecondary
                        outline={true}
                        onClick={() => {
                          return setExtraShow({
                            id: e.id,
                            title:
                              e.shop_category?.title ||
                              "Be kategorijos pavadinimo",
                          });
                          // return history.push(
                          //   `/marketing/guide/recomendations/${e.id}`
                          // );
                        }}
                      >
                        <IconEye />
                      </ButtonSecondary>
                    </div> */}
                    <ButtonEdit
                      onEdit={() => {
                        setModal(e.id);
                      }}
                    />
                    <div className="mx-1" />
                    <ButtonDelete
                      onDelete={() => {
                        AntdConfirm({
                          onOk: () => {
                            dispatch(deleteGuideRecommendations(e.id));
                            console.log("ok11");
                          },
                        });
                      }}
                    />
                  </Table.td>
                </Table.tr>
              ))}
          </Table>
        </ContentValidation>
      </ContentBlock>

      {typeof modal == "number" && (
        <ModalGuideRecommendation
          id={modal}
          setModal={setModal}
          landings={landings}
          setLandings={setLandings}
          onClose={() => setModal(false)}
        />
      )}
    </div>
  );
}

const NumberWithDot = ({ children, title }) => {
  let color = "#E61D01";
  if (children >= 3) color = "#FE9C00";
  if (children >= 20) color = "#10CA00";

  return (
    <div className="d-flex align-items-center">
      <div
        className="mr-2"
        style={{
          width: 7,
          height: 7,
          backgroundColor: color,
          borderRadius: "50%",
        }}
      />
      {title || children}
    </div>
  );
};

// Modalas
