import {
  GET_COMMENTSSTATE,
  POST_COMMENTSSTATE,
  DELETE_COMMENTSSTATE,
  UPDATE_COMMENTSSTATE,
  LOADING_COMMENTSSTATE
} from "../Actions/types";

const initialState = {
  comments_state: [],
  totals: 0,
  loading_comments_state: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_COMMENTSSTATE:
      return {
        ...state,
        loading_comments_state: true
      };
    case GET_COMMENTSSTATE:
      const comments_stateArray = [...action.payload.data];
      let newArray = [];
      comments_stateArray.forEach(item => {
        let newObj = {
          label: item.name,
          name: item.name,
          value: item.id,
          color: item.color,
          ordering: item.ordering,
          identifier: item.identifier,
          comment_type: item.comment_type,
          show_date: item.show_date
        };
        return newArray.push(newObj);
      });

      return {
        ...state,
        comments_state: newArray,
        totals: action.payload.totals,
        loading_comments_state: false
      };
    case POST_COMMENTSSTATE:
      let updateData = [...state.comments_state];
      let postObj = {
        label: action.payload,
        name: action.payload.name,
        value: action.payload.id,
        color: action.payload.color,
        ordering: action.payload.ordering,
        identifier: action.payload.identifier,
        comment_type: action.payload.comment_type,
        show_date: action.payload.show_date

      };
      updateData.push(postObj);
      return {
        ...state,
        comments_state: updateData
      };
    case UPDATE_COMMENTSSTATE:
      let getData = [...state.comments_state];
      const updateElementId = element => element.value === action.payload_id;
      const findIndex = getData.findIndex(updateElementId);
      let putObj = {
        label: action.payloadu,
        name: action.payload.name,
        value: action.payload.id,
        color: action.payload.color,
        ordering: action.payload.ordering,
        identifier: action.payload.identifier,
        comment_type: action.payload.comment_type,
        show_date: action.payload.show_date
      };
      getData[findIndex] = putObj;
      return {
        ...state,
        comments_state: getData
      };
    case DELETE_COMMENTSSTATE:
      return {
        ...state,
        comments_state: [...state.comments_state].filter(
          filterItem => filterItem.value !== action.payload
        ),
        loading_comments_state: false
      };
    default:
      return state;
  }
}
