import React from "react";
import Spinner from "./Spinner";
import { AlertSuccess, AlertError } from "./Alerts";

function ContentValidation(props) {
  if (props.loader) return <Spinner />;
  return (
    <>
      <AlertSuccess alertId={props.alertId_s} />
      <AlertError alertId={props.alertId_e} />
      {props.array?.length > 0 ? (
        props.children
      ) : (
        <div className="d-flex justify-content-center">Nėra duomenų...</div>
      )}
    </>
  );
}

export default ContentValidation;
