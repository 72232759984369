import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Spinner from "../../../common/Spinner";
import ContentBlock from "../../../Layout/ContentBlock";
import RivileBigBoxEditColumn from "./RivileBigBoxEditColumn";
import bigboxicon from "../../../../../assets/images/bigboxicon.svg";
import Axios from "axios";
import moment from "moment";
import {
  CheckBoxInput,
  SelectForm,
} from "../../../common/Buttons";
import { FaList } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import {
  antdMessageError,
  prettyDateFormat,
  uniqueArray,
} from "../../../../helpers/Utils";
import {
  addRivileBB,
  createRivileInternalDocs,
  rivileBigBoxExportToBB,
  sendMailTrRequest,
} from "../../../../redux/Actions/rivile/BigboxRivileAction";
import TextInputModal from "../../../common/TextInputModal";
import { AiOutlinePlus } from "react-icons/ai";
import { getStoreOptions } from "../../../../redux/Actions/storeAction";
import { Tooltip, Modal } from "antd";
import { IconExclamation } from "../../../Layout/Icons";
import AntdConfirm from "../../../../helpers/AntdConfirm";
import { getUserOptions } from "../../../../redux/Actions/userAction";
import { DropdownButton } from "../../../global";
const { confirm } = Modal;

const initialFilter = {
  tracking_status: undefined,
  in_warehouse: false,
  manager: undefined,
  department_code: undefined,
  good: "",
};
function RivileBigBoxEdit({
  bigbox,
  loading_show,
  StyledButton,
  StyledButton2,
  StyledButton3,
  onEdit,
  show_id,
  exportDocumentFunc,
}) {
  const dispatch = useDispatch();
  const [saved, setSaved] = useState([]);
  const [internal, setInternal] = useState([]);
  const [bad_ids, setBadIds] = useState([]);
  const [filters, setFilters] = useState(
    JSON.parse(localStorage.getItem("tr_request_filters")) || initialFilter
  );
  const [department_options, set_department_options] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (!filters) return
    localStorage.setItem("tr_request_filters", JSON.stringify(filters));
  }, [filters]);
  useEffect(() => {
    getUserOptions("&q[bigbox_import_true]=1").then((res) => {
      if (res.success) {
        setUsers(
          res.data
            .filter((e) => e.bigbox_import && e.firstname)
            .map((e) => ({
              value: e.firstname,
              label: e.firstname,
            }))
        );
      }
    });
    getStoreOptions(1, 240, "&q[bigbox_import_true]=1").then((res) => {
      set_department_options([
        {
          label: "Be padalinio",
          value: "without_department",
        },
        ...res.map((e) => ({
          label: `${e.rivile_code} - ${e.name || ""}`,
          value: e.rivile_code,
        })),
      ]);
    });
  }, []);

  const generateFilter = (val) => {
    const { manager, in_warehouse, tracking_status, good, department_code } =
      filters;
    const department_code_check =
      department_code && department_code?.length > 0;
    if (
      !manager &&
      !in_warehouse &&
      !tracking_status &&
      !good &&
      !department_code_check
    )
      return true;
    if (manager) {
      if (val.manager !== manager) return false;
    }
    if (good) {
      const product_title = val.bb_code?.toLowerCase() || "";
      const product_code = val.product_name?.toLowerCase() || "";
      const order_number = val.order_number?.toLowerCase() || "";
      const rivile_code = val.rivile_code?.toLowerCase() || "";
      const check_title = product_title.indexOf(good.toLowerCase()) !== -1;
      const check_code = product_code.indexOf(good.toLowerCase()) !== -1;
      const check_order_number =
        order_number.indexOf(good.toLowerCase()) !== -1;
      const check_rivile_code = rivile_code.indexOf(good.toLowerCase()) !== -1;
      const double_check =
        check_title || check_code || check_order_number || check_rivile_code;
      if (!double_check) return false;
    }
    if (in_warehouse) {
      if (val.department_name !== "Nėra" && val.department_code !== null)
        return false;
    }
    if (tracking_status) {
      if (tracking_status == "picked") {
        if (!val.picked_up_at) return false;
        if (val.delivered_at) return false;
      } else if (tracking_status == "delivered" && !val.delivered_at)
        return false;
      else if (
        tracking_status == "generated" &&
        val.courier_trackings.length == 0
      )
        return false;
      else if (
        tracking_status == "generated" &&
        val.courier_trackings.length > 0 &&
        val.picked_up_at
      )
        return false;
      else if (
        tracking_status == "assigned_store_without_tracking" &&
        val.courier_trackings.length > 0
      )
        return false;
      else if (
        tracking_status == "assigned_store_without_tracking" &&
        !val.department_code
      )
        return false;
    }
    if (
      department_code &&
      Array.isArray(department_code) &&
      department_code.length > 0
    ) {
      let deps = [...department_code];
      const without_department = department_code.includes("without_department");
      if (without_department) {
        // deps.push("");
        deps.push(null);
      }

      const department_code_available = deps.includes(val.department_code);
      if (!department_code_available) return false;
    }
    return true;
  };
  const bigbox_build = bigbox.length > 0 ? bigbox.filter(generateFilter) : [];

  useEffect(() => {
    setInternal([]);
  }, Object.values(filters));

  const updateRivileRequestOne = (id, item) => {
    Axios.put(`/api/v1/rivile_requests/${id}`, item).then(() => {
      setSaved((prev) => [...prev, id]);
      setTimeout(() => {
        setSaved((prev) => {
          prev.shift();
          return [...prev];
        });
      }, 2000);
    });
  };

  const dublicate = (id, item) => {
    dispatch(addRivileBB(id, item));
    return;
  };

  const date = useMemo(() => moment().format("YYYY-MM-DD"), []);

  const its_same_day = useMemo(() => {
    if (!bigbox) return false;
    if (bigbox?.length == 0) return false;
    return date == moment(bigbox[0].created_at).format("YYYY-MM-DD");
  }, [bigbox?.length]);

  const countSKUcodes = (arr) => {
    const unique = [...new Set(arr.map((e) => e.bb_code))];
    return unique.length;
  };

  const percentageTh = 100 / 13;

  const createInternalDocs = async () => {
    // setBadIds([]);
    const { success, bad_ids } = await dispatch(
      createRivileInternalDocs(selectedByTypes.export_intenal)
    );
    if (success) {
      setInternal([]);
      setBadIds((prev) => [...new Set([...prev, ...bad_ids])]);
    }
  };

  const all_selected = useMemo(() => {
    if (!show_id?.id) return false;
    if (bigbox_build.length == 0) return false;

    if (bigbox_build.every((d) => internal.includes(d.id))) return true;
    return false;
  }, [show_id?.id, loading_show, bigbox_build?.length, internal.length]);

  console.count("Counting times");

  const selectedByTypes = useMemo(() => {
    const with_includes = bigbox.filter((d) => internal.includes(d.id));
    // const with_includes = bigbox.filter((d) => internal.includes(d.id) && d.department_code)
    let export_intenal = [];

    if (show_id.bb_number) {
      export_intenal = with_includes
        .filter((d) => !d.inner_document_number)
        .filter((d) =>
          ["U0809", "U08091"].includes(d.department_code)
            ? true
            : !!d.courier_trackings?.length
        )
        .map((d) => d.id);
    } else {
      export_intenal = with_includes
        .filter((d) => !d.inner_document_number)
        .map((d) => d.id);
    }
    // const export_intenal = with_includes
    //   .filter((d) => !d.inner_document_number && !!d.courier_trackings?.length)
    //   .map((d) => d.id);

    const export_email = with_includes
      .filter((d) => d.department_code && d.inner_document_number)
      .filter((d) => !["U0809", "U08091"].includes(d.department_code))
      .map((d) => d.id);

    return {
      export_intenal,
      export_email,
    };
  }, [internal]);

  return (
    <div>
      <>
        <ContentBlock>
          <div className="mx-3 my-1 d-flex align-items-center justify-content-between">
            <>
              <div>
                <span className="text-black font-weight-bold">
                  {show_id.id ? (
                    <>
                      <span>
                        <img
                          src={bigboxicon}
                          className="mr-2"
                          style={{ width: "20px", height: "20px" }}
                        />
                        Nr: {"  "}B{show_id.id}{" "}
                        {show_id.bb_number ? `(${show_id.bb_number})` : null}
                      </span>
                      <span className="mx-3">
                        <FaList className="mr-2" size={20} />
                        Bendras prekių kiekis:{"  "}
                        {bigbox_build.reduce(
                          (acc, cur) => acc + cur.quantity,
                          0
                        ) || 0}
                        /
                        {bigbox.reduce((acc, cur) => acc + cur.quantity, 0) ||
                          0}
                      </span>
                      <span className="mx-3">
                        <FaList className="mr-2" size={20} />
                        Eilučių kiekis:{"  "}
                        {bigbox_build.length || 0}/{bigbox.length || 0}
                      </span>
                      <span className="mx-3">
                        <FaList className="mr-2" size={20} />
                        SKU kiekis:{"  "}
                        {countSKUcodes(bigbox_build) || 0}/
                        {countSKUcodes(bigbox) || 0}
                        {/* {bigBoxArr.reduce(
                          (acc, cur) => acc + (cur.rivile_code ? 1 : 0),
                          0
                        ) || 0} */}
                      </span>
                      <span className="mx-3">
                        <FaList className="mr-2" size={20} />
                        VID kiekis:{"  "}
                        {bigbox_build.filter((d) => d.inner_document_number)
                          .length || 0}
                        /
                        {bigbox.filter((d) => d.inner_document_number).length ||
                          0}
                      </span>
                      <span>
                        <MdDateRange className="mr-2" size={20} />
                        Sukūrimo data:{"  "}
                        {prettyDateFormat(show_id.created_at)}
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <div className="d-flex justify-content-end flex-wrap">
                <StyledButton
                  className="btn btn-sm btn-primary"
                  onClick={() => onEdit()}
                >
                  Grįžti
                </StyledButton>
                <Tooltip
                  placement="top"
                  title={
                    <div>
                      Eksportuoti dokumentą kaip{" "}
                      <span
                        className="text-orange cursor-pointer white-hover font-semi-bold ml-1"
                        onClick={() =>
                          exportDocumentFunc(
                            show_id.id,
                            "Naujas_Dok_nr_" + show_id.id + "_" + date,
                            "excel_download"
                          )
                        }
                      >
                        naują
                      </span>{" "}
                      /{" "}
                      <span
                        className="text-orange cursor-pointer white-hover font-semi-bold ml-1"
                        onClick={() =>
                          exportDocumentFunc(
                            show_id.id,
                            "Dok_nr_" + show_id.id + "_" + date
                          )
                        }
                      >
                        seną
                      </span>{" "}
                      ?
                    </div>
                  }
                  trigger="click"
                >
                  <StyledButton3 className="btn-info">
                    Eksportuoti
                  </StyledButton3>
                </Tooltip>
                <div
                  style={{
                    marginLeft: 10,
                  }}
                >
                  <DropdownButton
                    text="Masiniai veiksmai"
                    actions={[
                      {
                        label: `Kurti vidinį dokumentą (Pasirinkti ${selectedByTypes.export_intenal.length})`,
                        onFunction: () => {
                          if (selectedByTypes.export_intenal.length == 0)
                            return antdMessageError("Pasirinkite bent viena.");
                          createInternalDocs();
                        },
                      },
                      {
                        label: `Siųsti padaliniui (Pasirinkti ${selectedByTypes.export_email.length})`,
                        onFunction: () => {
                          if (selectedByTypes.export_email.length == 0)
                            return antdMessageError("Pasirinkite bent viena.");
                          const deps = uniqueArray(
                            bigbox
                              .filter((d) =>
                                selectedByTypes.export_email.includes(d.id)
                              )
                              .map(
                                (d) =>
                                  `${d.department_name}: ${d.department_code}`
                              )
                          );
                          AntdConfirm({
                            title: `Ar tikrai norite išsiųsti laiškus į padalinius? Padaliniai: ${deps}`,
                            onOk: () => {
                              sendMailTrRequest(
                                show_id.id,
                                selectedByTypes.export_email
                              );
                              console.log("ok11");
                            },
                          });
                        },
                      },
                    ]}
                  />
                </div>
                {show_id.bb_number && !show_id.exported_at && (
                  <Tooltip placement="top" title={`Eksportuoti į BIGBOX.`}>
                    <StyledButton3
                      color="#007BFF"
                      onClick={() => {
                        confirm({
                          title: "Ar tikrai norite eksportuoti?",
                          icon: <IconExclamation className="text-orange" />,
                          content: "Šio veiksmo anuliuoti negalėsite",
                          onOk() {
                            dispatch(rivileBigBoxExportToBB(show_id.id));
                            console.log("OK");
                          },
                          onCancel() {
                            console.log("Cancel");
                          },
                        });
                      }}
                    >
                      Eks.BB.
                    </StyledButton3>
                  </Tooltip>
                )}
              </div>
            </>
          </div>
        </ContentBlock>
        {!loading_show && (
          <ContentBlock>
            <div className="col-12">
              <div className="row">
                <div className="col-10 d-flex">
                  <div className="px-1">
                    <TextInputModal
                      placeholder="Prekės pavadinimas arba kodas"
                      value={filters.good}
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFilters((prev) => ({
                          ...prev,
                          good: value,
                        }));
                      }}
                    />
                  </div>
                  <div className="px-1" style={{ width: "200px" }}>
                    <SelectForm
                      style={{ width: "100%" }}
                      options={[
                        { label: "Visi vadybininkai", value: "" },
                        ...users,
                      ]}
                      onlyPlaceholder="Vadybininkas"
                      value={filters.manager || undefined}
                      onChange={(manager) =>
                        setFilters((prev) => ({ ...prev, manager }))
                      }
                    />
                  </div>
                  <div className="px-1" style={{ width: "200px" }}>
                    <SelectForm
                      onlyPlaceholder="Siuntos statusas"
                      options={[
                        {
                          value: "",
                          label: "Visi statusai",
                        },
                        {
                          value: "generated",
                          label: "Sugeneruotas",
                        },
                        {
                          value: "picked",
                          label: "Paimtas",
                        },
                        {
                          value: "delivered",
                          label: "Pristatytas",
                        },
                        {
                          value: "assigned_store_without_tracking",
                          label: "Be siuntos",
                        },
                      ]}
                      value={filters.tracking_status}
                      dropdownMatchSelectWidth={false}
                      onChange={(tracking_status) =>
                        setFilters((prev) => ({ ...prev, tracking_status }))
                      }
                    />
                  </div>
                  <div className="px-1" style={{ width: "200px" }}>
                    <SelectForm
                      onlyPlaceholder="Padaliniai"
                      mode="multiple"
                      options={department_options}
                      value={filters.department_code}
                      dropdownMatchSelectWidth={false}
                      onChange={(department_code) =>
                        setFilters((prev) => ({ ...prev, department_code }))
                      }
                    />
                  </div>
                  <div className="d-flex align-items-center px-1">
                    <CheckBoxInput
                      checked={filters.in_warehouse}
                      onChange={() =>
                        setFilters((prev) => ({
                          ...prev,
                          in_warehouse: !prev.in_warehouse,
                        }))
                      }
                      text="Nėra sandėlyje"
                    />
                  </div>
                </div>
                <div className="col-2 d-flex justify-content-end">
                  <StyledButton2
                    className="btn btn-sm btn-info"
                    onClick={() => {
                      setFilters(initialFilter);
                    }}
                  >
                    Išvalyti filtravimą
                  </StyledButton2>
                </div>
              </div>
            </div>
          </ContentBlock>
        )}
        {loading_show ? (
          <Spinner />
        ) : !show_id.finished_at ? (
          <div className="col-12">
            <div className="text-orange text-center text-xl text-medium mt-4">
              Sinchronizuojama
            </div>
          </div>
        ) : (
          <ContentBlock>
            <div
              className="table-responsive"
              style={{
                overflowX: window.innerWidth > 1350 ? "visible" : "auto",
              }}
            >
              <table className="table table-sm table-hover mt-3">
                <thead>
                  <tr className="text-muted">
                    <ThStyled width={percentageTh - 1}>
                      <CheckBoxInput
                        type="checkbox"
                        color="#f7951e"
                        checked={all_selected}
                        onChange={() => {
                          setInternal(() => {
                            if (all_selected) {
                              return [];
                            }
                            return uniqueArray(bigbox_build.map((d) => d.id));
                          });
                        }}
                      />
                    </ThStyled>
                    <ThStyled width={percentageTh - 1}>Užs. Nr.</ThStyled>
                    <ThStyled width={percentageTh - 1}>Siun. Kiekis</ThStyled>
                    <ThStyled width={percentageTh - 1}>BB kodas</ThStyled>
                    <ThStyled width={percentageTh + 20}>
                      Prekės pavadinimas
                    </ThStyled>
                    <ThStyled className="text-center" width={percentageTh - 4}>
                      Grž
                    </ThStyled>
                    <ThStyled width={percentageTh}>Vadybininkas</ThStyled>
                    <ThStyled width={percentageTh + 4}>
                      Admin komentaras
                    </ThStyled>
                    <ThStyled width={percentageTh + 4}>
                      Vadybos komentaras
                    </ThStyled>
                    <ThStyled width={percentageTh + 4}>Lipdukas</ThStyled>
                    <ThStyled
                      width={percentageTh - 2}
                      className="pr-2"
                      style={{ textAlign: "center" }}
                    >
                      {bigbox?.length > 0 &&
                        its_same_day &&
                        !show_id.exported_at && (
                          <AiOutlinePlus
                            style={{ width: 25, height: 25 }}
                            title="Naujas"
                            className="cursor-pointer text-success grow"
                            onClick={() => {
                              dublicate(0, {
                                admin_comment: "",
                                bb_code: "",
                                department_code: "",
                                department_name: "",
                                manager: "",
                                order_number: "",
                                prime_cost: 0,
                                product_name: "",
                                quantity: 0,
                                returned: false,
                                rivile_code: "",
                                stock_quantity: 0,
                                tr_comment: "",
                              });
                            }}
                            size={"20px"}
                          />
                        )}
                    </ThStyled>
                  </tr>
                </thead>
                <tbody>
                  {/* Add here useMemo */}
                  {bigbox_build.map((item) => {
                    const selected_internal = internal.includes(item.id);
                    return (
                      <RivileBigBoxEditColumnMemo
                        key={`rivile_item_${item.id}`}
                        i={item}
                        its_bb={show_id.bb_number}
                        users={users}
                        dublicate={(values) => dublicate(item.id, values)}
                        its_saved={saved.includes(item.id)}
                        its_same_day={its_same_day}
                        exported_at={!!show_id.exported_at}
                        updateRivileRequestOne={updateRivileRequestOne}
                        selected_internal={selected_internal}
                        selected_bad_id={
                          !item.inner_document_number &&
                          bad_ids.includes(item.id)
                        }
                        set_selected_internal={() =>
                          setInternal((prev) => {
                            if (prev.includes(item.id)) {
                              return prev.filter((it) => it !== item.id);
                            }
                            return [...prev, item.id];
                          })
                        }
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </ContentBlock>
        )}
      </>
    </div>
  );
}

const RivileBigBoxEditColumnMemo = (props) =>
  useMemo(
    () => <RivileBigBoxEditColumn {...props} />,
    [
      props.i.created_at,
      props.i.updated_at,
      props.i.identifier,
      props.i.inner_document_number,
      props.selected_internal,
      props.selected_bad_id,
      props.its_saved,
      props.users.length,
    ]
  );

export default RivileBigBoxEdit;
const ThStyled = styled.th`
  font-size: 13px;
  color: #000;
  width: ${(props) => props.width + "%"};
  background-color: #fff;
  position: sticky !important;
  top: 0;
  z-index: 2;
`;
