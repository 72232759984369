import React from "react";
import { FlopppyButton, FlopppyButtonCancel } from "./ButtonWithLoading";
import { Select } from "antd";
const { Option } = Select;

const FormWithMultipleInput = ({
  options,
  value,
  onChange,
  loading,
  onClose,
  onSave,
}) => {
  return (
    <div className="d-flex">
      <Select
        className="mr-1"
        dropdownMatchSelectWidth={true}
        placeholder="Pasirinkti"
        style={{ minWidth: 181 }}
        // placeholder="Įvesti"
        value={value || undefined}
        onChange={onChange}
      >
        {options.map((item, index) => (
          <Option key={index} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
      <FlopppyButton className="ml-1" loading={loading} onClick={onSave} />
      {onClose && (
        <FlopppyButtonCancel
          className={`ml-1 ${loading && "invisible"}`}
          onClick={onClose}
        />
      )}
    </div>
  );
};

export default FormWithMultipleInput;
