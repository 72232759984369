import { GET_REPRICINGS, LOADING_REPRICINGS, LOADING_REPRICING, GET_REPRICING} from "../Actions/types";

const initialState = {
  repricings: [],
  repricing: {},
  totals: 0,
  loading_repricings: false,
  loading_repricing: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_REPRICINGS:
      return {
        ...state,
        loading_repricings: true,
      };
    case LOADING_REPRICING:
      return {
        ...state,
        loading_repricing: true,
      };
    case GET_REPRICINGS:
      return {
        ...state,
        repricings: action.payload.data,
        last_updated: action.payload.last_update_at,
        totals: action.payload.totals,
        loading_repricings: false,
      };
    case GET_REPRICING:
        return {
          ...state,
          repricing: action.payload.data,
          loading_repricing: false,
        };
    default:
      return state;
  }
}
