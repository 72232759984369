import React from "react";
import { Tooltip } from "antd";
import { IconDot } from "../Layout/Icons";

export default function ActiveDot({ active }) {
  return (
    <Tooltip placement="top" title={active ? "Aktyvus" : "Neaktyvus"}>
      <IconDot
        style={{
          color: active ? "green" : "red",
        }}
      />
    </Tooltip>
  );
}
