import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonDelete,
  ButtonEdit,
  ButtonGradientAdd,
  CheckBoxInput,
} from "../../../../components/common/Buttons";
import ContentValidation from "../../../../components/common/ContentValidation";
import InputSwitchComponent from "../../../../components/common/InputSwitchComponent";
import SwitchComp from "../../../../components/common/SwitchComp";
import TableFilterBox from "../../../../components/common/TableFilterBox";
import ContentBlock from "../../../../components/Layout/ContentBlock";
import { onChangeUseState } from "../../../../helpers/GlobalFunctions";
import TableUi from "../../../../components/common/TableUi";
import {
  deleteGuideRecommendationItem,
  getGuideRecommendationShow,
  postGuideRecommendationItem,
  putGuideProductPostions,
  setterGuideRecommendationItemToReady,
} from "../../../../redux/Actions/guidesRecommendationAction";
import Spinner from "../../../../components/common/Spinner";
import ModalSpecial from "../../../../components/Layout/Modals/ModalSpecial";
import useFetch from "../../../../helpers/useFetch";
import WYSIWYG from "../../../../components/Marketing/Guide/GuideBuyersGuides/WYSIWYG";
import Input from "antd/lib/input/Input";
import AntdConfirm from "../../../../helpers/AntdConfirm";
import { IconCopy, IconEdit, IconTrash } from "../../../../components/Layout/Icons";
import DoubleButton from "../../../../components/Marketing/Guide/GuideBuyersGuides/DoubleButton";
import { copyToClipboard, errorHandler } from "../../../../helpers/Utils";
import { DebounceSelect, DropdownRounded } from "../../../../components/common";
import Axios from "axios";
import TextInputModal from "../../../../components/common/TextInputModal";
import useErrors from "../../../../hooks/useErrors";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DraggableSvg from "/app/assets/images/draggable.svg";
import { ActiveDot } from "../../../utils";

const filtersInitial = {
  product_code: "",
  product_title: "",
  description: "",
  current_balance: "",
};
export default function GuideRecommendationShow({ match, history, id }) {
  const dispatch = useDispatch();
  const { loading, show_data } = useSelector((state) => state.custom);

  const [filters, setFilters] = useState(filtersInitial);
  const [type, setType] = useState("top_5");
  const [selected, setChecked] = useState([]);

  const [itemSelected, setItemSelected] = useState(false);

  const startedPack = () => {
    dispatch(getGuideRecommendationShow(id, onFilter()));
  };
  useEffect(() => {
    startedPack();
  }, []);

  const onFilter = () => {
    const { product_code, product_title, description, current_balance } =
      filters;

    let search_string = "";

    if (product_code)
      search_string += `&q[product_code_cont_any]=${product_code}`;
    if (product_title)
      search_string += `&q[product_title_cont_any]=${product_title}`;
    if (description) search_string += `&q[ready_true]=${description}`;

    if (current_balance)
      search_string = search_string + `&q[current_balance]=${current_balance}`;

    setChecked([]);
    return search_string;
  };

  const data = useMemo(() => {
    if (!show_data?.guide_product_items) return [];

    return show_data.guide_product_items.filter((val) => {
      if (val.item_type !== type) return false;
      const product_title = val.product_title?.toLowerCase() || "";
      if (
        filters.product_title &&
        product_title.indexOf(filters.product_title.toLowerCase()) == -1
      )
        return false;
      const product_code = val.product_code?.toLowerCase() || "";
      if (
        filters.product_code &&
        product_code.indexOf(filters.product_code.toLowerCase()) == -1
      )
        return false;

      if (filters.description !== "") {
        if (val.ready && filters.description == "0") return false;
        if (!val.ready && filters.description == "1") return false;
      }
      if (filters.current_balance !== "") {
        if (filters.current_balance == "0") {
          if (val.current_balance >= 10) return false;
        }
        if (filters.current_balance == "10") {
          if (val.current_balance < 10) return false;
        }
      }
      return true;
    });
  }, [
    ...Object.values(filters),
    type,
    show_data?.guide_product_items,
    itemSelected,
  ]);

  const all_checked = useMemo(() => {
    if (loading) return false;
    if (selected.length == 0) return false;
    if (data.every((d) => selected.includes(d.id))) return true;
    return false;
  }, [loading, selected?.length, data?.length]);

  // dragable functions

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const onDragEnd = (result) => {
    console.log({ result });
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const draggable_arr = reorder(
      data,
      result.source.index,
      result.destination.index
    );
    const updated_array = draggable_arr.map((e, idx) => ({
      ...e,
      position: idx + 1,
    }));

    console.log({ updated_array });
    dispatch(putGuideProductPostions(updated_array));
    // putLandingTypes(updated_array);
  };

  return (
    <>
      <ContentBlock>
        <TableFilterBox clearFilter={() => setFilters(filtersInitial)}>
          <div className="col-2 pr-1">
            <TextInputModal
              text="Kodas"
              name="product_code"
              value={filters.product_code}
              onChange={(value) => onChangeUseState(value, setFilters)}
            />
          </div>
          <div className="col-2 px-1">
            <TextInputModal
              text="Pavadinimas"
              name="product_title"
              value={filters.product_title}
              onChange={(value) => onChangeUseState(value, setFilters)}
            />
          </div>
          <div
            className="col-3 px-1"
            style={{
              width: 180,
            }}
          >
            <InputSwitchComponent
              name="description"
              label="Aprašymas"
              options={[
                {
                  value: "",
                  label: "Visi",
                },
                {
                  value: "1",
                  label: "Paruoštas",
                },
                {
                  value: "0",
                  label: "Neparuoštas",
                },
              ]}
              value={filters.description}
              onChange={(value) => onChangeUseState(value, setFilters)}
            />
          </div>
          <div
            className="col-3 px-1"
            style={{
              width: 180,
            }}
          >
            <InputSwitchComponent
              name="current_balance"
              label="Likutis"
              options={[
                {
                  value: "",
                  label: "Visi",
                },
                {
                  value: "10",
                  label: "Yra",
                },
                {
                  value: "0",
                  label: "Nėra",
                },
              ]}
              value={filters.current_balance}
              onChange={(value) => onChangeUseState(value, setFilters)}
            />
          </div>
        </TableFilterBox>
      </ContentBlock>
      <div
        className="col-12 mt-3"
        style={{
          marginBottom: "-1px",
        }}
      >
        <div
          style={{
            width: 220,
          }}
        >
          <InputSwitchComponent
            name="type"
            options={[
              {
                value: "top_5",
                label: "TOP 5",
              },
              {
                value: "recommendation",
                label: "Rekomendacijos",
              },
            ]}
            value={type}
            onChange={({ target: { value } }) => setType(value)}
          />
        </div>
      </div>
      <ContentBlock
        style_children={{
          borderTop: "1px solid #FE9C00",
          borderRadius: "0px 4px 4px 4px",
        }}
      >
        <div>
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center">
              <h4
                className="font-weight-bold mb-0"
                style={{ fontSize: 18, color: "#262626" }}
              >
                {show_data?.shop_category?.title || "OOPS!!"}
              </h4>
              <div className="d-flex align-items-center">
                <DropdownRounded
                  text="Veiksmai pažymietiems"
                  actions={[
                    {
                      label: "Paversti paruoštais",
                      onFunction: () => {
                        AntdConfirm({
                          title: "Ar tikrai norite paversti paruoštu?",
                          onOk: () => {
                            dispatch(
                              setterGuideRecommendationItemToReady({
                                ids: selected,
                                ready: true,
                              })
                            );
                            console.log("ok11");
                          },
                        });
                      },
                    },
                    {
                      label: "Paversti neparuoštais",
                      onFunction: () => {
                        AntdConfirm({
                          title: "Ar tikrai norite paversti neparuoštu?",
                          onOk: () => {
                            dispatch(
                              setterGuideRecommendationItemToReady({
                                ids: selected,
                                ready: false,
                              })
                            );
                            console.log("ok11");
                          },
                        });
                      },
                    },
                  ]}
                />
                <div className="mx-2" />
                <ButtonGradientAdd
                  text="Pridėti prekę"
                  onClick={() => setItemSelected(0)}
                />
              </div>
            </div>
          </div>

          <ContentValidation loader={loading} array={data}>
            <TableUi
              without_body={true}
              ths={[
                <div className="d-flex">
                  <div
                    className="d-flex align-items-center"
                    style={{ width: 50, visibility: "hidden" }}
                  >
                    <img src={DraggableSvg} />
                  </div>
                  <CheckBoxInput
                    checked={all_checked}
                    onChange={() => {
                      setChecked((prev) => {
                        let obj = [...prev];
                        const all_data = [];
                        data.forEach((d) => all_data.push(d.id));
                        if (all_checked) {
                          return prev.filter((d) => !all_data.includes(d));
                        }
                        return [...new Set([...obj, ...all_data])];
                      });
                    }}
                  />
                </div>,
                "Kodas",
                "Pavadinimas",
                "Paruoštas",
                "Likutis",
                "",
              ]}
            >
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {data.map((e, index) => {
                        const checked_boolean = selected.includes(e.id);

                        return (
                          <Draggable
                            key={e.id}
                            draggableId={`${e.id}-id`}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <tr
                                className={`align-middle tableColumn custom_draggable ${
                                  snapshot.isDragging ? "active bg-white" : ""
                                }`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <TableUi.td className="p-left">
                                  <div className="d-flex">
                                    <div
                                      className="d-flex align-items-center"
                                      style={{ width: 50 }}
                                    >
                                      <img src={DraggableSvg} />
                                    </div>
                                    <CheckBoxInput
                                      onChange={() => {
                                        setChecked((prev) => {
                                          if (checked_boolean)
                                            return prev.filter(
                                              (pr) => pr !== e.id
                                            );
                                          return [...new Set([...prev, e.id])];
                                        });
                                      }}
                                      checked={checked_boolean}
                                    />
                                  </div>
                                </TableUi.td>
                                <TableUi.td>
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => {
                                      copyToClipboard(e.product_code);
                                    }}
                                  >
                                    {e.product_code}
                                    <IconCopy className="text-sm cursor-pointer ml-2" />
                                  </div>
                                </TableUi.td>
                                <TableUi.td>{e.product_title}</TableUi.td>
                                <TableUi.td>
                                  <ActiveDot active={e.ready} />
                                </TableUi.td>
                                <TableUi.td>
                                  {e.current_balance || "0"}
                                </TableUi.td>
                                <TableUi.td className="p-right">
                                  <div className="d-flex justify-content-end">
                                    <ButtonEdit
                                      onEdit={() => {
                                        setItemSelected(e.id);
                                      }}
                                    />
                                    <div className="mx-1" />
                                    <ButtonDelete
                                      onDelete={() => {
                                        AntdConfirm({
                                          onOk: () => {
                                            dispatch(
                                              deleteGuideRecommendationItem(
                                                e.id
                                              )
                                            );
                                            console.log("ok11");
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                </TableUi.td>
                              </tr>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </TableUi>
          </ContentValidation>
        </div>
      </ContentBlock>
      {typeof itemSelected == "number" && (
        <GuideBuyersGuidesModal
          id={itemSelected}
          onClose={() => setItemSelected(false)}
          item_type={type}
        />
      )}
    </>
  );
}

const GuideBuyersGuidesModal = ({ id, onClose, item_type }) => {
  const [editEffect, setEditEffect] = useState(false);
  const { errors, setErrors } = useErrors();
  // redux
  const dispatch = useDispatch();
  const guide_product_id = useSelector((state) => state.custom.show_data.id);
  // fetch

  const { value, setValue, loading, error } = useFetch(
    `/api/v1/guide_product_items/${id == 0 ? "new" : id}`,
    {},
    []
  );

  // effect
  useEffect(() => {
    if (value?.id) setEditEffect(true);
  }, [value?.id]);

  const type = id == 0 ? "create" : "update";

  const onChangeState = (e) => {
    const { name, value } = e.target;
    setValue((prevProps) => ({ ...prevProps, [name]: value }));
  };
  //

  //

  const onSave = async () => {
    console.error("REQUEST STARTED");

    try {
      const { success, errors } = await dispatch(
        postGuideRecommendationItem({
          ...value,
          guide_product_id,
          item_type,
        })
      );

      if (success) {
        onClose();
      } else {
        setErrors(errors);
      }
    } catch (err) {
      console.log({ err });
    }
  };

  return (
    <ModalSpecial
      style={{ maxWidth: 600 }}
      text={
        !editEffect ? (
          <span className="text-black text-xl">
            {type == "create" ? "Pridėti prekę" : "Redaguoti prekę"}
          </span>
        ) : (
          <div className="text-black text-xl">
            <div>
              {value.product_title}
              <IconEdit onClick={() => setEditEffect(false)} />
            </div>
            <div
              className="text-xs"
              style={{
                color: "#A0A0A0",
              }}
            >
              {value.product_code}
            </div>
          </div>
        )
      }
      onClose={() => {
        return onClose({});
      }}
    >
      {error ? (
        <div>{JSON.stringify(error)}</div>
      ) : loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100%" }}
        >
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="row">
            {!editEffect && (
              <>
                <div className="col-6">
                  <DebounceSelect
                    labelInValue={false}
                    label="Kodas"
                    showSearch={true}
                    value={value.product_code || undefined}
                    placeholder={"Type to search"}
                    fetchOptions={async (value) => {
                      try {
                        console.log("call");
                        // imti tik esancius
                        let api_url = `/api/v1/products/simple_products.json?q[title_or_identifier_cont]=${value}`;

                        const { data } = await Axios.get(api_url);
                        if (!data?.data) return [];
                        return (
                          data.data.map((e) => ({
                            ...e,
                            value: e.identifier,
                            label: `${e.identifier} - ${e.title}`,
                          })) || []
                        );
                      } catch (err) {
                        errorHandler(err);
                        return [];
                      }
                    }}
                    onChange={(_, val) => {
                      setValue((prev) => ({
                        ...prev,
                        product_title: val.title,
                        product_code: val.value,
                      }));
                    }}
                    style={{
                      width: "100%",
                    }}
                    dropdownMatchSelectWidth={true}
                    error={errors.product_code}
                  />
                </div>

                <div className="col-6">
                  <TextInputModal
                    text="Pavadinimas"
                    name="product_title"
                    value={value.product_title}
                    onChange={onChangeState}
                  />
                </div>
              </>
            )}

            <div className="col-12">
              <WYSIWYG
                label={"Aprašymas"}
                value={value.description}
                onChange={onChangeState}
              />
            </div>
            <div
              className="col-6"
              style={{
                borderRight: "1px solid #D9D9D9",
              }}
            >
              <BucketOfNotes
                label="Privalumai"
                array={value.advantages}
                setArray={(value) =>
                  onChangeState({ target: { name: "advantages", value } })
                }
              />
            </div>
            <div className="col-6">
              <BucketOfNotes
                label="Trūkumai"
                array={value.cons}
                setArray={(value) =>
                  onChangeState({ target: { name: "cons", value } })
                }
              />
            </div>
            <div className="col-6 d-flex align-items-center mt-4">
              <SwitchComp
                className=" "
                checkedChildren="I"
                unCheckedChildren="0"
                inlineText="Paruoštas"
                checked={value.ready}
                onChange={() =>
                  onChangeState({
                    target: {
                      name: "ready",
                      value: !value.ready,
                    },
                  })
                }
              />
            </div>
            <div className="col-6 mt-4">
              <DoubleButton
                showRemove={type == "update"}
                onBack={() => {
                  AntdConfirm({
                    onOk: () => {
                      dispatch(deleteGuideRecommendationItem(value.id)).then(
                        (res) => {
                          if (res.success) {
                            onClose();
                          }
                        }
                      );
                      console.log("ok11");
                    },
                  });
                }}
                onSave={onSave}
              />
            </div>
          </div>
        </div>
      )}
    </ModalSpecial>
  );
};

const BucketOfNotes = ({ label, array, setArray }) => {
  const handleAdd = () => {
    setArray([
      ...array,
      {
        note: "",
      },
    ]);
  };
  const handleRemove = (idx) => {
    let obj = [...array];
    obj.splice(idx);
    setArray(obj);
  };

  const handleEdit = (idx, val) => {
    let obj = [...array];
    obj[idx].note = val;
    setArray(obj);
  };
  const button_string =
    label == "Privalumai" ? "Pridėti privalumą" : "Pridėti trūkumą";
  return (
    <div>
      <label className="text-bold my-1">{label}</label>

      {array.length === 0 ? (
        <div
          className="d-flex align-items-center justify-content-between"
          style={{
            background: "#FFFFFF",
            border: "1px solid #EBEBEB",
            borderRadius: 4,
            padding: "14px 12px",
          }}
        >
          <div>Kolkas nėra jokių reikšmių</div>
          <div>
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={handleAdd}
            >
              {button_string}
            </button>
          </div>
        </div>
      ) : (
        <div>
          {array.map((d, idx) => (
            <div className="d-flex align-items-center mb-2">
              <Input
                value={d.note}
                onChange={(e) => handleEdit(idx, e.target.value)}
              />

              <IconTrash
                className="text-xl cursor-pointer ml-2"
                onClick={() => handleRemove(idx)}
              />
            </div>
          ))}
          <button
            className="btn btn-sm btn-outline-primary my-2"
            onClick={handleAdd}
          >
            {button_string}
          </button>
        </div>
      )}
    </div>
  );
};
