import React from "react";
import InputText from "../../common/InputText";
import SelectInput from "../../common/SelectInput";
import ModalToChange from "../../Layout/ModalToChange";
import FamilyAttributeList from "./FamilyAttributeList";
import { connect } from "react-redux";
import {
  getFamily,
  clearErrors
} from "../../../redux/Actions/familyAction";
import Spinner from "../../common/Spinner";
import {
  SelectForm,
} from "../../common/Buttons";


class FamilyEditModal extends React.Component {
  constructor() {
    super();
    this.state = {
      id: 0,
      item: {},
      counter: 0,
      maxAttributes: -1
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.action === "edit" && nextProps.familyId !== this.state.id) {
      this.props.getFamily(nextProps.familyId);
      this.setState({
        id: nextProps.familyId
      });
      //this.removeErrors(nextProps.errors);
    }
    
    if (nextProps.action === "edit" && nextProps.item && nextProps.item !== this.state.item) {
      let item = nextProps.item;
      if (item.marked) {
        item.type = "marked";
      }
      if (item.printed) {
        item.type = "printed";
      }
      //map priorities
        item.family_attribute_groups.map((i, index) => {
          let temp = item.family_priorities.find(element=> element.family_attribute_id === i.family_attribute_id);
          if(temp) {
            i.priority = temp.priority;
            i.synonim = temp.synonim;
            i.enabled = temp.active;
            i.custom_attributes = temp.custom_attributes ? temp.custom_attributes : [];
          }
          return i;
        });
        item.family_attribute_groups.sort((a, b) => a.priority > b.priority ? 1 : -1);
      this.setState({
        item: item
      });
      //this.removeErrors(nextProps.errors);
    }
  }

  removeErrors = (errors) => {
    if (Object.keys(errors).length > 0) {
      this.props.clearErrors();
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    let item = this.state.item;
    let selected_attributes = [];
    item.family_attribute_groups.map((i, index) => {
        let data = {
          family_attribute_id: i.family_attribute_id ? i.family_attribute_id : null, 
          synonim: i.synonim ? i.synonim : "", 
          priority: index,
          active: (i.enabled) ? true : false
        };
        if (i.temp_id || i.custom === true) { 
          data.custom = true;
          data.custom_attributes = i.custom_attributes ? i.custom_attributes : [];
          data.active = i.enabled;
        } else {
          data.custom = false;
        }
        selected_attributes.push(data);
    });
    item.family_priorities = selected_attributes;
      const variables = {
        family: item
      };
      if (this.props.action === "edit") {
        this.props.updateFamily(this.state.id, variables);
      }
  };

  typeChange = (e) => {
    let item = this.state.item;
    item.type = e.value;
    if (item.type === "marked") {
      item.marked = true;
      item.printed = false;
    } else if (item.type === "printed") {
      item.marked = false;
      item.printed = true;
    }
    this.setState(
      {
        item: item
      }
    );
    this.updateMaxAttributesCount(this.state.item.format_id);
  };

  typeFilterClass = (type) => {
    if (type === this.state.item.type) {
      return " active";
    }
    return "";
  };

  featureChange = (e) => {
    let item = this.state.item;
    item.feature = e.value;
    this.setState(
      {
        item: item
      }
    );
  };

  featureFilterClass = (feature) => {
    if (feature === this.state.item.feature) {
      return " active";
    }
    return "";
  };

 inputChange = (e) => {
    this.setState({
      item: {
        ...this.state.item,
        [e.target.name]: e.target.value,
      },
    });
  };

  selectChange = (value, name) => {
    this.setState({
      item: {
        ...this.state.item,
        [name]: value,
      },
    });
    if (name === "format_id") {
      this.updateMaxAttributesCount(value);
    }
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    let items = this.reorder(
      this.state.item.family_attribute_groups,
      result.source.index,
      result.destination.index
    );
    
    this.setState({
      item: {
        ...this.state.item,
        family_attribute_groups: items,
      }
    });
  }

  updateMaxAttributesCount = (id) => {
    let max_attributes = -1;
    if (this.state.item.format_id && this.state.item.printed === true) {
      let filtered = this.state.item.print_formats.find(item => item.id === id);
      if (filtered) {
        max_attributes = filtered.attribute_qnt ? filtered.attribute_qnt : -1;
      }
    }
    this.setState(
      {
        maxAttributes: max_attributes
      }
    );
  };

  onAttributeUpdate = (id, e, type = "") => {
    let items = false;
    if (e.target && e.target.type === 'checkbox') {
      items = this.state.item.family_attribute_groups.map(el => (el.family_attribute_id === id || el.temp_id === id ? {...el, enabled : e.target.checked}: el));
    }

    if (e.target && e.target.type === 'text') {
      items = this.state.item.family_attribute_groups.map(el => (el.family_attribute_id === id || el.temp_id === id ? {...el, [e.target.name] : e.target.value}: el));
    }
    if (type === 'attributes') {
      items = this.state.item.family_attribute_groups.map(el => (el.family_attribute_id === id || el.temp_id === id ? {...el, custom_attributes: e }: el));
    }
    if ( items !== false) {
      this.setState({
        item: {
          ...this.state.item,
          family_attribute_groups: items
        }
      });
    }
  }

  addCustomAttribute = () => {
    let attr = {
      temp_id: "new-custom-" + this.state.counter,
      type: "custom",
      enabled: false
    }
    this.setState({
      counter: this.state.counter + 1
    });
    this.state.item.family_attribute_groups.push(attr);
  }

  onAttributeDelete = (id) => {
    let items = this.state.item.family_attribute_groups.filter(x => { return x.family_attribute_id != id && x.temp_id != id; });
    this.setState({
      item: {
        ...this.state.item,
        family_attribute_groups: items
      }
    });
  }


  render() {
    return (
      <ModalToChange
        handleSave={(e) => this.onSubmit(e)}
        status="tableEdit"
        text={this.state.item.name}
        buttonText={"Išsaugoti"}
        className = {"wide " + (this.props.loading_family ? "loading ": "")}
      >
        <div className = {(this.props.loading_family ? "d-none ": "")}>
        <div className="row mt-2">
          <div className="col-6">
          <InputText
            width={"0.75rem"}
            number="1"
            placeholder="Kategorijos sinonimas"
            name="synonim"
            value={this.state.item.synonim === null ? "" : this.state.item.synonim}
            onChange={(e) => this.inputChange(e)}
          />
          </div>
          <div className="col-6">
          <div className="form-group">
                    <div>
                      <label className={"editLabelForm"}>Tipas</label>
                    </div>
                    <div className="d-flex">
                      <button className={"btn btn-family-filter" + this.typeFilterClass("printed") }
                        onClick={() => this.typeChange({name:"type", value:"printed"})}
                        >
                        Spausdinamos
                      </button>
                      <button className={"btn btn-family-filter" + this.typeFilterClass("marked") }
                        onClick={() => this.typeChange({name:"type", value:"marked"})}
                        >
                        Markiruojamos
                      </button>
                    </div>
                  </div>
          </div>
        </div>
        { (this.state.item.type === "printed") && <>
        <div className="card mb-2 mt-2">
          <div className="card-body p-3">
            <div className="card-border-title">Spausdinimo savybės</div>
            <div className = "row">
              <div className="col-6">
                <div className = "row">
                  <div className="col-6">
                    <SelectInput 
                      label = "Laikiklio dydis"
                      labelClass = "fs-12"
                      value = {this.state.item.holder_id}
                      options = {this.state.item.holder_formats && this.state.item.holder_formats.map(n => ({value: n.id, label: n.hold_size}) )}
                      onChange={(e) => this.selectChange(e, "holder_id")}
                    />
                  </div>
                  <div className="col-6">
                    <SelectInput 
                      label = "Formatas"
                      labelClass = "fs-12"
                      value = {this.state.item.format_id}
                      options = {this.state.item.print_formats && this.state.item.print_formats.filter(n => n.print_type === "paper").map(n => ({value: n.id, label: n.print_size}) )}
                      onChange={(e) => this.selectChange(e, "format_id")}
                    />
                  </div>
                </div>
              </div>
            <div className="col-6">
              <div className="form-group mb-0">
                        <div>
                          <label className={"editLabelForm"}>Savybė</label>
                        </div>
                        <div className="d-flex">
                          <button className={"btn btn-family-filter" + this.featureFilterClass("laidable") }
                            onClick={() => this.featureChange({name:"feature", value:"laidable"})}
                            >
                            Paguldoma
                          </button>
                          <button className={"btn btn-family-filter" + this.featureFilterClass("standable") }
                            onClick={() => this.featureChange({name:"feature", value:"standable"})}
                            >
                            Pastatoma
                          </button>
                          <button className={"btn btn-family-filter" + this.featureFilterClass("hinged") }
                            onClick={() => this.featureChange({name:"feature", value:"hinged"})}
                            >
                            Pakabinama
                          </button>
                        </div>
                      </div>
              </div>
            </div>
          </div>
        </div>
        <div className = "row">
          <div className = "col-12 small-label">
            <SelectForm
                  placeholder={["Padaliniai ", <small key = "padaliniai-sub">(popierinės kainos)</small>]}
                  mode="multiple"
                  onlyPlaceholder="Pasirinkti"
                  value={this.state.item.stores !== null ? this.state.item.stores : []}
                  options={this.props.stores.map(n => ({value: n.id, label: n.name}) )}
                  onChange={(e) => this.selectChange(e, "stores")}
                />
          </div>
        </div>
        </>
        }
        <hr/>
        <div className = "row">
          <div className = "col-12">
            <FamilyAttributeList
              items = {this.state.item.family_attribute_groups ? this.state.item.family_attribute_groups : []}
              onDragEnd={this.onDragEnd}
              onAttributeUpdate={this.onAttributeUpdate}
              addCustomAttribute={this.addCustomAttribute}
              onAttributeDelete={this.onAttributeDelete}
              maxAttributes={this.state.maxAttributes}
            />
          </div>
        </div>
        </div>
      <div className = "modal-loader">
        <Spinner/>
      </div>
      </ModalToChange>
    );
  }
}

const mapStateToProps = (state) => ({
  item: state.family.family,
  loading_family: state.family.loading_family,
});

export default  connect(mapStateToProps, {
  getFamily,
  clearErrors
})(FamilyEditModal);
