import React, { useState } from "react";
import { successToast, unsuccessToast } from "../../../../helpers/ErrorsToasts";
import {
  postLanding,
  putLanding,
} from "../../../../redux/Actions/landingAction";
import NewLanding from "./GuideFirstModal";

export default function GuideFirstModalWrapper({
  modal,
  setModal,
  landings,
  setLandings,
}) {
  const [oldData, setOldData] = useState({});

  const saveLanding = async (id, landing) => {
    try {
      if (id) {
        let { data, success } = await putLanding(id, { landing });
        if (success) {
          setModal(false);
          let old_landings = [...landings];
          const findIndex = old_landings.findIndex((e) => e.id == data.id);
          if (findIndex !== -1) {
            old_landings[findIndex] = data;
          }
          setModal(false);
          successToast("landing-s");
          setOldData({});
          return setLandings(old_landings);
        }
        throw new Error("Err");
      } else {
        let { data, success } = await postLanding({ landing });
        if (success) {
          setModal(false);
          successToast("landing-s");
          setOldData({});
          return setLandings([data, ...landings]);
        }
        throw new Error("Err");
      }
    } catch (err) {
      unsuccessToast("landing-e");
      console.log("Error: ", err);
    }
  };

  if (typeof modal == "number") {
    return (
      <NewLanding
        saveLanding={saveLanding}
        id={modal}
        oldData={oldData}
        onClose={(data) => {
          setOldData(data);
          setModal(false);
        }}
      />
    );
  }
  return null;
}
