import React, { useMemo } from "react";
import { GoPrimitiveDot } from "react-icons/go";
import { IoIosSearch } from "react-icons/io";
import { FaTrash } from "react-icons/fa";
import moment from "moment";
import { Tooltip } from "antd";

function DocumentPlan(props) {
  const selected_rivile_service = useMemo(() => {
    if (!props.planItem.rivile_service_id) return "Nepriskitas";
    const fItem = props.rivile_services.find(
      (d) => d.value == props.planItem.rivile_service_id
    );
    if (fItem) return fItem.label;
    return "Nerastas";
  }, [props.rivile_services, props.planItem.rivile_service_id]);
  return (
    <tr className="align-middle tableColumn" key={150}>
      <td className="align-middle">
        <GoPrimitiveDot
          className={props.planItem.active ? "text-success" : "text-danger"}
        />
      </td>
      <td className="align-middle">{props.planItem.name}</td>
      <td className="align-middle">
        {moment(props.planItem.updated_at).format("YYYY MM DD HH:mm")}
      </td>
      <td className="align-middle">
        {props.planItem.start_date && `${props.planItem.start_date} Mėn.`}
      </td>
      <td className="align-middle">
        {props.planItem.end_date && `${props.planItem.end_date} Mėn.`}
      </td>
      <td className="align-middle">{props.planItem.cost}</td>
      <td className="align-middle">{props.planItem.percentage}</td>
      <td className="align-middle">
        <Tooltip placement="top" title={selected_rivile_service}>
          <GoPrimitiveDot
            className={
              props.planItem.rivile_service_id ? "text-success" : "text-danger"
            }
          />
        </Tooltip>
      </td>
      <td className="align-middle" style={{ float: "right", width: "73px" }}>
        <button
          className="btn table_button block_orange"
          data-toggle="modal"
          data-target="#tablePlans"
          onClick={() =>
            props.updateDocumentPlan(props.planItem, props.documentId)
          }
        >
          <IoIosSearch />
        </button>
        <button
          className="btn table_button block_red"
          onClick={() =>
            props.deleteDocumentPlan(
              props.planItem.document_type_id,
              props.planItem.id,
              props.planItem.name
            )
          }
        >
          <FaTrash />
        </button>
      </td>
    </tr>
  );
}

export default DocumentPlan;
