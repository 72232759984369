import React, { useEffect } from "react";
import ContentBlock from "../components/Layout/ContentBlock";
import { useSelector } from "react-redux";
import { Hand_stop_svg } from "../helpers/SvgStrings";
import HistoryRedirect from "../helpers/HistoryRedirect";
import Container from "../components/Layout/Container";

function NotFound404({ history, match }) {
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (match.url == "/") {
      HistoryRedirect(user.user_role.permissions, history);
    }
  }, []);
  return (
    <Container
      location={{
        locationName: "Puslapis nerastas",
        locationUrl: match.url,
      }}
    >
      <ContentBlock>
        <div className="col-12 px-3 py-2">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div className="mr-3">{Hand_stop_svg()}</div>
              <div>
                <h1 className="text-xl mb-0 font-semi-bold">Atsiprašome,</h1>
                <p className="text-sm mb-0">
                  tačiau jūs neturite teisių peržiūrėti šį turinį. Jei manote,
                  jog tai klaida, prašome kreiptis{" "}
                  <b>
                    <span className="text-orange">
                      it.pagalba@technorama.lt
                    </span>
                  </b>
                </p>
              </div>
            </div>
            <div className="">
              <button
                className="btn btn-primary"
                onClick={() =>
                  HistoryRedirect(user.user_role.permissions, history)
                }
              >
                Grįžti į pagrindinį
              </button>
            </div>
          </div>
        </div>
      </ContentBlock>
    </Container>
  );
}

export default NotFound404;
