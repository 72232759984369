import React, { useEffect } from "react";
import { Input } from "antd";
import { FaTrash, FaPlus, FaCheck, FaTimes, FaMinus } from "react-icons/fa";
import TextInputModal from "../../../../TextInputModal";
import { SelectForm } from "../../../../../common/Buttons";
import Axios from "axios";
import useFetch from "../../../../../../helpers/useFetch";
import Spinner from "../../../../Spinner";
import DebounceSelect from "../../../../DebounceSelect";
import { errorHandler } from "../../../../../../helpers/Utils";

function ContractGoods({
  item,
  index,
  OrderRow,
  onDelete,
  PlanPriceMain,
  updateFieldChanged,
  SelectedItemData,
  setSelectedItemData,
  DisabledInputs,
  addAdditionalItem,
  changeAdditionalItem,
  removeAdditionalItem,
  meaningsOptions,
  handeChangePrice,
  warrantlyOption,
  setWarranty,
  Warranty,
  document_type_id,
  document_plan_id,
  errors
}) {
  const { value: document_plan, loading } = useFetch(
    `/api/v1/document_types/${document_type_id}/document_plans/${document_plan_id}`,
    {},
    [document_type_id || 'skip', document_plan_id || 'skip']
  );

  useEffect(() => {
    let newArr = [...SelectedItemData];
    if (item.created_at) {
      newArr[index].item_price = getNum(item.item_price);
      newArr[index].item_pricePerc = frompercentage(
        item.item_price,
        item.product_price
      );
    } else {
      newArr[index].item_pricePerc = getNum(PlanPriceMain.percentage);
      newArr[index].item_price = topercentage(
        getNum(PlanPriceMain.percentage),
        item.product_price
      );
    }
    // itemTotal
    const itemPrice = getNum(item.item_price);
    const discount = getNum(item.item_discount);
    const counter = itemPrice - discount;
    newArr[index].item_total = getNum(counter);
    setSelectedItemData(newArr);
  }, [PlanPriceMain]);

  useEffect(() => {
    const findIndex = OrderRow.findIndex((e) => e.value === item.order_row_id);
    if (findIndex !== -1) {
      updateFieldChanged(index, {
        target: {
          name: "id_number",
          value: OrderRow[findIndex].serial_number,
        },
      });
    }
  }, [OrderRow]);

  const topercentage = (percent, total) => {
    return ((percent / 100) * total).toFixed(2);
  };
  const frompercentage = (partialValue, totalValue) => {
    return ((100 * partialValue) / totalValue).toFixed(2);
  };
  const getNum = (val) => {
    val = +val || 0;
    return val.toFixed(2);
  };

  const findItemNames = (item, selector) => {
    const findItem = OrderRow.find((find) => find.value === item.order_row_id);
    if (findItem && selector == "reference") {
      return findItem.product_reference;
    }
    if (findItem && selector == "name") {
      return findItem.label;
    }
    return "";
  };

  const onChangeCategory = (e) => {
    updateFieldChanged(index, {
      target: {
        name: "category",
        value: e,
      },
    });
  };

  return (
    <div className="col-12 mt-1 py-2" style={{ backgroundColor: "#f8f8f8" }}>
      <div>
        <div className="d-flex justify-content-between mb-2">
          <span className="text-bold">
            {item.created_at ? (
              <FaCheck className="mr-2" title={"Sukurtas"} color="green" />
            ) : (
              <FaTimes className="mr-2" title={"Kuriamas"} color="red" />
            )}
            ({findItemNames(item, "reference")}) {findItemNames(item, "name")}
          </span>
          {!DisabledInputs && (
            <FaTrash
              style={{ cursor: "pointer", color: "orange" }}
              onClick={onDelete}
            />
          )}
        </div>

        <TextInputModal
          required="true"
          name="product_price"
          type="number"
          text="Prekės kaina"
          placeholder="Įvesti"
          value={item.product_price}
          disabled={DisabledInputs}
          onChange={(e) => handeChangePrice(e, index)}
        />
        {loading ? (
          <Spinner />
        ) : (
          <>
            <DebounceSelect
              required={true}
              label="Kategorija"
              fetchOptions={async (value) => {
                try {
                  let api_url = `/api/v1/orders/shop_categories_list?&q[title_cont_any]=${value}`;
                  if (
                    document_plan?.rivile_service?.limitation_shop_categories
                  ) {
                    api_url +=
                      document_plan?.rivile_service?.limitation_shop_categories
                        .map((item) => `&q[id_not_in][]=${item}`)
                        .join("");
                  }
                  const { data } = await Axios.get(api_url);

                  if (data) {
                    return data.map((item_each) => {
                      return {
                        value: item_each.title,
                        label: item_each.title,
                      };
                    });
                  }
                  throw new Error("Err");
                } catch (err) {
                  errorHandler(err);
                  return [];
                }
              }}
              showSearch
              value={item.category || undefined}
              disabled={DisabledInputs}
              onChange={(e) => onChangeCategory(e.value)}
              error={errors?.limitation_shop_categories}
            />
          </>
        )}
        <TextInputModal
          required="true"
          type="text"
          name="manufacturer"
          text="Gamintojas"
          placeholder="Įvesti"
          value={item.manufacturer}
          disabled={DisabledInputs}
          onChange={(e) => updateFieldChanged(index, e)}
          error={errors?.limitation_brands}
        />
        <TextInputModal
          required="true"
          type="text"
          name="model"
          text="Modelis"
          placeholder="Įvesti"
          value={item.model}
          disabled={DisabledInputs}
          onChange={(e) => updateFieldChanged(index, e)}
        />
        <TextInputModal
          required="true"
          name="id_number"
          // type="number"
          text="Serijinis numeris"
          placeholder="Įvesti"
          value={item.id_number}
          disabled={DisabledInputs}
          onChange={(e) => updateFieldChanged(index, e)}
        />
        <div className="text-bold my-1">
          Gamintojo garantija:
          <span className="orange_color">*</span>
        </div>
        <SelectForm
          onlyPlaceholder="Įvesti"
          options={warrantlyOption}
          value={Warranty || undefined}
          disabled={DisabledInputs}
          onChange={(e) => setWarranty(e)}
        />
        <>
          <div className="text-bold my-1 d-flex justify-content-between">
            <div>
              Papildoma informacija <span className="orange_color">*</span>
            </div>
            <div>
              <FaPlus
                style={{ color: "green", cursor: "pointer" }}
                onClick={() => addAdditionalItem(index)}
              />
            </div>
          </div>
          {item.additional_data &&
            item.additional_data.map((item, itemIndex) => {
              return (
                <div className="d-flex" key={itemIndex}>
                  <div style={{ width: "45%" }}>
                    <SelectForm
                      onlyPlaceholder="Pavadinimas"
                      name="key"
                      options={meaningsOptions}
                      value={item.key}
                      disabled={DisabledInputs}
                      onChange={(e) =>
                        changeAdditionalItem(index, itemIndex, e, "key")
                      }
                    />
                  </div>
                  <div style={{ width: "45%" }}>
                    <Input
                      className="orange"
                      name="text"
                      placeholder="Reikšmė"
                      value={item.text}
                      disabled={DisabledInputs || item.key === null}
                      onChange={(e) =>
                        changeAdditionalItem(
                          index,
                          itemIndex,
                          e.target.value,
                          "text"
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      width: "10%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FaMinus
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => removeAdditionalItem(index, itemIndex)}
                    />
                  </div>
                </div>
              );
            })}
        </>
        <label className="text-bold my-1">
          Plano kaina:
          <span className="orange_color">*</span>
        </label>
        <div className="d-flex">
          <div
            className="d-flex align-items-center pr-2"
            style={{ width: "40%" }}
          >
            <div className="position-relative">
              <label className="position-absolute input_text">&#8364;</label>
              <Input
                type="number"
                name="price"
                placeholder="Įvesti"
                value={item.item_price}
                disabled={DisabledInputs}
                onChange={(e) => handeChangePrice(e, index)}
              />
            </div>
          </div>
          <div
            className="text-bold"
            style={{
              display: "flex",
              alignItems: "center",
              width: "35%",
            }}
          >
            <div className="position-relative">
              <label className="position-absolute input_text">%</label>
              <Input
                type="number"
                name="pricePerc"
                placeholder="Įvesti"
                value={item.item_pricePerc}
                disabled={DisabledInputs}
                onChange={(e) => handeChangePrice(e, index)}
              />
            </div>
          </div>
          <div
            className="d-flex justify-content-center align-items-center text-secondary"
            style={{ width: "25%" }}
          >
            <span>nuo prekės kainos</span>
          </div>
        </div>
        <label className="text-bold my-1">Nuolaida</label>
        <div className="d-flex">
          <div
            className="d-flex align-items-center pr-2"
            style={{ width: "40%" }}
          >
            <div className="position-relative">
              <label className="position-absolute input_text">&#8364;</label>
              <Input
                type="number"
                name="item_discount"
                placeholder="Įvesti"
                value={item.item_discount || 0}
                disabled={DisabledInputs}
                onChange={(e) => handeChangePrice(e, index)}
              />
            </div>
          </div>
          <div
            className="text-secondary"
            style={{
              display: "flex",
              alignItems: "center",
              width: "60%",
            }}
          >
            Kaina po nuolaidos, {item.item_total} &#8364;
          </div>
        </div>
        {/* <div>Alert block!!!!!</div> */}
      </div>
    </div>
  );
}

export default ContractGoods;
