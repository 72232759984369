import { LOADING_CATEGORY, GET_CATEGORIES } from "../Actions/types";

const initialState = {
  categories: [],
  loading_category: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_CATEGORY:
      return {
        ...state,
        loading_category: true
      };
    case GET_CATEGORIES:
      let categoriesArray = Array.isArray(action.payload) ? action.payload : []
      return {
        ...state,
        loading_category: false,
        categories: categoriesArray
      };
    default:
      return state;
  }
}
