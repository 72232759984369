import React, { useState, useEffect } from "react";
import {
  SelectForm,
  Button2,
  LongButton,
  Button1,
} from "../../../../../common/Buttons";
import ContractGoods from "./ContractGoods";

import { FaChevronDown, FaChevronUp } from "react-icons/fa";

import TextInputModal from "../../../../TextInputModal";
import ContactContract from "./ContactContract";
import { DatePicker } from "antd";
import moment from "moment";
import { exportDocument } from "../../../../../../helpers/Utils";

function AdditionalServices(props) {
  const getNum = (val) => {
    val = +val || 0;
    return val.toFixed(2);
  };
  const topercentage = (percent, total) => {
    return ((percent / 100) * total).toFixed(2);
  };
  const frompercentage = (partialValue, totalValue) => {
    return ((100 * partialValue) / totalValue).toFixed(2);
  };

  const [BuyDate, setBuyDate] = useState(props.item.buy_date || undefined);
  const [Client, setClient] = useState(props.item.customer || "");
  const [Phone, setPhone] = useState(props.item.phone || "");
  const [Email, setEmail] = useState(props.item.email || "");
  const [Type, setType] = useState(props.item.document_type_id || undefined);
  const [Warranty, setWarranty] = useState(props.item.warranty || undefined);
  const [AdminPrice, setAdminPrice] = useState(
    getNum(props.new ? 0 : props.item.price)
  );
  const [TotalPrice, setTotalPrice] = useState(getNum(props.item.totals));
  const [Plan, setPlan] = useState(undefined);
  const [Items, setItems] = useState(undefined);
  const [PlanUpdate, setPlanUpdate] = useState(false);
  const [ShowContact, setShowContact] = useState(
    props.item.createdNew || false
  );
  const [DisabledInputs, setDisabledInputs] = useState(
    props.item.editable || false
  );
  const [DocumentPlanFilter, setDocumentPlanFilter] = useState([]);
  const [PlanPrice, setPlanPrice] = useState({ cost: 0, percentage: 0 });

  useEffect(() => {
    let filterPlan =
      Type === undefined
        ? []
        : props.DocumentPlan.filter((item) => item.type === Type);
    setDocumentPlanFilter(filterPlan);
    if (Type !== undefined && !PlanUpdate) {
      setPrice(props.item.document_plan_id);
      setPlanUpdate(true);
    } else {
      setPlan(undefined);
      setPlanPrice({ cost: 0, percentage: 0 });
      if (props.new) setAdminPrice(0);
    }
  }, [Type]);

  // Funckijos
  const onSubmitHandler = () => {
    const postVariables = {
      contract: {
        document_plan_id: Plan,
        buy_date: new Date(),
        // number: SFnumber,
        phone: Phone,
        email: Email,
        customer: Client,
        buy_date: BuyDate,
        invoice_number: props.orderId2,
        warranty: Warranty,
        price: AdminPrice,
        total: TotalPrice,
      },
    };
    if (props.new) {
      props
        .addContract(
          props.orderId,
          postVariables,
          props.item.contract_items,
          props.item.id
        )
        .then((res) => {
          if (res) {
            props.setOpen([...props.Open, res.id]);
          }
        });
    } else {
      props.updateContract(
        props.orderId,
        props.item.id,
        postVariables,
        props.item.contract_items
      );
    }
  };

  const onDelete = (item) => {
    if (item.update == "new") {
      props.changeContractItems(
        props.index,
        false,
        props.item.contract_items.filter((filter) => filter.id !== item.id)
      );
    } else {
      props.deleteContractItem(props.orderId, props.item.id, item.id);
    }
  };

  const handleItems = (value) => {
    if (props.item.contract_items.length == 0) {
      const currentIndex = props.item.contract_items.findIndex(
        (find) => find.order_row_id === value
      );
      const newChecked = [...props.item.contract_items];
      if (currentIndex === -1 && value !== undefined) {
        let indexOrder = props.OrderRow.findIndex(
          (item) => item.value == value
        );
        let obj = {
          additional_data: [],
          id_number: "",
          manufacturer: "",
          model: "",
          order_row_id: value,
          update: "new",
        };
        if (indexOrder !== -1) {
          obj.model = props.OrderRow[indexOrder].label;
          obj.id_number = props.OrderRow[indexOrder].serial_number;
          obj.product_name = props.OrderRow[indexOrder].label;
          obj.product_reference = props.OrderRow[indexOrder].product_reference;
          obj.product_price = props.OrderRow[indexOrder].product_price;
          obj.manufacturer = props.OrderRow[indexOrder].manufacturer;
          obj.category = props.OrderRow[indexOrder].category;
          obj.item_price = 0;
          obj.item_total = 0;
          obj.item_discount = 0;
        } else {
          obj.product_name = "";
          obj.product_reference = "";
          obj.product_price = "";
          obj.item_price = 0;
          obj.item_total = 0;
          obj.item_discount = 0;
        }

        newChecked.push(obj);
      }
      props.changeContractItems(props.index, false, newChecked);
      setItems(undefined);
      countingTotalPrice();
    }
  };

  const setPrice = (id) => {
    setPlan(id);
    const documentPlanId = props.DocumentPlan.findIndex(
      (item) => item.value == id
    );
    let variables = {};
    if (documentPlanId !== -1) {
      variables.cost = props.DocumentPlan[documentPlanId].cost;
      variables.percentage = props.DocumentPlan[documentPlanId].percentage;
    } else {
      const variables = {};
      variables.cost = 0;
      variables.percentage = 0;
    }
    setPlanPrice(variables);
    if (props.new) setAdminPrice(getNum(variables.cost));
  };

  const updateFieldChanged = (index, e, additional) => {
    let newArr = [...props.item.contract_items];
    if (additional) {
      let propertyName = additional;
      newArr[index].additional_data[propertyName] = e;
    } else {
      let propertyName = e.target.name;
      newArr[index][propertyName] = e.target.value;
    }
    props.changeContractItems(props.index, false, newArr);
  };

  const countingTotalPrice = () => {
    if (props.item.contract_items.length > 0) {
      let reducer = props.item.contract_items.reduce(
        (acc, cur) => acc + Number(cur.item_total == NaN ? 0 : cur.item_total),
        0
      );
      let total = +AdminPrice + +reducer;
      setTotalPrice(getNum(total));
    } else {
      setTotalPrice(getNum(AdminPrice));
    }
  };

  useEffect(() => {
    countingTotalPrice();
  }, [props.item.contract_items, AdminPrice]);

  // Additional
  const addAdditionalItem = (index) => {
    let oldArray = [...props.item.contract_items];
    const newObj = {
      key: undefined,
      text: "",
    };
    oldArray[index].additional_data = [
      ...oldArray[index].additional_data,
      newObj,
    ];
    props.changeContractItems(props.index, false, oldArray);
  };

  const changeAdditionalItem = (index, itemIndex, value, input) => {
    let oldArray = [...props.item.contract_items];
    oldArray[index].additional_data[itemIndex][input] = value;

    props.changeContractItems(props.index, false, oldArray);
  };

  const removeAdditionalItem = (index, itemIndex) => {
    let oldArray = [...props.item.contract_items];
    oldArray[index].additional_data.splice(itemIndex, 1);

    props.changeContractItems(props.index, false, oldArray);
  };

  const handeChangePrice = (e, index) => {
    let newArr = [...props.item.contract_items];

    const parentName = e.target.name;

    if (parentName === "price") {
      newArr[index].item_pricePerc = frompercentage(
        e.target.value,
        newArr[index].product_price
      );
      newArr[index].item_price = e.target.value;
    } else if (parentName == "pricePerc") {
      newArr[index].item_pricePerc = e.target.value;
      newArr[index].item_price = topercentage(
        e.target.value,
        newArr[index].product_price
      );
    } else if (parentName == "item_discount") {
      newArr[index].item_discount = e.target.value;
    } else if (parentName == "product_price") {
      newArr[index].product_price = e.target.value;
      newArr[index].item_price = topercentage(
        newArr[index].item_pricePerc,
        newArr[index].product_price
      );
    }
    // itemTotal
    const itemPrice = getNum(newArr[index].item_price);
    const discount = getNum(newArr[index].item_discount);
    const counter = itemPrice - discount;
    newArr[index].item_total = getNum(counter);
 
    props.changeContractItems(props.index, false, newArr);

  };
  //
  return (
    <>
      <div className="bg-silver">
        <div className="col-12 py-2">
          <div className="text-bold my-1">
            Pirkimo data <span className="orange_color">*</span>
          </div>
          <DatePicker
            style={{ width: "100%" }}
            placeholder="Įvesti"
            disabledDate={(d) =>
              d.isBefore(moment().subtract(40, "days")) ||
              d.isAfter(moment().format("YYYY-MM-DD"))
            }
            value={moment(BuyDate)}
            disabled={DisabledInputs}
            onChange={(e, arg) =>
              setBuyDate(arg == "" ? moment() : arg, "buy_date")
            }
          />
          <TextInputModal
            required="true"
            text="Klientas"
            placeholder="Įvesti"
            value={Client}
            disabled={DisabledInputs}
            onChange={(e) => setClient(e.target.value)}
          />

          <TextInputModal
            required="true"
            text="Telefonas"
            placeholder="Įvesti"
            value={Phone}
            disabled={DisabledInputs}
            onChange={(e) => setPhone(e.target.value)}
            error={
              props.errors.phone && props.errors.phone.map((item) => `${item} `)
            }
          />
          <TextInputModal
            required="true"
            text="El. paštas"
            placeholder="Įvesti"
            value={Email}
            disabled={DisabledInputs}
            onChange={(e) => setEmail(e.target.value)}
          />


          <div className="text-bold my-1">
            Tipas: <span className="orange_color">*</span>
          </div>
          <SelectForm
            onlyPlaceholder="Pasirinkti"
            options={props.DocumentType}
            value={Type}
            disabled={DisabledInputs}
            onChange={(e) => setType(e)}
          />

          <div
            className={`text-bold my-1 ${
              props.errors.document_plan_id ? "text-danger" : ""
            }`}
          >
            Planas: <span className="orange_color">*</span>
          </div>
          <SelectForm
            // mode="tags"
            onlyPlaceholder="Pasirinkti"
            options={DocumentPlanFilter}
            value={Plan}
            disabled={DisabledInputs || Type === undefined}
            onChange={(e) => setPrice(e)}
            error={
              props.errors.document_plan_id &&
              props.errors.document_plan_id.map((item) => `${item} `)
            }
          />
          <TextInputModal
            required="true"
            type="number"
            text="Mokestis"
            placeholder="Įvesti"
            disabled={DisabledInputs}
            value={AdminPrice}
            onChange={(e) => setAdminPrice(e.target.value)}
          />

          {props.item.contract_items.length === 0 && (
            <React.Fragment>
              <div className="text-bold my-1">Sutarties prekės</div>
              <div className="d-flex justify-content-between">
                <SelectForm
                  onlyPlaceholder="Pasirinkti"
                  value={Items}
                  options={props.OrderRow}
                  disabled={DisabledInputs}
                  onChange={(e) => setItems(e)}
                />
                <div style={{ width: "10%" }}></div>

                <LongButton
                  className="longButtonDoc"
                  text={"+  Pridėti prekę"}
                  onClick={() => handleItems(Items)}
                />
              </div>
            </React.Fragment>
          )}
        </div>
        {props.item.contract_items.map((item, index) => {
          return (
            <ContractGoods
              key={index}
              item={item}
              index={index}
              PlanPriceMain={PlanPrice}
              DisabledInputs={DisabledInputs}
              OrderRow={props.OrderRow}
              updateFieldChanged={updateFieldChanged}
              onDelete={() => onDelete(item)}
              SelectedItemData={props.item.contract_items}
              setSelectedItemData={(data) =>
                props.changeContractItems(props.index, false, data)
              }
              addAdditionalItem={addAdditionalItem}
              changeAdditionalItem={changeAdditionalItem}
              removeAdditionalItem={removeAdditionalItem}
              meaningsOptions={props.meaningsOptions}
              handeChangePrice={handeChangePrice}
              warrantlyOption={props.warrantlyOption}
              setWarranty={setWarranty}
              Warranty={Warranty}
              document_type_id={Type}
              document_plan_id={Plan}
              errors={props.errors}
            />
          );
        })}
        {props.errors && (
          <div className="col-12">
            {props.errors?.limitation_products && (
              <div className="text-danger">
                {props.errors.limitation_products}
              </div>
            )}
            {props.errors?.limitation_shop_categories && (
              <div className="text-danger">
                {props.errors.limitation_shop_categories}
              </div>
            )}
            {props.errors?.limitation_brands && (
              <div className="text-danger">
                {props.errors.limitation_brands}
              </div>
            )}
          </div>
        )}
      </div>
      {props.item.can_send && (
        <>
          <div
            className="d-flex justify-content-between bg-light px-1 py-1 mb-1"
            onClick={() => setShowContact(!ShowContact)}
          >
            <span className="addDoc">
              {ShowContact
                ? "Uždaryti dokumento siuntimo langą"
                : "Siųsti dokumentą"}
            </span>
            <div>
              {ShowContact ? (
                <FaChevronDown title={"Uždaryti"} />
              ) : (
                <FaChevronUp title="Atidaryti" />
              )}
            </div>
          </div>

          {ShowContact && (
            <ContactContract
              email={props.email}
              orderId2={props.orderId2}
              history={props.history}
              orderId={props.orderId}
              contractId={props.item.id}
              contractsItems={props.item.contract_emails}
              setDisabledInputs={setDisabledInputs}
              certificateNr={props.item.number}
            />
          )}
        </>
      )}
      <div className="col-12 my-2">
        <div className="d-flex justify-content-between">
          <div
            className={`${
              props.item.contract_items.length == 0 && "invisible"
            }`}
          >
            <LongButton
              style={{ width: "auto", padding: "0 10px" }}
              text={`Galutinė suma: ${TotalPrice}`}
            />
          </div>

          <Button2
            style={{ width: "33%", fontSize: "12px", width: "140px" }}
            onClick={() => onSubmitHandler()}
            text={props.new ? "Sukurti dokumentą" : "Atnaujinti"}
          />
        </div>
      
        {!props.new && (
          <div className="pt-5 pb-4">
            <div style={{ position: "relative" }}>
              <Button1
                style={{
                  position: "absolute",
                  top: "-16px",
                  left: "50%",
                  transform: "translate(-50%, 0px)",
                }}
                text={"Parsisiųskite"}
                option={"upload"}
                onClick={() =>
                  exportDocument(
                    `/api/v1/contracts/${props.item.id}/download`,
                    `Contract_${props.item.number}.pdf`,
                    "POST"
                  )
                }
              />
              <hr />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AdditionalServices;
