import React, { useState } from "react";
import { useDispatch } from "react-redux";
import useErrors from "../../hooks/useErrors";
import useFetch from "../../hooks/useFetch";
import { updateEnergyLabel } from "../../redux/Actions/energy_labels_action";
// import AutosysSku from "../Autosys/AutosysSku";
import { ButtonSecondary } from "../common";
import SelectInput from "../common/SelectInput";
import TextInputModal from "../common/TextInputModal";
import { InitialData, Label } from "../global";
import DataSheetItem from "./DataSheetItem";
import { onChangeUseState } from "../../helpers/GlobalFunctions";
import { ProductSku } from "../global";

export default function EnergyLabelModal({ modalOpt, onClose, options }) {
  const dispatch = useDispatch();
  const [formLoading, setFormLoading] = useState(false);

  const { errors, setErrors } = useErrors();
  console.log({ modalOpt });
  const { loading, error, value, setValue } = useFetch(
    `/api/v1/energy_labels/${
      modalOpt.type == "create" ? "new" : modalOpt.record_id
    }`
  );
  if (loading || error || !value) {
    return <InitialData loading={loading} error={error} value={value} />;
  }

  console.log({ value });
  const onSubmit = async () => {
    try {
      setFormLoading(true);
      const { success, errors } = await dispatch(updateEnergyLabel(value));
      if (success) {
        onClose();
      } else {
        console.log({ errors });
        setErrors(errors);
      }
    } catch (err) {
      console.error("ERROR: ", err);
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <div
      className="d-flex flex-column jusitfy-content-between"
      style={{
        height: "100%",
      }}
    >
      <div className="flex-1">
        <ProductSku
          type="energy"
          warning={errors["energy_products.sku"]}
          products={value.energy_products}
          setValue={setValue}
        />
        <SelectInput
          value_return={"simple"}
          required={true}
          label="Energijos klasė"
          options={options?.energy_class_image}
          name="energy_class"
          value={value.energy_class || undefined}
          onChange={(val) =>
            setValue((prev) => ({ ...prev, energy_class: val }))
          }
          error={errors.energy_class}
        />
        <div className="my-2" />
        <TextInputModal
          disabled={true}
          required={true}
          text="Eprel nr."
          name="eprel_id"
          value={value.eprel_id || undefined}
          // onChange={onChangeUseState(setValue)}
          warning={errors.eprel_id}
        />
        <div className="my-2" />
        <DataSheetItem
          label="Duomenų lapas"
          value="data_sheet"
          document={value.data_sheet}
          base64={value.data_sheet_base64}
          setValue={setValue}
          />
        <div className="my-2" />
        <DataSheetItem
          label="Etiketės nuotrauka"
          value="label_image"
          document={value.label_image}
          base64={value.label_image_base64}
          setValue={setValue}
        />
      </div>
      <div>
        <ButtonSecondary
          onClick={onSubmit}
          loading={loading || formLoading}
          disabled={loading || formLoading}
        >
          {modalOpt.type == "create" ? "Sukurti" : "Išsaugoti"}
        </ButtonSecondary>
      </div>
    </div>
  );
}
