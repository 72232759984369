import React, { useState, useEffect } from "react";
import TextInputModal from "../../../TextInputModal";
import { ButtonPrimary, LongButton } from "../../../Buttons";
import { GiSave } from "react-icons/gi";
import { phonenumberValid } from "../../../../../helpers/Utils";
import { posti_postal_code } from "../../../../../helpers/RequestToAnotherServer";
import { GoPrimitiveDot } from "react-icons/go";

function BillingAddressComponent({
  billing_address,
  billingAdr_action_edit,
  updateOrderAddress,
  invoice_number,
  postToRivile,
  orderCreateInvoice,
  orderId,
  disabled = false,
}) {
  const [billingAdr, setBillingAdr] = useState(billing_address);
  const [rivile, setRivile] = useState({ success: false, error: null });
  const [makeInvoiceData, setMakeInvoiceData] = useState({
    error: null,
    loading: false,
  });
  useEffect(() => {
    if (billingAdr_action_edit && billingAdr.city && billingAdr.street) {
      const t = setTimeout(async () => {
        const { success, postal_code } = await posti_postal_code(
          billingAdr.city,
          billingAdr.street
        );
        if (success && postal_code) {
          handleChangeText({
            target: { value: postal_code, name: "postal_code" },
          });
        }
      }, 1000);

      return () => clearTimeout(t);
    }
  }, [billingAdr.city, billingAdr.street]);
  const handleChangeText = (e) => {
    const { name, value } = e.target;
    setBillingAdr({
      ...billingAdr,
      [name]: value,
    });
  };
  const postRivile = () => {
    setRivile({ success: "loading", error: rivile.error });
    postToRivile(orderId).then((res) => {
      if (res.success) {
        setRivile({
          success: true,
          error: null,
        });
      } else {
        setRivile({
          success: false,
          error: res.error,
        });
      }
    });
  };

  const makeInvoice = () => {
    setMakeInvoiceData({ ...makeInvoiceData, loading: true });
    orderCreateInvoice(orderId).then((data) => setMakeInvoiceData(data));
  };

  const phonenumber = phonenumberValid(billing_address.phone);

  const onSave = () => {
    if (billingAdr.company && !billingAdr.company_code) {
      return;
    }
    updateOrderAddress("billing", billingAdr);
  };
  if (billingAdr_action_edit) {
    return (
      <ul className="ul-element">
        <li>
          <TextInputModal
            text="Vardas"
            placeholder="Įvesti"
            name="firstname"
            value={billingAdr.firstname}
            onChange={(e) => handleChangeText(e, "billingAdr_action")}
          />
        </li>
        <li>
          <TextInputModal
            text="Pavardė"
            placeholder="Įvesti"
            name="lastname"
            value={billingAdr.lastname}
            onChange={(e) => handleChangeText(e, "billingAdr_action")}
          />
        </li>
        <li>
          <TextInputModal
            text="Adresas"
            placeholder="Įvesti"
            name="street"
            value={billingAdr.street}
            onChange={(e) => handleChangeText(e, "billingAdr_action")}
          />{" "}
        </li>
        <li>
          <TextInputModal
            text="Miestas"
            placeholder="Įvesti"
            name="city"
            value={billingAdr.city}
            onChange={(e) => handleChangeText(e, "billingAdr_action")}
          />{" "}
        </li>
        <li>
          <TextInputModal
            text="Pašto kodas"
            placeholder="Įvesti"
            name="postal_code"
            value={billingAdr.postal_code}
            onChange={(e) => handleChangeText(e, "billingAdr_action")}
          />
        </li>
        <li>
          <TextInputModal
            text="Telefono numeris"
            placeholder="Įvesti"
            name="phone"
            value={billingAdr.phone}
            onChange={(e) => handleChangeText(e, "billingAdr_action")}
            warning={
              phonenumberValid(billingAdr.phone)
                ? null
                : "Blogai suvestas telefono numeris."
            }
          />{" "}
        </li>
        <li>
          <TextInputModal
            text="El. paštas"
            placeholder="Įvesti"
            name="other"
            value={billingAdr.other}
            onChange={(e) => handleChangeText(e, "billingAdr_action")}
          />
        </li>
        <li>
          <TextInputModal
            text="Imonė"
            placeholder="Įvesti"
            name="company"
            value={billingAdr.company}
            onChange={(e) => handleChangeText(e, "shippingAdr")}
          />
        </li>
        <li>
          <TextInputModal
            required={!!billingAdr.company}
            text="Imonės kodas"
            placeholder="Įvesti"
            name="company_code"
            value={billingAdr.company_code}
            onChange={(e) => handleChangeText(e, "shippingAdr")}
            warning={
              billingAdr.company && !billingAdr.company_code
                ? "Užpildykite laukelį"
                : ""
            }
          />
        </li>

        <li>
          <TextInputModal
            text="PVM"
            placeholder="Įvesti"
            name="vat_code"
            value={billingAdr.vat_code}
            onChange={(e) => handleChangeText(e, "shippingAdr")}
          />
        </li>

        <li className="my-3">
          <button
            className="btn btn-sm btn-primary"
            style={{ width: "100%" }}
            onClick={() => onSave()}
          >
            <GiSave />
            {"Atnaujinti"}
          </button>
        </li>
      </ul>
    );
  }
  const renderSaskaita = () => {
    if (billingAdr.company && !billingAdr.company_code)
      return <div className="text-danger">Neįvestas įmonės kodas</div>;
    if (disabled) return;
    return (
      <ButtonPrimary
        loading={makeInvoiceData.loading}
        onClick={() => makeInvoice()}
      >
        <GoPrimitiveDot className="text-orange" size={18} />
        <span>Sąskaita</span>
      </ButtonPrimary>
    );
  };
  return (
    <React.Fragment>
      <ul className="ul-element">
        <li>
          {billing_address.firstname} {billing_address.lastname}
        </li>
        {billing_address.company && <li>{billing_address.company}</li>}
        {billing_address.company_code && (
          <li>{billing_address.company_code}</li>
        )}
        {billing_address.vat_code && <li>PVM: {billing_address.vat_code}</li>}
        <li>{billing_address.street}</li>
        {billing_address.street2 !== null && <li>{billing_address.street2}</li>}
        {billing_address.city || billing_address.postal_code ? (
          <li>
            {billing_address.city && `${billing_address.city}, `}
            {billing_address.postal_code}
          </li>
        ) : null}
        <li className={phonenumber ? "" : "text-danger"}>
          {billing_address.phone}
        </li>
        <li>{billing_address.other}</li>

        <li className="d-flex flex-wrap">
          <div className="mr-3 d-flex">
            {billing_address.registered_in_rivile ? (
              <div className="d-flex">
                <LongButton
                  style={{
                    backgroundColor: "#28a745",
                    color: "white",
                    fontSize: "12px",
                    maxWidth: "150px",
                    height: "auto",
                    width: "auto",
                    padding: "0 13px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  text={"Eksportuota"}
                />
              </div>
            ) : disabled ? null : (
              <ButtonPrimary
                loading={rivile.success == "loading"}
                onClick={() => postRivile()}
              >
                <GoPrimitiveDot className="text-orange" size={18} />
                <span>Klientas</span>
              </ButtonPrimary>
            )}
          </div>
          {invoice_number ? (
            <div className="d-flex">
              <LongButton
                style={{
                  backgroundColor: "#28a745",
                  color: "white",
                  fontSize: "12px",
                  maxWidth: "150px",
                  height: "auto",
                  width: "auto",
                  padding: "0 13px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                text={invoice_number}
              />
            </div>
          ) : (
            renderSaskaita()
          )}
        </li>
        <li>
          {makeInvoiceData && makeInvoiceData.error && (
            <div className="text-danger">{makeInvoiceData.error}</div>
          )}
        </li>
        {rivile.error !== null && (
          <li className="text-danger" style={{ fontSize: "13px" }}>
            {rivile.error.map((item) => (
              <div>{item}</div>
            ))}
          </li>
        )}
      </ul>
    </React.Fragment>
  );
}

export default BillingAddressComponent;
