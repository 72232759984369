import React, { useState, useEffect } from "react";
import ContentBlock from "../../Layout/ContentBlock";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  ButtonGradientAdd,
  ButtonDelete,
  Button2,
  ButtonEdit,
  CheckBoxInput,
} from "../../common/Buttons";
import DraggableSvg from "../../../../assets/images/draggable.svg";
import TextInputModal from "../../common/TextInputModal";
import {
  deleteLandingTypes,
  getLandingTypes,
  postLandingTypes,
  putLandingTypes,
} from "../../../redux/Actions/landingAction";
import ModalSpecial from "../../Layout/Modals/ModalSpecial";
import { successToast, unsuccessToast } from "../../../helpers/ErrorsToasts";

export default function GuideThird() {
  const [draggable, setDraggable] = useState([]);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    getLandingTypes().then((res) => {
      if (res.success) {
        setDraggable(res.data);
      }
    });
  }, []);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const draggable_arr = reorder(
      draggable,
      result.source.index,
      result.destination.index
    );
    const updated_array = draggable_arr.map((e, idx) => ({
      ...e,
      position: idx + 1,
    }));
    setDraggable(updated_array);

    putLandingTypes(updated_array);
  }

  const saveLandingTypes = async (params) => {
    try {
      const { data, success } = await postLandingTypes(params);
      if (success) {
        const id = params.landing_type?.id;
        if (id) {
          setDraggable((prev) =>
            prev.map((d) => (d.id == id ? { ...d, ...data } : d))
          );
        } else {
          setDraggable([...draggable, data]);
        }
        successToast("landing-s");
        return setModal(false);
      }
      throw new Error("Err");
    } catch (err) {
      unsuccessToast("landing-e");
      console.log("Error: ", err);
    }
  };

  const deleteLanding = (id) => {
    deleteLandingTypes(id);
    setDraggable(draggable.filter((e) => e.id !== id));
    successToast("landing-s");
  };

  return (
    <div>
      <ContentBlock>
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center">
            <h4
              className="font-weight-bold mb-0"
              style={{ fontSize: 18, color: "#262626" }}
            >
              Landingų tipai
            </h4>
            {true && (
              <span>
                <ButtonGradientAdd
                  text="Pridėti tipą"
                  onClick={() => setModal({ type: "new_landing_type" })}
                />
              </span>
            )}
          </div>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                ref={provided.innerRef}
                className={""}
                {...provided.droppableProps}
              >
                <div className="d-flex align-items-center custom_draggable">
                  <div style={{ width: 50 }}></div>
                  <div className="font-semi-bold">Pavadinimas</div>
                  <div></div>
                </div>
                {draggable.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={`${item.id}-id`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        className={`d-flex tableColumn custom_draggable ${
                          snapshot.isDragging ? "active" : ""
                        }`}
                        style={provided.draggableProps.style}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{ width: 50 }}
                        >
                          <img src={DraggableSvg} />
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div>{item.title}</div>
                          <div className="d-flex justify-content-end align-items-center">
                            <ButtonEdit
                              onEdit={() =>
                                setModal({
                                  type: "new_landing_type",
                                  values: item,
                                })
                              }
                            />
                            <div className="mx-2" />
                            <ButtonDelete
                              onDelete={() => deleteLanding(item.id)}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ContentBlock>

      {modal && modal?.type && (
        <ModalSpecial
          text={"Pridėti naują landingą"}
          onClose={() => setModal(false)}
        >
          {modal?.type == "new_landing_type" ? (
            <NewLandingType
              values={modal.values || {}}
              saveLandingTypes={saveLandingTypes}
              biggestNumber={Math.max(...draggable.map((e) => e.position)) + 1}
            />
          ) : null}
        </ModalSpecial>
      )}
    </div>
  );
}

const NewLandingType = ({ values, saveLandingTypes, biggestNumber }) => {
  const [state, setstate] = useState({
    id: values?.id || null,
    title: values?.title || "",
    illustrations: values?.illustrations || false,
    position: values?.position || biggestNumber,
  });
  const [errors, setErrors] = useState({ title: "" });

  const onChangeState = (e) => {
    const { name, value } = e.target;
    setstate({ ...state, [name]: value });
  };

  const onSave = () => {
    if (state.title.trim() == "") {
      return setErrors({ title: "Įveskite pavadinimą" });
    }
    setErrors({});
    saveLandingTypes({ landing_type: state });
  };
  return (
    <div>
      <TextInputModal
        text="Pavadinimas"
        name="title"
        value={state.title}
        onChange={onChangeState}
        warning={errors.title}
      />
      <div className="my-2"></div>
      <TextInputModal
        disabled={true}
        text="Pozicija"
        name="position"
        value={state.position}
        onChange={onChangeState}
      />
      <div className="my-2"></div>
      <CheckBoxInput
        text="Iliustracijos"
        checked={state.illustrations}
        onChange={(e) =>
          onChangeState({
            target: { name: "illustrations", value: e.target.checked },
          })
        }
      />
      <div className="my-2"></div>
      <div className="mt-2 d-flex justify-content-end">
        <Button2 onClick={() => onSave()} text={"Saugoti"} />
      </div>
    </div>
  );
};
