import React from "react";

import { Upload, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { AiOutlinePlus } from "react-icons/ai";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () =>
    callback({ name: img.name, url: reader.result })
  );
  reader.readAsDataURL(img);
}

function UploadImages({ name, onChangeAttachments, items }) {
  const handleRemove = (index) => {
    const imagesArr = [...items];
    imagesArr.splice(index, 1);
    onChangeAttachments(name, "docs", imagesArr);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Nuotraukos formatai privalo būti JPG arba PNG!");
    }

    return isJpgOrPng;
  };

  const propsImage = {
    name: "file",
    onChange(info) {
      if (info.file.status === "done") {
        getBase64(info.file.originFileObj, (imgObj) =>
          onChangeAttachments(name, "docs", [...items, imgObj])
        );
      }
    },
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const returnName = (name) => {
    const lengthString = name.length;
    if (lengthString > 10) {
      const start = name.substring(0, 5);
      const end = name.substring(lengthString - 5, lengthString);
      return `${start}..${end}`;
    } else {
      return name;
    }
  };
  return (
    <div
      className="d-flex justify-content-center align-items-center px-3 py-2 flex-column"
      style={{
        background: "#F3F3F3",
        borderRadius: 3,
        width: "100%",
        minHeight: 76,
        // height: "100%",
      }}
    >
      <Upload
        {...propsImage}
        customRequest={dummyRequest}
        showUploadList={false}
        // beforeUpload={beforeUpload}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div
              className="d-flex justify-content-center align-items-center rounded-circle"
              style={{
                width: 28,
                height: 28,
                background: "#BABABA",
              }}
            >
              <AiOutlinePlus style={{ color: "#F3F3F3" }} />
            </div>
            <div>
              {items.length
                ? `${items.length} įkelti failai`
                : "Įkelkite failus"}{" "}
            </div>
          </div>
        </div>
      </Upload>
      <div className="d-flex flex-wrap">
        {items.length > 0 &&
          items.map((i, index) => (
            <div
              key={index}
              className="mr-2 mb-2"
              style={{
                backgroundColor: "#e9e9e9",
                width: "150px",
                borderRadius: "1rem",
              }}
            >
              <div style={{ color: "#3d3d3d", position: "relative" }}>
                <div className="d-flex justify-content-center py-1">
                  <div style={{ fontSize: "15px" }}>{returnName(i.name)}</div>
                  <div style={{ position: "absolute", right: "15px" }}>
                    <CloseOutlined
                      twoToneColor="#797979"
                      style={{ fontSize: "15px", cursor: "pointer" }}
                      onClick={() => handleRemove(index)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default UploadImages;
