import Axios from "axios";
import {
  GET_RIVILE_STORE,
  LOADING_RIVILE_STORE,
  UPDATE_RIVILE_STORE,
  CLEAR_ERRORS,
  GET_ERRORS,
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

const success = () => {
  if (!document.getElementById("rivileAlert-s")) return;
  document.getElementById("rivileAlert-s").style.display = "block";
  setTimeout(
    () => (document.getElementById("rivileAlert-s").style.display = "none"),
    5000
  );
  document.getElementById("modalClose").click();
};

export const getRivileStore = (page, pageCount) => (dispatch) => {
  dispatch(setRivileStoreLoading());
  Axios.get(`/api/v1/rivile_stores?page=${page}&per_page=${pageCount}`)
    .then((res) => {
      dispatch({
        type: GET_RIVILE_STORE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getRivileStoreOptions = () => {
  return Axios.get(`/api/v1/rivile_stores?page=1&per_page=99999`).then(
    (res) => {
      if (!res.data) {
        return [];
      }
      if (res.data.data) {
        const newData = [...res.data.data].filter(filt => !!filt.rivile_code)
        return newData
      }
    }
  );
};

export const updateRivileStore = (_id, body) => (dispatch) => {
  Axios.put(`/api/v1/rivile_stores/${_id}`, body)
    .then((res) => {
      dispatch({
        type: UPDATE_RIVILE_STORE,
        payload: res.data,
      });
      success();
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setRivileStoreLoading = () => {
  return {
    type: LOADING_RIVILE_STORE,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
