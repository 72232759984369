import Axios from "axios";
import {
  antdAsyncMessageError,
  antdAsyncMessageLoading,
  antdAsyncMessageSuccess,
} from "../../helpers/AsyncMessage";

export const getGuideBuyers =
  (page = 1, filter = "") =>
  async (dispatch) => {
    try {
      const { data } = await Axios.get(
        `/api/v1/guide_buyers?page=${page}${filter}`
      );
      console.log("Data: ", data);

      dispatch({
        type: "GET_CUSTOMS",
        payload: {
          current: "Guide buyers",
          data: data.data,
          totals: data.totals,
        },
      });
    } catch (err) {
      console.log("Error: ", err);
      return { success: false };
    }
  };
export const getGuideBuyersShow = async (id) => {
  try {
    const { data } = await Axios.get(`/api/v1/guide_buyers/${id ? id : "new"}`);
    return {
      success: true,
      data: data,
    };
  } catch (err) {
    console.log("Error: ", err);
    return { success: false };
  }
};

export const getGuidesBuyersCategories = async () => {
  try {
    const { data } = await Axios.get("/api/v1/guide_buyers/categories_ids");
    return {
      success: true,
      data: data.categories,
    };
  } catch (err) {
    console.log(`Error: ${err}`);
    return { success: false };
  }
};

export const postGuideBuyers = (params) => async (dispatch) => {
  try {
    const guide_buyers = { ...params };
    delete guide_buyers.category;
    delete guide_buyers.shop_category;
    delete guide_buyers.landing_documents;

    const id_is = params.id;
    antdAsyncMessageLoading({
      key: "postGuideBuyers",
    });
    const { data } = await Axios({
      url: `/api/v1/guide_buyers/${id_is || ""}`,
      method: id_is ? "PUT" : "POST",
      data: { guide_buyers },
    });
    console.log({ data });
    dispatch({
      type: id_is ? "UPDATE_CUSTOM" : "POST_CUSTOM",
      payload: data,
    });
    antdAsyncMessageSuccess({
      key: "postGuideBuyers",
    });
    return { success: true, data };
  } catch (err) {
    antdAsyncMessageError({
      key: "postGuideBuyers",
    });
    console.log("Error: ", err?.response?.data);
    return { success: false, errors: err?.response?.data?.errors };
  }
};

export const deleteGuideBuyer = (id) => async (dispatch) => {
  try {
    antdAsyncMessageLoading({
      key: "deleteGuideBuyer",
    });
    const { data } = await Axios.delete(`/api/v1/guide_buyers/${id}`);
    if (data.success) {
      dispatch({
        type: "REMOVE_CUSTOM",
        payload: id,
      });
      antdAsyncMessageSuccess({
        key: "deleteGuideBuyer",
      });
      return { success: true };
    }
    throw new Error("Err");
  } catch (err) {
    antdAsyncMessageError({
      key: "deleteGuideBuyer",
    });
    return { success: false };
  }
};
