import Axios from "axios";
import {
  GET_ORDER,
  GET_ORDERS_AMOUNT,
  GET_ACTIVE_NOT_READY_ORDERS,
  GET_ORDERS,
  UPDATE_SUPPLYORDERS,
  LOADING_ORDERS,
  LOADING_ORDER,
  POST_ORDER_ROW,
  DELETE_ORDER_ROW,
  UPDATE_ORDER,
  CLEAR_ERRORS,
  GET_ERRORS,
  CREATE_SMS_ITEM,
  POST_ORDER_SHIPPING,
  CREATE_ORDER_STATUS,
  UPDATE_SERIAL_NUMBER,
  POST_TO_RIVILE,
  UPDATE_SERIAL_NUMBER_STATE,
  UPDATE_ORDER_SOCKETS,
  ORDER_CREATE_INVOICE,
  POST_ORDER_ROW_GOODS,
  UPDATE_ORDER_ROW_GOODS,
  DELETE_ORDER_ROW_GOODS,
  UPDATE_ORDER_ALL,
  UPDATE_CART_RULES,
} from "./types";
import { logoutUser } from "./authAction";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;
import { successToast, unsuccessToast } from "../../helpers/ErrorsToasts";
import { message } from "antd";
import { antdMessageError } from "../../helpers/Utils";
import { rline_user } from "../../helpers/user";
import {
  orCorrectDeliveryMethod,
} from "../../helpers/OrdersFunctionsGlobal";

const success = () => {
  if (!document.getElementById("orderShippment-s")) return;
  document.getElementById("orderShippment-s").style.display = "block";
  setTimeout(
    () => (document.getElementById("orderShippment-s").style.display = "none"),
    5000
  );
};
const error = () => {
  if (!document.getElementById("orderShippment-e")) return;
  document.getElementById("orderShippment-e").style.display = "block";
  setTimeout(
    () => (document.getElementById("orderShippment-e").style.display = "none"),
    5000
  );
};

const errorOrder = () => {
  if (!document.getElementById("order-e")) return;
  document.getElementById("order-e").style.display = "block";
  setTimeout(
    () => (document.getElementById("order-e").style.display = "none"),
    5000
  );
};

const getOrdersAmount = (page, pageCount, filter) => (dispatch) => {
  Axios.get(
    `/api/v1/orders/total_amount?page=${page}&per_page=${pageCount}${filter}`
  ).then((response) => {
    dispatch({
      type: GET_ORDERS_AMOUNT,
      payload: response.data,
    });
  });
};

const getActiveAndNotReadyOrdersAmount =
  (page, pageCount, filter) => async (dispatch, getState) => {
    try {
      const role_identifier = getState().auth.user.user_role.identifier;
      if (rline_user(role_identifier)) return;
      const { data } = await Axios.get(
        `/api/v1/orders/total_active_not_ready_orders?page=${page}&per_page=${pageCount}${filter}`
      );
      dispatch({
        type: GET_ACTIVE_NOT_READY_ORDERS,
        payload: data,
      });
    } catch (e) {
      console.log(e);
      console.log("error getting active and not ready orders");
    }
  };

// pub

export const updateOrderSockets = (response) => (dispatch) => {
  dispatch({
    type: UPDATE_ORDER_SOCKETS,
    payload: response,
  });
};

export const getOrders = (page, pageCount, filter) => (dispatch) => {
  dispatch(setOrderLoading());
  dispatch({
    type: GET_ACTIVE_NOT_READY_ORDERS,
    payload: {
      active_orders: "loading",
      not_ready_orders: "loading",
      express_orders: "loading",
    },
  });
  return Axios.get(`/api/v1/orders?page=${page}&per_page=${pageCount}${filter}`)
    .then((res) => {
      if (res.data == undefined || Object.keys(res.data).length === 0) {
        return dispatch(logoutUser());
      }
      dispatch({
        type: GET_ORDERS,
        payload: res.data,
      });
      dispatch(getOrdersAmount(page, pageCount, filter));
      dispatch(getActiveAndNotReadyOrdersAmount(page, pageCount, filter));
      if (res.data.data.length > 0) {
        return true;
      }
    })
    .catch((err) => {
      if (err?.response?.data?.hasOwnProperty("error")) {
        dispatch(logoutUser());
      } else {
        if (!err?.response?.data) return antdMessageError();
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const orderCreateInvoice = (id) => (dispatch) => {
  return Axios.post(`/api/v1/orders/${id}/create_invoice`).then((response) => {
    if (response.data) {
      if (response.data.invoice_number) {
        dispatch({
          type: ORDER_CREATE_INVOICE,
          payload: { invoice_number: response.data.invoice_number, id },
        });
        return {
          error: null,
          loading: false,
        };
      }
      if (response.data.errors) {
        errorOrder();
        return {
          error: response.data.errors,
          loading: false,
        };
      }
      return { code: null, error: null, loading: false };
    }
  });
};

export const updateOrderAddress = (id, params) => (dispatch) => {
  const variables = {
    order: {
      ...params,
    },
  };
  return Axios.put(`/api/v1/orders/${id}`, variables)
    .then((res) => {
      dispatch({
        type: UPDATE_ORDER,
        payload: res.data,
      });
      return { ok: true, data: res.data };
    })
    .catch((err) => {
      return { ok: false };
    });
};

export const postOrderShipping = (id, orderBody) => (dispatch) => {
  if(!orCorrectDeliveryMethod(orderBody.shipment.shipment_type, orderBody.shipment.pickup_address_id, orderBody.shipment.shipment_additional_services_attributes)){
    return Promise.resolve({ success: false, errors: 'Neteisingai pasirinktas pristatymo būdas' });
  }
  return Axios.post(`/api/v1/orders/${id}/shipments`, orderBody)
    .then((res) => {
      dispatch({
        type: POST_ORDER_SHIPPING,
        payload: res.data,
      });
      success();
      return { success: true };
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
      return { success: false, errors: err.response.data };
    });
};

export const leasingRequest = (id, body) => (dispatch) => {
  return Axios.put(`/api/v1/orders/${id}`, body)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: UPDATE_ORDER,
          payload: response.data,
        });
        return { success: true };
      } else {
        return { success: false };
      }
    })
    .catch((e) => {
      return { success: false };
    });
};

export const postToRivile = (ID) => (dispatch) => {
  return Axios.post(`/api/v1/orders/${ID}/rivile/register_address`)
    .then((response) => {
      dispatch({
        type: POST_TO_RIVILE,
        payload: { data: response.data, id: ID },
      });
      return { success: true };
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      return { success: false, error: err.response.data };
    });
};

export const getOrder = (id) => (dispatch) => {
  dispatch(setOrderShowLoading());
  Axios.get(`/api/v1/orders/${id}`)
    .then((res) => {
      dispatch({
        type: GET_ORDER,
        payload: res.data,
        payload_id: id,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateSupplyOrders = (orderId, supplyId, body) => (dispatch) => {
  Axios.put(`/api/v1/orders/${orderId}/order_rows/${supplyId}`, body)
    .then((res) => {
      dispatch(clearErrors());
      dispatch({
        type: UPDATE_SUPPLYORDERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const postOrderRowGoods = (body, orderId) => (dispatch) => {
  return Axios.post(`/api/v1/orders/${orderId}/order_rows`, body)
    .then((res) => {
      if (res.data) {
        const response = {
          ...res.data.data,
          product_info: res.data.product_info,
        };
        dispatch({
          type: POST_ORDER_ROW_GOODS,
          payload: { items: response, total_price: res.data.total_price },
        });
      }
      return { ok: true, id: res.data.data };
    })
    .catch((err) => {
      return { ok: false };
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateOrderRowGoods = (body, orderId, rowId) => (dispatch) => {
  return Axios.put(`/api/v1/orders/${orderId}/order_rows/${rowId}`, body)
    .then((res) => {
      if (res.data) {
        const response = {
          ...res.data.data,
          product_info: res.data.product_info,
        };
        dispatch({
          type: UPDATE_ORDER_ROW_GOODS,
          payload: { items: response, total_price: res.data.total_price },
        });

        // const response = {
        //   ...res.data.data,
        //   product_info: res.data.product_info,
        // };
        // dispatch({
        //   type: POST_ORDER_ROW_GOODS,
        //   payload: {items: response, total_price: res.data.total_price},
        // });
        // dispatch({
        //   type: UPDATE_ORDER_ALL,
        //   payload: res.data.order,
        // });
      }
      return { ok: true };
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const deleteOrderRowGoods = (orderId, rowId) => (dispatch) => {
  return Axios.delete(`/api/v1/orders/${orderId}/order_rows/${rowId}`)
    .then((res) => {
      if (res.data.ok) {
        dispatch({
          type: DELETE_ORDER_ROW_GOODS,
          payload: { orderId, rowId, total_price: res.data.total_price },
        });
        return true;
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const postOrderRow = (PARAMS, orderId, orderRowId) => (dispatch) => {
  return Axios.post(
    `/api/v1/orders/${orderId}/order_rows/${orderRowId}/supplyings`,
    PARAMS
  )
    .then((res) => {
      dispatch({
        type: POST_ORDER_ROW,
        payload: res.data,
        payload2: { id: orderId, order: orderRowId },
      });
      return { success: true };
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      return { success: false };
    });
};

export const deleteOrderRow =
  (orderId, orderRowId, suppliersId) => (dispatch) => {
    Axios.delete(
      `/api/v1/orders/${orderId}/order_rows/${orderRowId}/supplyings/${suppliersId}`
    )
      .then((res) => {
        dispatch({
          type: DELETE_ORDER_ROW,
          payload: res.data,
          payload2: { id: orderId, order: orderRowId },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      });
  };

// export const createSmsItem =
//   (orderId, sms_sending, shipment_id) => (dispatch) => {
//     return Axios.post(`/api/v1/orders/${orderId}/sms_sendings`, {
//       shipment_id,
//       sms_sending,
//     })
//       .then((res) => {
//         if (!res.data) throw new Error("Err");
//         dispatch({
//           type: CREATE_SMS_ITEM,
//           payload: res.data.sms_sending,
//         });
//         if (res.data.order_histories) {

//           dispatch({
//             type: CREATE_ORDER_STATUS,
//             payload: res.data.order_histories,
//           });
//         }
//         if (res.data.comments) {
//           dispatch({
//             type: "UPDATE_COMMENTS_MASS",
//             payload: res.data.comments,
//           });
//         }
//         if (res.data.shipment) {
//           dispatch({
//             type: "SHIPMENT_UPDATE",
//             payload: res.data.shipment,
//           });
//         }
//         return { success: true };
//       })
//       .catch((err) => {
//         dispatch({
//           type: GET_ERRORS,
//           payload: err?.response?.data,
//         });
//         return { success: false };
//       });
//   };

export const createSmsItem =
  (orderId, sms_sending, shipment_id) => async (dispatch) => {
    try {
      await message.loading("Vykdoma..", 2.5);

      const { data } = await Axios.post(
        `/api/v1/orders/${orderId}/sms_sendings`,
        {
          shipment_id,
          sms_sending,
        }
      );
      if (!data) throw new Error("Err");
      dispatch({
        type: CREATE_SMS_ITEM,
        payload: data.sms_sending,
      });
      if (data.order_histories) {
        dispatch({
          type: CREATE_ORDER_STATUS,
          payload: data.order_histories,
        });
      }
      if (data.comments) {
        dispatch({
          type: "UPDATE_COMMENTS_MASS",
          payload: data.comments,
        });
      }
      if (data.shipment) {
        dispatch({
          type: "SHIPMENT_UPDATE",
          payload: data.shipment,
        });
      }
      await message.success("Sėkmingai įvykdyta", 2.5);

      return { success: true };
    } catch (err) {
      if (err?.response?.data) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
      await message.error("Vykdant įvyko klaida", 2.5);

      return { success: false };
    }
  };

export const createOrderStatus =
  (orderId, variables, avalaibleStatus) => (dispatch) => {
    return Axios.post(`/api/v1/orders/${orderId}/change_status`, variables)
      .then((res) => {
        dispatch({
          type: CREATE_ORDER_STATUS,
          payload: res.data,
        });
        return { success: true };
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        return { success: false };
      });
  };

export const updateSerialNumber = (id, itemId, body) => (dispatch) => {
  return Axios.put(`/api/v1/orders/${id}/order_rows/${itemId}`, body)
    .then((res) => {
      dispatch({
        type: UPDATE_SERIAL_NUMBER,
        payload: res.data.data,
        payload2: id,
      });
      return true;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateOrderCartRules = (id, orderId, params) => (dispatch) => {
  return Axios.put(`/api/v1/orders/${orderId}/order_cart_rules/${id}`, params)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: UPDATE_CART_RULES,
          payload: res.data.data,
          total_price: res.data.total_price,
        });
      }
      return true;
    })
    .catch((e) => {
      return false;
    });
};

//

export const createOrder = (params) => async (dispatch) => {
  try {
    const { data } = await Axios.post("/api/v1/orders", params);
    successToast("order-s");
    dispatch({
      type: "NEW_ORDER",
      payload: data,
    });
    return { success: true, data };
  } catch (err) {
    unsuccessToast("order-e");
    if (err.response?.data) {
      return { success: false, errors: err.response.data };
    }
    return err;
  }
};

export const updateSerialNumberState = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_SERIAL_NUMBER_STATE,
    payload: data,
  });
};

export const setOrderLoading = () => {
  return {
    type: LOADING_ORDERS,
  };
};
export const setOrderShowLoading = () => {
  return {
    type: LOADING_ORDER,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
