import React from "react";
import { CheckBoxInput } from "../../common/Buttons";

import { FaPlus, FaMinus } from "react-icons/fa";

function RivileCategoryEach({
  item,
  level,
  CurrentOpen,
  handleToggle,
  handleCheckboxChange,
}) {
  let lengthItems = item.children;

  return (
    <>
      <tr className="align-middle tableColumn">
        <td className="align-middle">{item.id}</td>
        <td className="align-middle">
          <div
            style={{ cursor: "pointer", marginLeft: level }}
            className="border-left"
            onClick={() => handleToggle(item.id)}
          >
            {CurrentOpen.includes(item.id) ? (
              <FaMinus
                title="Uždaryti"
                className="mr-2 text-danger"
                style={{ visibility: lengthItems ? "visible" : "hidden" }}
              />
            ) : (
              <FaPlus
                title="Atidaryti"
                className="mr-2 text-success"
                style={{ visibility: lengthItems ? "visible" : "hidden" }}
              />
            )}
            {item.title} {lengthItems && <span className="font-weight-bold">({lengthItems.length})</span>}
          </div>
        </td>
        <td>
          {" "}
          <CheckBoxInput
            type="checkbox"
            color="#f7951e"
            checked={item.pvm25}
            onChange={() => handleCheckboxChange(item)}
          />
        </td>
      </tr>
      {CurrentOpen.includes(item.id) &&
        item.children &&
        item.children.map((item, index) => (
          <RivileCategoryEach
          key={index}
            item={item}
            level={level + 10}
            CurrentOpen={CurrentOpen}
            handleToggle={handleToggle}
            handleCheckboxChange={handleCheckboxChange}
          />
        ))}
    </>
  );
}

export default RivileCategoryEach;
