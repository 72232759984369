import React from "react";
import { GoPrimitiveDot } from "react-icons/go";
import { ButtonEdit, ButtonDelete } from "../../common/Buttons";

function TableColumnDocuments({
  id,
  item,
  index,
  onEdit,
  onDelete
}) {
  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle">{item.id}</td>
      <td className="align-middle">{item.email}</td>
      <td className="align-middle">{item.title}</td>
      <td className="align-middle">
        <GoPrimitiveDot
          className={item.otp_required_for_login ? "text-success" : "text-danger"}
        />
      </td>
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonEdit onEdit={() => onEdit("edit",index)} />
      </td>
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonDelete onDelete={() => onDelete(id)} />
      </td>
    </tr>
  );
}

export default TableColumnDocuments;
