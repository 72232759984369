import {
  LOADING_SUPPLIER,
  GET_SUPPLIER,
  GET_SUPPLIERS,
  DELETE_SUPPLIER,
  UPDATE_SUPPLIER,
  POST_SUPPLIER
} from "../Actions/types";

const initialState = {
  suppliers: [],
  totals: 0,
  loading_supplier: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_SUPPLIER:
      return {
        ...state,
        loading_supplier: true
      };
    case GET_SUPPLIERS:
      return {
        ...state,
        suppliers: action.payload.data,
        totals: action.payload.totals,
        loading_supplier: false
      };
 
    case POST_SUPPLIER:
      let updateData = [...state.suppliers];
      updateData.push(action.payload);
      return {
        ...state,
        suppliers: updateData
      };
    case UPDATE_SUPPLIER:
      let getData = [...state.suppliers];
      const updateElementId = element => element.id === action.payload_id;
      const findIndex = getData.findIndex(updateElementId);
      getData[findIndex] = action.payload;
      return {
        ...state,
        suppliers: getData
      };
    case DELETE_SUPPLIER:
      return {
        ...state,
        suppliers: [...state.suppliers].filter(
          filterItem => filterItem.id !== action.payload
        ),
        loading_supplier: false
      };
    default:
      return state;
  }
}
