import {
  LOADING_SMS,
  GET_SMSITEMS,
  DELETE_SMSITEM,
  UPDATE_SMSITEM,
  POST_SMSITEM
} from "../Actions/types";

const initialState = {
  smsItems: [],
  totals: 0,
  loading_sms: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_SMS:
      return {
        ...state,
        loading_sms: true
      };
    case GET_SMSITEMS:
      return {
        ...state,
        smsItems: action.payload.data,
        totals: action.payload.totals,
        loading_sms: false
      };

    case POST_SMSITEM:
      let updateData = [...state.smsItems];
      updateData.push(action.payload);
      return {
        ...state,
        smsItems: updateData
      };
    case UPDATE_SMSITEM:
      let getData = [...state.smsItems];
      const updateElementId = element => element.id === action.payload_id;
      const findIndex = getData.findIndex(updateElementId);
      getData[findIndex] = action.payload;
      return {
        ...state,
        smsItems: getData
      };
    case DELETE_SMSITEM:
      return {
        ...state,
        smsItems: [...state.smsItems].filter(
          filterItem => filterItem.id !== action.payload
        ),
        loading_sms: false
      };
    default:
      return state;
  }
}
