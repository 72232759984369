import React, { useState, useEffect, useMemo } from "react";

import styled from "styled-components";
import { week_lt_short } from "../../helpers/GlobalOptions";
import { Lock_svg } from "../../helpers/SvgStrings";

const FirstStep = ({ day, setDay, setLevel, period_month, method }) => {
  const months = [...new Array(31)].map((_, idx) => ({
    value: idx + 1,
    label: idx + 1,
  }));
  const array = period_month ? months : week_lt_short;
  return (
    <>
      <div className="grid">
        {array.map((d) => (
          <div
            key={d.value}
            className={`grid_item ${day == d.value ? "active" : ""}`}
            onClick={() => setDay(d.value)}
          >
            <div className="grid_children_item">{d.label}</div>
          </div>
        ))}
      </div>
      <button
        onClick={() => {
          if (!day) return alert("Pasirinkite dieną");
          setLevel(2);
        }}
      >
        {method == "auto" ? "Pasirinkti ir pereiti prie laiko" : "Išsaugoti"}
      </button>
    </>
  );
};

const SecondStep = ({ hour, setHour, minute, setMinute, setLevel }) => {
  const hours = [...new Array(24)].map((_, idx) => idx + 1);
  const minutes = ["00", "15", "30", "45"];
  return (
    <>
      <label className="editLabelForm text-bold my-1 extra_text">
        Valanda{" "}
      </label>
      <div className="grid">
        {hours.map((d) => (
          <div
            key={d}
            className={`grid_item ${hour == d ? "active" : ""}`}
            onClick={() => setHour(d)}
          >
            <div className="grid_children_item">{d}</div>
          </div>
        ))}
      </div>
      <label className="editLabelForm text-bold my-1 extra_text">
        Minutės{" "}
      </label>

      <div className="grid">
        {minutes.map((d) => (
          <div
            key={d}
            className={`grid_item ${minute == d ? "active" : ""}`}
            onClick={() => setMinute(d)}
          >
            <div className="grid_children_item">{d}</div>
          </div>
        ))}
      </div>
      <button
        className="primary"
        onClick={() => {
          if (!minute || !hour)
            return alert("Pasirinkite valandas, bei minutes");
          setLevel();
        }}
      >
        Išsaugoti
      </button>
    </>
  );
};

const CalendarDayInput = ({
  period = "month",
  method,
  value,
  onChange,
  error,
  disabled,
}) => {
  const [active, setActive] = useState(false);
  const [level, setLevel] = useState(1);
  const [day, setDay] = useState();
  const [hour, setHour] = useState();
  const [minute, setMinute] = useState();
  const period_month = period == "month";
  const generateDate = () => {
    if (!value)
      return {
        day: "",
        time: "",
      };
    const date_splited = value?.split(" ");
    if (date_splited.length == 2) {
      const day = period_month
        ? date_splited[0]
        : week_lt_short.find((d) => `0${d.value}` == date_splited[0])?.label ||
          date_splited[0];
      setDay(day);
      const time = date_splited[1].split(":")
      setHour(time[0])
      setMinute(time[1])
      return {
        day,
        time: date_splited[1],
      };
    } else if (date_splited.length == 1) {
      setDay(value);
      return {
        day: value,
        time: "",
      };
    }
    return {
      day: "",
      time: "",
    };
  };
  useEffect(() => {
    // onChange("");
    setLevel(1);
    setDay();
    setHour();
    setMinute();
    // setActive(true);
  }, [period, method]);

  const date = useMemo(() => generateDate(), [value]);

  return (
    <div className="form-group ">
      <label
        className={`editLabelForm text-bold my-1 ${error ? "text-danger" : ""}`}
      >
        {period_month ? "Mėnesio" : "Savaitės"} diena{" "}
        {method == "auto" && "ir laikas"}
        {disabled && Lock_svg()}
      </label>
      <MonthPickerStyled
        className={`${error ? "error_border" : ""} ${
          disabled ? "disabled" : ""
        }`}
      >
        <div
          className="row cursor-pointer"
          onClick={disabled ? () => {} : () => setActive((prev) => !prev)}
        >
          <div className="col-6">
            <span
              className="intro_name cursor-pointer"
              onClick={() => setLevel(1)}
            >
              {period_month ? "Mėn. diena:" : "Diena"}
              <span className="text-black ml-1">{date.day}</span>
            </span>
          </div>
          {method == "auto" && (
            <div className="col-6">
              <span className={"intro_name"}>
                Laikas: <span className="text-black ml-1">{date.time}</span>
              </span>
            </div>
          )}
        </div>
        {/* Lentele */}
        {active && (
          <div className="calendar-block">
            <div className="col-12">
              <div className="row">
                <div
                  className={`${method == "auto" ? "col-6" : "col-12"} py-1 ${
                    level == 1 ? "active" : ""
                  }`}
                >
                  {period_month ? "Mėnesio" : "Savaitės"} diena
                </div>
                {method == "auto" && (
                  <div className={`col-6 py-1 ${level == 2 ? "active" : ""}`}>
                    Laikas
                  </div>
                )}
              </div>
            </div>
            <div className="py-2">
              <div className="col-12">
                {level == 1 ? (
                  <FirstStep
                    method={method}
                    day={day}
                    setDay={setDay}
                    setLevel={(val) => {
                      if (method == "auto") {
                        setLevel(val);
                      } else {
                        onChange(`${day > 9 ? day : `0${day}`}`);
                        setActive(false);
                      }
                    }}
                    period_month={period_month}
                  />
                ) : (
                  <SecondStep
                    hour={hour}
                    setHour={setHour}
                    minute={minute}
                    setMinute={setMinute}
                    setLevel={() => {
                      onChange(
                        `${day > 9 ? day : `0${day}`} ${
                          hour > 9 ? hour : `0${hour}`
                        }:${minute}`
                      );
                      setActive(false);
                      setLevel(1);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </MonthPickerStyled>

      {error && <div className="text-danger">{error}</div>}
    </div>
  );
};
const MonthPickerStyled = styled.div`
  // width: 218px;
  // height: 32px;
  padding: 4px 9px;

  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 2px;
  position: relative;

  &.disabled {
    background: #f9f9f9;
    & .col-6 {
      color: #d1d1d1;
    }
  }
  .extra_text {
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 10px;
    color: #000000;
  }
  .intro_name {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #d1d1d1;
  }
  .calendar-block {
    width: 100%;
    z-index: 15;
    position: absolute;
    top: 40px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    left: 0px;

    & .col-6 {
      background-color: #f6f6f6;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: #212121;
      &.active {
        background: #fe9c00;
        border-radius: 1px 0px 0px 0px;
        color: #ffffff;
      }
    }
    & .grid {
      display: grid;
      grid-template-columns: auto auto auto auto auto auto auto;
      // grid-gap: 10px;
      & .grid_item {
        cursor: pointer;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: #000000;
        text-align: center;
        & .grid_children_item {
          border-width: 1px;
          border-style: solid;
          border-color: transparent;
        }

        &.active .grid_children_item {
          border-color: #f29302 !important;
          background: #fff6e8;
          border-radius: 43px;
          color: #f29302;
        }
      }
    }
    & button {
      width: 100%;
      background: #fff6e8;
      border: 1px solid #fe9c00;
      border-radius: 3px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 19px;
      text-align: center;
      color: #fe9c00;
      margin-top: 8px;

      &.primary {
        background: #fe9c00;
        border: 1px solid #fe9c00;
        color: #ffffff;
      }
      &:focus {
        outline: none;
      }
    }
  }
`;

export default CalendarDayInput;
