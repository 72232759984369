import Axios from "axios";
import { getUrlExtension } from "../../helpers/Utils";
import {
  ADD_ORDER_DOCUMENT,
  GET_ORDER_DOCUMENT,
  UPDATE_ORDER_DOCUMENT,
  DELETE_ORDER_DOCUMENT,
  GET_ERRORS,
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

export const addOrderDocument = (orderId, data) => (dispatch) => {
  return Axios.post(`/api/v1/orders/${orderId}/order_documents`, data)
    .then((res) => {
      let data = res.data
      data.format = getUrlExtension(data.asset.url),
      dispatch({
      // 
        type: ADD_ORDER_DOCUMENT,
        payload: data,
      });
      return {success: true}
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      return {success: false}
    });
};

// export const updateOrderDocument = (
//   orderId,
//   contractId,
//   postVariables,
// ) => (dispatch) => {
//   Axios.put(`/api/v1/orders/${orderId}/order_documents/${contractId}`, postVariables)
//     .then((res) => {
//       dispatch({
//         type: UPDATE_ORDER_DOCUMENT,
//         payload: res.data,
//       });
//     })
//     .catch((err) => {
//       dispatch({
//         type: GET_ERRORS,
//         payload: err.response.data,
//       });
//     });
// };

export const deleteOrderDocument = (orderId, contractId) => (dispatch) => {
  Axios.delete(`/api/v1/orders/${orderId}/order_documents/${contractId}`)
    .then((res) => {
      dispatch({
        type: DELETE_ORDER_DOCUMENT,
        payload: {order_id: orderId, id: contractId},
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
