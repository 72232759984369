import React from "react";
import { Input } from "antd";
const { TextArea } = Input;

const TextAreaFieldGroup = ({
  name,
  placeholder,
  value,
  error,
  info,
  onChange,
  disabled,
  rows,
  onlyPlaceholder,
  labelStyle,
  validate
}) => {
  return (
    <div className="form-group mb-0">
      <label className={labelStyle ? "editLabelForm2" : "editLabelForm my-1 "}>{placeholder}{validate && <span className="text-orange"> *</span>}</label>
      <TextArea
        rows={rows}
        placeholder={onlyPlaceholder || placeholder}
        name={name}
        value={value || ""}
        onChange={onChange}
        className={`form-control form-control-lg editInputForm ${
          error && "is-invalid error_border"
        }`}
        disabled={disabled || false}
      />
      {info && <small className="form-text text-muted">{info}</small>}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

TextAreaFieldGroup.defaultProps = {
  rows: 4,
}

export default TextAreaFieldGroup;
