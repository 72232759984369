import React, { useState, useEffect } from "react";
import { ImQrcode } from "react-icons/im";
import styled from "styled-components";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import Logo from "../../../../assets/images/techno_logo_black.svg";
import { AlertError } from "../../common/Alerts";
import { logoutUser } from "../../../redux/Actions/authAction";

function Unavaible() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [code, setCode] = useState("");
  const [qrCode, setQrCode] = useState("");
  useEffect(() => {
    const fetchQrCode = async () => {
      const { data } = await Axios.get(
        "/api/v1/two_factor_settings/render_code"
      );
      if (data.qrcode) {
        setQrCode(data.qrcode);
      }
    };
    fetchQrCode();
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const params = {
        two_fa: {
          code,
        },
      };
      const { data } = await Axios.post(
        "/api/v1/two_factor_settings/set_auth_code",
        params
      );

      if (data.success) {
        dispatch({
          type: "SUCCESS_AUTHENTICATED",
        });
        const authenticate = user.iat + "" + user.exp;

        localStorage.setItem("trAuthenticate", authenticate);
      } else {
        setCode("");
        document.getElementById("authError").style.display = "block";
        setTimeout(
          () => (document.getElementById("authError").style.display = "none"),
          5000
        );
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const goBack = () => {
    dispatch(logoutUser());
  };

  const addClass = (value) => {
    const inputName = document.querySelector(".input-div.two");
    if (value == "") {
      inputName.classList.remove("focus");
    } else {
      inputName.classList.add("focus");
    }
  };

  return (
    <LoginContainer show={!!qrCode}>
      <div className="lg-container">
        <div className="lg-img">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img src={qrCode ? qrCode : Logo} />
            <h2
              className="font-semi-bold"
              style={{
                letterSpacing: 16,
              }}
            >
              THUB
            </h2>{" "}
          </div>
          {qrCode && (
            <a href="#" style={{ textAlign: "center" }}>
              Nuskanuokite QR kodą naudojant “Google” autentifikavimo priemonę
              arba “Twillio authy” aplikaciją.
            </a>
          )}
        </div>
        <AlertError title="2FA autentifikacija" alertId={"authError"} />

        <div className="login-container">
          <form className="lg" onSubmit={onSubmit}>
            <h2 className="title">2FA autentifikacija</h2>
            <div className="input-div two">
              <div className="i">
                <ImQrcode />
              </div>
              <div className="div">
                <h5>Kodas</h5>
                <input
                  type="number"
                  name="code"
                  className="input"
                  value={code}
                  onChange={(e) => {
                    addClass(e.target.value);
                    setCode(e.target.value);
                  }}
                  //   onChange={(e) => this.handleChange(e)}
                />
              </div>
            </div>
            <a to="#" style={{ textAlign: "left" }}>
              Prisijunkite įvesdami programėlėje nurodytą kodą{" "}
            </a>
            <input type="submit" className="logButton" value="Prisijungti" />
            <button
              onClick={() => goBack()}
              className="logButton bg-danger"
              style={{ backgroundImage: "none" }}
            >
              Grįžti atgal
            </button>
          </form>
        </div>
      </div>
    </LoginContainer>
  );
}

export default Unavaible;

const LoginContainer = styled.div`
  .lg-container {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 7rem;
    padding: 0 2rem;
    background-color: #f3f3f3;
  }
  .lg-img {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    justify-content: center;
    align-items: center;
  }
  .lg-img img {
    width: 500px;
  }
  .login-container {
    display: flex;
    align-items: center;
    text-align: center;
  }

  form.lg {
    width: 360px;
  }

  .lg-avatar {
    width: 100px;
  }

  form.lg h2 {
    font-size: 2.9rem;
    text-transform: uppercase;
    margin: 15px 0;
    color: #333;
  }
  .input-div {
    display: grid;
    grid-template-columns: 7% 93%;
    margin: 25px 0;
    padding: 5px 0;
    border-bottom: 2px solid #d9d9d9;

    &:after,
    &:before {
      content: "";
      position: absolute;
      bottom: -2px;
      width: 0;
      height: 2px;
      background-color: #f7931d;
      transition: 0.3s;
    }

    &:after {
      right: 50%;
    }
    &::before {
      left: 50%;
    }
    &.focus {
      & .i svg {
        color: #f7931d;
      }
      & div h5 {
        top: -5px;
        font-size: 15px;
      }
      &:after,
      &:before {
        width: 50%;
      }
    }
  }

  .input-div.one {
    margin-top: 0;
  }

  .input-div.two {
    margin-bottom: 4px;
  }

  .i {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .i svg {
    color: #d9d9d9;
    transition: 0.3s;
  }

  .input-div > div {
    position: relative;
    height: 45px;
  }

  .input-div > div h5 {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    font-size: 18px;
    transition: 0.3s;
  }
  .input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: none;
    outline: none;
    background: none;
    padding: 0.5rem 0.7rem;
    font-size: 1.2rem;
    color: #555;
  }

  a {
    display: block;
    text-align: right;
    text-decoration: none;
    color: #999;
    font-size: 0.9rem;
    transition: 0.3s;
    &:hover {
      color: #f7931d;
    }
  }

  .logButton {
    display: block;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    margin: 1rem 0;
    font-size: 1.2rem;
    outline: none;
    border: none;
    background-image: linear-gradient(to right, #f7931d, #f7941f, #ff9517);
    cursor: pointer;
    color: #fff;
    background-size: 200%;
    transition: 0.5s;
    &:hover {
      background-position: right;
    }
  }

  @media screen and (max-width: 1050px) {
    .lg-container {
      grid-gap: 5rem;
    }
  }

  @media screen and (max-width: 1000px) {
    form.lg {
      width: 290px;
    }

    form.lg h2 {
      font-size: 2.4rem;
      margin: 8px 0;
    }
  }

  @media screen and (max-width: 900px) {
    .lg-container {
      grid-template-columns: 1fr;
    }

    .login-container {
      justify-content: center;
    }
    .lg-img {
      justify-content: center;
      display: ${(props) => (props.show ? "block" : "none")};
    }
  }
`;
