import React from "react";
import styled from "styled-components";

function ServiceAddContainer({ children, level }) {
  return (
    <div className="col-12">
      <LineSelector>
        <div
          className={`${level >= 1 ? "selected" : "selected-none"} ${
            level == 1 && "border_radius"
          }`}
        >
          1
        </div>
        <div
          className={`${level >= 2 ? "selected" : "selected-none"} ${
            level == 2 && "border_radius"
          }`}
        >
          2
        </div>
        <div className={level >= 3 ? "selected" : "selected-none"}>3</div>
      </LineSelector>
      <div className="mb-1 bg-white py-3">
        <div className="col-12">{children}</div>
      </div>
    </div>
  );
}

const LineSelector = styled.div`
  display: flex;
  height: 16px;
  font-size: 10px;
  background-color: #e5e5e5;

  .border_radius {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .selected {
    flex: 1;
    display: flex;
    justify-content: center;
    background-color: #e89423;
    color: #fff;
    font-weight: bold;
  }
  .selected-none {
    flex: 1;
    display: flex;
    color: #aeaeae;
    justify-content: center;
  }
`;

export default ServiceAddContainer;
