import React from "react";

function ModalSimple(props) {
  return (
    <div className={"modal-wrapper"}>
      <div onClick={() => props.onClose()} className={"modal-backdrop"} />
      <div className="modal-dialog modal-edit" style={{ zIndex: 101 }}>
        <div className="modal-content" style={{ height: "100vh" }}>
          <div className="modal-header">
            <h5 className="modal-title">{props.text}</h5>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => props.onClose()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            className="modal-body"
            style={{ height: "100vh", overflow: "auto" }}
          >
            {props.children}
          </div>
          <div className="modal-footer">
            {props.secondButton && (
              <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={(e) => props.secondButton()}
              >
                {props.secondButtonText}
              </button>
            )}
            <button
              type="button"
              className={"btn btn-secondary btn-block"}
              onClick={() => props.onClose()}
            >
              Uždaryti
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalSimple;
