import { Input, Spin } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { MdEdit } from "react-icons/md";
import { FaCheck, FaTimes } from "react-icons/fa";
import { updateOrderCartRules } from "../../../../../../redux/Actions/orderAction";

function OrderCartRulesComponent({
  order_cart_rule,
  orderId,
  total_price,
  disabled = false,
}) {
  const dispatch = useDispatch();
  const [editCart, setEditCart] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalCart, setTotalCart] = useState(order_cart_rule.total);
  const onSaveHandler = () => {
    setLoading(true);
    const params = {
      cart_rules: {
        order_id: order_cart_rule.order_id,
        title: order_cart_rule.title,
        total: totalCart,
        // total_tax_excl: Number(totalCart) * 1.21,
        id_order_cart_rule: order_cart_rule.id_order_cart_rule,
      },
    };

    dispatch(updateOrderCartRules(order_cart_rule.id, orderId, params)).then(
      (res) => {
        if (res) {
          setEditCart(false);
          setError(false);
        } else {
          setError(true);
        }
        setLoading(false);
      }
    );
  };

  const handleClose = () => {
    if (editCart) {
      setEditCart(false);
      setError(false);
    } else {
      setEditCart(true);
    }
  };
  return (
    <React.Fragment key={order_cart_rule.id}>
      <div className="ml-1 d-flex justify-content-between">
        <div>
          <span
            className={`${error ? "text-danger" : ""} text-bold ${
              editCart ? "blink" : ""
            }`}
          >
            Nuolaida: {order_cart_rule.title}
          </span>{" "}
          {!editCart && <span>{order_cart_rule.total} &#8364;</span>}
          {editCart && (
            <Input
              type="number"
              placeholder="Įvesti"
              min={0}
              max={+total_price + +order_cart_rule.total}
              value={totalCart}
              onChange={(e) => setTotalCart(e.target.value)}
            />
          )}
        </div>
        {orderId && (
          <div className="d-flex flex-column">
            {editCart ? (
              <>
                <span className={`text-bold invisible`}>func</span>
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "100%" }}
                >
                  {loading ? (
                    <Spin />
                  ) : (
                    <>
                      <FaCheck
                        className="ml-3 cursor-pointer"
                        color="green"
                        size={18}
                        onClick={() => onSaveHandler()}
                      />
                      <FaTimes
                        className="ml-3 cursor-pointer"
                        size={18}
                        color="red"
                        onClick={() => handleClose()}
                      />
                    </>
                  )}
                </div>
              </>
            ) : disabled ? null : (
              <MdEdit
                className="cursor-pointer"
                color="#f0a22c"
                size={18}
                onClick={() => handleClose()}
              />
            )}
          </div>
        )}
      </div>
      <hr className="my-2" />
    </React.Fragment>
  );
}

export default OrderCartRulesComponent;
