import React from "react";
import { ButtonEdit } from "../../common/Buttons";
import { Dot } from "../../common/Dot";
import { Checkbox } from "antd";



function TableColumnSms({ item, index, onEdit, setSelected, selected }) {
  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle">
        <Checkbox onChange={setSelected} checked={selected} />
      </td>
      <td className="align-middle">{item.name}</td>
      <td className="align-middle">{item.synonim}</td>
      <td className="align-middle"><Dot value = {item.marked}/></td>
      <td className="align-middle"><Dot value = {item.printed}/></td>
      <td className="align-middle text-center">{item.family_attribute_groups_count}</td>
      <td className="align-middle text-center">{item.active_attribute_groups_count}</td>
      <td className="align-middle text-center">{item.format}</td>
      <td className="align-middle" style={{ width: "100px" }}>
        <ButtonEdit onEdit={() => onEdit("edit",item.id)} />
      </td>
    </tr>
  );
}

export default TableColumnSms;
