import Axios from "axios";

export const fetchUserRole = () => async (dispatch) => {
  try {
    dispatch({
      type: "LOADING_CUSTOM",
    });
    const { data } = await Axios.get("/api/v1/user_roles");
    dispatch({
      type: "GET_CUSTOMS",
      payload: { data, current: "Rolės" },
    });
    return { success: true };
  } catch (err) {
    dispatch({
      type: "STOP_LOADING_CUSTOM",
    });
    return { success: false };
  }
};

export const updateUserRole = (role_id, params) => async (dispatch) => {
  try {
    dispatch({
      type: "LOADING_ONLY_CUSTOM",
    });
    const { data } = await Axios.put(`/api/v1/user_roles/${role_id}`, {
      user_roles: params,
    });
    dispatch({
      type: "UPDATE_CUSTOM",
      payload: data,
    });
    return { success: true };
  } catch (err) {
    dispatch({
      type: "STOP_LOADING_CUSTOM",
    });
    return { success: false };
  }
};

export const removeUserRole = (role_id) => async (dispatch) => {
  try {
    dispatch({
      type: "LOADING_ONLY_CUSTOM",
    });
    await Axios.delete(`/api/v1/user_roles/${role_id}`);
    dispatch({
      type: "REMOVE_CUSTOM",
      payload: role_id,
    });
    return { success: true };
  } catch (err) {
    dispatch({
      type: "STOP_LOADING_CUSTOM",
    });
    return { success: false };
  }
};

export const postUserRole = (params) => async (dispatch) => {
  try {
    dispatch({
      type: "LOADING_ONLY_CUSTOM",
    });
    const { data } = await Axios.post("/api/v1/user_roles", {
      user_roles: params,
    });
    console.log(data);
    dispatch({
      type: "POST_CUSTOM",
      payload: data,
    });
    return { success: true };
  } catch (err) {
    dispatch({
      type: "STOP_LOADING_CUSTOM",
    });
    return { success: false };
  }
};
