import React from "react";
import Container from "../Layout/Container";
import { connect } from "react-redux";
import {
  getSuppliersInvoices,
  getSupplierInvoice,
  deleteSuppliersInvoice,
  clearErrors,
} from "../../redux/Actions/suppliersInvoicesAction";
import { getStores } from "../../redux/Actions/rivileAction";
import InvoicesEditModal from "./Items/InvoicesEditModal";
import ContentBlock from "../Layout/ContentBlock";
import TableColumnInvoices from "./Items/TableColumnInvoices";
import InputText from "../orders/accepted_orders/InputFilter";
import FilterMenu from "../Layout/FilterMenu";
import TableFilterBox from "../common/TableFilterBox";
import ContentValidation from "../common/ContentValidation";
import PaginationComp from "../Dashboard/items/Pagination";
import RangePickerComp from "../common/RangePickerComp";
import { Upload, message, Spin } from "antd";
import SwitchComp from "../common/SwitchComp";
import styled from "styled-components";
import moment from "moment";
import { Label } from "../global";

class SuppliersInvoices extends React.Component {
  constructor() {
    super();
    this.state = {
      requiredId: 0,
      page: 1,
      pageCount: 100,
      paramsFilter: "",
      action: "new",
      excel_loading: false,
      filter: {
        invoice_date: ["", ""],
        pay_due_date: ["", ""],
        invoice_number: "",
        supplier: "",
        sales_number: "",
        exported: false,
      },
    };
  }

  componentDidMount() {
    this.props.getStores();
    this.props.getSuppliersInvoices(
      this.state.page,
      this.state.pageCount,
      this.state.paramsFilter
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.props.getSuppliersInvoices(
        this.state.page,
        this.state.pageCount,
        this.state.paramsFilter
      );
    }
  }

  onAction = (action, index) => {
    this.setState({
      requiredId: index,
      action: action,
    });
    this.props.getSupplierInvoice(index, this.state.paramsFilter);
  };

  setPagination = (page) => {
    this.setState({
      page,
    });
  };

  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  renderImportButton = () => {
    const props = {
      name: "file",
      action: `/api/v1/suppliers_invoices_documents`,
      showUploadList: false,
      beforeUpload: this.beforeUpload,
      onChange: this.onChange,
    };

    return (
      <>
        <Upload {...props} className="float-right mr-3">
          <StyledButton
            className="btn btn-sm btn-primary"
            disabled={this.state.excel_loading}
          >
            {this.state.excel_loading ? (
              <Spin size="small" />
            ) : (
              "Įkelti sąskaitą"
            )}
          </StyledButton>
        </Upload>
      </>
    );
  };

  beforeUpload = (file) => {
    const isExcel =
      file.type.includes("application") &&
      (file.name.split(".")[1] === "pdf" || file.name.split(".")[1] === "PDF");
    if (!isExcel) {
      message.error("Failas privalo būti .pdf formato!");
    } else {
      this.setState({
        // start loading
        excel_loading: true,
      });
    }

    return isExcel;
  };

  onChange = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} failas sėkmingai įkeltas!`);
      this.setState({
        // stop loading
        excel_loading: false,
      });
      this.reloadData("");
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} failo įkėlimas nepavyko!`);
      this.setState({
        // stop loading
        excel_loading: false,
      });
    }
  };

  clearFilter = () => {
    this.setState(
      {
        paramsFilter: "",
        filter: {
          invoice_date: ["", ""],
          pay_due_date: ["", ""],
          invoice_number: "",
          supplier: "",
          sales_number: "",
          exported: false,
        },
      },
      () => this.reloadData("")
    );
  };

  getDateFormat = (date) => {
    var d = moment(date);
    return date && d.isValid() ? d : "";
  };

  filterChange = (e, date) => {
    if (date) {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            [date]: e,
          },
        },
        () => this.onFilter()
      );
    } else {
      this.setState({
        filter: {
          ...this.state.filter,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  handleKeyDown = (e) => {
    if (e.key == "Enter") {
      this.onFilter();
    }
  };

  delayOnFilter = () => {
    if (this.props.loading_invoices) {
      return setTimeout(() => {
        return this.delayOnFilter();
      }, 1000);
    }
    this.onFilter();
  };

  onFilter = () => {
    let filterParams = [];

    if (this.props.loading_invoices) {
      return this.delayOnFilter();
    }

    if (this.state.filter.supplier.length > 0) {
      filterParams.push(`&q[supplier_name_cont]=${this.state.filter.supplier}`);
    }

    if (this.state.filter.sales_number.length > 0) {
      filterParams.push(
        `&q[sales_number_cont]=${this.state.filter.sales_number}`
      );
    }

    if (this.state.filter.invoice_number.length > 0) {
      filterParams.push(
        `&q[invoice_number_cont]=${this.state.filter.invoice_number}`
      );
    }

    if (this.state.filter.exported) {
      filterParams.push(`&q[exported_eq]=1`);
    }

    if (this.state.filter.invoice_date[0].length > 0) {
      filterParams.push(
        `&q[invoice_date_gteq]=${this.state.filter.invoice_date[0]}`
      );
    }

    if (this.state.filter.invoice_date[1].length > 0) {
      filterParams.push(
        `&q[invoice_date_lteq]=${this.state.filter.invoice_date[1]}`
      );
    }

    if (this.state.filter.pay_due_date[0].length > 0) {
      filterParams.push(
        `&q[pay_due_date_gteq]=${this.state.filter.pay_due_date[0]}`
      );
    }

    if (this.state.filter.pay_due_date[1].length > 0) {
      filterParams.push(
        `&q[pay_due_date_lteq]=${this.state.filter.pay_due_date[1]}`
      );
    }

    this.setState(
      {
        paramsFilter: filterParams.join(""),
        page: 1,
      },
      () => this.reloadData(filterParams.join(""))
    );
  };

  reloadData = (data) => {
    this.props.getSuppliersInvoices(
      this.state.page,
      this.state.pageCount,
      data
    );
  };

  render() {
    return (
      <Container
        location={{
          locationName: "Tiekėjų sąskaitos",
          locationUrl: this.props.match.url,
        }}
      >
        <ContentBlock>
          <FilterMenu
            length={`${this.props.totals || 0} / ${
              this.props.invoices.length || 0
            }`}
            onChangeCountPage={this.setPageCount}
            countPage={this.state.pageCount}
            text="sąskaitų"
            text2="Sąskaitų"
          />
        </ContentBlock>
        <ContentBlock>
          <TableFilterBox
            clearFilter={() => this.clearFilter()}
            importPayModulePlans={this.renderImportButton()}
          >
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="supplier"
                placeholder="Tiekėjas"
                value={this.state.filter.supplier}
                onKeyDown={this.handleKeyDown}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="sales_number"
                placeholder="Užsakymo numeris"
                value={this.state.filter.sales_number}
                onKeyDown={this.handleKeyDown}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="invoice_number"
                placeholder="Sąsk. numeris"
                value={this.state.filter.invoice_number}
                onKeyDown={this.handleKeyDown}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1 pr-4">
              <SwitchComp
                name="exported"
                placeholder="Eksportuota"
                checked={this.state.filter.exported}
                onChange={() =>
                  this.setState(
                    {
                      filter: {
                        ...this.state.filter,
                        exported: !this.state.filter.exported,
                      },
                    },
                    () => this.onFilter()
                  )
                }
              />
            </div>
            <div className="px-1" style={{ width: "20rem" }}>
              <div className="form-group">
                <Label>Sąskaitos data</Label>
                <div>
                  <RangePickerComp
                    placeholder={["Nuo", "Iki"]}
                    value={[
                      this.getDateFormat(this.state.filter.invoice_date[0]),
                      this.getDateFormat(this.state.filter.invoice_date[1]),
                    ]}
                    onChange={(e, args) =>
                      this.filterChange(args, "invoice_date")
                    }
                  />
                </div>
              </div>
            </div>
            <div className="px-1" style={{ width: "20rem" }}>
              <div className="form-group">
                <Label>Apmokėti iki</Label>
                <div>
                  <RangePickerComp
                    placeholder={["Nuo", "Iki"]}
                    value={[
                      this.getDateFormat(this.state.filter.pay_due_date[0]),
                      this.getDateFormat(this.state.filter.pay_due_date[1]),
                    ]}
                    onChange={(e, args) =>
                      this.filterChange(args, "pay_due_date")
                    }
                  />
                </div>
              </div>
            </div>
          </TableFilterBox>
          <div>
            <ContentValidation
              loader={this.props.loading_invoices}
              array={this.props.invoices}
              alertId_s="invoiceAlert-s"
              alertId_e="invoiceAlert-e"
            >
              <div className="table-responsive">
                <table className="table table-sm table-hover mt-3">
                  <thead>
                    <tr className="text-muted">
                      <th className="text-black">Eksportuota</th>
                      <th className="text-black">Tiekėjas</th>
                      <th className="text-black">Sąskatos numeris</th>
                      <th className="text-black">Sąskaitos data</th>
                      <th className="text-black">Apmokėjimo data</th>
                      <th className="text-black">Prekių kiekis</th>
                      <th className="text-black">Suma be pvm</th>
                      <th className="text-black">PVM</th>
                      <th className="text-black">Nuolaida</th>
                      <th className="text-black">Galutinė suma</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.invoices.map((item, index) => {
                      return (
                        <TableColumnInvoices
                          key={index}
                          index={index}
                          id={item.id}
                          item={item}
                          onAction={this.onAction}
                          onDelete={this.props.deleteSuppliersInvoice}
                        />
                      );
                    })}
                  </tbody>
                </table>
                <PaginationComp
                  setPagination={this.setPagination}
                  page={this.state.page}
                  totals={this.props.totals}
                  pageCount={this.state.pageCount}
                />
              </div>
            </ContentValidation>
          </div>
        </ContentBlock>

        <InvoicesEditModal
          action={this.state.action}
          objectData={this.props.invoice}
          clearErrors={this.props.clearErrors}
          stores={this.props.stores}
          errors={this.props.errors}
        />
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  invoices: state.supplierInvoices.invoices,
  invoice: state.supplierInvoices.invoice,
  totals: state.supplierInvoices.totals,
  loading_invoices: state.supplierInvoices.loading_invoices,
  stores: state.rivile.rivileStores,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getSuppliersInvoices,
  getSupplierInvoice,
  clearErrors,
  deleteSuppliersInvoice,
  getStores,
})(SuppliersInvoices);

const StyledButton = styled.button`
  width: 112px;
  /* height: 29px; */
  background-color: rgb(242, 147, 2);
  border-radius: 3px;
  font-size: 13px;
  color: #fff;
  font-weight: 300;
  border-width: 0;

  &:hover {
    background-color: #fff;
    color: rgb(242, 147, 2);
  }
  &:active,
  &:focus {
    background-color: rgb(242, 147, 2);
    color: #fff;
  }
`;
