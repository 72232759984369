import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ServiceAddContainer from "./ServiceAddContainer";
import styled from "styled-components";
import ServiceForms_1 from "./ServiceForms/ServiceForms_1";
import ServiceForms_2 from "./ServiceForms/ServiceForms_2";
import ServiceForms_3 from "./ServiceForms/ServiceForms_3";
import Axios from "axios";
import { postService } from "../../../redux/Actions/Service/serviceAction";
import {
  antdMessageError,
  antdMessageSuccess,
  emailValid,
  phonenumberValid,
} from "../../../helpers/Utils";
import { message } from "antd";

function ServiceScreenAdd({ onClose }) {
  const dispatch = useDispatch();
  const [level, setLevel] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searched, setSearched] = useState(false);
  const [searching, setSearching] = useState(false);

  const [information, setInformation] = useState({
    client: {
      client_name: "",
      client_lastname: "",
      client_phone: "",
      client_mail: "",
      client_city: "",
      client_street: "",
      client_postal_code: "",
    },
    form: {
      type: "",
      goods: [],
    },
    description: {
      breaks: "",
      custom: "",
      goods_outside: "",
      complete_set: "",
    },
  });

  const [errorsState, setErrors] = useState({});

  const [attachments, setAttachments] = useState({
    defect_act: {
      boolean: false,
      docs: [],
    },
    goods_appearance: {
      boolean: false,
      docs: [],
    },
    complaint: {
      boolean: false,
      docs: [],
    },
    custom: {
      boolean: true,
      docs: [],
    },
  });

  const onChangeHandler = (e, position, item) => {
    const { name, value } = e.target;
    if (item) {
      setInformation((prev) => ({
        ...prev,
        [position]: {
          ...information[position],
          item_name: item.title,
          item_price: item.price,
          item_code: item.item_code || "",
          serial_number: item.serial_number || "",
        },
      }));
      // return
      setErrors({
        ...errorsState,
        item_name: item.title ? false : true,
        item_price: item.price || item.price == 0 ? false : true,
        serial_number: item.serial_number || "",
      });
    } else {
      setInformation((prev) => ({
        ...prev,
        [position]: {
          ...information[position],
          [name]: value,
        },
      }));
      // return
      setErrors({
        ...errorsState,
        [name]: value ? false : true,
      });
    }
  };

  const onChangeHandlerMass = (items) => {
    setInformation((prev) => ({
      ...prev,
      client: {
        ...prev.client,
        ...items,
      },
    }));
  };

  const onChangeAttachments = (name, nameAction, value) => {
    setAttachments({
      ...attachments,
      [name]: {
        ...attachments[name],
        [nameAction]: value,
      },
    });
  };

  const onChangeType = (e) => {
    const { name, value } = e.target;

    setInformation({
      ...information,
      form: {
        [name]: value,
        goods:
          value == "order"
            ? [
                {
                  item_name: "",
                  item_price: "",
                  item_quantity: "",
                },
              ]
            : [],
      },
    });
    // return
    setErrors({});
    setSearched(false);
  };

  const onSelectType = () => {
    const value = information.form.type;
    let strings = {};

    if (value == "Garantinis" || value == "Defekto aktas") {
      const document_type_selected =
        information.form.document_type == "invoice"
          ? "sf"
          : information.form.document_type == "cheque"
          ? "pos"
          : false;

      strings = {
        document_type: document_type_selected,
        document_identifier: information.form.buy_document,
      };
    } else if (value == "PPG") {
      strings = {
        document_type: "rpg",
        document_identifier: information.form.certificate_number,
      };
    } else if (value == "Priešprekybinis") {
      strings = {
        document_type: "pre-trade",
        document_identifier: information.form.item_code,
      };
    }

    setSearching(true);
    //
    Axios.get(
      `/api/v1/repair_services/get_products/${strings.document_type}/${strings.document_identifier}`
    )
      .then((res) => {
        if (res.data) {
          const { client_info, products } = res.data.products;
          if (products.length > 0) {
            const client_info_atr = client_info ? client_info : {};
            const rebuildPhoneNumber = (number) => {
              if (!number) return "";
              if (number) {
                let number_string = number + "";
                if (number_string.startsWith("370")) {
                  return number_string.split("370").join("");
                } else if (number_string.startsWith("+370")) {
                  return number_string.split("+370").join("");
                }
                return number;
              }
              return "";
            };
            setInformation((prev) => ({
              ...prev,
              form: {
                ...prev.form,
                goods: products,
                item_name: "",
                item_code: "",
                serial_number: "",
              },
              client:
                information?.form?.type == "Priešprekybinis"
                  ? information.client
                  : {
                      client_name: client_info_atr.firstname || "",
                      client_lastname: client_info_atr.lastname || "",
                      client_phone: typeof client_info_atr.phone
                        ? rebuildPhoneNumber(client_info_atr.phone)
                        : "",
                      client_mail: client_info_atr.other || "",
                      client_city: client_info_atr.city || "",
                      client_street: client_info_atr.street || "",
                      client_postal_code: client_info_atr.postal_code || "",
                    },
            }));
            antdMessageSuccess();
          } else {
            setInformation({
              ...information,
              client:
                information?.form?.type == "Priešprekybinis"
                  ? information.client
                  : {
                      client_name: "",
                      client_lastname: "",
                      client_phone: "",
                      client_mail: "",
                      client_city: "",
                      client_street: "",
                      client_postal_code: "",
                    },
              form: {
                ...information.form,
                goods: [],
                item_name: "",
                item_code: "",
                serial_number: "",
              },
            });
            antdMessageError("Nerasta...");
          }
          setSearched(true);
        }
        setSearching(false);
      })
      .catch((err) => {
        antdMessageError();
        setSearching(false);
      });
  };

  const onSave = async () => {
    try {
      let errors = {};
      if (level == 1 && information.form.type == "order") {
        const available_step_next = information.form.goods.every(
          (d) => d.item_name && d.item_quantity
        );
        if (available_step_next) {
          setLevel(level + 1);
          setErrors({});
        } else {
          message.error(
            "Užpildykite visus prekių pavadinimus, bei kiekius tinkamai."
          );
        }
        return;
      }
      if (level === 1) {
        if (!information.form.type) {
          errors.type = "Pasirinkite tipa.";
        } else {
          const value = information.form.type;

          if (
            !["Priešprekybinis"].includes(value) &&
            !information.form.serial_number
          ) {
            errors.serial_number = "Privalomas";
          }

          if (
            ["Garantinis"].includes(value) &&
            [null, false, undefined, 0, ""].includes(
              information.form.buy_document
            )
          ) {
            errors.buy_document = "Privalomas";
          }

          if (
            ["Garantinis", "Defekto aktas"].includes(value) &&
            [null, false, undefined, 0, ""].includes(information.form.buy_date)
          ) {
            errors.buy_date = "Privalomas";
          }

          if (
            [null, false, undefined, 0, ""].includes(information.form.item_name)
          ) {
            errors.item_name = "Privalomas";
          }
          if (
            ["Garantinis"].includes(value) &&
            [null, false, undefined, 0, ""].includes(
              information.form.document_type
            )
          ) {
            errors.document_type = "Privalomas";
          }
          if (
            ["Defekto aktas", "Garantinis"].includes(value) &&
            [null, false, undefined, 0, ""].includes(
              information.form.buy_document
            )
          ) {
            errors.buy_document = "Privalomas";
          }

          if (
            ["PPG"].includes(value) &&
            [null, false, undefined, 0, ""].includes(
              information.form.certificate_number
            )
          ) {
            errors.certificate_number = "Privalomas";
            errors.certificate_date = "Privalomas";
          }

          if (
            ["Priešprekybinis"].includes(value) &&
            [null, false, undefined, 0, ""].includes(information.form.item_code)
          ) {
            errors.item_code = "Privalomas";
          }
          if (
            ["Priešprekybinis"].includes(value) &&
            [null, false, undefined, 0, ""].includes(
              information.form.rivile_store_id
            )
          ) {
            errors.rivile_store_id = "Privalomas";
          }
        }
        if (!information.form.item_name) {
          message.error("Pasirinkite prekę arba pradėkite paiešką");
        }
      } else if (level === 2) {
        Object.entries(information.client).forEach((e) => {
          if (e[0] === "client_phone" && !phonenumberValid("+370" + e[1])) {
            errors[e[0]] = "Pasitikrinkite telefono numerį";
          } else if (e[0] === "client_mail") {
            if (e[1].trim() !== "") {
              if (!emailValid(e[1])) {
                errors[e[0]] = "Pasitikrinkite elektroninį paštą";
              }
            }
          } else if (e[1].trim() == "") {
            errors[e[0]] = "Privalomas";
          }
        });
      } else if (level === 3) {
        if (information.description.breaks.trim() === "") {
          errors.breaks = "Privalomas";
        }
      }

      if (Object.keys(errors).length > 0) {
        return setErrors(errors);
      }

      //

      if (["Priešprekybinis"].includes(information.form.type) && level == 1) {
        // setInformation({
        //   ...information,
        //   client: {
        //     ...information.client,
        //     client_name: "testas",
        //     client_lastname: "testas",
        //     client_phone: "60000000"
        //   },
        // });
        setErrors({});
        return setLevel(3);
      }
      //
      setLevel(level + 1);
      setErrors({});
      if (level === 3 || information.form.type == "order") {
        setLoading(true);
        dispatch(postService(information, attachments)).then((res) => {
          if (res.success) {
            setLoading(false);
            onClose(false);
          } else {
            setLoading(false);
            setLevel(1);
          }
        });
      }
    } catch (err) {
      console.log("error: ", err);
    }
  };

  return (
    <>
      <div className="col-12">
        <h6 className="font-weight-bold" style={{ fontSize: "21px" }}>
          Naujas gedimo dokumentas
          {information.form.type && `: ${information.form.type}`}
        </h6>
      </div>
      <ServiceAddContainer level={level}>
        {level == 1 ? (
          <ServiceForms_1
            form={information.form}
            onChangeType={onChangeType}
            onChangeHandler={onChangeHandler}
            onSelectType={onSelectType}
            errors={errorsState}
            searching={searching}
            onChangeHandlerMass={onChangeHandlerMass}
          />
        ) : level == 2 ? (
          <ServiceForms_2
            client={information.client}
            service_type={information?.form?.type || ""}
            onChangeHandler={onChangeHandler}
            onChangeHandlerMass={onChangeHandlerMass}
            errors={errorsState}
            attachments={attachments}
            onChangeAttachments={onChangeAttachments}
            description={information.description}
          />
        ) : (
          <ServiceForms_3
            description={information.description}
            attachments={attachments}
            onChangeHandler={onChangeHandler}
            onChangeAttachments={onChangeAttachments}
            loading={loading}
            errors={errorsState}
          />
        )}
        {information.form.goods.length == 0 && searched && (
          <div className="pt-4">
            <h5 className="font-weight-bold">Prekės nerastos</h5>
          </div>
        )}
        <div className="position-relative mt-4">
          <CustomButton
            className="btn btn-sm btn-outline-danger"
            style={{ visibility: level == 1 ? "visible" : "hidden" }}
            onClick={() => onClose(false)}
          >
            Atšaukti
          </CustomButton>
          <div
            className="position-absolute"
            style={{
              top: 0,
              left: "50%",
              transform: "translate(-50%, -0%)",
            }}
          >
            <CustomButton
              className="btn btn-sm btn-primary mr-1"
              style={{ visibility: level > 1 ? "visible" : "hidden" }}
              onClick={() =>
                information?.form?.type == "Priešprekybinis"
                  ? setLevel(1)
                  : setLevel(level - 1)
              }
            >
              Atgal
            </CustomButton>
            <CustomButton
              className="btn btn-sm btn-danger ml-1"
              onClick={() => onSave()}
            >
              {level !== 3 ? "Toliau" : "Baigti"}
            </CustomButton>
          </div>
        </div>
      </ServiceAddContainer>
    </>
  );
}

const CustomButton = styled.button`
  padding: 0.25rem 0.95rem;
  border-radius: 0.1rem;
  font-size: 13px;
`;
export default ServiceScreenAdd;
