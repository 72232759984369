import React, { Fragment } from "react";
import styled from "styled-components";
import {
  SelectForm,
  MainBlock,
  MiniBlock,
  MiniBlockOrderRows,
} from "../../../common/Buttons";
import { Spin } from "antd";
import PreviewComment from "./editModal/previewComment";
import { connect } from "react-redux";
import SubmitComment from "./editModal/submitComment";
import { postComments } from "../../../../redux/Actions/commentAction";
import { editPayment } from "../../../../redux/Actions/paymentAction";
import {
  postOrderRowGoods,
  updateOrderRowGoods,
  deleteOrderRowGoods,
  deleteOrderRow,
  updateSerialNumber,
  updateSerialNumberState,
  clearErrors,
  postToRivile,
  postOrderShipping,
  updateOrderAddress,
  orderCreateInvoice,
  postOrderRow,
} from "../../../../redux/Actions/orderAction";
import {
  addOrderDocument,
  deleteOrderDocument,
} from "../../../../redux/Actions/orderDocumentAction";
import {
  addContract,
  updateContract,
  deleteContractItem,
  deleteContract,
  firstAddContract,
  firstDeleteContract,
  changeContractItems,
} from "../../../../redux/Actions/orderContractAction";
import moment from "moment";
import Residuesitem from "./editModal/residuesItem";
import AdditionalServicesMain from "./editModal/AdditionalServices/AdditionalSerivcesMain";
import AttachOrderDocument from "./editModal/AttachOrderDocument/AttachOrderDocument";
import { SerialNumberBlock } from "../../../common/SerialNumberModal";
import Spinner from "../../../common/Spinner";
import { GrEdit, GrClose } from "react-icons/gr";
import ShippingAddressComponent from "./editModal/shippingAddress";
import BillingAddressComponent from "./editModal/billingAddress";
import OrderRowAdd from "./editModal/order_row_add";
import OrderRowsComponent from "./editModal/order_rows";
import PaymentBlockComponent from "./editModal/Payments/payments_block";
import OrderTotalComponent from "./editModal/SmallComp/order_total";
import SmsSending from "./editModal/SmallComp/sms_sending";
import {
  lengthOfCharactersDifference,
  phonenumberValid,
} from "../../../../helpers/Utils";
import DeliveryComponent from "./delivery/delivery_block";
import SupplyComponent from "./editModal/SupplyComponent";
import StatusComponent from "./StatusComponent";
import LeasingComponent from "./LeasingComponent";
import ShippingComponent from "./ShippingComponent";
import OrderCartRulesComponent from "./editModal/OrderCartRules/OrderCartRulesComponent";
import {
  onChangeCheckBoxDeliveryGlobal,
  handleChangeOrdertypeGlobal,
  orderTypeMount,
  orderDeliveryMount,
  setterOrderCod,
  have_product_reference,
} from "../../../../helpers/OrdersFunctionsGlobal";
import { shipment_types_options } from "../../../../helpers/GlobalOptions";
import { FaCopy } from "react-icons/fa";
import { AiOutlineInfoCircle } from "react-icons/ai";
import OrderHistoryById from "./editModal/OrderHistoryById";
import OrderAdditionals from "./OrderAdditional";
import { ruleCarrying } from "../../../../helpers/ShipmentRules";

class OrderShowModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      order_type: "dpd",
      delivery: "dpd_b2c",

      suppliers: undefined,
      weight: 0.1,
      pack: 1,
      carrying: false,
      same_day_delivery: false,
      cod: "",
      order_return: false,
      sendingDocuments: false,
      deliveryTime: false,
      deliveryOnSaturday: false,
      cod_amount: 0,
      who: "",
      deliveryTimeHours: ["", ""],
      shipping_loading: false,
      skipCarrying: false,
      skipCod: false,
      skipDeliveryOnSaturday: false,
      skipDeliveryTime: false,
      skipOrderReturn: false,
      skipSendingDocuments: false,
      skipSwap: false,
      // suppliersItems: [],
      swap: false,
      lp_express_sizes: undefined,

      shipping_address_edit: {},

      terminalSelected: undefined,
      // Collapse
      tableItemsCollapses: [],
      AdditionalServices: false,
      order_information: false,
      order_shipment_information: false,
      AttachDocuments: false,
      SmsSending: false,
      comment_col: false,
      ka_comment_col: false,
      log_comment_col: false,
      manag_comment_col: false,
      // client_comment: true,
      balances_left: false,
      serial_number: false,
      //
      information_leasing: false,
      information_goods: false,
      information_supply: false,
      information_balances: false,
      information_status: false,
      information_delivery: false,
      information_goods_add: false,
      payments_col: false,
      payments_add_col: false,
      delivery_add_col: false,
      order_history: false,
      service_history: false,

      //
      //
      selectedOrderRows: [],
      shipmentRowForStore: [],
      //
      // rivileSuccess: false,
      // rivileError: null,
      // Uzsakymo info redagavimas
      billingAdr_action_edit: false,
      shippingAdr_action_edit: false,
      dublicate_address: false,
      // makeInvoiceData: { code: null, error: null, loading: false },
    };
  }

  componentDidMount() {
    this.collapseBlockFetch();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.order_rows !== this.props.order_rows) {
      this.setState({
        weight: this.countWeight(this.props.order_rows),
      });
    }
    const stateObj = {};

    if (this.state.terminalSelected !== undefined && this.state.pack !== 1) {
      stateObj.pack = 1;
    }

    if (prevProps.order.total_price !== this.props.order.total_price) {
      stateObj.cod_amount = this.props.order.total_price;
    }
    if (prevProps.order_rows !== this.props.order_rows || prevState.delivery !== this.state.delivery) {
      stateObj.carrying = ruleCarrying({
        order_type: this.state.order_type,
        delivery: this.state.delivery,
        skipCarrying: this.state.skipCarrying
      })
        ? this.props.order_rows.some(
            (item) => item.product_name.trim() === "Užnešimas"
          )
        : false;
    }
    if (prevState.order_type !== this.state.order_type) {
      stateObj.weight = this.countWeight(this.props.order_rows);
    }
    if (
      !this.state.cod &&
      this.props?.order &&
      prevProps.order_histories?.length !== this.props.order_histories?.length
    ) {
      const cod =
        this.state.order_type === "at_the_store"
          ? false
          : setterOrderCod({
              payment_module: this.props.order.payment_module,
              cod: this.props.order.cod,
              id_status: this.props.order_histories
                .map((d) => d?.status?.id_status)
                .includes(13)
                ? 13
                : "",
            });
      if (this.state.cod !== cod) stateObj.cod = cod;
    }

    if (prevState.suppliers !== this.state.suppliers) {
      stateObj.skipCarrying = false;
      stateObj.skipCod = false;
      stateObj.skipDeliveryOnSaturday = false;
      stateObj.skipDeliveryTime = false;
      stateObj.skipOrderReturn = false;
      stateObj.skipSendingDocuments = false;
      stateObj.skipSwap = false;
    }

    if (prevState.delivery !== this.state.delivery) {
      stateObj.skipCarrying = false;
      stateObj.skipDeliveryOnSaturday = false;
      stateObj.skipDeliveryTime = false;
    }

    if (
      prevState.suppliers !== this.state.suppliers &&
      this.isSupplierTDBalticApi()
    ) {
      stateObj.cod = false;
      stateObj.cod_amount = 0;
      stateObj.delivery = orderDeliveryMount("dpd");
      stateObj.order_return = false;
      stateObj.order_type = "dpd";
      stateObj.sendingDocuments = false;
      stateObj.skipCod = true;
      stateObj.skipOrderReturn = true;
      stateObj.skipSendingDocuments = true;
      stateObj.skipSwap = true;
      stateObj.swap = false;
    }

    if (
      prevState.delivery !== this.state.delivery &&
      this.state.delivery === "dpd_postMachine" &&
      this.isSupplierTDBalticApi()
    ) {
      stateObj.carrying = false;
      stateObj.deliveryOnSaturday = false;
      stateObj.deliveryTime = false;
      stateObj.skipCarrying = true;
      stateObj.skipDeliveryOnSaturday = true;
      stateObj.skipDeliveryTime = true;
    }

    if (Object.keys(stateObj).length) this.setState({ ...stateObj });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const orderInfo = nextProps.order;
    if (Object.keys(orderInfo).length > 0 && orderInfo.id !== this.state.id) {
      // Logic

      this.props.clearErrors();
      const order_type = orderTypeMount(orderInfo.carrier);
      const delivery = orderDeliveryMount(order_type)
      let stateObj = {
        id: orderInfo.id,
        order_type,
        cod:
          order_type === "at_the_store"
            ? false
            : setterOrderCod({
                payment_module: orderInfo.payment_module,
                cod: orderInfo.cod,
                id_status: orderInfo?.status?.id_status,
              }),
        cod_amount: orderInfo.total_price,
        delivery,
        carrying: ruleCarrying({
          order_type,
          delivery,
          skipCarrying: false
        }) ?  
        orderInfo.order_rows.some(
          (item) => item.product_name.trim() == "Užnešimas"
        ) : false,
        // cod_amount: orderInfo.total_price,
        shipping_address_edit:
          order_type === "at_the_store"
            ? {
                ...orderInfo.billing_address,
                comment: orderInfo.client_comment,
              }
            : {
                ...orderInfo.shipping_address,
                comment: orderInfo.client_comment,
              },
      };

      if (orderInfo.shipping_address.parcel_terminal_id !== null) {
        if (order_type == "omniva") {
          stateObj = {
            ...stateObj,
            delivery: "omniva_postMachine",
            terminalSelected: orderInfo.shipping_address.parcel_terminal_id,
          };
        } else if (order_type == "lp_express") {
          stateObj = {
            ...stateObj,
            delivery: "lp_express_postMachine",
            terminalSelected: orderInfo.shipping_address.parcel_terminal_id,
          };
        } else if (order_type == "dpd") {
          stateObj = {
            ...stateObj,
            delivery: "dpd_postMachine",
            terminalSelected: orderInfo.shipping_address.parcel_terminal_id,
          };
        } else if (order_type == "itella") {
          stateObj = {
            ...stateObj,
            delivery: "itella_postMachine",
            terminalSelected: orderInfo.shipping_address.parcel_terminal_id,
          };
        } else if (order_type == "venipak") {
          stateObj = {
            ...stateObj,
            delivery: "venipak_postMachine",
            terminalSelected: orderInfo.shipping_address.parcel_terminal_id,
          };
        }
      }

      stateObj.shipmentRowForStore = this.shipmentRowForStoreBuild(
        nextProps.order_rows,
        orderInfo.total_shipping,
        orderInfo.carrier
      );
      if (order_type == "at_the_store") {
        const address = orderInfo.shipping_address;
        const takeFromArray = this.props.pickupAddress
          .filter(
            (e) =>
              e.company?.toUpperCase().split(" ").join("") == "UABVERSLOVARTAI"
          )
          .map((e) => ({
            value: e.value,
            address: lengthOfCharactersDifference(
              e.address.toLowerCase().split(" ").join(""),
              address.street.toLowerCase().split(" ").join("")
            ),
          }))
          .sort((a, b) => (a.address > b.address ? 1 : -1));
        if (takeFromArray.length > 0) {
          stateObj.suppliers = takeFromArray[0].value;
        }
      }
      this.setState({
        ...stateObj,
        selectedOrderRows: nextProps.order_rows.map((d) => d.id),
        weight: this.countWeight(nextProps.order_rows),
      });
    }
  }

  handleChangeOption = (e, select) => {
    if (select) {
      this.setState({
        [select]: e,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  shipmentRowForStoreBuild = (order_rows, total_shipping, carrier) => {
    let shipmentRowForStore = order_rows.map((e) => ({
      id: e.id,
      product_reference: !have_product_reference(e.product_reference)
        ? null
        : e.product_reference,
      title: e.product_name,
      price: e.unit_price_tax_incl,
      quantity: e.quantity,
      edit: false,
      changes: false,
    }));
    if (total_shipping > 0) {
      shipmentRowForStore.push({
        id: null,
        title: `Atsiėmimas parduotuvėje${
          carrier.module_name !== "fspickupatstorecarrier"
            ? ": " + carrier.name
            : ""
        }`,
        price: total_shipping,
        quantity: 1,
        edit: false,
        delivery: true,
        changes: true,
      });
    }
    return shipmentRowForStore;
  };
  createShipping = () => {
    this.setState({
      shipping_loading: true,
    });
    const orderTypeAtTheStore = this.state.order_type === "at_the_store";
    let orderVariables = {
      shipment: {
        order_row_ids: orderTypeAtTheStore ? [] : this.state.selectedOrderRows,
        shipment_type: this.state.order_type,
        packets: this.state.pack,
        weight: orderTypeAtTheStore ? 0.1 : this.state.weight,
        pickup_address_id: this.state.suppliers,
        shipment_address_attributes: {
          company: this.state.shipping_address_edit.company,
          name:
            this.state.shipping_address_edit.firstname +
            " " +
            this.state.shipping_address_edit.lastname,
          address: this.state.shipping_address_edit.street,
          city: this.state.shipping_address_edit.city,
          postal_code: this.state.shipping_address_edit.postal_code,
          parcel_terminal_id: this.state.terminalSelected,
          phone: this.state.shipping_address_edit.phone,
          email: this.state.shipping_address_edit.other,
          comment: this.state.shipping_address_edit.comment,
        },
        shipment_order_rows_attributes: orderTypeAtTheStore
          ? this.state.shipmentRowForStore.map((e) => ({
              quantity: e.quantity,
              title: e.title,
              price: e.price,
              order_row_id: e.id,
            }))
          : [],
        shipment_additional_services_attributes: [
          {
            service_key: "order_return",
            service_value: this.state.order_return ? 1 : 0,
          },
          {
            service_key: "cod",
            service_value: this.state.cod ? this.state.cod_amount : null,
          },
          {
            service_key: "sat",
            service_value: this.state.deliveryOnSaturday,
          },
          {
            service_key: "docret",
            service_value: this.state.sendingDocuments ? this.state.who : false,
          },
          {
            service_key: "time",
            service_value: this.state.deliveryTime
              ? `${this.state.deliveryTimeHours[0]} - ${this.state.deliveryTimeHours[1]}`
              : null,
          },
          {
            service_key: "delivery",
            service_value: this.state.delivery,
          },
          {
            service_key: "carrying",
            service_value: this.state.carrying,
          },
          {
            service_key: "same_day_delivery",
            service_value: this.state.same_day_delivery,
          },
          {
            service_key: "swap",
            service_value: this.state.swap,
          },
          {
            service_key: "weight",
            service_value: this.state.weight,
          },
          {
            service_key: "pack",
            service_value: this.state.pack,
          },
          {
            service_key: "lp_express_sizes",
            service_value: this.state.lp_express_sizes,
          },
        ],
      },
    };

    this.props.postOrderShipping(this.state.id, orderVariables).then((res) => {
      if (res.success) {
        this.props.clearErrors();
        this.setState({
          shipmentRowForStore: this.shipmentRowForStoreBuild(
            this.props.order_rows,
            this.props.order.total_shipping,
            this.props.order.carrier
          ),
          shipping_loading: false,
        });
      } else {
        this.setState({
          shipping_loading: false,
        });
      }
    });
  };

  shipmentRowForStoreFunction = (action, index, e) => {
    let shipmentRowForStore = [...this.state.shipmentRowForStore];
    if (action === "push") {
      shipmentRowForStore = [
        ...shipmentRowForStore,
        {
          id: null,
          product_reference: null,
          quantity: 1,
          title: "",
          price: 0,
          edit: true,
          changes: false,
        },
      ];
    } else if (action === "edit") {
      shipmentRowForStore[index].changes = true;
      shipmentRowForStore[index][e.target.name] = e.target.value;
    } else {
      shipmentRowForStore.splice(index, 1);
    }
    this.setState({
      shipmentRowForStore,
    });
  };

  handleChangeText = (e, where) => {
    if (where == null) {
      this.setState({
        [e.target.name]: e.target.value,
      });
    } else {
      this.setState({
        [where]: {
          ...this.state[where],
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  handleCheckBoxOrdersSelected = (value) => {
    const newChecked = [...this.state.selectedOrderRows];
    const currentIndex = newChecked.indexOf(value);

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    const weight =
      this.countWeight(
        this.props.order_rows.filter((d) => newChecked.includes(d.id))
      ) || 0.1;
    this.setState({
      selectedOrderRows: newChecked,
      weight,
    });
  };

  onSubmitComment = (e, type, text, select, date) => {
    e.preventDefault();
    let response = {
      order_id: this.state.id,
      comment: {
        comment_state_id: select,
        body: text,
        comment_type: type,
      },
    };
    if (date !== false) {
      response = {
        order_id: this.state.id,
        comment: {
          comment_state_id: select,
          body: text,
          comment_type: type,
          date: moment(date),
        },
      };
    }
    return this.props.postComments(response);
  };

  countWeight = (items) => {
    if (this.state.order_type == "at_the_store") {
      return 1;
    }
    const reducer = (accumulator, currentValue) => {
      if (
        typeof currentValue.product_info === "object" &&
        currentValue.product_info !== null
      ) {
        return accumulator + +currentValue.product_info.weight;
      } else {
        return accumulator + 0;
      }
    };
    const total = items.reduce(reducer, 0);
    return !total ? 0.1 : total > 0.1 ? total : 0.1;
  };

  onChangeDate = (e, date) => {
    this.setState({
      [date]: e,
    });
  };

  handleSupplyBlock = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  deleteSupply = (id, id_order_row) => {
    this.props.deleteOrderRow(this.state.id, id_order_row, id);
  };

  handleChangecollapseBlock = (item) => {
    const currentIndex = this.state.tableItemsCollapses.indexOf(item);
    const newChecked = [...this.state.tableItemsCollapses];
    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({
      tableItemsCollapses: newChecked,
      [item]: !this.state[item],
    });
    localStorage.setItem("itemsCollapse", JSON.stringify(newChecked));
  };

  collapseBlockFetch = () => {
    let defaultStorage = ["order_information"];
    const getLocalStorage = Array.isArray(
      JSON.parse(localStorage.getItem("itemsCollapse"))
    )
      ? JSON.parse(localStorage.getItem("itemsCollapse"))
      : defaultStorage;

    getLocalStorage.forEach((item) =>
      this.setState({
        [item]: true,
      })
    );
    this.setState({
      tableItemsCollapses: getLocalStorage,
    });
  };

  selectedBox = (value) => {
    this.setState({
      optionSelected: value,
    });
  };

  toogleModalElement = (name) => {
    const { shippingAdr_action_edit, billingAdr_action_edit } = this.state;
    if (name == "shippingAdr_action_edit") {
      if (shippingAdr_action_edit) {
        this.setState({
          shippingAdr_action_edit: false,
          // shippingAdr_action: {},
        });
      } else {
        this.setState({
          shippingAdr_action_edit: true,
          // shippingAdr_action: { ...this.state.shipping_address },
        });
      }
    } else if (name == "billingAdr_action_edit") {
      if (billingAdr_action_edit) {
        this.setState({
          billingAdr_action_edit: false,
          // billingAdr_action: {},
        });
      } else {
        this.setState({
          billingAdr_action_edit: true,
          // billingAdr_action: { ...this.state.billing_address },
        });
      }
    }
  };
  updateOrderAddress = (action, dataObj) => {
    let params = {};
    delete dataObj.valid_phone;
    let objData = {
      ...dataObj,
      firstname: dataObj.firstname,
      lastname: dataObj.lastname,
      street: dataObj.street,
      city: dataObj.city,
      postal_code: dataObj.postal_code,
      phone: dataObj.phone,
      other: dataObj.other,
      country_id: dataObj.country_id,
      customer_id: dataObj.customer_id,
    };
    if (action == "billing") {
      params = {
        billing_address_attributes: {
          ...objData,
          company: dataObj.company,
          vat_code: dataObj.vat_code,
          company_code: dataObj.company_code,
        },
      };
    } else if (action == "shipping") {
      params = {
        shipping_address_attributes: {
          ...objData,
        },
      };
    }

    return this.props
      .updateOrderAddress(this.state.id, params)
      .then((response) => {
        if (response.ok) {
          let changes = {};
          if (action == "billing") {
            changes = {
              billingAdr_action_edit: false,
            };
          } else if (action == "shipping") {
            changes = {
              shippingAdr_action_edit: false,
              shipping_address_edit: {
                ...this.state.shipping_address_edit,
                ...response.data.shipping_address,
              },
            };
          }
          this.setState({
            ...changes,
          });
          return { success: true };
        }
      })
      .catch((err) => {
        return { success: false };
      });
  };

  paymentBlock = () => {
    this.props.editPayment({
      reference: "",
      payment_type: undefined,
      price: "",
    });
    this.setState({
      payments_add_col: !this.state.payments_add_col,
    });
  };

  deliveryBlock = () => {
    this.setState({
      delivery_add_col: !this.state.delivery_add_col,
    });
  };

  updateShippingAddressTerminal = (terminalSelected) => {
    this.setState({
      terminalSelected,
    });
  };

  updateOrderRowGoods = (variables, orderId, itemId) => {
    return this.props
      .updateOrderRowGoods(variables, orderId, itemId)
      .then((res) => {
        if (res) {
          let shipmentRowForStore = [...this.state.shipmentRowForStore];
          const findIndex = shipmentRowForStore.findIndex(
            (e) => e.id == itemId
          );
          if (findIndex !== -1 && !shipmentRowForStore[findIndex].changes) {
            shipmentRowForStore[findIndex] = {
              ...shipmentRowForStore[findIndex],
              price: variables.order_row.product_price,
              quantity: variables.order_row.quantity,
            };
          }
          this.setState({
            shipmentRowForStore,
          });

          return res;
        }
      });
  };

  deleteOrderRowGoods = (orderId, itemID) => {
    return this.props.deleteOrderRowGoods(orderId, itemID).then((res) => {
      let shipmentRowForStore = [...this.state.shipmentRowForStore];
      const findIndex = shipmentRowForStore.findIndex((e) => e.id == itemID);
      if (!shipmentRowForStore[findIndex].changes) {
        shipmentRowForStore.splice(findIndex, 1);
      }
      this.setState({
        shipmentRowForStore,
      });

      return res;
    });
  };

  postOrderRowGoods = (body, orderId, rowGoods) => {
    return this.props.postOrderRowGoods(body, orderId).then((res) => {
      if (rowGoods) {
        let shipmentRowForStore = [...this.state.shipmentRowForStore];
        shipmentRowForStore.push({
          id: res.id.id,
          product_reference: !have_product_reference(res.id.product_reference)
            ? null
            : res.id.product_reference,
          title: body.order_row.product_name,
          price: body.order_row.product_price,
          quantity: body.order_row.quantity,
          edit: false,
          changes: false,
        });

        this.setState({
          shipmentRowForStore,
        });
      }
      return res;
    });
  };

  moveToShipping = () => {
    const { firstname, lastname, street, city, postal_code, phone, other } =
      this.props.order.billing_address;
    this.setState({
      dublicate_address: true,
    });
    this.updateOrderAddress("shipping", {
      firstname,
      lastname,
      street,
      city,
      postal_code,
      phone,
      other,
    }).then(() => this.setState({ dublicate_address: false }));
  };

  residuesItemBuilder = () => {
    let residuesItem = [];
    this.props.order_rows.forEach((item) => {
      if (item.product_info) {
        const filteredResi = item.product_info.product_suppliers.filter(
          (filt) => filt.quantity > 0
        );
        filteredResi.forEach((each) => {
          residuesItem.push({
            ...each,
            originalTitle: item.product_name,
            originalCode: item.product_reference,
          });
        });
      }
    });
    return residuesItem;
  };

  isSupplierTDBalticApi = (stateObj = null) => {
    const { suppliers } = stateObj || this.state;

    return this.props.pickupAddress.some(
      ({ code, id }) => code === "TD Baltic - API" && id === suppliers
    );
  };

  render() {
    console.count("Rendering times: ");
    const {
      order_histories,
      contracts,
      order_rows,
      shipments,
      order_documents,
      parcel_terminals,
      loading_order,
      order,
      comments,
      order_cart_rules,
    } = this.props;
    const order_type = this.state.order_type;
    const { billingAdr_action_edit, shippingAdr_action_edit } = this.state;

    const disabled =
      order.order_approved &&
      this.props.auth.user.user_role.identifier == "store_manager";
    if (loading_order) {
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100%" }}
        >
          <Spinner />
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-6">
          <Divs className="container-title-block">
            <MainBlock
              color={"#ffa800"}
              action={this.state.order_information}
              onChange={() =>
                this.handleChangecollapseBlock("order_information")
              }
            >
              Užsakymo informacija
            </MainBlock>
          </Divs>
          {this.state.order_information && (
            <Divs className="mt-3 mb-4">
              <>
                <div className="col-12 row" style={{ margin: 0 }}>
                  <Divs className="col-6" style={{ width: "50%" }}>
                    <div className="d-flex justify-content-between align-items-center font-weight-bold mb-3">
                      <span>Pristatymo informacija</span>
                      {!disabled && (
                        <div
                          className="d-flex align-items-center"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.toogleModalElement("shippingAdr_action_edit")
                          }
                        >
                          {shippingAdr_action_edit ? (
                            <GrClose title="Atšaukti" />
                          ) : (
                            <GrEdit title="Redaguoti" />
                          )}
                        </div>
                      )}
                    </div>
                    <ShippingAddressComponent
                      module_name={order.carrier.module_name}
                      shippingAdr_action_edit={shippingAdr_action_edit}
                      UlElements={UlElements}
                      shipping_address={order.shipping_address}
                      updateOrderAddress={this.updateOrderAddress}
                      customer={order.customer}
                    />
                  </Divs>
                  <Divs
                    className="col-6"
                    style={{
                      width: "50%",
                      borderLeft: "1px solid #e3e3e3",
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center font-weight-bold mb-3">
                      <span className="d-flex align-items-center">
                        {disabled ? null : this.state.dublicate_address ? (
                          <Spin size="small" className="mr-2" />
                        ) : (
                          <div
                            className="cursor-pointer mr-2"
                            onClick={() => this.moveToShipping()}
                          >
                            <FaCopy
                              color="orange"
                              size={15}
                              title="Kopijuoti iš 'Mokėtojo informacija' į 'Pristatymo informacija'"
                            />
                          </div>
                        )}
                        <span>Mokėtojo informacija</span>
                      </span>
                      {!disabled && (
                        <div
                          className="d-flex align-items-center"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.toogleModalElement("billingAdr_action_edit")
                          }
                        >
                          {billingAdr_action_edit ? (
                            <GrClose title="Atšaukti" />
                          ) : (
                            <GrEdit title="Redaguoti" />
                          )}
                        </div>
                      )}
                    </div>
                    <BillingAddressComponent
                      disabled={disabled}
                      billingAdr_action_edit={billingAdr_action_edit}
                      UlElements={UlElements}
                      billing_address={order.billing_address}
                      updateOrderAddress={this.updateOrderAddress}
                      invoice_number={order.invoice_number}
                      postToRivile={this.props.postToRivile}
                      orderCreateInvoice={this.props.orderCreateInvoice}
                      orderId={this.state.id}
                    />
                  </Divs>
                </div>
                <Divs className="mt-3 mb-2">
                  <OrderTotalComponent
                    order_id={order.id_order}
                    total_price={order.total_price}
                    payments={this.props.payments}
                  />
                  <br />
                  {this.props.order?.client_comment && (
                    <div
                      className={`d-flex justify-content-start align-items-center text-danger ${
                        order_cart_rules?.length > 0 ? "mb-3" : ""
                      }`}
                    >
                      <AiOutlineInfoCircle
                        className="text-danger mr-2"
                        style={{ fontSize: 17 }}
                      />{" "}
                      Kliento pastaba: {this.props.order.client_comment}
                    </div>
                  )}
                  {order_cart_rules &&
                    order_cart_rules.length > 0 &&
                    order_cart_rules.map((order_cart_rule) => (
                      <OrderCartRulesComponent
                        key={order_cart_rule.id}
                        order_cart_rule={order_cart_rule}
                        orderId={this.state.id}
                        total_price={order.total_price}
                        disabled={disabled}
                      />
                    ))}

                  {order?.order_additionals?.map((d) => (
                    <React.Fragment key={d.id}>
                      <div className="text-bold">
                        {[
                          "refrigerator_game_participation",
                          "basketball_game_participation",
                        ].includes(d.key)
                          ? "Dalyvavimas žaidime"
                          : d.key == "handle_rivile"
                          ? "Išsiuntimas į rivilę"
                          : d.key}
                        :{" "}
                        <OrderAdditionals
                          key={d.id}
                          action={d.key}
                          value={d.value}
                        />
                        {d.value !== "1" && d.value}
                      </div>
                      <hr className="my-2" />
                    </React.Fragment>
                  ))}
                </Divs>

                <MiniBlockOrderRows
                  color={"#ffcc69"}
                  action={this.state.information_goods}
                  onChange={() =>
                    this.handleChangecollapseBlock("information_goods")
                  }
                  goodsAddRow={
                    disabled ? false : this.state.information_goods_add
                  }
                  goodsAddRowClick={
                    disabled
                      ? false
                      : () =>
                          this.setState({
                            information_goods_add:
                              !this.state.information_goods_add,
                          })
                  }
                >
                  Prekės
                </MiniBlockOrderRows>
                {this.state.information_goods && (
                  <React.Fragment>
                    <Divs>
                      <div className="table-responsive bg-silver">
                        <div
                          className="col-12"
                          style={{
                            marginBottom: "1rem",
                          }}
                        >
                          <table
                            className="table table-sm"
                            style={{ marginBottom: "0px!important" }}
                          >
                            {order_rows.map((item, index) => (
                              <OrderRowsComponent
                                disabled={disabled}
                                key={index}
                                item={item}
                                orderId={this.state.id}
                                deleteSupply={this.deleteSupply}
                                updateSerialNumber={
                                  this.props.updateSerialNumber
                                }
                                updateOrderRowGoods={this.updateOrderRowGoods}
                                deleteOrderRowGoods={this.deleteOrderRowGoods}
                              />
                            ))}
                          </table>
                        </div>
                      </div>
                    </Divs>
                    {this.state.information_goods_add && (
                      <OrderRowAdd
                        orderId={this.state.id}
                        postOrderRowGoods={this.postOrderRowGoods}
                      />
                    )}
                  </React.Fragment>
                )}

                {order_rows.some((some) => some.required_serial_number) && (
                  <>
                    <MiniBlock
                      color={"#ffcc69"}
                      action={this.state.serial_number}
                      onChange={() =>
                        this.handleChangecollapseBlock("serial_number")
                      }
                    >
                      Serijiniai numeriai
                    </MiniBlock>
                    {this.state.serial_number && (
                      <Divs>
                        <div className="col-12 bg-silver">
                          <SerialNumberBlock
                            orderID={this.state.id}
                            updateSerialNumber={this.props.updateSerialNumber}
                            updateSerialNumberState={
                              this.props.updateSerialNumberState
                            }
                            options={order_rows.filter(
                              (filt) => filt.required_serial_number
                            )}
                          />
                        </div>
                      </Divs>
                    )}
                  </>
                )}

                {!disabled && (
                  <>
                    <MiniBlock
                      color={"#ffcc69"}
                      action={this.state.information_supply}
                      onChange={() =>
                        this.handleChangecollapseBlock("information_supply")
                      }
                    >
                      Tiekimas
                    </MiniBlock>
                    {this.state.information_supply && (
                      <Divs>
                        <SupplyComponent
                          order_rows={this.props.order_rows}
                          orderId={this.state.id}
                          postOrderRow={postOrderRow}
                        />
                      </Divs>
                    )}
                  </>
                )}

                <MiniBlock
                  color={"#ffcc69"}
                  action={this.state.information_balances}
                  onChange={() =>
                    this.handleChangecollapseBlock("information_balances")
                  }
                >
                  Likučiai
                </MiniBlock>
                {this.state.information_balances && (
                  <Divs>
                    <div className="col-12 bg-silver">
                      {this.residuesItemBuilder().length > 0 ? (
                        <div className="table-responsive">
                          <table className="table table-sm">
                            <tbody>
                              {this.residuesItemBuilder().map((item, index) => (
                                <Residuesitem key={index} item={item} />
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="text-bold">Prekių likučių nėra</div>
                      )}
                    </div>
                  </Divs>
                )}
                <MiniBlock
                  color={"#ffcc69"}
                  action={this.state.information_status}
                  onChange={() =>
                    this.handleChangecollapseBlock("information_status")
                  }
                >
                  Statusas
                </MiniBlock>
                {this.state.information_status && (
                  <Divs>
                    <StatusComponent
                      disabled={disabled}
                      orderId={this.state.id}
                      avalaibleStatus={this.props.avalaibleStatus}
                      order_histories={order_histories}
                    />
                  </Divs>
                )}

                <React.Fragment>
                  <MiniBlockOrderRows
                    color={"#ffcc69"}
                    action={this.state.information_delivery}
                    onChange={() =>
                      this.handleChangecollapseBlock("information_delivery")
                    }
                    // editAction={
                    //   ["omnivaltshipping", "lpexpress", "prdpd", "fspickupatstorecarrier"].includes(
                    //     order.carrier.module_name)
                    // }
                    // editAction={true}
                    goodsAddRow={this.state.delivery_add_col}
                    goodsAddRowClick={
                      disabled ? false : () => this.deliveryBlock()
                    }
                  >
                    Pristatymas
                  </MiniBlockOrderRows>
                  {this.state.information_delivery && (
                    <Divs>
                      <DeliveryComponent
                        orderId={this.state.id}
                        carrier_id={order.carrier_id}
                        carrier={order.carrier}
                        total_shipping={order.total_shipping}
                        payment={order.payment}
                        delivery_add_col={this.state.delivery_add_col}
                        parcel_terminals={parcel_terminals}
                        shipping_address={order.shipping_address}
                        updateShippingAddress={
                          this.updateShippingAddressTerminal
                        }
                      />
                    </Divs>
                  )}
                </React.Fragment>
                <React.Fragment>
                  <MiniBlockOrderRows
                    color={"#ffcc69"}
                    action={this.state.payments_col}
                    onChange={() =>
                      this.handleChangecollapseBlock("payments_col")
                    }
                    goodsAddRow={this.state.payments_add_col}
                    goodsAddRowClick={
                      disabled ? false : () => this.paymentBlock()
                    }
                  >
                    Apmokėjimai
                  </MiniBlockOrderRows>
                  {this.state.payments_col && (
                    <Divs>
                      <PaymentBlockComponent
                        disabled={disabled}
                        orderId={this.state.id}
                        payments_add_col={this.state.payments_add_col}
                        total_price={order.total_price}
                        paymentBlock={this.paymentBlock}
                        goodsAddRowClick={(boolean) =>
                          this.setState({
                            payments_add_col: boolean,
                          })
                        }
                      />
                    </Divs>
                  )}
                </React.Fragment>
              </>
            </Divs>
          )}

          <MainBlock
            color={"#BD00FF"}
            action={this.state.information_leasing}
            onChange={() =>
              this.handleChangecollapseBlock("information_leasing")
            }
          >
            Lizingas
          </MainBlock>
          {this.state.information_leasing && (
            <Divs className="bg-silver mb-4">
              <LeasingComponent
                orderId={this.state.id}
                p_discount_5090={this.props.order.discount_5090}
                p_leasing_number={this.props.order.leasing_number}
                p_leasing_discount={this.props.order.leasing_discount}
                p_leasing_advance={this.props.order.leasing_advance}
                disabled={disabled}
              />
            </Divs>
          )}

          <Divs className="bg-silver">
            <MainBlock
              color={"#008aff"}
              action={this.state.comment_col}
              onChange={() => this.handleChangecollapseBlock("comment_col")}
            >
              Komentarai
            </MainBlock>
            {this.state.comment_col && (
              <div className="mb-4 mt-2">
                <MiniBlock
                  color={"#7ebff7"}
                  action={this.state.ka_comment_col}
                  onChange={() =>
                    this.handleChangecollapseBlock("ka_comment_col")
                  }
                >
                  KA komentarai
                </MiniBlock>
                {this.state.ka_comment_col && (
                  <div className="col-12">
                    <SubmitComment
                      orderId={this.state.id}
                      onSubmit={this.onSubmitComment}
                      type="comment_ka"
                      options={this.props.comments_state || []}
                    />
                    <PreviewComment comments={comments} type="comment_ka" />
                  </div>
                )}
                <MiniBlock
                  color={"#7ebff7"}
                  action={this.state.log_comment_col}
                  onChange={() =>
                    this.handleChangecollapseBlock("log_comment_col")
                  }
                >
                  Logistikos komentarai
                </MiniBlock>
                {this.state.log_comment_col && (
                  <div className="col-12">
                    <SubmitComment
                      orderId={this.state.id}
                      onSubmit={this.onSubmitComment}
                      type="comment_logistic"
                      options={this.props.comments_state || []}
                    />
                    <PreviewComment
                      comments={comments}
                      type="comment_logistic"
                    />
                  </div>
                )}
                <MiniBlock
                  color={"#7ebff7"}
                  action={this.state.manag_comment_col}
                  onChange={() =>
                    this.handleChangecollapseBlock("manag_comment_col")
                  }
                >
                  Vadybos komentarai
                </MiniBlock>
                {this.state.manag_comment_col && (
                  <div className="col-12">
                    <SubmitComment
                      orderId={this.state.id}
                      onSubmit={this.onSubmitComment}
                      type="comment_management"
                      options={this.props.comments_state || []}
                    />
                    <PreviewComment
                      comments={comments}
                      type="comment_management"
                    />
                  </div>
                )}
                <MiniBlock
                  color={"#7ebff7"}
                  action={this.state.store_comment_col}
                  onChange={() =>
                    this.handleChangecollapseBlock("store_comment_col")
                  }
                >
                  Parduotuvės komentarai
                </MiniBlock>
                {this.state.store_comment_col && (
                  <div className="col-12">
                    <SubmitComment
                      orderId={this.state.id}
                      onSubmit={this.onSubmitComment}
                      type="comment_store"
                      options={this.props.comments_state || []}
                    />
                    <PreviewComment comments={comments} type="comment_store" />
                  </div>
                )}
                {/* {this.props.order.client_comment && (
                  <>
                    <MiniBlock
                      color={"#7ebff7"}
                      action={this.state.client_comment}
                      onChange={() =>
                        this.handleChangecollapseBlock("client_comment")
                      }
                    >
                      Kliento PASTABA
                    </MiniBlock>
                    {this.state.client_comment && (
                      <div className="col-12 py-2">
                        <span className="text-danger font-weight-bold">
                          {this.props.order.client_comment}
                        </span>
                      </div>
                    )}
                  </>
                )} */}
              </div>
            )}
          </Divs>

          <Divs>
            <MainBlock
              color={"#00aaa0"}
              action={this.state.AttachDocuments}
              onChange={() => this.handleChangecollapseBlock("AttachDocuments")}
            >
              Dokumentų įkėlimas
            </MainBlock>
            {this.state.AttachDocuments && (
              <AttachOrderDocument
                disabled={disabled}
                order_documents={order_documents}
                addOrderDocument={this.props.addOrderDocument}
                deleteOrderDocument={this.props.deleteOrderDocument}
                orderId={order.id}
              />
            )}
          </Divs>
        </div>
        <div className="col-6">
          <Divs>
            <MainBlock
              color={"#c600ff"}
              action={this.state.AdditionalServices}
              onChange={() =>
                this.handleChangecollapseBlock("AdditionalServices")
              }
            >
              Dokumentai
            </MainBlock>
            {this.state.AdditionalServices && (
              <div className="bg-silver mb-4">
                <AdditionalServicesMain
                  contracts={contracts}
                  orderId={order.id}
                  orderId2={order.id_order}
                  order_rows={order_rows}
                  addContract={this.props.addContract}
                  updateContract={this.props.updateContract}
                  deleteContractItem={this.props.deleteContractItem}
                  deleteContract={this.props.deleteContract}
                  billing_address={this.props.order.billing_address}
                  firstAddContract={this.props.firstAddContract}
                  firstDeleteContract={this.props.firstDeleteContract}
                  changeContractItems={this.props.changeContractItems}
                  errors={this.props.errors}
                />
              </div>
            )}
          </Divs>
          <Divs>
            <MainBlock
              color={"#00aaa0"}
              custom={this.state.order_shipment_information}
              action={this.state.order_shipment_information}
              onChange={() =>
                this.handleChangecollapseBlock("order_shipment_information")
              }
            >
              <div className="d-flex align-items-center justify-content-between">
                <div
                  className="w-50"
                  onClick={() =>
                    this.handleChangecollapseBlock("order_shipment_information")
                  }
                >
                  Siuntimas
                </div>
                {this.state.order_shipment_information && (
                  <SelectForm
                    options={
                      this.isSupplierTDBalticApi()
                        ? shipment_types_options.filter(
                            ({ value }) => value === "dpd"
                          )
                        : shipment_types_options
                    }
                    selector="order_type"
                    value={this.state.order_type}
                    onChange={(e) =>
                      this.setState({
                        ...handleChangeOrdertypeGlobal(
                          e,
                          "order_type",
                          {
                            ...this.props.order.shipping_address,
                            comment: this.state.shipping_address_edit.comment,
                          },
                          {
                            ...this.props.order.billing_address,
                            comment: this.state.shipping_address_edit.comment,
                          }
                        ),
                      })
                    }
                  />
                )}
              </div>
            </MainBlock>
            {this.state.order_shipment_information && (
              <>
                <div>
                  <ShippingComponent
                    disabled={disabled}
                    order_type={order_type}
                    delivery={this.state.delivery}
                    order_id={this.state.id}
                    id_order={this.props.order.id_order}
                    shipping_address_edit={this.state.shipping_address_edit}
                    terminalSelected={this.state.terminalSelected}
                    handleChangeText={this.handleChangeText}
                    handleChangeOption={this.handleChangeOption}
                    pickupAddress={this.props.pickupAddress}
                    order_rows={order_rows}
                    shipments={shipments}
                    handleCheckBoxOrdersSelected={
                      this.handleCheckBoxOrdersSelected
                    }
                    parcel_terminals={parcel_terminals}
                    onChangeDate={this.onChangeDate}
                    selectedOrderRows={this.state.selectedOrderRows}
                    onChangeCheckBoxDelivery={(e) =>
                      this.setState(
                        onChangeCheckBoxDeliveryGlobal(
                          e,
                          this.state.delivery,
                          this.props.order.shipping_address
                        )
                      )
                    }
                    createShipping={this.createShipping}
                    errors={this.props.errors}
                    variables={{
                      suppliers: this.state.suppliers,
                      weight: this.state.weight,
                      pack: this.state.pack,
                      carrying: this.state.carrying,
                      same_day_delivery: this.state.same_day_delivery,
                      cod: this.state.cod,
                      order_return: this.state.order_return,
                      sendingDocuments: this.state.sendingDocuments,
                      deliveryTime: this.state.deliveryTime,
                      deliveryOnSaturday: this.state.deliveryOnSaturday,
                      cod_amount: this.state.cod_amount,
                      who: this.state.who,
                      deliveryTimeHours: this.state.deliveryTimeHours,
                      skipCarrying: this.state.skipCarrying,
                      skipCod: this.state.skipCod,
                      skipDeliveryOnSaturday: this.state.skipDeliveryOnSaturday,
                      skipDeliveryTime: this.state.skipDeliveryTime,
                      skipOrderReturn: this.state.skipOrderReturn,
                      skipSendingDocuments: this.state.skipSendingDocuments,
                      skipSwap: this.state.skipSwap,
                      swap: this.state.swap,
                      lp_express_sizes: this.state.lp_express_sizes,
                      shipping_loading: this.state.shipping_loading,
                    }}
                    shipmentRowForStore={this.state.shipmentRowForStore}
                    shipmentRowForStoreFunction={
                      this.shipmentRowForStoreFunction
                    }
                  />
                </div>
              </>
            )}
          </Divs>
          <Divs>
            <MainBlock
              color={"#adcb35"}
              action={this.state.SmsSending}
              onChange={() => this.handleChangecollapseBlock("SmsSending")}
            >
              SMS siuntimas numeriui{" "}
              {
                <span
                  className={
                    phonenumberValid(order.billing_address.phone)
                      ? ""
                      : "text-danger"
                  }
                >
                  {order.billing_address.phone}
                </span>
              }
            </MainBlock>
            {this.state.SmsSending && (
              <SmsSending
                disabled={disabled}
                id={this.state.id}
                smsItems={this.props.smsItems}
                phoneNumber={order.billing_address.phone}
                shipping_address_street={
                  this.props.order.shipping_address.street
                }
                orderId={this.props.order.id_order}
                shipments={this.props.shipments}
              />
            )}
          </Divs>
          <Divs>
            <MainBlock
              color={"#006C66"}
              action={this.state.order_history}
              onChange={() => this.handleChangecollapseBlock("order_history")}
            >
              Užsakymų istorija
            </MainBlock>
            {this.state.order_history && this.props.order && (
              <OrderHistoryById
                id={this.props.order.id}
                checker={this.state.shippingAdr_action_edit}
              />
            )}
          </Divs>
          <Divs>
            <MainBlock
              color={"#00b3a9"}
              action={this.state.service_history}
              onChange={() => this.handleChangecollapseBlock("service_history")}
            >
              Servisų istorija
            </MainBlock>
            {this.state.service_history && this.props.order && (
              <OrderHistoryById
                id={this.props.order.id}
                mode="service_histories"
                order_rows={this.props.order_rows}
              />
            )}
          </Divs>
        </div>
      </div>
    );
  }
}

// const MainBlock = styled.div`
//   background-color: #f2f2f2;
//   padding: 8px 10px;
//   display: block;
//   font-weight: 700;
//   color: #000000;
//   font-size: 12px;
// `;

const UlElements = styled.ul`
  margin: 0;
  list-style-type: none;
  padding: 0 1px;
  li {
    font-size: 15px;
    color: #000000;
    margin-bottom: 5px;
    word-wrap: break-word;
  }
`;

const Divs = styled.div`
  display: block;
  color: #000000;
  font-size: 15px;
  font-weight: 400;
  & .text-bold {
    font-size: 14px;
    line-height: 20px;
    color: #030303;
    font-weight: 600;
  }
  & .residuesItem {
    font-size: 0.8rem;
    word-wrap: break-word;
  }

  & .orange_color {
    color: #f7951e;
  }
  & .addDoc {
    margin-top: 5px;
    color: #f7951e;
    &:hover {
      color: #bd6903;
      border-bottom: 1px solid #f7951e;
      cursor: pointer;
    }
  }
  & .input_text {
    right: 7%;
    top: 50%;
    font-weight: bold;
    text-transform: uppercase;
    transform: translate(-50%, -50%);
    z-index: 100;
  }
`;

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  // ShowIdData
  order_histories: state.orders.order_histories,
  contracts: state.orders.contracts,
  order_rows: state.orders.order_rows,
  payments: state.orders.payments,
  shipments: state.orders.shipments,
  order_documents: state.orders.order_documents,
  order_cart_rules: state.orders.order_cart_rules,
  comments: state.orders.comments,
  order: state.orders.order,
  loading_order: state.orders.loading_order,
});
export default connect(mapStateToProps, {
  postComments,
  postOrderRowGoods,
  updateOrderRowGoods,
  deleteOrderRowGoods,
  deleteOrderRow,
  addOrderDocument,
  deleteOrderDocument,
  addContract,
  updateContract,
  deleteContractItem,
  deleteContract,
  firstAddContract,
  firstDeleteContract,
  changeContractItems,
  updateSerialNumber,
  updateSerialNumberState,
  postToRivile,
  clearErrors,
  postOrderShipping,
  updateOrderAddress,
  orderCreateInvoice,
  editPayment,
})(OrderShowModal);
