import React from "react";
import Container from "../Layout/Container";
import { connect } from "react-redux";
import {
  getFamilies,
  updateFamily,
  updateMarking,
} from "../../redux/Actions/familyAction";
import { getStoreItems } from "../../redux/Actions/storeAction";
import ContentBlock from "../Layout/ContentBlock";
import TableColumnFamily from "./Items/TableColumnFamily";
import FamilyEditModal from "./Items/FamilyEditModal";
import FilterMenu from "../Layout/FilterMenu";
import TableFilterBox from "../common/TableFilterBox";
import ContentValidation from "../common/ContentValidation";
import InputText from "../orders/accepted_orders/InputFilter";
import Pagination from "../Dashboard/items/Pagination";
import { Checkbox } from "antd";
import { Label } from "../global";

const initialFilterState = {
  title: "",
  type: "all",
};

class Family extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyValue: "",
      page: 1,
      pageCount: 100,
      paramsFilter: "",
      filter:
        JSON.parse(localStorage.getItem("families_filters")) ||
        initialFilterState,
      selected: [],
      selectedAll: false,
    };
    this.available_edit = props.auth.user_role.identifier !== "hosts";
  }

  componentDidMount() {
    this.onFilter();
    this.loadData();
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.reloadData(this.state.paramsFilter);
    }
  }

  loadData = () => {
    this.props.getStoreItems(1, 200, "");
  };

  reloadData = (paramsFilter) => {
    this.props.getFamilies(this.state.page, this.state.pageCount, paramsFilter);
  };

  setPagination = (page) => {
    this.setState({
      page,
    });
  };

  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  onAction = (action, id) => {
    this.setState({
      requiredId: id,
      action: action,
    });
  };

  delayOnFilter = () => {
    if (this.props.loading_brands) {
      return setTimeout(() => {
        return this.delayOnFilter();
      }, 1000);
    }
    this.onFilter();
  };

  onFilter = () => {
    if (this.props.loading_brands) {
      return this.delayOnFilter();
    }
    let filterParams = [];
    if (this.state.filter.title.length > 0) {
      filterParams.push(`&q[name_or_synonim_title]=${this.state.filter.title}`);
    }
    if (this.state.filter.type.length > 0) {
      if (this.state.filter.type === "marked") {
        filterParams.push(`&q[marked_true]=1`);
      } else if (this.state.filter.type === "printed") {
        filterParams.push(`&q[printed_true]=1`);
      }
    }
    this.setState(
      {
        paramsFilter: filterParams.join(""),
        page: 1,
      },
      () => this.reloadData(filterParams.join(""))
    );
    localStorage.setItem("families_filters", JSON.stringify(this.state.filter));
  };

  filterChange = (e) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [e.target.name]: e.target.value,
      },
    });
  };

  typeChange = (e) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [e.name]: e.value,
        },
      },
      () => this.onFilter()
    );
  };

  handleKeyDown = (e) => {
    if (e.key == "Enter") {
      this.onFilter();
    }
  };

  clearFilter = () => {
    this.setState(
      {
        filter: initialFilterState,
      },
      () => this.onFilter()
    );
  };

  typeFilterClass = (type) => {
    if (type === this.state.filter.type) {
      return " active";
    }
    return "";
  };

  handleToggle = (value) => {
    const currentIndex = this.state.selected.indexOf(value);
    let newChecked = [...this.state.selected];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      selected: newChecked,
      selectedAll: false,
    });
  };

  handleCheckboxAll() {
    const myData = this.props.families;
    const newChecked = [...this.state.selected];
    let newArray = [];
    if (myData.length === newChecked.length) {
      this.setState({
        selectedAll: false,
        selected: [],
      });
    } else {
      myData.forEach((item) => newArray.push(item.id));
      this.setState({
        selectedAll: true,
        selected: newArray,
      });
    }
  }

  setSelected = (e, type) => {
    e.preventDefault();
    if (this.state.selected.length > 0) {
      const data = {
        family: {
          ids: this.state.selected.join(","),
          type: type,
        },
      };
      this.props.updateMarking(data);
      this.reloadData(this.state.paramsFilter);
      this.setState({
        selectedAll: false,
        selected: [],
      });
    }
  };

  render() {
    const requiredItem = this.state.requiredId;
    return (
      <Container
        location={{
          locationName: "Kategorijos",
          locationUrl: this.props.match.url,
        }}
      >
        <ContentBlock>
          <FilterMenu
            length={`${this.props.totals || 0} / ${
              this.props.families.length || 0
            }`}
            onChangeCountPage={this.setPageCount}
            countPage={this.state.pageCount}
            text="kategorijų"
            text2="Kategorijų"
          />
        </ContentBlock>
        <ContentBlock>
          <TableFilterBox
            filter={false}
            onFilter={this.onFilter}
            clearFilter={this.clearFilter}
          >
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="title"
                placeholder="Kategorija"
                onKeyDown={this.handleKeyDown}
                value={this.state.filter.title}
                onChange={(e) => this.filterChange(e)}
              />
            </div>{" "}
            <div className="px-1">
              <div className="form-group">
                <div>
                  <Label>Tipas</Label>
                </div>
                <div className="d-flex">
                  <button
                    className={
                      "btn btn-family-filter" + this.typeFilterClass("all")
                    }
                    onClick={() =>
                      this.typeChange({ name: "type", value: "all" })
                    }
                  >
                    Visos
                  </button>
                  <button
                    className={
                      "btn btn-family-filter" + this.typeFilterClass("printed")
                    }
                    onClick={() =>
                      this.typeChange({ name: "type", value: "printed" })
                    }
                  >
                    Spausdinamos
                  </button>
                  <button
                    className={
                      "btn btn-family-filter" + this.typeFilterClass("marked")
                    }
                    onClick={() =>
                      this.typeChange({ name: "type", value: "marked" })
                    }
                  >
                    Markiruojamos
                  </button>
                </div>
              </div>
            </div>
          </TableFilterBox>
        </ContentBlock>
        <div className="col-12 my-3">
          <div className="dropdown dropdown-family">
            <button
              className="btn btn-danger dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Pakeisti
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => this.setSelected(e, "marked")}
              >
                Markiruojamos
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => this.setSelected(e, "printed")}
              >
                Spausdinamos
              </a>
            </div>
          </div>
        </div>
        <ContentBlock>
          <div>
            <ContentValidation
              loader={this.props.loading}
              array={this.props.families}
              alertId_s="familiesAlert-s"
              alertId_e="familiesAlert-e"
            >
              <div className="table-responsive" style={{ overflow: "hidden" }}>
                <table className="table mt-3">
                  <thead>
                    <tr className="text-muted">
                      <th className="text-black">
                        <Checkbox
                          onChange={() => this.handleCheckboxAll()}
                          checked={this.state.selectedAll}
                        />
                      </th>
                      <th className="text-black">Kategorija</th>
                      <th className="text-black">Sinonimas</th>
                      <th className="text-black text-center">Markiruojamos</th>
                      <th className="text-black text-center">Spausdinamos</th>
                      <th className="text-black text-center">
                        Atributų skaičius
                      </th>
                      <th className="text-black text-center">
                        Privalomi atributai
                      </th>
                      <th className="text-black text-center">Formatas</th>
                      <th className="text-black"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.families &&
                      this.props.families.map((item, index) => {
                        return (
                          <TableColumnFamily
                            key={index}
                            index={index}
                            item={item}
                            onEdit={this.onAction}
                            setSelected={() => this.handleToggle(item.id)}
                            selected={this.state.selected.includes(item.id)}
                          />
                        );
                      })}
                  </tbody>
                </table>
                <Pagination
                  setPagination={this.setPagination}
                  page={this.state.page}
                  totals={this.props.totals}
                  pageCount={this.state.pageCount}
                />
              </div>
            </ContentValidation>
          </div>
        </ContentBlock>
        <FamilyEditModal
          action={this.state.action}
          updateFamily={this.props.updateFamily}
          familyId={requiredItem}
          clearErrors={this.props.clearErrors}
          errors={this.props.errors}
          stores={this.props.stores}
        />
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  families: state.family.families,
  totals: state.family.totals,
  loading: state.family.loading_families,
  errors: state.errors,
  auth: state.auth.user,
  stores: state.store.storeItems,
});

export default connect(mapStateToProps, {
  getFamilies,
  updateFamily,
  getStoreItems,
  updateMarking,
})(Family);
