import Axios from "axios";
import {
  antdMessageError,
  antdMessageSuccess,
  errorHandler,
} from "../../helpers/Utils";
import { getFamiliesOptions } from "./familiesAction";
import { getStoreOptions } from "./storeAction";
import { getSuppliersOption } from "./supplierAction";

export const getReports =
  (page = 1, pageCount = 50, filter = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: "LOADING_CUSTOM",
      });
      const { data } = await Axios.get(
        `/api/v1/reports?page=${page}&per_page=${pageCount}${filter}`
      );
      dispatch({
        type: "GET_CUSTOMS",
        payload: { data: data.data, current: "Ataskaitos" },
      });
      return { success: true };
    } catch (err) {
      dispatch({
        type: "STOP_LOADING_CUSTOM",
      });
      return { success: false };
    }
  };

export const getReportOptions = () => async (dispatch) => {
  try {
    const getCategories = () =>
      getFamiliesOptions().then((res) => {
        return res;
      });
    Promise.all([
      getCategories(),
      // TODO: remove later
      getStoreOptions().then((data) => [
        { label: "Suminis", value: "suminis" },
        ...data.map((d) => ({
          value: d.rivile_code,
          label: `${d.name} - ${d.rivile_code}`,
        })),
      ]),
      getSuppliersOption().then((res) =>
        res.data.data
          .filter((d) => d.company_code)
          .map((d) => ({
            value: d.id,
            label: d.name,
          }))
      ),
    ]).then((res) => {
      console.log("Response: ", { res });
      dispatch({
        type: "SET_OPTIONS",
        payload: {
          families: res[0],
          rivile_stores: res[1],
          suppliers: [
            {
              value: "",
              label: "Nepasirinkti",
            },
            ...res[2],
          ],
        },
      });
    });
    return { success: true };
  } catch (err) {
    console.log("ERROR ", err);
    return { success: false };
  }
};

export const createReport = (params) => async (dispatch) => {
  try {
    const { data } = await Axios.post("/api/v1/reports", { reports: params });
    await dispatch(getReports());
    antdMessageSuccess();
    return { success: true, data };
  } catch (err) {
    errorHandler(err);
    antdMessageError();
    return { success: false };
  }
};

export const regenerateReport = async (id) => {
  try {
    const { data } = await Axios.post(
      `/api/v1/reports/${id}/regenerate_report`
    );
    console.log(data);
    return { success: true };
  } catch (err) {
    antdMessageError();
    errorHandler(err);
    return { success: false };
  }
};

export const sendEmailReport = async (report_id, report_email_id) => {
  try {
    const { data } = await Axios.post(
      `/api/v1/reports/${report_id}/report_emails/${report_email_id}/send_email`
    );
    console.log(data);
    antdMessageSuccess();
    return { success: true };
  } catch (err) {
    errorHandler(err);
    antdMessageError(err?.response?.data?.error || "");
    return { success: false };
  }
};

export const updateReport = (params) => async (dispatch) => {
  try {
    const { data } = await Axios.put(`/api/v1/reports/${params.id}`, {
      reports: params,
    });
    dispatch({
      type: "UPDATE_CUSTOM",
      payload: data,
    });
    antdMessageSuccess();
    return { success: true };
  } catch (err) {
    antdMessageError();
    errorHandler(err);
    return { success: false };
  }
};

export const deactivateReports =
  (ids, page, perCount, filters) => async (dispatch) => {
    try {
      const { data } = await Axios.post(`/api/v1/reports/deactivate_reports`, {
        ids,
      });
      console.log("updateReport: ", data.data);
      dispatch(getReports(page, perCount, filters));
      antdMessageSuccess();
      return { success: true };
    } catch (err) {
      antdMessageError();
      errorHandler(err);
      return { success: false };
    }
  };

export const removeReport = (report_id) => async (dispatch) => {
  try {
    await Axios.delete(`/api/v1/reports/${report_id}`);
    dispatch({
      type: "REMOVE_CUSTOM",
      payload: report_id,
    });
    return { success: true };
  } catch (err) {
    dispatch({
      type: "STOP_LOADING_CUSTOM",
    });
    return { success: false };
  }
};
