import React, { memo, useMemo } from "react";
import { Button, Checkbox, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import AntdConfirm from "../../../helpers/AntdConfirm";
import TableUi from "../../common/TableUi";
import {
  IconAlternativeProduct,
  IconChevron,
  IconExclamationColored,
  IconProductAnalog,
  IconTrash,
} from "../../Layout/Icons";
import { deleteCommerceProduct } from "../../../redux/Actions/commerce_products_action";

function CommerceTableProductColumn({
  type,
  checkedIncluded,
  onChangeCheckedCallBack,
  open,
  setOpenCallBack,
  product,
  onHandleModalOptCallBack,
  trStyles = {},
}) {
  console.count("CommerceTableProductColumn: ", product.identifier);
  const dispatch = useDispatch();

  const tableBalances = useMemo(() => {
    const reducePrices = (arr, value) =>
      arr.reduce((acc, cur) => +acc + +cur[value], 0) + "";

    const values = {
      U080: "-",
      U0809: "-",
      U0711: "-",
      need_review: product.stores.some((d) => d.need_review),
      total_amount: reducePrices(product.stocks, "amount"),
    };

    product.stocks
      .filter((d) => ["U080", "U0809", "U0711"].includes(d.code))
      .forEach((stock) => {
        const amount = reducePrices([stock], "amount");
        const reserved = reducePrices([stock], "reserved");

        const stocks_amount = product.stores
          .filter((d) => d.code == stock.code)
          .reduce((cur, prev) => +prev.order_qty + cur, 0);

        if (stocks_amount) {
          values[stock.code] = (
            <div>
              {+amount + +reserved}{" "}
              <Tooltip placement="top" title="Paskirstytas kiekis">
                <span
                  className="text-white bg-orange rounded-circle text-xs text-center"
                  style={{
                    display: "inline-block",
                    height: 20,
                    width: 20,
                  }}
                >
                  {stocks_amount}
                </span>
              </Tooltip>{" "}
              (R:{reserved};L: {amount})
            </div>
          );
        } else {
          values[stock.code] = `${+amount + +reserved} (R:${reserved};L:
            ${amount})`;
        }
      });

    return values;
  }, [open, product.updated_at]);

  const have_childrens = !!product.childs?.length;

  return (
    <TableUi.tr
      key={product.id}
      style={trStyles}
      className={
        open
          ? "table-without-borders align-middle tableColumn"
          : "align-middle tableColumn"
      }
    >
      <>
        <TableUi.td className="p-left position-relative">
          {onChangeCheckedCallBack && (
            <div className="d-flex">
              {tableBalances.need_review && (
                <div
                  className="position-absolute"
                  style={{
                    left: "-13px",
                  }}
                >
                  <IconExclamationColored />
                </div>
              )}
              <Checkbox
                checked={checkedIncluded}
                onChange={() => onChangeCheckedCallBack(product.id)}
              />
              <div className={`ml-2 cursor-pointer ${!open && checkedIncluded ? "auto_commerce_open_button" : ""}`} onClick={setOpenCallBack}>
                <IconChevron up={open} />
              </div>
            </div>
          )}
        </TableUi.td>
        <TableUi.td>
          {open && have_childrens && (
            <>
              <div
                className="text-xs"
                style={{
                  color: "#555580",
                }}
              >
                Pirminė prekė
              </div>
            </>
          )}
          {open && type == "child" && (
            <div
              className="text-xs"
              style={{
                color: "#555580",
              }}
            >
              Alternatyvi prekė
            </div>
          )}
          <div className="d-flex">
            {(open ? type == "child" : have_childrens) && (
              <Tooltip placement="top" title="Prekė turi alternatyvą">
                <div className="mr-2">
                  <IconAlternativeProduct />
                </div>
              </Tooltip>
            )}

            <div className="text-sm mt-1">{product.identifier}</div>
          </div>
        </TableUi.td>
      </>
      <TableUi.td>{product.title}</TableUi.td>
      <TableUi.td>{product.mpn}</TableUi.td>
      <TableUi.td>{tableBalances.total_amount}</TableUi.td>
      <TableUi.td>{tableBalances["U080"]}</TableUi.td>
      <TableUi.td>{tableBalances["U0809"]}</TableUi.td>
      <TableUi.td>{tableBalances["U0711"]}</TableUi.td>
      <TableUi.td className={"py-3"}>
        <div className="d-flex justify-content-end">
          {type !== "child" && !have_childrens && (
            <Tooltip placement="top" title="Kurti alternatyvą">
              <Button
                style={{
                  background: "#555580",
                  borderRadius: 3,
                  width: 33,
                  height: 33,
                  padding: 0,
                }}
                onClick={() =>
                  onHandleModalOptCallBack("create_analog", product.id, product)
                }
              >
                <IconProductAnalog />
              </Button>
            </Tooltip>
          )}
          <div className="mx-1" />
          <Tooltip placement="top" title="Trinti">
            <Button
              style={{
                background: "#E61D01",
                borderRadius: 3,
                width: 33,
                height: 33,
                padding: 0,
              }}
              onClick={() =>
                AntdConfirm({
                  onOk: () => {
                    dispatch(deleteCommerceProduct(product.id));
                  },
                })
              }
            >
              <IconTrash color="#fff" />
            </Button>
          </Tooltip>
        </div>
      </TableUi.td>
    </TableUi.tr>
  );
}

export default memo(CommerceTableProductColumn);
