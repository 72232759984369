import React from "react";
import { Dot } from "../../common/Dot";
import printerSvg from "../../../../assets/images/printer.svg";
import previewSvg from "../../../../assets/images/preview.svg";
import moment from "moment";

function TableColumnSms({
  item,
  index,
  onPrint,
  onView,
  store,
}) {
  return (
    <tr
      className={
        "align-middle tableColumn repricingColumn " +
        (item.family && item.family.printed ? "printed" : "marked")
      }
      key={index}
    >
      {/* <td className="align-middle">
        <Checkbox onChange={setSelected} checked={selected} />
      </td> */}
      <td className="align-middle">
        <Dot value={false} className={item.moved_at ? "green" : "red"} />
      </td>
      <td className="align-middle">
        {moment(item.operation_time).format("YYYY-MM-DD HH:mm")}
      </td>
      <td className="align-middle">{item.operation_number}</td>
      <td className="align-middle">{item.document}</td>
      <td className="align-middle">{item.repricing_type}</td>
      <td className="align-middle">{item.note}</td>
      <td className="align-middle">{store}</td>
      <td className="align-middle">
        {item.moved_at && (
          <div className="d-flex flex-column align-items-center">
            <div className="text-sm text-black">
              {moment(item.moved_at).format("YYYY-MM-DD HH:mm")}
            </div>
            <div className="text-xs2 text-gray-600">{item.full_name}</div>
          </div>
        )}
      </td>
      <td className="align-middle" style={{ width: "120px" }}>
        <a
          className="btn btn-sm mb-1 mr-1"
          style={{ background: "#F7931E" }}
          onClick={(e) => onView(e, item.id)}
        >
          <img
            style={{ color: "white" }}
            width="14"
            height="14"
            src={previewSvg}
          />
        </a>

        <button
          className="btn btn-sm mb-1"
          style={{ background: "#ef3824" }}
          onClick={(e) => onPrint(e, "selected", item.id)}
        >
          <img
            style={{ color: "white" }}
            width="14"
            height="14"
            src={printerSvg}
          />
        </button>
      </td>
    </tr>
  );
}

export default TableColumnSms;
