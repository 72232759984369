import Axios from "axios";
import {
  antdAsyncMessageError,
  antdAsyncMessageLoading,
  antdAsyncMessageSuccess,
} from "../../helpers/AsyncMessage";
import { antdMessageError, buildErrorObject } from "../../helpers/Utils";

export const getGuideRecommendation =
  (page = 1, filter = "") =>
  async (dispatch) => {
    try {
      const { data } = await Axios.get(
        `/api/v1/guide_products.json?page=${page}${filter}`
      );
      console.log("Data: ", data);

      dispatch({
        type: "GET_CUSTOMS",
        payload: {
          current: "Guide buyers",
          data: data.data,
          totals: data.totals,
        },
      });
    } catch (err) {
      console.log("Error: ", err);
      return { success: false };
    }
  };

export const getGuideRecommendationShow = (id, params) => async (dispatch) => {
  try {
    const { data } = await Axios.get(`/api/v1/guide_products/${id}${params}`);

    dispatch({
      type: "GET_CUSTOM",
      payload: data,
    });
    return {
      success: true,
      data: data,
    };
  } catch (err) {
    console.log("Error: ", err);
    return { success: false };
  }
};

export const getGuidesRecommendationsShopCategories = async () => {
  try {
    const { data } = await Axios.get(
      "/api/v1/guide_products/shop_categories_ids"
    );
    return {
      success: true,
      data: data.shop_categories,
    };
  } catch (err) {
    console.log(`Error: ${err}`);
    return { success: false };
  }
};

export const postGuideRecommendations = (params) => async (dispatch) => {
  try {
    const guide_products = {
      ...params,
    };
    delete guide_products.shop_category;
    delete guide_products.guide_product_items;

    const id_is = params.id;
    antdAsyncMessageLoading({
      key: "postGuideRecommendations",
    });
    const { data } = await Axios({
      url: `/api/v1/guide_products/${id_is || ""}`,
      method: id_is ? "PUT" : "POST",
      data: { guide_products },
    });
    console.log(data.success);

    dispatch({
      type: id_is ? "UPDATE_CUSTOM" : "POST_CUSTOM",
      payload: data,
    });
    antdAsyncMessageSuccess({
      key: "postGuideRecommendations",
    });
    return { success: true, data };
  } catch (err) {
    antdAsyncMessageError({
      key: "postGuideRecommendations",
    });
    console.log("Error: ", err);
    return { success: false, errors: err?.response?.data };
  }
};

export const postGuideRecommendationItem =
  (params) => async (dispatch, getState) => {
    try {
      const guide_product_items = { ...params };
      delete guide_product_items.current_balance;

      const id_is = params.id;
      antdAsyncMessageLoading({
        key: "postGuideRecommendationItem",
      });
      const { data } = await Axios({
        url: `/api/v1/guide_product_items/${id_is ? `${id_is}` : ""}`,
        method: id_is ? "PUT" : "POST",
        data: { guide_product_items },
      });
      console.log({ data });
      if (data.success) {
        const redux_data = getState().custom.show_data;
        let guide_product_items = redux_data.guide_product_items;

        console.log({ guide_product_items });
        if (id_is)
          guide_product_items = guide_product_items.map((d) => {
            if (d.id == data.data.id) {
              return { ...d, ...data.data };
            }
            return d;
          });
        else guide_product_items.push(data.data);
        dispatch({
          type: "GET_CUSTOM",
          payload: {
            ...redux_data,
            guide_product_items,
          },
        });
      }
      antdAsyncMessageSuccess({
        key: "postGuideRecommendationItem",
      });
      return { success: true, data };
    } catch (err) {
      antdAsyncMessageError({
        key: "postGuideRecommendationItem",
      });
      const arr = buildErrorObject(err?.response?.data?.errors);

      if (err?.response?.data?.success == false) {
        Object.entries(arr).forEach((d) => {
          antdMessageError(
            `${d[0] == "product_code" ? "Produkto kodas" : d[0]}: ${d[1]}`
          );
        });
        console.log({ arr });
      }
      console.log("Error: ", err);
      return { success: false, errors: arr };
    }
  };

export const deleteGuideRecommendations = (id) => async (dispatch) => {
  try {
    antdAsyncMessageLoading({
      key: "deleteGuideRecommendations",
    });
    const { data } = await Axios.delete(`/api/v1/guide_products/${id}`);
    if (data.success) {
      dispatch({
        type: "REMOVE_CUSTOM",
        payload: id,
      });
      antdAsyncMessageSuccess({
        key: "deleteGuideRecommendations",
      });
      return { success: true };
    }
    throw new Error("Err");
  } catch (err) {
    antdAsyncMessageError({
      key: "deleteGuideRecommendations",
    });
    return { success: false };
  }
};

export const deleteGuideRecommendationItem =
  (id) => async (dispatch, getState) => {
    try {
      antdAsyncMessageLoading({
        key: "deleteGuideRecommendationItem",
      });
      const { data } = await Axios.delete(`/api/v1/guide_product_items/${id}`);
      const redux_data = getState().custom.show_data;
      if (data.success) {
        dispatch({
          type: "GET_CUSTOM",
          payload: {
            ...redux_data,
            guide_product_items: redux_data.guide_product_items.filter(
              (d) => d.id !== id
            ),
          },
        });
        antdAsyncMessageSuccess({
          key: "deleteGuideRecommendationItem",
        });
        return { success: true };
      }
      throw new Error("Err");
    } catch (err) {
      antdAsyncMessageError({
        key: "deleteGuideRecommendationItem",
      });
      return { success: false };
    }
  };

export const setterGuideRecommendationItemToReady =
  (params) => async (dispatch, getState) => {
    try {
      antdAsyncMessageLoading({
        key: "setterGuideRecommendationItemToReady",
      });
      const { data } = await Axios.post(
        `/api/v1/guide_product_items/ready_setter`,
        params
      );
      if (data.success) {
        const redux_data = getState().custom.show_data;
        console.log("buildina array");
        dispatch({
          type: "GET_CUSTOM",
          payload: {
            ...redux_data,
            guide_product_items: redux_data.guide_product_items.map((d) => {
              if (params.ids.includes(d.id))
                return { ...d, ready: params.ready };
              return d;
            }),
          },
        });
        antdAsyncMessageSuccess({
          key: "setterGuideRecommendationItemToReady",
        });
        return { success: true };
      }
      throw new Error("Err");
    } catch (err) {
      antdAsyncMessageError({
        key: "setterGuideRecommendationItemToReady",
      });
      return { success: false };
    }
  };

export const putGuideProductPostions =
  (collection) => async (dispatch, getState) => {
    try {
      let redux_data = getState().custom.show_data;
      redux_data.guide_product_items = collection;
      dispatch({
        type: "GET_CUSTOM",
        payload: redux_data,
      });
      const { data } = await Axios.put(
        "/api/v1/guide_product_items/update_positions",
        {
          collection,
          id: redux_data.id
        }
      );
      dispatch({
        type: "GET_CUSTOM",
        payload: data,
      });
      return { success: true, data };
    } catch (err) {
      console.log("Error: ", err);
      return { success: false };
    }
  };
