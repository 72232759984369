import React, { useState, useEffect, Fragment } from "react";
import {
  SelectForm,
  ButtonGradientAdd,
  ButtonDelete,
} from "../../common/Buttons";
import ContentValidation from "../../common/ContentValidation";
import TextInputModal from "../../common/TextInputModal";
import ContentBlock from "../../Layout/ContentBlock";
import Table from "../../../components/common/TableUi";
import AttachmentSvg from "../../../../assets/images/attachmentSvg.svg";
import GoodsSvg from "../../../../assets/images/goods.svg";
import {
  getLandings,
  getLandingTypes,
  getLandingCategories,
  removeLanding,
} from "../../../redux/Actions/landingAction";
import { Tooltip } from "antd";
import SimplePagination from "../../common/SimplePagination";
import { successToast, unsuccessToast } from "../../../helpers/ErrorsToasts";
import GuideFirstModalWrapper from "./GuideFirst/GuideFirstModalWrapper";
import InputSwitchComponent from "../../common/InputSwitchComponent";
import { ActiveDot } from "../../utils";

const prettierNameAssign = (name) => {
  switch (name) {
    case "product":
      return "Produktai";
    case "manufacturer":
      return "Gamintojai";
    case "category":
      return "Kategorijos";
    case "property_filters":
      return "Savybių filtrai";
    default:
      return "";
    // code block
  }
};

export default function GuideFirst() {
  const [landings, setLandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totals, setTotals] = useState(0);
  const [modal, setModal] = useState(false);

  const [filter, setFilter] = useState({
    title: "",
    type: undefined,
    category: undefined,
    status: "",
  });
  const [filterString, setfilterString] = useState("");
  //
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);

  const fetchLandings = async (filter = "") => {
    setLoading(true);
    try {
      const data = await getLandings(page, filter || filterString);
      if (!data.totals) {
        setLandings([]);
        setTotals(0);
        setLoading(false);
        return;
      }
      setLandings(data.data);
      setTotals(data.totals);
      setLoading(false);
    } catch (err) {
      console.log("Error: ", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLandings();
    getLandingCategories().then((res) => {
      if (res.success) {
        setCategories([
          { value: null, label: "Visi" },
          ...res.data.map((e) => ({ value: e.id, label: e.name })),
        ]);
      }
    });
    getLandingTypes().then((res) => {
      if (res.success) {
        setTypes([
          { value: null, label: "Visi" },
          ...res.data.map((e) => ({
            label: e.title,
            value: e.id,
          })),
        ]);
      }
    });
  }, []);

  useEffect(() => {
    fetchLandings(filterString);
  }, [filterString, page]);

  const onChange = (e) => {
    const { value, name } = e.target;
    const filters = { ...filter, [name]: value };
    setFilter(filters);
    onFilter(filters);
  };
  const onFilter = (filter) => {
    let filterParams = [];

    if (filter.title) {
      filterParams.push(`&q[title_cont_any]=${filter.title}`);
    }
    if (filter.type) {
      filterParams.push(`&q[landing_type_id_eq]=${filter.type}`);
    }
    if (filter.category) {
      filterParams.push(`&q[category_id_eq]=${filter.category}`);
    }
    if (filter.status) {
      if (filter.status === "active") filterParams.push(`&q[active_true]=1`);
      if (filter.status === "inactive") filterParams.push(`&q[active_true]=0`);
    }

    setfilterString(filterParams.join(""));
    setPage(1);
  };

  return (
    <div>
      <ContentBlock>
        <div className={"bg-white d-flex flex-wrap pl-1 pr-1"}>
          <div className="col-12">
            <div className="row">
              <div className="col-3">
                <TextInputModal
                  text="Pavadinimas"
                  name="title"
                  value={filter.title}
                  onChange={(e) =>
                    setFilter({ ...filter, title: e.target.value })
                  }
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      onFilter(filter);
                    }
                  }}
                />
              </div>
              <div className="col-3">
                <SelectForm
                  placeholder="Tipas"
                  onlyPlaceholder="Pasirinkti"
                  value={filter.type}
                  options={types}
                  onChange={(value) =>
                    onChange({ target: { value, name: "type" } })
                  }
                />
              </div>
              {/* <div className="col-3">
                <SelectForm
                  placeholder="Portalo kategorija"
                  onlyPlaceholder="Pasirinkti"
                  value={filter.category}
                  options={categories}
                  onChange={(value) =>
                    onChange({ target: { value, name: "category" } })
                  }
                />
              </div> */}
              <div className="col-3">
                <InputSwitchComponent
                  touches={true}
                  label="Statusas"
                  options={[
                    {
                      label: "Visi",
                      value: "",
                    },
                    {
                      label: "Aktyvus",
                      value: "active",
                    },
                    {
                      label: "Neaktyvus",
                      value: "inactive",
                    },
                  ]}
                  name="status"
                  value={filter.status}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </div>
      </ContentBlock>
      <ContentBlock extraClass={"mt-2"}>
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center">
            <h4
              className="font-weight-bold mb-0"
              style={{ fontSize: 18, color: "#262626" }}
            >
              Landingai
            </h4>
            <span>
              <ButtonGradientAdd
                text="Pridėti naują landingą"
                onClick={() => setModal(0)}
              />
            </span>
          </div>
        </div>
        <ContentValidation loader={loading} array={landings}>
          <Table
            ths={[
              "Pavadinimas",
              "Tipas",
              // "Portalo kategorija",
              "Priskirtas landingas",
              "Prekės",
              "SLUG",
              "Statusas",
              "",
            ]}
          >
            {landings?.map((e) => (
              <Table.tr key={e.id}>
                <Table.td className="p-left">
                  <div
                    className="text-decoration-underline cursor-pointer"
                    onClick={() => {
                      setModal(e.id);
                    }}
                  >
                    {e.title}
                  </div>
                </Table.td>
                <Table.td>{e.landing_type?.title}</Table.td>
                {/* <Table.td>
                  {e.landing_categories.map(
                    (d, idx) =>
                      `${d.category?.name}${
                        e.landing_categories.length - 1 == idx ? "" : ", "
                      }`
                  )}
                </Table.td> */}
                <Table.td>
                  {[...new Set(e.landing_assigns.map((e) => e.identifier))].map(
                    (la, idx) => (
                      <span key={idx}>
                        {prettierNameAssign(la)}
                        {[
                          ...new Set(
                            e.landing_assigns.map((e) => e.identifier)
                          ),
                        ].length -
                          1 >
                        idx
                          ? " ,"
                          : ""}
                      </span>
                    )
                  )}
                </Table.td>
                <Table.td>
                  {e.assign_text?.length > 0 && (
                    <Tooltip
                      placement="top"
                      title={
                        <span>
                          {e.assign_text.map((at, idx) => (
                            <Fragment key={`${idx}_${at}`}>
                              {at}
                              <br />
                            </Fragment>
                          ))}
                        </span>
                      }
                    >
                      <img src={GoodsSvg} />
                    </Tooltip>
                  )}
                </Table.td>
                <Table.td>
                  <Tooltip placement="top" title={e.slug}>
                    <img src={AttachmentSvg} />
                  </Tooltip>
                </Table.td>
                <Table.td>
                  <ActiveDot active={e.active} />
                </Table.td>
                <Table.td className="d-flex p-right">
                  <ButtonDelete
                    onDelete={async () => {
                      if (!confirm("Ar tikrai norite ištrinti?")) return;
                      const { success } = await removeLanding(e.id);
                      if (success) {
                        setLandings((prevProps) =>
                          prevProps.filter((d) => d.id !== e.id)
                        );
                        successToast("landing-s");
                      } else {
                        unsuccessToast("landing-e");
                      }
                    }}
                  />
                </Table.td>
              </Table.tr>
            ))}
          </Table>
        </ContentValidation>
      </ContentBlock>
      <div className="mt-2">
        <SimplePagination
          setPagination={(pg) => setPage(pg)}
          pageCount={10}
          page={page}
          totals={totals}
        />
      </div>
      <GuideFirstModalWrapper
        modal={modal}
        setModal={setModal}
        landings={landings}
        setLandings={setLandings}
      />
    </div>
  );
}

// Modalas
