import React from "react";
import { CheckBoxInput } from "../common/Buttons";
import TextInputModal from "../common/TextInputModal";

export function SbLeasingAdditionals({
  item,
  identifier,
  disabled,
  update_children,
  remove_children,
}) {
  const exist = !!item;
  return (
    <tr>
      <td className="pb-0 pl-0 align-middle">
        <nobr>
          <CheckBoxInput
            disabled={disabled}
            checked={exist}
            text={identifier}
            onChange={
              exist ? () => remove_children() : () => update_children({})
            }
          />
        </nobr>
      </td>
      <td className="pb-0 align-middle">
        <TextInputModal
          disabled={disabled || !exist}
          placeholder="Kaina"
          value={item?.price}
          onChange={(e) => {
            const price = e.target.value;
            update_children({ price, total: price });
          }}
          warning={exist && !item?.price}
        />
      </td>
      <td
        className="pb-0 align-middle"
        style={{
          width: 200,
        }}
      ></td>
    </tr>
  );
}
