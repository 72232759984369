import React from "react";
import moment from "moment";
import { LongButton } from "../../../../common/Buttons";

function OrderHistory({ item, index }) {
  return (
    <>
      <tr key={index}>
        <td className="residuesItem pb-0">
          {moment(item.datetime).format("YYYY-MM-DD HH:mm")}
        </td>
        <td className="residuesItem pb-0">{item.title}</td>
        <td className="residuesItem pb-0">
          <LongButton
          style={{
            backgroundColor: "#8678ab",
            color: "white",
            fontSize: "12px",
            height: 'auto'
          }}
          text={item.status?.title || item.status} />
        </td>
      </tr>
    </>
  );
}

export default OrderHistory;
