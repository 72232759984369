import React from "react";
import goodSvg from "../../../../../../../assets/images/good.svg";
import badSvg from "../../../../../../../assets/images/bad.svg";
import { toFixedNumber } from "../../../../../../helpers/Utils";

function OrderTotalComponent({ total_price, payments, order_id }) {
  total_price = toFixedNumber(total_price)
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    window.addEventListener("resize", (e) => setWidth(window.innerWidth));
  }, []);

  if (order_id < 29535) {
    return (
      <div className="text-center">
        <span className="badge badge-warning badge-middle  badge-order-price">
          Užsakymo kaina: {Number(total_price).toFixed(2)} {"\u20AC"}
        </span>
      </div>
    );
  }


  const screen_width = 1350
  const total = toFixedNumber(payments.reduce((acc, cur) => +acc + +cur.price, 0))
  const equalTo = Number(total) >= Number(total_price);
  const equal = total == total_price;
  const difference = equalTo ? total - +total_price : +total_price - total;
  const equalWidthBlock = !equal && width < screen_width
  
  return (
    <React.Fragment>
      {equalWidthBlock && (
        // nerodyt jai equal
        <div className="text-center mb-3">
          <span
            className={
              "badge badge-danger badge-center  badge-order-price badge-danger-color"
            }
            style={{fontWeight: "bold"}}
          >
            {/* <span>
              <img
                src={badSvg}
                width="13"
                className="mr-1"
                style={{ marginBottom: "3px" }}
              />
            </span> */}
            {Number(difference).toFixed(2)} {"\u20AC"} skirtumas
          </span>
        </div>
      )}

      <div className={"text-center"}>
        {equal ? (
          <span className="badge badge-danger badge-left  badge-order-price">
            {!equalWidthBlock && (
            <span>
              <img
              src={goodSvg}
              width="13"
              className="mr-1"
              style={{ marginBottom: "3px" }}
              />
            </span>
            )}
            Apmokėta
          </span>
        ) : equalTo ? (
          <span
            className="badge badge-danger badge-left  badge-order-price"
            style={{ backgroundColor: "#594f4f", borderColor : "#594f4f" }}
          >
             {!equalWidthBlock && (
               <span>
              <img
                src={goodSvg}
                width="13"
                className="mr-1"
                style={{ marginBottom: "3px" }}
                />
            </span>
                )}
            Permokėta
          </span>
        ) : (
          <span className="badge badge-danger badge-left  badge-order-price badge-danger-color">
          {!equalWidthBlock && (
            <span>
              <img
                src={badSvg}
                width="13"
                className="mr-1"
                // style={{ marginBottom: "3px" }}
                />
            </span>
                )}
            Neapmokėta
          </span>
        )}
        <span className="badge badge-warning badge-middle  badge-order-price">
          Užsakymo kaina: {Number(total_price).toFixed(2)} {"\u20AC"}
        </span>
        {width >= screen_width && (
          <span
            className={`badge badge-success badge-right badge-order-price ${
              equal ? "invisible" : ""
            }`}
          >
            <span>
              <img
                src={badSvg}
                width="13"
                className="mr-1"
                // style={{ marginBottom: "3px" }}
              />
            </span>
            {Number(difference).toFixed(2)} {"\u20AC"} skirtumas
          </span>
        )}
      </div>
    </React.Fragment>
  );
}

export default OrderTotalComponent;
