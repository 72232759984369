import React from "react";
import SuppliersInvoicesModal from "../../Layout/SuppliersInvoicesModal";
import {
  exportSuppliersInvoice
} from "../../../redux/Actions/suppliersInvoicesAction";
import { connect } from "react-redux";
import TableItem from "./TableItem";
import StoreEdit from "./StoreEdit";

class ManagersEditModal extends React.Component {
  constructor() {
    super();
    this.state = {
      action: "edit",
      preview_manager: false,
      preview_sales: true,
      loading: false,
      invoice: {
        suppliers_invoices_items: []
      },
      month: new Date().getMonth() +1,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.objectData).length > 0) {
      const data = nextProps.objectData;

      this.setState({
        invoice: data,
        action: "edit",
      });
    }
  }

  exportInvoice = () => {
    let id = this.state.invoice.id;
    this.setState({
      loading: true
    });
    this.props.exportSuppliersInvoice(id).then((res) => {
      this.setState({
        loading: false
      });
    });
  };

  render() {
    return (
      <SuppliersInvoicesModal
        status="tableEdit"
        text={this.state.invoice.invoice_number}
        exported={this.state.invoice.exported}
        id={this.state.invoice.id}
        pay_due={this.state.invoice.pay_due_date}
        invoice_date={this.state.invoice.invoice_date}
        exportInvoice={() => this.exportInvoice()}
        supplier={this.state.invoice.supplier_name}
        store={this.state.invoice.store}
        sales_number={this.state.invoice.sales_number}
        delivery_address={this.state.invoice.delivery_address}
        supplier_id={this.state.invoice.supplier_id}
        loading={this.state.loading}
        store={<StoreEdit id={this.state.invoice.id} store={this.state.invoice.store} stores={this.props.stores}/>}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-sm table-hover mt-3">
                <thead>
                  <tr className="text-muted">
                    <th className="text-black">Eilės nr.</th>
                    <th className="text-black">Rivilės kodas</th>
                    <th className="text-black">MPN</th>
                    <th className="text-black">Pavadinimas</th>
                    <th className="text-black">Kiekis</th>
                    <th className="text-black">Kaina(1vnt.)</th>
                    <th className="text-black">Suma</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.invoice.suppliers_invoices_items && this.state.invoice.suppliers_invoices_items.map((item, index) => (
                    <TableItem key={index} index={index} id={item.id} item={item} />
                  ))}
                </tbody>
              </table>
              <div className="d-flex justify-content-end">
                <p className="mt-2 mr-4 mt-2">
                  <span
                    className="text-black mr-3"
                    style={{ fontSize: "14px" }}
                  >
                    <b>Prekių kiekis:</b> {this.state.invoice.items_quantity}
                  </span>
                  <span className="text-black" style={{ fontSize: "14px" }}>
                    <b>Suma be PVM:</b> {this.state.invoice.price_without_pvm}
                  </span>
                </p>
                <p className=" mt-2 mr-4 mt-2">
                  <span
                    className="text-black mr-3"
                    style={{ fontSize: "14px" }}
                  >
                    <b>PVM suma:</b> {this.state.invoice.pvm}
                  </span>
                  <span className="text-black mr-3" style={{ fontSize: "14px" }}>
                    <b>Nuolaida:</b> {this.state.invoice.discount}
                  </span>
                  <span className="text-black" style={{ fontSize: "14px" }}>
                    <b>Galutinė suma:</b> {this.state.invoice.price_total}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </SuppliersInvoicesModal>
    );
  }
}

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {
  exportSuppliersInvoice
})(ManagersEditModal);
