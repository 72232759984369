import React, { forwardRef, useImperativeHandle } from "react";
import ReactDOM from "react-dom";
import ModalSimple from "../../../Layout/Modals/ModalSimple";
import ModalSpecial from "../../../Layout/Modals/ModalSpecial";
import LayoutOfTablesModal from "./layoutOfTablesModal";
import CategoryFilterModal from "./categoryFilterModal";
import StatusFilterModal from "./StatusFilterModal";
import OrderShowModal from "../../../common/GlobalModals/Orders/orderShowModal";
import OrderCreateModal from "../../../common/GlobalModals/Orders/OrderCreateModal";
import { getPickupAddressesOption } from "../../../../redux/Actions/pickupAddressAction";
import { removeOverFlow, addOverFlow } from "../../../../helpers/modalFunc";
import { getParcelTerminalsOption } from "../../../../redux/Actions/parcelTerminalsAction";
import { useSelector } from "react-redux";

const Modal = forwardRef((props, ref) => {
  const { id_order, id: orderID } = useSelector((state) => state.orders.order);
  const [display, setDisplay] = React.useState("first");
  const [parcelTerminals, setParcelTerminals] = React.useState({});
  const [PickupAddressData, setPickupAddressData] = React.useState([]);
  useImperativeHandle(ref, () => {
    return {
      openModal: (active) => open(active),
      close: () => close(),
      active: display,
    };
  });

  const open = (active) => {
    requestOfOptions();
    addOverFlow();
    setDisplay(active);
  };

  const close = () => {
    removeOverFlow();
    setDisplay(false);
  };

  const requestOfOptions = () => {
    getParcelTerminalsOption().then((res) => {
      setParcelTerminals(res);
    });
  
      getPickupAddressesOption().then((response) => {
        if (response.data) {
          let newPickupAdress = [];
          response.data.data.forEach((address) => {
            let newAddressObj = {
              ...address,
              value: address.id,
              label: `${address.company} - ${address.address} - ${address.city} `,
            };
            return newPickupAdress.push(newAddressObj);
          });
          setPickupAddressData(newPickupAdress);
        }
      });

    console.count("Request times:");
  };

  if (display == "LayoutOfTablesModal") {
    return ReactDOM.createPortal(
      <ModalSimple
        text={"Lentelės išdėstymas"}
        handleSave={() => {}}
        onClose={() => close()}
      >
        <LayoutOfTablesModal
          layoutOfTable={props.layoutOfTable}
          layoutOfTablesFunc={props.layoutOfTablesFunc}
        />
      </ModalSimple>,
      document.getElementById("modal-roots")
    );
  }

  if (display == "CategoryFilterModal") {
    return ReactDOM.createPortal(
      <ModalSimple
        text={"Filtruoti pagal kategorijas"}
        secondButton={() => props.filterCategoryChange([])}
        secondButtonText="Išvalyti kategorijų filtrą"
        onClose={() => close()}
      >
        <CategoryFilterModal
          filterCategoryChange={props.filterCategoryChange}
          categoryFilter={props.categoryFilter}
        />
      </ModalSimple>,
      document.getElementById("modal-roots")
    );
  }

  if (display == "StatusFilterModal") {
    return ReactDOM.createPortal(
      <ModalSimple
        text={"Filtruoti pagal statusus"}
        secondButton={() => props.filterStatusChange([])}
        secondButtonText="Išvalyti statusų filtrą"
        onClose={() => close()}
      >
        <StatusFilterModal
          options={props.avalaibleStatus}
          selectedItems={props.statusFilter}
          tableColumnSelector={props.filterStatusChange}
        />
      </ModalSimple>,
      document.getElementById("modal-roots")
    );
  }

  if (display == "OrderShowModal") {
    const view_order_id = id_order || "";
    const tilteSpan_edit_order = (
      <span
        style={{
          fontSize: "1.2rem",
          lineHeight: "27px",
          color: "#000000",
          fontWeight: "700",
        }}
      >
        Užsakymas <span className="text-orange">#</span>
        {view_order_id}
      </span>
    );
    return ReactDOM.createPortal(
      <ModalSpecial
        text={tilteSpan_edit_order}
        handleSave={() => {}}
        onClose={() => close()}
      >
        <OrderShowModal
          pickupAddress={PickupAddressData}
          avalaibleStatus={props.avalaibleStatus}
          comments_state={props.comments_state_all}
          parcel_terminals={parcelTerminals}
        />
      </ModalSpecial>,
      document.getElementById("modal-roots")
    );
  }

  if (display == "OrderCreateModal") {
    const tilteSpan = (
      <span
        style={{
          fontSize: "1.2rem",
          lineHeight: "27px",
          color: "#000000",
          fontWeight: "700",
        }}
      >
        Užsakymo <span className="text-orange">kūrimas</span>
      </span>
    );

    let paymentTypes = props.payment_types.filter((e) => e.label !== "Visi");

    if (props.rline) paymentTypes = paymentTypes.filter(({ rline }) => rline);

    return ReactDOM.createPortal(
      <ModalSpecial
        text={tilteSpan}
        handleSave={() => {}}
        onClose={() => close()}
      >
        <OrderCreateModal
          parcel_terminals={parcelTerminals}
          payment_types={paymentTypes}
          carriers={props.carriers}
          avalaibleStatus={props.avalaibleStatus}
          onClose={() => close()}
          rline={props.rline}
        />
      </ModalSpecial>,
      document.getElementById("modal-roots")
    );
  }

  return null;
});

export default Modal;
