import React, { useMemo, useState } from "react";
import { Tooltip, Modal } from "antd";
const { confirm } = Modal;
import { ButtonDelete2, ButtonEdit } from "../common/Buttons";
import ClientInfoComponent from "../Repetitive_components/clientInfoComponent";
import { FiArrowUpRight } from "react-icons/fi";
import {
  antdMessageInfo,
  prettyDateFormat,
  returnEuroString,
} from "../../helpers/Utils";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { leasing_options } from "../../helpers/GlobalOptions";
import {
  deleteLeasing,
  sbLeasingOpenSession,
} from "../../redux/Actions/leasingAction";
import { useDispatch } from "react-redux";
import { MdErrorOutline } from "react-icons/md";
import { IconDot, IconExclamation } from "../Layout/Icons";

function TableColumnLeasing({
  item,
  index,
  onShow,
  store_manager,
  permissionDelete,
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const itemsArr = () => {
    return open ? item.items : item.items.slice(0, 2);
  };
  const status = useMemo(
    () => leasing_options.find((d) => d.value == item?.state)?.label,
    []
  );

  const renderStatusIcon = () => {
    if (status == "Pasirašė")
      return <IconDot className="text-sm mr-1" color="#1CC700" />;
    if (status == "Atšauktas")
      return <IconDot className="text-sm mr-1" color="#EF3824" />;
    return <IconDot className="text-sm mr-1" color="#FF8A00" />;
  };

  const apiErrorPreview = (error) => {
    if (!error) return "--";
    if (error.startsWith("{") && error.endsWith("}")) {
      const parsed_error = JSON.parse(error);
      return (
        <div className="text-sm">
          <div className="font-semi-bold">{parsed_error?.errorMessage}</div>
          {parsed_error?.errors?.map((error) =>
            error?.dataErrors?.map((dataError) => (
              <div key={dataError.code}>
                <span className="font-semi-bold mr-1">{dataError.tag}:</span>
                {dataError.message}
              </div>
            ))
          )}
        </div>
      );
    }
    return error;
  };

  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle">{prettyDateFormat(item.created_at)}</td>
      <td className="align-middle">
        <u className="cursor-pointer" onClick={() => onShow()}>
          {item.identifier || item.request_identifier}
        </u>
      </td>
      <td className="align-middle">{item.contract_number}</td>
      <td className="align-middle">
        <ClientInfoComponent
          client={{
            firstname: item.name,
            lastname: item.last_name,
            company: item.phone,
            other: item.email,
          }}
          customer={{}}
        />
      </td>
      <td className="align-middle">
        {itemsArr().map((d) => (
          <div
            className="text-sm text-black mb-2"
            key={`${d.id}_${d.identifier}`}
          >
            <div>{d.identifier}</div>
            {d.title} {d.serial_number && <b>{d.serial_number}</b>}
          </div>
        ))}
        {item.items.length > 2 && (
          <button
            style={{
              backgroundColor: "white",
              color: "#989c9a",
              border: "1px solid #989c9a",
              borderRadius: "5px",
            }}
            className="text-xs btn btn-md btn-block my-1 d-flex align-items-center justify-content-center"
            onClick={() => setOpen((prev) => !prev)}
          >
            {open
              ? "Rodyti mažiau prekių"
              : `Rodyti daugiau prekių (${item.items.length - 2}) `}
            {open ? (
              <FaAngleUp className="ml-2" />
            ) : (
              <FaAngleDown className="ml-2" />
            )}
          </button>
        )}
      </td>
      <td className="align-middle">
        <div className="d-flex align-items-center">
          {renderStatusIcon()}
          {status}
          {item.api_errors && (
            <Tooltip placement="top" title={apiErrorPreview(item.api_errors)}>
              <MdErrorOutline className="text-danger ml-1" size={20} />
            </Tooltip>
          )}
        </div>
      </td>
      {!store_manager && (
        <td className="align-middle">
          {item.user?.firstname || ""} {item.user?.lastname || ""}
        </td>
      )}
      <td className="align-middle">{returnEuroString(item.full_price)}</td>
      <td className="align-middle">{returnEuroString(item.advance)}</td>
      <td className="align-middle">
        {returnEuroString(item.full_price - (item.advance || 0))}
      </td>
      <td className="align-middle">
        <div className="d-flex justify-content-end">
          {item.leasing_url && !["2", "-1", "signed"].includes(item.state) && (
            <Tooltip
              placement="top"
              title="Inicijuoti duomenų suvedimą lizingui"
            >
              <button
                className="btn btn-sm mr-2"
                style={{ borderColor: "#f29302" }}
                onClick={async () => {
                  const { data, success } = await dispatch(
                    sbLeasingOpenSession(item.id, item.identification_number)
                  );
                  if (success) {
                    antdMessageInfo("Generuojama nuoroda");
                    setTimeout(() => {
                      window.open(data.leasing_url, "_blank");
                    }, 5000);
                  }
                }}
              >
                <FiArrowUpRight color="#f29302" />
              </button>
            </Tooltip>
          )}
          <ButtonEdit onEdit={() => onShow()} />
          {permissionDelete && (
            <>
              <div className="ml-2" />
              <ButtonDelete2
                onDelete={() =>
                  confirm({
                    title: "Ar tikrai norite ištrinti?",
                    icon: <IconExclamation className="text-orange" />,
                    content: "Šio veiksmo anuliuoti negalėsite",
                    onOk() {
                      dispatch(deleteLeasing(item.id));
                    },
                    onCancel() {
                      console.log("Cancel");
                    },
                  })
                }
              />
            </>
          )}
        </div>
      </td>
    </tr>
  );
}

export default TableColumnLeasing;
