import React from "react";
import {
  ButtonEdit,
  ButtonDelete,
  EditElementClick
} from "../../common/Buttons";

function TableColumnSupplier({ item, onDelete, index, onEdit }) {
  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle">{item.id}</td>
      <td className="align-middle">
        {" "}
        <EditElementClick onEdit={() => onEdit("edit",index)} name={item.name} />
      </td>
      <td className="align-middle">{item.code}</td>
      <td className="align-middle">{item.is_code ? "TAIP" : "NE"}</td>
      <td className="align-middle">
        {item.is_active ? "Aktyvus" : "Neaktyvus"}
      </td>
      <td className="align-middle">{item.company_code}</td>
      <td className="align-middle">{item.comment}</td>
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonEdit onEdit={() => onEdit("edit" ,index)} />
      </td>
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonDelete onDelete={() => onDelete(item.id)} />
      </td>
    </tr>
  );
}

export default TableColumnSupplier;
