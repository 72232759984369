import React, { useState } from "react";
import { GoPrimitiveDot } from "react-icons/go";
import { FaMapMarkerAlt } from "react-icons/fa";
import { CheckBoxInput, EditElementClick } from "../../../common/Buttons";
import { AiOutlineCheckCircle, AiOutlineNumber } from "react-icons/ai";
import ShipmentStoreSvg from "../../../../../assets/images/shipment/shipment_store.svg";
import moment from "moment";
import TableComment from "./TableComment";
import BalancesIndex from "./balancesIndex";
import SupplyingTableIndex from "./SupplyingTableIndex";
import SmsIcon from "../../../../../assets/images/sms.svg";
import attachmentIcon from "../../../../../assets/images/attachment.svg";
import { SerialNumberModal } from "../../../common/SerialNumberModal";
import { numberCheckERS, toFixedNumber } from "../../../../helpers/Utils";
import ClientInfoComponent from "../../../Repetitive_components/clientInfoComponent";
import CourierLinksComponent from "../../../Repetitive_components/courierLinksComponent";
import { Popover } from "antd";
import ShipmentComponentIndex from "./ShipmentComponentIndex";
import { have_product_reference } from "../../../../helpers/OrdersFunctionsGlobal";
import MiniTimeTracking from "../../../common/MiniTimeTracking";

function TableColumnAO({
  id,
  item,
  index,
  checkedArray,
  handleCheckboxChange,
  checkBoxIncludes,
  comments_state,
  onEdit,
  updateSerialNumber,
}) {
  const [visible, setVisible] = useState(false);
  const [SNumber, setSNumber] = useState(false);
  const commentItems = item.comments.sort(
    (a, b) => new Date(b.created_at) + new Date(a.created_at)
  );

  const sfColorButton = () => {
    return item.order_rows.some(
      (item) => item.required_serial_number && !item.serial_number
    )
      ? "bg-warning"
      : item.order_rows.some((item) => item.required_serial_number)
      ? "bg-success"
      : "bg-danger";
  };

  const renderRivilesNumbers = () => {
    let array = commentItems;
    array = array
      .filter((e) => e.com_state === "Siunčiama" && numberCheckERS(e.body))
      .map((e) => numberCheckERS(e.body));

    if (item.invoice_number) {
      array = [...new Set([...array, item.invoice_number])];
    } else {
      array = [...new Set(array)];
    }

    return (
      <div className="row m-0">
        {array.map((e) => (
          <div
            key={e}
            className="badge badge-pill badge-success mr-2 mb-1 text-black editInputForm"
          >
            {e}
          </div>
        ))}
      </div>
    );
  };
  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle">
        <div className="d-flex">
          <CheckBoxInput
            type="checkbox"
            color="#f7951e"
            checked={checkedArray.includes(id)}
            onChange={() => handleCheckboxChange(id)}
          />
        </div>
      </td>
      {checkBoxIncludes("Eksp") && (
        <td className="align-middle">
          <GoPrimitiveDot
            style={item.checked ? { color: "green" } : { color: "red" }}
          />
        </td>
      )}
      {checkBoxIncludes("Užsakymo data") && (
        <td className="align-middle">
          <span className="date-field">
            {moment(item.order_date).format("YYYY MM DD")}
          </span>
          <br />
          <span className="date-field">
            {moment(item.order_date).format("HH:mm")}
          </span>
        </td>
      )}
      {checkBoxIncludes("Order numeris") && (
        <td
          className={`align-middle ${
            [
              item.shipping_address.street2,
              item.billing_address.street2,
            ].includes("express")
              ? "text-danger"
              : ""
          }`}
        >
          <EditElementClick
            id={item.id_order}
            name={item.id_order}
            onEdit={() => onEdit(item.id_order, id)}
          />
          {![5, 6, 39, 29].includes(item.status.id_status) &&
            [
              item.shipping_address.street2,
              item.billing_address.street2,
            ].includes("express") &&
            typeof item.express_timer == "number" && (
              <MiniTimeTracking number={item.express_timer || 0} />
            )}
        </td>
      )}
      {checkBoxIncludes("Klientas") && (
        <td className="align-middle">
          <ClientInfoComponent
            order_additionals={
              item?.order_additionals?.filter(
                (d) => d.key !== "handle_rivile"
              ) || []
            }
            client={item.billing_address}
            customer={item.customer}
          />
        </td>
      )}
      {checkBoxIncludes("Gabenimo adresas") && (
        <td className="align-middle">
          <span className="font-weight-bold">
            {item.shipping_address.firstname !== null &&
              item.shipping_address.firstname}{" "}
            {item.shipping_address.lastname !== null &&
              item.shipping_address.lastname}
          </span>
          <br />
          <span style={{ fontSize: "0.6rem" }}>
            {item.shipping_address.city + " " + item.shipping_address.street}
            <br />
            {item.shipping_address.phone}
          </span>
          <br />
          <span style={{ fontSize: "0.6rem" }}>
            {item.shipping_address.other}
          </span>
        </td>
      )}
      {checkBoxIncludes("Prekes") && (
        <td className="align-middle" style={{ width: "15%" }}>
          {item.order_rows.map((or_item) => (
            <React.Fragment key={`or_item_${or_item.id}`}>
              <div
                className=""
                style={{
                  borderBottom: "1px solid #E9E9E9",
                }}
              >
                <div>
                  {parseInt(or_item.quantity)}*
                  {!have_product_reference(or_item.product_reference) ? (
                    <AiOutlineCheckCircle
                      className={`text-${
                        or_item.product_reference == "1234"
                          ? "warning"
                          : "success"
                      } mr-1`}
                    />
                  ) : (
                    or_item.product_reference
                  )}
                  &nbsp;
                  <span
                    className={
                      or_item.manual ? "border-bottom border-danger" : null
                    }
                  >
                    {or_item.product_name}
                  </span>
                </div>
                {or_item.required_serial_number && !or_item.serial_number && (
                  <div className="text-danger">Reikalingas S/N</div>
                )}
                {have_product_reference(or_item.product_reference) && (
                  <div>
                    <CourierLinksComponent
                      shipments={item.shipments}
                      order_row_id={or_item.id}
                    />
                  </div>
                )}
              </div>
              <table>
                <tbody style={{ fontWeight: "400" }}>
                  {or_item.supplyings.map((itemSup, i) => (
                    <SupplyingTableIndex
                      type={"Order"}
                      key={i}
                      item={itemSup}
                      product_reference={or_item.product_reference}
                      id_order_row={or_item.id}
                    />
                  ))}
                </tbody>
              </table>
              <br />
            </React.Fragment>
          ))}
          {renderRivilesNumbers()}
          {item.client_comment && (
            <div>
              PASTABA: <AiOutlineCheckCircle className="text-success mr-1" />
              <div
                className="text-danger"
                style={{
                  wordBreak: "break-word",
                }}
              >
                {item.client_comment}
              </div>
            </div>
          )}
        </td>
      )}
      {checkBoxIncludes("Likučiai") && (
        <td className="align-middle">
          <table className="table-hover table-sm">
            <tbody style={{ fontWeight: "400" }}>
              {item.order_rows.map(
                (item, index) =>
                  item.product_info !== null &&
                  item.product_info !== undefined && (
                    <BalancesIndex key={index} index={index} item={item} />
                  )
              )}
            </tbody>
          </table>
        </td>
      )}
      {checkBoxIncludes("Mokėjimo būdas") && (
        <td className="align-middle">{item.payment}</td>
      )}
      {checkBoxIncludes("Pristatymas") && (
        <td className="align-middle">
          <div className="d-flex align-items-center">
            <div className="mr-2">
              <Popover
                className="cursor-pointer mb-2 "
                placement="right"
                open={visible}
                onOpenChange={(value) => setVisible(value)}
                content={
                  visible ? (
                    <ShipmentComponentIndex
                      id_status={item?.status?.id_status}
                      id={item.id}
                      cod_default={item.cod}
                      remark={item.client_comment}
                      order_rows={item.order_rows}
                      shipping_address={item.shipping_address}
                      billing_address={item.billing_address}
                      total_shipping={item.total_shipping}
                      carrier={item.carrier}
                      total_price={toFixedNumber(item.total_price)}
                      payment_module={item.payment_module}
                      onClose={() => setVisible(false)}
                    />
                  ) : (
                    ""
                  )
                }
                trigger="click"
                onClick={() => {
                  console.log("Atidaro!");
                  setVisible(!visible);
                }}
              >
                <div
                  className="btn-primary d-flex justify-content-center align-items-center m-auto"
                  style={{ width: 30, height: 30, borderRadius: "50%" }}
                >
                  <img
                    src={ShipmentStoreSvg}
                    style={{ width: 18, height: 18, marginLeft: 2 }}
                  />
                </div>
              </Popover>
            </div>
            {item.carrier.module_name == "fspickupatstorecarrier" ? (
              <span>Atsiėmimas parduotuvėje:</span>
            ) : (
              item.carrier.name
            )}
          </div>
          {item.carrier.module_name == "fspickupatstorecarrier" && (
            <div className="d-flex py-1 px-2">
              <div className="d-flex justify-content-center align-items-center mr-1">
                <FaMapMarkerAlt className="text-orange" />
              </div>
              <div className="text-orange">
                <p className="mb-0">{item.shipping_address.street}</p>
              </div>
            </div>
          )}
        </td>
      )}
      {checkBoxIncludes("Statusas") && (
        <td className="align-middle">{item.status.title}</td>
      )}
      {checkBoxIncludes("KA komentarai") && (
        <TableComment
          comments={commentItems}
          type="comment_ka"
          comments_state={comments_state}
        />
      )}
      {checkBoxIncludes("Logistika") && (
        <TableComment
          comments={commentItems}
          type="comment_logistic"
          comments_state={comments_state}
        />
      )}
      {checkBoxIncludes("Vadyba") && (
        <TableComment
          comments={commentItems}
          type="comment_management"
          comments_state={comments_state}
        />
      )}
      {checkBoxIncludes("Sukurė") && (
        <td className="align-middle">
          <p>{item.user?.firstname || item.user || "Presta"}</p>
        </td>
      )}
      {checkBoxIncludes("Suma") && (
        <td className="align-middle">{toFixedNumber(item.total_price)}</td>
      )}
      <td className="align-middle" style={{ width: "50px" }}>
        {item.has_documents && (
          <button className="btn btn-sm " style={{ background: "#f29302" }}>
            <img
              style={{ color: "white" }}
              width="11.12"
              height="11.12"
              src={attachmentIcon}
            />
          </button>
        )}
        {item.has_sms && (
          <button className="btn btn-sm " style={{ background: "red" }}>
            <img
              style={{ color: "white" }}
              width="11.12"
              height="11.12"
              src={SmsIcon}
            />
          </button>
        )}
        {SNumber ? (
          <div>
            <SerialNumberModal
              id={item.id}
              SNumber={SNumber}
              options={item.order_rows}
              onOk={() => setSNumber((prevState) => !prevState)}
              onCancel={() => setSNumber((prevState) => !prevState)}
              updateSerialNumber={updateSerialNumber}
            />
          </div>
        ) : (
          <button
            className={`btn btn-sm ${sfColorButton()}`}
            onClick={() => setSNumber((prevState) => !prevState)}
          >
            <AiOutlineNumber color="white" />
          </button>
        )}
        {/* <ButtonEdit id={item.id_order} onEdit={() => onEdit(index, id)} /> */}
      </td>
    </tr>
  );
}

export default TableColumnAO;
