import { getUrlExtension, parcelTerminalBulder } from "../../helpers/Utils";
import {
  GET_ORDER,
  GET_ORDERS,
  GET_ORDERS_AMOUNT,
  GET_ACTIVE_NOT_READY_ORDERS,
  UPDATE_SUPPLYORDERS,
  POST_ORDER_ROW,
  DELETE_ORDER_ROW,
  LOADING_ORDERS,
  POST_COMMENTS_TO_ORDERS,
  ADD_CONTRACT,
  UPDATE_CONTRACT,
  DELETE_CONTRACT,
  ADD_CONTRACT_ITEM,
  UPDATE_CONTRACT_ITEM,
  DELETE_CONTRACT_ITEM,
  ADD_ORDER_DOCUMENT,
  DELETE_ORDER_DOCUMENT,
  UPDATE_ORDER_DOCUMENT,
  CREATE_SMS_ITEM,
  POST_ORDER_SHIPPING,
  CREATE_ORDER_STATUS,
  FIRST_ADD_CONTRACT,
  FIRST_DELETE_CONTRACT,
  CHANGE_CONTRACT_ITEMS,
  UPDATE_ORDER,
  UPDATE_ORDER_ALL,
  UPDATE_SERIAL_NUMBER,
  UPDATE_SERIAL_NUMBER_STATE,
  POST_TO_RIVILE,
  UPDATE_ORDER_SOCKETS,
  ORDER_CREATE_INVOICE,
  LOADING_ORDER,
  POST_ORDER_ROW_GOODS,
  UPDATE_ORDER_ROW_GOODS,
  DELETE_ORDER_ROW_GOODS,
  POST_PAYMENT_ORDER,
  UPDATE_PAYMENT_ORDER,
  EDIT_PAYMENT_ORDER,
  DELETE_PAYMENT_ORDER,
  UPDATE_CART_RULES,
  CREATE_WAITING_PAYMENT,
  GET_WAITING_PAYMENTS,
} from "../Actions/types";

const initialState = {
  orders: [],
  avalaibleStatus: [],
  order_histories: [],
  contracts: [],
  order_rows: [],
  order_documents: [],
  sms_sendings: [],
  shipments: [],
  payment: {},
  payments: [],
  waiting_payments: [],
  comments: [],
  order_cart_rules: [],
  order: {},
  totals: 0,
  total_active_orders: "loading",
  total_not_ready_orders: "loading",
  total_express_orders: "loading",
  total_amount: 0,
  showID: 0,
  loading_orders: false,
  loading_order: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_ORDERS:
      return {
        ...state,
        loading_orders: true,
      };
    case UPDATE_ORDER_ALL:
      return {
        ...state,
        order: action.payload,
        orders: updateOrder(action.payload, state),
      };

    case POST_COMMENTS_TO_ORDERS:
      let updateData = [...state.orders];
      const updateComments = (element) =>
        element.id === action.payload.order_id;
      const findIndexOrderForComment = updateData.findIndex(updateComments);
      if (updateData[findIndexOrderForComment] !== -1) {
        updateData[findIndexOrderForComment].comments.push(action.payload);
      }

      return {
        ...state,
        orders: updateData,
        comments: [...state.comments, action.payload],
      };

    case ORDER_CREATE_INVOICE:
      let arrOrderCreateInvoice = [...state.orders];
      const findIndexOrderCreateInvoice = state.orders.findIndex(
        (item) => item.id === action.payload.id
      );
      if (findIndexOrderCreateInvoice !== -1) {
        arrOrderCreateInvoice[findIndexOrderCreateInvoice].invoice_number =
          action.payload.invoice_number;
      }
      return {
        ...state,
        order: {
          ...state.order,
          invoice_number: action.payload.invoice_number,
        },
        orders: arrOrderCreateInvoice,
      };
    case POST_ORDER_ROW:
      return {
        ...state,
        order_rows: postOrderRow(action.payload, state),
        orders: postOrdersRow(action.payload, state, action.payload2),
      };
    case UPDATE_ORDER:
      let updateOrderOne = { ...state.order };
      if (updateOrderOne.id == action.payload.id) {
        updateOrderOne = action.payload;
      }
      return {
        ...state,
        orders: updateOrder(action.payload, state),
        order: updateOrderOne,
      };
    case UPDATE_SUPPLYORDERS:
      let orderId = action.payload.order_id;
      let itemId = action.payload.id;
      let updateOrdersArray = [...state.orders];
      if (orderId && itemId) {
        let findObjOrder = updateOrdersArray.findIndex(
          (findIndex) => findIndex.id === orderId
        );
        if (findObjOrder !== -1) {
          let findItem = updateOrdersArray[findObjOrder].order_rows.findIndex(
            (item) => item.id == itemId
          );
          updateOrdersArray[findObjOrder].order_rows[findItem] = action.payload;
        }
      }
      return {
        ...state,
        orders: updateOrdersArray,
      };

    case DELETE_ORDER_ROW: {
      return {
        ...state,
        order_rows: deleteOrderRow(action.payload, state),
        orders: deleteOrdersRow(action.payload, state, action.payload2),
      };
    }
    case GET_ORDERS:
      return {
        ...state,
        orders: action.payload.data,
        avalaibleStatus: avalaibleStatus(action.payload.statuses),
        totals: action.payload.totals,
        loading_orders: false,
      };
    case GET_ORDERS_AMOUNT:
      return {
        ...state,
        total_amount: action.payload,
      };
    case GET_ACTIVE_NOT_READY_ORDERS:
      return {
        ...state,
        total_active_orders: action.payload.active_orders,
        total_not_ready_orders: action.payload.not_ready_orders,
        total_express_orders: action.payload.express_orders,
      };
    case LOADING_ORDER:
      return {
        ...state,
        loading_order: true,
      };
    case GET_ORDER:
      return {
        ...state,
        contracts: contractStatus(action.payload.contracts),
        order_documents: orderDocumentsBuilder(action.payload.order_documents),
        order_histories: action.payload.order_histories,
        order_rows: action.payload.order_rows,
        payments: action.payload.payments,
        shipments: action.payload.shipments,
        order_cart_rules: action.payload.order_cart_rules,
        sms_sendings: action.payload.sms_sendings,
        comments: action.payload.comments,
        order: action.payload.order,
        showID: action.payload_id,
        loading_order: false,
      };
    case "NEW_ORDER":
      return {
        ...state,
        orders: [action.payload, ...state.orders],
        totals: state.totals + 1,
      };
    case ADD_CONTRACT:
      return {
        ...state,
        contracts: addContractItemNew(action.payload, state),
      };
    case FIRST_ADD_CONTRACT:
      return {
        ...state,
        contracts: [action.payload, ...state.contracts],
      };
    case FIRST_DELETE_CONTRACT:
      return {
        ...state,
        contracts: state.contracts.filter(
          (filter) => filter.id !== action.payload
        ),
      };
    case CHANGE_CONTRACT_ITEMS:
      return {
        ...state,
        contracts: changeContractItems(action.payload, state),
      };
    case UPDATE_CONTRACT:
      return {
        ...state,
        contracts: updateContract(action.payload, state),
      };
    case DELETE_CONTRACT:
      return {
        ...state,
        contracts: state.contracts.filter(
          (item) => item.id !== action.payload.id
        ),
      };
    case ADD_CONTRACT_ITEM:
      let addContractItem = [...state.contracts];
      let findId = state.contracts.findIndex(
        (find) => find.id == action.payload.contract_id
      );
      if (findId !== -1) {
        let add_contract_itemObj = {
          ...action.payload,
          update: "update",
        };
        addContractItem[findId].contract_items.push(add_contract_itemObj);
      }

      return {
        ...state,
        contracts: addContractItem,
      };
    case UPDATE_CONTRACT_ITEM:
      const updateContractItemObj = {
        ...action.payload,
        update: "update",
      };
      return {
        ...state,
        contracts: updateContractItem(updateContractItemObj, state),
      };
    case DELETE_CONTRACT_ITEM:
      return {
        ...state,
        contracts: deleteContract(action.payload, state),
      };

    case ADD_ORDER_DOCUMENT:
      const order_document_count2 = [...state.order_documents, action.payload];

      return {
        ...state,
        order_documents: [...state.order_documents, action.payload],
        orders: hasDocumentCheck(action.payload, state, order_document_count2),
      };
    case DELETE_ORDER_DOCUMENT:
      const order_document_count = [...state.order_documents].filter(
        (item) => item.id !== action.payload.id
      );
      return {
        ...state,
        order_documents: order_document_count,
        orders: hasDocumentCheck(action.payload, state, order_document_count),
      };
    case UPDATE_ORDER_DOCUMENT:
      return {
        ...state,
        order_documents: updateOrderDocument(action.payload, [
          ...state.order_documents,
        ]),
      };
    case CREATE_SMS_ITEM:
      return {
        ...state,
        sms_sendings: [action.payload, ...state.sms_sendings],
        orders: hasSmsCheck(action.payload, state),
      };
    case POST_ORDER_SHIPPING:
      return {
        ...state,
        shipments: [...state.shipments, action.payload],
        orders: state.orders.map((e) => {
          if (e.id == action.payload.service_order_id) {
            return {
              ...e,
              express_timer: false,
              shipments: [...e.shipments, action.payload],
            };
          }
          return e;
        }),
      };
    case "REMOVE_SHIPMENT_ORDER":
      const remove_shipment_order = state.shipments.filter(
        (e) => e.id !== action.payload.id
      );
      return {
        ...state,
        shipments: remove_shipment_order,
        orders: state.orders.map((e) =>
          e.id == action.payload.orderId
            ? {
                ...e,
                shipments: e.shipments.filter(
                  (e) => e.id !== action.payload.id
                ),
              }
            : e
        ),
      };
    case "CUSTOMER_PICKUP_CALLED":
      return {
        ...state,
        shipments: [...state.shipments].map((e) => {
          if (e.id == action.payload.shipment_id) {
            return { ...e, customer_pickup_called: true };
          }
          return e;
        }),
      };
    case "SHIPMENT_UPDATE":
      return {
        ...state,
        shipments: [...state.shipments].map((e) => {
          if (e.id == action.payload.id) {
            return action.payload;
          }
          return e;
        }),
      };
    case CREATE_ORDER_STATUS:
      return {
        ...state,
        order_histories: action.payload,
        orders:
          action.payload.length == 0
            ? state.orders
            : state.orders.map((e) => {
                if (e.id == action.payload[0].order_id) {
                  return {
                    ...e,
                    status: action.payload[0].status,
                  };
                }
                return e;
              }),
      };
    case UPDATE_SERIAL_NUMBER:
      return {
        ...state,
        orders: updateSerialNumber(action.payload, state, action.payload2),
        order_rows: updateSerialNumber(action.payload, state),
      };
    case POST_TO_RIVILE:
      let toRivile = [...state.orders];

      const findIndex = toRivile.findIndex(
        (find) => find.id == action.payload.id
      );
      if (findIndex !== -1) {
        toRivile[findIndex].billing_address = action.payload.data;
      }

      return {
        ...state,
        orders: toRivile,
        order: { ...state.order, billing_address: action.payload.data },
      };
    case UPDATE_SERIAL_NUMBER_STATE:
      return {
        ...state,
        order_rows: action.payload,
      };
    case UPDATE_ORDER_SOCKETS:
      return {
        ...state,
        orders: updateOrder(action.payload, state),
        comments: updateSocketComments(action.payload, state),
      };

    case "UPDATE_COMMENTS_MASS":
      return {
        ...state,
        ...updateCommentsMass(action.payload, state),
      };
    case POST_ORDER_ROW_GOODS:
      return {
        ...state,
        order_rows: [
          ...state.order_rows,
          {
            ...action.payload.items,
            supplyings: [],
            quantity: Number(action.payload.items.quantity),
          },
        ],
        order: { ...state.order, total_price: action.payload.total_price },
        orders: postOrderRowGoods(action.payload, state),
      };
    case UPDATE_ORDER_ROW_GOODS:
      return {
        ...state,
        order: { ...state.order, total_price: action.payload.total_price },
        order_rows: updateOrderRowGoodsShow(action.payload, state),
        orders: updateOrderRowGoods(action.payload, state),
      };
    case DELETE_ORDER_ROW_GOODS:
      return {
        ...state,
        order_rows: [...state.order_rows].filter(
          (filt) => filt.id !== action.payload.rowId
        ),
        orders: deleteOrderRowGoods(action.payload, state),
        order: { ...state.order, total_price: action.payload.total_price },
      };
    // waiting payments
    case GET_WAITING_PAYMENTS:
      return {
        ...state,
        waiting_payments: action.payload,
      };
    case CREATE_WAITING_PAYMENT:
      let new_waiting_payments = [...state.waiting_payments];
      new_waiting_payments.push(action.payload);
      return {
        ...state,
        waiting_payments: new_waiting_payments,
      };

    // payment
    case POST_PAYMENT_ORDER:
      return {
        ...state,
        payments: [...state.payments, action.payload],
      };
    case EDIT_PAYMENT_ORDER:
      return {
        ...state,
        payment: { ...action.payload },
      };
    case UPDATE_PAYMENT_ORDER:
      return {
        ...state,
        payments: updatePaymentOrder(action.payload, state),
      };
    case DELETE_PAYMENT_ORDER:
      return {
        ...state,
        payments: [...state.payments].filter(
          (filt) => filt.id !== action.payload
        ),
      };
    case UPDATE_CART_RULES:
      return {
        ...state,
        order_cart_rules: updateCartRules(action.payload, state),
        order: { ...state.order, total_price: action.total_price },
        orders: updateTotalPrice(action, state),
      };
    case "SET_DOCUMENT_EMAIL_IN_ORDERS":
      return {
        ...state,
        contracts: state.contracts.map((d) =>
          d.id == action.payload.contract_id
            ? { ...d, contract_emails: [...d.contract_emails, action.payload] }
            : d
        ),
      };
    default:
      return state;
  }
}

const orderDocumentsBuilder = (arr) => {
  const array = [...arr].map((e) => ({
    ...e,
    format: getUrlExtension(e.asset.url),
  }));
  return array;
};

const updateTotalPrice = (action, state) => {
  let orders = [...state.orders];
  const findIndex = orders.findIndex((e) => e.id == action.payload.order_id);
  if (findIndex !== -1) {
    orders[findIndex].total_price = action.total_price;
  }
  return orders;
};

const updateCartRules = (payload, state) => {
  let cartRules = [...state.order_cart_rules];
  const findIndexArr = cartRules.findIndex((findI) => findI.id == payload.id);
  if (findIndexArr !== -1) {
    cartRules[findIndexArr] = payload;
  }
  return cartRules;
};

const updatePaymentOrder = (payload, state) => {
  let paymentsArray = [...state.payments];
  const findIndexArr = paymentsArray.findIndex(
    (findI) => findI.id == payload.id
  );
  if (findIndexArr !== -1) {
    paymentsArray[findIndexArr] = payload;
  }
  return paymentsArray;
};

const updateSerialNumber = (payload, state, payload2) => {
  let orderArray = [...state.orders];
  let order_rowsArray = [...state.order_rows];
  if (payload2) {
    const findOrder = orderArray.findIndex((find) => find.id == payload2);
    if (findOrder !== -1) {
      const row = orderArray[findOrder].order_rows;
      const findRow = row.findIndex((find) => find.id == payload.id);
      if (findRow !== -1) {
        row[findRow] = {
          ...row[findRow],
          ...payload,
        };
      }
    }
    return orderArray;
  } else {
    const rowShow = order_rowsArray;
    const findRowShow = rowShow.findIndex((find) => find.id == payload.id);
    if (findRowShow !== -1) {
      rowShow[findRowShow] = {
        ...rowShow[findRowShow],
        ...payload,
      };
    }
    return order_rowsArray;
  }
};

const hasSmsCheck = (action, data) => {
  let oldData = [...data.orders];
  const findIndex = oldData.findIndex((find) => find.id == action.order_id);
  if (findIndex !== -1) {
    oldData[findIndex].has_sms = true;
  }
  return oldData;
};

const hasDocumentCheck = (action, data, docData) => {
  let oldData = [...data.orders];
  const findIndex = oldData.findIndex((find) => find.id == action.order_id);
  if (findIndex !== -1) {
    oldData[findIndex].has_documents = docData.length == 0 ? false : true;
  }
  return oldData;
};

const changeContractItems = (payload, state) => {
  let oldStateContracts = [...state.contracts];
  oldStateContracts[payload.id].contract_items = payload.data;
  return oldStateContracts;
};

const addContractItemNew = (items, state) => {
  let oldStateContracts = [...state.contracts];
  const findIndex = oldStateContracts.findIndex((find) => find.id === items.id);
  if (findIndex !== -1) {
    let newObj = {
      ...items.data,
      action_status: "update",
      createdNew: true,
    };
    oldStateContracts[findIndex] = newObj;
  }
  return oldStateContracts;
};

const contractStatus = (items) => {
  let newArray = [];
  items.forEach((item) => {
    newArray.push({
      ...item,
      action_status: "update",
    });
  });
  return newArray;
};

const avalaibleStatus = (data) => {
  let availableStatusArray = [];

  data.forEach((item) => {
    const findIndex = availableStatusArray.findIndex(
      (value) => value.label == item.title
    );
    if (findIndex == -1) {
      availableStatusArray.push({
        value: item.id,
        label: item.title,
      });
    }
  });
  return availableStatusArray;
};

const updateOrderDocument = (data, state) => {
  let nextIndex = state.findIndex((item) => item.id === data.id);
  if (nextIndex !== -1) {
    state[nextIndex] = data;
  }
  return state;
};

const updateContract = (data, state) => {
  let contracts = [...state.contracts];
  const findIndex = contracts.findIndex((item) => item.id == data.id);
  if (findIndex !== -1) {
    let obj = {
      ...data,
      action_status: "update",
    };
    contracts[findIndex] = obj;
  }
  return contracts;
};

const deleteContract = (data, state) => {
  let contracts = [...state.contracts];
  const findIndex = contracts.findIndex((item) => item.id == data.contract_id);
  if (findIndex !== -1) {
    contracts[findIndex].contract_items = contracts[
      findIndex
    ].contract_items.filter((item) => item.id !== data.id);
  }
  return contracts;
};

const updateContractItem = (data, state) => {
  let contracts = [...state.contracts];
  const findIndex = contracts.findIndex((item) => item.id == data.contract_id);
  if (findIndex !== -1) {
    let secondFindArray = contracts[findIndex].contract_items;
    let secondFind = secondFindArray.findIndex((find) => find.id == data.id);
    if (secondFind !== -1) {
      let obj = {
        ...data,
        action_status: "update",
      };
      secondFindArray[secondFind] = obj;
    }
  }
  return contracts;
};

const postOrderRow = (itemAction, state) => {
  let order_ROW = [...state.order_rows];
  const findId = order_ROW.findIndex(
    (item) => item.id == itemAction.service_order_row_id
  );
  if (findId !== -1) {
    let haveObj = order_ROW[findId];
    haveObj.supplyings.push(itemAction);
  }
  return order_ROW;
};
const postOrdersRow = (itemAction, state, allId) => {
  let orders_row = [...state.orders];
  const findOrder = orders_row.findIndex((item) => item.id == allId.id);
  if (findOrder !== -1) {
    let findItem = orders_row[findOrder].order_rows.findIndex(
      (item) => item.id == allId.order
    );
    if (findItem !== -1) {
      orders_row[findOrder].order_rows[findItem].supplyings.push(itemAction);
    }
  }
  return orders_row;
};

const deleteOrderRow = (itemAction, state) => {
  let order_ROW = [...state.order_rows];
  const findId = order_ROW.findIndex(
    (item) => item.id == itemAction.service_order_row_id
  );
  if (findId !== -1) {
    let haveObj = order_ROW[findId];
    haveObj.supplyings = haveObj.supplyings.filter(
      (item) => item.id !== itemAction.id
    );
  }
  return order_ROW;
};

const deleteOrdersRow = (itemAction, state, allId) => {
  let orders_row = [...state.orders];
  const findOrder = orders_row.findIndex((item) => item.id == allId.id);
  if (findOrder !== -1) {
    let findItem = orders_row[findOrder].order_rows.findIndex(
      (item) => item.id == allId.order
    );
    if (findItem !== -1) {
      orders_row[findOrder].order_rows[findItem].supplyings = orders_row[
        findOrder
      ].order_rows[findItem].supplyings.filter(
        (item) => item.id !== itemAction.id
      );
    }
  }
  return orders_row;
};

const updateOrder = (items, state) => {
  let orders = [...state.orders];
  const findOrder = orders.findIndex((item) => item.id == items.id);
  if (findOrder !== -1) {
    orders[findOrder] = items;
  }
  return orders;
};

const updateCommentsMass = (items, state) => {
  let orders = [...state.orders];
  const findOrder = orders.findIndex(
    (item) => item.id == items[0].sms_sendable_id
  );
  if (findOrder !== -1) {
    orders[findOrder].comments = items;
  }
  return {
    orders,
    comments: items,
  };
};

const updateSocketComments = (item, state) => {
  let comments = [...state.comments];
  const check = item.id == state.showID;
  if (check) {
    comments = item.comments;
  }
  return comments;
};

const postOrderRowGoods = (payload, state) => {
  const orders = [...state.orders];
  const findOrderIndex = orders.findIndex(
    (find) => find.id == payload.items.order_id
  );
  if (findOrderIndex !== -1) {
    orders[findOrderIndex].total_price = payload.total_price;
    orders[findOrderIndex].order_rows = [
      ...orders[findOrderIndex].order_rows,
      {
        ...payload.items,
        supplyings: [],
        quantity: Number(payload.items.quantity),
      },
    ];
  }
  return orders;
};

const updateOrderRowGoods = (payload, state) => {
  const orders = [...state.orders];
  const findOrderIndex = orders.findIndex(
    (find) => find.id == payload.items.order_id
  );
  if (findOrderIndex !== -1) {
    orders[findOrderIndex].total_price = payload.total_price;
    const findOrderRowIndex = orders[findOrderIndex].order_rows.findIndex(
      (find) => find.id == payload.items.id
    );
    if (findOrderRowIndex !== -1) {
      orders[findOrderIndex].order_rows[findOrderRowIndex] = {
        ...orders[findOrderIndex].order_rows[findOrderRowIndex],
        ...payload.items,
      };
    }
  }
  return orders;
};

const updateOrderRowGoodsShow = (payload, state) => {
  const ordersShow = [...state.order_rows];
  const findOrderRowIndex = ordersShow.findIndex(
    (find) => find.id == payload.items.id
  );
  if (findOrderRowIndex !== -1) {
    ordersShow[findOrderRowIndex] = {
      ...ordersShow[findOrderRowIndex],
      ...payload.items,
    };
  }
  return ordersShow;
};

const deleteOrderRowGoods = (payload, state) => {
  const orders = [...state.orders];
  const findOrderIndex = orders.findIndex((find) => find.id == payload.orderId);
  if (findOrderIndex !== -1) {
    orders[findOrderIndex].total_price = payload.total_price;
    orders[findOrderIndex].order_rows = orders[
      findOrderIndex
    ].order_rows.filter((filt) => filt.id !== payload.rowId);
  }
  return orders;
};
