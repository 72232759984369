import {
  GET_PAYMENT_TYPES,
  PUT_PAYMENT_TYPES,
  LOADING_PAYMENT_TYPES
} from "../Actions/types";


const initialState = {
  payment_types: [],
  payment_types_loading: false,
  totals: 0,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_PAYMENT_TYPES:
      return {
        ...state,
        payment_types_loading: true
      };
    case GET_PAYMENT_TYPES:
      return {
        ...state,
        payment_types: action.payload.data,
        totals: action.payload.totals,
        payment_types_loading: false
      };
    case PUT_PAYMENT_TYPES:
      let getData = [...state.payment_types];
      const updateElementId = element => element.id === action.payload.id;
      const findIndex = getData.findIndex(updateElementId);
      getData[findIndex] = action.payload;
      return {
        ...state,
        payment_types: getData
      };
    default:
      return state;
  }
}
