import React from "react";
import InputForm from "../../common/InputForm";
import ModalToChange from "../../Layout/ModalToChange";
import { SelectForm } from "../../common/Buttons";
import UploadImagesSmall from "../../common/UploadImagesSmall";
import { AiOutlineDelete } from "react-icons/ai";

class BrandEditModal extends React.Component {
  constructor() {
    super();
    this.state = {
      id: 0,
      title: "",
      asset: {url: null},
      image: []
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.action === "new") {
      this.setState({
        title: "",
        action: "new",
        asset: {url: null},
        image: []
      });
      this.removeErrors(nextProps.errors);
    } else if (nextProps.action === "edit") {
      let item = nextProps.objectData;
      this.setState({
        id: item.id || 0,
        title: item.title || "",
        action: "edit",
        asset: item.asset || {url: null},
        image: []
      });
      this.removeErrors(nextProps.errors);
    }
  }

  removeErrors = (errors) => {
    if (Object.keys(errors).length > 0) {
      this.props.clearErrors();
    }
  };

  handleChangeText = (e, select) => {
    if (select) {
      this.setState({
        [select]: e,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  uploadImage = (name, nameAction, value) => {
    this.setState({
      image: value,
    });
  };

  removeImage = () => {
    this.setState({
      image: [],
      asset: {url: null},
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    let url = null;
    if (this.state.image.length > 0) {
      url = this.state.image[0].url;
    }
    if (url != null || this.state.image.length === 0 && this.state.asset.url == null) {
      const variables = {
        brand: {
          id: this.state.id,
          asset: url
        },
      };
      if (this.props.action == "new") {
        this.props.createBrand(variables);
      } else if (this.props.action === "edit") {
        this.props.updateBrand(this.state.id, variables);
      }
    }
  };


  render() {
    return (
      <ModalToChange
        handleSave={(e) => this.onSubmit(e)}
        status="tableEdit"
        text={this.props.action === "new" ? "Kurti naują prekės ženklą" : "Redaguoti prekės ženklą"}
        buttonText={this.props.action === "new" ? "Sukurti" : "Atnaujinti"}
        className = {"wide " + (this.props.loading_family ? "loading ": "")}
      >
        <div className="row mt-2">
          <div className="col-4">
          <InputForm
            type="text"
            placeholder="Ženklas"
            name="title"
            disabled = {true}
            value={this.state.title}
            error={
              this.props.errors.title &&
              this.props.errors.title.map((item) => `${item} `)
            }
            onChange={(e) => this.handleChangeText(e)}
          />
          </div>
            
          <div className="col-8">
            <div className="text-bold my-2 ">Logotipas</div>
            
            <div className = "row">
              <div className="col-6">
                  { this.state.asset.url !== null &&
                    <>
                    <img src = {this.state.asset.url} title = {this.state.title} alt = {this.state.title} className = "brand-img" />
                    <button className="brand-image-delete" onClick={this.removeImage}><AiOutlineDelete></AiOutlineDelete></button>
                    </>
                  }
                  { this.state.asset.url === null &&
                        <UploadImagesSmall
                          name="image"
                          items={this.state.image}
                          onChangeAttachments={this.uploadImage}
                        />
                  }       
              </div>
              <div className="col-6">
                    { this.state.asset.url !== null &&
                          <UploadImagesSmall
                            name="image"
                            items={this.state.image}
                            onChangeAttachments={this.uploadImage}
                          />
                    }
                    { this.state.asset.url === null &&
                    <p style = {{fontSize: "12px" ,lineHeight: "12px"}}>
                      <small>Reikalavimai</small><br/>
                      Dydis: 208x64<br/>
                      Rezol.:150 PPI
                    </p>
                    }
              </div>
            </div>
          </div>
        </div>
      </ModalToChange>
    );
  }
}

export default BrandEditModal;
