import { Spin } from "antd";
import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { toFixedNumber } from "../../../../../helpers/Utils";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
function ResiduesItem({ item }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (open) {
      const fetchRivileDepartment = async () => {
        setLoading(true);
        try {
          const { data } = await Axios.get(
            `/api/v1/product_suppliers/${item.id}`
          );
          if (!data) throw new Error();
          if (data?.length == 0) throw new Error();
          setLoading(false);
          setValues(data);
        } catch (err) {
          console.log("Error: ", err);
          setLoading(false);
          setValues("Empty");
        }
      };
      fetchRivileDepartment();
    }
  }, [open]);

  if (
    !["bigbox_rivile", "rivile_alsobigbox"].includes(item.supplier?.name) &&
    item.supplier?.name?.toLowerCase().includes("rivi")
  ) {
    const border_left = { borderLeft: "4px solid #FFD467" };
    return (
      <>
        <tr>
          <td className="residuesItem">{item.quantity}</td>
          <td className="residuesItem">{item.originalCode}</td>
          <td className="residuesItem">{item.originalTitle}</td>
          <td className="residuesItem">{item.code}</td>
          <td className="residuesItem text-primary">{item.supplier.name}</td>
          <td className="residuesItem">{item.wholesale_price}</td>
        </tr>
        {open && (
          <>
            {loading ? (
              <tr>
                <td colSpan={6}>
                  <div className="d-flex justify-content-center align-items-center">
                    <Spin />
                  </div>
                </td>
              </tr>
            ) : values == "Empty" ? (
              <tr>
                <td colSpan={6}>
                  <b>Nerasta kiekių.</b>
                </td>
              </tr>
            ) : (
              <Fragment>
                <tr>
                  <td
                    className="residuesItem align-middle pb-0"
                    colSpan={3}
                  ></td>
                  <td className="residuesItem pb-0">
                    <small>Kiekis:</small>
                  </td>
                  <td className="residuesItem pb-0">
                    <small>Kaina:</small>
                  </td>
                  <td className="residuesItem pb-0">
                    <small>E-Kaina:</small>
                  </td>
                </tr>
                {values.map((val) => (
                  <tr style={border_left}>
                    <td className="residuesItem align-middle pb-0" colSpan={3}>
                      <b className="pl-2">{val.identifier}: </b>
                      {val.store}
                    </td>
                    <td className="residuesItem pb-0">
                      {val.amount} ({val.reservation})
                    </td>
                    <td className="residuesItem pb-0">
                      <div className="d-flex">
                        <span>{toFixedNumber(val.price)}</span>
                        <span>{" €"}</span>
                      </div>
                    </td>
                    <td className="residuesItem pb-0">
                      <div className="d-flex">
                        <span>{toFixedNumber(val.e_price)}</span>
                        <span>{" €"}</span>
                      </div>
                    </td>
                  </tr>
                ))}
              </Fragment>
            )}
          </>
        )}
        <tr style={{ borderBottom: "1px solid #DBDBDB" }}>
          <td colSpan={6}>
            <div className="d-flex justify-content-center align-items-center mb-1">
              <StyledButton
                open={open}
                onClick={() => setOpen((prev) => !prev)}
              >
                {open ? (
                  <span>
                    Rodyti mažiau{" "}
                    <FaChevronUp className="pl-1" color="#FFD467" />
                  </span>
                ) : (
                  <span>
                    Rodyti daugiau{" "}
                    <FaChevronDown className="pl-1" color="#FFD467" />
                  </span>
                )}
              </StyledButton>
            </div>
          </td>
        </tr>
      </>
    );
  }
  return (
    <tr style={{ borderBottom: "1px solid #DBDBDB" }}>
      <td className="residuesItem">{item.quantity}</td>
      <td className="residuesItem">{item.originalCode}</td>
      <td className="residuesItem">{item.originalTitle}</td>
      <td className="residuesItem">{item.code}</td>
      <td className="residuesItem">{item.supplier.name}</td>
      <td className="residuesItem">{item.wholesale_price}</td>
    </tr>
  );
}

const StyledButton = styled.div`
  padding: 3px 25px;
  border: 1px solid ${(props) => (props.open ? "#BFBFBF" : "#FFD467")};
  border-radius: 30px;
  text-align: center;
  color: #000;
  cursor: pointer;
  font-size: 12px;
`;

export default ResiduesItem;
