import React, { useState } from "react";
import Axios from "axios";
import { FaCheck, FaMinus, FaPlus, FaTimes } from "react-icons/fa";
import { Input, AutoComplete } from "antd";
import { SelectForm } from "../../common/Buttons";
import TextInputModal from "../../common/TextInputModal";
import { useEffect } from "react";
import AutoFillComponent from "../../common/AutoFillComponent";
import useFetch from "../../../helpers/useFetch";
import { DebounceSelect } from "../../common";
import Spinner from "../../common/Spinner";
import { errorHandler } from "../../../helpers/Utils";

function EditAdditionalItem(props) {
  const { value: document_plan, loading } = useFetch(
    `/api/v1/document_types/${props.document_type_id}/document_plans/${props.document_plan_id}`,
    {},
    [props.document_type_id, props.document_plan_id]
  );

  console.log({ document_plan, loading });
  const [Models, setModels] = useState([]);

  useEffect(() => {
    Axios.get(
      `/api/v1/categories/list?q[name_cont]=${props.data.category}`
    ).then((response) => {
      if (response.data.length > 0) {
        let modelsData = [];
        response.data.forEach((item) => {
          item.products.forEach((product) => {
            modelsData.push({
              value: product.title,
              product_reference: product.identifier,
            });
          });
        });
        setModels(modelsData);
      }
    });
  }, [props.data.category]);

  const onChangeCategory = (e) => {
    props.onChangeContractItem(
      {
        target: {
          name: "category",
          value: e,
        },
      },
      props.index
    );
    props.onChangeContractItem(
      {
        target: {
          name: "manufacturer",
          value: undefined,
        },
      },
      props.index
    );

    props.onChangeContractItem(
      {
        target: {
          name: "model",
          value: undefined,
        },
      },
      props.index
    );
  };
  console.log({ document_plan, loading });

  const renderSearchEndPoint = (value) => {
    let api_url = `/api/v1/products?page=1&per_page=9999999&q[title_or_identifier_i_cont_any]=${value}`;
    if (document_plan?.rivile_service?.limitation_products) {
      api_url += document_plan.rivile_service.limitation_products
        .map((item) => `&q[id_not_in][]=${item}`)
        .join("");
    }
    return api_url;
  };

  const ArrayBuilder = (arr) => {
    const responseBuild = arr.map((e) => ({
      label: e.title,
      value: e.identifier,
      manufacturer: e.manufacturer || "",
      family: e.family?.name || "",
      product_price: e.e_price || 0,
    }));
    return responseBuild;
  };
  const setterValue = (name, value) => {
    props.onChangeContractItem(
      {
        target: {
          name,
          value,
        },
      },
      props.index
    );
  };
  return (
    <div className="mt-1 p-2" style={{ backgroundColor: "#f8f8f8" }}>
      <div>
        <div className="d-flex justify-content-between mb-2">
          <span className="text-bold">
            {props.data.created_at ? (
              <>
                <FaCheck className="mr-2" title={"Sukurtas"} color="green" />
                Redagavimas
              </>
            ) : (
              <>
                <FaTimes className="mr-2" title={"Kuriamas"} color="red" />
                Dokumento prekė
              </>
            )}
          </span>
        </div>
        <AutoFillComponent
          label={"Prekės paieška"}
          endPoint={renderSearchEndPoint}
          onChange={(_, full) => {
            if (full.manufacturer) {
              setterValue("manufacturer", full.manufacturer);
            } else {
              setterValue("manufacturer", undefined);
            }
            setterValue("category", full.family ? full.family : undefined);
            setterValue("model", full.label);
            setterValue("product_price", full.product_price);
            setterValue("product_reference", full.value);
          }}
          arrayBuilder={ArrayBuilder}
        />

        <div className="text-bold my-1">
          Prekės kaina <span className="orange_color">*</span>
        </div>

        <div className="position-relative">
          <label className="position-absolute input_text">&#8364;</label>
          <Input
            type="number"
            name="product_price"
            placeholder="Įvesti"
            value={props.data.product_price}
            onChange={(e) => props.onChangeContractItem(e, props.index)}
            disabled={props.editable}
          />
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <DebounceSelect
              required={true}
              label="Kategorija"
              fetchOptions={async (value) => {
                try {
                  let api_url = `/api/v1/orders/shop_categories_list?&q[title_cont_any]=${value}`;
                  if (
                    document_plan?.rivile_service?.limitation_shop_categories
                  ) {
                    api_url +=
                      document_plan?.rivile_service?.limitation_shop_categories
                        .map((item) => `&q[id_not_in][]=${item}`)
                        .join("");
                  }
                  const { data } = await Axios.get(api_url);

                  if (data) {
                    return data.map((item_each) => {
                      return {
                        value: item_each.title,
                        label: item_each.title,
                      };
                    });
                  }
                  throw new Error("Err");
                } catch (err) {
                  errorHandler(err);
                  return [];
                }
              }}
              showSearch
              value={props.data.category || undefined}
              disabled={props.editable}
              onChange={(e) => onChangeCategory(e.value)}
              error={props.errors?.limitation_shop_categories}
            />
            <DebounceSelect
              required={true}
              label="Gamintojas"
              fetchOptions={async (value) => {
                try {
                  let api_url = `/api/v1/brands?&q[title_cont_any]=${value}`;
                  if (document_plan?.rivile_service?.limitation_brands) {
                    api_url += document_plan?.rivile_service?.limitation_brands
                      .map((item) => `&q[id_not_in][]=${item}`)
                      .join("");
                  }
                  const { data } = await Axios.get(api_url);

                  if (data?.data) {
                    return data?.data?.map((item_each) => {
                      return {
                        value: item_each.title,
                        label: item_each.title,
                      };
                    });
                  }
                  throw new Error("Err");
                } catch (err) {
                  errorHandler(err);
                  return [];
                }
              }}
              showSearch
              value={props.data.manufacturer || undefined}
              disabled={props.editable}
              onChange={(e) => {
                props.onChangeContractItem(
                  {
                    target: {
                      name: "manufacturer",
                      value: e.value,
                    },
                  },
                  props.index
                );
              }}
              error={props.errors?.limitation_brands}
            />
          </>
        )}
        <div className="text-bold my-1">
          Modelis <span className="orange_color">*</span>
        </div>
        <AutoComplete
          style={{ width: "100%" }}
          options={Models}
          value={props.data.model || undefined}
          onChange={(value, arg) => {
            props.onChangeContractItem(
              {
                target: {
                  name: "model",
                  value,
                },
              },
              props.index
            );
            props.onChangeContractItem(
              {
                target: {
                  name: "product_reference",
                  value: arg.product_reference,
                },
              },
              props.index
            );
          }}
          placeholder="Įvesti"
          disabled={props.editable}
          filterOption={(inputValue, option) => {
            if (!!option && !!inputValue && option.value !== null) {
              return (
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              );
            }
          }}
        />

        <div className="text-bold my-1">
          Gamintojo garantija:
          <span className="orange_color">*</span>
        </div>
        <SelectForm
          onlyPlaceholder="Pasirinkti"
          options={props.warrantlyOption}
          value={props.warrantlyValue || undefined}
          // onChange={(e) => props.onChangeContract(e, "warranty")}
          onChange={(e, arg) => {
            props.onChangeContract(e, "warranty");
          }}
          disabled={props.editable}
          // onChange={(e) => setWarranty(e)}
        />
        <TextInputModal
          required="true"
          name="id_number"
          // type="number"
          text="S/N ar IMEI"
          placeholder="Įvesti"
          value={props.data.id_number}
          onChange={(e) => props.onChangeContractItem(e, props.index)}
          disabled={props.editable}
          // onChange={(e) => updateFieldChanged(index, e)}
        />
        <>
          <div className="text-bold my-1 d-flex justify-content-between">
            <div>
              Papildoma informacija <span className="orange_color">*</span>
            </div>
            <div>
              <FaPlus
                style={{ color: "green", cursor: "pointer" }}
                onClick={() => props.addAdditionalItem(props.index)}
              />
            </div>
          </div>
          {props.data.additional_data &&
            props.data.additional_data.map((item, itemIndex) => {
              return (
                <div className="d-flex" key={itemIndex}>
                  <div style={{ width: "45%" }}>
                    <SelectForm
                      onlyPlaceholder="Pavadinimas"
                      name="key"
                      options={props.meaningsOptions}
                      value={item.key}
                      disabled={props.editable}
                      onChange={(e) =>
                        props.changeAdditionalItem(
                          props.index,
                          itemIndex,
                          e,
                          "key"
                        )
                      }
                    />
                  </div>
                  <div style={{ width: "45%" }}>
                    <Input
                      className="orange"
                      name="text"
                      placeholder="Reikšmė"
                      value={item.text}
                      disabled={props.editable || item.key === null}
                      onChange={(e) =>
                        props.changeAdditionalItem(
                          props.index,
                          itemIndex,
                          e.target.value,
                          "text"
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      width: "10%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FaMinus
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() =>
                        props.removeAdditionalItem(props.index, itemIndex)
                      }
                    />
                  </div>
                </div>
              );
            })}
        </>
        <label className="text-bold my-1">
          Plano kaina:
          <span className="orange_color">*</span>
        </label>
        <div className="d-flex">
          <div
            className="d-flex align-items-center pr-2"
            style={{ width: "40%" }}
          >
            <div className="position-relative">
              <label className="position-absolute input_text">&#8364;</label>
              <Input
                type="number"
                name="item_price"
                placeholder="Įvesti"
                value={props.data.item_price}
                disabled={props.editable}
                onChange={(e) => props.onChangeContractItem(e, props.index)}
              />
            </div>
          </div>
          <div
            className="text-bold"
            style={{
              display: "flex",
              alignItems: "center",
              width: "35%",
            }}
          >
            <div className="position-relative">
              <label className="position-absolute input_text">%</label>
              <Input
                type="number"
                name="item_pricePerc"
                placeholder="Įvesti"
                value={props.data.item_pricePerc}
                disabled={props.editable}
                onChange={(e) => props.onChangeContractItem(e, props.index)}
                // onChange={(e) => handeChangePrice(e, index)}
              />
            </div>
          </div>
          <div
            className="d-flex justify-content-center align-items-center text-secondary"
            style={{ width: "25%" }}
          >
            <span>nuo prekės kainos</span>
          </div>
        </div>
        <label className="text-bold my-1">Nuolaida</label>
        <div className="d-flex">
          <div
            className="d-flex align-items-center pr-2"
            style={{ width: "40%" }}
          >
            <div className="position-relative">
              <label className="position-absolute input_text">&#8364;</label>
              <Input
                type="number"
                name="item_discount"
                placeholder="Įvesti"
                value={props.data.item_discount}
                onChange={(e) => props.onChangeContractItem(e, props.index)}
                disabled={props.editable}
              />
            </div>
          </div>
          <div
            className="text-secondary"
            style={{
              display: "flex",
              alignItems: "center",
              width: "60%",
            }}
          >
            Kaina po nuolaidos, {props.data.item_total} &#8364;
          </div>
        </div>
      </div>
    </div>
  );
}
export default EditAdditionalItem;
