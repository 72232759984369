import { Tooltip } from "antd";
import React from "react";
import { IconDiscount } from "../../../Layout/Icons";

const OrderAdditionals = ({ action, value }) => {
  if (!action.includes("game_participation")) {
    return <span />;
  }
  const textTitle = () => {
    return "Dalyvauja loterijoje";
    if (action == "refrigerator_game_participation")
      return "Dalyvauja šaldytuvų loterijoje";
    if (action == "basketball_game_participation")
      return "Dalyvauja krepšinio loterijoje";
    return `${action}: ${value}`;
  };
  return (
    <Tooltip placement="top" title={textTitle()}>
      <IconDiscount />
    </Tooltip>
  );
};

export default OrderAdditionals;
