import {
  GET_MANIFEST_DPD,
  GET_MANIFEST_OMNIVA,
  GET_MANIFEST_LP_EXPRESS,
  LOADING_MANIFEST_DPD,
  LOADING_MANIFEST_OMNIVA,
  LOADING_MANIFEST_LP_EXPRESS,
} from "../Actions/types";

const initialState = {
  loading: false,
  loading_manifest_dpd: false,
  loading_manifest_omniva: false,
  loading_manifest_lp_express: false,
  manifest: [],
  manifest_dpd: [],
  manifest_omniva: [],
  manifest_lp_express: [],
  totals: 0,
  totals_manifest_omniva: 0,
  totals_omniva: 0,
  totals_lp_express: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "LOADING_MANIFEST":
      return {
        ...state,
        loading_manifest: true,
      };
    case LOADING_MANIFEST_DPD:
      return {
        ...state,
        loading_manifest_dpd: true,
      };
    case LOADING_MANIFEST_OMNIVA:
      return {
        ...state,
        loading_manifest_omniva: true,
      };
    case LOADING_MANIFEST_LP_EXPRESS:
      return {
        ...state,
        loading_manifest_lp_express: true,
      };
    case "GET_MANIFEST":
      return {
        ...state,
        manifest: action.payload.data,
        totals_manifest: action.payload.totals,
        loading_manifest: false,
      };
    case GET_MANIFEST_DPD:
      return {
        ...state,
        manifest_dpd: action.payload.data,
        totals_manifest_dpd: action.payload.totals,
        loading_manifest_dpd: false,
      };
    case GET_MANIFEST_OMNIVA:
      return {
        ...state,
        manifest_omniva: action.payload.data,
        totals_manifest_omniva: action.payload.totals,
        loading_manifest_omniva: false,
      };
    case GET_MANIFEST_LP_EXPRESS:
      return {
        ...state,
        manifest_lp_express: action.payload.data,
        totals_manifest_lp_express: action.payload.totals,
        loading_manifest_lp_express: false,
      };
    default:
      return state;
  }
}
