import React from "react";
import Container from "../Layout/Container";
import { connect } from "react-redux";
import {
  getProducts,
  remove_checked_substitutions,
  syncProductAkemeo,
} from "../../redux/Actions/productAction";
import ContentBlock from "../Layout/ContentBlock";
import TableColumnProduct from "./Items/TableColumnProduct";
import FilterMenu from "../Layout/FilterMenu";
import TableFilterBox from "../common/TableFilterBox";
import ContentValidation from "../common/ContentValidation";
import InputText from "../orders/accepted_orders/InputFilter";
import Pagination from "../Dashboard/items/Pagination";
import {
  SelectForm,
  ExportDocumentButton,
  SyncButton,
} from "../common/Buttons";
import { Switch, Select, Input } from "antd";
import { getFamiliesOptions } from "../../redux/Actions/familiesAction";
import { AiOutlineCloudSync } from "react-icons/ai";
import moment from "moment";
const { Option } = Select;

const initialFilterState = {
  code: "",
  name: "",
  supplier_code: "",
  product_category: undefined,
  manufacturer: "",
  focus: false,
  quantity: "",
  quantity_operation: ">",
};

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyValue: "",
      openProducts: [],
      familiesOptions: [],
      page: 1,
      pageCount: 100,
      paramsFilter: "",
      filter: initialFilterState,
    };
    this.available_edit = props.auth.user_role.identifier !== "hosts";
  }
  
  componentDidMount() {
    this.reloadData(this.state.paramsFilter);
    getFamiliesOptions().then((familiesOptions) =>
      this.setState({
        familiesOptions,
      })
    );
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.reloadData(this.state.paramsFilter);
    }
  }

  reloadData = (paramsFilter) => {
    this.props.getProducts(this.state.page, this.state.pageCount, paramsFilter);
  };

  setPagination = (page) => {
    this.setState({
      page,
    });
  };
  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  handleToggle = (value) => {
    const currentIndex = this.state.openProducts.indexOf(value);
    const newChecked = [...this.state.openProducts];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({
      openProducts: newChecked,
    });
  };

  filterChange = (e) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [e.target.name]: e.target.value,
        },
      },
      ["product_category", "focus", "quantity_operation"].includes(
        e.target.name
      )
        ? () => this.onFilter()
        : () => {
            console.log("t1");
          }
    );
  };
  delayOnFilter = () => {
    if (this.props.loading) {
      return setTimeout(() => {
        return this.delayOnFilter();
      }, 1000);
    }
    this.onFilter();
  };

  onFilter = () => {
    if (this.props.loading) {
      return this.delayOnFilter();
    }
    let filterParams = [];
    if (this.state.filter.name.length > 0)
      filterParams.push(`&q[title_i_cont_any]=${this.state.filter.name}`);
    if (this.state.filter.code.length > 0)
      filterParams.push(`&q[identifier_i_cont_any]=${this.state.filter.code}`);
    if (this.state.filter.supplier_code.length > 0)
      filterParams.push(
        `&q[product_suppliers_code_i_cont_any]=${this.state.filter.supplier_code}`
      );
    if (this.state.filter.product_category)
      filterParams.push(
        `&q[family_id_eq]=${this.state.filter.product_category}`
      );
    if (this.state.filter.manufacturer)
      filterParams.push(
        `&q[manufacturer_cont_any]=${this.state.filter.manufacturer}`
      );
    if (this.state.filter.quantity) {
      filterParams.push(`&q[quantity]=${this.state.filter.quantity}`);
      filterParams.push(
        `&q[quantity_operation]=${this.state.filter.quantity_operation}`
      );
    }
    if (this.state.filter.focus) filterParams.push(`&q[upsell_null]=0`);
    this.setState(
      {
        paramsFilter: filterParams.join(""),
        page: 1,
      },
      () => this.reloadData(filterParams.join(""))
    );
  };

  handleKeyDown = (e) => {
    if (e.key == "Enter") {
      this.onFilter();
    }
  };

  clearFilter = () => {
    this.setState(
      {
        filter: initialFilterState,
      },
      () => this.onFilter()
    );
  };

  renderCustomButtons = () => {
    return (
      <>
        {this.available_edit && (
          <>
            <SyncButton onClick={() => this.props.syncProductAkemeo()}>
              Produktų SYNC
            </SyncButton>
            <div className="dropdown">
              <button
                className="btn btn-sm btn-danger float-right mr-3 dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Ištrinti focus prekes
              </button>
              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdownMenuButton"
              >
                <button
                  className="dropdown-item"
                  onClick={() =>
                    this.props.remove_checked_substitutions(
                      this.state.openProducts
                    )
                  }
                >
                  Pasirinktas
                </button>
                <button
                  className="dropdown-item"
                  onClick={() =>
                    this.props.remove_checked_substitutions(
                      this.state.openProducts,
                      "filtered",
                      this.state.paramsFilter
                    )
                  }
                >
                  Išfiltruotas
                </button>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  render() {
    return (
      <Container
        location={{
          locationName: "Produktai",
          locationUrl: this.props.match.url,
        }}
      >
        <ContentBlock>
          <FilterMenu
            length={`${this.props.totals || 0} / ${
              this.props.products.length || 0
            }`}
            text="produktų"
            text2="Produktų"
            onChangeCountPage={this.setPageCount}
            countPage={this.state.pageCount}
          />
        </ContentBlock>
        <ContentBlock>
          <TableFilterBox
            // onFilter={this.onFilter}
            filter={false}
            add={false}
            clearFilter={() => this.clearFilter()}
            button1={this.renderCustomButtons()}
          >
            <div className="d-flex justify-content-between flex-1 flex-wrap">
              <div className="d-flex flex-wrap">
                <div className="px-1">
                  <InputText
                    width={"0.75rem"}
                    number="1"
                    name="code"
                    placeholder="Kodas"
                    value={this.state.filter.code}
                    onKeyDown={this.handleKeyDown}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
                <div className="px-1">
                  <InputText
                    width={"0.75rem"}
                    number="1"
                    name="name"
                    placeholder="Pavadinimas"
                    value={this.state.filter.name}
                    onKeyDown={this.handleKeyDown}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
                <div className="px-1">
                  <SelectForm
                    name="product_category"
                    placeholder="Prekių kategorijos"
                    onlyPlaceholder="Pasirinkti"
                    value={this.state.filter.product_category}
                    options={this.state.familiesOptions}
                    onChange={(value) =>
                      this.filterChange({
                        target: { value, name: "product_category" },
                      })
                    }
                  />
                </div>
                <div className="px-1">
                  <InputText
                    width={"0.75rem"}
                    number="1"
                    name="manufacturer"
                    placeholder="Gamintojas"
                    value={this.state.filter.manufacturer}
                    onKeyDown={this.handleKeyDown}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>{" "}
                <div className="px-1">
                  <InputText
                    width={"0.75rem"}
                    number="1"
                    name="supplier_code"
                    placeholder="Tiekėjo kodas"
                    value={this.state.filter.supplier_code}
                    onKeyDown={this.handleKeyDown}
                    onChange={(e) => this.filterChange(e)}
                  />
                </div>
                <div className="px-1">
                  <div className="form-group">
                    <div>
                      <label className={"editLabelForm"}>Kiekis</label>
                    </div>
                    <div className="d-flex">
                      <Select
                        style={{ width: 70 }}
                        onChange={(value) =>
                          this.filterChange({
                            target: {
                              value,
                              name: "quantity_operation",
                            },
                          })
                        }
                        value={this.state.filter.quantity_operation}
                      >
                        <Option value=">">{">"}</Option>
                        <Option value=">=">{">="}</Option>
                        <Option value="<">{"<"}</Option>
                        <Option value="<=">{"<="}</Option>
                        <Option value="==">{"=="}</Option>
                      </Select>
                      <Input
                        type="text"
                        style={{ fontSize: "0.75rem" }}
                        name="quantity"
                        className="form-control"
                        placeholder={"Įvesti"}
                        onKeyDown={this.handleKeyDown}
                        value={this.state.filter.quantity}
                        onChange={(e) => this.filterChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="px-1">
                  <div className="form-group">
                    <div>
                      <label className={"editLabelForm"}>{"Fokus"}</label>
                    </div>
                    <div
                      className="d-flex justify-content-center mt-2"
                      style={{ fontSize: "0.75rem" }}
                    >
                      <Switch
                        checkedChildren={"I"}
                        unCheckedChildren={"O"}
                        checked={this.state.filter.focus}
                        onChange={(value) =>
                          this.filterChange({
                            target: {
                              value,
                              name: "focus",
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-start px-1">
                {this.props.last_sync && (
                  <div className="d-flex align-items-center">
                    <AiOutlineCloudSync
                      className="mr-2"
                      color={"#C3C3C3"}
                      size={28}
                    />
                    <span style={{ color: "#C3C3C3", fontSize: 17 }}>
                      <b>Dienos:</b>{" "}
                      {moment(this.props.last_sync).format("YYYY-MM-DD HH:mm")}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </TableFilterBox>
          <div>
            <ContentValidation
              loader={this.props.loading}
              array={this.props.products}
              alertId_s="smsAlert-s"
              alertId_e="smsAlert-e"
            >
              <div className="table-responsive" style={{ overflow: "hidden" }}>
                <table className="table mt-3">
                  <thead>
                    <tr className="text-muted">
                      <th className="text-black"></th>
                      <th className="text-black">Kodas</th>
                      <th className="text-black" style={{ width: 350 }}>
                        Pavadinimas
                      </th>
                      <th className="text-black">Pagrindinis tiekėjas</th>
                      <th className="text-black">Kiekis</th>
                      <th className="text-black">Tiekėjas</th>
                      <th className="text-black">Tiekėjo kodas</th>
                      <th className="text-black">Tiekėjo kaina</th>
                      <th className="text-black">E-kaina</th>
                      <th className="text-black">R-kaina</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.products &&
                      this.props.products.map((item, index) => {
                        return (
                          <TableColumnProduct
                            key={index}
                            index={index}
                            item={item}
                            setSelected={() => this.handleToggle(item.id)}
                            selected={this.state.openProducts.includes(item.id)}
                            available_edit={this.available_edit}
                          />
                        );
                      })}
                  </tbody>
                </table>
                <Pagination
                  setPagination={this.setPagination}
                  page={this.state.page}
                  totals={this.props.totals}
                  pageCount={this.state.pageCount}
                />
              </div>
            </ContentValidation>
          </div>
        </ContentBlock>
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  products: state.product.products,
  totals: state.product.totals,
  last_sync: state.product.last_sync,
  loading: state.product.loading_products,
  errors: state.errors,
  auth: state.auth.user,
});

export default connect(mapStateToProps, {
  syncProductAkemeo,
  getProducts,
  remove_checked_substitutions,
})(Product);
