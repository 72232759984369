const service_clean = {
  number: "",
  type: undefined,
  client: "",
  goods: "",
  status: undefined,
  date: ["", ""],
  supplier: undefined,
  supp_date: ["", ""],
  external_number: "",
  stores: undefined,
  rivile_stores: undefined,
  comment_state: undefined,
  comment: "",
  not_closed: "",
};

const orders_clean = {
  id: "",
  client: "",
  date: ["", ""],
  supp_date: ["", ""],
  payment: undefined,
  supplier: "",
  order_row: "",
  delivery: undefined,
  delivery_custom: [],
  statusFilter: [],
  categoryFilter: [],
  r_line: "",
  comment: "",
  comment_state: [],
  serialNumber_check: false,
  game_participation: false,
  serialNumber_switch: false,
  active_orders: false,
  not_ready_orders: false,
  express_orders: false,
  at_the_store: undefined,
  created: undefined,
};

const service_filters = localStorage.getItem("service_filters")
  ? JSON.parse(localStorage.getItem("service_filters"))
  : service_clean;
const orders_filters = localStorage.getItem("orders_filters")
  ? JSON.parse(localStorage.getItem("orders_filters"))
  : orders_clean;

const initialState = {
  service: service_filters,
  orders: orders_filters,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "SET_ORDERS_FILTERS":
      setLocalOrder(payload);
      return { ...state, orders: payload };

    case "UPDATE_ORDERS_FILTERS":
      const orderChangeValue = {
        ...state.orders,
        [payload.name]: payload.value,
      };
      setLocalOrder(orderChangeValue);

      return {
        ...state,
        orders: orderChangeValue,
      };
    case "UPDATE_MULTI_ORDERS_FILTERS":
      const orderChangeMultiValue = {
        ...state.orders,
        ...payload,
      };
      setLocalOrder(orderChangeMultiValue);

      return {
        ...state,
        orders: orderChangeMultiValue,
      };
    case "RESET_ORDERS_FILTERS":
      setLocalOrder(orders_clean);
      return {
        ...state,
        orders: orders_clean,
      };

    //

    case "SET_SERVICE_FILTERS":
      setLocalService(payload);
      return { ...state, service: payload };

    case "UPDATE_SERVICE_FILTERS":
      const serviceChangeValue = {
        ...state.service,
        [payload.name]: payload.value,
      };
      setLocalService(serviceChangeValue);
      return {
        ...state,
        service: serviceChangeValue,
      };
    case "RESET_SERVICE_FILTERS":
      let service_clean_impr = { ...service_clean };
      service_clean_impr.status = state.service.status;
      setLocalService(service_clean_impr);
      return {
        ...state,
        service: service_clean_impr,
      };
    default:
      return state;
  }
}

const setLocalService = (obj) =>
  localStorage.setItem("service_filters", JSON.stringify(obj));

const setLocalOrder = (obj) =>
  localStorage.setItem("orders_filters", JSON.stringify(obj));
