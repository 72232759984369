import React, { useState, useEffect } from "react";
import $ from "jquery";

function ScrollYComponent() {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY < 7000) {
      setScrollY(window.scrollY);
    }
  };
  return (
    <div
      className={`navbar navbar-expand-lg navbar-light mx-2 ${
        scrollY >= 100 ? "d-block" : "d-none"
      }`}
    >
      <span
        className="navbar-toggler-icon"
        id="menu-toggle"
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          e.preventDefault();
          $("#wrapper").toggleClass("toggled");

          const collapseSetup =
            document.getElementById("wrapper").className === "d-flex toggled";

          localStorage.setItem("collapse", collapseSetup);
        }}
      ></span>
    </div>
  );
}

export default ScrollYComponent;
