import React from "react";
import Container from "../Layout/Container";
import { connect } from "react-redux";
import {
  getCountries,
  postCountry,
  deleteCountry,
  updateCountry,
  clearErrors
} from "../../redux/Actions/countryAction";
import CountryEditModal from "./Items/CountryEditModal";
import ContentBlock from "../Layout/ContentBlock";
import Pagination from "../Dashboard/items/Pagination";
import TableColumnCountry from "./Items/TableColumnCountry";
import FilterMenu from "../Layout/FilterMenu";
import TableFilterBox from "../common/TableFilterBox";
import ContentValidation from "../common/ContentValidation";

class Countries extends React.Component {
  constructor() {
    super();
    this.state = {
      nameValue: "",
      en_nameValue: "",
      currencyValue: "",
      codeValue: "",
      euValue: false,
      requiredId: 0,
      page: 1,
      pageCount: 100,
      action: "new",
    };
  }
  componentDidMount() {
    this.props.getCountries(this.state.page, this.state.pageCount);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.props.getCountries(this.state.page, this.state.pageCount);
    }
  }

  onAction = (action, index) => {
    this.setState({
      requiredId: index,
      action: action,
    });
  };

  setPagination = (page) => {
    this.setState({
      page,
    });
  };
  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  render() {
    const requiredItem = this.state.requiredId;
    let modalData = this.props.countryItems[requiredItem]
      ? this.props.countryItems[requiredItem]
      : [];
    return (
      <Container
        location={{ locationName: "Šalys", locationUrl: this.props.match.url }}
      >
        <ContentBlock>
          <FilterMenu
            length={`${this.props.totals || 0} / ${
              this.props.countryItems.length || 0
            }`}
            onChangeCountPage={this.setPageCount}
            countPage={this.state.pageCount}
            text="šalių"
            text2="Šalių"
          />
        </ContentBlock>
        <ContentBlock>
          <TableFilterBox filter={false} add={this.onAction}></TableFilterBox>
          <div>
            <ContentValidation
              loader={this.props.loading_country}
              array={this.props.countryItems}
              alertId_s="countriesAlert-s"
              alertId_e="countriesAlert-e"
            >
              <div className="table-responsive">
                <table className="table table-sm table-hover mt-3">
                  <thead>
                    <tr className="text-muted">
                      <th className="text-black">#</th>
                      <th className="text-black">Šalies pav.</th>
                      <th className="text-black">Šalies pav. angliškai</th>
                      <th className="text-black">Valiuta</th>
                      <th className="text-black">Šalies kodas</th>
                      <th className="text-black">EU?</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.countryItems.map((item, index) => {
                      return (
                        <TableColumnCountry
                          key={index}
                          index={index}
                          item={item}
                          onEdit={this.onAction}
                          onDelete={this.props.deleteCountry}
                        />
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                  setPagination={this.setPagination}
                  page={this.state.page}
                  totals={this.props.totals}
                  pageCount={this.state.pageCount}
                />
              </div>
            </ContentValidation>
          </div>
        </ContentBlock>

        <CountryEditModal
          action={this.state.action}
          postCountry={this.props.postCountry}
          updateCountry={this.props.updateCountry}
          objectData={modalData}
          clearErrors={this.props.clearErrors}
          errors={this.props.errors}
        />
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  countryItems: state.country.countries,
  totals: state.country.totals,
  loading_country: state.country.loading_country,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getCountries,
  postCountry,
  deleteCountry,
  updateCountry,
  clearErrors
})(Countries);
