import { useState } from "react";

export default function useLeasingItems(defaultValue) {
  const [array, setArray] = useState(defaultValue);
  const [destroyed, setDestroyed] = useState([]);

  function push(element) {
    setArray((a) => [...a, element]);
  }

  function filter(items, identifier) {
    return items.find((d) => d.identifier == identifier);
  }

  function update(index, newElement) {
    setArray((prev) => {
      const data = [...prev];
      data[index] = {
        ...data[index],
        ...newElement,
      };
      return data;
    });
  }

  function update_children(parent_idx, identifier, val) {
    setArray((prev) => {
      const data = [...prev];
      const findIndex = data[parent_idx].items.findIndex(
        (d) => d.identifier == identifier
      );
      if (findIndex == -1) {
        data[parent_idx].items.push({
          identifier,
          amount: 1,
          title: identifier,
          price: "",
          document_plan_id: null,
          total: 0
        });
      } else {
        data[parent_idx].items[findIndex] = {
          ...data[parent_idx].items[findIndex],
          ...val,
        };
      }
      return data;
    });
  }

  function remove_children(idx, identifier) {
    setArray((prev) => {
      const data = [...prev];
      const item_in_db = data[idx].items.find(
        (d) => d.identifier == identifier
      )?.id;
      if (data[idx].id && item_in_db) {
        setDestroyed((prev) => {
          let oldData = [...prev];
          if (oldData.find((d) => d.id == data[idx].id)) {
            oldData = oldData.map((d) => {
              if (d.id == data[idx].id) {
                return {
                  ...d,
                  items_attributes: [
                    ...d.items_attributes,
                    {
                      id: item_in_db,
                      _destroy: 1,
                    },
                  ],
                };
              }
              return d;
            });
          }

          return [
            ...prev,
            {
              id: data[idx].id,
              items_attributes: [
                {
                  id: item_in_db,
                  _destroy: 1,
                },
              ],
            },
          ];
        });
      }
      data[idx].items = data[idx].items.filter(
        (d) => d.identifier !== identifier
      );
      return data;
    });
  }

  function remove(index) {
    const item = array[index];
    if (item.id) {
      setDestroyed((prev) => {
        let oldData = [...prev];
        oldData.push({
          id: item.id,
          _destroy: 1,
          items_attributes: item.items
            .filter((d) => d.id)
            .map((d) => ({
              id: d.id,
              _destroy: 1,
            })),
        });
        return oldData;
      });
    }
    setArray((a) => [...a.slice(0, index), ...a.slice(index + 1, a.length)]);
  }

  function clear() {
    setArray([]);
  }

  function setValues(arr) {
    setArray(arr);
    setDestroyed([]);
  }

  function duplicate(idx) {
    setArray((a) => {
      let array = [...a];
      const newElement = {
        ...a[idx],
        id: null,
        items: a[idx].items.map((e) => ({
          ...e,
          id: null,
        })),
      };
      array.splice(idx + 1, 0, newElement);
      return array;
    });
  }

  return {
    array,
    destroyed,
    set: setValues,
    push,
    filter,
    duplicate,
    update,
    update_children,
    remove,
    remove_children,
    clear,
  };
}
