import React from "react";
import { Label } from "../global";

function InputForm({
  placeholder,
  name,
  value,
  onChange,
  type,
  error,
  disabled,
}) {
  return (
    <div className="form-group py-2">
      <Label>{placeholder}</Label>
      <input
        className={`form-control ${
          error ? "border border-danger" : "editInputForm"
        } ${error && "is-invalid"}`}
        style={error && { borderColor: "red!important" }}
        type={type}
        placeholder={placeholder}
        name={name}
        value={value || ""}
        disabled={disabled || false}
        onChange={onChange}
      />
      {error && <div className="invalid-feedback d-block">{error}</div>}
    </div>
  );
}

export default InputForm;
