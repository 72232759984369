import React, { useState } from "react";
import moment from "moment";

function TableComment(props) {
  const [ShowMore, setShowMore] = useState(false);
  let data = props.comments
    .filter((comFilter) => comFilter.comment_type === props.type)
    .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

  if (props.action == "Service") {
    data = props.comments
      .filter((comFilter) => comFilter.identifier === props.type)
      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  }
  const textBuilder = (val) => {
    const textValue = val || ""
    const replacePattern1 =
      /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;

    return textValue.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');
  };
  let tableShow;
  if (data.length == 0) {
    tableShow = (
      <td className="align-middle border p-0">
        <div style={{ width: "auto" }} />
      </td>
    );
  } else if (data.length == 1) {
    let comment = data[0];
    tableShow = (
      <React.Fragment>
        <td
          className="align-middle border p-0"
          style={{
            backgroundColor: comment?.comment_state?.color || "#dee2e6",
          }}
        >
          <div
            className="p-2"
            style={{
              width: "auto",
              fontSize: "0.8rem",
            }}
          >
            <span className="text-primary">
              {" "}
              {moment(comment.created_at).format("YYYY MM DD [\n] HH:mm")}
            </span>
            <br />
            <span className="font-weight-bold">
              {`${comment.user_title || comment.user || ""} - ${
                comment?.comment_state?.name || ""
              }`}
            </span>
            {comment.date !== null && (
              <>
                <br />
                <span className="font-weight-bold">
                  {moment(comment.date).format("YYYY MM DD")}
                </span>
              </>
            )}
            <div
              style={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{
                __html: `<p>${textBuilder(
                  comment.body || comment.comment
                )}</p>`,
              }}
            ></div>
          </div>
        </td>
      </React.Fragment>
    );
  } else {
    let manyDataFirst = data.splice(-1);
    let manyDataSeconds = data;

    tableShow = (
      <td className="align-middle border p-0">
        <div style={{ width: "auto" }}>
          <button
            className="btn btn-sm btn-primary"
            style={{
              padding: "0",
              width: "100%",
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
            onClick={() => setShowMore(!ShowMore)}
          >
            <span style={{ fontSize: "0.8rem", fontWeight: "400" }}>
              Rodyti senesnius
            </span>
          </button>
          {ShowMore
            ? manyDataSeconds.map((comment, index) => {
                return (
                  <React.Fragment key={index}>
                    <div
                      className="p-2"
                      style={{
                        fontSize: "0.8rem",
                        // padding: "3px",
                        backgroundColor:
                          comment?.comment_state?.color || "#dee2e6",
                      }}
                    >
                      <span className="text-primary">
                        {moment(comment.created_at).format(
                          "YYYY MM DD [\n] HH:mm"
                        )}
                      </span>
                      <br />
                      <span className="font-weight-bold">
                        {`${comment.user_title || comment.user || ""} - ${
                          comment?.comment_state?.name || ""
                        }`}
                      </span>
                      {comment.date !== null && (
                        <>
                          <br />
                          <span className="font-weight-bold">
                            {moment(comment.date).format("YYYY MM DD")}
                          </span>
                        </>
                      )}
                      <br />
                      <div
                        style={{ whiteSpace: "pre-wrap" }}
                        dangerouslySetInnerHTML={{
                          __html: `<p>${textBuilder(
                            comment.body || comment.comment
                          )}</p>`,
                        }}
                      ></div>
                    </div>
                  </React.Fragment>
                );
              })
            : null}
          {manyDataFirst.map((comment, index) => {
            return (
              <React.Fragment key={index}>
                <div
                  className="p-2"
                  style={{
                    fontSize: "0.8rem",
                    // padding: "3px",
                    backgroundColor: comment?.comment_state?.color || "#dee2e6",
                  }}
                >
                  <span className="text-primary">
                    {moment(comment.created_at).format("YYYY MM DD [\n] HH:mm")}
                  </span>
                  <br />
                  <span className="font-weight-bold">
                    {`${comment.user_title || comment.user || ""} - ${
                      comment?.comment_state?.name || ""
                    }`}
                  </span>
                  {comment.date !== null && (
                    <>
                      <br />
                      <span className="font-weight-bold">
                        {moment(comment.date).format("YYYY MM DD")}
                      </span>
                    </>
                  )}
                  <br />
                  <div
                    style={{ whiteSpace: "pre-wrap" }}
                    dangerouslySetInnerHTML={{
                      __html: `<p>${textBuilder(
                        comment.body || comment.comment
                      )}</p>`,
                    }}
                  ></div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </td>
    );
  }

  return tableShow;
}

export default TableComment;
