export const success = (label, closeId) => {
  if (!document.getElementById(label)) return;
  document.getElementById(label).style.display = "block";
  setTimeout(
    () => (document.getElementById(label).style.display = "none"),
    5000
  );
  if(closeId) {
    document.getElementById(closeId).click();
  }
};
export const error = (label) => {
  if (!document.getElementById(label)) return;
  document.getElementById(label).style.display = "block";
  setTimeout(
    () => (document.getElementById(label).style.display = "none"),
    5000
  );
};
