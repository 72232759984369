import React, { useState } from "react";
import { Modal } from "antd";
import { QrReader } from "react-qr-reader";
import Axios from "axios";
import styled from "styled-components";
import { IconLoptop } from "../Layout/Icons";
import TextInfoWrapper from "../common/TextInfoWrapper";
import ButtonSecondary from "../common/ButtonSecondary";

const SbLeasingQrCodeScanner = ({ onClose, onSave }) => {
  const [step, setStep] = useState(1);
  const [qrCode, setQrCode] = useState({
    type: "waiting",
    code: "",
  });

  const startScann = () => {
    setQrCode({
      type: "waiting",
      code: "",
    });
    setStep(2);
  };
  return (
    <Modal
      title="Prekės skenavimas"
      open
      footer={null}
      onCancel={onClose}
    >
      {step == 1 ? (
        <StyledButton onClick={() => startScann()}>
          <IconLoptop />
          <span className="ml-1">Naudokite esamo įrenginio kamerą</span>
        </StyledButton>
      ) : qrCode.type == "waiting" ? (
        <QrReader
          constraints={{
            facingMode: "environment",
          }}
          onResult={(result, error) => {
            if (!!result) {
              if (result?.text) {
                Axios.get(
                  // `/api/v1/products/simple_products.json?&q[identifier_eq]=${result.text}`
                  `/api/v1/products/simple_products.json?&[inspection_rivile]=1&q[identifier_eq]=${result.text}`
                ).then((res) => {
                  let infoObj = {
                    type: "failed",
                    code: result.text,
                    product_title:
                      "Toks barkodas neegizstuoja sistemoje arba nėra jūsų padaliniuose.",
                  };
                  if (res.data && res.data.data && res.data.data.length > 0) {
                    const finded_item = res.data.data[0];
                    onSave(finded_item);
                    infoObj.type = "successfully";
                    infoObj.product_title = finded_item.title;
                  }
                  setQrCode((prev) => ({
                    ...prev,
                    ...infoObj,
                  }));
                });
              }
            }

            // if (!!error) {
            //   console.info(error);
            // }
          }}
          style={{ width: "100%" }}
        />
      ) : (
        <>
          <TextInfoWrapper
            text={
              qrCode.type == "successfully"
                ? "Prekė pridėta į krepšelį"
                : qrCode.type == "failed"
                ? "Nuskenuoti nepavyko"
                : "Nėra statuso"
            }
            color={qrCode.type == "successfully" ? "#1D950C" : "#DD2F45"}
            backgroundColor={
              qrCode.type == "successfully" ? "#E5F3E3" : "#F9DADE"
            }
          >
            <p className="mb-0 text-xs text-black">
              {qrCode.type == "successfully" && (
                <span>
                  {qrCode.code} <br />
                </span>
              )}
              {qrCode.product_title}
            </p>
          </TextInfoWrapper>
          <div className="d-flex justify-content-between align-items-center my-3">
            <div
              style={{
                height: 1,
                backgroundColor: "#D9D9D9",
                width: "20%",
              }}
            ></div>
            <p
              className="text-xs text-black mb-0 px-3"
              style={{
                whiteSpace: "nowrap",
              }}
            >
              Tęskite skenavimą arba spauskite “Užbaigti”
            </p>
            <div
              style={{
                height: 1,
                backgroundColor: "#D9D9D9",
                width: "20%",
              }}
            ></div>
          </div>{" "}
          <StyledButton onClick={() => startScann()}>
            <IconLoptop />
            <span className="ml-1">Naudokite esamo įrenginio kamerą</span>
          </StyledButton>
        </>
      )}
      <div className="d-flex align-items-center justify-content-between mt-4">
        <div
          style={{
            width: 140,
          }}
        >
          <ButtonSecondary outline={true} onClick={onClose}>
            Uždaryti
          </ButtonSecondary>
        </div>
        {step > 1 && qrCode.code && (
          <div
            style={{
              width: 140,
            }}
          >
            <ButtonSecondary onClick={onClose}>Užbaigti</ButtonSecondary>
          </div>
        )}
      </div>
    </Modal>
  );
};

const StyledButton = styled.button`
  background: #fff6e8;
  border: 1px solid #f7931e;
  border-radius: 3px;
  font-weight: 400;
  font-size: 16px;
  text-align: center;

  color: #f7931e;
  padding: 25px 0px 20px;

  width: 100%;
`;

export default SbLeasingQrCodeScanner;
