import React, { useState, useRef, useEffect, Fragment, useMemo } from "react";
import styled from "styled-components";
import { Alert, Checkbox, Input, Select, Tooltip } from "antd";
import { GoPrimitiveDot } from "react-icons/go";
import { CheckBoxInput, SelectForm } from "../../../common/Buttons";
const { TextArea } = Input;
const { Option } = Select;
import { FcCheckmark } from "react-icons/fc";
import Axios from "axios";
import {
  antdMessageError,
  exportDocument,
  getFromPercentage,
  last_arr,
  makeid,
  prettyDateFormat,
  returnEuroString,
  uniqueArray,
} from "../../../../helpers/Utils";
import {
  Percentage_svg,
  Product_price_svg,
  Trash_svg,
} from "../../../../helpers/SvgStrings";
import omniva_icon from "../../../../../assets/images/omniva.svg";
import dpd_icon from "../../../../../assets/images/dpd.svg";
import lpexpress_icon from "../../../../../assets/images/lpexpress.svg";
import useDebounce from "../../../../hooks/useDebounce";
import {
  IconCancel,
  IconCopy,
  IconExclamation,
  IconSticker,
} from "../../../Layout/Icons";
// import { printBigBoxLabels } from "../../../../redux/Actions/rivile/BigboxRivileAction";

function RivileBigBoxEditColumn({
  i,
  users,
  its_saved,
  its_same_day,
  exported_at,
  updateRivileRequestOne,
  selected_internal,
  set_selected_internal,
  selected_bad_id,
  its_bb,
  dublicate,
}) {
  // console.count("Updated: ");
  // State
  const trComRef = useRef(null);
  const [state, setState] = useState(i);
  const [identifier, setIdentifier] = useState("");
  const [currentEditing, setCurrentEditing] = useState(false);

  useDebounce(() => onBlur(), 600, [
    state.bb_code,
    state.product_name,
    state.department_code,
    state.prime_cost,
    state.stock_quantity,
    state.returned,
    state.manager,
    state.admin_comment,
    state.tr_comment,
    state.rivile_code,
    state.department_name,
  ]);

  useDebounce(() => onChangeRivile(), 600, [state.rivile_code]);

  useEffect(() => {
    if (!i.identifier) return;
    if (i.identifier !== identifier) {
      setCurrentEditing(false);
      setIdentifier(i.identifier);
      setState({
        bb_code: i.bb_code,
        product_name: i.product_name,
        rivile_code: i.rivile_code,
        department_name: i.department_name,
        department_code: i.department_code,
        prime_cost: i.prime_cost,
        returned: i.returned,
        manager: i.manager,
        stock_quantity: i.stock_quantity,
        tr_comment: i.tr_comment,
        admin_comment: i.admin_comment,
        total_cost: i.total_cost,
      });
    } else {
      setCurrentEditing(true);
    }
  }, [i.identifier, i.updated_at]);

  const have_trackings = useMemo(() => {
    return i.courier_trackings?.length > 0 || false;
  }, [i.courier_trackings?.length]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setCurrentEditing(true);
    if (name === "returned") {
      setState((prev) => ({
        ...prev,
        [name]: value,
        prime_cost: null,
        total_cost: null,
        quantity: i.quantity,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const departmentChange = (opt) => {
    setCurrentEditing(true);
    if (opt.department_name === "Nėra") {
      setState((prev) => ({
        ...prev,
        department_code: "",
        department_name: "Nėra",
        prime_cost: 0,
        stock_quantity: 0,
        total_cost: calculatingTotals(0),
      }));
    } else {
      const valueQuantity = opt.quantity || 0;
      const valuePrice = opt.prime_cost || 0;
      const itemValue = {
        department_code: opt.department_code,
        department_name: opt.department_name,
        prime_cost: state.returned ? null : valuePrice,
        stock_quantity:
          valueQuantity >= i.quantity ? i.quantity : valueQuantity,
      };
      setState((prev) => ({
        ...prev,
        ...itemValue,
        total_cost: calculatingTotals(itemValue.prime_cost),
      }));
    }
  };
  const onChangeRivile = async () => {
    if (!currentEditing) return;
    const { data } = await Axios.get(
      `/api/v1/products/simple_products.json?q[identifier_eq]=${state.rivile_code}`
    );
    if (data && data.data && !!data.data.length) {
      const product = last_arr(data.data);
      setCurrentEditing(true);

      setState((prev) => ({
        ...prev,
        product_name: product.title,
        rivile_code: product.identifier,
        garbage_fee_amount: product.garbage_fee_amount,
        surcharge_percentage: product.surcharge_percentage,
      }));
    } else {
      antdMessageError("Produktas nerastas");
    }
  };

  const onClickOk = () => {
    onChange({ target: { name: "tr_comment", value: "ok" } });
    trComRef.current.focus();
  };

  const onBlur = () => {
    // console.log("tą", currentEditing)
    if (!currentEditing) return;
    const randomId = makeid(7);

    const newState = { ...state, identifier: randomId };
    delete newState.format_items_data;
    delete newState.bigbox_shop_identifier;
    delete newState.tracking_numbers;
    delete newState.courier_trackings;
    delete newState.inner_document_number;
    delete newState.dublicate;
    updateRivileRequestOne(i.id, newState);
    setIdentifier(randomId);
  };

  const renderIcon = () => {
    const style = {
      width: 12,
      heigth: 12,
      marginRight: 4,
    };
    switch (i.deliverie) {
      case "DPD":
        return <img src={dpd_icon} style={style} />;
      case "Omniva":
        return <img src={omniva_icon} style={style} />;
      case "LP Exp":
        return <img src={lpexpress_icon} style={style} />;
      default:
        return "";
    }
  };

  const enabled = its_same_day && !exported_at;
  // const enabled = true;
  const enable_edit_product = enabled && (i.dublicate || i?.order_number?.startsWith('FO') || !i?.bb_identifier ? true : false)

  const calculatingTotals = (val) => {
    const value = +val || 0;
    const garbage_fee_amount = +i.garbage_fee_amount || 0;
    const precentage =
      +getFromPercentage(+i.surcharge_percentage || 0, value) || 0;
    let total_cost_cal = +value + +garbage_fee_amount + +precentage;
    return total_cost_cal;
  };

  const showCheckBox = useMemo(() => {
    if (
      ["U0809", "U08091"].includes(i.department_code)
        ? false
        : !have_trackings && its_bb
    )
      return (
        <Tooltip title="Nėra galimybas kūrimas be lipdukų." placement="top">
          <IconExclamation />
        </Tooltip>
      );
    if (i.inner_document_number) {
      return (
        <>
          <div className="text-primary">{i.inner_document_number}</div>
          {!["U0809", "U08091"].includes(i.department_code) &&
            i.department_code && (
              <CheckBoxInput
                checked={selected_internal}
                onChange={() => set_selected_internal()}
              />
            )}
        </>
      );
    } else {
      return (
        <CheckBoxInput
          checked={selected_internal}
          onChange={() => set_selected_internal()}
        />
      );
    }
  }, [
    selected_internal,
    have_trackings,
    i.inner_document_number,
    i.department_code,
  ]);

  const {
    bb_code,
    product_name,
    rivile_code,
    department_name,
    department_code,
    prime_cost,
    returned,
    manager,
    stock_quantity,
    tr_comment,
    admin_comment,
    total_cost,
  } = state;

  return (
    <StyledTr colorBoolean={i.dublicate}>
      <StyledTd>
        {showCheckBox}
        {selected_bad_id && (
          <Alert message="Nepriskirtas vidinis" type="error" />
        )}
        {i.canceled_at && (
          <div>
            <Tooltip
              placement="top"
              title={`Atšauktas ${prettyDateFormat(i.canceled_at)}`}
            >
              <IconCancel />
            </Tooltip>
          </div>
        )}
      </StyledTd>
      <StyledTd>{i.order_number}</StyledTd>
      <StyledTd>{i.quantity}</StyledTd>
      <StyledTd>
        {enabled && !i.bb_code ? (
          <Input
            autoComplete="off"
            placeholder="Įveskite"
            name="bb_code"
            value={bb_code}
            // onBlur={onBlur}
            onChange={onChange}
          />
        ) : (
          bb_code
        )}
        <div
          className="d-flex justify-content-center align-items-center bg-white text-xs mt-2"
          style={{
            border: "1px solid #E8E8E8",
            borderRadius: 160,
            padding: "4px 5px",
          }}
        >
          {renderIcon()}
          {i.deliverie}
        </div>
      </StyledTd>
      <StyledTd>
        <div className="d-flex align-items-center">
          {enable_edit_product ? (
            <Input
              style={{
                maxWidth: 120,
              }}
              autoComplete="off"
              placeholder="Įveskite"
              name="rivile_code"
              // onBlur={onBlur}
              onChange={onChange}
              value={rivile_code}
            />
          ) : (
            <span className="text-sm font-semi-bold">{rivile_code}</span>
          )}
          <GoPrimitiveDot className="mx-2 text-orange" />
          {enable_edit_product ? (
            <Input
              autoComplete="off"
              placeholder="Įveskite"
              name="product_name"
              value={product_name}
              // onBlur={onBlur}
              onChange={onChange}
            />
          ) : (
            product_name
          )}
        </div>
        <div>
          {i.courier_trackings.map((val, idx) => (
            <Fragment key={`${val.number}_${idx}`}>
              <Tooltip
                placeholder="top"
                title={val.department || "Nepriskirtas"}
              >
                <a
                  className={`badge badge-pill mr-1 ${
                    i.picked_up_at
                      ? i.delivered_at
                        ? "badge-success"
                        : "badge-warning"
                      : "badge-light"
                  }`}
                  href={val.url}
                  target="_blank"
                >
                  {val.number}
                </a>
                {Number.isInteger((idx + 1) / 4) && <br />}
              </Tooltip>
            </Fragment>
          ))}
        </div>
        <div className="d-flex mt-2">
          {/* Layout */}
          <div
            style={{
              width: "40%",
            }}
          >
            {enabled ? (
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder={"Pasirinkti"}
                dropdownMatchSelectWidth={false}
                value={department_name || undefined}
                size="default"
                // onBlur={onBlur}
                onChange={(_, arg) => departmentChange(arg)}
              >
                {[
                  ...i.format_items_data,
                  {
                    department_code: "Nėra",
                    department_name: "Nėra",
                    prime_cost: "",
                    quantity: "",
                  },
                ].map((item) => (
                  <Option
                    key={`${item.department_code}_${item.department_name}`}
                    value={item.department_name}
                    department_code={item.department_code}
                    department_name={item.department_name}
                    prime_cost={item.prime_cost}
                    quantity={item.quantity}
                  >
                    {item.department_name}{" "}
                    {item.quantity && `(${item.quantity})`}
                  </Option>
                ))}
              </Select>
            ) : (
              department_name
            )}
          </div>
          <div
            className="mr-2"
            style={{
              width: "20%",
            }}
          >
            {enabled ? (
              <Input
                autoComplete="off"
                placeholder="Pad. kodas"
                name="department_code"
                disabled={true}
                value={department_code}
                // onBlur={onBlur}
                onChange={onChange}
              />
            ) : (
              department_code
            )}
          </div>
          <div
            style={{
              width: "20%",
            }}
          >
            {enabled ? (
              <Input
                type="number"
                className="without_arrows"
                autoComplete="off"
                placeholder="NETNET"
                name="prime_cost"
                value={prime_cost}
                disabled={returned || department_name == "Nėra"}
                // onBlur={onBlur}
                onChange={(e) => {
                  const value = e.target.value;
                  setCurrentEditing(true);
                  setState((prev) => ({
                    ...prev,
                    prime_cost: value,
                    total_cost: calculatingTotals(value),
                  }));
                }}
              />
            ) : (
              prime_cost
            )}
          </div>
          <div
            style={{
              width: "20%",
            }}
          >
            {enabled ? (
              <Input
                disabled={department_name == "Nėra"}
                type="number"
                autoComplete="off"
                className={
                  stock_quantity > i.quantity
                    ? "border-danger without_arrows"
                    : "without_arrows"
                }
                min={0}
                max={i.quantity}
                placeholder="Kiekis"
                name="stock_quantity"
                value={stock_quantity}
                // onBlur={onBlur}
                onChange={onChange}
              />
            ) : (
              stock_quantity
            )}
          </div>
        </div>
        <div className="col-12 my-2 px-1">
          <div className="row">
            <div className="col-4 d-flex">
              <Trash_svg />
              <div className="font-semi-bold mr-3 ml-1">
                {i.garbage_fee_amount || 0}
              </div>
            </div>

            <div className="col-4 d-flex">
              <Percentage_svg />
              <div className="font-semi-bold ml-1">
                {i.surcharge_percentage || 0}%
              </div>
            </div>

            <div className="col-4 d-flex">
              <Product_price_svg />
              <div className="font-semi-bold ml-1">
                {returnEuroString(total_cost)}
              </div>
            </div>
          </div>
        </div>
        {i.manager_comment && (
          <div className="col-12 p-2 border border rounded bg-white">
            {i.manager_comment}
          </div>
        )}
      </StyledTd>
      <StyledTd className="text-center">
        <Checkbox
          choised="returned"
          name="returned"
          checked={returned}
          disabled={!enabled}
          // onBlur={onBlur}
          onChange={(e) =>
            onChange({
              target: {
                name: "returned",
                value: e.target.checked,
              },
            })
          }
        />
      </StyledTd>
      <StyledTd>
        {enabled ? (
          <SelectForm
            options={users}
            style={{ width: "100%" }}
            onlyPlaceholder="Pasirinkti"
            dropdownMatchSelectWidth={false}
            value={manager || undefined}
            // onBlur={onBlur}
            onChange={(e) =>
              onChange({
                target: { name: "manager", value: e },
              })
            }
          />
        ) : (
          manager
        )}
      </StyledTd>
      <StyledTd>
        {its_same_day ? (
          <TextArea
            placeholder="Įveskite"
            name="admin_comment"
            rows={2}
            value={admin_comment}
            onChange={onChange}
          />
        ) : (
          admin_comment
        )}
      </StyledTd>
      <StyledTd>
        {enabled ? (
          <TextArea
            ref={trComRef}
            placeholder="Įveskite"
            name="tr_comment"
            rows={2}
            value={tr_comment}
            onChange={onChange}
          />
        ) : (
          tr_comment
        )}
      </StyledTd>
      <StyledTd>
        {i.bb_identifier && have_trackings && (
          <Tooltip
            placement="top"
            title={`Parsisiųsti lipduką ${uniqueArray(
              i.courier_trackings.map((d) => d.department)
            ).join(", ")}`}
          >
            <button
              onClick={() => {
                exportDocument(
                  "/api/v1/rivile_requests_documents/download_labels",
                  `labels_${i.bb_code}.pdf`,
                  "POST",
                  {
                    bb_identifier: i.bb_identifier,
                  }
                );

                // printBigBoxLabels(i.bb_identifier);
              }}
              className="btn btn-primary"
              style={{
                backgroundColor: "#F29302",
              }}
            >
              <IconSticker />
            </button>
          </Tooltip>
        )}
      </StyledTd>
      <StyledTd className="d-flex pr-2">
        {!i.dublicate && enabled && (
          <Tooltip placement="top" title="Kopijuoti">
            <div
              className="mr-2"
              onClick={() => {
                dublicate({
                  ...i,
                  ...state,
                });
                console.log("!123");
              }}
            >
              <IconCopy />
            </div>
          </Tooltip>
        )}
        {its_saved ? (
          <FcCheckmark
            className="cursor-pointer grow icon_opacity"
            size={"20px"}
          />
        ) : (
          <>
            {enabled && tr_comment !== "ok" && (
              <div
                className="btn btn-sm text-success grow cursor-pointer"
                onClick={onClickOk}
              >
                OK
              </div>
            )}
          </>
        )}
      </StyledTd>
    </StyledTr>
  );
}

const StyledTr = styled.tr`
  border-bottom: 1px solid silver;
  border-left: 4px solid ${(props) => (props.colorBoolean ? "red" : "silver")};
`;

const StyledTd = styled.td`
  font-size: 13px;
  color: #000;
  vertical-align: middle;
`;

export default RivileBigBoxEditColumn;

// const TextAreaDebounce = (props) => {
//   useDebounce(
//     () => {
//       console.log("UPDate: ", props.name);
//       props.onSave(props.name);
//     },
//     1000,
//     [props.value]
//   );
//   return (
//     <TextArea
//       {...props}
//       // ref={trComRef}
//       // placeholder="Įveskite"
//       // name="tr_comment"
//       // rows={2}
//       // value={tr_comment}
//       // onChange={onChange}
//     />
//   );
// };
