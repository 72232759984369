import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Input, Alert } from "antd";
// import { createSmsItem } from "../../../../../../redux/Actions/orderAction";
import { ClassicButton } from "../../../common/ButtonWithLoading";
import { antdMessageError, phonenumberValid } from "../../../../helpers/Utils";
import { createServiceRepairSms } from "../../../../redux/Actions/Service/serviceAction";
import { SelectForm } from "../../../common/Buttons";

const { TextArea } = Input;

function SmsSending({
  id,
  phoneNumber,
  sms,
  shipping_address_street,
  orderId,
  shipments,
}) {
  const { sms_template } = useSelector((state) => state.service);
  const [bodyText, setBodyText] = useState("");
  const [loading, setLoading] = useState(false);
  const [bodyTextID, setBodyTextID] = useState(undefined);
  const [alertText, setAlertText] = useState({
    success: "success",
    text: "",
    show: false,
  });
  const [error, setError] = useState(false);

  const dispatch = useDispatch();

  const sendSms = () => {
    if (bodyText.trim() === "") {
      return setError(true);
      // return antdMessageError("Įveskite tekstą.");
    }
    setLoading(true);
    const variables = {
      body: bodyText,
      phone: phoneNumber,
      id,
    };
    dispatch(createServiceRepairSms(id, variables)).then((res) => {
      if (res !== false) {
        setLoading(false);
        setAlertText({
          success: "success",
          text: "Sėkmingai išsiųsta sms žinutė.",
          show: true,
        });
        setBodyText("");
        setBodyTextID(undefined);
        setError(false);
      } else {
        setError(true);
        setLoading(false);
        setAlertText({
          success: "error",
          text: "Įvyko klaida.",
          show: true,
        });
      }
    });
  };

  // const setSmsText = (evt, arg) => {
  //   let value = evt;

  //   setBodyTextID(arg.children);
  //   setBodyText(value);
  // };

  const setSmsText = (evt, arg) => {
    let value = evt;
    if (value.search("[SHOP]") !== -1) {
      value = value.split("#[SHOP]").join(shipping_address_street);
    }
    if (value.search("[uzsakymo nr]") !== -1) {
      value = value.split("#[uzsakymo nr]").join(orderId);
    }
    if (value.search("[tracking link]") !== -1) {
      let courier_trackings = [];
      let courier_trackings_number = [];
      shipments.forEach((ship) => {
        ship.courier_trackings.forEach((track) => {
          courier_trackings.push(track.url);
          courier_trackings_number.push(track.number);
        });
      });
      if (courier_trackings.length === 1) {
        value = value.split("#[tracking link]").join(courier_trackings[0]);
        if (value.search("[tracking number]")) {
          value = value
            .split("#[tracking number]")
            .join(courier_trackings_number[0]);
        }
      } else if (courier_trackings.length > 1) {
        value = value
          .split("#[tracking link]")
          .join(courier_trackings.join(", "));
        if (value.search("[tracking number]")) {
          value = value
            .split("#[tracking number]")
            .join(courier_trackings_number.join(", "));
        }
      }
    }

    setBodyTextID(arg.children);
    setBodyText(value);
  };

  const smsOptions = sms_template.map((e) => ({
    label: e.message,
    value: e.body,
  }));
  return (
    <div className="col-12 bg-silver pt-3">
      {alertText.show && (
        <Alert
          className="mt-2"
          message={alertText.text}
          type={alertText.success}
          showIcon
        />
      )}
      <div className="pt-2 mb-2">
        <SelectForm
          onlyPlaceholder="Šablonai"
          options={smsOptions}
          onChange={(evt, arg) => setSmsText(evt, arg)}
          value={bodyTextID}
        />
      </div>
      <div>
        <div style={{ width: "100%" }}>
          <TextArea
            placeholder="Tekstas"
            value={bodyText}
            autoSize={{ minRows: 2, maxRows: 6 }}
            onChange={(e) => setBodyText(e.target.value)}
          />
        </div>
        <div
          style={{
            width: "100%",
            marginTop: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="text-danger">{error && "Įveskite tekstą"}</div>

          {phonenumberValid(phoneNumber) && (
            <ClassicButton loading={loading} text="Siųsti" onClick={sendSms} />
          )}
        </div>
      </div>
      <table className="table table-hover mt-3">
        {sms
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .map((item, index) => {
            return (
              <tbody key={index}>
                <tr>
                  <td style={{ width: "20%" }}>
                    {moment(item.created_at).format("YYYY-MM-DD HH:mm")}
                    <br />
                    <div>{item.user_title}</div>
                  </td>
                  <td>{item.body}</td>
                </tr>
              </tbody>
            );
          })}
      </table>
    </div>
  );
}

export default SmsSending;
