import { SET_CURRENT_USER } from "../Actions/types";

const initialState = {
  isAuthenticated: false,
  isAvalaible: false,
  user: {},
  additional_information: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        isAuthenticated: isEmpty(action.payload),
        user: action.payload,
        isAvalaible: false,
      };
    case "SUCCESS_AUTHENTICATED":
      return {
        ...state,
        isAvalaible: true,
      };
    case "SET_ADDITIONAL_INFO":
      return {
        ...state,
        additional_information: {
          ...state.additional_information,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}

const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return true;
  }
  return false;
};
