import React from "react";

function ContentBlock({ children, extraClass, style, style_children = {} }) {
  return (
    <div className={`col-12 ${extraClass}`} style={style}>
      <div className="mb-1 bg-white py-2 shadow-sm" style={style_children}>
        {children}
      </div>
    </div>
  );
}

ContentBlock.defaultProps = {
  extraClass: "",
  style_children: {
    borderRadius: 4,
  },
};

export default ContentBlock;
