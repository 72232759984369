import React, { useState, useEffect } from "react";
import {
  SelectForm,
  CheckBoxInput,
  ButtonTrash,
} from "../../common/Buttons";
import ContentValidation from "../../common/ContentValidation";
import TextInputModal from "../../common/TextInputModal";
import ContentBlock from "../../Layout/ContentBlock";
import Table from "../../../components/common/TableUi";
import { Tooltip } from "antd";
import SimplePagination from "../../common/SimplePagination";
import { getPoster, removePoster } from "../../../redux/Actions/postersAction";
import { useDispatch, useSelector } from "react-redux";
// import RangePickerComp from "../../common/RangePickerComp";
import {
  antdMessageError,
  exportDocument,
  getDateFormat
} from "../../../helpers/Utils";
import { AiFillPrinter } from "react-icons/ai";
import TableFilterBox from "../../common/TableFilterBox";
import DataPickerInputModal from "../../common/DataPickerInputModal";
import { useMemo } from "react";
import { DropdownButton } from "../../global";

const initialFilterState = {
  discount_title: "",
  discount_date_from: "",
  discount_date_to: "",
  product: "",
  product_category: undefined,
};

export default function PosterFirst({ setAction, setModal }) {
  const dispatch = useDispatch();
  const { data, totals, loading, options, current } = useSelector(
    (state) => state.custom
  );
  const { user_role } = useSelector((state) => state.auth.user);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState([]);

  const [filter, setFilter] = useState(initialFilterState);
  const [filterString, setfilterString] = useState("");
  //

  useEffect(() => {
    console.log("2");
    dispatch(getPoster(page, filterString));
  }, [filterString, page]);

  useEffect(() => {
    onFilter(filter);
  }, [
    filter.product_category,
    filter.discount_date_from,
    filter.discount_date_to,
  ]);

  const onChange = (e) => {
    const { value, name } = e.target;
    const filters = { ...filter, [name]: value };
    setFilter(filters);
  };

  const onFilter = (filter) => {
    let filterParams = "";

    if (filter.discount_title)
      filterParams += `&q[discount_title_cont_any]=${filter.discount_title}`;
    if (filter.discount_date_from)
      filterParams += `&q[discount_date_from_gteq]=${filter.discount_date_from}`;
    if (filter.discount_date_to)
      filterParams += `&q[discount_date_to_lteq]=${filter.discount_date_to}`;
    if (filter.product)
      filterParams += `&q[product_title_or_product_code_cont_any]=${filter.product}`;
    if (filter.product_category)
      filterParams += `&q[product_category_eq]=${filter.product_category}`;

    console.log(filterParams);

    setfilterString(filterParams);
    setSelected([]);
    setPage(1);
  };

  const all_selected = useMemo(() => {
    if (loading) return false;
    if (data.every((d) => selected.includes(d.id))) return true;
    return false;
  }, [loading, selected?.length, data?.length, page]);
  // console.log(selected.length, all_selected);
  return (
    <div>
      <ContentBlock>
        <TableFilterBox clearFilter={() => setFilter(initialFilterState)}>
          <div className={"w-100"}>
            <div className="col-12">
              <div className="row">
                <div className="col-2">
                  <TextInputModal
                    text="Prekė/ Nr."
                    name="product"
                    value={filter.product}
                    onChange={(e) =>
                      setFilter({ ...filter, product: e.target.value })
                    }
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        onFilter(filter);
                      }
                    }}
                  />
                </div>
                <div className="col-2">
                  <TextInputModal
                    text="Akcijos pavadinimas"
                    name="discount_title"
                    value={filter.discount_title}
                    onChange={(e) =>
                      setFilter({ ...filter, discount_title: e.target.value })
                    }
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        onFilter(filter);
                      }
                    }}
                  />
                </div>
                <div className="col-2">
                  <DataPickerInputModal
                    text="Akcijos laikotarpis nuo"
                    placeholder={"Pasirinkti"}
                    value={getDateFormat(filter.discount_date_from)}
                    onChange={(_, discount_date_from) => {
                      setFilter({
                        ...filter,
                        discount_date_from,
                      });
                    }}
                  />
                </div>
                <div className="col-2">
                  <DataPickerInputModal
                    text="Akcijos laikotarpis iki"
                    placeholder={"Pasirinkti"}
                    value={getDateFormat(filter.discount_date_to)}
                    onChange={(_, discount_date_to) => {
                      setFilter({
                        ...filter,
                        discount_date_to,
                      });
                    }}
                  />
                </div>
                {/* <div className="col-2">
                  <div className="form-group">
                    <label className="text-bold my-1">
                      Akcijos laikotarpis
                    </label>
                    <div>
                      <RangePickerComp
                        placeholder={["Pasirinkti", "Pasirinkti"]}
                        disabledDate={() => {}}
                        value={[
                          getDateFormat(filter.discount_date_from),
                          getDateFormat(filter.discount_date_to),
                        ]}
                        onChange={(_, args) => {
                          setFilter({
                            ...filter,
                            discount_date_from: args[0],
                            discount_date_to: args[1],
                          });
                        }}
                      />
                    </div>
                  </div>
                </div> */}
                <div className="col-2">
                  <SelectForm
                    placeholder="Prekių kategorija"
                    onlyPlaceholder="Pasirinkti"
                    value={filter.product_category}
                    options={options?.families || []}
                    onChange={(value) =>
                      onChange({ target: { value, name: "product_category" } })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </TableFilterBox>
      </ContentBlock>
      <div className="col-12 d-flex mt-2">
        {["admin", "marketing"].includes(user_role.identifier) && (
          <div className="mr-3">
            <DropdownButton
              text="Importuoti"
              actions={[
                {
                  label: "Importuoti iš .xlsx",
                  onFunction: () => setModal({ type: "submit_csv" }),
                },
              ]}
            />
          </div>
        )}
        <div className="mr-3">
          <DropdownButton
            text="Spausdinti"
            color="#EF3824"
            actions={[
              {
                label: "Pasirinkti",
                onFunction: () => {
                  if (selected.length == 0)
                    return antdMessageError("Nieko nepasirinkote");
                  setModal({
                    type: "print",
                    selected: selected.map((d) => ({
                      id: d,
                      discount_title:
                        data.find((e) => e.id == d)?.discount_title || "",
                    })),
                  });
                },
              },
              {
                label: "Visi",
                onFunction: () =>
                  setModal({
                    type: "print",
                    selected: data.map((d) => ({
                      id: d.id,
                      discount_title: d.discount_title,
                    })),
                  }),
              },
            ]}
          />
        </div>
        {["admin", "marketing"].includes(user_role.identifier) && (
          <>
            <div className="mr-3">
              <DropdownButton
                text="Pakeisti"
                color="#17A2B8"
                actions={[
                  {
                    label: "Akcijos galiojimo pradžią",
                    onFunction: () => {
                      if (selected.length == 0)
                        return antdMessageError("Nieko nepasirinkote");
                      setModal({
                        type: "discount_date_from",
                        selected,
                      });
                    },
                  },
                  {
                    label: "Akcijos galiojimo pabaigą",
                    onFunction: () => {
                      if (selected.length == 0)
                        return antdMessageError("Nieko nepasirinkote");
                      setModal({
                        type: "discount_date_to",
                        selected,
                      });
                    },
                  },
                  {
                    label: "Teisinį tekstą",
                    onFunction: () => {
                      if (selected.length == 0)
                        return antdMessageError("Nieko nepasirinkote");
                      setModal({
                        type: "legal_text",
                        selected,
                      });
                    },
                  },
                  {
                    label: "Pašalinti pasirinktus",
                    onFunction: () => {
                      if (selected.length == 0)
                        return antdMessageError("Nieko nepasirinkote");
                      setModal({
                        type: "mass_remove",
                        selected,
                      });
                    },
                  },
                ]}
              />
            </div>
            <div className="mr-3">
              <DropdownButton
                text="Parsisiųsti"
                color="#597d35"
                actions={[
                  {
                    label: "Kategorijos šablonas",
                    onFunction: () => {
                      exportDocument(
                        `/api/v1/posters/download_template`,
                        `kategorijos_sablonas.xlsx`,
                        "POST"
                      );
                    },
                  },
                  {
                    label: "Prekės šablonas",
                    onFunction: () => {
                      exportDocument(
                        `/api/v1/posters/download_template?type=product`,
                        `prekes_sablonas.xlsx`,
                        "POST"
                      );
                    },
                  },
                ]}
              />
            </div>
          </>
        )}
      </div>
      <ContentBlock extraClass={"mt-2"}>
        <ContentValidation loader={loading} array={data}>
          <Table
            ths={[
              <CheckBoxInput
                checked={all_selected}
                onChange={() => {
                  setSelected((prev) => {
                    let obj = [...prev];
                    const all_data = data.map((d) => d.id);
                    if (all_selected) {
                      return prev.filter((d) => !all_data.includes(d));
                    }
                    return [...new Set([...obj, ...all_data])];
                  });
                }}
              />,
              "Akcijos pavadinimas",
              "Prekių kategorija",
              "Prekės pavadinimas",
              "Pr. ženklas",
              "Laisvas lauk.",
              "Akcija nuo",
              "Akcija iki",
              "",
            ]}
          >
            {current == "Plakatai" &&
              data?.map((e) => (
                <Table.tr key={e.id}>
                  <Table.td className="p-left">
                    <CheckBoxInput
                      checked={selected.includes(e.id)}
                      onChange={() => {
                        setSelected((prev) => {
                          let obj = [...prev];
                          if (obj.includes(e.id)) {
                            return obj.filter((d) => d !== e.id);
                          }
                          obj.push(e.id);
                          return obj;
                        });
                      }}
                    />
                  </Table.td>
                  <Table.td>
                    <u
                      className="cursor-pointer"
                      onClick={() => setAction(e.id)}
                    >
                      {e.discount_title}
                    </u>
                  </Table.td>
                  <Table.td>{e.product_category}</Table.td>
                  <Table.td>
                    {e.product_title}
                    <br />
                    {e.product_code}
                  </Table.td>
                  <Table.td>{e.brand?.title}</Table.td>
                  <Table.td>
                    {e.free_text}
                    {/* && (
                    <Tooltip placement="top" title={e.free_text}>
                      {`${e.free_text.substr(0, 11)}...`}
                    </Tooltip>
                  )} */}
                  </Table.td>
                  <Table.td>{e.discount_date_from}</Table.td>
                  <Table.td>{e.discount_date_to}</Table.td>
                  <Table.td>
                    <Tooltip
                      className="mr-2"
                      placement="top"
                      title={"Spausdinti"}
                    >
                      <button
                        className="btn btn-sm btn-primary"
                        style={{
                          width: 30,
                          height: 30,
                        }}
                        onClick={() =>
                          setModal({
                            type: "print",
                            selected: [
                              { id: e.id, discount_title: e.discount_title },
                            ],
                          })
                        }
                      >
                        <AiFillPrinter className="text-white" />
                      </button>
                    </Tooltip>
                    {["admin", "marketing"].includes(user_role.identifier) && (
                      <Tooltip placement="top" title={"Plakato ištrinimas"}>
                        <ButtonTrash
                          onClick={() => {
                            if (!confirm("Ar tikrai norite ištrinti?")) return;
                            dispatch(removePoster(e.id));
                            setSelected((prev) =>
                              prev.filter((d) => d !== e.id)
                            );
                          }}
                        />
                      </Tooltip>
                    )}
                  </Table.td>
                </Table.tr>
              ))}
          </Table>
        </ContentValidation>
      </ContentBlock>
      <div className="mt-2">
        <SimplePagination
          setPagination={(pg) => setPage(pg)}
          pageCount={25}
          page={page}
          totals={totals}
        />
      </div>
    </div>
  );
}
