import React from "react";

const Td = ({ children, colspan, className, align_middle = true }) => (
  <td
    className={`${align_middle ? "align-middle" : ""} ${className || ""}`}
    colSpan={colspan || 1}
  >
    {children || ""}
  </td>
);
const Tr = ({ children, className, style }) => (
  <tr className={className || "align-middle tableColumn"} style={style || {}}>
    {children}
  </tr>
);

function TableUi({
  children,
  style = {},
  ths,
  className,
  without_body = false,
  table_small = true,
  total = 1
}) {
  return (
    <table
      className={`table ${table_small || ""} table-hover ${
        className || "mt-3"
      }`}
      style={style}
    >
      <thead>
        <tr className="text-muted tableColumn bg-white">
          {ths.map((title, idx) => (
            <th
              className={`text-black align-middle ${
                idx == 0 ? "p-left" : idx == title.lenght - 1 ? "p-right" : ""
              }`}
              key={idx}
            >
              {title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {total == 0 ? <RenderEmpty total_ths={ths.length} /> : children}
      </tbody>
    </table>
  );
}

const RenderEmpty = ({ total_ths }) => {
  return (
    <Tr>
      <Td colspan={total_ths}>
        <div className={"d-flex align-items-center justify-content-center"}>
          No data...
        </div>
      </Td>
    </Tr>
  );
};
TableUi.td = Td;
TableUi.tr = Tr;
export default TableUi;
