import React, { useEffect, useState } from "react";
import Container from "../components/Layout/Container";
import ContentBlock from "../components/Layout/ContentBlock";
import FilterMenu from "../components/Layout/FilterMenu";
import TableFilterBox from "../components/common/TableFilterBox";
import ContentValidation from "../components/common/ContentValidation";
import { IconEnergyLabel } from "../components/Layout/Icons";
import { useDispatch, useSelector } from "react-redux";
import SelectInput from "../components/common/SelectInput";
import TextInputModal from "../components/common/TextInputModal";
import {
  getEnergyLabels,
  getEnergyLabelsOptions,
} from "../redux/Actions/energy_labels_action";
import useModal from "../hooks/useModal";
import EnergyLabelsModalWrapper from "../components/energy_labels/EnergyLabelsModalWrapper";
import Pagination from "../components/Dashboard/items/Pagination";
import TableUi from "../components/common/TableUi";
import { handleKeyDown } from "../helpers/GlobalFunctions";
import EnergyLabelColumn from "../components/energy_labels/EnergyLabelColumn";

const filterInitial = {
  product_code: "",
  energy_class: undefined,
};

export default function EnergyLabelsPage({ match }) {
  const dispatch = useDispatch();
  const { totals, current, loading, data, options } = useSelector(
    (state) => state.custom
  );
  console.log("Main values: ", totals, current, loading, data);
  const [page, setPage] = useState(1);
  const [perCount, setPerPage] = useState(50);
  const [filters, setFilters] = useState(filterInitial);

  const startedPack = () => {
    dispatch(getEnergyLabels(page, perCount, onFilter()));
  };
  useEffect(() => {
    startedPack();
    console.count("FETCH 1");
    dispatch(getEnergyLabelsOptions());
  }, []);

  useEffect(() => {
    console.count("FETCH 2");
    if(page !== 1) return setPage(1)
    startedPack();
  }, [perCount, filters.energy_class]);

  useEffect(() => {
    console.count("FETCH 3");
    startedPack();
  }, [page]);

  const { modalOpt, setModalOpt, closeModal } = useModal();

  const onFilter = () => {
    const { product_code, energy_class } = filters;

    let search_string = "";
    if (product_code) {
      search_string += `&q[energy_products_sku_cont]=${product_code}`;
    }
    if (energy_class) {
      search_string += `&q[energy_class_eq]=${energy_class}`;
    }

    return search_string;
  };

  return (
    <Container
      location={{
        locationName: "Energijos efektyvumo etiketės",
        locationUrl: match.url,
      }}
    >
      <ContentBlock>
        <FilterMenu
          totals={totals}
          length={data?.length}
          text="energijos efektyvumo etikečių"
          iconComponent={
            <IconEnergyLabel
              style={{
                width: 32,
                height: 32,
                marginRight: "4px" 
              }}
            />
          }
          onChangeCountPage={setPerPage}
          countPage={perCount}
        />
      </ContentBlock>
      <ContentBlock containerClass="pt-3">
        <TableFilterBox
          add={false}
          // filter={true}
          onFilter={() => startedPack()}
          clearFilter={() => setFilters(filterInitial)}
          button1={
            <>
              {" "}
              <button
                type="button"
                className="btn btn-sm btn-primary float-left ml-3"
                onClick={() => setModalOpt("create")}
              >
                {"Nauja etiketė"}
              </button>
            </>
          }
          extraClass="pb-2"
        >
          <div className="col-3 pr-1 pl-3">
            <TextInputModal
              text="Prekės kodas"
              name="product_code"
              value={filters.product_code}
              onKeyDown={(e) => handleKeyDown(e, startedPack)}
              onChange={(e) => {
                const product_code = e.target.value;
                setFilters((prev) => ({ ...prev, product_code }));
              }}
            />
          </div>

          <div className="col-3 px-1">
            <SelectInput
              options={options?.energy_class_image}
              label="Energijos klasė"
              value={filters.energy_class || undefined}
              onChange={(energy_class) => {
                setFilters((prev) => ({
                  ...prev,
                  energy_class,
                }));
              }}
            />
          </div>
        </TableFilterBox>
        <div>
          <ContentValidation
            loader={loading}
            array={current == "EnergyLabels" ? data : []}
          >
            <div className="table-responsive">
              <Pagination
                page={page}
                totals={totals}
                setPagination={setPage}
                pageCount={perCount}
              />
            </div>
            <EnergyLabelsTable
              data={data}
              setModalOpt={setModalOpt}
              options={options}
            />
            <div className="table-responsive">
              <Pagination
                page={page}
                totals={totals}
                setPagination={setPage}
                pageCount={perCount}
              />
            </div>
          </ContentValidation>
        </div>
      </ContentBlock>
      {modalOpt?.type && (
        <EnergyLabelsModalWrapper
          modalOpt={modalOpt}
          closeModal={() => closeModal()}
          options={options}
        />
      )}
    </Container>
  );
}

const EnergyLabelsTable = ({ data, setModalOpt, options }) => {
  return (
    <div className="table-responsive">
      <TableUi
        className={"table-striped"}
        ths={[
          "Prekės kodas",
          "Energijos klasė",
          "Eprel nr.",
          "Duomenų lapas",
          "Etiketės nuotrauka",
        ]}
      >
        {data.map((label) => {
          return (
            <EnergyLabelColumn
              key={label.id}
              {...label}
              energy_class={
                options?.energy_class_image?.find(
                  (d) => d.value == label.energy_class
                )?.label || label.energy_class
              }
              setModalOpt={setModalOpt}
            />
          );
        })}
      </TableUi>
    </div>
  );
};
