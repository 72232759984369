import React, { useState } from "react";
import styled from "styled-components";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";

// export default function DropdownRounded() {
//   return (
//     <div>
//       <div
//         className="text-sm text-orange rounded-full px-2"
//         style={{
//           background: "#FFFFFF",
//           border: "1px solid #F29302",
//           borderRadius: 60,
//         }}
//       >
//         Veiksmai pažymietiems
//       </div>
//     </div>
//   );
// }

export default function DropdownRounded({ text, actions}) {
  const [active, setActive] = useState(false);
  return (
    <ButtonMassActionsStyled
      active={active}
      className="position-relative"
      onClick={() => setActive((prev) => !prev)}
    >
      <button className="btn btn-sm font-normal">
        <span>{text}</span>
        <span className="mx-3" />
        <span>{active ? <FaChevronUp /> : <FaChevronDown />}</span>
      </button>
      <AnimatePresence>
        {active && (
          <motion.div
            className="select_box pt-2"
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, origin: 1 }}
          >
            <ul className="">
              {actions.map((d) => (
                <li
                  key={d.label}
                  className="cursor-pointer py-2"
                  onClick={d.onFunction}
                >
                  {d.label}
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </ButtonMassActionsStyled>
  );
}

const ButtonMassActionsStyled = styled.div`
  .btn {
    background: ${(props) => !props.active ? "#FFFFFF" : "#F29302"};
    color: ${(props) => props.active ? "#FFFFFF" : "#F29302"};
    border: 1px solid #F29302;
    text-align: center;
    border-radius: 60px;
    position: relative;
    z-index: 6;
  }
  .select_box {
    top: 14px;
    position: absolute;
    width: 100%;
    background: #ffffff;
    z-index: 5;
    border: 1px solid #fe9c00;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    & ul {
      margin: 4px 8px 5px 8px;
    }
    & li {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 13px;
      color: #000000;
    }
  }
`;
