export const handleKeyDown = (e, func) => {
  if (e.key == "Enter") {
    return func();
  }
};

export const onChangeUseState = (e, setState) => {
  const { name, value } = e.target;
  return setState((prev) => ({
    ...prev,
    [name]: value,
  }));
};

export const onChangeUseState2 = (setState) => (e) => {
  const { name, value } = e.target;
  return setState((prev) => ({
    ...prev,
    [name]: value,
  }));
};

export const renderByColor = (mc_id) => {
  switch (mc_id) {
    case 2:
      return "#82ff85";
    case 3:
      return "#84fffe";
    case 4:
      return "#8181fc";
    case 5:
      return "#fd0018";
    case 6:
      return "#fe80c0";
    case 7:
      return "#fd0081";
    case 8:
      return "#82ff85";
    case 9:
      return "#84fffe";
    case 10:
      return "#8181fc";
    case 11:
      return "#fd0018";
    case 12:
      return "#fe80c0";
    case 13:
      return "#fd0081";
    case 14:
      return "#fefe87";
    case 15:
      return "#fefe87";
    default:
      return "#fff";
  }
};
