import React, { useState } from "react";
import RivileCategoryEach from "./RivileCategoryEach";
import { CheckBoxInput } from "../../common/Buttons";
import { FaPlus, FaMinus } from "react-icons/fa";

function TableColumnRivileService({
  item,
  index,
  updateShopCategories,
  array,
}) {
  const [CurrentOpen, setCurrentOpen] = useState([]);

  const handleToggle = (value) => {
    const currentIndex = CurrentOpen.indexOf(value);
    const newChecked = [...CurrentOpen];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCurrentOpen(newChecked);
  };
  const handleCheckboxChange = (item, changeValue) => {
    const value = changeValue == undefined ? !item.pvm25 : changeValue;
    let body = {
      pvm25: value,
    };
    updateShopCategories(item.id, body);

    if (item.children) {
      checkboxMassFunction(item.children, value);
      if (value) {
        const currentIndex = CurrentOpen.indexOf(value);
        const newChecked = [...CurrentOpen];
        if (currentIndex === -1) {
          newChecked.push(item.id);
          setCurrentOpen(newChecked);
        }
      }
    }
  };

  const checkboxMassFunction = (childrenArray, value) => {
    childrenArray.forEach((arrayItem) => {
      handleCheckboxChange(arrayItem, value);
    });
  };

  return (
    <React.Fragment key={index}>
      <tr className="align-middle tableColumn">
        <td className="align-middle">{item.id}</td>
        <td className="align-middle" style={{ cursor: "pointer" }}>
          <div onClick={() => handleToggle(item.id)}>
            {CurrentOpen.includes(item.id) ? (
              <FaMinus title="Uždaryti" className="mr-2 text-danger" />
            ) : (
              <FaPlus title="Atidaryti" className="mr-2 text-success" />
            )}
            {item.title}{" "}
            {item.children && (
              <span className="font-weight-bold">({item.children.length})</span>
            )}
          </div>
        </td>
        <td>
          <CheckBoxInput
            type="checkbox"
            color="#f7951e"
            checked={item.pvm25}
            onChange={() => handleCheckboxChange(item)}
          />
        </td>
      </tr>
      {CurrentOpen.includes(item.id) &&
        typeof item.children !== "undefined" &&
        item.children.length > 0 &&
        item.children.map((item, index) => {
          return (
            <RivileCategoryEach
              key={index}
              item={item}
              level={10}
              CurrentOpen={CurrentOpen}
              handleToggle={handleToggle}
              handleCheckboxChange={handleCheckboxChange}
            />
          );
        })}
    </React.Fragment>
  );
}

export default TableColumnRivileService;
