import Axios from "axios";
import {
  POST_DOCUMENT_PLAN,
  UPDATE_DOCUMENT_PLAN,
  DELETE_DOCUMENT_PLAN,
  LOADING_DOCUMENT_PLAN,
  CLEAR_ERRORS,
  GET_ERRORS,
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;


const success = () => {
  if (!document.getElementById("document-s")) return;
  document.getElementById("document-s").style.display = "block";
  setTimeout(
    () => (document.getElementById("document-s").style.display = "none"),
    5000
  );
  document.querySelector("#closeDocumentPlan").click();
};
const error = () => {
  if (!document.getElementById("document-e")) return;
  document.getElementById("document-e").style.display = "block";
  setTimeout(
    () => (document.getElementById("document-e").style.display = "none"),
    5000
  );
};

export const postDocumentPlan = (docId, CountryData) => (dispatch) => {
  Axios.post(`/api/v1/document_types/${docId}/document_plans`, CountryData)
    .then((res) => {
      dispatch({
        type: POST_DOCUMENT_PLAN,
        payload: res.data,
      });
      dispatch(clearErrors());
      success();
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const updateDocumentPlan = (docId, planId, CountryData) => (
  dispatch
) => {
  Axios.put(
    `/api/v1/document_types/${docId}/document_plans/${planId}`,
    CountryData
  )
    .then((res) => {
      dispatch({
        type: UPDATE_DOCUMENT_PLAN,
        payload: res.data,
      });
      dispatch(clearErrors());
      success();
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const deleteDocumentPlan = (docId, planId) => (dispatch) => {
  Axios.delete(`/api/v1/document_types/${docId}/document_plans/${planId}`)
    .then((res) => {
      dispatch({
        type: DELETE_DOCUMENT_PLAN,
        payload: res.data,
      });
      dispatch(clearErrors());
      success();
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const setCountryLoading = () => {
  return {
    type: LOADING_DOCUMENT_PLAN,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
