import Axios from "axios";
import {
  GET_PAYMENT_TYPES,
  PUT_PAYMENT_TYPES,
  LOADING_PAYMENT_TYPES,
  GET_ERRORS,
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;
import { success } from "./modalsFunctios";

export const getPaymentTypes = (page, pageCount, filter) => (dispatch) => {
  dispatch(setLoadingtickets());
  Axios.get(`/api/v1/payment_types?page=${page}&per_page=${pageCount}${filter}`)
    .then((res) => {
      dispatch({
        type: GET_PAYMENT_TYPES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const updatePaymentTypes = (id, body) => (dispatch) => {
  Axios.put(`/api/v1/payment_types/${id}`, body)
    .then((res) => {
      dispatch({
        type: PUT_PAYMENT_TYPES,
        payload: res.data,
      });
      success("paymentTypes-s", "modalClose");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setLoadingtickets = () => {
  return {
    type: LOADING_PAYMENT_TYPES,
  };
};
