import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { FlopppyButton } from "../../../common/ButtonWithLoading";
import { Input, DatePicker, message } from "antd";
import moment from "moment";
import { CheckBoxInput, SelectForm } from "../../../common/Buttons";
// import { postOrderRow } from "../../../../../redux/Actions/orderAction";
import { getDateFormat } from "../../../../helpers/Utils";

function ServiceSupplier({
  supplySupplier,
  supplyItem,
  order_rows,
  orderId,
  postOrderRow,
  mode,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showExternalNumber, setshowExternalNumber] = useState(false);
  const [externalNumber, setExternalNumber] = useState("");

  const [variables, setVariables] = useState({
    date: "",
    amount: 1,
    product_code: "",
    supplier_id: undefined,
    supplier_code_boolean: false,
    supplier_code: "",
    external_number: "",
    supplier_selected: undefined,
    supplierCode: false,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (supplyItem.length > 0) {
      handleSupplyBlock("supplier_selected", supplyItem[0].value);
    }
  }, [supplyItem]);

  const createNewOrderRow = () => {
    const {
      date,
      amount,
      product_code,
      supplier_id,
      supplier_code_boolean,
      supplier_code,
      external_number,
      supplier_selected,
    } = variables;
    let errors = {};
    if (!supplier_id || !supplier_selected) {
      errors.service = "Pasirinkite servisą.";
    }
    if (!date) {
      errors.date = "Pasirinkite datą.";
    }
    if (Object.entries(errors).length > 0) {
      return setErrors(errors);
    }
    setLoading(true);
    const resVariables = {
      date,
      amount,
      product_code,
      supplier_id,
      external_number,
    };
    if (supplier_code_boolean) {
      resVariables.supplier_code = supplier_code;
    }
    dispatch(postOrderRow(resVariables, orderId, supplier_selected)).then(
      (res) => {
        if (res.success) {
          setVariables({
            date: "",
            amount: 1,
            product_code: "",
            supplier_id: undefined,
            supplier_code_boolean: false,
            supplier_code: "",
            external_number: "",
            supplier_selected: undefined,
            supplierCode: false,
          });
          setLoading(false);
          setshowExternalNumber(false);
          setErrors({});
        } else {
          setLoading(false);
          setErrors({ error: "Įvyko klaida" });
        }
      }
    );
  };
  const handleSupplyBlock = (name, value) => {
    setVariables({
      ...variables,
      [name]: value,
    });
  };

  const {
    date,
    amount,
    product_code,
    supplierCode,
    supplier_id,
    supplier_code_boolean,
    supplier_selected,
    supplier_code,
    external_number,
  } = variables;

  const unCheckExtraNumber = () => {
    setshowExternalNumber(!showExternalNumber);
    setVariables({ ...variables, external_number: "" });
  };

  return (
    <div className="col-12 bg-silver">
      <div className="d-flex pt-3">
        {supplyItem.length > 1 && (
          <span className="pr-1" style={{ width: "25%" }}>
            <SelectForm
              options={supplyItem}
              onlyPlaceholder="Prekės"
              name="supplier_selected"
              value={supplier_selected}
              dropdownMatchSelectWidth={false}
              onChange={(e) => handleSupplyBlock("supplier_selected", e)}
              error={errors.service}
            />
          </span>
        )}
        <span
          className="pr-1"
          style={{ width: supplyItem.length > 1 ? "25%" : "50%" }}
        >
          <SelectForm
            options={supplySupplier}
            onlyPlaceholder="Servisai"
            name="supplier_id"
            value={supplier_id}
            dropdownMatchSelectWidth={false}
            onChange={(e) => handleSupplyBlock("supplier_id", e)}
            error={errors.service}
          />
        </span>
        <span className="pl-1 pr-1">
          <CheckBoxInput
            choised="sending_doc"
            color="#f7951e"
            // text="Papildomas numeris"
            checked={showExternalNumber}
            onChange={unCheckExtraNumber}
          />
        </span>
        {showExternalNumber && (
          <span className="pl-1" style={{ width: "50%" }}>
            <Input
              placeholder="Išorinis numeris"
              name="external_number"
              value={external_number}
              onChange={(e) =>
                handleSupplyBlock("external_number", e.target.value)
              }
            />
          </span>
        )}
        <span className="pl-1" style={{ width: "50%" }}>
          <DatePicker
            className={errors.date ? "error_border" : ""}
            style={{ width: "100%" }}
            placeholder="Data"
            disabledDate={(d) =>
              !d || d.isBefore(moment().format("YYYY-MM-DD"))
            }
            value={getDateFormat(date)}
            onChange={(e, arg) => handleSupplyBlock("date", arg)}
            error={errors.date}
          />
          {errors.date && <div className="text-danger">{errors.date}</div>}
        </span>
      </div>
      <div className="d-flex justify-content-between">
        <span style={{ width: "20%" }}>
          {errors.error && <div className="text-danger">{errors.error}</div>}
        </span>
        <span style={{ width: "25%" }}>
          {supplier_code_boolean && (
            <Input
              placeholder="ID"
              value={supplier_code}
              onChange={(e) =>
                handleSupplyBlock("supplier_code", e.target.value)
              }
            />
          )}
        </span>
        <span style={{ width: "5%" }}></span>
        <span></span>
        <span style={{ width: "25%" }}>
          {supplierCode && (
            <Input
              placeholder="ID"
              value={product_code}
              onChange={(e) =>
                handleSupplyBlock("product_code", e.target.value)
              }
            />
          )}
        </span>
        <span className="pl-1 pt-2 pb-3 text-right" style={{ width: "25%" }}>
          <FlopppyButton
            loading={loading}
            onClick={() => createNewOrderRow()}
          />
        </span>
      </div>
    </div>
  );
}

export default ServiceSupplier;
