import { useEffect, useState, useMemo } from "react";
export default function useChecked(initial = []) {
  const [checked, setChecked] = useState(initial || []);

  useEffect(() => {
    if (initial) setChecked(initial);
  }, [initial.length]);

  const onChangeChecked = (id) => {
    setChecked((prev) => {
      let obj = [...prev];
      if (obj.includes(id)) {
        return obj.filter((value) => value !== id);
      } else {
        return [...obj, id];
      }
    });
  };

  const checkedAll = (val) => {
    if (!val) return false
    if (val.length !== checked.length) return false
    if (val.length == 0) return false
    return val.every((d) =>
        checked.includes(d.id)
    );
};
  const checkedIncluded = (id) => checked.includes(id);
  return { checked, setChecked, onChangeChecked, checkedIncluded, checkedAll };
}
