import React from "react";
import Container from "../Layout/Container";
import { connect } from "react-redux";
import { getRepricings } from "../../redux/Actions/repricingAction";
import { getStoreItems } from "../../redux/Actions/storeAction";
import ContentBlock from "../Layout/ContentBlock";
import TableColumnRepricing from "./Items/TableColumnRepricing";
import FilterMenu from "../Layout/FilterMenu";
import TableFilterBox from "../common/TableFilterBox";
import ContentValidation from "../common/ContentValidation";
import InputText from "../orders/accepted_orders/InputFilter";
import Pagination from "../Dashboard/items/Pagination";
import { SelectForm } from "../common/Buttons";
import RepricingModal from "./RepricingModal";
import { MdStore } from "react-icons/md";
import RangePickerComp from "../common/RangePickerComp";
import moment from "moment";

const initialFilterState = {
  product: "",
  start: "",
  end: "",
  comment: "",
  type: "",
  store: "",
  operation_number: "",
};

let filterSearchTimeout;

class Repricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceControl: true,
      showModal: false,
      modalInfo: { id: 0, number: 0 },

      bodyValue: "",
      page: localStorage.getItem("repricings.page") || 1,
      pageCount: 25,
      paramsFilter:
        JSON.parse(localStorage.getItem("repricings.paramsfilter")) || "",
      filter:
        JSON.parse(localStorage.getItem("repricings.filter")) ||
        initialFilterState,
      selected: [],
      selectedAll: false,
      refreshing: false,
    };
    this.available_edit = props.auth.user_role.identifier !== "hosts";
  }

  componentDidMount() {
    this.reloadData(this.state.paramsFilter);
    this.loadData();
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.reloadData(this.state.paramsFilter);
    }
  }

  loadData = () => {
    this.props.getStoreItems(1, 1000, "");
  };

  reloadData = (paramsFilter) => {
    this.props.getRepricings(
      this.state.page,
      this.state.pageCount,
      paramsFilter
    );
  };

  setPagination = (page) => {
    localStorage.setItem("repricings.page", page);
    this.setState({
      page,
    });
  };

  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  delayOnFilter = () => {
    if (this.props.loading) {
      return setTimeout(() => {
        return this.delayOnFilter();
      }, 1000);
    }
    this.onFilter();
  };

  onFilter = () => {
    if (this.props.loading) {
      return this.delayOnFilter();
    }


    let filterParams = [];
    if (this.state.filter.product.length > 0) {
      filterParams.push(
        `&q[repricings_product_title_or_repricings_product_identifier_i_cont]=${this.state.filter.product}`
      );
    }
    if (this.state.filter.comment.length > 0) {
      filterParams.push(
        `&q[repricings_note_i_cont]=${this.state.filter.comment}`
      );
    }
    if (this.state.filter.type == "moved") {
      filterParams.push(`&q[moved_at_not_null]=true`);
    }
    if (this.state.filter.start.length > 0) {
      filterParams.push(`&q[operation_date_gteq]=${this.state.filter.start}`);
    }
    if (this.state.filter.operation_number) {
      filterParams.push(`&q[operation_number_eq]=${this.state.filter.operation_number}`);
    }
    if (this.state.filter.end.length > 0) {
      filterParams.push(`&q[operation_date_lteq]=${this.state.filter.end}`);
    }
    if (this.state.filter.store != "") {
      filterParams.push(`&q[store_id_eq]=${this.state.filter.store}`);
    }
    localStorage.setItem(
      "repricings.filter",
      JSON.stringify(this.state.filter)
    );
    localStorage.setItem(
      "repricings.paramsfilter",
      JSON.stringify(filterParams.join(""))
    );
    localStorage.setItem("repricings.page", 1);
    this.setState(
      {
        paramsFilter: filterParams.join(""),
        page: 1,
      },
      () => this.reloadData(filterParams.join(""))
    );
  };

  filterChange = (e) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [e.target.name]: e.target.value,
      },
    });
    clearTimeout(filterSearchTimeout);
    filterSearchTimeout = setTimeout(() => {
      this.onFilter();
    }, 500);
  };

  handleKeyDown = (e) => {
    if (e.key == "Enter") {
      this.onFilter();
    }
  };

  filterDateChange = (args) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          start: args[0],
          end: args[1],
        },
      },
      () => this.onFilter()
    );
  };

  getDateFormat = (date) => {
    var d = moment(date);
    return date && d.isValid() ? d : "";
  };

  clearFilter = () => {
    this.setState(
      {
        filter: initialFilterState,
      },
      () => this.onFilter()
    );
  };

  handleToggle = (value) => {
    const currentIndex = this.state.selected.indexOf(value);
    let newChecked = [...this.state.selected];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      selected: newChecked,
      selectedAll: false,
    });
  };

  handleCheckboxAll() {
    const myData = this.props.repricings;
    const newChecked = [...this.state.selected];
    let newArray = [];
    if (myData.length === newChecked.length) {
      this.setState({
        selectedAll: false,
        selected: [],
      });
    } else {
      myData.forEach((item) => newArray.push(item.id));
      this.setState({
        selectedAll: true,
        selected: newArray,
      });
    }
  }

  printSelected = (e, type, id = false) => {
    e.preventDefault();
    let ids = [];
    if (type === "selected") {
      if (this.state.selected.length > 0) {
        ids = this.state.selected;
      }
    } else {
      ids = this.props.repricings.map((i) => i.id);
    }
    if (id !== false) {
      ids = [id];
    }
    if (ids.length > 0) {
      let data = {
        repricing: {
          ids: ids,
        },
      };
      fetch("/api/v1/repricings/print_labels", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((response) => response.blob())
        .then((response) => {
          const fileURL = URL.createObjectURL(response);
          const pdfWindow = window.open();
          pdfWindow.location.href = fileURL;
        });
    }
  };



  onView = (action, id) => {
    this.props.history.push(`/goods/repricings/${id}`);
  };

  getStoreName = () => {
    let user_store_id = this.props.user.store_id;
    if (this.props.stores && user_store_id) {
      let store = this.props.stores.find((s) => s.id === user_store_id);
      if (store) {
        return "Padalinys: " + store.name;
      }
    }
    return "Padalinys nerastas";
  };

  getStoreNameById = (id) => {
    if (id) {
      let store = this.props.stores.find((s) => s.id === id);
      if (store) {
        return store.name;
      }
    }
    return "-";
  };

  typeFilterClass = (type) => {
    if (type === this.state.filter.type) {
      return " active";
    }
    return "";
  };

  typeChange = (e) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [e.name]: e.value,
        },
      },
      () => this.onFilter()
    );
  };

  refreshRepricings = () => {
    this.setState({
      refreshing: true,
    });
    fetch("/api/v1/repricings/refresh_repricings", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState(
          {
            refreshing: false,
          },
          this.onFilter()
        );
      })
      .catch((error) => {
        this.setState(
          {
            refreshing: false,
          },
          this.onFilter()
        );
      });
  };

  render() {
    const requiredItem = this.state.requiredId;
    return (
      <Container
        location={{
          locationName: "Perkainavimai",
          locationUrl: this.props.match.url,
        }}
      >
        <ContentBlock>
          <FilterMenu
            length={`${this.props.totals || 0} / ${
              this.props.repricings.length || 0
            }`}
            onChangeCountPage={this.setPageCount}
            countPage={this.state.pageCount}
            text="perkainavimų"
            text2="Perkainavimų"
          />
        </ContentBlock>
        <ContentBlock>
          <TableFilterBox
            filter={false}
            onFilter={this.onFilter}
            clearFilter={this.clearFilter}
          >
            <div className="d-flex w-100">
              <div className="px-2" style={{ width: "100%" }}>
                <InputText
                  width={"0.75rem"}
                  number="1"
                  name="product"
                  label="Prekė"
                  placeholder="Kodas arba pavadinimas"
                  onKeyDown={this.handleKeyDown}
                  value={this.state.filter.product}
                  onChange={(e) => this.filterChange(e)}
                />
              </div>{" "}
              <div className="px-2" style={{ width: "100%" }}>
                <InputText
                  width={"0.75rem"}
                  number="1"
                  name="operation_number"
                  label="Operacijos nr."
                  placeholder="Operacijos nr."
                  onKeyDown={this.handleKeyDown}
                  value={this.state.filter.operation_number}
                  onChange={(e) => this.filterChange(e)}
                />
              </div>{" "}
              <div
                className="px-2 small-label"
                style={{ width: "100%", maxWidth: "290px" }}
              >
                <label className="editLabelForm">Dokumento data</label>
                <div>
                  <RangePickerComp
                    placeholder={["Pradžios laikas", "Pabaigos laikas"]}
                    value={[
                      this.getDateFormat(this.state.filter.start),
                      this.getDateFormat(this.state.filter.end),
                    ]}
                    onChange={(_, args) => this.filterDateChange(args)}
                    disabledDate={(d) => !d}
                  />
                </div>
              </div>{" "}
              <div className="px-2" style={{ width: "100%" }}>
                <InputText
                  width={"0.75rem"}
                  number="1"
                  name="comment"
                  placeholder="Komentaras"
                  onKeyDown={this.handleKeyDown}
                  value={this.state.filter.comment}
                  onChange={(e) => this.filterChange(e)}
                />
              </div>{" "}
              <div className="px-2">
                <div className="form-group">
                  <div>
                    <label className={"editLabelForm"}>Statusas</label>
                  </div>
                  <div className="d-flex">
                    <button
                      className={
                        "btn btn-family-filter" + this.typeFilterClass("all")
                      }
                      onClick={() =>
                        this.typeChange({ name: "type", value: "all" })
                      }
                    >
                      Visi
                    </button>
                    <button
                      className={
                        "btn btn-family-filter" + this.typeFilterClass("moved")
                      }
                      onClick={() =>
                        this.typeChange({ name: "type", value: "moved" })
                      }
                    >
                      Perkelta
                    </button>
                  </div>
                </div>
              </div>{" "}
              {this.props.user.user_role.identifier === "admin" && (
                <div className="px-2" style={{ minWidth: "200px" }}>
                  <SelectForm
                    placeholder="Padalinys"
                    onlyPlaceholder="Pasirinkti"
                    options={this.props.stores.map((n) => ({
                      value: n.id,
                      label: n.name,
                    }))}
                    value={
                      this.state.filter.store ? this.state.filter.store : []
                    }
                    onChange={(e) =>
                      this.filterChange({ target: { name: "store", value: e } })
                    }
                  />
                </div>
              )}
            </div>
          </TableFilterBox>
        </ContentBlock>
        <div className="row m-0">
          <div className="col-6 my-3">
            {/* <div className="dropdown dropdown-repricing d-inline-block mr-3">
                <button className="btn btn-danger dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style = {{fontSize: '0.8rem'}}>
                  Spausdinti
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a className="dropdown-item" href="#" onClick={(e) => this.printSelected(e, "selected")}>Pažymėtus</a>
                  <a className="dropdown-item" href="#" onClick={(e) => this.printSelected(e, "pricetag")}>Kainoženklius</a>
                  <a className="dropdown-item" href="#" onClick={(e) => this.printSelected(e, "marked")}>Markiruojamas</a>
                  <a className="dropdown-item" href="#" onClick={(e) => this.printSelected(e, "printed")}>Nemarkiruojamas</a>
                  <a className="dropdown-item" href="#" onClick={(e) => this.printSelected(e, "all")}>Visus</a>
                </div>
            </div> */}
            {this.props.user.user_role.identifier !== "admin" && (
              <div className="d-inline-block repricing-store">
                <MdStore
                  style={{
                    width: 30,
                    height: 25,
                    paddingRight: 5,
                  }}
                />
                {this.getStoreName()}
              </div>
            )}
          </div>
          <div className="col-6 my-3 text-right">
            {this.props.last_updated && (
              <span className="pr-3">
                Paskutinis atnaujinimas:{" "}
                {moment(this.props.last_updated).format("YYYY-MM-DD HH:mm")}
              </span>
            )}
            <button
              className="btn btn-primary"
              type="button"
              style={{ fontSize: "0.8rem" }}
              onClick={this.refreshRepricings}
              disabled={this.state.refreshing}
            >
              Gauti naujausius
            </button>
          </div>
        </div>
        <ContentBlock>
          <div>
            <ContentValidation
              loader={this.props.loading || this.state.refreshing}
              array={this.props.repricings}
              alertId_s="repricingsAlert-s"
              alertId_e="repricingsAlert-e"
            >
              <div className="table-responsive" style={{ overflow: "hidden" }}>
                <table className="table mt-3">
                  <thead>
                    <tr className="text-muted">
                      {/* <th className="text-black" style={{ width: "50px" }}><Checkbox onChange={() => this.handleCheckboxAll()} checked={this.state.selectedAll} /></th> */}
                      <th className="text-black" style={{ width: "50px" }}></th>
                      <th className="text-black">Data</th>
                      <th className="text-black">Operacijos nr.</th>
                      <th className="text-black">Dok. nr.</th>
                      <th className="text-black">Dok. tipas</th>
                      <th className="text-black">Komentaras</th>
                      <th className="text-black">Padalinys</th>
                      <th className="text-black">Perkelta</th>
                      <th className="text-black"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.repricings &&
                      this.props.repricings.map((item, index) => {
                        return (
                          <TableColumnRepricing
                            key={index}
                            index={index}
                            item={item}
                            onPrint={this.printSelected}
                            onView={this.onView}
                            store={this.getStoreNameById(item.store_id)}
                          />
                        );
                      })}
                  </tbody>
                </table>
                <Pagination
                  setPagination={this.setPagination}
                  page={this.state.page}
                  totals={this.props.totals}
                  pageCount={this.state.pageCount}
                />
              </div>
            </ContentValidation>
          </div>
        </ContentBlock>
        <RepricingModal
          showModal={this.state.showModal}
          modalInfo={this.state.modalInfo}
          closeModal={this.openModal}
        />
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  repricings: state.repricing.repricings,
  last_updated: state.repricing.last_updated,
  totals: state.repricing.totals,
  loading: state.repricing.loading_repricings,
  errors: state.errors,
  auth: state.auth.user,
  stores: state.store.storeItems,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getRepricings,
  getStoreItems,
})(Repricing);
