import React, { useState, useEffect } from "react";
import { SelectForm } from "../../common/Buttons";
import EditAdditionalItem from "./EditAdditionalItem";
import { DatePicker } from "antd";
import moment from "moment";

import TextInputModal from "../../common/TextInputModal";
import { connect } from "react-redux";

function EditAdditional(props) {
  const [DocumentPlanFilter, setDocumentPlanFilter] = useState([]);

  useEffect(() => {
    const Type = props.data.document_type_id;
    let filterPlan =
      Type === undefined
        ? []
        : props.documentPlan.filter((item) => item.type === Type);
    setDocumentPlanFilter(filterPlan);
  }, [props.data.document_type_id]);

  useEffect(() => {
    handleItems();
  }, [props.data.contract_items]);

  useEffect(() => {
    if (props.data.document_plan_id == undefined) {
      return;
    }
    const documentPlanId = props.documentPlan.findIndex(
      (item) => item.value == props.data.document_plan_id
    );

    let variables = {};
    if (documentPlanId !== -1) {
      variables.cost = props.documentPlan[documentPlanId].cost;
      variables.percentage = props.documentPlan[documentPlanId].percentage;
    } else {
      variables.cost = 0;
      variables.percentage = 0;
    }

    let newArray = [];
    props.data.contract_items.forEach((item) => {
      if (item.created_at === null) {
        newArray.push({
          ...item,
          item_price: props.topercentage(
            variables.percentage,
            item.product_price
          ),
          item_pricePerc: variables.percentage,
        });
      } else {
        newArray.push({
          ...item,
        });
      }
    });
    props.setContractItems(newArray);
  }, [props.data.document_plan_id]);

  const handleItems = () => {
    // const randomId = Math.floor(Math.random() * 100);
    if (props.data.contract_items.length == 0) {
      let obj = {
        id: null,
        additional_data: [],
        category: "",
        id_number: "",
        item_discount: 0,
        item_price: 0,
        item_pricePerc: props.data.charge,
        item_total: 0,
        manufacturer: "",
        model: "",
        product_price: 0,
        created_at: null,
      };
      props.addContractItem(obj);
    }
  };

  const warrantlyOption = [
    {
      label: "1 metai",
      value: 1,
    },
    {
      label: "2 metai",
      value: 2,
    },
    {
      label: "3 metai",
      value: 3,
    },
    {
      label: "4 metai",
      value: 4,
    },
    {
      label: "5 metai",
      value: 5,
    },
    {
      label: "Garantijos nėra",
      value: 0,
    },
  ];

  const meaningsOptions = [
    {
      value: 0,
      label: "-- Pasirinkite --",
    },
    {
      value: 1,
      label: "IMEI",
    },
    {
      value: 2,
      label: "Serijinis numeris",
    },
    {
      value: 3,
      label: "Kita...",
    },
  ];

  const inputOnFocus = () => {
    if (props.data.phone.length == 0) {
      const variables = {
        target: {
          name: "phone",
          value: "+3706",
        },
      };
      props.onChangeContract(variables);
    }
  };
  const editable =
    !["admin", "accounting"].includes(props.auth.user_role?.identifier) &&
    props.data.editable;
  return (
    <>
      <div
        className="border"
        style={{ borderRadius: "6px", backgroundColor: "#ffffff" }}
      >
        <div className="p-2">
          <div className="text-bold my-1">
            Pirkimo data <span className="orange_color">*</span>
          </div>
          <DatePicker
            style={{ width: "100%" }}
            // value={moment()}
            placeholder="Įvesti"
            disabledDate={(d) =>
              d.isBefore(moment().subtract(40, "days")) ||
              d.isAfter(moment().format("YYYY-MM-DD"))
            }
            value={moment(props.data.buy_date)}
            onChange={(e, arg) =>
              props.onChangeContract(arg == "" ? moment() : arg, "buy_date")
            }
            // onChange={(e, arg) => props.onChangeContract(arg, "buy_date")}
          />

          <TextInputModal
            required="true"
            text="Klientas"
            placeholder="Įvesti"
            style={
              props.data.customer.length == 0 ? { border: "1px solid red" } : {}
            }
            name="customer"
            value={props.data.customer}
            disabled={editable}
            onChange={(e) => props.onChangeContract(e)}
          />
          <TextInputModal
            required="true"
            text="El. paštas"
            placeholder="Įvesti"
            style={
              props.data.email.length == 0 ? { border: "1px solid red" } : {}
            }
            name="email"
            value={props.data.email}
            disabled={editable}
            onChange={(e) => props.onChangeContract(e)}
          />
          <TextInputModal
            required="true"
            text="Telefonas"
            placeholder="Įvesti"
            onFocus={() => inputOnFocus()}
            name="phone"
            style={
              props.data.phone.length === 12 ? {} : { border: "1px solid red" }
            }
            value={props.data.phone}
            disabled={editable}
            // onChange={(e) => setPhone(e.target.value)}
            onChange={(e) => props.onChangeContract(e)}
          />

          <TextInputModal
            required="true"
            text="Pirk. Dok. Nr."
            placeholder="Įvesti"
            style={
              props.data.invoice_number.length == 0
                ? { border: "1px solid red" }
                : {}
            }
            name="invoice_number"
            value={props.data.invoice_number}
            disabled={editable}
            // onChange={(e) => setSFnumber(e.target.value)}
            onChange={(e) => props.onChangeContract(e)}
          />

          <div className="text-bold my-1">
            Tipas: <span className="orange_color">*</span>
          </div>
          <SelectForm
            onlyPlaceholder="Pasirinkti"
            options={props.documentType}
            value={props.data.document_type_id}
            style={
              props.data.document_type_id.length == 0
                ? { border: "1px solid red", width: "100%" }
                : { width: "100%" }
            }
            onChange={(e) => props.onChangeContract(e, "document_type_id")}
            disabled={editable}
            // onChange={(e) => setType(e)}
          />

          <div className="text-bold my-1">
            Planas: <span className="orange_color">*</span>
          </div>
          <SelectForm
            // mode="tags"
            onlyPlaceholder="Pasirinkti"
            options={DocumentPlanFilter}
            value={props.data.document_plan_id}
            onChange={(e) => props.onChangeContract(e, "document_plan_id")}
            disabled={editable}
            // onChange={(e) => setPrice(e)}
            // error={
            //   props.errors.document_plan &&
            //   props.errors.document_plan.map((item) => `${item} `)
            // }
          />
          <TextInputModal
            required="true"
            type="number"
            text="Mokestis"
            style={
              props.data.price.length === 0 ? { border: "1px solid green" } : {}
            }
            placeholder="Įvesti"
            value={props.data.price}
            name="price"
            onChange={(e) => props.onChangeContract(e)}
            disabled={editable}
          />

          <div className="d-flex justify-content-between">
          </div>
        </div>
        <div className="p-2">
          {props.data.contract_items.map((item, index) => (
            <EditAdditionalItem
              key={index}
              index={index}
              data={item}
              getNum={props.getNum}
              meaningsOptions={meaningsOptions}
              onChangeContract={props.onChangeContract}
              onChangeContractItem={props.onChangeContractItem}
              addAdditionalItem={props.addAdditionalItem}
              changeAdditionalItem={props.changeAdditionalItem}
              removeAdditionalItem={props.removeAdditionalItem}
              deleteContractItem={props.deleteContractItem}
              editable={editable}
              warrantlyOption={warrantlyOption}
              warrantlyValue={props.data.warranty}
              document_type_id={props.data.document_type_id}
              document_plan_id={props.data.document_plan_id}
              errors={props.errors}
            />
          ))}
        </div>
        {props.errors && (
          <div className="col-12">
            {props.errors?.limitation_products && (
              <div className="text-danger">
                {props.errors.limitation_products}
              </div>
            )}
            {props.errors?.limitation_shop_categories && (
              <div className="text-danger">
                {props.errors.limitation_shop_categories}
              </div>
            )}
            {props.errors?.limitation_brands && (
              <div className="text-danger">
                {props.errors.limitation_brands}
              </div>
            )}
          </div>
        )}

        <div className="p-2 d-flex justify-content-between">
          <div className="d-flex align-items-center text-bold">
            Galutinė suma: {props.data.total} &#8364;
          </div>
          <button
            className="btn btn-primary btn-sm "
            style={{ fontSize: "12px", width: "140px" }}
            onClick={() => props.onSubmitHandler()}
          >
            Atnaujinti
          </button>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps, {})(EditAdditional);
