const initialState = {
  totals: 0,
  current: "",
  loading: false,
  data: [],
  options: {},
  show_data: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "STOP_LOADING_CUSTOM":
      return {
        ...state,
        loading: false,
      };
    case "LOADING_CUSTOM":
      return {
        ...state,
        loading: true,
        data: [],
      };
    case "LOADING_ONLY_CUSTOM":
      return {
        ...state,
        loading: true,
      };
    case "SET_OPTIONS":
      return {
        ...state,
        options: action.payload,
      };
    case "GET_CUSTOMS":
      return {
        ...state,
        data: action.payload.data,
        totals: action.payload.totals || 0,
        current: action.payload.current || "",
        loading: false,
      };
    case "GET_CUSTOM":
      console.log('GET_CUSTOM')
      return {
        ...state,
        show_data: action.payload,
        loading: false,
      };
    case "POST_CUSTOM":
      let updateData = [...state.data];
      updateData.unshift(action.payload);
      return {
        ...state,
        data: updateData,
        totals: state.totals + 1,
      };
    case "UPDATE_CUSTOM":
      let getData = [...state.data];
      const updateElementId = (element) => element.id === action.payload.id;
      const findIndex = getData.findIndex(updateElementId);
      console.log(action.payload)
      getData[findIndex] = action.payload;
      return {
        ...state,
        data: getData,
      };
    case "REMOVE_CUSTOM":
      return {
        ...state,
        data: [...state.data].filter((e) => e.id !== action.payload),
        loading: false,
        totals: state.totals - 1,
      };
    default:
      return state;
  }
}
