import React, { useEffect } from "react";

function useOutsideAlerter(ref, onAction) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      // console.log(event.target.text);
      if (ref.current && !ref.current.contains(event.target)) {
        if (!["Cancel", "OK"].includes(event.target.innerText)) {
          onAction();
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
export default useOutsideAlerter;
