import React from "react";
import Container from "../Layout/Container";
import { connect } from "react-redux";
import {
  getDescription
} from "../../redux/Actions/descriptionAction";
import InputText from "../orders/accepted_orders/InputFilter";
import TextAreaFieldGroup from "../common/TextAreaFieldGroup";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Spinner from "../common/Spinner";
import { MdInfo } from "react-icons/md";
import TextInputModal from "../common/TextInputModal";

class Description extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyValue: "",
      printing: false,
      p: (new URLSearchParams(window.location.search)).get("p") ?? 1,
      store_id: (new URLSearchParams(window.location.search)).get("store_id") ?? null
    };
    this.available_edit = props.auth.user_role.identifier !== "hosts";
  }
  
  componentDidMount() {
    this.reloadData();
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.reloadData();
    }
  }

  reloadData = () => {
    this.props.getDescription(this.props.match.params.action, this.state.store_id);
  };

  print = () => {
    let data = {
      description : {
        ids: [this.props.description.id]
      }
    };
    fetch(`/api/v1/descriptions/combined_descriptions?store_id=${this.state.store_id}`,
    { 
        method: "POST",
        headers: { "Content-Type": "application/json"},
        body: JSON.stringify(data)
    })
    .then(response => response.blob())
    .then(response => {
      const fileURL = URL.createObjectURL(response);
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
    });
  };

  loadImage = () => {
    if (this.props.description.id) {
      let data = {
        description : {
          type: "image"
        }
      };
      fetch(`/api/v1/descriptions/${this.props.description.id}/pdf_description?store_id=${this.state.store_id}`,
      { 
          method: "POST",
          headers: { "Content-Type": "application/json"},
          body: JSON.stringify(data)
      })
      .then(response => {
        if(!response.ok) throw new Error(response.status);
        else return response.blob();
      })
      .then(response => {
        if (response.size > 80){
          let objectURL = URL.createObjectURL(response);
          let myImage = new Image();
          myImage.src = objectURL;
          document.getElementById('description-image').appendChild(myImage);
          document.getElementById('description-image-container').style.display = "block";
        }
      })
      .catch((error) => {
        console.log('error: ' + error);
      });
    }
  };

  getFormatName = () => {
    if (this.props.description.family && this.props.description.family.format_id) {
      let format = this.props.description.family.print_formats.find(i => i.id == this.props.description.family.format_id);
      if (format) {
        return format.print_size;
      }
    }
    return "-";
  };

  render() {
    return (
      <Container
        location={{
          locationName: this.props.description.title,
          locationUrl: this.props.match.url,
        }}
      >
        <div className = "container-fluid">
        <div className={"row " + (this.props.loading ? "d-none" : "")}>
        <div className = "col-md-8">
          <div className = "bg-white p-3">
            <div className = "row">
              <div className = "col-md-4">
                <div className = "description-image-container">
                <div className = "description-image" style = {{backgroundImage: `url("${this.props.description.image_url}")`}}></div>
                </div>
              </div>
              <div className = "col-md-8">
                <div className = "row small-label">
                  <div className = "col-md-6 mb-2">
                    <TextInputModal
                      style={{fontSize: `14px`, color: `#000000` }}
                      number="1"
                      disabled={true}
                      text="Prekės kodas"
                      value={this.props.description.identifier}
                    />
                  </div> 
                  <div className = "col-md-6 mb-2"> 
                    <TextInputModal
                      style={{fontSize: `14px`, color: `#000000` }}
                      number="1"
                      disabled={true}
                      text="Prekės gamintojas"
                      value={this.props.description.manufacturer ? this.props.description.manufacturer : ""}
                    />
                  </div> 
                  <div className = "col-md-6 mb-2">
                    <TextInputModal
                      style={{fontSize: `14px`, color: `#000000` }}
                      number="1"
                      disabled={true}
                      text="Prekės kategorija"
                      value={this.props.description.family ? ( this.props.description.family.synonim? this.props.description.family.synonim:this.props.description.family.name) :"" }
                    />
                    </div> 
                    <div className = "col-md-3 mb-2">
                        <div className="form-group mb-0">
                            <div>
                              <label className={"editLabelForm"}>Logotipas</label>
                            </div>
                            { this.props.description.brand_logo ? (<img src = {this.props.description.brand_logo} className ="img-fluid" style = {{maxHeight: "30px"}}/>) : "-"}
                        </div>
                    </div>
                    <div className = "col-md-3 mb-2">
                      <div className="form-group mb-0">
                              <div>
                                <label className={"editLabelForm"}>QR kodas</label>
                              </div>
                              { this.props.description.qr_code_image ? (<img src = {this.props.description.qr_code_image} className ="img-fluid" style = {{maxHeight: "100px"}}/>) : "-"}
                          </div>
                      </div>
                  </div> 
              </div>
            </div>
            <hr/>
            <div className = "row">
              <div className = "col-6">
              <ul className="list-group">
                <li className="list-group-item "><b>Atributai</b></li>
                { this.props.description.product_attribute_values && this.props.description.product_attribute_values.map((item, index) => 
                <li className="list-group-item" key = {index}>
                  <div className = "row">
                    <div className = "col-6">
                      { item.synomim ? item.synomim : item.title }
                    </div>
                    <div className = "col-6 text-right">
                      { item.value }
                    </div>
                  </div>
                </li>
                )
                }
              </ul>
              </div>
              <div className = "col-6">
                <TextAreaFieldGroup
                  placeholder="Ypatingos savybės"
                  value={this.props.description.spec_details}
                  disabled={true}
                />
              </div>
            </div>
            <hr/>
            <div className = "row small-label">
              <div className = "col-6 mb-2">
                <TextInputModal
                  style={{fontSize: `14px`, color: `#000000` }}
                  number="1"
                  disabled={true}
                  text="Extra fee (AGATA)"
                  value={this.props.description.fee ? this.props.description.fee : "-"}
                />
              </div>
              <div className = "col-6 mb-2">
                <TextInputModal
                  style={{fontSize: `14px`, color: `#000000` }}
                  number="1"
                  disabled={true}
                  text="Formatas"
                  value={this.getFormatName()}
                />
              </div>
            </div>
            <div className = "row">
              <div className = "col-6">
                <Link to={"/goods/descriptions"} className = "btn btn-outline-secondary" style = {{width: `112px`}} >Grįžti</Link>
              </div>
              <div className = "col-6 text-right">
                { this.props.description.family && this.props.description.family.printed && 
                <button className = "btn btn-danger" style = {{width: `112px`}} onClick = {() => this.print()} 
                disabled = {this.props.description.family.format_id === null}>Spausdinti</button>
                }
              </div>
            </div>
          </div>
        </div>
        <div className = "col-md-4">
          { this.loadImage() }
          <div id = "description-image-container" className = "mb-3" style = {{display: "none"}}>
            <h5>Numatomas atvaizdavimas</h5>
            <div className = "mb-2" id = "description-image"></div>
            <span><MdInfo/> Realus vaizdas gali skirtis nuo numatomo</span>      
          </div>
          <div className = "">
            { this.props.description.store_prices && this.props.description.store_prices.length > 0 &&
            <div className="list-group store-prices">
              <div className="list-group-item store-price-header">Prekės kaina skirtinguose padaliniuose</div>
                <div className="store-prices-container">
                {this.props.description.store_prices.map((i, index) => i[0] && i[1] && 
                  <div className="list-group-item store-price" key={index}>
                    <div className = "row">
                      <div className = "col-6">
                        { i[0] }
                      </div>
                      <div className = "col-6 text-right">
                        { i[1] }
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            }
          </div>
        </div>
        </div>
        <div className = {"description-loader " + (this.props.loading ? "loading ": "")}>
          <Spinner/>
        </div>
        </div>
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  description: state.description.description,
  loading: state.description.loading_description,
  errors: state.errors,
  auth: state.auth.user
});

export default connect(mapStateToProps, {
  getDescription
})(Description);
