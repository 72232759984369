import React, { Fragment } from "react";
import printerSvg from "../../../../assets/images/printer.svg";
import mailSvg from "../../../../assets/images/mail.svg";
import moment from "moment";
import ColumnTableComment from "./ServiceForms/ColumnTableComment";
import { exportDocument, returnEuroString } from "../../../helpers/Utils";
import { CheckBoxInput } from "../../common/Buttons";
import {
  status_options,
  shipment_types_options,
} from "../../../helpers/GlobalOptions";
import SupplyingTableIndex from "../../orders/accepted_orders/items/SupplyingTableIndex";
import { AiOutlineAlert, AiOutlineCheckCircle } from "react-icons/ai";
import ClientInfoComponent from "../../Repetitive_components/clientInfoComponent";
import CourierLinksComponent from "../../Repetitive_components/courierLinksComponent";
import TableComment from "../../orders/accepted_orders/items/TableComment";
import { Tooltip } from "antd";
import { GiReturnArrow } from "react-icons/gi";
import ServiceRowChangesTable from "./ServiceForms/service_row_changes_table";
import { have_product_reference } from "../../../helpers/OrdersFunctionsGlobal";

function TableColumnService({
  item,
  index,
  openModal,
  checked_in_array,
  handleCheckboxChange,
}) {
  const findStatusName = () => {
    const find_status = status_options.find((e) => e.value == item.status);
    return find_status ? find_status.label : item.status;
  };

  const renameShipmentName = (name) => {
    const findShipment = shipment_types_options.find((e) => e.value === name);
    if (findShipment) {
      return findShipment.label;
    } else {
      return "";
    }
  };

  const booleanAkropolisStore = () => {
    if (!item.store || !item.store?.name) return true;
    const check = item.store.name.toLocaleLowerCase().includes("akrop");
    return !check;
  };

  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle">
        <div className="d-flex">
          <CheckBoxInput
            type="checkbox"
            color="#f7951e"
            checked={checked_in_array}
            onChange={() => handleCheckboxChange(item.id)}
          />
        </div>
      </td>
      <td className="align-middle">
        <span className="date-field">
          {moment(item.created_at).format("YYYY MM DD")}
        </span>
        <br />
        <span className="date-field">
          {moment(item.created_at).format("HH:mm")}
        </span>
      </td>
      <td
        className="align-middle cursor-pointer"
        onClick={() => openModal("modal", { id: item.id, number: item.number })}
      >
        {item.number}
      </td>
      <td className="align-middle">
        {item.service_type == "order" ? "Užsakymas" : item.service_type}
      </td>
      <td className="align-middle">
        {item.address ? (
          item.rivile_store_id ? (
            <div>
              <div className="font-weight-bold">{item.address.street}</div>
              <div>{`${item.address.city || ""}, ${
                item.address.postal_code || ""
              }`}</div>
            </div>
          ) : (
            <ClientInfoComponent client={item.address} />
          )
        ) : null}
      </td>
      <td className="align-middle">
        {item.service_rows &&
          item.service_rows.map((e, index) => (
            <React.Fragment key={index}>
              <span>
                {index == 0 && !!item.has_complain && (
                  <AiOutlineAlert
                    className="text-danger mr-1"
                    title="Yra parašytas skundas"
                  />
                )}
                {index == 0 && booleanAkropolisStore() && (
                  <span>
                    <RenderToolTip item_id={item.id} />
                  </span>
                )}
                {parseInt(e.item_quantity || 0)}
                &nbsp;*&nbsp;
                {!have_product_reference(e.item_code) ? (
                  <AiOutlineCheckCircle className="text-success mr-1" />
                ) : (
                  e.item_code
                )}
                &nbsp;
                <span
                  className={e.manual ? "border-bottom border-danger" : null}
                >
                  {e.item_name}
                </span>
              </span>
              <table className="table">
                <tbody style={{ fontWeight: "400" }}>
                  {e.supplyings &&
                    e.supplyings.map((itemSup, i) => (
                      <SupplyingTableIndex
                        key={i}
                        item={itemSup}
                        service_id={item.id}
                        product_reference={e.item_code}
                        id_order_row={e.id}
                        id_order={item.id}
                      />
                    ))}
                  {e.service_row_changes &&
                    e.service_row_changes.map((itemSup, i) => (
                      <ServiceRowChangesTable key={i} item={itemSup} />
                    ))}
                </tbody>
              </table>
              <div className="my-1" />
            </React.Fragment>
          ))}
      </td>
      <td className="align-middle">
        {item.service_description?.description || ""}
      </td>
      <td className="align-middle">{item.store ? item.store?.name : ""}</td>
      <td className="align-middle">
        <CourierLinksComponent shipments={item.shipments} />
        {/* {lastShipment &&
          lastShipment.tracking_numbers.map((tracking_numbers) => (
            <span
              key={tracking_numbers}
              className="badge px-2 py-1 font-weight-normal"
              style={{
                backgroundColor: "#8dc63f",
                color: "#fff",
                fontSize: 13,
              }}
            >
              {tracking_numbers}
            </span>
          ))} */}
      </td>
      <td className="align-middle">
        {item.shipments &&
          item.shipments.map((e) => (
            <div key={e.id}>{renameShipmentName(e.shipment_type)}</div>
          ))}
      </td>
      <td className="align-middle">{findStatusName()}</td>
      {/* <td className="align-middle"> */}
      {/* <ColumnTableComment comments={item.service_comments} type="comment_ka" /> */}
      <TableComment
        comments={item.service_comments}
        type="comment_ka"
        action="Service"
      />
      {/* </td> */}
      {/* <td className="align-middle"> */}
      <ColumnTableComment
        comments={item.service_comments}
        type="comment_logistic"
      />
      {/* </td> */}
      <td className="align-middle">{returnEuroString(item.total_price)}</td>
      <td className="align-middle">
        {/* <button
          className="btn btn-sm mr-1 mb-1"
          style={{ background: "#f7951e" }}
          onClick={() =>
            openModal("modal", { id: item.id, number: item.number })
          }
        >
          <FaSearch size={11} color="#fff" />
        </button>{" "} */}
        <button
          className="btn btn-sm mr-1 mb-1"
          style={{ background: "#8678ab" }}
          onClick={() =>
            openModal("email", {
              id: item.id,
              number: item.number,
              email: item.address.other,
            })
          }
        >
          <img
            style={{ color: "white" }}
            width="11.12"
            height="11.12"
            src={mailSvg}
          />
        </button>
        <button
          className="btn btn-sm mb-1"
          style={{ background: "#ef3824" }}
          onClick={() =>
            exportDocument(
              `/api/v1/repair_services/download/${item.id}`,
              `${item.number}.pdf`
            )
          }
        >
          <img
            style={{ color: "white" }}
            width="11.12"
            height="11.12"
            src={printerSvg}
          />
        </button>
      </td>
    </tr>
  );
}

const RenderToolTip = ({item_id}) => {
  return (
    <Tooltip
      placement="top"
      title={
        <div className="d-flex flex-wrap">
          Spausdinti dokumentą kaip
          <div
            className="text-orange cursor-pointer white-hover font-semi-bold ml-1"
            onClick={() =>
              exportDocument(
                "/api/v1/repair_services/export_documents_rma",
                `exported_rma_${item_id}.pdf`,
                "POST",
                {
                  ids: item_id,
                  akropolis: true,
                }
              )
            }
          >
            į akropolį
          </div>
          <div className="mx-1">/</div>
          <div
            className="text-orange cursor-pointer white-hover font-semi-bold"
            onClick={() =>
              exportDocument(
                "/api/v1/repair_services/export_documents_rma",
                `exported_rma_${item_id}.pdf`,
                "POST",
                {
                  ids: item_id,
                  transfer_back: true,
                  akropolis: true,
                }
              )
            }
          >
            iš akropolio atgal
          </div>
          <span>?</span>
        </div>
      }
    >
      <span
        className="btn-primary cursor-pointer mr-2"
        style={{
          width: 29,
          height: 29,
          borderRadius: "50%",
          textAlign: "center",
          display: "inline-block",
        }}
      >
        <GiReturnArrow width={29} />
      </span>
    </Tooltip>
  );
};

export default TableColumnService;
