import React from "react";
import { first_arr } from "../../../../helpers/Utils";
import TextAreaFieldGroup from "../../../common/TextAreaFieldGroup";
import TextInputModal from "../../../common/TextInputModal";
import ItemsPreview from "./ItemsPreview";

// value.guide_buyer_items => answers
const SecondModalPreview = ({
  value,
  modalType,
  setModalType,
  setValue,
}) => {

  const onMainChange = ({ target: { value, name } }) => {
    // const { value, name } = e.target;
    setValue((prev) => {
      let obj = { ...prev };
      obj.steps[first_arr(modalType.steps)][name] = value;
      console.log(name, value, obj.steps);
      return obj;
    });
  };

  console.log(modalType);
  const onHandleChange = (e, idx) => {
    const { value, name } = e.target;
    setValue((prev) => {
      let obj = { ...prev };
      obj.steps[first_arr(modalType.steps)].answers[idx][name] = value;
      return obj;
    });
  };
  const onHandleAdd = (d) => {
    console.log({ d });
    setValue((prev) => {
      let obj = { ...prev };
      obj.steps[first_arr(modalType.steps)].answers.push({
        title: "Be pavadinimo",
        description: "",
        additionals: [],
        active: true
      });
      return obj;
    });
  };
  console.log("SecondModalPreview: ", value);
  const onHandleDelete = (d, idx) => {
    setValue((prev) => {
      let obj = { ...prev };
      obj.steps[first_arr(modalType.steps)].answers[idx]._destroy = 1;
      return obj;
    });
  };
  return (
    <>
      <div>
        <TextInputModal
          text="Pavadinimas"
          name="title"
          value={value.title}
          onChange={onMainChange}
        />
        <div className="py-1" />

        <TextAreaFieldGroup
          placeholder="Įžanga"
          name="description"
          value={value.description}
          onChange={onMainChange}
          rows={3}
        />

        <div className="py-1" />

        <ItemsPreview
          title="Atsakymai"
          steps={value.answers || []}
          setModalType={setModalType}
          onHandleAdd={onHandleAdd}
          onHandleChange={onHandleChange}
          onHandleDelete={onHandleDelete}
        />
      </div>
    </>
  );
};

export default SecondModalPreview;
