import React from "react";
import Container from "../Layout/Container";
import { connect } from "react-redux";
import {
  getSuppliers,
  postSupplier,
  deleteSupplier,
  updateSupplier,
  clearErrors
} from "../../redux/Actions/supplierAction";
import SuppliersEditModal from "./Items/SupplierEditModal";
import ContentBlock from "../Layout/ContentBlock";
import Pagination from "../Dashboard/items/Pagination";
import TableColumnSuppliers from "./Items/TableColumnSupplier";
import FilterMenu from "../Layout/FilterMenu";
import TableFilterBox from "../common/TableFilterBox";
import ContentValidation from "../common/ContentValidation";
import InputText from "../orders/accepted_orders/InputFilter";

class Suppliers extends React.Component {
  constructor() {
    super();
    this.state = {
      nameValue: "",
      is_codeValue: false,
      is_activeValue: false,
      page: 1,
      pageCount: 100,
      commentValue: "",
      requiredId: 0,
      paramsFilter: "",
      filter: {
        name: "",
        code: "",
        company_code: "",
      },
      action: "new",
    };
  }
  componentDidMount() {
    this.reloadData(this.state.paramsFilter);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.reloadData(this.state.paramsFilter);
    }
  }

  reloadData = (paramsFilter) => {
    this.props.getSuppliers(
      this.state.page,
      this.state.pageCount,
      paramsFilter
    );
  };


  filterChange = (e) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [e.target.name]: e.target.value,
      },
    });
  };

  delayOnFilter = () => {
    if (this.props.loading_suppliers) {
      return setTimeout(() => {
        return this.delayOnFilter();
      }, 1000);
    }
    this.onFilter();
  };

  onFilter = () => {
    if (this.props.loading_suppliers) {
      return this.delayOnFilter();
    }
    let filterParams = [];
    if (this.state.filter.name.length > 0)
      filterParams.push(`&q[name_i_cont_any]=${this.state.filter.name}`);
    if (this.state.filter.code.length > 0)
      filterParams.push(`&q[code_i_cont_any]=${this.state.filter.code}`);
    if (this.state.filter.company_code.length > 0)
      filterParams.push(
        `&q[company_code_i_cont_any]=${this.state.filter.company_code}`
      );
    this.setState(
      {
        paramsFilter: filterParams.join(""),
        page: 1,
      },
      () => this.reloadData(filterParams.join(""))
    );
  };

  onAction = (action, index) => {
    this.setState({
      requiredId: index,
      action: action,
    });
  };

  setPagination = (page) => {
    this.setState({
      page,
    });
  };

  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  handleKeyDown = (e) => {
    if (e.key == "Enter") {
      this.onFilter();
    }
  };

  clearFilter = () => {
    this.setState(
      {
        filter: {
          name: "",
          code: "",
          company_code: "",
        },
      },
      () => this.onFilter()
    );
  };


  render() {
    const requiredItem = this.state.requiredId;
    let modalData = this.props.suppliersItems[requiredItem]
      ? this.props.suppliersItems[requiredItem]
      : [];
    return (
      <Container
        location={{
          locationName: "Tiekėjai",
          locationUrl: this.props.match.url,
        }}
      >
        <ContentBlock>
          <FilterMenu
            length={`${this.props.totals || 0} / ${
              this.props.suppliersItems.length || 0
            }`}
            onChangeCountPage={this.setPageCount}
            countPage={this.state.pageCount}
            text="tiekėjų"
            text2="Tiekėjų"
          />
        </ContentBlock>
        <ContentBlock>
          <TableFilterBox
              // onFilter={this.onFilter}
              // filter={true}
              add={this.onAction}
              clearFilter={() => this.clearFilter()}
            >
              <div className="px-1">
                <InputText
                  width={"0.75rem"}
                  number="1"
                  name="name"
                  placeholder="Pavadinimas"
                  onKeyDown={this.handleKeyDown}
                  value={this.state.filter.name}
                  onChange={(e) => this.filterChange(e)}
                />
              </div>
              <div className="px-1">
                <InputText
                  width={"0.75rem"}
                  number="1"
                  name="code"
                  placeholder="Padainio kodas"
                  onKeyDown={this.handleKeyDown}
                  value={this.state.filter.code}
                  onChange={(e) => this.filterChange(e)}
                />
              </div>
              <div className="px-1">
                <InputText
                  width={"0.75rem"}
                  number="1"
                  name="company_code"
                  placeholder="Įmonės kodas"
                  onKeyDown={this.handleKeyDown}
                  value={this.state.filter.company_code}
                  onChange={(e) => this.filterChange(e)}
                />
              </div>
            </TableFilterBox>
          <div>
            <ContentValidation
              loader={this.props.loading_suppliers}
              array={this.props.suppliersItems}
              alertId_s="supAlert-s"
              alertId_e="supAlert-e"
            >
              <div className="table-responsive">
                <table className="table table-sm table-hover mt-3">
                  <thead>
                    <tr className="text-muted">
                      <th className="text-black">#</th>
                      <th className="text-black">Pavadinimas</th>
                      <th className="text-black">Padalinio kodas</th>
                      <th className="text-black">Ar kodas?</th>
                      <th className="text-black">Ar aktyvus?</th>
                      <th className="text-black">Įmonės kodas</th>
                      <th className="text-black">Komentarai</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.suppliersItems.map((item, index) => {
                      return (
                        <TableColumnSuppliers
                          key={index}
                          index={index}
                          item={item}
                          onEdit={this.onAction}
                          onDelete={this.props.deleteSupplier}
                        />
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                  setPagination={this.setPagination}
                  page={this.state.page}
                  totals={this.props.totals}
                  pageCount={this.state.pageCount}
                />
              </div>
            </ContentValidation>
          </div>
        </ContentBlock>

        <SuppliersEditModal
          action={this.state.action}
          postSupplier={this.props.postSupplier}
          updateSupplier={this.props.updateSupplier}
          objectData={modalData}
          clearErrors={this.props.clearErrors}
          errors={this.props.errors}
        />
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  suppliersItems: state.supplier.suppliers,
  totals: state.supplier.totals,
  loading_suppliers: state.supplier.loading_supplier,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getSuppliers,
  postSupplier,
  deleteSupplier,
  updateSupplier,
  clearErrors
})(Suppliers);
