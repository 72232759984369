import { parcelTerminalBulder } from "../../../helpers/Utils";
import {
  GET_SERVICE,
  SET_LOADING_SERVICE,
  SET_LOADING_SERVICE_SHOW,
  GET_SERVICE_SHOW,
  SET_SERVICE,
  UPDATE_SERVICE_ITEM_SHOW,
  SERVICE_REPAIR_ADDRESS_UPDATE,
} from "../../Actions/types";

const initialState = {
  service: [],
  serviceShow: {
    service: {
      service_comments: [],
    },
    address: {},
    service_description: {},
    service_documents: [],
    sms: [],
    active_log: [],
    repair_history: [],
    parcel_terminals: [],
    shipments: [],
    waiting_payments: [],
  },
  loading: false,
  sms_template: [],
  loadingShow: false,
  totals: 0,
  total_complaints: 0,
  total_late: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_SERVICE:
      return {
        ...state,
        loading: true,
      };
    case SET_SERVICE:
      return {
        ...state,
        service: [action.payload, ...state.service],
      };
    case SET_LOADING_SERVICE_SHOW:
      return {
        ...state,
        loadingShow: true,
      };
    case GET_SERVICE:
      return {
        ...state,
        loading: false,
        service: action.payload.data,
        totals: action.payload.totals,
        total_complaints: action.payload.total_complaints,
        total_late: action.payload.total_late,
      };
    case UPDATE_SERVICE_ITEM_SHOW:
      return {
        ...state,
        service: serviceUpdate(state, action.payload),
        serviceShow: {
          ...state.serviceShow,
          service: action.payload,
        },
      };
    case GET_SERVICE_SHOW:
      return {
        ...state,
        loadingShow: false,
        serviceShow: {
          ...state.serviceShow,
          ...action.payload,
          parcel_terminals: parcelTerminalBulder(
            action.payload.parcel_terminals
          ),
        },
      };
    case "SERVICE_CREATE_WAITING_PAYMENT":
      return {
        ...state,
        serviceShow: {
          ...state.serviceShow,
          waiting_payments: [
            ...state.serviceShow.waiting_payments,
            action.payload,
          ],
        },
      };
    case "SET_SERVICE_COMMENTS":
      return {
        ...setRivileComment(state, action.payload),
      };
    case SERVICE_REPAIR_ADDRESS_UPDATE:
      return {
        ...state,
        serviceShow: { ...state.serviceShow, address: action.payload },
      };

    case "SERVICE_REPAIR_SMS_TEMPLATE":
      return {
        ...state,
        sms_template: action.payload,
      };
    case "SERVICE_REPAIR_ACTIVE_LOG_UPDATE":
      return {
        ...state,
        serviceShow: {
          ...state.serviceShow,
          active_log: action.payload.logs,
          repair_history: action.payload.history,
        },
      };
    case "SERVICE_REPAIR_CUSTOM_DOCUMENT_UPLOAD":
      return {
        ...state,
        serviceShow: {
          ...state.serviceShow,
          service_documents: [
            ...state.serviceShow.service_documents,
            action.payload,
          ],
        },
      };
    case "SERVICE_REPAIR_SMS_CREATED":
      return {
        ...state,
        serviceShow: {
          ...state.serviceShow,
          sms: [...state.serviceShow.sms, action.payload],
        },
      };

    case "SERVICE_REPAIR_ADD_SHIPMENTS":
      return {
        ...state,
        serviceShow: {
          ...state.serviceShow,
          shipments: [...state.serviceShow.shipments, action.payload],
        },
      };
    case "POST_SERVICE_ROW_GOODS":
      return {
        ...state,
        service: state.service.map((e) =>
          e.id == action.payload.service_id
            ? {
                ...e,
                total_price: action.payload.total_price,
                service_rows: [...e.service_rows, action.payload],
              }
            : e
        ),
        serviceShow: {
          ...state.serviceShow,
          service: {
            ...state.serviceShow.service,
            total_price: action.payload.total_price,
            service_rows: [
              ...state.serviceShow.service.service_rows,
              action.payload,
            ],
          },
        },
      };

    case "UPDATE_SERVICE_ROW_GOODS":
      return {
        ...state,
        service: state.service.map((e) =>
          e.id == action.payload.service_id
            ? {
                ...e,
                total_price: action.payload.total_price,
                service_rows: e.service_rows.map((sr) =>
                  sr.id === action.payload.id
                    ? { ...sr, ...action.payload }
                    : sr
                ),
              }
            : e
        ),
        serviceShow: {
          ...state.serviceShow,
          service: {
            ...state.serviceShow.service,
            total_price: action.payload.total_price,
            service_rows: state.serviceShow.service.service_rows.map((e) =>
              e.id === action.payload.id ? action.payload : e
            ),
          },
        },
      };
    case "REMOVE_SERVICE_ROW_GOODS":
      return {
        ...state,
        service: state.service.map((e) =>
          e.id == action.payload.orderId
            ? {
                ...e,
                total_price: action.payload.total_price,
                service_rows: e.service_rows.filter(
                  (sr) => sr.id !== action.payload.rowId
                ),
              }
            : e
        ),
        serviceShow: {
          ...state.serviceShow,
          service: {
            ...state.serviceShow.service,
            total_price: action.payload.total_price,
            service_rows: state.serviceShow.service.service_rows.filter(
              (e) => e.id !== action.payload.rowId
            ),
          },
        },
      };
    case "ADD_SERVICE_ROW_SUPPLY":
      return {
        ...state,
        service: state.service.map((e) =>
          e.id == action.payload.service_id
            ? {
                ...e,
                service_rows: e.service_rows.map((sr) =>
                  sr.id == action.payload.service_order_row_id
                    ? {
                        ...sr,
                        supplyings: [...sr.supplyings, action.payload],
                      }
                    : sr
                ),
              }
            : e
        ),
        serviceShow: {
          ...state.serviceShow,
          service: {
            ...state.serviceShow.service,
            service_rows: state.serviceShow.service.service_rows.map((e) =>
              e.id === action.payload.service_order_row_id
                ? { ...e, supplyings: [...e.supplyings, action.payload] }
                : e
            ),
          },
        },
      };
    case "ADD_SERVICE_CHANGES_TO_SERVICE_ROW":
      return {
        ...state,
        service: state.service.map((e) =>
          e.id == action.payload.service_id
            ? {
                ...e,
                service_rows: e.service_rows.map((sr) =>
                  sr.id == action.payload.service_row_id
                    ? {
                        ...sr,
                        service_row_changes: [
                          ...sr.service_row_changes,
                          action.payload,
                        ],
                      }
                    : sr
                ),
              }
            : e
        ),
        serviceShow: {
          ...state.serviceShow,
          service: {
            ...state.serviceShow.service,
            service_rows: state.serviceShow.service.service_rows.map((e) =>
              e.id === action.payload.service_row_id
                ? {
                    ...e,
                    service_row_changes: [
                      ...e.service_row_changes,
                      action.payload,
                    ],
                  }
                : e
            ),
          },
        },
      };
    case "REMOVE_SERVICE_CHANGES_TO_SERVICE_ROW":
      return {
        ...state,
        service: state.service.map((e) =>
          e.id == action.payload.service_id
            ? {
                ...e,
                service_rows: e.service_rows.map((sr) =>
                  sr.id == action.payload.orderId
                    ? {
                        ...sr,
                        service_row_changes: sr.service_row_changes.filter(
                          (sup) => sup.id !== action.payload.itemId
                        ),
                      }
                    : sr
                ),
              }
            : e
        ),
        serviceShow: {
          ...state.serviceShow,
          service: {
            ...state.serviceShow.service,
            service_rows: state.serviceShow.service.service_rows.map((e) =>
              e.id === action.payload.orderId
                ? {
                    ...e,
                    service_row_changes: e.service_row_changes.filter(
                      (sup) => sup.id !== action.payload.itemId
                    ),
                  }
                : e
            ),
          },
        },
      };
    case "REMOVE_SERVICE_ROW_SUPPLY":
      return {
        ...state,
        service: state.service.map((e) =>
          e.id == action.payload.service_id
            ? {
                ...e,
                service_rows: e.service_rows.map((sr) =>
                  sr.id == action.payload.orderId
                    ? {
                        ...sr,
                        supplyings: sr.supplyings.filter(
                          (sup) => sup.id !== action.payload.itemId
                        ),
                      }
                    : sr
                ),
              }
            : e
        ),
        serviceShow: {
          ...state.serviceShow,
          service: {
            ...state.serviceShow.service,
            service_rows: state.serviceShow.service.service_rows.map((e) =>
              e.id === action.payload.orderId
                ? {
                    ...e,
                    supplyings: e.supplyings.filter(
                      (sup) => sup.id !== action.payload.itemId
                    ),
                  }
                : e
            ),
          },
        },
      };
    case "SERVICE_POST_TO_RIVILE":
      return {
        ...state,
        serviceShow: {
          ...state.serviceShow,
          address: action.payload.data,
        },
      };

    case "SERVICE_DESCRIPTION_UPDATE":
      return {
        ...state,
        serviceShow: {
          ...state.serviceShow,
          service_description: action.payload,
        },
      };
    default:
      return state;
  }
}

const setRivileComment = (state, payload) => {
  // console.log(state,payload)
  const arrComm = [...state.service];
  const objComm = { ...state.serviceShow };
  if (objComm.service) {
    objComm.service.service_comments.push(payload);
  } else {
    objComm.service.service_comments = [];
    objComm.service.service_comments.push(payload);
  }
  const findIndex = arrComm.findIndex((e) => e.id == payload.service_id);
  if (findIndex !== -1) {
    arrComm[findIndex].service_comments.push(payload);
  }

  return {
    ...state,
    service: arrComm,
    serviceShow: objComm,
  };
};

const serviceUpdate = (state, payload) => {
  const arrComm = [...state.service];
  const findIndex = arrComm.findIndex((e) => e.id == payload.id);
  if (findIndex !== -1) {
    arrComm[findIndex] = payload;
  }
  return arrComm;
};
