
export const CommentStateType = (type) => {
  if (type === "comment_ka") {
    return "KA komentaras";
  }
  if (type === "comment_logistic") {
    return "Logistikos komentaras";
  }
  if (type === "comment_management") {
    return "Vadybos komentaras";
  }
  if (type === "comment_store") {
    return "Parduotuvės komentaras";
  }
};

