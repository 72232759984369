import React from "react";
import { Input } from "antd";
import { FlopppyButton, FlopppyButtonCancel } from "./ButtonWithLoading";

const FormWithOneInput = ({ value, onChange, loading, onClose, onSave, type }) => {
  return (
    <div className="d-flex">
      <Input
        className="mr-1"
        type={type || "text"}
        placeholder="Įvesti"
        min={type == "number" ? 0 : false}
        value={value}
        onChange={onChange}
      />
      <FlopppyButton className="ml-1" loading={loading} onClick={onSave} />

      <FlopppyButtonCancel
        className={`ml-1 ${loading && "invisible"}`}
        onClick={onClose}
      />
    </div>
  );
};

export default FormWithOneInput;
