import React, { useState } from "react";
import { Button1, SelectForm } from "../../../Buttons";
import OrderHistory from "../editModal/OrderHistory";
import { ClassicButton } from "../../../ButtonWithLoading";
import { useSelector } from "react-redux";


function RenderStatus({
  avalaibleStatus = [],
  status_id,
  onChange,
  errors,
}) {
  const { user } = useSelector((state) => state.auth);

  const user_title = `${user.firstname || ""} ${user.lastname || ""}`

  const [newStatus, setNewStatus] = useState(false);
  const [statusSelect, setStatus] = useState(undefined);

  const addNewStatus = () => {
    onChange({ target: { name: "status_id", value: statusSelect } });
    setStatus(undefined);
    setNewStatus(false);
  };
  const findStatus = () => {
    const findItem = avalaibleStatus.find((e) => e.value == status_id);
    return findItem ? findItem.label : "";
  };

  const order_histories = status_id
    ? findStatus()
      ? [{ status: findStatus(), datetime: new Date(), title: user_title }]
      : []
    : [];
  return (
    <div className="col-12 bg-silver">
      {errors.status && <p className="text-danger">Pasirinkite statusą</p>}
      <div className="table-responsive">
        <table className="table table-sm">
          <tbody>
            {order_histories.length > 0 &&
              order_histories.map((item, index) => (
                <OrderHistory key={index} index={index} item={item} />
              ))}
          </tbody>
        </table>
      </div>

      <div className="d-flex">
        {newStatus && (
          <>
            <div className="w-75">
              <SelectForm
                options={avalaibleStatus}
                value={statusSelect}
                onlyPlaceholder="Statusas"
                onChange={(e) => setStatus(e)}
              />
            </div>
            <div className="w-25 text-right">
              <ClassicButton
                loading={false}
                text="Saugoti"
                onClick={() => addNewStatus()}
              />
            </div>
          </>
        )}
      </div>
      <div className="py-4">
        <div style={{ position: "relative" }}>
          <Button1
            style={{
              position: "absolute",
              top: "-16px",
              left: "50%",
              transform: "translate(-50%, 0px)",
            }}
            text={
              newStatus
                ? "Atšaukti"
                : status
                ? "Keisti statusą"
                : "Pridėti naują statusą"
            }
            option={newStatus ? "minus" : "plus"}
            onClick={() => setNewStatus(!newStatus)}
          />
          <hr />
        </div>
      </div>
    </div>
  );
}

export default RenderStatus;
