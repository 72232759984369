import Axios from "axios";
import { errorHandler } from "../../helpers/Utils";
import { GET_CATEGORIES, LOADING_CATEGORY, GET_ERRORS } from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

export const getCategories = () => (dispatch) => {
  dispatch(setCategoriesLoading());
  Axios.get("/api/v1/categories")
    .then((res) => {
      dispatch({
        type: GET_CATEGORIES,
        payload: res.data[0].children,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getCategoriesOption = async () => {
  try {
    const { data } = await Axios.get("/api/v1/categories");
    return { success: true, data: data[0].children };
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const setCategoryGarbageAmount = async (garbage_fee) => {
  try {
    await Axios.put(`/api/v1/garbage_fees/${garbage_fee.category_id}`, {
      garbage_fee,
    });
  } catch (err) {
    errorHandler(err);
  }
};

export const getCategoryGarbageAmounts = async () => {
  try {
    const { data } = await Axios.get("/api/v1/garbage_fees");
    let obj = {};
    data.forEach((e) => (obj[e[0]] = e[1]));
    return { success: true, data: obj };
  } catch (err) {
    errorHandler(err);
    return { success: false };
  }
};

export const setCategoriesLoading = () => {
  return {
    type: LOADING_CATEGORY,
  };
};
