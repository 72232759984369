import Axios from "axios";

export const getLandings = async (page = 1, filter = "") => {
  try {
    const { data } = await Axios.get(`/api/v1/landings?page=${page}${filter}`);
    console.log("Data: ", data);
    return {
      success: true,
      data: data.data,
      totals: data.totals,
    };
  } catch (err) {
    console.log("Error: ", err);
    return { success: false };
  }
};
export const getLandingShow = async (id) => {
  try {
    const { data } = await Axios.get(`/api/v1/landings/${id}`);
    return {
      success: true,
      data: data,
    };
  } catch (err) {
    console.log("Error: ", err);
    return { success: false };
  }
};

export const getLandingCategories = async () => {
  try {
    const { data } = await Axios.get("/api/v1/landings/categories_ids");
    return {
      success: true,
      data: data.categories,
    };
  } catch (err) {
    console.log(`Error: ${err}`);
    return { success: false };
  }
};

export const postLanding = async (params) => {
  try {
    const { data } = await Axios.post("/api/v1/landings", params);
    return { success: true, data };
  } catch (err) {
    console.log("Error: ", err);
    return { success: false };
  }
};

export const putLanding = async (id, params) => {
  try {
    const { data } = await Axios.put(`/api/v1/landings/${id}`, params);
    return { success: true, data };
  } catch (err) {
    console.log("Error: ", err);
    return { success: false };
  }
};

export const removeLanding = async (id) => {
  try {
    const { data } = await Axios.delete(`/api/v1/landings/${id}`);
    if (data.success) {
      return { success: true };
    }
    throw new Error("Err");
  } catch (err) {
    return { success: false };
  }
};

// Landing types

export const getLandingTypes = async () => {
  try {
    const { data } = await Axios.get("/api/v1/landing_types");
    console.log("Data: ", data);
    return {
      success: true,
      data: data.sort((a, b) => (a.position > b.position ? 1 : -1)),
    };
  } catch (err) {
    console.log("Error: ", err);
    return { success: false };
  }
};

export const postLandingTypes = async (params) => {
  try {
    const id = params.landing_type?.id
    const { data } = await Axios(
      `/api/v1/landing_types/${id || ''}`,
      {
        method: id ? "PUT" : "POST",
        data: params,
      }
    );
    return { success: true, data };
  } catch (err) {
    console.log("Error: ", err);
    return { success: false };
  }
};

export const putLandingTypes = async (collection) => {
  try {
    const { data } = await Axios.put("/api/v1/landing_types/update_positions", {
      collection,
    });
    console.log("Data: ", data);
    return { success: true, data };
  } catch (err) {
    console.log("Error: ", err);
    return { success: false };
  }
};

export const deleteLandingTypes = async (id) => {
  try {
    const { data } = await Axios.delete(`/api/v1/landing_types/${id}`);
    console.log("Data: ", data);
    return { success: true };
  } catch (err) {
    console.log("Error: ", err);
    return { success: false };
  }
};
