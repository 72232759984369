import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { GoPrimitiveDot } from "react-icons/go";
import { RiErrorWarningLine } from "react-icons/ri";
import { FaCheck, FaTimes } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import TextInputModal from "../../common/TextInputModal";
import { updateRivileItem } from "../../../redux/Actions/rivileAction";

function TableItemsRivile({ id, item, index, rivileItem, url, role }) {
  if(url == '/rivile-pardavimai') {
    var item_code = role == 'big_box_user' ? item.technorama_code : item.bb_code
  } else {
    var item_code = role == 'big_box_user' ? item.bb_code : item.technorama_code
  }

  useEffect(() => {
    setEdit(false)
  }, [item])

  const [edit, setEdit] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const dispatch = useDispatch();

  const onEdit = () => {
    setEdit(true);
    setInputValue(item_code);
  };

  const onSubmit = () => {
    dispatch(updateRivileItem(id, inputValue)).then((res) => {
      if (res.success) {
        setEdit(false);
        setInputValue("");
      }
    });
  };

  const onCancel = () => {
    setEdit(false);
    setInputValue("");
  };

  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className={`align-middle ${item.agata_mismatch == true ? 'text-danger' : ''}`}>
        {`${item.code}${item.agata_code ? ' (agata: ' + item.agata_code + ')' : ''}`}
      </td>
      <td className="align-middle">
        {edit ? (
          <TextInputModal
            text=""
            placeholder="Įvesti"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        ) : item_code ? (
          item_code
        ) : (
          <>
            <div style={{opacity: "0.5"}}>
              <RiErrorWarningLine className="text-warning mr-1" />
              Trūksta duomenų
            </div>
          </>
        )}
      </td>
      <td className="align-middle">{item.name}</td>
      <td className="align-middle">{item.quantity}</td>
      <td className="align-middle">{item.price_without_pvm}</td>
      <td className="align-middle">{item.pvm}</td>
      <td className="align-middle text-center">
        <GoPrimitiveDot
          style={item.reversed_pvm == 1 ? { color: "green" } : { color: "red" }}
        />
      </td>
      <td className="align-middle">
      {!(url == "/rivile-pardavimai"  && (rivileItem.status == "exporting" || rivileItem.status == "exported")) && (
        <>
        {!(rivileItem.status == "exported") && (
          <div>
            {!edit ? (
              <MdEdit
                className="text-warning"
                size={20}
                style={{ cursor: "pointer" }}
                onClick={onEdit}
              />
            ) : (
              <div>
                <FaCheck
                  className="text-success mr-1"
                  size={20}
                  style={{ cursor: "pointer" }}
                  onClick={onSubmit}
                />
                <FaTimes
                  className="text-danger"
                  size={20}
                  style={{ cursor: "pointer" }}
                  onClick={onCancel}
                />
              </div>
            )}
          </div>
        )}
        </>
      )}
      </td>
    </tr>
  );
}

export default TableItemsRivile;
