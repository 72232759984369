import React from "react";
import moment from "moment";

function SuppliersInvoices(props) {
  const exportInvoice = () => {
    props.exportInvoice();
  };

  let showImportBtn = !props.exported && props.store.props.store != null && props.store.props.store != undefined;
  // let showImportBtn = !props.exported;
  
  const waitForElement = (id, callback) => {
    var poops = setInterval(function () {
      if (document.getElementById(id)) {
        clearInterval(poops);
        callback();
      }
    }, 100);
  };

  if (props.exported == false) {
    waitForElement("import_rivile_btn_" + props.id, function () {
      document.getElementById(
        "import_rivile_btn_" + props.id
      ).style.cssText = "block !important";
    });
  }

  return (
    <div
      className="modal fade"
      id={props.status}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-edit modal-editOrders" role="document">
        <div className="modal-content" style={{ height: "100vh" }}>
          <div className="modal-header">
            <div className="col-md-6">
              <div className="row">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Sąskaitos numeris <span className="text-orange">#</span>{props.text}
                </h5>
              </div>
              {![43, 11, 8].includes(props.supplier_id) && (
                <div className="row">
                  <span className="text-black">
                    <b>Užsakymo numeris: {props.sales_number}</b>
                  </span>
                </div>
              )}
              <div className="row">
                <span className="text-black">
                  <b>Sąskaitos data: {moment(props.invoice_date).format("YYYY-MM-DD")}, Apmokėti iki: {moment(props.pay_due).format("YYYY-MM-DD")}</b>
                </span>
              </div>
              {![43, 11, 8].includes(props.supplier_id) && (
                <div className="row">
                  <span className="text-black">
                    <b>Pristatyti: {props.delivery_address}</b>
                  </span>
                </div>
              )}
              <div className="row">
                <span className="text-black">
                  <b>Tiekėjas: <span className="text-orange">{props.supplier}</span></b>
                </span>
              </div>
              <div className="row">
                {props.store}
              </div> 
            </div>
            <div className="col-md-6">
              <div className="row float-right">
                {showImportBtn && (
                  <button
                  id={`import_rivile_btn_${props.id}`}
                  className="btn btn-sm float-right mr-3 d-flex justify-content-between import_suppliers_inv_btn"
                  onClick={() => exportInvoice()}
                  disabled={props.loading}
                  >
                    {props.loading ? 'Importuojama...' : 'Importuoti sąskaitą'}
                  </button>
                )}
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  id="modalClose"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
          <div
            className="modal-body"
            style={{ height: "100vh", overflow: "auto" }}
          >
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuppliersInvoices;