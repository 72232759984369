import { notification } from "antd";

export const notifications = (type, text) => {
  const textMessage = !text
    ? type === "success"
      ? "Sėkmingai išsaugota"
      : "Įvyko klaida"
    : text;
  notification[type]({
    message: type === "success" ? "Sėkmingai" : "Klaida",
    description: textMessage,
    placement: "topLeft",
  });
};
