import React from "react";
import DraggableSvg from "../../../../assets/images/draggable.svg";
import { CheckBoxInput } from "../../common/Buttons";
import InputText from "../../common/InputText";
import { Draggable } from "react-beautiful-dnd";
import { FaTrash } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import {
  SelectForm,
} from "../../common/Buttons";
import $ from 'jquery';

class FamilyAttributeListItem extends React.Component {
  constructor() {
    super();
    this.state = {
        data: [],
        open: false,
        value: []
    };
  }

  jQueryCode = () => {
      $('.custom-attribute-add .dropdown-toggle').off('click').on('click', function (){
        $(this).parent().find('.dropdown-menu').toggleClass('show');
      });
  };

  componentDidMount() {
    this.jQueryCode();
    var data = [];
    if (this.props.selectedAtributes) {
        this.props.selectedAtributes.map(i => {
            data.push(i.id);
            if  (i.label !== null) {
                data.push(i.label);
            }
        });
        this.setState({
            data: data
        });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    var data = [];
    if (nextProps.selectedAtributes) {
        nextProps.selectedAtributes.map(i => {
            data.push(i.id);
            if  (i.label !== null) {
                data.push(i.label);
            }
        });
        this.setState({
            data: data
        });
    }
  };

  deleteAttribute = (index) => {
    var data = this.state.data;
    data.splice(index, 1);
    this.setState({data: data});
    this.updateAttributes();
  };

  customSelect = (e, option) => {
    var data = this.state.data;
    let id = parseInt(e[0]);
    data.push(isNaN(id) ? e[0] : id );
    this.setState({
        data: data,
        value: [],
        open: false
    });
    this.updateAttributes();
    $('#attribute-dropdown').dropdown('hide');
  };

  updateAttributes = () => {
    var data = [];
    var lastItem = false;
    this.state.data.forEach( (item) => {
        const check = this.props.attributes.find(i => i.family_attribute_id === item);
        if (check) {
            if (lastItem) {
                data.push(lastItem);
                lastItem = false;
            }
            lastItem = {id: item, family_attribute_id: check.family_attribute_id , label: null};
        } else {
            if (lastItem) {
                lastItem.label = item;
                data.push(lastItem);
                lastItem = false;
            }
        }
    });
    if (lastItem) {
        data.push(lastItem);
        lastItem = false;
    }
    this.props.onAttributeUpdate(this.props.id, data, 'attributes')
  };

  render() {
    if (this.props.type === "custom") {
        return (
            <Draggable
                        key={this.props.id}
                        draggableId={`${this.props.id}-id`}
                        index={this.props.index}
                      >
                          {(provided, snapshot) => 
                            <div className = {`list-group-item ${provided.isDragging ? 'dragging' : ''}`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <div className = "row">
                                    <div className = "col-1 d-flex align-items-center">
                                        <img src={DraggableSvg} /> 
                                    </div>
                                    <div className = "col-4">
                                        <div className = "row">
                                            <div className = "col-2 pr-0 d-flex align-items-center">
                                            <CheckBoxInput
                                                text = ""
                                                onChange = {(e) => this.props.onAttributeUpdate(this.props.id, e)}
                                                name = "enabled"
                                                checked = {this.props.enabled}
                                            />
                                            </div>
                                            <div className = "col-10 pl-0">
                                            <InputText
                                                width={"0.75rem"}
                                                number="1"
                                                placeholder="Atributo pavadinimas"
                                                name="synonim"
                                                value={this.props.synonim}
                                                inline = {true}
                                                className = "mb-0"
                                                onChange = {(e) => this.props.onAttributeUpdate(this.props.id, e)}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                    <div className = "col-6 d-flex align-items-center">
                                        <div className="btn-group custom-attribute-add dropup">
                                            <button id = "attribute-dropdown" className="btn btn-primary btn-sm dropdown-toggle" type="button" aria-haspopup="true" aria-expanded="false">
                                                +
                                            </button>
                                            <div className="dropdown-menu p-1">
                                                <SelectForm
                                                    placeholder=""
                                                    mode="tags"
                                                    onlyPlaceholder="Pasirinkti"
                                                    open={this.state.open}
                                                    value={this.state.value}
                                                    options = {this.props.attributes.map(n => ({value: "" + n.family_attribute_id, label: n.family_attribute ? n.family_attribute.title:""}))}
                                                    onChange = {(e, option) => this.customSelect(e, option)}
                                                    name = "attributes"
                                                />
                                            </div>
                                        </div>
                                        {
                                        this.state.data.map((i, index) => { 
                                            let name = i;
                                            const filtered = this.props.attributes.find(item => item.family_attribute_id === i)
                                            if (filtered) {
                                                name = filtered.family_attribute.title;
                                                return <span className = "custom-attribute" key = {index}>{name} <AiOutlineClose className = "attribute-icon" onClick = {() => this.deleteAttribute(index)}/></span>;
                                            }
                                            return <span className = "custom-attribute label" key = {index}>{name}<AiOutlineClose className = "attribute-icon" onClick = {() => this.deleteAttribute(index)}/></span>;
                                        })
                                        }
                                    </div>
                                    <div className = "col-1 d-flex align-items-center">
                                            <FaTrash
                                            style={{
                                              cursor: "pointer",
                                              fontSize: "16px",
                                              color: "#D5D5D5",
                                            }}
                                            onClick={() => this.props.onAttributeDelete(this.props.id)}
                                          />
                                    </div>
                                </div>
                                {provided.placeholder}
                            </div>
                          }
            </Draggable>
        );
    } else {
    return (
        <Draggable
                    key={this.props.id}
                    draggableId={`${this.props.id}-id`}
                    index={this.props.index}
                  >
                      {(provided, snapshot) => 
                        <div className = {`list-group-item ${provided.isDragging ? 'dragging' : ''} ${this.props.active ? 'default' : ''} `}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <div className = "row">
                                <div className = "col-1 d-flex align-items-center">
                                    <img src={DraggableSvg} /> 
                                </div>
                                <div className = "col-5 d-flex align-items-center">
                                    <CheckBoxInput
                                        text = {this.props.name}
                                        name = "enabled"
                                        checked = {this.props.enabled ? true : false}
                                        onChange = {(e) => this.props.onAttributeUpdate(this.props.id, e)}
                                    />
                                </div>
                                <div className = "col-5 d-flex align-items-center">
                                    <InputText
                                        width={"0.75rem"}
                                        number="1"
                                        placeholder="Sinonimas"
                                        name="synonim"
                                        inline = {true}
                                        className = "mb-0"
                                        value={this.props.synonim}
                                        onChange = {(e) => this.props.onAttributeUpdate(this.props.id, e)}
                                    />
                                </div>
                                <div className = "col-1 d-flex align-items-center">
                                </div>
                            </div>
                            {provided.placeholder}
                        </div>
                      }
        </Draggable>
    );
     }
  }
}

export default FamilyAttributeListItem;
