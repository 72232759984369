import {
  LOADING_RIVILE_STORE,
  UPDATE_RIVILE_STORE,
  GET_RIVILE_STORE,
} from "../Actions/types";

const initialState = {
  rivile_store: [],
  totals: 0,
  loading_rivile_store: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_RIVILE_STORE:
      return {
        ...state,
        loading_rivile_store: true,
      };
    case GET_RIVILE_STORE:
      return {
        ...state,
        rivile_store: action.payload.data,
        totals: action.payload.totals,
        loading_rivile_store: false,
      };
    case UPDATE_RIVILE_STORE:
      const updatedRivileServiceArray = [...state.rivile_store];
      const findIndex = updatedRivileServiceArray.findIndex(
        (find) => find.id == action.payload.id
      );
      if (findIndex !== -1) {
        updatedRivileServiceArray[findIndex] = action.payload;
      }
      return {
        ...state,
        rivile_store: updatedRivileServiceArray,
      };
    default:
      return state;
  }
}
