import Axios from "axios";
import {
  LOADING_SUPP_INVOICES,
  GET_SUPP_INVOICES,
  SHOW_SUPP_INVOICE,
  UPDATE_INVOICE_STATUS,
  DELETE_SUPP_INVOICE,
  EDIT_SUPP_INVOICE,
  CLEAR_ERRORS,
  GET_ERRORS,
} from "./types";

const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

const success = () => {
  if (!document.getElementById("invoiceAlert-s")) return;
  document.getElementById("invoiceAlert-s").style.display = "block";
  setTimeout(
    () => (document.getElementById("invoiceAlert-s").style.display = "none"),
    5000
  );
  // document.getElementById("modalClose").click();
};

const hideImportButton = (id) => {
  document.getElementById("import_rivile_btn_" + id).style.cssText = 'display:none !important';
  console.log("import_rivile_btn_" + id)
}

const error = (error) => {
  if (!document.getElementById("invoiceAlert-e")) return;
  document.getElementById("invoiceAlert-e").style.display = "block";
  document.getElementById("toast-error").innerHTML = error ? error : "Klaida"
  setTimeout(
    () => (document.getElementById("invoiceAlert-e").style.display = "none"),
    5000
  );
};

export const getSuppliersInvoices = (page, pageCount, filter) => (dispatch) => {
  dispatch(setInvoicesLoading());
  Axios.get(`/api/v1/suppliers_invoices?page=${page}&per_page=${pageCount}${filter}`)
    .then((res) => {
      let response = res.data;
      if (!res.data && !res.data.data) {
        response = {
          invoices: [],
          totals: 0,
        };
      }
      dispatch({
        type: GET_SUPP_INVOICES,
        payload: response,
      });
    })
    .catch((err) => {
      if(err.response && err.response.data) {

        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const getSupplierInvoice = (id, filter) => (dispatch) => {
  Axios.get(`/api/v1/suppliers_invoices/${id}?${filter}`)
    .then((res) => {
      dispatch({
        type: SHOW_SUPP_INVOICE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const deleteSuppliersInvoice = (id) => (dispatch) => {
  if (confirm("Ar tikrai norite ištrinti?")) {
    Axios.delete(`/api/v1/suppliers_invoices/${id}`)
      .then((res) => {
        dispatch({
          type: DELETE_SUPP_INVOICE,
          payload: id,
        });
        success();
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        error();
      });
  }
};

export const exportSuppliersInvoice = (id) => (dispatch) => {
  if (confirm("Ar tikrai norite exportuoti?")) {
    return Axios.post("/api/v1/suppliers_invoices/export_invoice", { id: id })
      .then((res) => {
        console.log(res.data)
        if(res.data == true){
          success("Sąskaita importuota");
          dispatch({
            type: UPDATE_INVOICE_STATUS,
            payload: id,
          });
          hideImportButton(id);
          return { success: true }
        } else {
          error(res.data[0])
          return { success: false }
        }
      })
  }
  return false;;
}

export const updateSuppliersInvoiceItem = (id, code) => (dispatch) => {
  let params = {
    suppliers_invoice: {
      item_code_rivile: code,
    },
  };
  return Axios.put(`/api/v1/suppliers_invoices_items/${id}`, params).then(
    (res) => {
      dispatch({
        type: EDIT_SUPP_INVOICE,
        payload: res.data,
      });
      success();
      return {
        success: true,
      };
    }
  );
};

export const updateSuppliersInvoice = (id, value) => (dispatch) => {
  const params = {
    suppliers_invoice: {
      store: value
    },
  };

  return Axios.put(`/api/v1/suppliers_invoices/${id}`, params).then(
    (res) => {
      dispatch({
        type: EDIT_SUPP_INVOICE,
        payload: res.data,
      });
      success();
      return {
        success: true,
      };
    }
  ).catch(res => {
    error('Klaida');
    console.log(res)
    return { success: false }
  })
};

export const setInvoicesLoading = () => {
  return {
    type: LOADING_SUPP_INVOICES,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
