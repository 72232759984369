import React from "react";

export default function TextInfoWrapper({
  backgroundColor = "#E5F3E3",
  color = "#F7951E",
  text = "",
  children,
}) {
  return (
    <div
      className="d-flex flex-column"
      style={{
        backgroundColor,
        padding: "10px 14px 7px",
        borderRadius: 3,
      }}
    >
      <p
        className="font-semi-bold text-base mb-2"
        style={{
          color,
          lineHeight: 1,
        }}
      >
        {text}
      </p>
      {children}
    </div>
  );
}
