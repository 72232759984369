import React from "react";
import { GoPlus } from "react-icons/go";
import { FaMinus } from "react-icons/fa";

export const AlertSuccess = props => {
  return (
    <>
      <div
        id={props.alertId}
        className="position-fixed"
        style={{ right: "0", display: "none", minWidth: "200px", top: "26px", zIndex: "1500" }}
      >
        <div className="toast fade show">
          <div className="toast-header">
            <strong className="mr-auto text-success">
              <GoPlus /> Sėkmingai!
            </strong>
            <button
              type="button"
              className="ml-2 mb-1 close"
              data-dismiss="toast"
              onClick={() =>
                (document.getElementById(props.alertId).style.display = "none")
              }
            >
              &times;
            </button>
          </div>
          <div className="toast-body"> Atlikta sėkmingai.</div>
        </div>
      </div>
    </>
  );
};

export const AlertError = props => {
  return (
    <>
      <div
        id={props.alertId}
        className="position-fixed"
        style={{ right: "0", display: "none", minWidth: "200px", top: "26px", zIndex: "1500" }}
      >
        <div className="toast fade show">
          <div className="toast-header">
            <strong className="mr-auto text-danger">
              <FaMinus /> <span className="alert_body">{props.title || "Klaida!"}</span>
            </strong>
            <button
              type="button"
              className="ml-2 mb-1 close"
              data-dismiss="toast"
              onClick={() =>
                (document.getElementById(props.alertId).style.display = "none")
              }
            >
              &times;
            </button>
          </div>
          <div className="toast-body" id="toast-error"> {props.sub_title || "Įvyko klaida"}</div>
        </div>
      </div>
    </>
  );
};
