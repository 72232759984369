import React from "react";

function BasicModal(props) {
  return (
    <div
      className="modal fade"
      id={props.status}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className={"modal-dialog modal-edit " + props.className } role="document">
        <div className="modal-content" style={{ height: "100vh" }}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {props.text}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              id="modalClose"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            className="modal-body"
            style={{ height: "100vh", overflow: "auto" }}
          >
            {props.children}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              aria-label="Close"
              onClick={(e) => props.handleSave(e)}
            >
              {props.buttonText}
            </button>
            {props.close &&
              <button
              type="button"
              id="modalClose"
              className="btn btn-secondary"
              data-dismiss="modal"
              >
              Uždaryti
            </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default BasicModal;
