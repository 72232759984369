import React from "react";
import {
  ButtonEdit,
  EditElementClick,
} from "../../common/Buttons";

function TableColumnManagers({ id, item, index, onAction }) {
  return (
    <tr className="align-middle tableColumn" key={index}>
      {/* <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", index)}
          name={item.table_number}
        />
      </td> */}
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", id)}
          name={item.manager_id}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", id)}
          name={item.firstname}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", id)}
          name={item.lastname}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", id)}
          name={item.department}
        />
      </td>
      {/* <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", index)}
          name={item.working_since}
        />
      </td> */}
      {/* <td className="align-middle">
        <EditElementClick
          onEdit={() => onAction("edit", index)}
          name={item.status}
        />
      </td> */}
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonEdit onEdit={() => onAction("edit", id)} />
      </td>
    </tr>
  );
}

export default TableColumnManagers;