import React from "react";

function TableFilterBox(props) {
  return (
    <>
      <div className={`bg-white d-flex flex-wrap pl-1 pr-1 ${props.extraClass}`} style={props.style}>{props.children}</div>
      {props.button1 &&
        props.button1
      }
      {props.filter && (
        <button
          className="btn btn-sm float-right mr-3 btn-danger"
          onClick={props.onFilter}
        >
          {props.filterText || "Filtruoti"}
        </button>
      )}

      {props.add && (
        <button
          type="button"
          className="btn btn-sm btn-primary float-right mr-3"
          data-toggle="modal"
          data-target={props.modalId || "#tableEdit"}
          onClick={() => props.add("new")}
        >
          {props.addText || "Sukurti naują"}
        </button>
      )}
      {props.clearFilter && (
        <button
          className="btn btn-sm float-right mr-3 btn-info font-normal"
          onClick={props.clearFilter}
        >
          Išvalyti filtravimą
        </button>
      )}
      {props.getInvoices && (
        <button
          className="btn btn-sm float-right mr-3 btn-info"
          onClick={props.getInvoices}
        >
         Gauti sąskaitas
        </button>
      )}
      {props.importPayModulePlans && 
        props.importPayModulePlans
      }
      <hr />
    </>
  );
}

TableFilterBox.defaultProps = {
  extraClass: "",
  style: {}
}

export default TableFilterBox;
