import {
  GET_SHIPMENT_DPD,
  GET_SHIPMENT_OMNIVA,
  GET_SHIPMENT_LP_EXPRESS,
  LOADING_SHIPMENT_DPD,
  LOADING_SHIPMENT_OMNIVA,
  LOADING_SHIPMENT_LP_EXPRESS,
  REMOVE_SHIPMENT,
  GET_SHIPMENT_OWN_TRANSPORT,
} from "../Actions/types";

const initialState = {
  loading_shipment_dpd: false,
  loading_shipment_omniva: false,
  loading_shipment_own_transport: false,
  loading_shipment_lp_express: false,
  loading: false,
  shipment_dpd: [],
  shipment_omniva: [],
  shipment_own_transport: [],
  shipment_lp_express: [],
  shipment: [],
  totals_dpd: 0,
  totals_omniva: 0,
  totals_own_transport: 0,
  totals_lp_express: 0,
  totals: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "LOADING_SHIPMENT":
      return {
        ...state,
        loading: true,
      };
    case LOADING_SHIPMENT_DPD:
      return {
        ...state,
        loading_shipment_dpd: true,
      };
    case LOADING_SHIPMENT_OMNIVA:
      return {
        ...state,
        loading_shipment_omniva: true,
      };
    case LOADING_SHIPMENT_LP_EXPRESS:
      return {
        ...state,
        loading_shipment_lp_express: true,
      };
    case "GET_SHIPMENT":
      return {
        ...state,
        shipment: action.payload.data,
        totals: action.payload.totals,
        loading: false,
      };
    case GET_SHIPMENT_DPD:
      return {
        ...state,
        shipment_dpd: action.payload.data,
        totals_dpd: action.payload.totals,
        loading_shipment_dpd: false,
      };
    case GET_SHIPMENT_OMNIVA:
      return {
        ...state,
        shipment_omniva: action.payload.data,
        totals_omniva: action.payload.totals,
        loading_shipment_omniva: false,
      };
    case GET_SHIPMENT_LP_EXPRESS:
      return {
        ...state,
        shipment_lp_express: action.payload.data,
        totals_lp_express: action.payload.totals,
        loading_shipment_lp_express: false,
      };
    case GET_SHIPMENT_OWN_TRANSPORT:
      return {
        ...state,
        shipment_own_transport: action.payload.data,
        totals_own_transport: action.payload.totals,
        loading: false,
      };
    case REMOVE_SHIPMENT:
      return removeShipment(state, action.payload);
    default:
      return state;
  }
}

const removeShipment = (state, action) => {
  if (action.position == "dpd") {
    return {
      ...state,
      shipment_dpd: state.shipment_dpd.filter((item) => item.id !== action.id),
    };
  } else if (action.position == "omniva") {
    return {
      ...state,
      shipment_omniva: state.shipment_omniva.filter(
        (item) => item.id !== action.id
      ),
    };
  } else if (action.position == "itella") {
    return {
      ...state,
      shipment: state.shipment.filter(
        (item) => item.id !== action.id
      ),
    };
  } else {
    return {
      ...state,
      shipment_own_transport: state.shipment_own_transport.filter(
        (item) => item.id !== action.id
      ),
    };
  }
};
