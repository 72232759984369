import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { OrderTableFooterButton } from "../../common/Buttons";

function ModalSpecial(props) {
  return (
    <div className={"modal-wrapper"}>
      <div onClick={props.backdrop ? () => props.backdrop() : () => props.onClose()} className={"modal-backdrop"} />

      <div
        className="modal-dialog modal-edit modal-editOrders"
        style={{ zIndex: 101, ...props.style }}
      >
        <div className="modal-content px-2 py-3" style={{ height: "100vh" }}>
          {props.text && (
            <div className="col">
              <div className="d-flex justify-content-between font-weight-bold d-block modal-title text-black text-xl">
                {props.text}
                <div className="cursor-pointer d-flex align-items-center justify-content-center">
                  {props.extras}
                  <AiOutlineClose
                    className="pl-2 text-black"
                    size={25}
                    // color={"#BFBFBF"}
                    onClick={() => props.onClose()}
                  />
                </div>
              </div>
            </div>
          )}
          <div
            className="modal-body"
            style={{ height: "100vh", overflow: "auto", ...props.modal_body }}
          >
            {props.children}
          </div>
          {props.bottoms && (
            <div className="modal-footer justify-content-center">
              <OrderTableFooterButton
                color="#ee3624"
                text="Išsaugoti"
                handleSave={props.handleSave}
              />

              <OrderTableFooterButton
                color="silver"
                text="Uždaryti"
                onClick={() => props.onClose()}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModalSpecial;
