import React from "react";
import {
  EditElementClick,
} from "../../common/Buttons";
import { GoPrimitiveDot, GoDiffRenamed } from "react-icons/go";
import moment from "moment";
import InvoicedItem from "./InvoicedItem";
import CommentsMainPage from "./CommentsMainPage";
import { CheckBoxInput } from "../../common/Buttons";

function TableColumnRivile({ id, item, index, onEdit, checkedArray, handleCheckboxChange, validation}) {
  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle text-center">
        <CheckBoxInput
          disabled={validation && item.status !== 'new'}
          type="checkbox"
          color="#f7951e"
          checked={checkedArray.includes(id)}
          onChange={() => handleCheckboxChange(id)}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onEdit("edit", item.id)}
          name={item.document_date ? moment(item.document_date).format("YYYY-MM-DD") : ""}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onEdit("edit", item.id)}
          name={item.rivile_invoice_type.name}
        />
      </td>
      <td className="align-middle text-center">
        <GoPrimitiveDot
          style={item.status == 'new' ? { color: "red" } : item.status == 'exporting' ? { color: "yellow" } : { color: "green" }}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onEdit("edit", item.id)}
          name={item.document_number}
        />
      </td>
      <td className="align-middle">
        <InvoicedItem invoiced={item.rivile_invoiced_items.sort((a, b) => (a.bb_code > b.bb_code ? 1 : -1))} />
        
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onEdit("edit", item.id)}
          name={item.price_without_pvm}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onEdit("edit", item.id)}
          name={item.pvm}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onEdit("edit", item.id)}
          name={item.price}
        />
      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onEdit("edit", item.id)}
          name={item.notes}
        />
      </td>
      <td className="align-middle">
        <CommentsMainPage comments={item.rivile_comments} />

      </td>
      <td className="align-middle">
        <EditElementClick
          onEdit={() => onEdit("edit", item.id)}
          name={item.import_api_errors[0]}
        />
      </td>
    </tr>
  );
}

export default TableColumnRivile;
