import React, { useState } from "react";
import { Input, Modal, Switch, Button } from "antd";
import { useEffect } from "react";
import { FaCheck, FaTimes, FaEdit } from "react-icons/fa";
import { have_product_reference } from "../../helpers/OrdersFunctionsGlobal";

const SerialNumberModal = ({
  id,
  SNumber,
  onCancel,
  options,
  updateSerialNumber,
}) => {
  const [DataToChange, setDataToChange] = useState(options);
  const [DataEdit, setDataEdit] = useState([]);

  const handleToChange = (ID, name, value, serial_value) => {
    const data = [...DataToChange];
    const findIndexData = data.findIndex((find) => find.id == ID);
    if (findIndexData !== -1) {
      data[findIndexData][name] = value;
      setDataToChange(data);

      if (name == "required_serial_number") {
        let body = {
          order_row: {
            required_serial_number: value,
            serial_number: serial_value,
          },
        };
        updateSerialNumber(id, ID, body);
        let dataEditOld = [...DataEdit];
        const findIndex = dataEditOld.findIndex((find) => find.id == ID);
        if (findIndex !== -1) {
          dataEditOld.splice(findIndex, 1);
          setDataEdit(dataEditOld);
        }
      }
    }
  };

  const saveButton = (productId, value) => {
    let data = [...DataEdit];
    const findIndexfData = data.findIndex((find) => find.id == productId);
    if (findIndexfData >= 0) {
      let body = {
        order_row: {
          serial_number: value,
        },
      };
      updateSerialNumber(id, productId, body).then((res) => {
        if (res) {
          data.splice(findIndexfData, 1);
          setDataEdit(data);
        }
      });
    }
  };

  const editButton = (productId) => {
    let data = [...DataEdit];
    const findIndexfData = data.findIndex((find) => find.id == productId);
    if (findIndexfData >= 0) {
      handleToChange(productId, "serial_number", data[findIndexfData].text);
      data.splice(findIndexfData, 1);
      setDataEdit(data);
    }
  };
  return (
    <Modal
      title="Serijinis numeris"
      open={Boolean(SNumber)}
      okButtonProps={{ hidden: true }}
      cancelText="Uždaryti"
      onCancel={onCancel}
    >
      <div>
        <table className="table" style={{ marginBottom: "0px!important" }}>
          {DataToChange.map((item, index) => (
            <React.Fragment key={index}>
              <thead>
                <tr>
                  <th style={{ fontSize: "0.8rem" }}>{item.quantity}</th>
                  <th style={{ fontSize: "0.8rem" }}>
                    {have_product_reference(item.product_reference)
                      ? item.product_reference
                      : ""}
                  </th>
                  <th colSpan="4">
                    <span style={{ fontSize: "0.8rem" }}>
                      {item.product_name !== "Užnešimas" ? (
                        <span>
                          {parseInt(item.quantity)}
                          &nbsp;*&nbsp;
                          {item.product_name}
                        </span>
                      ) : (
                        <span className="d-flex align-items-center">
                          {/* <AiOutlineCheckCircle className="text-success mr-1" /> */}
                          {item.product_name}
                        </span>
                      )}
                    </span>
                  </th>
                  <td>
                    {have_product_reference(item.product_reference) && (
                      <Switch
                        checkedChildren="S/N"
                        unCheckedChildren="S/N"
                        checked={item.required_serial_number}
                        disabled={
                          item.required_serial_number && item.serial_number
                        }
                        onChange={(e) =>
                          handleToChange(
                            item.id,
                            "required_serial_number",
                            e,
                            item.serial_number
                          )
                        }
                      />
                    )}
                  </td>
                  <td
                    style={{
                      float: "right",
                      fontSize: "0.8rem",
                    }}
                  >
                    {Number(item.unit_price_tax_incl).toFixed(2) || 0} &#8364;
                  </td>
                </tr>
              </thead>
            </React.Fragment>
          ))}
        </table>
      </div>
      <>
        {DataToChange.filter((item) => item.required_serial_number).map(
          (product) => (
            <div className="py-2" key={product.id}>
              <label className="text-black">{product.product_name} S/N</label>
              <div className="d-flex">
                <Input
                  placeholder="Įrašyti"
                  name="number"
                  value={product.serial_number}
                  disabled={!DataEdit.some((some) => some.id == product.id)}
                  onChange={(e) =>
                    handleToChange(product.id, "serial_number", e.target.value)
                  }
                />
                {!DataEdit.some((some) => some.id == product.id) && (
                  <Button
                    onClick={() =>
                      setDataEdit([
                        ...DataEdit,
                        { id: product.id, text: product.serial_number },
                      ])
                    }
                  >
                    <FaEdit />
                  </Button>
                )}
                {DataEdit.some((some) => some.id == product.id) && (
                  <>
                    <Button
                      onClick={() =>
                        saveButton(product.id, product.serial_number)
                      }
                    >
                      <FaCheck />
                    </Button>
                    {product.serial_number &&
                      product.serial_number.length !== 0 && (
                        <Button danger onClick={() => editButton(product.id)}>
                          <FaTimes />
                        </Button>
                      )}
                  </>
                )}
              </div>
            </div>
          )
        )}
      </>
    </Modal>
  );
};

const SerialNumberBlock = ({
  options,
  updateSerialNumber,
  orderID,
  updateSerialNumberState,
}) => {
  const [DataEdit, setDataEdit] = useState([]);
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(options);
  }, [options.length]);

  const handleToChange = (ID, name, value) => {
    const data = [...options];
    const findIndexData = data.findIndex((find) => find.id == ID);
    if (findIndexData !== -1) {
      data[findIndexData][name] = value;
      setData(data);
    }
  };

  const saveButton = (productId, value) => {
    let data = [...DataEdit];
    const findIndexfData = data.findIndex((find) => find.id == productId);
    if (findIndexfData >= 0) {
      let body = {
        order_row: {
          serial_number: value,
        },
      };
      updateSerialNumber(orderID, productId, body).then((res) => {
        if (res) {
          data.splice(findIndexfData, 1);
          setDataEdit(data);
        }
      });
    }
  };

  const editButton = (productId) => {
    let data = [...DataEdit];
    const findIndexfData = data.findIndex((find) => find.id == productId);
    if (findIndexfData >= 0) {
      handleToChange(productId, "serial_number", data[findIndexfData].text);
      data.splice(findIndexfData, 1);
      setDataEdit(data);
    }
  };

  return (
    <>
      {data.map((product) => (
        <div className="py-2" key={product.id}>
          <label className="text-black">{product.product_name} S/N</label>
          <div className="d-flex">
            <Input
              placeholder="Įrašyti"
              name="number"
              value={product.serial_number}
              disabled={!DataEdit.some((some) => some.id == product.id)}
              onChange={(e) =>
                handleToChange(product.id, "serial_number", e.target.value)
              }
            />
            {!DataEdit.some((some) => some.id == product.id) && (
              <Button
                onClick={() =>
                  setDataEdit([
                    ...DataEdit,
                    { id: product.id, text: product.serial_number },
                  ])
                }
              >
                <FaEdit />
              </Button>
            )}
            {DataEdit.some((some) => some.id == product.id) && (
              <>
                <Button
                  onClick={() => saveButton(product.id, product.serial_number)}
                >
                  <FaCheck />
                </Button>
                {product.serial_number && product.serial_number.length !== 0 && (
                  <Button danger onClick={() => editButton(product.id)}>
                    <FaTimes />
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export { SerialNumberModal, SerialNumberBlock };
