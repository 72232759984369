import {
  GET_COUNTRIES,
  POST_COUNTRY,
  DELETE_COUNTRY,
  UPDATE_COUNTRY,
  LOADING_COUNTRY
} from "../Actions/types";

const initialState = {
  countries: [],
  totals: 0,
  loading_country: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_COUNTRY:
      return {
        ...state,
        loading_country: true
      };
    case GET_COUNTRIES:
      return {
        ...state,
        countries: action.payload.data,
        totals: action.payload.totals,
        loading_country: false
      };
    case POST_COUNTRY:
      let updateData = [...state.countries];
      updateData.push(action.payload);
      return {
        ...state,
        countries: updateData
      };
    case UPDATE_COUNTRY:
      let getData = [...state.countries];
      const updateElementId = element => element.id === action.payload_id;
      const findIndex = getData.findIndex(updateElementId);
      getData[findIndex] = action.payload;
      return {
        ...state,
        countries: getData
      };
    case DELETE_COUNTRY:
      return {
        ...state,
        countries: [...state.countries].filter(
          filterItem => filterItem.id !== action.payload
        ),
        loading_country: false
      };
    default:
      return state;
  }
}
