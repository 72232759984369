import React, { useRef } from "react";
import { Tooltip } from "antd";
import { useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import { CheckBoxInput } from "../common/Buttons";
import { AnimatePresence, motion } from "framer-motion";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import { AiOutlinePlus } from "react-icons/ai";
import { uniqueArray } from "../../helpers/array";

export default function ButtonSettings({
  columns = [],
  showColumns,
  setShowColumns,
}) {
  const wrapperRef = useRef(null);
  const [internalColumns, setInternalColumns] = useState(showColumns);
  const [open, setOpen] = useState(false);
  useDebounce(
    () => {
      if (!open) return false;
      setShowColumns(internalColumns);
      setOpen(false);
    },
    1500,
    [JSON.stringify(internalColumns)]
  );

  const handlerActive = () => {
    setOpen((prev) => {
      if (!prev) setInternalColumns(showColumns);
      return !prev;
    });
  };

  return (
    <div className="position-relative" ref={wrapperRef}>
      <Tooltip placement="top" title="Pasirinkti">
        <div
          className="rounded-circle p-1 d-flex align-items-center justify-content-center"
          style={{
            background: open ? "#D9D9D9" : "rgba(217, 217, 217, 0.4)",
            width: 28,
            height: 28,
          }}
          onClick={() => {
            handlerActive();
          }}
        >
          <AiOutlinePlus />
        </div>
      </Tooltip>
      <AnimatePresence>
        {open && (
          <ActiveShow
            wrapperRef={wrapperRef}
            internalColumns={internalColumns}
            setInternalColumns={setInternalColumns}
            columns={columns}
            onClose={() => handlerActive()}
          />
        )}
      </AnimatePresence>
    </div>
  );
}

const ActiveShow = ({
  internalColumns,
  setInternalColumns,
  columns,
  onClose,
  wrapperRef,
}) => {
  const onAction = () => {
    onClose();
  };
  useOutsideAlerter(wrapperRef, onAction);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, origin: 1 }}
      className="bg-white p-1 position-absolute"
      style={{
        background: "#FFFFFF",
        border: "1px solid #D9D9D9",
        borderRadius: 4,
        right:
          internalColumns.length > 2 &&
          internalColumns.filter((d) =>
            ["SKU", "Prod. cat.", "Brand", "MPN", "EAN", "Prime cost"].includes(
              d
            )
          ).length >= 2
            ? 0
            : -140,
        top: 35,
        width: 170,
        zIndex: 15,
      }}
    >
      {columns.map((val, idx) => {
        const valueArr = Array.isArray(val.value) ? val.value : [val.value];
        const checked = valueArr.length > 0 && valueArr.every((str) => internalColumns.includes(str));
        return (
          <React.Fragment key={idx}>
            <CheckBoxInput
              checked={checked}
              text={<div className="text-xs font-normal">{val.label}</div>}
              onChange={() => {
                setInternalColumns((prev) => {
                  if (checked) {
                    return prev.filter((d) => !valueArr.includes(d));
                  }
                  return uniqueArray([...prev, ...valueArr]);
                });
              }}
            />
            {columns.length - 1 !== idx && <div className="mb-1" />}
          </React.Fragment>
        );
      })}
    </motion.div>
  );
};
