import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  handleChangeOrdertypeGlobal,
  onChangeCheckBoxDeliveryGlobal,
} from "../../../../../helpers/OrdersFunctionsGlobal";
import { postServiceShiping } from "../../../../../redux/Actions/Service/serviceAction";
import { getPickupAddressesOption } from "../../../../../redux/Actions/pickupAddressAction";
import { MainBlock, SelectForm } from "../../../../common/Buttons";
import ShippingComponent from "../../../../common/GlobalModals/Orders/ShippingComponent";
import { shipment_types_options } from "../../../../../helpers/GlobalOptions";
import { getRivileStoreOptions } from "../../../../../redux/Actions/rivileStoreAction";
import { lengthOfCharactersDifference } from "../../../../../helpers/Utils";

function ServiceRepairShipment({
  shipmentAddr,
  service,
  parcel_terminals,
  shipments,
  id,
  onChangeCol,
}) {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    carrying: false,
    cod: false,
    sendingDocuments: false,
    deliveryTime: false,
    deliveryOnSaturday: false,
    terminalSelected: undefined,
    swap: false,
    deliveryTimeHours: ["", ""],
    who: "",
    order_type: "dpd",
    delivery: "dpd_b2c",
    suppliers: undefined,
    weight: 0.1,
    pack: 1,
    cod_amount: "",
    order_return: "",
    lp_express_sizes: undefined,
    shipping_loading: false,
    departament_id: undefined,
  });

  const [shipmentAddress, setShipmentAddress] = useState({
    ...shipmentAddr,
    comment: "",
  });

  const [pickupAddressData, setPickupAddressData] = useState([]);
  const [departamentOptions, setDepartamentOptions] = useState([]);

  const [orderRows, setOrderRows] = useState(
    service.service_rows
      ? service.service_rows.map((e) => ({
          id: e.id,
          quantity: 1,
          product_name: e.item_name,
          unit_price_tax_incl: e.item_price,
        }))
      : []
  );

  const [shipmentRowForStore, setShipmentRowForStore] = useState(
    service.service_rows
      ? service.service_rows.map((e) => ({
          id: e.id,
          product_reference: null,
          title: e.item_name,
          price: e.item_price,
          quantity: 1,
          edit: false,
          changes: false,
        }))
      : []
  );

  const [selectedOrderRows, setSelectedOrderRows] = useState(
    service.service_rows ? service.service_rows.map((e) => e.id) : []
  );

  const [errors, setErrors] = useState({});

  //

  useEffect(() => {
    if (Object.entries(shipmentAddr).length === 0) {
      return;
    }
    setShipmentAddress({
      comment: "",
      ...shipmentAddress,
      ...shipmentAddr,
    });
  }, [shipmentAddr]);

  useEffect(() => {
    getPickupAddressesOption().then((response) => {
      if (response.data) {
        let newPickupAdress = [];
        response.data.data.forEach((address) => {
          let newAddressObj = {
            ...address,
            value: address.id,
            label: `${address.company} - ${address.address} - ${address.city} `,
          };
          return newPickupAdress.push(newAddressObj);
        });
        setPickupAddressData(newPickupAdress);
      }
    });
    getRivileStoreOptions().then((array) => {
      setDepartamentOptions(
        array.map((e) => ({
          value: e.id,
          label: `${e.title} - ${e.address} - ${e.rivile_code}`,
          title: e.title,
          address: e.address,
          city: e.city,
          postal_code: e.postal_code,
          phone: e.phone || "",
        }))
      );
    });
  }, []);

  useEffect(() => {
    // cia
    if (service.service_rows) {
      const cod_amount = service.service_rows
        .filter((f) => selectedOrderRows.includes(f.id))
        .reduce((acc, cur) => acc + cur.item_price, 0);
      setState({
        ...state,
        cod_amount,
      });
      const serviceOr = service.service_rows
        ? service.service_rows.map((e) => ({
            id: e.id,
            quantity: 1,
            product_name: e.item_name,
            unit_price_tax_incl: e.item_price,
          }))
        : [];

      setOrderRows(serviceOr);
    }
  }, [service.service_rows, selectedOrderRows, state.order_type]);

  // Functions
  const findDepartmentInfo = (id) => {
    const findDep = departamentOptions.find((e) => e.value == id);
    if (!findDep) return {};
    return {
      firstname: findDep.title,
      lastname: `${shipmentAddr.firstname ? `${shipmentAddr.firstname} ${shipmentAddr.lastname || ""}` : findDep.title}`,
      street: findDep.address,
      postal_code: findDep.postal_code,
      city: findDep.city,
      phone: findDep.phone,
    };
  };

  const shipmentRowForStoreFunction = (action, index, e) => {
    let shipmentRowForStoreArr = [...shipmentRowForStore];
    if (action === "push") {
      shipmentRowForStoreArr = [
        ...shipmentRowForStoreArr,
        {
          id: null,
          product_reference: null,
          quantity: 1,
          title: "",
          price: 0,
          edit: true,
          changes: false,
        },
      ];
    } else if (action === "edit") {
      shipmentRowForStoreArr[index].changes = true;
      shipmentRowForStoreArr[index][e.target.name] = e.target.value;
    } else {
      shipmentRowForStoreArr.splice(index, 1);
    }
    setShipmentRowForStore(shipmentRowForStoreArr);
  };

  const onChangeDate = (e, date) => {
    setState({
      ...state,
      [date]: e,
    });
  };

  const handleChangeOption = (e, select) => {
    if (select) {
      if (select == "departament_id") {
        setShipmentAddress(findDepartmentInfo(e));
      }
      setState({
        ...state,
        [select]: e,
      });
    } else {
      setShipmentAddress({
        ...shipmentAddress,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChangeText = (e, where) => {
    if (where == null) {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    } else {
      setShipmentAddress({
        ...shipmentAddress,
        [e.target.name]: e.target.value,
      });
    }
  };

  const createShipping = () => {
    // const findDepartmentLabel = (value) => {
    //   const findDepartment = departamentOptions.find((e) => e.value == value);
    //   if (findDepartment) {
    //     return findDepartment.label;
    //   }
    //   return value;
    // };
    setState({
      ...state,
      shipping_loading: true,
    });

    const orderTypeAtTheStore = state.order_type === "at_the_store";
    // Model

    let orderVariables = {
      shipment: {
        // order_id: 7156,
        // service_order_id: 7156,
        // service_order_type: state.order_type,
        // service_row_ids: service.service_rows.map((e) => e.id),
        order_row_ids: [],
        // order_row_ids: service.service_rows.map((e) => e.id),
        shipment_type: state.order_type,
        packets: state.pack,
        // weight: state.weight,
        weight: orderTypeAtTheStore ? 0.1 : state.weight,

        pickup_address_id: state.suppliers,
        shipment_address_attributes: {
          company: "",
          name: shipmentAddress.firstname + " " + shipmentAddress.lastname,
          address: shipmentAddress.street,
          city: shipmentAddress.city,
          postal_code: shipmentAddress.postal_code,
          phone: shipmentAddress.phone,
          email: shipmentAddress.other,
          comment: shipmentAddress.comment,
          parcel_terminal_id: state.terminalSelected,
        },
        shipment_order_rows_attributes: orderTypeAtTheStore
          ? shipmentRowForStore.map((e) => ({
              quantity: e.quantity,
              title: e.title,
              price: e.price,
              order_row_id: null,
              service_row_id: e.id,
            }))
          : orderRows
              .filter((e) => selectedOrderRows.includes(e.id))
              .map((e) => ({
                quantity: e.quantity,
                title: e.product_name,
                price: e.unit_price_tax_incl,
                order_row_id: null,
                service_row_id: e.id,
              })),
        shipment_additional_services_attributes: [
          {
            service_key: "order_return",
            service_value: state.order_return ? 1 : 0,
          },
          {
            service_key: "cod",
            service_value: state.cod ? state.cod_amount : null,
          },
          {
            service_key: "sat",
            service_value: state.deliveryOnSaturday,
          },
          {
            service_key: "docret",
            service_value: state.sendingDocuments ? state.who : false,
          },
          {
            service_key: "time",
            service_value: state.deliveryTime
              ? `${state.deliveryTimeHours[0]} - ${state.deliveryTimeHours[1]}`
              : null,
          },
          {
            service_key: "delivery",
            service_value: state.delivery,
          },
          {
            service_key: "carrying",
            service_value: state.carrying,
          },
          {
            service_key: "swap",
            service_value: state.swap,
          },
          {
            service_key: "weight",
            service_value: state.weight,
          },
          {
            service_key: "pack",
            service_value: state.pack,
          },
          {
            service_key: "lp_express_sizes",
            service_value: state.lp_express_sizes,
          },
        ],
      },
    };

    dispatch(postServiceShiping(id, orderVariables)).then((res) => {
      if (res.success) {
        setErrors({});
      } else {
        setErrors(res.errors);
      }
      setState({
        ...state,
        shipping_loading: false,
      });
    });
  };

  const handleCheckBoxOrdersSelected = (value) => {
    const currentIndex = selectedOrderRows.indexOf(value);
    const newChecked = [...selectedOrderRows];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedOrderRows(newChecked);
  };

  return (
    <>
      {/*  */}

      <MainBlock
        color={"#00aaa0"}
        custom={true}
        action={true}
        onChange={onChangeCol}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="w-50" onClick={onChangeCol}>
            Siuntimas
          </div>
          <SelectForm
            options={shipment_types_options}
            selector="order_type"
            value={state.order_type}
            onChange={(e) =>
              setState({
                ...handleChangeOrdertypeGlobal(
                  e,
                  "order_type",
                  shipmentAddress,
                  shipmentAddress
                ),
              })
            }
          />
        </div>
      </MainBlock>
      {/*  */}
      <div className="bg-silver">
        <ShippingComponent
          order_type={state.order_type}
          delivery={state.delivery}
          mode="Servisas"
          shipping_address_edit={shipmentAddress}
          terminalSelected={state.terminalSelected}
          pickupAddress={pickupAddressData}
          order_rows={orderRows}
          parcel_terminals={parcel_terminals}
          shipments={shipments}
          selectedOrderRows={selectedOrderRows}
          errors={errors}
          variables={state}
          handleChangeText={handleChangeText}
          handleChangeOption={handleChangeOption}
          handleCheckBoxOrdersSelected={handleCheckBoxOrdersSelected}
          onChangeDate={onChangeDate}
          onChangeCheckBoxDelivery={(e) => {
            const value = e.target.name;
            let obj = {
              ...onChangeCheckBoxDeliveryGlobal(
                e,
                state.delivery,
                value == "pickup_from_department"
                  ? findDepartmentInfo(service.rivile_store_id)
                  : shipmentAddr
              ),
            };
            setShipmentAddress(obj.shipping_address_edit);
            delete obj.shipping_address_edit;
            let stateObj = {
              ...state,
              ...obj,
            };
            if (value == "pickup_from_department") {
              stateObj.departament_id = service.rivile_store_id;
              if (service.store_id) {
                const store = service.store;
                const takeFromArray = pickupAddressData
                  .map((e) => ({
                    value: e.value,
                    address: lengthOfCharactersDifference(
                      e.address.toLowerCase().split(" ").join(""),
                      store.address.toLowerCase().split(" ").join("")
                    ),
                  }))
                  .sort((a, b) => (a.address > b.address ? 1 : -1));
                if (takeFromArray.length) {
                  stateObj.suppliers = takeFromArray[0].value;
                }
              }
            }
            setState(stateObj);
          }}
          createShipping={createShipping}
          //
          shipmentRowForStore={shipmentRowForStore}
          shipmentRowForStoreFunction={shipmentRowForStoreFunction}
          departamentOptions={departamentOptions}
        />
      </div>
    </>
  );
}

export default ServiceRepairShipment;
