import { Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import useFetch from "../../../helpers/useFetch";
import { errorHandler, returnEuroString } from "../../../helpers/Utils";
import {
  add_substitutions_for_product,
  remove_substitutions_for_product,
} from "../../../redux/Actions/productAction";
import { ButtonExpandWithInput, ButtonTrash } from "../../common/Buttons";
import Spinner from "../../common/Spinner";

function TableFocusColumnProduct({
  item_id,
  TableColumnProduct,
  DivLine,
  available_edit,
}) {
  const [refetch, setreFetch] = useState(false);
  const { loading, value, setValue } = useFetch(
    `/api/v1/products/${item_id}/get_upsells`,
    {},
    [refetch]
  );

  const dispatch = useDispatch();
  const onSubmit = async (value) => {
    try {
      const { success, data, remake } = await dispatch(
        add_substitutions_for_product(item_id, value)
      );
      if (remake) {
        return setreFetch((prev) => !prev);
      }
      if (success) {
        setValue((prev) => [...prev, data]);
      }
      return success;
    } catch (err) {
      errorHandler(err);
    }
  };

  const onRemove = async (value, product_identifier) => {
    try {
      const { success } = await dispatch(
        remove_substitutions_for_product(item_id, value, product_identifier)
      );
      if (success) {
        setValue((prev) => prev.filter((d) => d.id !== value));
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  if (loading) {
    return (
      <TableColumnProduct className="align-middle" parentItem="#FCFCFC">
        <td colSpan={10}>
          <Spinner />
        </td>
      </TableColumnProduct>
    );
  }
  return (
    <>
      <TableColumnProduct className="align-middle" parentItem="#FCFCFC">
        <th className="align-middle"></th>
        <th className="align-middle" className="d-flex">
          <span style={{ width: "120px", height: "30px" }} />
          <DivLine width="33px" style={{ height: "45px" }}>
            {value.length > 0 ? (
              <svg
                width="55"
                height="100"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="25"
                  x2="25"
                  y1="0"
                  y2="75"
                  stroke="#8678AB"
                  strokeWidth="2"
                />
              </svg>
            ) : (
              <svg
                width="55"
                height="100"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="25"
                  x2="25"
                  y1="0"
                  y2="23"
                  stroke="#8678AB"
                  strokeWidth="2"
                />
                <line
                  x1="25"
                  x2="100%"
                  y1="22"
                  y2="22"
                  stroke="#8678AB"
                  strokeWidth="2"
                />
              </svg>
            )}
          </DivLine>
        </th>
        <th className="align-middle">Kodas</th>
        <th className="align-middle" colSpan={3}>
          Pavadinimas
        </th>
        <th className="align-middle">R-kaina</th>
        <th className="align-middle">E-kaina</th>
        <th className="align-middle">R.likutis</th>
        <th className="align-middle"></th>
      </TableColumnProduct>
      {value?.map((d, idx) => (
        <TableColumnProduct
          className="align-middle"
          parentItem="#FCFCFC"
          key={idx}
        >
          <td></td>
          <td className="d-flex align-middle">
            <span style={{ width: "120px", height: "30px" }} />
            <DivLine style={{ height: "45px" }} width="35px">
              <svg
                width="55"
                height="100"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="25"
                  x2="25"
                  y1="0"
                  y2="25"
                  stroke="#8678AB"
                  strokeWidth="2"
                />
                <line
                  x1="25"
                  x2="100%"
                  y1="25"
                  y2="25"
                  stroke="#8678AB"
                  strokeWidth="2"
                />
                {idx + 1 !== value.length && (
                  <line
                    x1="25"
                    x2="25"
                    y1="0"
                    y2="80"
                    stroke="#8678AB"
                    strokeWidth="2"
                  />
                )}
              </svg>
            </DivLine>
          </td>
          <td className="align-middle">
            {" "}
            <img
              className="mr-2"
              src={`https://pim.technorama.lt/media/cache/thumbnail_small/${d.identifier_product.image_url}`}
              alt={`product_children_${d.identifier_product.title}`}
              style={{
                width: 38,
                height: 38,
              }}
            />
            {d.identifier_product.identifier}
          </td>
          <td className="align-middle" colSpan={3}>
            {d.identifier_product.title}
            <br />
            <Tooltip placement="top" title={"Įtraukimo data"}>
              <span className="text-xs" style={{ color: "#A9A9A9" }}>
                {moment(d.updated_at).format("YYYY-MM-DD")}
              </span>
            </Tooltip>
          </td>
          <td className="align-middle">
            {returnEuroString(d.identifier_product.r_price)}
          </td>
          <td className="align-middle">
            {returnEuroString(d.identifier_product.e_price)}
          </td>
          <td className="align-middle">
            {d?.identifier_product?.product_suppliers?.reduce(
              (cur, item) => +item.quantity || 0 + cur,
              0
            )}
          </td>
          <td className="align-middle">
            {available_edit && (
              <Tooltip placement="left" title={"Pašalinti iš Fokus"}>
                <div className="">
                  <ButtonTrash
                    onClick={() =>
                      onRemove(d.id, d.identifier_product.identifier)
                    }
                  />
                </div>
              </Tooltip>
            )}
          </td>
        </TableColumnProduct>
      ))}
      {available_edit && (
        <TableColumnProduct className="align-middle" parentItem="#FCFCFC">
          <td colSpan={2}></td>
          <td colSpan={8}>
            <ButtonExpandWithInput onClick={onSubmit} />
          </td>
        </TableColumnProduct>
      )}
    </>
  );
}

export default TableFocusColumnProduct;
