import React from "react";
import Container from "../Layout/Container";
import { connect } from "react-redux";
import {
  getPaymentTypes,
  updatePaymentTypes,
} from "../../redux/Actions/paymentTypeAction";
import RivilePaymentTypesEditModal from "./Items/RivilePaymentTypesEditModal";
import ContentBlock from "../Layout/ContentBlock";
import TableColumnRivilePaymentTypes from "./Items/TableColumnRivilePaymentTypes";
import FilterMenu from "../Layout/FilterMenu";
import TableFilterBox from "../common/TableFilterBox";
import ContentValidation from "../common/ContentValidation";
import PaginationComp from "../Dashboard/items/Pagination";

class RivilePaymentTypes extends React.Component {
  constructor() {
    super();
    this.state = {
      requiredId: 0,
      page: 1,
      pageCount: 100,
    };
  }
  componentDidMount() {
    this.props.getPaymentTypes(this.state.page, this.state.pageCount);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.props.getPaymentTypes(this.state.page, this.state.pageCount);
    }
  }

  onAction = (index) => {
    this.setState({
      requiredId: index,
    });
  };

  setPagination = (page) => {
    this.setState({
      page,
    });
  };

  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  render() {
    const requiredItem = this.state.requiredId;
    let modalData = this.props.payment_types[requiredItem]
      ? this.props.payment_types[requiredItem]
      : [];
    return (
      <Container
        location={{
          locationName: "Mokėjimo tipai",
          locationUrl: this.props.match.url,
        }}
      >
        <ContentBlock>
          <FilterMenu
            length={`${this.props.totals || 0} / ${
              this.props.payment_types.length || 0
            }`}
            onChangeCountPage={this.setPageCount}
            countPage={this.state.pageCount}
            text="mokėjimo tipų"
            text2="Mokėjimo tipų"
          />
        </ContentBlock>
        <ContentBlock>
          <TableFilterBox filter={false} add={false}></TableFilterBox>
          <div>
            <ContentValidation
              loader={this.props.payment_types_loading}
              array={this.props.payment_types}
              alertId_s="paymentTypes-s"
              //   alertId_e="rivileAlert-e"
            >
              <div className="table-responsive">
                <table className="table table-sm table-hover mt-3">
                  <thead>
                    <tr className="text-black">
                      <th>#</th>
                      <th>Pavadinimas</th>
                      <th>Modulio pavadinimas</th>
                      <th>Rivile kodas</th>
                      <th>Kliento kodas</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.payment_types.map((item, index) => {
                      return (
                        <TableColumnRivilePaymentTypes
                          key={index}
                          item={item}
                          index={index}
                          onAction={this.onAction}
                        />
                      );
                    })}
                  </tbody>
                </table>
                <PaginationComp
                  setPagination={this.setPagination}
                  page={this.state.page}
                  totals={this.props.totals}
                  pageCount={this.state.pageCount}
                />
              </div>
            </ContentValidation>
          </div>
        </ContentBlock>

        <RivilePaymentTypesEditModal
          objectData={modalData}
          updatePaymentTypes={this.props.updatePaymentTypes}
        />
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  payment_types: state.paymentTypes.payment_types,
  totals: state.paymentTypes.totals,
  payment_types_loading: state.paymentTypes.payment_types_loading,
});

export default connect(mapStateToProps, {
  getPaymentTypes,
  updatePaymentTypes,
})(RivilePaymentTypes);
