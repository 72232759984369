import React from "react";
import Container from "../Layout/Container";
import { connect } from "react-redux";
import {
  getPrestaServicesItems,
  postServiceItem,
  deleteServiceItem,
  updateServiceItem,
  clearErrors,
} from "../../redux/Actions/prestaAction";
import ServiceEditModal from "./Items/ServiceEditModal";
import ContentBlock from "../Layout/ContentBlock";
import TableColumnServices from "./Items/TableColumnServices";
import FilterMenu from "../Layout/FilterMenu";
import TableFilterBox from "../common/TableFilterBox";
import ContentValidation from "../common/ContentValidation";
import PaginationComp from "../Dashboard/items/Pagination";
import InputText from "../orders/accepted_orders/InputFilter";

class PrestaServices extends React.Component {
  constructor() {
    super();
    this.state = {
      requiredId: 0,
      page: 1,
      pageCount: 100,
      paramsFilter: "",
      action: "new",
      paramsFilter: "",
      filter: {
        category: "",
        sub_category: "",
        manufacturer: "",
        service_center: "",
        phone: "",
        address: "",
        email: "",
        comments: "",
      },
    };
  }
  componentDidMount() {
    this.props.getPrestaServicesItems(
      this.state.paramsFilter,
      this.state.page,
      this.state.pageCount
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.props.getPrestaServicesItems(
        this.state.paramsFilter,
        this.state.page,
        this.state.pageCount
      );
    }
  }

  onAction = (action, index) => {
    this.setState({
      requiredId: index,
      action: action,
    });
  };

  setPagination = (page) => {
    this.setState({
      page,
    });
  };

  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  handleKeyDown = (e) => {
    if (e.key == "Enter") {
      this.onFilter();
    }
  };

  onFilter = () => {
    let filterParams = [];

    if (this.props.loading_rivile) {
      return this.delayOnFilter();
    }

    if (this.state.filter.category.length > 0) {
      filterParams.push(`&q[category_cont]=${this.state.filter.category}`);
    }

    if (this.state.filter.sub_category.length > 0) {
      filterParams.push(
        `&q[sub_category_cont]=${this.state.filter.sub_category}`
      );
    }

    if (this.state.filter.manufacturer.length > 0) {
      filterParams.push(
        `&q[manufacturer_cont]=${this.state.filter.manufacturer}`
      );
    }

    if (this.state.filter.service_center.length > 0) {
      filterParams.push(
        `&q[service_center_cont]=${this.state.filter.service_center}`
      );
    }

    if (this.state.filter.phone.length > 0) {
      filterParams.push(`&q[phone_i_cont_any]=${this.state.filter.phone}`);
    }

    if (this.state.filter.address.length > 0) {
      filterParams.push(`&q[address_cont]=${this.state.filter.address}`);
    }

    if (this.state.filter.email.length > 0) {
      filterParams.push(`&q[email_cont]=${this.state.filter.email}`);
    }

    if (this.state.filter.comments.length > 0) {
      filterParams.push(`&q[comments_cont]=${this.state.filter.comments}`);
    }

    this.setState(
      {
        paramsFilter: filterParams.join(""),
        page: 1,
      },
      () => this.reloadData(filterParams.join(""))
    );
  };

  clearFilter = () => {
    this.setState(
      {
        paramsFilter: "",
        filter: {
          category: "",
          sub_category: "",
          manufacturer: "",
          service_center: "",
          phone: "",
          address: "",
          email: "",
          comments: "",
        },
      },
      () => this.reloadData("")
    );
  };

  reloadData = (data) => {
    this.props.getPrestaServicesItems(
      data,
      this.state.page,
      this.state.pageCount
    );
  };

  filterChange = (e, date) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [e.target.name]: e.target.value,
      },
    });
  };

  render() {
    const requiredItem = this.state.requiredId;
    let modalData = this.props.serviceItems[requiredItem]
      ? this.props.serviceItems[requiredItem]
      : [];
    return (
      <Container
        location={{
          locationName: "Servisų kontaktai",
          locationUrl: this.props.match.url,
        }}
      >
        <ContentBlock>
          <FilterMenu
            length={`${this.props.totals || 0} / ${
              this.props.serviceItems.length || 0
            }`}
            onChangeCountPage={this.setPageCount}
            countPage={this.state.pageCount}
            text="servisų"
            text2="Servisų"
          />
        </ContentBlock>
        <ContentBlock>
          <TableFilterBox
            clearFilter={() => this.clearFilter()}
            add={this.props.auth.user_role.identifier == "hosts" ? false : this.onAction}
          >
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="category"
                placeholder="Įrangos kategorija"
                value={this.state.filter.category}
                onKeyDown={this.handleKeyDown}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="sub_category"
                placeholder="Sub. kategorija"
                value={this.state.filter.sub_category}
                onKeyDown={this.handleKeyDown}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="manufacturer"
                placeholder="Gamintojas"
                value={this.state.filter.manufacturer}
                onKeyDown={this.handleKeyDown}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="service_center"
                placeholder="Garantinio aptarnavimo centras"
                value={this.state.filter.service_center}
                onKeyDown={this.handleKeyDown}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="phone"
                placeholder="Telefonas"
                value={this.state.filter.phone}
                onKeyDown={this.handleKeyDown}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="address"
                placeholder="Adresas"
                value={this.state.filter.address}
                onKeyDown={this.handleKeyDown}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="email"
                placeholder="El.paštas"
                value={this.state.filter.email}
                onKeyDown={this.handleKeyDown}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="comments"
                placeholder="Komentarai"
                value={this.state.filter.comments}
                onKeyDown={this.handleKeyDown}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
          </TableFilterBox>
          <div>
            <ContentValidation
              loader={this.props.loading_service_items}
              array={this.props.serviceItems}
              alertId_s="serviceAlert-s"
              alertId_e="serviceAlert-e"
            >
              <div className="table-responsive">
                <table className="table table-sm table-hover mt-3">
                  <thead>
                    <tr className="text-muted">
                      <th className="text-black">Įrangos kategorija</th>
                      <th className="text-black">Sub. kategorija</th>
                      <th className="text-black">Gamintojas</th>
                      <th className="text-black">
                        Garantinio aptarnavimo centras
                      </th>
                      <th className="text-black">Telefonas</th>
                      <th className="text-black">Adresas</th>
                      <th className="text-black">El.paštas</th>
                      <th className="text-black">Komentarai</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.serviceItems.map((item, index) => {
                      return (
                        <TableColumnServices
                          auth={this.props.auth}
                          key={index}
                          index={index}
                          id={item.id}
                          item={item}
                          onAction={this.onAction}
                          onDelete={this.props.deleteServiceItem}
                        />
                      );
                    })}
                  </tbody>
                </table>
                <PaginationComp
                  setPagination={this.setPagination}
                  page={this.state.page}
                  totals={this.props.totals}
                  pageCount={this.state.pageCount}
                />
              </div>
            </ContentValidation>
          </div>
        </ContentBlock>

        <ServiceEditModal
          action={this.state.action}
          postServiceItem={this.props.postServiceItem}
          updateServiceItem={this.props.updateServiceItem}
          objectData={modalData}
          clearErrors={this.props.clearErrors}
          errors={this.props.errors}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.user,
  serviceItems: state.presta.serviceItems,
  totals: state.presta.totals,
  loading_service_items: state.presta.loading_service_items,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getPrestaServicesItems,
  postServiceItem,
  deleteServiceItem,
  updateServiceItem,
  clearErrors,
})(PrestaServices);
