import React from "react";
import Container from "../../Layout/Container";
import { connect } from "react-redux";
import {
  postDocumentType,
  getDocumentType,
  updateDocumentType,
  deleteDocumentType,
  clearErrors,
} from "../../../redux/Actions/documentTypeAction";
import {
  postDocumentPlan,
  updateDocumentPlan,
  deleteDocumentPlan,
} from "../../../redux/Actions/documentPlanAction";
import DocumentBlock from "./items/documentBlock";
import styled from "styled-components";
import DocumentModal from "./items/documentModal";
import DocumentModalType from "./items/documentModalType";
import { AlertSuccess, AlertError } from "../../common/Alerts";
import { getRivileServicesOption } from "../../../redux/Actions/rivileServiceAction";

class DocumentOption extends React.Component {
  constructor() {
    super();
    this.state = {
      requiredId: 0,
      action: "new",

      documentId: { label: "text", value: 0 },
      actionPlan: "new",
      documentPlan: {},
      rivile_services: [],
    };
  }

  componentDidMount() {
    this.props.getDocumentType();
    getRivileServicesOption().then((rivile_services) => {
      this.setState({
        rivile_services,
      });
    });
  }

  onAction = (action, index) => {
    this.setState({
      requiredId: index,
      action: action,
    });
    this.removeErrors(this.props.errors);
  };

  createNewPlan = (object) => {
    this.setState({
      documentId: object,
      actionPlan: "new",
    });
    this.removeErrors(this.props.errors);
  };
  updateDocumentPlan = (items, object) => {
    this.setState({
      actionPlan: "edit",
      documentPlan: items,
      documentId: object,
    });
    this.removeErrors(this.props.errors);
  };

  removeErrors = (errors) => {
    if (Object.keys(errors).length > 0) {
      this.props.clearErrors();
    }
  };

  deleteDocumentPlan = (typeID, id, name) => {
    if (confirm(`Ar pašalinti ${name} ?`)) {
      this.props.deleteDocumentPlan(typeID, id);
    }
  };

  render() {
    const requiredItem = this.state.requiredId;
    let modalData = this.props.documentType[requiredItem]
      ? this.props.documentType[requiredItem]
      : [];
    return (
      <Container
        location={{
          locationName: "Dokumentų nustatymai",
          locationUrl: this.props.match.url,
        }}
      >
        <ContainerTop className="col-12 my-3 d-flex justify-content-between align-items-center">
          <span className="Top_text">Dokumentų planai</span>
          <button
            className="btn Top_button"
            data-toggle="modal"
            data-target="#tabletype"
            onClick={() => this.onAction("new")}
          >
            Pridėti naują tipą
          </button>
        </ContainerTop>
        <AlertSuccess alertId={"document-s"} />
        <AlertError alertId={"document-e"} />
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {!this.props.loading_documentType &&
            this.props.documentType.map((item, i) => {
              return (
                <DocumentBlock
                  rivile_services={this.state.rivile_services}
                  key={i}
                  index={i}
                  item={item}
                  createNewPlan={this.createNewPlan}
                  postDocumentPlan={this.props.postDocumentPlan}
                  deleteDocumentPlan={this.deleteDocumentPlan}
                  documentTypes={this.props.documentType}
                  deleteDocumentType={this.props.deleteDocumentType}
                  updateDocumentPlan={this.updateDocumentPlan}
                  onAction={this.onAction}
                />
              );
            })}
        </div>
        <DocumentModalType
          postDocumentType={this.props.postDocumentType}
          updateDocumentType={this.props.updateDocumentType}
          modalData={modalData}
          action={this.state.action}
          errors={this.props.errors}
          clearErrors={this.props.clearErrors}
        />
        <DocumentModal
          rivile_services={this.state.rivile_services}
          action={this.state.actionPlan}
          documentId={this.state.documentId}
          documentPlan={this.state.documentPlan}
          postDocumentPlan={this.props.postDocumentPlan}
          updateDocumentPlan={this.props.updateDocumentPlan}
          errors={this.props.errors}
          clearErrors={this.props.clearErrors}
        />
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  documentType: state.documentType.documentType,
  loading_documentType: state.documentType.loading_documentType,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getDocumentType,
  postDocumentType,
  updateDocumentType,
  deleteDocumentType,
  postDocumentPlan,
  updateDocumentPlan,
  deleteDocumentPlan,
  clearErrors,
})(DocumentOption);

const ContainerTop = styled.div`
  .Top_button {
    color: black;
    font-weight: bold;
    padding: 5px 5px;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    font-family: "Barlow";
    font-weight: 900;
    width: 129px;
    height: 37.5px;
    border-radius: 4px;
    background-color: #ffffff;
  }
  .Top_text {
    font-size: 19.5px;
    line-height: 14px;
    color: #212121;
    font-family: "Barlow";
    font-weight: 900;
  }
`;
