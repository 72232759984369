import Axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { errorHandler, first_arr, last_arr } from "../../../../helpers/Utils";
import { DebounceSelect } from "../../../common";
import ButtonSecondary from "../../../common/ButtonSecondary";
import TextInputModal from "../../../common/TextInputModal";
import { IconTrash } from "../../../Layout/Icons";
import WYSIWYG from "./WYSIWYG";

const ThirdModalPreview = ({ value, setValue, modalType }) => {
  console.log("ThirdModalPreview value:  ", { value });

  const onMainChange = ({ target: { value, name } }) => {
    // const { value, name } = e.target;
    setValue((prev) => {
      let obj = { ...prev };
      obj.steps[first_arr(modalType.steps)].answers[last_arr(modalType.steps)][
        name
      ] = value;
      return obj;
    });
  };

  const setAttributes = (attr) => {
    setValue((prev) => {
      let obj = { ...prev };
      obj.steps[first_arr(modalType.steps)].answers[
        last_arr(modalType.steps)
      ].additionals = attr;
      return obj;
    });
  };

  return (
    <>
      <div>
        <TextInputModal
          text="Atsakymas"
          name="title"
          value={value.title}
          onChange={onMainChange}
        />

        <WYSIWYG value={value.description} onChange={onMainChange} />
        <AttributesTable
          additionals={value.additionals}
          setAttributes={(val) => setAttributes(val)}
        />
      </div>
    </>
  );
};

export default ThirdModalPreview;

const AttributesTable = ({ additionals, setAttributes }) => {
  const onHandleChange = (type, value) => {
    if (type == "add")
      return setAttributes([
        ...additionals,
        {
          attribute: "",
          meaning: [],
        },
      ]);
    let attr = [...additionals];
    if (type == "remove") {
      attr.splice(value, 1);
      return setAttributes(attr);
    }
    attr[value.idx][value.name] = value.value;
    if (value.name == "attribute") {
      attr[value.idx]["meaning"] = [];
    }
    return setAttributes(attr);
  };

  return (
    <>
      <label className={"editLabelForm text-bold my-1 "}>Atributai</label>

      <div
        style={{
          background: "#FFFFFF",
          border: "1px solid #D9D9D9",
          borderRadius: 2,
        }}
      >
        {additionals.map((d, idx) => {
          return (
            <Additional
              key={`additional_${idx}`}
              idx={idx}
              {...d}
              onHandleChange={onHandleChange}
            />
          );
        })}
        <div className="d-flex align-items-center justify-content-center">
          <div
            className="my-2"
            style={{
              width: 203,
            }}
          >
            <ButtonSecondary
              outline={true}
              onClick={() => onHandleChange("add")}
            >
              Pridėti naują atributą
            </ButtonSecondary>
          </div>
        </div>
      </div>
    </>
  );
};

const fetchFamiliesOptions = async (value) => {
  try {
    let api_url = `/api/v1/families_attributes?q[title_or_code_i_cont_any]=${value}&q[code_not_eq]=gamintojas_46233`;

    const { data } = await Axios.get(api_url);
    const valArr = data?.data?.filter((d) => d.title) || [];
    if ("Gamintojas (manufacturer)".toLowerCase().indexOf(value) > -1) {
      valArr.push({
        code: "manufacturer",
        title: "Gamintojas (manufacturer)",
      });
    }
    return (
      valArr.map((e) => {
        return {
          ...e,
          value: e.code,
          label: e.title,
        };
      }) || []
    );
  } catch (err) {
    errorHandler(err);
    return [];
  }
};

const fetchFamiliesOptOptions = (attribute) => async (value) => {
  try {
    const controller_url =
      attribute == "manufacturer"
        ? "brands?"
        : `families_attributes/family_attribute_options?family_attribute_code=${attribute}`;

    let api_url = `/api/v1/${controller_url}&q[title_i_cont_any]=${value}&q[title_i_cont_any]=${value}`;
    if (Array.isArray(value)) {
      api_url = `/api/v1/${controller_url}`;
      value.forEach((d) => {
        if (attribute == "manufacturer") {
          api_url += `&q[title_in][]=${d}`;
        } else {
          api_url += `&q[title_or_code_in][]=${d}`;
        }
      });
    }
    const { data } = await Axios.get(api_url);
    const valArr = data?.data?.filter((d) => d.title) || [];

    return (
      valArr.map((e) => {
        return {
          ...e,
          value: e.code || e.title,
          label: e.title,
        };
      }) || []
    );
  } catch (err) {
    errorHandler(err);
    return [];
  }
};

const Additional = ({ idx, attribute, meaning, onHandleChange }) => {
  const [options, setOptions] = useState({
    attributes: [],
    meanings: [],
  });
  useEffect(() => {
    if (attribute && meaning) {
      const fetcher = async () => {
        const attr = await fetchFamiliesOptions(attribute);
        const mean = await fetchFamiliesOptOptions(attribute)(meaning);
        console.log({ mean });
        console.log({ attr });
        setOptions((prev) => ({
          ...prev,
          attributes: attr,
          meanings: mean,
        }));
      };
      fetcher();
    }
  }, []);
  return (
    <div className="col-12">
      <div
        className="row px-3 py-2"
        style={{
          borderBottom: "1px solid #D9D9D9",
        }}
      >
        <div className="col-4">
          <DebounceSelect
            options={options.attributes}
            showSearch={true}
            fetchOptions={fetchFamiliesOptions}
            style={{
              width: "100%",
            }}
            //
            placeholder="Atributas (filtras)"
            value={attribute || undefined}
            onChange={(value) => {
              onHandleChange("", {
                idx,
                name: "attribute",
                value: value.value,
              });
            }}
          />
        </div>
        <div className="col-6">
          <DebounceSelect
            options={options.meanings}
            disabled={!attribute}
            mode="multiple"
            showSearch={true}
            fetchOptions={fetchFamiliesOptOptions(attribute)}
            style={{
              width: "100%",
            }}
            //
            placeholder="Reikšmė"
            value={meaning || undefined}
            onChange={(value) => {
              console.log(value);
              onHandleChange("", {
                idx,
                name: "meaning",
                value: value.map((d) => d.value),
              });
            }}
          />
        </div>
        <div className="col-2 d-flex align-items-center justify-content-center">
          <IconTrash
            onClick={() => {
              onHandleChange("remove", idx);
            }}
          />
        </div>
      </div>
    </div>
  );
};
