import React from "react";
import InputForm from "../../common/InputForm";
import ModalToChange from "../../Layout/ModalToChange";

class RivilePaymentTypesEditModal extends React.Component {
  constructor() {
    super();
    this.state = {
      id: 0,
      title: "",
      rivile_code: "",
      module_name: "",
      client_code: "",
      errors: {},
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      id: nextProps.objectData.id,
      title: nextProps.objectData.title,
      rivile_code: nextProps.objectData.rivile_code,
      client_code: nextProps.objectData.client_code,
      module_name: nextProps.objectData.module_name,
      errors: {},
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.title.length == 0) {
      let errors = {};
      if (this.state.title.length == 0)
        errors.title = "Neužpildytas pavadinimo laukelis";
      return this.setState({
        errors,
      });
    }
    const variables = {
      payment_type: {
        title: this.state.title,
        rivile_code: this.state.rivile_code,
        module_name: this.state.module_name,
        client_code: this.state.client_code,
      },
    };
    this.props.updatePaymentTypes(this.state.id, variables);
  };

  render() {
    const { errors } = this.state;
    return (
      <ModalToChange
        handleSave={(e) => this.onSubmit(e)}
        status="tableEdit"
        text={"Redaguoti"}
        buttonText={"Atnaujinti"}
      >
        <InputForm
          type="text"
          placeholder="Pavadinimas"
          name="title"
          value={this.state.title}
          error={errors.title}
          onChange={(e) => this.handleChange(e)}
        />
        <InputForm
          type="text"
          placeholder="Modulio pavadinimas"
          name="module_name"
          value={this.state.module_name}
          onChange={(e) => this.handleChange(e)}
        />
          <InputForm
          type="text"
          placeholder="Rivile kodas"
          name="rivile_code"
          value={this.state.rivile_code}
          onChange={(e) => this.handleChange(e)}
        />
          <InputForm
          type="text"
          placeholder="Kliento kodas"
          name="client_code"
          value={this.state.client_code}
          onChange={(e) => this.handleChange(e)}
        />
      </ModalToChange>
    );
  }
}

export default RivilePaymentTypesEditModal;
