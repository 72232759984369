import Axios from "axios";
import {
  GET_SHIPMENT_DPD,
  GET_SHIPMENT_OMNIVA,
  GET_SHIPMENT_LP_EXPRESS,
  LOADING_SHIPMENT_DPD,
  LOADING_SHIPMENT_OMNIVA,
  LOADING_SHIPMENT_LP_EXPRESS,
  GET_SHIPMENT_OWN_TRANSPORT,
  REMOVE_SHIPMENT,
  GET_ERRORS,
  CREATE_ORDER_STATUS,
} from "./types";
import { success, error } from "./modalsFunctios";
import { message } from "antd";
import {
  antdAsyncMessageError,
  antdAsyncMessageSuccess,
  antdAsyncMessageLoading
} from "../../helpers/AsyncMessage";

const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

export const getCourrierSummary = async (type, filter) => {
  try {
    const { data } = await Axios({
      url: `/api/v1/order_shipments/c/${type}/summary?${filter}`,
      data: {},
      method: "POST",
      responseType: "blob", // important
      headers: { Accept: "application/vnd.ms-excel" },
    });
    const downloadUrl = window.URL.createObjectURL(new Blob([data]));

    const link = document.createElement("a");

    link.href = downloadUrl;

    link.setAttribute("download", `${`Ataskaita_${type}`}.xlsx`);

    document.body.appendChild(link);

    link.click();

    link.remove();
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const getOwnTransportSummary = async (page, pageCount, filter) => {
  try {
    const { data } = await Axios({
      url: `/api/v1/order_shipments/pickups_download?page=${page}&per_page=${pageCount}${filter}`,
      data: {},
      method: "POST",
      responseType: "blob", // important
      headers: { Accept: "application/vnd.ms-excel" },
    });
    const downloadUrl = window.URL.createObjectURL(new Blob([data]));

    const link = document.createElement("a");

    link.href = downloadUrl;

    link.setAttribute("download", `${`atsiemimai`}.xlsx`);

    document.body.appendChild(link);

    link.click();

    link.remove();
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const getShipmentsDpd = (page, pageCount, filter) => (dispatch) => {
  dispatch(setShipmentLoadingDpd());
  Axios.get(
    `/api/v1/order_shipments/c/dpd?page=${page}&per_page=${pageCount}${filter}`
  )
    .then((res) => {
      dispatch({
        type: GET_SHIPMENT_DPD,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const getShipments = (type, page, pageCount, filter) => (dispatch) => {
  dispatch(setShipmentLoading());
  Axios.get(
    `/api/v1/order_shipments/c/${type}?page=${page}&per_page=${pageCount}${filter}`
  )
    .then((res) => {
      dispatch({
        type: "GET_SHIPMENT",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const shipmentUserPickup = (params, order_id) => async (dispatch) => {
  try {
    const { data } = await Axios.post(`/api/v1/shipments/user_pickup`, params);
    if (data.success == false) {
      return { success: false, error: data.errors || "Įvyko klaida." };
    }
    success("orderShippment-s");
    dispatch({
      type: "CUSTOMER_PICKUP_CALLED",
      payload: { shipment_id: params.pickup.shipment_id },
    });
    return { success: data.success };
  } catch (err) {
    console.log("Error: ", err);
    error("orderShippment-e");
    return {
      success: false,
      error: err?.response?.data?.errors || "Įvyko klaida.",
    };
  }
};

export const getShipmentsOmniva = (page, pageCount, filter) => (dispatch) => {
  dispatch(setShipmentLoadingOmniva());
  Axios.get(
    `/api/v1/order_shipments/c/omniva?page=${page}&per_page=${pageCount}${filter}`
  )
    .then((res) => {
      dispatch({
        type: GET_SHIPMENT_OMNIVA,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getShipmentsLPexpress =
  (page, pageCount, filter) => (dispatch) => {
    dispatch(setShipmentLoadingLPexpress());
    Axios.get(
      `/api/v1/order_shipments/c/lp_express?page=${page}&per_page=${pageCount}${filter}`
    )
      .then((res) => {
        dispatch({
          type: GET_SHIPMENT_LP_EXPRESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      });
  };

export const getShipmentsOwnTransport =
  (page, pageCount, filter) => (dispatch) => {
    dispatch(setShipmentLoading());
    Axios.get(
      `/api/v1/order_shipments/c/own_transport?page=${page}&per_page=${pageCount}${filter}`
    )
      .then((res) => {
        dispatch({
          type: GET_SHIPMENT_OWN_TRANSPORT,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      });
  };

export const removeShipmentBeforeCalled =
  (id, order_id, position) => async (dispatch) => {
    try {
      antdAsyncMessageLoading({
        key: "removeShipmentBeforeCalled",
      });
      const { data } = await Axios.delete(
        `/api/v1/orders/${order_id}/shipments/${id}`
      );

      if (data.success) {
        if (position && !["FakeOrder", "Order"].includes(position)) {
          dispatch({
            type: REMOVE_SHIPMENT,
            payload: { position: position, id: id },
          });
        } else {
          dispatch({
            type: "REMOVE_SHIPMENT_ORDER",
            payload: {
              orderId: order_id,
              id: id,
            },
          });
        }
        antdAsyncMessageSuccess({
          key: "removeShipmentBeforeCalled",
        });
        return true;
      }
      throw new Error("ERR");
    } catch (err) {
      antdAsyncMessageError({
        key: "removeShipmentBeforeCalled",
      });
      return false;
    }
  };

export const setPickupGiveAway =
  (order_id, shipment_id, pickup_request_id) => async (dispatch) => {
    try {
      await message.loading("Vykdoma..", 2.5);
      const { data } = await Axios.post(
        `/api/v1/orders/${order_id}/shipments/${shipment_id}/set_pickup_give_away/`,
        {
          pickup_request_id,
        }
      );
      if (data.order_histories) {
        dispatch({
          type: CREATE_ORDER_STATUS,
          payload: data.order_histories,
        });
      }
      if (data.comments) {
        dispatch({
          type: "UPDATE_COMMENTS_MASS",
          payload: data.comments,
        });
      }
      if (data.shipment) {
        dispatch({
          type: "SHIPMENT_UPDATE",
          payload: data.shipment,
        });
      }
      await message.success("Sėkmingai įvykdyta", 2.5);
    } catch (err) {
      console.log("Error: ", err);
      await message.error("Vykdant įvyko klaida", 2.5);
    }
  };

export const setShipmentLoading = () => {
  return {
    type: "LOADING_SHIPMENT",
  };
};
export const setShipmentLoadingDpd = () => {
  return {
    type: LOADING_SHIPMENT_DPD,
  };
};
export const setShipmentLoadingOmniva = () => {
  return {
    type: LOADING_SHIPMENT_OMNIVA,
  };
};

export const setShipmentLoadingLPexpress = () => {
  return {
    type: LOADING_SHIPMENT_LP_EXPRESS,
  };
};
