import React from "react";
import { CheckBoxInput } from "../../common/Buttons";
import { GoPrimitiveDot } from "react-icons/go";
import { FaEye } from "react-icons/fa";
import moment from "moment";
import { returnEuroString } from "../../../helpers/Utils";

function TableColumnDocuments({
  id,
  item,
  index,
  checkedArray,
  handleCheckboxChange,
  onEdit,
  role,
  orderModal
}) {
  return (
    <tr className="align-middle tableColumn" key={index}>
      <td>
        {" "}
        <CheckBoxInput
          type="checkbox"
          color="#f7951e"
          checked={checkedArray.includes(id)}
          onChange={() => handleCheckboxChange(id)}
        />
      </td>
      <td
        id={item.id}
        style={{ cursor: "pointer" }}
        data-toggle="modal"
        data-target="#documentModal"
        onClick={() => onEdit(index, id)}
      >
        <FaEye
          className="text-info"
          // className="btn btn-sm "
        />
      </td>
      <td>
        {item.created_at ? moment(item.created_at).format("YYYY-MM-DD") : ""}
      </td>
      <td>{item.buy_date ? moment(item.buy_date).format("YYYY-MM-DD") : ""}</td>
      {role !== "store_user" && <td>{item.user_title}</td>}
      <td>
        <GoPrimitiveDot
          title="Info"
          className={item.can_send ? "text-success" : "text-danger"}
        />
      </td>
      <td>
        <GoPrimitiveDot
          title="Išs"
          className={!!item?.contract_emails?.length ? "text-success" : "text-danger"}
        />
      </td>
      <td>
        <GoPrimitiveDot
          title="Ats"
          className={item.canceled_at ? "text-success" : "text-danger"}
        />
      </td>
      <td>{item.document_plan.name}</td>
      <td>{item.store ? item.store.name : "ONLINE"}</td>
      <td>{item.number}</td>
      <td>{item.customer}</td>
      <td>{item.email}</td>
      <td>{item.phone}</td>
      <td>
        {["store_manager", "store_user"].includes(role)
          ? item.order_id
            ? item.order && item.order.id_order
            : item.invoice_number
          : item.order_id
          ? item.order && (
              <a
                className="text-primary"
                onClick={() =>
                  orderModal(
                    item.order.id_order,
                    item.order.id
                  )
                }
              >
                {item.order.id_order}
              </a>
            )
          : item.invoice_number}
      </td>
      <td>{item.start_date}</td>
      <td>{item.end_date}</td>
      <td>{returnEuroString(item.total)}</td>
      <td>{returnEuroString(item.ppg_repair_amount)}</td>
    </tr>
  );
}

export default TableColumnDocuments;
