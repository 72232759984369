import React from "react";
import Container from "../Layout/Container";
import { connect } from "react-redux";
import {
  getRivileItems,
  getRivileItemTypes,
  getRivileItem,
  getRivileItemsSales,
  changeStatusAction,
  loadingRivileItems,
  changeStatusToExported,
  forceStatusToExported,
  getStores
} from "../../redux/Actions/rivileAction";
import ContentBlock from "../Layout/ContentBlock";
import TableColumnRivile from "./Items/TableColumnRivile";
import FilterMenu from "../Layout/FilterMenu";
import ContentValidation from "../common/ContentValidation";
import PaginationComp from "../Dashboard/items/Pagination";
import InputText from "../orders/accepted_orders/InputFilter";
import RangePickerComp from "../common/RangePickerComp";
import SwitchComp from "../common/SwitchComp";
import TableFilterBoxRivile from "../common/TableFilterBoxRivile";
import RivileModal from "./Items/RivileModal";
import moment from "moment";
import { SelectForm } from "../common/Buttons";
import { CheckBoxInput } from "../common/Buttons";
import Axios from "axios";

class Rivile extends React.Component {
  constructor() {
    super();
    this.state = {
      requiredId: 0,
      page: 1,
      pageCount: 100,
      paramsFilter: "",
      checkedArray: [],
      checkedAll: false,
      getInvoicesShow: true,
      filter: {
        document_number: "",
        name: "",
        code: "",
        exported: false,
        document_date: ["", ""],
        document_type: undefined,
      },
    };
  }

  componentDidMount() {
    this.props.getRivileItemTypes();
    this.props.getStores();
    if (this.props.match.url ==  "/rivile-pirkimai") {
      this.props.getRivileItemsSales(this.state.paramsFilter, this.state.page, this.state.pageCount)
    } else {
      this.props.getRivileItems(this.state.paramsFilter, this.state.page, this.state.pageCount);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      if (this.props.match.url ==  "/rivile-pirkimai") {
        this.props.getRivileItemsSales(this.state.paramsFilter, this.state.page, this.state.pageCount)
      } else {
        this.props.getRivileItems(this.state.paramsFilter, this.state.page, this.state.pageCount);
      }
    }

    if(prevProps.match.url !== this.props.match.url)
     {
      if (this.props.match.url ==  "/rivile-pirkimai") {
        this.props.getRivileItemsSales(this.state.paramsFilter, this.state.page, this.state.pageCount)
      } else {
        this.props.getRivileItems(this.state.paramsFilter, this.state.page, this.state.pageCount);
      }
      this.setState({
        checkedArray: [],
        checkedAll: false,
      })
     }
  }

  renderCustomButton = () => {
    return (
      <>
        <div className="dropdown">
          <button
            className="btn btn-sm btn-dark float-right mr-3 dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Eksportuoti
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <button
              className="dropdown-item"
              onClick={() => this.exportContracts("selected")}
            >
              Eksportuoti pasirinktus
            </button>
            <button
              className="dropdown-item"
              onClick={() => this.exportContracts("all")}
            >
              Eksportuoti išfiltruotus
            </button>
          </div>
        </div>
      </>
    );
  };

  renderCustomImportButton = () => {
    return (
      <>
        <div className="dropdown">
          <button
            className="btn btn-sm btn-dark float-right mr-3 dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Importuoti
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <button
              className="dropdown-item"
              onClick={() => this.importContracts("selected")}
            >
              Importuoti pasirinktus
            </button>
            <button
              className="dropdown-item"
              onClick={() => this.importContracts("all")}
            >
              Importuoti išfiltruotus
            </button>
          </div>
        </div>
      </>
    );
  };

  filterChange = (e, date) => {
    if (date) {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            [date]: e,
          },
        },
        () => this.onFilter()
      );
    } else {
      this.setState({
        filter: {
          ...this.state.filter,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  delayOnFilter = () => {
    if (this.props.loading_rivile) {
      return setTimeout(() => {
        return this.delayOnFilter();
      }, 1000);
    }
    this.onFilter();
  };

  onFilter = () => {
    let filterParams = [];

    if (this.props.loading_rivile) {
      return this.delayOnFilter();
    }

    if (this.state.filter.exported) {
      filterParams.push(`&q[exported_eq]=1`);
    } 

    if (this.state.filter.document_date[0].length > 0){
      filterParams.push(`&q[document_date_gteq]=${this.state.filter.document_date[0]}`);
    }

    if (this.state.filter.document_date[1].length > 0){
      filterParams.push(`&q[document_date_lteq]=${this.state.filter.document_date[1]}`);
    }

    if (this.state.filter.document_number.length > 0){
      filterParams.push(`&q[document_number_cont]=${this.state.filter.document_number}`);
    }

    if (this.state.filter.document_type){
      filterParams.push(`&q[document_type_eq]=${this.state.filter.document_type}`);
    }

    if (this.state.filter.name.length > 0){
      filterParams.push(`&q[rivile_invoiced_items_name_cont]=${this.state.filter.name}`);
    }

    if (this.state.filter.code.length > 0){
      filterParams.push(`&q[rivile_invoiced_items_code_cont]=${this.state.filter.code}`);
    }

    this.setState(
      {
        paramsFilter: filterParams.join(""),
        page: 1
      },
      () => this.reloadData(filterParams.join(""))
    );
  };

  clearFilter = () => {
    this.setState(
      {
        paramsFilter: "",
        filter: {
          document_number: "",
          name: "",
          code: "",
          exported: false,
          document_date: ["", ""],
          document_type: undefined,
        },
      },
      () => this.reloadData("")
    );
  };

  handleKeyDown = (e) => {
    if (e.key == "Enter") {
      this.onFilter();
    }
  };

  getDateFormat = (date) => {
    var d = moment(date);
    return date && d.isValid() ? d : "";
  };

  reloadData = (data) => {
    if (this.props.match.url ==  "/rivile-pirkimai") {
      this.props.getRivileItemsSales(data, this.state.page, this.state.pageCount)
    } else {
      this.props.getRivileItems(data, this.state.page, this.state.pageCount);
    }
  };

  onEdit = (index, id) => {
    this.setState({
      requiredId: id,
    });
    this.props.getRivileItem(id);
  };

  handleCheckboxChange = (value) => {
    const currentIndex = this.state.checkedArray.indexOf(value);
    const newChecked = [...this.state.checkedArray];
    const myData = this.props.rivileItems;

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    let variableAll = myData.length === newChecked.length;

    this.setState({
      checkedArray: newChecked,
      checkedAll: variableAll,
    });
  };

  handleCheckboxChangeRemove = (value) => {
    const currentIndex = this.state.checkedArray.indexOf(value);
    const newChecked = [...this.state.checkedArray];

    if (currentIndex !== -1) {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({
      checkedArray: newChecked,
      checkedAll: false
    });
  };
 
 
  handleCheckboxAll() {
    const myData = this.props.rivileItems;
    const newChecked = [...this.state.checkedArray];
    let newArray = [];
    myData.forEach((item) => {
      if(item.status == "new") {

        newArray.push(item.id)
      }
      });
    if (newArray.length === newChecked.length) {
      this.setState({
        checkedAll: false,
        checkedArray: [],
      });
    } else {
    
      this.setState({
        checkedAll: true,
        checkedArray: newArray,
      });
    }
  }

 
  handleCheckboxAllImport() {
    const myData = this.props.rivileItems;
    const newChecked = [...this.state.checkedArray];
    let newArray = [];
    if (myData.length === newChecked.length) {
      this.setState({
        checkedAll: false,
        checkedArray: [],
      });
    } else {
      myData.forEach((item) => newArray.push(item.id));
      this.setState({
        checkedAll: true,
        checkedArray: newArray,
      });
    }
  }

  getAllInvoices = () => {
    this.props.loadingRivileItems()
    this.setState({
      getInvoicesShow: false
    })
    let url = "/api/v1/rivile_invoices/get_new_invoices";
    Axios({
      url: url,
      method: "GET",
    }).then((response) => {
      this.setState({
        getInvoicesShow: true
      })
      this.reloadData("")
    });
  };


  exportContracts = (action) => {
    let url;
    if (action == "selected") {
      url = "/api/v1/rivile_invoices/state_to_exporting";
    } else if (action == "all") {
      url = `/api/v1/rivile_invoices/state_to_exporting_all?${this.state.paramsFilter}`;
    }
    Axios({
      url: url,
      method: "POST",
      data: {
        checked: this.state.checkedArray,
      },
    }).then((response) => {
      if(response.data && response.data.success == true){
        this.props.changeStatusAction(response.data.checked, false)
        response.data.checked.forEach(each => this.handleCheckboxChangeRemove(each))
      }
    });
  };

  importContracts = (action) => {
    let url;
    if (action == "selected") {
      url = "/api/v1/rivile_invoices/import_selected";
    } else if (action == "all") {
      url = `/api/v1/rivile_invoices/import_all?${this.state.paramsFilter}`;
    }
    Axios({
      url: url,
      method: "POST",
      data: {
        checked: this.state.checkedArray,
      },
    }).then((response) => {
      if(response.data && response.data.success == true){
        this.props.changeStatusToExported(response.data.exported, false, response.data.error)
        response.data.exported.forEach(each => this.handleCheckboxChangeRemove(each))
      }
    });
  };

  setPagination = (page) => {
    this.setState({
      page,
    });
  };

  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  render() {
    return (
      <Container
        location={{
          locationName: this.props.match.url == "/rivile-pirkimai" ? "Rivilė Pirkimai" : "Rivilė Pardavimai",
          locationUrl: this.props.match.url,
        }}
      >
        <ContentBlock>
          <FilterMenu
            length={`${this.props.totals || 0} / ${
              this.props.rivileItems.length || 0
            }`}
            onChangeCountPage={this.setPageCount}
            countPage={this.state.pageCount}
            text="sąskaitų"
            text2="Sąskaitų"
          />
        </ContentBlock>
        <ContentBlock>
          <TableFilterBoxRivile
            filterText={"Eksportuoti"}
            button1={this.props.match.url == "/rivile-pirkimai" ? this.renderCustomImportButton() : this.renderCustomButton() }
            clearFilter={() => this.clearFilter()}
            getInvoices={this.props.match.url == "/rivile-pardavimai" ? () => this.getAllInvoices() : ''}
            getInvoicesShow={this.props.match.url == "/rivile-pardavimai" ?  this.state.getInvoicesShow : false}
            >
              <div className="px-1">
                <InputText
                  width={"0.75rem"}
                  number="1"
                  name="document_number"
                  placeholder="Dok.numeris"
                  value={this.state.filter.document_number}
                  onKeyDown={this.handleKeyDown}
                  onChange={(e) => this.filterChange(e)}
                />
              </div>
              <div className="px-1">
                <InputText
                  width={"0.75rem"}
                  number="1"
                  name="name"
                  placeholder="Prekė"
                  value={this.state.filter.name}
                  onKeyDown={this.handleKeyDown}
                  onChange={(e) => this.filterChange(e)}
                />
              </div>
              <div className="px-1">
                <InputText
                  width={"0.75rem"}
                  number="1"
                  name="code"
                  placeholder="Barkodas"
                  onKeyDown={this.handleKeyDown}
                  value={this.state.filter.code}
                  onChange={(e) => this.filterChange(e)}
                />
              </div>
              <div className="px-1 pr-4" >
              <SwitchComp
                name="exported"
                placeholder="Eksportuota"
                checked={this.state.filter.exported}
                onChange={() =>
                  this.setState(
                    {
                      filter: {
                        ...this.state.filter,
                        exported: !this.state.filter.exported,
                      },
                    },
                    () => this.onFilter()
                  )
                }
              />
                            </div>
              <div className="px-1" style={{ width: "20rem" }}>
                <div className="form-group">
                  <label className="editLabelForm">Data:</label>
                  <div>
                    <RangePickerComp
                      placeholder={["Nuo", "Iki"]}
                      value={[
                        this.getDateFormat(this.state.filter.document_date[0]),
                        this.getDateFormat(this.state.filter.document_date[1]),
                      ]}
                      onChange={(e, args) => this.filterChange(args, "document_date")}
                    />
                  </div>
                </div>
              </div>
              <div className="px-1">
                <SelectForm
                  options={this.props.rivileItemTypes}
                  name="document_type"
                  placeholder="Dokumento tipas"
                  value={this.state.filter.document_type}
                  onChange={(e) => this.filterChange(e, "document_type")}
                />
              </div>
            </TableFilterBoxRivile>
          <div>
            <ContentValidation
              loader={this.props.loading_rivile}
              array={this.props.rivileItems}
              alertId_s="rivileAlert-s"
              alertId_e="rivileAlert-e"
            >
            <PaginationComp
              setPagination={this.setPagination}
              page={this.state.page}
              pageCount={this.state.pageCount}
              totals={this.props.totals}
            />
              <div className="table-responsive">
                <table className="table table-sm table-hover mt-3">
                  <thead>
                    <tr className="text-muted">
                      <th className="text-black">
                        <div className="d-flex justify-content-center">
                          <CheckBoxInput
                            type="checkbox"
                            color="#f7951e"
                            checked={this.state.checkedAll}
                            onChange={this.props.match.url == "/rivile-pirkimai" ? () => this.handleCheckboxAllImport() : () => this.handleCheckboxAll()}
                          />
                        </div>
                      </th>
                      <th className="text-black">Dok.data</th>
                      <th className="text-black">Dok.tipas</th>
                      <th className="text-black">Statusas</th>
                      <th className="text-black">Dok.numeris</th>
                      <th className="text-black">Prekės</th>
                      <th className="text-black">Suma</th>
                      <th className="text-black">PVM suma</th>
                      <th className="text-black">Viso</th>
                      <th className="text-black">Pastaba</th>
                      <th className="text-black">Komentarai</th>
                      <th className="text-black">Klaidos</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.rivileItems.map((item, index) => {
                      return (
                        <TableColumnRivile
                          key={index}
                          index={index}
                          id={item.id}
                          onEdit={this.onEdit}
                          item={item}
                          handleCheckboxChange={this.handleCheckboxChange}
                          checkedArray={this.state.checkedArray}
                          validation={this.props.match.url == "/rivile-pardavimai"}
                        />
                      );
                    })}
                  </tbody>
                </table>
                <PaginationComp
                  setPagination={this.setPagination}
                  page={this.state.page}
                  pageCount={this.state.pageCount}
                  totals={this.props.totals}
                  changeStatusAction={this.props.changeStatusAction}
                />
              </div>
            </ContentValidation>
          </div>
        </ContentBlock>

        <RivileModal
          rivileItem={this.props.rivileItem}
          requiredId={this.state.requiredId}
          url={this.props.match.url}
          auth={this.props.auth}
          stores={this.props.stores}
        />

      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.user,
  rivileItems: state.rivile.rivileItems,
  rivileItem: state.rivile.rivileItem,
  rivileItemTypes: state.rivile.rivileItemTypes,
  loading_rivile: state.rivile.loading_rivile,
  totals: state.rivile.totals,
  stores: state.rivile.rivileStores,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getRivileItems,
  getRivileItemTypes,
  getRivileItem,
  getRivileItemsSales,
  changeStatusAction,
  loadingRivileItems,
  changeStatusToExported,
  forceStatusToExported,
  getStores
})(Rivile);
