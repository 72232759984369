import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonSecondary from "../../components/common/ButtonSecondary";
import ContentValidation from "../../components/common/ContentValidation";
import TableFilterBox from "../../components/common/TableFilterBox";
import PaginationComp from "../../components/Dashboard/items/Pagination";
import Container from "../../components/Layout/Container";
import ContentBlock from "../../components/Layout/ContentBlock";
import FilterMenu from "../../components/Layout/FilterMenu";
import AntdConfirm from "../../helpers/AntdConfirm";
import { handleKeyDown, onChangeUseState } from "../../helpers/GlobalFunctions"; 
import {
  deleteCommerceList,
  getCommerceList,
  updateCommerceList,
} from "../../redux/Actions/commerce_orders_action";
import ReactDOM from "react-dom";
import useFetch from "../../helpers/useFetch";
import { addOverFlow, removeOverFlow } from "../../helpers/modalFunc";
import ModalSpecial from "../../components/Layout/Modals/ModalSpecial";
import Spinner from "../../components/common/Spinner";
import { Button, Tooltip } from "antd";
import { IconEdit, IconSearch, IconTrash } from "../../components/Layout/Icons";
import order_list_svg from "../../../assets/images/order_list.svg";
import TableUi from "../../components/common/TableUi";
import { Input, SelectInputAsync } from "../../components/global";
import useErrors from "../../hooks/useErrors";

const filtersInitial = {
  title: "",
};
export default function CommerceOrdersPage({ match, history }) {
  const dispatch = useDispatch();
  const { loading, data, totals, current } = useSelector(
    (state) => state.custom
  );

  const [filters, setFilters] = useState(filtersInitial);
  const [page, setPage] = useState(1);
  const [perCount, setPerPage] = useState(50);
  const [listSelected, setListSelected] = useState(false);

  const startedPack = (defaultPage = page) => {
    if (defaultPage !== page) setPage(defaultPage);
    dispatch(getCommerceList(page, perCount, onFilter()));
  };
  useEffect(() => {
    startedPack();
  }, []);

  useEffect(() => {
    console.count("FETCH");
    startedPack(1);
  }, [perCount]);

  useEffect(() => {
    console.count("FETCH");
    startedPack();
  }, [page]);

  const onFilter = () => {
    const { title } = filters;

    let search_string = "";
    if (title) {
      search_string =
        search_string + `&q[title_or_rivile_group_code_cont]=${title}`;
    }

    return search_string;
  };

  return (
    <Container
      location={{
        locationName: "Užsakymų sąrašas",
        locationUrl: match.url,
      }}
    >
      <ContentBlock>
        <FilterMenu
          icon={order_list_svg}
          length={`${totals || 0} / ${data.length || 0}`}
          onChangeCountPage={setPerPage}
          countPage={perCount}
          text="sąrašų"
          text2="Sąrašų"
        />
      </ContentBlock>

      <ContentBlock>
        <TableFilterBox
          button1={
            <div
              className="float-right mr-1"
              style={{
                maxWidth: 150,
              }}
            >
              <ButtonSecondary onClick={() => setListSelected("create")}>
                Sukurti naują sąrašą
              </ButtonSecondary>
            </div>
          }
        >
          <div className="px-1 col-3">
            <Input
              text="Prekių grupė arba pavadinimas"
              name="title"
              onKeyDown={(e) => handleKeyDown(e, startedPack)}
              onChange={(e) => onChangeUseState(e, setFilters)}
              value={filters.title}
              suffix={<IconSearch onClick={() => startedPack(1)} />}
            />
          </div>
        </TableFilterBox>
        <ContentValidation loader={loading} array={data}>
          <TableUi
            className={"table-borders"}
            table_small={false}
            ths={["Prekių grupė", "Pavadinimas", "Prekės", ""]}
          >
            {current == "CommerceOrders" &&
              data.map((list) => {
                return (
                  <TableUi.tr key={list.id}>
                    <TableUi.td className="p-left">{list.rivile_group}</TableUi.td>
                    <TableUi.td>{list.title}</TableUi.td>
                    <TableUi.td>
                      {!!list.products.length ? (
                        <div>
                          {list.products
                            .map((d) =>
                              typeof d == "string" ? d : d.identifier
                            )
                            .join(", ")}
                        </div>
                      ) : (
                        <span
                          className="text-sm"
                          style={{
                            color: "rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          Kolkas nėra jokių prekių...
                        </span>
                      )}
                    </TableUi.td>
                    <TableUi.td>
                      <div className="d-flex justify-content-end">
                        <Tooltip placement="top" title="Redaguoti">
                          <div
                            style={{
                              width: 33,
                              height: 33,
                            }}
                          >
                            <ButtonSecondary
                              style={{
                                background: "#E61D01",
                                borderRadius: 3,
                                width: 33,
                                height: 33,
                                padding: 0,
                              }}
                              onClick={() => setListSelected(list.id)}
                              outline={true}
                            >
                              <IconEdit className="text-orange" />
                            </ButtonSecondary>
                          </div>
                        </Tooltip>
                        <div className="mx-1" />
                        <Tooltip placement="top" title="Peržiura">
                          <div
                            style={{
                              width: 33,
                              height: 33,
                            }}
                          >
                            <ButtonSecondary
                              style={{
                                background: "#E61D01",
                                borderRadius: 3,
                                width: 33,
                                height: 33,
                                padding: 0,
                              }}
                              onClick={() => {
                                history.push(`/commerce/orders/${list.id}`);
                              }}
                            >
                              <IconSearch color="#fff" />
                            </ButtonSecondary>
                          </div>
                        </Tooltip>
                        <div className="mx-1" />
                        <Tooltip placement="top" title="Trinti">
                          <Button
                            style={{
                              background: "#E61D01",
                              borderRadius: 3,
                              width: 33,
                              height: 33,
                              padding: 0,
                            }}
                            onClick={() =>
                              AntdConfirm({
                                onOk: () => {
                                  dispatch(deleteCommerceList(list.id));
                                },
                              })
                            }
                          >
                            <IconTrash color="#fff" />
                          </Button>
                        </Tooltip>
                      </div>
                    </TableUi.td>
                  </TableUi.tr>
                );
              })}
          </TableUi>
          <div className="table-responsive">
            <PaginationComp
              page={page}
              totals={totals}
              setPagination={setPage}
              pageCount={perCount}
            />
          </div>
        </ContentValidation>
      </ContentBlock>
      {listSelected && (
        <ListModalWrapper
          listSelected={listSelected}
          setListSelected={setListSelected}
          closeModal={() => setListSelected(false)}
        />
      )}
    </Container>
  );
}

function ListModalWrapper({ listSelected, closeModal, setListSelected }) {
  useEffect(() => {
    if (listSelected) {
      addOverFlow();
    }
  }, []);

  const onClose = () => {
    closeModal();
    removeOverFlow();
  };

  if (listSelected) {
    return ReactDOM.createPortal(
      <ModalSpecial
        text={
          listSelected == "create" ? "Pridėti naują sąrašą" : "Redaguoti sąrašą"
        }
        style={{
          width: 359,
        }}
        onClose={onClose}
      >
        <ListModal
          list_id={listSelected}
          setListSelected={setListSelected}
          onClose={onClose}
        />
      </ModalSpecial>,
      document.getElementById("custom_modal")
    );
  }
  return null;
}

const ListModal = ({ list_id, onClose }) => {
  const dispatch = useDispatch();
  const { errors, setErrors } = useErrors();
  const { value, loading, setValue, error } = useFetch(
    `/api/v1/commerce/lists/${list_id == "create" ? "new" : list_id}`,
    {},
    []
  );

  console.log({ value, error, loading });
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spinner />
      </div>
    );
  }
  if (error) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        Įvyko klaida
      </div>
    );
  }

  return (
    <div className="pb-5">
      <SelectInputAsync
        required={true}
        label="Prekių grupė"
        value={value.rivile_group_id}
        onChange={(value) =>
          onChangeUseState({ target: { name: "rivile_group_id", value } }, setValue)
        }
        api_url="/api/v1/rivile_groups"
        rebuildArray={(val) =>
          val?.data?.map((d) => ({
            value: d.id,
            label: d.code,
          }))
        }
        warning={errors?.rivile_group}
      />
      <div className="my-2" />
      <Input
        required={true}
        text="Sąrašo pavadinimas"
        name="title"
        value={value.title}
        onChange={(e) => onChangeUseState(e, setValue)}
        warning={errors.title}
      />

      <div className="mt-5 d-flex justify-content-end">
        <div
          style={{
            width: 127,
          }}
        >
          <ButtonSecondary
            onClick={() => {
              dispatch(updateCommerceList(value)).then((res) => {
                console.log(res);
                if (res.success) {
                  onClose();
                } else {
                  setErrors(res.errors);
                }
              });
            }}
          >
            {list_id == "create" ? "Sukurti" : "Išsaugoti"}
          </ButtonSecondary>
        </div>
      </div>
    </div>
  );
};
