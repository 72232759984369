import { Component } from "react";
import { connect } from "react-redux";
import { getOmniauthData } from "../../../redux/Actions/authAction";
import HistoryRedirect from "../../../helpers/HistoryRedirect";

class Omniauth extends Component {
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      HistoryRedirect(this.props.auth.user.user_role.permissions, this.props.history);
    } else {
      this.props.getOmniauthData(this.props.history);
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { getOmniauthData })(Omniauth);
