import {
  GET_PICKUPADDRESSES,
  POST_PICKUPADDRESS,
  DELETE_PICKUPADDRESS,
  UPDATE_PICKUPADDRESS,
  LOADING_PICKUPADDRESS,
  GET_COUNTRIESOPTION
} from "../Actions/types";

const initialState = {
  pickupAddresses: [],
  pickupAddress: {},
  countriesOption: [],
  loading_pickupAddress: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_PICKUPADDRESS:
      return {
        ...state,
        loading_pickupAddress: true
      };
    case GET_COUNTRIESOPTION:
      return {
        ...state,
        countriesOption: action.payload.data
      };
    case GET_PICKUPADDRESSES:
      return {
        ...state,
        pickupAddresses: action.payload.data,
        totals: action.payload.totals,
        loading_pickupAddress: false
      };
    case POST_PICKUPADDRESS:
      let updateData = [...state.pickupAddresses];
      updateData.push(action.payload);
      return {
        ...state,
        pickupAddresses: updateData
      };
    case UPDATE_PICKUPADDRESS:
      let getData = [...state.pickupAddresses];
      const updateElementId = element => element.id === action.payload_id;
      const findIndex = getData.findIndex(updateElementId);
      getData[findIndex] = action.payload;
      return {
        ...state,
        pickupAddresses: getData
      };
    case DELETE_PICKUPADDRESS:
      return {
        ...state,
        pickupAddresses: [...state.pickupAddresses].filter(
          filterItem => filterItem.id !== action.payload
        ),
        loading_pickupAddress: false
      };
    default:
      return state;
  }
}
