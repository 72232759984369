import React from "react";
import { notifications } from "../../helpers/Alerts";
import InputText from "../orders/accepted_orders/InputFilter";
import Container from "../Layout/Container";
import ContentBlock from "../Layout/ContentBlock";
import FilterMenu from "../Layout/FilterMenu";
import TableFilterBox from "../common/TableFilterBox";
import TableColumnUsers from "./items/TableColumnUsers";
import UserseditModal from "./items/UserseditModal";
import ContentValidation from "../common/ContentValidation";
import {
  getUsers,
  postUser,
  deleteUer,
  updateUser,
  authRemove,
  clearErrors,
} from "../../redux/Actions/userAction";
import { logoutUser } from "../../redux/Actions/authAction";
import { getStoreItems } from "../../redux/Actions/storeAction";
import { connect } from "react-redux";
import PaginationComp from "../Dashboard/items/Pagination";
import Axios from "axios";
import { SelectForm } from "../common/Buttons";

class Users extends React.Component {
  constructor() {
    super();

    this.state = {
      isSyncInProgress: false,
      requiredId: 0,
      page: 1,
      pageCount: 100,
      paramsFilter: "",
      filter: {
        email: "",
        first_lastname: "",
        role: undefined,
      },
      stores: [],
      user_roles: [],
      action: "new",
      openModalEdit: true,
    };
  }

  componentDidMount() {
    this.reloadData(this.state.paramsFilter);
    this.props.getStoreItems();
    Axios.get("/api/v1/user_roles").then((res) => {
      if (res.data) {
        this.setState({
          user_roles: res.data.map((e) => ({
            label: e.title,
            value: e.id,
          })),
        });
      }
    });
  }

  componentDidUpdate(_, prevState) {
    if (this.state.filter.role !== prevState.filter.role) {
      this.onFilter();
    }
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.reloadData(this.state.paramsFilter);
    }
    if (
      this.props.location.props2 &&
      this.state.openModalEdit &&
      this.props.totals !== 0
    ) {
      this.setState({
        openModalEdit: false,
      });
      document.getElementById(this.props.location.props2).click();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.storeItems.length !== this.state.stores) {
      let storeArray = [];
      nextProps.storeItems.forEach((store) => {
        storeArray.push({
          value: store.id,
          label: store.name,
        });
      });
      this.setState({
        stores: storeArray,
      });
    }
  }

  reloadData = (paramsFilter) => {
    this.props.getUsers(this.state.page, this.state.pageCount, paramsFilter);
  };

  onAction = (action, index) => {
    this.setState({
      requiredId: index,
      action: action,
    });
  };

  delayOnFilter = () => {
    if (this.props.loading_users) {
      return setTimeout(() => {
        return this.delayOnFilter();
      }, 1000);
    }
    this.onFilter();
  };

  onFilter = () => {
    if (this.props.loading_users) {
      return this.delayOnFilter();
    }
    let filterParams = [];
    if (this.state.filter.email.length > 0)
      filterParams.push(`&q[email_i_cont_any]=${this.state.filter.email}`);
    if (this.state.filter.first_lastname.length > 0) {
      let clientArray = this.state.filter.first_lastname.split(" ");
      clientArray.forEach((item) => {
        filterParams.push(`&q[firstname_or_lastname_cont]=${item}`);
      });
    }
    if (this.state.filter.role) {
      filterParams.push(`&q[user_role_id_eq]=${this.state.filter.role}`);
    }
    this.setState(
      {
        paramsFilter: filterParams.join(""),
        page: 1,
      },
      () => this.reloadData(filterParams.join(""))
    );
  };

  filterChange = (e) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [e.target.name]: e.target.value,
      },
    });
  };

  setPagination = (page) => {
    this.setState({
      page,
    });
  };
  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };
  handleKeyDown = (e) => {
    if (e.key == "Enter") {
      this.onFilter();
    }
  };

  clearFilter = () => {
    this.setState(
      {
        filter: {
          email: "",
          first_lastname: "",
          role: undefined,
        },
      },
      () => this.onFilter()
    );
  };

  handleSyncUsers = () => {
    if (this.props.auth.user_role?.identifier !== "admin") return;

    this.setState({ isSyncInProgress: true });

    Axios.post("/api/v1/users/sync_with_azure").then(({ data }) => {
      this.setState({ isSyncInProgress: false });

      if (data.success) {
        this.reloadData(this.state.paramsFilter);
      } else {
        notifications("error");
      }
    }).catch(() => {
      this.setState({ isSyncInProgress: false });
      notifications("error");
    });
  };

  render() {
    const { errors, users } = this.props;
    const requiredItem = this.state.requiredId;
    let modalData = users[requiredItem] ? users[requiredItem] : [];
    let usersOption = this.props.auth.user_role.permissions.options_users;

    const showSyncButton = Boolean(
      this.props.auth.azure_session_id &&
      this.props.auth.user_role?.identifier === "admin"
    )

    return (
      <>
        <Container
          location={{
            locationName: "Vartotojai",
            locationUrl: this.props.match.url,
          }}
        >
          <>
            <ContentBlock>
              <FilterMenu
                length={`${this.props.totals || 0} / ${
                  this.props.users.length || 0
                }`}
                onChangeCountPage={this.setPageCount}
                countPage={this.state.pageCount}
                text="vartotojų"
                text2="Vartotojų"
              />
            </ContentBlock>

            <ContentBlock>
              <TableFilterBox
                button1={showSyncButton ? (
                  <button
                    className="btn btn-sm float-right mr-3 btn-dark"
                    disabled={this.state.isSyncInProgress}
                    onClick={this.handleSyncUsers}
                  >
                    Sinchronizuoti Azure vartotojus
                  </button>
                ) : null}
                filter={false}
                add={usersOption ? this.onAction : false}
                onFilter={usersOption ? this.onFilter : false}
                clearFilter={usersOption ? () => this.clearFilter() : false}
              >
                {usersOption && (
                  <>
                    <div className="px-1">
                      <InputText
                        width={"0.75rem"}
                        number="1"
                        name="email"
                        placeholder="Email"
                        onKeyDown={this.handleKeyDown}
                        value={this.state.filter.email}
                        onChange={(e) => this.filterChange(e)}
                      />
                    </div>
                    <div className="px-1">
                      <InputText
                        width={"0.75rem"}
                        number="1"
                        name="first_lastname"
                        placeholder="Vardas Pavardė"
                        onKeyDown={this.handleKeyDown}
                        value={this.state.filter.first_lastname}
                        onChange={(e) => this.filterChange(e)}
                      />
                    </div>{" "}
                    <div className="px-1" style={{ width: "150px" }}>
                      <SelectForm
                        name="role_filter"
                        placeholder="Rolė"
                        options={[
                          { label: "Visi", value: null },
                          ...this.state.user_roles,
                        ]}
                        value={this.state.filter.role}
                        dropdownMatchSelectWidth={false}
                        onChange={(value) =>
                          this.filterChange({ target: { name: "role", value } })
                        }
                      />
                    </div>
                  </>
                )}
              </TableFilterBox>
              <div>
                <ContentValidation
                  loader={this.props.loading_users}
                  array={this.props.users}
                  alertId_s="userAlert-s"
                  alertId_e="userAlert-e"
                >
                  <div className="table-responsive">
                    <table className="table table-sm table-hover mt-3">
                      <thead>
                        <tr className="text-muted">
                          <th className="text-black"></th>
                          <th className="text-black">El.adresas</th>
                          <th className="text-black">Vardas</th>
                          <th className="text-black">Pavardė</th>
                          <th className="text-black">Rolė</th>
                          <th className="text-black">Parduotuvė</th>
                          <th className="text-black">Tabelio nr.</th>
                          <th className="text-black">Menedžerio kodas</th>
                          <th className="text-black">SB lizingas</th>
                          <th className="text-black">Bigbox Import</th>
                          <th className="text-black">2FA autentifikacija</th>
                          <th className="text-black">Deaktyvavimo data</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.users.map((item, index) => {
                          return (
                            <TableColumnUsers
                              key={index}
                              index={index}
                              id={item.id}
                              item={item}
                              stores={this.state.stores}
                              onEdit={this.onAction}
                              onDelete={this.props.deleteUer}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                    <PaginationComp
                      setPagination={this.setPagination}
                      page={this.state.page}
                      totals={this.props.totals}
                      pageCount={this.state.pageCount}
                    />
                  </div>
                </ContentValidation>
              </div>
            </ContentBlock>
          </>
          <UserseditModal
            action={this.state.action}
            user_roles={this.state.user_roles}
            auth={this.props.auth}
            postUser={this.props.postUser}
            updateUser={this.props.updateUser}
            authRemove={this.props.authRemove}
            stores={this.state.stores}
            objectData={modalData}
            logoutUser={this.props.logoutUser}
            clearErrors={this.props.clearErrors}
            errors={errors}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.user,
  users: state.user.users,
  storeItems: state.store.storeItems,
  totals: state.user.totals,
  loading_users: state.user.loading_users,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getUsers,
  getStoreItems,
  postUser,
  deleteUer,
  updateUser,
  authRemove,
  logoutUser,
  clearErrors,
})(Users);
