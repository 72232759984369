import React from "react";
import { ButtonEdit, ButtonDelete } from "../../../common/Buttons";
import plus from "../claimed.svg";

function TableColumnTickets({ item, index, onEdit, onDelete, claimed }) {
  return (
    <tr className="align-middle tableColumn" key={index}>
      <td className="align-middle">{item.id}</td>
      <td className="align-middle">{item.code}</td>
      <td className="align-middle">{item.project}</td>
      <td className="align-middle">{item.prize}</td>
      <td className="align-middle" style={{ width: "50px" }}>
        {item.claimed_at == null && (
          <button
            className="btn btn-sm "
            style={{ backgroundColor: "#395064" }}
            onClick={() => claimed(item.id)}
          >
            <img src={plus} style={{ width: "11px", height: "11px", color: "white" }} />
          </button>
        )}
      </td>
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonEdit onEdit={() => onEdit(index)} />
      </td>
      <td className="align-middle" style={{ width: "50px" }}>
        <ButtonDelete onDelete={() => onDelete(item.id)} />
      </td>
    </tr>
  );
}

export default TableColumnTickets;
