import React, { useState, useMemo } from "react";
import styled from "styled-components";
import Container from "../../components/Layout/Container";
import GuideFirst from "../../components/Marketing/Guide/GuideFirst";
import GuideSecond from "../../components/Marketing/Guide/GuideSecond";
import GuideThird from "../../components/Marketing/Guide/GuideThird";
import LandingOptionsSvg from "../../../assets/images/landing_options.svg";
import PortalOptionsSvg from "../../../assets/images/portal_options.svg";
import OptionsSvg from "../../../assets/images/options.svg";
import { AlertError, AlertSuccess } from "../../components/common/Alerts";
import GuideBuyersGuides from "../../components/Marketing/Guide/GuideBuyersGuides";
import GuideRecommendation from "../../components/Marketing/Guide/GuideRecommendation/GuideRecommendation";
import { IconGuideBuyer, IconGuideTop5 } from "../../components/Layout/Icons";
import GuideRecommendationShow from "../../components/Marketing/Guide/GuideRecommendation/GuideRecommendationShow";

const default_steps = [
  {
    title: "Landingų valdymas",
    action: 1,
    image: LandingOptionsSvg,
  },
  {
    title: "Portalo valdymas",
    action: 2,
    image: PortalOptionsSvg,
  },
  {
    title: "Nustatymai",
    action: 3,
    image: OptionsSvg,
  },
  {
    title: "Pirkėjų gidai",
    action: 4,
    component: <IconGuideBuyer />,
  },
  {
    title: "TOP 5 / Rekomendacijos",
    action: 5,
    component: <IconGuideTop5 />,
  },
];

export default function Guide({ match, history }) {
  const [section, setSection] = useState(
    JSON.parse(localStorage.getItem("guide_step")) || 1
  );
  const [extraShow, setExtraShow] = useState({});

  const setSectionFunc = (sec) => {
    localStorage.setItem("guide_step", sec);
    setSection(sec);
    setExtraShow({});
  };

  const checkExtraShow = () => Object.values(extraShow).length > 0;

  const sectionRender = useMemo(() => {
    switch (section) {
      case 1:
        return <GuideFirst />;
      case 2:
        return <GuideSecond />;
      case 4:
        return <GuideBuyersGuides />;
      case 5:
        if (checkExtraShow())
          return (
            <GuideRecommendationShow
              history={history}
              match={match}
              id={extraShow.id}
            />
          );
        return (
          <GuideRecommendation history={history} setExtraShow={setExtraShow} />
        );
      default:
        return <GuideThird />;
    }
  }, [section, extraShow]);

  const breadcrumbs = useMemo(() => {
    const breadcrumb_default = [
      {
        label: "Gidas",
        value: match.url,
        onClick: () => {
          setSectionFunc(1);
        },
      },
      {
        label: default_steps.find((d) => d.action == section).title,
        value: match.url,
        onClick: () => {
          setSectionFunc(section);
        },
      },
    ];
    if (checkExtraShow()) {
      console.log({ extraShow });
      breadcrumb_default.push({
        label: extraShow.title,
        value: match.url,
      });
    }

    return breadcrumb_default;
  }, [section, extraShow]);

  const title = useMemo(() => {
    let section_title = default_steps.find((d) => d.action == section).title;
    if (checkExtraShow()) return extraShow.title;
    return section_title;
  }, [section, extraShow]);

  return (
    <Container
      location={{
        locationName: title,
        locationUrl: match.url,
      }}
      breadcrumbs={breadcrumbs}
      goBack={checkExtraShow() ? () => setExtraShow({}) : false}
    >
      <AlertSuccess alertId={"landing-s"} />
      <AlertError alertId={"landing-e"} />
      <div className="col-12 d-flex">
        {default_steps.map(({ title, action, image, component }) => (
          <ButtonMenuLanding
            key={action}
            active={action == section}
            onClick={() => {
              setSectionFunc(action);
            }}
          >
            {image ? <img src={image} className="mr-2" /> : component}
            {title}
          </ButtonMenuLanding>
        ))}
      </div>
      {sectionRender}
    </Container>
  );
}

const ButtonMenuLanding = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fcfcfc;
  border-radius: 4px;
  border: ${(props) => (props.active ? "1px solid #FE9C00" : "none")};
  color: ${(props) => (props.active ? "#FE9C00" : "#A0A0A0")};
  padding: 10px 15px;
  margin-bottom: 9px;
  margin-right: 9px;
  & img {
    ${(props) =>
      props.active &&
      "filter: invert(50%) sepia(89%) saturate(748%) hue-rotate(2deg) brightness(107%) contrast(103%);"}
  }
  & svg {
    ${(props) =>
      props.active &&
      "filter: invert(50%) sepia(89%) saturate(748%) hue-rotate(2deg) brightness(107%) contrast(103%);"}
  }
  &:focus {
    outline: none;
  }
`;
