import React, { Component } from "react";
import Container from "../../Layout/Container";
import TableFilterBox from "../../common/TableFilterBox";
import ContentBlock from "../../Layout/ContentBlock";
import FilterMenu from "../../Layout/FilterMenu";
import ContentValidation from "../../common/ContentValidation";
import TableColumnManifest from "./item/TableColumnManifest";

import {
  getManifest,
  callCourrierManifest,
} from "../../../redux/Actions/manifestAction";
import VenipakManifestModal from "./item/VenipakManifestModal";
import { getPickupAddresses } from "../../../redux/Actions/pickupAddressAction";
import { connect } from "react-redux";
import InputText from "../../orders/accepted_orders/InputFilter";
import PaginationComp from "../../Dashboard/items/Pagination";
import { SelectForm } from "../../common/Buttons";
import SwitchComp from "../../common/SwitchComp";
import { AlertSuccess, AlertError } from "../../common/Alerts";
import { getOrder } from "../../../redux/Actions/orderAction";
import RangePickerComp from "../../common/RangePickerComp";
import RegularOrderChildren from "../../common/GlobalModals/Orders/RegularOrderChildren";
import { formatDatePlus } from "../../../helpers/Utils";
import { CheckBoxInput } from "../../common/Buttons";
import Axios from "axios";

class venipak_manifest extends Component {
  constructor() {
    super();
    this.state = {
      checkedArray: [],
      requiredId: 0,
      requiredOrderId: 0,
      paramsFilter: "",
      page: 1,
      pageCount: 100,
      actionId: 0,
      filter: {
        date: ["", ""],
        orderId: "",
        supplier: "",
        closed_at: null,
        called_at: null,
      },
      optionArray: [
        {
          value: "",
          label: "Visi",
        },
      ],
    };
    this.modalRef = React.createRef();
  }

  onAction = (index, id) => {
    this.setState({
      requiredId: index,
      actionId: id,
    });
  };

  componentDidMount() {
    this.reloadData(this.state.paramsFilter);
    this.props.getPickupAddresses(1, 10000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount
    ) {
      this.reloadData(this.state.paramsFilter);
    }
    if (
      this.state.filter.closed_at !== prevState.filter.closed_at ||
      this.state.filter.called_at !== prevState.filter.called_at ||
      this.state.filter.date !== prevState.filter.date ||
      this.state.filter.supplier !== prevState.filter.supplier
    ) {
      this.onFilter();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pickupAddressesItems) {
      const optionArray = [...this.state.optionArray];
      nextProps.pickupAddressesItems.forEach((item) => {
        if (!optionArray.some((some) => some.label == item.code)) {
          optionArray.push({
            value: item.code,
            label: item.code,
          });
        }
      });
      this.setState({
        optionArray,
      });
    }
  }

  reloadData = (paramsFilter) => {
    this.props.getManifest(
      'venipak',
      this.state.page,
      this.state.pageCount,
      paramsFilter
    );
  };

  handleKeyDown = (e) => {
    if (e.key == "Enter") {
      this.onFilter();
    }
  };

  filterChange = (e, date) => {
    if (date) {
      this.setState({
        filter: {
          ...this.state.filter,
          [date]: e,
        },
      });
    } else {
      this.setState({
        filter: {
          ...this.state.filter,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  setPagination = (page) => {
    this.setState({
      page,
    });
  };

  delayOnFilter = () => {
    if (this.props.loading_manifest) {
      return setTimeout(() => {
        return this.delayOnFilter();
      }, 1000);
    }
    this.onFilter();
  };

  onFilter = () => {
    if (this.props.loading_manifest) {
      return this.delayOnFilter();
    }
    let filterParams = [];
    // Order ID
    if (this.state.filter.orderId.length > 0)
      filterParams.push(
        `&q[shipments_service_order_of_Order_type_id_order_cont]=${this.state.filter.orderId}`
      );
    // Date
    if (this.state.filter.date[0].length > 0)
      filterParams.push(`&q[created_at_gteq]=${this.state.filter.date[0]}`);

    if (this.state.filter.date[1].length > 0)
      filterParams.push(
        `&q[created_at_lt]=${formatDatePlus(this.state.filter.date[1])}`
      );

    // Supplier
    if (this.state.filter.supplier.length > 0)
      filterParams.push(
        `&q[pickup_address_code_cont_any]=${this.state.filter.supplier}`
      );
    // null

    if (this.state.filter.closed_at) {
      filterParams.push(
        `&q[closed_at_not_null]=${this.state.filter.closed_at}`
      );
    }
    if (this.state.filter.called_at) {
      filterParams.push(
        `&q[called_at_not_null]=${this.state.filter.called_at}`
      );
    }

    this.setState(
      {
        paramsFilter: filterParams.join(""),
        page: 1
      },
      () => this.reloadData(filterParams.join(""))
    );
  };

  clearFilter = () => {
    this.setState(
      {
        filter: {
          date: ["", ""],
          orderId: "",
          supplier: "",
          closed_at: null,
          called_at: null,
        },
      },
      () => this.onFilter()
    );
  };

  orderModal = (order_id, id, type) => {
    this.setState({
      requiredOrderId: order_id,
    });
    if (type === "Service") {
      return this.modalRef.current.openModal("ServiceShowModal");
    }
    this.props.getOrder(id);
    this.modalRef.current.openModal("OrderShowModal");
  };

  handleCheckboxChange = (value) => {
    const currentIndex = this.state.checkedArray.indexOf(value);
    const newChecked = [...this.state.checkedArray];
    const myData = this.props.manifest;

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    let variableAll = myData.length === newChecked.length;

    this.setState({
      checkedArray: newChecked,
      checkedAll: variableAll,
    });
  };

  handleCheckboxChangeRemove = () => {
    this.setState({
      checkedArray: [],
      checkedAll: false
    });
  };
 
 
  handleCheckboxAll() {
    const myData = this.props.manifest;
    const newChecked = [...this.state.checkedArray];
    let newArray = [];
    myData.forEach((item) => {
      newArray.push(item.id)
    });
    if (newArray.length === newChecked.length) {
      this.setState({
        checkedAll: false,
        checkedArray: [],
      });
    } else {
    
      this.setState({
        checkedAll: true,
        checkedArray: newArray,
      });
    }
  }

  exportShipments = (action) => {
    let url;
    let checked;
    if (action == "selected") {
      url = "/api/v1/manifests/export_shipments_in_excel";
      checked = this.state.checkedArray;
    } else if (action == "all") {
      url = `/api/v1/manifests/export_shipments_in_excel?${this.state.paramsFilter}`;
      checked  = [];
    }
    Axios({
      url: url,
      method: "POST",
      data: {
        checked: checked,
        courier: 'venipak',
      },
      responseType: "blob", // important
      headers: { Accept: "application/vnd.ms-excel" },
    }).then((response) => {
      this.handleCheckboxChangeRemove();

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", `${"Manifestai"}.xlsx`); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  };

  renderCustomButton = () => {
    return (
      <>
        <div className="dropdown">
          <button
            className="btn btn-sm btn-dark float-right mr-3 dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Eksportuoti
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <button
              className="dropdown-item"
              onClick={() => this.exportShipments("selected")}
            >
              Eksportuoti pasirinktus
            </button>
            <button
              className="dropdown-item"
              onClick={() => this.exportShipments("all")}
            >
              Eksportuoti išfiltruotus
            </button>
          </div>
        </div>
      </>
    );
  };

  render() {
    const requiredItem = this.state.requiredId;
    let modalData = this.props.manifest[requiredItem]
      ? this.props.manifest[requiredItem]
      : [];
    return (
      <Container
        location={{
          locationName: "Venipak manifestai",
          locationUrl: this.props.match.url,
        }}
      >
        <ContentBlock>
          <FilterMenu
            //length={this.props.manifest.length || 0}
            length={`${this.props.totals_manifest || 0} / ${
              this.props.manifest.length || 0
            }`}
            onChangeCountPage={this.setPageCount}
            countPage={this.state.pageCount}
            text="Venipak manifestų"
            text2="Venipak manifestų"
          />
        </ContentBlock>
        <ContentBlock>
          <TableFilterBox
            add={false}
            onFilter={this.onFilter}
            filter={true}
            clearFilter={() => this.clearFilter()}
            button1={ this.renderCustomButton() }
          >
            <div className="px-1" style={{ width: "20rem" }}>
              <div className="form-group">
                <label className="editLabelForm">Data:</label>
                <div>
                  <RangePickerComp
                    placeholder={["Pradžios laikas", "Pabaigos laikas"]}
                    // value={this.state.filter.date}
                    onChange={(e, args) => this.filterChange(args, "date")}
                  />
                </div>
              </div>
            </div>
            <div className="px-1">
              <SwitchComp
                name="Perduota"
                placeholder="Perduota"
                checked={this.state.filter.closed_at}
                onChange={() =>
                  this.setState({
                    filter: {
                      ...this.state.filter,
                      closed_at: !this.state.filter.closed_at,
                    },
                  })
                }
              />
            </div>
            <div className="px-1">
              <SwitchComp
                name="Paėmimas"
                placeholder="Paėmimas"
                checked={this.state.filter.called_at}
                onChange={() =>
                  this.setState({
                    filter: {
                      ...this.state.filter,
                      called_at: !this.state.filter.called_at,
                    },
                  })
                }
              />
            </div>
            <div className="px-1">
              <InputText
                width={"0.75rem"}
                number="1"
                name="orderId"
                placeholder="Užsakymo nr."
                onKeyDown={this.handleKeyDown}
                onChange={(e) => this.filterChange(e)}
              />
            </div>
            <div className="px-1">
              <SelectForm
                placeholder={"Tiekėjas"}
                dropdownMatchSelectWidth={false}
                onChange={(e) => this.filterChange(e, "supplier")}
                options={this.state.optionArray}
              />
            </div>
          </TableFilterBox>
          <AlertSuccess alertId={"manifest-s"} />
          <AlertError alertId={"manifest-e"} />
          <div>
            <ContentValidation
              loader={this.props.loading_manifest}
              array={this.props.manifest}
            >
              <div className="table-responsive">
                <table className="table table-sm table-hover mt-3">
                  <thead>
                    <tr className="text-muted">
                    <th className="text-black">
                        <div className="d-flex">
                          <CheckBoxInput
                            type="checkbox"
                            color="#f7951e"
                            checked={this.state.checkedAll}
                            onChange={() => this.handleCheckboxAll()}
                          />
                        </div>
                      </th>
                      <th className="text-black"></th>
                      <th style={{ color: "black", width: "4%" }}>Perduota</th>
                      <th style={{ color: "black", width: "4%" }}>Paėmimas</th>
                      <th className="text-black">Sukurta</th>
                      <th className="text-black">Tiekėjas</th>
                      <th className="text-black">Užsakymų nr.</th>
                      <th className="text-black">Siuntų</th>
                      <th className="text-black">Paketų</th>
                      <th className="text-black">Svoris</th>
                      <th className="text-black">Bilietai A6</th>
                      <th className="text-black">Manifestas</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.manifest.map((item, index) => {
                      return (
                        <TableColumnManifest
                          item={item}
                          key={index}
                          id={item.id}
                          index={index}
                          onAction={this.onAction}
                          orderModal={this.orderModal}
                          handleCheckboxChange={this.handleCheckboxChange}
                          checkedArray={this.state.checkedArray}
                        />
                      );
                    })}
                  </tbody>
                </table>
                <PaginationComp
                  setPagination={this.setPagination}
                  page={this.state.page}
                  totals={this.props.totals_manifest}
                  pageCount={this.state.pageCount}
                />
              </div>
            </ContentValidation>
          </div>
        </ContentBlock>
        {this.props.manifest.length > 0 && (
          <VenipakManifestModal
            actionId={this.state.actionId}
            objectData={modalData}
            callCourrierManifest={this.props.callCourrierManifest}
          />
        )}
        <div id="modal-roots" />
        <RegularOrderChildren
          ref={this.modalRef}
          requiredOrderId={this.state.requiredOrderId}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  pickupAddressesItems: state.pickupAddress.pickupAddresses,
  totals_manifest: state.manifest.totals_manifest,
  manifest: state.manifest.manifest,
  loading_manifest: state.manifest.loading_manifest,
});

export default connect(mapStateToProps, {
  getOrder,
  getManifest,
  callCourrierManifest,
  getPickupAddresses,
})(venipak_manifest);
