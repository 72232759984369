import Axios from "axios";
import {
  ADD_CONTRACT_ITEM,
  UPDATE_CONTRACT_ITEM,
  ADD_CONTRACT,
  UPDATE_CONTRACT,
  DELETE_CONTRACT,
  DELETE_CONTRACT_ITEM,
  GET_ERRORS,
  SEND_EMAIL,
  FIRST_ADD_CONTRACT,
  FIRST_DELETE_CONTRACT,
  CHANGE_CONTRACT_ITEMS,
  SEND_DOCUMENT_EMAIL,
  ADD_CONTRACT_DOCUMENT,
  UPDATE_CONTRACT_ITEM_DOCUMENT,
  CANCEL_DOCUMENT,
  CLEAR_ERRORS
} from "./types";
const token = document.querySelector('meta[name="csrf-token"]').content;
Axios.defaults.headers.common["X-CSRF-Token"] = token;

const success = () => {
  if (!document.getElementById("order-s")) return;
  document.getElementById("order-s").style.display = "block";
  setTimeout(
    () => (document.getElementById("order-s").style.display = "none"),
    5000
  );
};
const error = () => {
  if (!document.getElementById("order-e")) return;
  document.getElementById("order-e").style.display = "block";
  setTimeout(
    () => (document.getElementById("order-e").style.display = "none"),
    5000
  );
};

export const firstAddContract = (postVariables) => (dispatch) => {
  dispatch({
    type: FIRST_ADD_CONTRACT,
    payload: postVariables,
  });
};

export const firstDeleteContract = (itemId) => (dispatch) => {
  dispatch({
    type: FIRST_DELETE_CONTRACT,
    payload: itemId,
  });
};

export const changeContractItems = (id, contract, items) => (dispatch) => {
  dispatch({
    type: CHANGE_CONTRACT_ITEMS,
    payload: { id: id, contract: contract, data: items },
  });
};

export const addContract = (
  orderId,
  postVariables,
  SelectedItemData,
  oldID
) => (dispatch) => {
  console.log({ postVariables })
  let variables = {
    ...postVariables
  }
  variables.contract.contract_items_attributes = SelectedItemData.map((item) => {
    return {
      order_row_id: item.order_row_id,
      item_price: item.item_price,
      item_discount: item.item_discount,
      item_total: item.item_total,
      product_price: item.product_price,
      category: item.category,
      manufacturer: item.manufacturer,
      warranty: item.warranty,
      model: item.model,
      id_number: item.id_number,
      additional_data: item.additional_data,
      id: item.id || null
    }
  })
  return Axios.post(`/api/v1/orders/${orderId}/contracts`, postVariables)
    .then((res) => {
      // SelectedItemData.forEach((item) => {
      //   const contractItem = {
      //     contract_item: {
      //       order_row_id: item.order_row_id,
      //       order_row_id: item.order_row_id,
      //       item_price: item.item_price,
      //       item_discount: item.item_discount,
      //       item_total: item.item_total,
      //       product_price: item.product_price,
      //       category: item.category,
      //       manufacturer: item.manufacturer,
      //       warranty: item.warranty,
      //       model: item.model,
      //       id_number: item.id_number,
      //       additional_data: item.additional_data,
      //     },
      //   };
      //   return dispatch(createContractItem(orderId, res.data.id, contractItem));
      // });
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
      dispatch({
        type: ADD_CONTRACT,
        payload: {
          id: oldID,
          data: res.data,
        },
      });
      success();
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const deleteContract = (orderId, contractId) => (dispatch) => {
  Axios.delete(`/api/v1/orders/${orderId}/contracts/${contractId}`)
    .then((res) => {
      dispatch({
        type: DELETE_CONTRACT,
        payload: res.data,
      });
      success();
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};
export const deleteContractTest = (orderId, contractId) => (dispatch) => {
  return Axios.delete(`/api/v1/orders/${orderId}/contracts/${contractId}`)
    .then((res) => {
      // dispatch({
      //   type: DELETE_CONTRACT,
      //   payload: res.data,
      // });
      success();

      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const updateContract = (
  orderId,
  contractId,
  postVariables,
  SelectedItemData
) => (dispatch) => {
  console.log({ postVariables })

  // cia

  let variables = {
    ...postVariables
  }
  variables.contract.contract_items_attributes = SelectedItemData.map((item) => {
    return {
      order_row_id: item.order_row_id,
      item_price: item.item_price,
      item_discount: item.item_discount,
      item_total: item.item_total,
      product_price: item.product_price,
      category: item.category,
      manufacturer: item.manufacturer,
      warranty: item.warranty,
      model: item.model,
      id_number: item.id_number,
      additional_data: item.additional_data,
      id: item.id || null
    }
  })
  Axios.put(`/api/v1/orders/${orderId}/contracts/${contractId}`, postVariables)
    .then((res) => {
      // SelectedItemData.forEach((item) => {
      //   const contractItem = {
      //     contract_item: {
      //       order_row_id: item.order_row_id,
      //       item_price: item.item_price,
      //       item_discount: item.item_discount,
      //       item_total: item.item_total,
      //       product_price: item.product_price,
      //       category: item.category,
      //       manufacturer: item.manufacturer,
      //       warranty: item.warranty,
      //       model: item.model,
      //       id_number: item.id_number,
      //       additional_data: item.additional_data,
      //     },
      //   };
      //   if (item.created_at) {
      //     return dispatch(
      //       updateContractItem(orderId, res.data.id, contractItem, item.id)
      //     );
      //   } else {
      //     return dispatch(
      //       createContractItem(orderId, res.data.id, contractItem)
      //     );
      //   }
      // });
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
      dispatch({
        type: UPDATE_CONTRACT,
        payload: res.data,
      });
      success();
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const addContractDocument = (orderId, postVariables) => (dispatch) => {
  return Axios.post(`/api/v1/contracts`, postVariables)
    .then((res) => {
      dispatch({
        type: ADD_CONTRACT_DOCUMENT,
        payload: res.data,
      });
      success();
      dispatch(clearErrors());
      return res;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const updateContractTest = (
  _,
  contractId,
  postVariables,
  SelectedItemData
) => (dispatch) => {
  let variables = {
    ...postVariables
  }

  variables.contract.contract_items_attributes = SelectedItemData.map((item) => {
    return {
      // order_row_id: item.order_row_id,
      item_price: isNaN(item.item_price) ? 0 : item.item_price,
      item_discount: item.item_discount,
      item_total: item.item_total,
      product_price: item.product_price,
      category: item.category,
      manufacturer: item.manufacturer,
      model: item.model,
      id_number: item.id_number,
      product_reference: item.product_reference,
      additional_data: item.additional_data,
      id: item.id || null
    }
  })

  console.log({ variables })

  return Axios.put(`/api/v1/contracts/${contractId}`, variables)
    .then((res) => {
      dispatch({
        type: UPDATE_CONTRACT_ITEM_DOCUMENT,
        payload: res.data,
      });
      success();
      return { success: true, data: res.data };
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err?.response?.data || {},
      });
      error();
      return { success: false, data: {} }
    });
};

// export const createContractItem = (orderId, contractId, contractItem) => (
//   dispatch
// ) => {
//   Axios.post(
//     `/api/v1/orders/${orderId}/contracts/${contractId}/contract_items`,
//     contractItem
//   )
//     .then((res) => {
//       dispatch({
//         type: ADD_CONTRACT_ITEM,
//         payload: res.data,
//       });
//       success();
//     })
//     .catch((err) => {
//       dispatch({
//         type: GET_ERRORS,
//         payload: err.response.data,
//       });
//       error();
//     });
// };

// const updateContractItem = (orderId, contractId, contractItem, itemId) => (
//   dispatch
// ) => {
//   Axios.put(
//     `/api/v1/orders/${orderId}/contracts/${contractId}/contract_items/${itemId}`,
//     contractItem
//   )
//     .then((res) => {
//       dispatch({
//         type: UPDATE_CONTRACT_ITEM,
//         payload: res.data,
//       });
//       success();
//     })
//     .catch((err) => {
//       dispatch({
//         type: GET_ERRORS,
//         payload: err.response.data,
//       });
//       error();
//     });
// };

export const deleteContractItem = (orderId, contractId, itemId) => (
  dispatch
) => {
  Axios.delete(
    `/api/v1/orders/${orderId}/contracts/${contractId}/contract_items/${itemId}`
  )
    .then((res) => {
      dispatch({
        type: DELETE_CONTRACT_ITEM,
        payload: res.data,
      });
      success();
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const deleteContractItemTest = (orderId, contractId, itemId) => (
  dispatch
) => {
  return Axios.delete(
    `/api/v1/contracts/${contractId}/contract_items/${itemId}`
  )
    .then((res) => {
      // dispatch({
      //   type: DELETE_CONTRACT_ITEM,
      //   payload: res.data,
      // });
      // success();
      success()
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};


export const sendContractEmailTest = (contractId, variablesMail) => (
  dispatch
) => {
  return Axios.post(
    `/api/v1/contracts/${contractId}/contract_emails`,
    variablesMail
  )
    .then((res) => {

      dispatch({
        type: "SET_DOCUMENT_EMAIL_IN_ORDERS",
        payload: res.data,
      });
      success()
      dispatch(clearErrors());
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};

export const cancelDocument = (id, contract) => (dispatch) => {
  const variables = {
    contract
  }
  return Axios.patch(`/api/v1/contracts/${id}/cancel`, variables)
    .then((response) => {
      dispatch({
        type: CANCEL_DOCUMENT,
        payload: response.data,
      });
      return response.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      error();
    });
};


export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};