import React, { useState } from "react";

import { Upload, Modal, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { AttachButton } from "../../../common/StyledComponents";
import { getUrlExtension } from "../../../../helpers/Utils";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () =>
    callback({ name: img.name, url: reader.result })
  );
  reader.readAsDataURL(img);
}

function PicturesWall({ name, onChangeAttachments, items }) {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState([]);

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file);
  };

  const handleRemove = (index) => {
    const imagesArr = [...items];
    imagesArr.splice(index, 1);
    onChangeAttachments(name, "docs", imagesArr);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Nuotraukos formatai privalo būti JPG arba PNG!");
    }

    return isJpgOrPng;
  };

  const propsImage = {
    name: "file",
    onChange(info) {
      if (info.file.status === "done") {
        getBase64(info.file.originFileObj, (imgObj) =>
          onChangeAttachments(name, "docs", [...items, imgObj])
        );
      }
    },
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const returnName = (name) => {
    const lengthString = name.length;
    if (lengthString > 10) {
      const start = name.substring(0, 5);
      const end = name.substring(lengthString - 5, lengthString);
      return `${start}..${end}`;
    } else {
      return name;
    }
  };
  return (
    <>
      <div className="d-flex flex-wrap">
        {items.length > 0 &&
          items.map((i, index) =>
          ["png", "jpg", "jpeg", "png"].includes(getUrlExtension(i.name)) ? (
              <div
                key={index}
                className="mr-2 rounded mb-2"
                style={{ backgroundColor: "#e9e9e9" }}
              >
                <img
                  style={{ objectFit: "cover", cursor: "pointer" }}
                  src={i.url}
                  alt={i.name}
                  width={150}
                  height={150}
                  onClick={() => handlePreview(i)}
                />
                <div style={{ color: "#3d3d3d", position: "relative" }}>
                  <div className="d-flex justify-content-center py-1">
                    <div style={{ fontSize: "15px" }}>{returnName(i.name)}</div>
                    <div style={{ position: "absolute", right: "15px" }}>
                      <CloseOutlined
                        twoToneColor="#797979"
                        style={{ fontSize: "15px", cursor: "pointer" }}
                        onClick={() => handleRemove(index)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                key={index}
                className="mr-2 mb-2"
                style={{
                  backgroundColor: "#e9e9e9",
                  width: "150px",
                  borderRadius: "1rem",
                }}
              >
                <div style={{ color: "#3d3d3d", position: "relative" }}>
                  <div className="d-flex justify-content-center py-1">
                    <div style={{ fontSize: "15px" }}>{returnName(i.name)}</div>
                    <div style={{ position: "absolute", right: "15px" }}>
                      <CloseOutlined
                        twoToneColor="#797979"
                        style={{ fontSize: "15px", cursor: "pointer" }}
                        onClick={() => handleRemove(index)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
      </div>
      <Upload
        {...propsImage}
        customRequest={dummyRequest}
        showUploadList={false}
        // beforeUpload={beforeUpload}
      >
        <AttachButton text="Įkelti nuotrauką" />
      </Upload>
      <Modal
        open={previewVisible}
        title={previewImage.name}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt={previewImage.name}
          style={{ width: "100%" }}
          src={previewImage.url}
        />
      </Modal>
    </>
  );
}

export default PicturesWall;
