import React, { useState } from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

function InvoicedItem({ invoiced }) {
  const [open, setOpen] = useState(false);

  const invoidedMapFilt = () => {
    if (open) {
      return invoiced;
    } else {
      return invoiced.slice(0, 2);
    }
  };

  return (
    <React.Fragment>
      {invoidedMapFilt().sort((a, b) => (a.bb_code > b.bb_code ? 1 : -1)).map((item, index) => (
        <React.Fragment key={index}>
          <span>
            {item.quantity + "*" + item.code}
            {item.bb_code ? "" : 
              <span className="ml-1">
                <RiErrorWarningLine
                  title="Trūksta BigBox kodo"
                  className="text-warning mr-1"
                />
              </span>
            }
            <br />
            {item.name} <br />
          </span>
        </React.Fragment>
      ))}
      {invoiced.length > 2 && (
        <button style={{  
            backgroundColor: "white",
            fontSize: "12px",
            color: "#989c9a",
            border: "1px solid #989c9a",
            borderRadius: "5px",
            fontWeight: "500px"
          }} className="btn btn-md btn-block my-1" onClick={() => setOpen(open ? false : true)}>
          {open ? 
            ("Rodyti mažiau prekių") : 
            (`Rodyti daugiau prekių (${invoiced.length - 2}) `)
          }
          { open ? <FaAngleUp /> : <FaAngleDown /> }
        </button>
      )}
    </React.Fragment>
  );
}

export default InvoicedItem;
