import { Spin } from "antd";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import useFetch from "../../../../../helpers/useFetch";
import TableUi from "../../../TableUi";

export default function OrderHistoryById({
  id,
  mode = "order_histories",
  order_rows = "",
  checker = "",
}) {
  const { value, loading, error } = useFetch(
    `/api/v1/orders/${id}/${mode}`,
    {},
    [order_rows, checker]
  );
  if (loading) {
    return (
      <div className="bg-silver py-3 d-flex align-items-center justify-content-center">
        <Spin />
      </div>
    );
  }
  if (error) {
    return (
      <div className="bg-silver py-3 d-flex align-items-center justify-content-center text-danger">
        {JSON.stringify(error)}
      </div>
    );
  }
  if (value?.length == 0) {
    return (
      <div className="col-12 font-semi-bold bg-silver py-3 overflow-auto">
        Nėra istorijos
      </div>
    );
  }
  return (
    <div className="bg-silver py-3 overflow-auto" style={{ maxHeight: 417 }}>
      <TableUi className="h-auto" ths={["Užs. data", "Nr", "Prekės"]}>
        {value?.map((e, idx) => (
          <TableUi.tr
            key={`nu_${e.number}_idx_${idx}`}
            className={value.length - 1 == idx ? "" : "border-bottom"}
          >
            <TableUi.td className="p-left pt-3" align_middle={false}>
              <StyledText>
                {moment(e.date).format("YYYY")}
                <br />
                {moment(e.date).format("MM-DD")}
              </StyledText>
            </TableUi.td>
            <TableUi.td className="pt-3" align_middle={false}>
              <StyledText>
                <u
                  className="cursor-pointer"
                  onClick={() => {
                    window
                      .open(
                        `/${
                          mode == "order_histories"
                            ? "orders"
                            : "service/repair"
                        }/${e.id}`,
                        "_blank"
                      )
                      .focus();
                  }}
                >
                  {e.number}
                </u>
              </StyledText>
            </TableUi.td>
            <TableUi.td className="pt-3 p-right">
              {e.goods.map((good, good_idx) => (
                <StyledText
                  key={`pr_${good.product_reference}_idx_${good_idx}`}
                >
                  {good.quantity} * {good.product_reference} {good.product_name}
                </StyledText>
              ))}
            </TableUi.td>
          </TableUi.tr>
        ))}
      </TableUi>
    </div>
  );
}

const StyledText = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  color: #212529;
`;
