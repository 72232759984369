import React from "react";
import Container from "../Layout/Container";
import { connect } from "react-redux";
import {
  descriptionCallESL,
  getDescriptions,
} from "../../redux/Actions/descriptionAction";
import { getStoreItems } from "../../redux/Actions/storeAction";
import { getFamilies } from "../../redux/Actions/familyAction";
import ContentBlock from "../Layout/ContentBlock";
import TableColumnDescription from "./Items/TableColumnDescription";
import FilterMenu from "../Layout/FilterMenu";
import TableFilterBox from "../common/TableFilterBox";
import ContentValidation from "../common/ContentValidation";
import InputText from "../orders/accepted_orders/InputFilter";
import Pagination from "../Dashboard/items/Pagination";
import { Checkbox } from "antd";
import { CheckBoxInput, SelectForm, SyncButton } from "../common/Buttons";
import { MdStore } from "react-icons/md";
import DescriptionModalWrapper from "./DescriptionModalWrapper";

const initialFilterState = {
  family: "",
  product: "",
  store: "",
  printed: false,
  has_quantity: false,
  family_id: undefined,
  esl: false,
  esl_to: "",
};

let filterSearchTimeout;
let familySearchTimeout;

class Description extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceControl: true,
      showModal: false,
      modalInfo: { id: 0, number: 0 },

      bodyValue: "",
      page: parseInt(
        this.props.match.params.page ||
          localStorage.getItem("descriptions.page") ||
          1
      ),
      pageCount: 25,
      paramsFilter:
        JSON.parse(localStorage.getItem("descriptions.paramsfilter")) || "",
      filter:
        JSON.parse(localStorage.getItem("descriptions.filter")) ||
        initialFilterState,
      selected: [],
      selectedAll: false,
      description_id: false,
    };
    this.available_edit = props.auth.user_role.identifier !== "hosts";
  }

  componentDidMount() {
    this.reloadData(this.state.paramsFilter);
    this.loadData();
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.pageCount !== this.state.pageCount ||
      this.state.paramsFilter != prevState.paramsFilter
    ) {
      this.reloadData(this.state.paramsFilter);
    }
  }

  loadData = () => {
    this.props.getStoreItems(1, 2000, "");
    this.props.getFamilies(1, 400, "");
  };

  reloadData = (paramsFilter) => {
    this.props.getDescriptions(
      this.state.page,
      this.state.pageCount,
      paramsFilter
    );
  };

  setPagination = (page) => {
    const target = `/goods/descriptions/${page}`;
    this.props.history.push({
      pathname: target,
    });
    localStorage.setItem("descriptions.page", page);
    this.setState({
      page,
    });
  };

  setPageCount = (pageCount) => {
    this.setState({
      pageCount,
      page: 1,
    });
  };

  onAction = (action, id) => {
    this.setState({
      requiredId: id,
      action: action,
    });
  };

  searchFamilies = (value) => {
    clearTimeout(familySearchTimeout);
    //if (value) {
    familySearchTimeout = setTimeout(() => {
      this.props.getFamilies(1, 400, `&q[name_i_cont]=${value}`);
    }, 500);
    //}
  };

  delayOnFilter = () => {
    if (this.props.loading) {
      return setTimeout(() => {
        return this.delayOnFilter();
      }, 1000);
    }
    this.onFilter();
  };

  onFilter = () => {
    if (this.props.loading) {
      return this.delayOnFilter();
    }
    let filterParams = [];
    if (this.state.filter.product.length > 0) {
      filterParams.push(
        `&q[title_or_identifier_i_cont_any]=${this.state.filter.product}`
      );
    }
    if (this.state.filter.family_id) {
      filterParams.push(`&q[family_id_eq]=${this.state.filter.family_id}`);
    }
    if (this.state.filter.printed) {
      filterParams.push(`&q[family_printed_true]=1`);
    }
    if (this.state.filter.has_quantity) {
      filterParams.push(`&has_quantity=1`);
    }
    if (this.state.filter.store.toString().length > 0) {
      filterParams.push(`&store_id=${this.state.filter.store}`);
    }
    if (this.state.filter.esl) {
      filterParams.push(`&q[esl_gt]=0`);
      if (this.state.filter.esl_to) {
        filterParams.push(`&q[esl_eq]=${this.state.filter.esl_to}`);
      }
    }
    localStorage.setItem(
      "descriptions.filter",
      JSON.stringify(this.state.filter)
    );
    localStorage.setItem(
      "descriptions.paramsfilter",
      JSON.stringify(filterParams.join(""))
    );
    localStorage.setItem("descriptions.page", 1);
    this.setState(
      {
        paramsFilter: filterParams.join(""),
        page: 1,
      }
      //() => this.reloadData(filterParams.join(""))
    );
  };

  filterChange = (e) => {
    if (e.target.name == "family") {
      let family = this.props.families.find(({ id }) => id == e.target.value);
      if (family) {
        e.target.value = family.name;
      }
    }
    this.setState({
      filter: {
        ...this.state.filter,
        [e.target.name]: e.target.value,
      },
    });
    clearTimeout(filterSearchTimeout);
    filterSearchTimeout = setTimeout(() => {
      this.onFilter();
    }, 500);
  };

  handleKeyDown = (e) => {
    if (e.key == "Enter") {
      this.onFilter();
    }
  };

  clearFilter = () => {
    this.setState(
      {
        filter: {
          family: "",
          product: "",
          store: "",
          printed: "",
        },
      },
      () => this.onFilter()
    );
  };

  handleToggle = (value) => {
    const currentIndex = this.state.selected.indexOf(value);
    let newChecked = [...this.state.selected];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      selected: newChecked,
      selectedAll: false,
    });
  };

  handleCheckboxAll() {
    const myData = this.props.descriptions;
    const newChecked = [...this.state.selected];
    let newArray = [];
    if (myData.length === newChecked.length) {
      this.setState({
        selectedAll: false,
        selected: [],
      });
    } else {
      myData.forEach((item) => newArray.push(item.id));
      this.setState({
        selectedAll: true,
        selected: newArray,
      });
    }
  }

  printSelected = (e, type, id = false) => {
    e.preventDefault();
    let ids = [];
    if (type === "selected") {
      if (this.state.selected.length > 0) {
        ids = this.state.selected;
      }
    } else {
      ids = this.props.descriptions.map((i) => i.id);
    }
    if (id !== false) {
      ids = [id];
    }
    if (ids.length > 0) {
      let data = {
        description: {
          ids: ids,
        },
      };
      fetch(
        `/api/v1/descriptions/combined_descriptions?store_id=${this.state.filter.store}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      )
        .then((response) => response.blob())
        .then((response) => {
          const fileURL = URL.createObjectURL(response);
          const pdfWindow = window.open();
          pdfWindow.location.href = fileURL;
        });
    }
  };

  onAction = (action, id) => {
    this.props.history.push(`/goods/descriptions/${id}`);
  };

  openModal = (type, info) => {
    this.setState({
      showModal: type,
    });
    if (info) {
      this.setState({
        modalInfo: info,
        description_id: info.id,
      });
    }
  };

  getStoreName = () => {
    let user_store_id = this.props.user.store_id;
    if (this.props.stores && user_store_id) {
      let store = this.props.stores.find((s) => s.id === user_store_id);
      if (store) {
        return "Padalinys: " + store.name;
      }
    }
    return "Padalinys nerastas";
  };

  eslButtonCheck = () => {
    if (
      ["manager", "store_manager"].includes(
        this.props.user.user_role.identifier
      )
    ) {
      if (this.props.user?.store?.rivile_code == "U111") return true;
    } else if (["admin"].includes(this.props.user.user_role.identifier)) {
      if (
        this.props.stores.find((s) => s.id === this.state.filter?.store)
          ?.rivile_code == "U111"
      )
        return true;
    }
    return false;
  };

  render() {
    return (
      <Container
        location={{
          locationName: "Aprašymai",
          locationUrl: this.props.match.url,
        }}
      >
        <ContentBlock>
          <FilterMenu
            length={`${this.props.totals || 0} / ${
              this.props.descriptions.length || 0
            }`}
            onChangeCountPage={this.setPageCount}
            countPage={this.state.pageCount}
            text="aprašymų"
            text2="Aprašymų"
          />
        </ContentBlock>
        <ContentBlock>
          <TableFilterBox
            filter={false}
            onFilter={this.onFilter}
            clearFilter={this.clearFilter}
          >
            <div className="px-2">
              <InputText
                width={"0.75rem"}
                number="1"
                name="product"
                placeholder="Prekė"
                //onKeyDown={this.handleKeyDown}
                value={this.state.filter.product}
                onChange={(e) => this.filterChange(e)}
              />
            </div>{" "}
            <div className="px-2" style={{ width: "200px" }}>
              {" "}
              <SelectForm
                name="family"
                placeholder="Kategorija"
                onlyPlaceholder="Pasirinkti"
                options={this.props.families.map((n) => ({
                  value: n.id,
                  label: n.name,
                }))}
                value={this.state.filter.family_id || undefined}
                onChange={(e) =>
                  this.filterChange({ target: { name: "family_id", value: e } })
                }
                onSearch={(e) => this.searchFamilies(e)}
              />
            </div>{" "}
            <div className="px-2" style={{ paddingTop: `30px` }}>
              <CheckBoxInput
                choised="filter_printed"
                color="#f7951e"
                text="Spausdinama"
                checked={this.state.filter.printed}
                onChange={(e) =>
                  this.filterChange({
                    target: {
                      name: "printed",
                      value: !this.state.filter.printed,
                    },
                  })
                }
              />
            </div>
            <div className="px-2" style={{ paddingTop: `30px` }}>
              <CheckBoxInput
                choised="has_quantity"
                color="#f7951e"
                text="Turi likutį"
                checked={this.state.filter.has_quantity}
                onChange={(e) =>
                  this.filterChange({
                    target: {
                      name: "has_quantity",
                      value: !this.state.filter.has_quantity,
                    },
                  })
                }
              />
            </div>{" "}
            {this.props.user.user_role.identifier === "admin" && (
              <div className="px-2" style={{ width: "200px" }}>
                <SelectForm
                  placeholder="Padalinys"
                  onlyPlaceholder="Pasirinkti"
                  options={this.props.stores.map((n) => ({
                    value: n.id,
                    label: n.name,
                  }))}
                  value={this.state.filter.store ? this.state.filter.store : []}
                  onChange={(e) =>
                    this.filterChange({ target: { name: "store", value: e } })
                  }
                />
              </div>
            )}
            <div className="px-2">
              <InputText
                type="number"
                label={
                  <div>
                    <CheckBoxInput
                      text="Turi ESL"
                      checked={this.state.filter.esl}
                      onChange={(e) =>
                        this.filterChange({
                          target: {
                            name: "esl",
                            value: !this.state.filter.esl,
                          },
                        })
                      }
                    />
                  </div>
                }
                disabled={!this.state.filter.esl}
                width={"0.75rem"}
                name="esl_to"
                placeholder="Pajungti ESL (KIEKIS)"
                value={this.state.filter.esl_to}
                onChange={(e) => this.filterChange(e)}
              />
            </div>{" "}
          </TableFilterBox>
        </ContentBlock>
        <div className="col-12 my-3 d-flex align-items-center justify-content-between">
          <div className="dropdown dropdown-description d-inline-block mr-3">
            <button
              className="btn btn-danger dropdown-toggle btn-md"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Spausdinti
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => this.printSelected(e, "selected")}
              >
                Pažymėtus
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => this.printSelected(e, "all")}
              >
                Visus
              </a>
            </div>
          </div>
          {this.props.user.user_role.identifier !== "admin" && (
            <div className="d-inline-block description-store">
              <MdStore
                style={{
                  width: 30,
                  height: 25,
                  paddingRight: 5,
                }}
              />
              {this.getStoreName()}
            </div>
          )}
          <div>
            {this.eslButtonCheck() && (
              <SyncButton
                disabled={this.state.selected.length == 0}
                className={"btn btn-md btn-warning"}
                onClick={async () => {
                  await descriptionCallESL(this.state.selected);
                }}
              >
                ESL
              </SyncButton>
            )}
          </div>
        </div>
        <ContentBlock>
          <div>
            <ContentValidation
              loader={this.props.loading}
              array={this.props.descriptions}
              alertId_s="descriptionsAlert-s"
              alertId_e="descriptionsAlert-e"
            >
              <div className="table-responsive" style={{ overflow: "hidden" }}>
                <table className="table mt-3">
                  <thead>
                    <tr className="text-muted">
                      <th className="text-black">
                        <Checkbox
                          onChange={() => this.handleCheckboxAll()}
                          checked={this.state.selectedAll}
                        />
                      </th>
                      <th className="text-black">Foto</th>
                      <th className="text-black">Barkodas</th>
                      <th className="text-black">ESL</th>
                      <th className="text-black">Kodas</th>
                      <th className="text-black">Pavadinimas</th>
                      <th className="text-black">Kategorija</th>
                      <th className="text-black text-center">Spausdinamas</th>
                      <th className="text-black text-center">Instrukcija</th>
                      <th className="text-black"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.descriptions &&
                      this.props.descriptions.map((item, index) => {
                        return (
                          <TableColumnDescription
                            key={index}
                            index={index}
                            item={item}
                            onPrint={this.printSelected}
                            onEmail={this.openModal}
                            setSelected={() => this.handleToggle(item.id)}
                            selected={this.state.selected.includes(item.id)}
                            page={this.state.page}
                            store={this.state.filter.store}
                            openModal={() =>
                              this.setState({ description_id: item.id })
                            }
                          />
                        );
                      })}
                  </tbody>
                </table>
                <Pagination
                  setPagination={this.setPagination}
                  page={this.state.page}
                  totals={this.props.totals}
                  pageCount={this.state.pageCount}
                />
              </div>
            </ContentValidation>
          </div>
        </ContentBlock>
        {this.state.description_id && (
          <DescriptionModalWrapper
            showModal={this.state.showModal}
            modalInfo={this.state.modalInfo}
            description_id={this.state.description_id}
            store_id={this.state.filter.store}
            closeModal={() =>
              this.setState({
                description_id: false,
                showModal: false,
                modalInfo: { id: 0, number: 0 },
              })
            }
          />
        )}
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  descriptions: state.description.descriptions,
  totals: state.description.totals,
  loading: state.description.loading_descriptions,
  errors: state.errors,
  auth: state.auth.user,
  stores: state.store.storeItems,
  user: state.auth.user,
  families: state.family.families,
});

export default connect(mapStateToProps, {
  getDescriptions,
  getStoreItems,
  getFamilies,
})(Description);
